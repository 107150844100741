import React from 'react'
import { TextInput, SelectInput,  TabbedForm, FormTab } from 'react-admin'
import { ExtendedInput, DatePickerCanal } from '../common/inputs'
import { DomainsItems } from '../../tools'
import { MainIcon, AuditedIcon, } from '../../constants/icons'

const CardEdit = ({...props}) => (
	<TabbedForm {...props}>
		<FormTab label="Fiche" icon={<MainIcon />}>
			<TextInput label="fields.id" source="id" disabled/>
			<SelectInput label="fields.roleid" source="roleid" choices={DomainsItems.getSelectList(22)} allowEmpty={false} />
			<SelectInput label="fields.civilityid" source="civilityid" choices={DomainsItems.getSelectList(23)} allowEmpty={false} />
			<TextInput label="fields.lastname" source="lastname" />
			<TextInput label="fields.firstname" source="firstname" />
			<DatePickerCanal label="fields.birthdate" source="birthdate" />
			<DatePickerCanal label="fields.deathdate" source="deathdate" />
		</FormTab>
		<FormTab label="Gestion" icon={<AuditedIcon />}>
			<ExtendedInput label="fields.createdon" source="createdon" date disabled />
			<ExtendedInput label="fields.createdby" source="createdby" contact disabled />
			<ExtendedInput label="fields.modifiedon" source="modifiedon" date disabled />
			<ExtendedInput label="fields.modifiedby" source="modifiedby" contact disabled />
		</FormTab>
	</TabbedForm>
)

export default CardEdit
