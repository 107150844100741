import { linkToRecord } from 'ra-core'

/*
Example :
<ListItem component={Link} to={Links.CompaniesmoviesShow(1500)}>
	...
</ListItem>	
*/

export default class Links {
	static JobHistory = (refid, typeid) =>Links.Show(`/JobHistory?name=${refid}&typeid=${typeid}`)
	static CompaniesmoviesShow = (id) => Links.Show('/Companiesmovies', id, true)
	static CompaniesalbumsShow = (id) =>  Links.Show('/Companiesalbums', id, true)
	static CompaniesalbumsList = () => `/Companiesalbums`
	static CompaniesalbumsEdit = (id) => `/Companiesalbums/${id}/edit`
	static CompaniesalbumsAdd = (id) => `/Companiesalbums/create/${id ? id : 0}`
	static CompaniesalbumsAddItem = (id,value) => `/Companiesalbums/${id}/create/${value}`

	static CompaniesEditoAdd = () => `/Albumseditorials/create`

	static CompaniespeoplesShow = (id) => Links.Show('/Companiespeoples', id, true)
	static CompaniesproductsShow = (id) => Links.Show('/Companiesproducts', id, true)
	static CompaniestransfersShow = (id) => Links.Show('/Companiestransfers', id, true)
	static CompaniesShow = (id) => Links.Show('/Companies', id, true)
	static PeoplesmoviesList = (id) => `/Peoplesmovies?peopleid=${id}`
	static MoviespicturesList = (id) => `/Moviespictures?movieid=${id}`
	static PeoplespicturesList = (id) => `/Peoplespictures?peopleid=${id}`
	static DomainsitemsShow = (domainid, itemid, langid = 2) => `/Domainsitems/${domainid}_${langid}_${itemid}/Show`
	static MoviesprioritiesShow = (id) => Links.Show('/Moviespriorities', id, true)
	static PublicationPreparationLink = () => '/PublicationTracking/Preparation'
	static PublicationPlanningLink = () => '/PublicationTracking/Planning'
	static PromotionList = () => `/Promotions`
	static PromotionShow = (id) => `/Promotions/${id}/show`
	static PromotionEdit = (id) => `/Promotions/${id}/edit`
	static PromotionCreate = () => `/Promotions/create`
	static ConfigurationManagerList = () => '/DispatcherConfigurations'
	static ConfigurationManagerShow = (id) => Links.Show('/DispatcherConfigurations', id, true)
	static ConfigurationManagerEdit = (id) => `/DispatcherConfigurations/${id}/edit`
	static PlanningPricePackLink = (objectId) =>  `/PricePlanning/Price/${objectId} `

	static Show = (basePath, id, isShow) => `${linkToRecord(basePath, id, isShow ? 'show' : null)}`
}