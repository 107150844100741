import React from 'react'
import PropTypes from 'prop-types'
import { SelectInput } from 'react-admin'
import { isArrayLike } from '30-seconds-of-code'
import { Promotions } from '../../../tools'

const PromotionsInput = (props) => {
	const { withAll, ...rest } = props

	const choices = Promotions.getSelectList(withAll)

	return (
		<SelectInput choices={isArrayLike(choices) ? choices : []} {...rest} />
	)
}

PromotionsInput.propTypes = {
	withAll: PropTypes.bool,
}
 
PromotionsInput.defaultProps = {
	withAll: false,
	addLabel: true,
}

export default PromotionsInput