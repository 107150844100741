import CacheItems from './CacheItems'
import { Promotions as request } from '../requests'

class Promotions extends CacheItems {
	constructor(cacheKey = 'dataPromotions') {
		super(cacheKey, request.getAll, true)
	}

	static get instance() {
		if (!this.singleton) {
			this.singleton = new Promotions()
			this.singleton.logger.debug(`${this.__proto__.name} > new instance`)
		}

		return this.singleton
	}

	// --------------------
	// Custom methods below
	// --------------------

	// Get one promotion
	getItem = (promoid) => {
		if (promoid) {
			const data = this.getAllItems()
			if (null != data && data.length > 0) {
				return Object.values(data).find(item => item.id === promoid)
			}
		}
		return null
	}

	// Get label
	getLabel = (promoid) => {
		if (promoid === -1) return 'Tous (-1)'
		const item = this.getItem(promoid)
		const label = null != item ? item.name : ''
		return label.trim()
	}

	// Get formatted label 
	getFormattedLabel = (promoid) => {
		if (promoid) {
			const label = this.getLabel(promoid)
			return `${label} (${promoid})`.trim()
		}
		return ''
	}

	// Get formatted list to use in selects
	getSelectList = (withAll = false) => {
		const list = this.getAllItems()
		return this.getGenericItemsList(list, 'id', 'name', withAll)
	}

}

const PromotionsInstance = Promotions.instance

export default PromotionsInstance
