import React from 'react'
import { TextField } from 'react-admin'
import { withStyles } from '@material-ui/core/styles'

const styles =   ({
	field: { display: 'none' },
})

const HiddenField = withStyles(styles)(({ classes, ...props }) => (
	<TextField className={classes.field} {...props} />
))

export default HiddenField
