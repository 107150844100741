import React from 'react'
import PropTypes from 'prop-types'
import get from 'lodash/get'
import pure from 'recompose/pure'
import Typography from '@material-ui/core/Typography'
import sanitizeRestProps from './sanitizeRestProps'
import { withStyles } from '@material-ui/core/styles'

const styles = theme =>   ({
	root: {
		borderRadius: 4,
		color: theme.palette.common.black,
		backgroundColor: theme.palette.common.white,
		border: '1px solid #ced4da',
		padding: '6px 8px',
		minWidth: '150px',
		minHeight: '20px',
	},
})

const toLocaleStringSupportsLocales = (() => {
	// from https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Date/toLocaleString
	try {
		new Date().toLocaleString('i')
	} catch (error) {
		return error instanceof RangeError
	}
	return false
})()

export const MyDateField = ({
	classes,
	className,
	locales,
	options,
	record,
	showTime = false,
	source,
	...rest
}) => {
	if (!record) return null
	const value = get(record, source)
	if (value == null) return null
	const date = value instanceof Date ? value : new Date(value)
	const dateString = showTime
		? toLocaleStringSupportsLocales
			? date.toLocaleString(locales, options)
			: date.toLocaleString()
		: toLocaleStringSupportsLocales
			? date.toLocaleDateString(locales, options)
			: date.toLocaleDateString()

	return (
		<Typography
			component="span"
			body1="body1"
			className={className}
			classes={classes}
			{...sanitizeRestProps(rest)}
		>
			{dateString}
		</Typography>
	)
}

MyDateField.propTypes = {
	addLabel: PropTypes.bool,
	basePath: PropTypes.string,
	className: PropTypes.string,
	cellClassName: PropTypes.string,
	headerClassName: PropTypes.string,
	label: PropTypes.string,
	locales: PropTypes.oneOfType([
		PropTypes.string,
		PropTypes.arrayOf(PropTypes.string),
	]),
	options: PropTypes.object,
	record: PropTypes.object,
	showTime: PropTypes.bool,
	sortBy: PropTypes.string,
	source: PropTypes.string.isRequired,
	classes: PropTypes.object.isRequired
}

const PureMyDateField = pure(withStyles(styles)(MyDateField))

PureMyDateField.defaultProps = {
	addLabel: true,
}

export default PureMyDateField