import { DomainsItems, PricePlanning } from '../../../tools'

export const getTargetLabel = (target) => {
	let targetLabel = ''
	switch(target){
	case 'CANAL VOD PC/MAC':{
		targetLabel = 'Web PC/Mac' 
		break
	}
	case 'CANAL VOD FREE':{
		targetLabel = 'Free'
		break
	}
	case 'CANAL VOD BOUYGUES TELECOM':{
		targetLabel = 'Bouygues Tel'
		break
	}
	case 'CANAL VOD DECODEUR CANAL SAT/TNT':{
		targetLabel = 'Decodeur Canal Sat/TNT'
		break
	}
	case 'LE PORN DECODEUR CANAL SAT/TNT':{
		targetLabel = 'Le Porn Decodeur Canal Sat/TNT'
		break
	}
	case 'CANAL VOD APPLE':{
		targetLabel = 'Apple'
		break
	}
	case 'CANAL VOD G9':{
		targetLabel = 'Décodeur G9'
		break
	}
	case 'CANAL VOD ANDROID':{
		targetLabel = 'Android'
		break
	}
	case 'CANAL VOD SMART TV':{
		targetLabel = 'Smart TV'
		break
	}
	case 'CANAL VOD FREE 4K':{
		targetLabel = 'Free 4K'
		break
	}
	case 'CANAL VOD SFR':{
		targetLabel = 'SFR'
		break
	}
	case 'CANAL VOD G9MINI':{
		targetLabel = 'Décodeur G9 MINI'
		break
	}
	case 'M7 VOD BELGIQUE':{
		targetLabel = 'M7 Belgique'
		break
	}
	case 'M7 VOD PAYS-BAS':{
		targetLabel = 'M7 Pays-Bas'
		break
	}
	default: {
		targetLabel = target
		break
	}
	}
	return targetLabel
}

export const buildTargets = () => {
	var storedTargets = PricePlanning.getTargets()
	let targets = []
	storedTargets.forEach((storedTarget) => {
		const currentTarget = {
			value : storedTarget.label,
			// label : getTargetLabel(storedTarget.label),
			label : storedTarget.comments,
			checked : false,
			countryId : storedTarget.countryId
		}
		targets.push(currentTarget)
	})
	return targets
}

export const mapFormWithReduxStore = (pricingslists, publicationInterval, selectedClassificationId) => {
	if (selectedClassificationId){
		const formats = DomainsItems.getItems(29)
		const SDformat = formats.find(d => d.label === 'SD').itemid
		const HDformat = formats.find(d => d.label === 'HD').itemid
		const UHDformat = formats.find(d => d.label === 'UHD').itemid
		const pricingsEST = pricingslists.filter(w => w.pricingline.commercialtypeid === 7 && w.pricingid === selectedClassificationId)
		// const pricingsDVD = pricingslists.filter(w => w.pricingline.commercialtypeid === 8 && w.pricingid === selectedClassificationId)
		// const pricingsBR = pricingslists.filter(w => w.pricingline.commercialtypeid === 9 && w.pricingid === selectedClassificationId)
		const pricingsVOD = pricingslists.filter(w => w.pricingline.commercialtypeid >= 1 && w.pricingline.commercialtypeid <= 6 && w.pricingid === selectedClassificationId)
		return {
			priceVODSD: pricingsVOD.some(f => f.pricingline.formatid === SDformat) ? pricingsVOD.find(f => f.pricingline.formatid === SDformat).pricingline.price : null,
			priceVODHD: pricingsVOD.some(f => f.pricingline.formatid === HDformat) ? pricingsVOD.find(f => f.pricingline.formatid === HDformat).pricingline.price : null,
			priceVOD4K: pricingsVOD.some(f => f.pricingline.formatid === UHDformat) ? pricingsVOD.find(f => f.pricingline.formatid === UHDformat).pricingline.price : null,
			priceESTSD: pricingsEST.some(f => f.pricingline.formatid === SDformat) ? pricingsEST.find(f => f.pricingline.formatid === SDformat).pricingline.price : null,
			priceESTHD: pricingsEST.some(f => f.pricingline.formatid === HDformat) ? pricingsEST.find(f => f.pricingline.formatid === HDformat).pricingline.price : null,
			priceEST4K: pricingsEST.some(f => f.pricingline.formatid === UHDformat) ? pricingsEST.find(f => f.pricingline.formatid === UHDformat).pricingline.price : null,
			// priceHDDVD: pricingsDVD.some(f => f.pricingline.formatid === HDformat) ? pricingsDVD.find(f => f.pricingline.formatid === HDformat).pricingline.price : null,
			// price4KDVD: pricingsDVD.some(f => f.pricingline.formatid === UHDformat) ? pricingsDVD.find(f => f.pricingline.formatid === UHDformat).pricingline.price : null,
			// priceHDBR: pricingsBR.some(f => f.pricingline.formatid === HDformat) ? pricingsBR.find(f => f.pricingline.formatid === HDformat).pricingline.price : null,
			// price4KBR: pricingsBR.some(f => f.pricingline.formatid === UHDformat) ? pricingsBR.find(f => f.pricingline.formatid === UHDformat).pricingline.price : null,
			classificationId: selectedClassificationId,
			vodStartDate: publicationInterval ? publicationInterval.vodStartDate : null,
			vodEndDate: publicationInterval ? publicationInterval.vodEndDate : null,
			estStartDate: publicationInterval ? publicationInterval.estStartDate : null,
			estEndDate: publicationInterval ? publicationInterval.estEndDate : null,
			// estBkStartDate: publicationInterval ? publicationInterval.estBkStartDate : null,
			// estBkEndDate: publicationInterval ? publicationInterval.estBkEndDate : null,
			targets: buildTargets()
		}
	}
	else{
		return {
			priceVODSD: null,
			priceVODHD: null,
			priceVOD4K: null,
			priceESTSD: null,
			priceESTHD: null,
			priceEST4K: null,
			priceHDDVD: null,
			price4KDVD: null,
			priceHDBR: null,
			price4KBR: null,
			vodStartDate: publicationInterval ? publicationInterval.vodStartDate : null,
			vodEndDate: publicationInterval ? publicationInterval.vodEndDate : null,
			estStartDate: publicationInterval ? publicationInterval.estStartDate : null,
			estEndDate: publicationInterval ? publicationInterval.estEndDate : null,
			// estBkStartDate: publicationInterval ? publicationInterval.estBkStartDate : null,
			// estBkEndDate: publicationInterval ? publicationInterval.estBkEndDate : null,
			targets: buildTargets()
		}
	}
}

export const getExpandedTransfersRightsByPeriod = (transfersRightsByPeriod) => {
	var periodItemExpandedList = []
	for(let element of transfersRightsByPeriod){
		element.transfersRightsByPeriodItems.forEach((items) => {
			const periodItemExpanded = {
				startDate : element.startDate,
				endDate : element.endDate,
				...items
			}
			periodItemExpandedList.push(periodItemExpanded)
		})
	}
	return periodItemExpandedList
}

export const getPrice = (dataToBeUpdated, newPrice) => {
	if(dataToBeUpdated.productFormat === 'SD'){
		return newPrice.priceSD
	}
	if(dataToBeUpdated.productFormat === 'HD'){
		return newPrice.priceHD
	}
	if(dataToBeUpdated.productFormat === 'UHD'){
		return newPrice.price4K
	}
	// if(dataToBeUpdated.productFormat === 'HDDVD'){
	// 	return newPrice.priceHDDVD
	// }
	// if(dataToBeUpdated.productFormat === 'HDBR'){
	// 	return newPrice.priceHDBR
	// }
	// if(dataToBeUpdated.productFormat === 'UHDDVD'){
	// 	return newPrice.price4KDVD
	// }
	// if(dataToBeUpdated.productFormat === 'UHDBR'){
	// 	return newPrice.price4KBR
	// }
	return null
}

export const getPricesToBeUpdated = (formValues, modeComm) => {
	var modifiedPrice = {
		priceSD : null,
		priceHD : null,
		price4K : null
		// priceHDDVD : null,
		// priceHDBR : null,
		// price4KDVD : null,
		// price4KBR : null
	}
	switch(modeComm){
	case 'EST':{
		if(formValues.checkPriceESTSD && formValues.checkPriceESTSD === true && formValues.priceESTSD != null){
			modifiedPrice.priceSD = formValues.priceESTSD
		}
		if(formValues.checkPriceESTHD && formValues.checkPriceESTHD === true && formValues.priceESTHD != null){
			modifiedPrice.priceHD = formValues.priceESTHD
		}
		if(formValues.checkPriceEST4K && formValues.checkPriceEST4K === true && formValues.priceEST4K != null){
			modifiedPrice.price4K = formValues.priceEST4K
		}
		// if(formValues.checkPriceHDDVD && formValues.checkPriceHDDVD === true && formValues.priceHDDVD != null){
		// 	modifiedPrice.priceHDDVD = formValues.priceHDDVD
		// }
		// if(formValues.checkPriceHDBR && formValues.checkPriceHDBR === true && formValues.priceHDBR != null){
		// 	modifiedPrice.priceHDBR = formValues.priceHDBR
		// }
		// if(formValues.checkPrice4KDVD && formValues.checkPrice4KDVD === true && formValues.price4KDVD != null){
		// 	modifiedPrice.price4KDVD = formValues.price4KDVD
		// }
		// if(formValues.checkPrice4KBR && formValues.checkPrice4KBR === true && formValues.price4KBR != null){
		// 	modifiedPrice.price4KBR = formValues.price4KBR
		// }

		break
	}
	case 'VOD':{
		if(formValues.checkPriceVODSD && formValues.checkPriceVODSD === true && formValues.priceVODSD != null){
			modifiedPrice.priceSD = formValues.priceVODSD
		}
		if(formValues.checkPriceVODHD && formValues.checkPriceVODHD  === true && formValues.priceVODHD != null){
			modifiedPrice.priceHD = formValues.priceVODHD
		}
		if(formValues.checkPriceVOD4K && formValues.checkPriceVOD4K === true && formValues.priceVOD4K != null){
			modifiedPrice.price4K = formValues.priceVOD4K
		}
		break
	}
	default: {
		break
	}
	}
	return modifiedPrice
}

export const isItemModified = (formValues, modeComm, currentItem, priceModifiedInTheForm ) => {
	const isPriceModified = getPrice(currentItem, priceModifiedInTheForm) !== null 
		&& getPrice(currentItem, priceModifiedInTheForm) !== '' 
		&& getPrice(currentItem, priceModifiedInTheForm) !== currentItem.price ? true : false
	let isDateModified = false
	switch(modeComm){
	case 'EST' : {
		// if((currentItem.productFormat === 'HDDVD' 
		// 		|| currentItem.productFormat === 'HDBR'  
		// 		|| currentItem.productFormat === 'UHDDVD' 
		// 		|| currentItem.productFormat === 'UHDBR') 
		// 		&& formValues.checkEstBkDate){
		// 	if(formValues.estBkStartDate !== currentItem.publicationPeriodStartDate || formValues.estBkEndDate !== currentItem.publicationPeriodEndDate){
		// 		isDateModified = true
		// 	}
		// }
		//else
		if(formValues.checkEstDate) {
			if(formValues.estStartDate !== currentItem.publicationPeriodStartDate || formValues.estEndDate !== currentItem.publicationPeriodEndDate){
				isDateModified = true
			}
		}
		break
	}
	case 'VOD' : {
		if(formValues.checkVodDate){
			if(formValues.vodStartDate !== currentItem.publicationPeriodStartDate || formValues.vodEndDate !== currentItem.publicationPeriodEndDate){
				isDateModified = true
			}
		}
		break
	}
	default:
		break;
	}
	
	const isDataModified = {
		isPriceModified : isPriceModified,
		isDateModified  :isDateModified
	}
	return isDataModified
}

export const verifyAndApplyPublicationDate = (currentItem, dataToBeUpdated, modeComm, formValues, isPreview) => {
	switch(modeComm){
	case 'EST':{
		if(formValues.checkEstDate){//formValues.checkEstBkDate || 
			//EST B&K
			// if(currentItem.productFormat === 'HDDVD' || currentItem.productFormat === 'HDBR' 
			// 	|| currentItem.productFormat === 'UHDDVD' || currentItem.productFormat === 'UHDBR'){
			// 	dataToBeUpdated.PublicationPeriodStartDate = formValues.estBkStartDate
			// 	dataToBeUpdated.PublicationPeriodEndDate = formValues.estBkEndDate ? formValues.estBkEndDate : null
			// }
			//EST
			//else
			{
				dataToBeUpdated.PublicationPeriodStartDate = formValues.estStartDate
				dataToBeUpdated.PublicationPeriodEndDate = formValues.estEndDate ? formValues.estEndDate : null
			}
		}
		else{
			if(isPreview){
				dataToBeUpdated.PublicationPeriodStartDate = currentItem.publicationPeriodStartDate
				dataToBeUpdated.PublicationPeriodEndDate = currentItem.publicationPeriodEndDate
			}
			else{
				dataToBeUpdated.PublicationPeriodStartDate = currentItem.publicationPeriodStartDate
				dataToBeUpdated.PublicationPeriodEndDate = currentItem.publicationPeriodEndDate
			}	
		}
		break
	}
	case 'VOD':{
		if(formValues.checkVodDate){
			dataToBeUpdated.PublicationPeriodStartDate = formValues.vodStartDate
			dataToBeUpdated.PublicationPeriodEndDate = formValues.vodEndDate ? formValues.vodEndDate : null
		}
		else{
			if(isPreview){
				dataToBeUpdated.PublicationPeriodStartDate = currentItem.publicationPeriodStartDate
				dataToBeUpdated.PublicationPeriodEndDate = currentItem.publicationPeriodEndDate
			}
			else{
				dataToBeUpdated.PublicationPeriodStartDate = currentItem.publicationPeriodStartDate
				dataToBeUpdated.PublicationPeriodEndDate = currentItem.publicationPeriodEndDate
			}
		}
		break
	}
	default: {
		break
	}
	}
}

export const prepareUpdate = (formValues, transfersRightsData, currentContentId, objectTypeId, isPreview, userId) => {
	// const userId  = parseInt(Token.getId(), 10)
	const dataArrayIndex = `${currentContentId}_${objectTypeId}`
	let transfersRightsFiltered = []
	let valuesToBeUpdated = []
	
	const formSelectedTargets = formValues.targets.filter(target => target.checked === true).map(target => { return target.value } )
	const estPrice = getPricesToBeUpdated(formValues, 'EST')
	const vodPrice = getPricesToBeUpdated(formValues, 'VOD')

	const currentTransfersRights = transfersRightsData[dataArrayIndex].transfersRights

	// filter by target checked
	formSelectedTargets.forEach((target) => {
		const tempTransfersRights = currentTransfersRights.filter(transferRight => transferRight.target === target)
		tempTransfersRights.forEach(item=>{
			transfersRightsFiltered.push(item)
		})
	})
	// get EST and VOD in the filtered price
	const dataToBeUpdatedEst = transfersRightsFiltered.filter(item => item.modeComm === 'EST')
	const dataToBeUpdatedVod = transfersRightsFiltered.filter(item => item.modeComm === 'VOD')
	dataToBeUpdatedEst.forEach((item) => {
		//filter by price checked
		const isItemUpdated = isItemModified(formValues, 'EST', item, estPrice)
		if(isItemUpdated.isPriceModified || isItemUpdated.isDateModified){
			let currentValue = {
				Id : item.id,
				Price: isItemUpdated.isPriceModified ? getPrice(item, estPrice) : item.price,
				ModifiedBy : userId,
				Recordid : item.recordid,
				IsPriceModified : isItemUpdated.isPriceModified, 
				IsDateModified : isItemUpdated.isDateModified
			}
			verifyAndApplyPublicationDate(item, currentValue, 'EST', formValues, isPreview) // Apply only if the publication date is checked
			valuesToBeUpdated.push(currentValue)
		}
	})
	dataToBeUpdatedVod.forEach((item) => {
		const isItemUpdated = isItemModified(formValues, 'VOD', item, vodPrice)
		if(isItemUpdated.isPriceModified || isItemUpdated.isDateModified){
			let currentValue = {
				Id : item.id,
				Price: isItemUpdated.isPriceModified ? getPrice(item, vodPrice) : item.price,
				ModifiedBy : userId,
				Recordid : item.recordid ,
				IsPriceModified : isItemUpdated.isPriceModified, 
				IsDateModified : isItemUpdated.isDateModified
			}
			verifyAndApplyPublicationDate(item, currentValue, 'VOD', formValues, isPreview) // Apply only if the publication date is checked
			valuesToBeUpdated.push(currentValue)
		}
	})
	if(isPreview){
		if(valuesToBeUpdated.length === 0){
			return valuesToBeUpdated
		}
		else{
			var valuesForPreview = currentTransfersRights.map(function(item){
				if(valuesToBeUpdated.find(v => v.Id === item.id)){
					return {
						...item,
						price : valuesToBeUpdated.find(v => v.Id === item.id).Price,
						publicationPeriodStartDate : valuesToBeUpdated.find(v => v.Id === item.id).PublicationPeriodStartDate,
						publicationPeriodEndDate : valuesToBeUpdated.find(v => v.Id === item.id).PublicationPeriodEndDate,
					}
				}
				else return item
			})
			return valuesForPreview
		}
	}
	return valuesToBeUpdated
}