
import React, { Component } from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import { connect } from 'react-redux'
import compose from 'recompose/compose'
import Card from '@material-ui/core/Card'
import { ImageGridList } from '../common/lists'
import LogoPicture from '../../pictures/typo_logo_incruste_hawc_bleu.png'
import { Funny } from '../../requests'
import { AUTH_PAGE_LOADED } from '../../constants/actions'
// import { darkTheme } from '../layout/themes'
import { MyNotification } from '../layout'
import { withStyles, ThemeProvider } from '@material-ui/core/styles'
import { Button } from '@material-ui/core'

import CssBaseline from '@material-ui/core/CssBaseline'
import TextField from '@material-ui/core/TextField'
import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'
import Container from '@material-ui/core/Container'
import { Authentification } from '../../requests'
import IconButton from "@material-ui/core/IconButton"
import Visibility from "@material-ui/icons/Visibility"
import InputAdornment from "@material-ui/core/InputAdornment"
import VisibilityOff from "@material-ui/icons/VisibilityOff"
// eslint-disable-next-line react/prop-types

const Logo = ({ src, classes }) => <img src={src} alt="Hawc" className={classes.logo} />

const styles = theme => ({
	main: {
		display: 'flex',
		flexDirection: 'column',
		minHeight: '100vh',
		height: '1px',
		alignItems: 'center',
		justifyContent: 'flex-start',
		backgroundRepeat: 'no-repeat',
		backgroundSize: 'cover',
	},
	card: {
		position: 'fixed',
		top: '25%',
		minWidth: 300,
		textAlign: 'center',
	},
	logo: {
		width: 'auto',
		height: '50px',
		marginTop: '20px',
	},
	error: {
		color: 'red',
		fontSize: 'larger',
		fontWeight: 'bold',
		padding: '25px'
	},
	helperText: {
		//position: "absolute",
		marginRight: "1%",
		//bottom: "-1.6em",
		right: "0",
		fontSize: '0.7rem',
		color: 'darkred'
	},
	valid: {
		color: 'green'
	},
	invalid: {
		color: 'black'
	}
})
// eslint-disable-next-line no-unused-vars
const sanitizeRestProps = ({ classes, className, location, title, array, theme, staticContext, onLoadMosaic, ...rest }) => rest

class ResetPassWord extends Component {
	state = {
		ResetPwd: '',
		ReinitPwdstatus: true,
		confPassword: '',
		length: false,
		uppercase: false,
		lowercase: false,
		number: false,
		special: false,
		identique: false,
		PwdError: false,
		showPassword: false,
		showPasswordConf: false,
		expiredToken: false,
		succeedPwdReset: false

	}

	UNSAFE_componentWillMount() {
		const key = 'AUTH_MOSAIC'
		const mosaic = localStorage.getItem(key)
		if (null != mosaic) {
			this.props.onLoadMosaic(JSON.parse(mosaic))
		}
		else {
			Funny.getRandomMosaic(346, 1).then(json => {
				this.props.onLoadMosaic(json.data)
				localStorage.setItem(key, JSON.stringify(json.data))
			})
		}
	}
	handleClickShowPassword = (type) => {
		type ==='init' ?
		this.setState({  showPassword: !this.state.showPassword }):
		this.setState({  showPasswordConf: !this.state.showPasswordConf })
	}
	  
	handleMouseDownPassword = (event) => {
		event.preventDefault()
	}

	handlePWdReset = (event) => {
		const { length, uppercase, lowercase, number, special, identique } = this.state
		const { token } = this.props.match.params
		event.preventDefault()
		const data = new FormData(event.currentTarget)
		
		const params = {
			password: data.get('Newpassword'),
			confirmpassword: data.get('ConfirmNewpassword')
		}
		
		if (length && uppercase && lowercase && number && special && identique) {
			this.setState({ PwdError: false })
			
			return new Promise((resolve, reject) =>
				Authentification.passwordReset(params.password, token).then((res) => {
					this.setState({ ReinitPwdstatus: true,expiredToken: false,succeedPwdReset:true })
					setTimeout(() => {
						window.location.href = process.env.PUBLIC_URL + '/#'
					}, 5000)
					resolve()
				}
				)
					.catch((error) => {
						console.log(error)
						if(error.status === 404) 
							this.setState({ expiredToken: true })
						else
							this.setState({ expiredToken: false })

						reject()
						this.setState({ ReinitPwdstatus: false })
					}))

		}
		else {
			this.setState({ PwdError: true })
		}
	}
	validateForm = (pwd) => {

		if (pwd.length >= 12) {
			this.setState({ length: true })
		} else {
			this.setState({ length: false })
		}

		// Check uppercase
		if (/[A-Z]/.test(pwd)) {
			this.setState({ uppercase: true })
		} else {
			this.setState({ uppercase: false })
		}

		// Check lowercase
		if (/[a-z]/.test(pwd)) {
			this.setState({ lowercase: true })
		} else {
			this.setState({ lowercase: false })
		}

		// Check number
		if (/[0-9]/.test(pwd)) {
			this.setState({ number: true })
		} else {
			this.setState({ number: false })
		}

		// Check special characters
		if (/[@!?#$%^&=]/.test(pwd)) {
			this.setState({ special: true })
		} else {
			this.setState({ special: false })
		}
		// Check identique Pwds
		if (pwd !== '' && this.state.confPassword === pwd) {
			this.setState({ identique: true })
		} else {
			this.setState({ identique: false })
		}
	

	}

	handleChange = (event) => {
		const { length, uppercase, lowercase, number, special,identique } = this.state
		this.setState({ ResetPwd: event.target.value })
		this.validateForm(event.target.value)
		if (length && uppercase && lowercase && number && special && identique ) 
			this.setState({PwdError: false})
	}
	// handleKeyPress = (event) => {
	// 	const blockedCharacters = ['+'] // Liste des caractères à bloquer
	
	// 	// if (blockedCharacters.includes(event.key)) {
	// 	//   event.preventDefault(); // Bloquer l'événement de saisie
	// 	// }
	//   };
	handleChangeConfPwd = (event) => {
		const { length, uppercase, lowercase, number, special,ResetPwd } = this.state
		this.setState({ confPassword: event.target.value })
		if (ResetPwd !=='' && ResetPwd === event.target.value) {
			this.setState({ identique: true })
			if (length && uppercase && lowercase && number && special ) 
				this.setState({PwdError: false})
		} else {
			this.setState({ identique: false })
		}
	}
	handlePaste = (event) => {
		event.preventDefault() // Prevent pasting
	}
	render() {
		const { classes, className, pictures, ...rest } = this.props
		const { length, uppercase, lowercase, number, special, identique, showPassword,showPasswordConf,confPassword } = this.state
		return (
			<div
				className={classnames(classes.main, className)}
				{...sanitizeRestProps(rest)}
			>
				<ImageGridList list={pictures} />

				<Card className={classes.card}>
					<div className={classes.avatar}>
						<Logo src={LogoPicture} classes={classes} />
					</div>
					<Container component="main" maxWidth="xs" style={{ width: "400px",padding:'20px' }}  >
						<CssBaseline />
						<Box
							sx={{
								marginTop: 2,
								marginBottom: 8,
								display: 'flex',
								flexDirection: 'column',
								alignItems: 'center',
							}}
						>
							<Typography style={{fontSize: '1.3rem',fontWeight:'bold',color:'#009dcd' }}>Nouveau mot de passe</Typography>
							{this.state.succeedPwdReset && <Typography style={{fontSize: '1.2rem' }}>votre mot de passe a été modifié avec succès</Typography>}
 							{this.state.expiredToken ? <Typography style={{ color: 'red' }}>Ce lien de réinitialisation a expiré</Typography> :
							<div>
							{!this.state.expiredToken && !this.state.succeedPwdReset &&
							<Box component="form" onSubmit={this.handlePWdReset} noValidate sx={{ mt: 1 }} style={{ minWidth: '300px' }}>

								<TextField
									//margin="normal"
									required
									fullWidth
									name="Newpassword"
									label="Nouveau mot de passe"
									type={showPassword ? "text" : "password"}  
									id="Newpassword"
									onChange={this.handleChange}
									InputProps={{
										//onKeyPress: this.handleKeyPress,
										onPaste: this.handlePaste,
										endAdornment : <>
										{
											<InputAdornment position="end">
											  <IconButton
												onClick={()=>this.handleClickShowPassword('init')}
												onMouseDown={this.handleMouseDownPassword}

											  >
												{showPassword ? <Visibility /> : <VisibilityOff />}
											  </IconButton>
											</InputAdornment>
										  }
										  </>	
									}}

									
									error={!this.state.ReinitPwdstatus}
									helperText={!this.state.ReinitPwdstatus ? 'Erreur lors de modifications du mot de passe, Veuillez réessayer' :''}
									FormHelperTextProps={{
										classes: {
											root:classes.helperText
										}
									}}
								/>
								<ul style={{ padding: '0 15px' }} >
									<li style={{ color: length ? 'green' : 'black', textAlign: 'left' }}>Minimum 12 caractères.</li>
									<li style={{ color: uppercase ? 'green' : 'black', textAlign: 'left' }} >Au moins 1 caractère majuscule.</li>
									<li style={{ color: lowercase ? 'green' : 'black', textAlign: 'left' }} >Au moins 1 caractère minuscule.</li>
									<li style={{ color: number ? 'green' : 'black', textAlign: 'left' }} >Au moins 1 chiffre.</li>
									<li style={{ color: special ? 'green' : 'black', textAlign: 'left' }} >Au moins 1 caractère spécial.(@!?#$%^&+=)</li>
								</ul>
								<TextField
									//margin="normal"
									required
									fullWidth
									name="ConfirmNewpassword"
									label="Confirmer mot de passe"
									type={showPasswordConf ? "text" : "password"} 
									id="ConfirmNewpassword"
									// autoComplete="current-password"
									InputProps={{
										onPaste: this.handlePaste,
										endAdornment : <>
										{
											<InputAdornment position="end">
											  <IconButton
												onClick={()=>this.handleClickShowPassword('confirm')}
												onMouseDown={this.handleMouseDownPassword}

											  >
												{showPasswordConf ? <Visibility /> : <VisibilityOff />}
											  </IconButton>
											</InputAdornment>
										  }
										  </>	,
									}}
									onChange={this.handleChangeConfPwd}
									// error={!(length && uppercase && lowercase && number && special && identique )}

									error={!( identique ) && confPassword !==''}
								/>
								<ul style={{ padding: '0 15px' }} >
									<li style={{ color: identique ? 'green' : 'black', textAlign: 'left' }}>Mots de passes identiques</li>
								</ul>

								<Button
									type="submit"
									variant="contained"
									sx={{ mt: 3, mb: 2 }}
									style={{ marginBottom: '15px', background: '#288DB1', color: 'white', minWidth: '350px' }}
								>
									VALIDER
								</Button>

							</Box>}
							</div>}
						</Box>


					</Container>

				</Card>
				<MyNotification />
			</div>
		)
	}
}

ResetPassWord.propTypes = {
	className: PropTypes.string,
	authProvider: PropTypes.func,
	classes: PropTypes.object.isRequired,
	input: PropTypes.object,
	meta: PropTypes.object,
	previousRoute: PropTypes.string,
	onLoadMosaic: PropTypes.func,
}



const mapStateToProps = state => ({
	...state,
	pictures: state.reducer ? state.reducer.Auth.pictures : null,
})

const mapDispatchToProps = dispatch => ({
	onLoadMosaic: (payload) =>
		dispatch({ type: AUTH_PAGE_LOADED, payload: payload }),

})

const enhance = compose(
	connect(mapStateToProps, mapDispatchToProps),
	withStyles(styles)
)

const EnhancedReset = enhance(ResetPassWord)

const ResetPassWordWithTheme = props => (
	<ThemeProvider >
		<EnhancedReset {...props} />
	</ThemeProvider>
)

export default ResetPassWordWithTheme
