import { ON_EXPORT_JOBS_LIST_UPDATE } from '../constants/actions'

export function onExportJobs(Jobs){
	return {
		type : ON_EXPORT_JOBS_LIST_UPDATE,
		payload : {
			ExportJobs : Jobs
		}
	}
}
 