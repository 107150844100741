import React from 'react'
import { CardActions, ShowButton, ListButton } from 'react-admin'

const sanitizeRestProps = ({
	basePath,
	className,
	record,
	hasShow,
	hasList,
	...rest
}) => rest

const MyEditActions = ({
	basePath,
	className,
	data,
	hasShow,
	hasList,
	resource,
	...rest
}) =>
	(
		<CardActions className={className} {...sanitizeRestProps(rest)}>
			{hasShow && <ShowButton basePath={basePath} record={data} />}
			{hasList && <ListButton basePath={basePath} />}
		</CardActions>
	)

export default MyEditActions