import React, { Component } from 'react'
import PropTypes from 'prop-types'
import SmallIconButton from './SmallIconButton'
import { ProductIcon } from '../../../constants/icons'

class ProductIconButton extends Component {
	render() {
		const { id, classes, movieid } = this.props
		return (
			<SmallIconButton id={id} movieid={movieid} classes={classes} label='pluralizations.products' basePath='/Companiesproducts'>
				<ProductIcon />
			</SmallIconButton>
		)
	}
}

ProductIconButton.propTypes = {
	id: PropTypes.number,
	movieid: PropTypes.number,
	classes: PropTypes.object.isRequired,
}

export default ProductIconButton