import { AUTH_PAGE_LOADED,RESET_PASSEWORD,CREATE_PASSEWORD } from '../constants/actions'

export default (state = {}, action) => {
	switch (action.type) {
	case AUTH_PAGE_LOADED:
		return {
			...state,
			pictures: action.payload
		}
	case RESET_PASSEWORD:
		return {
			...state,
			resetPwd: action.payload
		}
	case CREATE_PASSEWORD:
		return {
			...state,
			createPwd: action.payload
		}
	default:
		return state
	}
}