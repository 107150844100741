import React, { Component } from 'react'
import PropTypes from 'prop-types'
import TextField from '@material-ui/core/TextField'
import { addField, FieldTitle } from 'ra-core'
import get from 'lodash/get'
import sanitizeRestProps from './sanitizeRestProps'
import { getTextFromProps } from '../../../tools/xmlDocParser'

let recordSource = ''

export class XMLInput extends Component {
	handleBlur = eventOrValue => {
		this.props.onBlur(eventOrValue)
		this.props.input.onBlur(eventOrValue)
	}

	handleFocus = event => {
		this.props.onFocus(event)
		this.props.input.onFocus(event)
	}

	handleChange = eventOrValue => {
		this.props.onChange(eventOrValue)
		this.props.input.onChange(eventOrValue)
	}

	render() {
		const {
			className,
			input,
			isRequired,
			label,
			meta,
			options,
			resource,
			source,
			type,
			record,
			...rest
		} = this.props
		if (typeof meta === 'undefined') {
			throw new Error(
				'The XMLInput component wasn'/'t called within a redux-form <Field>. Did you decorate it and forget to add the addField prop to your component? See https://marmelab.com/react-admin/Inputs.html#writing-your-own-input-component for details.'
			)
		}
		const { touched, error } = meta

		recordSource = get(record, source)

		switch (label) {
		case 'Lieu de naissance':
			recordSource = getTextFromProps('BIRTHPLACE', recordSource)
			break
		case 'Lieu de décès':
			recordSource = getTextFromProps('DEATHPLACE', recordSource)
			break
		case 'Publication':
			recordSource = getTextFromProps('PRESSREVIEWS', recordSource, true)
			break
		default:
			recordSource = null
			break
		}

		return (
			<TextField
				margin="normal"
				type={type}
				label={
					<FieldTitle
						label={label}
						source={source}
						resource={resource}
						isRequired={isRequired}
					/>
				}
				error={!!(touched && error)}
				helperText={touched && error}
				className={className}
				{...options}
				{...sanitizeRestProps(rest)}
				{...input}
				onBlur={this.handleBlur}
				onFocus={this.handleFocus}
				onChange={this.handleChange}
				value={recordSource}
			/>
		)
	}
}

XMLInput.propTypes = {
	className: PropTypes.string,
	input: PropTypes.object,
	isRequired: PropTypes.bool,
	label: PropTypes.string,
	meta: PropTypes.object,
	name: PropTypes.string,
	onBlur: PropTypes.func,
	onChange: PropTypes.func,
	onFocus: PropTypes.func,
	options: PropTypes.object,
	resource: PropTypes.string,
	source: PropTypes.string,
	type: PropTypes.string,
	record: PropTypes.object
}

XMLInput.defaultProps = {
	onBlur: () => { },
	onChange: () => { },
	onFocus: () => { },
	options: {},
	type: 'text',
}

export default addField(XMLInput)
