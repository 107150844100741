import React, { Component } from 'react'
import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import { connect } from 'react-redux'
import compose from 'recompose/compose'
import { CardHeader, Typography } from '@material-ui/core'
import { PriceInput, DatePickerCanal, PricingsInput } from '../common/inputs'
import { SimpleForm, CheckboxGroupInput, SaveButton, fetchEnd, fetchStart, showNotification, TextInput } from 'react-admin'
import Grid from '@material-ui/core/Grid'
import { submit, isSubmitting, initialize } from 'redux-form'
import { crudGetList as crudGetListAction } from 'ra-core/lib/actions/dataActions'
import { PRICING_TOOL_SELECT_LINE_RESET } from '../../constants/actions'
import { validateTargets, validatePrice } from './validate'
import { validateRequired } from '../common/forms/globalValidation'
import { withStyles } from '@material-ui/core/styles'
import { ConfigContext } from './../layout/ConfigProvider'
const styles = ({
	card: {
		marginTop: '-40px',
	},
	cardPrice: {
		marginTop: '-30px',
	},
	price: {
		width: '80px',
	},
	inputPrices: {
		// width: '170px',
		marginRight: '16px',
	},
})

const REDUX_FORM_PRICING_TOOL_UPDATE = 'form-pricing-tool-update'

export const targets = [
	{ id: 'ALL', name: 'Toutes' },
	{ id: 'FREE', name: 'Free' },
	{ id: 'BOUYGUES TV', name: 'Bouygues Tel' },
	{ id: 'CPTV', name: 'Décodeur R7' },
	{ id: 'CPTVGBOX', name: 'Décodeur G9' },
	{ id: 'WEB', name: 'Web PC/Mac' },
	{ id: 'WINDOWS MOBILE', name: 'Windows Mobile' },
	{ id: 'IOS', name: 'Apple iOS/Mac OSX' },
	{ id: 'ANDROID', name: 'Android' },
	{ id: 'SMARTTV', name: 'Smart TV' },
	{ id: 'FREE4K', name: 'Free 4K' },
]

class PricingToolUpdate extends Component {

	static contextType = ConfigContext;

	handleUpdateClick = () => {
		// eslint-disable-next-line react/prop-types
		const { submit } = this.props

		// Trigger a submit of our custom quick create form
		// This is needed because our modal action buttons are oustide the form
		submit(REDUX_FORM_PRICING_TOOL_UPDATE)
	}

	handleSubmit = values => {
		// const { fetchStart, fetchEnd, showNotification } = this.props

		console.log('submited values:')
		console.log(values)

		// let results = {}
		// const initialValues = Object.entries(values).filter(value => value[0] !== 'typeid' && value[0] !== 'pictures')
		// initialValues.forEach(value => {
		// 	results[value[0]] = value[1]
		// })

		// let query = {
		// 	sourceId: results.sourceid,
		// 	objectId: results.objectid,
		// 	id: results.id,
		// 	pivotId: results.pivotid,
		// 	cartoucheId: results.cartoucheid,
		// 	sendToBddp: results.sendtobddp,
		// 	applyToSeason: results.applytoseason
		// }
		// Dispatch an action letting react-admin know a API call is ongoing
		// fetchStart()

		// As we want to know when the new post has been created in order to close the modal, we use the
		// dataProvider directly
		// dataProvider(CREATE, `PictureWizard/Pivot/?${stringify(query)}`, { data: formData })
		// 	.then(({ data }) => {
		// 		this.setState({ showDialog: false })
		// 		showNotification('Done', 'success') // TODO: schedule refactoring
		// 	})
		// 	.catch(error => {
		// 		showNotification(error.message, 'error')
		// 	})
		// 	.finally(() => {
		// 		// Dispatch an action letting react-admin know a API call has ended
		// 		fetchEnd()
		// 	})
	}

	handleAll = (event, keys, oldKeys) => {



	}

	formDefaultValues = () => {
		// eslint-disable-next-line react/prop-types
		const { selected, pricingslists } = this.props
		if (selected) {
			return {
				...selected
			}
		}
		else if (pricingslists) {
			return this.mapPricingline(Object.values(pricingslists))
		}
		return {}
	}

	mapPricingline(pricingslists) {
		const pricingsEST = pricingslists.filter(w => w.pricingline.commercialtypeid === 7)
		const pricingsDVD = pricingslists.filter(w => w.pricingline.commercialtypeid === 8)
		const pricingsBR = pricingslists.filter(w => w.pricingline.commercialtypeid === 9)
		const pricingsVOD = pricingslists.filter(w => w.pricingline.commercialtypeid >= 1 && w.pricingline.commercialtypeid <= 6)

		return {
			priceVODSD: pricingsVOD.some(f => f.pricingline.formatid === 1) ? pricingsVOD.find(f => f.pricingline.formatid === 1).pricingline.price : null,
			priceVODHD: pricingsVOD.some(f => f.pricingline.formatid === 2) ? pricingsVOD.find(f => f.pricingline.formatid === 2).pricingline.price : null,
			priceVOD4K: pricingsVOD.some(f => f.pricingline.formatid === 7) ? pricingsVOD.find(f => f.pricingline.formatid === 7).pricingline.price : null,
			priceESTSD: pricingsEST.some(f => f.pricingline.formatid === 1) ? pricingsEST.find(f => f.pricingline.formatid === 1).pricingline.price : null,
			priceESTHD: pricingsEST.some(f => f.pricingline.formatid === 2) ? pricingsEST.find(f => f.pricingline.formatid === 2).pricingline.price : null,
			priceEST4K: pricingsEST.some(f => f.pricingline.formatid === 7) ? pricingsEST.find(f => f.pricingline.formatid === 7).pricingline.price : null,
			priceHDDVD: pricingsDVD.some(f => f.pricingline.formatid === 2) ? pricingsDVD.find(f => f.pricingline.formatid === 2).pricingline.price : null,
			price4KDVD: pricingsDVD.some(f => f.pricingline.formatid === 7) ? pricingsDVD.find(f => f.pricingline.formatid === 7).pricingline.price : null,
			priceHDBR: pricingsBR.some(f => f.pricingline.formatid === 2) ? pricingsBR.find(f => f.pricingline.formatid === 2).pricingline.price : null,
			price4KBR: pricingsBR.some(f => f.pricingline.formatid === 7) ? pricingsBR.find(f => f.pricingline.formatid === 7).pricingline.price : null,
		}
	}

	// eslint-disable-next-line no-unused-vars
	handleSearchPrices = (event, key, oldPayload) => {
		// eslint-disable-next-line react/prop-types
		const { crudGetList, onResetLine } = this.props

		onResetLine()
		initialize(REDUX_FORM_PRICING_TOOL_UPDATE)

		crudGetList(
			'Pricingslists', // resource
			{}, // pagination
			{}, // sort
			{ pricingid: key } //filter
		)
	}

	render() {
		// eslint-disable-next-line react/prop-types
		const { classes, isSubmitting } = this.props
		const { config } = this.context
		const priceLimit = config && config.priceLimit
		return (
			<Card>
				<CardHeader title="Créer / Modifier une période de commercialisation"></CardHeader>
				<SimpleForm	
					// We override the redux-form name to avoid collision with the react-admin main form
					form={REDUX_FORM_PRICING_TOOL_UPDATE}
					resource="Transfersrightsprices/MoviesByPeriods"
					defaultValue={this.formDefaultValues}
					onSubmit={this.handleSubmit}
					toolbar={null}
					style={{ marginTop: '-20px' }}
				>
					<TextInput source="priceids" label="priceids" style={{ display: 'none' }} />
					<TextInput source="modecomms" label="modecomms" style={{ display: 'none' }} />

					<Grid container direction="row" spacing={24}>
						<Grid item xs>
							<Card>
								<CardContent>
									<PricingsInput onChange={this.handleSearchPrices} label="Classe de prix actuelle" source="classificationid" shrink={false} options={{ style: { minWidth: '444px' } }} /><br />
									<DatePickerCanal source="dateStartVOD" label="Début de période VOD" dateOnly shrink={true} validate={validateRequired} />
									<DatePickerCanal source="dateEndVOD" label="Fin de période VOD" dateOnly shrink={true} validate={validateRequired} /><br />
									<DatePickerCanal source="dateStartEST" label="Début de période EST" dateOnly shrink={true} validate={validateRequired} />
									<DatePickerCanal source="dateEndEST" label="Fin de période EST" dateOnly shrink={true} validate={validateRequired} />
								</CardContent>
							</Card>
						</Grid>
						<Grid item xs>
							<Card>
								<CardContent>
									<CheckboxGroupInput source="targets" label="Cibles" choices={targets} onChange={this.handleAll.bind(this)} translateChoice={false} validate={validateTargets} />
								</CardContent>
							</Card>
						</Grid>
					</Grid>

					<Grid container direction="row" justify="flex-start" alignItems="flex-start" style={{ marginTop: '16px' }}>
						<Grid item className={classes.inputPrices} xs>
							<Card>
								<CardContent>
									<Typography variant="title">Prix VOD</Typography>
									<PriceInput label="SD" source="priceVODSD" className={classes.price} validate={validatePrice(priceLimit)} /><br />
									<PriceInput label="HD" source="priceVODHD" className={classes.price} validate={validatePrice(priceLimit)} /><br />
									<PriceInput label="4K" source="priceVOD4K" className={classes.price} validate={validatePrice(priceLimit)} /><br />
								</CardContent>
							</Card>
						</Grid>
						<Grid item className={classes.inputPrices} xs>
							<Card>
								<CardContent>
									<Typography variant="title">Prix EST</Typography>
									<PriceInput label="SD" source="priceESTSD" className={classes.price} validate={validatePrice(priceLimit)} /><br />
									<PriceInput label="HD" source="priceESTHD" className={classes.price} validate={validatePrice(priceLimit)} /><br />
									<PriceInput label="4K" source="priceEST4K" className={classes.price} validate={validatePrice(priceLimit)} /><br />
								</CardContent>
							</Card>
						</Grid>
						{/* <Grid item className={classes.inputPrices} xs>
							<Card>
								<CardContent>
									<Typography variant="title">Prix DVD</Typography>
									<PriceInput label="HD DVD" source="priceHDDVD" className={classes.price} validate={validatePrice} /><br />
									<PriceInput label="4K DVD" source="price4KDVD" className={classes.price} validate={validatePrice} /><br />
								</CardContent>
							</Card>
						</Grid> */}
						{/* <Grid item className={classes.inputPrices} xs>
							<Card>
								<CardContent>
									<Typography variant="title">Prix BR</Typography>
									<PriceInput label="HD BR" source="priceHDBR" className={classes.price} validate={validatePrice} /><br />
									<PriceInput label="4K BR" source="price4KBR" className={classes.price} validate={validatePrice} /><br />
								</CardContent>
							</Card>
						</Grid> */}
						<Grid item xs>
							<Card>
								<CardContent>
									<SaveButton
										saving={isSubmitting}
										onClick={this.handleUpdateClick}
									/>
								</CardContent>
							</Card>
						</Grid>
					</Grid>

				</SimpleForm>

			</Card>
		)
	}
}

const mapStateToProps = state => ({
	...state,
	isSubmitting: isSubmitting(REDUX_FORM_PRICING_TOOL_UPDATE)(state),
	selected: state.reducer.Pricing.selected,
	pricingslists: state.admin.resources['Pricingslists']
		? state.admin.resources['Pricingslists'].data
		: {},
})

const mapDispatchToProps = ({
	submit,
	initialize,
	fetchStart,
	fetchEnd,
	showNotification,
	crudGetList: crudGetListAction,
	onResetLine: () => { return { type: PRICING_TOOL_SELECT_LINE_RESET } },
})

const enhance = compose(
	connect(mapStateToProps, mapDispatchToProps),
	withStyles(styles, { index: 1 })
)

export default enhance(PricingToolUpdate)
