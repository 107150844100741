import React, { Component, Fragment } from 'react'
import Button from '@material-ui/core/Button'
import compose from 'recompose/compose'
import { connect } from 'react-redux'
import { change, getFormValues } from 'redux-form'
import pure from 'recompose/pure'

const REDUX_FORM_NAME = 'record-form'

class ButtonGroupInput extends Component {
	handleClick = (value) => {
		const { change } = this.props
		switch (value) {
		case 'VF':
			change(REDUX_FORM_NAME, 'langid', 2)
			change(REDUX_FORM_NAME, 'subtitlesid', 0)
			break
		case 'VO':
			change(REDUX_FORM_NAME, 'langid', 1)
			change(REDUX_FORM_NAME, 'subtitlesid', 0)
			break
		case 'VOST':
			change(REDUX_FORM_NAME, 'langid', 1)
			change(REDUX_FORM_NAME, 'subtitlesid', 2)
			break
		case 'VOST/VF':
			change(REDUX_FORM_NAME, 'langid', 2)
			change(REDUX_FORM_NAME, 'subtitlesid', 2)
			break
		default:
		}
	}

	render() {
		const { formData } = this.props

		let langid
		let subtitlesid

		if (formData !== undefined) {
			langid = formData.langid
			subtitlesid = formData.subtitlesid
		}

		return (
			<Fragment>
				<Button variant="contained" color={langid === 2 && subtitlesid === 0 ? 'secondary' : 'default'} onClick={this.handleClick.bind(this, 'VF')}>VF</Button>
				<Button variant="contained" color={langid === 1 && subtitlesid === 0 ? 'secondary' : 'default'} onClick={this.handleClick.bind(this, 'VO')}>VO</Button>
				<Button variant="contained" color={langid === 1 && subtitlesid === 2 ? 'secondary' : 'default'} onClick={this.handleClick.bind(this, 'VOST')}>VOST</Button>
				<Button variant="contained" color={langid === 2 && subtitlesid === 2 ? 'secondary' : 'default'} onClick={this.handleClick.bind(this, 'VOST/VF')}>VOST/VF</Button>
			</Fragment>
		)
	}
}

const mapStateToProps = (state, { record }) => ({
	formData: getFormValues(REDUX_FORM_NAME)(state) || record,
})

const mapDispatchToProps = {
	change
}

ButtonGroupInput.defaultProps = {
	addLabel: true
}

const enhance = compose(
	connect(mapStateToProps, mapDispatchToProps),
	pure
)

export default enhance(ButtonGroupInput)
