import { SHOW_FULL_SCREEN_MODAL, HIDE_FULL_SCREEN_MODAL } from '../constants/actions'

const previousState = { open: false, payload: null }

export default (state = previousState, action) => {
	if (action.type === SHOW_FULL_SCREEN_MODAL) {
		return {
			open: true,
			payload: action.payload,
		}
	}
	else if (action.type === HIDE_FULL_SCREEN_MODAL) {
		return {
			open: false,
			payload: null,
		}
	}
	return state
}
