import React from 'react'
import Dialog from '@material-ui/core/Dialog'
import Slide from '@material-ui/core/Slide'
import PropTypes from 'prop-types'
import cyan from '@material-ui/core/colors/cyan'
import Button from '@material-ui/core/Button'
import { CancelIcon ,DoneIcon,CloseIcon } from '../../../constants/icons'
import { withStyles } from '@material-ui/core/styles'

const Transition = React.forwardRef(function Transition(props, ref) {
	return <Slide direction="up" ref={ref} {...props} />
})

const styles = theme =>  ({
	paperWidthSm : {
		maxWidth: '600px',
		width: '400px'
	},
	button: {
		margin: theme.spacing(1),
		width : '150px'
	},
	buttonCancel: {
		margin: theme.spacing(1),
		width : '150px',
		background: '#e23434'
	},
	buttonCheck: {
		margin: theme.spacing(1),
		width : '150px',
		background: '#0c9e0c'
	},
	leftIcon: {
		marginRight: theme.spacing(1),
	},
	dialogHeader:{
		padding: '10px 10px 10px',
		background: cyan[500],
		color: 'white',
		fontSize: 'medium',
		fontWeight: 'bold',
		textAlign: 'center',
		//height: '36px'
	},
	dialogContent: {
		padding: '16px 24px 17px',
		flex: '1 1 auto',
		color: '#000',
		fontSize: '1rem',
		fontWeight: '400',
		textAlign : 'center',
		//backgroundColor : '#424242'
	},
	dialogFooter: {
		flex: '0 0 auto',
		// margin: '8px 4px',
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'flex-end',
		//backgroundColor : '#424242'
	},
	margin: {
		margin: theme.spacing(1),
	},
	closeIcon:{
		position: 'absolute',
  		width : '15px',
    	top: '0px',
    	right: '0px',
		color: 'white',
		'&:hover': {
			background: 'red'
		},
		borderRadius: '0'
	},
})


function ValidateUpdateResponsable(props) {
	const [open, setOpen] = React.useState(false)
	const { modalOpen, onClose, classes, handleValidateClick,validateUpdateContent } = props
	const { title, resultText } = validateUpdateContent
	function setOpenModalFromProps(modalOpen){
		setOpen(modalOpen)
	}

	React.useEffect(() => {
		setOpenModalFromProps(modalOpen)
	})

	return (
		<div>
			<Dialog
				open={open}
				TransitionComponent={Transition}
				keepMounted
				onClose={onClose}
				aria-labelledby="alert-dialog-slide-title"
				aria-describedby="alert-dialog-slide-description"
				disableEscapeKeyDown = {true}
				//disableBackdropClick = {true}
				classes = {{
					paperWidthSm : classes.paperWidthSm
				}}
			>
				<div className={classes.dialogHeader}>
					{title}
					<Button onClick={onClose}  className={classes.closeIcon} >
						<CloseIcon/>				 
					</Button>	
				</div>
				<div className={classes.dialogContent}>
					{resultText}
				</div>
				<div className={classes.dialogFooter}>
					<Button onClick={handleValidateClick} color="primary" variant="contained" className={classes.buttonCheck}  >
						<DoneIcon className={classes.leftIcon} />
						Valider
					</Button>
					<Button onClick={onClose} color="secondary" variant="contained" className={classes.buttonCancel}  >
						<CancelIcon className={classes.leftIcon} />
						Annuler
					</Button>
				</div>
			</Dialog>
		</div>
	)
}

ValidateUpdateResponsable.propTypes = {
	modalOpen: PropTypes.bool,
	onClose: PropTypes.func
}

export default withStyles(styles)(ValidateUpdateResponsable)