import React, { Component } from 'react'
import { Button } from 'react-admin'
import { HistoryIcon } from '../../../constants/icons'

class BackButton extends Component {
	handleClick = () => {
		//eslint-disable-next-line
		history.go(-1)
	};

	render() {
		return (
			<Button label="ra.action.back" onClick={this.handleClick}>
				<HistoryIcon />
			</Button>
		)
	}
}

export default BackButton
