import React from 'react'
import { MyUserMenu } from '../layout'
import LogoPicture from '../../pictures/logo.png'
import Logo from './Logo'
import CustomAppBar from './CustomAppBar'
import { SearchWarning } from '.'
import { withStyles } from '@material-ui/core/styles'


const styles = theme => ({
	title: {
		flex: 1,
		textOverflow: 'ellipsis',
		whiteSpace: 'nowrap',
		overflow: 'hidden',
	},
	spacer: {
		flex: 1,
	},
	logo: {
		width: '40px',
		height: '40px',
		filter: 'brightness(0) invert(1)'
	},
})

const MyAppBar = props => {
	const { classes } = props
	return (
		<CustomAppBar {...props} userMenu={<MyUserMenu />}>
			{/* <CssBaseline /> */}
			{/* <Typography variant="h8" color="inherit" className={classes.title} id="react-admin-title">BO HAWC</Typography> */}
			<Logo src={LogoPicture} classes={classes} />
			<span className={classes.spacer} />
			<SearchWarning />
		</CustomAppBar>
	)
}

export default withStyles(styles)(MyAppBar)