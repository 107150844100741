import React, { useState, useEffect, useContext } from 'react';
import compose from 'recompose/compose'
import { translate } from 'react-admin'

import { withStyles } from '@material-ui/core/styles'
import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import { CircularProgress, IconButton, Select, MenuItem } from '@material-ui/core';
import DateFnsUtils from '@date-io/date-fns';
import {
	MuiPickersUtilsProvider,
	KeyboardDatePicker,
  } from '@material-ui/pickers';
import { ArrowDownward, ArrowUpward, Search } from '@material-ui/icons/';

import { CustomAlert } from '../layout/GlobalStyles';

import { Contacts, DateFormatter, Links } from '../../tools'
import ImportexportRequest from '../../requests/ImportexportRequest'
import { ConfigContext } from './../layout/ConfigProvider'
const styles = (theme) => ({
	headerContainer:{
		fontSize:'1.5em',
		verticalAlign:'bottom'
	},
	jobsContainer: {
		display: 'flex',
		width:'100%',
		maxWidth:'100%',
		minHeight: '3em',
		alignItems: 'stretch',
		border: '2px solid black',
		borderTop: '0px',
		borderRight: "0px solid black",
		"&:first-child":{borderTop: "2px solid black"},
		// "&:last-child":{borderRight: "0px solid black"}
		// "&:first-child > $labelContainer":{border: "0px"}
	},
	jobsLabelContainer: {
		display: 'flex',
		alignItems: 'center',
		// height: '100%',
		minHeight: '3em',
		width: '20%',
		borderRight: "2px solid black",
		justifyContent:'center',
		textAlign:'center'
	},
	jobLabel: {
		fontSize: '1em',
		wordBreak: 'break-word',
		color: 'black',
	},
	label: {
		fontSize: '1em',
		// fontWeight: 'bold',
		// color: '#288DB1',
		color: 'black',
	},
	selectHistory: {
		marginLeft: '20px',
		marginRight: '20px',
		fontSize:'1em',
		verticalAlign:'bottom'
	}
});

const JobHistory = (props) => {
	const {classes} = props
	const { config } = useContext(ConfigContext)

	const [jobsHistory, setJobsHistory] = useState([])
	const [snackbar, setSnackbar] = useState({
		open: false,
		message: ' ',
		severity: ''
	  });
	const [selectHistoryValue, setSelectHistoryValue] = useState('15')
	const [selectedStartDate, setSelectedStartDate] = useState(new Date())
	// const [selectedStartDate, setSelectedStartDate] = React.useState(new Date());
	const [selectedEndDate, setSelectedEndDate] = useState(new Date());
	const [isLoading, setIsLoading] = useState(false)
	const [scrollPosition, setScrollPosition] = useState(0)
	const [limitJobsHistories, setlimitJobHistories] = useState(100)


	const handleScroll = () => {
    	const position = window.pageYOffset;
		setScrollPosition(position);
	};

	useEffect(() => {
		setlimitJobHistories(config ? config.limitJobsHistories : 100)
    	window.addEventListener('scroll', handleScroll, { passive: true });

    	return () => {
        	window.removeEventListener('scroll', handleScroll);
    	};
	}, []);

	const parser = new DOMParser();


	const getJobHistory = async (refid, typeid, amount) => {
		setIsLoading(true)
		await ImportexportRequest.getJobHistory(refid, typeid, amount).then((json) => {
			setJobsHistory(json.data.values)
			setIsLoading(false)
		}).catch((error)=>{
			console.log(error)
			setJobsHistory([])
		})
	}

	const getJobHistoryAll = async (refid, typeid, limit) => {
		setIsLoading(true)
		await ImportexportRequest.getJobHistoryAll(refid, typeid, limit).then((json) => {
			setJobsHistory(json.data.values)
			setIsLoading(false)
		}).catch((error)=>{
			console.log(error)
			handleSnackbarOpen(`Le nombre d''éléments affichable maximum est dépassé (${limitJobsHistories}), veuillez affiner votre recherche`, "error")
			setJobsHistory([])
		})
	}

	const getJobHistoryPeriod = async (refid, typeid, limit, startdate, enddate) => {
		setIsLoading(true)
		await ImportexportRequest.getJobHistoryPeriod(refid, typeid, limit, startdate.toLocaleDateString('en-US'), enddate.toLocaleDateString('en-US')).then((json) => {
			setJobsHistory(json.data.values)
			console.log(startdate)
			setIsLoading(false)
		}).catch((error)=>{
			console.log(error)
			handleSnackbarOpen(`Le nombre d''éléments affichable maximum est dépassé (${limitJobsHistories}), veuillez affiner votre recherche`, "error")
			setJobsHistory([])
		})
	}


	useEffect( () => {
		var date = new Date();
		date.setDate(date.getDate() - 1);
		setSelectedStartDate(date)
		console.log(selectedStartDate)
		console.log(selectedEndDate)
		getJobHistory(refid, typeid, selectHistoryValue)
	}, [])

	const fetchXmlToObject = (data) => {
			const xml = parser.parseFromString(data, 'text/xml');

			let prop = []
			prop = xml.querySelectorAll('PROP')
			let obj = {}
			prop.forEach(prop=>{
				const xml = parser.parseFromString(prop, 'text/xml');
				if(xml){
					const name = prop.getAttribute('NAME')
					const value = prop.getAttribute('VALUE')
					obj[name] = value
				}
			})
			// console.log(obj)
			return obj
	}

	const queryParameters = window.location.hash
	const refid = queryParameters.substring(queryParameters.indexOf("?refid=")+7, queryParameters.lastIndexOf("&"))
	const typeid = queryParameters.substring(queryParameters.indexOf("&typeid=")+8)



	const handleSelectHistoryChange = (event) =>{
		setSelectHistoryValue(event.target.value)
		switch(event.target.value){
			case 15: 
				getJobHistory(refid, typeid, event.target.value);
				break;
			case 25:
				getJobHistory(refid, typeid, event.target.value);
				break;
			case 100:
				getJobHistoryAll(refid, typeid, 5);
				break;
			case 101:
				getJobHistoryPeriod(refid, typeid, limitJobsHistories, selectedStartDate, selectedEndDate)
				break;
		}
	}


	const splitComment = (string) => {
		if(string){
			//Regexp pour séparer par "," ou ";" tout en gardant la ponctuation en fin de phrase
			var comment = string.split(/(?<=[,;])/)
			return (
				comment.map(value=>{
					return (
						<p style={{margin:0}}>{value}</p>
					)
				})
			)
		}else{
			return null
		}
		
	}

	const splitProperties = (string) => {
		var properties = fetchXmlToObject(string)
		var propertiesArray = []
		for (const [key, value] of Object.entries(properties)) {
			if(key.includes('FILENAME')){
				var htmlString=key+" = <a target='_blank' href='"+process.env.REACT_APP_BO+"/Logs/"+refid+"/"+value+"'>"+value+"</a>"
				propertiesArray.push(htmlString);
			}else{
				propertiesArray.push(`${key} = ${value}`);
			}
			
		}
		return(
			propertiesArray.map(value=>{
				return(
					<div dangerouslySetInnerHTML={{__html: value}} style={{margin:0, marginBottom:'5px'}}></div>
				)
			})
		)
	}


	
	const handleMouseDown = (event) => {
		event.preventDefault();
	}

	const handleStartDateChange = (date) => {
		setSelectedStartDate(date);
	  };

	
	const handleEndDateChange = (date) => {
		setSelectedEndDate(date);
	  };

	//Gestion de la snackbar d'erreur
	const handleSnackbarOpen = (message, severity) => {
		setSnackbar({ ...snackbar, open: true, message: message, severity: severity});
	  };
	const handleSnackbarClose = () => {
	setSnackbar({ ...snackbar, open: false });
	};
	const { vertical, horizontal, open, message } = snackbar;




	var renderDates
	if(selectHistoryValue=='101')
	renderDates = 
		<>
			du
			<MuiPickersUtilsProvider utils={DateFnsUtils} style={{verticalAlign:'bottom'}}>
			<KeyboardDatePicker
			label="Date de début"
			format="dd/MM/yyyy"
			value={selectedStartDate}
			onChange={handleStartDateChange}
			KeyboardButtonProps={{
				'aria-label': 'change date',
			}}
			style={{marginLeft:"20px", marginRight:'20px', verticalAlign:'bottom'}}
			/>
			au
			<KeyboardDatePicker
			label="Date de fin"
			format="dd/MM/yyyy"
			value={selectedEndDate}
			onChange={handleEndDateChange}
			KeyboardButtonProps={{
				'aria-label': 'change date',
			}}
			style={{marginLeft:"20px", marginRight:'20px', verticalAlign:'bottom'}}
			/>
			</MuiPickersUtilsProvider>
			<IconButton
				onClick={()=>{getJobHistoryPeriod(refid, typeid, limitJobsHistories, selectedStartDate, selectedEndDate)}}
				onMouseDown={handleMouseDown}
				size="small"
				style={{verticalAlign:'bottom'}}
			>
				<Search />
			</IconButton>
		</>

	var count=0
	var	renderJobsHistory
	if(isLoading==true){
		renderJobsHistory=
		<CircularProgress />
	}else{
		renderJobsHistory=
		jobsHistory.map(value=>{
			count++
			return(
				<div className={classes.jobsContainer} style={{width:'99.5%'}}>
					<div className={classes.jobsLabelContainer} style={{width:'2%'}}>
						<div className={classes.jobLabel}>
							{count}
						</div>
					</div>
					<div className={classes.jobsLabelContainer} style={{width:'10%'}}>
						<div className={classes.jobLabel}>
							{value.type} ({value.typeid})
						</div>
					</div>
					<div className={classes.jobsLabelContainer} style={{width:'5%'}}>
						<div className={classes.jobLabel}>
							{value.jobid}
						</div>
					</div>
					<div className={classes.jobsLabelContainer} style={{width:'5%', backgroundColor:value.state=='Traité'?'green':value.state=='En cours'?'orange':'red'}}>
						<div className={classes.jobLabel}>
							{value.state}
						</div>
					</div>
					<div className={classes.jobsLabelContainer} style={{width:'19%'}}>
						<div className={classes.jobLabel}>
							{splitComment(value.comment)}
						</div>
					</div>
					<div className={classes.jobsLabelContainer} style={{width:'5%'}}>
						<div className={classes.jobLabel}>
							{DateFormatter.getDayNameDateHourMinSec(value.executiondate)}
						</div>
					</div>
					<div className={classes.jobsLabelContainer} style={{width:'5%'}}>
						<div className={classes.jobLabel}>
							{Contacts.getFormattedLabel(value.createdby)}
						</div>
					</div>
					<div className={classes.jobsLabelContainer} style={{width:'5%'}}>
						<div className={classes.jobLabel}>
							{DateFormatter.getDayNameDateHourMinSec(value.createdon)}
						</div>
					</div>
					<div className={classes.jobsLabelContainer} style={{width:'5%'}}>
						<div className={classes.jobLabel}>
							{Contacts.getFormattedLabel(value.modifiedby)}
						</div>
					</div>
					<div className={classes.jobsLabelContainer} style={{width:'5%'}}>
						<div className={classes.jobLabel}>
							{DateFormatter.getDayNameDateHourMinSec(value.modifiedon)}
						</div>
					</div>
					<div className={classes.jobsLabelContainer} style={{width:'6%'}}>
						<div className={classes.jobLabel}>
							{value.servername}
						</div>
					</div>
					<div className={classes.jobsLabelContainer} style={{width:'27%', justifyContent:'flex-start', textAlign:'start'}}>
						<div className={classes.jobLabel}>
							{splitProperties(value.properties)}
						</div>
					</div>
				</div>
			)
		})
	}

	const scrollMax = document.documentElement.scrollHeight - document.documentElement.clientHeight
	return(
		<>
		<Card>
			<CardContent style={{width:"98vw%"}}>
				<div className={classes.headerContainer}>
					Affichage
					<Select
					value={selectHistoryValue}
					onChange={handleSelectHistoryChange}
					displayEmpty
					className={classes.selectHistory}
					inputProps={{ 'aria-label': 'Without label' }}
					>
						<MenuItem value={15}>des 15 derniers éléments</MenuItem>
						<MenuItem value={25}>des 25 derniers éléments</MenuItem>
						<MenuItem value={100}>de tout l'historique</MenuItem>
						<MenuItem value={101}>de la période</MenuItem>
					</Select>
					{renderDates}
				</div>
				<div style={{marginTop:'30px'}}>
					Nombre d'éléments : {jobsHistory.length}
				</div>
				<div style={{width:'100%', marginTop:'5px'}}>
					<div className={classes.jobsContainer} style={{width:'99.5%'}}>
						<div className={classes.jobsLabelContainer} style={{width:'2%'}}>
							<div className={classes.jobLabel} style={{fontWeight:'bold'}}>
								
							</div>
						</div>
						<div className={classes.jobsLabelContainer} style={{width:'10%'}}>
							<div className={classes.jobLabel} style={{fontWeight:'bold'}}>
								Type
							</div>
						</div>
						<div className={classes.jobsLabelContainer} style={{width:'5%'}}>
							<div className={classes.jobLabel} style={{fontWeight:'bold'}}>
								JobId
							</div>
						</div>
						<div className={classes.jobsLabelContainer} style={{width:'5%'}}>
							<div className={classes.jobLabel} style={{fontWeight:'bold'}}>
								Status
							</div>
						</div>
						<div className={classes.jobsLabelContainer} style={{width:'19%'}}>
							<div className={classes.jobLabel} style={{fontWeight:'bold'}}>
								Commentaire
							</div>
						</div>
						<div className={classes.jobsLabelContainer} style={{width:'5%'}}>
							<div className={classes.jobLabel} style={{fontWeight:'bold'}}>
								Exécution le
							</div>
						</div>
						<div className={classes.jobsLabelContainer} style={{width:'5%'}}>
							<div className={classes.jobLabel} style={{fontWeight:'bold'}}>
								Créé par
							</div>
						</div>
						<div className={classes.jobsLabelContainer} style={{width:'5%'}}>
							<div className={classes.jobLabel} style={{fontWeight:'bold'}}>
								Créé le
							</div>
						</div>
						<div className={classes.jobsLabelContainer} style={{width:'5%'}}>
							<div className={classes.jobLabel} style={{fontWeight:'bold'}}>
								Modifié par
							</div>
						</div>
						<div className={classes.jobsLabelContainer} style={{width:'5%'}}>
							<div className={classes.jobLabel} style={{fontWeight:'bold'}}>
								Modifié le
							</div>
						</div>
						<div className={classes.jobsLabelContainer} style={{width:'6%'}}>
							<div className={classes.jobLabel} style={{fontWeight:'bold'}}>
								Serveur
							</div>
						</div>
						<div className={classes.jobsLabelContainer} style={{width:'27%'}}>
							<div className={classes.jobLabel} style={{fontWeight:'bold'}}>
								Propriété
							</div>
						</div>
					</div>
					{renderJobsHistory}
				</div>

				{scrollPosition!=scrollMax||scrollPosition==0?
				<IconButton
					onClick={()=>{
						window.scrollTo({
							top: document.body.scrollHeight,
							left: 0,
							behavior: 'smooth'
							})
						}}
					onMouseDown={handleMouseDown}
					size="small"
					style={{
						position:'fixed',
						bottom:'70px',
						right:'70px'
					}}
				>
						<ArrowDownward style={{fontSize:'75px', color:'white',backgroundColor:'black', borderRadius:'75px', opacity:0.5}} />
				</IconButton>
				:
				<IconButton
					onClick={()=>{
						window.scrollTo({
							top: 0,
							left: 0,
							behavior: 'smooth'
							})
						}}
					onMouseDown={handleMouseDown}
					size="small"
					style={{
						position:'fixed',
						top:'70px',
						right:'70px'
					}}
				>
						<ArrowUpward style={{fontSize:'75px', color:'white',backgroundColor:'black', borderRadius:'75px', opacity:0.5}} />
				</IconButton>}
			</CardContent>
		</Card>
		<CustomAlert libelle={snackbar.message} severity={snackbar.severity} open={open} setOpen={handleSnackbarClose} />
		</>
	)
}

const enhance = compose(
	withStyles(styles)
)

export default translate(enhance(JobHistory))