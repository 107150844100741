import React from 'react'
import { translate } from 'ra-core'
import PropTypes from 'prop-types'
import Icon from '@material-ui/core/Icon'
import IconButton from '@material-ui/core/IconButton'
import { Link } from 'react-router-dom'
import compose from 'recompose/compose'
import { stringify } from 'query-string'
import { Links } from '../../../tools'
import { withStyles } from '@material-ui/core/styles'

const styles = { }

function SmallIconButton(props) {
	const { label, basePath, id, classes, children, movieid, peopleid, translate } = props

	let to
	if (id) {
		to = Links.Show(basePath, id)
	}
	else {
		if ('/Companiesproducts' === basePath && movieid) {
			to = {
				pathname: basePath,
				search: stringify({
					filter: JSON.stringify({ q: movieid, mode: 4 }),
					pagination: JSON.stringify({ page: 1, perPage: 10 }),
					sort: JSON.stringify({ field: 'id', order: 'ASC' }),
				}),
			}
		}
		else if ('/Peoplesmovies' === basePath && peopleid) {
			to = Links.PeoplesmoviesList(peopleid)
		}
		else if ('/Peoplespictures' === basePath && peopleid) {
			to = Links.PeoplespicturesList(peopleid)
		}
		else if ('/Moviespictures' === basePath && movieid) {
			to = Links.MoviespicturesList(movieid)
		}
	}

	return (
		<IconButton component={Link} title={translate(label, { smart_count: 2 })} aria-label={translate(label, { smart_count: 2 })} className={classes.button} to={to}
		>
			<Icon className={classes.icon}>
				{children}
			</Icon>
		</IconButton>
	)
}

SmallIconButton.propTypes = {
	label: PropTypes.string,
	children: PropTypes.node,
	basePath: PropTypes.string.isRequired,
	id: PropTypes.number,
	movieid: PropTypes.number,
	peopleid: PropTypes.number,
	classes: PropTypes.object.isRequired,
}

const enhance = compose(
	withStyles(styles),
	translate
)
export default enhance(SmallIconButton)