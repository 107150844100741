import React, { Component } from 'react'
import PropTypes from 'prop-types'
import Paper from '@material-ui/core/Paper'
import Grid from '@material-ui/core/Grid'
import JobStatus from './JobStatus'
import { initializeArrayWithRange } from '30-seconds-of-code'
import { withStyles} from '@material-ui/core'

const styles = theme =>({
	root: {
		//...theme.mixins.gutters(),
		paddingTop: theme.spacing(2),
		paddingBottom: theme.spacing(2),
	},
	grid: {
		flexGrow: 1,
	},
})

class JobsStatus extends Component {
	constructor() {
		super()
		this.getGlobalStatus = this.getGlobalStatus.bind(this)
	}

	getGlobalStatus = (items, classes) => {
		const states = initializeArrayWithRange(5)

		return (
			<Grid container direction='row' justify='flex-start' alignItems='stretch' className={classes.grid} spacing={8}>
				{
					states.map(stateid => {
						let count = 0
						const filtered = items.filter(item => item.stateId === stateid)
						filtered.map(item => count += item.count)

						return (
							<Grid item key={`item_${stateid}`}>
								<JobStatus stateid={stateid} count={count} />
							</Grid>
						)
					})
				}
			</Grid>
		)
	}

	render() {
		const { ids, data, classes, isLoading } = this.props

		if (isLoading || null == ids || null == data) {
			return null
		}
		const items = Object.values(ids).map(id => { return data[id] })
		const globalStatuses = this.getGlobalStatus(items, classes)

		return (
			<Paper className={classes.root}>{globalStatuses}</Paper>
		)
	}
}

JobsStatus.propTypes = {
	ids: PropTypes.array,
	data: PropTypes.object,
	classes: PropTypes.object.isRequired,
	isLoading: PropTypes.bool,
}

export default withStyles(styles)(JobsStatus)
