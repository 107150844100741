import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-admin'
import Avatar from '@material-ui/core/Avatar'
//import { fade } from '@material-ui/core/styles/colorManipulator'
import { Links } from '../../../tools'
import { getId, getValue } from '../../../tools/utils'
import NameField from './NameField'
import Color from 'color'
import { withStyles } from '@material-ui/core/styles'

const styles = theme => ({
	root: {
		display: 'flex',
		flexWrap: 'nowrap',
		alignItems: 'center'
	},
	avatar: {
		color: '#fff',
		backgroundColor:  Color(theme.palette.text.primary).alpha(0.15).string(),//fade(theme.palette.text.primary, 0.15),
	}
})

const TransferField = props => {
	const { addId, addLabel, sourceId, sourceLabel, size, isLink, classes, style } = props

	if (!addId && !addLabel)
		return null

	const id = getId(props, sourceId)

	let label
	if (addLabel) {
		const name = getValue(props, sourceLabel)
		label = name ? name : ''
	}

	let field = (<div className={classes.root} style={style}>
		<Avatar sizes={size} className={classes.avatar}>{id}</Avatar>
		<NameField style={{ marginLeft: '5px' }} text={label} />
	</div>
	)

	return (
		<Fragment>
			{
				isLink ?
					<Link to={Links.CompaniestransfersShow(id)}>
						{field}
					</Link>
					:
					field
			}
		</Fragment>
	)
}

TransferField.propTypes = {
	record: PropTypes.object,
	sourceId: PropTypes.string,
	sourceLabel: PropTypes.string,
	addId: PropTypes.bool,
	addLabel: PropTypes.bool,
	size: PropTypes.number,
	classes: PropTypes.object.isRequired,
	style: PropTypes.string,
	isLink: PropTypes.bool,
}

TransferField.defaultProps = {
	record: {},
	sourceId: 'id',
	sourceLabel: 'label',
	addId: true,
	addLabel: true,
	size: 32,
	isLink: false,
}

export default withStyles(styles)(TransferField)
