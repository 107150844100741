import { translate } from 'react-admin'
import { Publicationstracking } from '../../../../requests'
import React from 'react'
import Dialog from '@material-ui/core/Dialog'
import Slide from '@material-ui/core/Slide'
import PropTypes from 'prop-types'
import cyan from '@material-ui/core/colors/cyan'
import Button from '@material-ui/core/Button'
import { DoneIcon, CloseIcon } from '../../../../constants/icons'
import TextField from '@material-ui/core/TextField'
import InputAdornment from '@material-ui/core/InputAdornment'
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'
import { onChangeGroup } from '../../../../actions/publicationTracking'
import { connect } from 'react-redux'
import compose from 'recompose/compose'
import InputLabel from '@material-ui/core/InputLabel'
import FormControl from '@material-ui/core/FormControl'
import { withStyles, createTheme , ThemeProvider } from '@material-ui/core/styles'


const Transition = React.forwardRef(function Transition(props, ref) {
	return <Slide direction="up" ref={ref} {...props} />
})
const theme = createTheme ({
	overrides: {
		MuiSelect: {
			selectMenu: {
				width: '250px',
				overflow: 'hidden',
				minHeight: '1.1875em',
				whiteSpace: 'nowrap',
				textOverflow: 'ellipsis',
				textAlign: 'left'
			}
		}
	},
})
const styles = theme => ({
	paperWidthSm: {
		maxWidth: '600px',
		width: '400px'
	},
	button: {
		margin: theme.spacing(1),
		width: '150px'
	},
	buttonCancel: {
		margin: theme.spacing(1),
		width: '150px',
		background: '#e23434'
	},
	buttonCheck: {
		margin: theme.spacing(1),
		width: '150px',
		background: '#0c9e0c'
	},
	leftIcon: {
		marginRight: theme.spacing(1),
	},
	dialogHeader: {
		padding: '10px 10px 10px',
		background: cyan[500],
		color: 'white',
		fontSize: 'medium',
		fontWeight: 'bold',
		textAlign: 'center',
		//height: '36px'
	},
	dialogContent: {
		padding: '16px 24px 17px',
		flex: '1 1 auto',
		color: '#fff',
		fontSize: '1rem',
		fontWeight: '400',
		textAlign: 'center',
		backgroundColor: '#fff'

	},
	dialogFooter: {
		flex: '0 0 auto',
		// margin: '8px 4px',
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'flex-end',
		backgroundColor: '#fff'
	},
	margin: {
		margin: theme.spacing(1),
	},
	closeIcon: {
		color: 'white',
		position: 'absolute',
		width: '15px',
		top: '0px',
		right: '0px',
		borderRadius: '0',
		'&:hover': {
			background: 'red'
		}
	},
	textfield: {
		width: '150px',
		marginRight: '10%'
	},
	customSelect: {
		width: '100px',
		right: '10%'
	},
	labelfileld: {
		width: '280px',
	}
})
const colors = [
	{
		value: '#e8ecf1'
	},
	{
		value: '#fcf9ea'
	},
	{
		value: '#ffffc7'
	},
	{
		value: '#dcffcc'
	},
	{
		value: '#b0e0a8'
	},
	{
		value: '#bbded6'
	},
	{
		value: '#ddf3f5'
	},
	{
		value: '#39a9cb'
	},
	{
		value: '#c3bef0'
	},
	{
		value: '#f6dfeb'
	},
	{
		value: '#fcd1d1'
	},
	{
		value: '#e3cdc1'
	},
	{
		value: '#ffc5a1'
	},
	{
		value: '#f9b208'
	},
	{
		value: '#CA8A8B'
	},
	{
		value: '#B7657B'
	},
]
function AddTrackingTypeModal(props) {
	const [open, setOpen] = React.useState(false)
	const [gp, setGroup] = React.useState('')
	const [color, setColor] = React.useState('#fff')
	const [groupName, SetNewGroup] = React.useState('')
	const [typeItem, SetNewType] = React.useState('')

	const clearState = () => {
		setGroup('')
		setColor('')
		SetNewGroup('')
		SetNewType('')
	}
	const { modalOpen, onClose, classes, itemToadd, group } = props
	function setOpenModalFromProps(modalOpen) {
		setOpen(modalOpen)
	}
	function handleChange(event) {
		setGroup(event.target === undefined ? '' : event.target.value)
	}
	function handleChangeColor(event) {
		setColor(event.target.value)
	}
	function handleblurGroup(event) {
		SetNewGroup(event.target === undefined ? '' : event.target.value)
	}
	function handleBlurType(event) {
		SetNewType(event.target === undefined ? '' : event.target.value)
	}
	React.useEffect(() => {
		setOpenModalFromProps(modalOpen)
	})
	function handleSavetype(event, typeItem, gp) {
		let newtype = {
			trackinggroupid: gp,
			name: typeItem,
		}
		Publicationstracking.saveTrackingTypes(newtype)
		clearState()
		onClose()
	}
	function handleSaveGroup(event, groupName, color) {
		let group = {
			groupName: groupName,
			color: color
		}
		Publicationstracking.saveTrackingGroup(group)
		//window.location.reload(false);
		clearState()
		onClose()
	}
	function handleClose() {
		clearState()
		onClose()
	}
	if (itemToadd === 'type') {
		return (
			<div>
				<Dialog
					open={open}
					TransitionComponent={Transition}
					keepMounted
					onClose={onClose}
					aria-labelledby="alert-dialog-slide-title"
					aria-describedby="alert-dialog-slide-description"
					disableEscapeKeyDown={true}
					//disableBackdropClick={true}
					classes={{
						paperWidthSm: classes.paperWidthSm
					}}
				>

					<div className={classes.dialogHeader}>
						NOUVEAU TYPE
						<Button onClick={handleClose} className={classes.closeIcon}  >
							<CloseIcon />
						</Button>
					</div>

					<div className={classes.dialogContent}>
						<div>
							<ThemeProvider theme={theme}>
								<TextField
									id="standard-select"
									select
									label="Groupe"
									value={gp}
									onChange={handleChange}
									defaultValue=''
									required
								//className ={classes.customSelect}
								>
									{group.map((value, index) => {
										return <MenuItem key={value.id} value={value.id} >{value.groupName}</MenuItem>
									})}
								</TextField>
								<TextField className={classes.labelfileld} id="standard-basic" label="Libellé "
									onChange={(event) => handleBlurType(event)} value={typeItem} required />

							</ThemeProvider>
						</div>
					</div>
					<div className={classes.dialogFooter}>
						<Button onClick={(event) => handleSavetype(event, typeItem, gp)} color="primary" variant="contained" className={classes.buttonCheck}  >
							<DoneIcon className={classes.leftIcon} />
							Ajouter
						</Button>
					</div>
				</Dialog>
			</div>
		)
	}
	else {
		return (
			<div>
				<Dialog
					open={open}
					TransitionComponent={Transition}
					keepMounted
					onClose={onClose}
					aria-labelledby="alert-dialog-slide-title"
					aria-describedby="alert-dialog-slide-description"
					disableEscapeKeyDown={true}
					//disableBackdropClick={true}
					classes={{
						paperWidthSm: classes.paperWidthSm
					}}
				>
					<div className={classes.dialogHeader}>
						NOUVEAU GROUPE
						<Button onClick={handleClose} type="reset" className={classes.closeIcon}  >
							<CloseIcon />
						</Button>
					</div>
					<div className={classes.dialogContent}>
						<div>
							<TextField className={classes.labelfileld} id="standard-basic" label="Nom du groupe" value={groupName} onChange={event => handleblurGroup(event)}
								InputProps={{ startAdornment: <InputAdornment position="start"> </InputAdornment> }} required />
							<FormControl className={classes.formControl}>
								<InputLabel id="demo-simple-select-helper-label">Couleur</InputLabel>
								<Select
									className={classes.labelfileld}
									label="Couleur"
									value={color}
									name="couleur"
									onChange={handleChangeColor}
									inputProps={{
										id: 'open-select'
									}}
									required
								>
									{colors.map((option, key) => (
										<MenuItem value={option.value} key={key}>
											<Button disabled={group !== undefined && group.filter(s => s.color === option.value)} style={{ background: option.value, minWidth: '120px' }}></Button>
										</MenuItem>
									))}
								</Select>
							</FormControl>
						</div>
					</div>
					<div className={classes.dialogFooter}>
						<Button onClick={(event) => handleSaveGroup(event, groupName, color)} type="reset" color="primary" variant="contained" className={classes.buttonCheck}   >
							<DoneIcon className={classes.leftIcon} />
							Ajouter
						</Button>
					</div>
				</Dialog>
			</div>
		)
	}
}
const mapDispatchToProps = ({
	onChangeGroup
})
AddTrackingTypeModal.propTypes = {
	modalOpen: PropTypes.bool,
	onClose: PropTypes.func,
	onChange: PropTypes.func,

}
const mapStateToProps = state => ({
	...state,
	selectedGroup: state.reducer.publicationTracking.selectedGroup !== null ? state.reducer.publicationTracking.selectedGroup : {
		id: 0,
		groupName: ''
	},

})
const enhance = compose(
	connect(mapStateToProps, mapDispatchToProps),
	withStyles(styles)
)

export default translate(enhance(AddTrackingTypeModal))