import React, { Component } from 'react'

class DateCell extends Component{
	constructor(props) {
		super(props)
	}

	render(){
		const { label, customWidth } = this.props
		return(
			<div style={{ width: customWidth }}>
				{label}
			</div>
		)
	}
}

export default DateCell