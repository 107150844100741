import React from 'react'
import { SimpleShowLayout, TextField, RichTextField } from 'react-admin'
import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import Typography from '@material-ui/core/Typography'
import CardChild from '../../CardChild'
import { ContactField, ExtendedTextField, CheckboxField, MyDateField } from '../common/fields'
import { withStyles } from '@material-ui/core/styles'

const styles = theme =>   ({
	cardPaddingMargin: {
		padding: 20,
		margin: 20,
		flex: '1 auto'
	},
	cardDiv: {
		flex: '1 auto'
	},
	flexContent: {
		display: 'flex',
		alignItems: 'flex-start'
	},
	input: {
		display: 'inline-block'
	},
	inputLeft: {
		display: 'inline-block',
		marginLeft: 32
	},
	noMarginLeft: {
		marginLeft: 0
	}
})

const CardShow = withStyles(styles)(({ classes, record } = this.props) => (
	<div className={classes.flexContent}>
		<Card className={classes.cardPaddingMargin}>
			<CardContent>
				<Typography variant="title">Fiche du transfert {record.label}</Typography>
			</CardContent>
			<CardChild>
				<SimpleShowLayout record={record}>
					<TextField label="fields.id" source="id" />
					<TextField label="fields.label" source="label" />
					<ExtendedTextField label="fields.companyid" source="companyid" companyid />
					<ExtendedTextField label="fields.contactid" source="contactid" contactid />
					<ExtendedTextField label="fields.serviceid" source="serviceid" serviceid />
					<ExtendedTextField label="fields.modeid" source="modeid" domainid={9} />
					<MyDateField showTime={true} label="fields.startdate" source="startdate" />
					<MyDateField showTime={true} label="fields.enddate" source="enddate" />
					<CheckboxField label="fields.renewed" source="renewed" />
					<CheckboxField label="fields.exclusive" source="exclusive" />
					<RichTextField label="fields.comments" source="comments" />
					<ExtendedTextField label="fields.vatid" source="vatid" domainid={25} />
					<ExtendedTextField label="fields.currencyid" source="currencyid" domainid={3} />
					<TextField label="fields.commissionfee" source="commissionfee" />
				</SimpleShowLayout>
			</CardChild>
		</Card>
		<div className={classes.cardDiv}>
			<Card className={classes.cardPaddingMargin}>
				<CardContent>
					<Typography variant="title">Gestion</Typography>
				</CardContent>
				<CardChild>
					<SimpleShowLayout record={record}>
						<MyDateField showTime={true} label="fields.createdon" source="createdon" />
						<ContactField label="fields.createdby" source="createdby" />
						<MyDateField showTime={true} label="fields.modifiedon" source="modifiedon" />
						<ContactField label="fields.modifiedby" source="modifiedby" />
					</SimpleShowLayout>
				</CardChild>
			</Card>
		</div>
	</div>
))

export default CardShow
