export const getTextFromProps = (label, recordSource, attributes = false) => {
	const parser = new DOMParser()
	const xmlDoc = parser.parseFromString(recordSource,'text/xml')
	const x = xmlDoc.getElementsByTagName('DETAIL')
	if (label === 'PRESSREVIEWS' && attributes === true) {
		if (x.length > 0) {
			const value = Object.values(x[0].children).find((name) => name.nodeName === label)
			if (value !== undefined) {
				return recordSource = value.attributes[0].nodeValue
			} else {
				return recordSource = null
			}
		}
	} else {
		if (x.length > 0) {
			const value = Object.values(x[0].children).find((name) => name.nodeName === label)
			if (value !== undefined) {
				return recordSource = value.innerHTML
			} else {
				return recordSource = null
			}
		}
	}
}

export const switchLabelProps = (label, recordSource) => {
	const parser = new DOMParser()
	const xmlDoc = parser.parseFromString(recordSource,'text/xml')
	const y = xmlDoc.getElementsByTagName('PROP')
	if (y.length > 0) {
		const value = Object.values(y).find((item) => item.attributes[0].nodeValue === label)
		if (value !== undefined) {
			return recordSource = value.attributes[1].nodeValue
		} else {
			return recordSource = null
		}
	}
}

export const getAttrValueFromProps = (attribute, recordSource) => {
	const parser = new DOMParser()
	const xmlDoc = parser.parseFromString(recordSource,'text/xml')
	const items = xmlDoc.getElementsByTagName('PROP')
	if (items.length > 0) {
		const value = Object.values(items).find(item => item.attributes[0].nodeValue === attribute)
		if (value !== undefined) {
			return recordSource = value.attributes[1].nodeValue
		} else {
			return recordSource = null
		}
	}
}

export const removeTags = input =>
	input ? input.replace(/<[^>]+>/gm, '') : ''


export	const ObjecttoXML=(obj)=> {
		var xml = '';
		for (var prop in obj) {
		  xml += obj[prop] instanceof Array ? '' : "<" + prop + ">"
		  if (obj[prop] instanceof Array) {
			for (var array in obj[prop]) {
			  xml += "<" + prop + ">"
			  xml += ObjecttoXML(new Object(obj[prop][array]))
			  xml += "</" + prop + ">"
			}
		  } else if (typeof obj[prop] == "object") {
			xml += ObjecttoXML(new Object(obj[prop]))
		  } else {
			xml += obj[prop]
		  }
		  xml += obj[prop] instanceof Array ? '' : "</" + prop + ">"
		}
		xml = xml.replace(/<\/?[0-9]{1,}>/g, '')
		return xml
	}