/* eslint-disable no-use-before-define */
import React from 'react'
import { useTheme, alpha, makeStyles } from '@material-ui/core/styles'
import Popper from '@material-ui/core/Popper'
import CloseIcon from '@material-ui/icons/Close'
import ButtonBase from '@material-ui/core/ButtonBase'
import FilterListIcon from '@material-ui/icons/FilterList'
import { RadioGroup, Typography, Grid } from '@material-ui/core'
import { CustomRadio, CustomTextField } from '../../layout/GlobalStyles'
import { Button } from '@material-ui/core'
 
const useStyles = makeStyles((theme) => ({
	root: {
		//width: 51,
		fontSize: 13,
	},
 
	tag: {
		marginTop: 3,
		height: 20,
		padding: '.15em 4px',
		fontWeight: 600,
		lineHeight: '15px',
		borderRadius: 2,
	},
	popper: {
		border: '1px solid rgba(27,31,35,.15)',
		boxShadow: '0 3px 12px rgba(27,31,35,.15)',
		borderRadius: 3,
		width: 350,
		zIndex: 1,
		fontSize: 13,
		color: '#586069',
		backgroundColor: '#f6f8fa',
	},
	header: {
		borderBottom: '1px solid #e1e4e8',
		padding: '8px 10px',
		fontWeight: 600,
	},
	inputBase: {
		padding: 10,
		width: '100%',
		borderBottom: '1px solid #dfe2e5',
		'& input': {
			borderRadius: 4,
			backgroundColor: theme.palette.common.white,
			padding: 8,
			transition: theme.transitions.create(['border-color', 'box-shadow']),
			border: '1px solid #ced4da',
			fontSize: 14,
			'&:focus': {
				boxShadow: `${alpha(theme.palette.primary.main, 0.25)} 0 0 0 0.2rem`,
				borderColor: theme.palette.primary.main,
			},
		},
	},
	paper: {
		boxShadow: 'none',
		margin: 0,
		color: '#586069',
		fontSize: 13,
	},
	option: {
		minHeight: 'auto',
		alignItems: 'flex-start',
		padding: 8,
		'&[aria-selected="true"]': {
			backgroundColor: 'transparent',
		},
		'&[data-focus="true"]': {
			backgroundColor: theme.palette.action.hover,
		},
	},
	popperDisablePortal: {
		position: 'relative',
	},
	iconSelected: {
		width: 17,
		height: 17,
		marginRight: 5,
		marginLeft: -2,
	},
	color: {
		width: 14,
		height: 14,
		flexShrink: 0,
		borderRadius: 3,
		marginRight: 8,
		marginTop: 2,
	},
	text: {
		flexGrow: 1,
	},
	close: {
		opacity: 0.6,
		width: 18,
		height: 18,
	},
	closeIcon: {
		width: '15px',
		position: 'absolute',
		top: '0px',
		right: '0px',
		borderRadius: '4px',
		//color: 'black',
		// '&:hover': {
		// 	background: 'red'
		// }
	},
}))

export default function CustomFilter(props) {
	const { filter,OnUpdateFilterProgrammes } = props
	const classes = useStyles()
	const [anchorEl, setAnchorEl] = React.useState(null)
	const [rankValue, setRankValue] = React.useState('')
	const [checkRank, setCheckedRank] = React.useState('')
	const [dateValue, setDateValue] = React.useState('')
	const [checkDate, setCheckedDate] = React.useState('')
 	//const theme = useTheme()
	console.log(filter)
	const resetform =()=>{
		setRankValue('')
		setCheckedRank('')
		setDateValue('')
		setCheckedDate('')
		let newfilter = {
			...filter,
			rank : '',
			rankorder : '',
			date: '',
			dateorder: ''
		}
		OnUpdateFilterProgrammes(newfilter)


	}
	const handleRankChange = (event) => {
		setCheckedRank(event.target.value)
	  }
	const handleDateChange = (event) => {
		setCheckedDate(event.target.value)
	  }
	const handleClick = (event) => {
 		setAnchorEl(event.currentTarget)
		//  filter.rank = rankValue
		//  filter.rankorder = checkRank
		//  filter.date = dateValue
		//  filter.dateorder = checkDate
	}
	const onSetFilter = (event) => {
 		let newfilter = {
			...filter,
			rank : rankValue,
			rankorder : checkRank,
			date: dateValue,
			dateorder: checkDate
		}
		OnUpdateFilterProgrammes(newfilter)
		console.log(newfilter)
		// filter.rank = rankValue
		// filter.rankorder = checkRank
		// filter.date = dateValue
		// filter.dateorder = checkDate
	}
	const handleClose = (event, reason) => {
		resetform()
		if (reason === 'toggleInput') {
			return
		}
		//setValue(pendingValue)
		if (anchorEl) {
			anchorEl.focus()
		}
		setAnchorEl(null)
	}

	  const openfilter = Boolean(anchorEl)
 
	return (
		<React.Fragment>
			<div className={classes.root}>
				<ButtonBase
					disableRipple
					className={classes.button}
 					onClick={handleClick}
				>
					{/* <span>Labels</span> */}
					<FilterListIcon />
				</ButtonBase>
				{/* {value.map((label) => (
          <div
            key={label.name}
            className={classes.tag}
            style={{
              backgroundColor: label.color,
              color: theme.palette.getContrastText(label.color),
            }}
          >
            {label.name}
          </div>
        ))} */}
			</div>
			<Popper
				//id={id}
				open={openfilter}
				anchorEl={anchorEl}
				placement="bottom-start"
				className={classes.popper}
			>
				<Grid container style={{ margin: '8px' }}>


					<Grid item xs={12}>
						<Typography>Rang</Typography>
						<Button onClick={handleClose } className={classes.closeIcon} >
								<CloseIcon />
						</Button>
					</Grid>

					<Grid item xs={5}>
					<RadioGroup aria-label="Rank" value={checkRank} onChange={handleRankChange} style={{display:'block'}}>

						<CustomRadio  value="inf"/>Inf.
						<CustomRadio  value="sup"/>Sup.
					</RadioGroup>	
					</Grid>
					<Grid item xs={5}>
						<CustomTextField type='number' value={rankValue} onChange={e=>setRankValue(e.target.value)} readOnly={checkRank ===''}/>
					</Grid>


					<Grid item xs={12}>
						<Typography>Date d'ajout d'album</Typography>
					</Grid>

						<Grid item xs={5}>
						<RadioGroup aria-label="Date" value={checkDate} onChange={handleDateChange} style={{display:'block'}}>

							<CustomRadio value="inf"/>Inf.
							<CustomRadio value="sup"/>Sup.
						</RadioGroup>	

						</Grid>
						<Grid item xs={5}>
							<CustomTextField type='date' style={{margin: 0}}  value={dateValue} onChange={e=>setDateValue(e.target.value)} readOnly={checkDate ===''}/>
						</Grid>

					
				</Grid>
				<div style={{marginLeft:'135px'}}>
				<Button onClick={onSetFilter}>Filtrer</Button>
				<Button onClick={resetform}>REINITIALISER</Button>
				</div>

				{/* <Autocomplete
          open
          onClose={handleClose}
          multiple
          classes={{
            paper: classes.paper,
            option: classes.option,
            popperDisablePortal: classes.popperDisablePortal,
          }}
          value={pendingValue}
          onChange={(event, newValue) => {
            setPendingValue(newValue)
          }}
          disableCloseOnSelect
          disablePortal
          renderTags={() => null}
          noOptionsText="No labels"
          renderOption={(option, { selected }) => (
            <React.Fragment>
              <DoneIcon
                className={classes.iconSelected}
                style={{ visibility: selected ? 'visible' : 'hidden' }}
              />
              <span className={classes.color} style={{ backgroundColor: option.color }} />
              <div className={classes.text}>
                {option.name}
                <br />
                {option.description}
              </div>
              <CloseIcon
                className={classes.close}
                style={{ visibility: selected ? 'visible' : 'hidden' }}
              />
            </React.Fragment>
          )}
          options={[...labels].sort((a, b) => {
            // Display the selected labels first.
            let ai = value.indexOf(a)
            ai = ai === -1 ? value.length + labels.indexOf(a) : ai
            let bi = value.indexOf(b)
            bi = bi === -1 ? value.length + labels.indexOf(b) : bi
            return ai - bi
          })}
          getOptionLabel={(option) => option.name}
          renderInput={(params) => (
            <InputBase
              ref={params.InputProps.ref}
              inputProps={params.inputProps}
              autoFocus
              className={classes.inputBase}
            />
          )}
        /> */}
			</Popper>
		</React.Fragment>
	)
}


