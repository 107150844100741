import React, { Component } from 'react'
import PropTypes from 'prop-types'
import Checkbox from '@material-ui/core/Checkbox'
import compose from 'recompose/compose'
import { addField } from 'ra-core'
import { withStyles } from '@material-ui/core/styles'

const styles = theme =>   ({
	root: {},
	checkbox: {
		justifyContent: 'flex-start'
	}
})

class CheckboxInput extends Component {
	handleCheck = (event, isChecked) => {
		const { input: { onChange } } = this.props

		if (isChecked)
			onChange(1)
		else
			onChange(0)
	}

	render() {
		const { classes = {}, input: { value }, meta } = this.props

		if (typeof meta === 'undefined') {
			throw new Error('The CheckboxInput component wasn\'t called within a redux-form <Field>. Did you decorate it and forget to add the addField prop to your component? See https://marmelab.com/react-admin/Inputs.html#writing-your-own-input-component for details.')
		}

		return (
			<Checkbox color="primary"
				checked={value ? value !== undefined : false}
				onChange={this.handleCheck}
				value={String(value)}
				className={classes.checkbox}
			/>
		)
	}
}

CheckboxInput.defaultProps = {
	fullWidth: true,
	addLabel: true
}

CheckboxInput.propTypes = {
	classes: PropTypes.object.isRequired,
	meta: PropTypes.object,
	input: PropTypes.shape({
		onChange: PropTypes.func.isRequired
	})
}

export default compose(
	addField,
	withStyles(styles)
)(CheckboxInput)
