import React from 'react'
import { List, Filter, SelectInput, TextInput, CardActions, ExportButton } from 'react-admin'
import WatcherIngest from './WatcherIngest'
import { BackButton } from '../common/buttons'

const types = [
	{ id: 1, name: 'labels.ingestCablelabsAntenne' },
	{ id: 2, name: 'labels.ingestPicturesAntenne' },
]

const pagination = [
	{ id: 10, name: '10' },
	{ id: 25, name: '25' },
	{ id: 50, name: '50' },
	{ id: 100, name: '100' },
	{ id: 250, name: '250' },
	{ id: 500, name: '500' },
	{ id: 1000, name: '1000' },
	{ id: -1, name: 'labels.all' },
]

// eslint-disable-next-line react/prop-types
const ActionsList = ({ basePath, currentSort, exporter, filterValues, resource, }) => (
	<CardActions>
		<BackButton basePath={basePath} />
		<ExportButton
			resource={resource}
			sort={currentSort}
			filter={filterValues}
			exporter={exporter}
		/>
	</CardActions>
)

const WatcherFilter = (props) => (
	<Filter {...props}>
		<SelectInput label="fields.type" source="typeid" choices={types} alwaysOn allowEmpty={false} />
		<SelectInput label="fields.maxitems" source="perPage" choices={pagination} alwaysOn allowEmpty={false} options={{ style: { width: '250px' } }} />
		<TextInput label="labels.filterByFilename" source="q" alwaysOn allowEmpty={false} resettable clearAlwaysVisible />
	</Filter>
)

const WatcherList = (props) => (
	<List title="titles.ingestionStatus" filters={<WatcherFilter />} filterDefaultValues={{ typeid: 1, perPage: 10, term: null }} actions={<ActionsList />} pagination={null} perPage={-1} {...props} >
		<WatcherIngest />
	</List>
)

export default WatcherList
