import React from 'react'
import { CardHeader } from '@material-ui/core'
import cyan from '@material-ui/core/colors/cyan'
import { withStyles } from '@material-ui/core/styles'
// cardHeaderTitle and cardHeader classes should be defined in the parent component
const styles = theme =>   (
	{
		cardHeader:{
			background: cyan['A700'],
			border: 0,
			borderRadius: 3,
			// boxShadow: '0 3px 5px 2px rgba(33, 203, 243, .3)',
			color: 'white',
			padding: '0 30px',
			textAlign: 'center'
		},
		cardHeaderTitle:{
			color: 'white',
			fontSize: '1rem'
		},
	}
)
const CustomCardHeader = props => {
	const { classes, title } = props
	return(
		<CardHeader classes={{ title: classes.cardHeaderTitle, root: classes.cardHeader}} title={title}/>
	)
}

export default withStyles(styles)(CustomCardHeader)