import React from 'react'
import { SimpleShowLayout, SimpleForm, Show, Edit, Create } from 'react-admin'
import { MyShowActions, MyEditActions, MyCreateActions } from '../../common/actions'
import { EditorialsShow, EditorialsEdit, EditorialsCreate } from '.'

const PeopleseditorialsShow = (props) => (
	<Show title="titles.peoplesEditorialsCard" actions={<MyShowActions />} {...props}>
		<SimpleShowLayout>
			<EditorialsShow />
		</SimpleShowLayout>
	</Show>
)

const PeopleseditorialsEdit = (props) => (
	<Edit title="titles.peoplesEditorialsCard" undoable={false} actions={<MyEditActions />} {...props}>
		<SimpleForm>
			<EditorialsEdit />
		</SimpleForm>
	</Edit>
)

const PeopleseditorialsCreate = (props) => (
	<Create title="titles.peoplesEditorialsCard" undoable={false} actions={<MyCreateActions />} {...props}>
		<SimpleForm>
			<EditorialsCreate />
		</SimpleForm>
	</Create>
)

export { PeopleseditorialsShow, PeopleseditorialsEdit, PeopleseditorialsCreate }