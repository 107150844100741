import React from 'react'
import Dialog from '@material-ui/core/Dialog'
import Slide from '@material-ui/core/Slide'
import PropTypes from 'prop-types'
import cyan from '@material-ui/core/colors/cyan'
import LinearProgress from '@material-ui/core/LinearProgress'
import Button from '@material-ui/core/Button'
import { CancelIcon } from '../../../constants/icons'
import { withStyles,  } from '@material-ui/core/styles'

const Transition = React.forwardRef(function Transition(props, ref) {
	return <Slide direction="up" ref={ref} {...props} />
})

const styles = theme =>  ({
	paperWidthSm : {
		maxWidth: '600px',
		width: '400px'
	},
	button: {
		margin: theme.spacing(1),
		width : '150px'
	},
	buttonCancel: {
		margin: theme.spacing(1),
		width : '150px',
		background: '#e23434'
	},
	buttonCheck: {
		margin: theme.spacing(1),
		width : '150px',
		background: '#0c9e0c'
	},
	leftIcon: {
		marginRight: theme.spacing(1),
	},
	dialogHeader:{
		padding: '10px 10px 10px',
		background: cyan[500],
		color: 'white',
		fontSize: 'medium',
		fontWeight: 'bold',
		textAlign: 'center',
		//height: '36px'
	},
	dialogContent: {
		padding: '16px 24px 17px',
		flex: '1 1 auto',
		//color: 'white',
		fontSize: '1rem',
		fontWeight: '400',
		textAlign : 'center'
	},
	dialogFooter: {
		flex: '0 0 auto',
		margin: '8px 4px',
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'flex-end'
	},
	margin: {
		margin: theme.spacing(1),
	},
})

const CustomLinearProgress = props => {
	const { isOperationFinished, operationFinishedText, isOperationFailed,isPrio } = props
	if(isPrio){
		if(!isOperationFinished){
			return (
				<React.Fragment>
					<br/>
					<LinearProgress color="secondary" /> 
				</React.Fragment>
			)
		}
		else{
		 
				return (
					<React.Fragment>
						<div>Contenus référents modifiés avec succès</div>
						<br/>
					</React.Fragment>
				)
			 
		}
	}
	else{
			if(!isOperationFinished){
		return (
			<React.Fragment>
				<br/>
				<LinearProgress color="secondary" /> 
			</React.Fragment>
		)
	}
	else{
		if(isOperationFailed){
			return(
				<React.Fragment>
					Erreur durant la synchronisation du planning.
					Veuillez s'assurer que le fichier à été importé
				</React.Fragment>
			)
		}
		else{
			return (
				<React.Fragment>
					<div>Lignes parcourues : {operationFinishedText.rowCount}</div>
					<br/>
					<div>Nouvelles lignes : {operationFinishedText.newRow}</div>
					<br/>
					<div>Lignes modifiées : {operationFinishedText.updatedRow}</div>
					<br/>
					<div>Lignes supprimées : {operationFinishedText.deletedRow}</div>
					<br/>
					<div>Erreurs : {operationFinishedText.errorCount}</div>
					<br/>
				</React.Fragment>
			)
		}
	}
	}

}

function ImportProcessLoader(props) {
	const [open, setOpen] = React.useState(false)
	const { modalOpen, onClose, classes, progressModalContent, isOperationFinished, isOperationFailed  } = props
	const { title, closeButtonText, operationFinished ,isPrio} = progressModalContent
	
	function setOpenModalFromProps(modalOpen){
		setOpen(modalOpen)
	}

	React.useEffect(() => {
		setOpenModalFromProps(modalOpen)
	})

	return (
		<div>
			<Dialog
				open={open}
				TransitionComponent={Transition}
				keepMounted
				onClose={onClose}
				disableEscapeKeyDown={true}
				disableBackdropClick={true}
				aria-labelledby="alert-dialog-slide-title"
				aria-describedby="alert-dialog-slide-description"
				classes = {{
					paperWidthSm : classes.paperWidthSm
				}}
			>
				<div className={classes.dialogHeader}>
					{title}
				</div>
				<div className={classes.dialogContent}>
					<CustomLinearProgress isVisible = {true} operationFinishedText = {operationFinished} isOperationFinished = {isOperationFinished} isOperationFailed = {isOperationFailed} isPrio={isPrio}/>
				</div>
				<div className={classes.dialogFooter}>
					<Button onClick={onClose} color="secondary" variant="contained" className={classes.buttonCancel} disabled = {!isOperationFinished}>
						<CancelIcon className={classes.leftIcon} />
						{closeButtonText}
					</Button>
				</div>
			</Dialog>
		</div>
	)
}

ImportProcessLoader.propTypes = {
	modalOpen: PropTypes.bool,
	onClose: PropTypes.func,
	classes: PropTypes.object.isRequired,

}

export default withStyles(styles)(ImportProcessLoader)