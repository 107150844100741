import React, { Component } from 'react'
import { SimpleShowLayout, SelectInput, TextInput, ImageInput, REDUX_FORM_NAME } from 'react-admin'
import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import Typography from '@material-ui/core/Typography'
import { ImageField } from '../../common/fields'
import { DomainsItems } from '../../../tools'
import { ExtendedInput } from '../../common/inputs'
import { change } from 'redux-form'
import { connect } from 'react-redux'
import compose from 'recompose/compose'
import { withStyles } from '@material-ui/core/styles'
import PropTypes from 'prop-types'

const styles = theme =>   ({
	cardPaddingMargin: {
		padding: 20,
		margin: 20,
		flex: '1 auto',
	},
	cardDiv: {
		flex: '1 auto',
	},
	flexContent: {
		display: 'flex',
		alignItems: 'flex-start',
	},
	hidden: {
		display: 'none'
	}
})

class PicturesEdit extends Component {
	state = {
		filename: null
	}
	handleUploadImage = (event) => {
		console.log('nandalo')
		const { change } = this.props
		this.setState({ filename: event.rawFile.preview })
		change(REDUX_FORM_NAME, 'filename', event.rawFile.name)
	}
	render() {
		const { classes, record, basePath } = this.props

		let previewPicture

		if (this.state.filename !== null) {
			previewPicture = <img src={this.state.filename} alt="Preview" />
		} else {
			previewPicture = (
				<SimpleShowLayout record={record} basePath={basePath}>
					<ImageField source="filename" />
				</SimpleShowLayout>
			)
		}

		return (
			<div className={classes.flexContent}>
				<div className={classes.cardDiv}>
					<Card className={classes.cardPaddingMargin}>
						<CardContent>
							<Typography variant="title">Visuel</Typography>
						</CardContent>
						<SimpleShowLayout>
							<TextInput label="fields.id" source="id" disabled />
							<SelectInput label="fields.countryid" source="countryid" choices={DomainsItems.getSelectList(2, true)} allowEmpty={false} />
							<SelectInput label="fields.typeid" source="typeid" choices={DomainsItems.getSelectList(8)} allowEmpty={false} />
							<ImageInput label="fields.image" source="image" accept="image/*" onChange={this.handleUploadImage.bind(this)} classes={{ removeButton: classes.hidden }}>
								<ImageField source="src" title="title" upload={true} hidden={true} />
							</ImageInput>
							<TextInput label="fields.filename" source="filename" disabled/>
							<TextInput label="fields.title" source="title" />
							<TextInput label="fields.width" source="width" disabled/>
							<TextInput label="fields.height" source="height" disabled/>
						</SimpleShowLayout>
					</Card>
					<Card className={classes.cardPaddingMargin}>
						<CardContent>
							<Typography variant="title">Gestion</Typography>
						</CardContent>
						<SimpleShowLayout>
							<ExtendedInput label="fields.createdon" source="createdon" date disabled />
							<ExtendedInput label="fields.createdby" source="createdby" contact disabled />
							<ExtendedInput label="fields.modifiedon" source="modifiedon" date disabled />
							<ExtendedInput label="fields.modifiedby" source="modifiedby" contact disabled />
						</SimpleShowLayout>
					</Card>
				</div>
				<Card className={classes.cardPaddingMargin}>
					<CardContent>
						<Typography variant="title">Visualisation</Typography>
					</CardContent>
					{previewPicture}
				</Card>
			</div>
		)
	}
}

const mapDispatchToProps = {
	change
}
PicturesEdit.propTypes = {
	classes: PropTypes.object.isRequired,
}
const enhance = compose(
	connect(null, mapDispatchToProps),
	withStyles(styles)
)

export default enhance(PicturesEdit)
