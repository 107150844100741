import React, { Component } from 'react'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableRow from '@material-ui/core/TableRow'
import TableHead from '@material-ui/core/TableHead'
import classnames from 'classnames'
import {CloseIcon } from '../../../constants/icons'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import Paper from '@material-ui/core/Paper'
import cyan from '@material-ui/core/colors/cyan'
import { withStyles } from '@material-ui/core/styles'

const styles = theme =>  ({

	tabCell: {
		textAlign: 'center',
		padding : '8px',
		maxHeight:'25px'
	},
	responsibleCell:{
		textAlign: 'left',
		padding :'3px',
		fontSize: '0.855rem',
		maxWidth :'80px',
	},
	dialogHeader:{
		//padding: '1px',
		background: cyan[500],
		color: 'white',
		fontSize: 'medium',
		fontWeight: 'bold',
		textAlign: 'right',
		//height: '36px'
	},
	closeIcon:{
		backgroundColor: '#43434300',
		maxHeight: '30px',
		maxWidth: '30px',
		color: 'white',
		borderRadius: '0',
		'&:hover': {
			background: 'red'
		}
	},
	button : { 
		 
		minWidth: '30px',
		minHeight: '30px',
	
	},
	dialogGlobal: {
		backgroundColor: '#f5f5f9',
		color: 'white',
		maxWidth: '1200px',
		fontSize: theme.typography.pxToRem(10),
	},
	paper :{
		maxWidth : '1200px',
		width : '800px',	
	},
	dialog :{
		maxWidth : '1200px',
		position: 'absolute',
		// top: '-60%',
		// left: '53%'
   },
})

class StatsTooltipTitle extends Component{
	constructor(props) {
		super(props)
	}
	
	render(){
		const { stats ,classes,onClose , modalOpen} = this.props
		return (
			<div>
				<Dialog
					open={modalOpen}
					keepMounted
					onClose={onClose}
					disableEscapeKeyDown = {false}
					disableBackdropClick = {false}
					//className={classes.dialog}	 
					//style={ {left : Number.parseInt(targetAttr.x - targetAttr.width - 600) + "px"}}  
				>
				<div className={classes.dialogHeader}> 
				<Button onClick={onClose}  className={classes.closeIcon}  >
					<CloseIcon/>				 
				</Button>
			</div>
			<div className = {classes.dialogGlobal}>
				<div className={classes.dialogContent}>	
					<Paper className={classes.paper}>
				{/* <div className={classnames.container}> */}
					<Table  className={classnames.table}  >
					<TableHead>
					<TableRow>
						<TableCell className={classes.responsibleCell}>Responsable</TableCell>
						<TableCell className={classes.tabCell}>Total</TableCell>
						<TableCell className={classes.tabCell}>Pourcentage</TableCell>
						<TableCell className={classes.tabCell}>Nouveau <br/> programme</TableCell>
						<TableCell className={classes.tabCell}>Ouverture <br/> LOC/EST</TableCell>
						<TableCell className={classes.tabCell}>Suspension<br/> /Cloture</TableCell>
						<TableCell className={classes.tabCell}>Mise à jour<br/> prix</TableCell>
						<TableCell className={classes.tabCell}>Autre</TableCell>
					</TableRow>
					</TableHead>
					<TableBody>
					{stats.map((row, index) => (
						<TableRow key={row.contactName}>
						<TableCell className={classes.responsibleCell} component="th" scope="row">
							{row.contactName}
						</TableCell>
						<TableCell className={classes.tabCell}>{row.publicationsCount}</TableCell>
						<TableCell className={classes.tabCell}>{row.publicationPercent}%</TableCell>
						<TableCell className={classes.tabCell}>{row.pgm}</TableCell>
						<TableCell className={classes.tabCell}>{row.ouv}</TableCell>
						<TableCell className={classes.tabCell}>{row.susp}</TableCell>
						<TableCell className={classes.tabCell}>{row.chgtprix}</TableCell>
						<TableCell className={classes.tabCell}>{row.autre}</TableCell>
						</TableRow>
					))}
						<TableRow  >
						<TableCell className={classes.responsibleCell}>Total</TableCell>
						<TableCell className={classes.tabCell}>{stats.reduce((res, row) => res + row.publicationsCount, 0)}</TableCell>
						<TableCell className={classes.tabCell}>{Number( stats.reduce((res, row) => res + row.publicationPercent, 0)).toFixed(1)}%</TableCell>
						<TableCell className={classes.tabCell}>{stats.reduce((res, row) => res + row.pgm, 0)}</TableCell>
						<TableCell className={classes.tabCell}>{stats.reduce((res, row) => res + row.ouv, 0)}</TableCell>
						<TableCell className={classes.tabCell}>{stats.reduce((res, row) => res + row.susp, 0)}</TableCell>
						<TableCell className={classes.tabCell}>{stats.reduce((res, row) => res + row.chgtprix, 0)}</TableCell>
						<TableCell className={classes.tabCell}>{stats.reduce((res, row) => res + row.autre, 0)}</TableCell>
						</TableRow>
										 
					</TableBody>
				</Table>
				</Paper>
				</div>
			</div>
				</Dialog>
			</div>
		)

	}
}

export default withStyles(styles)(StatsTooltipTitle)