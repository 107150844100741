/* eslint-disable react/jsx-key */
import React, { Component, useMemo } from 'react'
import Dropzone from 'react-dropzone'
import { connect } from 'react-redux'
import { SimpleForm, fetchEnd, fetchStart, showNotification, translate } from 'react-admin'
import { change, submit, isSubmitting } from 'redux-form'
import { stringify } from 'query-string'
import { post } from 'axios'
import { Token, Logger } from '../../tools'
import { CloudUploadIcon } from '../../constants/icons'
import { ExpandMore } from '@material-ui/icons/';
import { Accordion, AccordionSummary, AccordionDetails } from '@material-ui/core';
import compose from 'recompose/compose'
import Button from '@material-ui/core/Button'
import green from '@material-ui/core/colors/green'
import red from '@material-ui/core/colors/red'
import grey from '@material-ui/core/colors/grey'
import Typography from '@material-ui/core/Typography'
import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import FileInformationContainer from './dropzone/FileInformationContainer'
import { ProcessLoader } from '../common'
import { withStyles } from '@material-ui/core/styles'

import { CustomSelect } from '../layout/GlobalStyles';
import { resetForm } from 'ra-core'

const styles = theme => ({
	button: {
		marginTop: theme.spacing(1),
		marginBottom: theme.spacing(1),
		width : '150px'
	},
	buttonCancel: {
		margin: theme.spacing(1),
		width : '150px',
		background: '#e23434'
	},
	buttonCheck: {
		margin: theme.spacing(1),
		width : '150px',
		background: '#0c9e0c'
	},
	leftIcon: {
		marginRight: theme.spacing(1),
	},
	rightIcon: {
		marginLeft: theme.spacing(1),
	},
	fileAccepted: {
		backgroundColor : green[500],
		marginTop: '5px',
		textAlign : 'center'
	},
	fileRejected: {
		backgroundColor : red[400],
		marginTop: '5px',
		textAlign : 'center'
	},
	fileInformationSection : {
		width : '100%'
	},
	noJsValidation:{
		backgroundColor : 'grey[200]',
		marginTop: '5px',
		textAlign : 'center'
	},
	title: {
		display:'inline-block',
		color: 'white',
		fontWeight: 'bold',
		fontSize: '13px',
		fontFamily: 'Roboto, Helvetica, Arial, sans-serif',
		lineHeight: 1,
	},
	baseStyle: {
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
		padding: '20px',
		borderRadius: '7px',
		backgroundColor: '#fafafa',
		color: 'grey[900]',
		// zIndex: 1,
		// '&:hover':{
		// 	backgroundColor:'blue',
		// 	cursor: 'pointer'
		// },
	},
	customText:{
		display:'inline-block'
	},
	FAI: {
		display:'inline-block',
		marginTop:'10px',
		marginLeft:'20px',
	},
	label:{
		marginTop:'10px',
		marginLeft:'20px',
	}
})

const baseStyle = {
	display: 'flex',
	flexDirection: 'column',
	alignItems: 'center',
	justifyContent: 'center',
	maxWidth:'600px',
	height:'40px',
	maxHeight:'80px',
	padding: '20px',
	borderRadius: '7px',
	// backgroundColor: '#00BCD4',
	backgroundColor: '#b3b3b3',
	color: 'grey[900]',
	// zIndex: 1000,
	// '&:hover':{
	// 	backgroundColor:'blue',
	// 	cursor: 'pointer'
	// },
	outline: 'none',
	transition: 'border .24s ease-in-out'
}

const activeStyle = {
	backgroundColor: '#2196f3'
}

const acceptStyle = {
	backgroundColor: green[200]
}

const rejectStyle = {
	backgroundColor: red[400]
}

const AddButton = withStyles((theme) => ({
	root: {
	  color: 'white',
	  backgroundColor: green[600],
	  '&:hover': {
		backgroundColor: green[700],
	  },
	},
  }))(Button);

const REDUX_FORM_UPLOAD_TOOLS_MASSIMAGE = 'REDUX_FORM_UPLOAD_TOOLS_MASSIMAGE'
const REDUX_FORM_UPLOAD_TOOLS_ORANGEORDER = 'REDUX_FORM_UPLOAD_TOOLS_ORANGEORDER'
const REDUX_FORM_UPLOAD_TOOLS_SFRORDER = 'REDUX_FORM_UPLOAD_TOOLS_SFRORDER'
const REDUX_FORM_UPLOAD_TOOLS_SFRHEBDO = 'REDUX_FORM_UPLOAD_TOOLS_SFRHEBDO'
const REDUX_FORM_UPLOAD_TOOLS_ORANGEIDS = 'REDUX_FORM_UPLOAD_TOOLS_ORANGEIDS'

const maxSize = 20971520

class MyDropzoneArea extends Component{
	constructor(props){
		super(props)
		this.state = {
			acceptedFiles: [],
			rejectedFiles: [],
			style : baseStyle,
			isButtonSaveActivated : false,
			isProcessLoaderOpen : false,
			isOperationFinished : false,
			expandedImages : false,
			expandedCommands : false,
			expandedIdOrange: false,
			type: '',
			FAI: '1',
			FAIerror: false,
		}
	}
	
	handleChange = (event) => {
		this.setState({FAI:event.target.value})
		this.setState({FAIerror:false})
		console.log(this.state.acceptedFiles)
		if(this.state.acceptedFiles.length != 0){
			this.setState({isButtonSaveActivated:true})
		}
	}

	handleOnDrop = (acceptedFiles, rejectedFiles) => {
		//Debug format issue
		Logger.info('accepted files')
		Logger.info(acceptedFiles)
		Logger.info('rejected files')
		Logger.info(rejectedFiles)
		const isJsValidationEnabled = parseInt(process.env.REACT_APP_ACTIVATE_DROPZONE_JSVALIDATION, 10) === 1 ? true : false
		if(isJsValidationEnabled){
			if(acceptedFiles && acceptedFiles.length !== 0){
				this.setState({
					file : acceptedFiles[0],
					isButtonSaveActivated : true,
					acceptedFiles : acceptedFiles,
					rejectedFiles : []
				})
			}
			else{
				this.setState({ 
					isButtonSaveActivated : false,
					rejectedFiles : rejectedFiles,
					acceptedFiles : [] 
				})
			}
		}else if(this.state.expandedCommands==true){
			if(this.state.FAI == '1'){
				this.setState({
					file : acceptedFiles[0],
					isButtonSaveActivated : false,
					acceptedFiles : acceptedFiles,
					rejectedFiles : [],
				})
			}else{
				this.setState({
					file : acceptedFiles[0],
					isButtonSaveActivated : true,
					acceptedFiles : acceptedFiles,
					rejectedFiles : [],
				})
			}
		}else {
			this.setState({
				file : acceptedFiles[0],
				isButtonSaveActivated : true,
				acceptedFiles : acceptedFiles,
				rejectedFiles : []
			})
		}
	}


	handleSaveClick = (type) => {
		const { submit } = this.props
		console.log(type)
		// unregisterField(REDUX_FORM_UPLOAD_TOOLS, 'typeid')
		// Trigger a submit of our custom quick create form
		// This is needed because our modal action buttons are oustide the form
		if(type == 'massImage'){
			submit(REDUX_FORM_UPLOAD_TOOLS_MASSIMAGE)
		}
		else if(type == "orangeOrder"){
			submit(REDUX_FORM_UPLOAD_TOOLS_ORANGEORDER)
		}else if(type == "sfrOrder"){
			submit(REDUX_FORM_UPLOAD_TOOLS_ORANGEORDER)
		}else if(type == "sfrHebdo"){
			submit(REDUX_FORM_UPLOAD_TOOLS_ORANGEORDER)}
		else if(type == "orangeIds"){
			submit(REDUX_FORM_UPLOAD_TOOLS_ORANGEIDS)
		}
		
	}

	handleCloseProgressBar = () => {
		this.setState({isProcessLoaderOpen: false})
	}

	handleSubmit = (type) => {
		const { showNotification } = this.props
		const isJsValidationEnabled = parseInt(process.env.REACT_APP_ACTIVATE_DROPZONE_JSVALIDATION, 10) === 1 ? true : false
		this.setState({
			type : type
		})
		var formData = new FormData()
		formData.append('files', this.state.file)
		let query = {
			type: type,
			fileName: this.state.file.name,
			contactId : parseInt(Token.getId(), 10),
			triggerBackendValidation : !isJsValidationEnabled
		}
		const url = `${process.env.REACT_APP_API}/Tools/Upload?${stringify(query)}`;
		console.log(url)
		const t = Token.getToken()
		const config = {
			headers: {
				'content-type': 'multipart/form-data',
				'Authorization': `Bearer ${t}`
			},
		}
		Logger.info(this.state.file.name)
		//check size first
		if(this.state.file.size > maxSize){
			showNotification(`La taille du fichier est de ${this.state.file.size} octets et est supérieur à la taille autorisée`, 'warning', { autoHideDuration : '6000' })
			this.setState({
				isButtonSaveActivated : false
			})
		}
		else{
			this.setState({ 
				isProcessLoaderOpen : true,
				isOperationFinished : false
			})
			post(url, formData, config).then((response) => {
				Logger.info(response)
				// showNotification('Le fichier a été transféré avec succès', 'info', { autoHideDuration : '2000' })
				this.setState({ 
					isButtonSaveActivated : false,
					acceptedFiles : [],
					rejectedFiles : [],
					isOperationFinished : true,
					// FAI: '1',
					file: [],
					//type: ''
				})
			}).catch(error => {
				const { response } = error
				Logger.info(response)
				this.setState({
					isButtonSaveActivated : false,
					isProcessLoaderOpen : false,
					isOperationFinished : true
				})
				console.log(response)
				if(response.status === 801){
					showNotification(`La taille du fichier est de ${this.state.file.size} octets et est supérieur à la taille autorisée`, 'warning', { autoHideDuration : '6000' })
				}
				else if(response.status === 802){
					showNotification('Le fichier n’est pas dans un format reconnu', 'warning', { autoHideDuration : '6000' })
				}
				else{
					showNotification('Erreur lors du transfert du fichier', 'warning', { autoHideDuration : '6000' })
				}
				// return Promise.reject(new Error(response.data.message))
			})
		}
	}
	handlePanel = (panel) => () => {
		this.setState({[panel] : !this.state[panel]})
		if(panel == 'expandedImages' && (this.state.expandedCommands  ||this.state.expandedIdOrange)){
			this.setState({
				expandedCommands: false,
				expandedIdOrange : false,
				isButtonSaveActivated : false,
				acceptedFiles : [],
				type : 'massImage'
			})
		}
		if(panel == 'expandedCommands' &&(this.state.expandedImages   ||this.state.expandedIdOrange)){
			this.setState({
				expandedImages: false,
				expandedIdOrange : false,
				isButtonSaveActivated : false,
				acceptedFiles : []
			})
		}
		if(panel == 'expandedIdOrange' && (this.state.expandedCommands || this.state.expandedImages ) ){
			this.setState({
				expandedCommands: false,
				expandedImages : false,
				isButtonSaveActivated : false,
				acceptedFiles : []
			})
		}
	  };

	render(){
		const { classes, translate }  = this.props
		const saveButtonLabel = 'Transférer'
		const isJsValidationEnabled = parseInt(process.env.REACT_APP_ACTIVATE_DROPZONE_JSVALIDATION, 10) === 1 ? true : false
		const progressModalContent = {
			title : this.state.type==='massImage'?translate('titles.uploadFileModalImage')
			:this.state.type==='orangeOrder'?translate('titles.uploadFileModalOrange')
			:(this.state.type==='sfrOrder' || this.state.type === 'sfrHebdo') ? translate('titles.uploadFileModalSFR') : translate('titles.uploadFileModalOrangeIds') ,
			closeButtonText : translate('ra.action.close'),
			operationFinished : translate('messages.uploadSuccess')
		}
		if(isJsValidationEnabled === true){
			return (
				<div>
					<Card>
						<CardContent>

							
						<Accordion square expanded={this.state.expanded} onChange={this.handlePanel('expandedImages')} style={{marginBottom: '10px'}}>
							<AccordionSummary
								style={{backgroundColor:"#288DB1"}}
								expandIcon={<ExpandMore style={{color:"white"}} />} >
									<Typography className={classes.title}>
										IMPORT D'IMAGES EN MASSE
									</Typography>
							</AccordionSummary>
							<AccordionDetails style={{width:'100%', padding:0, marginBottom:"20px"}}>
							<SimpleForm 
								onSubmit={() => {this.handleSubmit('massImage')}}
								form={REDUX_FORM_UPLOAD_TOOLS_MASSIMAGE}
								resource="Tools/Upload"
								// We want no toolbar at all as we have our modal actions
								toolbar={null}
							>
								<Dropzone 
									onDrop={this.handleOnDrop}
									accept="application/x-zip-compressed, application/x-7z-compressed, application/zip"
									minSize={0}
									maxSize={maxSize} //20Mb
									multiple = {false}
									onDragEnter = { this.handleOnDragEnter }
								>
									{({getRootProps, getInputProps, isDragActive, isDragReject, rejectedFiles, isDragAccept, acceptedFiles, draggedFiles }) => {
										// const isFileTooLarge = (rejectedFiles.length > 0 && rejectedFiles[0].size > maxSize) || (acceptedFiles.length > 0 && acceptedFiles[0].size > maxSize)
										const style = useMemo(() => ({
											...baseStyle,
											...(isDragActive ? activeStyle : {}),
											...(isDragAccept ? acceptStyle : {}),
											...(isDragReject ? rejectStyle : {}),
											// backgroundColor: "#00BCD4",
											// color: "#fff",
											borderRadius: "5px"
										}), [
											isDragActive,
											isDragReject
										])
										return (
											<React.Fragment>
												<div {...getRootProps({style})}>
													<input {...getInputProps()} />
													{!isDragActive && 'Glissez votre fichier* ici ou cliquez pour sélectionner le fichier à transférer'}
													{isDragActive && !isDragReject  && 'Fichier accepté!'}
													{isDragReject && 'Fichier rejeté!'}
												</div>
											</React.Fragment>
										)}
									}
								</Dropzone>
								<FileInformationContainer classes = {classes} acceptedFiles = { this.state.acceptedFiles } rejectedFiles = { this.state.rejectedFiles } isJsValidationEnabled = {isJsValidationEnabled}/>
								<Button onClick={() => {this.handleSaveClick('massImage')}} color='primary' variant="contained" className={classes.button} disabled = {!this.state.isButtonSaveActivated}>
									<CloudUploadIcon className={classes.leftIcon} />
									{saveButtonLabel}
								</Button>
								<Typography variant="caption" gutterBottom>
									*Le fichier doit être de type .zip avec une taille inférieure ou égale à 20 Mo
								</Typography>
							</SimpleForm>
							</AccordionDetails>
						</Accordion>


						<Accordion square expanded={this.state.expanded} onChange={this.handlePanel('expandedCommands')} style={{marginBottom: '10px'}}>
							<AccordionSummary
								style={{backgroundColor:"#288DB1"}}
								expandIcon={<ExpandMore style={{color:"white"}} />} >
									<Typography className={classes.title}>
										IMPORT DES COMMANDES ORANGE ET SFR
									</Typography>
							</AccordionSummary>
							<AccordionDetails style={{width:'100%', padding:0, marginBottom:"20px"}}>
							<SimpleForm 
								onSubmit={() => {
									if(this.state.FAI == '2'){this.handleSubmit('orangeOrder')}
									else if(this.state.FAI == '3'){this.handleSubmit('sfrOrder')}
									else if(this.state.FAI == '4'){this.handleSubmit('sfrHebdo')}
								}}
								form={this.state.FAI=='2'?REDUX_FORM_UPLOAD_TOOLS_ORANGEORDER: this.state.FAI == '3' ? REDUX_FORM_UPLOAD_TOOLS_SFRORDER : REDUX_FORM_UPLOAD_TOOLS_SFRHEBDO
							}
								resource="Tools/Upload"
								// We want no toolbar at all as we have our modal actions
								toolbar={null}
							>
								<Dropzone 
									onDrop={this.handleOnDrop}
									accept="application/x-zip-compressed, application/x-7z-compressed, application/zip"
									minSize={0}
									maxSize={maxSize} //20Mb
									multiple = {false}
									onDragEnter = { this.handleOnDragEnter }
								>
									{({getRootProps, getInputProps, isDragActive, isDragReject, rejectedFiles, isDragAccept, acceptedFiles, draggedFiles }) => {
										// const isFileTooLarge = (rejectedFiles.length > 0 && rejectedFiles[0].size > maxSize) || (acceptedFiles.length > 0 && acceptedFiles[0].size > maxSize)
										const style = useMemo(() => ({
											...baseStyle,
											...(isDragActive ? activeStyle : {}),
											...(isDragAccept ? acceptStyle : {}),
											...(isDragReject ? rejectStyle : {}),
											// backgroundColor: "#00BCD4",
											// color: "#fff",
											borderRadius: "5px"
										}), [
											isDragActive,
											isDragReject
										])
										return (
											<React.Fragment>
												<div {...getRootProps({style})}>
													<input {...getInputProps()} />
													{!isDragActive && 'Glissez votre fichier* ici ou cliquez pour sélectionner le fichier à transférer'}
													{isDragActive && !isDragReject  && 'Fichier accepté!'}
													{isDragReject && 'Fichier rejeté!'}
												</div>
											</React.Fragment>
										)}
									}
								</Dropzone>
								<CustomSelect 
									values={[
										{ id: 1, name: "Veuillez sélectionner un FAI" },
										{ id: 2, name: "Orange" },
										{ id: 3, name: "SFR" },
										{ id: 4, name: "SFR Hebdo" }
									]} 
									value={this.state.FAI} label="FAI" create="true" 
									handleChange={(e)=>this.handleChange(e)} 
									errorLabel={this.state.FAIerror==true?"Veuillez sélectionner un FAI":null}
									classes={classes}
									className={classes.FAI}
								/>
								<FileInformationContainer classes = {classes} acceptedFiles = { this.state.acceptedFiles } rejectedFiles = { this.state.rejectedFiles } isJsValidationEnabled = {isJsValidationEnabled}/>
								<Button 
									onClick={() => {
										if(this.state.FAI == '2')this.handleSaveClick('orangeOrder')
										if(this.state.FAI == '3')this.handleSaveClick('sfrOrder')
										if(this.state.FAI == '4')this.handleSaveClick('sfrHebdo')
									}} 
									color='primary' variant="contained" className={classes.button} disabled = {!this.state.isButtonSaveActivated}>
									<CloudUploadIcon className={classes.leftIcon} />
									{saveButtonLabel}
								</Button>
								<Typography variant="caption" gutterBottom>
									*Le fichier doit être de type .csv avec une taille inférieure ou égale à 20 Mo
								</Typography>
							</SimpleForm>
							</AccordionDetails>
						</Accordion>
	 
						<Accordion square expanded={this.state.expanded} onChange={this.handlePanel('expandedIdOrange')} style={{marginBottom: '10px'}}>
							<AccordionSummary
								style={{backgroundColor:"#288DB1"}}
								expandIcon={<ExpandMore style={{color:"white"}} />} >
									<Typography className={classes.title}>
										IMPORT DES IDENTIFIANTS ORANGE	
									</Typography>
							</AccordionSummary>
							<AccordionDetails style={{width:'100%', padding:0, marginBottom:"20px"}}>
							<SimpleForm 
								onSubmit={() => {this.handleSubmit('orangeIds')}}
								form={REDUX_FORM_UPLOAD_TOOLS_ORANGEIDS}
								resource="Tools/Upload"
								// We want no toolbar at all as we have our modal actions
								toolbar={null}
							>
								<Dropzone 
									onDrop={this.handleOnDrop}
									minSize={0}
									maxSize={maxSize} //20Mb
									multiple = {false}
									onDragEnter = { this.handleOnDragEnter }
								>
									{({getRootProps, getInputProps, isDragActive, isDragReject, rejectedFiles, isDragAccept, acceptedFiles, draggedFiles }) => {
										// const isFileTooLarge = (rejectedFiles.length > 0 && rejectedFiles[0].size > maxSize) || (acceptedFiles.length > 0 && acceptedFiles[0].size > maxSize)
										const style = useMemo(() => ({
											...baseStyle,
											...(isDragActive ? activeStyle : {}),
											...(isDragAccept ? acceptStyle : {}),
											...(isDragReject ? rejectStyle : {}),
											// backgroundColor: "#00BCD4",
											// color: "#fff",
											borderRadius: "5px"
										}), [
											isDragActive,
											isDragReject
										])
										return (
											<React.Fragment>
												<div {...getRootProps({style})}>
													<input {...getInputProps()} />
													{!isDragActive && 'Glissez votre fichier* ici ou cliquez pour sélectionner le fichier à transférer'}
													{isDragActive && !isDragReject  && 'Fichier accepté!'}
													{isDragReject && 'Fichier rejeté!'}
												</div>
											</React.Fragment>
										)}
									}
								</Dropzone>
								<FileInformationContainer classes = {classes} acceptedFiles = { this.state.acceptedFiles } rejectedFiles = { this.state.rejectedFiles } isJsValidationEnabled = {isJsValidationEnabled}/>
								<Button onClick={() => {this.handleSaveClick('orangeIds')}} color='primary' variant="contained" className={classes.button} disabled = {!this.state.isButtonSaveActivated}>
									<CloudUploadIcon className={classes.leftIcon} />
									{saveButtonLabel}
								</Button>
								<Typography variant="caption" gutterBottom>
									*Le fichier doit être de type .csv ou .xls avec une taille inférieure ou égale à 20 Mo
								</Typography>
							</SimpleForm>
							</AccordionDetails>
						</Accordion>
						</CardContent>
					</Card>
					<ProcessLoader modalOpen = {this.state.isProcessLoaderOpen} 
						onClose={this.handleCloseProgressBar}  
						isOperationFinished = { this.state.isOperationFinished }
						progressModalContent = {progressModalContent}
					/>
				</div>
			) 
		}
		else{
			return (
				<div>
					<Card>
						<CardContent>


						<Accordion square expanded={this.state.expandedImages} onChange={this.handlePanel('expandedImages')} style={{marginBottom:'10px', marginTop:'0px'}}>
							<AccordionSummary
								style={{backgroundColor:"#288DB1"}}
								expandIcon={<ExpandMore style={{color:"white"}} />} >
									<Typography className={classes.title}>
										IMPORT D'IMAGES EN MASSE
									</Typography>
							</AccordionSummary>
							<AccordionDetails style={{width:'100%', padding:0, marginBottom:"20px"}}>
							<SimpleForm 
								onSubmit={() => {this.handleSubmit('massImage')}}
								form={REDUX_FORM_UPLOAD_TOOLS_MASSIMAGE}
								resource="Tools/Upload"
								// We want no toolbar at all as we have our modal actions
								toolbar={null}
							>
								<Dropzone 
									onDrop={this.handleOnDrop}
									multiple = {false}
									onDragEnter = { this.handleOnDragEnter }
								>
									{({getRootProps, getInputProps, isDragActive, isDragReject, rejectedFiles, isDragAccept, acceptedFiles, draggedFiles }) => {
										// Logger.info('dragged')
										// Logger.info(isDragReject)
										// Logger.info(isDragAccept)
										const style = useMemo(() => ({
											...baseStyle,
											...(isDragActive ? baseStyle : {}),
											...(isDragAccept ? baseStyle : {}),
											...(isDragReject ? baseStyle : {})
										}), [
											isDragActive,
											isDragReject
										])
										return (
											<React.Fragment>
												<div {...getRootProps({style})}>
													<input {...getInputProps()} />
													{!isDragActive && 'Glissez votre fichier* ici ou cliquez pour sélectionner le fichier à transférer'}
													{isDragActive && !isDragReject  && 'Fichier prêt à être transféré'}
													{isDragReject && 'Fichier prêt à être transféré'}
												</div>
											</React.Fragment>
										)}
									}
								</Dropzone>
								<FileInformationContainer classes = {classes} acceptedFiles = { this.state.acceptedFiles } rejectedFiles = { this.state.rejectedFiles } isJsValidationEnabled = {isJsValidationEnabled}/>
								<AddButton onClick={() => {this.handleSaveClick('massImage')}}  color='primary' variant="contained" className={classes.button} disabled = {!this.state.isButtonSaveActivated}>
									<CloudUploadIcon className={classes.leftIcon} />
									{saveButtonLabel}
								</AddButton>
								<Typography variant="caption" gutterBottom>
									*Le fichier doit être de type .zip avec une taille inférieure ou égale à 20 Mo
								</Typography>
							</SimpleForm>
							</AccordionDetails>
						</Accordion>

						<Accordion square expanded={this.state.expandedCommands} onChange={this.handlePanel('expandedCommands')} style={{marginBottom: '10px'}}>
							<AccordionSummary
								style={{backgroundColor:"#288DB1"}}
								expandIcon={<ExpandMore style={{color:"white"}} />} >
									<Typography className={classes.title}>
										IMPORT DES COMMANDES ORANGE ET SFR
									</Typography>
							</AccordionSummary>
							<AccordionDetails style={{width:'100%', padding:0, marginBottom:"20px"}}>
							<SimpleForm 
								onSubmit={() => {
									if(this.state.FAI == '2' && this.state.isButtonSaveActivated==true){this.handleSubmit('orangeOrder')}
									else if(this.state.FAI == '3' && this.state.isButtonSaveActivated==true){this.handleSubmit('sfrOrder')}
									else if(this.state.FAI == '4' && this.state.isButtonSaveActivated==true){this.handleSubmit('sfrHebdo')}
								}}
								form={REDUX_FORM_UPLOAD_TOOLS_ORANGEORDER}
								resource="Tools/Upload"
								// We want no toolbar at all as we have our modal actions
								toolbar={null}
							>
								<div style={{display:'flex', width:"1000px"}}>
								<Dropzone 
									onDrop={this.handleOnDrop}
									multiple = {false}
									onDragEnter = { this.handleOnDragEnter }
								>
									{({getRootProps, getInputProps, isDragActive, isDragReject, rejectedFiles, isDragAccept, acceptedFiles, draggedFiles }) => {
										// Logger.info('dragged')
										// Logger.info(isDragReject)
										// Logger.info(isDragAccept)
										const style = useMemo(() => ({
											...baseStyle,
											...(isDragActive ? baseStyle : {}),
											...(isDragAccept ? baseStyle : {}),
											...(isDragReject ? baseStyle : {})
										}), [
											isDragActive,
											isDragReject
										])
										return (
											<React.Fragment>
												<div {...getRootProps({style})}>
													<input {...getInputProps()} />
													{!isDragActive && 'Glissez votre fichier* ici ou cliquez pour sélectionner le fichier à transférer'}
													{isDragActive && !isDragReject  && 'Fichier prêt à être transféré'}
													{isDragReject && 'Fichier prêt à être transféré'}
												</div>
											</React.Fragment>
										)}
									}
								</Dropzone>
								<CustomSelect 
									values={[
										{ id: 1, name: "Veuillez sélectionner un FAI" },
										{ id: 2, name: "Orange" },
										{ id: 3, name: "SFR" },
										{ id: 4, name: "SFR Hebdo" }
									]} 
									value={this.state.FAI} label="FAI" create="true" 
									handleChange={(e)=>this.handleChange(e)} 
									errorLabel={this.state.FAIerror==true?"Veuillez sélectionner un FAI":null}
									classes={classes}
									className={classes.FAI}
								/>
								</div>
								<FileInformationContainer classes = {classes} acceptedFiles = { this.state.acceptedFiles } rejectedFiles = { this.state.rejectedFiles } isJsValidationEnabled = {isJsValidationEnabled}/>
								<AddButton 
									onClick={() => {
										if(this.state.FAI == '1'){this.setState({FAIerror:true})}
										else if(this.state.FAI == '2'){this.handleSaveClick('orangeOrder')}
										else if(this.state.FAI == '3'){this.handleSaveClick('sfrOrder')}
										else if(this.state.FAI == '4'){this.handleSaveClick('sfrHebdo')}
									}} 
									color="primary" variant="contained" className={classes.button} disabled = {!this.state.isButtonSaveActivated}>
									<CloudUploadIcon className={classes.leftIcon} />
									{saveButtonLabel}
								</AddButton>
								<Typography variant="caption" gutterBottom>
									*Le fichier doit être de type .csv avec une taille inférieure ou égale à 20 Mo
								</Typography>
							</SimpleForm>
							</AccordionDetails>
						</Accordion>

						<Accordion square expanded={this.state.expandedIdOrange} onChange={this.handlePanel('expandedIdOrange')} style={{marginBottom: '10px'}}>
							<AccordionSummary
								style={{backgroundColor:"#288DB1"}}
								expandIcon={<ExpandMore style={{color:"white"}} />} >
									<Typography className={classes.title}>
										IMPORT DES IDENTIFIANTS ORANGE	
									</Typography>
							</AccordionSummary>
							<AccordionDetails style={{width:'100%', padding:0, marginBottom:"20px"}}>
							<SimpleForm 
								onSubmit={() => {this.handleSubmit('orangeIds')}}
								form={REDUX_FORM_UPLOAD_TOOLS_ORANGEIDS}
								resource="Tools/Upload"
								// We want no toolbar at all as we have our modal actions
								toolbar={null}
							>
								<Dropzone 
									onDrop={this.handleOnDrop}
									//accept="application/x-zip-compressed, application/x-7z-compressed, application/zip"
									minSize={0}
									maxSize={maxSize} //20Mb
									multiple = {false}
									onDragEnter = { this.handleOnDragEnter }
								>
									{({getRootProps, getInputProps, isDragActive, isDragReject, rejectedFiles, isDragAccept, acceptedFiles, draggedFiles }) => {
										// const isFileTooLarge = (rejectedFiles.length > 0 && rejectedFiles[0].size > maxSize) || (acceptedFiles.length > 0 && acceptedFiles[0].size > maxSize)
										const style = useMemo(() => ({
											...baseStyle,
											...(isDragActive ? activeStyle : {}),
											...(isDragAccept ? acceptStyle : {}),
											...(isDragReject ? rejectStyle : {}),
											// backgroundColor: "#00BCD4",
											// color: "#fff",
											borderRadius: "5px"
										}), [
											isDragActive,
											isDragReject
										])
										return (
											<React.Fragment>
												<div {...getRootProps({style})}>
													<input {...getInputProps()} />
													{!isDragActive && 'Glissez votre fichier* ici ou cliquez pour sélectionner le fichier à transférer'}
													{isDragActive && !isDragReject  && 'Fichier accepté!'}
													{isDragReject && 'Fichier rejeté!'}
												</div>
											</React.Fragment>
										)}
									}
								</Dropzone>
								<FileInformationContainer classes = {classes} acceptedFiles = { this.state.acceptedFiles } rejectedFiles = { this.state.rejectedFiles } isJsValidationEnabled = {isJsValidationEnabled}/>
								<Button onClick={() => {this.handleSaveClick('orangeIds')}} color='primary' variant="contained" className={classes.button} disabled = {!this.state.isButtonSaveActivated}>
									<CloudUploadIcon className={classes.leftIcon} />
									{saveButtonLabel}
								</Button>
								<Typography variant="caption" gutterBottom>
									*Le fichier doit être de type .csv ou .xls avec une taille inférieure ou égale à 20 Mo
								</Typography>
							</SimpleForm>
							</AccordionDetails>
						</Accordion>
						</CardContent>
					</Card>
					<ProcessLoader modalOpen = {this.state.isProcessLoaderOpen} 
						onClose={this.handleCloseProgressBar}  
						isOperationFinished = { this.state.isOperationFinished }
						progressModalContent = {progressModalContent}
					/>
				</div>
			) 
		}
	}
} 

const mapStateToProps = state => {
	return ({
		isSubmitting: isSubmitting(REDUX_FORM_UPLOAD_TOOLS_MASSIMAGE)(state),
	})
}

const mapDispatchToProps = {
	change,
	// fetchEnd,
	// fetchStart,
	showNotification,
	submit,
}

const enhance = compose(
	connect(mapStateToProps, mapDispatchToProps),
	withStyles(styles)
)
 
export default translate(enhance(MyDropzoneArea))