import React from 'react'
import PropTypes from 'prop-types'
import { SelectArrayInput } from 'react-admin'
import { isNumber, isArrayLike } from '30-seconds-of-code'
import { DomainsItems } from '../../../tools'

const DomainsItemsArrayInput = (props) => {
	const { domainid, items, withAll, ...rest } = props

	let choices
	if (isNumber(domainid))
		choices = DomainsItems.getSelectList(domainid, withAll)

	return (
		<SelectArrayInput choices={isArrayLike(choices) ? choices : (isArrayLike(items) ? items : [])} {...rest} />
	)
}

DomainsItemsArrayInput.propTypes = {
	domainid: PropTypes.number.isRequired,
	items: PropTypes.array,
	withAll: PropTypes.bool,
}

DomainsItemsArrayInput.defaultProps = {
	withAll: false,
	addLabel: true,
}

export default DomainsItemsArrayInput