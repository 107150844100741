import React from 'react'
import { SimpleShowLayout, SimpleForm, Show, Edit, Create, List, SelectInput, TextInput, Datagrid, TextField, Filter } from 'react-admin'
import { ConcatFieldMovies, TransferField } from '../common/fields'
import { MyListActions, MyShowActions, MyEditActions, MyCreateActions } from '../common/actions'
import { CardShow, CardEdit, CardCreate } from '.'

const mode = [
	{ id: 1, name: 'Libellé du transfert' },
	{ id: 2, name: 'Identifiant du transfert' },
	{ id: 4, name: 'Nom du service' },
	{ id: 8, name: 'Raison sociale' },
	{ id: 16, name: 'Nom du contact' },
	{ id: 32, name: 'Identifiant du service' },
]

const CompaniestransfersFilter = (props) => (
	<Filter {...props}>
		<SelectInput label="fields.criteria" source="mode" choices={mode} alwaysOn allowEmpty={false} />
		<TextInput label="fields.value" source="q" alwaysOn allowEmpty={false} resettable clearAlwaysVisible />
	</Filter>
)

const CompaniestransfersList = (props) => (
	<List
		title="titles.companiesTransfersList"
		filters={<CompaniestransfersFilter />}
		actions={<MyListActions />}
		filterDefaultValues={{ mode: 32, q: 104, order: 2 }}
		bulkActionButtons={false}
		{...props}
	>
		<Datagrid rowClick="show">
			<TextField source="id" label="fields.transferid" sortable={false} />
			<ConcatFieldMovies label="fields.label" source='label;value' sortable={false} />
		</Datagrid>
	</List>
)

const Title = (props) => <TransferField addId={true} addLabel={true} sourceId='id' sourceLabel='label' size={32} {...props} />

const CompaniestransfersShow = (props) => (
	<Show title={<Title />} actions={<MyShowActions />} {...props}>
		<SimpleShowLayout>
			<CardShow />
		</SimpleShowLayout>
	</Show>
)

const CompaniestransfersEdit = (props) => (
	<Edit title="titles.companiesTransfersCard" undoable={false} actions={<MyEditActions />} {...props}>
		<SimpleForm>
			<CardEdit />
		</SimpleForm>
	</Edit>
)

const CompaniestransfersCreate = (props) => (
	<Create title="titles.companiesTransfersCard" undoable={false} actions={<MyCreateActions />} {...props}>
		<SimpleForm>
			<CardCreate />
		</SimpleForm>
	</Create>
)

export { CompaniestransfersList, CompaniestransfersShow, CompaniestransfersEdit, CompaniestransfersCreate }