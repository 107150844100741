import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-admin'
import Avatar from '@material-ui/core/Avatar'
//import { fade } from '@material-ui/core/styles/colorManipulator'
import classnames from 'classnames'
import { Links } from '../../../tools'
import { getId, getValue } from '../../../tools/utils'
import NameField from './NameField'
import Color from 'color'
import { withStyles } from '@material-ui/core/styles'

const styles = theme =>   ({
	root: {
		display: 'flex',
		flexWrap: 'nowrap',
		alignItems: 'center'
	},
	text: {
		color: theme.palette.text.primary,
		marginLeft: '5px',
	},
	link: {
		marginLeft: '5px',
	},
	avatar: {
		color: theme.palette.text.primary,
		backgroundColor: Color(theme.palette.text.primary).alpha(0.15).string(), //fade(theme.palette.text.primary, 0.15),
	}
})

const ProductField = props => {
	const { addId, addLabel, sourceId, sourceLabel, style, isLink, classes } = props

	if (!addId && !addLabel)
		return null

	const id = getId(props, sourceId)

	let label
	if (addLabel) {
		const name = getValue(props, sourceLabel)
		label = name ? name : ''
	}

	if (addId) {
		if (addLabel) {
			label = `${label} (${id})`
		}
		else {
			label = id
		}
	}

	let avatarLetters
	if (label) {
		const position = label.indexOf('/')
		if (position !== -1) {
			avatarLetters = label.slice(0, position)
			if (avatarLetters.length >= 3)
				avatarLetters = avatarLetters.slice(0, 3)
			label = label.slice(position + 1)
		}
	}

	let field = (<div className={classes.root}>
		{avatarLetters ? <Avatar className={classes.avatar} style={style}>{avatarLetters}</Avatar> : null}
		<NameField
			text={label}
			className={classnames({
				[classes.text]: !isLink,
				[classes.link]: isLink,
			})}
		/>
	</div>
	)

	return (
		<Fragment>
			{
				isLink ?
					<Link to={Links.CompaniesproductsShow(id)}>
						{field}
					</Link>
					:
					field
			}
		</Fragment>
	)
}

ProductField.propTypes = {
	record: PropTypes.object,
	sourceId: PropTypes.string,
	sourceLabel: PropTypes.string,
	addId: PropTypes.bool,
	addLabel: PropTypes.bool,
	style: PropTypes.object,
	classes: PropTypes.object.isRequired,
	isLink: PropTypes.bool,
}

ProductField.defaultProps = {
	record: {},
	sourceId: 'id',
	sourceLabel: 'name',
	addId: true,
	addLabel: true,
	style: {},
	isLink: false,
}

export default withStyles(styles)(ProductField)
