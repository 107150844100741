export default
{
	'fields': {
		'action': 'Action',
		'actionby': 'Action par',
		'actionid': 'ID Action',
		'actionon': 'Action le',
		'actionqty': 'Quantité action',
		'activation': 'Activation',
		'address': 'Adresse',
		'advertid': 'ID Advert',
		'albumid': 'ID Album',
		'alertid': 'ID Alerte',
		'allocineid': 'ID Allocine',
		'amount': 'Montant',
		'amountleft': 'Montant restant',
		'amounttotal': 'Montant total',
		'anciennete': 'Ancienneté',
		'appearance': 'Apparition',
		'associateid': 'ID Association',
		'atg': 'ATG',
		'atgid': 'ID ATG',
		'attributes': 'Attributs',
		'audiocodecid': 'ID Codec audio',
		'audiofmtid': 'ID Format audio',
		'audioproperties': 'Propriétés audio',
		'author': 'Auteur',
		'availabledate': 'Date de disponibilité',
		'availabledcodes': 'Codes disponibles',
		'averagetargetage': 'Age moyen',
		'awardid': 'ID Récompense',
		'bandwidth': 'Bande passante',
		'bannerid': 'ID Bannière',
		'baseline': 'Slogan',
		'biography': 'Biographie',
		'birthdate': 'Date de naisssance',
		'birthplace': 'Lieu de naissance',
		'bitrateid': 'ID Bitrate',
		'campaignid': 'ID Campagne',
		'canceled': 'Annulé',
		'canceledorderid': 'ID Commande annulé',
		'capabilities': 'Capacités',
		'catalogdate': 'Date catalogue',
		'categoryid': 'ID Catégorie',
		'cganumber': 'CGA',
		'cganumberweb': 'CGA Web',
		'checkedin': 'Pris',
		'civility': 'Civilité',
		'civilityid': 'ID Civilité',
		'classificationid': 'ID Classification',
		'clientinfo': 'Information client',
		'closed': 'Clôt',
		'closedby': 'Clôturé par',
		'closedon': 'Clôturé le',
		'cncid': 'ID CNC',
		'code': 'Code',
		'codeid': 'ID Code',
		'col': 'Colonne',
		'color': 'Couleur',
		'comment': 'Commentaire',
		'commentid': 'ID Commentaire',
		'comments': 'Commentaires',
		'commercialtypeid': 'ID Type commercial',
		'commissionamount': 'Montant de la comission',
		'commissionfee': 'Commission fee',
		'commondrm': 'DRM Commun',
		'companyid': 'ID Compagnie',
		'config': 'Configuration',
		'contactid': 'ID Contact',
		'content': 'Contenu',
		'contentid': 'ID Contenu',
		'contractid': 'ID Contrat',
		'contrat_id': 'ID Contrat',
		'copyright': 'Copyright',
		'country': 'Pays',
		'countryid': 'ID Pays',
		'createdby': 'Crée par',
		'createdon': 'Crée le',
		'credit': 'Crédits',
		'credits': 'Crédits',
		'creditsfree': 'Crédits gratuits',
		'creditsleft': 'Crédits restants',
		'creditstotal': 'Crédits totaux',
		'crightid': 'ID Droit',
		'criteria': 'Critère',
		'currencyid': 'ID Monnaie',
		'customercontentid': 'ID Contenu du client',
		'customerfavoriteid': 'ID Favori',
		'customergameid': 'ID Jeu du client',
		'customerid': 'ID Client',
		'customerpassid': 'ID Customerpass',
		'customerproductid': 'ID Produit client',
		'customerratingid': 'Note du client',
		'data': 'Données',
		'date': 'Date',
		'deathdate': 'Date de décès',
		'deathplace': 'Lieu de decès',
		'defaultitemid': 'ID Item par défaut',
		'definition': 'Définition',
		'deleted': 'Supprimé',
		'desactivated': 'Désactivé',
		'desactivatedby': 'Désactivé par',
		'desactivatedon': 'Désactivé le',
		'desactivatedtech': 'Désactivation technique',
		'description': 'Description',
		'devices': 'Périphériques',
		'diagram_id': 'ID Diagramme',
		'diffusionmodeid': 'ID Mode de diffusion',
		'disabled': 'Désactivé',
		'disabledby': 'Désactivé par',
		'disabledon': 'Désactivé le',
		'discount': 'Discount',
		'discountid': 'ID Discount',
		'distributorid': 'ID Distribution',
		'domainid': 'ID Domaine',
		'done': 'Effectué',
		'downloadmanagerstate': 'Etat du gestionnaire de téléchargement',
		'drm': 'DRM',
		'drmid': 'ID DRM',
		'drmprovid': 'ID DRM Provider',
		'dte_echeance_contrat': 'Date écheance du contrat',
		'dte_souscript_canalplay': 'Date souscription',
		'dte_validite_avantage': 'Date de validité du contrat',
		'duration': 'Durée',
		'editorial': 'Editorial',
		'elapsedtime': 'Temps écoulé',
		'email': 'Email',
		'emailfake': 'Email fictif',
		'emailid': 'ID Email',
		'emailpass': 'Email P@SS',
		'emails': 'Emails',
		'emailutile': 'Email',
		'enabled': 'Activé',
		'enddate': 'Date de fin',
		'endtime': 'Heure de fin',
		'episode': 'Episode',
		'errorid': 'ID Erreur',
		'errormessage': "Message d'erreur",
		'estatid': 'ID Estat',
		'estimatedpublishdate': 'Date de publication estimée',
		'eventcomments': 'Commentaires',
		'eventcontactid': 'ID Contact événement',
		'eventid': 'ID Evenement',
		'exclusive': 'Exclusif',
		'executiondate': "Date d'éxécution",
		'expiredate': "Date d'expiration",
		'expireddate': "Date d'expiration",
		'expireon': 'Expire le',
		'expirycard': "Date d'expiration",
		'extended': 'Etendue',
		'externalid': 'ID Externe',
		'faqid': 'ID FAQ',
		'fax': 'Fax',
		'festivalid': 'ID Festival',
		'filename': 'Fichier',
		'filescount': 'Nombre de fichiers',
		'filmography': 'Filmographie',
		'filter': 'Filtre',
		'firstip': 'Première IP',
		'firstname': 'Prénom',
		'format': 'Format',
		'formatid': 'ID Format',
		'formulaid': 'ID Formule',
		'ftpserverid': 'ID Serveur FTP',
		'gameid': 'ID Jeu',
		'genderid': 'ID Genre',
		'genre': 'Genre',
		'grossrevenue': 'TVA Gross',
		'groupid': 'ID Groupe',
		'gsm': 'Téléphone',
		'guid': 'GUID',
		'hashcard': 'CB Hash',
		'headerid': 'ID En-tête',
		'headers': 'En-tête',
		'height': 'Hauteur',
		'hidden': 'Caché',
		'historyid': 'ID Historique',
		'homeid': 'ID Home',
		'href': 'Lien',
		'html': 'HTML',
		'id': 'ID',
		'idprogrammeantenne': 'ID Programme Antenne',
		'imagefmtid': 'ID Format image',
		'imdbid': 'ID IMDB',
		'includeid': 'ID Inclusion',
		'instance': 'Instance',
		'invoicingaddress': 'Adresse de livraison',
		'ip': 'IP',
		'ipaddress': 'Addresse IP',
		'ippoolid': 'ID Pool',
		'isconnected': 'Connecté',
		'ispack': 'Pack',
		'isprogram' : 'Programme',
		'itemid': 'ID Item',
		'jobid': 'ID Job',
		'keycache': 'Clé cache',
		'keyid': 'ID Clé',
		'keys': 'Clés',
		'keyword': 'Mot clé',
		'keywordid': 'ID Mot clé',
		'label': 'Libellé',
		'langid': 'ID Langue',
		'language': 'Langue',
		'lasterror': 'Dernière erreur',
		'lastexecution': 'Dernière exécution',
		'lastip': 'Dernière IP',
		'lastlogon': 'Dernière connexion',
		'lastname': 'Nom',
		'lastrelease': 'Dernière disponibilité',
		'lastupdate': 'Dernière mise à jour',
		'leadid': 'ID Lead',
		'length': 'Longueur',
		'levelid': 'ID Niveau',
		'license': 'Licence',
		'licenseid': 'ID Licence',
		'licenserequest': 'Licence de requête',
		'lineid': 'ID Ligne',
		'link': 'Lien',
		'linkid': 'ID Lien',
		'listcontentid': 'ID Contenu de liste',
		'listhomeid': "ID Liste de l'accueil",
		'listid': 'ID Liste',
		'locatorid': 'ID Locator',
		'locked': 'Bloqué',
		'logid': 'ID Log',
		'logs': 'Traces',
		'lvalue': 'Valeur longue',
		'mailingid': 'ID Mailing',
		'marker': 'Marker',
		'marketingid': 'ID Marketing',
		'mask': 'Masque',
		'maxitems':  "Nombre d'éléments maximal" ,
		'mediahost': 'Host média',
		'mediaid': 'ID Média',
		'medias': 'Médias',
		'mediaserver': 'Serveur de média',
		'menuid': 'ID Menu',
		'message': 'Message',
		'messageid': 'ID Message',
		'metadata': 'Méta données',
		'method': 'Méthode',
		'mg': 'MG',
		'mimetypeid': 'ID Type MIME',
		'minprice': 'Prix minimum',
		'modecommid': 'ID Mode de commercialisation',
		'modeid': 'ID Mode',
		'modifiedby': 'Modifié par',
		'modifiedon': 'Modifié le',
		'movieid': 'ID Programme',
		'moviescount': 'Nombre de programmes',
		'movietitle': 'Titre programme',
		'msd': 'MSD',
		'multilanguage': 'Multi langue',
		'name': 'Nom',
		'newstateid': 'ID Nouveau statut',
		'nextitemid': 'ID Prochain item',
		'nom': 'Nom',
		'num_id_avantage': 'ID Avantage',
		'numaffaireantenne': 'ID Affaire Antenne',
		'numcustomer': 'N° Client',
		'numepi1': 'N° Premier épisode',
		'numepi2': 'N° Second épisode',
		'objectid': 'ID Objet',
		'offer': 'Offre',
		'offerid': 'ID Offre',
		'operateur': 'Opérateur',
		'options': 'Options',
		'orderid': 'ID Commande',
		'ordernum': 'Numéro de commande',
		'orginalname': 'Nom original',
		'originalname': 'Nom original',
		'pack': 'Pack',
		'packexist': 'Pack existe',
		'paramname': 'Nom du paramètre',
		'paramvalue': 'Valeur du paramètre',
		'parentalcode': 'Code parental',
		'parentcompanyid': 'ID Compagnie Parente',
		'parentid': 'ID Parent',
		'partnerid': 'ID Partenaire',
		'password': 'Mot de passe',
		'path': 'Chemin',
		'peopleid': 'ID Personnalité',
		'percentsale': 'Pourcentage de vente',
		'periodicity': 'Périodicité',
		'pfvideoid': 'ID PFVideo',
		'phone': 'Téléphone',
		'pictureid': 'ID Image',
		'picturename': "Nom de l'image",
		'pictures': 'Images',
		'picturetypeid': "ID Type d'image",
		'pictureurl': 'URL Image',
		'playerid': 'ID Player',
		'playlistid': 'ID Playlist',
		'port': 'Port',
		'postalcode': 'Code postal',
		'prenom': 'Prénom',
		'preorder': 'Pré-commande',
		'pressreviewauthor': "Auteur de l'article de presse",
		'previousvalue': 'Valeur précédente',
		'price': 'Prix',
		'pricingclassid': 'ID Classe de prix',
		'pricingid': 'ID Pricing',
		'pricinglineid': 'ID Pricing line',
		'principal_id': 'ID Principal',
		'priority': 'Priorité',
		'privatekey': 'Clé privée',
		'problemid': 'ID Problème',
		'processed': 'Réalisé',
		'processedby': 'Réalisé par',
		'processedon': 'Réalisé le',
		'procid': 'ID Procédure',
		'procname': 'Nom de procédure',
		'productid': 'ID Produit',
		'profiledata': 'Données du profil',
		'profileid': 'ID Profil',
		'programtype': 'Type de programme',
		'promoid': 'ID Promotion',
		'properties': 'Propriétés',
		'property': 'Propriété',
		'protected_2': 'Protégé',
		'protocol': 'Protocole',
		'providerid': 'ID Provider',
		'proxy': 'Proxy',
		'pseudo': 'Pseudo',
		'public_2': 'Public',
		'published': 'Publié',
		'publishedby': 'Publié par',
		'publishedmoviescount': 'Nombre de programmes publiés',
		'publishedon': 'Publié le',
		'publishedtech': 'Publication technique',
		'publisheduntil': "Publié jusqu'au",
		'purchasecode': 'Code achat',
		'quantity': 'Quantité',
		'query': 'Requête',
		'queryid': 'ID Requête',
		'queryname': 'Nom de la requête',
		'question': 'Question',
		'questions': 'Questions',
		'quote': 'Citation',
		'quoteid': 'ID Citation',
		'rank': 'Rang',
		'rating': 'Rating',
		'ratingid': 'ID Rating',
		'rc': 'Code retour',
		'recordid': 'ID Enregistrement',
		'ref_msd': 'Référence MSD',
		'refcanal': 'Référence CANAL',
		'refepi1': 'N° Premier épisode de référence',
		'refepi2': 'N° Sécond épisode de référence',
		'reference': 'Référence',
		'refhapi': 'Référence HAPI',
		'refid': 'ID Référence',
		'regexp': 'Expression régulière',
		'regionid': 'ID Region',
		'release': 'Date de disponibilité',
		'releasedate': 'Date de sortie',
		'remoteassistance': 'Assistance à distance',
		'renewed': 'Renouvellé',
		'requestdate': 'Date de requête',
		'requestid': 'ID Requête',
		'response': 'Réponse',
		'responseid': 'ID Réponse',
		'restricted': 'En restriction',
		'restrictionid': 'ID Restriction',
		'review': 'Revue',
		'reviewid': 'ID Revue',
		'rights': 'Droits',
		'roleid': 'ID Rôle',
		'rolename': 'Nom du rôle',
		'root': 'Base',
		'route': 'Route',
		'row': 'Ligne',
		'rsac': 'RSAC',
		'rsac_max': 'RSAC Max',
		'rsac_min': 'RSAC Min',
		'ruleid': 'ID Règle',
		'rules': 'Règles',
		'sacdamount': 'Montant SACD',
		'sacdfee': 'SACD Fee',
		'sacemamount': 'Montant SACEM',
		'sacemfee': 'SACEM Fee',
		'savedon': 'Sauvegardé le',
		'score': 'Score',
		'season': 'Saison',
		'sender': 'Envoyeur',
		'sendingdate': "Date d'envoi",
		'serieid': 'ID Série',
		'servername': 'Nom du serveur',
		'serviceid': 'ID Service',
		'serviceurl': 'URL Service',
		'short_2': 'Court',
		'shorttitle': 'Titre court',
		'size': 'Taille',
		'slotid': 'ID Slot',
		'sold': 'Ventes',
		'soldtotal': 'Ventes totales',
		'soldtypeid': 'ID Type de vente',
		'solutionid': 'ID Solution',
		'sourcefilename': 'Fichier source',
		'sourcename': 'Nom source',
		'spname': 'Nom de la procédure stockée',
		'startdate': 'Date de début',
		'starttime': 'Heure de début',
		'state': 'Statut',
		'stateid': 'ID Statut',
		'statsoldid': 'ID Stats ventes',
		'statuscode': 'Statut',
		'stdescription': 'Description',
		'step': 'Etape',
		'stepid': 'ID Etape',
		'storagepathname': 'Chemin de stockage',
		'stpicturename': "Nom de l'image",
		'strateid': 'ID Strate',
		'subalbumid': 'ID Sous album',
		'subgenreid': 'ID Sous-genre',
		'subject': 'Sujet',
		'submediaid': 'ID Sous média',
		'subscriberid': 'ID Subscriber',
		'subscriptionid': 'ID Sousscription',
		'subtitlesid': 'ID Sous-titres',
		'subtypeid': 'ID Sous-Type',
		'support_souscription': 'Support sousscription',
		'supportfaqid': 'ID FAQ Support',
		'suspended': 'Suspendu',
		'synthesisid': 'ID Synthèse',
		'system': 'Système',
		'targetfilename': 'Fichier target',
		'targetid': 'ID Target',
		'targettitle': 'Titre target',
		'targettypeid': 'ID Type de target',
		'templateid': 'ID Template',
		'tendency': 'Tendances',
		'territoriality': 'Territorialité',
		'theme': 'Thème',
		'themeid': 'ID Thème',
		'timecode': 'Time code',
		'title': 'Titre',
		'transactionid': 'ID Transaction',
		'transferid': 'ID Transfert',
		'trdprice': 'Prix promotionné',
		'trightid': 'ID Droit de transfert',
		'trprice': 'Prix',
		'trustlevelid': 'ID Niveau de confiance',
		'tsaamount': 'Montant TSA',
		'tsafee': 'TSA Fee',
		'ttl': 'TTL',
		'type': 'Type',
		'type_offre': "Type d'offre",
		'typeid': 'ID Type',
		'universid': 'ID Univers',
		'until': "Jusqu'au",
		'url': 'URL',
		'useaddress': "Utilise l'adresse",
		'usecommondrm': 'Utilise le DRM commun',
		'used': 'Utilisé',
		'userid': 'ID Utilisateur',
		'uvalue': 'Valeur 2',
		'valdate': 'Date de validité',
		'validatedby': 'Validé par',
		'validatedon': 'Validé le',
		'value': 'Valeur',
		'vat': 'TVA',
		'vatid': 'ID TVA',
		'vatrevenue': 'TVA Revenue',
		'version': 'Version',
		'videocodecid': 'ID Codec vidéo',
		'videoproperties': 'Propriétés vidéo',
		'viewduration': 'Durée de visualisation',
		'virtualcard': 'Carte virtuelle',
		'visa': 'Visa',
		'voucher': 'Voucher',
		'voucherid': 'ID Voucher',
		'website': 'Site Web',
		'width': 'Largeur',
		'winners': 'Gagnants',
		'workflow': 'Workflow',
		'workflowid': 'ID Workflow',
		'xml': 'XML',
		'xmlfile': 'Fichier XML',
		'year': 'Annèe',
		'week':'Semaine',
		'jobStatus' : 'Statut du job',
		'jobTypeId' : 'Type Job Id',
		'jobProperties' : 'Propriétés',
		'serverOne' : 'DISPATCH1',
		'serverTwo' : 'DISPATCH2',
		'jobParams' : 'Params',
		'jobParamsServerOne' : 'Params DISPATCH 1',
		'jobParamsServerTwo' : 'Params DISPATCH 2',
		'albumId' : 'ID Album',
		'albumTitle' : 'Titre Album',
		'searchContentPlaceHolder' : 'Rechercher ID',
		'contractPeriodStartDateVOD' : 'Date de début des contrats VOD',
		'contractPeriodEndDateVOD' : 'Date de fin des contrats VOD',
		'contractPeriodStartDateEST' : 'Date de début des contrats EST',
		'contractPeriodEndDateEST' : 'Date de fin des contrats EST',
		'periodTableId' : 'ID',
		'periodTableModeComm' : 'VOD/EST',
		'periodTableTarget' : 'CIBLE',
		'periodTableInterval' : 'PERIODE',
		'periodTableSD' : 'SD',
		'periodTableHD' : 'HD',
		'periodTable4K' : '4K',
		'periodTableHDDVD' : 'HD DVD',
		'periodTable4KDVD' : '4K DVD',
		'periodTableHDBR' : 'HD BR',
		'periodTable4KBR' : '4K BR',
		'searchMode' : 'Mode de recherche'
	},
	'messages': {
		'atLeastThreeCharacters': 'Vous devez saisir au moins 3 caractères',
		'errorCreatingJobBddp': "Erreur lors de la création du job d'export vers la BDDP",
		'errorCreatingJobImgConvertIt': "Erreur lors de la création du job de conversion d'image",
		'errorUpdatingPictureRecord': 'Erreur lors de la mise à jour du visuel en base de données',
		'fieldRequired': 'Le champ {0} est obligatoire',
		'fileMoved': 'Le fichier à été déplacé avec succès',
		'fileNoLongerAvailable': "Le fichier n'est plus disponible",
		'fileNotFound': 'Fichier non trouvé',
		'invalidRatioFromPivot': "Le ratio largeur/hauteur de l'image diffère de celui du pivot",
		'invalidSizeFromPivot': "Les dimensions de l'image diffèrent de celui du pivot",
		'invalidUploadedFile': 'Fichier envoyé invalide',
		'loginFailed': "Echec lors de l'authentification",
		'noFileUploaded': 'Aucun fichier transféré',
		'pictureTypeInvalidSize': 'Dimensions invalides pour ce type de visuel',
		'pictureTypeNotFound': 'Le type de visuel est inconnu',
		'pictureTypeSizeUnknown': 'Dimensions manquantes pour ce type de visuel',
		'tokenUnavailable': 'Jeton indisponible',
		'userLocked': 'Utilisateur bloqué',
		'userUnknown': 'Utilisateur inconnu',
		'onlyOneServerActivation': 'On peut seulement activer le job sur un serveur',
		'dispatcherConfigJobsNotCreated': 'Le job n'/'a pas encore été créé sur le serveur',
		'displayOnlyActiveJobs' : 'Afficher uniquement les jobs actifs',
		'displayModalText' : 'Afficher la valeur des paramètres',
		'activateJobOnTheServer' : 'Activer le job sur ce serveur',
		'deactivateJobOnTheServer' : 'Desactiver le job sur ce serveur',
		'errorUpdatingConfigurationManagerStatus' : 'Erreur durant la mis à jour du statut du job sur le serveur',
		'activateJobModalContentDescription' : 'Si vous voulez activer le job "#label" sur ce serveur, il faudrait le desactiver sur le serveur #currentserver.',
		'activateJobModalContentDescription2' : 'Vous pouvez cliquer sur le bouton "#messageActivateJob" pour continuer l'/'activation sinon cliquer sur le bouton "#messageDontActivateJob" pour annuler.',
		'successfulJobStatusUpdate' : 'Le statut du job a été mis à jour avec succès',
		'showParameterValue' : 'Cliquer pour afficher la valeur du paramètre',
		'successfulMultipleJobsChangeStatus' : 'Les statuts du job sur les 2 serveurs ont été mis à jour avec succès',
		'getAlbumPriceListError' : "Erreur lors de la récuperation des prix de l'album",
		'objectTitle' : 'Informations du programme ou du pack',
		'multipleStartDate' : 'Plusieurs programmes ont des dates de début de droit différentes',
		'contentInformation' : 'Information sur le contenu',
		'createOrUpdatePriceTitle' : 'Créer / Modifier une période de publication et prix',
		'publicationIntervalError' : 'La date de fin de publication doit être après la date de début pour le mode de commercialisation #modeComm ',
		'priceCheckboxMandatoryError' : 'Veuillez selectionner au moins un nouveau prix ou une date de publication',
		'targetsCheckboxMandatoryError' : 'Veuillez selectionner au moins une cible',
		'updatePriceFormValidated' : 'Le formulaire de mis à jour des prix est validé',
		'emptyPriceCheckbox' : 'Veuillez remplir les champs selectionnés avec leurs prix correspondantes: ',
		'invalidApplePrice' : 'Les prix IOS et Apple doivent être entre ',
		'emptyPublicationDate' : 'Veuillez remplir la période de publication #modeComm ',
		'notFoundContent' : 'Le contenu que vous recherchez est inexistant',
		'pricePlanningModalDescription' : 'Voulez-vous vraiment lancer la mise à jour des prix en fonction des données dans le tableau ci-dessous?',
		'pricePlanningModalProgress' : 'Opération(s) effectuée(s)',
		'pricePlanningModalSuccess' : 'Succès',
		'pricePlanningModalFailure' : 'Echec',
		'noChangeInThePeriodTable' : 'Aucun changement ne sera opéré dans le tableau',
		'uploadSuccess' : 'Le fichier a été transféré avec succès',
		'quickSearchMaintenance' : 'La recherche est en mode dégradé suite à un incident ou une maintenance. Le temps de réponse peut donc s’avérer plus long.',
		'planningLoading' : 'CHARGEMENT DU PLANNING...',
		'importPlanningSuccess' : "L'import du planning est terminé",
		'syncPlanningInstruction' : 'Veuillez mettre les paramètres de lancement de la synchronisation',
		'importPlanningInstruction' : "Instruction d'import du planning"
	},
	'labels': {
		'albums': 'Albums',
		'all': 'Tous',
		'canceled': 'Annulé',
		'configuration': 'Configuration',
		'dark': 'Dark',
		'done': 'Effectué',
		'empty': 'Vide',
		'error': 'Erreur',
		'ingestCablelabsAntenne': 'Ingestion Cablelabs Antenne',
		'ingestPicturesAntenne': 'Ingestion visuels Antenne',
		'inProgress': 'En cours',
		'light': 'Light',
		'manyFiles': '{0} fichiers',
		'moveToNormal': 'Déplacer en normal',
		'moveToTop': 'Déplacer en prioritaire',
		'none': 'Aucun',
		'noneFiles': 'Aucun fichier',
		'oneFile': '{0} fichier',
		'pending': 'En attente',
		'peoples': 'Personnalités',
		'movies': 'Programmes',
		'products': 'Produits',
		'search': 'Rechercher par identifiant ou par titre',
		'show': 'Voir',
		'standby': 'Mise en attente',
		'supervision': 'Supervision',
		'workingInError': 'Traitements en erreur',
		'workingRepository': 'Dossier de réception',
		'workingTop': 'Traitements prioritaire',
		'workInProgress': 'Traitements en cours',
		'media':'Média',
		'submedia':'Sous-média',
		'modecomm':'Mode de commercialisation',
		'haveRight':'Ayant-Droit',
		'status':'Statut',
		'paramsServerOne' : 'Params Serveur DISPATCH1',
		'paramsServerTwo' : 'Params Serveur DISPATCH2',
		'contractsPeriod' : 'Périodes de droits contrats (en cours et à venir VOD/EST)',
		'targets' : 'Cibles:',
		'currentPriceClassification' : 'Classe de prix actuelle',
		'publicationPeriodVOD' : 'Période de publication VOD',
		'publicationPeriodEST' : 'Période de publication EST',
		'publicationPeriodESTBK' : 'Période de publication EST B&K',
		'newVODPrice' : 'Nouveau Prix VOD:',
		'newESTPrice' : 'Nouveau Prix EST:',
		'newBKPrice' : 'Nouveau Prix EST B&K:',
		'checkButton' : 'Vérifier',
		'applyButton' : 'Appliquer',
		'searchByAlbum' : 'Recherche par album',
		'searchByProgram' : 'Recherche par programme',
		'normalSearchMode' : 'Normal',
		'emergencySearchMode' : 'Dégradé',
		'launchExcelImport' : 'Synchroniser planning',
		'uploadPlanning' : 'IMPORT PLANNING',
		'assignPreparation' : 'GERER AFFECTATION',
		'showAffectation' : 'Afficher répartition'
	},
	'titles': {
		'companiesAlbumsList': 'Liste des albums',
		'companiesMoviesCard': 'Fiche programme',
		'companiesMoviesList': 'Liste des programmes',
		'companiesPeoplesCard': 'Fiche personnalité',
		'companiesPeoplesList': 'Liste des personnalités',
		'companiesProductsCard': 'Fiche produit',
		'companiesProductsList': 'Liste des produits',
		'companiesTransfersCard': 'Fiche transfert',
		'companiesTransfersList': 'Liste des transferts',
		'configuration': 'Configuration',
		'ingestionStatus': 'Statut des ingestions',
		'moviesContentsCard': 'Fiche contenu',
		'moviesContentsList': 'Liste des contenus',
		'moviesEditorialsCard': 'Fiche éditoriale',
		'moviesPicturesCard': 'Fiche visuel',
		'moviesPicturesList': 'Liste des visuels',
		'peoplesEditorialsCard': 'Fiche editoriale de la personnalité',
		'peoplesMoviesList': 'Liste des programmes de la personnalité',
		'peoplesPicturesCard': 'Fiche du visuel de la personnalité',
		'peoplesPicturesList': 'Liste des visuels de la personnalité',
		'supervisingJobs': 'Supervision des jobs',
		'configurationManager' : 'Conf. MSJobExecute',
		'transfersRightsCard': "Fiche des droits d'exploitation",
		'catalogPrograms': 'Catalogue des programmes',
		'domainsitemsList': 'Liste des domaines',
		'moviesprioritiesTopPrio': 'Contenus top prioritaire',
		'JobParamModalTitle' : 'Params du job "#job" sur le serveur "#server"',
		'JobPropsTitle' : 'Propriétés du job "#job"',
		'PricePlanification' : 'Planification des prix et publication',
		'periodTableSubtitle' : 'Période de publication/pricing : #startDate – #endDate',
		'periodTableTitle' : 'Affichage des prix par période',
		'periodTablePreviewTitle' : 'Prévisualisation des données après modification',
		'pricePlanningSwitchButtonTitle' : 'Cliquer sur le type de contenu à rechercher' ,
		'pricePlanningModalHeader' : 'Processus de mise à jour',
		'uploadFileModalImage' : "Import d'images en masse",
		'uploadFileModalOrange' : 'Import de commandes Orange',
		'uploadFileModalSFR' : 'Import de commandes SFR',
		'uploadFileModalOrangeIds' : 'Import de identifiants Orange',
		'publicationPlanning' : 'Planning de publication',
		'synchPlanning' : 'SYNCHRONISATION DU PLANNING',
		'importPlanning' : 'IMPORT DU PLANNING',
		'publicationPreparation' : 'Preparation de publication',
	},
	'pluralizations': {
		'albums': 'Album |||| Albums',
		'peoples': 'Personnalité |||| Personnalités',
		'movies': 'Programme |||| Programmes',
		'products': 'Produit |||| Produits',
		'pictures': 'Image |||| Images',
		'numFiles': '%{smart_count} fichier |||| %{smart_count} fichiers',
		'fieldMaxLength': 'Le champ {0} ne peut être vide ||| La taille du champ {0} ne doit pas être supérieure à %{smart_count} caractères',
	},
	'buttons' : {
		'continueJobActivation' : "Continuer l'activation",
		'cancelJobActivation' : "Annuler l'activation",
		'undoAction' : 'Réinitialiser'
	}
}