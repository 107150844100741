import React from 'react'
import Avatar from '@material-ui/core/Avatar'
import Tooltip from '@material-ui/core/Tooltip'
import red from '@material-ui/core/colors/red'
import green from '@material-ui/core/colors/green'
import orange from '@material-ui/core/colors/orange'
import blue from '@material-ui/core/colors/blue'
import { withStyles } from '@material-ui/core/styles'
const styles =   ({
	defaultAvatar: {
	},
	redAvatar: {
		backgroundColor: red[500],
	},
	greenAvatar: {
		backgroundColor: green[500],
	},
	orangeAvatar: {
		backgroundColor: orange[500],
	},
	blueAvatar: {
		backgroundColor: blue[500],
	},
})

const AvatarCustom = withStyles(styles)(({color, icon, tooltip, letters, classes}) => {

	let colorClassName = classes.defaultAvatar
	if ('red' === color)
		colorClassName = classes.redAvatar
	else if ('green' === color)
		colorClassName = classes.greenAvatar
	else if ('orange' === color)
		colorClassName = classes.orangeAvatar
	else if ('blue' === color)
		colorClassName = classes.blueAvatar

	let content = null != icon ? icon : letters
	if (null != tooltip && icon !== undefined)
		content = <Tooltip title={tooltip}>{icon}</Tooltip>

	return (
		<Avatar className={colorClassName}>
			{content}
		</Avatar>
	)
})

export default AvatarCustom