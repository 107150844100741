import React from 'react'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import Slide from '@material-ui/core/Slide'
import PropTypes from 'prop-types'
import { CancelIcon, CheckIcon } from '../../../constants/icons'
import cyan from '@material-ui/core/colors/cyan'
import {  translate } from 'react-admin'
import { DateFormatter } from '../../../tools'
import { connect } from 'react-redux'
import compose from 'recompose/compose'
import { Publicationstracking } from '../../../requests'
import { Contacts as ContactsTools ,PublicationTrackingReferenceData} from '../../../tools'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import Paper from '@material-ui/core/Paper'
import { onLoadPlanning } from '../../../actions/publicationTracking'
import {CloseIcon } from '../../../constants/icons'
import { withStyles } from '@material-ui/core/styles'

const Transition = React.forwardRef(function Transition(props, ref) {
	return <Slide direction="up" ref={ref} {...props} />
})

const styles = theme =>  ({
	button: {
		margin: theme.spacing(1),
		width : '150px'
	},
	buttonCancel: {
		margin: theme.spacing(1),
		width : '150px',
		background: '#e23434'
	},
	buttonCheck: {
		margin: theme.spacing(1),
		width : '170px',
		background: '#0c9e0c'
	},
	leftIcon: {
		marginRight: theme.spacing(1),
	},
	dialogGlobal : {
		background: theme.palette.background.default,//'#353333'
	},
	dialogHeader:{
		padding: '10px 10px 10px',
		background: cyan[500],
		color: 'white',
		fontSize: 'medium',
		fontWeight: 'bold',
		textAlign: 'center',
		//height: '36px'
	},
	dialogContent: {
		padding: '16px 24px 17px',
		flex: '1 1 auto',
		color: 'white',
		fontSize: '1rem',
		fontWeight: '400',
	},
	dialogFooter: {
		flex: '0 0 auto',
		margin: '8px 4px',
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'flex-end',
	},
	formControl: {
		margin: theme.spacing(1),
		minWidth: 120,
	},
	selectEmpty: {
		marginTop: theme.spacing(2),
	},
	customSelect: {
		width : '80%'
	},
	table: {
		width: '100%',
	},
	closeIcon:{
		//marginRight: '2%',
		position: 'absolute',
  		width : '15px',
    	top: '0px',
    	right: '0px',
		borderRadius: '0',
		color: 'white',
		'&:hover': {
			background: 'red'
		}
	},
	validateButton: {
		// margin: theme.spacing(1),
		width : '10%',
		color : 'green'
	}
})
class StoryModal extends React.Component {
	constructor(props) {
		super(props)
		this.state = {
			open : false,
			enabled : true,
			stories : []
		}
	}

	componentDidMount(){
		const { modalOpen, rowId  } = this.props
		Publicationstracking.getStories(rowId).then((json)=>{
			this.setState({
				stories : Object.values(json.data)
			})
		})
		this.setState({ open : modalOpen })
	}

	componentDidUpdate(previousProps, previousState) {
		if (previousProps.modalOpen !== this.props.modalOpen) {
			const { modalOpen } = this.props
			this.setState({ open : modalOpen }) 
		}
	}

	buildPlanningList = (jsonData, onLoadPlanning) => {
		const trackingData = jsonData
		let planningToBeSaved = {
			publicationsplannings : trackingData.publicationsplannings,
			publicationsstats : []
		}
		const users = ContactsTools.getContactsByGroupid(160)
		let distribution = {
			pgm: trackingData.publicationsplannings.filter(x => x.publications !== null && (x.taskTypeId === 14 || x.taskTypeId === 16 || x.taskTypeId === 17 || x.taskTypeId === 27 || x.taskTypeId === 28)),
			ouv: trackingData.publicationsplannings.filter(x => x.publications !== null && (x.taskTypeId === 21 || x.taskTypeId === 22)),
			susp: trackingData.publicationsplannings.filter(x => x.publications !== null && (x.taskTypeId === 11 || x.taskTypeId === 12 || x.taskTypeId === 13 || x.taskTypeId === 24 || x.taskTypeId === 25)),
			chgtprix: trackingData.publicationsplannings.filter(x => x.publications !== null && (x.taskTypeId && PublicationTrackingReferenceData.getTasksTypes().find(r => r.itemid === x.taskTypeId).taskGroupDomainItemId  === 7 )),
			//regulprix: trackingData.publicationsplannings.filter(x => x.publications !== null && (x.taskTypeId >= 31 && x.taskTypeId <= 46)),
			autre: trackingData.publicationsplannings.filter(x => x.publications !== null && ((x.taskTypeId >= 1 && x.taskTypeId <= 3) || x.taskTypeId === null)),

		}

		if (trackingData.publicationsstats !== null && trackingData.publicationsstats.length !== 0) {
			trackingData.publicationsstats.forEach(item => {
				const currentUser = users.find(u => u.id === item.contactId)

				const stat = {
					contactName: currentUser ? currentUser.gsm : '',
					publicationsCount: item.publicationsCount,
					pgm: distribution.pgm !== undefined ? distribution.pgm.filter(x => x.publications.contactId === item.contactId).length : 0,
					ouv: distribution.ouv !== undefined ? distribution.ouv.filter(x => x.publications.contactId === item.contactId).length : 0,
					susp: distribution.susp !== undefined ? distribution.susp.filter(x => x.publications.contactId === item.contactId).length : 0,
					chgtprix: distribution.chgtprix !== undefined ? distribution.chgtprix.filter(x => x.publications.contactId === item.contactId).length : 0,
					//regulprix: distribution.regulprix !== undefined ? distribution.regulprix.filter(x => x.publications.contactId === item.contactId).length : 0,
					autre: distribution.autre !== undefined ? distribution.autre.filter(x => x.publications.contactId === item.contactId).length : 0,
					publicationPercent: Number((item.publicationsCount * 100 / trackingData.publicationsplannings.length).toFixed(1))
				}
				planningToBeSaved.publicationsstats.push(stat)
			})
		}
		onLoadPlanning(planningToBeSaved)
	}

	handleSaveClick = () => {
		const { rowId, yearNumber, weekNumber, onLoadPlanning } = this.props
		Publicationstracking.updateApproval(rowId).then(()=>{
			Publicationstracking.getPlanning(Number.parseInt(`${yearNumber.toString()}${weekNumber.toString()}`)).then((json)=>{
				this.buildPlanningList(json.data, onLoadPlanning)
			})
			this.setState({ open : false })
		})
	}

	getItem =(name, value ) =>{
		const { categorie,tasktype,version,format} = this.props
		var itemLabel  =''
		switch (name) {
			case 'Categorie':
				itemLabel = categorie !== [] && categorie.find(x=>x.itemid === Number.parseInt(value)) ? categorie.find(x=>x.itemid === Number.parseInt(value)).label : value
				break;
			case 'Accès':
				itemLabel = tasktype !== [] && tasktype.find(x=>x.itemid === Number.parseInt(value)) ? tasktype.find(x=>x.itemid === Number.parseInt(value)).label : value

				break;
			case 'Version':
				itemLabel = version !== [] && version.find(x=>x.itemid === Number.parseInt(value)) ? version.find(x=>x.itemid === Number.parseInt(value)).label : value

				break;
			case 'Format':
				itemLabel = format !== [] && format.find(x=>x.itemid === Number.parseInt(value)) ? format.find(x=>x.itemid === Number.parseInt(value)).label : value

					break;
			default: 
			itemLabel = value
				break;
		}
		return itemLabel 
	}

	handleSavePrioClick = () => {
		const { rowId, yearNumber, weekNumber, onLoadPlanning } = this.props
		Publicationstracking.updatePrioItem(rowId).then(()=>{
			Publicationstracking.getPlanning(Number.parseInt(`${yearNumber.toString()}${weekNumber.toString()}`)).then((json)=>{
				this.buildPlanningList(json.data, onLoadPlanning)
			})
			this.setState({ open : false })
		})
	}
	isEmpty = (obj) => {
		for(var key in obj) {
			if(obj.hasOwnProperty(key))
				return false
		}
		return true
	}

	handleDateChange = (updatedDate) => {
		const updatedDateObject = Object.values(updatedDate)
		if(updatedDateObject !== null){
			this.setState(
				{
					isEndDateEnabled : true,
					isIntervalEnabled : false
				}
			)
		}
	}

	handleChange = (event) => {
		this.setState({ interval : event.target.value })
	}
	render(){
		const { onClose,  modalContent, classes, movieTitle, validationType ,categorie,tasktype,version,format} = this.props
		const modalTitle = movieTitle
		const disagreeButtonText = modalContent.disagreeButtonText
		const agreeButtonText = modalContent.agreeButtonText
		const rows = this.state.stories
		if(validationType === 'update'){
			const stories = rows.filter(r => r.columnName !== 'Approbation suppression')
  			return (
				<div>
					<Dialog
						open={this.state.open}
						TransitionComponent={Transition}
						keepMounted
						onClose={onClose}
						aria-labelledby="alert-dialog-slide-title"
						aria-describedby="alert-dialog-slide-description"
						className = {classes.modalPaper}
					>
						<div className={classes.dialogHeader}>
							{modalTitle}
						</div>
						<div className = {classes.dialogGlobal}>
							<div className={classes.dialogContent}>	
								<Paper>
									<Table className={classes.table} aria-label="simple table">
										<TableHead>
											<TableRow>
												<TableCell>Colonne modifiée</TableCell>
												<TableCell align="right">Ancienne valeur</TableCell>
												<TableCell align="right">Nouvelle valeur</TableCell>
												<TableCell align="right">Date modification</TableCell>
											</TableRow>
										</TableHead>
										<TableBody>
											{stories.map((row) => (
												<TableRow key={row.columnName}>
													<TableCell component="th" scope="row">
														{row.columnName}
													</TableCell>
													<TableCell  > 
														{this.getItem(row.columnName,row.oldValue)}
														</TableCell>
													<TableCell >
														{this.getItem(row.columnName,row.newValue)}
													</TableCell>
													<TableCell align="right">{DateFormatter.getDayNameDate(row.actionOn)}</TableCell>
												</TableRow>
											))}
										</TableBody>
									</Table>
								</Paper>
							</div>
							<div className={classes.dialogFooter}>
								<Button onClick={this.handleSaveClick} color="primary" variant="contained" className={classes.buttonCheck} disabled = {!this.state.open}>
									<CheckIcon className={classes.leftIcon} />
									{agreeButtonText}
								</Button>
								<Button onClick={onClose} color="secondary" variant="contained" className={classes.buttonCancel}>
									<CancelIcon className={classes.leftIcon} />
									{disagreeButtonText}
								</Button>
							</div>
						</div>
					</Dialog>
				</div>
			)
		}
		else if (validationType === 'delete'){
			const row = rows.find(r => r.actionOn !== null)
			const deleteinformation = {
				deleteDate : row ? row.actionOn : '',
				deleteResponsible : row ? row.actionBy : ''
			}
			return (
				<div>
					<Dialog
						open={this.state.open}
						TransitionComponent={Transition}
						keepMounted
						onClose={onClose}
						aria-labelledby="alert-dialog-slide-title"
						aria-describedby="alert-dialog-slide-description"
						className = {classes.modalPaper}
					>
						<div className={classes.dialogHeader}>
							{modalTitle}
						</div>
						<div className = {classes.dialogGlobal}>
							<div className={classes.dialogContent}>	
								<Paper>
									<Table className={classes.table} aria-label="simple table">
										<TableHead>
											<TableRow>
												<TableCell>Action</TableCell>
												<TableCell align="right">Date</TableCell>
											</TableRow>
										</TableHead>
										<TableBody>
											<TableRow key='1'>
												<TableCell component="th" scope="row">
													Suppression
												</TableCell>
												<TableCell align="right">{DateFormatter.getDayNameDate(deleteinformation.deleteDate)}</TableCell>
											</TableRow>
										</TableBody>
									</Table>
								</Paper>
							</div>
							<div className={classes.dialogFooter}>
								<Button onClick={this.handleSaveClick} color="primary" variant="contained" className={classes.buttonCheck} disabled = {!this.state.open}>
									<CheckIcon className={classes.leftIcon} />
									{agreeButtonText}
								</Button>
								<Button onClick={onClose} color="secondary" variant="contained" className={classes.buttonCancel}>
									<CancelIcon className={classes.leftIcon} />
									{disagreeButtonText}
								</Button>
							</div>
						</div>
					</Dialog>
				</div>
			)
		}else if (validationType === 'prioUpdate'){
			// const row = rows.find(r => r.actionOn !== null)
			// const deleteinformation = {
			// 	deleteDate : row ? row.actionOn : '',
			// 	deleteResponsible : row ? row.actionBy : ''
			// }
			return (
				<div>
					<Dialog
						open={this.state.open}
						TransitionComponent={Transition}
						keepMounted
						onClose={onClose}
						aria-labelledby="alert-dialog-slide-title"
						aria-describedby="alert-dialog-slide-description"
						className = {classes.modalPaper}
					>
						<div className={classes.dialogHeader}>
							Contenu Référent
						<Button onClick={onClose}  className={classes.closeIcon}  >
							<CloseIcon/>				 
						</Button>
						</div>
						<div className = {classes.dialogGlobal}>
							<div className={classes.dialogContent}>	
							{modalTitle}
							</div>
							<div className={classes.dialogFooter}>
								<Button onClick={this.handleSavePrioClick} color="primary" variant="contained" className={classes.buttonCheck} disabled = {!this.state.open}>
									<CheckIcon className={classes.leftIcon} />
									{agreeButtonText}
								</Button>
								<Button onClick={onClose} color="secondary" variant="contained" className={classes.buttonCancel}>
									<CancelIcon className={classes.leftIcon} />
									{disagreeButtonText}
								</Button>
							</div>
						</div>
					</Dialog>
				</div>
			)
		}
	}
}

StoryModal.propTypes = {
	modalOpen: PropTypes.bool,
	onClose: PropTypes.func,
	modalContent: PropTypes.object,
	launchActivationProcess: PropTypes.func,
	rowId: PropTypes.number,
	crudGetList : PropTypes.func,
	yearNumber : PropTypes.number,
	weekNumber : PropTypes.number
}

const mapStateToProps = state => ({
	...state,
})

const mapDispatchToProps = ({
	onLoadPlanning
})

const enhance = compose(
	connect(mapStateToProps, mapDispatchToProps),
	withStyles(styles)
)

export default translate(enhance(StoryModal))
