import React from 'react'
import PropTypes from 'prop-types'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import LinkListItem from './LinkListItem'
import { withStyles } from '@material-ui/core/styles'

const styles = ({
	tertiary: { float: 'right', opacity: 0.541176 },
})

const SimpleList = ({
	basePath,
	classes = {},
	className,
	data,
	ids,
	xml = false,
	children,
	...rest
}) => {
	return (
		<List className={className}>
			{ids.map((id, index) =>
				<span key={data[id].id}>
					{xml !== true ? (
						<LinkListItem key={data[id].id} id={data[id].id}>
							<ListItem button>
								{React.Children.map(
									children,
									field =>
										React.cloneElement(field, {
											key: id,
											record: data[id],
											basePath,
										})
								)}
							</ListItem>
						</LinkListItem>
					) : (
						React.Children.map(
							children,
							field =>
								React.cloneElement(
									field, {
										key: id,
										record: data[id],
										basePath,
									})
						)
					)}
				</span>
			)}
		</List>
	)
}

SimpleList.propTypes = {
	basePath: PropTypes.string,
	classes: PropTypes.object.isRequired,
	className: PropTypes.string,
	data: PropTypes.object,
	ids: PropTypes.array,
	// xml: PropTypes.object,
	// children: PropTypes.object
}

export default withStyles(styles)(SimpleList)