import React, { useState, useEffect } from 'react';
import compose from 'recompose/compose'
import { translate } from 'react-admin'
import { Prompt } from 'react-router-dom'
import { createTheme, ThemeProvider, withStyles } from '@material-ui/core/styles'

import { List, ListItem, ListItemText, ListItemIcon, Collapse, InputAdornment, Button, Switch, FormControl, FormControlLabel, OutlinedInput, IconButton, Snackbar, Slide, Dialog, DialogContent, DialogContentText, DialogActions, CircularProgress } from '@material-ui/core';
import {Alert} from '@material-ui/lab';
import { green, blue, red, cyan } from '@material-ui/core/colors';
import { AttachMoney, ExpandLess, ExpandMore, Euro, Apple, Android, Description, WatchLater, Lock, LockOpen, Add, Edit, Search, Cancel, Check, Close } from '@material-ui/icons/';
import { CustomTextField, CustomSelect, CustomDisplayDialog, CustomAlert } from '../layout/GlobalStyles';

import PricingscodesRequests from '../../requests/Pricingscodes';
import DomainsItems from '../../requests/DomainsItems';
import { Contacts, DateFormatter } from '../../tools'
import SidescrollList from '../dashboard/SidescrollList'

const getMuiTheme = () => createTheme({
	overrides: {
		MuiPaper: {
			root: {
				backgroundColor: '#FFF',
			}
		},
		MuiCardContent: {
			root: {
				'&:first-child': {
					paddingLeft: 0,
					paddingRight: 0,
				},
			}
		},
	}
})

const styles = (theme) => ({
	root: {
		width: '100%',
		'& > * + *': {
		  marginTop: theme.spacing(2),
		},
	  },
	contentContainer: {
		height:'100%',
		width:'100%',
	},
	listContainer: {
		float: 'left',
		minWidth: '12vw',
		width: '15vw',
		minHeight: '20vh',
		maxHeight: '89vh',
		overflow: 'auto',
		borderRadius: 5,
		marginRight: 30,
	},
	detailsContainer: {
		position:'relative',
		overflow:'hidden',
		boxSizing: 'border-box',
		// height:'89vh',
		maxHeight:'89vh',
		overflow: 'auto',
		width: 'auto',
		backgroundColor: "white",
		borderTopRightRadius: 5,
		borderBottomRightRadius: 5,
	},
	detailsGeneral: {
		float: 'left',
		marginLeft: 50,
		marginBottom: 30,
		width: '55%',
	},
	detailsManagement: {
		float: 'right',
		marginRight: 50,
		width: '25%'
	},
	titleContainer: {
		fontSize: 20,
		color: 'black',
		height: "5%",
		display : 'flex',
  		alignItems : 'center',
		verticalAlign:"text-bottom"
	},
	buttonsContainer: {
		float: 'right',
		height: '5%',
		width: '25%',
		textAlign: 'right',
	},
	list: {
		width: '100%',
		backgroundColor: "white",
		paddingTop: 0,
		paddingBottom: 0,
	},
	listSearchbar: {
		marginTop:'10px',
		marginLeft:'10px',
		width: '92%',
	},
	nested: {
		paddingLeft: theme.spacing(4),
	},
	textFields: {
		marginBottom: '20px',
		// width: '48%',
		flexGrow: 1,
		'&:nth-child(1)':{
			marginRight: '10px',
		}
	},
	switch: {
		display: "block",
		color: "black",
	},
	dialogHeader:{
        padding: '10px 10px 8px',
        background: cyan[500],
        color: 'white',
        fontSize: 'medium',
        fontWeight: 'bold',
        textAlign: 'center',
    },
	closeIcon:{
        width : '15px',
        position: 'absolute',
        top: '0px',
        right: '0px',
        borderRadius: '0',
        color: 'white',
        '&:hover': {
            background: 'red'
        }
    },
	buttonCancel: {
		width : '150px',
		background: '#e23434',
		margin: theme.spacing(1),
	},
	leftIcon: {
		marginRight: theme.spacing(1),
	},
	buttonCheck: {
		background: '#0c9e0c',
		width : '150px',
		margin: theme.spacing(1),
	},
});

const Pricingscodes = (props) => {
	const {classes} = props
	// States declarations
	const [isListLoading, setListLoading] = useState(true);
	const [isListLoaded, setListLoaded] = useState(false);
	const [data, setData] = useState([]);
	const [searchbarValue, setSearchbarValue] = useState('');
	const [prices, setPrices] = useState(null);
	const [selected, setSelected] = useState({
		index: 'none',
		pricingcodeid: '',
		price: '',
		typeid: '',
		code: '',
		comments: '',
		disabled: '',
		disabledon: '',
		disabledby: '',
		createdon: '',
		createdby: '',
		modifiedon: '',
		modifiedby: '',
		recordid: '',
	})
	const [copySelected, setCopySelected] = useState({
		index: 'none',
		pricingcodeid: '',
		price: '',
		typeid: '',
		code: '',
		comments: '',
		disabled: '',
		disabledon: '',
		disabledby: '',
		createdon: '',
		createdby: '',
		modifiedon: '',
		modifiedby: '',
		recordid: '',
	})
	const [checked, setChecked] = useState({
		checkedActive: false,
	});
	const [type, setType] = useState('show');
	const [snackbar, setSnackbar] = useState({
		open: false,
		message: ' ',
		severity: ''
	  });
	const [openModal, setOpenModal] = useState(false)
	const [typeList, setTypeList] = useState([]);
	const [typeLabel, setTypeLabel] = useState("")
	const [textFieldChanged, setTextFieldChanged] = useState(false)
	const [dataChanged, setDataChanged] = useState(false)
	const [modalTitle, setModalTitle] = useState("")
	const [modalText, setModalText] = useState("")
	  

	// Empeche de quitter la page s'il y a eu des modifications
	useEffect(() => {
		if(textFieldChanged){
			window.addEventListener('beforeunload', alertUser, {capture: true})
			return () => {
				window.removeEventListener('beforeunload', alertUser, {capture: true})
			  }
		}else{
			window.removeEventListener('beforeunload', alertUser, {capture: true})
		}
	}, [textFieldChanged])
	const alertUser = e => {
		e.preventDefault()
		return e.returnValue = ''
	}
	  

// Préparation de la fonction de récupération des données dans la bdd et la met les données json dans la state d'objet "data"
	const getData = async () => {
		await PricingscodesRequests.getAll().then((json) => {
				setData(json.data.pricingscodes)
				setListLoading(false)
		})
	}
	const loadData = async (pricingcodeid) => {
		await PricingscodesRequests.LoadPricingscodes(pricingcodeid).then((json) => {
			handleListItemClick(
				null, 
				json.data.pricingscodes[0].pricingcodeid+' '+json.data.pricingscodes[0].typeid, 
				json.data.pricingscodes[0].pricingcodeid, 
				json.data.pricingscodes[0].price.toFixed(2), 
				json.data.pricingscodes[0].typeid, 
				json.data.pricingscodes[0].code, 
				json.data.pricingscodes[0].comments, 
				json.data.pricingscodes[0].disabled, 
				json.data.pricingscodes[0].disabledon, 
				json.data.pricingscodes[0].disabledby, 
				json.data.pricingscodes[0].createdon, 
				json.data.pricingscodes[0].createdby, 
				json.data.pricingscodes[0].modifiedon, 
				json.data.pricingscodes[0].modifiedby, 
				json.data.pricingscodes[0].recordid, 
				'show'
			)
			console.log(json)
			setListLoading(false)
	})
	}
	var selectObject
	const getUpdatedData = async () => {
		await PricingscodesRequests.getAll().then((json) => {
				setData(json.data.pricingscodes)
				setDataChanged(true)
				setListLoading(false)
		})
	}
	const getDomainsItems = async () => {
		setListLoading(true)
		await DomainsItems.getAll(119).then(data => {
			let types = []
			data.data.forEach(data => {
				types.push({
					itemid: data.itemid,
					label: data.label
				})
			})
			setTypeList(types)
			setListLoading(false)
		})
	}
// Préparation de la fonction d'ajout de code et récupère les données à jour
	const AddPricingscodes = async (data) => {
		setListLoading(true)
		if (selected.price.length < 1 || selected.code.length < 1){
			handleSnackbarOpen('Veuillez renseigner les informations nécessaires', 'error')
		}else{
			await PricingscodesRequests.AddPricingscodes(data).then((response) => {
				if (response !== undefined) {
					handleSnackbarOpen('Ajouté avec succès', 'success')
				}
				getUpdatedData(data)
			}).catch((error) => {
				handleSnackbarOpen('Fetch error: ' + error.message + ' status : ' + error.status, 'error')
			})
		}
	}
// Préparation de la fonction d'edit de code et récupère les données à jour
	const UpdatePricingscodes = async (data) => {
		setListLoading(true)
		await PricingscodesRequests.UpdatePricingscodes(data).then((response) => {
			if (response !== undefined) {
				handleSnackbarOpen('Edité avec succès', 'success')
			}
			getUpdatedData(data)
		}).catch((error) => {
			handleSnackbarOpen('Fetch error: ' + error.message + ' status : ' + error.status, 'error')
		})
	}

// Préparation de la fonction de disable de code et récupère les données à jour
	const DisablePricingscodes = async (data) => {
		setListLoading(true)
		await PricingscodesRequests.DisablePricingscodes(data).then((response) => {
			if (response !== undefined) {
				handleSnackbarOpen('Désactivé avec succès', 'success')
			}
			getUpdatedData(data)
		}).catch((error) => {
			handleSnackbarOpen('Fetch error: ' + error.message + ' status : ' + error.status, 'error')
		})
	}

	
// Créé un array avec uniquement les prix unique pour pouvoir avoir une liste de prix sans doublon et attribue la clé disabled pour pouvoir trier 
// les prix qui n'ont que des codes désactivé
	const UpdateUniquePrices = () => { 
		var uniquePricesList = [];
		const uniquePrices = [...new Set(data.map(item => item.price))];
			uniquePrices.forEach( number => {
				const priceObj = {price: number, disabled: 1, listCollapse: false};
				uniquePricesList.push(priceObj);
			});
		uniquePricesList.sort(
			(p1, p2) => (p1.price > p2.price) ? 1 : (p1.price < p2.price) ? -1 : 0);
		setPrices(uniquePricesList)
	}


// Lancement de la fonction à la création du component", []" -> éxecute qu'une fois
	useEffect( () => {
		getData()
		getDomainsItems()
	}, [])
// Ajout d'une ligne listCollapse dans chaque objet afin d'ouvrir ou fermer la liste, ", [isListLoading]" -> lance le useEffect a chaque modification de isListLoading
	useEffect(() => {
		if(isListLoading === false){
			setListLoaded(true)
			if(checked.checkedActive){
				setChecked({ ...checked, checkedActive: true });
				updatedHandleActiveFilter()
			}else{
				if(dataChanged){
					selectObject = data.find((obj) => obj.price.toFixed(2) === Number(selected.price).toFixed(2) && obj.typeid.toString() === selected.typeid.toString())
					data.map(data=>{
						if(data.price===selected.price && data.typeid===selected.typeid){
							loadData(data.pricingcodeid)
						}
					})
					const newState = prices.map(obj => {
						if (obj.price === selectObject.price) {
						return {...obj, listCollapse: true};
						}
						return obj;
					});
						setPrices(newState)
				}
			}
			
		}
	},[isListLoading, dataChanged])

	useEffect(() => {
		if(isListLoaded){
			UpdateUniquePrices()
		}
	}, [isListLoaded])

	useEffect(() => {
		if(data !== undefined && data !== null){
			UpdateUniquePrices()
		}
		data.map((data)=>{
			if(data.price==selected.price && data.typeid==selected.typeid){
				loadData(data.pricingcodeid)
			}
		})
	}, [data])

// Fonction searchbar
	const handleSearchbar = (event) => {
		setSearchbarValue(event.target.value);
		if(searchbarValue===undefined || searchbarValue.length===0){
		}
	  };
	const handleMouseDownSearchButton = (event) => {
		event.preventDefault();
	};
// Fonction bouton switch
	const handleSwitch = (event) => {
		setChecked({ ...checked, [event.target.name]: event.target.checked });
	};

// Gestion d'ouverture/fermeture des éléments de la liste
	const handleCollapseClick = (price) => {
		const newState = prices.map(obj => {
			if (obj.price === price) {
			  return {...obj, listCollapse: !obj.listCollapse};
			}
			return obj;
		});
			setPrices(newState)
	};

// Met les informations de l'objet sélectionné dans des states
	const handleListItemClick = (event, index, pricingcodeid, price, typeid, code, comments, disabled, disabledon, disabledby, createdon, createdby, modifiedon, modifiedby, recordid, type) => {
		if(textFieldChanged===true){
			if(window.confirm("Les modifications que vous avez apportées ne seront pas enregistrées")){
				setType(type)
				setSelected({ ...selected, 
					index: index,
					pricingcodeid: pricingcodeid,
					price: price,
					typeid: typeid,
					code: code,
					comments: comments,
					disabled: disabled,
					disabledon: disabledon,
					disabledby: disabledby,
					createdon: createdon,
					createdby: createdby,
					modifiedon: modifiedon,
					modifiedby: modifiedby,
					recordid: recordid,
				})

				//Affiche le textfield typeid sous forme de nom(id)
				typeList.map((value) => {
					if(value.itemid === typeid){
						setTypeLabel(value.label+" ("+value.itemid+")")
					}
				})
			}
		}else{
			setType(type)
			setSelected({ ...selected, 
				index: index,
				pricingcodeid: pricingcodeid,
				price: price,
				typeid: typeid,
				code: code,
				comments: comments,
				disabled: disabled,
				disabledon: disabledon,
				disabledby: disabledby,
				createdon: createdon,
				createdby: createdby,
				modifiedon: modifiedon,
				modifiedby: modifiedby,
				recordid: recordid,
			})
			console.log(selected)
			//Affiche le textfield typeid sous forme de nom(id)
			typeList.map((value) => {
				if(value.itemid === typeid){
					setTypeLabel(value.label+" ("+value.itemid+")")
				}
			})
		}
	};
	const copyHandleListItemClick = (event, index, pricingcodeid, price, typeid, code, comments, disabled, disabledon, disabledby, createdon, createdby, modifiedon, modifiedby, recordid, type) => {
		if(textFieldChanged===true){
			if(window.confirm("Les modifications que vous avez apportées ne seront pas enregistrées")){		
				setType(type)
				setSelected({ ...selected, 
					index: index,
					pricingcodeid: pricingcodeid,
					price: price,
					typeid: typeid,
					code: code,
					comments: comments,
					disabled: disabled,
					disabledon: disabledon,
					disabledby: disabledby,
					createdon: createdon,
					createdby: createdby,
					modifiedon: modifiedon,
					modifiedby: modifiedby,
					recordid: recordid,
				})

				//Affiche le textfield typeid sous forme de nom(id)
				typeList.map((value) => {
					if(value.itemid === typeid){
						setTypeLabel(value.label+" ("+value.itemid+")")
					}
				})
			}
		}else{
			setType(type)
			setSelected({ ...selected, 
				index: index,
				pricingcodeid: pricingcodeid,
				price: price,
				typeid: typeid,
				code: code,
				comments: comments,
				disabled: disabled,
				disabledon: disabledon,
				disabledby: disabledby,
				createdon: createdon,
				createdby: createdby,
				modifiedon: modifiedon,
				modifiedby: modifiedby,
				recordid: recordid,
			})

			//Affiche le textfield typeid sous forme de nom(id)
			typeList.map((value) => {
				if(value.itemid === typeid){
					setTypeLabel(value.label+" ("+value.itemid+")")
				}
			})
		}
	};
	const handleCopySelected = (event, index, pricingcodeid, price, typeid, code, comments, disabled, disabledon, disabledby, createdon, createdby, modifiedon, modifiedby, recordid) => {
		setCopySelected({ ...copySelected, 
			index: index,
			pricingcodeid: pricingcodeid,
			price: price,
			typeid: typeid,
			code: code,
			comments: comments,
			disabled: disabled,
			disabledon: disabledon,
			disabledby: disabledby,
			createdon: createdon,
			createdby: createdby,
			modifiedon: modifiedon,
			modifiedby: modifiedby,
			recordid: recordid
		})
};


// Affichage des champs et du logo Actif/Désactivé
	var renderDisabledTextfields
	var renderDisabledLogo
	if(selected.disabled == '1'){
		renderDisabledTextfields=
		<>
		{type==='show' || type==='edit'?
			<div style={{display: "flex"}}>
				<CustomTextField 
					label="Désactivé par" 
					value={Contacts.getFormattedLabel(selected.disabledby)}
					fullWidth="true"
					disabled={type==='edit' && selected.disabled=='1' ? true : false}
					style={{flexGrow: 1}}
					onFocus={event => {
						event.target.select();
					}}
				/>
				<CustomTextField 
					label="Désactivé le" 
					value={DateFormatter.getDayNameDateHourMinSec(selected.disabledon)}
					fullWidth="true"
					disabled={type==='edit' && selected.disabled=='1' ? true : false}
					style={{flexGrow: 1}}
					onFocus={event => {
						event.target.select();
					}}
				/>
			</div>
		:
			false
		}
		</>;

		renderDisabledLogo=
		<div style={{marginLeft:10, marginRight:10, color: "red", fontSize: 20, alignSelf:'center', width:150}}>
			{type==='show' && selected.disabled=='0'? 
				<IconButton
					onClick={()=>{type==='show'?handleOpenModal('Alert', <><p>Êtes-vous sûr(e) de vouloir désactiver ce code ?</p><p>Après validation, vous ne pourrez plus le réactiver</p></>):handleOpenModal('ERROR', 'Veuillez valider ou annuler les modifications avant de procéder à la désactivation du code')}}
					onMouseDown={handleMouseDownSearchButton}
					size="small"
					style={{verticalAlign:"middle"}}
				>
					<Lock style={{fontSize: 30,verticalAlign: "middle", color:"red"}}/>
				</IconButton>
			:
				<IconButton
				onMouseDown={handleMouseDownSearchButton}
				size="small"
				style={{verticalAlign:"middle"}}
				>
					<Lock style={{fontSize: 30,verticalAlign: "middle", color:"red"}}/>
				</IconButton>
			}
			<span style={{verticalAlign: "middle", marginLeft:10}}>Désactivé</span>
		</div>
	}else if(selected.disabled == "0"){
		renderDisabledTextfields=null
		renderDisabledLogo=
		<div style={{marginLeft:10,  marginRight:10, color: "Black", fontSize: 20, alignSelf:'center', width:150}}>
				<IconButton
					onClick={()=>{type==='show'?handleOpenModal('Alert', <><p>Êtes-vous sûr(e) de vouloir désactiver ce code ?</p><p>Après validation, vous ne pourrez plus le réactiver</p></>):handleOpenModal('ERROR', 'Veuillez valider ou annuler les modifications avant de procéder à la désactivation du code')}}
					onMouseDown={handleMouseDownSearchButton}
					size="small"
					style={{verticalAlign:"middle"}}
				>
					<LockOpen style={{fontSize: 30,verticalAlign: "middle", color:"black"}}/>
				</IconButton>
			<span style={{verticalAlign: "middle", marginLeft:10}}>Actif</span>
		</div>
	}else if(selected.disabled == ""){
		renderDisabledLogo=
		<div style={{marginLeft:10,  marginRight:10, width:150}}></div>
	}

//Affichage du logo du type de code
	const renderTypeLogo = (value) => {
		switch (value){
			case 1: return (
						<>
							<ListItemIcon>
								<Apple />
							</ListItemIcon>
							<ListItemText style={{ color: "black" }} primary={typeList.map((type)=>{if(type.itemid===value){return type.label}})} />
						</>
					)
			case 2: return (
						<>
							<ListItemIcon>
								<Android />
							</ListItemIcon>
							<ListItemText style={{ color: "black" }} primary={typeList.map((type)=>{if(type.itemid===value){return type.label}})} />
						</>
					)
			default:return (
						<>
							<ListItemIcon>
							</ListItemIcon>
							<ListItemText style={{ color: "black" }} primary={typeList.map((type)=>{if(type.itemid===value){return type.label}})} />
						</>
			)
		}
	}

//Affichage de la liste des prix
	var renderListItems;
	var localSeparator;
	if (prices!==null){
		renderListItems=
			prices.map((uniquePrice) => {
				localSeparator=uniquePrice.price.toLocaleString(undefined, {minimumFractionDigits: 2});
				if(searchbarValue===undefined || searchbarValue.length===0 || uniquePrice.price.toFixed(2).toString().includes(searchbarValue)){
					//Filtre uniquement les actifs
					if(checked.checkedActive){
						if(uniquePrice.disabled.toString() === "0"){
							return(
								<>
								<ListItem
								button
								onClick={() => handleCollapseClick(uniquePrice.price)}
								>
									<ListItemIcon>
										<Euro />
									</ListItemIcon>
									<ListItemText style={{color:"black"}} primary={localSeparator}/>
									{uniquePrice.listCollapse ? <ExpandLess style={{ color: "black" }}/> : <ExpandMore style={{ color: "black" }}/>}
								</ListItem>
								
								{data.filter(({ price }) => price === uniquePrice.price).map((data) => {
									if(checked.checkedActive){
										if(data.disabled.toString() === "0"){
											return(
												<Collapse in={uniquePrice.listCollapse} timeout="auto" unmountOnExit>
													<List component="div" disablePadding>
															<ListItem
															button
															className={classes.nested}
															selected={selected.index === data.pricingcodeid+' '+data.typeid}
															onClick={(event) => {
																loadData(data.pricingcodeid)
															}}
															>
																{renderTypeLogo(data.typeid)}
															</ListItem>
													</List>
												</Collapse>
											)
										}
									}
								})}
								</>
							);
						}
					}else{
						//Non filtré
						return(
							<>
							<ListItem
							button
							onClick={() => handleCollapseClick(uniquePrice.price)}
							>
								<ListItemIcon>
									<Euro />
								</ListItemIcon>
								<ListItemText style={{color:"black"}} primary={localSeparator}/>
								{uniquePrice.listCollapse ? <ExpandLess style={{ color: "black" }}/> : <ExpandMore style={{ color: "black" }}/>}
							</ListItem>
							
							{/* Collapse */}
							{data.filter(({ price }) => price === uniquePrice.price).map((data) => {
								return(
									<Collapse in={uniquePrice.listCollapse} timeout="auto" unmountOnExit>
										<List component="div" disablePadding>
												<ListItem
												button
												className={classes.nested}
												selected={selected.index === data.pricingcodeid+' '+data.typeid}
												onClick={(event) => {
													loadData(data.pricingcodeid)
												}}
												>
													{data.disabled=='0' ?
														<>
															{renderTypeLogo(data.typeid)}
														</>
													:	
														<>
															{renderTypeLogo(data.typeid)}
															<Lock style={{color: "red"}} />
														</>
													}
												</ListItem>
										</List>
									</Collapse>
								)
							})}
							</>
						);
					}
				}
			})
	}else{
		renderListItems=<p>Problème d'affichage des prix</p>
	}

	var title
	title=<div className={classes.titleContainer}><AttachMoney style={{marginRight:5}} /> Grille Tarifaire InApp</div>





	const handleChange = (prop) => (event) => {
		setSelected({ ...selected, [prop]: event.target.value });
		setTextFieldChanged(true)
	};

//Change le champ disabled de la liste de prix si nécéssaire lors du clique sur le bouton switch
	const handleActiveFilter = () => {
		if(prices !== null){
			const newState = prices.map(obj => {
				var nestedMapPrice;
				var nestedMapDisabled = 1;
				data.filter(({ price }) => price === obj.price).map((data) => {
					nestedMapPrice = data.price
					if(data.disabled===0){
						nestedMapDisabled = 0
					}
				})
				if(nestedMapDisabled === 0 && obj.price === nestedMapPrice){
					return {...obj, disabled: 0};
				}
				return obj
			})
			newState.sort(
				(p1, p2) => (p1.price > p2.price) ? 1 : (p1.price < p2.price) ? -1 : 0);
			setPrices(newState)
		}
	}

	const updatedHandleActiveFilter = () => {
		if(prices !== null){
			selectObject = data.find((obj) => obj.price.toFixed(2) === Number(selected.price).toFixed(2) && obj.typeid.toString() === selected.typeid.toString())
			handleListItemClick(null, selectObject.pricingcodeid+' '+selectObject.typeid, selectObject.pricingcodeid, selectObject.price.toFixed(2), selectObject.typeid, selectObject.code, selectObject.comments, selectObject.disabled, selectObject.disabledon, selectObject.disabledby, selectObject.createdon, selectObject.createdby, selectObject.modifiedon, selectObject.modifiedby, selectObject.recordid, 'show')
			const newState = prices.map(obj => {
				var nestedMapPrice;
				var nestedMapDisabled = 1;
				data.filter(({ price }) => price === obj.price).map((data) => {
					nestedMapPrice = data.price
					if(data.disabled===0){
						nestedMapDisabled = 0
					}
				})
				if(nestedMapDisabled === 0 && obj.price === nestedMapPrice && obj.price.toString() === selectObject.price.toString()){
					return {...obj, disabled: 0, listCollapse: true};
				}else if(nestedMapDisabled === 0 && obj.price === nestedMapPrice){
					return {...obj, disabled: 0};
				}
				return obj
			})
			newState.sort(
				(p1, p2) => (p1.price > p2.price) ? 1 : (p1.price < p2.price) ? -1 : 0);
			setPrices(newState)
		}
	}

	const AddButton = withStyles((theme) => ({
		root: {
		  color: 'white',
		  backgroundColor: green[600],
		  '&:hover': {
			backgroundColor: green[700],
		  },
		},
	  }))(Button);
	
	const EditButton = withStyles((theme) => ({
	root: {
		color: 'white',
		backgroundColor: blue[700],
		'&:hover': {
		backgroundColor: blue[800],
		},
	},
	}))(Button);

	const CancelButton = withStyles((theme) => ({
	root: {
		color: 'white',
		backgroundColor: red[600],
		'&:hover': {
		backgroundColor: red[700],
		},
	},
	}))(Button);
	  
//Gestion de la snackbar
	const handleSnackbarOpen = (message, severity) => {
		setSnackbar({ ...snackbar, open: true, message: message, severity: severity});
	  };
	const handleSnackbarClose = () => {
	setSnackbar({ ...snackbar, open: false });
	};
	const { vertical, horizontal, open, message } = snackbar;

//Gestion Modal
	const handleOpenModal= (title, text) => {
		setModalTitle(title)
		setModalText(text)
		setOpenModal(1)
	}
	const handleCloseModal = () => {
		setOpenModal(0)
	}
	const handleChangeTypeValue = (event) => {
		setSelected({ ...selected, typeid: event.target.value });
	}

//Do something when type changes
	useEffect(() => {
		//Met la selection de typeid sur 1 par défaut lorsqu'on entre en create
		if(type==='create'){
			setSelected({ ...selected, typeid: "1"})
		}
		setTextFieldChanged(false)
	}, [type]);	

















	// if(isListLoading || !isListLoaded){
	// 	return <div></div>
	// }
	return (
		<ThemeProvider theme={getMuiTheme()}>
		<div className={classes.contentContainer}>
		<div className={classes.listContainer}>
			<List 
				className={classes.list} 
				component="nav" 
				aria-label="list" 
				subheader={
					<>
						<OutlinedInput
							className={classes.listSearchbar}
							value={searchbarValue}
							onChange={handleSearchbar}
							endAdornment={<InputAdornment position="end">
								<IconButton
									onClick={()=>{handleSnackbarOpen(searchbarValue, 'success')}}
									onMouseDown={handleMouseDownSearchButton}
									edge="end"
								>
									<Search/>
								</IconButton>
							</InputAdornment>}
						/>
						<FormControl component="fieldset"  className={classes.switch}>
								<FormControlLabel
								value="filter"
								control={<Switch checked={checked.checkedActive} onChange={handleSwitch} color="primary" name="checkedActive" size="normal" style={{display: "inline-block", width:20, height:20}}/>}
								label="N'afficher que les actifs"
								labelPlacement="start"
								onClick={() => handleActiveFilter()}
								/>
						</FormControl>
					</>
				}
			>
				{!isListLoading || isListLoaded ? renderListItems : <div style={{textAlign: 'center'}}><CircularProgress /></div>}
			</List>
		</div>
		<div className={classes.detailsContainer}>
			<div style={{width:"98%", position:'relative', marginBottom:30, padding:15, display:'flex', borderBottom: '3px solid #288DB1', alignItems : 'center',justifyContent: 'space-between'}}>
				{title}
				<div className={classes.buttonsContainer}>
					{type==='show'?
					<>
						<AddButton
							variant="contained"
							color="primary"
							className={classes.button}
							startIcon={<Add />}
							style={{marginRight:10, width:"125px"}}
							onClick={(event) => {
								setType('create');
								handleCopySelected(event, selected.index, selected.pricingcodeid, selected.price, selected.typeid, selected.code, selected.comments, selected.disabled, selected.disabledon, selected.disabledby, selected.createdon, selected.createdby, selected.modifiedon, selected.modifiedby, selected.recordid)
								handleListItemClick(event, '', '', '', '', '', '', '', '', '', '', '', '', '', '', 'create')
							}}
						>
							AJOUTER
						</AddButton>
						{selected.pricingcodeid!==''?
						<EditButton
							variant="contained"
							color="primary"
							className={classes.button}
							startIcon={<Edit />}
							style={{width:"125px"}}
							onClick={(event) => {
								setType('edit');
								handleCopySelected(event, selected.index, selected.pricingcodeid, selected.price, selected.typeid, selected.code, selected.comments, selected.disabled, selected.disabledon, selected.disabledby, selected.createdon, selected.createdby, selected.modifiedon, selected.modifiedby, selected.recordid)
							}}
						>
						EDITER
						</EditButton>:false}
					</>
					:
					<>
					<AddButton
						variant="contained"
						color="primary"
						className={classes.button}
						startIcon={<Check />}
						style={{marginRight:10, width:"125px"}}
						onClick={() => {
							{
								var errorTextList=[]
								if(selected.price===null || selected.price.length<1){
									errorTextList.push(<li>Veuillez renseigner le prix</li>)
								}
								if(selected.code===null || selected.code.length<1){
									errorTextList.push(<li>Veuillez renseigner le code</li>)
								}
								if(selected.code===null || selected.price===null || selected.price.length<1 || selected.code.length<1){
									handleOpenModal('ERROR', <ul>{errorTextList}</ul>)
								}else{
									setType('show')
									if(type==='create'){
										AddPricingscodes({
											price: selected.price,
											typeid: selected.typeid,
											code: selected.code,
											comments: selected.comments,
											disabled: 0,
										});
									}else if(type==='edit'){
										UpdatePricingscodes({
											pricingcodeid: selected.pricingcodeid,
											price: selected.price,
											typeid: selected.typeid,
											code: selected.code,
											comments: selected.comments,
											disabled: Number(selected.disabled),
											disabledon: selected.disabledon,
											disabledby: 0,
											modifiedby: 0,
											recordid:selected.recordid,
										});
									}
								}
							}
							
						}}
					>
						VALIDER
					</AddButton>
					<CancelButton
						variant="contained"
						color="primary"
						className={classes.button}
						startIcon={<Cancel color="white" />}
						style={{width:"125px"}}
						onClick={ event => {copyHandleListItemClick(
												event, 
												copySelected.index, 
												copySelected.pricingcodeid, 
												copySelected.price, 
												copySelected.typeid, 
												copySelected.code, 
												copySelected.comments,
												copySelected.disabled, 
												copySelected.disabledon, 
												copySelected.disabledby, 
												copySelected.createdon, 
												copySelected.createdby, 
												copySelected.modifiedon, 
												copySelected.modifiedby, 
												copySelected.recordid, 
												'show')
											} 
									} 
					>
						ANNULER
					</CancelButton>
					</>
					}
				</div>
			</div>
			<div className={classes.detailsGeneral}>
				<div style={{color:'black', fontSize: 20, marginBottom:30, display: "flex", alignItems: "center"}}>
					<Description style={{fontSize: 30,verticalAlign: "middle", marginRight:10}}/><span>Général</span>
				</div>
				<div style={{display:"flex"}}>
					<CustomTextField 
						label="Identifiant"
						value={selected.pricingcodeid}
						{...(type==='edit' || type==='create' ? { inputProps:{readOnly:false}, disabled:true } : { inputProps:{readOnly:true}, disabled:false})}
						disabled={type==='edit' || type==='create' ? true : false}
						fullWidth="true"
						style={{flexGrow: 1, }}
						{...(type==='show' ? { onFocus : event => {event.target.select()} }  : {})}
						onChange={handleChange('pricingcodeid')}
					/>

					<CustomTextField 
						label="Prix" 
						type="number"
						value={selected.price}
						{...(type==='edit' || type==='create' ? { inputProps:{readOnly:false} } : { inputProps:{readOnly:true} })}
						disabled={type==='edit' && selected.disabled=='1' ? true : false}
						fullWidth="true"
						textAdornment={<Euro style={{color:'grey'}} />}
						style={{flexGrow: 1}}
						{...(type==='show' ? { onFocus : event => {event.target.select()} }  : {})}
						onChange={handleChange('price')}
					/>
						{renderDisabledLogo}
				</div>
				<div style={{display: "flex"}}>
					{type==='show'?
					<CustomTextField 
					label="Type de code" 
					value={typeLabel}
					inputProps={{readOnly:true}}
					disabled={type==='edit' && selected.disabled=='1' ? true : false}
					style={{width:'200px'}}
					{...(type==='show' ? { onFocus : event => {event.target.select()} }  : {})}
					onChange={handleChange('typeid')}
					/>
					:
					<CustomSelect 
						label="Type de code" 
						propsType="domainItem" 
						value={selected.typeid} 
						values={typeList} 
						disabled={type==='edit' && selected.disabled=='1' ? true : false}
						style={{width:'200px'}}
						handleChange={handleChangeTypeValue} 
						fullWidth="true" />
					}
					<CustomTextField 
						label="Code" 
						value={selected.code}
						{...(type==='edit' || type==='create' ? { inputProps:{readOnly:false} } : { inputProps:{readOnly:true} })}
						disabled={type==='edit' && selected.disabled=='1' ? true : false}
						fullWidth="true"
						style={{flexGrow: 1}}
						{...(type==='show' ? { onFocus : event => {event.target.select()}}  : {})}
						onChange={handleChange('code')}
					/>
				</div>
				<div style={{display: "flex"}}>
					<CustomTextField 
						label="Commentaire" 
						value={selected.comments}
						{...(type==='edit' || type==='create' ? { inputProps:{readOnly:false} } : { inputProps:{readOnly:true} })}
						disabled={type==='edit' && selected.disabled=='1' ? true : false}
						fullWidth="true"
						style={{flexGrow: 1}}
						{...(type==='show' ? { onFocus : event => {event.target.select()}}  : {})}
						onChange={handleChange('comments')}
					/>
				</div>
				{renderDisabledTextfields}
			</div>


			<div className={classes.detailsManagement}>
				<div style={{color:'black', fontSize: 20, marginBottom:30, display: "flex", alignItems: "center"}}>
					<WatchLater style={{fontSize: 30, verticalAlign: "middle", marginRight:15}}/><span>Gestion</span>
				</div>	
				<CustomTextField 
						label="Créé par" 
						value={Contacts.getFormattedLabel(selected.createdby)}
						fullWidth="true"
						onFocus={event => {
							event.target.select();
						}}
				/>
				<CustomTextField 
						label="Créé le" 
						value={DateFormatter.getDayNameDateHourMinSec(selected.createdon)} 
						fullWidth="true"
						onFocus={event => {
							event.target.select();
						}}
				/>
				<CustomTextField 
						label="Modifié par" 
						value={Contacts.getFormattedLabel(selected.modifiedby)}
						fullWidth="true"
						onFocus={event => {
							event.target.select();
						}}
				/>
				<CustomTextField 
						label="Modifié le" 
						value={DateFormatter.getDayNameDateHourMinSec(selected.modifiedon)}
						fullWidth="true"
						onFocus={event => {
							event.target.select();
						}}
				/>
			</div>
		</div>
		</div>

		<CustomAlert libelle={snackbar.message} severity={snackbar.severity} open={open} setOpen={handleSnackbarClose} />

		<CustomDisplayDialog 
			open={openModal} 
			handleClose={handleCloseModal} 
			info={modalTitle} 
			content={modalText} 
			type='window' 
			handleSave={()=>{
				setSelected({...selected, 
					disabled : !selected.disabled})
				handleCloseModal()
				// UpdatePricingscodes({
				// 	pricingcodeid: selected.pricingcodeid,
				// 	price: selected.price,
				// 	typeid: selected.typeid,
				// 	code: selected.code,
				// 	comments: "CANAL VOD "+selected.price+"€",
				// 	disabled: 1,
				// 	disabledon: selected.disabledon,
				// 	disabledby: 0,
				// 	modifiedby: 0,
				// 	recordid:selected.recordid,
				// });
				DisablePricingscodes({
					pricingcodeid: selected.pricingcodeid,
					disabledby: 0,
					recordid:selected.recordid,
				});
			}}
		/>
		<Prompt
			when={textFieldChanged}
			message="Les modifications que vous avez apportées ne seront pas enregistrées"
		/>
		</ThemeProvider>
	);
			
}
const enhance = compose(
	// connect(mapStateToProps, mapDispatchToProps),
	withStyles(styles)
)

export default translate(enhance(Pricingscodes))