
import React, { Component } from 'react'
import FormControl from '@material-ui/core/FormControl'
import { onLoadPlanning } from '../../../actions/publicationTracking'
import { connect } from 'react-redux'
import compose from 'recompose/compose'
import { Publicationstracking } from '../../../requests'
import { Logger } from '../../../tools'
import Tooltip from '@material-ui/core/Tooltip'
import { withStyles } from '@material-ui/core/styles'
import TextField from '@material-ui/core/TextField'

const styles = theme =>({
	formControl: {
		margin: theme.spacing(1),
		width: '200px',
	},
	inputstyle:{
		font: 'menu'

	}

})

class StatusCell extends Component {
	constructor(props) {
		super(props)
		this.state = {
			open: false,
			commentvalue:''
		}
	}
	componentDidMount=()=>{
		const {  commentvalue } = this.props

		this.setState({commentvalue : commentvalue})
	}
	componentDidUpdate(previousProps, previousState){

		const {  commentvalue } = this.props

		if(this.props !== previousProps){
			this.setState({commentvalue: commentvalue})
		}
	}

	handleChange = (event) => {
		// if (event.keyCode === 13 || event.keyCode ===9) {
		//     console.log('ok')
		// }
		// else {
		// 	console.log(event.keyCode)
		// }

		const { planningId,   statusName, } = this.props
		this.setState({commentvalue: event.target.value})
		let publication = {
			PlanningId: planningId,
			CommentLevelId: 1,
			...(statusName === 'comments' && { comments: event.target.value }),
		}
		Publicationstracking.savePublication(publication).then(
			(json) => {
			}
		).catch((error) => {
			Logger.debug(error)
		})
	}
	handleClose = () => {
		this.setState({ open: false })
	};

	handleOpen = () => {
		this.setState({ open: true })
	};
	render() {
		const { classes, commentvalue } = this.props
		return (
			<React.Fragment>
				<FormControl className={classes.formControl}>
					<Tooltip title={commentvalue} style={{marginTop: '-10px'}}>
						  <TextField id="standard-basic" 
 							value={this.state.commentvalue} onChange={event => this.handleChange(event)} 
							defaultValue={this.state.commentvalue}
							InputProps={{className:classes.inputstyle  }}/>

					</Tooltip>
				</FormControl>
			</React.Fragment>
		)
	}
}

const mapStateToProps = state => ({
	...state,
	weekNumber: state.reducer.publicationTracking.weekNumber !== null ? state.reducer.publicationTracking.weekNumber : 16,
	yearNumber: state.reducer.publicationTracking.yearNumber !== null ? state.reducer.publicationTracking.yearNumber : 2020,
})

const mapDispatchToProps = ({
	onLoadPlanning
})

const enhance = compose(
	connect(mapStateToProps, mapDispatchToProps),
	withStyles(styles)
)

export default enhance(StatusCell)