import React, { Component } from 'react'
import PropTypes from 'prop-types'
import get from 'lodash/get'
import Checkbox from '@material-ui/core/Checkbox'
import compose from 'recompose/compose'
import { withStyles } from '@material-ui/core/styles'

const styles = theme =>   ({
	root: {
	},
	label: {
		transform: 'translate(0, 5px) scale(0.75)',
		transformOrigin: `top ${theme.direction === 'ltr' ? 'left' : 'right'}`,
	},
	checkbox: {
		justifyContent: 'flex-start'
	}
})

class CheckboxField extends Component {

	renderCheckbox = (value) => {
		const { classes = {} } = this.props
		return (
			<Checkbox color="primary"
				checked={value ? value !== undefined : false}
				disabled
				className={classes.checkbox}
			/>
		)
	}

	render() {
		const { source, record, } = this.props
		return (
			this.renderCheckbox(get(record, source))
		)
	}
}

CheckboxField.propTypes = {
	classes: PropTypes.object.isRequired,
	className: PropTypes.string,
	label: PropTypes.string,
	source: PropTypes.string,
	resource: PropTypes.string,
	record: PropTypes.object
}

CheckboxField.defaultProps = {
	fullWidth: true,
	addLabel: true
}

const enhance = compose(withStyles(styles))
export default enhance(CheckboxField)
