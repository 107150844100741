import React, { Component } from 'react'
import {   translate } from 'react-admin'
import { connect } from 'react-redux'
import compose from 'recompose/compose'
import Button from '@material-ui/core/Button'
import WeekResponsibleModal from './WeekResponsibleModal'
import SettingsIcon from '@material-ui/icons/Settings';
import { withStyles } from '@material-ui/core/styles'

const styles = theme =>  ({
	button: {
		margin: theme.spacing(1),
		marginTop: '0px',
		width : '150px',
		maxHeight: '35px',
		fontSize: '0.75rem',
		top: '5px',
		backgroundColor : '#288DB1',
		lineHeight: '1.2em',
		'&:hover': {
			background: '#1c627b'
		}
	},
	rightIcon:{
		marginLeft: theme.spacing(1),

	}
})

class ParamWeekResponible extends Component{
	constructor(props) {
		super(props)
		this.state = {
			modalOpen : false,
			isProcessLoaderOpen : false,
			isOperationFinished : false,
			resultText : null
		}
	}

	handleImportButtonClick = () => {
		this.setState({
			modalOpen : true
		})
	}

	handleCloseProgressBar = () => {
		this.setState({
			isProcessLoaderOpen: false,
			modalOpen : false
		})
	}

	handleClose = () => {
		this.setState({modalOpen: false})
	}

	render(){
		const { translate, classes} = this.props
		const modalContent = {
			disagreeButtonText : translate('ra.action.close'),
			agreeButtonText: 'Valider'
		}
		return(
			
			<React.Fragment>
				
				<Button onClick={this.handleImportButtonClick} color="primary" variant="contained" className={classes.button}>
					Paramétrer		
					<SettingsIcon className={classes.rightIcon} />
				</Button>
				<WeekResponsibleModal {...this.props}
					modalOpen = {this.state.modalOpen} 
					onClose={this.handleClose} 
					modalContent = {modalContent} 
				/>
			</React.Fragment>
		)
	}
}

const mapStateToProps = state => ({
	...state,
	newProgramContactId : state.reducer.publicationTracking.newProgramContactId
})

const mapDispatchToProps = ({
	// showNotification,
	// onLoadWeeks,
	// onLoadPlanning
})

const enhance = compose(
	connect(mapStateToProps, mapDispatchToProps),
	withStyles(styles)
)


export default translate(enhance(ParamWeekResponible))