import React from 'react'
import { SimpleShowLayout, TextField, translate, FunctionField } from 'react-admin'
import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import Typography from '@material-ui/core/Typography'
import CardChild from '../../CardChild'
import { ContactField, ExtendedTextField, MovieField, AlbumField, MyDateField } from '../common/fields'
import { withStyles } from '@material-ui/core/styles'

const styles = theme => ({
	cardPaddingMargin: {
		padding: 20,
		margin: 20,
		flex: '1 auto'
	},
	cardDiv: {
		flex: '1 auto'
	},
	flexContent: {
		display: 'flex',
		alignItems: 'flex-start'
	},
	input: {
		display: 'inline-block'
	},
	inputLeft: {
		display: 'inline-block',
		marginLeft: 32
	},
	noMarginLeft: {
		marginLeft: 0
	}
})

const CardShow = withStyles(styles)(({ classes, record, translate } = this.props) => (
	<div className={classes.flexContent}>
		<Card className={classes.cardPaddingMargin}>
			<CardContent>
				<Typography variant="title">Fiche du produit {record.name}</Typography>
			</CardContent>
			<CardChild>
				<SimpleShowLayout record={record}>
					<TextField label="fields.id" source="id" />
					<ExtendedTextField label="fields.name" source="name" nameproduct={0} className={classes.input} />
					<ExtendedTextField label="" source="name" nameproduct={1} className={classes.inputLeft} classes={{ root: classes.noMarginLeft }} />
					<ExtendedTextField label="fields.typeid" source="typeid" domainid={40} />
					<FunctionField
						label={translate('pluralizations.movies', { smart_count: 1 })}
						render={
							record => {
								return (
									record.movieid > 0 ?
										<MovieField value={record.movieid} addId={true} addLabel={false} addAvatar={true} isLink={true} /> :
										null
								)
							}
						}
					/>
					<FunctionField
						label={translate('pluralizations.albums', { smart_count: 1 })}
						render={
							record => {
								return (
									record.albumid > 0 ?
										<AlbumField value={record.albumid} addId={true} addLabel={false} addAvatar={true} isLink={true} /> :
										null
								)
							}
						}
					/>
				</SimpleShowLayout>
			</CardChild>
		</Card>
		<div className={classes.cardDiv}>
			<Card className={classes.cardPaddingMargin}>
				<CardContent>
					<Typography variant="title">Gestion</Typography>
				</CardContent>
				<CardChild>
					<SimpleShowLayout record={record}>
						<MyDateField showTime={true} label="fields.createdon" source="createdon" />
						<ContactField label="fields.createdby" source="createdby" />
						<MyDateField showTime={true} label="fields.modifiedon" source="modifiedon" />
						<ContactField label="fields.modifiedby" source="modifiedby" />
					</SimpleShowLayout>
				</CardChild>
			</Card>
		</div>
	</div>
))

export default translate(CardShow)
