import React from 'react'
import PropTypes from 'prop-types'
import InputLabel from '@material-ui/core/InputLabel'
import FormControl from '@material-ui/core/FormControl'
import { FieldTitle } from 'ra-core'
import { withStyles } from '@material-ui/core/styles'

const styles = theme =>   ({
	container: {
		display: 'flex',
		flexWrap: 'nowrap',
		alignItems: 'center',
		flexDirection: 'row',
	},
	label: {
		position: 'relative',
		marginTop: '5px',
		minWidth: '150px',
		maxWidth: '150px',
	},
})

export const LabeledInput = ({
	children,
	classes,
	className,
	fullWidth,
	input,
	isRequired,
	label,
	meta,
	resource,
	source,
	...rest
}) => {
	if (!label && !source) {
		throw new Error(
			`Cannot create label for component <${children &&
			children.type &&
			children.type
				.name}>: You must set either the label or source props. You can also disable automated label insertion by setting 'addLabel: false' in the component default props`
		)
	}
	const restProps = fullWidth ? { ...rest, fullWidth } : rest

	return (
		<FormControl
			className={className}
			margin="normal"
			fullWidth={fullWidth}
			error={meta && meta.touched && meta.error}
		>
			<div className={classes.container}>
				<InputLabel shrink className={classes.label} >
					<FieldTitle
						label={label}
						source={source}
						resource={resource}
						isRequired={isRequired}
					/>
				</InputLabel>
				<div>
					{children && typeof children.type !== 'string'
						? React.cloneElement(children, {
							input,
							resource,
							...restProps,
						})
						: children}
				</div>
			</div>

		</FormControl>
	)
}

LabeledInput.propTypes = {
	basePath: PropTypes.string,
	children: PropTypes.element,
	classes: PropTypes.object.isRequired,
	className: PropTypes.string,
	fullWidth: PropTypes.bool,
	input: PropTypes.object,
	isRequired: PropTypes.bool,
	label: PropTypes.string,
	meta: PropTypes.object,
	onChange: PropTypes.func,
	record: PropTypes.object,
	resource: PropTypes.string,
	source: PropTypes.string,
	labelStyle: PropTypes.object,
}

export default withStyles(styles)(LabeledInput)