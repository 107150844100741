import { DateFormatter, Contacts as ContactsTools, PublicationTrackingReferenceData } from '../../../tools'

const getRowdataStatus = (rowData) => {
	let status = 'other'
	var today = new Date()
	if (rowData.createdon !== rowData.modifiedon) {
		status = rowData.desactivatedOn === null ? 'updated' : 'deleted'
	}
	if (rowData.createdon === rowData.modifiedon && rowData.desactivatedOn === null && DateFormatter.getDayNameDate(rowData.createdon) === DateFormatter.getDayNameDate(today))
		status = 'new'
	return status
}
const setPublicationTracking = (item, trackingtypes) => {
	let result = []
	if (item.publications  && item.publications.publicationTracking ) {
		item.publications.publicationTracking.forEach((elt) => {
			const newitem = {
				stateid: elt.stateid,
				trackingTypeId: elt.trackingTypeId

			}
			result.push(newitem)
		})
	}
	return result
}
const isSeasonRow = (rowData) => {
	return (rowData.pgm === 0 && (rowData.categoryId === 13 || rowData.categoryId === 12) )
}

const getCommentsValue = (comments) => {
	return comments !== null ? comments : ''
}
const getuserName = (userId) => {

	const users = ContactsTools.getContactsByGroupid(160)
	return users.find(item => item.id === userId) ? users.find(item => item.id === userId).gsm : userId
}

export const convertDataToMaterialTableRow = (data, isUpdatePlanningTri) => {
	let results = []
	data.forEach((item) => {
		const newItem = {
			id: item.id,
			userId: item.publications !== null ? item.publications.contactId : 999999,
			userName: item.publications !== null ? getuserName(item.publications.contactId) : 'zzzzzz',
			control: item.control,
			week: item.week,
			affair: item.affair,
			pgm: item.pgm,
			planningDate: item.planningDate,
			prio: item.prio,
			toBeConfirmed: item.toBeConfirmed,
			movieId: item.movieId,
			movieTitle: item.movieTitle,
			movieTitleComment: item.movieTitleComment,
			categoryId: item.categoryId,
			categoryName: item.categoryId !== null && item.categoryId !== 0 ? PublicationTrackingReferenceData.getCellInformation(item.categoryId, 'category').label : 'zzzzzz',
			ayd: item.ayd,
			taskTypeId: item.taskTypeId,
			taskTypeName: item.taskTypeId !== null && item.taskTypeId !== 0 ? PublicationTrackingReferenceData.getCellInformation(item.taskTypeId, 'taskType').label : 'zzzzzz',
			versionId: item.versionId,
			formatId: item.formatId,
			bk: item.bk,
			dtvntr: item.dtvNtr,
			vodDateShifted: item.vodDateShifted,
			sdLocationPrice: item.sdLocationPrice,
			hdUhdLocationPrice: item.hdUhdLocationPrice,
			sdEstPrice: item.sdEstPrice,
			estHdUhdPrice: item.estHdUhdPrice,
			bkDvdPrice: item.bkDvdPrice,
			bkBrPrice: item.bkBrPrice,
			eanDvd: item.eanDvd,
			eanBr: item.eanBr,
			rowStatus: getRowdataStatus(item),
			isSeasonRow: isSeasonRow(item),
			isUpdateApproved: item.isUpdateApproved,
			locSdPriceComments: getCommentsValue(item.locSdPriceComments),
			locHdUhdPriceComments: getCommentsValue(item.locHdUhdPriceComments),
			sdEstPriceComments: getCommentsValue(item.sdEstPriceComments),
			uhdEstPriceComments: getCommentsValue(item.uhdEstPriceComments),
			bkDvdPriceComments: getCommentsValue(item.bkDvdPriceComments),
			bkBrPriceComments: getCommentsValue(item.bkBrPriceComments),
			taskGroupId: item.taskTypeId !== null && item.taskTypeId !== 0 ? PublicationTrackingReferenceData.getTasksTypes().find(r => r.itemid === item.taskTypeId).taskGroupDomainItemId   : 'zzzzzz',
		}
		results.push(newItem)
	})

	isUpdatePlanningTri ?
		results.sort((a, b) => a.pgm > b.pgm)
			.sort((a, b) => (a.affair < b.affair) ? 1 : -1).sort((a, b) => (a.planningDate > b.planningDate) ? 1 : -1)
			.sort((a, b) => (a.categoryName < b.categoryName) ? 1 : -1).sort((a, b) => a.userName > b.userName ? 1 : -1)
		:
		results.sort((a, b) => a.pgm < b.pgm ? 1 : -1)
			.sort((a, b) => (a.affair > b.affair) ? 1 : -1).sort((a, b) => (a.planningDate < b.planningDate) ? 1 : -1)
			.sort((a, b) => (a.categoryName > b.categoryName) ? 1 : -1)

	return results

}

export const convertDataToPreparationMaterialTableRow = (data, trackingtypes, isUpdatePlanningTri) => {
	let results = []
	data.forEach((item) => {
		if (item.publications !== null) {
			const newItem = {
				id: item.id,
				userId: item.publications !== null && item.publications.contactId ? item.publications.contactId  : 999999,
				userName: item.publications !== null ? getuserName(item.publications.contactId) : 'zzzzzz',
				preparationStatus: item.publications !== null ? item.publications.preparationStatusId : 0,
				frontStatus: item.publications !== null ? item.publications.frontStatusId : 0,
				koDetails: item.publications !== null ? item.publications.comments : '',
				control: item.control,
				week: item.week,
				affair: item.affair,
				pgm: item.pgm,
				planningDate: item.planningDate,
				prio: item.prio,
				toBeConfirmed: item.toBeConfirmed,
				movieId: item.movieId,
				movieTitle: item.movieTitle,
				movieTitleComment: item.movieTitleComment,
				categoryId: item.categoryId,
				categoryName:  item.categoryId !== 0 ? PublicationTrackingReferenceData.getCellInformation(item.categoryId, 'category').label : 'aaaaaa',
				ayd: item.ayd,
				taskTypeId: item.taskTypeId,
				taskTypeName: item.taskTypeId !== 0 ? PublicationTrackingReferenceData.getCellInformation(item.taskTypeId, 'taskType').label : 'aaaaaa',
				versionId: item.versionId,
				formatId: item.formatId,
				bk: item.bk,
				dtvntr: item.dtvNtr,
				vodDateShifted: item.vodDateShifted,
				sdLocationPrice: item.sdLocationPrice,
				hdUhdLocationPrice: item.hdUhdLocationPrice,
				sdEstPrice: item.sdEstPrice,
				estHdUhdPrice: item.estHdUhdPrice,
				bkDvdPrice: item.bkDvdPrice,
				bkBrPrice: item.bkBrPrice,
				eanDvd: item.eanDvd,
				eanBr: item.eanBr,
				trackingvalue: setPublicationTracking(item, trackingtypes),
				rowStatus: getRowdataStatus(item),
				isSeasonRow: isSeasonRow(item),
				isUpdateApproved: item.isUpdateApproved,
				locSdPriceComments: getCommentsValue(item.locSdPriceComments),
				locHdUhdPriceComments: getCommentsValue(item.locHdUhdPriceComments),
				sdEstPriceComments: getCommentsValue(item.sdEstPriceComments),
				uhdEstPriceComments: getCommentsValue(item.uhdEstPriceComments),
				bkDvdPriceComments: getCommentsValue(item.bkDvdPriceComments),
				bkBrPriceComments: getCommentsValue(item.bkBrPriceComments),
				taskGroupId: item.taskTypeId !== null && item.taskTypeId !==0 ? PublicationTrackingReferenceData.getTasksTypes().find(r => r.itemid === item.taskTypeId).taskGroupDomainItemId   : 'zzzzzz',

			}


			results.push(newItem)
		}
	})
	isUpdatePlanningTri ?
		results.sort((a, b) => a.pgm > b.pgm)
			.sort((a, b) => (a.affair < b.affair) ? 1 : -1).sort((a, b) => (a.planningDate > b.planningDate) ? 1 : -1)
			.sort((a, b) => (a.categoryName < b.categoryName) ? 1 : -1).sort((a, b) => a.userName > b.userName ? 1 : -1)
		:
		results.sort((a, b) => a.pgm < b.pgm ? 1 : -1)
		.sort((a, b) => (a.affair > b.affair) ? 1 : -1).sort((a, b) => (a.planningDate < b.planningDate) ? 1 : -1)
		.sort((a, b) => (a.categoryName > b.categoryName) ? 1 : -1)

	return results
}
export const getcolor = (data, name) => {
	let result = ''
	data.find((item) => {
		if (item.desactivated === 0 && item.name === name) {
			result = item.color !== null ? item.color : 'white'
		}
	})
	return result
}
export const getDistinctDate = (datatable) => {
	const results = [...new Set(datatable.map(x => x.planningDate))]
	return results.sort((a, b) => a > b ? 1 : -1)
}
export const getDistinctItem = (datatable,item,list) => {
	
	let results = []
	let ids =[]
	switch(item){
		case 'category' :
			ids = [...new Set(datatable.map(x => x.categoryId))]
			results =[...new Set(list.filter(x => ids.includes(x.itemid)))]
			//[...new Set(list.map(x => x.categoryId))]
			break;
		case 'tasktype' :		
			ids =[...new Set(datatable.map(x => x.taskTypeId))]
			results =[...new Set(list.filter(x => ids.includes(x.itemid)))]
			break;
		case 'version' :
			ids =	[...new Set(datatable.map(x => x.versionId))]
			results =[...new Set(list.filter(x => ids.includes(x.itemid)))]

			break;	
		case 'responsable' :
			ids =	[...new Set(datatable.map(x => x.userId))]
			results =[...new Set(list.filter(x => ids.includes(x.id)))]

			break;	
		case 'ayd' :
			//ids =	[...new Set(datatable.map(x => x.ayd))]
			results =[...new Set(datatable.map(x => x.ayd))]

			break;	
		case 'taskGroup' :
			//ids =	[...new Set(datatable.map(x => x.ayd))]
 			ids =	[...new Set(datatable.map(x => x.taskGroupId))]
			results =[...new Set(list.filter(x => ids.includes(x.itemid)))]

			break;	
	}
	return results
}