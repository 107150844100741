import React from 'react'
import { Route } from 'react-router-dom'
import { RightsList } from './components/companiestransfers/transfersrights'
import { ContentsworkflowList } from './components/contentsworkflow'
import { Configuration } from './components/layout'
import  ResetPassWordWithTheme  from  './components/auth/ResetPassWord'
import RedirectConnexion from './components/auth/RedirectConnexion'
import Dashboard from './Dashboard'
import CallBackWithTheme from './components/auth/CallBack'
import ErrorPage from './tools/ErrorPage'
import LoginFrm from './components/auth/Login'

export default [
	<Route key="configuration" exact path="/configuration" component={Configuration} />,
	<Route key="transfersrights" exact path="/Transfersrights" component={RightsList} />,
	<Route key="contentsworkflowproduct" exact path="/Contentsworkflow/Product" component={ContentsworkflowList} />,
	// <Route key="resetPasseword" exact path="/ResetPasseword" component={ResetPassWord} />,
	<Route exact path="/reinitPasseword/:token" component={ResetPassWordWithTheme} noLayout />,
	<Route exact path="/Oktacallback" render={() => <CallBackWithTheme error={false} />} noLayout />, // render={(props) => <Dashboard {...props.auth} isLoggedIn={true}/> } />,
	<Route exact path="/errorlogin" render={() => <LoginFrm errorLogin={true}/>}  noLayout />,
]