import React from 'react'
import { SimpleForm, Edit, List, } from 'react-admin'
import { BackButton } from '../../common/buttons'
import PicturesList from './PicturesList'
import PicturesEdit from './PicturesEdit'

const MoviespicturesList = (props) => (
	<List title="titles.moviesPicturesList" actions={null} {...props} pagination={null}>
		<PicturesList />
	</List>
)

const MoviespicturesEdit = (props) => (
	<Edit title="titles.moviesPicturesCard" undoable={false} actions={<BackButton />} {...props}>
		<SimpleForm>
			<PicturesEdit />
		</SimpleForm>
	</Edit>
)

export { MoviespicturesList, MoviespicturesEdit }
