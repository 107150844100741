function _defineProperty(obj, key, value) {
	if (key in obj) {
		Object.defineProperty(obj, key, {
			value: value,
			enumerable: true,
			configurable: true,
			writable: true
		})
	} else {
		obj[key] = value
	}

	return obj
}

class Logger {
	constructor() {
		_defineProperty(this, 'LEVELS', {
			LOG: 0,
			DEBUG: 1,
			INFO: 2,
			WARN: 3,
			ERROR: 4,
			OFF: 5
		})

		_defineProperty(this, 'defaultLevel', process.env.NODE_ENV === 'production' ? this.LEVELS.INFO : this.LEVELS.LOG)

		this.currentLevel = this.defaultLevel
	}

	static get instance() {
		if (!this.singleton) {
			console.debug('Logger > new instance')
			this.singleton = new Logger()
		}

		return this.singleton
	}

	setLevel(value) {
		if (typeof value === 'string') {
			this.currentLevel = this.LEVELS[value]
		} else {
			this.currentLevel = value
		}
	}

	isEnabled(level) {
		return level >= this.currentLevel
	}

	log(msg, ...args) {
		const LOG = this.LEVELS.LOG

		if (this.isEnabled(LOG)) {
			console.log(msg, ...args)
		}
	}

	debug(msg, ...args) {
		const DEBUG = this.LEVELS.DEBUG

		if (this.isEnabled(DEBUG)) {
			console.debug(msg, ...args)
		}
	}

	info(msg, ...args) {
		const INFO = this.LEVELS.INFO

		if (this.isEnabled(INFO)) {
			console.info(msg, ...args)
		}
	}

	warn(msg, ...args) {
		const WARN = this.LEVELS.WARN

		if (this.isEnabled(WARN)) {
			console.warn(msg, ...args)
		}
	}

	error(msg, ...args) {
		const ERROR = this.LEVELS.ERROR

		if (this.isEnabled(ERROR)) {
			console.error(msg, ...args)
		}
	}
}

const logger = Logger.instance

export default logger