import React, { Component } from 'react'
import PropTypes from 'prop-types'
import get from 'lodash/get'
import FormControl from '@material-ui/core/FormControl'
import InputLabel from '@material-ui/core/InputLabel'
import Button from '@material-ui/core/Button'
import { addField, FieldTitle } from 'ra-core'
import sanitizeRestProps from '../inputs/sanitizeRestProps'
import { withStyles } from '@material-ui/core'

const styles =  ({
	root: {
		display: 'flex',
	},
	button: {
		paddingLeft: 8,
		'&:first-child': {
			paddingLeft: 0,
		},
	},
})

export class ButtonInput extends Component {
	handleClick = (value) => {
		this.props.input.onChange(parseInt(value, 10))
	}

	renderButton = choice => {
		const {
			optionText,
			optionValue,
			input,
			classes,
		} = this.props
		const value = get(choice, optionValue)
		const text = get(choice, optionText)
		const currentValue = input.value
		const color = currentValue !== value ? 'default' : 'secondary'
		return (
			<div className={classes.button}>
				<Button key={value} variant="contained" color={color} onClick={this.handleClick.bind(this, value)}>{text}</Button>
			</div>
		)
	}

	render() {
		const {
			classes,
			className,
			label,
			resource,
			source,
			isRequired,
			choices,
			shrink,
			...rest
		} = this.props

		return (
			<FormControl
				component="fieldset"
				required={isRequired}
				className={className}
				margin="normal"
				{...sanitizeRestProps(rest)}
			>
				{
					!shrink ?
						<InputLabel
							component="legend"
							shrink
							required={isRequired}
						>
							<FieldTitle
								label={label}
								source={source}
								resource={resource}
								isRequired={isRequired}
							/>
						</InputLabel> :
						null
				}
				<div className={classes.root}>
					{choices.map(this.renderButton)}
				</div>
			</FormControl>
		)
	}
}

ButtonInput.propTypes = {
	choices: PropTypes.arrayOf(PropTypes.object),
	classes: PropTypes.object.isRequired,
	className: PropTypes.string,
	input: PropTypes.object,
	isRequired: PropTypes.bool,
	label: PropTypes.string,
	optionText: PropTypes.oneOfType([
		PropTypes.string,
		PropTypes.func,
		PropTypes.element,
	]).isRequired,
	optionValue: PropTypes.string.isRequired,
	resource: PropTypes.string,
	source: PropTypes.string,
}

ButtonInput.defaultProps = {
	classes: {},
	choices: [],
	optionText: 'name',
	optionValue: 'id',
	shrink: false,
}

const ButtonInputField = addField(withStyles(styles)(ButtonInput))

ButtonInputField.defaultProps = {
	shrink: true,
	addLabel: true,
}

export default ButtonInputField
