import React from 'react'
import PropTypes from 'prop-types'
import { SelectInput } from 'react-admin'
import { isArrayLike } from '30-seconds-of-code'
import { Domains } from '../../../tools'

const DomainsInput = (props) => {
	const { withAll, ...rest } = props

	const choices = Domains.getSelectList(withAll)

	return (
		<SelectInput choices={isArrayLike(choices) ? choices : []} {...rest} />
	)
}

DomainsInput.propTypes = {
	withAll: PropTypes.bool,
}

DomainsInput.defaultProps = {
	withAll: false,
	addLabel: true,
}

export default DomainsInput