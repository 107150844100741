import uniq from 'lodash/uniq'
import getFetchedAt from 'ra-core/lib/util/getFetchedAt'
import {
	CRUD_GET_ANOTHER_ONE_SUCCESS,
	CRUD_GET_ANOTHER_LIST_SUCCESS
} from '../actions/dataReferenceResource'

export const addRecordsFactory = getFetchedAt => (
	newRecords = [],
	oldRecords
) => {
	const newFetchedAt = getFetchedAt(
		Object.values(newRecords).map(({ id }) => id),
		oldRecords.fetchedAt
	)

	const newRecordsById = Object.values(newRecords).reduce(
		(acc, record) => ({
			...acc,
			[record.id]: record,
		}),
		{}
	)

	const records = Object.keys(newFetchedAt).reduce(
		(acc, id) => ({
			...acc,
			[id]: newRecordsById[id] || oldRecords[id],
		}),
		{}
	)

	Object.defineProperty(records, 'fetchedAt', {
		value: newFetchedAt,
	}) // non enumerable by default

	return records
}

export const addRecordIdsFactory = getFetchedAt => (
	newRecordIds = [],
	oldRecordIds
) => {
	const newFetchedAt = getFetchedAt(newRecordIds, oldRecordIds.fetchedAt)
	const recordIds = uniq(
		Object.values(oldRecordIds).filter(id => !!newFetchedAt[id]).concat(newRecordIds)
	)

	Object.defineProperty(recordIds, 'fetchedAt', {
		value: newFetchedAt,
	}) // non enumerable by default
	return recordIds
}

const addRecords = addRecordsFactory(getFetchedAt)
const addRecordIds = addRecordIdsFactory(getFetchedAt)

const initialState = {}
Object.defineProperty(initialState, 'fetchedAt', { value: {} }) // non enumerable by default

export default (previousState = initialState, { type, payload, meta }) => {
	switch (type) {
	case CRUD_GET_ANOTHER_ONE_SUCCESS:
		return {
			...previousState,
			[meta.resource]: {
				data: addRecords([payload.data], previousState),
				list: {
					ids: addRecordIds([payload.data.id], previousState)
				}
			}
		}
	case CRUD_GET_ANOTHER_LIST_SUCCESS:
		return {
			...previousState,
			[meta.resource]: {
				data: addRecords(payload.data, previousState),
				list: {
					ids: addRecordIds(Object.values(payload.data).map(({ id }) => id), [])
				}
			}
		}
	default:
		return previousState
	}
}
