import React, { Component } from 'react'
import { SimpleShowLayout, TextField, NumberField } from 'react-admin'
import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import Typography from '@material-ui/core/Typography'
import CardChild from '../../../CardChild'
import { StatusButton } from '../../common/buttons'
import classNames from 'classnames'
import { Token } from '../../../tools'
import { CheckboxField, ContactField, ExtendedTextField, IconStatusField, ProductField, MyDateField } from '../../common/fields'
import { withStyles } from '@material-ui/core/styles'


const styles = theme => ({
	cardPaddingMargin: {
		padding: 20,
		margin: 20,
		flex: '1 auto'
	},
	cardDiv: {
		flex: '1 auto'
	},
	flexContent: {
		display: 'flex',
		alignItems: 'flex-start'
	},
	field: {
		display: 'inline-block'
	},
	inputLeft: {
		display: 'inline-block',
		marginLeft: 32
	},
	noMarginLeft: {
		marginLeft: 0
	},
	status: {
		display: 'flex',
		padding: 0
	},
	statusfield: {
		display: 'flex',
		alignItems: 'center'
	},
	statusButton: {
		marginTop: 25,
		marginLeft: 15
	}
})

class CardShow extends Component {
	state = {
		stateid: null
	}

	componentDidMount() {
		const { record } = this.props
		if (record.productid !== undefined)
			this.fetchData(record)
	}

	componentDidUpdate(prevProps) {
		const { record } = this.props
		if (record !== prevProps.record) {
			this.fetchData(record)
		}
	}

	fetchData = (record) => {
		const t = Token.getToken()
		const requestCompaniescontacts = new Request(process.env.REACT_APP_API + `/Contentsworkflow/Product?id=${record.productid}&transferid=${record.transferid}`, {
			method: 'GET',
			headers: new Headers({
				'Content-Type': 'application/json',
				'Authorization': `Bearer ${t}`
			}),
		})
		fetch(requestCompaniescontacts)
			.then(response => {
				if (response.status < 200 || response.status >= 300) {
					throw new Error(response.statusText)
				}
				return response.json()
			})
			.then(({ data }) => {
				if (null != data && data.length > 0) {
					if (null != data.error)
						console.log(data.error)
					let stateid = data.map(item => {
						return item.stateid
					})
					const allEqual = arr => arr.every(v => v === arr[0])
					const record = Object.assign({}, data)
					let iconStatus
					if (allEqual(stateid)) {
						iconStatus = record[0].stateid
					} else {
						const values = stateid.filter(id => id !== 4)
						values.sort(function compareNombres(a, b) {
							return a - b
						})
						values.reverse()
						iconStatus = values[1] === 5 ? 5 : values[0]
					}
					this.setState({ stateid: iconStatus })
				} else
					this.setState({ stateid: 2 })
			})
	}

	render() {
		const { classes, record } = this.props
		const stateid = this.state.stateid
		//const colorButton = this.state.colorButton
		if (stateid !== null) {
			record['stateid'] = parseInt(stateid, 10)
		}
		return (
			<div className={classes.flexContent}>
				<Card className={classes.cardPaddingMargin}>
					<CardContent>
						<Typography variant="title">Droits du Transfert</Typography>
					</CardContent>
					<CardChild>
						<SimpleShowLayout record={record}>
							<TextField label="fields.id" source="id" />
							<ExtendedTextField label="fields.countryid" source="countryid" domainid={2} />
							<ProductField label="fields.name" sourceId="product.id" sourceLabel="product.name" addId={true} addLabel={true} isLink={true} />
							<ExtendedTextField label="fields.modecommid" source="modecommid" domainid={92} />
							<MyDateField showTime={true} label="fields.startdate" source="startdate" />
							<MyDateField showTime={true} label="fields.enddate" source="enddate" />
							<NumberField label="fields.price" source="price" />
							<ExtendedTextField label="fields.vatid" source="vatid" domainid={25} />
							<TextField label="fields.sold" source="sold" />
							<SimpleShowLayout classes={{ root: classes.status }}>
								{stateid !== null ? <IconStatusField label="fields.stateid" source="stateid" className={classes.field} variant="contained" transfersright /> : null}
								<StatusButton label="fields.status" record={record} className={classNames(classes.field, classes.statusfield)} classes={{ statusButton: classes.statusButton }} />
							</SimpleShowLayout>
							<CheckboxField label="fields.published" source="published" />
							<CheckboxField label="fields.exclusive" source="exclusive" />
						</SimpleShowLayout>
					</CardChild>
				</Card>
				<div className={classes.cardDiv}>
					<Card className={classes.cardPaddingMargin}>
						<CardContent>
							<Typography variant="title">Taux</Typography>
						</CardContent>
						<CardChild>
							<SimpleShowLayout record={record}>
								<NumberField label="fields.commissionfee" source="commissionfee" />
								<NumberField label="fields.sacemfee" source="sacemfee" />
								<NumberField label="fields.sacdfee" source="sacdfee" />
								<NumberField label="fields.tsafee" source="tsafee" />
							</SimpleShowLayout>
						</CardChild>
					</Card>
					<Card className={classes.cardPaddingMargin}>
						<CardContent>
							<Typography variant="title">Gestion</Typography>
						</CardContent>
						<CardChild>
							<SimpleShowLayout record={record}>
								<MyDateField showTime={true} label="fields.createdon" source="createdon" />
								<ContactField label="fields.createdby" source="createdby" />
								<MyDateField showTime={true} label="fields.modifiedon" source="modifiedon" />
								<ContactField label="fields.modifiedby" source="modifiedby" />
								<MyDateField showTime={true} label="fields.publishedon" source="publishedon" />
								<ContactField label="fields.publishedby" source="publishedby" />
								<MyDateField showTime={true} label="fields.desactivatedon" source="desactivatedon" />
								<ContactField label="fields.desactivatedby" source="desactivatedby" />
							</SimpleShowLayout>
						</CardChild>
					</Card>
				</div>
			</div>
		)
	}
}

export default withStyles(styles)(CardShow)
