import React, { Fragment } from 'react'
import { Edit, SimpleForm, Show } from 'react-admin'
import { NameField } from '../common/fields'
import { Configurationmanager } from '.'
import { createTheme, ThemeProvider } from '@material-ui/core/styles'
import ConfigurationManagerDetails from './fields/ConfigurationManagerDetails'

const getMuiTheme = () => createTheme({
	overrides: {
		MuiCardContent: {
			root: {
				'&:first-child': {
					paddingLeft: 0,
					paddingRight: 0,
				},
			}
		},
	},
	display: {
		display: 'none'
	},
})

export const Title = (props) => <NameField addId={true} addLabel={true} sourceId='id' sourceLabel='label' {...props} />


const ConfigurationList = (props) => (
	<Fragment>
		<Configurationmanager {...props}/>
	</Fragment>
)

const ConfigurationShow = (props) => (
	<ThemeProvider theme={getMuiTheme()}>
		<Show title={<Title />} actions={null} {...props}>
			<SimpleForm toolbar={null} style={{paddingTop: 0, marginTop: '-8px'}}>
				<ConfigurationManagerDetails create={false} type='show' />
			</SimpleForm>
		</Show>
	</ThemeProvider>
)

const ConfigurationEdit = (props) => (
	<ThemeProvider theme={getMuiTheme()}>
		<Edit undoable={true} actions={null} {...props}>
			<SimpleForm toolbar={null} style={{paddingTop: 0, marginTop: '-8px'}}>
				<ConfigurationManagerDetails create={true} type='edit' />
			</SimpleForm>
		</Edit>
	</ThemeProvider>
)

export { ConfigurationShow, ConfigurationEdit, ConfigurationList }