import React from 'react'
import { connect } from 'react-redux'
import { Layout } from 'react-admin'
import { MyAppBar, MySidebar, MyNotification, MyMenu } from '../layout'
import { darkTheme, lightTheme } from './themes'

const MyLayout = (props) => (
<Layout
	{...props }
	appBar={MyAppBar}
	sidebar={MySidebar}
	notification={MyNotification}
	menu={MyMenu}
/>)
export default connect(
	(state, props) => ({
		theme: state.theme === 'dark' ? darkTheme : lightTheme,
	}),
	{}
)(MyLayout)