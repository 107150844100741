import Api from './Api'

const Publicationstracking = {
	launchManualExcelImport: (interval, startdate, enddate) =>
		Api.Requests.get('/PublicationsTracking/ExcelPlanning?dateInterval=' + interval+'&startDate=' +startdate+ '&endDate='+enddate ),
	//getWeeks: () =>
	//Api.Requests.getAll('/PublicationsTracking/Weeks'),
	getStories: (rowid) => 
		Api.Requests.getAll('/PublicationsTracking/Stories/'+rowid),
	updateApproval: (rowid) =>
		Api.Requests.put('/PublicationsTracking/UpdateApproval/'+rowid),
	// updatePrioItem: (rowid) =>
	// 	Api.Requests.put('/PublicationsTracking/UpdatePrioItem/'+rowid),
	// getPlanning : (weekNumber) =>
	// Api.Requests.getAll('/PublicationsTracking/Planning?week='+( weekNumber.toString().substring(0, 4) +  ('0'+ (weekNumber.toString().substring(4, 6))).slice(-2))),
	getPlanning : (weekNumber) =>
		Api.Requests.getAll('/PublicationsTracking/Planning?week='+( weekNumber.toString().substring(0, 4) +  ('0'+ (weekNumber.toString().substring(4, 6))).slice(-2)) +'&'+ Api.Limit(50, 1)) ,
	savePublication : (data) =>
		Api.Requests.postWithBody('/PublicationsTracking/Publications', data),
	getInProgressPlublication: (weekNumber) =>
		Api.Requests.getAll('/PublicationsTracking/InProgress?week='+( weekNumber.toString().substring(0, 4) +  ('0'+ (weekNumber.toString().substring(4, 6))).slice(-2))),
	getPublicationstrackingtypes: () =>
		Api.Requests.getAll('/PublicationsTracking/PublicationsTrackingTypes'),		
	getWeekResponible : (weekNumber) =>
		Api.Requests.getAll('/PublicationsTracking/WeekResponsibles?week='+( weekNumber.toString().substring(0, 4) +  ('0'+ (weekNumber.toString().substring(4, 6))).slice(-2))),
	saveWeekResponsible : (data) =>
		Api.Requests.postWithBody('/PublicationsTracking/SaveWeekResponsible', data),	
	saveTrackingTypes : (data) =>
		Api.Requests.postWithBody('/PublicationsTracking/SaveTrackingTypes', data),	
	saveTrackingGroup : (data ) =>
		Api.Requests.postWithBody('/PublicationsTracking/SaveTrackingGroup', data ),	
	desactivateTrackingGroup : (id ) =>
		Api.Requests.postWithBody('/PublicationsTracking/DesactivateTrackingGroup', id ),	
	desactivateTrackingType : (id ) =>
		Api.Requests.postWithBody('/PublicationsTracking/DesactivateTrackingType', id ),	
	getPublicationstrackingGroupetypes: () =>
		Api.Requests.getAll('/PublicationsTracking/PublicationsTrackingGroupTypes'),
	sendMailJob: (job ,mailtype ) => 
		Api.Requests.postWithBody('/PublicationsTracking/sendMail?mailtype=' + mailtype , job )	,
	updatePrioItem: (data ) => 
		Api.Requests.postWithBody('/PublicationsTracking/UpdatePrioItem'  , data )	
}

export default Publicationstracking