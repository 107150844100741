import React, { Component } from 'react'
import { createTheme, withStyles, ThemeProvider } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import { CustomTextField,CustomFooterList } from '../../layout/GlobalStyles'
import CompaniesAlbums from '../../../requests/CompaniesAlbums'
import { DomainsItems } from '../../../../src/tools'
import GestionFields from '../../common/fields/GestionFields'
import Card from '@material-ui/core/Card'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'
import ListItemAvatar from '@material-ui/core/ListItemAvatar'
import { AlbumField } from '../../common/fields'
import { fetchStart, GET_ONE } from 'react-admin'
import dataProvider from '../../../dataProvider'
import VisibilityIcon from '@material-ui/icons/Visibility'
import { Button, Tooltip } from '@material-ui/core'
import AddIcon from '@material-ui/icons/Add'
import { Links } from '../../../tools'
import { connect } from 'react-redux'
import compose from 'recompose/compose'
import { OnAssociateSousAlbumList } from '../../../actions/companiesAlbums'
import SearchListField from '../../common/fields/SearchListField'
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction'
import DeleteIcon from '@material-ui/icons/Delete'
import IconButton from '@material-ui/core/IconButton'
import { CancelIcon } from '../../../constants/icons'
import UndoIcon from '@material-ui/icons/Undo'
import AlbumInfoGlobal from './AlbumInfoGlobal'
import ListSubheader from '@material-ui/core/ListSubheader'
import CloseIcon from '@material-ui/icons/Close'
import InputBase from '@material-ui/core/InputBase'
import SearchIcon from '@material-ui/icons/Search'
import InputAdornment from '@material-ui/core/InputAdornment'
const styles = theme => ({
	container: {
		display: 'flex'
	},
	item: {
		marginRight: '1rem',
		marginLeft: '5px'
	},
	formControl: {
		margin: '.5em .5em 1.5em 2.2em',
		minWidth: '230px',
		height: '25px'
	},
	customSelect: {
		//position: 'absolute',
		top: '8px',
		margin: theme.spacing(1),
		//padding:'6px 8px',
		maxWidth: '90px',
		minHeight: '40px',
		backgroundColor: '#fff',
		borderRadius: '4px',
	},
	cardPaddingMargin: {
		marginRight: '20px'
	},
	button: {
		//margin: theme.spacing(1),
		marginTop: '5px',
		backgroundColor: '#288DB1',
		width: '90%', 
		maxHeight: '35px',
		// maxWidth: '132px',
		fontSize: '0.7rem',
		//top: '5px',
		lineHeight: '1.2em',
		'&:hover': {
			background: '#1c627b'
		}
	},
	listContainer: {
		backgroundColor: theme.palette.background.paper,
		position: 'relative',
		overflow: 'auto',
		maxHeight: 'calc(70vh - 100px)',
		minWidth: 'auto'
	},
	rightIcon: {
		marginLeft: theme.spacing(1),
	},
})

class SousAlbumFiche extends Component {

	constructor(props) {
		super(props)
		this.state = {
			albums: [],
			SousAlbums: [],
			types: [],
			selectedssAlbum: 0,
			isLoading: false,
			searchAlbums: '',
			openSearch: false,
			openADD: false,
			searchValue:'',
			initialssAlbum:[],
			
		}
	}

	getalbumsType = () => {
		return Object.values(DomainsItems.getItems(21))
	}
	componentDidMount() {
		const { record } = this.props

		CompaniesAlbums.getAllalbums().then((json) => {
			if (json.data !== undefined) {
				this.setState({ albums: json.data })
				this.setState({ SousAlbums: json.data.filter(s => s.parentid === record.id) })
				this.setState({ initialssAlbum: json.data.filter(s => s.parentid === record.id) })

				//this.setState({selectedssAlbum : json.data.filter(s=>s.parentid===record.id)[0] ? json.data.filter(s=>s.parentid===record.id)[0] : 0})
			}
		})
		this.setState({ types: this.getalbumsType() })
		//}
	}
	handleSousAlbumClick(select) {
		
			fetchStart()
			this.setState({ isLoading: true }, () => {
				dataProvider(GET_ONE,
					"Companiesalbums", { id: select.id }).then(resp => {
						if (!this.isUnmounted) {
							let arr = JSON.parse(JSON.stringify(resp.data))
							this.setState({ searchAlbums: arr })
							this.setState({ isLoading: false })
						}
					})
			})
		
		this.setState({ selectedssAlbum: select })
	}
	handleAssociateButtonClick = () => {
		this.setState({ openADD: true })
	}
	handleDessociateClick = () => {
		this.setState({ openSearch: true })
	}
	handleRemoveAssociatedClick = (event, element) => {
		const { record, dissocitedAlbum, associatesousAlbumList, OnAssociateSousAlbumList } = this.props
		let res = []
		let finalassociated = []
		this.state.SousAlbums.forEach((item) => {
			if (dissocitedAlbum && dissocitedAlbum.find(s => s.id === element.id)) {
				item.parentid = record.id
				res.push(item)
			} else
				if (item.id !== element.id)
					res.push(item)
		})
		if (associatesousAlbumList && associatesousAlbumList.find(s => s.id === element.id)) {
			finalassociated = associatesousAlbumList.filter(i => i.id !== element.id)
			OnAssociateSousAlbumList(finalassociated)
		}
		this.setState({ selectedssAlbum: 0 })
		this.setState({ SousAlbums: res })
	}
	closeSearch = (event) => {
		this.setState({ openSearch: false })
		this.setState({ openADD: false })
	}
	handleShowAlbumClick = () => {
		window.open('/#' + Links.CompaniesalbumsShow(this.state.selectedssAlbum.id))

	}
	handleChangeInput = (event,type) => {
		this.setState({ searchValue: event.target.value })
		if(type === 'reset')
		{
			this.setState({ searchValue: '' })
			this.setState({SousAlbums : this.state.initialssAlbum })
 		}

	}
	searchItem =(e,source) =>{
		if(e.key === 'Enter' || source==='search'){
		if(this.state.searchValue ==='') this.setState({SousAlbums : this.state.initialssAlbum})
 			else 
		if(this.state.initialssAlbum &&  this.state.searchValue !== ''){
			let res = []
			if(this.state.initialssAlbum.filter(s=>s.id == this.state.searchValue)[0])
			res.push(this.state.initialssAlbum.filter(s=>s.id == this.state.searchValue)[0])
			if(this.state.SousAlbums.filter(s=>s.name.toUpperCase().includes(this.state.searchValue.toUpperCase())))
			this.state.SousAlbums.filter(s=>s.name.toUpperCase().includes(this.state.searchValue.toUpperCase())).forEach((elt) =>
				res.push(elt)
			)
			this.setState({SousAlbums : res})
		}
		}
  	}
	getMuiTheme = () => createTheme({
		overrides: {
			MuiBox: {
				root: {
					paddingTop: '5px'
				}
			},
			MuiGrid: {
				container: {
					paddingBottom: '15px'
				}
			},
			MuiTypography: {
				root: {
					// margin: '25px',
					// fontSize: 'larger',
					// color: '#288db1'
				}
			},
			MuiCardContent: {
				root: {
					'&:first-child': {
						paddingTop: 0,
						paddingBottom: 0,
						paddingLeft: 8,
						paddingRight: 8,
					},
				}
			},
			MuiFormLabel:{
				root:{
					"&$focused": {
						"color": "#288DB1"
					},
				}
			},
			MuiOutlinedInput: {
				multiline: {
					width: '480px'
				},
				'root': {  // - The Input-root, inside the TextField-root
					'&:hover fieldset': {
						borderColor: '#288DB1 !important', // - Set the Input border when parent has :hover
					},
					'&.Mui-focused fieldset': { // - Set the Input border when parent is focused 
						borderColor: '#288DB1 !important',
					},
				},
			},

		},
	})
	render() {
		const { classes, create, record, dissocitedAlbum } = this.props
		return (
			<div>
				<ThemeProvider theme={this.getMuiTheme()}>
					<Grid container spacing={12} direction="row" justify="space-between" >
						<Grid container direction="row" xs={12}>
							<Grid container>
								<Grid item xs={4}>
									<Card className={classes.cardPaddingMargin}>
										{/* <GlobalListComponent listItem={this.state.SousAlbums} 
										selecteditem={this.state.selectedssAlbum} 
										onChangeSelect={this.handleSousAlbumClick(this.state.selectedssAlbum) }
										  /> */}
										<List dense className={classes.listContainer} 
										subheader={
											<ListSubheader >
												<Grid container direction="row">
													<Grid item xs='10'> 
													<CustomTextField label="Recherche par nom ou ID"  value={this.state.searchValue}  handleChange={e=>this.handleChangeInput(e,'')}  valueReset={(e) =>this.handleChangeInput(e,'reset')}
														create="true" handleKeyPress={e=>this.searchItem(e,'')} fullWidth="true" removeicon={true} /> 
													</Grid>
													<Grid item xs='2'> 
													<IconButton type="button" aria-label="search" onClick={(e) => this.searchItem(e,'search')} style={{ marginRight: '20px' }}  >
														<SearchIcon />
													</IconButton>
													</Grid>
												</Grid>

												{/* <InputBase
													style={{ marginLeft: '0.2rem', flex: 1, width: '65%',backgroundColor:'#8080800f' }}
													placeholder="Recherche par nom ou ID"
													value={this.state.searchValue}
													onChange={e=>this.handleChangeInput(e,'')}
													onKeyPress={(e) => {
														if (e.key === 'Enter') {
															this.searchItem()
														}
													}}
														 endAdornment = {<InputAdornment position="end" size="small">
															<IconButton
																aria-label='supprimer'
															>
																{ this.state.searchValue !==''? <CloseIcon onClick={(e)=> this.handleChangeInput(e,'reset')  } /> : <div></div>
																}
															</IconButton></InputAdornment>}
												 
												 />
												<IconButton type="button" aria-label="search" 
												onClick={() => this.searchItem()}
												style={{ marginRight: '20px' }}  >
													<SearchIcon />
												</IconButton> */}
											</ListSubheader>
										}>
											{this.state.SousAlbums.map((value) => {
												return (
													<ListItem key={value.id} button
														selected={this.state.selectedssAlbum === value}
														onClick={() => this.handleSousAlbumClick(value)}>
														<ListItemAvatar>
															<AlbumField value={value.id} data={value} type='album'
																addId={false} addLabel={false} addAvatar={true} isLink={false} size={50} />
														</ListItemAvatar>
														<ListItemText id={value.id} primary={`${value.name} (${value.id})`} />
														{(value.parentid !== record.id) && create ?
															(dissocitedAlbum && dissocitedAlbum.find(s => s.id === value.id)) ?
																<ListItemSecondaryAction >
																	<IconButton edge="end" aria-label="delete" onClick={(event) => this.handleRemoveAssociatedClick(event, value)} >
																		<Tooltip title='Réassocier'>
																			<UndoIcon />
																		</Tooltip>
																	</IconButton>
																</ListItemSecondaryAction>
																:
																<ListItemSecondaryAction >
																	<IconButton edge="end" aria-label="delete" onClick={(event) => this.handleRemoveAssociatedClick(event, value)} >
																		<Tooltip title='Retirer de la liste'>
																			<DeleteIcon />
																		</Tooltip>
																	</IconButton>
																</ListItemSecondaryAction> : <div></div>}
													</ListItem>
												);
											})}
										</List>
										<CustomFooterList curent={this.state.SousAlbums.length} total={this.state.initialssAlbum.length}/>
									</Card>
									<Grid container>
										<Grid item xs={4}>
											<Button onClick={this.handleShowAlbumClick} disabled={this.state.selectedssAlbum === 0} color="primary" variant="contained" className={classes.button} target="_blank"  >
												Afficher l'album
												<VisibilityIcon className={classes.rightIcon} />
											</Button>
										</Grid>
										<Grid item xs={4}>
											<Button onClick={this.handleAssociateButtonClick} disabled={!create} color="primary" variant="contained" className={classes.button}>
												Associer
												<AddIcon className={classes.rightIcon} />
											</Button>
											<SearchListField open={this.state.openADD} close={this.closeSearch} exceptionItem={record} exceptionList={this.state.SousAlbums}
												Title='Liste des albums' ListToSearch={this.state.albums} TypeItem='Albums' multiSelect={true} source='AssociersousAlbum' />
										</Grid>
										<Grid item xs={4}>
											<Button onClick={this.handleDessociateClick} disabled={!create ||  this.state.selectedssAlbum === 0} color="primary" variant="contained" className={classes.button}>
												Dissocier
												<CancelIcon className={classes.rightIcon} />
											</Button>
											<SearchListField open={this.state.openSearch} close={this.closeSearch} exceptionItem={this.state.selectedssAlbum}
												Title='Liste des albums' ListToSearch={this.state.albums} TypeItem='Albums' multiSelect={false} source='DissociersousAlbum' />
										</Grid>
									</Grid>
								</Grid>

								<Grid item xs={8}>

									<Grid container>

										<Grid item xs={8} direction="row" >
											<Grid item xs={12}>
												<AlbumInfoGlobal recordelt={record} create={false} />
											</Grid>
											<Grid container>
												<Grid item xs={4}>
													<CustomTextField label="Identifiant sous album" value={this.state.selectedssAlbum.id} width='35%' fullWidth="false" />
												</Grid>
												<Grid item xs={8}>
													<CustomTextField label="Titre" value={this.state.selectedssAlbum.name} width='35%' fullWidth="true" />
												</Grid>

											</Grid>
											<Grid container>

												<Grid item xs={4}>
													<CustomTextField label="Type" value={this.state.types.find(t => t.itemid === this.state.selectedssAlbum.typeid) ? `${this.state.types.find(t => t.itemid === this.state.selectedssAlbum.typeid).label} (${this.state.selectedssAlbum.typeid})` : ''} width='35%' fullWidth="true" />
												</Grid>
												<Grid item xs={8}>
													<CustomTextField label="Rang" value={this.state.selectedssAlbum.rank} width='35%' fullWidth="true" />
												</Grid>

											</Grid>



										</Grid>

										<Grid item xs={4} direction="row" >
											<GestionFields classes={classes} record={this.state.searchAlbums} />
										</Grid>
									</Grid>
								</Grid>

							</Grid>
						</Grid>
					</Grid>
				</ThemeProvider >
			</div>

		)
	}
}
const mapStateToProps = state => ({
	...state,
	tabValue: state.reducer.albums.tabValue !== undefined ? state.reducer.albums.tabValue : 0,
	AllAlbums: state.reducer.albums.AllAlbums,
	dissocitedAlbum: state.reducer.albums.dissocitedAlbum,
	associatesousAlbumList: state.reducer.albums.associatesousAlbumList !== undefined ? state.reducer.albums.associatesousAlbumList : [],

})

const mapDispatchToProps = ({
	OnAssociateSousAlbumList
})
const enhance = compose(
	connect(mapStateToProps, mapDispatchToProps),
	withStyles(styles)
)
export default enhance(SousAlbumFiche)

