import React, { Component } from 'react'
import { connect } from 'react-redux'
import compose from 'recompose/compose'
import { translate, showNotification } from 'react-admin'
import { PricePlanificationPeriodTableItem } from '.'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import Paper from '@material-ui/core/Paper'
import TablePreviewTitle from './fields/TablePreviewTitle'
import { withStyles  } from '@material-ui/core/styles'

const styles = theme =>  ({
	root: {
		width: '100%',
		marginTop: theme.spacing(1),
		flexGrow: 1
	},
	table: {
		minWidth: 700,
	},
	cardContent: {
		flex: '1 auto',
		marginTop :'-6px'
	},
	previewTitle:{
		textAlign : 'center'
	}
})

class PricePlanificationPeriodTable extends Component{
	render(){
		const { classes, transfersRightsData, currentContentId, periodTablePreview, translate, scrollAction, objectTypeId } = this.props
		const dataArrayIndex = `${currentContentId}_${objectTypeId}`
		const previewTitle = translate('titles.periodTablePreviewTitle')
		const previewButtonText = translate('buttons.undoAction')
		if(periodTablePreview && periodTablePreview.length !== 0){
			const validTable = periodTablePreview
			return(
				<React.Fragment>
					<Paper className={classes.root}>
						<div className = {classes.previewTitle}>
							<TablePreviewTitle message = {previewTitle} buttonText = {previewButtonText} />
						</div>
						<Table className={classes.table}>
							<TableBody>
								{validTable.map((row, index) =>(
									// eslint-disable-next-line react/jsx-key
									<PricePlanificationPeriodTableItem row={row} index={index} isPreviewTable = {true} scrollAction = {scrollAction}/>
								))}
							</TableBody>
						</Table>
					</Paper>
				</React.Fragment>
			)
		}
		else{
			if(transfersRightsData[dataArrayIndex]){
				// remove the record which have null start date and end date
				const validTable = transfersRightsData[dataArrayIndex].transfersRightsByPeriod
				return(
					<React.Fragment>
						<Paper className={classes.root}>
							<Table className={classes.table}>
								<TableBody>
									{validTable.map((row, index) =>(
										// eslint-disable-next-line react/jsx-key
										<PricePlanificationPeriodTableItem row={row} index={index} scrollAction = {scrollAction}/>
									))}
								</TableBody>
							</Table>
						</Paper>
					</React.Fragment>
				)
			}
			else{
				return (
					<div></div>
				)
			}
		}
		
	}
}

const mapStateToProps = state => ({
	...state,
	currentContentId: state.reducer.pricePlanning.contentSelected,
	transfersRightsData: state.admin.resources['PricePlanning/TransfersRights']
		? state.admin.resources['PricePlanning/TransfersRights'].data
		: {},
	periodTablePreview : state.reducer.pricePlanning.transfersRightsByPeriodPreview ? state.reducer.pricePlanning.transfersRightsByPeriodPreview : [],
	objectTypeId : state.reducer.pricePlanning.objectTypeId
})

const mapDispatchToProps = ({
	showNotification
})

const enhance = compose(
	connect(mapStateToProps, mapDispatchToProps),
	withStyles(styles)
)

export default translate(enhance(PricePlanificationPeriodTable))