import React, { useEffect } from 'react'
import Chip from '@material-ui/core/Chip'
import { connect } from 'react-redux'
import compose from 'recompose/compose'
import { onPromotionUpdate, onGetPromo } from '../../../actions/Promotion'
import Switch from '@material-ui/core/Switch'
import Tooltip from '@material-ui/core/Tooltip'
import Zoom from '@material-ui/core/Zoom'
import { createTheme, withStyles, ThemeProvider } from '@material-ui/core/styles'
import { DomainsItems } from '../../../requests'
//import { DomainsItems } from '../../../tools'

const getMuiTheme = () => createTheme({
	tooltip: {
		color: '#f1f1f1',
	  },
})

const styles = {
	borderRadius: 15,
	border: 1,
	borderColor: 'white',
	borderStyle: 'solid',
	color: 'white',
	padding: '25px',
	paddingLeft: '40px',
	chip: {
		color: '#fff',
		backgroundColor : '#288DB1',
		'&:hover': {
			background: '#1c627b'
		},
		'&:active': {
			background: '#1c627b'
		},
		'&:focus': {
			background: '#288DB1'
		},
	},
}


function PromotionsCheckBox(props) {
	
	let { create, promotion, classes } = props
	const [applyToAll, setApplyToAll] = React.useState(false)
	const [choices, setChoices] = React.useState([])

	let parser = new DOMParser()
	let cible
	let cibleList = []
	// const c = DomainsItems.getAll(120)
	
	useEffect(() => {
		// Companiestransfers.getAll().then(json => {
		// 	setChoices(json.data.filter(d => d.closed === 0 && d.atg))
		// })
		const fetchData = async () => {
			let choicesData = await DomainsItems.getAll(120)
			setChoices(choicesData.data)
		}
		fetchData().catch(console.error)
	}, [])

	if (promotion != null) {
		let xmlDoc = parser.parseFromString(promotion.rules,"text/xml")
		if(typeof xmlDoc.getElementsByTagName("TARGETS")[0].childNodes[0] !== "undefined") {
			cible = xmlDoc.getElementsByTagName("TARGETS")[0].childNodes[0].nodeValue
			cibleList = cible.split(';')
			if((choices.length !== cibleList.length && cibleList[0] !== "*") && applyToAll)	
				setApplyToAll(false)
			if( !applyToAll && ((choices.length === cibleList.length )  || (cibleList[0] === '*')))
				setApplyToAll(true)
		}
	}
	const applyToStore = (xmlDoc, prom, targets) => {
		const { onPromotionUpdate } = props
		if(choices.length === targets.split(';').length)
			targets ="*"
		const serializer = new XMLSerializer()
		if(xmlDoc.getElementsByTagName("TARGETS")[0]) {
			while (xmlDoc.getElementsByTagName("TARGETS")[0].firstChild) {
				xmlDoc.getElementsByTagName("TARGETS")[0].removeChild(xmlDoc.getElementsByTagName("TARGETS")[0].firstChild)
			}
			xmlDoc.getElementsByTagName("TARGETS")[0].prepend(targets)
			prom.rules = serializer.serializeToString(xmlDoc)		
			onPromotionUpdate(prom)
		}
	}

	const applyToAllItems = () => {
		let parser = new DOMParser()
		const { promotion } = props
		let xmlDoc = parser.parseFromString(promotion.rules,"text/xml")
		let targets
		let prom = promotion
		// const choices = DomainsItems.getItems(120)
		let array = []
 		choices.forEach((elt) =>{
			array.push(elt.itemid)
		})
		
		setApplyToAll(true)
		targets = array.join(';')
		applyToStore(xmlDoc, prom, targets)
	}

	const deselectAllItems = () => {
		let parser = new DOMParser()
		const serializer = new XMLSerializer()

		const { promotion, onPromotionUpdate } = props
		let prom = promotion
		let xmlDoc = parser.parseFromString(promotion.rules,"text/xml")

		if(xmlDoc.getElementsByTagName("TARGETS")[0]) {
			while (xmlDoc.getElementsByTagName("TARGETS")[0].firstChild) {
				xmlDoc.getElementsByTagName("TARGETS")[0].removeChild(xmlDoc.getElementsByTagName("TARGETS")[0].firstChild)
			}		
			prom.rules = serializer.serializeToString(xmlDoc)
			onPromotionUpdate(prom)
			setApplyToAll(false)
		} 
	}

	const updateState = (element, checked) => {
		let parser = new DOMParser()
		const { promotion } = props
		let xmlDoc = parser.parseFromString(promotion.rules,"text/xml")
		let targets
		let prom = promotion

		if(checked ) {
			//var array = []
			var array = []
			if (cibleList[0]==="*"){
				choices.forEach((elt) =>{
					array.push(elt.itemid.toString())
				})
			}else
			array =[...cibleList]
			const index = array.indexOf(element.itemid.toString())
			array.splice(index, 1)
			setApplyToAll(false)
			targets = array.join(";")
			applyToStore(xmlDoc, prom, targets)
			
		} else {
				targets = [...cibleList, element.itemid.toString()]
				if(choices.length !== targets.length || cibleList[0] !=='*')
					setApplyToAll(false)
				else 
					setApplyToAll(true)
				if(cibleList[0]==='*') setApplyToAll(true)
				applyToStore(xmlDoc, prom, targets.join(";"))
		}
	}
	let liste = []
	choices.forEach(element => {
		let checked = false
		let parser = new DOMParser()
		let xmlDoc = parser.parseFromString(element.properties,"text/xml")

		let hapiTarget
		let hapiTargets = xmlDoc.getElementsByTagName("PROPS")[0].getElementsByTagName("PROP")
		if(hapiTargets.length > 0) {
			for(let i = 0; i < hapiTargets.length; i++) {
				if(hapiTargets[i].getAttribute("NAME") === "HAPI_TARGET") {
					hapiTarget = 'TARGET HAPI: '.concat(hapiTargets[i].getAttribute("VALUE"))
				}
			}
		}
		if (typeof cibleList !== 'undefined') {
			cibleList.forEach(value => {
				if (element.itemid === parseInt(value,10) || value === "*") {
					checked = true
				}
			})
			// if(cibleList.length ===0 && !applyToAll){
			// 	cibleList.push('*')
			// 	setApplyToAll(true)

			// }
		
			if (create) {
				liste[element.itemid] = checked 
					? <Tooltip title={hapiTarget} style={{ backgroundColor: "#000"}}><Chip className={classes.chip} clickable onClick={() => updateState(element, checked)} label= { element.label } style={{margin: '.5em'}}/></Tooltip>
					: <Tooltip title={hapiTarget} style={{ backgroundColor: "#000"}}><Chip color="default" clickable onClick={() => updateState(element, checked)} label={ element.label } style={{margin: '.5em'}}/></Tooltip>
			} else {
				liste[element.itemid] = checked 
					? <Tooltip title={hapiTarget} style={{ backgroundColor: "#000"}}><Chip className={classes.chip} label= { element.label } style={{margin: '.5em'}}/></Tooltip>
					: <Tooltip title={hapiTarget} style={{ backgroundColor: "#000"}}><Chip color="default" label={ element.label } style={{margin: '.5em'}}/></Tooltip>
			}
		} else {
			if (create) {
				liste[element.itemid] = <Tooltip title={hapiTarget} style={{ backgroundColor: "#000"}}><Chip color="default" clickable onClick={() => updateState(element, checked)} label= { element.label } style={{margin: '.5em'}}/></Tooltip>
			} else {
				liste[element.itemid] = <Tooltip title={hapiTarget} style={{ backgroundColor: "#000"}}><Chip color="default" label= { element.label } style={{margin: '.5em'}}/></Tooltip>
			}
		}
	})
	return (
		<>
			{ create ? (
				applyToAll ?
					<Tooltip title="Désélectionner tout"
						placement="right-start" 
						TransitionComponent={Zoom}
					> 	
						<Switch
							checked={applyToAll}
							onChange={deselectAllItems}
							color="primary" size="small" 
							inputProps={{ 'aria-label': 'primary checkbox' }}
							disabled={!create}
							className={classes.switch}
						/>
					</Tooltip>
					: 
					<Tooltip title="Sélectionner tout"
						placement="right-start" 
						TransitionComponent={Zoom}
					> 
						<Switch
							checked={applyToAll}
							onChange={applyToAllItems}
							color="primary" size="small" 
							inputProps={{ 'aria-label': 'primary checkbox' }}
							disabled={!create}
							className={classes.switch}
						/>
					</Tooltip> )
				: null
			}
			<div style={{display: 'flex', justifyContent: 'flex-start', flexWrap: 'wrap', marginTop: '.2em', cursor: "pointer"}}>
				<ThemeProvider theme={getMuiTheme()}>
					{ liste }
				</ThemeProvider>
			</div>
		</>
	)	
}

const mapStateToProps = state => ({
	...state,
	promotion: state.reducer ? state.reducer.Promotion.payload : null
})

const mapDispatchToProps = ({
	onPromotionUpdate,
	onGetPromo
})

const enhance = compose(
	connect(mapStateToProps, mapDispatchToProps),
	withStyles(styles)
)

export default enhance(PromotionsCheckBox)
