import { SELECTED_TAB, ON_CHANGE_SELECTED_CRITERE ,  SEARCH_VALUE , ON_LOAD_ALBUMS , TAB_VALUE,PARENT_ID_UPDATE,
	ON_ALBUM_FICHE_UPDATE,ON_ALBUM_EDITO_UPDATE,ON_LOAD_ALL_ALBUMS, ON_ADD_DISSOCIATE_ALBUM ,ON_ASSOCIATE_SOUS_ALBUM_UPDATE,
	ON_ALBUM_PICTURE_UPDATE, ON_LOAD_NEW_IMAGE,ON_IS_IMAGE_PIVOT,ON_ALBUM_MOVIE_UPDATE ,ON_ALBUM_MOVIES_LIST_UPDATE,ON_ALBUM_MOVIES_LIST_FILTER,ON_ALBUM_MOVIES_SHOW_MODE} from '../constants/actions'

export function onLoadAlbums(AlbumsData){
	return {
		type : ON_LOAD_ALBUMS,
		payload : {
			companiesAlbums : AlbumsData
		}
	}
}
export function onLoadAllAlbums(AlbumsData){
	return {
		type : ON_LOAD_ALL_ALBUMS,
		payload : {
			albums : AlbumsData
		}
	}
}
export function OnSelectTab(tab) { 
	return { 
		type: SELECTED_TAB, 
		payload: { 
			tabIndex : tab
		} 
	}
}
export function OnChangeCritere(critere) {
	return { 
		type: ON_CHANGE_SELECTED_CRITERE, 
		payload: { 
			critere : critere
		} 
	}
}
export function OnChangeValue(searchValue) { 
	return { 
		type: SEARCH_VALUE, 
		payload: { 
			searchValue : searchValue
		} 
	}
}
export function OnChangeTab(tabValue) { 
	return { 
		type: TAB_VALUE, 
		payload: { 
			tabValue : tabValue
		} 
	}
}
export function onChangeParent(parentId) { 
	return { 
		type: PARENT_ID_UPDATE, 
		payload: { 
			selectedAlbumParent : parentId
		} 
	}
} 
export function OnUpdateAlbumFiche(album) { 
	return { 
		type: ON_ALBUM_FICHE_UPDATE, 
		payload: { 
			album : album,
		} 
	}
} 
export function OnUpdateAlbumEditorial(edito) { 
	return { 
		type: ON_ALBUM_EDITO_UPDATE, 
		payload: { 
			edito : edito,
		} 
	}
} 
export function OnAddDissociatedAlbum(album ) { 
	return { 
		type: ON_ADD_DISSOCIATE_ALBUM, 
		payload: { 
			album : album,
		} 
	}
} 
export function OnAssociateSousAlbumList(listAlbums) { 
	return { 
		type: ON_ASSOCIATE_SOUS_ALBUM_UPDATE, 
		payload: { 
			listAlbums : listAlbums,
		} 
	}
} 
export function OnAlbumPictureUpdate(albumPicture) { 
	return { 
		type: ON_ALBUM_PICTURE_UPDATE, 
		payload: { 
			albumPicture : albumPicture,
		} 
	}
} 
export function OnLoadNewImage(image) { 
	return { 
		type: ON_LOAD_NEW_IMAGE, 
		payload: { 
			image : image,
		} 
	}
} 
export function SetIsImagePivot(ispivot) { 
	return { 
		type: ON_IS_IMAGE_PIVOT, 
		payload: { 
			ispivot : ispivot,
		} 
	}
} 

export function OnAlbumMovieUpdate(albumMovie) { 
	return { 
		type: ON_ALBUM_MOVIE_UPDATE, 
		payload: { 
			albumMovie : albumMovie,
		} 
	}
}
export function OnAlbumMovieListUpdate(listMovies) { 
 	return { 
		type: ON_ALBUM_MOVIES_LIST_UPDATE, 
		payload: { 
			listMovies : listMovies,
		} 
	}
}  
export function OnUpdateFilterProgrammes(filter) { 
 	return { 
		type: ON_ALBUM_MOVIES_LIST_FILTER, 
		payload: { 
			filter : filter,
		} 
	}
} 
export function OnUpdateProgrammeMode(mode) { 
 	return { 
		type: ON_ALBUM_MOVIES_SHOW_MODE, 
		payload: { 
			mode : mode,
		} 
	}
} 