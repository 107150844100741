import React, { useMemo, useState } from 'react'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import Slide from '@material-ui/core/Slide'
import cyan from '@material-ui/core/colors/cyan'
import { translate, showNotification } from 'react-admin'
import { submit  } from 'redux-form'
import { connect } from 'react-redux'
import compose from 'recompose/compose'
import green from '@material-ui/core/colors/green'
import red from '@material-ui/core/colors/red'
import grey from '@material-ui/core/colors/grey'
import { ProcessLoader } from '../../common'
import { Token, Logger } from '../../../tools'
import Switch from '@material-ui/core/Switch'
import FormControl from '@material-ui/core/FormControl'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Typography from '@material-ui/core/Typography'
import Dropzone from 'react-dropzone'
import { CloudUploadIcon, CloseIcon } from '../../../constants/icons'
import { stringify } from 'query-string'
import { post } from 'axios'
import FileInformationContainer from '../../publicationtracking/fields/dropzone/FileInformationContainer'
import { createTheme, withStyles, ThemeProvider } from '@material-ui/core/styles'
import { CustomSelect, CustomTextField } from '../../layout/GlobalStyles'
 
const Transition = React.forwardRef(function Transition(props, ref) {
	return <Slide direction="up" ref={ref} {...props} />
})

const styles = theme =>  ({
	button: {
		margin: theme.spacing(1),
		width : '150px',
		position: "absolute",
		right: "3%",
		bottom: "3%",
		backgroundColor:'#43a047'
	},
	buttonCancel: {
		margin: theme.spacing(1),
		width : '200px',
		background: '#e23434'
	},
	buttonCheck: {
		margin: theme.spacing(1),
		width : '200px',
		background: '#0c9e0c'
	},
	leftIcon: {
		marginRight: theme.spacing(1),
	},
	dialogHeader:{
        padding: '10px 10px 8px',
        background: cyan[500],
        color: 'white',
        fontSize: 'medium',
        fontWeight: 'bold',
        textAlign: 'center',
    },
	closeIcon:{
		width : '15px',
		position: 'absolute',
    	top: '0px',
    	right: '0px',
		borderRadius: '0',
		color: 'white',
		'&:hover': {
			background: 'red'//red
		}
	},
	dialogContent: {
		padding: '16px 24px 17px',
		flex: '1 1 auto',
		color: 'white',
		fontSize: '1rem',
		fontWeight: '400'
	},
	dialogFooter: {
		flex: '0 0 auto',
		margin: '8px 4px',
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'flex-end'
	},
	formControl: {
		margin: theme.spacing(1),
		minWidth: 120,
	},
	selectEmpty: {
		marginTop: theme.spacing(2),
	},
	customSelect: {
		width : '80%'
	},
	rightIcon: {
		// marginLeft: theme.spacing(1),
	},
	fileAccepted: {
		backgroundColor : green[500],
		marginTop: '5px',
		textAlign : 'center'
	},
	fileRejected: {
		backgroundColor : red[400],
		marginTop: '5px',
		textAlign : 'center'
	},
	fileInformationSection : {
		width : '100%'
	},
	noJsValidation:{
		backgroundColor : grey[200],
		marginTop: '5px',
		textAlign : 'center'
	},
	container: {
		padding: "1.2em"
	},
	switch: {
		margin: "1.2em"
	},
})

const baseStyle = {
	display: 'flex',
	flexDirection: 'column',
	alignItems: 'center',
	padding: '20px',
	borderRadius: '7px',
	backgroundColor: '#b3b3b3',
	color: grey[900],
	outline: 'none',
	transition: 'border .24s ease-in-out'
}

const getMuiTheme = () => createTheme({
	overrides: {
		MuiCardContent: {
			root: {
				padding: "16px"
			}
		},
		MuiButton :{
			containedPrimary: {
			color: '#fff',
			backgroundColor: '#3f51b5'
			
		}}
	}
})

const maxSize = 20971520

const PromotionsAddUserModal = (props) => {
	const { open, onClose, classes, translate, create, title, fileRules, mode, promotion } = props
	
	const [acceptedFiles, setAcceptedFiles] = useState([])
	const [rejectedFiles, setRejectedFiles] = useState([])
	const [isButtonSaveActivated, setIsButtonSaveActivated] = useState(false)
	const [isProcessLoaderOpen, setIsProcessLoaderOpen] = useState(false)
	const [isOperationFinished, setIsOperationFinished] = useState(false)
	const [file, setFile] = useState([])
	const [modeSelected, setModeSelected] = useState(2)

	const isJsValidationEnabled = parseInt(process.env.REACT_APP_ACTIVATE_DROPZONE_JSVALIDATION, 10) === 1 ? true : false
	const [textFieldValues, setTextFieldValues] = useState("")
	const [sendMode, setSendMode] = useState(false)
	const [textFieldValueError, setTextFieldValueError] = useState("")
	const handleOnDrop = (acceptedFiles, rejectedFiles) => {
		if(isJsValidationEnabled){
			if(acceptedFiles && acceptedFiles.length !== 0){
				setTextFieldValues("")
				setFile(acceptedFiles[0])
				setIsButtonSaveActivated(true)
				setAcceptedFiles(acceptedFiles)
				setRejectedFiles([])
			}
			else{
				setTextFieldValues("")
				setIsButtonSaveActivated(false)
				setAcceptedFiles([])
				setRejectedFiles(rejectedFiles)
			}
		}
		else{
			setTextFieldValues("")
			setFile(acceptedFiles[0])
			setIsButtonSaveActivated(true)
			setAcceptedFiles(acceptedFiles)
			setRejectedFiles([])
		}
	}

	const handleCloseProgressBar = () => {
		setIsProcessLoaderOpen(false)
	}
	
	const handleChangeTypeValue = (event) => {
		setModeSelected(event.target.value)
	}

	const handleSaveClick = () => {
		if(textFieldValues.length > 0 &&  sendMode) {
			let textFieldValuesCheck = ""
			/* eslint-disable */
			textFieldValuesCheck = textFieldValues.replace(/[`~!@#$%^&*()_|+\-=?;:'",.<>\{\}\[\]\\\/]/gi, '')
			let isMailValid = true
			// REGEX check mail format
			let values = textFieldValues.split(/\r\n|\r|\n/)
			/* eslint-disable */
			let pattern = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/
			
			values.forEach(v => {
				if(isMailValid) {
					if(!pattern.test(v))
						isMailValid = false
				}
			})
			/* eslint-disable */
			let arr = textFieldValues.split('\n')
				.filter((el) => {
					return el != null && el != ""
				})

			//let text = '<Html>' + arr.forEach(elt => elt + "<br/>") +"</Html>"
			console.log(arr.map(el => el + '\n'))
 			if(modeSelected !== 4 && textFieldValues !== textFieldValuesCheck) {
				setTextFieldValueError("La saisie ne respecte pas les règles ci-dessous.")	
			}
			else if (modeSelected === 4 && !isMailValid) {
				setTextFieldValueError("Une ou plusieurs adresse mail saisies sont incorrect")
			} else {
				var blob = new Blob( arr.map(el => el + '\r\n') , { type: "text/plain"})
				createAndSendFile(blob)
			}
		} else {
			createAndSendFile(file)
		}
	}

	const createAndSendFile = (fileSelected) => {
		const { showNotification } = props

		var formData = new FormData()
		formData.append('files', fileSelected)
		let query = {
			fileName: fileSelected.name,
			triggerBackendValidation : !isJsValidationEnabled,
			typeMode: mode.filter(s=>s.id=== parseInt(modeSelected))[0].Tag,
			promoId: promotion.id

		}
		if (sendMode ){ 
			query = fileSelected
			query.promoId =  promotion.id
			//query.typeMode = parseInt(modeSelected)
			query.typeMode= mode.filter(s=>s.id=== parseInt(modeSelected))[0].Tag

		}
		const url = `${process.env.REACT_APP_API}/Promotionssubscribers/SubscribersUpload?${stringify(query)}`
		const t = Token.getToken()
		const config = {
			headers: {
				'content-type': 'multipart/form-data',
				'Authorization': `Bearer ${t}`
			},
		}
		Logger.info(fileSelected.name)
		console.log(fileSelected)
		//check size first
		if(fileSelected.size > maxSize){
			showNotification(`La taille du fichier est de ${fileSelected.size} octets et est supérieur à la taille autorisée`, 'warning', { autoHideDuration : '6000' })
			setIsButtonSaveActivated(false)
		}
		else{
			setIsProcessLoaderOpen(true)
			setIsOperationFinished(false)

			post(url, formData, config).then((response) => {
				Logger.info(response)
				setIsButtonSaveActivated(false)
				setAcceptedFiles([])
				setRejectedFiles([])
				setIsOperationFinished(true)
			}).catch(error => {
				const { response } = error
				Logger.info(response)
			
				setIsButtonSaveActivated(false)
				setIsOperationFinished(true)
				setIsProcessLoaderOpen(false)

				if(response.status === 801){
					showNotification(`La taille du fichier est de ${fileSelected.size} octets et est supérieur à la taille autorisée`, 'warning', { autoHideDuration : '6000' })
				}
				else if(response.status === 802){
					showNotification('Le fichier n’est pas dans un format reconnu', 'warning', { autoHideDuration : '6000' })
				}
				else{
					showNotification('Erreur lors du transfert du fichier', 'warning', { autoHideDuration : '6000' })
				}
			})
		}
	}

	const handleTextFieldChange = (event) => {
		setFile([])
		setTextFieldValues(event.target.value)
		setTextFieldValueError("")
		if(event.target.value.length > 0) {
			setIsButtonSaveActivated(true)
		} else {
			setIsButtonSaveActivated(false)
		}
	}

	const handleSwitchModeChange = () => {
		setSendMode(!sendMode)
		setFile([])
		setTextFieldValues("")
		setAcceptedFiles([])
		setRejectedFiles([])
		setIsButtonSaveActivated(false)
	}
	// const modalTitle = modalContent.title
	// const saveButtonLabel = 'Transférer'
	// const isJsValidationEnabled = parseInt(process.env.REACT_APP_ACTIVATE_DROPZONE_JSVALIDATION, 10) === 1 ? true : false
	
	const progressModalContent = {
		title : "IMPORT UTILISATEURS",
		closeButtonText : "FERMER",
		operationFinished : sendMode ? 'La liste des identifiants a été transférée avec succès' :  translate('messages.uploadSuccess')
	}

	const SendModeSwitch = () => (
		<FormControl component="fieldset" className={classes.switch}>
			<FormControlLabel
				value="top"
				control={
					<Switch checked={sendMode}
						onChange={handleSwitchModeChange}
						color="primary" size="small" 
						inputProps={{ 'aria-label': 'primary checkbox' }}
					/>
				}
				label={<span style={{ fontSize: '.8em', marginLeft: '.5em'}}>Passer en mode saisie manuelle des identifiants ?</span>}
				labelPlacement="right"
			/>
		</FormControl>
	)

	return (
		<div>
			<Dialog
				open={open}
				TransitionComponent={Transition}
				keepMounted
				onClose={onClose}
				fullWidth={false}
       			maxWidth="lg"
				aria-labelledby="alert-dialog-slide-title"
				aria-describedby="alert-dialog-slide-description" 
				PaperProps={{ style: {
					minHeight: '40vh',
					maxHeight: '95vh',
					minWidth: '40vw',
				}}}
			>
					<div className={classes.dialogHeader}>
						{ title }
					<Button onClick={onClose}  className={classes.closeIcon} >
						<CloseIcon/>				 
					</Button>	
					</div>
					<ThemeProvider theme={getMuiTheme()}>
						<div className={classes.container}>	
							<CustomSelect label="Type d'identifiant" values={mode} 
								value={modeSelected} 
								handleChange={handleChangeTypeValue}
							/>
							<SendModeSwitch />
							{ !sendMode && <>
								<Dropzone 
									onDrop={handleOnDrop}
									multiple = {false}
									accept="text/plain"
									disabled={sendMode}
								>
									{({getRootProps, getInputProps, isDragActive, isDragReject, rejectedFiles, isDragAccept, acceptedFiles, draggedFiles }) => {
										const style = useMemo(() => ({
											...baseStyle,
											...(isDragActive ? baseStyle : {}),
											...(isDragAccept ? baseStyle : {}),
											...(isDragReject ? baseStyle : {}),
											// backgroundColor: !sendMode ? "#00BCD4" : "#E0E0E0",
											// color: "#fff",
											borderRadius: "5px"
										}), [
											isDragActive,
											isDragReject,
											sendMode
										])
										return (
											<React.Fragment>
												<div {...getRootProps({style})}>
													<input {...getInputProps()} />
													{!isDragActive && 'Glissez votre fichier* ici ou cliquez pour sélectionner le fichier à transférer'}
													{isDragActive && !isDragReject  && 'Fichier prêt à être transféré!'}
													{isDragReject && 'Fichier prêt à être transféré!'}
												</div>
											</React.Fragment>
										)}
									}
								</Dropzone>
								<FileInformationContainer classes={classes} acceptedFiles={acceptedFiles} rejectedFiles={rejectedFiles} isJsValidationEnabled={isJsValidationEnabled}/>
								<div>
									<Typography variant="caption" gutterBottom style={{ marginBottom: "1em"}}>
										{fileRules}
									</Typography>
									<div>
										<Typography variant="caption" gutterBottom >
											Chaque ligne ne doit contenir qu'une seule valeur et aucun séparateur.
										</Typography>
									</div>
								</div>
								<br /><br /><br />
								
							</> }
							{ sendMode && <>
								<CustomTextField value={textFieldValues} errorLabel={textFieldValueError} multiline={true} disabled={!sendMode} fullWidth={true} rows={6} handleChange={handleTextFieldChange} create={!create} />
								<div style={{ padding: "0 0 3em .5em"}}>
									<Typography variant="caption" gutterBottom >
										Chaque ligne ne doit contenir qu'une seule valeur et aucun séparateur.
									</Typography>
								</div>
							</> }
							<Button onClick={handleSaveClick} color='primary' variant="contained" className={classes.button} disabled={!isButtonSaveActivated}>
								<CloudUploadIcon className={classes.leftIcon} />
								TRANSFERER
							</Button>
						
						</div>
					</ThemeProvider>
				<ProcessLoader modalOpen = {isProcessLoaderOpen} 
					onClose={handleCloseProgressBar}  
					isOperationFinished = { isOperationFinished }
					progressModalContent = {progressModalContent}
				/>
			</Dialog>
		</div>
	)
}

const mapStateToProps = state => ({
	...state,
	promotion: state.reducer ? state.reducer.Promotion.payload : null
})

const mapDispatchToProps = ({
	submit,
	showNotification
})

const enhance = compose(
	connect(mapStateToProps, mapDispatchToProps),
	withStyles(styles)
)

export default translate(enhance(PromotionsAddUserModal))
