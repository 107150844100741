import React, { useEffect } from 'react'
import { GET_LIST, GET_ONE} from 'react-admin'
import { compose } from 'redux'
import { connect } from 'react-redux'

import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import Badge from '@material-ui/core/Badge';
import { Button, DialogContent } from '@material-ui/core'
import BurstModeIcon from '@material-ui/icons/BurstMode';
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContentText from '@material-ui/core/DialogContentText'
import FiberNewIcon from '@material-ui/icons/FiberNew';
import GridList from '@material-ui/core/GridList'
import GridListTile from '@material-ui/core/GridListTile'
import DeleteIcon from '@material-ui/icons/Delete'
import withWidth, { isWidthUp } from '@material-ui/core/withWidth'
import LinearProgress from '@material-ui/core/LinearProgress'
import { withStyles } from '@material-ui/core/styles'

import dataProvider from '../../../dataProvider'
import { AlbumsPictures, MoviesPictures } from '../../../requests'
import { ImageField } from '../../common/fields'
import { Links } from '../../../tools'
import styled from 'styled-components'
import cyan from '@material-ui/core/colors/cyan'
import { CloseIcon, CancelIcon, CheckIcon } from '../../../constants/icons'
import { onPromotionUpdate } from '../../../actions/Promotion'

const styles = (theme) => ({
	dialogHeader:{
        padding: '10px 10px 8px',
        background: cyan[500],
        color: 'white',
        fontSize: 'medium',
        fontWeight: 'bold',
        textAlign: 'center',
    },
	closeIcon:{
        width : '15px',
        position: 'absolute',
        top: '0px',
        right: '0px',
        borderRadius: '0',
        color: 'white',
        '&:hover': {
            background: 'red'
        }
    },
	buttonCancel: {
		width : '150px',
		background: '#e23434',
	},
	buttonCheck: {
		background: '#0c9e0c',
		width : '150px',
		margin: theme.spacing(1),
	},
	leftIcon: {
		marginRight: '.5em',
	},
	button: {
		backgroundColor : '#288DB1',
		'&:hover': {
			background: '#1c627b'
		}
	},
})

const buttonHorizontalScrollLeft = {
	height: '97%',
	background: 'none',
    border: 'none',
}

const buttonHorizontalScrollRight = {
	height: '97%',
	background: 'none',
    border: 'none',
}

const HoverButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  height: 3em;
  width: 3em;
  &:hover {
	transition-duration: 0.1s;
	background-color: #ebedf7;
	z-index: 1
  }
  &:active {
	background-color: #d8dcf0;
	opacity: 1;
	box-shadow: 0 0 5px 5px #ebedf7;
  }
`

const HoverElement = styled.div`
	position: relative;
	width: 100%;
	&:before {
		content: "";
		position: absolute;
		top: 0;
		left: 0;
		display: block;
		width: 100%;
		height: 97%;
		background: rgba(0,0,0,0.6);
		z-index: 2;
		transition: 0.5s all;
		opacity: 0;
	};
	&:hover:before {
		opacity: 1
	};
`

const Overlay = styled.div`
	position: absolute;
	bottom: 1em;
	width: 100%;
	z-index: 3;
	opacity: 0;
	text-align: center;
	font-size: 1em;
	${HoverElement}:hover & {
		transform: translateY(-5px);
		transition-duration: 0.5s;
		color: white;
		opacity: 1
	}
`

const newIconStyle = {
	position: 'absolute',
	top: '2px',
	left: '2px',
	zIndex: 10,
	backgroundColor: '#FFF',
	border: '1px solid #DBDBDB',
	borderRadius: 3,
	margin: 0
}

const deleteIconStyle = {
	position: 'absolute',
	top: '2px',
	right: '2px',
	zIndex: 10,
	backgroundColor: '#FFF',
	border: '1px solid #DBDBDB',
	borderRadius: 3,
	margin: 0
}

const PromotionsProgrammes = (props) => {

	let {create, type, info, isUnmounted, value, width, classes } = props

	const [addedState, setAdded] = React.useState('')
	const [open, setOpen] = React.useState(false)
	const [openDelete, setOpenDelete] = React.useState(false)
	const [movieidDelete, setMovieidDelete] = React.useState('')
	const [isNewDelete, setIsNewDelete] = React.useState(false)
	const [titleDelete, setTitleDelete] = React.useState('')
	const [display, setDisplay] = React.useState([])
	
	let tempElement = []
	let element = []
	let picturesLoad = []
	const ref = React.createRef()
	let sum = 0
	let totalMovies = 0

	const loadPictures = async (value) => {
		if ((value !== undefined && value !== null && value.length > 0)) {
			props.info === 'programmes' 
				? await loadMoviesPictures(value) 
				: await loadAlbumsPictures(value)

			
			tempElement.push(createList(create, value, type, info, picturesLoad))

			// if(picturesLoad.length > 0) {
			// 	tempElement.push(createList(create, value, type, info, added))
			// }
			return tempElement[0]
		} 
	}

	const loadMoviesPictures = async (value) => {
		for (let index = 0; index < value.length; index++) {
			let element = value[index].getAttribute('ID')
			let data
			await MoviesPictures.getById(element).then(json => {
				data = JSON.parse(JSON.stringify(json.data)).filter(pic => pic.typeid === 1)[0]  //63
				if(data instanceof Object) {
					let arr = [...picturesLoad, data]
					picturesLoad = arr		 
				}
			})
		}

		for (let index = 0; index < value.length; index++) {
			let element = value[index].getAttribute('ID')
			if(picturesLoad.filter(p => p.movieid == element).length === 0) {
				await loadMoviesInfos(element)
			}
		}
	}

	const loadMoviesInfos = async (id) => {
		await dataProvider(GET_LIST, 
			"Companiesmovies", 
			{ pagination: { page: 1 , perPage: 100 },
				sort: { field: {}, order: {} }, 
				filter: {q : id, mode: 1}}).then(resp => {
					if (!isUnmounted) {
					let arr = [...picturesLoad, { movieid: resp.data[0].id, title: resp.data[0].value} ]
					picturesLoad = arr	
				} 
		})
	}

	const loadAlbumsPictures = async (value) => {
		for (let index = 0; index < value.length; index++) {
			let element = value[index].getAttribute('ID')
			let data
			await AlbumsPictures.getById(element).then(json => {
				data = JSON.parse(JSON.stringify(json.data)).filter(pic => pic.typeid === 1)[0]
				if(data instanceof Object) {
					let arr = [...picturesLoad, data]
					picturesLoad = arr
				}
			})
		}
		for (let index = 0; index < value.length; index++) {
			let element = value[index].getAttribute('ID')
			if(picturesLoad.filter(p => p.albumid == element).length === 0) {
				await loadAlbumsInfos(element)
			}
		}
	}

	const loadAlbumsInfos = async (id) => {
		await dataProvider(GET_ONE,
			"Companiesalbums", { id: id }).then(resp => {
				if (!isUnmounted) {
					let arr = [...picturesLoad, { albumid: resp.data.id, title: resp.data.name} ]
					picturesLoad = arr
				}
		})
	}


	const handleClose = () => {
		setOpen(false)
	}
	
	const handleClickOpen = () => {
		setOpen(true)
	}

	const handleCloseDelete = () => {
		setOpenDelete(false)
	}
	
	const handleClickOpenDelete = () => {
		setOpenDelete(true)
	}

	const handleChangeProgrammesValue = (value) => {
		let parser = new DOMParser()
		const { promotion, onPromotionUpdate } = props
		let xmlDoc = parser.parseFromString(promotion.rules,"text/xml")

		const serializer = new XMLSerializer()
		let prom = promotion
		
		if(xmlDoc.getElementsByTagName("MIDS")[0] && info === 'programmes') {
			let v = xmlDoc.getElementsByTagName("MIDS")[0]
			for(var i = 0; i < v.childNodes.length ; i++) {
				if(parseInt(v.getElementsByTagName("MID")[i].getAttribute("ID")) === parseInt(value))
					v.removeChild(v.getElementsByTagName("MID")[i])
			}
			prom.rules = serializer.serializeToString(xmlDoc).replace(/&lt;/g, '<').replace(/&gt;/g, '>')
			onPromotionUpdate(prom)
		}

		if(xmlDoc.getElementsByTagName("AIDS")[0] && info === 'albums') {
			let v = xmlDoc.getElementsByTagName("AIDS")[0]
			for(var i = 0; i < v.childNodes.length ; i++) {
				if(parseInt(v.getElementsByTagName("AID")[i].getAttribute("ID")) === parseInt(value))
					v.removeChild(v.getElementsByTagName("AID")[i])
			}
			prom.rules = serializer.serializeToString(xmlDoc).replace(/&lt;/g, '<').replace(/&gt;/g, '>')
			onPromotionUpdate(prom)
		}
	}

	const deleteFromList = (movieid) => {
		let newArr = []
		let index = 0;
		handleChangeProgrammesValue(movieid)

		while(index < addedState.length){
			if (parseInt(addedState[index].getAttribute('ID')) !== parseInt(movieid)) {
				newArr.push(addedState[index])
				index++;
			}else{
				index++;
			}
		}
		handleCloseDelete()
	}

	const handleDelete = (movieid, title, isNew) => {
		setMovieidDelete(movieid)
		setTitleDelete(title)
		handleClickOpenDelete()
	}

	const handleShowClick = (id) => {
		if(info === "programmes")
			window.open('/#' + Links.CompaniesmoviesShow(id), "_blank")
		else if(info === "albums")
			window.open('/#' + Links.CompaniesalbumsShow(id), "_blank")

	}

	const createList = (create, listValue = null, type = 'albums', info, picturesLoaded) => {
		let liste = []
		for (let index = 0; index < listValue.length; index++) {
			let selectPicture = []

			let element = listValue[index].getAttribute('ID')

			if(picturesLoaded.length > 0 && picturesLoaded[0] !== undefined)
			{
				if(info === 'programmes')
				{
					let value = picturesLoaded.filter(pic => pic.movieid && parseInt(pic.movieid, 10) === parseInt(element, 10))[0]
					if(value !== undefined)
						selectPicture.push(value)
				} else if(info === 'albums') {
					let value = picturesLoaded.filter(pic => pic.albumid && parseInt(pic.albumid, 10) === parseInt(element, 10))[0]
					if(value !== undefined)
						selectPicture.push(value)

				}
				if(selectPicture !== undefined && selectPicture.length > 0) {
					liste.push(
						selectPicture.map((pic, index) => {
							return ( 
								// info === 'programmes' ? 
									<GridListTile style={{ marginRight: '.2em', height: '100% !important', width: "auto", cursor: "pointer" }}>
										{create ? <DeleteIcon style={deleteIconStyle} color="primary" onClick={() => handleDelete(info === 'programmes' ? pic.movieid : pic.albumid, pic.title, pic.isNew)} fontSize="small" /> : null}
											{ create && pic.isNew ? <FiberNewIcon style={newIconStyle} color="primary" fontSize="small" /> : null }
											<HoverElement onClick={() => handleShowClick(info === 'programmes' ? pic.movieid : pic.albumid)}> 
												<ImageField source="filename"
													record={pic}
													basePath={info === 'programmes' ? "/Moviespictures" : "/Albumspictures"}
													styleImg="imagesPromotions"/>
												<Overlay>{pic.title} ({info === 'programmes' ? pic.movieid : pic.albumid})</Overlay>
											</HoverElement>
									</GridListTile>	
							)}
						)	
					)
				}
			}
		}
		return liste
	}

	const scroll = (e, ref, scrollOffset) => {
		e.preventDefault()
		ref.current.scrollLeft += scrollOffset;
	}

	const getGridListCols = () => {
		if (isWidthUp('xl', width)) {
			return 6
		}
		if (isWidthUp('lg', width)) {
			return 6
		}
		if (isWidthUp('md', width)) {
			return 3
		}
		if (isWidthUp('sm', width)) {
			return 2
			}
		return 1
	}

	useEffect(() => {
		const fetchData = async () => {
			let temp = await loadPictures(value)
			setDisplay(temp)
		}
		fetchData().catch(console.error)
	}, [value])

	sum = (value ? value.length : 0) 
	totalMovies = (value ? value.length : 0) 
	element = display
	if(parseInt(sum) === 0) {
		element = (<span style={{marginLeft: '4em', fontWeight: 400, height: '2em'}}>Aucune donnée</span>)
	} else {
		if(parseInt(display ? display.length : 0) === parseInt(sum)) {
			element = display
		} else {
			element = (<LinearProgress style={{height: '.2em', width: '100%', marginTop: '1em'}} />)
		}
	}


	return (
		<div>
			<div style={{ position:'relative' }}>
				<Badge badgeContent={totalMovies} color="primary" style={{ position:'absolute', top: '-2.5rem', right: 15}}>
					<Button size="small" variant="contained" color="primary" onClick={handleClickOpen} className={classes.button}>
						<BurstModeIcon />
					</Button> 
				</Badge>
				<Dialog onClose={handleClose} fullWidth={true} maxWidth={"md"} aria-labelledby="customized-dialog-title" open={open}>
					<div className={classes.dialogHeader}>
						{info.toUpperCase()}
						<Button onClick={handleClose}  className={classes.closeIcon} >
							<CloseIcon/>				 
						</Button>
					</div>
					<DialogContent dividers>
						<GridList spacing={3} cols={ getGridListCols() }>
							{ element }
						</GridList>
					</DialogContent>
					<DialogActions>
						<Button onClick={handleClose} color="secondary" variant="contained" className={classes.buttonCancel}>
							<CancelIcon className={classes.leftIcon} />
							FERMER
						</Button>
					</DialogActions>
				</Dialog>
			</div>
			<Dialog
				open={openDelete}
				onClose={handleCloseDelete}
				aria-labelledby="alert-dialog-title"
				aria-describedby="alert-dialog-description"
			>
				<div className={classes.dialogHeader}>
					SUPPRIMER ?
					<Button onClick={handleCloseDelete}  className={classes.closeIcon} >
						<CloseIcon/>				 
					</Button>
				</div>
				<DialogContent>
					<DialogContentText id="alert-dialog-description">
						{titleDelete} ({movieidDelete})							
					</DialogContentText>
				</DialogContent>
				<DialogActions>
						<Button onClick={() => deleteFromList(movieidDelete, isNewDelete)} color="primary" variant="contained" className={classes.buttonCheck}>
							<CheckIcon className={classes.leftIcon} />
							Valider
						</Button>
						<Button onClick={handleCloseDelete} color="secondary" variant="contained" className={classes.buttonCancel}>
							<CancelIcon className={classes.leftIcon} />
							Annuler
						</Button>
				</DialogActions>
			</Dialog>
			<div style={{ display: 'flex', justifyContent: 'flex-start', overflow: 'hidden', marginLeft: '-1.6em', marginRight: '-1.6em' }}>
				<div>
					{ 
						parseInt(sum) > 0 ?
						<button type="button" style={ buttonHorizontalScrollLeft } onClick={(e) => scroll(e, ref, -1500)}>
							<HoverButton>
								<ChevronLeftIcon color="primary" />
							</HoverButton>
						</button>
						: null
					}
				</div>
					<GridList ref={ref} style={{ flexWrap: 'nowrap', transform: 'translateZ(0)', width: '100%', overflow: 'hidden', scrollBehavior: 'smooth' }} cols={8}>
						{ element }
					</GridList>
				<div>
					{ 
						parseInt(sum) > 0 ?
						<button type="button" style={buttonHorizontalScrollRight} onClick={(e) => scroll(e, ref, 1500)}>
							<HoverButton>
								<ChevronRightIcon color="primary" />
							</HoverButton>
						</button>
						: null
					}
				</div>
			</div>
		</div>
	)
}

const mapStateToProps = state => ({
	...state,
	promotion: state.reducer ? state.reducer.Promotion.payload : null,
})

const mapDispatchToProps = ({
	onPromotionUpdate
})

const enhance = compose(
	withWidth(),
	connect(mapStateToProps, mapDispatchToProps),
	withStyles(styles),
)

export default enhance(PromotionsProgrammes)

