import React, { cloneElement, Component } from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import { Link } from 'react-router-dom'
import MenuItem from '@material-ui/core/MenuItem'
import Avatar from '@material-ui/core/Avatar'
import compose from 'recompose/compose'
import { connect } from 'react-redux'
import { withStyles } from '@material-ui/core/styles'

const styles =  (theme) => ({
	root: {
		color: '#39a5cb',
		display: 'flex',
		alignItems: 'flex-start',
		"&$focused": {
			color: '#fff',
			backgroundColor: '#39a5cb'
		},
	},
	active: {
		color: '#000',
		backgroundColor: "#288DB1"
	},
	
	icon: { paddingRight: '1.3em' },
	iconRight: { marginLeft: '1.2em', backgroundColor: '#2979ff', fontWeight: 600 },
	avatar: { width: 20, height: 20, fontSize: '0.6rem', color: '#fff' }
})
export class MenuItemLink extends Component {
	constructor(props) {
		super(props)		
	}
	static propTypes = {
		classes: PropTypes.object.isRequired,
		//className: PropTypes.string,
		//leftIcon: PropTypes.node,
		onClick: PropTypes.func,
		primaryText: PropTypes.string,
		to: PropTypes.string.isRequired,
		rightIcon: PropTypes.bool,
		count: PropTypes.string,
	};

	handleMenuTap = () => {
		this.props.onClick && this.props.onClick()
	};

	render() {
		const {
			classes,
			className,
			primaryText,
			leftIcon,
			rightIcon,
			...props
		} = this.props
		let avatar = null
		if (this.props.count && !isNaN(this.props.count)) {
			avatar = <Avatar aria-label="Recipe" className={classes.iconRight} classes={{ root: classes.avatar }}>
				{this.props.count}
			</Avatar>
		}
		return (
			<MenuItem
				{...props}
				className={classnames(classes.root, className)}
				activeclassname={classes.active}
				style= {{
					height: '50px',
					color: this.props.theme === 'dark' ? "#fff" : "#000", 
					backgroundColor: this.props.theme === 'dark' ? "#232c31" : "#eeeeee", 
				}}
				component={Link}
				onClick={this.handleMenuTap}
			>	
				{leftIcon && (
					<span className={classes.icon}>
						{cloneElement(leftIcon, { titleAccess: primaryText })}
					</span>
				)}
				{primaryText}
				{rightIcon && avatar}
			</MenuItem>
		)
	}
}
const mapStateToProps = state => ({
	...state,
})
const mapDispatchToProps = ({
 	// classes: PropTypes.object.isRequired,
 	className: PropTypes.string,
})
const enhance = compose(
	connect(
		mapStateToProps,
		mapDispatchToProps
	),
	withStyles(styles)
)
export default enhance(MenuItemLink)
//export default withStyles(styles)(MenuItemLink)
