import React, { Component } from 'react'
import FormControl from '@material-ui/core/FormControl'
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'
import { connect } from 'react-redux'
import compose from 'recompose/compose'
import { Publicationstracking } from '../../../requests'
import { Contacts as ContactsTools } from '../../../tools'
import { Logger } from '../../../tools'
import { withStyles } from '@material-ui/core/styles'
import { CustomAlert,CustomAlertInfo } from '../../layout/GlobalStyles'

const styles = theme => ({
	formControl: {
		margin: theme.spacing(1),
		width: '95px',
		padding: '2px 2px 2px 2px',
		textAlign: 'start'
	},
	selectEmpty: {
		marginTop: theme.spacing(2),
	},
	customSelect: {
		width: '100%'
	},
})

class StatusCell extends Component {
	constructor(props) {
		super(props)
		this.state = {
			open: false,
			selectedvalue: 0,
			prepStatus: 0,
			frontStatus: 0,
			updatedPublications: [],
			upload: false ,
			timer:10
		}
	}
	componentDidMount = () => {
		const { selectedStatus, statusName } = this.props
		this.setState({ selectedvalue: selectedStatus })
		if (statusName === 'Prepa')
			this.setState({ prepStatus: selectedStatus })
		if (statusName === 'Front')
			this.setState({ frontStatus: selectedStatus })
		this.setState({upload : false})

	}
	componentDidUpdate(previousProps, previousState){
		const { selectedStatus, statusName } = this.props
		if(this.props !== previousProps)
		{this.setState({ selectedvalue: selectedStatus })
		if (statusName === 'Prepa')
			this.setState({ prepStatus: selectedStatus })
		if (statusName === 'Front')
			this.setState({ frontStatus: selectedStatus })
		this.setState({upload : false})
		}
	}

	buildPlanningList = async (jsonData, onLoadPlanning) => {
		const trackingData = jsonData
		let planningToBeSaved = {
			publicationsplannings: trackingData.publicationsplannings,
			publicationsstats: []
		}
		const users = ContactsTools.getContactsByGroupid(160)
		if (trackingData.publicationsstats !== null && trackingData.publicationsstats.length !== 0) {
			trackingData.publicationsstats.forEach(item => {
				const currentUser = users.find(u => u.id === item.contactId)
				const stat = {
					contactName: currentUser? currentUser.name : '',
					publicationsCount: item.publicationsCount,
					publicationPercent: Number((item.publicationsCount * 100 / trackingData.publicationsplannings.length).toFixed(1))
				}
				planningToBeSaved.publicationsstats.push(stat)
			})
		}
		onLoadPlanning(planningToBeSaved)
	}
 
	handleChange = async (event) => {
		const {  planningId,statusName,updatestate,updateTimerCount} = this.props
		this.setState({ selectedvalue: event.target.value })
  		this.setState({upload :true})
		// updateTimerCount(true)
		let publication = {
			PlanningId: planningId,
			CommentLevelId: 1,
			trackingType: statusName,
			trackingValue: event.target.value,
			...(statusName === 'Prepa' && { preparationstatusid: event.target.value }),
			...(statusName === 'Front' && { frontStatusId: event.target.value }),
		}
		new Promise((resolve, reject) => {
			Publicationstracking.savePublication(publication)
				.then((json) => {
					updatestate()		
					resolve()
					// clearInterval(timerInterval)
					//updateTimerCount(false)
					//localStorage.setItem('counter','stop')

				}
				).catch((error) => {
					Logger.debug(error)
					reject()
					// clearInterval(timerInterval)
					//localStorage.setItem('counter', 'stop')
					this.setState({upload :false})


				})
		})

	}
	handleCloseAlert = () => {
		this.setState({ upload: false })
	}
	render() {
		const { classes,planningId, statusTypes, selectedStatus, responsibleId, statusName } = this.props
		// const currentStatuses = statusTypes.filter(s => s.trackingGroupDomainItemId === trackingGroupItemId)
		return (
			<React.Fragment>
 				<FormControl className={classes.formControl}>
					<Select
						id={`${planningId}-${statusName}`}
						value={statusName === 'Prepa' || statusName === 'Front' ? selectedStatus : this.state.selectedvalue}
						onChange={event => this.handleChange(event)}
						className={classes.customSelect}
						disabled={(responsibleId === 999999 ? true : false) || (statusName === 'Prepa') || (statusName === 'Front')}
					>
						{statusTypes.map((value, index) => {
							return <MenuItem value={value.itemid} color={value.color} disabled={value.system === 1}>{value.label}</MenuItem>
						})}
					</Select>
				</FormControl>
				{this.state.upload &&
				(<CustomAlertInfo libelle="Mise à jour des status ..." severity="info" stateid={`${planningId}-${statusName}`} 
				open={this.state.upload} setOpen={this.handleCloseAlert} timer ={true}/>)}

 			</React.Fragment>
		)

	}
}

const enhance = compose(
	connect(null, null),
	withStyles(styles)
)

export default (enhance(StatusCell))