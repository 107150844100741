import Api from './Api'

const Properties = {
	// searchProperties : () =>
	// 	Api.Requests.getAll('/Properties/Search', )
	getPropertiesByObjName : (objectId,name) =>
		Api.Requests.getAll('/Properties/GetByrefIdName?objectId='+objectId+'&name='+name, )

}

export default Properties