import React, { createContext, useState, useEffect } from 'react';

// Create the ConfigContext
export const ConfigContext = createContext();

export const ConfigProvider = ({ children }) => {
    const [config, setConfig] = useState(null);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const fetchConfig = async () => {
            try {
                const response = await fetch(`${process.env.REACT_APP_VERSION_API}/config`, {
                    method: 'GET',
                    headers: new Headers({
                        'Content-Type': 'application/json',
                    }),
                });
                const result = await response.json();
                setConfig(result.data.value); // Assuming the config data is in 'data'
				console.log(result.data)
            } catch (error) {
                console.error('Error fetching config:', error);
            } finally {
                setLoading(false);
            }
        };

        fetchConfig();
    }, []);

    // Provide the configuration and loading state to children components
    return (
        <ConfigContext.Provider value={{ config, loading }}>
            {children}
        </ConfigContext.Provider>
    );
};
