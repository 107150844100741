import ra_fr from 'ra-language-french'
import { fr } from './i18n'

const i18nProvider = locale => {
	if (locale === 'en') {
		return import('./i18n/en').then(messages => messages.default)
	}

	// Always fallback in french
	const messages = { fr: { ...ra_fr, ...fr } }
	return messages[locale]
}

export default i18nProvider