import React, { Component } from 'react'
import PropTypes from 'prop-types'
import SmallIconButton from './SmallIconButton'
import { PictureIcon } from '../../../constants/icons'

class PictureIconButton extends Component {
	render() {
		const { movieid, peopleid, classes } = this.props
		return (
			peopleid ?
				<SmallIconButton peopleid={peopleid} classes={classes} label='pluralizations.pictures' basePath='/Peoplespictures'>
					<PictureIcon />
				</SmallIconButton> :
				<SmallIconButton movieid={movieid} classes={classes} label='pluralizations.pictures' basePath='/Moviespictures'>
					<PictureIcon />
				</SmallIconButton>
		)
	}
}

PictureIconButton.propTypes = {
	movieid: PropTypes.number,
	peopleid: PropTypes.number,
	classes: PropTypes.object.isRequired,
}

export default PictureIconButton