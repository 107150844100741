import React from 'react'
import TableFooter from '@material-ui/core/TableFooter'
import TableRow from '@material-ui/core/TableRow'
import TableCell from '@material-ui/core/TableCell'
import MuiTablePagination from '@material-ui/core/TablePagination'
import { withStyles } from '@material-ui/core/styles'

const defaultFooterStyles = {
}

class CustomFooter extends React.Component {

	handleRowChange = event => {
		this.props.changeRowsPerPage(event.target.value);
	}

	handlePageChange = (_, page) => {
		this.props.changePage(page)
	}

	render() {
		const { count, textLabels, rowsPerPage, page } = this.props;

		const footerStyle = {
			display:'flex', 
			justifyContent: 'flex-end',
			padding: '0px 24px 0px 24px',
			
		};
		const paginationstyle ={
			position: 'sticky',
			right: '0px',
			bottom: '0px',
		}

		return (
			<TableFooter>
				<TableRow>
					<TableCell style={footerStyle} colSpan={1000}>
						{/* <button>Custom Option</button> */}
			
						<MuiTablePagination style={paginationstyle}
							component="div"
							count={count}
							rowsPerPage={rowsPerPage}
							page={page}
							labelRowsPerPage={textLabels.rowsPerPage}
							labelDisplayedRows={({ from, to, count }) => `${from}-${to} ${textLabels.displayRows} ${count}`}
							backIconButtonProps={{
								'aria-label': textLabels.previous,
							}}
							nextIconButtonProps={{
								'aria-label': textLabels.next,
							}}
							rowsPerPageOptions={[20,50,100,{ value: count, label: 'Tout' }]}
							onPageChange={this.handlePageChange}
							onRowsPerPageChange={this.handleRowChange}
						/>
					</TableCell>
				</TableRow>
			</TableFooter>
		)
	}

}

export default withStyles(defaultFooterStyles, { name: 'CustomFooter' })(CustomFooter)