/* eslint-disable indent */
import React, { Component } from 'react'
import {  showNotification, translate } from 'react-admin'
import StoryModal from './StoryModal'
import { connect } from 'react-redux'
import compose from 'recompose/compose'
import IconButton from '@material-ui/core/IconButton'
import VisibilityIcon from '@material-ui/icons/Visibility'
import FiberNewIcon from '@material-ui/icons/FiberNew'
import { withStyles } from '@material-ui/core/styles'
import PublicationTrackingReferenceData from './../../../tools/PublicationTrackingReferenceData'

const styles = theme => ({
	validateButton: {
		// margin: theme.spacing(1),
		width : '10%',
		color : 'green',
		padding: '0px'
	}
})

class ValidateUpdate extends Component{
	constructor(props) {
		super(props)
		this.state = {
			modalOpen : false,
			categorie:[],
			tasktype:[],
			version:[],
			format : []
		}
	}

	handleValidateButtonClick = () => {
		this.setState({modalOpen: true})
	}

	handleClose = () => {
		this.setState({modalOpen: false})
	}
	componentDidMount(){
		this.setState({ categorie : PublicationTrackingReferenceData.getCategories() }) 
		this.setState({tasktype : PublicationTrackingReferenceData.getTasksTypes() })
		this.setState({version : PublicationTrackingReferenceData.getVersions()})
		this.setState({format : PublicationTrackingReferenceData.getFormats() })
	}
	render(){
		const { movieId,  translate, movieTitle, validationType } = this.props
		const modalContent = {
			title : translate('titles.importPlanning'),
			//description : translate('messages.importPlanningInstruction'),
			disagreeButtonText : translate('ra.action.close'),
			agreeButtonText: translate('ra.action.confirm')
		}
		if(validationType === 'update')
		{
			return(
			<React.Fragment>
				<IconButton onClick={this.handleValidateButtonClick} color = "red" variant="contained" aria-label="validate update" component="span">
					<VisibilityIcon />
				</IconButton>
				<StoryModal {...this.props}
					modalOpen = {this.state.modalOpen} 
					onClose={this.handleClose} 
					modalContent = {modalContent} 
					launchActivationProcess = { this.handleSubmit } 
					rowId = {movieId}
					movieTitle = {movieTitle}
					validationType = {validationType}
					categorie ={this.state.categorie}
					tasktype = {this.state.tasktype}
					version = {this.state.version}
					format  = {this.state.format}
					/>
			</React.Fragment>
		)
		}
		else{
			return(
				<IconButton color = "primary" variant="contained" aria-label="" component="span">
					<FiberNewIcon  />
				</IconButton>
			)
		}
	}
}

const mapStateToProps = state => ({
	...state,
	weekNumber: state.reducer.publicationTracking.weekNumber !==null ? state.reducer.publicationTracking.weekNumber : 16,
	yearNumber: state.reducer.publicationTracking.yearNumber !==null ? state.reducer.publicationTracking.yearNumber : 2020
})

const mapDispatchToProps = ({
	showNotification,
})

const enhance = compose(
	connect(mapStateToProps, mapDispatchToProps),
	withStyles(styles)
)

export default translate(enhance(ValidateUpdate))