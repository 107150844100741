import React from 'react'
import PropTypes from 'prop-types'
import { getId, getValue } from '../../../tools/utils'
import Tooltip from '@material-ui/core/Tooltip'

const NameField = props => {
	const { addId, addLabel, sourceId, sourceLabel, text, style, className,toolTipMsg } = props

	if (!addId && !addLabel && null === text)
		return null

	let name = text
	if (!name) {
		if (addLabel) {
			name = getValue(props, sourceLabel)
		}
		if (addId) {
			const id = getId(props, sourceId)
			if (addLabel) {
				name += ` (${id})`
			}
			else {
				name = id
			}
		}
	}

	return (	
	toolTipMsg && toolTipMsg.length !==0  ?
	<Tooltip title={toolTipMsg}>
	<span className={className} style={style}>{name}</span> 
	</Tooltip> : 	<span className={className} style={style}>{name}</span> )
}

NameField.propTypes = {
	record: PropTypes.object,
	className: PropTypes.object,
	text: PropTypes.string,
	sourceId: PropTypes.string,
	sourceLabel: PropTypes.string,
	addLabel: PropTypes.bool,
	addId: PropTypes.bool,
	style: PropTypes.object,
}

NameField.defaultProps = {
	record: {},
	sourceId: 'id',
	sourceLabel: 'label',
	addLabel: true,
	addId: false,
}

export default NameField
