import React from 'react'
//import { Fade as fade } from '@material-ui/core'
import QuickSearchInputBase from '../common/inputs/QuickSearchInputBase'
import match from 'autosuggest-highlight/match'
import parse from 'autosuggest-highlight/parse'
import { ProgramType } from '../../constants/types'
import { Url, Links } from '../../tools'
import { MenuItem, ListItem, ListItemIcon, Avatar, ListItemText, Divider } from '@material-ui/core'
import { ProductIconButton, PictureIconButton, MovieIconButton } from '../common/buttons'
import { Link } from 'react-router-dom'
//import Link from 'ra-ui-materialui/lib/Link'
import { validateNumber } from '30-seconds-of-code'
import { crudGetList as crudGetListAction, crudGetOne as crudGetOneAction } from 'ra-core/lib/actions/dataActions'
import dataProvider from '../../dataProvider'
import { connect } from 'react-redux'
import compose from 'recompose/compose'
import { translate, fetchEnd, fetchStart, showNotification, GET_LIST } from 'react-admin'
import { onSelectContent, onExpandUpdateForm, onChangeContentFilter, changeContentType } from '../../actions/pricePlanning'
import { reset, change } from 'redux-form'
import { REDUX_FORM_PRICEPLANNING_UPDATE, REDUX_FORM_PRICEPLANNING_LOAD } from '../../constants/reduxFormName'
import Color from 'color'
import { withStyles } from '@material-ui/core/styles'

const styles = theme => ({
	root: {
		flexGrow: 1,
		display: 'flex',
		width: '700px',
	},
	container: {
		position: 'relative',
	},
	search: {
		position: 'relative',
		backgroundColor: Color(theme.palette.text.primary).alpha(0.15).string(),//alpha(theme.palette.text.primary, 0.15),
		'&:hover': {
			backgroundColor:Color(theme.palette.text.primary).alpha(0.25).string(),// alpha(theme.palette.text.primary, 0.25),
		},
		marginLeft: 0,
		width: '100%',
		[theme.breakpoints.up('sm')]: {
			marginLeft: theme.spacing(1),
			width: '100%',
		},
	},
	searchIcon: {
		width:  theme.spacing(9) ,
		height: '100%',
		position: 'absolute',
		pointerEvents: 'none',
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
	},
	inputContainer: {
		height: '100%',
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
	},
	inputRoot: {
		color: 'inherit',
		width: '140%',
		marginLeft: '10px'
	},
	inputInput: {
		paddingTop:  theme.spacing(1),
		paddingRight:  theme.spacing(1),
		paddingBottom:  theme.spacing(1),
		paddingLeft:  theme.spacing(2),
		backgroundColor: 'transparent',
		border: 0,
		color: theme.palette.common.white,
		width: '100%',
	},
	resultsContainer: {
		width: '690px',
		maxHeight: '60vw',
		overflowY: 'auto',
		right: '55px',
		position: 'relative',
		zIndex: 100
	}
})

class PureSearchApp extends React.Component {
	constructor(props) {
		super(props)

		this.isOnPricePlanningPage = this.isOnPricePlanningPage.bind(this)
		this.renderSuggestion = this.renderSuggestion.bind(this)
	}

	isOnPricePlanningPage() {
		const { isOnPricePlanningPage } = this.props
		return isOnPricePlanningPage
	}

	renderSuggestion(suggestion, { query, isHighlighted }) {
		const matches = match(suggestion.label, query)
		const parts = parse(suggestion.label, matches)
		let url
		if (ProgramType.MOVIE === suggestion.type)
			url = Url.avatarMovie(suggestion.id)
		else if (ProgramType.ALBUM === suggestion.type)
			url = Url.avatarAlbum(suggestion.id)
		else if (ProgramType.PEOPLE === suggestion.type)
			url = Url.avatarPeople(suggestion.id)

		const label = parts.map((part, index) => {
			return part.highlight ?
				<span key={String(index)} style={{ fontWeight: 'bold' }}>
					{part.text}
				</span>
				:
				<span key={String(index)}>
					{part.text}
				</span>
		})

		//need refactoring
		if (this.isOnPricePlanningPage()) {
			return (
				<MenuItem selected={isHighlighted} component='div'>
					<ListItem button={true} dense={true} component='div'>
						<ListItemIcon>
							<Avatar variant="square" alt={suggestion.label} src={url} imgProps={{ onError: (e) => { e.target.src = Url.defaultPicture } }} style={{ borderRadius: 0 }} />
						</ListItemIcon>
						<ListItemText primary={label} secondary={suggestion.id} />
					</ListItem>
				</MenuItem>
			)
		}
		else {
			let link = ''
			if (suggestion) {
				if (ProgramType.MOVIE === suggestion.type)
					link = Links.CompaniesmoviesShow(suggestion.id)
				else if (ProgramType.ALBUM === suggestion.type) {
					link = Links.CompaniesalbumsShow(suggestion.id)
				}
				else if (ProgramType.PEOPLE === suggestion.type)
					link = Links.CompaniespeoplesShow(suggestion.id)
			}
			let icons
			if (suggestion) {
				if (ProgramType.MOVIE === suggestion.type)
					icons = ([
						<ProductIconButton key={`productIconButton${suggestion.id}`} movieid={suggestion.id} />,
						<PictureIconButton key={`moviePictureIconButton_${suggestion.id}`} movieid={suggestion.id} />,
					])
				else if (ProgramType.ALBUM === suggestion.type)
					icons = null
				else if (ProgramType.PEOPLE === suggestion.type)
					icons = ([
						<MovieIconButton key={`movieIconButton_${suggestion.id}`} peopleid={suggestion.id} />,
						<PictureIconButton key={`peoplePictureIconButton_${suggestion.id}`} peopleid={suggestion.id} />,
					])
			}

			return (
				<MenuItem selected={isHighlighted} component='div'>
					<ListItem button={true} dense={true} component={Link} to={link}>
						<ListItemIcon>
							<Avatar variant="square" alt={suggestion.label} src={url} imgProps={{ onError: (e) => { e.target.src = Url.defaultPicture } }} style={{ borderRadius: 0 }} />
						</ListItemIcon>
						<ListItemText primary={label} secondary={suggestion.id} />
					</ListItem>
					{icons}
				</MenuItem>
			)
		}

	}

	renderSectionTitle = (section) => {
		return (
			<div>
				<Divider />
				<MenuItem disabled={true} component='div'>
					<ListItem>
						<ListItemText primary={section.title} primaryTypographyProps={{ variant: 'headline' }} />
					</ListItem>
				</MenuItem>
			</div>
		)
	}

	shouldRenderSuggestions = (value) => {
		if (!value || typeof (value) === 'undefined') return false
		const val = value.trim()
		return validateNumber(val) ? true : val.length >= 1
	}

	handleQuickSearchResult = (suggestion) => {
		const { crudGetList, onSelectContent, fetchEnd, fetchStart, showNotification, crudGetOne, isOnPricePlanningPage, onExpandUpdateForm,
			onChangeContentFilter, reset, changeContentType, change } = this.props
		if (isOnPricePlanningPage) {
			fetchStart()

			//reset the redux store and set the content type
			onExpandUpdateForm(false)
			onChangeContentFilter()
			reset(REDUX_FORM_PRICEPLANNING_UPDATE)
			changeContentType(suggestion.type)

			dataProvider(GET_LIST, 'PricePlanning/Price', { pagination: { page: 1, perPage: 10 }, sort: { field: {}, order: {} }, filter: { objectId: suggestion.id, objectTypeId: suggestion.type } }).then((json) => {
				if (json.data && json.data.length !== 0) {
					const [first] = json.data
					onSelectContent(first.pricingClassId, suggestion.id, json.data) // set the default price classification id and the album id
					crudGetList(
						'Pricingslists', // resource
						{}, // pagination
						{}, // sort
						{ pricingid: first.pricingClassId } //filter
					)
					crudGetOne(
						'PricePlanning/TransfersRights', // resource
						`${suggestion.id}_${suggestion.type}`
					)
					change(REDUX_FORM_PRICEPLANNING_LOAD, 'objectId', suggestion.id)
				}
			})
				.catch((error) => {
					console.log('Get Album Price Error: ' + error.message)
					showNotification('messages.getAlbumPriceListError', 'warning', { messageArgs: { autoHideDuration: '6000' } })
				})
				.finally(() => {
					fetchEnd()
				})
		}
	}

	render() {
		return <QuickSearchInputBase
			placement='bottom'
			renderSuggestion={this.renderSuggestion}
			renderSectionTitle={this.renderSectionTitle}
			shouldRenderSuggestions={this.shouldRenderSuggestions}
			handleParentComponentInputs={this.handleQuickSearchResult}
			test={this.test}
			{...this.props}
		/>
	}
}

const mapStateToProps = state => ({
	...state,
	isOnPricePlanningPage: state.reducer.pricePlanning.isOnPricePlanningPage
})

const mapDispatchToProps = {
	fetchEnd,
	fetchStart,
	crudGetList: crudGetListAction,
	crudGetOne: crudGetOneAction,
	showNotification,
	onSelectContent: onSelectContent,
	onExpandUpdateForm,
	onChangeContentFilter,
	reset,
	changeContentType,
	change
}

const enhance = compose(
	connect(mapStateToProps, mapDispatchToProps),
	withStyles(styles)
)

export default translate(enhance(PureSearchApp))
// export default connect (
// 	mapStateToProps,
// 	mapDispatchToProps
// )(PureSearchApp)