import React, { Component, Fragment } from 'react'
import { connect } from 'react-redux'
import { PricePlanificationFilter } from '.'
import { PricePlanificationUpdate } from '.'
import { PricePlanificationPeriodTable } from '.'
import { ExpandMoreIcon } from '../../constants/icons'
//import ExpansionPanel from '@material-ui/core/ExpansionPanel'
import { Accordion as ExpansionPanel } from '@material-ui/core'
//import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails'
import { AccordionDetails as ExpansionPanelDetails} from '@material-ui/core'
//import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary'
import { AccordionSummary as ExpansionPanelSummary} from '@material-ui/core'
import Typography from '@material-ui/core/Typography'
import compose from 'recompose/compose'
import { translate } from 'react-admin'
import { animateScroll as scroll, Element, scroller } from 'react-scroll'
import { onExpandUpdateForm } from '../../actions/pricePlanning'
import { withStyles  } from '@material-ui/core/styles'

const styles = theme =>  ({
	root: {
		width: '100%',
		color: 'white'

	},
	heading: {
		color: 'white',
		fontSize: theme.typography.pxToRem(15),
		fontWeight: theme.typography.fontWeightBold
	},
	expansionPanelTitle:{
		background: '#288DB1',//cyan['A700'],
		color: 'white'
	},
	expansionPanel:{
		background: 'white'// theme.palette.text.secondary//'dimgrey'
	},
	switchContentType : {
		width : '97px'
	},
	switchContentTypeTitle: {
		fontSize: 'small',
		fontWeight: 'bold',
		color: theme.palette.text.secondary,
		marginBottom : '5px'
	},
})

const PriceUpdateExpansionPanel = props => {
	const { currentContentId, classes, translate, isUpdateFormExpanded, onExpandUpdateForm } = props
	const handleChange = () => (event) => {
		onExpandUpdateForm(isUpdateFormExpanded ? false : true)
	}

	const scrollToBottom = () => (event) => {
		scroll.scrollToBottom()
	}

	if(currentContentId != null){
		return (
			<div style={{ margin: '5px'}}>
				<ExpansionPanel expanded = {isUpdateFormExpanded} onChange={handleChange()} className={classes.expansionPanel}>
					<ExpansionPanelSummary
						expandIcon={<ExpandMoreIcon style={{ color: "white" }} />}
						aria-controls="panel2a-content"
						id="panel2a-header"
						className = { classes.expansionPanelTitle }
					>
						<Typography className={classes.heading}>{translate('messages.createOrUpdatePriceTitle')}</Typography>
					</ExpansionPanelSummary>
					<ExpansionPanelDetails>
						<PricePlanificationUpdate scrollAction = {scrollToBottom()}/>
					</ExpansionPanelDetails>
				</ExpansionPanel>
			</div>
		)
	}
	else{
		return <div></div>
	}
}

const PeriodTableExpansionPanel = props => {
	const { currentContentId, classes, translate, scrollAction } = props
	const [expanded, setExpanded] = React.useState(true)

	const handleChange = () => (event) => {
		setExpanded(expanded ? false : true)
	}

	if(currentContentId != null){
		return (
			<div style={{ margin: '5px'}}>
				<ExpansionPanel expanded = {expanded} onChange={handleChange()} className={classes.expansionPanel}>
					<ExpansionPanelSummary
						expandIcon={<ExpandMoreIcon style={{ color: "white" }} />}
						aria-controls="panel3a-content"
						id="panel3a-header"
						className = { classes.expansionPanelTitle }
					>
						<Typography className={classes.heading}>{translate('titles.periodTableTitle')}</Typography>
					</ExpansionPanelSummary>
					<ExpansionPanelDetails>
						<PricePlanificationPeriodTable scrollAction = { scrollAction }/>
					</ExpansionPanelDetails>
				</ExpansionPanel>
			</div>
		)
	}
	else{
		return <div></div>
	}
}

class PricePlanification extends Component {
	constructor(props){
		super(props)
		this.state = {
			panelOneExpanded : true
		}
	}

	handleChange = (event, panel) =>{
		if(panel === 'panel1'){
			this.setState({ panelOneExpanded: this.state.panelOneExpanded ? false : true })
		}
	}

	scrollToTheUpdateSection = () => {
		scroller.scrollTo('updateSection', {
			duration: 500,
			delay: 100,
			smooth: true,
			offset: 50, // Scrolls to element + 50 pixels down the page
		})
	}

	render(){
		const { classes, translate } = this.props
		//const { switchContentType, switchContentTypeTitle } = classes
		return(
			<Fragment>
				<div className={classes.root}>
					<div style={{ margin: '5px'}}>
						<ExpansionPanel expanded = {this.state.panelOneExpanded} onChange={event => this.handleChange(event, 'panel1')} className={classes.expansionPanel} >
							<ExpansionPanelSummary
								expandIcon={<ExpandMoreIcon  style={{ color: "white" }} />}
								aria-controls="panel1a-content"
								id="panel1a-header"
								className = { classes.expansionPanelTitle }
							>
								<Typography className={classes.heading}>{translate('messages.objectTitle')}</Typography>
							</ExpansionPanelSummary>
							<ExpansionPanelDetails>
								<PricePlanificationFilter />
							</ExpansionPanelDetails>
						</ExpansionPanel>
					</div>
					<Element name="updateSection">
						<PriceUpdateExpansionPanel {...this.props} />
					</Element>
					<PeriodTableExpansionPanel scrollAction = {this.scrollToTheUpdateSection} {...this.props} />
				</div>
			</Fragment>	
		)
	}
}

const mapStateToProps = state => ({
	...state,
	currentContentId: state.reducer.pricePlanning.contentSelected,
	isUpdateFormExpanded: state.reducer.pricePlanning.isUpdateFormExpanded
})

const mapDispatchToProps  = ({
	onExpandUpdateForm
})

const enhance = compose(
	connect(mapStateToProps, mapDispatchToProps),
	withStyles(styles)
)

export default translate(enhance(PricePlanification))