import React, { Component } from 'react'
import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import { submit, isSubmitting, initialize, change, reset, getFormSyncErrors } from 'redux-form'
import { connect } from 'react-redux'
import compose from 'recompose/compose'
import { SimpleForm, translate, fetchEnd, fetchStart, showNotification, UPDATE } from 'react-admin'
import { crudGetList as crudGetListAction, crudGetOne as crudGetOneAction } from 'ra-core/lib/actions/dataActions'
import { PricingsInput } from '../common/inputs'
import Typography from '@material-ui/core/Typography'
import Grid from '@material-ui/core/Grid'
import { validatePrice, validatePriceCheckboxes, validatePublicationTargets, validatePublicationDate, validatePriceCheckboxesValues, validateAppleTragetsPrice, validateNumberOfUpdateProcess,validateCheckAllPriceValues } from './validate'
import { CustomCheckboxItemGroup } from '../common/inputs'
import { CancelIcon, CheckIcon, PublishIcon } from '../../constants/icons'
import Button from '@material-ui/core/Button'
import { Token, Logger } from '../../tools'
import { priceList } from '../../constants/defaultValues'
import { AlertDialogSlide, LinearProgressModal } from '../common'
import dataProvider from '../../dataProvider'
import { onSelectContent, onExpandUpdateForm, onPricePlanningChangePriceLine, onCheckUpdateForm, resetPreview } from '../../actions/pricePlanning'
import { DateFormatter } from '../../tools'
import isEqual from 'lodash/isEqual'
import CustomPriceInput from './fields/CustomPriceInput'
import CustomDatePickerCanal from './fields/CustomDatePickerCanal'
import { REDUX_FORM_PRICEPLANNING_UPDATE } from '../../constants/reduxFormName'
import { AUTH_LOGOUT } from 'react-admin'
import authProvider from '../../authProvider'
import { mapFormWithReduxStore, getExpandedTransfersRightsByPeriod, prepareUpdate, buildTargets } from './actions/pricePlanningUpdateAction'
import { withStyles } from '@material-ui/core/styles'
import {Tooltip } from '@material-ui/core'
import {CustomSwitch , CustomCheckBox} from './../layout/GlobalStyles'
import { NewDatePicker } from '../common/inputs'
import { ConfigContext } from './../layout/ConfigProvider'
const styles = theme => ({
	price: {
		width: '110px',
		fontSize: '0.75em',
		marginLeft:'0px',
		marginTop:'0px'
	},
	priceLarge: {
		width: '150px',
		fontSize: '0.7rem'
	},
	inputPrices: {
		marginRight: '16px',
	},
	root: {
		flexGrow: 1
	},
	secondaryHeading: {
		fontSize: 'small',
		fontWeight: 'bold',
		paddingBottom: '1px',
		color: '#288DB1',
		marginLeft: '8px',
	},
	cardContent: {
		marginTop: '0%'
	},
	flexItem: {
		padding: '5px',
		margin: '5px'
	},
	flexContainer: {
		flexDirection: 'row',
		marginTop: '0%'
	},
	button: {
		margin: theme.spacing(1),
		width: '150px',
		background: '#0c9e0c'
	},
	buttonCancel: {
		margin: '1px',
		width: '150px',
		background: '#e23434',
		marginTop: '4px'

	},
	buttonCheck: {
		margin: theme.spacing(1),
		width: '150px',
	},
	leftIcon: {
		marginRight: theme.spacing(1),
	},
	rightIcon: {
		marginLeft: theme.spacing(1),
	},
	iconSmall: {
		fontSize: 20,
	},
	gridForm: {
		width: 'calc(100% + 24px)',
		minWidth: '1350px'
	},
	customShrink: {
		width: 37,
		transform: 'translate(0, 13px) scale(0.75)',
	},
	customShrinkLarge: {
		width: 20,
		transform: 'translate(0, 13px) scale(0.75)',
	},
	customNumberInput: {
		marginLeft: '30%'
	},
	customNumberInputLarge: {
		marginLeft: '88px'
	},
	customNumberInputType: {
		marginTop: '-15px'
	},
	checkCard: {
		// marginTop: '20px',
		// padding: '5%'
	}
})
class PricePlanificationUpdate extends Component {
	constructor(props) {
		super(props)
		this.state = {
			checkboxes: [],
			modalOpen: false,
			progressBarOpen: false,
			updateProgress: 0,
			numberOfUpdate: 0,
			numberOfSuccess: 0,
			applyButtonDisabled: true,
			transfersRightsTemp: [],
			checkAllVODPrice: false,
			checkAllESTPrice : false,
			checkedEst: false,
			checkedVod: false
		}
	}
    static contextType = ConfigContext;

	componentDidMount() {
		this.setState({ checkboxes: buildTargets() })
	}

	handleClose = () => {
		this.setState({ modalOpen: false })
	}

	handleCloseProgressBar = () => {
		this.setState({ progressBarOpen: false })
	}

	handleCheckboxgroupChange = (updatedUsecaseCBState) => {
		const { change } = this.props
		this.setState({
			checkboxes: updatedUsecaseCBState,
		})
		change(REDUX_FORM_PRICEPLANNING_UPDATE, 'targets', updatedUsecaseCBState)
	}

	handleUpdateClick = () => {
		const { formValues, transfersRightsData, currentContentId, objectTypeId } = this.props
		this.isFormValidated(formValues, transfersRightsData, currentContentId, objectTypeId, false).then((result) => {
			if (result.success) {

				this.setState({
					modalOpen: true,
					checkAllVODPrice: false,
					checkAllESTPrice: false
				})
			}
			else {
				const { showNotification } = this.props
				showNotification(result.errorMessages.join(' - '), 'warning', { autoHideDuration: '6000' })
			}
		})
	}

	isFormValidated = (formValues, transfersRightsData, currentContentId, objectTypeId, isPreview) => {
		let validationResult = {
			errorMessages: [],
			success: false
		}
		const { translate, formSyncErrors } = this.props
		const publicationDateErrorMessage = {
			emptyPublicationDate: translate('messages.emptyPublicationDate'),
			publicationIntervalError: translate('messages.publicationIntervalError')
		}
		const userId = parseInt(Token.getId(), 10)
		const transfersRightsTemp = prepareUpdate(formValues, transfersRightsData, currentContentId, objectTypeId, isPreview, userId)
		this.setState({ transfersRightsTemp: transfersRightsTemp }, () => {
			// console.log(this.state.transfersRightsTemp)
		})
		const validationMethods = [validatePriceCheckboxes(formValues, translate('messages.priceCheckboxMandatoryError')),
		validatePublicationDate(formValues, publicationDateErrorMessage),
		validatePublicationTargets(formValues, translate('messages.targetsCheckboxMandatoryError')),
		validatePriceCheckboxesValues(formValues, translate('messages.emptyPriceCheckbox')),
		validateNumberOfUpdateProcess(transfersRightsTemp, translate('messages.noChangeInThePeriodTable'))]

		validationMethods.forEach(item => { if (!item.success) validationResult.errorMessages.push(item.message) }) // go through all the validation
		if (formSyncErrors) {
			priceList.forEach(item => {
				if (formSyncErrors[item]) {
					validationResult.errorMessages.push(`${item} : ${formSyncErrors[item]}`)
				}
			})
		}
		//Error apple product
		return validateAppleTragetsPrice(formValues, translate('messages.invalidApplePrice')).then((result) => {
			if (!result.success) {
				validationResult.errorMessages.push(result.message)
			}
			if (0 !== validationResult.errorMessages.length) {
				validationResult.success = false
			}
			else {
				validationResult.success = true
			}
			return validationResult
		})
			.catch((error) => {
				Logger.debug('Error message ' + error.message)
			})
	}

	checkUpdateForm = () => {
		const { showNotification, formValues, transfersRightsData, currentContentId, objectTypeId, onCheckUpdateForm, scrollAction } = this.props
		const dataArrayIndex = `${currentContentId}_${objectTypeId}`
		const expandedTransfersRightsPeriod = getExpandedTransfersRightsByPeriod(transfersRightsData[dataArrayIndex].transfersRightsByPeriod)
		console.log(expandedTransfersRightsPeriod)
		this.isFormValidated(formValues, transfersRightsData, currentContentId, objectTypeId, true).then((result) => {
			if (result.success) {
				showNotification('messages.updatePriceFormValidated', 'info', { autoHideDuration: '3000' })
				dataProvider(UPDATE, 'PricePlanning/TransfersRightsByPeriod', { values: this.state.transfersRightsTemp }).then(
					(json) => {
						var dataForPreview = json.data.map(function (previewItem) {
							var taggedPeriodTranfersRightsByPeriod = previewItem.transfersRightsByPeriodItems.map((element) => {
								element.startDate = previewItem.startDate
								element.endDate = previewItem.endDate

								let isDifferent = false
								let countDifference = 0
								for (let expandedElement of expandedTransfersRightsPeriod) {
									if (!isEqual(expandedElement, element)) countDifference++
								}
								if (countDifference === expandedTransfersRightsPeriod.length) {
									isDifferent = true
								}

								//TODO Trouvez pourquoi les valeurs qu'ils reçoient du composant DatePickerMaterial sont 2h en retard alors que les deux dates sont au fuseau horaire
								// GMT +2, exemple :
								// DatePickerMaterial = 12/07/2021 T 14:30
								// PricePlanificationUpdate = 12/07/2021 T 12:30
								// Pour palier au probleme du decalage sur les heures je rajoute des heures en plus
								// if (previewItem.startDate != null ) {
								// 	let startDate =  new Date(previewItem.startDate)
								// 	console.log(startDate)
								// if (startDate.getHours() !== 0) {
								// previewItem.startDate =  this.dateFormatter(startDate.setHours( startDate.getHours() + 1 )); //je sais pas pourquoi mais la il fait -1 h et pas -2 h
								// }
								// }

								// if (previewItem.endDate != null ) {
								// 	let endDate = new Date (previewItem.endDate)
								// 	console.log(endDate)

								// 	// previewItem.endDate =  this.dateFormatter(endDate.setHours( endDate.getHours() + 2 ));
								// }

								return {
									...element,
									isDifferent: isDifferent
								}
							})
							return {
								...previewItem,
								transfersRightsByPeriodItems: taggedPeriodTranfersRightsByPeriod
							}
						})
						this.setState({
							applyButtonDisabled: false
						})
						onCheckUpdateForm(dataForPreview)
						scrollAction()
					}
				)
					.catch((error) => {
						Logger.debug(`Erreur durant la contruction du tableau de prévisualisation : ${error.message}`)
						if (error.status === 401 || error.status === 403) {
							showNotification('ra.notification.logged_out', 'warning', { autoHideDuration: '6000' })
							authProvider(AUTH_LOGOUT, error)
						}
						else {
							showNotification(`Erreur durant la contruction du tableau de prévisualisation  : ${error.message}`, 'warning', { autoHideDuration: '6000' })
						}
					})
			}
			else {
				showNotification(result.errorMessages.join(' - '), 'warning', { autoHideDuration: '6000' })
			}
		}).catch((error) => {
			Logger.debug(`Erreur durant la contruction du tableau de prévisualisation : ${error.message}`)
			showNotification(`Erreur durant la contruction du tableau de prévisualisation  : ${error.message}`, 'warning', { autoHideDuration: '6000' })
		})
	}

	updateMultipleTransfersRights = (itemToBeUpdated) => {
		const {  formValues, currentContentId, objectTypeId} = this.props

		return new Promise((resolve, reject) => {
			dataProvider(UPDATE, 'PricePlanning/TransfersRights', { id: itemToBeUpdated.Id, transfersRights: itemToBeUpdated }).then(
				(json) => {
					this.setState({
						updateProgress: this.state.updateProgress + 1,
						numberOfSuccess: this.state.numberOfSuccess + 1
					})
				
					resolve()
				}
			).catch((error) => {
				Logger.debug(`Erreur durant la modification des prix : ${error.message} - Transfers right id ${itemToBeUpdated.Id}`)
				this.setState({
					updateProgress: this.state.updateProgress + 1
				})
			})
			dataProvider(UPDATE, 'PricePlanning/UpdatePricingClass', { id: currentContentId,objectId:objectTypeId,  classeId: formValues.classificationId }).then(

				(json) => {
					resolve()
				}
			).catch((error) => {
				Logger.debug(`Erreur durant la modification du movie/album classe de prix : ${error.message} - id ${itemToBeUpdated.Id}`)
				 
			})
		
		}
		)
	}

	handleSubmit = values => {
		const { showNotification, currentContentId, reset, crudGetOne, onExpandUpdateForm, resetPreview, objectTypeId } = this.props
		const valuesToBeUpdated = this.state.transfersRightsTemp
		const operationNumber = valuesToBeUpdated.length
		this.setState({
			numberOfUpdate: operationNumber,
			updateProgress: 0,
			numberOfSuccess: 0
		})
		if (operationNumber !== 0) {
			this.setState({
				modalOpen: false,
				checkboxes: buildTargets(),
				progressBarOpen: true
			})
			valuesToBeUpdated.reduce((accumulatorPromise, value) => {
				return accumulatorPromise.then(() => {
					return this.updateMultipleTransfersRights(value);
				})
			}, Promise.resolve()).then(() => {
				reset(REDUX_FORM_PRICEPLANNING_UPDATE) //reset form
				resetPreview() // reset the preview in the redux store
				onExpandUpdateForm(false) //hide zone 2
				crudGetOne('PricePlanning/TransfersRights', `${currentContentId}_${objectTypeId}`) //update the redux store
				this.setState({
					applyButtonDisabled: true,
					transfersRightsTemp: []
				})
			}).catch((error) => {
				Logger.debug(`Erreur durant la modification des prix : ${error.message}`)
				if (error.status === 401 || error.status === 403) {
					showNotification('ra.notification.logged_out', 'warning', { autoHideDuration: '6000' })
					authProvider(AUTH_LOGOUT, error)
				}
				showNotification(`Erreur durant la modification des prix : ${error.message}`, 'warning', { autoHideDuration: '6000' })
			})
		}
	}

	formDefaultValues = () => {
		// eslint-disable-next-line react/prop-types
		const { pricingslists, currentContentId, transfersRightsData, objectTypeId, selectedClassificationId } = this.props
		const dataArrayIndex = `${currentContentId}_${objectTypeId}`
		if (pricingslists && currentContentId && transfersRightsData[dataArrayIndex]) {
			const publicationInterval = {
				vodStartDate: DateFormatter.getDatepickerFieldValue(transfersRightsData[dataArrayIndex].vodStartDate),
				vodEndDate: DateFormatter.getDatepickerFieldValue(transfersRightsData[dataArrayIndex].vodEndDate),
				estStartDate: DateFormatter.getDatepickerFieldValue(transfersRightsData[dataArrayIndex].estStartDate),
				estEndDate: DateFormatter.getDatepickerFieldValue(transfersRightsData[dataArrayIndex].estEndDate),
				// estBkStartDate : DateFormatter.getDatepickerFieldValue(transfersRightsData[dataArrayIndex].estBkStartDate),
				// estBkEndDate: DateFormatter.getDatepickerFieldValue(transfersRightsData[dataArrayIndex].estBkEndDate)
			}
			return mapFormWithReduxStore(Object.values(pricingslists), publicationInterval, selectedClassificationId)
		}
		return {
			targets: buildTargets()
		}
	}
	handleDateChange = (dates, fieldName) =>{
		const { change } = this.props
		change(REDUX_FORM_PRICEPLANNING_UPDATE, fieldName, DateFormatter.convertSystemDate(dates)? DateFormatter.convertSystemDate(dates).format('YYYY-MM-DDTHH:mm:ss'):null)
		
	}
	handleDateCheck = (fieldName) =>{
		const { change } = this.props
		if(fieldName === 'checkVodDate'){
			this.setState({checkedVod : !this.state.checkedVod})
			change(REDUX_FORM_PRICEPLANNING_UPDATE, fieldName,!this.state.checkedVod  )
		}
		if(fieldName === 'checkEstDate'){
			this.setState({checkedEst : !this.state.checkedEst})
			change(REDUX_FORM_PRICEPLANNING_UPDATE, fieldName,!this.state.checkedEst  )
		}
		
	}
	// eslint-disable-next-line no-unused-vars
	handleSearchPrices = (event, key, oldPayload) => {
		// eslint-disable-next-line react/prop-types
		const { crudGetList, onPricePlanningChangePriceLine } = this.props
		initialize(REDUX_FORM_PRICEPLANNING_UPDATE)

		crudGetList(
			'Pricingslists', // resource
			{}, // pagination
			{}, // sort
			{ pricingid: key } //filter
		)

		onPricePlanningChangePriceLine(key)
	}

	resetForm = () => {
		const { reset, resetPreview ,formValues} = this.props

		this.setState({
			checkboxes: buildTargets(),
			applyButtonDisabled: true
		})
		reset(REDUX_FORM_PRICEPLANNING_UPDATE)
		resetPreview()
	}
	CheckAllChange =(type)=>{
		const {  formValues} = this.props

		if(type === 'est'){
			this.setState({
				checkAllESTPrice: !this.state.checkAllESTPrice	
			})
			return validateCheckAllPriceValues(formValues,!this.state.checkAllESTPrice,this.state.checkAllVODPrice,translate('messages.emptyPriceCheckbox'))
		}
		if(type === 'vod'){
			this.setState({
				checkAllVODPrice: !this.state.checkAllVODPrice
			})
			return validateCheckAllPriceValues(formValues,this.state.checkAllESTPrice,!this.state.checkAllVODPrice,translate('messages.emptyPriceCheckbox'))
		}
	}
	render() {
		// eslint-disable-next-line react/prop-types
		const { classes, translate, formValues,objectTypeId } = this.props
		const { checkboxes } = this.state
		const { config } = this.context;
		const priceLimit = config && config.priceLimit
 		const formTargets = formValues && formValues.targets ? formValues.targets.filter(t => t.countryId === 73) : checkboxes.filter(t => t.countryId === 73)
		const otherFormTargets = formValues && formValues.targets ? formValues.targets.filter(t => t.countryId !== 73) : checkboxes.filter(t => t.countryId !== 73)
		const modalContent = {
			title: translate('titles.pricePlanningModalHeader'),
			description: translate('messages.pricePlanningModalDescription'),
			disagreeButtonText: translate('ra.action.undo'),
			agreeButtonText: translate('ra.action.confirm')
		}
		const progressModalContent = {
			title: translate('titles.pricePlanningModalHeader'),
			progress: translate('messages.pricePlanningModalProgress'),
			success: translate('messages.pricePlanningModalSuccess'),
			failure: translate('messages.pricePlanningModalFailure'),
			closeButtonText: translate('ra.action.close')
		}
		
		return (
			<div className={classes.root}>
				<Card>
					<CardContent className={classes.cardContent}>
						<SimpleForm
							// We override the redux-form name to avoid collision with the react-admin main form
							form={REDUX_FORM_PRICEPLANNING_UPDATE}
							resource="PricePlanification"
							defaultValue={this.formDefaultValues}
							onSubmit={this.handleSubmit}
							toolbar={null}
							style={{ marginTop: '-1%' }}
						>
							<Grid container spacing={2} className={classes.gridForm}>
								<Grid item xs={6}>
								
 								{/* <CustomCheckBox  isprice={true} value={this.state.checkedVod} onChange={() =>this.handleDateCheck('checkVodDate')} />
 								<NewDatePicker input={formValues && formValues.vodStartDate} dateType='start' startDateSource="vodStartDate" label='VOD' classes={classes}
								 disabled={!this.state.checkedVod}  handleDateChange={(newDate) => this.handleDateChange(newDate, 'vodStartDate')}/>
								<NewDatePicker input={formValues && formValues.vodEndDate} dateType='end' endDateSource="vodEndDate" label='VOD' classes={classes}
								  disabled={!this.state.checkedVod} handleDateChange={(newDate) => this.handleDateChange(newDate, 'vodEndDate')}/>
								<br/>
								<CustomCheckBox isprice={true} value={this.state.checkedEst} onChange={() =>this.handleDateCheck('checkEstDate')} />
								<NewDatePicker input={formValues && formValues.estStartDate} dateType='start' startDateSource="estStartDate" label='EST' classes={classes} 
								disabled={!this.state.checkedEst} handleDateChange={(newDate) => this.handleDateChange(newDate, 'estStartDate')}/>
								<NewDatePicker input={formValues && formValues.estEndDate} dateType='end' endDateSource="estEndDate" label='EST' classes={classes}
								  disabled={!this.state.checkedEst} handleDateChange={(newDate) => this.handleDateChange(newDate, 'estEndDate')}/> */}
									<CustomDatePickerCanal name="checkVodDate" startDateSource="vodStartDate" endDateSource="vodEndDate" label={translate('labels.publicationPeriodVOD')} classes={classes} /><br />
									<CustomDatePickerCanal name="checkEstDate" startDateSource="estStartDate" endDateSource="estEndDate" label={translate('labels.publicationPeriodEST')} classes={classes} /><br />
								<PricingsInput onChange={this.handleSearchPrices} label={translate('labels.currentPriceClassification')} source="classificationId" shrink={false} options={{ style: { minWidth: '90%', } }} objectTypeId={objectTypeId} /><br />
								</Grid>
								<Grid item xs={6}>
									<Card className={classes.checkCard}>
										<CardContent>
											<CustomCheckboxItemGroup
												frCheckboxes={formTargets}
												otherCheckboxes={otherFormTargets}
												onCheckboxGroupChange={this.handleCheckboxgroupChange}
											/>
										</CardContent>
									</Card>
								</Grid>
								<br />
								
								<Grid container spacing={1}>
								<Grid item xs={3}>
								<Grid container style={{paddingTop:"20px"}}>
								<Typography className={classes.secondaryHeading}>{translate('labels.newVODPrice')}</Typography>
								<Tooltip title="Désélectionner tout" placement="right-start" > 	
									<CustomSwitch 
										checked={this.state.checkAllVODPrice}
										onChange={()=>this.CheckAllChange('vod')}
										color="primary" size="small" 	
										inputProps={{ 'aria-label': 'primary checkbox' }}
									/>
								</Tooltip>
								</Grid>
								</Grid>

									<Grid item xs={2}>
										<CustomPriceInput label="SD" source="priceVODSD" name="checkPriceVODSD" classes={classes} inputValidation={validatePrice(priceLimit)} selectAllVOD={this.state.checkAllVODPrice} />
									</Grid>
									<Grid item xs={2}>
										<CustomPriceInput label="HD" source="priceVODHD" name="checkPriceVODHD" classes={classes} inputValidation={validatePrice(priceLimit)} selectAllVOD={this.state.checkAllVODPrice}/>
									</Grid>
									<Grid item xs={2}>
										<CustomPriceInput label="4K" source="priceVOD4K" name="checkPriceVOD4K" classes={classes} inputValidation={validatePrice(priceLimit)} selectAllVOD={this.state.checkAllVODPrice}/>
									</Grid>
									<Grid item xs={2}  style={{paddingTop:"10px"}}>
										<Button onClick={this.handleUpdateClick} color="primary" variant="contained" className={classes.button} disabled={this.state.applyButtonDisabled}>
												<PublishIcon className={classes.leftIcon} />
												{translate('labels.applyButton')}
											</Button>
											<br/>
											<Button onClick={this.checkUpdateForm} color="primary" variant="contained" className={classes.buttonCheck}>
												<CheckIcon className={classes.leftIcon} />
												{translate('labels.checkButton')}
											</Button>
									</Grid>
								</Grid>
								
								<Grid container spacing={2}>
								<Grid item xs={3}>
								<Grid container style={{paddingTop:"20px"}}>
								<Typography className={classes.secondaryHeading}>{translate('labels.newESTPrice')}</Typography>
								<Tooltip title="Désélectionner tout"
									placement="right-start" 
									 
								> 	
									<CustomSwitch
										checked={this.state.checkAllESTPrice}
										onChange={()=>this.CheckAllChange('est')}
										color="primary" size="small" 
										inputProps={{ 'aria-label': 'primary checkbox' }}
										// disabled={!create}
										// className={classes.switch}
									/>
								</Tooltip>
								</Grid>
								</Grid>
									<Grid item xs={2}>
										<CustomPriceInput label="SD" source="priceESTSD" name="checkPriceESTSD" classes={classes} inputValidation={validatePrice(priceLimit)}  selectAllEST={this.state.checkAllESTPrice}/>
									</Grid>
									<Grid item xs={2}>
										<CustomPriceInput label="HD" source="priceESTHD" name="checkPriceESTHD" classes={classes} inputValidation={validatePrice(priceLimit)} selectAllEST={this.state.checkAllESTPrice}/>
									</Grid>
									<Grid item xs={2}>
										<CustomPriceInput label="4K" source="priceEST4K" name="checkPriceEST4K" classes={classes} inputValidation={validatePrice(priceLimit)} selectAllEST={this.state.checkAllESTPrice}/>
									</Grid>
									<Grid item xs={2}>
										<Button onClick={this.resetForm} color="secondary" variant="contained" className={classes.buttonCancel} >
											<CancelIcon className={classes.leftIcon} />
											{translate('ra.action.undo')}
										</Button>
									</Grid>

								</Grid>
								{/* <Grid item xs={3} style={{ padding: '20px' }}>
									<Typography  className={classes.secondaryHeading}>{translate('labels.newBKPrice')}</Typography>
									<CustomPriceInput label="BK HD/DVD" source="priceHDDVD" name="checkPriceHDDVD" classes={classes} inputValidation={validatePrice} isLargeLabel = {true}/>
									<CustomPriceInput label="BK 4K/DVD" source="price4KDVD" name="checkPrice4KDVD" classes={classes} inputValidation={validatePrice} isLargeLabel = {true}/>
									<CustomPriceInput label="BK HD/BR" source="priceHDBR" name="checkPriceHDBR" classes={classes} inputValidation={validatePrice} isLargeLabel = {true}/>
									<CustomPriceInput label="BK 4K/BR" source="price4KBR" name="checkPrice4KBR" classes={classes} inputValidation={validatePrice} isLargeLabel = {true}/>
								</Grid> */}
							</Grid>
						</SimpleForm>
					</CardContent>
				</Card>
				<AlertDialogSlide {...this.props}
					modalOpen={this.state.modalOpen}
					onClose={this.handleClose}
					modalContent={modalContent}
					launchActivationProcess={this.handleSubmit} />
				<LinearProgressModal {...this.props}
					modalOpen={this.state.progressBarOpen}
					onClose={this.handleCloseProgressBar}
					totalCount={this.state.numberOfUpdate}
					updateProgress={this.state.updateProgress}
					numberOfSuccess={this.state.numberOfSuccess}
					progressModalContent={progressModalContent}
				/>
			</div>
		)
	}
}

const mapStateToProps = state => ({
	...state,
	isSubmitting: isSubmitting(REDUX_FORM_PRICEPLANNING_UPDATE)(state),
	formSyncErrors: getFormSyncErrors(REDUX_FORM_PRICEPLANNING_UPDATE)(state),
	selectedClassificationId: state.reducer.pricePlanning.priceSelected,
	currentContentId: state.reducer.pricePlanning.contentSelected,
	transfersRightsData: state.admin.resources['PricePlanning/TransfersRights']
		? state.admin.resources['PricePlanning/TransfersRights'].data
		: {},
	pricingslists: state.admin.resources['Pricingslists']
		? state.admin.resources['Pricingslists'].data
		: {},
	formValues: state.form[REDUX_FORM_PRICEPLANNING_UPDATE] ? state.form[REDUX_FORM_PRICEPLANNING_UPDATE].values : {},
	objectTypeId: state.reducer.pricePlanning.objectTypeId
})

const mapDispatchToProps = ({
	submit,
	initialize,
	fetchStart,
	fetchEnd,
	showNotification,
	change,
	reset,
	crudGetList: crudGetListAction,
	crudGetOne: crudGetOneAction,
	onSelectContent: onSelectContent,
	onPricePlanningChangePriceLine: onPricePlanningChangePriceLine,
	onExpandUpdateForm,
	onCheckUpdateForm,
	resetPreview
})

const enhance = compose(
	connect(mapStateToProps, mapDispatchToProps),
	withStyles(styles)
)

export default translate(enhance(PricePlanificationUpdate))