export const validateAlbum = (values) => {
	let errorFields = []
	if(values){
		if(values.name === null || values.name === undefined || values.name === ''){
			errorFields.push("Veillez renseigner le nom de l'album")
		}
		if(values.typeid === 0 || values.typeid === undefined){
			errorFields.push("Veillez selectionner le type d'album")
		}
		if(values.parentid === 0 || values.parentid === undefined){
			errorFields.push('Veillez selectionner un album parent')
		}
		if(values.rank === undefined || values.rank ===""){
			errorFields.push("Veillez saisir le rang d'album")
		}
		if(values.isPack && values.typeid !== 2 && values.typeid !== 5)
		 {	errorFields.push("Un album éligible au pack doit être de type 'Pack de Film' ou 'Saison'") }
	}
	if(errorFields.length !== 0){
		return {
			success : false,
			message : errorFields
		}
	}
	return  {
		success : true
	}
}

export const validateEdito = (values) => {
	let errorFields = []
	if(values){
		if(values.name === null || values.name === undefined || values.name === ''){
			errorFields.push("Veillez renseigner le nom de l'editorial")
		}
		if(values.langid === 0 || values.langid === undefined){
			errorFields.push("Veillez selectionner une langue")
		}
		if(values.title === null || values.title === undefined || values.title === ''){
			errorFields.push("Veillez renseigner le titre de l'editorial")
		}
		// if(values.shorttitle === null || values.shorttitle === undefined || values.shorttitle === ''){
		// 	errorFields.push("Veillez renseigner le titre court de l'editorial")
		// }
	
		 
	}
	if(errorFields.length !== 0){
		return {
			success : false,
			message : errorFields
		}
	}
	return  {
		success : true
	}
}

export const validateSousAlbum = (associated, dissocited) => {
	let errorFields = []
		if(associated && associated.length ===0 && dissocited && dissocited.length ===0){
			errorFields.push("Aucun ligne à modifier")
		} 
	
	if(errorFields.length !== 0){
		return {
			success : false,
			message : errorFields
		}
	}
	return  {
		success : true
	}
}
export const validateImage = (values) => {
	let errorFields = []
 	let re = new RegExp( /\w+\.\w{3,4}/)
	if(values){
		if(values.typeid === null || values.typeid === undefined || values.typeid === ''  || values.typeid === 0){
			errorFields.push("Veillez renseigner le type de l'image")
		}
		if(values.title === null || values.title === undefined || values.title === ''){
			errorFields.push("Veillez renseigner le titre de l'image")
		}
		if(values.filename === null || values.filename === undefined || values.filename === ''){
			errorFields.push("Veillez importer un fichier")
		}
		if(values.filename !== null  && values.filename.match(/\w+\.\w{3,4}/) === null ){
				errorFields.push("Nom de fichier cible incorrect")
		}
	
		 
	}
	if(errorFields.length !== 0){
		return {
			success : false,
			message : errorFields
		}
	}
	return  {
		success : true
	}
}
export const validateAlbumMovie = (albumMovie) => {
	console.log(albumMovie)
	let errorFields = []
		if(!albumMovie.rank  || albumMovie.rank === null){
			errorFields.push("Veillez saisir le rang du programme")
		} 
		if( (!albumMovie.movie || !albumMovie.movie.id )){
			errorFields.push("Le programme n'est pas trouvé")
		} 
	
	if(errorFields.length !== 0){
		return {
			success : false,
			message : errorFields
		}
	}
	return  {
		success : true
	}
}