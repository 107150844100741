import { HOME_PAGE_LOADED, HOME_PAGE_UNLOADED, ON_DOMAINS_ITEMS_LOAD } from '../constants/actions'
import { Contacts, Domains, Pricings, PricePlanning } from '../tools'
//import { Contacts, Domains, Companies, Services, Transfers, Profiles, Ratings, Pricings, PricePlanning } from '../tools'

const Promise = global.Promise

const initialState = {
	domainsItemsLoaded: false
}

export default (state = initialState, { type, payload }) => {
	switch (type) {
	case HOME_PAGE_LOADED:
		//Promise.all([Contacts.load(), Domains.load(), Companies.load(), Services.load(), Transfers.load(), Profiles.load(), Ratings.load(), Pricings.load(), PricePlanning.load()])
		Promise.all([Contacts.load(), Domains.load(), Pricings.load(), PricePlanning.load()])
		return {
			...state,
		}
	case ON_DOMAINS_ITEMS_LOAD:
		return {
			...state,
			domainsItemsLoaded: payload.domainsItemsLoaded
		}
	case HOME_PAGE_UNLOADED:
		return state
	default:
		return state
	}
}