import React, { Component } from 'react'
import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import { submit } from 'redux-form'
import { connect } from 'react-redux'
import compose from 'recompose/compose'
import { SimpleForm, TextInput } from 'react-admin'
import IconButton from '@material-ui/core/IconButton'
import { crudGetList as crudGetListAction } from 'ra-core/lib/actions/dataActions'
import { SearchIcon } from '../../constants/icons'
import { CardHeader } from '@material-ui/core'
import { QuickSearchInput } from '../common/inputs'
import { ProgramType } from '../../constants/types'
import { validateRequiredMovieid } from '../common/forms/globalValidation'
import { withStyles } from '@material-ui/core/styles'

const styles = ({
	cardContent: {
		marginTop: '-30px',
	},
})

const REDUX_FORM_PRICINGS_LOAD = 'form-pricings-load'
const resource = 'Transfersrightsprices/MoviesByPeriods'

class PricingToolFilter extends Component {

	handleSearchClick = () => {
		// eslint-disable-next-line react/prop-types
		const { submit } = this.props

		// This is needed because our modal action buttons are oustide the form
		submit(REDUX_FORM_PRICINGS_LOAD)
	}

	handleSubmit = values => {
		// eslint-disable-next-line react/prop-types
		const { crudGetList } = this.props

		crudGetList(
			resource, // resource
			{}, // pagination
			{}, // sort
			{
				movieid: values.movieid,
			} //filter
		)
	}

	render() {
		// eslint-disable-next-line react/prop-types
		const { classes } = this.props
		return (
			<Card>
				<CardHeader title="Rechercher un titre ou un album" />
				<CardContent className={classes.cardContent}>
					<SimpleForm
						form={REDUX_FORM_PRICINGS_LOAD}
						resource={resource}
						onSubmit={this.handleSubmit}
						toolbar={null}
					>
						<QuickSearchInput label="fields.movieid2" source="movieid2" modes={[ProgramType.MOVIE]} />
						<div>
							<TextInput label="fields.movieid" source="movieid" validate={validateRequiredMovieid} />
							<IconButton aria-label="Submit">
								<SearchIcon onClick={this.handleSearchClick} />
							</IconButton>
						</div>
					</SimpleForm>
				</CardContent>
			</Card>
		)
	}
}

const mapStateToProps = state => {
	return state
}

const mapDispatchToProps = {
	submit,
	crudGetList: crudGetListAction
}

const enhance = compose(
	connect(mapStateToProps, mapDispatchToProps),
	withStyles(styles)
)

export default enhance(PricingToolFilter)
