import {
	SELECTED_TAB, ON_CHANGE_SELECTED_CRITERE, SEARCH_VALUE, ON_LOAD_ALBUMS, TAB_VALUE, PARENT_ID_UPDATE,
	ON_ALBUM_FICHE_UPDATE, ON_ALBUM_EDITO_UPDATE, ON_LOAD_ALL_ALBUMS, ON_ADD_DISSOCIATE_ALBUM, ON_ASSOCIATE_SOUS_ALBUM_UPDATE,
	ON_ALBUM_PICTURE_UPDATE, ON_LOAD_NEW_IMAGE,ON_IS_IMAGE_PIVOT,ON_ALBUM_MOVIE_UPDATE,ON_ALBUM_MOVIES_LIST_UPDATE,ON_ALBUM_MOVIES_LIST_FILTER,ON_ALBUM_MOVIES_SHOW_MODE
} from '../constants/actions'

const defaultState = {
	tabIndex: 0,
	critere: 1,
	searchValue: '',
	AlbumsData: [],
	AllAlbums: [],
	tabValue: 0,
	selectedAlbumParent: 0,
	albumFiche: {
		id: 0,
		name: '',
		parentid: 0,
		typeid: 0,
		rank: 0,
		properties: '<PROPS />',

	},
	albumEditorial: {
		albumid: 0,
		name: '',
		title: '',
		shorttitle: '',
		editorial: '<DETAIL><CATCHLINE></CATCHLINE><OUTLINE></OUTLINE><SYNOPSIS></SYNOPSIS><PACKSUMMARY></PACKSUMMARY></DETAIL>',
		properties: '<PROPS/>',
		langid: 2
	},
	programme: {},
	albumPicture: {
		id: '',
		companyid: 1,
		name: '',
		countryid: -1,
		typeid: 0,
		filename: '',
		height: '',
		parentid: '',
		width: '',
		title: ''
	},
	image:{
		path:'',
		preview: '',
		name:'',
		width:0,
		height:0
	},
	albumMovie: {
		id: '',
		companyid: 1,
		countryid: -1,
		title: ''
	},
	associatesousAlbumList: [],
	dissocitedAlbum:[],
	isImagePivot: false,
	addedMoviesToAlbum : [],
	filterProgrammes: {},
	mode: ''
}
 
export default (state = defaultState, { type, payload }) => {
	if (type === SELECTED_TAB) {
		return {
			...state,
			tabIndex: payload.tabIndex
		}
	}
	else if (type === ON_CHANGE_SELECTED_CRITERE) {
		return {
			...state,
			critere: payload.critere
		}
	}
	else if (type === SEARCH_VALUE) {
		return {
			...state,
			searchValue: payload.searchValue
		}
	}
	if (type === ON_LOAD_ALBUMS) {
		return {
			...state,
			AlbumsData: payload.companiesAlbums
		}
	}
	if (type === TAB_VALUE) {
		return {
			...state,
			tabValue: payload.tabValue
		}
	}
	if (type === PARENT_ID_UPDATE) {
		return {
			...state,
			selectedAlbumParent: payload.selectedAlbumParent
		}
	} if (type === ON_ALBUM_FICHE_UPDATE) {
		return {
			...state,
			albumFiche: payload.album
		}
	}
	if (type === ON_ALBUM_EDITO_UPDATE) {
		return {
			...state,
			albumEditorial: payload.edito
		}
	}
	if (type === ON_LOAD_ALL_ALBUMS) {
		return {
			...state,
			AllAlbums: payload.albums
		}
	}
	if (type === ON_ADD_DISSOCIATE_ALBUM) {
		return {
			...state,
			dissocitedAlbum: payload.album,
			

		}
	}
	if (type === ON_ASSOCIATE_SOUS_ALBUM_UPDATE) {
		return {
			...state,
			associatesousAlbumList: payload.listAlbums,
		}
	}
	if (type === ON_ALBUM_PICTURE_UPDATE) {
		return {
			...state,
			albumPicture: payload.albumPicture,
		}
	}if (type === ON_LOAD_NEW_IMAGE) {
		return {
			...state,
			image: payload.image,
		}
	}if (type === ON_IS_IMAGE_PIVOT) {
		return {
			...state,
			isImagePivot: payload.ispivot,
		}
	}if (type === ON_ALBUM_MOVIE_UPDATE) {
		return {
			...state,
			albumMovie: payload.albumMovie,
		}
	}if (type === ON_ALBUM_MOVIES_LIST_UPDATE) {
		return {
			...state,
			addedMoviesToAlbum: payload.listMovies,
		}
	}if (type === ON_ALBUM_MOVIES_LIST_FILTER) {
		return {
			...state,
			filterProgrammes: payload.filter,
		}
	}if (type === ON_ALBUM_MOVIES_SHOW_MODE) {
		return {
			...state,
			mode: payload.mode,
		}
	}
	return state
}