import { ON_LOAD_FAVORITE_BY_OBJECT} from '../constants/actions'

const defaultState = { 
		contactFavorite : [],
 
}
 
export default (state = defaultState, { type, payload }) => {
	if (type === ON_LOAD_FAVORITE_BY_OBJECT) {
		return {
			...state,
			contactFavorite: payload.ContactFavorite }
	}
	return state
}