import React from 'react'
import PropTypes from 'prop-types'
import AvatarCustom from '../AvatarCustom'
import { DomainsItems } from '../../../tools'
import get from 'lodash/get'
import Button from '@material-ui/core/Button'
import {Labeled} from 'react-admin'
import { connect } from 'react-redux'
import compose from 'recompose/compose'
import CircularProgress from '@material-ui/core/CircularProgress'
import { WatchLaterIcon, PlayIcon, DoneIcon, ErrorIcon, CancelIcon } from '../../../constants/icons'
import { withStyles } from '@material-ui/core/styles'

const styles = theme =>   ({
	card: {
		display: 'flex'
	},
	details: {
		display: 'flex',
		flexDirection: 'column'
	},
	content: {
		flex: '1 0 auto'
	},
	controls: {
		display: 'flex',
		alignItems: 'center',
		paddingLeft: theme.spacing(1),
		paddingRight: theme.spacing(1),
		paddingTop: theme.spacing(1),
		paddingBottom: theme.spacing(1)
	},
	lightTooltip: {
		background: theme.palette.text.primary,
		color: '#111',
		boxShadow: theme.shadows[1],
		fontSize: 11
	},
	icon: {
		width: '9em',
		height: '9em'
	},
	colorGreen: {
		background: '#4caf50',
		'&:hover': {
			background: '#4caf50'
		}
	},
	colorRed: {
		background: '#f44336',
		'&:hover': {
			background: '#f44336'
		}
	},
	colorOrange: {
		background: '#ff9800',
		'&:hover': {
			background: '#ff9800'
		}
	},
	colorBlue: {
		background: '#2196f3',
		'&:hover': {
			background: '#2196f3'
		}
	},
	colorGrey: {
		background: '#757575',
		color: '#303030',
		'&:hover': {
			background: '#757575',
			color: '#303030'
		}
	}
})

const IconStatusField = (props) => {
	const { classes, isLoading, label, record, source, variant = null, transfersright = false } = props
	const stateid = get(record, source)

	let di = DomainsItems.getLabel(101, stateid)
	di = transfersright ? DomainsItems.getLabel(86, stateid) : di
	let labelTip = di ? di : 'unknown'

	let icon
	let color
	let colorClassName

	if (1 === stateid) { // Waiting
		icon = <WatchLaterIcon />
		colorClassName = classes.colorGrey
	}
	else if (2 === stateid) { // To do
		icon = <WatchLaterIcon />
		color = 'orange'
		colorClassName = classes.colorOrange
	}
	else if (3 === stateid) { // Pending
		icon = <PlayIcon />
		color = 'blue'
		colorClassName = classes.colorBlue
	}
	else if (4 === stateid) { // OK
		icon = <DoneIcon />
		color = 'green'
		colorClassName = classes.colorGreen
	}
	else if (5 === stateid) { // Error
		icon = <ErrorIcon />
		color = 'red'
		colorClassName = classes.colorRed
	}
	else if (6 === stateid) { // Deprovisionning
		icon = <CancelIcon />
		color = 'orange'
		colorClassName = classes.colorOrange
	}
	else if (7 === stateid) { // To Delete
		icon = <CancelIcon />
		colorClassName = classes.colorGrey
	}

	return (
		transfersright ? (
			<Labeled label={label}>
				<Button variant={variant} color="primary" className={colorClassName}>
					{isLoading && <CircularProgress size={25} thickness={2} />}
					<AvatarCustom icon={icon} color={color} />
					{labelTip}
				</Button>
			</Labeled>
		) : (
			<Button variant={variant} color="primary" className={colorClassName}>
				<AvatarCustom icon={icon} color={color} tooltip={labelTip} />
			</Button>
		)
	)
}

IconStatusField.propTypes = {
	stateid : PropTypes.number,
	classes: PropTypes.object.isRequired,
	isLoading : PropTypes.bool
}

const mapStateToProps = state => ({ isLoading: state.admin.loading > 0 })

const enhance = compose(
	connect(mapStateToProps),
	withStyles(styles)
)

export default enhance(IconStatusField)
