import { ON_CHANGE_SELECTED_WEEK, ON_LOAD_WEEKS, ON_CHANGE_SELECTED_YEAR, ON_LOAD_PLANNING, ON_CHANGE_USER, ON_CHANGE_CATEGORY, ON_CHANGE_GROUP,
	ON_UNLOCK_RESPONSIBLE_UPDATE, ON_UNLOCK_PRIO_UPDATE, ON_UPDATE_RESPONSIBLE_IDS, ON_INITIALIZE_RESPONSIBLE_IDS, 
	ON_UPDATE_RESPONSIBLE_COMPLETED,ON_LOAD_RESPONSIBLE_ROLES, ON_UPDATE_PRIO_PLANNING_IDS,ON_INITIALIZE_PRIO_IDS ,ON_UPDATE_PRIO_COMPLETED,ON_CHANGE_TASKTYPE,
	 ON_CHANGE_SEARCH ,ON_UPDATE_PLANNING_TRI,ON_CHANGE_TASKGROUP,ON_UPDATE_PREP_STATUS,ON_START_TIMER_COUNT} from '../constants/actions'

const initialState = {
	weekNumber : null,
	yearNumber : null,
	years : [],
	weeks : [],
	planningData : [],
	selectedUser : null,
	selectedCategory : null,
	selectedTaskType : null,
	stats : [],
	isUpdateResponsibleLocked : true,
	isUpdatePrioLocked: true,
	publicationResponsibles : [],
	prioContenents :[],
	planningPrios : [],
	isUpdateResponsibleCompleted : false,
	isUpdatePrioCompleted : false,
	programTitle: '',
	isUpdatePlanningTri : true,
	selectedTaskGroup: null,
	prepStatus: [],
	startCount: false
}

export default (state = initialState, { type, payload }) => {
	if (type === ON_CHANGE_SELECTED_WEEK) {
		return { 
			...state,
			weekNumber : payload.weekNumber
		}
	}
	if (type === ON_CHANGE_SELECTED_YEAR) {
		return { 
			...state,
			yearNumber : payload.yearNumber
		}
	}
	if(type === ON_LOAD_WEEKS){
		let tempYears = []
		let tempWeekNumbers = []
		payload.weeks.forEach(week => {
			const currentYear = Number.parseInt(week.toString().substring(0, 4))
			const currentWeekNumber = Number.parseInt(week.toString().substring(4, 6))
			if(!tempYears.find(x => x === currentYear)){
				tempYears.push(currentYear)
			}
			if(!tempWeekNumbers.find(x => x === currentWeekNumber)){
				tempWeekNumbers.push(currentWeekNumber)
			}
		})
		return {
			...state,
			years: tempYears,
			weeks: tempWeekNumbers
		}
	}
	if(type === ON_LOAD_PLANNING){
		return {
			...state,
			planningData : payload.planningData,
			stats : payload.publicationStats
		}
	}
	if(type === ON_CHANGE_USER){
		return {
			...state,
			selectedUser : payload.user
		}
	}
	if(type === ON_CHANGE_CATEGORY){
		return {
			...state,
			selectedCategory : payload.categoryId
		}
	}
	if(type === ON_CHANGE_TASKTYPE){
		return {
			...state,
			selectedTaskType : payload.taskTypeId
		}
	}
	if(type === ON_CHANGE_TASKGROUP){
		return {
			...state,
			selectedTaskGroup : payload.taskGroupId
		}
	}
	if(type === ON_CHANGE_SEARCH){
		return {
			...state,
			programTitle : payload.programTitle
		}
	}
	if(type === ON_CHANGE_GROUP){
		return {
			...state,
			selectedGroup : payload.groupId
		}
	}
	if(type === ON_UNLOCK_RESPONSIBLE_UPDATE){
		return {
			...state,
			isUpdateResponsibleLocked : !payload.isUnlocked
		}
	}
	if(type === ON_UPDATE_PLANNING_TRI){
		return {
			...state,
			isUpdatePlanningTri : !payload.isDefaultTri
		}
	}
	if(type === ON_UPDATE_RESPONSIBLE_IDS){
		let list = []
		const newElement = {
			userId : payload.userId,
			planningId : payload.planningId
		}
		if(state.publicationResponsibles.length!==0 && state.publicationResponsibles.find(item => item.planningId === payload.planningId) !== undefined){
			list = state.publicationResponsibles.filter(item => item.planningId !== payload.planningId )
		}
		else{
			list = state.publicationResponsibles
		}
		list.push(newElement)
		return {
			...state, 
			publicationResponsibles : list
		}
	}

	if(type === ON_INITIALIZE_RESPONSIBLE_IDS){
		return {
			...state,
			publicationResponsibles : []
		}
	}

	if(type === ON_UPDATE_RESPONSIBLE_COMPLETED){
		return {
			...state,
			isUpdateResponsibleCompleted : payload.isOperationCompleted
		}
	}
	if(type === ON_LOAD_RESPONSIBLE_ROLES){
		return {
			...state,
			userId : payload.userId
		}
	}	
	//prio
	if(type === ON_UNLOCK_PRIO_UPDATE){
		return {
			...state,
			isUpdatePrioLocked : !state.isUpdatePrioLocked //payload.isUnlocked
		}
	}
	if(type === ON_UPDATE_PRIO_PLANNING_IDS){
		let list = []
		const newElement = {
			prio : payload.prio,
			planningId : payload.planningId
		}
		if(state.planningPrios.length!==0 && state.planningPrios.find(item => item.planningId === payload.planningId) !== undefined){
			list = state.planningPrios.filter(item => item.planningId !== payload.planningId )
		}
		else{
			list = state.planningPrios
		}
		list.push(newElement)
		return {
			...state, 
			planningPrios : list
		}
	}
	if(type === ON_INITIALIZE_PRIO_IDS){
		return {
			...state,
			planningPrios : []
		}
	}

	if(type === ON_UPDATE_PRIO_COMPLETED){
		return {
			...state,
			isUpdatePrioCompleted : payload.isOperationCompleted
		}
	}
	
	if(type === ON_UPDATE_PREP_STATUS){
		console.log(state.prepStatus)

 		const newElement = {
			publication : payload.publication,
			planningId : payload.planningId
		}
		let list=[]
		if(state.prepStatus.length!==0 && state.prepStatus.find(item => item.planningId === payload.planningId && item.publication.trackingType === payload.publication.trackingType) !== undefined){
			list = state.prepStatus.filter(item => item.planningId !== payload.planningId || item.publication.trackingType !== payload.publication.trackingType )
		}
		else{
			list = state.prepStatus
		}
		list.push(newElement)
		return {
			...state, 
			prepStatus : list
		}
	}	
	if(type === ON_START_TIMER_COUNT){
		return {
			...state, 
			startCount : payload.startCount
		}
	}
	return state
}