/* eslint-disable react/prop-types */
import React, { useState, useEffect } from 'react'
import { TextInput, translate, fetchEnd, fetchStart, GET_LIST, showNotification } from 'react-admin'
import { crudGetList as crudGetListAction, crudGetOne as crudGetOneAction } from 'ra-core/lib/actions/dataActions'
import dataProvider from '../../../dataProvider'
import IconButton from '@material-ui/core/IconButton'
import { SearchIcon } from '../../../constants/icons'
import red from '@material-ui/core/colors/red'
import { QuickSearch } from '../../../requests'
import { connect } from 'react-redux'
import compose from 'recompose/compose'
import { onSelectContent, onPricePlanningFirstLoad, onChangeContentFilter ,changeContentType} from '../../../actions/pricePlanning'
import { change, getFormSyncErrors, initialize, reset  } from 'redux-form'
import { validateContentId } from '../validate'
import { REDUX_FORM_PRICEPLANNING_LOAD, REDUX_FORM_PRICEPLANNING_UPDATE } from '../../../constants/reduxFormName'
import { AUTH_LOGOUT } from 'react-admin'
import authProvider from '../../../authProvider'
import { Logger } from '../../../tools'
import {  withStyles } from '@material-ui/core'

const styles = theme =>({
	albumInformationField : {
		width: '100%',
	},
	buttonCheck: {
		margin: theme.spacing(1),
		width : '150px',
	},
	leftIcon: {
		marginRight: theme.spacing(1),
	},
	customSearchButtonIcon: {
		margin: theme.spacing(1),
		color : theme.palette.text.primary,
		width : '4%',
		height : '38%'
	},
	searchContentLoader: {
		margin: theme.spacing(1),
		color : theme.palette.text.primary,
	},
	checkInputContainer:{
		display: 'flex'
	},
	customInput: {
		float: 'left',
		width : '41%',
		display: 'inline-block'
	},
	notFoundErrorMessage:{
		color: red[500],
		float: 'right',
		marginTop : '5%'
	},
})

const CheckContentInput = props => {
	const { label, source, classes, translate, modes, 
		crudGetList, onSelectContent, fetchEnd, fetchStart, 
		crudGetOne, onChangeContentFilter, change, 
		formSyncErrors, placeholder, initialize,
		objectTypeId, reset, showNotification, isMaintenanceModeEnabled ,changeContentType} = props
	const [inputValue, setInputValue] = useState('')
	const [ searchButtonEnabled, enableSearchButton] = useState(inputValue !== null && inputValue !== '')
	const checkButtonLabel = translate('labels.checkButton')
	const translatedErrorMessage = translate('messages.notFoundContent')
	const id = window.location.href.split('/')[6]

	useEffect(() => {
		initialize(REDUX_FORM_PRICEPLANNING_LOAD)
		if (id){
			setInputValue(id)
			changeContentType(2)
			searchContent('PACK')

		}
		//if()
		
	}, [inputValue==id])

	const searchContent = (formSyncErrors) => {
		if(!formSyncErrors || !formSyncErrors['objectId'] ){ // perform the search only when there is no error
			enableSearchButton(false)
			fetchStart()
			console.log('tetet')
			QuickSearch.search(inputValue, modes, isMaintenanceModeEnabled)
				.then(({ data })  => {
					const list = Object.values(data)
					console.log(list)
					if(list && list.length !== 0){
						dataProvider(GET_LIST, 'PricePlanning/Price', { pagination: { page: 1 , perPage: 10 }, sort: { field: {}, order: {} }, filter: {objectId : inputValue, objectTypeId : objectTypeId} }).then((json)=>{
							if(json.data && json.data.length !== 0){
								const [ first ] = json.data
								initialize(REDUX_FORM_PRICEPLANNING_LOAD)
								onSelectContent(first.pricingClassId, inputValue, json.data) // set the default price classification id and the content id
								crudGetList(
									'Pricingslists', // resource
									{}, // pagination
									{}, // sort
									{ pricingid: first.pricingClassId } //filter
								)
								crudGetOne(
									'PricePlanning/TransfersRights', // resource
									// { contentId: inputValue, contentType : contentType } 
									`${inputValue}_${objectTypeId}`
								)
								enableSearchButton(false)
							}
						}).catch((error) =>{
							enableSearchButton(true)
							showNotification(translatedErrorMessage, 'warning', { autoHideDuration : '6000' })
							Logger.debug('Error message '+error.message)
						})
					}
					else{
						throw new Error(translatedErrorMessage)
					}
				}).catch((error) => {
					Logger.debug('Error message '+ error.message)
					if(error.status === 401 || error.status === 403){
						showNotification('ra.notification.logged_out', 'warning', { autoHideDuration : '6000' })
						authProvider(AUTH_LOGOUT, error)
					}
					else {
						enableSearchButton(true)
						// showNotification(translatedErrorMessage, 'warning', { autoHideDuration : '6000' })
					}
				}).finally(()=>{
					fetchEnd()
				})
		}
		else if(formSyncErrors =='PACK'){
			enableSearchButton(false)
			fetchStart()
			QuickSearch.search(inputValue, modes, isMaintenanceModeEnabled)
				.then(({ data })  => {
					const list = Object.values(data)
					console.log(list)
					if(list && list.length !== 0){
						dataProvider(GET_LIST, 'PricePlanning/Price', { pagination: { page: 1 , perPage: 10 }, sort: { field: {}, order: {} }, filter: {objectId : inputValue, objectTypeId : objectTypeId} }).then((json)=>{
							if(json.data && json.data.length !== 0){
								const [ first ] = json.data
								initialize(REDUX_FORM_PRICEPLANNING_LOAD)
								onSelectContent(first.pricingClassId, inputValue, json.data) // set the default price classification id and the content id
								crudGetList(
									'Pricingslists', // resource
									{}, // pagination
									{}, // sort
									{ pricingid: first.pricingClassId } //filter
								)
								crudGetOne(
									'PricePlanning/TransfersRights', // resource
									// { contentId: inputValue, contentType : contentType } 
									`${inputValue}_${objectTypeId}`
								)
								enableSearchButton(false)
							}
						}).catch((error) =>{
							enableSearchButton(true)
							//showNotification(translatedErrorMessage, 'warning', { autoHideDuration : '6000' })
							Logger.debug('Error message '+error.message)
						})
					}
					// else{
					// 	throw new Error(translatedErrorMessage)
					// }
				}).catch((error) => {
					Logger.debug('Error message '+ error.message)
					if(error.status === 401 || error.status === 403){
						showNotification('ra.notification.logged_out', 'warning', { autoHideDuration : '6000' })
						authProvider(AUTH_LOGOUT, error)
					}
					else {
						enableSearchButton(true)
						//showNotification(translatedErrorMessage, 'warning', { autoHideDuration : '6000' })
					}
				}).finally(()=>{
					fetchEnd()
				})
		}
	}

	const handleCheckButtonClick = (event, formSyncErrors) => {
		searchContent(formSyncErrors)
	}

	const handleEnterKeyPress = (event, formSyncErrors) => {
		searchContent(formSyncErrors)
	}

	const handleInputChange = (event, onChangeContentFilter, change) => {
		setInputValue(event.target.value)
		enableSearchButton(event.target.value !== null && event.target.value !== '')
		onChangeContentFilter()
		change(REDUX_FORM_PRICEPLANNING_LOAD, 'objectId', '')
		change(REDUX_FORM_PRICEPLANNING_LOAD, 'name', '')
		change(REDUX_FORM_PRICEPLANNING_LOAD, 'dateStartVOD', '')
		change(REDUX_FORM_PRICEPLANNING_LOAD, 'dateEndVOD', '')
		change(REDUX_FORM_PRICEPLANNING_LOAD, 'dateStartEST', '')
		change(REDUX_FORM_PRICEPLANNING_LOAD, 'dateEndEST', '')
		reset(REDUX_FORM_PRICEPLANNING_UPDATE)
	}

	return(
		
		<div className = {classes.customInput}>
			{/* <TextInput 
				InputProps = {{ 
					classes : {
						formControl : classes.albumInformationField
					}
				}} 
				label={label} source={source} 
				onChange = {(event) => handleInputChange(event, onChangeContentFilter, change, formSyncErrors)} 
				autoComplete='off'
				validate = {validateContentId} placeholder={placeholder}
				onKeyPress={(event) => {
					if (event.key === 'Enter') {
						handleEnterKeyPress(event, formSyncErrors)
						event.preventDefault()
					}
				}}
			/> */}
			<TextInput id="outlined-search" 
			variant="outlined"
			InputProps = {{ 
				classes : {
					formControl : classes.albumInformationField
				}
			}} 
			label={label} 
			source={source} 
			onChange = {(event) => handleInputChange(event, onChangeContentFilter, change, formSyncErrors)} 
			autoComplete='off'
			validate = {validateContentId} placeholder={placeholder}
			onKeyPress={(event) => {
				if (event.key === 'Enter') {
					handleEnterKeyPress(event, formSyncErrors)
					event.preventDefault()
				}
			}}
			/>

			<IconButton onClick={(event) => handleCheckButtonClick(event, formSyncErrors)} color="primary" className={classes.customSearchButtonIcon} aria-label={checkButtonLabel} disabled = {!searchButtonEnabled}>
				<SearchIcon />
			</IconButton>
		</div>
		
				
	)
}

const mapStateToProps = state => ({
	...state,
	formSyncErrors: getFormSyncErrors(REDUX_FORM_PRICEPLANNING_LOAD)(state),
	objectTypeId: state.reducer.pricePlanning.objectTypeId,
	isMaintenanceModeEnabled : state.reducer.applicationStatus !== null ?  state.reducer.applicationStatus.isMaintenanceModeEnabled : false
})

const mapDispatchToProps = {
	initialize,
	fetchEnd,
	fetchStart,
	crudGetList: crudGetListAction,
	crudGetOne: crudGetOneAction,
	onSelectContent: onSelectContent,
	onPricePlanningFirstLoad: onPricePlanningFirstLoad,
	onChangeContentFilter: onChangeContentFilter,
	change,
	reset,
	showNotification,
	changeContentType
}

const enhance = compose(
	connect(mapStateToProps, mapDispatchToProps),
	withStyles(styles)
)

export default translate(enhance(CheckContentInput))