/* eslint-disable react/jsx-key */
import React, { useState, useMemo } from 'react'
import Dropzone ,{ useDropzone } from 'react-dropzone'
import { connect } from 'react-redux'
// import { SimpleForm, fetchEnd, fetchStart, showNotification, translate } from 'react-admin'
import { change, submit, isSubmitting } from 'redux-form'
import { stringify } from 'query-string'
import { post } from 'axios'
import { Token, Logger } from '../../tools'
import { CloudUploadIcon ,CloseIcon} from '../../constants/icons'
import compose from 'recompose/compose'
import Button from '@material-ui/core/Button'
import green from '@material-ui/core/colors/green'
import red from '@material-ui/core/colors/red'
import grey from '@material-ui/core/colors/grey'
import Typography from '@material-ui/core/Typography'
import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import FileInformationContainer from './dropzone/FileInformationContainer'
import { ProcessLoader } from '../common'
import { createTheme, withStyles, ThemeProvider } from '@material-ui/core/styles'
import Dialog from '@material-ui/core/Dialog'
import Slide from '@material-ui/core/Slide'
import cyan from '@material-ui/core/colors/cyan'


const styles = theme => ({
	fileAccepted: {
		backgroundColor : green[500],
		marginTop: '5px',
		textAlign : 'center'
	},
	fileRejected: {
		backgroundColor : red[400],
		marginTop: '5px',
		textAlign : 'center'
	},
  
	formControl: {
		margin: theme.spacing(1),
		minWidth: 120,
	},
	selectEmpty: {
		marginTop: theme.spacing(2),
	},
	customSelect: {
		width : '80%'
	},
	fileInformationSection : {
		width : '100%'
	},
	container: {
		padding: '0.5em',
	},
	 
})

const baseStyle = {
	display: 'flex',
	flexDirection: 'column',
	alignItems: 'center',
	padding: '20px',
	borderRadius: '7px',
	backgroundColor: '#b3b3b3',
	color: 'grey[900]',
	outline: 'none',
	transition: 'border .24s ease-in-out'
}

const activeStyle = {
	backgroundColor: '#2196f3'
}

const acceptStyle = {
	backgroundColor: green[200]
}

const rejectStyle = {
	backgroundColor: red[400]
}
const Transition = React.forwardRef(function Transition(props, ref) {
	return <Slide direction="up" ref={ref} {...props} />
})
const REDUX_FORM_UPLOAD_TOOLS = 'REDUX_FORM_UPLOAD_TOOLS'
const maxSize = 20971520

// class DropZoneGlobal extends Component{
const DropZoneGlobal = (props) => {
	const { classes, open, onClose,  translate, create, title, fileRules, mode,image,OnLoadNewImage,disabled,removeImage }  = props
	const [acceptedFiles, setAcceptedFiles] = useState([])
	const [rejectedFiles, setRejectedFiles] = useState([])
	const [isButtonSaveActivated, setIsButtonSaveActivated] = useState(false)
	const [isProcessLoaderOpen, setIsProcessLoaderOpen] = useState(false)
	const [isOperationFinished, setIsOperationFinished] = useState(false)
	const [file, setFile] = useState([])
	const [modeSelected, setModeSelected] = useState(2)
	const isJsValidationEnabled = parseInt(process.env.REACT_APP_ACTIVATE_DROPZONE_JSVALIDATION, 10) === 1 ? true : false
	const [textFieldValues, setTextFieldValues] = useState("")
	const [sendMode, setSendMode] = useState(false)
	const [textFieldValueError, setTextFieldValueError] = useState("")
 	const handleOnDrop = (acceptedFiles, rejectedFiles) => {
		const image = new Image()
 		acceptedFiles.map((file) =>
		{
 			image.src =  URL.createObjectURL(file)
			image.onload = function() {
				file.width = image.width
				file.height= image.height
				OnLoadNewImage(file)

			}
			Object.assign(file, {
				preview: URL.createObjectURL(file),
				width: file.width,
				height: file.height
			  })
 			// if(removeImage)
			// removeFile(file)
		}

		
	  )

		if(isJsValidationEnabled){
			if(acceptedFiles && acceptedFiles.length !== 0){
 				setTextFieldValues("")
				setFile(acceptedFiles[0])
				setIsButtonSaveActivated(true)
				setAcceptedFiles(acceptedFiles)
				setRejectedFiles([])
			}
			else{
				setTextFieldValues("")
				setIsButtonSaveActivated(false)
				setAcceptedFiles([])
				setRejectedFiles(rejectedFiles)
			}
		}
		else{
 			setTextFieldValues("")
			setFile(acceptedFiles[0])
			setIsButtonSaveActivated(true)
			setAcceptedFiles(acceptedFiles)
			setRejectedFiles([])
		}
		if(image)
		image.filename = acceptedFiles[0] ? acceptedFiles[0].name : ''	

		
 	}

	const removeFile = (file) => () => {
		const newFiles = [...acceptedFiles];
		newFiles.splice(newFiles.indexOf(file), 1);
		setFile(newFiles);
	  };

 
	const getMuiTheme = (props) => createTheme({
		overrides: {
			MuiPaper:{
				elevation1:{
					boxShadow: 'none'
				},
				root:{
					color : props.disabled ? '#a8a3a3de' :'black'
				}
			}
		}
	})
	const handleCloseProgressBar = () => {
		setIsProcessLoaderOpen(false)
	}
		// const saveButtonLabel = 'Transférer'
		// const isJsValidationEnabled = parseInt(process.env.REACT_APP_ACTIVATE_DROPZONE_JSVALIDATION, 10) === 1 ? true : false
		const progressModalContent = {
			title : "IMPORT IMAGE",
			closeButtonText : "FERMER",
			operationFinished : ''//translate('messages.uploadSuccess')
		}
			return (		
				<div>
			
					<ThemeProvider theme={getMuiTheme(props)}>
						<Card>
						<div className={classes.container}>	
							
								<Dropzone 
									onDrop={handleOnDrop}
									multiple = {false}
									accept=".jpeg,.jpg,.png,.gif"
									disabled={disabled}
								>
									{({getRootProps, getInputProps, isDragActive, isDragReject, rejectedFiles, isDragAccept, acceptedFiles, draggedFiles }) => {
										const style = useMemo(() => ({
											...baseStyle,
											...(isDragActive ? baseStyle : {}),
											...(isDragAccept ? baseStyle : {}),
											...(isDragReject ? baseStyle : {}),
											//backgroundColor: !sendMode ? "#00BCD4" : "#E0E0E0",
											//color: disabled ? '#a8a3a3de' :'grey[900]',
											borderRadius: "5px"
										}), [
											isDragActive,
											isDragReject,
											//sendMode
										])
										return (
											<React.Fragment>
												<div {...getRootProps({style})}>
													<input {...getInputProps()} />
													{!isDragActive && 'Glissez votre fichier ici ou cliquez pour sélectionner le fichier à transférer'}
													{isDragActive && !isDragReject  && 'Fichier prêt à être transféré!'}
													{isDragReject && 'Fichier prêt à être transféré!'}
												</div>
											</React.Fragment>
										)}
									}
								</Dropzone>

								{ <FileInformationContainer classes={classes} acceptedFiles={acceptedFiles} rejectedFiles={rejectedFiles} isJsValidationEnabled={isJsValidationEnabled} removeImage={removeImage}/>}
								
							
						</div>
						</Card>
					</ThemeProvider>
					<ProcessLoader modalOpen = {isProcessLoaderOpen} 
					onClose={handleCloseProgressBar}  
					isOperationFinished = { isOperationFinished }
					progressModalContent = {progressModalContent}
				/>
			{/* </Dialog> */}
				</div>
				 
 				
			)


	}
			
		 

	

const mapStateToProps = state => {
	return ({
		isSubmitting: isSubmitting(REDUX_FORM_UPLOAD_TOOLS)(state),
	})
}

const mapDispatchToProps = {
	change,
	// fetchEnd,
	// fetchStart,
	// showNotification,
	submit,
}

const enhance = compose(
	connect(mapStateToProps, mapDispatchToProps),
	withStyles(styles)
)
 
export default (enhance(DropZoneGlobal))