
import { Procs } from '../constants/procs'
export const MenuSection = [
	{
		'menuname': 'Referentiel',
		'menunameitem': [
			{
				'target': 'Domainsitems',
				'label': 'Domaines',
				'procid' : Procs.MENU2_DOMAINS_READ
			},
			{
				'target': 'Pricingscodes',
				'label': 'Grille tarifaire InApp',
				'procid' : Procs.MENU3_MOVIES_READ
			},
		]
	},
	{
		'menuname': 'Catalogues',
		'menunameitem': [
			{
				'target': 'Companiesalbums',
				'label': 'Albums',
				'procid' : Procs.MENU2_ALBUMS_READ
			},
			{
				'target': 'Companiesmovies',
				'label': 'Programmes',
				'procid' : Procs.MENU3_MOVIES_READ
			},
			{
				'target': 'Companiesproducts',
				'label': 'Produits',
				'procid' : Procs.MENU3_PRODUCTS_READ
			},
			{
				'target': 'Companiespeoples',
				'label': 'Personnalités',
				'procid' : Procs.MENU3_PEOPLES_READ
			}
		]
	},
	{
		'menuname': 'Droits',
		'menunameitem': [
			{
				'target': 'Companiestransfers',
				'label': 'Transfert',
				'procid' : Procs.MENU4_TRANSFERS_READ
			}
		]
	},
	{
		'menuname': 'Outils',
		'menunameitem': [
			{
				'target': 'Watcher/IngestionStatus',
				'label': 'Ingestions',
				'procid' : Procs.INGESTION_STATUS
			},
			{
				'target': 'Jobs/Supervision',
				'label': 'Supervision Jobs',
				'procid' : Procs.JOBS_SUPERVISION
			},
			// {
			// 	'target': 'Moviespriorities',
			// 	'label': 'Top Prio',
			// 	'procid' : Procs.MOVIES_TOP_PRIO_READ
			// },
			{
				'target': 'Catalogbase/List',
				'label': 'Catalogue',
				'procid' : Procs.CATALOG_BASE_LIST
			},
			{
				'target': 'PricePlanning/Price',
				'label': 'Planification des prix',
				'procid' : Procs.MENU3_MOVIES_READ
			},
			{
				'target': 'Tools/Upload',
				'label': 'Import de fichiers',
				'procid' : Procs.MENU3_MOVIES_READ
			},
			{
				'target': 'PublicationTracking/Planning',
				'label': 'Planning des publications',
				'procid' : Procs.MENU3_MOVIES_READ
			},
			// {
			// 	'target': 'PublicationTracking/Preparation',
			// 	'label': 'Preparation des publications',
			// 	'procid' : Procs.MENU3_MOVIES_READ
			// },
			{
				'target': 'Promotions',
				'label': 'Promotion Hapi',
				'procid': Procs.MENU3_MOVIES_READ
			},
		],
	},
]