import React, { Component } from 'react'
import { TextInput, SelectInput, REDUX_FORM_NAME, NumberInput, SimpleShowLayout } from 'react-admin'
import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import Typography from '@material-ui/core/Typography'
import { autofill, registerField } from 'redux-form'
import { connect } from 'react-redux'
import compose from 'recompose/compose'
import RichTextInput from 'ra-input-rich-text'
import { CheckboxInput, DatePickerCanal } from '../common/inputs'
import { Token, DomainsItems, Services, Contacts, Companies } from '../../tools'
import { withStyles } from '@material-ui/core/styles'

const styles = theme => ({
	cardPaddingMargin: {
		padding: 20,
		margin: 20,
		flex: '1 auto'
	},
	cardDiv: {
		flex: '1 auto'
	},
	flexContent: {
		display: 'flex',
		alignItems: 'flex-start'
	},
	input: {
		display: 'inline-block'
	},
	inputLeft: {
		display: 'inline-block',
		marginLeft: 32
	},
	noMarginLeft: {
		marginLeft: 0
	}
})

class CardCreate extends Component {

	render() {
		const { classes, autofill } = this.props
		autofill(REDUX_FORM_NAME, 'companyid', 1)
		autofill(REDUX_FORM_NAME, 'contactid', parseInt(Token.getId(), 10))

		return (
			<div className={classes.flexContent}>
				<Card className={classes.cardPaddingMargin}>
					<CardContent>
						<Typography variant="title">Fiche du nouveau programme</Typography>
					</CardContent>
					<SimpleShowLayout>
						<TextInput label="fields.label" source="label" />
						<SelectInput label="fields.companyid" source="companyid" choices={Companies.getSelectList()} allowEmpty={false} />
						<SelectInput label="fields.contactid" source="contactid" choices={Contacts.getSelectList()} allowEmpty={false} />
						<SelectInput label="fields.serviceid" source="serviceid" choices={Services.getSelectList()} allowEmpty={false} />
						<SelectInput label="fields.modeid" source="modeid" choices={DomainsItems.getSelectList(9)} allowEmpty={false} />
						<DatePickerCanal label="fields.startdate" source="startdate" />
						<DatePickerCanal label="fields.enddate" source="enddate" />
						<CheckboxInput label="fields.renewed" source="renewed" />
						<CheckboxInput label="fields.exclusive" source="exclusive" />
						<RichTextInput label="fields.comments" source="comments" />
						<SelectInput label="fields.vatid" source="vatid" choices={DomainsItems.getSelectList(25)} allowEmpty={false} />
						<SelectInput label="fields.currencyid" source="currencyid" choices={DomainsItems.getSelectList(3)} allowEmpty={false} />
						<NumberInput label="fields.commissionfee" source="commissionfee" />
					</SimpleShowLayout>
				</Card>
			</div>
		)
	}
}

const mapDispatchToProps = {
	autofill,
	registerField
}

const enhance = compose(
	connect(null, mapDispatchToProps),
	withStyles(styles)
)

export default enhance(CardCreate)
