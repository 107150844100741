export const ON_PROMO_LOAD = 'ON_PROMO_LOAD'
export const ON_PROMO_UPDATE = 'ON_PROMO_UPDATE'
export const ON_GET_PROMO = 'ON_GET_PROMO'
export const ON_PROMO_UPDATE_REPONSE = 'ON_PROMO_UPDATE_REPONSE'
export const ON_PROMO_CHECK_VOUCHER_EXIST = 'ON_PROMO_CHECK_VOUCHER_EXIST'
export const ON_PROMO_CHECK_SUBSCRIBER_EXIST = 'ON_PROMO_CHECK_SUBSCRIBER_EXIST'
export const ON_RESET_PROMO = 'ON_RESET_PROMO'
export const ON_PROMO_SET_CONTROL_FIELD = 'ON_PROMO_SET_CONTROL_FIELD'
export const ON_PROMO_RESET_CONTROL_FIELD = 'ON_PROMO_RESET_CONTROL_FIELD'
export const ON_PROMO_SET_APPLY_ALL_CATALOGUE = 'ON_PROMO_SET_APPLY_ALL_CATALOGUE'
export const ON_PROMO_SET_SELECTED_TAB = 'ON_PROMO_SET_SELECTED_TAB'
export const ON_PROMO_SET_SEARCH_PARAMS = 'ON_PROMO_SET_SEARCH_PARAMS'
export const ON_LOAD_PROMO_LIST = 'ON_LOAD_PROMO_LIST'

export const onPromotionLoad = (payload) => ({
	type: ON_PROMO_LOAD,
	payload,
})

export const onPromotionUpdate = (payload) => ({
	type: ON_PROMO_UPDATE,
	payload,
})

export const onGetPromo = () => ({
	type: ON_GET_PROMO
})

export const onPromotionUpdateResponse = (returned) => ({
	type: ON_PROMO_UPDATE_REPONSE,
	returned
})

export const onPromotionVoucherExist = (isVoucherExist) => ({
	type: ON_PROMO_CHECK_VOUCHER_EXIST,
	isVoucherExist
})

export const onPromotionSubscriberExist = (isSubscribersExist) => ({
	type: ON_PROMO_CHECK_SUBSCRIBER_EXIST,
	isSubscribersExist
})

export const onResetPromo = () => ({
	type: ON_RESET_PROMO
})

export const onSetControlField = (controlField) => ({
	type: ON_PROMO_SET_CONTROL_FIELD,
	controlField
})

export const onResetControlField = () => ({
	type: ON_PROMO_RESET_CONTROL_FIELD
})

export const onPromoApplyAllCatalogue = (promotionApplyAllCatalogue) => ({
	type: ON_PROMO_SET_APPLY_ALL_CATALOGUE,
	promotionApplyAllCatalogue
})

export const onPromoSetSelectedTab = (selectedTabIndex) => ({
	type: ON_PROMO_SET_SELECTED_TAB,
	selectedTabIndex
})

export const onPromoSetSearchParams = (searchPromoListParams) => ({
	type: ON_PROMO_SET_SEARCH_PARAMS,
	searchPromoListParams
})

export const onPromoLoadList = (searchPromoList) => ({
	type: ON_LOAD_PROMO_LIST,
	searchPromoList
})