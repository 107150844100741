import React from 'react'
import { connect } from 'react-redux'
import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import { HOME_PAGE_LOADED, HOME_PAGE_UNLOADED, ON_DOMAINS_ITEMS_LOAD } from './constants/actions'
import { DomainsItems } from './tools'
import ContactsFavorites from './requests/ContactsFavorites'

import SidescrollList from './components/dashboard/SidescrollList'
import { ConfigContext } from '../src/components/layout/ConfigProvider'

class Dashboard extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			isFavoriteMoviesLoading: true,
			isFavoriteAlbumsLoading: true,
			isFavoritePromotionsLoading: true,
			favoriteMovies: [],
			favoriteAlbums: [],
			favoritePromotions: [],
			history: [],
		};
	}
	static contextType = ConfigContext
	getFavoriteMovies = async () => {
		this.setState({isFavoriteMoviesLoading: true})
		await ContactsFavorites.getAllWithTitle(1).then((json) => {
			console.log(json.data)
				this.renameDataId(json.data)
				this.setState({favoriteMovies: json.data.reverse()})
				this.setState({isFavoriteMoviesLoading: false})
				// console.log(this.state.favoriteMovies)
		})
	}
	getFavoriteAlbums = async () => {
		this.setState({isFavoriteAlbumsLoading: true})
		await ContactsFavorites.getAllWithTitle(2).then((json) => {
				this.renameDataId(json.data)
				this.setState({favoriteAlbums: json.data.reverse()})
				this.setState({isFavoriteAlbumsLoading: false})
				// console.log(this.state.favoriteAlbums)
		})
	}
	getFavoritePromotions = async () => {
		this.setState({isFavoritePromotionsLoading: true})
		await ContactsFavorites.getAllWithTitle(6).then((json) => {
				this.renameDataId(json.data)
				this.setState({favoritePromotions: json.data.reverse()})
				this.setState({isFavoritePromotionsLoading: false})
				// console.log(this.state.favoritePromotions)
		})
	}

	removeFavoriteMovies = async (id) => {
		await ContactsFavorites.removeFavorite(id).then(() => {
			this.getFavoriteMovies()
		}).catch((error)=>{
			console.log(error)
			this.getFavoriteMovies()
		})
	}
	removeFavoriteAlbums = async (id) => {
		await ContactsFavorites.removeFavorite(id).then(() => {
			this.getFavoriteAlbums()
		}).catch((error)=>{
			console.log(error)
			this.getFavoriteAlbums()
		})
	}
	removeFavoritePromotions = async (id) => {
		await ContactsFavorites.removeFavorite(id).then(() => {
			this.getFavoritePromotions()
		}).catch((error)=>{
			console.log(error)
			this.getFavoritePromotions()
		})
	}

	removeAllFavorites = async (objectid) => {
		await ContactsFavorites.removeAllFavorites(objectid).then(() => {
			switch(objectid){
				case 1: 
					this.getFavoriteMovies()
					break
				case 2: 
					this.getFavoriteAlbums()
					break
				case 6:
					this.getFavoritePromotions()
					break
				default:
					break
			}
		}).catch((error)=>{
			console.log(error)
			switch(objectid){
				case 1: 
					this.getFavoriteMovies()
					break
				case 2: 
					this.getFavoriteAlbums()
					break
				case 6:
					this.getFavoritePromotions()
					break
				default:
					break
			}
		})
	}

	setHistoryData = () => {
		var data = null
		if(JSON.parse(localStorage.getItem('history'))){
			data = JSON.parse(localStorage.getItem('history'))
		}
		if(data!=null){
			this.setState({history: data})
		}else{this.setState({history: []})}
		console.log(data)
	}

	updateHistoryData = (data) => {
		this.setState({history : data})
	}
	
	//Transforme la clé "refId" en "id" dans les listes d'objets
	renameDataId(data) {
		const newData = data.map((data)=>{
			data['id'] = data['refId']
			delete data['refId']
			return newData
		})
	}


	componentDidMount() {
		const { loadingDomainsItems } = this.props
		// eslint-disable-next-line react/prop-types
		console.log(this.props.admin)
		 // Read cookies		
		// Token.store(data)

		if (this.props.admin && this.props.admin.auth && this.props.admin.auth.isLoggedIn){
			if(!DomainsItems.exists()){
				DomainsItems.load().then(json => {
					DomainsItems.store(json)
					loadingDomainsItems(true)
				})
			}
			else{
				loadingDomainsItems(true)
			}
			// eslint-disable-next-line react/prop-types
			this.props.onLoad()
		}

		this.setHistoryData()
		this.getFavoriteMovies()
		this.getFavoriteAlbums()
		this.getFavoritePromotions()
		if(localStorage.getItem('expandHistory') == null)localStorage.setItem('expandHistory', "true")
		if(localStorage.getItem('expandFavMovies') == null)localStorage.setItem('expandFavMovies', "true")
		if(localStorage.getItem('expandFavAlbums') == null)localStorage.setItem('expandFavAlbums', "true")
		if(localStorage.getItem('expandFavPromotions') == null)localStorage.setItem('expandFavPromotions', "true")
	}

	componentWillUnmount() {
		// eslint-disable-next-line react/prop-types
		if (this.props.admin && this.props.admin.auth && this.props.admin.auth.isLoggedIn)
			// eslint-disable-next-line react/prop-types
			this.props.onUnload()
	}
	getCookie(name) {
		const value = `; ${document.cookie}`;
		const parts = value.split(`; ${name}=`);
		if (parts.length === 2) return parts.pop().split(';').shift();
	  }
	
	render() {
	 
		const { config } = this.context 
 		const maxFav = config && config.maxFavoris
		return (
			<div>
				<Card>
					{/* <CardHeader
						title="Back-Office Hawc"
						subheader="Bienvenue dans le nouveau Back-Office"
					/> */}
					<CardContent>
						<SidescrollList 
							title="RÉCEMMENT CONSULTÉS" 
							data={this.state.history} 
							updateHistoryData={this.updateHistoryData}
							objectId='history'
							// maxFavItems={window.Max_Favoris}
							localStorageName="expandHistory" />
						<SidescrollList 
							title="PROGRAMMES FAVORIS" 
							data={this.state.favoriteMovies} 
							objectId='1'
							maxFavItems={maxFav}
							isFavoriteMoviesLoading={this.state.isFavoriteMoviesLoading}
							getFavoriteMovies={this.getFavoriteMovies}
							removeFavoriteMovies={this.removeFavoriteMovies}
							removeAllFavorites={this.removeAllFavorites}
							localStorageName="expandFavMovies" />
						<SidescrollList 
							title="ALBUMS FAVORIS" 
							data={this.state.favoriteAlbums}
							objectId='2' 
							maxFavItems={maxFav}
							isFavoriteAlbumsLoading={this.state.isFavoriteAlbumsLoading}
							getFavoriteAlbums={this.getFavoriteAlbums}
							removeFavoriteAlbums={this.removeFavoriteAlbums}
							removeAllFavorites={this.removeAllFavorites}
							localStorageName="expandFavAlbums" />
						<SidescrollList 
							title="PROMOTIONS FAVORITES" 
							data={this.state.favoritePromotions} 
							objectId='6'
							maxFavItems={maxFav}
							isFavoritePromotionsLoading={this.state.isFavoritePromotionsLoading}
							getFavoritePromotions={this.getFavoritePromotions}
							removeFavoritePromotions={this.removeFavoritePromotions}
							removeAllFavorites={this.removeAllFavorites}
							localStorageName="expandFavPromotions" />
					</CardContent>
				</Card>
			</div>
		)
	}
}

const mapStateToProps = state => ({
	...state
})

const mapDispatchToProps = dispatch => ({
	loadingDomainsItems : (isDomainLoaded) => 
		dispatch({ type: ON_DOMAINS_ITEMS_LOAD, payload: {
			domainsItemsLoaded : isDomainLoaded
		}}),
	onLoad: () =>
		dispatch({ type: HOME_PAGE_LOADED }),
	onUnload: () =>
		dispatch({ type: HOME_PAGE_UNLOADED }),
})

export default connect(mapStateToProps, mapDispatchToProps)(Dashboard)
