import React, { Component } from 'react'
import PropTypes from 'prop-types'
import Dialog from '@material-ui/core/Dialog'
import AppBar from '@material-ui/core/AppBar'
import Toolbar from '@material-ui/core/Toolbar'
import IconButton from '@material-ui/core/IconButton'
import Typography from '@material-ui/core/Typography'
import Slide from '@material-ui/core/Slide'
import { CloseIcon } from '../../constants/icons'
import { withStyles } from '@material-ui/core/styles'

const styles =   ({
	appBar: {
		position: 'relative',
	},
	flex: {
		flex: 1,
	},
})

function Transition(props) {
	return <Slide direction="up" {...props} />
}

class FullScreenDialog extends Component {
	render() {
		const { open, classes, title, src, content, xml, onClose } = this.props

		const iframe = null != src ? <iframe title={title} src={src} className={classes.flex} /> : null
		const xmlTree = null != xml ? <div>{xml}</div> : null

		return (
			<Dialog
				fullScreen
				open={open}
				onClose={onClose}
				TransitionComponent={Transition}
			>
				<AppBar className={classes.appBar}>
					<Toolbar>
						<Typography variant="title" color="inherit" className={classes.flex}>
							{title}
						</Typography>
						<IconButton color="inherit" onClick={onClose} aria-label="Close">
							<CloseIcon />
						</IconButton>
					</Toolbar>
				</AppBar>
				<div>
					<pre>
						<Typography variant="body1">
							{content}
							{xmlTree}
							{iframe}
						</Typography>
					</pre>
				</div>
			</Dialog>
		)
	}
}

FullScreenDialog.propTypes = {
	open: PropTypes.bool,
	classes: PropTypes.object.isRequired,
	title: PropTypes.string,
	src: PropTypes.string,
	xml: PropTypes.string,
	content: PropTypes.node,
	onClose: PropTypes.func,
}

export default withStyles(styles)(FullScreenDialog)