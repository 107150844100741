import React from 'react'
import { SelectInput, TextInput } from 'react-admin'
import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import Typography from '@material-ui/core/Typography'
import CardChild from '../../../CardChild'
import RichTextInput from 'ra-input-rich-text'
import { DomainsItems } from '../../../tools'
import { ExtendedInput } from '../../common/inputs'
import { withStyles } from '@material-ui/core/styles'

const styles = theme =>   ({
	cardPaddingMargin: {
		padding: 20,
		margin: 20,
		flex: '10'
	},
	cardDiv: {
		flex: '1 auto'
	},
	flexContent: {
		display: 'flex',
		alignItems: 'flex-start'
	}
})

const EditorialsEdit = withStyles(styles)(({ classes, record } = this.props) => (
	<div className={classes.flexContent}>
		<Card className={classes.cardPaddingMargin}>
			<CardContent>
				<Typography variant="title">Éditorial de la personnalité {record.firstname} {record.lastname}</Typography>
			</CardContent>
			<CardChild>
				<SelectInput label="fields.langid" source="langid" choices={DomainsItems.getSelectList(1)} allowEmpty={false} />
				<TextInput label="fields.birthplace" source="birthPlace" />
				<TextInput label="fields.deathplace" source="deathPlace" />
				<RichTextInput label="fields.biography" source="bioGraphy" />
			</CardChild>
		</Card>
		<div className={classes.cardDiv}>
			<Card className={classes.cardPaddingMargin}>
				<CardContent>
					<Typography variant="title">Gestion</Typography>
				</CardContent>
				<CardChild>
					<ExtendedInput label="fields.createdon" source="createdon" date disabled />
					<ExtendedInput label="fields.createdby" source="createdby" contact disabled />
					<ExtendedInput label="fields.modifiedon" source="modifiedon" date disabled />
					<ExtendedInput label="fields.modifiedby" source="modifiedby" contact disabled />
				</CardChild>
			</Card>
		</div>
	</div>
))

export default EditorialsEdit
