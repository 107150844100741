import React from 'react'
import PropTypes from 'prop-types'
import get from 'lodash/get'
import pure from 'recompose/pure'
import { Contacts } from '../../../tools'
import Typography from '@material-ui/core/Typography'
import sanitizeRestProps from './sanitizeRestProps'
import { withStyles } from '@material-ui/core/styles'

const styles = theme =>   ({
	root: {
		borderRadius: 4,
		color: theme.palette.common.black,
		backgroundColor: theme.palette.common.white,
		border: '1px solid #ced4da',
		padding: '6px 8px',
		minWidth: '200px',
		minHeight: '20px',
	},
})

const ContactField = ({ classes, className, source, record = {}, ...rest }) => {
	const contactid = get(record, source)
	if (!contactid || contactid <= 0)
		return null

	return <Typography
		component="span"
		body1="body1"
		className={className}
		classes={classes}
		{...sanitizeRestProps(rest)}
	>
		{Contacts.getFormattedLabel(contactid)}
	</Typography>
}

ContactField.propTypes = {
	addLabel: PropTypes.bool,
	className: PropTypes.string,
	record: PropTypes.object,
	source: PropTypes.string.isRequired,
	classes: PropTypes.object.isRequired
}

const PureContactField = pure(withStyles(styles)(ContactField))

PureContactField.defaultProps = {
	addLabel: true,
}

export default PureContactField
