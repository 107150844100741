export const Procs = { 
	MENU1_ACCESS: 1000, 
	MENU1_STATS: 1002,
	MENU2_ACCESS: 2000, 
	MENU2_DOMAINS_READ: 2100, 
	MENU2_DOMAINS_WRITE: 2101, 
	MENU2_REGIONS_READ: 2120, 
	MENU2_REGIONS_WRITE: 2121, 
	MENU2_ALBUMS_READ: 2140, MENU2_ALBUMS_WRITE: 2141, 
	MENU2_ALBUMS_EDITORIALS_READ: 2142,
	MENU2_ALBUMS_EDITORIALS_WRITE: 2143,
	MENU2_ALBUMS_PICTURES_READ: 2144, 
	MENU2_ALBUMS_PICTURES_WRITE: 2145,
	MENU2_ALBUMS_MOVIES_READ: 2146, 
	MENU2_ALBUMS_MOVIES_WRITE: 2147,
	MENU2_ALBUMS_PEOPLES_READ: 2148, 
	MENU2_ALBUMS_PEOPLES_WRITE: 2149, 
	MENU2_ALBUMS_QUERIES_READ: 2150,
	MENU2_ALBUMS_QUERIES_WRITE: 2151,
	MENU2_PROFILES_READ: 2160,
	MENU2_PROFILES_WRITE: 2161,
	MENU2_RATINGS_READ: 2180,
	MENU2_RATINGS_WRITE: 2181,
	MENU2_PRICING_CLASS_PRICE_READ: 2190,
	MENU2_PRICING_CLASS_PRICE_WRITE: 2191,
	MENU2_LOCATOR_READ: 2200, 
	MENU2_LOCATOR_WRITE: 2201, 
	MENU2_GROUP_READ: 2220, 
	MENU2_GROUP_WRITE: 2221, 
	MENU2_TEMPLATE_MAILING_READ: 2240,
	MENU2_TEMPLATE_MAILING_WRITE: 2241, 
	MENU2_EXCLUDEDKEYWORDS_READ: 2260, 
	MENU2_EXCLUDEDKEYWORDS_WRITE: 2261, 
	MENU2_WIZARD_SLOTS_READ: 2270, 
	MENU2_WIZARD_SLOTS_WRITE: 2271, 
	MENU2_CMS_MENUS_READ: 2280, 
	MENU2_CMS_MENUS_WRITE: 2281, 
	MENU3_ACCESS: 3000, 
	MENU3_MOVIES_READ: 3100, 
	MENU3_MOVIES_WRITE: 3101, 
	MENU3_MOVIESDETAILS_READ: 3102, 
	MENU3_MOVIESDETAILS_WRITE: 3103, 
	MENU3_MOVIESPICTURES_READ: 3104, 
	MENU3_MOVIESPICTURES_WRITE: 3105, 
	MENU3_MOVIESEDITO_READ: 3106, 
	MENU3_MOVIESEDITO_WRITE: 3107, 
	MENU3_MOVIESALBUMS_READ: 3108, 
	MENU3_MOVIESALBUMS_WRITE: 3109, 
	MENU3_MOVIESPEOPLES_READ: 3110, 
	MENU3_MOVIESPEOPLES_WRITE: 3111, 
	MENU3_MOVIESCONTENTS_READ: 3112,
	MENU3_MOVIESCONTENTS_WRITE: 3113, 
	MENU3_MOVIESWORKFLOW_WRITE: 3115, 
	MENU3_PEOPLES_READ: 3120, 
	MENU3_PEOPLES_WRITE: 3121, 
	MENU3_PEOPLESEDITO_READ: 3122, 
	MENU3_PEOPLESEDITO_WRITE: 3123, 
	MENU3_PEOPLESFILMO_READ: 3124, 
	MENU3_PEOPLESFILMO_WRITE: 3125, 
	MENU3_PEOPLESPICTURES_READ: 3126, 
	MENU3_PEOPLESPICTURES_WRITE: 3127, 
	MENU3_PRODUCTS_READ: 3140, 
	MENU3_PRODUCTS_WRITE: 3141, 
	MENU3_PRODUCTSEDITO_READ: 3142, 
	MENU3_PRODUCTSEDITO_WRITE: 3143, 
	MENU3_PRODUCTSPICTURES_READ: 3144, 
	MENU3_PRODUCTSPICTURES_WRITE: 3145, 
	MENU3_PRODUCTSCONTENTS_READ: 3146, 
	MENU3_PRODUCTSCONTENTS_WRITE: 3147, 
	MENU3_ALBUMS_READ: 3160, 
	MENU3_SUPERVISION: 3180, 
	MENU3_CUSTOMERS_COMMENTS_READ: 3200,
	MENU3_CUSTOMERS_COMMENTS_WRITE: 3201,
	MENU3_MOVIES_LINKS_READ: 3220, 
	MENU3_MOVIES_LINKS_WRITE: 3221, 
	MENU3_MOVIES_CHAPTERS_READ: 3222, 
	MENU3_MOVIES_CHAPTERS_WRITE: 3223, 
	MENU3_MOVIES_KEYWORDS_READ: 3224, 
	MENU3_MOVIES_KEYWORDS_WRITE: 3225, 
	MENU3_MOVIES_QUOTES_READ: 3226, 
	MENU3_MOVIES_QUOTES_WRITE: 3227, 
	MENU3_MOVIES_PRESSREVIEWS_READ: 3228, 
	MENU3_MOVIES_PRESSREVIEWS_WRITE: 3229, 
	MENU3_MOVIES_AWARDS_READ: 3230, 
	MENU3_MOVIES_AWARDS_WRITE: 3231, 
	MENU3_MOVIES_CATALOG_START_READ: 3240, 
	MENU3_MOVIES_CATALOG_START_WRITE: 3241, 
	MENU3_MOVIES_ALLOCINE_WIZARD_READ: 3250, 
	MENU3_MOVIES_ALLOCINE_WIZARD_WRITE: 3251, 
	MENU4_ACCESS: 4000, 
	MENU4_COMPANIES_READ: 4100, 
	MENU4_COMPANIES_WRITE: 4101, 
	MENU4_COMPANIESCONTACTS_READ: 4102, 
	MENU4_COMPANIESCONTACTS_WRITE: 4103, 
	MENU4_COMPANIESSERVICES_READ: 4104, 
	MENU4_COMPANIESSERVICES_WRITE: 4105, 
	MENU4_SERVICESALBUMS_READ: 4106, MENU4_SERVICESALBUMS_WRITE: 4107, 
	MENU4_SERVICESFAQS_READ: 4108, MENU4_SERVICESFAQS_WRITE: 4109, 
	MENU4_SERVICESFORMULAS_READ: 4110, MENU4_SERVICESFORMULAS_WRITE: 4111, 
	MENU4_SERVICESFTP_READ: 4112, MENU4_SERVICESFTP_WRITE: 4113, 
	MENU4_SERVICESIPPOOLS_READ: 4114, MENU4_SERVICESIPPOOLS_WRITE: 4115, 
	MENU4_SERVICESMARKETINGS_READ: 4116, MENU4_SERVICESMARKETINGS_WRITE: 4117, 
	MENU4_SERVICESMESSAGES_READ: 4118, MENU4_SERVICESMESSAGES_WRITE: 4119, 
	MENU4_SERVICESADVERTS_READ: 4120, MENU4_SERVICESADVERTS_WRITE: 4121, 
	MENU4_SERVICESCAMPAIGNS_READ: 4122, MENU4_SERVICESCAMPAIGNS_WRITE: 4123, 
	MENU4_SERVICESMAILINGS_READ: 4124, MENU4_SERVICESMAILINGS_WRITE: 4125, 
	MENU4_SERVICESQUIZZ_READ: 4126, MENU4_SERVICESQUIZZ_WRITE: 4127, 
	MENU4_SERVICESOFFERS_READ: 4128, MENU4_SERVICESOFFERS_WRITE: 4129, 
	MENU4_RULES_READ: 4130, MENU4_RULES_WRITE: 4131, 
	MENU4_SYNTHESES_READ: 4140, MENU4_SYNTHESES_WRITE: 4141,
	MENU4_ACQUISITION_READ: 4160, MENU4_ACQUISITION_WRITE: 4161, 
	MENU4_OWNERS_READ: 4162, MENU4_OWNERS_WRITE: 4163, 
	MENU4_CRIGHTS_READ: 4164, MENU4_CRIGHTS_WRITE: 4165, 
	MENU4_CRRESTRICTIONS_READ: 4166, MENU4_CRRESTRICTIONS_WRITE: 4167, 
	MENU4_CDETAILS_READ: 4168, MENU4_CDETAILS_WRITE: 4169, 
	MENU4_TRANSFERS_READ: 4180,	MENU4_TRANSFERS_WRITE: 4181, 
	MENU4_TRIGHTS_READ: 4182, MENU4_TRIGHTS_WRITE: 4183, 
	MENU4_TRHISTORY_READ: 4184, MENU4_TRHISTORY_WRITE: 4185, 
	MENU4_TRIGHTSDISCOUNT_READ: 4186, MENU4_TRIGHTSDISCOUNT_WRITE: 4187, 
	MENU4_TRIGHT_ACTIVATE: 4188, MENU5_ACCESS: 5000, MENU5_TOP_MOVIES_READ: 5100, 
	MENU5_TRANSACTIONS_READ: 5120, MENU5_SYNTHESIS_READ: 5140, MENU5_SERVICE_READ: 5160, MENU5_PARTNER_READ: 5180, 
	MENU5_MARKETING_READ: 5200, MENU5_TRENDS_READ: 5220, MENU5_ALERTS_READ: 5240, MENU5_CAMPAIGNS_READ: 5260, 
	MENU5_CUSTOMERS_READ: 5280, MENU5_HOMEPAGE_SLOTS_READ: 5300, MENU5_WORKFLOW_READ: 5320, MENU6_ACCESS: 6000, 
	MENU6_MAIN_READ: 6100, MENU6_MAIN_WRITE: 6101, MENU6_STATS_READ: 6120, MENU6_SUPERVISION_READ: 6140, 
	MENU6_ADMIN_READ: 6160, MENU6_ADMIN_WRITE: 6161, MENU6_ADDCUSTOMER_WRITE: 6181, MENU6_ATTACHCUSTOMERS2EVENTS_WRITE: 6201, 
	MENU6_GEOLOCALIZATION_WRITE: 6221, MENU6_TOOL_READ: 6240, MENU6_TOOL_WRITE: 6241, MENU7_ACCESS: 7000, MENU7_SIPS_WRITE: 7101,
	CATALOG_BASE_LIST: 7110, INGESTION_STATUS: 7120, MENU7_BOX_OFFICE_WRITE: 7121, 
	JOBS_SUPERVISION: 7130, MENU7_FREE_WRITE: 7141, MENU7_MALIGNE_TV_WRITE: 7161, 
	MENU7_TOP10_WRITE: 7181, MENU7_REPORTING_EMAILS_WRITE: 7201, 
	MENU7_YPSO_WRITE: 7221, MENU7_CNC_WRITE: 7401, MENU7_CONTENTS_VALIDATOR_WRITE: 7421, 
	MENU7_HOME_CANALPLAY_WRITE: 7441, MENU7_RESET_CACHE: 7461, MENU7_SEEALSO: 7481, 
	MENU7_MIGRATION_FREE_WRITE: 7501, MENU_PROVISIONNING_BYTEL_PCCAD: 7502, MENU7_WIZARD_PROGRAM: 7601, 
	MENU7_PORTAL_CDN: 7701, MENU7_IMPORT_PICTURES_READ: 7750, MENU7_IMPORT_PICTURES_WRITE: 7751, 
	MENU7_IMPORT_FILES_READ: 7760, MENU7_IMPORT_FILES_WRITE: 7761, MENU9: 9000, MENU9_MOVIESWORKFLOW_READ: 9100, 
	MENU9_STORAGE: 9200, STORED_PROCS_READ: 10000, STORED_PROCS_WRITE: 12000, FUNCTIONAL_PROC_SUPPORT: 14001, 
	FUNCTIONAL_PROC_SUPPORT_VIP: 14002, FUNCTIONAL_PROC_SUPPORT_ADMIN: 14003, HEADER_EXPORT: 15000, 
	MOVIES_TOP_PRIO_READ: 7190, MOVIES_TOP_PRIO_WRITE: 7191, 


	
	HAWC_MENU7_CONFIGURATION_MANAGER_READ: 712, 
	HAWC_MENU7_PRICING: 710, 
	HAWC_MENU7_MASS_IMAGE_IMPORT: 711,
	
	
	HAWC_MENU1_ALBUMS_READ:110,
	HAWC_MENU1_ALBUMS_WRITE:111,
	HAWC_MENU1_DOMAINS_READ:120,
	HAWC_MENU1_DOMAINS_WRITE:121,
	HAWC_MENU2_MOVIES_READ:210,
	HAWC_MENU2_MOVIES_WRITE:211,
	HAWC_MENU2_PRODUCTS_READ:220,
	HAWC_MENU2_PRODUCTS_WRITE:221,
	HAWC_MENU2_PEOPLE_READ:230,
	HAWC_MENU2_PEOPLE_WRITE:231,
	HAWC_MENU3_TRANSFERS_WRITE:310,
	HAWC_MENU3_TRANSFERS_READ:311,
	HAWC_MENU4_INGESTION_READ:410,
	HAWC_MENU4_INGESTION_WRITE:411,
	HAWC_MENU4_JOBS_SUPERVISION_READ:420,
	HAWC_MENU4_JOBS_SUPERVISION_WRITE:421,
	HAWC_MENU4_TOP_PRIO_READ:430,
	HAWC_MENU4_TOP_PRIO_WRITE:431,
	HAWC_MENU4_CATALOG_BASE_READ:440,
	HAWC_MENU4_CATALOG_BASE_WRITE:441,
	HAWC_MENU4_PRICING_READ:450,
	HAWC_MENU4_PRICING_WRITE:451,
	HAWC_MENU4_MASS_IMAGE_IMPORT_READ:460,
	HAWC_MENU4_MASS_IMAGE_IMPORT_WRITE:461,
	HAWC_MENU4_CONFIGURATION_MANAGER_READ:470,
	HAWC_MENU4_CONFIGURATION_MANAGER_WRITE:471
}