import React from 'react'
import { SimpleShowLayout, SimpleForm, Show, Edit, Create, List, SelectInput, TextInput, Datagrid, TextField, Filter } from 'react-admin'
import { ConcatFieldMovies, ProductField } from '../common/fields'
import { MyListActions, MyShowActions, MyEditActions, MyCreateActions } from '../common/actions'
import { CardShow, CardEdit, CardCreate } from '.'

const mode = [
	{ id: 1, name: 'Nom du produit' },
	{ id: 2, name: 'Identifiant du produit' },
	{ id: 4, name: 'Identifiant du programme' },
	{ id: 8, name: 'Titre du programme' }
]

const CompaniesproductsFilter = (props) => (
	<Filter {...props}>
		<SelectInput label="fields.criteria" source="mode" choices={mode} alwaysOn allowEmpty={false} />
		<TextInput label="fields.value" source="q" alwaysOn allowEmpty={false} resettable clearAlwaysVisible />
	</Filter>
)

const CompaniesproductsList = (props) => (
	<List
		title="titles.companiesProductsList"
		filters={<CompaniesproductsFilter />}
		perPage={20}
		actions={<MyListActions />}
		filterDefaultValues={{ mode: 4}}
		bulkActionButtons={false}
		{...props}
	>
		<Datagrid rowClick="show">
			<TextField source="id" label="fields.productid" sortable={false} />
			<ConcatFieldMovies label="fields.name" source='name;value' sortable={false} />
		</Datagrid>
	</List>
)

const Title = (props) => <ProductField addId={true} addLabel={true} sourceId='id' sourceLabel='name' size={32} {...props} />

const CompaniesproductsShow = (props) => (
	<Show title={<Title />} actions={<MyShowActions />} {...props}>
		<SimpleShowLayout>
			<CardShow />
		</SimpleShowLayout>
	</Show>
)

const CompaniesproductsEdit = (props) => (
	<Edit title="titles.companiesProductsCard" undoable={false} actions={<MyEditActions />} {...props}>
		<SimpleForm>
			<CardEdit />
		</SimpleForm>
	</Edit>
)

const CompaniesproductsCreate = (props) => (
	<Create title="titles.companiesProductsCard" undoable={false} actions={<MyCreateActions />} {...props}>
		<SimpleForm>
			<CardCreate />
		</SimpleForm>
	</Create>
)

export { CompaniesproductsList, CompaniesproductsShow, CompaniesproductsEdit, CompaniesproductsCreate }