import CacheItems from './CacheItems'
import { Contacts as request } from '../requests'

class Contacts extends CacheItems {
	constructor(cacheKey = 'dataContacts') {
		super(cacheKey, request.getAll, true)
	}

	static get instance() {
		if (!this.singleton) {
			this.singleton = new Contacts()
			this.singleton.logger.debug(`${this.__proto__.name} > new instance`)
		}

		return this.singleton
	}

	// --------------------
	// Custom methods below
	// --------------------

	// Get one contact
	getItem = (contactid) => {
		if (contactid) {
			const data = this.getAllItems()
			if (null != data && data.length > 0) {
				return Object.values(data).find(item => item.id === contactid)
			}
		}
		return null
	}

	// Get label
	getLabel = (contactid) => {
		if (contactid === -1) return 'Tous (-1)'
		const item = this.getItem(contactid)
		const label = null != item ? item.name : ''
		return label.trim()
	}

	// Get formatted label 
	getFormattedLabel = (contactid) => {
		if (contactid && contactid !== ' ') {
			const label = this.getLabel(contactid)
			return `${label} (${contactid})`.trim()
		}
		return ''
	}

	// Get formatted list to use in selects
	getSelectList = (withAll = false) => {
		const list = this.getAllItems()
		return this.getGenericItemsList(list, 'id', 'name', withAll)
	}

	// Get contact by groupid
	getContactsByGroupid = (groupId) => {
		const list = this.getAllItems()
		if(list !== null){
			//console.log(list.filter(item => item.groupid === 160))
			return list.filter(item => item.groupid === groupId)
		}
		return []
	}
}

const ContactsInstance = Contacts.instance

export default ContactsInstance
