import React from 'react'
import { Typography } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'

const styles =   ({
	root: {
		display: 'flex',
		flexWrap: 'nowrap',
		alignItems: 'center'
	}
})

const CardTitleField = props => {
	// eslint-disable-next-line react/prop-types
	const { icon, children, classes, style } = props
	return (
		<Typography variant="title">
			<div className={classes.root} style={style}>
				{icon}
				<div style={{ marginLeft: '5px' }}>
					{children}
				</div>
			</div>
		</Typography>
	)
}

export default withStyles(styles)(CardTitleField)
