import React from 'react'
import PropTypes from 'prop-types'
import Chip from '@material-ui/core/Chip'
import { getId, getValue } from '../../../tools/utils'
import { DomainsItems } from '../../../tools'
import { withStyles } from '@material-ui/core/styles'

const styles = theme =>   ({
	root: {
		display: 'flex',
		justifyContent: 'center',
		flexWrap: 'wrap',
	},
	chip: {
		margin: theme.spacing(1),
	},
})

const DomainItemField = props => {
	const { domainId, source, withId, withLabel, style, classes } = props

	if (!withId && !withLabel)
		return null

	let arrSource = source.split(';')

	const id = getId(props, arrSource[0])
	if (!id)
		return null
		
	let label
	if (withLabel) {
		if (arrSource.length > 1) {
			label = getValue(props, arrSource[1])
		}

		if (!label && domainId) {
			const di = DomainsItems.getItem(domainId, id)
			if (di)
				label = di.label
		}
	}

	let chip
	if (withId && !withLabel) {
		chip = id
	}
	else if (!withId && withLabel) {
		chip = label
	}
	else {
		chip = `${label} (${id})`
	}

	return (
		<div className={classes.root} style={style}>
			<Chip
				label={chip}
				className={classes.chip}
			/*
			clickable
			component={Link}
			to={{
				pathname: '/Domainsitems/show',
				state: { record: { domainid: domainId, itemid: id } }
			}}
			*/
			/>
		</div>
	)
}

DomainItemField.propTypes = {
	domainId: PropTypes.number,
	source: PropTypes.string,
	style: PropTypes.object,
	withId: PropTypes.bool,
	withLabel: PropTypes.bool,
	classes: PropTypes.object.isRequired,
}

DomainItemField.defaultProps = {
	record: {},
	domainId: -1,
	source: 'itemid',
	withId: true,
	withLabel: false,
}

export default withStyles(styles)(DomainItemField)
