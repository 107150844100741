import React from 'react'
import PropTypes from 'prop-types'
import get from 'lodash/get'
import pure from 'recompose/pure'
import sanitizeRestProps from './sanitizeRestProps'
import Typography from '@material-ui/core/Typography'
import { withStyles } from '@material-ui/core/styles'

const styles = theme => ({
	root: {
		borderRadius: 4,
		color: theme.palette.common.black,
		backgroundColor: theme.palette.common.white,
		border: '1px solid #ced4da',
		padding: '6px 6px',
		minWidth: '150px',
		minHeight: '20px',
	},
})

const Languages = [
	'Inoffensive slang, no profanity',
	'Mild expletives or mild terms for body functions',
	'Moderate expletives; non-sexual anatomical references',
	'Strong, vulgar language; obscene gestures. Use of epithets',
	'Extreme hate speech or crude language. Explicit sexual references'
]
const Nudities = [
	'No nudity',
	'Revealing attire',
	'Partial nudity',
	'Frontal nudity',
	'Provocative frontal nudity'
]

const Sexes = [
	'No sexual activity portrayed. Romance',
	'Passionate kissing',
	'Clothed sexual touching',
	'Non-explicit sexual touching',
	'Explicit sexual activity'
]

const Violences = [
	'No aggressive violence; no natural or accidental violence',
	'Fighting, creatures injured or killed; damage to realistic objects',
	'Humans or non-threatening creatures injured or killed',
	'Killing with blood and gore, humans injured or killed',
	'Wanton and gratuitous violence'
]

const RSACField = ({ className, classes, rsacType, source, record = {}, ...rest }) => {
	const rsac = get(record, source)

	// (rsacLang * 0x1000000) | (rsacNak * 0x10000) | (rsacSex * 0x100) | rsacVio
	const langue = (rsac & 0xff000000) / 0x1000000
	const nudite = (rsac & 0xff0000) / 0x10000
	const sexe = (rsac & 0xff00) / 0x100
	const violence = rsac & 0xff

	let rsacValue = ''

	switch (rsacType) {
	case 'langue':
		rsacValue = Languages[langue]
		break
	case 'nudite':
		rsacValue = Nudities[nudite]
		break
	case 'sexe':
		rsacValue = Sexes[sexe]
		break
	case 'violence':
		rsacValue = Violences[violence]
		break
	default:
		rsacValue = null
		break
	}

	return (
		<Typography
			component="span"
			body1="body1"
			className={className}
			classes={classes}
			{...sanitizeRestProps(rest)}
		>
			{rsacValue}
		</Typography>
	)
}

RSACField.propTypes = {
	addLabel: PropTypes.bool,
	basePath: PropTypes.string,
	className: PropTypes.string,
	cellClassName: PropTypes.string,
	headerClassName: PropTypes.string,
	label: PropTypes.string,
	record: PropTypes.object,
	sortBy: PropTypes.string,
	source: PropTypes.string.isRequired,
	classes: PropTypes.object.isRequired,
	rsacType: PropTypes.string
}

const PureRSACField = pure(withStyles(styles)(RSACField))

PureRSACField.defaultProps = {
	addLabel: true,
}

export default PureRSACField
