import React from 'react'
import PropTypes from 'prop-types'
import TextField from '@material-ui/core/TextField'
import { addField, FieldTitle } from 'ra-core'
import { getNameProduct } from '../../../tools/utils'
import sanitizeRestProps from './sanitizeRestProps'
import { Contacts, DateFormatter } from '../../../tools'

const ExtendedInput = ({
	classes,
	className,
	input: { value },
	label,
	resource,
	source,
	options,
	contact = false,
	date = false,
	nameproduct,
	disabled = false,
	...rest
}) => {

	let textinput = value
	if (contact)
		textinput = Contacts.getFormattedLabel(value)
	if (date)
		textinput = DateFormatter.getDateTimeLocale(value)
	if (nameproduct === 0)
		textinput = getNameProduct(value, 0)
	if (nameproduct === 1)
		textinput = getNameProduct(value, 1)

	return (
		<TextField
			disabled={disabled}
			margin="normal"
			value={textinput}
			label={<FieldTitle label={label} source={source} resource={resource} />}
			className={className}
			classes={classes}
			{...options}
			{...sanitizeRestProps(rest)}
		/>
	)
}

ExtendedInput.propTypes = {
	record: PropTypes.object,
	classes: PropTypes.object.isRequired,
	className: PropTypes.string,
	label: PropTypes.string,
	input: PropTypes.object,
	options: PropTypes.object,
	resource: PropTypes.string,
	source: PropTypes.string,
	disabled: PropTypes.bool,
}

export default addField(ExtendedInput)
