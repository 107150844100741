import React from 'react'
import { BooleanField, Datagrid, DateField, DateInput, Filter, List, NullableBooleanInput } from 'react-admin'
import Responsive from 'ra-ui-materialui/lib/layout/Responsive'
import { MovieField, IntegerField, NumberFieldCurrency, ProductField, DomainItemField } from '../../common/fields'
import { PriceInput, DomainsItemsArrayInput, ServicesArrayInput, TransfersArrayInput, IntegerInput } from '../../common/inputs'
import { DomainsType } from '../../../constants/types'
import {withStyles} from '@material-ui/core/styles'

const CatalogFilter = props => (
	<Filter {...props}>
		<ServicesArrayInput source="serviceid" label="fields.serviceid" margin="dense" options={{ style: { minWidth: '100px' } }} />
		<TransfersArrayInput source="transferid" label="fields.transferid" margin="dense" options={{ style: { minWidth: '110px' } }} alwaysOn />
		<IntegerInput source="movieid" label="fields.movieid" margin="dense" alwaysOn resettable clearAlwaysVisible />
		<IntegerInput source="productid" label="fields.productid" margin="dense" alwaysOn resettable clearAlwaysVisible />
		<IntegerInput source="albumid" label="fields.albumid" margin="dense" resettable clearAlwaysVisible />
		<DateInput source="release" label="fields.release" margin="dense" resettable clearAlwaysVisible />
		<DateInput source="until" label="fields.until" margin="dense" resettable clearAlwaysVisible />
		<PriceInput source="price" label="fields.price" margin="dense" resettable clearAlwaysVisible />
		<PriceInput source="discount" label="fields.discount" margin="dense" resettable clearAlwaysVisible />
		<DomainsItemsArrayInput source="mediaid" label="fields.mediaid" margin="dense" domainid={DomainsType.MEDIA} options={{ style: { minWidth: '100px' } }} />
		<DomainsItemsArrayInput source="submediaid" label="fields.submediaid" margin="dense" domainid={DomainsType.SUBMEDIA} options={{ style: { minWidth: '150px' } }} />
		<DomainsItemsArrayInput source="modecommid" label="fields.modecommid" margin="dense" domainid={DomainsType.CONTRACTCOMMERCIALIZATIONMODE} options={{ style: { minWidth: '240px' } }} />
		<NullableBooleanInput source="restricted" label="fields.restricted" margin="dense" resettable clearAlwaysVisible />
		<NullableBooleanInput source="ispack" label="fields.ispack" margin="dense" resettable clearAlwaysVisible />
	</Filter>
)

const styles = {}

export const CatalogList = withStyles(styles)(({ ...props }) => (
	<Responsive
		medium={
			<List
				{...props}
				filters={<CatalogFilter />}
				sort={{ field: 'release', order: 'DESC' }}
				perPage={20}
				bulkActionButtons={false}
				filterDefaultValues={null}
				title="titles.catalogPrograms"
			>
				<Datagrid>
					<MovieField addId={true} addLabel={true} addAvatar={true} sourceId="movie.id" sourceLabel="movie.title" label="labels.movies" size={40} isLink={true} />
					<IntegerField source="serviceid" label="fields.serviceid" />
					<IntegerField source="transferid" label="fields.transferid" />
					<ProductField addId={true} addLabel={true} sourceId="product.id" sourceLabel="product.name" label="labels.products" isLink={true} />
					<NumberFieldCurrency source="price" label="fields.price" sign='-' reference={0.1} />
					<NumberFieldCurrency source="discount" label="fields.discount" sign='+' reference={0} />
					<DateField source="release" label="fields.release" />
					<DateField source="until" type="date" label="fields.until" />
					<DomainItemField source="mediaid" label="labels.media" domainId={DomainsType.MEDIA} withId={false} withLabel={true} />
					<DomainItemField source="submediaid" label="labels.submedia" domainId={DomainsType.SUBMEDIA} withId={false} withLabel={true} />
					<BooleanField source="restricted" label="fields.restricted" />
					<BooleanField source="ispack" label="fields.ispack" />
					<DomainItemField source="modecommid" label="labels.modecomm" domainId={DomainsType.CONTRACTCOMMERCIALIZATIONMODE} withId={false} withLabel={true} />
				</Datagrid>
			</List>
		}
		small={
			<List
				{...props}
				filters={<CatalogFilter />}
				sort={{ field: 'release', order: 'DESC' }}
				perPage={10}
				bulkActionButtons={false}
				filterDefaultValues={null}
				title="titles.catalogPrograms"
			>
				<Datagrid>
					<MovieField addId={false} addLabel={true} addAvatar={false} sourceId="movie.id" sourceLabel="movie.title" label="labels.movies" isLink={false} />
					<IntegerField source="serviceid" label="fields.serviceid" />
					<IntegerField source="transferid" label="fields.transferid" />
					<ProductField addId={false} addLabel={true} sourceId="product.id" sourceLabel="product.name" label="labels.products" isLink={false} />
				</Datagrid>
			</List>
		}
	/>
))
