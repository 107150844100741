import React, { useState, useEffect } from 'react';
import compose from 'recompose/compose'
import { translate } from 'react-admin'

import { withStyles } from '@material-ui/core/styles'
import { CircularProgress, Typography, Accordion, AccordionSummary, AccordionDetails, FormControl, FormControlLabel, RadioGroup, Radio, Button } from '@material-ui/core';
import { Cached, ExpandMore, Search } from '@material-ui/icons/';

import FilterField from '../common/fields/FilterField'
import { CustomTextField, CustomSelect, CustomDisplayDialog, CustomAlert } from '../layout/GlobalStyles';
import { Url, DateFormatter } from '../../tools'

const styles = (theme) => ({
	root: {
		display: 'flex',
		flexWrap: 'wrap',
		'& > *': {
			margin: theme.spacing(1),
			width: '100%',
			height: theme.spacing(10),
		},
	  },
	formControl: {
		margin: theme.spacing(2),
		width: '15%'
	},
	datesContainer:{
		marginLeft: '1em',
		width: "30%",
		height:'100%',
		marginTop: '1em',
	},
	select:{
		// width:"20%",
		minWidth:'110px',

	},
	customText:{
		width:'158px',
	},
	searchButton:{
		marginLeft:'5px',
		// color:'yellow'
	},
	imageContainer:{
		display:'flex',
		alignItems:'center',
		justifyContent:'center',
		height:'auto',
		width:'auto',
		marginLeft:'20px',
	},
	imageStyle:{
		// backgroundColor: 'black',
		height:'110px',
		width:'82.5px'
	}
});






const searchWidget = (props) => {
	const {classes} = props

	const mode = [
		{ id: 1, name: "Identifiant" },
		{ id: 2, name: "Titre" }
	]



	// Changement dans le champ "Critère"
	const handleChange = (event) => {
		props.setSelectedCriteria(event.target.value)
	}
	// Gestion après clique sur le bouton croix qui apparait après une saisie de valeur
	const handleReset = () => {
		props.setSelectedValue('')
	}
	// Changement dans le champ "Valeur"
	const handleValueChange = (event) => {
		props.setSelectedValue(event.target.value)
	}

	// Gestion après clique sur la loupe de recherche / sur la touche Entrée
	const handleSearchButtonClick = () =>{
		props.handleSearch(props.value)
		if(props.criteria=='2' || props.value==''){
			props.handleClickOpenSearchListField()
		}
	}

	const DisplayDates = () => (
		<>
		<div className={classes.datesContainer}>
			<div style={{display:'flex'}}>
				<CustomTextField 
					label="Date de début des contrats VOD" 
					value={props.vod.VODstart}
					fullWidth="true"
					style={{flexGrow:'1'}}
				/>
				<CustomTextField 
					label="Date de fin des contrats VOD" 
					value={props.vod.VODend}
					fullWidth="true"
					style={{flexGrow:'1'}}
				/>
			</div>
			<div style={{display:'flex'}}>
				<CustomTextField 
					label="Date de début des contrats EST" 
					value={props.est.ESTstart}
					fullWidth="true"
					style={{flexGrow:'1'}}
				/>
				<CustomTextField 
					label="Date de fin des contrats EST" 
					value={props.est.ESTend}
					fullWidth="true"
					style={{flexGrow:'1'}}
				/>
			</div>
		</div>

		<div className={classes.imageContainer}>
			{props.searchbarLoading==true?<CircularProgress />:
				props.renderImg?
				<img
					title={props.img.title}
					alt={props.img.title}
					src={Url.pictureById(props.img.id, props.type=="album"?'Albums':'Movies', props.img.filename)}
					onError={
						e => {
							e.target.onerror = null
							e.target.src = Url.defaultCover
						}
					}
					className={classes.imageStyle} 
				/>
				:
				null
			}
		</div>
		</>
	)


	const DisplayData = () => (
			<div style={{display:'flex'}}>
				<CustomTextField 
					label="Identifiant" 
					value={props.id}
					fullWidth="true"
					style={{width:'156px'}}
					onFocus={event => {
						event.target.select();
					}}
				/>
				<CustomTextField 
					label="Titre" 
					value={props.title}
					fullWidth="true"
					style={{flexGrow:'1'}}
					onFocus={event => {
						event.target.select();
					}}
				/>
			</div>
	)

	const DisplayRadio = () => (
		<div>
		<RadioGroup aria-label="radio" name="radio" value={props.radio} onChange={props.handleRadioChange} row style={{display:"inline-block", marginLeft:"2em"}}>
			<FormControlLabel value="movie" control={<Radio style={{padding:0, color:"#288DB1"}} />} label="Programme" />
			<FormControlLabel value="album" control={<Radio style={{padding:0, color:"#288DB1"}} />} label="Album" />
		</RadioGroup>
		</div>
	)

	return(
			<FilterField 
				textValue={props.value}
				selectedValue={props.criteria} values={mode} classes={classes} removeicon={true} 
				paperStyle={{height:'auto', marginBottom:'15px', paddingLeft: '16px', paddingRight: '16px', paddingBottom:'8px'}}
				textfieldStyle={{flexGrow:1}}
				valueChange={(event) => handleValueChange(event)} 
				valueReset={() => handleReset()} selectedChange={event => handleChange(event)} removeicon={true}
				ButtonClick={() => handleSearchButtonClick()}
				// getKeyValue={event => this.getKeyValue(event)}
				displayRadio={<DisplayRadio />} displayData={<DisplayData />} switch={<DisplayDates />}
			/>
	)

}


const enhance = compose(
	withStyles(styles)
)

export default translate(enhance(searchWidget))