import React, { Fragment } from 'react'
import { Create, Edit, SimpleForm, Show } from 'react-admin'
import { NameField } from '../common/fields'
import { PromotionDetail } from '.'
import { PromotionsListFilter } from './modules'
import { createTheme, ThemeProvider } from '@material-ui/core/styles'

const getMuiTheme = () => createTheme({
	overrides: {
		MuiCardContent: {
			root: {
				'&:first-child': {
					paddingLeft: 0,
					paddingRight: 0,
				},
			}
		},
		MuiFormLabel:{
			root:{
				"&$focused": {
					"color": "#288DB1"
				},
			}
		},
		MuiOutlinedInput: {
			'root': {  // - The Input-root, inside the TextField-root
				'&:hover fieldset': {
					borderColor: '#288DB1 !important', // - Set the Input border when parent has :hover
				},
				'&.Mui-focused fieldset': { // - Set the Input border when parent is focused 
					borderColor: '#288DB1 !important',
				},
			},
		} 
	},
	display: {
		display: 'none'
	},
})

const PromotionsList = (props) => (
	<Fragment>
		<PromotionsListFilter {...props}/>
	</Fragment>
)
export const Title = (props) => <NameField addId={true} addLabel={true} sourceId='id' sourceLabel='label' {...props} />

const PromotionsShow = (props) => (
	<ThemeProvider theme={getMuiTheme()}>
		<Show title={<Title />} actions={null} {...props}>
			<SimpleForm toolbar={null} style={{paddingTop: 0, marginTop: '-8px'}}>
				<PromotionDetail create={false} type='show'/>
			</SimpleForm>
		</Show>
	</ThemeProvider>
)

const PromotionsEdit = (props) => ( 
	<ThemeProvider theme={getMuiTheme()}>
		<Edit undoable={true} actions={null} {...props}>
			<SimpleForm toolbar={null} style={{paddingTop: 0, marginTop: '-8px'}}>
				<PromotionDetail create={true} type='edit' />
			</SimpleForm>
		</Edit>
	</ThemeProvider>
)

const PromotionsCreate = (props) => (
	<ThemeProvider theme={getMuiTheme()}>
		<Create undoable={false} actions={null} {...props}>
			<SimpleForm toolbar={null} style={{paddingTop: 0, marginTop: '-8px'}}>
				<PromotionDetail create={true} type='create'/>
			</SimpleForm>
		</Create>
	</ThemeProvider>
)


export { PromotionsList, PromotionsShow, PromotionsEdit, PromotionsCreate }