/* eslint-disable no-mixed-spaces-and-tabs */
import React from 'react'
import Dialog from '@material-ui/core/Dialog'
import PropTypes from 'prop-types'
import cyan from '@material-ui/core/colors/cyan'
import { translate } from 'react-admin'
import { connect } from 'react-redux'
import compose from 'recompose/compose'
import { Publicationstracking } from '../../../requests'
import { Contacts as ContactsTools } from '../../../tools'
import Paper from '@material-ui/core/Paper'
import { onChangeUser } from '../../../actions/publicationTracking'
import { CloseIcon } from '../../../constants/icons'
import Button from '@material-ui/core/Button'
import MenuItem from '@material-ui/core/MenuItem'
import FormControl from '@material-ui/core/FormControl'
import Select from '@material-ui/core/Select'
import { Labeled } from 'react-admin'
import { Logger } from '../../../tools'
import { withStyles, createTheme, ThemeProvider } from '@material-ui/core/styles'
const theme = createTheme({
	overrides: {
		MuiSelect: {
			selectMenu: {
				width: '200px',
				overflow: 'hidden',
				minHeight: '1.1875em',
				whiteSpace: 'nowrap',
				textOverflow: 'ellipsis'
			}
		}
	},
})

const styles = theme => ({
	button: {
		margin: theme.spacing(1),
		width: '150px'
	},
	dialogGlobal: {
		background: theme.palette.background.default,
		maxHeight: '60%',
	},
	dialogHeader: {
		padding: '10px 10px 10px',
		background: cyan[500],
		color: 'white',
		fontSize: 'medium',
		fontWeight: 'bold',
		textAlign: 'center',
	},
	closeIcon: {
		position: 'absolute',
		width: '10px',
		top: '0px',
		right: '0px',
		borderRadius: '0',
		color: 'white',
		'&:hover': {
			background: 'red'
		}
	},
	dialogContent: {
		padding: '16px 24px 17px',
		flex: '1 1 auto',
		color: 'white',
		fontSize: '1rem',
		fontWeight: '400',
	},
	formControl: {
		margin: '4px 28px',
		minWidth: 150,
	},

	customSelect: {
		width: '250px',
		padding: {
			left: '20px',
		},
	},
	paper: {
		width: '100%',
		marginBottom: theme.spacing(2),
	},
	paperWidthSm: {
		maxWidth: '700px',
	},
	isupdateclasse: {
		padding: '15px 5px 25px 200px'
	},
	labels: {
		marginTop: '0px',
		marginBottom: '0px'
	},
	rightIcon: {
		marginLeft: theme.spacing(1),
	},
})

class WeekResponsibleModal extends React.Component {
	constructor(props) {
		super(props)
		this.state = {
			open: false,
		}
	}

	componentDidMount() {
		const { modalOpen } = this.props
		this.setState({
			open: modalOpen
		})
	}

	componentDidUpdate(previousProps, previousState) {
		const { modalOpen } = this.props
		if (previousProps.modalOpen !== this.props.modalOpen) {

			this.setState({
				open: modalOpen,
			})
			//onChangeUser(null)
			previousState.isupdate = true
			this.setState({
				isupdate: true
			})
		}
	}

	handleRequestSort = (event, property) => {
		const isAsc = this.state.orderBy === property && this.state.order === 'asc'
		this.setState({
			order: isAsc ? 'desc' : 'asc',
			orderBy: property
		})
	};


	handleClick = (event, name) => {
		const selectedIndex = this.state.selected.indexOf(name)
		let newSelected = []
		if (selectedIndex === -1) {
			newSelected = newSelected.concat(this.state.selected, name)
		} else if (selectedIndex === 0) {
			newSelected = newSelected.concat(this.state.selected.slice(1))
		} else if (selectedIndex === this.state.selected.length - 1) {
			newSelected = newSelected.concat(this.state.selected.slice(0, -1))
		} else if (selectedIndex > 0) {
			newSelected = newSelected.concat(
				this.state.selected.slice(0, selectedIndex),
				this.state.selected.slice(selectedIndex + 1),
			)
		}
		this.setState({
			selected: newSelected
		})
	};

	saveRoles = (itemToBeUpdated) => {

		return new Promise((resolve, reject) => {
			Publicationstracking.saveWeekResponsible(itemToBeUpdated).then(
				() => {
					resolve()
				}
			).catch((error) => {
				Logger.debug(error)
				reject()
			})
		})
	}
	handleChange = (event, type) => {

		const { onChangeUser, roles, weekFilter } = this.props
		const users = ContactsTools.getContactsByGroupid(160)
		if (roles.length === 0 ) {
			let newrole = {
				week: weekFilter,
			}
			switch (type) {
				case 'newProgram':
					const newProgram = users.find(u => u.id === event.target.value)
					newrole.newProgramContactId = newProgram.id
					onChangeUser(newProgram)
					break
				case 'catalog':
					const catalog = users.find(u => u.id === event.target.value)
					newrole.catalogContactId = catalog.id
					onChangeUser(catalog)
					break
				case 'serie1':
					const serie1 = users.find(u => u.id === event.target.value)
					newrole.seriesOneContactId = serie1.id
					onChangeUser(serie1)
					break
				case 'serie2':
					const serie2 = users.find(u => u.id === event.target.value)
					newrole.seriesTwoContactId = serie2.id
					onChangeUser(serie2)
					break
				case 'reference':
					const reference = users.find(u => u.id === event.target.value)
					newrole.referenceContentContactId = reference.id
					onChangeUser(reference)
					break
				case 'mail':
					const mail = users.find(u => u.id === event.target.value)
					newrole.mailContactId = mail.id
					onChangeUser(mail)
					break
				case 'emergency':
					const emergency = users.find(u => u.id === event.target.value)
					newrole.emergencyContactId = emergency.id
					onChangeUser(emergency)
					break;
				default:
					break;
			}
			this.saveRoles(newrole)
		}
		else {
			switch (type) {
				case 'newProgram':
					const newProgram = users.find(u => u.id === event.target.value)
					roles.newProgramContactId = newProgram.id
					onChangeUser(newProgram)
					break
				case 'catalog':
					const catalog = users.find(u => u.id === event.target.value)
					roles.catalogContactId = catalog.id
					onChangeUser(catalog)
					break
				case 'serie1':
					const serie1 = users.find(u => u.id === event.target.value)
					roles.seriesOneContactId = serie1.id
					onChangeUser(serie1)
					break
				case 'serie2':
					const serie2 = users.find(u => u.id === event.target.value)
					roles.seriesTwoContactId = serie2.id
					onChangeUser(serie2)
					break
				case 'reference':
					const reference = users.find(u => u.id === event.target.value)
					roles.referenceContentContactId = reference.id
					onChangeUser(reference)
					break
				case 'mail':
					const mail = users.find(u => u.id === event.target.value)
					roles.mailContactId = mail.id
					onChangeUser(mail)
					break
				case 'emergency':
					const emergency = users.find(u => u.id === event.target.value)
					roles.emergencyContactId = emergency.id
					onChangeUser(emergency)
					break
				default:
					break;
			}
			this.saveRoles(roles)
		}

	};

	render() {

		const { classes, onClose, roles } = this.props
		const modalTitle = 'Affectation des responsables'
		const users = ContactsTools.getContactsByGroupid(160)
 		//const userroles = roles !== undefined ? roles : selectedUser 
		return (
			<div>
				<Dialog
					open={this.state.open}
					//TransitionComponent={Transition}
					keepMounted
					onClose={onClose}
					aria-labelledby="alert-dialog-slide-title"
					aria-describedby="alert-dialog-slide-description"
					classes={{
						paperWidthSm: classes.paperWidthSm
					}}
				>
					<div className={classes.dialogHeader}>
						{modalTitle.toUpperCase()}
						<Button onClick={onClose} className={classes.closeIcon}  >
							<CloseIcon />
						</Button>
					</div>
					<div className={classes.dialogGlobal}>
						<div className={classes.dialogContent}>
							<Paper className={classes.paper}>
								{/* <Grid container direction="row" >
									<Grid item xs={6}>
										<CustomSelect values={users} value={roles ? Number.parseInt(roles.newProgramContactId) : null} propsType="Responsible" label="Nouveautés/ doc/ éro" handleChange={event => this.handleChange(event, "newProgram")} />
										<CustomSelect values={users} value={roles ? Number.parseInt(roles.catalogContactId) : null} propsType="Responsible" label="Catalogue" handleChange={event => this.handleChange(event, "Catalog")} />
										<CustomSelect values={users} value={roles ? Number.parseInt(roles.seriesOneContactId) : null} propsType="Responsible" label="Série 1" handleChange={event => this.handleChange(event, "serie1")} />
										<CustomSelect values={users} value={roles ? Number.parseInt(roles.seriesTwoContactId) : null} propsType="Responsible" label="Série 2" handleChange={event => this.handleChange(event, "serie2")} />
									
									</Grid>
									<Grid item xs={6}>
										<CustomSelect values={users} value={roles ? Number.parseInt(roles.referenceContentContactId) : null} propsType="Responsible" label="Contenus référents" handleChange={event => this.handleChange(event, "reference")} />
										<CustomSelect values={users} value={roles ? Number.parseInt(roles.mailContactId) : null} propsType="Responsible" label="Mail suivi  animation" handleChange={event => this.handleChange(event, "mail")} />
										<CustomSelect values={users} value={roles ? Number.parseInt(roles.emergencyContactId) : null} propsType="Responsible" label="Suivi mail urgence" handleChange={event => this.handleChange(event, "emergency")} />

									</Grid>
								</Grid> */}
								 <FormControl className={classes.formControl}> 

									<Labeled label="Nouveautés/ doc/ éro" id="demo-simple-select-helper-label" className={classes.labels}></Labeled>
									<ThemeProvider theme={theme}>
								<Select
											labelId="demo-simple-select-helper-label"
											id="demo-simple-select-helper"
											value={roles ? Number.parseInt(roles.newProgramContactId) : 0}//{newProgramContactId.id}
											onChange={event => this.handleChange(event, "newProgram")}
											className={classes.customSelect}
										>
											{users.map((value) => {
												return <MenuItem  value={value.id}>{value.gsm}</MenuItem>
											})}
										</Select>
								</ThemeProvider>
								</FormControl>
								<FormControl className={classes.formControl}>

									<Labeled label="Contenus référents" id="demo-simple-select-helper-label" className={classes.labels}></Labeled>
									<ThemeProvider theme={theme}>
										<Select
											labelId="demo-simple-select-helper-label"
											id="demo-simple-select-helper"
											value={roles ? Number.parseInt(roles.referenceContentContactId) : 0}//{referenceContentContactId.id}
											onChange={event => this.handleChange(event, "reference")}
											className={classes.customSelect}
										>
											{users.map((value, index) => {
												return <MenuItem key={value.id} value={value.id}>{value.gsm}</MenuItem>
											})}
										</Select>
									</ThemeProvider>
								</FormControl>
								<FormControl className={classes.formControl}>

									<Labeled label="Catalogue" id="demo-simple-select-helper-label"  ></Labeled>
									<ThemeProvider theme={theme}>
										<Select
											labelId="demo-simple-select-helper-label"
											id="demo-simple-select-helper"
											value={roles ? Number.parseInt(roles.catalogContactId) : 0}//{catalogContactId.id}
											onChange={event => this.handleChange(event, "catalog")}
											className={classes.customSelect}
										>
											{users.map((value, index) => {
												return <MenuItem key={value.id} value={value.id}>{value.gsm}</MenuItem>
											})}
										</Select>
									</ThemeProvider>
								</FormControl>
								<FormControl className={classes.formControl}>
									<Labeled label="Mail suivi  animation" id="demo-simple-select-helper-label"  ></Labeled>
									<ThemeProvider theme={theme}>
										<Select
											labelId="demo-simple-select-helper-label"
											id="demo-simple-select-helper"
											value={roles ? Number.parseInt(roles.mailContactId) : 0}//{mailContactId.id}
											onChange={event => this.handleChange(event, "mail")}
											className={classes.customSelect}
										>
											{users.map((value, index) => {
												return <MenuItem key={value.id} value={value.id}>{value.gsm}</MenuItem>
											})}
										</Select>
									</ThemeProvider>
								</FormControl>
								<FormControl className={classes.formControl}>
									<Labeled label="Série 1" id="demo-simple-select-helper-label"  ></Labeled>
									<ThemeProvider theme={theme}>
										<Select
											labelId="demo-simple-select-helper-label"
											id="demo-simple-select-helper"
											value={roles ? Number.parseInt(roles.seriesOneContactId) : 0}//{seriesOneContactId.id}
											onChange={event => this.handleChange(event, "serie1")}
											className={classes.customSelect}
										>
											{users.map((value, index) => {
												return <MenuItem key={value.id} value={value.id}>{value.gsm}</MenuItem>
											})}
										</Select>
									</ThemeProvider>
								</FormControl>
								<FormControl className={classes.formControl}>
									<Labeled label="Suivi mail urgence" id="demo-simple-select-helper-label"  ></Labeled>
									<ThemeProvider theme={theme}>
										<Select
											labelId="demo-simple-select-helper-label"
											id="demo-simple-select-helper"
											value={roles ? Number.parseInt(roles.emergencyContactId) : 0}//{emergencyContactId.id}
											onChange={event => this.handleChange(event, "emergency")}
											className={classes.customSelect}
										>
											{users.map((value, index) => {
												return <MenuItem key={value.id} value={value.id}>{value.gsm}</MenuItem>
											})}
										</Select>
									</ThemeProvider>
								</FormControl>
								<FormControl className={classes.formControl}>
									<Labeled label="Série 2" id="demo-simple-select-helper-label"  ></Labeled>
									<ThemeProvider theme={theme}>
										<Select
											labelId="demo-simple-select-helper-label"
											id="demo-simple-select-helper"
											value={roles ? Number.parseInt(roles.seriesTwoContactId) : 0}//{seriesTwoContactId.id}
											onChange={event => this.handleChange(event, "serie2")}
											className={classes.customSelect}
										>
											{users.map((value, index) => {
												return <MenuItem key={value.id} value={value.id}>{value.gsm}</MenuItem>
											})}
										</Select>
									</ThemeProvider> 
								</FormControl> 


							</Paper>
						</div>
					</div>
				</Dialog>
			</div>
		)
	}
}

WeekResponsibleModal.propTypes = {
	modalOpen: PropTypes.bool,
	onClose: PropTypes.func,
	modalContent: PropTypes.object,
	launchActivationProcess: PropTypes.func,
	crudGetList: PropTypes.func,
	yearNumber: PropTypes.number,
	weekNumber: PropTypes.number
}

const mapStateToProps = state => ({
	// ...state,
	// weekNumber: state.reducer.publicationTracking.weekNumber !== null ? state.reducer.publicationTracking.weekNumber : 16,
	// yearNumber: state.reducer.publicationTracking.yearNumber !== null ? state.reducer.publicationTracking.yearNumber : 2020,
	// planningData: state.reducer.publicationTracking.planningData !== null ? state.reducer.publicationTracking.planningData : [],
	// selectedCategory: state.reducer.publicationTracking.selectedCategory !== null ? state.reducer.publicationTracking.selectedCategory : 0,
	 selectedUser: state.reducer.publicationTracking.selectedUser !== null ? state.reducer.publicationTracking.selectedUser : {
		id: 0,
		name: ''
	},
	// isupdate: true,
})

const mapDispatchToProps = ({
	onChangeUser,
})

const enhance = compose(
	connect(mapStateToProps, mapDispatchToProps),
	withStyles(styles)
)

export default translate(enhance(WeekResponsibleModal))
