import React, { Component } from 'react'
import { connect } from 'react-redux'
import compose from 'recompose/compose'
import { unlockResponsibleUpdate, initializeResponsibleIds, onLoadPlanning, completeUpdateResponsible } from '../../../actions/publicationTracking'
import CustomLockIconButton from './CustomLockIconButton'
import { Publicationstracking } from '../../../requests'
import { Contacts as ContactsTools, PublicationTrackingReferenceData } from '../../../tools'
import AssignProcessLoader from './preparation/AssignProcessLoader'
import {  translate } from 'react-admin'
import { Logger } from '../../../tools'
import ValidateUpdateResponsable from './ValidateUpdateResponsable'
import { withStyles  ,  } from '@material-ui/core/styles'

const styles = () =>  ({
	responsibleHeader : {
		//backgroundColor: '#FFF',
		padding: '1px',
		position: 'sticky',
		top: '0',
		zIndex: '1',
		color: '#0000008a',
		fontWeight: 'bold',
		fontSize: '0.825em',
		backgroundColor: '#FFF',
		// padding: '5px',
		// paddingLeft: '0px',
		 borderBottom : '1px solid rgba(224, 224, 224, 1)'
	}
})

class ResponsibleColumnHeader extends Component{
	constructor(props) {
		super(props)
		this.state = {
			isProcessLoaderOpen : false,
			isOperationFinished : false,
			isFinishedUpdate : 0
		}
	}

	handleLockResponsibleUpdate = () => {
		const {publicationResponsibles, isUpdateResponsibleLocked, unlockResponsibleUpdate } = this.props
		unlockResponsibleUpdate(isUpdateResponsibleLocked ? true : false)
	
		if(isUpdateResponsibleLocked  )
		{
			this.setState({isFinishedUpdate :  1  })
		}
		else if(!isUpdateResponsibleLocked && this.state.isFinishedUpdate === 1 && publicationResponsibles.length !== 0)
		{
			this.setState({isFinishedUpdate :  2  })
		}
		 else if ((isUpdateResponsibleLocked && this.state.isFinishedUpdate === 2 )||publicationResponsibles.length === 0)
		{
			this.setState({isFinishedUpdate :  0  })
		}
	
	}

	savePublication = (itemToBeUpdated) => {
		return new Promise((resolve, reject) => {
			Publicationstracking.savePublication(itemToBeUpdated).then(
				() => {
					resolve()
				}
			).catch((error)=>{
				Logger.debug(error)
				reject()
			})
		})
	}

	manageProcessLoader = (isOperationFinished, isProcessLoaderOpen, isFinishedUpdate) => {
		this.setState({
			isOperationFinished : isOperationFinished,
			isProcessLoaderOpen : isProcessLoaderOpen,
			isFinishedUpdate : isFinishedUpdate
		})
	}

	buildPlanningList = (jsonData, onLoadPlanning) => {
		const trackingData = jsonData
		let planningToBeSaved = {
			publicationsplannings : trackingData.publicationsplannings,
			publicationsstats : []
		}
		const users = ContactsTools.getContactsByGroupid(160)
		let distribution = {
			pgm: trackingData.publicationsplannings.filter(x => x.publications !== null && (x.taskTypeId === 14 || x.taskTypeId === 16 || x.taskTypeId === 17 || x.taskTypeId === 27 || x.taskTypeId === 28)),
			ouv: trackingData.publicationsplannings.filter(x => x.publications !== null && (x.taskTypeId === 21 || x.taskTypeId === 22)),
			susp: trackingData.publicationsplannings.filter(x => x.publications !== null && (x.taskTypeId === 11 || x.taskTypeId === 12 || x.taskTypeId === 13 || x.taskTypeId === 24 || x.taskTypeId === 25)),
			chgtprix: trackingData.publicationsplannings.filter(x => x.publications !== null && (x.taskTypeId && PublicationTrackingReferenceData.getTasksTypes().find(r => r.itemid === x.taskTypeId).taskGroupDomainItemId  === 7 )),
			autre: trackingData.publicationsplannings.filter(x => x.publications !== null && ((x.taskTypeId >= 1 && x.taskTypeId <= 3) || x.taskTypeId === null)),

		}

		if (trackingData.publicationsstats !== null && trackingData.publicationsstats.length !== 0) {
			trackingData.publicationsstats.forEach(item => {
				const currentUser = users.find(u => u.id === item.contactId)

				const stat = {
					contactName:  currentUser ? currentUser.gsm : '',
					publicationsCount: item.publicationsCount,
					pgm: distribution.pgm !== undefined ? distribution.pgm.filter(x => x.publications.contactId === item.contactId).length : 0,
					ouv: distribution.ouv !== undefined ? distribution.ouv.filter(x => x.publications.contactId === item.contactId).length : 0,
					susp: distribution.susp !== undefined ? distribution.susp.filter(x => x.publications.contactId === item.contactId).length : 0,
					chgtprix: distribution.chgtprix !== undefined ? distribution.chgtprix.filter(x => x.publications.contactId === item.contactId).length : 0,
					//regulprix: distribution.regulprix !== undefined ? distribution.regulprix.filter(x => x.publications.contactId === item.contactId).length : 0,
					autre: distribution.autre !== undefined ? distribution.autre.filter(x => x.publications.contactId === item.contactId).length : 0,
					publicationPercent: Number((item.publicationsCount * 100 / trackingData.publicationsplannings.length).toFixed(1))
				}
				planningToBeSaved.publicationsstats.push(stat)
			})
		}
		onLoadPlanning(planningToBeSaved)
	}

	initializePlanningData = () => {
		const { weekNumber, yearNumber, onLoadPlanning } = this.props
		Publicationstracking.getPlanning(Number.parseInt(`${yearNumber.toString()}${weekNumber.toString()}`)).then((json)=>{
			this.buildPlanningList(json.data, onLoadPlanning)
		})
	}

	handleValidateClick = () => {
		const { publicationResponsibles, unlockResponsibleUpdate, initializeResponsibleIds, completeUpdateResponsible } = this.props
		if(publicationResponsibles.length !== 0 ){
			this.manageProcessLoader(false, true,true, '')
			publicationResponsibles.reduce((accumulatorPromise, value) => {
				const publication = {
					PlanningId : value.planningId,
					ContactId : value.userId,
					Comments : '',
					CommentLevelId : 1
				}
				return accumulatorPromise.then(() => {
					return this.savePublication(publication)
				})
			}, Promise.resolve()).then(() =>{
				completeUpdateResponsible(true)
				this.manageProcessLoader(true, true,true, '')
				this.initializePlanningData()
				unlockResponsibleUpdate(false)
				initializeResponsibleIds()
				this.setState({
					isFinishedUpdate :0
				})
			}).catch((error)=>{
				this.initializePlanningData()
				Logger.log('save ko')
				Logger.log(error)
			})
		}
	}

	handleCancelClick = () => {
		const { initializeResponsibleIds, completeUpdateResponsible } = this.props
		initializeResponsibleIds()
		completeUpdateResponsible(false)
		this.setState({
			isFinishedUpdate :0
		})
	}

	handleCloseProgressBar = () => {
		this.setState({
			isProcessLoaderOpen: false,
			isFinishedUpdate :0
		})
	}

	

	render(){
		const { classes, label, isUpdateResponsibleLocked } = this.props
		const progressModalContent = {
			title : 'Affectation en cours',
			closeButtonText : translate('ra.action.close'),
			resultText : this.state.resultText
		}	
		const validateUpdateContent = {
			title : 'MODIFIER RESPONSABLES',
			disagreeButtonText : translate('ra.action.undo'),
			resultText : this.state.resultText
		}	
		return(
			<th key={label} className = {classes.responsibleHeader}>
				{label}
				<CustomLockIconButton handleClick = {this.handleLockResponsibleUpdate} isLocked = {isUpdateResponsibleLocked} type={'Responsible'}/>
				<ValidateUpdateResponsable modalOpen = {this.state.isFinishedUpdate ===2} 
				  onClose={this.handleCancelClick} 
				  handleValidateClick = {this.handleValidateClick}
				  validateUpdateContent ={validateUpdateContent}/>
				<AssignProcessLoader modalOpen = {this.state.isProcessLoaderOpen} 
					onClose={this.handleCloseProgressBar}  
					isOperationFinished = { this.state.isOperationFinished }
					progressModalContent = {progressModalContent}
				/> 
			</th>
		)
	}
}

const mapStateToProps = state => ({
	...state,
	weekNumber: state.reducer.publicationTracking.weekNumber !==null ? state.reducer.publicationTracking.weekNumber : 16,
	yearNumber: state.reducer.publicationTracking.yearNumber !==null ? state.reducer.publicationTracking.yearNumber : 2020,
	planningData : state.reducer.publicationTracking.planningData !==null ? state.reducer.publicationTracking.planningData : [],
	selectedCategory : state.reducer.publicationTracking.selectedCategory !== null ? state.reducer.publicationTracking.selectedCategory : 0,
	selectedUser : state.reducer.publicationTracking.selectedUser,
	isUpdateResponsibleLocked: state.reducer.publicationTracking.isUpdateResponsibleLocked,
	publicationResponsibles : state.reducer.publicationTracking.publicationResponsibles,
	isFinishedUpdate : 0
})

const mapDispatchToProps = ({
	unlockResponsibleUpdate,
	initializeResponsibleIds,
	onLoadPlanning,
	completeUpdateResponsible
})

const enhance = compose(
	connect(mapStateToProps, mapDispatchToProps),
	withStyles(styles)
)

export default translate(enhance(ResponsibleColumnHeader))