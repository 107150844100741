import React, { PureComponent } from 'react'
import QuickSearchInputBase from './QuickSearchInputBase'
import match from 'autosuggest-highlight/match'
import parse from 'autosuggest-highlight/parse'
import { ProgramType } from '../../../constants/types'
import { Url } from '../../../tools'
import { MenuItem, ListItem, Avatar, ListItemText, ListItemIcon } from '@material-ui/core'
import { validateNumber } from '30-seconds-of-code'
import { addField } from 'react-admin'
import { compose } from 'redux';
import { withStyles } from '@material-ui/core/styles'

const styles = theme =>    ({
	root: {
		flexGrow: 1,
		display: 'flex',
		width: '200px',
	},
	container: {
		position: 'relative',
	},
	search: {
		position: 'relative',
		marginLeft: 0,
		width: '100%',
	},
	searchIcon: {
		width: theme.spacing(9),
		height: '100%',
		position: 'absolute',
		pointerEvents: 'none',
		display: 'flex',
		justifyContent: 'center',
		marginLeft: '145px',
		marginTop: '5px',
		zIndex: '100'
	},
	inputContainer: {
		height: '100%',
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'left',
	},
	inputRoot: {
		color: 'inherit',
		width: '100%',
	},
	inputInput: {
		width: '100%',
	},
	suggestion: {
		display: 'block',
	},
	resultsContainer: {
		width: '100%',
		overflowY: 'auto',
		marginLeft: '20px',
		position: 'relative',
		zIndex: '100',
	},
})

export class QuickSearchInput extends PureComponent {
	renderSuggestion(suggestion, { query, isHighlighted }) {
		const matches = match(suggestion.label, query)
		const parts = parse(suggestion.label, matches)
		let url
		if (ProgramType.MOVIE === suggestion.type)
			url = Url.avatarMovie(suggestion.id)
		else if (ProgramType.ALBUM === suggestion.type)
			url = Url.avatarAlbum(suggestion.id)
		else if (ProgramType.PEOPLE === suggestion.type)
			url = Url.avatarPeople(suggestion.id)

		const label = parts.map((part, index) => {
			return part.highlight ?
				<span key={String(index)} style={{ fontWeight: 'bold' }}>
					{part.text}
				</span>
				:
				<span key={String(index)}>
					{part.text}
				</span>
		})

		return (
			<MenuItem selected={isHighlighted} component='div'>
				<ListItem button={true} dense={true} component='div'>
					<ListItemIcon>
						<Avatar variant="square" alt={suggestion.label} src={url} imgProps={{ onError: (e) => { e.target.src = Url.defaultPicture } }} style={{ borderRadius: 0 }}/>
					</ListItemIcon>
					<ListItemText primary={label} secondary={suggestion.id} />
				</ListItem>
			</MenuItem>
		)
	}

	// eslint-disable-next-line no-unused-vars
	renderSectionTitle = (section) => {
		return null
	}

	shouldRenderSuggestions = (value) => {
		if (!value || typeof (value) === 'undefined') return false
		const val = value.trim()
		return validateNumber(val) ? true : val.length >= 3
	}

	render() {
		const { input, meta: { touched, error }, handleParentComponentInputs, handleParentComponentChange } = this.props

		return <QuickSearchInputBase
			placement='right'
			placeholder='MovieId ou Titre...'
			renderSuggestion={this.renderSuggestion}
			renderSectionTitle={this.renderSectionTitle}
			shouldRenderSuggestions={this.shouldRenderSuggestions}
			handleParentComponentInputs = {handleParentComponentInputs}
			handleParentComponentChange = {handleParentComponentChange}
			errorText={touched && error}
			{...input}
			{...this.props}
		/>
	}
}

const composed = compose(
	addField,
	withStyles(styles)
)(QuickSearchInput)

composed.defaultProps = {
	addLabel: true,
}

export default composed
