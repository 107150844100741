import React, { Fragment, Component } from 'react'
import { connect } from 'react-redux'
import { GET_ONE, fetchEnd, fetchStart, getResources } from 'react-admin'
import { withRouter } from 'react-router-dom'
import Collapse from '@material-ui/core/Collapse'
import ResourcesJson from '../../configurations/resources.json'
import MenuList from '@material-ui/core/MenuList'
import { MenuItemLink } from '../layout'
import dataProvider from '../../dataProvider'
import getParentIdFromDetail from './getParentIdFromDetail'
import { DefaultIcon } from '../../constants/icons'
import { setSidebarVisibility } from 'ra-core'
import { withStyles } from '@material-ui/core/styles'
import PropTypes from 'prop-types'

const styles = theme => ({
	root: {
		paddingTop: 0,
		paddingBottom: 0
	}, // Style applied to the MenuItem from material-ui
	active: {
		fontWeight: 'bold',
		backgroundColor: '#288DB1',
		'& $primary, & $icon': {
			color: theme.palette.text.primary
		},
		color: theme.palette.text.primary
	}, // Style applied when the menu item is the active one
	menuItem: {
		'&:focused': {
			backgroundColor: '#288DB1',
			'& $primary, & $icon': {
				color: theme.palette.text.primary
			},
			color: '#000'
		}
	},
	primary: {},
	icon: {}, // Style applied to the icon
	heading: {
		fontSize: theme.typography.pxToRem(15),
		fontWeight: theme.typography.fontWeightRegular
	},
	nested: {
		paddingLeft: theme.spacing(4),
		paddingTop: 0,
		paddingBottom: 0,
		backgroundColor: '#232c31'
	}
})

class MyMenu extends Component {
	constructor(props) {
		super(props)
		this.state = {
			open: false,
			key: '',
			id: '',
			countAll: ''
		}
	}

	//On refresh
	componentDidMount() {
		this.getCount()
		let openKey = ''
		let openId = ''
		let openResource = ''
		const location = window.location.href.split('/')
		const locationId = location[4].toString().split('?')[1]
		const locationResource = location[4].toString().split('?')[0]
		ResourcesJson.forEach((resource, index) => {
			resource.resourceNameChild.forEach(item => {
				if (item.type !== 'show') {
					openResource = locationResource
					openId = locationId !== undefined ? locationId.split('=')[1] : location[5]
				} else {
					openResource = location[4]
					openId = location[5]
				}
				if (item.name === openResource) {
					openKey = index
				}
			})
			if (resource.resourceName === openResource) openKey = index
		})

		if (openId === 'create') openId = undefined

		this.setState({
			open:
				openResource.length === 5
					? false
					: !isNaN(parseInt(openId, 10))
						? true
						: false,
			key: openKey,
			id: openId
		})
	}

	// TODO: refactoring : isn't recommended to use setState in componentWillUpdate
	UNSAFE_componentWillUpdate(nextProps, nextState) {
		if (nextState.id !== this.state.id) {
			this.setState({
				open:
					nextState.id === undefined || nextState.id === 'create' ? false : true
			})
			this.getCount()
		}
	}

	UNSAFE_componentWillReceiveProps(nextProps) {
		if (nextProps.location.pathname !== this.props.location.pathname) {
			const id =
				nextProps.location.search.length > 0
					? nextProps.location.search.split('=')[1]
					: nextProps.location.pathname.split('/')[2]
			this.setState({
				id: id
			})
		}
	}

	UNSAFE_componentWillMount() {
		// eslint-disable-next-line react/prop-types
		this.props.setSidebarVisibility(false)
	}

	// TODO: refactoring => Collapse doesn't open smoothly
	handleClick = value => {
		const openResource = window.location.href.split('/')
		this.setState({
			open:
				openResource.length === 5
					? false
					: value[1].resourceName === openResource[4]
						? true
						: false,
			key: value[0]
		})
	};

	// TODO: refactoring => may be it's better to store countAll on redux store
	getCount = () => {
		const { fetchStart, fetchEnd } = this.props

		let openId = ''
		let openResource = ''
		const location = window.location.href.split('/')
		const locationId = location[4].toString().split('?')[1]
		const locationResource = location[4].toString().split('?')[0]
		ResourcesJson.forEach((resource, index) => {
			if (!resource.resourceNameChild.length > 0) return
			resource.resourceNameChild.forEach(item => {
				if (item.name === resource.resourceName) {
					openResource = locationResource
					openId = locationId !== undefined ? locationId.split('=')[1] : location[5]
					if (openId === 'create') openId = undefined
					resource.resourceNameChild.forEach(item => {
						if (item.name === openResource && openId !== undefined) {
							fetchStart()

							dataProvider(GET_ONE, resource.resourceName + '/CountChilds', {
								id: openId
							})
								.then(({ data }) => {
									this.setState({ countAll: data })
								})
								.catch(error => {
									//showNotification(error.message, 'error')
								})
								.finally(() => {
									fetchEnd()
								})
						}
					})
				}
			})
		})
	};

	setCount = (countAll, resourceNameChild) => {
		let count = 0
		const arrProps = {}
		ResourcesJson.forEach((resource, index) => {
			resource.resourceNameChild.forEach(item => {
				const resourceChild = item.name.toLowerCase() + 'Count'
				if (item.title !== 'Fiche') {
					arrProps[resourceChild] = countAll[resourceChild]
				}
			})
		})
		// he doesn't display 0, come back again
		const childResource = resourceNameChild.toLowerCase() + 'Count'
		Object.keys(arrProps).forEach(item => {
			if (childResource === item) count = arrProps[childResource]
		})

		return parseInt(count, 10)
	};

	getItem = (resource, key, resources) => {
		const { classes, onMenuClick } = this.props
		const { id } = this.state

		let internalResource
		if (key !== '') {
			internalResource = resources.find(r => r.name === key)
			if (!internalResource)
				return null
		}

		if (key === '') {
			return (
				<Fragment key={key}>
					<MenuList
						className={classes.root}
						onClick={this.handleClick.bind(this, [key, resource])}
					>
						<MenuItemLink
							key={key}
							className={classes.menuItem}
							to={`/${resource.resourceName}`}
							primaryText={resource.resourceNameFr}
							onClick={onMenuClick}
							//leftIcon={<DefaultIcon/>}
							leftIcon={internalResource ? React.createElement(internalResource.icon, null) : <DefaultIcon />}
						/>
					</MenuList>
				</Fragment>
			)
		}

		return (
			<Fragment key={key}>
				<MenuList
					className={classes.root}
					onClick={this.handleClick.bind(this, [key, resource])}
				>
					<MenuItemLink
						key={key}
						className={classes.menuItem}
						classes={{ active: classes.active }}
						to={`/${resource.resourceName}`}
						primaryText={resource.resourceNameFr}
						onClick={onMenuClick}
						//leftIcon={<DefaultIcon/>}
						leftIcon={internalResource ? React.createElement(internalResource.icon, null) : <DefaultIcon />}
					/>
				</MenuList>
				<Collapse in={this.state.open} timeout="auto" unmountOnExit>
					<MenuList className={classes.nested}>
						{key === this.state.key
							? resource.resourceNameChild.map((item, key) => {
								const childInternalResource = resources.find(r => r.name === item.name)
								return item.type !== 'show' ? (
									<MenuItemLink
										key={key}
										className={classes.menuItem}
										classes={{ active: classes.active }}
										to={
											`/${item.name}?${resource.childId}=` +
											getParentIdFromDetail(
												id,
												this.props.reduxform &&
												this.props.reduxform['record-form'] &&
												this.props.reduxform['record-form'].initial,
												resource
											)
										}
										primaryText={item.title}
										onClick={onMenuClick}
										leftIcon={childInternalResource ? React.createElement(childInternalResource.icon, null) : <DefaultIcon />}
										rightIcon={true}
										count={String(this.setCount(this.state.countAll, item.name))}
									/>
								) : (
									<MenuItemLink
										key={key}
										className={classes.menuItem}
										classes={{ active: classes.active }}
										to={
											`/${item.name}/` +
											getParentIdFromDetail(
												id,
												this.props.reduxform &&
												this.props.reduxform['record-form'] &&
												this.props.reduxform['record-form'].initial,
												resource
											) +
											'/show'
										}
										primaryText={item.title}
										onClick={onMenuClick}
										leftIcon={childInternalResource ? React.createElement(childInternalResource.icon, null) : <DefaultIcon />}
										rightIcon={item.title === 'Fiche' ? false : true}
										count={String(this.setCount(this.state.countAll, item.name))}
									/>
								)
							}
							)
							: null}
					</MenuList>
				</Collapse>
			</Fragment>
		)
	}

	render() {
		// eslint-disable-next-line react/prop-types
		const { resources } = this.props

		return (
			<div>
				{
					ResourcesJson.map(rJson => {
						return this.getItem(rJson, rJson.resourceName, resources)
					})
				}
			</div>
		)
	}
}

const mapStateToProps = state => ({
	reduxform: state && state.form,
	resources: getResources(state),
})

const mapDispatchToProps = {
	fetchEnd,
	fetchStart,
	setSidebarVisibility
}
MyMenu.propTypes = {
	className: PropTypes.string,
	classes: PropTypes.object.isRequired,
}

export default withRouter(
	connect(
		mapStateToProps,
		mapDispatchToProps
	)(withStyles(styles)(MyMenu))
)
