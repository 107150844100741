import React, { Component } from 'react'
import { WarningIcon } from '../../../constants/icons'
import { red } from '@material-ui/core/colors'
import { Tooltip } from '@material-ui/core'
import Typography from '@material-ui/core/Typography'
import {  withStyles  } from '@material-ui/core'

const HtmlTooltip = withStyles(theme => ({
	tooltip: {
		backgroundColor: red[500],
		color: 'rgba(0, 0, 0, 0.87)',
		maxWidth: 220,
		fontSize: theme.typography.pxToRem(12),
		border: '1px solid #dadde9',
	},
}))(Tooltip)

class PriceCell extends Component{
	constructor(props) {
		super(props)
	}

	render(){
		const { label, customWidth, comments } = this.props
		const displayLabel = label !== null ? parseFloat(label.trim().replace(/\s+/g, '')).toFixed(2) +'€': ''
		if(comments !== ''){
			return(
				<div style={{ width: customWidth, fontWeight : 'bold' ,textAlign:'right'}}>
					<HtmlTooltip title={
						<React.Fragment>
							<Typography color="inherit">{comments}</Typography>
						</React.Fragment>
					}
					placement="top"
					>
						<WarningIcon style={{ color: red[500] }}/>
					</HtmlTooltip>
					{displayLabel}
				</div>
			)
		}
		else{
			return(
				<div style={{ width: customWidth,textAlign:'right' }}>
					{displayLabel}
				</div>
			)
		}
	}
}

export default PriceCell