import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import compose from 'recompose/compose'
import{ withStyles }  from '@material-ui/core/styles'
import { DispatcherConfigurations } from '../../requests'
import StraightenIcon from '@material-ui/icons/Straighten'
import Typography from '@material-ui/core/Typography'
import ReplayIcon from '@material-ui/icons/Replay'
import { translate } from 'react-admin'
import { Links } from '../../tools'
import { CustomShowBar, CustomUpdateBar, AntTab, AntTabs, CustomAlert, CustomDisplayDialog } from '../layout/GlobalStyles'
import ConfigurationManagerTable from './fields/ConfigurationManagerTable'
import { Create, Edit, SimpleForm, Show } from 'react-admin'
import { createTheme, ThemeProvider } from '@material-ui/core/styles'
import { ON_DISPATCHER_SET_SELECTED_TAB } from '../../actions/ConfigurationManager'

// const styles = ({
// 	root: {
// 		display: 'flex',
// 		flexWrap: 'nowrap',
// 		alignItems: 'center'
// 	},
// 	avatarStatus: {
// 		width: 15,
// 		height: 15
// 	},
// 	headerCell: {
// 		fontSize: '1rem',
// 		fontWeight : 'bold'
// 	},
// 	statusFieldCell: {
// 		width: '10%'
// 	},
// 	jobTypeFieldCell: {
// 		width: '10%'
// 	},
// 	propertyFieldCell: {
// 		width: '5%'
// 	},
// 	labelFieldHeaderCell: {
// 		textAlign: 'center'
// 	},
// 	labelFieldCell: {
// 		width: 'auto',
// 		textAlign: 'center'
// 	},
// 	jobParamsCell: {
// 		width: '100px'
// 	},
// 	activateDispatchServerHeader: {
// 		textAlign : 'center'
// 	},
// 	activateDispatchServerCell: {
// 		width : '60px'
// 	}
// })

// eslint-disable-next-line react/prop-types
// const StatusIcon = ({ source, classes, style, ...props  }) => {
// 	const currentState = getValue(props, source)
// 	if (!currentState)
// 		return null
// 	let bgColor
// 	let tooltipText
// 	if (currentState === 'disabled') { // disabled
// 		bgColor = '#FF0000'
// 		tooltipText = 'Desactivé'
// 	}
// 	else if (currentState === 'enabled') { // activated
// 		bgColor = '#00B911'
// 		tooltipText = 'Activé'
// 	}
// 	else if (currentState === 'not configured') { // not configured
// 		bgColor = '#F0FF3B'
// 		tooltipText = 'Inexistant'
// 	}
// 	return (
// 		<div className={classes.root} style={style}>
// 			<Tooltip title={tooltipText}>
// 				<Avatar className={classes.avatarStatus} style={{ backgroundColor: bgColor, marginLeft:'40px' }} />
// 			</Tooltip>
// 		</div>
// 	)
// }

// const ConfigurationmanagerFilter = props => {
// 	const { translate } = props
// 	const filterChoices = [
// 		{ 
// 			defaultValue: true, 
// 			filterTitle: translate('messages.displayOnlyActiveJobs')
// 		}
// 	]

// 	return (
// 		<Filter {...props}>
// 			<CheckboxGroupInput label="" source="jobStatus" choices={filterChoices} optionText="filterTitle" optionValue="defaultValue" alwaysOn/>
// 		</Filter>
// 	)
// }

// const BuildPropertiesDescription = (jobParamsArray) =>{
// 	if (!Object.prototype.forEach) {
// 		Object.defineProperty(Object.prototype, 'forEach', {
// 			value: function (callback, thisArg) {
// 				if (this == null) {
// 					throw new TypeError('Not an object')
// 				}
// 				thisArg = thisArg || window
// 				for (var key in this) {
// 					if (this.hasOwnProperty(key)) {
// 						callback.call(thisArg, this[key], key, this)
// 					}
// 				}
// 			}
// 		})
// 	}
// 	var propsArray = []
// 	var convert = require('xml-js')
// 	var result1 = convert.xml2json(jobParamsArray, {compact: true, spaces: 4})
// 	var jsonObject = JSON.parse(result1)
// 	if(jsonObject.PROPS.PROP){
// 		var panelNumber = 0
// 		if(jsonObject.PROPS.PROP.length !== undefined){
// 			jsonObject.PROPS.PROP.forEach(function(element){
// 				const currentElement = {
// 					attributeName: element._attributes.NAME,
// 					attributeValue: element._attributes.VALUE,
// 					panelNumber: 'panel'+panelNumber,
// 					subElements: []
// 				}
// 				panelNumber++
// 				propsArray.push(currentElement)
// 			})
// 		}
// 		else{
// 			const currentElement = {
// 				attributeName: jsonObject.PROPS.PROP._attributes.NAME,
// 				attributeValue: jsonObject.PROPS.PROP._attributes.VALUE,
// 				panelNumber: 'panel'+panelNumber,
// 				subElements: []
// 			}
// 			propsArray.push(currentElement)
// 			panelNumber++
// 		}
// 	}
// 	return propsArray
// }

// const BuildTitle = (jobName, serverName, isProps, translate) => {
// 	let title = ''
// 	if(isProps){
// 		title = translate('titles.JobPropsTitle').replace('#job', jobName)
// 	}
// 	else{
// 		title = translate('titles.JobParamModalTitle').replace('#job', jobName).replace('#server', serverName)
// 	}
// 	return title
// }

// const BuildParamsDescription = (jobParamsArray) =>{
// 	if (!Object.prototype.forEach) {
// 		Object.defineProperty(Object.prototype, 'forEach', {
// 			value: function (callback, thisArg) {
// 				if (this == null) {
// 					throw new TypeError('Not an object')
// 				}
// 				thisArg = thisArg || window
// 				for (var key in this) {
// 					if (this.hasOwnProperty(key)) {
// 						callback.call(thisArg, this[key], key, this)
// 					}
// 				}
// 			}
// 		})
// 	}
// 	var paramArray = []
// 	var convert = require('xml-js')
// 	var result1 = convert.xml2json(jobParamsArray, {compact: true, spaces: 4})
// 	var jsonObject = JSON.parse(result1)
// 	if(jsonObject.params.param && jsonObject.params.param.length !== 0){
// 		var panelNumber = 0
// 		jsonObject.params.param.forEach(function(element){
// 			const itemName= element._attributes.name
// 			const itemText = element._text
// 			var current = {
// 				attributeName: itemName,
// 				attributeValue: itemText,
// 				panelNumber: 'panel'+panelNumber,
// 				subElements: []
// 			}
// 			// For sub elements
// 			if(itemText === undefined){
// 				var keys = Object.keys(element)
// 				for(var key of keys){
// 					if(key !== '_attributes'){
// 						if(element[key]){
// 							if(element[key].length){
// 								for(var item of element[key]){
// 									const textValue = item._text !== undefined ? item._text : key // set the text 
// 									const keyValues = []
// 									if(item._attributes){
// 										item._attributes.forEach(function(item, key){
// 											const keyValue = {
// 												key : '',
// 												value: ''
// 											}
// 											keyValue.key = key
// 											keyValue.value = item
// 											keyValues.push(keyValue)
// 										})
// 									}
// 									const subElement = {
// 										textValue: textValue,
// 										keyValues: keyValues
// 									}
// 									current.subElements.push(subElement)
// 								}
// 							}
// 							else{
// 								const textValue = (element[key]._text) !== undefined ? element[key]._text : key // set the text 
// 								const keyValues = []
// 								if(element[key]._attributes){
// 									element[key]._attributes.forEach(function(item, key){
// 										const keyValue = {
// 											key : '',
// 											value: ''
// 										}
// 										keyValue.key = key
// 										keyValue.value = item
// 										keyValues.push(keyValue)
// 									})
// 								}
// 								const subElement = {
// 									textValue: textValue,
// 									keyValues: keyValues
// 								}
// 								current.subElements.push(subElement)
// 							}
// 						}
// 					}
// 				}
// 			}
// 			panelNumber++
// 			paramArray.push(current)
// 		})
// 	}
// 	return paramArray
// }

// const ModalFieldWrapper = props =>{
// 	// eslint-disable-next-line react/prop-types
// 	const { source, record = {}, withTitle, fieldTitle, tooltipText, translate } = props
// 	const parameters = get(record, source)
// 	const label = get(record, 'label')
// 	var modalContent = {
// 		isEmpty: true,
// 		contents: {}
// 	}

// 	if(withTitle){
// 		if(parameters){
// 			const jobParamsArray = parameters.split('|')
// 			if(jobParamsArray[0]){
// 				modalContent.contents = {
// 					title : BuildTitle(label, fieldTitle, false, translate),
// 					descriptions: BuildParamsDescription(jobParamsArray[0])
// 				}
// 			}
// 			modalContent.isEmpty = false
// 		}
// 	}
// 	else{
// 		modalContent.isEmpty = false
// 		modalContent.contents = {
// 			title : BuildTitle(label, null, true, translate),
// 			descriptions: BuildPropertiesDescription(parameters)
// 		}
// 	}
// 	return(
// 		<CustomConfigurationModal modalContent={modalContent} tooltipText={tooltipText} />
// 	)
// }

// class ConfigurationmanagerList extends Component {
// 	constructor(props) {
// 		super(props)
// 		this.changeStatusFunction = this.changeStatusFunction.bind(this)
// 		this.checkServersStatus = this.checkServersStatus.bind(this)
// 		this.state = {
// 			modalOpen: false,
// 			errorMessage: '',
// 			activationModalContent : {
// 				title : '',
// 				description : '',
// 				disagreeButtonText : '',
// 				agreeButtonText : '',
// 				serverName: ''
// 			},
// 			recordToBeUpdate : {}
// 		}
// 	}

// 	handleOpen = () => {
// 		this.setState({
// 			modalOpen: true
// 		})
// 	}

// 	handleClose = () => {
// 		this.setState({modalOpen: false})
// 	}

// 	checkServersStatus = (props) =>{
// 		const { source, record = {}, serverName, translate, showNotification } = props
// 		//first check if the record exists in the dispatcher config jobs table
// 		const serverIds = get(record, 'serverIds')
// 		let continueProcess = true
// 		if(!serverIds){
// 			showNotification('messages.dispatcherConfigJobsNotCreated', 'warning', { autoHideDuration : '6000' })
// 			continueProcess = false
// 		}
// 		else{
// 			const currentServerStatus = get(record, source)
// 			let newStatus = 0
// 			if(currentServerStatus === 'enabled'){
// 				newStatus = 1
// 			}
// 			const label = get(record, 'label')
// 			const modalDescription2 = translate('messages.activateJobModalContentDescription2')
// 				.replace('#messageActivateJob', translate('buttons.continueJobActivation'))
// 				.replace('#messageDontActivateJob', translate('buttons.cancelJobActivation'))
// 			if(serverName === 'serverOne'){
// 				const serverTwoStatus = get(record, 'serverTwoStatus')
// 				if(newStatus === 0 && serverTwoStatus === 'enabled'){
// 					const modalDescription1 = translate('messages.activateJobModalContentDescription')
// 						.replace('#label', label)
// 						.replace('#currentserver', translate('fields.serverTwo'))
// 					this.setState({
// 						modalOpen: true,
// 						errorMessage: translate('messages.onlyOneServerActivation'),
// 						activationModalContent : {
// 							title : translate('fields.serverOne'),
// 							description : `${modalDescription1} ${modalDescription2}`,
// 							disagreeButtonText : translate('buttons.cancelJobActivation'),
// 							agreeButtonText: translate('buttons.continueJobActivation'),
// 							serverName: serverName
// 						},
// 						recordToBeUpdated : record
// 					})
// 					continueProcess = false
// 				}
// 			}
// 			else{
// 				const serverOneStatus = get(record, 'serverOneStatus')
// 				if(newStatus === 0 && serverOneStatus === 'enabled'){
// 					const modalDescription1 = translate('messages.activateJobModalContentDescription')
// 						.replace('#label', label)
// 						.replace('#currentserver', translate('fields.serverOne'))
// 					this.setState({
// 						modalOpen: true,
// 						errorMessage: translate('messages.onlyOneServerActivation'),
// 						activationModalContent : {
// 							title : translate('fields.serverTwo'),
// 							description : `${modalDescription1} ${modalDescription2}`,
// 							disagreeButtonText : translate('buttons.cancelJobActivation'),
// 							agreeButtonText: translate('buttons.continueJobActivation'),
// 							serverName: serverName
// 						},
// 						recordToBeUpdated : record
// 					})
// 					continueProcess = false
// 				}
// 			}
// 		}
		
// 		return continueProcess
// 	}

// 	changeStatusFunction = (props) =>{
// 		try{
// 			const { source, record = {}, serverName, showNotification } = props
// 			const currentServerStatus = get(record, source)
// 			let newStatus = 0
// 			if(currentServerStatus === 'enabled'){
// 				newStatus = 1
// 			}
// 			const updatedRecord = { ...record, CurrentStatus: 0, NewStatus: newStatus, serverName:  serverName}

// 			dataProvider(CREATE, 'Dispatcherconfigjobs/ChangeDispatchConfigJobsstatus', {id: record.id, updatedRecord})
// 				.then((json) => {
// 					if(json.data.serverId === 1){
// 						record.recordIdServerOne = json.data.recordid
// 						record.serverOneStatus = json.data.disabled === true ? 'disabled': 'enabled'
// 					}
// 					else{
// 						record.recordIdServerTwo = json.data.recordid
// 						record.serverTwoStatus = json.data.disabled === true ? 'disabled': 'enabled'
// 					}
// 					showNotification('messages.successfulJobStatusUpdate', 'info', { autoHideDuration : '3000' })
// 				})
// 				.catch((error) => {
// 					console.log('Update error: '+ error.message)
// 					showNotification('messages.errorUpdatingConfigurationManagerStatus', 'warning', { autoHideDuration : '6000' })
// 				})
// 		}
// 		catch(error){
// 			console.log('Update error: ' + error.message)
// 		}
		
// 	}

// 	launchActivationProcess = (props) => {
// 		const { recordToBeUpdated, showNotification, modalContent, crudGetList } = props
		
// 		const record1 = { ...recordToBeUpdated, CurrentStatus: 0, NewStatus: 0, serverName: modalContent.serverName}
// 		const record2 = { ...recordToBeUpdated, CurrentStatus: 0, NewStatus: 1, serverName: modalContent.serverName === 'serverOne'? 'servertwo' : 'serverOne'}

// 		//updated records
// 		Promise.all([dataProvider(CREATE, 'Dispatcherconfigjobs/ChangeDispatchConfigJobsstatus', {id: record1.id, updatedRecord : record1}), 
// 			dataProvider(CREATE, 'Dispatcherconfigjobs/ChangeDispatchConfigJobsstatus', {id: record2.id, updatedRecord : record2})]).then(() => {
// 			showNotification('messages.successfulMultipleJobsChangeStatus', 'info', { autoHideDuration : '3000' })
// 			//update the redux store
// 			crudGetList(
// 				'Dispatcherconfigjobs/ConfigurationManager', // resource
// 				{}, // pagination
// 				{}, // sort
// 				{ 
// 					domainId: configurationManagerDomainId,
// 					langId : configurationManagerLangId
// 				} //filter
// 			)
// 			setTimeout(() => {
// 					this.setState({
// 					modalOpen: false
// 				})}, 
// 				1000)
// 		}).catch((error) => {
// 			console.log('Update error: '+ error.message)
// 			showNotification('messages.errorUpdatingConfigurationManagerStatus', 'warning', { autoHideDuration : '6000' })
// 		})
// 	}

// 	render(){
// 		const { translate } = this.props
// 		const serverOneParamsTitle = translate('fields.serverOne')
// 		const serverTwoParamsTitle = translate('fields.serverTwo')
// 		const paramTextHover = translate('messages.displayModalText')
		
// 		const switchButtonTooltipText = {
// 			activateText : translate('messages.activateJobOnTheServer'),
// 			deactivateText : translate('messages.deactivateJobOnTheServer')
// 		}
// 		return(
// 			<React.Fragment>
// 				<List
// 					filters={<ConfigurationmanagerFilter {...this.props}/>}
// 					{...this.props}
// 					title="titles.configurationManager"
// 					filterDefaultValues={{ domainId: 93, langId: 2, jobStatus: false}}
// 					bulkActionButtons={false}
// 					perPage = {50}
// 					exporter = {false}
// 				>
// 					<Datagrid classes={{headerCell: this.props.classes.headerCell}}>
// 						<StatusIcon cellClassName = {this.props.classes.statusFieldCell} source="jobStatus" label="fields.jobStatus" {...this.props} sortable={false}/>
// 						<TextField cellClassName = {this.props.classes.jobTypeFieldCell} source="id" label="fields.jobTypeId" sortable={false}/>
// 						<ModalFieldWrapper cellClassName = {this.props.classes.propertyFieldCell} source="properties" label="fields.jobProperties" {...this.props} sortable={false} withTitle={false} tooltipText={paramTextHover}/>
// 						<TextField cellClassName = {this.props.classes.labelFieldCell} headerClassName = {this.props.classes.labelFieldHeaderCell} source="label" label="fields.label" sortable={false}/>
// 						<ModalFieldWrapper cellClassName = {this.props.classes.jobParamsCell}  source="jobParamsServerOne" label="fields.jobParamsServerOne"  {...this.props} fieldTitle={serverOneParamsTitle} sortable={false} withTitle={true} tooltipText={paramTextHover}/>
// 						<CustomSwitchButton source="serverOneStatus" label="fields.serverOne"  cellClassName = {this.props.classes.activateDispatchServerCell} headerClassName = {this.props.classes.activateDispatchServerHeader} enableSwitchProcess={this.checkServersStatus} {...this.props} serverName="serverOne" switchFunction={this.changeStatusFunction} sortable={false} tooltipText = {switchButtonTooltipText}/>
// 						<ModalFieldWrapper cellClassName = {this.props.classes.jobParamsCell} source="jobParamsServerTwo" label="fields.jobParamsServerTwo"  {...this.props} fieldTitle={serverTwoParamsTitle} sortable={false} withTitle={true} tooltipText={paramTextHover}/>
// 						<CustomSwitchButton source="serverTwoStatus" label="fields.serverTwo" cellClassName = {this.props.classes.activateDispatchServerCell} headerClassName = {this.props.classes.activateDispatchServerHeader} enableSwitchProcess={this.checkServersStatus} {...this.props} serverName="servertwo" switchFunction={this.changeStatusFunction} sortable={false} tooltipText = {switchButtonTooltipText}/>
// 					</Datagrid>
// 				</List>
// 				<AlertDialogSlide {...this.props} modalOpen = {this.state.modalOpen} onClose={this.handleClose} modalContent = {this.state.activationModalContent} recordToBeUpdated = {this.state.recordToBeUpdated} launchActivationProcess = { this.launchActivationProcess }/>
// 			</React.Fragment>
// 		)
// 	}
// }

const styles = (theme) => ({
	root: {
		display: 'flex',
		flexWrap: 'wrap',
		'& > *': {
			margin: theme.spacing(1),
			width: '100%',
			height: theme.spacing(10),
		},
	},
	formControl: {
		margin: theme.spacing(2),
		width: '15%'
	},
	searchButton: {
		color: 'rgba(0, 0, 0, 0.54)',
		// margin: theme.spacing(3)
	},
	switchs: {
		right: '0%',
		position: 'absolute',
		marginTop: '1.5em',
		width: '20em'
	},
	switch: {
		marginBottom: '.5em'
	},
	spanLabel: {
		fontSize: '.8em'
	},
	linkStyle: {
		color: "#000",
		textDecoration: "none" 
	},
	favHeaderStyle: {
		padding: '1px',
		top: '0',
		zIndex: '1',
		color: '#0000008a',
		fontWeight: 'bold',
		fontSize: '0.825em',
		backgroundColor: '#FFF',
		borderBottom: '1px solid rgba(224, 224, 224, 1)'
	},
	container: {
		backgroundColor: "#fff",
		borderRadius: "5px"
	}
})

function TabPanel(props) {
	const { children, value, index, ...other } = props;
	return (
	  <div
		role="tabpanel"
		hidden={value !== index}
		id={`full-width-tabpanel-${index}`}
		aria-labelledby={`full-width-tab-${index}`}
		{...other}
	  >
		{value === index && (
		  <div p={3}>
			<Typography>{children}</Typography>
		  </div>
		)}
	  </div>
	)
}



const CreateActions = () => (<></>
	//<CustomUpdateBar to={Links.PromotionList()} method={savePromotion}/>
)

function setPropsTab(index) {
	return {
		id: `simple-tab-${index}`,
		'aria-controls': `simple-tabpanel-${index}`,
	}
}
const getMuiTheme = () => createTheme({
	overrides: {
		MuiCardContent: {
			root: {
				'&:first-child': {
					paddingLeft: 0,
					paddingRight: 0,
				},
			}
		},
	},
	display: {
		display: 'none'
	},
})

const Configurationmanager = (props) => {
	const { classes, create, selectedTabIndex, onDispatcherSetSelectedTab } = props
	
	const [value, setValue] = React.useState(0)
	const [mSJobsExecuteValues, setMSJobsExecuteValues] = React.useState([])
	const [vodDispatcherValues, setVodDispatcherValues] = React.useState([])

	let MSJobsExecuteValues = []
	let VodDispatcherValues = []

	useEffect(() => {
		const fetchData = async () => {
			await DispatcherConfigurations.getAll().then((json) => {
				if (json.data !== undefined) {
					json.data.forEach(d => {
						if(d.servicename.startsWith('Instance'))
							MSJobsExecuteValues.push({
								configid : d.id,
								servername : d.servername,
								servicename: d.servicename,
								config : d.config,
								createdby: d.createdby,
								createdon: d.createdon,
								modifiedby : d.modifiedby,
								modifiedon : d.modifiedon,
								recordid: d.recordid,
								disabled: d.disabled,
								disabledby: d.disabledby,
								disabledon: d.disabledon
							})
						else if(d.servicename === "VodDispatcher")
							VodDispatcherValues.push({
								configid : d.id,
								servername : d.servername,
								servicename: d.servicename,
								config : d.config,
								createdby: d.createdby,
								createdon: d.createdon,
								modifiedby : d.modifiedby,
								modifiedon : d.modifiedon,
								recordid: d.recordid,
								disabled: d.disabled,
								disabledby: d.disabledby,
								disabledon: d.disabledon
							})
					})
				}
			})
			setMSJobsExecuteValues(MSJobsExecuteValues)
			setVodDispatcherValues(VodDispatcherValues)
		}
		fetchData().catch(console.error)
	}, [])

	const handleChange = (event, newValue) => {
		onDispatcherSetSelectedTab(newValue)
	}


	return(
		<div className={classes.container}>
			<AntTabs
				value={selectedTabIndex}
				onChange={handleChange}
				style={{ marginTop: 'auto', borderTopLeftRadius: "5px", borderTopRightRadius: "5px" }}
				TabIndicatorProps={{
					style: {transition: 'none'}
				}}
				fullWidth
			>
				<AntTab label="MSJOBS EXECUTE" {...setPropsTab(0)} />
				<AntTab label="VOD DISPATCHER" {...setPropsTab(1)} />
			</AntTabs>
			<TabPanel value={selectedTabIndex} index={0}>
				<ConfigurationManagerTable data={mSJobsExecuteValues} title="MSJobsExecute" serviceType="MSJobsExecute" />
			</TabPanel>
			<TabPanel value={selectedTabIndex} index={1}>
				<ConfigurationManagerTable data={vodDispatcherValues} title="VodDispatcher" serviceType="VodDispatcher"/>
			</TabPanel>
		</div>
	)
}

const mapStateToProps = state => ({
	...state,
	selectedTabIndex: state.reducer ? state.reducer.configurationManager.selectedTabIndex : null,
})

const mapDispatchToProps = dispatch => ({
	onDispatcherSetSelectedTab : (selectedTabIndex) => 
		dispatch({ type: ON_DISPATCHER_SET_SELECTED_TAB, selectedTabIndex: selectedTabIndex }),
})

const enhance = compose(
	connect(mapStateToProps, mapDispatchToProps),
	withStyles(styles)
)

export default translate(enhance(Configurationmanager))
