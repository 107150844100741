import React, { Component } from 'react'
import { PublicationTrackingReferenceData } from '../../../tools'
import ErrorIcon from '@material-ui/icons/Error'
import { Tooltip } from '@material-ui/core'
class ReferenceDataCell extends Component{
	constructor(props) {
		super(props)
	}

	render(){
		const { referencedataid, type, customWidth ,seasonRow} = this.props
		if(referencedataid !== null && referencedataid !== 0){
			const information = PublicationTrackingReferenceData.getCellInformation(referencedataid, type)
			return(
				<div style={{ textAlign : 'center', width: customWidth }}>
					{information.label}
				</div>
			)
		}
		else{
			 
			if(type !== 'format' && type !== 'version' && !seasonRow && (referencedataid === null || referencedataid === 0))
			return(
				<div style={{ width: customWidth ,textAlign : 'center' }} >
					<Tooltip title='Vérifier le paramétrage ou bien le fichier de planning'>
						<ErrorIcon style={{color: 'red'}}/>
					</Tooltip>
				</div>
			)
			else {
				return(
					<div style={{ width: customWidth ,textAlign : 'center'   }}>
						-
					</div>
				)
			}
		}
	}
}

export default ReferenceDataCell