import React from 'react'
import { List, Filter, SelectInput, CardActions, ExportButton } from 'react-admin'
import JobsStatus from './JobsStatus'
import { DomainsItemsInput } from '../../common/inputs'
import { DomainsType } from '../../../constants/types'
import { BackButton } from '../../common/buttons'

const periodicities = [
	{ id: '-1', name: 'labels.all' },
	{ id: '1H', name: '1H' },
	{ id: '2H', name: '2H' },
	{ id: '12H', name: '12H' },
	{ id: '24H', name: '24H' },
	{ id: '48H', name: '48H' },
	{ id: '7D', name: '7j' },
]

const JobsFilter = (props) => (
	<Filter {...props}>
		<DomainsItemsInput source="typeid" label="fields.type" domainid={DomainsType.JOBTYPE} withAll={true} options={{ style: { minWidth: '240px' } }} alwaysOn allowEmpty={false} shrink={false} />
		<SelectInput label="fields.periodicity" source="periodicityid" choices={periodicities} alwaysOn allowEmpty={false} />
	</Filter>
)

// eslint-disable-next-line react/prop-types
const ActionsList = ({ basePath, currentSort, exporter, filterValues, resource, }) => (
	<CardActions>
		<BackButton basePath={basePath} />
		<ExportButton
			resource={resource}
			sort={currentSort}
			filter={filterValues}
			exporter={exporter}
		/>
	</CardActions>
)

const JobsList = (props) => (
	<List title="titles.supervisingJobs" filters={<JobsFilter />} filterDefaultValues={{ typeid: -1, stateid: -1, periodicityid: '24H' }} actions={<ActionsList />} pagination={null} perPage={-1} {...props} >
		<JobsStatus />
	</List>
)

export default JobsList
