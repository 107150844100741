import React from 'react'
import PropTypes from 'prop-types'
import deburr from 'lodash/deburr'
import Autosuggest from 'react-autosuggest'
import TextField from '@material-ui/core/TextField'
import Paper from '@material-ui/core/Paper'
import Popper from '@material-ui/core/Popper'
import { translate, fetchEnd, fetchStart, showNotification } from 'react-admin'
import compose from 'recompose/compose'
import { connect } from 'react-redux'
import { QuickSearch } from '../../../requests'
import { ProgramType, WindowUrlType } from '../../../constants/types'
import { validateNumber } from '30-seconds-of-code'
import { SearchIcon } from '../../../constants/icons'
import { withStyles } from '@material-ui/core'
import get from 'lodash/get'
import { Logger } from '../../../tools'
import { AUTH_LOGOUT } from 'react-admin'
import authProvider from '../../../authProvider'

const styles = ({
	root: {
	},
	container: {
	},
	search: {
	},
	searchIcon: {
	},
	inputContainer: {
	},
	inputRoot: {
	},
	inputInput: {
	},
	suggestion: {
		display: 'block',
	},
	suggestionsList: {
		margin: 0,
		padding: 0,
		listStyleType: 'none',
		overflowY: 'auto',
		maxHeight: '200px'
	},
	resultsContainer: {
	},
})

class QuickSearchInputBase extends React.Component {
	constructor(props) {
		super(props)

		const { modes, source, record } = props

		let initValue = ''
		if (source && record) {
			initValue = get(record, source)
			if (!initValue || typeof (initValue) === 'undefined')
				initValue = ''
		}

		this.state = {
			value: String(initValue),
			suggestions: [],
			isOnPricePlanningPage : false,
			modes: modes && modes.length > 0 ? modes : [ProgramType.MOVIE, ProgramType.ALBUM, ProgramType.PEOPLE]
		}

		this.lastRequestId = null
		this.selected = null

		this.renderInputComponent = this.renderInputComponent.bind(this)
		this.onSuggestionsFetchRequested = this.handleSuggestionsFetchRequested.bind(this)
		this.onSuggestionsClearRequested = this.handleSuggestionsClearRequested.bind(this)
		this.getSuggestionValue = this.getSuggestionValue.bind(this)
		this.onSuggestionSelected = this.onSuggestionSelected.bind(this)
	}

	renderInputComponent(inputProps) {
		const { classes, inputRef = () => { }, ref, ...other } = inputProps

		return (
			<TextField
				fullWidth
				InputProps={{
					inputRef: node => {
						ref(node)
						inputRef(node)
					},
					classes: {
						root: classes.inputRoot,
						input: classes.inputInput
					},
					disableUnderline: true,
				}}
				{...other}
			/>
		)
	}

	getSuggestionValue(suggestion) {
		return suggestion.id
	}

	// eslint-disable-next-line no-unused-vars
	renderSuggestion(suggestion, { query, isHighlighted }) {
		return (
			<div>
				{suggestion.label}
			</div>
		)
	}

	shouldRenderSuggestions = (value) => {
		if (!value || typeof (value) === 'undefined') return false
		const val = value.trim()
		return validateNumber(val) ? true : val.length >= 3
	}

	getModes = (url) => {
		let modes = []
		if(url.includes(WindowUrlType.MOVIE)){
			modes.push(ProgramType.MOVIE)
		}
		if(url.includes(WindowUrlType.ALBUM)){
			modes.push(ProgramType.ALBUM)
		}
		if(url.includes(WindowUrlType.PRICEPLANIFICATION)){
			modes.push(ProgramType.ALBUM)
			modes.push(ProgramType.MOVIE)
		}
		if(url.includes(WindowUrlType.PEOPLE)){
			modes.push(ProgramType.PEOPLE)
		}
		return modes
	}

	handleSuggestionsFetchRequested = ({ value }) => {
		const { translate, fetchStart, fetchEnd, modes, showNotification, isMaintenanceModeEnabled } = this.props

		// Cancel the previous request
		if (this.lastRequestId !== null) {
			console.log('Quick search request canceled, ' + this.lastRequestId)
			clearTimeout(this.lastRequestId)
			fetchEnd()
		}
		fetchStart()
		//contextual search
		if(modes === undefined || modes === null || (modes && modes.length === 0)){
			var currentModes = this.getModes(window.location.href)
			this.setState({ modes : currentModes && currentModes.length > 0 ? currentModes : [ProgramType.MOVIE, ProgramType.ALBUM, ProgramType.PEOPLE] })
		}

		this.setState({ value : value })

		this.lastRequestId = setTimeout(() => {
			QuickSearch.search(deburr(value.trim()).toLowerCase(), this.state.modes, isMaintenanceModeEnabled)
				.then(({ data }) => {
					const list = Object.values(data)
					const movies = list.filter(item => ProgramType.MOVIE === item.type)
					const albums = list.filter(item => ProgramType.ALBUM === item.type)
					const peoples = list.filter(item => ProgramType.PEOPLE === item.type)
					let suggestions = [
						{
							title: translate('pluralizations.movies', { smart_count: movies.length }),
							type: ProgramType.MOVIE,
							items: movies
						},
						{
							title: translate('pluralizations.albums', { smart_count: albums.length }),
							type: ProgramType.ALBUM,
							items: albums
						},
						{
							title: translate('pluralizations.peoples', { smart_count: peoples.length }),
							type: ProgramType.PEOPLE,
							items: peoples
						},
					]

					suggestions = suggestions.filter(section => section.items.length > 0)

					fetchEnd()

					this.setState({ suggestions: suggestions })
				}).catch((error) => {
					Logger.debug('Error message '+ error.message)
					if(error.status === 401 || error.status === 403){
						showNotification('ra.notification.logged_out', 'warning', { autoHideDuration : '6000' })
						//authProvider(AUTH_LOGOUT, error)
					}
				}).finally(()=>{
					fetchEnd()
				})
		}, 500)
	}

	handleSuggestionsClearRequested = () => {
		this.setState({
			suggestions: []
		})
	}

	handleChange = () => (event, { newValue, method }) => {
		if (method === 'click') return
		this.setState({
			value: newValue,
		})
		this.selected = null
		if(this.props.handleParentComponentChange){
			this.props.handleParentComponentChange()
		}
	}

	renderSectionTitle = (section) => {
		return (
			<div>
				{section.title}
			</div>
		)
	}

	onSuggestionSelected = (event, { suggestion }) => {
		// var parsed = Number.parseInt(this.state.value, 10)
		// this.selected = Number.isNaN(parsed) ? suggestion.label : suggestion.id
		this.selected = this.state.value
		if(this.props.handleParentComponentInputs){
			this.props.handleParentComponentInputs(suggestion, this.state.isOnPricePlanningPage)
		}
	}

	render() {
		const {
			classes,
			translate,
			placement = 'bottom',
			placeholder = 'labels.search',
			renderSuggestion = this.renderSuggestion,
			renderSectionTitle = this.renderSectionTitle,
			shouldRenderSuggestions = this.shouldRenderSuggestions,
			input,
		} = this.props

		const autosuggestProps = {
			renderInputComponent: this.renderInputComponent,
			suggestions: this.state.suggestions,
			onSuggestionsFetchRequested: this.handleSuggestionsFetchRequested,
			onSuggestionsClearRequested: this.handleSuggestionsClearRequested,
			getSuggestionValue: this.getSuggestionValue,
			renderSuggestion: renderSuggestion,
			shouldRenderSuggestions: shouldRenderSuggestions,
			onSuggestionSelected: this.onSuggestionSelected
		}

		return (
			<div className={classes.root}>
				<div className={classes.search}>
					{
						classes.searchIcon ?
							<div className={classes.searchIcon}>
								<SearchIcon />
							</div> :
							null
					}
					<div id='autosuggest_quicksearch' className={classes.inputContainer}>
						<Autosuggest
							id='autosuggest_quicksearch'
							{...autosuggestProps}
							multiSection={true}
							inputProps={{
								classes,
								placeholder: translate(placeholder),
								value: this.selected ? String(this.selected) : this.state.value,
								onChange: this.handleChange(),
								inputRef: node => {
									this.popperNode = node
								},
								InputLabelProps: {
									shrink: true,
								},
								input,
							}}
							theme={classes}
							renderSuggestionsContainer={options => (
								<Popper disablePortal={true} transition={true} placement={placement} anchorEl={this.popperNode} container={this.popperNode} open={Boolean(options.children)}
									modifiers={{
										flip: {
											enabled: false,
										},
										hide: {
											enabled: false,
										},
										preventOverflow: {
											enabled: false,
											boundariesElement: 'scrollParent',
										}
									}}
								>
									<Paper
										square
										{...options.containerProps}
										className={classes.resultsContainer}
									>
										{options.children}
									</Paper>
								</Popper>
							)}
							renderSectionTitle={renderSectionTitle}
							getSectionSuggestions={section => section.items}
						/>
					</div>
				</div>
			</div>
		)
	}
}

QuickSearchInputBase.propTypes = {
	classes: PropTypes.object.isRequired,
	translate: PropTypes.func,
}
const mapStateToProps = state => ({
	...state,
	isMaintenanceModeEnabled : state.reducer.applicationStatus !== null ?  state.reducer.applicationStatus.isMaintenanceModeEnabled : false
})

const mapDispatchToProps = {
	fetchEnd,
	fetchStart,
	showNotification
}

export default compose(
	translate,
	connect(mapStateToProps, mapDispatchToProps),
	withStyles(styles)
)(QuickSearchInputBase)