import React, { PureComponent  } from 'react'
import { connect } from 'react-redux'
import compose from 'recompose/compose'
import { unlockPrioUpdate,   initializePrioIds , completeUpdatePrio, onLoadPlanning} from '../../../actions/publicationTracking'
import CustomLockIconButton from './CustomLockIconButton'
import { Publicationstracking } from '../../../requests'
 import { translate } from 'react-admin'
import { Logger ,Contacts as ContactsTools } from '../../../tools'
import Badge from '@material-ui/core/Badge'
import ValidateUpdateResponsable from './ValidateUpdateResponsable'
import { withStyles } from '@material-ui/core/styles'
import ImportProcessLoader from './ImportProcessLoader'




const styles = () =>  ({
	prioHeader: {
		backgroundColor: '#FFF',
		padding: '1px',
		paddingTop:'5px',
		'&:nth-child(1)': {
			width: '3%',
		},
		position: 'sticky',
		top: '0',
		zIndex: '1',
		color: '#0000008a',
		fontWeight: 'bold',
		fontSize: '0.825em',
		minWidth: '80px',
		borderBottom : '1px solid rgba(224, 224, 224, 1)'

	},
})
const StyledBadge = withStyles((theme) => ({
	badge: {
		top: '-5px',
		right: '2px'
	},
  }))(Badge);
const defaultProps = {
	color: "secondary",
};

class PrioColumnHeader extends PureComponent  {
	constructor(props) {
		super(props)
		this.state = {
			isProcessLoaderOpen: false,
			isOperationFinished: false,
			isFinishedUpdate: 0,
			lockClick : false,
			resultText :'Voulez-vous valider la modification des contenus référents ?'
		}
	}

	handleLockPrioUpdate = () => {
		const {planningPrios, isUpdatePrioLocked, unlockPrioUpdate } = this.props
		unlockPrioUpdate(isUpdatePrioLocked ? true : false)

		if(isUpdatePrioLocked  )
		{
			this.setState({isFinishedUpdate :  1  })
		}
		else if(!isUpdatePrioLocked && this.state.isFinishedUpdate === 1 && planningPrios.length !== 0)
		{
			this.setState({isFinishedUpdate :  2  })
			unlockPrioUpdate(!isUpdatePrioLocked ? true : false)
		}
		 else if ((isUpdatePrioLocked && this.state.isFinishedUpdate === 2) ||planningPrios.length === 0)
		{
			this.setState({isFinishedUpdate :  0  })

		}
	}
	initializePlanningData = () => {
		const { weekNumber, yearNumber, onLoadPlanning } = this.props
		Publicationstracking.getPlanning(Number.parseInt(`${yearNumber.toString()}${weekNumber.toString()}`)).then((json)=>{
			this.buildPlanningList(json.data, onLoadPlanning)
		})
	}
		buildPlanningList = (jsonData, onLoadPlanning) => {
		const trackingData = jsonData
		let planningToBeSaved = {
			publicationsplannings: trackingData.publicationsplannings,
			publicationsstats: []
		}
		const users = ContactsTools.getContactsByGroupid(160)
		let distribution = {
			pgm: trackingData.publicationsplannings.filter(x => x.publications !== null && (x.taskTypeId === 14 || x.taskTypeId === 16 || x.taskTypeId === 17 || x.taskTypeId === 27 || x.taskTypeId === 28)),
			ouv: trackingData.publicationsplannings.filter(x => x.publications !== null && (x.taskTypeId === 21 || x.taskTypeId === 22)),
			susp: trackingData.publicationsplannings.filter(x => x.publications !== null && (x.taskTypeId === 11 || x.taskTypeId === 12 || x.taskTypeId === 13 || x.taskTypeId === 24 || x.taskTypeId === 25)),
			chgtprix: trackingData.publicationsplannings.filter(x => x.publications !== null && (x.taskTypeId === 4 || x.taskTypeId === 6 || x.taskTypeId === 7)),
			//regulprix: trackingData.publicationsplannings.filter(x => x.publications !== null && (x.taskTypeId >= 31 && x.taskTypeId <= 46)),
			autre: trackingData.publicationsplannings.filter(x => x.publications !== null && ((x.taskTypeId >= 1 && x.taskTypeId <= 3) || (x.taskTypeId >= 31 && x.taskTypeId <= 46) || x.taskTypeId === null)),

		}

		if (trackingData.publicationsstats !== null && trackingData.publicationsstats.length !== 0) {
			trackingData.publicationsstats.forEach(item => {
				const currentUser = users.find(u => u.id === item.contactId)

				const stat = {
					contactName: currentUser.name.split(" ")[1],
					publicationsCount: item.publicationsCount,
					pgm: distribution.pgm !== undefined ? distribution.pgm.filter(x => x.publications.contactId === item.contactId).length : 0,
					ouv: distribution.ouv !== undefined ? distribution.ouv.filter(x => x.publications.contactId === item.contactId).length : 0,
					susp: distribution.susp !== undefined ? distribution.susp.filter(x => x.publications.contactId === item.contactId).length : 0,
					chgtprix: distribution.chgtprix !== undefined ? distribution.chgtprix.filter(x => x.publications.contactId === item.contactId).length : 0,
					//regulprix: distribution.regulprix !== undefined ? distribution.regulprix.filter(x => x.publications.contactId === item.contactId).length : 0,
					autre: distribution.autre !== undefined ? distribution.autre.filter(x => x.publications.contactId === item.contactId).length : 0,
					publicationPercent: Number((item.publicationsCount * 100 / trackingData.publicationsplannings.length).toFixed(1))
				}
				planningToBeSaved.publicationsstats.push(stat)
			})
		}
		onLoadPlanning(planningToBeSaved)
	}

	savePlanning = (itemToBeUpdated) => {
		const { weekNumber, yearNumber, onLoadPlanning } = this.props
		return new Promise((resolve, reject) => {
			Publicationstracking.updatePrioItem(itemToBeUpdated).then(
				() => {
					resolve()
					// Publicationstracking.getPlanning(Number.parseInt(`${yearNumber.toString()}${weekNumber.toString()}`)).then((json)=>{
					// 	this.buildPlanningList(json.data, onLoadPlanning)
					// })
				}
			).catch((error) => {
				Logger.debug(error)
				reject()
			})
		})
	}

	manageProcessLoader = (isOperationFinished, isProcessLoaderOpen, isFinishedUpdate) => {
		this.setState({
			isOperationFinished: isOperationFinished,
			isProcessLoaderOpen: isProcessLoaderOpen,
			isFinishedUpdate: isFinishedUpdate
		})
	}

 

	handleValidateClick = () => {
		const { planningPrios, unlockPrioUpdate, initializePrioIds, completeUpdatePrio } = this.props
		if(planningPrios.length !== 0 ){
			this.manageProcessLoader(false, true,true, '')
			planningPrios.reduce((accumulatorPromise, value) => {
				 const planning = {
				 	Id : value.planningId,
					PRIO : value.prio
				}
				return accumulatorPromise.then(() => {
					return this.savePlanning(planning)
				})
			}, Promise.resolve()).then(() =>{
				completeUpdatePrio(true)
				this.manageProcessLoader(true, true,true, '')
 				unlockPrioUpdate(true)
				initializePrioIds()
				this.setState({
					isFinishedUpdate :0
				})
			}).catch((error)=>{
 				Logger.log('save ko')
				Logger.log(error)
			})

		}
	}

	handleCancelClick = () => {
		const { initializePrioIds, completeUpdatePrio,unlockPrioUpdate, } = this.props
		initializePrioIds()
		completeUpdatePrio(false)
		unlockPrioUpdate(true)

		this.setState({
			isFinishedUpdate :0
		})
	}

	handleCloseProgressBar = () => {
		const { weekNumber, yearNumber, onLoadPlanning } = this.props

		this.setState({
			isProcessLoaderOpen: false,
			isFinishedUpdate :0
		})
		Publicationstracking.getPlanning(Number.parseInt(`${yearNumber.toString()}${weekNumber.toString()}`)).then((json)=>{
			this.buildPlanningList(json.data, onLoadPlanning)
		})
	}


	render() {
		const { classes, label, isUpdatePrioLocked, PrioCount } = this.props
		const progressModalContent = {
			title :'MODIFICATION DES CONTENUS REFERENTS',
			closeButtonText : 'Fermer',
			operationFinished : this.state.resultText,
			isPrio : true

		}
		const validateUpdateContent = {
			title: 'MODIFIER CONTENU REFERENT',
			disagreeButtonText: translate('ra.action.undo'),
			resultText: this.state.resultText
		}
		return (
			<th key={1} className={classes.prioHeader}>
			{/* PrioCount */}
			<StyledBadge badgeContent={PrioCount} max={99} {...defaultProps} >
				{label}
			</StyledBadge>
			<CustomLockIconButton handleClick={this.handleLockPrioUpdate} isLocked={isUpdatePrioLocked} type='Prio' />
			<ValidateUpdateResponsable
				  modalOpen = {this.state.isFinishedUpdate ===2 } 
				  onClose={this.handleCancelClick} 
				  handleValidateClick = {this.handleValidateClick}
				  validateUpdateContent ={validateUpdateContent}/>
			<ImportProcessLoader modalOpen = {this.state.isProcessLoaderOpen} 
					onClose={this.handleCloseProgressBar}  
					isOperationFinished = { this.state.isOperationFinished }
					isOperationFailed = { this.state.isOperationFailed }
					progressModalContent = {progressModalContent}
				/>
			</th>
		)			 
	}
}

const mapStateToProps = state => ({
	...state,
	weekNumber: state.reducer.publicationTracking.weekNumber !== null ? state.reducer.publicationTracking.weekNumber : 16,
	yearNumber: state.reducer.publicationTracking.yearNumber !== null ? state.reducer.publicationTracking.yearNumber : 2020,
	planningData: state.reducer.publicationTracking.planningData !== null ? state.reducer.publicationTracking.planningData : [],
	isUpdatePrioLocked: state.reducer.publicationTracking.isUpdatePrioLocked ,
	planningPrios : state.reducer.publicationTracking.planningPrios,
	isFinishedUpdate: 0
})

const mapDispatchToProps = ({
	unlockPrioUpdate,
	initializePrioIds,
	onLoadPlanning,
	completeUpdatePrio,
})

const enhance = compose(
	connect(mapStateToProps, mapDispatchToProps),
	withStyles(styles)
)

export default translate(enhance(PrioColumnHeader))