import React, { Component } from 'react'
import {  showNotification, translate } from 'react-admin'
import { connect } from 'react-redux'
import compose from 'recompose/compose'
import TrackingTypeModal from './TrackingTypeModal'
import { onLoadWeeks, onLoadPlanning } from '../../../../actions/publicationTracking'
import { withStyles } from '@material-ui/core/styles'


const styles = theme => ({
	importButton: {
		margin: theme.spacing(1),
		marginTop : '0px',
		backgroundColor : '#288DB1',
		top: '5px',
		width : '150px',
		maxHeight: '35px',
		fontSize: '0.75rem',
		lineHeight: '1.2em',
		'&:hover': {
			background: '#1c627b'
		}
	},
 
	rightIcon: {
		marginLeft: theme.spacing(1),
	},
	tooltip:{
		backgroundColor:'black'
	},
	
})
 
class TrackingsType extends Component{
	constructor(props) {
		super(props)
		this.state = {
			modalOpen : false,
			isProcessLoaderOpen : false,
			isOperationFinished : false,
			resultText : null
		}
	}

	handleButtonClick = () => {
		this.setState({
			modalOpen : true
		})
	}

	handleCloseProgressBar = () => {
		this.setState({
			isProcessLoaderOpen: false,
			modalOpen : false
		})
	}

	handleClose = () => {
		this.setState({modalOpen: false})
		window.location.reload();
	}

	render(){
		const { translate } = this.props
		const modalContent = {
			disagreeButtonText : translate('ra.action.close'),
			agreeButtonText: 'Valider'
		}
		// const defaultProps = {
		// 	color: 'secondary',
		// 	children: <MailIcon />,
		//   };
		return(
			
			<React.Fragment>
				
				{/* <Button onClick={this.handleButtonClick} color="primary" variant="contained" className={classes.importButton}>
					{"Paramétrer colonnes"}				
					<SettingsIcon className={classes.rightIcon} />
				</Button> */}
				<TrackingTypeModal {...this.props}
					modalOpen = {this.state.modalOpen} 
					onClose={this.handleClose} 
					modalContent = {modalContent} 
				/>
			</React.Fragment>
		)
	}
}

const mapStateToProps = state => ({
	...state,
	weekNumber: state.reducer.publicationTracking.weekNumber !==null ? state.reducer.publicationTracking.weekNumber : 16,
	yearNumber: state.reducer.publicationTracking.yearNumber !==null ? state.reducer.publicationTracking.yearNumber : 2020
})

const mapDispatchToProps = ({
	showNotification,
	onLoadWeeks,
	onLoadPlanning
})

const enhance = compose(
	connect(mapStateToProps, mapDispatchToProps),
	withStyles(styles)
)

export default translate(enhance(TrackingsType))