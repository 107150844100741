import React from 'react'
import PropTypes from 'prop-types'
import get from 'lodash/get'
import pure from 'recompose/pure'
import Typography from '@material-ui/core/Typography'
import sanitizeRestProps from './sanitizeRestProps'
import { LinkListItem } from '../lists'
import ListItem from '@material-ui/core/ListItem'
import { connect } from 'react-redux'
import compose from 'recompose/compose'
import { getTextFromProps, switchLabelProps, removeTags } from '../../../tools/xmlDocParser'

let recordSource = ''

const XMLField = ({ className, label, source, stripTags, idList, idProgramme = false, data, record = {}, ...rest }) => {
	recordSource = get(record, source)
	const parser = new DOMParser()
	const xmlDoc = parser.parseFromString(recordSource,'text/xml')
	switch (label) {
	case 'Lieu de naissance':
		recordSource = getTextFromProps('BIRTHPLACE', recordSource)
		break
	case 'Lieu de décès':
		recordSource = getTextFromProps('DEATHPLACE', recordSource)
		break
	case 'Résumé court':
		recordSource = getTextFromProps('OUTLINE', recordSource)
		break
	case 'Résumé moyen':
		recordSource = getTextFromProps('SHORTSYNOPSIS', recordSource)
		break
	case 'Résumé long':
		recordSource = getTextFromProps('SYNOPSIS', recordSource)
		break
	case 'Article de presse':
		recordSource = getTextFromProps('PRESSREVIEWS', recordSource)
		break
	case 'Publication':
		recordSource = getTextFromProps('PRESSREVIEWS', recordSource)
		break
	case 'A propos':
		recordSource = getTextFromProps('EDITOREVIEW', recordSource)
		break
	case 'Source':
		recordSource = getTextFromProps('SOURCE', recordSource)
		break
	case 'Biographie':
		xmlDoc.getElementsByTagName('BIOGRAPHY').length > 0 && xmlDoc.getElementsByTagName('BIOGRAPHY')[0].childNodes[0] !== undefined ?
			recordSource = xmlDoc.getElementsByTagName('BIOGRAPHY')[0].childNodes[0].nodeValue
			: recordSource = null
		if (stripTags) {
			return (
				<Typography
					className={className}
					component="span"
					{...sanitizeRestProps(rest)}
				>
					{removeTags(recordSource)}
				</Typography>
			)
		}

		return (
			<Typography
				className={className}
				component="span"
				{...sanitizeRestProps(rest)}
			>
				<span dangerouslySetInnerHTML={{ __html: recordSource }} />
			</Typography>
		)
	case 'ID Programme':
		const x = xmlDoc.getElementsByTagName('MOVIE')
		if (x.length > 0) {
			return (
				idProgramme ?
					(
						Object.values(x).map(item => {
							if (item.attributes[0].nodeValue === idList) {
								return (
									<span key={item.attributes[0].nodeValue} className={className} {...sanitizeRestProps(rest)}>
										{item.attributes[0].nodeValue}
									</span>
								)
							}
							else
								return null
						})
					)
					: (
						Object.values(x).map(item =>
							<LinkListItem key={item.attributes[0].nodeValue} id={item.attributes[0].nodeValue}>
								<ListItem button>
									<span key={item.attributes[0].nodeValue} className={className} {...sanitizeRestProps(rest)}>
										{item.attributes[0].nodeValue}
									</span>
								</ListItem>
							</LinkListItem>

						)
					)
			)
		} else {
			recordSource = null
		}
		break
	case 'Code Milo':
		recordSource = switchLabelProps('Code Milo', recordSource)
		break
	case 'ALLOCINE_ID':
		recordSource = switchLabelProps('ALLOCINE_ID', recordSource)
		break
	case 'Identifiant IMDb':
		recordSource = switchLabelProps('Identifiant IMDb', recordSource)
		break
	case 'CNC_ID':
		recordSource = switchLabelProps('CNC_ID', recordSource)
		break
	case 'PFVIDEO_ID':
		recordSource = switchLabelProps('PFVIDEO_ID', recordSource)
		break
	case 'ATG_ID':
		recordSource = switchLabelProps('ATG_ID', recordSource)
		break
	case 'Référence Ayant droit':
		recordSource = switchLabelProps('Référence Ayant droit', recordSource)
		break
	case 'NUM_AFF_ANT':
		recordSource = switchLabelProps('NUM_AFF_ANT', recordSource)
		break
	case 'ID_PROG_ANT':
		recordSource = switchLabelProps('ID_PROG_ANT', recordSource)
		break
	default:
		recordSource = null
		break
	}
	return (
		<span className={className} {...sanitizeRestProps(rest)}>
			{recordSource}
		</span>
	)
}

XMLField.propTypes = {
	addLabel: PropTypes.bool,
	basePath: PropTypes.string,
	className: PropTypes.string,
	cellClassName: PropTypes.string,
	headerClassName: PropTypes.string,
	label: PropTypes.string,
	record: PropTypes.object,
	sortBy: PropTypes.string,
	source: PropTypes.string.isRequired,
	stripTags: PropTypes.bool,
}

XMLField.defaultProps = {
	addLabel: true,
	stripTags: false,
}

const mapStateToProps = (state) => ({ idList: state.id })

const enhance = compose(
	connect(
		mapStateToProps,
		null
	),
	pure
)

export default enhance(XMLField)
