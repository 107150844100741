import { ON_CHANGE_SELECTED_WEEK, ON_LOAD_WEEKS, ON_CHANGE_SELECTED_YEAR, ON_LOAD_PLANNING, ON_CHANGE_USER, ON_CHANGE_CATEGORY, ON_CHANGE_GROUP,
	ON_UNLOCK_RESPONSIBLE_UPDATE, ON_UNLOCK_PRIO_UPDATE, ON_UPDATE_RESPONSIBLE_IDS, ON_INITIALIZE_RESPONSIBLE_IDS, 
	ON_UPDATE_RESPONSIBLE_COMPLETED,ON_LOAD_RESPONSIBLE_ROLES, ON_UPDATE_PRIO_PLANNING_IDS,ON_INITIALIZE_PRIO_IDS ,ON_UPDATE_PRIO_COMPLETED,
	ON_CHANGE_TASKTYPE, ON_CHANGE_SEARCH,ON_UPDATE_PLANNING_TRI,ON_CHANGE_TASKGROUP , ON_UPDATE_PREP_STATUS,ON_START_TIMER_COUNT} from '../constants/actions'

export function onChangeWeek(weekNumber) { 
	return { 
		type: ON_CHANGE_SELECTED_WEEK, 
		payload: { 
			weekNumber : weekNumber
		} 
	}
}

export function onChangeYear(yearNumber) { 
	return { 
		type: ON_CHANGE_SELECTED_YEAR, 
		payload: { 
			yearNumber : yearNumber
		} 
	}
}

export function onLoadWeeks(weeks){
	return {
		type: ON_LOAD_WEEKS,
		payload: {
			weeks : weeks
		}
	}
}

export function onLoadPlanning(planningData){
	return {
		type : ON_LOAD_PLANNING,
		payload : {
			planningData : planningData.publicationsplannings,
			publicationStats : planningData.publicationsstats
		}
	}
}

export function onChangeUser(userId){
	return {
		type : ON_CHANGE_USER,
		payload : {
			user : userId
		}
	}
}
export function onChangeTaskType(taskTypeId){
	return {
		type : ON_CHANGE_TASKTYPE,
		payload : {
			taskTypeId : taskTypeId
		}
	}
}
export function onChangeTaskGroup(taskGroupId){
	return {
		type : ON_CHANGE_TASKGROUP,
		payload : {
			taskGroupId : taskGroupId
		}
	}
}
export function onChangeSearch(programTitle){
	return {
		type : ON_CHANGE_SEARCH,
		payload : {
			programTitle : programTitle
		}
	}
}
export function onChangeCategory(categoryId){
	return {
		type : ON_CHANGE_CATEGORY,
		payload : {
			categoryId : categoryId
		}
	}
}
export function onChangeGroup(groupId){
	return {
		type : ON_CHANGE_GROUP,
		payload : {
			group : groupId
		}
	}
}
export function unlockResponsibleUpdate(isUnlocked){
	return {
		type : ON_UNLOCK_RESPONSIBLE_UPDATE,
		payload : {
			isUnlocked: isUnlocked
		}
	}
}
export function updatePlanningTri(isDefaultTri){
	return {
		type : ON_UPDATE_PLANNING_TRI,
		payload : {
			isDefaultTri: isDefaultTri
		}
	}
}
export function updateResponsibleIds(userId, planningId){
	return {
		type : ON_UPDATE_RESPONSIBLE_IDS,
		payload : {
			userId : userId,
			planningId : planningId
		}
	}
}

export function initializeResponsibleIds(){
	return {
		type : ON_INITIALIZE_RESPONSIBLE_IDS
	}
}

export function completeUpdateResponsible(isOperationCompleted){
	return {
		type : ON_UPDATE_RESPONSIBLE_COMPLETED,
		payload : {
			isOperationCompleted : isOperationCompleted
		}
	}
}

export function onLoadResponsibleRoles(userId){
	return {
		type: ON_LOAD_RESPONSIBLE_ROLES,
		payload: {
			userId : userId,
		}
	}
}

export function unlockPrioUpdate(isUnlocked){
	return {
		type : ON_UNLOCK_PRIO_UPDATE,
		payload : {
			isUnlocked: isUnlocked
		}
	}
}

export function updatePrioPlanningIds(prio, planningId){
	return {
		type : ON_UPDATE_PRIO_PLANNING_IDS,
		payload : {
			prio : prio,
			planningId : planningId
		}
	}
}

export function initializePrioIds(){
	return {
		type : ON_INITIALIZE_PRIO_IDS
	}
}

export function completeUpdatePrio(isOperationCompleted){
	return {
		type : ON_UPDATE_PRIO_COMPLETED,
		payload : {
			isOperationCompleted : isOperationCompleted
		}
	}
}

export function updatePrepStaus(isprepStatus){
	return {
		type : ON_UPDATE_PREP_STATUS,
		payload : {
			prepStatus : isprepStatus,
 		}
	}
}
export function updateTimerCount(startCount){
	return {
		type : ON_START_TIMER_COUNT,
		payload : {
			startCount : startCount,
 		}
	}
}
