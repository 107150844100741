import { ON_CONFIGURATIONMANAGER_SWITCH_BUTTON } from '../constants/actions'

const switchButtonInfo = {
	checkedB: undefined
}

const configurationManagerReducer = (state = switchButtonInfo , action) => {
	if (action.type === ON_CONFIGURATIONMANAGER_SWITCH_BUTTON) {
		const { enableSwitchProcess, changeJobStatus } = action.payload.ownProps
		if(enableSwitchProcess(action.payload.ownProps) === true){
			changeJobStatus(action.payload.ownProps)
			return {
				...state,
				checkedB: action.payload.event.target.checked
			}
		}
	}
	return state
}

export default configurationManagerReducer