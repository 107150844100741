import React, { Fragment, Component } from 'react'
import { TextField, DateField, FunctionField } from 'react-admin'
import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import Typography from '@material-ui/core/Typography'
import { SimpleChildList } from '../../common/lists'
import { connect } from 'react-redux'
import compose from 'recompose/compose'
import Button from '@material-ui/core/Button'
import { CheckboxField, ContactField, ListChildField, ExtendedTextField } from '../../common/fields'
import { withStyles } from '@material-ui/core/styles'

const styles = theme =>   ({
	cardPaddingMargin: {
		padding: 20,
		margin: 20,
		flex: '1 auto'
	},
	cardDiv: {
		flex: '1 auto'
	},
	flexContent: {
		display: 'flex',
		alignItems: 'flex-start'
	}
})

const FilenameField = ({ record = {} }) => <Typography>{record.filename} ({record.id})</Typography>

class ContentsList extends Component {

	render() {
		const { classes, data, ids, basePath, totalItems } = this.props

		return (
			<div className={classes.flexContent}>
				<Card className={classes.cardPaddingMargin}>
					<CardContent>
						<Typography variant="title">{totalItems} contenus disponibles :</Typography>
					</CardContent>
					<SimpleChildList record={data} idsReducer={ids} basePath={basePath} edit>
						<FilenameField label="fields." source="filename" />
					</SimpleChildList>
				</Card>
				<Card className={classes.cardPaddingMargin}>
					<CardContent>
						<Typography variant="title">Détails du contenu</Typography>
					</CardContent>
					<ListChildField showHide={true} record={data} idsReducer={ids}>
						<TextField label="fields.movieid" source="movieid" />
						<TextField label="fields.id" source="id" />
						<FunctionField
							label="Audio"
							render={
								record => {
									const color = 'secondary'

									return (
										<Fragment>
											<Button variant="contained" color={color} disabled={record.langid === 2 && record.subtitlesid === 0 ? false : true}>VF</Button>
											<Button variant="contained" color={color} disabled={record.langid === 1 && record.subtitlesid === 0 ? false : true}>VO</Button>
											<Button variant="contained" color={color} disabled={record.langid === 1 && record.subtitlesid === 2 ? false : true}>VOST</Button>
											<Button variant="contained" color={color} disabled={record.langid === 2 && record.subtitlesid === 2 ? false : true}>VOST/VF</Button>
										</Fragment>
									)
								}
							}
						/>
						<CheckboxField label="fields.multilanguage" source="multilanguage" />
						<ExtendedTextField label="fields.typeid" source="typeid" domainid={20} />
						<ExtendedTextField label="fields.profileid" source="profileid" profileid />
						<TextField label="fields.filename" source="filename" />
						<TextField label="fields.sourcename" source="sourcename" />
						<TextField label="fields.orginalname" source="orginalname" />
						<ExtendedTextField label="fields.drmprovid" source="drmprovid" domainid={49} />
						<CheckboxField label="fields.usecommondrm" source="usecommondrm" />
					</ListChildField>
				</Card>
				<div className={classes.cardDiv}>
					<Card className={classes.cardPaddingMargin}>
						<CardContent>
							<Typography variant="title">Informations complémentaires</Typography>
						</CardContent>
						<ListChildField showHide={true} record={data} idsReducer={ids}>
							<TextField label="fields.duration" source="duration" />
							<TextField source="convertedDuration" label="fields.duration" />
							<TextField label="fields.size" source="size" />
							<TextField source="convertedSize" label="fields.size" />
							<ExtendedTextField label="fields.stateid" source="stateid" domainid={86} />
							<TextField label="fields.reference" source="reference" />
							<TextField label="fields.storagepathname" source="storagepathname" />
						</ListChildField>
					</Card>
					<Card className={classes.cardPaddingMargin}>
						<CardContent>
							<Typography variant="title">Gestion</Typography>
						</CardContent>
						<ListChildField showHide={true} record={data} idsReducer={ids}>
							<DateField showTime={true} label="fields.createdon" source="createdon" />
							<ContactField label="fields.createdby" source="createdby" />
							<DateField showTime={true} label="fields.modifiedon" source="modifiedon" />
							<ContactField label="fields.modifiedby" source="modifiedby" />
						</ListChildField>
					</Card>
				</div>
			</div>
		)
	}
}

const mapStateToProps = (state, props) => ({ totalItems: state.admin.resources[props.resource].list.total })

const enhance = compose(
	connect(mapStateToProps),
	withStyles(styles)
)

export default enhance(ContentsList)
