import React from 'react'
import { Datagrid, DateField, Filter, List, Show, Edit, Create, SimpleForm } from 'react-admin'
import { IntegerField, DomainItemField, ExtendedTextField, MovieField } from '../common/fields'
import { DomainsType } from '../../constants/types'
import { Icon, Avatar, Card, CardContent, Typography, CardHeader } from '@material-ui/core'
import { DoneIcon, WatchLaterIcon, PlayIcon, ErrorIcon, CancelIcon, CommentsIcon } from '../../constants/icons'
import { getValue } from '../../tools/utils'
import { DomainsItems } from '../../tools'
import moment from 'moment'
import { MyListActions, MyShowActions, MyEditActions, MyCreateActions } from '../common/actions'
import CardShow from './CardShow'
import CardEdit from './CardEdit'
import CardCreate from './CardCreate'
import { DatePickerCanal } from '../common/inputs'
import {withStyles }  from '@material-ui/core/styles'

const MoviesprioritiesFilter = props => (
	<Filter {...props}>
		<DatePickerCanal source="startdate" label="fields.release" dateOnly alwaysOn shrink={false} />
	</Filter>
)

const styles = ({
	root: {
		display: 'flex',
		flexWrap: 'nowrap',
		alignItems: 'center'
	}
})

// eslint-disable-next-line react/prop-types
const StatusIcon = ({ source, classes, style, ...props }) => {
	const stateid = getValue(props, source)
	if (!stateid)
		return null

	let icon
	let bgColor
	if (0 === stateid) { // pending
		bgColor = 'gray'
		icon = <WatchLaterIcon />
	}
	else if (1 === stateid) { // inprogress
		bgColor = '#0000FF'
		icon = <PlayIcon />
	}
	else if (2 === stateid) { // done
		bgColor = '#008000'
		icon = <DoneIcon />
	}
	else if (3 === stateid) { // error
		bgColor = '#FF0000'
		icon = <ErrorIcon />
	}
	else if (4 === stateid) { // canceled
		bgColor = '#FFA500'
		icon = <CancelIcon />
	}
	else if (5 === stateid) { // standby
		bgColor = '#FFA500'
		icon = <WatchLaterIcon />
	}
	return (
		<div className={classes.root} style={style}>
			<Avatar style={{ backgroundColor: bgColor }}>
				<Icon>
					{icon}
				</Icon>
			</Avatar>
			<span style={{ marginLeft: '5px' }}>
				{DomainsItems.getLabel(DomainsType.JOBSTATE, stateid)}
			</span>
		</div>
	)
}

export const MoviesprioritiesList = withStyles(styles)(({ ...props }) => (
	<List
		title="titles.moviesprioritiesTopPrio"
		filters={<MoviesprioritiesFilter />}
		perPage={20}
		actions={<MyListActions />}
		bulkActionButtons={false}
		filterDefaultValues={null}
		sort={{}}
		{...props}
	>
		<Datagrid rowClick="show" expand={<ListExpand />}>
			<IntegerField source="week" label="fields.week" sortable={false} />
			<IntegerField source="year" label="fields.year" sortable={false} />
			<IntegerField source="numAffaireAntenne" label="fields.numaffaireantenne" sortable={false} />
			<DateField source="contract.startDate" label="fields.release" options={{ weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' }} style={{ minWidth: '150px' }} sortable={false} />
			<MovieField addId={true} addLabel={true} addAvatar={true} sourceId="movieid" sourceLabel="title" label="labels.movies" size={40} isLink={true} style={{ minWidth: '200px' }} />
			<DomainItemField source="contract.modeCommId" label="labels.modecomm" domainId={DomainsType.CONTRACTCOMMERCIALIZATIONMODE} withId={false} withLabel={true} sortable={false} />
			<ExtendedTextField source="contract.companyId" label="labels.haveRight" companyid listonly style={{ minWidth: '200px' }} sortable={false} />
			<StatusIcon source="jobEdgar.stateId" label="labels.status" {...props} sortable={false} />
		</Datagrid>
	</List>
))

// eslint-disable-next-line react/prop-types
const ListExpand = ({ record }) => {
	if (!record || !record.jobEdgar)
		return null
	return (
		<Card>
			<CardHeader title="Commentaires" avatar={<CommentsIcon />} />
			<CardContent>
				<Typography component="p">
					{record.jobEdgar.comment}
				</Typography>
			</CardContent>
		</Card>
	)
}

export const MoviesprioritiesShow = props => (
	<Show title=" " actions={<MyShowActions />} {...props}>
		<CardShow />
	</Show>
)

export const MoviesprioritiesEdit = props => (
	<Edit title=" " undoable={false} actions={<MyEditActions />} {...props}>
		<SimpleForm redirect="show">
			<CardEdit />
		</SimpleForm>
	</Edit>
)

export const MoviesprioritiesCreate = props => (
	<Create title=" " undoable={false} actions={<MyCreateActions />} {...props}>
		<SimpleForm redirect="show">
			<CardCreate />
		</SimpleForm>
	</Create>
)

export const Title = ({ record } = this.props) => {
	let week = String(record.week)
	const year = String(record.year)
	const weekPadded = week.length === 1 ? '0' + week : week

	const date = moment(year + weekPadded, 'YYYYWW')
	const from = date.startOf('week').isoWeekday(1)

	// TODO : Fix that because it doens't works, so we need a ugly trick (see below)
	//const to = date.endOf('week').isoWeekday(1)
	const tmpWeek = (Number(week) >= 1 && Number(week) <= 51) ? String((Number(week) + 1)) : '1'
	const tmpWeekPadded = tmpWeek.length === 1 ? '0' + tmpWeek : tmpWeek
	const to = moment(year + tmpWeekPadded, 'YYYYWW').endOf('week').isoWeekday(1).subtract(1, 'days')
	// END of TODO

	return `Semaine ${weekPadded}, du ${from.format('LL').toString()} au ${to.format('LL').toString()}`
}
