import { required, minValue, number } from 'react-admin'

// required(message) if the field is mandatory
// minValue(min, message) to specify a minimum value for integers
// maxValue(max, message) to specify a maximum value for integers
// minLength(min, message) to specify a minimum length for strings
// maxLength(max, message) to specify a maximum length for strings
// number(message) to check that the input is a valid number
// email(message) to check that the input is a valid email address
// regex(pattern, message) to validate that the input matches a regex
// choices(list, message) to validate that the input is within a given list

export const validateRequired = [required()]
export const validateRequiredMovieid = [required(), number(), minValue(1)]
