import Api from './Api'
import { stringify } from 'query-string'

const AlbumsPictures = {
	getById: (albumid) =>
		Api.Requests.getAll(`/Albumspictures/albumid=${albumid}`),
	RemoveImage : (imageId) =>
		Api.Requests.delete(`/AlbumspicturesSpecifique/DeleteAlbumPicture?pictureId=${imageId}`),
	SaveImageAssistant : (query, formData) =>
		Api.Requests.postWithFile(`/PicturePivot/Pivot?${stringify(query)}`, formData ),
	SaveImage : (albumpicture, formData) =>
		Api.Requests.postWithBody(`/AlbumspicturesSpecifique/SaveAlbumPicture`,albumpicture,  formData )
 
}

export default AlbumsPictures