import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import { Manager, Target, Popper } from 'react-popper'
import { getResources } from 'react-admin'
import Button from '@material-ui/core/Button'
import ClickAwayListener from '@material-ui/core/ClickAwayListener'
import Collapse from '@material-ui/core/Collapse'
import Paper from '@material-ui/core/Paper'
import Portal from '@material-ui/core/Portal'
import MenuList from '@material-ui/core/MenuList'
import MenuItemLink from 'ra-ui-materialui/lib/layout/MenuItemLink'
//import { MenuItemLink } from '../layout'
import { connect } from 'react-redux'
import { withStyles } from '@material-ui/core/styles'

const styles = theme => ({
	root: {
		display: 'flex',
	},
	paper: {
		marginRight: theme.spacing(2) ,
	},
	popperClose: {
		pointerEvents: 'none',
	},
})

class MenuListComposition extends React.Component {
	constructor(props) {
		super(props)
		this.state = {
			open: false,
		}
	}

	handleToggle = () => {
		this.setState({ open: !this.state.open })
	};

	handleClose = event => {
		if (this.target.contains(event.target)) {
			return
		}

		this.setState({ open: false })
	};

	getItem = (item, key, onClick, resources) => {
		let internalResource
		if (key !== '') {
			internalResource = resources.find(r => r.name === key)
			if (!internalResource)
				return null
		}

		const icon = internalResource.icon ? React.createElement(internalResource.icon, null) : null

		if (item.label !== undefined)
			return <MenuItemLink key={key} onClick={this.handleClose} to={`/${item.target}`} primaryText={item.label} leftIcon={icon} exact />
		else
			return <MenuItemLink key={key} onClick={this.handleClose} to={`/${item}`} primaryText={item} leftIcon={icon} exact />
	}

	render() {
		const { classes, onClick, resources } = this.props
		const open = this.state.open
		return (
			<div className={classes.root}>
				<Manager className={classes.root}>
					<Target className={classes.root}>
						<div
							ref={node => {
								this.target = node
							}}
							className={classes.root}
						>
							<Button
								aria-owns={open ? 'menu-list-collapse' : null}
								aria-haspopup='true'
								onClick={this.handleToggle}
								style={{ color: '#fff' }}
							>
								{this.props.menuname}
							</Button>
						</div>
					</Target>
					<Portal>
						<Popper
							placement="bottom-start"
							eventsEnabled={open}
							className={classNames({ [classes.popperClose]: !open })}
							style={{ zIndex: 1 }}
						>
							<ClickAwayListener onClickAway={this.handleClose}>
								<Collapse in={open} id="menu-list-collapse" style={{ transformOrigin: '0 0 0' }}>
									<Paper style={{ margin: 3 }}>
										<MenuList role="menu">
											{
												this.props.menunameitem.map((item, key) => {
													return this.getItem(item, item.target, onClick, resources)
												})
											}
										</MenuList>
									</Paper>
								</Collapse>
							</ClickAwayListener>
						</Popper>
					</Portal>
				</Manager>
			</div>
		)
	}
}

const mapStateToProps = state => ({
	resources: getResources(state),
})

MenuListComposition.propTypes = {
	classes: PropTypes.object.isRequired,
}

export default connect(mapStateToProps, null)(withStyles(styles)(MenuListComposition))
//export default (withStyles(styles)(MenuListComposition))