import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import { Chip } from '@material-ui/core'
const styles =   ({
	root: {
		color: '#288DB1',
		'&$checked': {
			color: '#288DB1',
		},
	},
	checked: {},
	customFormControLabel: {
		cursor: 'pointer',
		display: 'inline-flex',
		alignItems: 'center',
		marginLeft: '-14px',
		marginRight: '4px',
		verticalAlign: 'middle'
		// -webkit-tap-highlight-color: transparent,
	},
	customLabelClass: {
		marginLeft: '-8px',
		marginRight: '5px',
		fontSize: '13px'
	}
})

class CustomCheckBoxItem extends Component {
	constructor(props) {
		super(props)
		this.state = {
		}
	}

	handleCheckboxChange = (event) => {
		const { checkboxChangeCallback ,checked } = this.props
		console.log(checked)
		checkboxChangeCallback(!checked)
	}

	render() {
		const { checkboxLabel, checked } = this.props
		return (
			<Chip
			onClick ={this.handleCheckboxChange}
			onDelete={checked ? this.handleCheckboxChange : null}
			color={checked ? "primary" : "default"}
			variant={checked ? "default" : "outlined"}
			//deleteIcon={checked ?<Close /> :<Done />}
			label={checkboxLabel}
			style={{margin: '.5em'}}
			//size="small"
		  />
			
		)
	}
}

export default withStyles(styles)(CustomCheckBoxItem)

CustomCheckBoxItem.propTypes = {
	classes: PropTypes.object.isRequired,
	checkboxLabel: PropTypes.string.isRequired,
	checkboxValue: PropTypes.string.isRequired,
	checked: PropTypes.bool.isRequired,
	handleCheckboxChange: PropTypes.func,
	checkboxChangeCallback: PropTypes.func
}
CustomCheckBoxItem.defaultProps = {
	handleCheckboxChange: null,
}