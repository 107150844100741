import React, { Component } from 'react'
import PropTypes from 'prop-types'
import SmallIconButton from './SmallIconButton'
import { MovieIcon } from '../../../constants/icons'


class MovieIconButton extends Component {
	render() {
		const { id, classes, peopleid } = this.props
		return (
			peopleid ?
				<SmallIconButton peopleid={peopleid}  classes={classes} label='pluralizations.movies' basePath='/Peoplesmovies'>
					<MovieIcon />
				</SmallIconButton> :
				<SmallIconButton id={id} classes={classes} label='pluralizations.movies' basePath='/Companiesmovies'>
					<MovieIcon />
				</SmallIconButton>

		)
	}
}

MovieIconButton.propTypes = {
	id: PropTypes.number,
	peopleid: PropTypes.number,
	classes: PropTypes.object.isRequired,
}

export default MovieIconButton