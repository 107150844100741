import CacheItems from './CacheItems'
import { DomainsItems as request } from '../requests'

class DomainsItems extends CacheItems {
	constructor(cacheKey = 'dataDomainsItems') {
		super(cacheKey, request.getAll, false)
	}

	static get instance() {
		if (!this.singleton) {
			this.singleton = new DomainsItems()
			this.singleton.logger.debug(`${this.__proto__.name} > new instance`)
		}

		return this.singleton
	}

	// --------------------
	// Custom methods below
	// --------------------

	// Get all domains items for one domain
	getItems = (domainid) => {
		const key = `${this.cacheKey}:${domainid}`
		let items = JSON.parse(this.storage.getItem(key))

		// No domains items for one domain found in cache
		if (null == items) {
			const allItems = this.getAllItems()
			if (null != allItems && allItems.length > 0) {
				items = Object.values(allItems).filter(item => item.domainid === domainid)
				if (null != items)
					this.storage.setItem(key, JSON.stringify(items))
			}
		}

		return items
	}

	// Get one domain item
	getItem = (domainid, itemid) => {
		if (domainid && itemid) {
			const data = this.getItems(domainid)
			if (null != data && data.length > 0) {
				return Object.values(data).find(item => item.itemid === itemid)
			}
		}
		return null
	}

	// Get label
	getLabel = (domainid, itemid) => {
		if (itemid === -1) return 'Tous (-1)'
		const item = this.getItem(domainid, itemid)
		const label = null != item ? item.label : ''
		return label.trim()
	}

	// Get formatted label 
	getFormattedLabel = (domainid, itemid) => {
		//console.log(domainid,itemid)
		if (domainid && itemid) {
			const label = this.getLabel(domainid, itemid)
			return `${label} (${itemid})`.trim()
		}
		return ''
	}

	// Get formatted list to use in selects
	getSelectList = (domainid, withAll = false, withDisabled = false) => {
		let list = this.getItems(domainid)
		if (!withDisabled)
			list = list.filter(f => f.disabled === false)
		return this.getGenericItemsList(list, 'itemid', 'label', withAll)
	}

	load = (skipCache = false) => {
		if (!this.exists() || skipCache) {
			return this.request()
		}
	}
}

const DomainsItemsInstance = DomainsItems.instance

export default DomainsItemsInstance
