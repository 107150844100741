import React from 'react'
import PropTypes from 'prop-types'
import get from 'lodash/get'
import pure from 'recompose/pure'
import FalseIcon from '@material-ui/icons/Clear'
import TrueIcon from '@material-ui/icons/Done'
import Typography from '@material-ui/core/Typography'
import sanitizeRestProps from './sanitizeRestProps'

export const MyBooleanField = ({ className, source, record = {}, ...rest }) => {
	const value = get(record, source)

	if (value === false || value === 0) {
		return (
			<Typography
				component="span"
				body1="body1"
				className={className}
				{...sanitizeRestProps(rest)}
			>
				<FalseIcon />
			</Typography>
		)
	}

	if (value === true || value === 1) {
		return (
			<Typography
				component="span"
				body1="body1"
				className={className}
				{...sanitizeRestProps(rest)}
			>
				<TrueIcon />
			</Typography>
		)
	}

	return (
		<Typography
			component="span"
			body1="body1"
			className={className}
			{...sanitizeRestProps(rest)}
		/>
	)
}

MyBooleanField.propTypes = {
	addLabel: PropTypes.bool,
	basePath: PropTypes.string,
	className: PropTypes.string,
	cellClassName: PropTypes.string,
	headerClassName: PropTypes.string,
	label: PropTypes.string,
	record: PropTypes.object,
	sortBy: PropTypes.string,
	source: PropTypes.string.isRequired,
}

const PureMyBooleanField = pure(MyBooleanField)

PureMyBooleanField.defaultProps = {
	addLabel: true,
}

export default PureMyBooleanField