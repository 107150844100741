/*eslint no-case-declarations: "error"*/
/*eslint-env es6*/
import { DomainsItems, Logger } from '.'

class PublicationTrackingReferenceData {
	constructor() {
		this.logger = Logger
	}

	static get instance() {
		if (!this.singleton) {
			this.singleton = new PublicationTrackingReferenceData()
			if(this.singleton.logger)
			this.singleton.logger.debug(`${this.__proto__.name} > new instance`)
		}
		return this.singleton
	}

	getTasksGroups = () => {
		return Object.values(DomainsItems.getItems(130))
	}
	getTasksTypes = () => {
		return Object.values(DomainsItems.getItems(131))
	}
	getVersions = () => {
		return Object.values(DomainsItems.getItems(132))
	}
	getCategoryGroups = () => {
		return Object.values(DomainsItems.getItems(133))
	}
	getCategories = () => {
		return Object.values(DomainsItems.getItems(134))
	}
	getFormats = () => {
		return Object.values(DomainsItems.getItems(135))
	}
	getTrackingGroups = () => {
		return Object.values(DomainsItems.getItems(136))
	}
	getStatusTypes = () => {
		return Object.values(DomainsItems.getItems(137))
	}
	getCommentLevel = () => {
		return Object.values(DomainsItems.getItems(138))
	}

	getStatusTypePrepa = () => {
		return Object.values(DomainsItems.getItems(141))
	}
	getStatusTypeFront = () => {
		return Object.values(DomainsItems.getItems(142))
	}
	getStatusTypeBug = () => {
		return Object.values(DomainsItems.getItems(143))
	}
	getTrackingTypeGroups = () => {
		return Object.values(DomainsItems.getItems(144))
	}
	getTrackingType = () => {
		return Object.values(DomainsItems.getItems(145))
	}
	getCellInformation(referencedataid, type) {
 		let information = {
			color: '',
			label: ''
		}
		if (referencedataid !== null) {
			switch (type) {
			case 'category':
				{
					const category = this.getCategories().find(r => r.itemid === referencedataid)
					if (category !== undefined) {
						information.color = category.color
						information.label = category.label
						break
					}
				}
				break
			case 'taskType':
				{
					const taskType = this.getTasksTypes().find(r => r.itemid === referencedataid)
 					if (taskType !== undefined) {
						information.color = taskType.color
						information.label = taskType.label
						break
					}
				}
				break
			case 'version':
				{
					const version = this.getVersions().find(r => r.itemid === referencedataid)
					if (version !== undefined) {
						information.color = version.color
						information.label = version.label
						break
					}
				}
				break
			case 'format':
				{
					const format = this.getFormats().find(r => r.itemid === referencedataid)
					if (format !== undefined) {
						information.color = format.color
						information.label = format.label
						break
					}
				}
				break
			case 'PropStatus':
					{
						const propstatus = this.getStatusTypes().find(r => r.itemid === referencedataid)
						if (propstatus !== undefined) {
							information.color = propstatus.color
							information.label = propstatus.label
							break
						}
					}
					break
			default:
				break
			}
		}
		return information
	}
}

const PublicationTrackingReferenceDataInstance = PublicationTrackingReferenceData.instance

export default PublicationTrackingReferenceDataInstance