import React, { Component } from 'react'
import { translate } from 'react-admin'
import { connect } from 'react-redux'
import compose from 'recompose/compose'
import Button from '@material-ui/core/Button'
import EmailIcon from '@material-ui/icons/Email';
import SelectEmailModel from './SelectEmailModel'
import Snackbar from '@material-ui/core/Snackbar';
import IconButton from '@material-ui/core/IconButton';
import { CloseIcon } from '../../../constants/icons'
import { withStyles } from '@material-ui/core/styles'

const styles = theme =>  ({
	button: {
		margin: theme.spacing(1),
		marginTop: '0px',
		backgroundColor : '#288DB1',
		width : '150px',
		maxHeight: '35px',
		fontSize: '0.75rem',
		top: '5px',
		lineHeight: '1.2em',
		'&:hover': {
			background: '#1c627b'
		}
	},
	snackbarStyleViaContentProps:{
		position: 'fixed',
		top: '.5em',
		zIndex: 10000,
		width: '20em',
		height: '4em',
		//right: '-9.5em',
		backgroundColor : 'green',
		transform: 'translate(-50%)',
	}
})

class SendEmail extends Component{
	constructor(props) {
		super(props)
		this.state = {
			modalOpen : false,
			isProcessLoaderOpen : false,
			isOperationFinished : false,
			resultText : null,
			showAlert : false
		}
	}

	handleImportButtonClick = () => {
		this.setState({
			modalOpen : true
		})
	}

	handleClose = () => {
		this.setState({modalOpen: false})
	}
	handleCloseAlert = (event, reason) => {
		if (reason === 'clickaway') {
		  return;
		}
		this.setState({showAlert : false})
	  };
	  handleopenAlert =() =>{
		this.setState({showAlert : true})
	  }
	render(){
		const { translate, classes,weekNumber, yearNumber } = this.props
		const modalContent = {
			disagreeButtonText : translate('ra.action.close'),
			agreeButtonText: 'Valider'
		}
		return(
			
			<React.Fragment>
				
				<Button onClick={this.handleImportButtonClick} color="primary" variant="contained" className={classes.button}>
					Envoyer rapport	
					<EmailIcon className={classes.rightIcon} />
				</Button>
				<div>
								<Snackbar
									anchorOrigin={{
									vertical: 'top',
									horizontal: 'right',
									}}
									open={this.state.showAlert}
									autoHideDuration={3000}
									onClose={this.handleCloseAlert}
									message="Mail en cours d'envoi !"
									ContentProps={{
										"aria-describedby": "message-id",
										className: classes.snackbarStyleViaContentProps
									  }}
									action={
									<React.Fragment>
										<IconButton size="small" aria-label="close" color="inherit" onClick={this.handleCloseAlert}>
										<CloseIcon fontSize="small" />
										</IconButton>
									</React.Fragment>
									}
								/>
								</div>
				<SelectEmailModel {...this.props}
					modalOpen = {this.state.modalOpen} 
					onClose={this.handleClose} 
					modalContent = {modalContent} 
					weekNumber={weekNumber} 
					yearNumber={ yearNumber}
					onAlert ={this.handleopenAlert}
				/>
			</React.Fragment>
		)
	}
}

const mapStateToProps = state => ({
	...state,
	weekNumber: state.reducer.publicationTracking.weekNumber !==null ? state.reducer.publicationTracking.weekNumber : 16,
	yearNumber: state.reducer.publicationTracking.yearNumber !==null ? state.reducer.publicationTracking.yearNumber : 2020,
	newProgramContactId : state.reducer.publicationTracking.newProgramContactId
})

const mapDispatchToProps = ({
	// showNotification,
	// onLoadWeeks,
	// onLoadPlanning
})

const enhance = compose(
	connect(mapStateToProps, mapDispatchToProps),
	withStyles(styles)
)


export default translate(enhance(SendEmail))