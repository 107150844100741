import React, { Component } from 'react'
import { Publicationstracking } from '../../../requests'
import { Contacts as ContactsTools , PublicationTrackingReferenceData} from '../../../tools'
import {  showNotification, translate } from 'react-admin'
import { connect } from 'react-redux'
import compose from 'recompose/compose'
import Button from '@material-ui/core/Button'
import { Links } from '../../../tools'
import { onLoadWeeks, onLoadPlanning } from '../../../actions/publicationTracking'
import { Link } from 'react-router-dom'
import { EventNoteIcon } from '../../../constants/icons'
import { withStyles } from '@material-ui/core/styles'


const styles = theme =>  ({
	importButton: {
		margin: theme.spacing(1),
		marginTop : '0px',
		top: '5px',
		width : '130px',
		maxHeight: '35px',
		fontSize: '0.75rem',
		backgroundColor : '#288DB1',
		lineHeight: '1.2em',
		'&:hover': {
			background: '#1c627b'
		}
	},
	rightIcon: {
		marginLeft: theme.spacing(1),
	},
})

class PlanningLauncher extends Component{
	constructor(props) {
		super(props)
		this.state = {
			modalOpen : false,
			isProcessLoaderOpen : false,
			isOperationFinished : false,
			resultText : null
		}
	}

	handleImportButtonClick = () => {
		this.setState({
			modalOpen : true
		})
	}

	handleCloseProgressBar = () => {
		this.setState({
			isProcessLoaderOpen: false,
			modalOpen : false
		})
	}

	handleClose = () => {
		this.setState({modalOpen: false})
	}

	buildPlanningList = (jsonData, onLoadPlanning) => {
		const trackingData = jsonData
		let planningToBeSaved = {
			publicationsplannings : trackingData.publicationsplannings,
			publicationsstats : []
		}
		const users = ContactsTools.getContactsByGroupid(160)
		let distribution = {
			pgm: trackingData.publicationsplannings.filter(x => x.publications !== null && (x.taskTypeId === 14 || x.taskTypeId === 16 || x.taskTypeId === 17 || x.taskTypeId === 27 || x.taskTypeId === 28)),
			ouv: trackingData.publicationsplannings.filter(x => x.publications !== null && (x.taskTypeId === 21 || x.taskTypeId === 22)),
			susp: trackingData.publicationsplannings.filter(x => x.publications !== null && (x.taskTypeId === 11 || x.taskTypeId === 12 || x.taskTypeId === 13 || x.taskTypeId === 24 || x.taskTypeId === 25)),
			chgtprix: trackingData.publicationsplannings.filter(x => x.publications !== null && (x.taskTypeId && PublicationTrackingReferenceData.getTasksTypes().find(r => r.itemid === x.taskTypeId).taskGroupDomainItemId  === 7 )),
			//regulprix: trackingData.publicationsplannings.filter(x => x.publications !== null && (x.taskTypeId >= 31 && x.taskTypeId <= 46)),
			autre: trackingData.publicationsplannings.filter(x => x.publications !== null && ((x.taskTypeId >= 1 && x.taskTypeId <= 3) || x.taskTypeId === null)),

		}

		if (trackingData.publicationsstats !== null && trackingData.publicationsstats.length !== 0) {
			trackingData.publicationsstats.forEach(item => {
				const currentUser = users.find(u => u.id === item.contactId)

				const stat = {
					contactName: currentUser ? currentUser.gsm : '',
					publicationsCount: item.publicationsCount,
					pgm: distribution.pgm !== undefined ? distribution.pgm.filter(x => x.publications.contactId === item.contactId).length : 0,
					ouv: distribution.ouv !== undefined ? distribution.ouv.filter(x => x.publications.contactId === item.contactId).length : 0,
					susp: distribution.susp !== undefined ? distribution.susp.filter(x => x.publications.contactId === item.contactId).length : 0,
					chgtprix: distribution.chgtprix !== undefined ? distribution.chgtprix.filter(x => x.publications.contactId === item.contactId).length : 0,
					//regulprix: distribution.regulprix !== undefined ? distribution.regulprix.filter(x => x.publications.contactId === item.contactId).length : 0,
					autre: distribution.autre !== undefined ? distribution.autre.filter(x => x.publications.contactId === item.contactId).length : 0,
					publicationPercent: Number((item.publicationsCount * 100 / trackingData.publicationsplannings.length).toFixed(1))
				}
				planningToBeSaved.publicationsstats.push(stat)
			})
		}
		onLoadPlanning(planningToBeSaved)
	}

	handleSubmit = (processParameter) => {
		const { weekNumber, yearNumber,onLoadPlanning } = this.props
		this.setState({ 
			isProcessLoaderOpen : true,
			isOperationFinished : false
		})
		Publicationstracking.launchManualExcelImport(processParameter.dateinterval, processParameter.startdate, processParameter.enddate).then((jsonDataResult)=>{
			Publicationstracking.getPlanning(Number.parseInt(`${yearNumber.toString()}${weekNumber.toString()}`))
				.then((json)=>{
					this.buildPlanningList(json.data, onLoadPlanning)
				})
			// Publicationstracking.getWeeks().then((json)=>{
			// 	onLoadWeeks(json.data)
			// })
			this.setState({ 
				isOperationFinished : true,
				resultText : {
					rowCount : jsonDataResult.data.processedRowsCount,
					newRow : jsonDataResult.data.newRowsCount,
					updatedRow : jsonDataResult.data.updatedRowsCount,
					deletedRow : jsonDataResult.data.deletedRowsCount,
					errorCount : jsonDataResult.data.errorCount
				}
			})
		})
	}

	render(){
		const {  classes } = this.props
		const to = Links.PublicationPlanningLink()
		return(
			<React.Fragment>
				{/* {localStorage.getItem('counter') === '0' ? (<CustomAlert libelle="Changement de donnée en cours" severity="success" open={this.state.showSave} setOpen={this.handleCloseAlert} />) : null} */}

				{/* <CircularStatic></CircularStatic> */}
				<Button component = {Link} color="primary" variant="contained" className={classes.importButton} to = {to}>
					Planning
					<EventNoteIcon className={classes.rightIcon} />
				</Button>
			</React.Fragment>
		)
	}
}

const mapStateToProps = state => ({
	...state,
	weekNumber: state.reducer.publicationTracking.weekNumber !==null ? state.reducer.publicationTracking.weekNumber : 16,
	yearNumber: state.reducer.publicationTracking.yearNumber !==null ? state.reducer.publicationTracking.yearNumber : 2020
})

const mapDispatchToProps = ({
	showNotification,
	onLoadWeeks,
	onLoadPlanning
})

const enhance = compose(
	connect(mapStateToProps, mapDispatchToProps),
	withStyles(styles)
)

export default translate(enhance(PlanningLauncher))