import React, { useState, useEffect } from 'react'
import * as metadata from '../../metadata.json'
import * as metadataPreprod from '../../metadata-preprod.json'
import { CopyrightIcon } from '../../constants/icons'
import { Logger } from '../../tools/index.js'
import { Tooltip } from '@material-ui/core'
import Typography from '@material-ui/core/Typography'
import { withStyles } from '@material-ui/core/styles'
import { Alert } from '@material-ui/lab'
import Button from '@material-ui/core/Button'
import RefreshIcon from '@material-ui/icons/Refresh'

import { AUTH_LOGOUT } from 'react-admin'
import authProvider from '../../authProvider'

const styles = theme => ({
	title: {
		flex: 1,
		textOverflow: 'ellipsis',
		whiteSpace: 'nowrap',
		overflow: 'hidden',
	},
	spacer: {
		flex: 1,
	},
	logo: {
		width: '40px',
		height: '40px',
		filter: 'brightness(0) invert(1)'
	},
	footerLeft: {
		position: 'fixed',
		left: 0,
		bottom: 0,
		zIndex: 100,
		padding: theme.spacing(1),
		//backgroundColor: theme.palette.background.default,
		background: 'linear-gradient(to right, ' + theme.palette.background.default + ' 0%, rgba(0,0,0,0) 85%, rgba(0,0,0,0) 100%)',
		width: '20%',
		textAlign: 'left',
		fontSize: '10px',
	},
	footerRight: {
		position: 'fixed',
		right: 0,
		bottom: 0,
		zIndex: 100,
		padding: theme.spacing(1),
		color:'#000',
		//backgroundColor: theme.palette.background.default,
		background: 'linear-gradient(to left, ' + theme.palette.background.default + ' 0%, rgba(0,0,0,0) 85%, rgba(0,0,0,0) 100%)',
		width: '30%',
		textAlign: 'right',
		fontSize: '12px',
	},
	copyright: {
		fontSize: '10px',
	},
	paper: {
		border: '1px solid',
		padding: theme.spacing(1),
		backgroundColor: theme.palette.background.paper,
	},
	root: {
		width: '100%',
		'& > * + *': {
			marginTop: theme.spacing(2),
		},
	},
	alert: {
		position: 'fixed',
		top: 0,
		zIndex: 10000,
		width: '20em',
		height: '4.5em',
		right: '-9.5em',
		transform: 'translate(-50%)',
	},
	error:{
		position: 'fixed',
		top: '9.5em',
		zIndex: 10000,
		width: '20em',
		height: '4em',
		right: '-9.5em',
		transform: 'translate(-50%)',
	},
	span: {
		top: '50%',
		left: '60%',
		position: 'absolute',
		transform: 'translate(-50%,-50%)',
		width: '80%'
	},
	icon: {
		position: 'absolute',
		top: '50%',
		transform: 'translate(0, -50%)',
		
	},
})

const getBuildInformation = (environment) => {
	let buildInformation = {
		buildVersion: '',
		buildVersionDatetime: ''
	}
	Logger.info(process.env)
	if (environment === 'production') {
		if (process.env.PUBLIC_URL === 'http://hawc.canal-bis.com') {
			if (metadataPreprod) {
				const metadataObject = Object.values(metadataPreprod)
				if (metadataObject[0]) {
					buildInformation.buildVersion = metadataObject[0].buildVersion
					buildInformation.buildVersionDatetime = metadataObject[0].buildDatetime
				}
			}
		}
		else {
			if (metadata) {
				const metadataObject = Object.values(metadata)
				if (metadataObject[0]) {
					buildInformation.buildVersion = metadataObject[0].buildVersion
					buildInformation.buildVersionDatetime = metadataObject[0].buildDatetime
				}
			}
		}

	}
	else {
		if (metadataPreprod) {
			const metadataObject = Object.values(metadataPreprod)
			if (metadataObject[0]) {
				buildInformation.buildVersion = metadataObject[0].buildVersion
				buildInformation.buildVersionDatetime = metadataObject[0].buildDatetime
			}
		}
	}
	return buildInformation
}

const HtmlTooltip = withStyles(theme => ({
	tooltip: {
		backgroundColor: '#f5f5f9',
		color: 'rgba(0, 0, 0, 0.87)',
		maxWidth: 130,
		fontSize: theme.typography.pxToRem(10),
		border: '1px solid #dadde9',
		bottom: '100%',
  		left: '20',
  		marginLeft: '-165px'
	},
}))(Tooltip)

const FooterCopyright = React.forwardRef(function MyComponent(props, ref) {
	//  Spread the props to the underlying DOM element.
	const { classes } = props
	return (
		<div className={classes.footerLeft} {...props} ref={ref}>
			{React.createElement(CopyrightIcon, { style: { fontSize: '11px', marginBottom: '-2px', color: '#000' } })} <em style={{ fontSize: '10px', fontStyle: 'inherit', color: '#000' }}> BACK OFFICE HAWC</em>
		</div>
	)
})
const refreshVersion=()=>{
 
	if(null != localStorage.getItem('RefreshVer_Count')  ){
		localStorage.setItem('RefreshVer_Count',parseInt( localStorage.getItem('RefreshVer_Count')) + 1)
	}
	window.location.reload()
}
const MyFooter = (props) => {
	// eslint-disable-next-line react/prop-types
	const { classes } = props
	const appVersion = {
		buildVersion: '',
		buildVersionDatetime: ''
	}
	const [frontendVersion, setFrontendVersion] = useState(appVersion)
	const [backendVersion, setBackendVersion] = useState(appVersion)

	useEffect(() => {
		let appVersionInfo ={}

		const request = new Request(process.env.REACT_APP_VERSION_API + '/info', {
			method: 'GET',
			headers: new Headers({ 'Content-Type': 'application/json' }),
		})
		fetch(request)
			.then(response => {
				return response.json()
			})
			.then(({ data }) => {
				appVersionInfo = {
					buildVersion: data.value.version,
					buildVersionDatetime: data.value.publishDate,
					buildIhmVersion: data.value.ihmVersion,
					serverName: data.value.serverName

				}
				console.log(appVersionInfo)
				setBackendVersion(appVersionInfo)
				setFrontendVersion(getBuildInformation(process.env.NODE_ENV))

				if(data.value.ihmVersion && (getBuildInformation(process.env.NODE_ENV).buildVersion.toString() === data.value.ihmVersion) )
				{
					if(parseInt( localStorage.getItem('RefreshVer_Count')) !== 0)
						{
							localStorage.setItem('RefreshVer_Count', 0)
							window.location.reload()
						}
				}
				else{
					authProvider(AUTH_LOGOUT, 'error')

				}
			})
			.catch(error => {
				Logger.error('Get app version error : ' + error.message)
				return Promise.reject(new Error(error.message))
			})			
	}, [])
	// useEffect(() => {
	// 	let appConfigInfo ={}

	// 	const request = new Request(process.env.REACT_APP_VERSION_API + '/config', {
	// 		method: 'GET',
	// 		headers: new Headers({ 'Content-Type': 'application/json' }),
	// 	})
	// 	fetch(request)
	// 		.then(response => {
	// 			return response.json()
	// 		})
	// 		.then(({ data }) => {
	// 			console.log(data)
	// 			// appConfigInfo = {
	// 			// 	PriceLimit: data.value.pricelimit,
	// 			// 	buildVersionDatetime: data.value.publishDate,
	// 			// 	buildIhmVersion: data.value.ihmVersion

	// 			// }
	// 			// setBackendVersion(appVersionInfo)
	// 			// setFrontendVersion(getBuildInformation(process.env.NODE_ENV))

				
	// 		})
	// 		.catch(error => {
	// 			Logger.error('Get app version error : ' + error.message)
	// 			return Promise.reject(new Error(error.message))
	// 		})			
	// }, [])
	return (
		<React.Fragment>
			{/* <HtmlTooltip title={
				<React.Fragment>
					<Typography color="inherit" >Hawc Version</Typography>
					<em>IHM</em> <b>v{frontendVersion.buildVersion}-{frontendVersion.buildVersionDatetime}</b> <br/>
					<em>API</em> <b>v{backendVersion.buildVersion}-{backendVersion.buildVersionDatetime}</b>
				</React.Fragment>
			}
			>
				<FooterCopyright classes = {classes}/>
			</HtmlTooltip> */}
			<div className={classes.footerLeft}>
				<HtmlTooltip title={
					<React.Fragment>
						<Typography color="inherit" fontSize="4" >Hawc Version</Typography>
						<em>IHM</em> <b>v{frontendVersion.buildVersion}-{frontendVersion.buildVersionDatetime}</b> <br />
						<em>API</em> <b>v{backendVersion.buildVersion}-{backendVersion.buildVersionDatetime}</b>  <br />
						<em>Server</em> <b>{backendVersion.serverName}</b> <br />
					</React.Fragment>
				}
				>
					<FooterCopyright classes={classes} />
				</HtmlTooltip>
			</div>
			
			<div className={classes.footerRight}>
				 Powered by MovieSystem
			</div>
			{parseInt(localStorage.getItem('RefreshVer_Count')) < 4 && backendVersion.buildIhmVersion && (frontendVersion.buildVersion.toString() !== backendVersion.buildIhmVersion) &&  
			
			<Alert severity="warning"   className={classes.alert}
				// iconMapping={  { error: <ErrorOutlineIcon fontSize="inherit" className={classes.icon} /> }}
			>			
				<span >Nouvelle version est disponible, Veuillez rafraichir la page</span>
					<Button color="inherit" size="small"  onClick={()=>refreshVersion()}>
						<RefreshIcon/>
					</Button>
			</Alert>}

			{/* {(Token.getGroupId()===140) && backendVersion.buildIhmVersion && (frontendVersion.buildVersion.toString() !== backendVersion.buildIhmVersion)&&
			<Alert severity="error"   className={classes.error} 
				// iconMapping={  { error: <ErrorOutlineIcon fontSize="inherit" className={classes.icon} /> }}
			>			
				<span >Veuillez vérifier les versions deployées</span>	
			</Alert>
				} */}

		</React.Fragment>

	)
}

export default withStyles(styles)(MyFooter)