export const AUTH_PAGE_LOADED = 'AUTH_PAGE_LOADED'
export const RESET_PASSEWORD = 'RESET_PASSEWORD'
export const CREATE_PASSEWORD = 'CREATE_PASSEWORD'
export const AUTH_PAGE_UNLOADED = 'AUTH_PAGE_UNLOADED'
export const HOME_PAGE_LOADED = 'HOME_PAGE_LOADED'
export const HOME_PAGE_UNLOADED = 'HOME_PAGE_UNLOADED'
export const SHOW_FULL_SCREEN_MODAL = 'SHOW_FULL_SCREEN_MODAL'
export const HIDE_FULL_SCREEN_MODAL = 'HIDE_FULL_SCREEN_MODAL'
export const CHANGE_THEME = 'CHANGE_THEME'
export const PRICING_TOOL_SELECT_LINE = 'PRICING_TOOL_SELECT_LINE'
export const PRICING_TOOL_SELECT_LINE_RESET = 'PRICING_TOOL_SELECT_LINE_RESET'
export const ON_CONFIGURATIONMANAGER_SWITCH_BUTTON = 'ON_CONFIGURATIONMANAGER_SWITCH_BUTTON'
export const ON_PRICE_PLANNING_SELECT_CONTENT = 'ON_PRICE_PLANNING_SELECT_CONTENT'
export const ON_PRICE_PLANNING_CHANGE_CONTENTID_FIELD = 'ON_PRICE_PLANNING_CHANGE_CONTENTID_FIELD'
export const ON_PRICE_PLANNING_EXPAND_UPDATE_FORM = 'ON_PRICE_PLANNING_EXPAND_UPDATE_FORM'
export const ON_PRICE_PLANNING_LOAD = 'ON_PRICE_PLANNING_LOAD'
export const ON_PRICE_PLANNING_SELECT_PRICELINE = 'ON_PRICE_PLANNING_SELECT_PRICELINE'
export const ON_CHECK_UPDATE_FORM = 'ON_CHECK_UPDATE_FORM'
export const RESET_PREVIEW  = 'RESET_PREVIEW'
export const ON_PRICE_PLANNING_CHANGE_CONTENTTYPE = 'ON_PRICE_PLANNING_CHANGE_CONTENTTYPE'
export const ON_REFRESH_TOKEN = 'ON_REFRESH_TOKEN'
export const ON_DOMAINS_ITEMS_LOAD = 'ON_DOMAINS_ITEMS_LOAD'
export const QUICKSEARCH_MAINTENANCE_MODE = 'QUICKSEARCH_MAINTENANCE_MODE'
export const ON_CHANGE_SELECTED_WEEK = 'ON_CHANGE_SELECTED_WEEK'
export const ON_CHANGE_SELECTED_YEAR = 'ON_CHANGE_SELECTED_YEAR'
export const ON_LOAD_WEEKS = 'ON_LOAD_WEEKS'
export const ON_LOAD_PLANNING = 'ON_LOAD_PLANNING'
export const ON_CHANGE_USER = 'ON_CHANGE_USER'
export const ON_CHANGE_CATEGORY = 'ON_CHANGE_CATEGORY'
export const ON_UNLOCK_RESPONSIBLE_UPDATE = 'ON_UNLOCK_RESPONSIBLE_UPDATE'
export const ON_UPDATE_RESPONSIBLE_IDS = 'ON_UPDATE_RESPONSIBLE_IDS'
export const ON_INITIALIZE_RESPONSIBLE_IDS = 'ON_INITIALIZE_RESPONSIBLE_IDS'
export const ON_UPDATE_RESPONSIBLE_COMPLETED = 'ON_UPDATE_RESPONSIBLE_COMPLETED'
export const ON_LOAD_RESPONSIBLE_ROLES ='ON_LOAD_RESPONSIBLE_ROLES'
export const ON_CHANGE_GROUP ='ON_CHANGE_GROUP'
export const ON_UNLOCK_PRIO_UPDATE = 'ON_UNLOCK_PRIO_UPDATE'
export const ON_UPDATE_PRIO_PLANNING_IDS = 'ON_UPDATE_PRIO_PLANNING_IDS'
export const ON_UPDATE_PREP_STATUS = 'ON_UPDATE_PREP_STATUS'
export const ON_INITIALIZE_PRIO_IDS ='ON_INITIALIZE_PRIO_IDS'
export const ON_UPDATE_PRIO_COMPLETED ='ON_UPDATE_PRIO_COMPLETED'
export const ON_CHANGE_TASKTYPE = 'ON_CHANGE_TASKTYPE'
export const ON_CHANGE_TASKGROUP = 'ON_CHANGE_TASKGROUP'
export const ON_CHANGE_SEARCH = 'ON_CHANGE_SEARCH'
export const ON_UPDATE_PLANNING_TRI = 'ON_UPDATE_PLANNING_TRI'
export const ON_LOAD_ALBUMS = 'ON_LOAD_ALBUMS'
export const ON_LOAD_ALL_ALBUMS = 'ON_LOAD_ALL_ALBUMS'
export const ON_ADD_DISSOCIATE_ALBUM = 'ON_ADD_DISSOCIATE_ALBUM'
export const ON_PROMO_LOAD = 'ON_PROMO_LOAD'
export const ON_PROMO_UPDATE = 'ON_PROMO_UPDATE'
export const ON_GET_PROMO = 'ON_GET_PROMO'
export const ON_PROMO_UPDATE_REPONSE = 'ON_PROMO_UPDATE_REPONSE'
export const ON_PROMO_CHECK_VOUCHER_EXIST = 'ON_PROMO_CHECK_VOUCHER_EXIST'
export const ON_PROMO_CHECK_SUBSCRIBER_EXIST = 'ON_PROMO_CHECK_SUBSCRIBER_EXIST'
export const ON_RESET_PROMO = 'ON_RESET_PROMO'
export const SELECTED_TAB = 'SELECTED_TAB'
export const ON_CHANGE_SELECTED_CRITERE = 'ON_CHANGE_SELECTED_CRITERE'
export const SEARCH_VALUE = 'SEARCH_VALUE'
export const TAB_VALUE = 'TAB_VALUE'
export const PARENT_ID_UPDATE = 'PARENT_ID_UPDATE'
export const ON_ALBUM_FICHE_UPDATE = 'ON_ALBUM_FICHE_UPDATE'
export const ON_ALBUM_EDITO_UPDATE = 'ON_ALBUM_EDITO_UPDATE'
export const ON_ASSOCIATE_SOUS_ALBUM_UPDATE = 'ON_ASSOCIATE_SOUS_ALBUM_UPDATE'
export const ON_PROMO_SET_CONTROL_FIELD = 'ON_PROMO_SET_CONTROL_FIELD'
export const ON_PROMO_RESET_CONTROL_FIELD = 'ON_PROMO_RESET_CONTROL_FIELD'
export const ON_PROMO_SET_APPLY_ALL_CATALOGUE = 'ON_PROMO_SET_APPLY_ALL_CATALOGUE'
export const ON_PROMO_SET_SELECTED_TAB = 'ON_PROMO_SET_SELECTED_TAB'
export const ON_PROMO_SET_SEARCH_PARAMS = 'ON_PROMO_SET_SEARCH_PARAMS'
export const ON_LOAD_FAVORITE_BY_OBJECT = 'ON_LOAD_FAVORITE_BY_OBJECT'
export const ON_LOAD_PROMO_LIST = 'ON_LOAD_PROMO_LIST'
export const ON_LOAD_SELECTED_CONFIG = 'ON_LOAD_SELECTED_CONFIG'
export const ON_DISPATCHER_SET_SELECTED_TAB = 'ON_DISPATCHER_SET_SELECTED_TAB'
export const ON_ALBUM_PICTURE_UPDATE = 'ON_ALBUM_PICTURE_UPDATE'
export const ON_UPDATE_CONFIG = 'ON_UPDATE_CONFIG'
export const ON_LOAD_NEW_IMAGE = 'ON_LOAD_NEW_IMAGE'
export const ON_START_TIMER_COUNT = 'ON_START_TIMER_COUNT'
export const ON_IS_IMAGE_PIVOT = 'ON_IS_IMAGE_PIVOT'
export const ON_ALBUM_MOVIE_UPDATE = 'ON_ALBUM_MOVIE_UPDATE'
export const ON_ALBUM_MOVIES_LIST_UPDATE = 'ON_ALBUM_MOVIES_LIST_UPDATE'
export const ON_ALBUM_MOVIES_LIST_FILTER = 'ON_ALBUM_MOVIES_LIST_FILTER'
export const ON_ALBUM_MOVIES_SHOW_MODE = 'ON_ALBUM_MOVIES_SHOW_MODE'
export const ON_EXPORT_JOBS_LIST_UPDATE = 'ON_EXPORT_JOBS_LIST_UPDATE'

