import moment from 'moment'
import 'moment/locale/fr'

export default class DateFormatter {

	// 21/09/2018 10:37:21
	static getDateTimeLocale = value => {
		if (null == value || value === '') return ''

		return `${DateFormatter.getDateLocale(value)} ${DateFormatter.getTimeLocale(value)}`
	}

	// 21/09/2018
	static getDateLocale = value => {
		if (null == value || value === '') return ''

		let date = DateFormatter.convertSystemDate(value)

		return null != date ? `${date.format('L')}` : ''
	}

	static getDateLocaleCustom = value => {
		if (null == value || value === '') return ''

		// let date = DateFormatter.convertSystemDate(value)
		return `${DateFormatter.getDateLocale(value)} ${DateFormatter.getTimeLocale(value)}`
	}

	// 10:37:21
	static getTimeLocale = value => {
		if (null == value || value === '') return ''
		let date = DateFormatter.convertSystemDate(value)

		return null != date ? `${date.format('LTS')}` : ''
	}

	// à partir du 21/09/2018
	// jusqu'au 21/09/2018
	// du 21/09/2018 au 22/09/2018
	static getDatePeriodLocale = (start, end) => {
		if ((null == start || start === '') && (null == end || end === '')) return ''

		let dtStart = DateFormatter.convertSystemDate(start)
		let dtEnd = DateFormatter.convertSystemDate(end)
		
		if (null != dtStart && null == dtEnd)
			return `à partir du ${DateFormatter.getDateLocale(start)}`
		else if (null == dtStart && null != dtEnd)
			return `jusqu'au ${DateFormatter.getDateLocale(end)}`
		else if (null != dtStart && null != dtEnd)
			return `du ${DateFormatter.getDateLocale(start)} au ${DateFormatter.getDateLocale(end)}`
		else
			return ''
	}

	// à partir du 21/09/2018 10:37:21
	// jusqu'au 21/09/2018 10:37:21
	// du 21/09/2018 10:37:21 au 21/09/2018 10:37:21
	static getDateTimePeriodLocale = (start, end) => {
		if ((null == start || start === '') && (null == end || end === '')) return ''

		let dtStart = DateFormatter.convertSystemDate(start)
		let dtEnd = DateFormatter.convertSystemDate(end)
		
		if (null != dtStart && null == dtEnd)
			return `à partir du ${DateFormatter.getDateTimeLocale(dtStart)}`
		else if (null == dtStart && null != dtEnd)
			return `jusqu'au ${DateFormatter.getDateTimeLocale(dtEnd)}`
		else if (null != dtStart && null != dtEnd)
			return `du ${DateFormatter.getDateTimeLocale(dtStart)} au ${DateFormatter.getDateTimeLocale(dtEnd)}`
		else
			return ''
	}

	// Convert the date returned in responses, ex : 2018-12-31T23:59:59.000+02:00
	static convertSystemDate = value => {
		// console.log(value, 'convert')
		const dt = moment(value, 'YYYY-MM-DDTHH:mm:ss')
		return dt.isValid() ? dt : null
	}

	// Format minutes in literal duration, ex: 146 => 2H26
	static getFormattedDuration = value => {
		if (null == value || value === '') return ''

		let duration = moment.duration(value, 'minutes')

		return `${duration.hours()}H${duration.minutes()}`
	}

	// Test if current date is between start and date, ex : 2018-09-24 between 2018-09-01 and 2018-09-30 = true
	static isPeriodActive = (start, end) => {
		if ((null == start || start === '') && (null == end || end === '')) return false

		let dtStart = DateFormatter.convertSystemDate(start)
		let dtEnd = DateFormatter.convertSystemDate(end)
		
		return moment().isBetween(dtStart, dtEnd, null, '[]')
	}

	static getDatepickerFieldValue = (date) => {
		return (date === '0001-01-01T00:00:00.000' || date === null) ? '' : date
	}

	static getDayNameDate = (stringDate) => {
		var date = new Date(stringDate)
		const dayName = date.toLocaleDateString('fr-Fr', { weekday: 'long' })
		const day = date.getDate()
		const monthName = date.toLocaleString('fr-Fr', { month: 'long' })
		let validMonthName = ''
		if(monthName === 'juin' || monthName === 'mars' || monthName === 'août'|| monthName === 'mai') validMonthName = monthName
		else if(monthName === 'juillet') validMonthName = monthName.substring(0,4)
		else validMonthName = monthName.substring(0,3) +'.'
		const year = date.getFullYear()
		const dayNameDate = `${this.capitalizeFirstLetter(dayName.substring(0,3))} ${day} ${this.capitalizeFirstLetter(validMonthName)} ${year}`
		return dayNameDate
	}

	static getDayNameDateHourMinSec = (stringDate) => {
		if(!stringDate) return ''
		var date = new Date(stringDate)
		const hours = ('0' + date.getHours()).slice(-2)
		const day = date.getDate()
		const year = date.getFullYear()
		
		let validMonthName = ''
		
		const secs = ('0' + date.getSeconds()).slice(-2)
		const mins = ('0' + date.getMinutes()).slice(-2)
		const dayName = date.toLocaleDateString('fr-Fr', { weekday: 'long' })
		const monthName = date.toLocaleString('fr-Fr', { month: 'long' })

		if(monthName === 'juin' || monthName === 'mars' || monthName === 'août') 
			validMonthName = monthName
		else if(monthName === 'juillet') 
			validMonthName = monthName.substring(0,4)
		else 
			validMonthName = monthName.substring(0,3)
	
		return `${this.capitalizeFirstLetter(dayName.substring(0,3))} ${day} ${this.capitalizeFirstLetter(validMonthName)} ${year} ${hours}:${mins}:${secs}`
	}
	static getDayNameDateHourMin = (stringDate) => {
		if(!stringDate) return ''
		var date = new Date(stringDate)
		const hours = ('0' + date.getHours()).slice(-2)
		const day = date.getDate()
		const year = date.getFullYear()
		
		let validMonthName = ''
		
		const mins = ('0' + date.getMinutes()).slice(-2)
		const dayName = date.toLocaleDateString('fr-Fr', { weekday: 'long' })
		const monthName = date.toLocaleString('fr-Fr', { month: 'long' })

		if(monthName === 'juin' || monthName === 'mars' || monthName === 'août') 
			validMonthName = monthName
		else if(monthName === 'juillet') 
			validMonthName = monthName.substring(0,4)
		else 
			validMonthName = monthName.substring(0,3)
	
		return `${this.capitalizeFirstLetter(dayName.substring(0,3))} ${day} ${this.capitalizeFirstLetter(validMonthName)} ${year} ${hours}:${mins}`
	}

	static getNumberOfWeek() {
		const today = new Date()
		const thisweek = moment().week()
		//  const firstDayOfYear = new Date(today.getFullYear(), 0, 1)
		//  const pastDaysOfYear = Math.floor((today - firstDayOfYear) / (86400000)) 
		//  const week =  Math.ceil((today.getDay() + 1 + pastDaysOfYear  ) / 7)
		const week = Number.parseInt (thisweek.toString().length === 1 ? `${'0' + thisweek.toString()}` : thisweek)
		const yearWeek = `${today.getFullYear()}${week}` 
		return Number.parseInt(yearWeek)
	}

	static capitalizeFirstLetter(string) {
		return string.charAt(0).toUpperCase() + string.slice(1);
	}
// convert string date MMDDYYHHmm to datetime value
	static getDateFromStringFormat = (stringDate) => {
		if(!stringDate) return ''
		var month = stringDate.slice(0,2)
		const day = stringDate.slice(2,4)
		const year = stringDate.slice(4,6)
		const hours = stringDate.slice(6,8)
		const min = stringDate.slice(8,10)
		const finaldate = month +'/'+ day +'/'+ year+' '+ hours+':'+min
		var date = new Date(finaldate)
		console.log(date)
		return date
	}

	// convert string duration datatype P0Y0M0DT0H0M0S to periode value
	static getPeriodeValueFromDatatype = (stringPeriod,interval) => {
		//let period = 'P5Y4M1DT15H20M25S'
		if(!stringPeriod) return 0
		let periodeItem = stringPeriod.toUpperCase().replace('P','').split('T')
		const year = periodeItem[0] && periodeItem[0].split('Y')[0]
		const month = periodeItem[0].split('Y')[1].split('M')[0]
		const day = periodeItem[0].split('M')[1].replace('D','')
		const hours = periodeItem[1].split('H')[0]
		const min = periodeItem[1].split('H')[1].split('M')[0]
		const sec = periodeItem[1].split('M')[1].replace('S','')
		//const result 
		switch(interval){
			case 'Y':
				return year
			case 'M':
				return month
			case 'D':
				return day
			case 'H':
				return hours
			case 'TM':
				return min
			case 'S':
				return sec
			default:
				return 0
				break
		}
		// const finaldate = year +'/'+ month +'/'+ day+' '+ hours+':'+min +':' + sec
		
		// return finaldate
	}
}
