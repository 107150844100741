import React from 'react'
import { TextField, TabbedShowLayout, Tab } from 'react-admin'
import { ContactField, ExtendedTextField, MyDateField } from '../common/fields'
import { MainIcon, AuditedIcon, PropertiesIcon } from '../../constants/icons'

const CardShow = ({ ...props }) => (
	<TabbedShowLayout {...props}>
		<Tab label="Fiche" icon={<MainIcon />}>
			<TextField label="fields.id" source="id" />
			<ExtendedTextField label="fields.roleid" source="roleid" domainid={22} />
			<ExtendedTextField label="fields.civilityid" source="civilityid" domainid={23} />
			<TextField label="fields.lastname" source="lastname" />
			<TextField label="fields.firstname" source="firstname" />
			<MyDateField label="fields.birthdate" source="birthdate" />
			<MyDateField label="fields.deathdate" source="deathdate" />
		</Tab>
		<Tab label="Propriétés" icon={<PropertiesIcon/>}>
			<TextField label="fields.properties" source="properties" />
		</Tab>
		<Tab label="Gestion" icon={<AuditedIcon />}>
			<MyDateField showTime={true} label="fields.createdon" source="createdon" />
			<ContactField label="fields.createdby" source="createdby" />
			<MyDateField showTime={true} label="fields.modifiedon" source="modifiedon" />
			<ContactField label="fields.modifiedby" source="modifiedby" />
		</Tab>
	</TabbedShowLayout>
)

export default CardShow
