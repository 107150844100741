/* eslint-disable react/jsx-key */
import React from 'react'
import Typography from '@material-ui/core/Typography'
import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'

const acceptedTypes = [ 'application/x-zip-compressed']
const maxSize = 20971520

const FileInformationContainer = (props) => {
	const { classes, acceptedFiles, rejectedFiles, isJsValidationEnabled,removeImage} = props
	if(acceptedFiles.length >0){
		if(removeImage){
			return (
				<Card className={classes.noJsValidation}>
					<CardContent>
						<Typography variant="caption">Veuillez ajouter une image</Typography><br/>
					</CardContent>
				</Card>	
			)
		}
		if(isJsValidationEnabled){
			return (
				<React.Fragment>
					{acceptedFiles.map(acceptedFile => {
						return (
							<Card className={classes.fileAccepted}>
								<CardContent>
									<Typography variant="caption">Nom du fichier : {acceptedFile.name}</Typography>
									{<img
										src={acceptedFile.path}
										//style={img}
										// Revoke data uri after image is loaded
										onLoad={() => { URL.revokeObjectURL(acceptedFile.path) }}
										/>
									}
								</CardContent>
							</Card>
						)
					})}
				</React.Fragment>
			)
		}
		else{
			return (
				<React.Fragment>
					{acceptedFiles.map(acceptedFile => {
						return (
							<Card className={classes.noJsValidation}>
								<CardContent>
									<Typography variant="caption">Nom du fichier : {acceptedFile.name}</Typography>
								</CardContent>
							</Card>
						)
					})}
				</React.Fragment>
			)
		}
		
	}

	if(rejectedFiles.length > 0){
		const currentFile = rejectedFiles[0]
		if(currentFile.type === null || currentFile.type === '' || (currentFile.type !== null && !acceptedTypes.includes(currentFile.type))){
			return (
				<Card className={classes.fileRejected}>
					<CardContent>
						<Typography variant="caption">Nom du fichier : {currentFile.name}</Typography><br/>
						<Typography variant="caption">Le fichier n’est pas dans un format reconnu</Typography><br/>
					</CardContent>
				</Card>	
			)
		}
		else if(currentFile.size > maxSize){
			const errorMessage = `La taille du fichier est de ${currentFile.size} octets et est supérieur à la taille autorisée`
			return (
				<Card className={classes.fileRejected}>
					<CardContent>
						<Typography variant="caption">Nom du fichier : {currentFile.name}</Typography><br/>
						<Typography variant="caption">{errorMessage}</Typography><br/>
					</CardContent>
				</Card>	
			)
		}
	}
 	if(acceptedFiles.length === 0 && rejectedFiles.length ===0 && removeImage){
		return (	
		<Card className={classes.fileRejected}>
					<CardContent>
						<Typography variant="caption">Veuillez ajouter une image</Typography><br/>
					</CardContent>
			</Card>	
		)
	}
	return <div></div>
}

export default FileInformationContainer