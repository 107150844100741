import get from 'lodash/get'
import { isEmpty } from '30-seconds-of-code'
import logger from './Logger'

class CacheItems {

	constructor(cacheKey, request, isLocalStorage = false) {
		this.logger = logger
		this.cacheKey = cacheKey
		this.request = request

		if (isLocalStorage)
			this.storage = localStorage
		else
			this.storage = sessionStorage
	}

	// Return True if the items are in the storage
	exists = () => { return null != this.storage.getItem(this.cacheKey) }

	// Remove the items from the storage
	clear = () => { this.storage.removeItem(this.cacheKey) }

	// Store the payload in the storage
	store = (payload) => {
		if (null != payload && null != payload.data && !this.exists()) {
			logger.debug(`Store ${this.__proto__.constructor.name} in storage...`)
			this.storage.setItem(this.cacheKey, JSON.stringify(payload.data))
		}
	}

	// Load items and cache the result in the storage
	load = (skipCache = false) => {
		if (!this.exists() || skipCache) {
			this.request()
				.then(json => {
					this.store(json)
				})
		}
	}

	// Get all items
	getAllItems = () => {
		let dataStorage = this.storage.getItem(this.cacheKey)
		if (null === dataStorage ) {
			this.load()
			dataStorage = this.storage.getItem(this.cacheKey)
		}
		return JSON.parse(dataStorage)
	}

	// Build a generic list
	getGenericItemsList = (list, fieldid = 'id', fieldlabel = 'label', withAll = false) => {
		if (!isEmpty(list)) {
			let items = Object.values(list).map(item => {
				const id = get(item, fieldid)
				const label = get(item, fieldlabel)
				return ({
					id: id,
					name: `${label} (${id})`.trim()
				})
			})

			if (withAll) {
				items.unshift({ id: -1, name: 'Tous (-1)' })
			}

			return items
		}
		return []
	}
}

export default CacheItems