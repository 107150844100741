import React from 'react'
import { connect } from 'react-redux'
import compose from 'recompose/compose'
import { UserMenu, MenuItemLink, translate, LoadingIndicator } from 'react-admin'
import { withRouter } from 'react-router-dom'
import Menu from './Menu'
import SearchApp from './SearchApp'
import { SettingsIcon } from '../../constants/icons'

const MyUserMenu = translate(({ translate, ...props }) =>
	<div style={{ display: 'flex',
	flex: 'unset',    right: '0px',    bottom: '0px',    position: 'sticky',    textAlign: 'right'}}>
		<SearchApp />
		<LoadingIndicator />
		<Menu />
		<UserMenu {...props}>
			<MenuItemLink
				to="/configuration"
				primaryText={translate('labels.configuration')}
				leftIcon={<SettingsIcon/>}
			/>
		</UserMenu>
	</div>
)

const enhance = compose(
	withRouter,
	connect(
		state => ({
			theme: state.theme,
			locale: state.i18n.locale,
		}),
		{}
	),
	translate
)

export default enhance(MyUserMenu)