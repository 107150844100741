import Api from './Api'

const ImportexportRequest = {
	getAll: (id, objectid) =>
		Api.Requests.getAll(`/Importexport/ImportexportLoad?id=${id}&objectid=${objectid}`),
	getJob: (id, objectid) =>
	Api.Requests.getAll(`/Importexport/ImportexportJobLoad?id=${id}&objectid=${objectid}`),
	getJobHistory: (id, typeid, amount) =>
	Api.Requests.getAll(`/Importexport/ImportexportJobHistoryLoad?id=${id}&typeid=${typeid}&amount=${amount}`),
	getJobHistoryAll: (id, typeid, limit) =>
	Api.Requests.getAll(`/Importexport/ImportexportJobHistoryLoadAll?id=${id}&typeid=${typeid}&limit=${limit}`),
	getJobHistoryPeriod: (id, typeid, limit, startdate, enddate) =>
	Api.Requests.getAll(`/Importexport/ImportexportJobHistoryLoadPeriod?id=${id}&typeid=${typeid}&limit=${limit}&startdate=${startdate}&enddate=${enddate}`),
}

export default ImportexportRequest