import React from 'react'
import NumberFieldError from './NumberFieldError'

const NumberFieldCurrency = props => {
	return (
		<NumberFieldError options={{ style: 'currency', currency: 'EUR' }} {...props} />
	)
}

export default NumberFieldCurrency
