import React, { Component } from 'react'
import MomentUtils from '@date-io/moment';
import {
	DatePicker,
	TimePicker,
	MuiPickersUtilsProvider,
	//KeyboardDatePicker
} from '@material-ui/pickers'
import { addField } from 'ra-core'
import sanitizeRestProps from '../inputs/sanitizeRestProps'
import moment from 'moment'
import PropTypes from 'prop-types'
import { createTheme, ThemeProvider ,Grid} from '@material-ui/core'


const materialTheme = createTheme({
	overrides: {
		MuiPickersModal: {
			dialogRoot: {
				backgroundColor: 'white',
				color: "black",
			}
		},
		MuiPickersToolbar: {
			toolbar: {
				backgroundColor: '#288DB1'
			}
		},
		MuiPickersDay: {
			daySelected: {
				backgroundColor: '#288DB1'
			}
		},
		MuiButton: {
			textPrimary: {
				color: '#288DB1'
			}
		},
		MuiTab:{
			fullWidth:{
				background: '#288DB1'

			}
		}
	},
});
moment.locale("fr")

const labels = {
	color: '#288DB1',
	fontWeight: 'bold',
	fontSize: '1em',
	fontFamily: 'Roboto, Helvetica, Arial, sans-serif',
	lineHeight: 1
}


const dateFormatter = (v, t) => {
	if (!(v instanceof Date) || isNaN(v)) return
	const pad = '00'
	const yyyy = v.getFullYear().toString()
	const MM = (v.getMonth() + 1).toString()
	const dd = v.getDate().toString()
	const HH = v.getHours().toString()
	const mm = v.getMinutes().toString()
	const ss = v.getSeconds().toString()
	return `${yyyy}-${(pad + MM).slice(-2)}-${(pad + dd).slice(-2)} ${(pad + HH).slice(-2)}:${(pad + mm).slice(-2)}:${(pad + ss).slice(-2)}`
}

const sanitizeValue = (value, type) => {
	// null, undefined and empty string values should not go through dateFormatter
	// otherwise, it returns undefined and will make the input an uncontrolled one.
	if (value == null || value === '') {
		return ''
	}

	const finalValue = typeof value instanceof Date ? value : new Date(value)
	return dateFormatter(finalValue, type)
}
class BasicDatePicker extends Component {
	//dateOnly = true
	constructor(props) {
		super(props)
		this.state = {
			starttime: [],
			endtime: [],
		}
	}
	onChange = (dateType) => event => {
		if (event) {
			let dateEvent = event.format('YYYY-MM-DDTHH:mm:ss')
			// let currentTime = this.dateOnly ? 'T00:00:00' : moment().format('THH:mm:ss')
			// this.props.input.onChange(dateEvent)
			if (dateType === 'end') {
				if (this.state.endtime.length !== 0) {
					dateEvent = event.set('hour',this.state.endtime[0]).set('minute',this.state.endtime[1]).set('second',59).format('YYYY-MM-DDTHH:mm:ss')
				} else {
					dateEvent = event.set('hour',23).set('minute',59).set('second',59).format('YYYY-MM-DDTHH:mm:ss')
				}
				this.props.input.onChange(dateEvent)
			}
			else if(dateType === 'start') {
				if (this.state.starttime.length !== 0) {
					dateEvent = event.set('hour',this.state.starttime[0]).set('minute',this.state.starttime[1]).format('YYYY-MM-DDTHH:mm:ss')
				}
				else {
					dateEvent = event.set('hour',0).set('minute',0).set('second',0).format('YYYY-MM-DDTHH:mm:ss')
				}
				this.props.input.onChange(dateEvent)
			} else {
				
				this.setState({[dateType] : [event.hours(), event.minutes()]}, () => {
					// console.log(this.state)
				})
				if (dateType === 'end') {
					dateEvent = event.set('second',59).format('YYYY-MM-DDTHH:mm:ss')
				}
				this.props.input.onChange(dateEvent)
			}
			if(!this.props.withTime) {
				let dateEvent = event.format('YYYY-MM-DD')
				let currentTime =  'T00:00:00'  
				this.props.input.onChange(dateEvent + currentTime)
			}
		}
		else {
			this.props.input.onChange(null)
		}
		
	}
	// onChange = (event) => {
	// 	console.log(event)
	// 	if(this.props.withTime){
	// 		if (event) {
	// 			let dateEvent = event.format('YYYY-MM-DD HH:mm')
	// 			console.log(dateEvent)
	// 			this.props.input.onChange(dateEvent)
	// 		}
	// 		else {
	// 			this.props.input.onChange(null)
	// 		}
	// 	}
	// 	else{
 	// 	if (event) {
	// 		let dateEvent = event.format('YYYY-MM-DD')
	// 		let currentTime = this.dateOnly ? 'T00:00:00' : moment().format('THH:mm:ss')
	// 		this.props.input.onChange(dateEvent + currentTime)
	// 	}
	// 	else {
	// 		this.props.input.onChange(null)
	// 	}
	// }
	// }
	render() {
		const {
			className,
			required,
			meta,
			input,
			label,
			options,
			resource,
			source,
			dateOnly,
			shrink,
			classes,
			withCustomLabelStyle,
			disabled,
			errorLabel,
			withTime,
			timelabel,
			dateType,
			...rest
		} = this.props

		this.dateOnly = dateOnly
		// const { touched, error } = meta
		const value = sanitizeValue(input.value, dateType)
		// const value =withTime ?  input.value : sanitizeValue(input.value)
		if (value !== '') {
			return (
				<ThemeProvider theme={materialTheme}>
					<MuiPickersUtilsProvider utils={MomentUtils}
						moment={moment}
						//locale={frLocale}
						locale="fr"
					>
						<Grid container spacing={4} direction="row" >
							<Grid item xs={withTime ? 6 : 12}>

								{/* <InputLabel htmlFor="input-with-icon-adornment" style={labels} required={required ? required : false}>{label}</InputLabel> */}
								<DatePicker
									{...input}
									className={className}
									style={labels}
									label={label}
									required={required ? required : false}
									margin="dense"
									keyboard
									inputVariant="outlined"
									//variant="outlined"
									clearLabel="Vider"
									cancelLabel="annuler"
									autoOk={false}
									error={errorLabel != null ? true : false}
									onError={(error, values) => {
										// console.log("Error is", error, values);
									}}
									disabled={disabled}
									helperText={errorLabel}
									format="DD/MM/YYYY"
									placeholder="JJ/MM/AAAA"
									size="small"
									// handle clearing outside => pass plain array if you are not controlling value outside
									//mask={value => (value && value !== '' ? [/\d/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/] : [])}
									clearable
									disableopenonenter
									animateYearScrolling={false}
									value={value}
									onChange={this.onChange(dateType)}
									onBlur={this.onBlur}
									InputLabelProps={{
										shrink: shrink,
										style: labels

									}
									}
									// InputLabelProps={withCustomLabelStyle ? {
									// 	classes: {
									// 		shrink: classes.customLabelStyle
									// 	}
									// } : {}}
									InputProps={{
										disabled: false
									}}
									{...options}
									{...sanitizeRestProps(rest)}
								/>
							</Grid>
							{withTime &&
								<Grid item xs={6}>
									<TimePicker
										clearable
										ampm={false}
										label={timelabel}
										{...input}
										className={className}
										style={labels}
										required={ false}
										margin="dense"
										keyboard
										inputVariant="outlined"
										//variant="outlined"
										clearLabel="Vider"
										cancelLabel="annuler"
										autoOk={false}
										error={errorLabel != null ? true : false}
										onError={(error, values) => {
											// console.log("Error is", error, values);
										}}
										disabled={disabled}
										helperText={errorLabel}
										format="HH:mm:ss"
										placeholder="HH:MM:SS"
										size="small"
										mask={value => (value && value !== '' ? [/\d/, /\d/, ':', /\d/, /\d/, ':', /\d/, /\d/] : [])}

										// handle clearing outside => pass plain array if you are not controlling value outside
										//mask={value => (value && value !== '' ? [/\d/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/] : [])}
										disableopenonenter
										animateYearScrolling={false}
										value={value}
										onChange={this.onChange(dateType+'time')}
										onBlur={this.onBlur}
										InputLabelProps={{
											shrink: shrink,
											style: labels

										}
										}
										// InputLabelProps={withCustomLabelStyle ? {
										// 	classes: {
										// 		shrink: classes.customLabelStyle
										// 	}
										// } : {}}
										InputProps={{
											disabled: false
										}}
										{...options}
										{...sanitizeRestProps(rest)}
									// value={selectedDate}
									// onChange={handleDateChange}
									/>
								</Grid>}
						</Grid>
					</MuiPickersUtilsProvider>
				</ThemeProvider>
			)
		}
		else {
			return (
				<ThemeProvider theme={materialTheme}>
					<MuiPickersUtilsProvider utils={MomentUtils}
						moment={moment}
						//locale={frLocale}
						locale="fr"
					>
						<Grid container spacing={4} direction="row" >
							<Grid item xs={withTime ? 6 : 12}> 
						{/* <InputLabel htmlFor="input-with-icon-adornment" style={labels} required={required ? required : false}>{label}</InputLabel> */}
						<DatePicker
							{...input}
							label={label}
							//style={labels}
							required={required ? required : false}
							className={className}
							margin="dense"
							keyboard
							inputVariant="outlined"
							//variant="outlined"
							clearLabel="Vider"
							error={errorLabel != null ? true : false}
							helperText={errorLabel}
							format="DD/MM/YYYY"
							size="small"
							placeholder="JJ/MM/AAAA"
							disabled={disabled}
							// handle clearing outside => pass plain array if you are not controlling value outside
							// mask={value => (value && value !== '' ? [/\d/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/] : [])}
							//mask={() => ([])}
							clearable
							disableopenonenter
							animateYearScrolling={false}
							value={null}
							onChange={this.onChange(dateType)}
							onBlur={this.onBlur}
							// InputLabelProps={withCustomLabelStyle ? {
							// 	classes: {
							// 		shrink: classes.customLabelStyle
							// 	}
							// } : {}}
							InputLabelProps={{
								shrink: shrink,
								style: labels,
							}
							}
							InputProps={{
								disabled: false
							}}
							{...options}
							{...sanitizeRestProps(rest)}
						/></Grid>
						{withTime &&
							<Grid item xs={6}>


								<TimePicker
									clearable
									ampm={false}
									label={timelabel}
									{...input}
									className={className}
									style={labels}
									required={false}
									margin="dense"
									keyboard
									inputVariant="outlined"
									//variant="outlined"
									clearLabel="Vider"
									cancelLabel="annuler"
									autoOk={false}
									error={errorLabel != null ? true : false}
									onError={(error, values) => {
										// console.log("Error is", error, values);
									}}
									disabled={disabled}
									helperText={errorLabel}
									format="HH:mm:ss"
									placeholder="HH:MM:SS"
									size="small"
									// handle clearing outside => pass plain array if you are not controlling value outside
									//mask={value => (value && value !== '' ? [/\d/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/] : [])}
									disableopenonenter
									animateYearScrolling={false}
									value={null}
									onChange={this.onChange(dateType+'time')}
									onBlur={this.onBlur}
									InputLabelProps={{
										shrink: shrink,
										style: labels

									}
									}
									// InputLabelProps={withCustomLabelStyle ? {
									// 	classes: {
									// 		shrink: classes.customLabelStyle
									// 	}
									// } : {}}
									InputProps={{
										disabled: false
									}}
									{...options}
									{...sanitizeRestProps(rest)}
								// value={selectedDate}
								// onChange={handleDateChange}
								/>
							</Grid>}
					</Grid>
					</MuiPickersUtilsProvider>
				</ThemeProvider>
			)
		}
	}
}

BasicDatePicker.propTypes = {
	addLabel: PropTypes.bool,
	dateOnly: PropTypes.bool,
	shrink: PropTypes.bool,
}

const BasicDatePickerField = addField(BasicDatePicker)

BasicDatePickerField.defaultProps = {
	addLabel: true,
	dateOnly: true,
	shrink: true,
}

export default BasicDatePickerField
