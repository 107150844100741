import React, { Component } from 'react'
import PropTypes from 'prop-types'
import get from 'lodash/get'
import pure from 'recompose/pure'
import TextField from '@material-ui/core/TextField'
import MenuItem from '@material-ui/core/MenuItem'
import { change, getFormValues } from 'redux-form'
import { REDUX_FORM_NAME } from 'react-admin'
import { connect } from 'react-redux'
import compose from 'recompose/compose'

// TODO: refactoring => i though there is a better way to do this
class RSACInput extends Component {

	state = {
		rsacValue: '',
		rsacLang: '',
		rsacNak: '',
		rsacSex: '',
		rsacVio: '',
		choices: {},
		result: null
	}

	componentDidMount() {
		const { record, source, rsacType } = this.props
		const rsac = get(record, source)

		const RSAC = this.getRSAC(rsacType, rsac)

		this.setState({
			choices: RSAC.choices,
			rsacValue: RSAC.rsacValue,
		})
	}

	// Parse rsac to get 4 values
	setRSACFormatted = (rsac) => {
		const langue = (rsac & 0xff000000) / 0x1000000
		const nudite = (rsac & 0xff0000) / 0x10000
		const sexe = (rsac & 0xff00) / 0x100
		const violence = rsac & 0xff

		return {
			langue: langue,
			nudite: nudite,
			sexe: sexe,
			violence: violence,
		}
	}

	// Update new value of rsac
	setRSAC = (langue, nudite, sexe, violence) => {
		const result = langue * 0x1000000 + nudite * 0x10000 + sexe * 0x100 + violence
		this.setState({
			rsacLang: langue,
			rsacNak: nudite,
			rsacSex: sexe,
			rsacVio: violence
		})
		return {
			result: result
		}
	}

	// Get the right index on differents array choices of rsac
	getRSAC = (rsacType, rsac) => {

		const RSAC = this.setRSACFormatted(rsac)

		let choices
		let rsacValue

		this.setState({
			rsacLang: RSAC.langue,
			rsacNak: RSAC.nudite,
			rsacSex: RSAC.sexe,
			rsacVio: RSAC.violence
		})

		switch (rsacType) {
		case 'langue':
		{
			rsacValue = RSAC.langue
			choices = [
				{ id: 0, name: 'Inoffensive slang, no profanity' },
				{ id: 1, name: 'Mild expletives or mild terms for body functions' },
				{ id: 2, name: 'Moderate expletives; non-sexual anatomical references' },
				{ id: 3, name: 'Strong, vulgar language; obscene gestures. Use of epithets' },
				{ id: 4, name: 'Extreme hate speech or crude language. Explicit sexual references' }
			]
			return { rsacValue: rsacValue, choices: choices }
		}
		case 'nudite':
			rsacValue = RSAC.nudite
			choices = [
				{ id: 0, name: 'No nudity' },
				{ id: 1, name: 'Revealing attire' },
				{ id: 2, name: 'Partial nudity' },
				{ id: 3, name: 'Frontal nudity' },
				{ id: 4, name: 'Provocative frontal nudity' }
			]
			return { rsacValue: rsacValue, choices: choices }
		case 'sexe':
			rsacValue = RSAC.sexe
			choices = [
				{ id: 0, name: 'No sexual activity portrayed. Romance' },
				{ id: 1, name: 'Passionate kissing' },
				{ id: 2, name: 'Clothed sexual touching' },
				{ id: 3, name: 'Non-explicit sexual touching' },
				{ id: 4, name: 'Explicit sexual activity' }
			]
			return { rsacValue: rsacValue, choices: choices }
		case 'violence':
			rsacValue = RSAC.violence
			choices = [
				{ id: 0, name: 'No aggressive violence; no natural or accidental violence' },
				{ id: 1, name: 'Fighting, creatures injured or killed; damage to realistic objects' },
				{ id: 2, name: 'Humans or non-threatening creatures injured or killed' },
				{ id: 3, name: 'Killing with blood and gore, humans injured or killed' },
				{ id: 4, name: 'Wanton and gratuitous violence' }
			]
			return { rsacValue: rsacValue, choices: choices }
		default:
			rsacValue = null
			choices = {}
			return { rsacValue: rsacValue, choices: choices }
		}
	}

	// On change, send the new value rsac appropriate
	handleChange = event => {
		const { rsacType, change, rsacResult } = this.props
		const value = event.target.value
		const rsac = rsacResult.rsac
		const RSACFormatted = this.setRSACFormatted(rsac)

		let rsacLang = RSACFormatted.langue
		let rsacNak = RSACFormatted.nudite
		let rsacSex = RSACFormatted.sexe
		let rsacVio = RSACFormatted.violence

		let RSAC

		switch (rsacType) {
		case 'langue':
		{
			RSAC = this.setRSAC(value, rsacNak, rsacSex, rsacVio)
			rsacLang = value
			break
		}
		case 'nudite':
		{
			RSAC = this.setRSAC(rsacLang, value, rsacSex, rsacVio)
			rsacNak = value
			break
		}
		case 'sexe':
		{
			RSAC = this.setRSAC(rsacLang, rsacNak, value, rsacVio)
			rsacSex = value
			break
		}
		case 'violence':
		{
			RSAC = this.setRSAC(rsacLang, rsacNak, rsacSex, value)
			rsacVio = value
			break
		}
		default:

		}

		change(REDUX_FORM_NAME, 'rsac', RSAC.result)
		this.setState({
			result: RSAC.result,
			rsacValue: value
		})
	}

	render() {
		const { rsacType } = this.props
		const choices = this.state.choices

		return (
			<TextField
				select
				margin="normal"
				title={rsacType}
				value={this.state.rsacValue}
				onChange={this.handleChange}
			>
				{Object.values(choices).map(choice => (
					<MenuItem
						key={choice.id}
						value={choice.id}
					>
						{choice.name}
					</MenuItem>
				))}
			</TextField>
		)
	}

}

RSACInput.propTypes = {
	addLabel: PropTypes.bool,
	label: PropTypes.string,
	// record: PropTypes.object,
	// source: PropTypes.object,
	// rsacType: PropTypes.string,
	// change: PropTypes.func,
	// rsacResult: PropTypes.object
}

const mapStateToProps = state => ({
	rsacResult: getFormValues(REDUX_FORM_NAME)(state)
})

const mapDispatchToProps = {
	change,
}

const enhance = compose(
	connect(mapStateToProps, mapDispatchToProps),
	pure
)

const EnhancedRSACInput = enhance(RSACInput)

EnhancedRSACInput.defaultProps = {
	addLabel: true,
}

export default EnhancedRSACInput
