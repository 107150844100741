import { ON_PROMO_LOAD, ON_PROMO_UPDATE, ON_GET_PROMO, ON_PROMO_UPDATE_REPONSE, ON_PROMO_CHECK_VOUCHER_EXIST, ON_PROMO_CHECK_SUBSCRIBER_EXIST, ON_RESET_PROMO, ON_PROMO_SET_CONTROL_FIELD, ON_PROMO_RESET_CONTROL_FIELD, ON_PROMO_SET_APPLY_ALL_CATALOGUE, ON_PROMO_SET_SELECTED_TAB, ON_PROMO_SET_SEARCH_PARAMS, ON_LOAD_PROMO_LIST } from '../constants/actions'

const defaultState = { payload: {
		closed: 0,
		createdby: 0,
		createdon: new Date(),
		description: "",
		descriptionedito: "",
		startdate: null,
		enddate: null,
		id: 0,
		modifiedby: 0,
		modifiedon: new Date(),
		name: "",
		nameedito: "",
		promotionssubscribers: [],
		promotionsvouchers: [],
		properties: '<PROPS><PROP NAME="MAX" VALUE=""/><PROP NAME="LENGTH" VALUE=""/><PROP NAME="PREFIX" VALUE=""/></PROPS>',
		recordid: "",
		rules: '<RULES><COUNT>0</COUNT><REBATE TYPEID="0" MODEID="0" MEDIA="0" DISTTECHNOLOGY="0">0</REBATE><TTL TYPEID="2" /><TARGETS></TARGETS><MIDS /><AIDS /><PRICES /><BDDPIDS /><HAPILISTIDS /><USERQUALIFIER MICRO=""/><RECURRING/></RULES>',
		typeid: 0,
	}, 
	returned : false,
	isVoucherExist: {
		exist: false,
		count: 0
	},
	isSubscribersExist: false,
	controlField: {
		name: "",
		startdate: "",
		enddate: "",
		typeid: "",
		promotype: "",
		validitytype: "",
		validitytypeduration: "",
		consumptiontype: "",
		voucher: ""
	},
	promotionApplyAllCatalogue: false,
	selectedTabIndex: 0,
	searchPromoListParams: {
		critere: 1,
		value: ''
	},
	PromotionListSearch: []
}

export default (state = defaultState, action) => {
	if (action.type === ON_PROMO_LOAD) {
			let parser = new DOMParser()
			let applyAll = false
			let xmlDoc = parser.parseFromString(action.payload.rules, "text/xml")

			if(xmlDoc.getElementsByTagName("MIDS")[0].childNodes.length > 0
				|| xmlDoc.getElementsByTagName("AIDS")[0].childNodes.length > 0
				|| xmlDoc.getElementsByTagName("BDDPIDS")[0].childNodes.length > 0
				|| xmlDoc.getElementsByTagName("HAPILISTIDS")[0].childNodes.length > 0) {
				applyAll = false
			} else {
				applyAll = true
			}
		return {
			...state,
			payload: action.payload !== null ? action.payload : defaultState.payload,
			controlField: {
				name: "",
				startdate: "",
				enddate: "",
				typeid: "",
				promotype: "",
				validitytype: "",
				validitytypeduration: "",
				consumptiontype: "",
				voucher: ""
			},
			promotionApplyAllCatalogue: applyAll
		}
	}
	else if (action.type === ON_RESET_PROMO) {
		return {
			...state,
			payload: {
				closed: 0,
				createdby: 0,
				createdon: new Date(),
				description: "",
				descriptionedito: "",
				startdate: null,
				enddate: null,
				id: 0,
				modifiedby: 0,
				modifiedon: new Date(),
				name: "",
				nameedito: "",
				promotionssubscribers: [],
				promotionsvouchers: [],
				properties: '<PROPS><PROP NAME="MAX" VALUE=""/><PROP NAME="LENGTH" VALUE=""/><PROP NAME="PREFIX" VALUE=""/></PROPS>',
				recordid: "",
				rules: '<RULES><COUNT>0</COUNT><REBATE TYPEID="0" MODEID="0" MEDIA="0" DISTTECHNOLOGY="0">0</REBATE><TTL TYPEID="2" /><TARGETS></TARGETS><MIDS /><AIDS /><PRICES /><BDDPIDS /><HAPILISTIDS /><USERQUALIFIER MICRO=""/><RECURRING/></RULES>',
				typeid: 0,
			},
			returned: false,
			isVoucherExist: {
				exist: false,
				count: 0
			},
			isSubscribersExist: false,
			controlField: {
				name: "",
				startdate: "",
				enddate: "",
				typeid: "",
				promotype: "",
				validitytype: "",
				validitytypeduration: "",
				consumptiontype: "",
				voucher: ""
			},
			promotionApplyAllCatalogue: false,
			selectedTabIndex: 0,
		}
	}
	else if (action.type === ON_PROMO_UPDATE) {
		return {
			...state,
			payload: action.payload
		}
	}
	else if (action.type === ON_GET_PROMO) {
		return	state 
	}
	else if (action.type === ON_PROMO_UPDATE_REPONSE) {
		return {
			...state,
			returned: action.returned
		}
	}
	else if (action.type === ON_PROMO_CHECK_VOUCHER_EXIST) {
		return {
			...state,
			isVoucherExist: action.isVoucherExist
		}
	}
	else if (action.type === ON_PROMO_CHECK_SUBSCRIBER_EXIST) {
		return {
			...state,
			isSubscribersExist: action.isSubscribersExist
		}
	}
	else if (action.type === ON_PROMO_SET_CONTROL_FIELD) {
		return {
			...state,
			controlField: action.controlField
		}
	}
	else if (action.type === ON_PROMO_RESET_CONTROL_FIELD) {
		return {
			...state,
			controlField: {
				name: "",
				startdate: "",
				enddate: "",
				typeid: "",
				promotype: "",
				validitytype: "",
				validitytypeduration: "",
				consumptiontype: "",
				voucher: ""
			}
		}
	}
	else if (action.type === ON_PROMO_SET_APPLY_ALL_CATALOGUE) {
		return {
			...state,
			promotionApplyAllCatalogue: action.promotionApplyAllCatalogue
		}
	}
	else if (action.type === ON_PROMO_SET_SELECTED_TAB) {
		return {
			...state,
			selectedTabIndex: action.selectedTabIndex
		}
	}
	else if (action.type === ON_PROMO_SET_SEARCH_PARAMS) {
		return {
			...state,
			searchPromoListParams: action.searchPromoListParams
		}
	}
	else if(action.type === ON_LOAD_PROMO_LIST){
		return {
			...state,
			PromotionListSearch : action.searchPromoList
		}
	}
	return state
}
