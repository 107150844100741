import React, { useEffect } from 'react'
import { withStyles,createTheme ,ThemeProvider} from '@material-ui/core/styles'
import LinearProgress from '@material-ui/core/LinearProgress'
 
const styles = theme =>  ({
	progress: {
		width: '100%',
		position: 'absolute',
		bottom: 0,
		left: 0,
		margin: 0,
		padding: 0,
		borderBottomLeftRadius: '2px',
		borderBottomRightRadius: '2px',
	}
})

const LinearProgressGlobal = props => {
	const { classes, setOpen,severity } = props
    const [progress, setProgress] = React.useState(0)
 	const getMuiTheme = () => createTheme({
		overrides: {
			MuiLinearProgress:{
					colorPrimary :{
						backgroundColor: severity ==='success' ?  '#b7e2b6': severity ==='info' ?   '#5f9ed073'  : '#a02e2e82'
					},
					barColorPrimary:{
						backgroundColor:  severity ==='success' ? '#478a31' :severity ==='info' ? '#1e5e92': '#b02424fa'
					}
				}
		},
	})
	useEffect(() => {
	  const timer = setInterval(() => {
		setProgress((oldProgress) => {
		  if (oldProgress >= 100) {
			setOpen()  
			return 100
		  }
		  const diff = 100 / 3 / 10
		  return Math.min(oldProgress + diff, 100)
		})
	  },severity==='info'? 300 : 100)
  
	  return () => {
		clearInterval(timer)
	  }
	}, [])

	return (
		<ThemeProvider theme={getMuiTheme()}>
			<LinearProgress variant="determinate" value={progress} className={classes.progress}  />
		</ThemeProvider>
	)
}

export default withStyles(styles)(LinearProgressGlobal)
