import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { ResettableTextField } from 'react-admin'
import { addField, FieldTitle } from 'ra-core'
import sanitizeRestProps from './sanitizeRestProps'
import compose from 'recompose/compose'
import { withStyles } from '@material-ui/core/styles'


const styles = theme =>   ({
	root: {
	},
	enabled: {
		color: theme.palette.common.black,
		backgroundColor: theme.palette.common.white,
	},
	disabled: {
		color: theme.palette.common.black,
		backgroundColor: theme.palette.common.white,
	},
})

export class MyTextInput extends PureComponent {
	handleBlur = eventOrValue => {
		this.props.onBlur(eventOrValue)
		this.props.input.onBlur(eventOrValue)
	};

	handleFocus = event => {
		this.props.onFocus(event)
		this.props.input.onFocus(event)
	};

	handleChange = eventOrValue => {
		this.props.onChange(eventOrValue)
		this.props.input.onChange(eventOrValue)
	};

	render() {
		const {
			classes,
			className,
			input,
			isRequired,
			label,
			meta,
			options,
			resource,
			source,
			type,
			disabled,
			clearAlwaysVisible,
			shrink,
			style,
			xs, s, m, l, xl,
			...rest
		} = this.props
		if (typeof meta === 'undefined') {
			throw new Error(
				'The MyTextInput component wasn\'t called within a redux-form <Field>. Did you decorate it and forget to add the addField prop to your component? See https://marmelab.com/react-admin/Inputs.html#writing-your-own-input-component for details.'
			)
		}
		const { touched, error } = meta

		let localStyle
		if (xs)
			localStyle = { width: '50px' }
		else if (s)
			localStyle = { width: '100px' }
		else if (m)
			localStyle = { width: '200px' }
		else if (l)
			localStyle = { width: '400px' }
		else if (xl)
			localStyle = { width: '600px' }

		// if (disabled) {
		// 	return (
		// 		<MyTextField
		// 			className={className}
		// 			style={style ? style : localStyle}
		// 			{...options}
		// 			{...sanitizeRestProps(rest)}
		// 			{...input}
		// 			onBlur={this.handleBlur}
		// 			onFocus={this.handleFocus}
		// 			onChange={this.handleChange}
		// 			clearAlwaysVisible={clearAlwaysVisible}
		// 			disabled={disabled}
		// 			source={source}
		// 			type={type}
		// 			style={style ? style : localStyle}
		// 			className={className}
		// 			label={!shrink ?
		// 				(
		// 					label === false ? (
		// 						label
		// 					) : (
		// 							<FieldTitle
		// 								label={label}
		// 								source={source}
		// 								resource={resource}
		// 								isRequired={isRequired}
		// 							/>
		// 						)
		// 				) :
		// 				null
		// 			}
		// 			{...rest}
		// 		/>
		// 	)
		// }
		return (
			<ResettableTextField
				type={type}
				label={!shrink ?
					(
						label === false ? (
							label
						) : (
								<FieldTitle
									label={label}
									source={source}
									resource={resource}
									isRequired={isRequired}
								/>
							)
					) :
					null
				}
				error={!!(touched && error)}
				helperText={touched && error}
				className={className}
				style={style ? style : localStyle}
				{...options}
				{...sanitizeRestProps(rest)}
				{...input}
				onBlur={this.handleBlur}
				onFocus={this.handleFocus}
				onChange={this.handleChange}
				clearAlwaysVisible={clearAlwaysVisible}
				disabled={disabled}
			/>
		)
	}
}

MyTextInput.propTypes = {
	className: PropTypes.string,
	input: PropTypes.object,
	isRequired: PropTypes.bool,
	label: PropTypes.string,
	meta: PropTypes.object,
	name: PropTypes.string,
	onBlur: PropTypes.func,
	onChange: PropTypes.func,
	onFocus: PropTypes.func,
	options: PropTypes.object,
	resource: PropTypes.string,
	source: PropTypes.string,
	type: PropTypes.string,
	clearAlwaysVisible: PropTypes.bool,
	disabled: PropTypes.bool,
	shrink: PropTypes.bool,
	classes: PropTypes.object.isRequired,

}

MyTextInput.defaultProps = {
	onBlur: () => { },
	onChange: () => { },
	onFocus: () => { },
	options: {},
	type: 'text',
	resettable: false,
	clearAlwaysVisible: false,
	shrink: true,

}

const composed = compose(
	addField,
	withStyles(styles)
)(MyTextInput)

composed.defaultProps = {
	addLabel: true,
}
export default composed
