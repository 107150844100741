import React from 'react'
import { ContactField, ExtendedTextField, MyDateField, MyTextField, CardTitleField } from '../common/fields'
import { AuditedIcon } from '../../constants/icons'
import { DomainsType } from '../../constants/types'
import { MySimpleFormLayout } from '../layout'
import { Card, CardContent, Typography } from '@material-ui/core'
import CardChild from '../../CardChild'
import { Title } from './Domainsitems'
import { withStyles } from '@material-ui/core/styles'

const styles = ({
	card: {
		paddingTop: 10,
		flex: '1 auto'
	},
})

const CardShow = withStyles(styles)(({ classes, record } = this.props) => (
	<MySimpleFormLayout>
		<Card>
			<CardContent>
				<Typography variant="title">
					<Title record={record} />
				</Typography>
			</CardContent>
			<CardChild>
				<MySimpleFormLayout record={record}>
					<MyTextField label="fields.itemid" source="itemid" disabled />
					<MyTextField label="fields.label" source="label" isRequired />
					<ExtendedTextField label="fields.domainid" source="domainid" domain />
					<ExtendedTextField label="fields.langid" source="langid" domainid={DomainsType.LANGUAGE} />
					<MyTextField label="fields.properties" source="properties" isRequired />
				</MySimpleFormLayout>
			</CardChild>
		</Card>

		<Card className={classes.card}>
			<CardContent>
				<CardTitleField icon={<AuditedIcon />}>Gestion</CardTitleField>
			</CardContent>
			<CardChild>
				<MySimpleFormLayout record={record}>
					<MyDateField showTime={true} label="fields.createdon" source="createdon" />
					<ContactField label="fields.createdby" source="createdby" />
					<MyDateField showTime={true} label="fields.modifiedon" source="modifiedon" />
					<ContactField label="fields.modifiedby" source="modifiedby" />
				</MySimpleFormLayout>
			</CardChild>
		</Card>
	</MySimpleFormLayout>
))

export default withStyles(styles)(CardShow)
