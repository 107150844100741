import React from 'react'
import { Show, List, TextInput, Datagrid, Filter } from 'react-admin'
import { IntegerField, NameField } from '../common/fields'
import { DomainsItemsInput, DomainsInput } from '../common/inputs'
import { DomainsType } from '../../constants/types'
import { MyListActions, MyShowActions } from '../common/actions'
import { CardShow } from '.'
import { Card, CardHeader, CardContent, Typography } from '@material-ui/core';
import { PropertiesIcon } from '../../constants/icons';

const DomainsitemsFilter = props => (
	<Filter {...props}>
		<DomainsInput source="domainid" label="fields.domainid" withAll={false} options={{ style: { minWidth: '200px' } }} alwaysOn allowEmpty={false} />
		<DomainsItemsInput source="langid" label="fields.langid" domainid={DomainsType.LANGUAGE} withAll={false} options={{ style: { minWidth: '200px' } }} alwaysOn allowEmpty={false} />
		<TextInput label="fields.itemid" source="q" alwaysOn allowEmpty={false} resettable clearAlwaysVisible />
	</Filter>
)

const DomainsitemsList = (props) => (
	<List
		title="titles.domainsitemsList"
		actions={<MyListActions />}
		filters={<DomainsitemsFilter />}
		filterDefaultValues={{}}
		bulkActionButtons={false}
		perPage={25}
		sort={{}}
		{...props}
	>
		<Datagrid rowClick="show" expand={<ListExpand />}>
			<IntegerField source="domainid" label="fields.domainid" sortable={false} />
			<IntegerField source="langid" label="fields.langid" sortable={false} />
			<IntegerField source="itemid" label="fields.itemid" sortable={false} />
			<NameField source="label" label="fields.label" sortable={false} />
		</Datagrid>
	</List>
)

// eslint-disable-next-line react/prop-types
const ListExpand = ({ record }) => {
	if (!record && !record.properties)
		return null
	return (
		<Card>
			<CardHeader title="Propriétés" avatar={<PropertiesIcon />} />
			<CardContent>
				<Typography component="p">
					{record.properties}
				</Typography>
			</CardContent>
		</Card>
	)
}

export const Title = (props) => <NameField addId={true} addLabel={true} sourceId='itemid' sourceLabel='label' {...props} />

const DomainsitemsShow = props => (
	<Show title={<Title />} actions={<MyShowActions />} {...props}>
		<CardShow />
	</Show>
)

export { DomainsitemsList, DomainsitemsShow }