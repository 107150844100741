export const SHOW_LIST_FIELD = 'SHOW_LIST_FIELD'

export const showListField = (id) => ({
	type: SHOW_LIST_FIELD,
	payload: { id }
})

export const SHOW_LABEL_TAB = 'SHOW_LABEL_TAB'

export const showLabelTab = (label, pathname) => ({
	type: SHOW_LABEL_TAB,
	payload: {
		label,
		pathname
	}
})
