import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Url } from '../../../tools'
import { AspectRatio } from '../../../constants/types'
import LogoAlbum from '../../../pictures/iconImage.png'

//import LogoMovie from '../../../pictures/movieicon.png'

//src/pictures/defaultImage.png
class AvatarField extends Component {
	constructor() {
		super()
		this.handleLoad = this.handleLoad.bind(this)
		this.handleError = this.handleError.bind(this)
		this.state = { error: false, isLoaded: false }
	}
	// componentDidMount() {
	// 	const { src,size} = this.props
	// 	let tmpsrc = src
	//    if (null != size) {
	// 	   tmpsrc = tmpsrc + `?size=${size}x${size}`
	//    }
	// 	// Simple GET request using fetch
	// 	fetch(tmpsrc).then(response =>{ 
	// 			console.log(response)
	// 			response.json()})
 	// }
	handleLoad() {
		if (this.state.isLoaded)
			return
		this.setState({ isLoaded: true })
	}

	handleError() {
 		if (this.state.error)
			return
		this.setState({ error: true })
	}

	getDefault() {
		return   Url.defaultPictureByRatio(AspectRatio.COVER)
	}

	getSrc() {
		const { src, record, source, size } = this.props

		let tmpsrc = src

 		if (null === tmpsrc) {
			tmpsrc = record[source]
		}

		if (null != size) {
			tmpsrc = tmpsrc + `?size=${size}x${size}`
		}
		return null != tmpsrc ? tmpsrc : null
	}
	tryRequire = (path) => {
		try {
		 return require(path)
		} catch (err) {
		 return null
		}
	  }
	 
	render() {
		const { size } = this.props
		return (
			<img
				src={this.state.error ? LogoAlbum  :  this.getSrc()  }
				size={size}
				style={{ width: size, height: size }}
				onLoad={this.handleLoad}
				onError={this.handleError}
			/>  
			// <img src={this.getSrc() ? this.getSrc() : LogoAlbum }  size={size} style={{ width: size, height: size }}/>
		)
	}
}

AvatarField.propTypes = {
	src: PropTypes.string,
	record: PropTypes.object,
	source: PropTypes.string,
	size: PropTypes.number,
}

AvatarField.defaultProps = {
	src: LogoAlbum,
	record: {},
	source: 'avatar',
	size: 50,
}

export default AvatarField
