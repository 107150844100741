import React, { Fragment } from 'react'
import { SelectInput, TextInput } from 'react-admin'
import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import Typography from '@material-ui/core/Typography'
import CardChild from '../../../CardChild'
import RichTextInput from 'ra-input-rich-text'
import { DomainsItems } from '../../../tools'
import { withStyles } from '@material-ui/core/styles'

const styles = theme =>   ({
	cardPaddingMargin: {
		padding: 20,
		margin: 20,
		flex: '1 auto',
	},
})

const EditorialsCreate = withStyles(styles)(({ classes, record } = this.props) => (
	<Fragment>
		<Card className={classes.cardPaddingMargin}>
			<CardContent>
				<Typography variant="title">Éditorial du nouveau programme</Typography>
			</CardContent>
			<CardChild>
				<TextInput label="fields.movieid" source="movieid" disabled/>
				<SelectInput label="fields.langid" source="langid" choices={DomainsItems.getSelectList(1)} allowEmpty={false} />
				<TextInput label="fields.shorttitle" source="shorttitle" />
				<TextInput label="fields.title" source="title" />
				<RichTextInput label="fields.outline" source="outline" />
				<RichTextInput label="fields.shortSynopsis" source="shortSynopsis" />
				<RichTextInput label="fields.synopsis" source="synopsis" />
				<RichTextInput label="fields.pressReview" source="pressReview" />
				<TextInput label="fields.pressreviewauthor" source="pressReviewAuthor" />
			</CardChild>
		</Card>
	</Fragment>
))

export default EditorialsCreate
