import React from 'react'
import { SelectArrayInput } from 'react-admin'
import { isArrayLike } from '30-seconds-of-code'
import { Transfers } from '../../../tools'

const TransfersArrayInput = (props) => {
	const { items } = props

	let choices
	if (!isArrayLike(items))
		choices = Transfers.getSelectList(false, false)

	return (
		<SelectArrayInput choices={isArrayLike(choices) ? choices : []} {...props} />
	)
}

export default TransfersArrayInput