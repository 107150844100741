import React from 'react'
import { SelectArrayInput } from 'react-admin'
import { isArrayLike } from '30-seconds-of-code'
import { Services } from '../../../tools'

const ServicesArrayInput = (props) => {
	const { items } = props

	let choices
	if (!isArrayLike(items))
		choices = Services.getSelectList(false, 1 /*En ligne*/)

	return (
		<SelectArrayInput choices={isArrayLike(choices) ? choices : []} {...props} />
	)
}

export default ServicesArrayInput