import React, { Component } from 'react'
import { createTheme, withStyles, ThemeProvider } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import { CustomTextField, CustomSelect,CustomDisplayDialog, CustomAlert} from '../../layout/GlobalStyles'
import CompaniesAlbums from '../../../requests/CompaniesAlbums'
import { DomainsItems } from '../../../../src/tools'
import GestionFields from '../../common/fields/GestionFields'
import { Typography } from '@material-ui/core'
import AccountBalance from '@material-ui/icons/AccountBalance'
import { OnUpdateAlbumEditorial } from '../../../actions/companiesAlbums'
import { connect } from 'react-redux'
import compose from 'recompose/compose'
import Card from '@material-ui/core/Card'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'
import IconButton from '@material-ui/core/IconButton'
import AlbumInfoGlobal from './AlbumInfoGlobal'
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction'
import DeleteIcon from '@material-ui/icons/Delete'
import ListSubheader from '@material-ui/core/ListSubheader'
import CloseIcon from '@material-ui/icons/Close'
import InputBase from '@material-ui/core/InputBase'
import SearchIcon from '@material-ui/icons/Search'
import InputAdornment from '@material-ui/core/InputAdornment'
const styles = theme => ({
	container: {
		display: 'flex'
	},
	item: {
		marginRight: '1px',
		marginLeft: '2px',

	},
	formControl: {
		margin: '.5em .5em 1.5em 2.2em',
		minWidth: '230px',
		height: '25px'
	},
	customSelect: {
		//position: 'absolute',
		top: '8px',
		margin: theme.spacing(1),
		//padding:'6px 8px',
		maxWidth: '90px',
		minHeight: '40px',
		backgroundColor: '#fff',
		borderRadius: '4px',
	},
	listContainer: {
		backgroundColor: theme.palette.background.paper,
		position: 'relative',
		overflow: 'auto',
		maxHeight: 'calc(70vh - 100px)',
		minWidth: 'auto'
	},
})

class AlbumEditorialFiche extends Component {

	constructor(props) {
		super(props)
		this.state = {
			albums: [],
			types: [],
			childCount: [],
			Listproperties: [],
			editorials: [],
			initialEditorials: [],
			count: 0,
			langueListe: [],
			listeditoToupdate: [],
			edito: {
				albumid: 0,
				name: '',
				title: '',
				shorttitle: '',
				editorial: "<DETAIL><CATCHLINE></CATCHLINE><OUTLINE></OUTLINE><SYNOPSIS></SYNOPSIS><PACKSUMMARY></PACKSUMMARY></DETAIL>",
				properties: '<PROPS/>',
				langid: 2
			},
			editoToremove: {
				albumid: 0,
				name: '',
				title: '',
				shorttitle: '',
				editorial: "<DETAIL><CATCHLINE></CATCHLINE><OUTLINE></OUTLINE><SYNOPSIS></SYNOPSIS><PACKSUMMARY></PACKSUMMARY></DETAIL>",
				properties: '<PROPS/>',
				langid: 2
			},
			openErrorMessage: false,
			showError: false,
			show : false,
			paysFilter:{}
		}
	}

	getLangueListe = () => {
		return Object.values(DomainsItems.getItems(1))
	}
	componentDidMount() {
		const { record, OnUpdateAlbumEditorial, langId,type,create,albumEditorial } = this.props
		let edito = {
			albumid: record.id,
			name: '',
			title: '',
			shorttitle: '',
			editorial: "<DETAIL><CATCHLINE></CATCHLINE><OUTLINE></OUTLINE><SYNOPSIS></SYNOPSIS><PACKSUMMARY></PACKSUMMARY></DETAIL>",
			properties: '<PROPS/>',
			langid: 2,
		}
		OnUpdateAlbumEditorial(edito)

		CompaniesAlbums.getAlbumsEditorials(record.id, langId).then((json) => {
				if (json.data !== undefined) {
					this.setState({ editorials: json.data })
					this.setState({ initialEditorials: json.data })
					//OnUpdateAlbumEditorial(json.data)
					// if (json.data.length === 1)
					// 	this.setState({ edito: json.data[0] })
					if (!create)
						if(json.data.find(s=>s.langid===2)) {
							this.setState({edito: json.data.find(s=>s.langid === 2)})
							OnUpdateAlbumEditorial(json.data.find(s=>s.langid === 2))
						}
						if (create && type === 'edit'){
							this.setState({ edito: json.data.find(s=>s.langid === albumEditorial.langid) })
							OnUpdateAlbumEditorial(json.data.find(s=>s.langid === albumEditorial.langid))

						}
						// else
						// {
						// 	//this.setState({edito: json.data.find(s=>s.langid === 2)})
						// 	OnUpdateAlbumEditorial(edito)
						// }
				}
			})
		this.setState({ langueListe: this.getLangueListe() })
	}
	getMuiTheme = () => createTheme({
		overrides: {
			MuiGrid: {
				container: {
					//padding: '0px'
				}
			},
			MuiCardContent: {
				root: {
					'&:first-child': {
						paddingTop: 0,
						paddingBottom: 0,
						paddingLeft: 4,
						paddingRight: 4,
					},
				}
			},
			MuiTypography: {
				root: {
					paddingLeft: '10px',
				}
			},
			MuiFormLabel:{
				root:{
					"&$focused": {
						"color": "#288DB1"
					},
				}
			},
			MuiOutlinedInput: {
				'root': {  // - The Input-root, inside the TextField-root
					'&:hover fieldset': {
						borderColor: '#288DB1 !important', // - Set the Input border when parent has :hover
					},
					'&.Mui-focused fieldset': { // - Set the Input border when parent is focused 
						borderColor: '#288DB1 !important',
					},
				},
			} ,
			MuiMenu :{
				paper:{
					maxHeight: '30%'

				}}

		},
	})
	getValueFromProperties = (property, label) => {
		let parser = new DOMParser()
		let xmlDoc = parser.parseFromString(property, "text/xml")
		if (this.state.count === 0) this.setState({ count: 1 })

		if (xmlDoc.getElementsByTagName(label)[0] !== undefined && xmlDoc.getElementsByTagName(label)[0].childNodes[0] !== undefined) {
			return xmlDoc.getElementsByTagName(label)[0].childNodes[0].nodeValue
		}
		return ''
	}

	handleEditoClick = (event, value) => {
		const { create, type, OnUpdateAlbumEditorial } = this.props
		if (create && type === 'create')
			this.setState({ edito: {} })
		else
			this.setState({ edito: value })
		OnUpdateAlbumEditorial(value)

	}
	handleChangeGeneralItem = (event, field) => {
		const { create, OnUpdateAlbumEditorial, record } = this.props
		if (create) {
			let edito = this.state.edito
			if (edito.albumid !== record.id) edito.albumid = record.id
			if (field === 'Name') edito.name = event.target.value
			if (field === 'Title') edito.title = event.target.value
			if (field === 'langue') edito.langid = event.target.value
			if (field === 'shorttitle') edito.shorttitle = event.target.value
			if (field === 'CATCHLINE' || field === 'OUTLINE' || field === 'SYNOPSIS') {
				let parser = new DOMParser()
				const serializer = new XMLSerializer()
				let xmlDoc = parser.parseFromString(edito.editorial, "text/xml")
				if (xmlDoc.getElementsByTagName(field)[0] !== undefined && xmlDoc.getElementsByTagName(field)[0].childNodes[0] !== undefined) {
					xmlDoc.getElementsByTagName(field)[0].childNodes[0].nodeValue = event.target.value
				}
				else {
					let newField = xmlDoc.createElement(field)
					let newValue = xmlDoc.createTextNode(event.target.value)
					newField.appendChild(newValue)
					let fieldNode = xmlDoc.getElementsByTagName(field)[0]
					if (fieldNode)
						xmlDoc.documentElement.replaceChild(newField, fieldNode)

				}
				edito.editorial = serializer.serializeToString(xmlDoc)
			}
			this.setState({ edito: edito })
			
			OnUpdateAlbumEditorial(edito)
		}
	}
	handleCloseErrorMessage =() =>{
		this.setState({openErrorMessage: false})

	}
	handleRemoveEditoClick =()=>{
		const { record,langId } = this.props
		let Defaultedito = {
			albumid: record.id,
			name: '',
			title: '',
			shorttitle: '',
			editorial: "<DETAIL><CATCHLINE></CATCHLINE><OUTLINE></OUTLINE><SYNOPSIS></SYNOPSIS><PACKSUMMARY></PACKSUMMARY></DETAIL>",
			properties: '<PROPS/>',
			langid: 2,
		}
		//CompaniesAlbums.RemoveEdito(edito.albumid,edito.langid)
 		this.setState({openErrorMessage: false})
		new Promise((resolve, reject) => {
			CompaniesAlbums.RemoveEdito(this.state.editoToremove.albumid,this.state.editoToremove.langid).then((data) => {
				CompaniesAlbums.getAlbumsEditorials(record.id,langId).then((json) => {
					if (json.data !== undefined) {
						this.setState({ editorials: json.data })
						this.setState({ initialEditorials: json.data })
					}
				})
				OnUpdateAlbumEditorial(Defaultedito)
				this.setState({show : true})
				resolve()
			}
			).catch((error) => {
				reject()
				this.setState({showError : true})
 				const timeId = setTimeout(() => {
					this.setState({showError : false})
				}, 3000)
				return () => {
					clearTimeout(timeId)
				}
			})
		})
	

	}
	handleChangeInput = (event,type) => {
		this.setState({ searchValue: event.target.value })
		if(type === 'reset')
		{
			this.setState({ searchValue: '' })
			this.setState({editorials : this.state.initialEditorials })
 		}

	}
	handleChangeCountrie = (event)=>{
		console.log(event.target.value)
		this.setState({paysFilter: event.target.value})
	}
	searchItem =() =>{
		if(this.state.searchValue ==='') this.setState({editorials : this.state.initialEditorials})
 			else 
		if(this.state.editorials &&  this.state.searchValue !== ''){
			let res = []
			if(this.state.editorials.filter(s=>DomainsItems.getLabel(1, s.langid).toUpperCase().includes(this.state.searchValue.toUpperCase()))[0])
			res.push(this.state.editorials.filter(s=>DomainsItems.getLabel(1, s.langid).toUpperCase().includes(this.state.searchValue.toUpperCase()))[0])
			if(this.state.editorials.filter(s=>s.name.toUpperCase().includes(this.state.searchValue.toUpperCase())))
			this.state.editorials.filter(s=>s.name.toUpperCase().includes(this.state.searchValue.toUpperCase())).forEach((elt) =>
				res.push(elt)
			)
			this.setState({editorials : res})
		}
  	}
	SetopenErrorMessage =(event,value)=> {
		this.setState({editoToremove: value})
		this.setState({openErrorMessage: true})
	}
	handleOpenAlert = () => {
		this.setState({show:false})
		this.setState({showError : false})
		//setopenAlbumSaveEnd(false)
	}
	render() {
		const { classes, create, record,type,albumEditorial } = this.props
 		return (

			<div>
				<ThemeProvider theme={this.getMuiTheme()}>
				{this.state.show ? (<CustomAlert libelle="Editorial supprimé" severity="success" open={!this.state.showError} setOpen={this.handleOpenAlert} />) : null}
				{this.state.showError ? (<CustomAlert libelle="Erreur lors de la suppression" severity="error" open={this.state.showError} setOpen={this.handleOpenAlert} />) : null}
					<Grid container spacing={12} direction="row" justify="space-between" >
						<Grid item direction="row" xs={3}>
							<Card  >
								<List dense className={classes.listContainer} 
										subheader={
											<ListSubheader >
												{/* <InputBase
													style={{ marginLeft: '0.2rem', flex: 1, width: '80%',backgroundColor:'#8080800f' }}
													placeholder="Recherche par nom /langue"
													value={this.state.searchValue}
													onChange={e=>this.handleChangeInput(e,'')}
													onKeyPress={(e) => {
														if (e.key === 'Enter') {
															this.searchItem()
														}
													}}
														endAdornment = {<InputAdornment position="end" size="small">
															<IconButton
																aria-label='supprimer'
															>
																{ this.state.searchValue !==''? <CloseIcon onClick={(e)=> this.handleChangeInput(e,'reset')  } /> : <div></div>
																}
															</IconButton></InputAdornment>}
												
												/> */}
												{/* <Grid container direction="row">
													<Grid item xs='10'> 
														<CustomSelect label="Pays" propsType="domainItem" value={this.state.paysFilter} values={this.state.langueListe} handleChange={(e) => this.handleChangeCountrie(e)} fullWidth="true" />
 													</Grid>
													<Grid item xs='2'> 
														<IconButton type="button" aria-label="search" 
														onClick={() => this.searchItem()}
														style={{ marginRight: '5px' }}  >
															<SearchIcon />
														</IconButton>
													</Grid>
												</Grid> */}
											
												{/* <Grid container direction="row">
													<Grid item xs='10'> 
													<CustomTextField label="Recherche par nom /langue"  value={this.state.searchValue}  handleChange={e=>this.handleChangeInput(e, '')}  valueReset={(e) => this.handleChangeInput(e, 'reset')}
														create="true" handleKeyPress={e=>this.searchItem()} fullWidth="true" removeicon={true} /> 
													</Grid>
													<Grid item xs='2'> 
													<IconButton type="button" aria-label="search" onClick={() => this.searchItem()} style={{ marginRight: '20px' }}  >
														<SearchIcon />
													</IconButton>
													</Grid>
												</Grid> */}
											</ListSubheader>
										}
								>
									{this.state.editorials.map((value) => {
										return (
											<ListItem key={value} button
												disabled ={type === 'create' ||(create && this.state.edito !== value )}
												selected={this.state.edito === value}
												onClick={(event) => this.handleEditoClick(event, value)}
											>
												<ListItemText id={value.id} primary={  DomainsItems.getFormattedLabel(1, value.langid)  }  />
												<ListItemSecondaryAction >
													<IconButton title='Supprimer' disabled ={type === 'create' ||(create && this.state.edito !== value )}
													 edge="end" aria-label="delete" onClick={(event) => this.SetopenErrorMessage(event, value)} >
														<DeleteIcon />
													</IconButton>
												</ListItemSecondaryAction>
											</ListItem>

										);
									})}
								</List>

							</Card>
						</Grid>
						<Grid item xs={6} >
							<Grid item xs={12}>
								<AlbumInfoGlobal recordelt={record} create={false} />
							</Grid>
							<Grid item xs={12}>
								{!create ? <CustomTextField label="Langue" value={DomainsItems.getFormattedLabel(1, this.state.edito.langid)} fullWidth="true" /> :
									<CustomSelect propsType="domainItem" values={this.state.langueListe} value={this.state.edito.langid} label="Langue" handleChange={(e) => this.handleChangeGeneralItem(e, 'langue')} fullWidth={true} />
								}
								{/* <CustomTextField label="Langue" value={DomainsItems.getFormattedLabel(1, this.state.edito.langid)} fullWidth="true" /> */}
							</Grid>
							<Grid item xs={12}>
								<CustomTextField label="Nom" textFieldLenght={64} required={true} create={create} value={this.state.edito.name ? this.state.edito.name : ''} handleChange={(event, field) => this.handleChangeGeneralItem(event, 'Name')} fullWidth="true" />
							</Grid>
							<Grid item xs={12}>
								<CustomTextField label="Titre" textFieldLenght={250} required={true} create={create} value={this.state.edito.title ? this.state.edito.title : ''} handleChange={(event, field) => this.handleChangeGeneralItem(event, 'Title')} fullWidth="true" />
							</Grid>
							<Grid item xs={12}>
								<CustomTextField label="Titre court" textFieldLenght={120} create={create} value={this.state.edito.shorttitle ? this.state.edito.shorttitle : ''} handleChange={(event, field) => this.handleChangeGeneralItem(event, 'shorttitle')} fullWidth="true" />
							</Grid>
							<Grid item xs={12}>
								<Grid container direction="row">
									<AccountBalance style={{ paddingTop: '10px' }} /><Typography variant="title" style={{ paddingTop: '10px' }} > Détails</Typography>
								</Grid>
								<Grid container direction="row">
									<Grid item xs={12}>
										<CustomTextField label="Résumé court" textFieldLenght={180} create={create} value={this.getValueFromProperties(this.state.edito.editorial, 'CATCHLINE')} handleChange={(event, field) => this.handleChangeGeneralItem(event, 'CATCHLINE')} multiline={true} fullWidth="true" />
									</Grid>
									<Grid item xs={12}>
										<CustomTextField label="Résumé moyen" textFieldLenght={400} create={create} value={this.getValueFromProperties(this.state.edito.editorial, 'OUTLINE')} handleChange={(event, field) => this.handleChangeGeneralItem(event, 'OUTLINE')} multiline={true} fullWidth="true" />
									</Grid>
									<Grid item xs={12}>
										<CustomTextField label="Résumé long" create={create} textFieldLenght={850} value={this.getValueFromProperties(this.state.edito.editorial, 'SYNOPSIS')} handleChange={(event, field) => this.handleChangeGeneralItem(event, 'SYNOPSIS')} multiline={true} fullWidth="true" />
									</Grid>
									{/* <Grid item xs={12}>
											<CustomTextField label="Description du Pack" value={this.getValueFromProperties(this.state.editorials.editorial,'PACKSUMMARY')} multiline="true" fullWidth="true" />
										</Grid> */}
									<CustomDisplayDialog  info='Alerte' to={window.location.href } handleClose={this.handleCloseErrorMessage} handleSave={this.handleRemoveEditoClick} open={this.state.openErrorMessage}
										content={"Etes-vous sûr de vouloir supprimer cet editorial ?"} maxWidth="sm" />
								</Grid>
							</Grid>
						</Grid>
						<Grid item xs={3} direction="row" >
							<GestionFields classes={classes} record={this.state.edito} />
						</Grid>
					</Grid>
				</ThemeProvider >
			</div>
			// </Dialog>

		)
	}
}
const mapStateToProps = state => ({
	...state,
	albumEditorial: state.reducer.albums.albumEditorial,
	AllAlbums: state.reducer.albums.AllAlbums

})

const mapDispatchToProps = ({
	OnUpdateAlbumEditorial,
})
const enhance = compose(
	connect(mapStateToProps, mapDispatchToProps),
	withStyles(styles)
)
export default enhance(AlbumEditorialFiche)

