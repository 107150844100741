import { stringify } from 'query-string'
import { fetchUtils, GET_LIST, GET_ONE, GET_MANY, GET_MANY_REFERENCE, CREATE, UPDATE, UPDATE_MANY, DELETE, DELETE_MANY } from 'react-admin'
import { capitalize, isNil, prettyBytes } from '30-seconds-of-code'
import { DateFormatter } from './tools'
import { Logger, Token, DomainsItems } from './tools'

// TODO: refactoring
const defaultHttpClient = (url, options = {}) => {
	if (!options.headers) {
		options.headers = new Headers({ Accept: 'application/json' })
	}

	if (options.body instanceof URLSearchParams) {
		options.headers.set('Content-Type', 'application/x-www-form-urlencoded; charset=UTF-8')
	}

	const t = Token.getToken()
	if (null != t) {
		options.headers.set('Authorization', `Bearer ${t}`)
	}

	return fetchUtils.fetchJson(url, options)
}

function albumsToTree(data) {
	var i, current, parentid, allParents, roots
	if (data) {
		allParents = []
		roots = []
		for (i = 0; i < data.length; i++) {
			current = data[i]
			if (allParents[current.id]) {
				current = Object.assign(allParents[current.id], current)
			} else {
				current = Object.assign({ children: [] }, current)
			}
			allParents[current.id] = current

			parentid = current.parentid
			if (parentid === 0) {
				roots.push(current)
			} else {
				if (allParents[parentid]) {
					allParents[parentid].children.push(current)
				} else {
					allParents[parentid] = { children: [current] }
				}
			}
		}
		return {
			data: roots,
			total: roots.length
		}
	} else {
		return {
			data: [],
			total: 0
		}
	}
}

/**
 * Maps react-admin queries to a simple REST API
 *
 * The REST dialect is similar to the one of FakeRest
 * @see https://github.com/marmelab/FakeRest
 * @example
 * GET_LIST     => GET http://my.api.url/posts?sort=['title','ASC']&range=[0, 24]
 * GET_ONE      => GET http://my.api.url/posts/123
 * GET_MANY     => GET http://my.api.url/posts?filter={ids:[123,456,789]}
 * UPDATE       => PUT http://my.api.url/posts/123
 * CREATE       => POST http://my.api.url/posts/123
 * DELETE       => DELETE http://my.api.url/posts/123
 */
/* eslint-disable indent */
export default (apiUrl = process.env.REACT_APP_API, httpClient = defaultHttpClient) => {
	/**
   * @param {String} type One of the constants appearing at the top if this file, e.g. 'UPDATE'
   * @param {String} resource Name of the resource to fetch, e.g. 'posts'
   * @param {Object} params The data request params, depending on the type
   * @returns {Object} { url, options } The HTTP request parameters
   */
	const convertDataRequestToHTTP = (type, resource, params) => {
		let url = ''
		let request
		let query = {}
		let initialValues
		const options = {}
		const formData = new FormData()
		switch (type) {
			case GET_LIST: {
				const { page, perPage } = params.pagination
				const { q, mode, closed } = params.filter
				let { order } = params.filter
				const { field } = params.sort
				const id = window.location.href.split('=')[1]

				if (!order)
					order = 1

				switch (resource) {
					case 'Watcher/IngestionStatus':
						query = {
							typeid: params.filter.typeid,
							term: params.filter.q,
							pageSize: params.filter.perPage
						}
						break
					case 'Jobs/Supervision':
						query = {
							typeid: params.filter.typeid,
							stateid: params.filter.stateid,
							periodicity: params.filter.periodicityid
						}
						console.log(query)
						break
					// case 'DispatcherConfigurations':
					// 	query = {
					// 		domainId: params.filter.domainId,
					// 		langId: params.filter.langId,
					// 		jobStatus: params.filter.jobStatus
					// 	}
					// 	break
					case 'Domainsitems':
						query = {
							domainid: params.filter.domainid,
							langid: params.filter.langid,
							itemid: params.filter.q,
							pageNumber: page,
							pageSize: perPage,
						}
						break
					case 'Transfersrights':
						query = {
							id: params.filter.transferid,
							search: q,
							mode: mode,
							order: order,
							pageNumber: page,
							pageSize: perPage
						}
						options.headers = new Headers({ 'x-includes': 'Product' })
						break
					case 'Catalogbase/List':
						query = {
							serviceid: params.filter.serviceid,
							transferid: params.filter.transferid,
							movieid: params.filter.movieid,
							productid: params.filter.productid,
							albumid: params.filter.albumid,
							price: params.filter.price,
							discount: params.filter.discount,
							release: params.filter.release,
							until: params.filter.until,
							mediaid: params.filter.mediaid,
							submediaid: params.filter.submediaid,
							restricted: params.filter.restricted,
							ispack: params.filter.ispack,
							modecommid: params.filter.modecommid,
							order: order,
							pageNumber: page,
							pageSize: perPage
						}
						options.headers = new Headers({ 'x-order-fields': capitalize(field), 'x-order-sort': params.sort.order })
						break
					case 'Moviespriorities':
						query = {
							startdate: params.filter.startdate,
							pageNumber: page,
							pageSize: perPage
						}
						break
					case 'Transfersrightsprices/MoviesByPeriods':
						query = {
							movieid: params.filter.movieid,
						}
						break
					case 'Pricingslists':
						query = {
							pricingid: params.filter.pricingid,
							pageNumber: page,
							pageSize: perPage
						}
						options.headers = new Headers({ 'x-includes': 'Pricingline' })
						break
					case 'PricePlanning/Price':
						query = {
							objectId: params.filter.objectId,
							objectTypeId: params.filter.objectTypeId,
							pageNumber: page,
							pageSize: perPage
						}
						break
					case 'PricePlanning/TransfersRights':
						query = {
							objectId: params.filter.objectId,
							objectTypeId: params.filter.objectTypeId,
							pageNumber: page,
							pageSize: perPage
						}
						break
					case 'PublicationsTracking/Planning':
						query = {
							week: params.filter.week,
							startDate : params.filter.startDate,
							endDate : params.filter.endDate
						}
						break
					default:
						isNil(q) || isNil(mode)
							? (query = {
								pageNumber: page,
								pageSize: perPage
							})
							: (query = {
								id: 1,
								search: q,
								mode: mode,
								order: order,
								pageNumber: page,
								pageSize: perPage
							}) 
						break
				}

				switch (resource) {
					case 'Moviescontents':
					case 'Moviespictures':
						query = {
							pageNumber: page,
							pageSize: perPage
						}
						options.headers = new Headers({ 'x-order-fields': 'Typeid' })
						params.id !== undefined
							? (url = `${apiUrl}/${resource}/movieid=${params.id}?${stringify(query)}`)
							: (url = `${apiUrl}/${resource}/movieid=${id}`) // Don't overextend get back here
						break
					case 'Peoplespictures':
						params.id !== undefined
							? (url = `${apiUrl}/${resource}/peopleid=${params.id}`)
							: (url = `${apiUrl}/${resource}/peopleid=${id}`)
						break
					case 'Peoplesmovies':
						params.id !== undefined
							? (url = `${apiUrl}/${resource}?peopleid=${params.id}`)
							: (url = `${apiUrl}/${resource}?peopleid=${id}`)
						break
					case 'Supervision':
						url = `${apiUrl}/Companiesmovies/Search?${stringify(query)}`
						break
					// case 'Companiesalbums':
					// 	url = `${apiUrl}/Companiesalbums/SimpleList`
					// 	break
					case 'Companiesalbums/Search':
						url = `${apiUrl}/Companiesalbums/Search?${stringify(query)}`
						break
					case 'PromotionsVouchers/Search':
						url = `${apiUrl}/PromotionsVouchers/Search?id=${params.filter.id}&mode=${params.filter.mode}&${stringify({search : params.filter.wordSearch})}&dateGenStart=${params.filter.dateGenStart}&dateGenEnd=${params.filter.dateGenEnd}`
						break
					case 'Promotionssubscribers/Search':
						url = `${apiUrl}/Promotionssubscribers/Search?id=${params.filter.id}&mode=${params.filter.mode}&${stringify({search : params.filter.wordSearch})}`
						break
					case 'Contentsworkflow/Product':{
						let search = window.location.href.split('?')[1]
						url = `${apiUrl}/Contentsworkflow/Product?${search}`
						options.headers = new Headers({ 'x-includes': 'Content' })
						break
					}
					case 'Catalogbase/List':
						url = `${apiUrl}/${resource}/?${stringify(query)}`
						break
					case 'Domainsitems':
						url = `${apiUrl}/${resource}/List/?${stringify(query)}`
						break
					case 'Moviespriorities':
						url = `${apiUrl}/${resource}/TopPrio/?${stringify(query)}`
						break
					case "Companiesmovies":
						url = `${apiUrl}/Companiesmovies/Search?id=1&search=${params.filter.q}&mode=${params.filter.mode}`
						break 
					default:
						q === undefined || mode === undefined
							? (url = `${apiUrl}/${resource}?${stringify(query)}`)
							: (url = `${apiUrl}/${resource}/Search?${stringify(query)}`)
						break
				}
				break
			}
			case GET_ONE:
				url = `${apiUrl}/${resource}/${params.id}`
				switch (resource) {
					case 'Movieseditorials':
						url = `${apiUrl}/${resource}?movieid=${params.id}&langid=2`
						break
					case 'Pricingscodes/PriceInterval':
						url = `${apiUrl}/${resource}?typeId=${params.typeId}`
						break 
					case 'PricePlanning/TransfersRights':
						var paramsArray = params.id.split('_')
						url = `${apiUrl}/${resource}?objectId=${paramsArray[0]}&objectTypeId=${paramsArray[1]}`
						break
					case 'Peopleseditorials':
						url = `${apiUrl}/${resource}?peopleid=${params.id}&langid=2`
						break
					case 'Transfersrights':
						options.headers = new Headers({ 'x-includes': 'Product' })
						break
					case 'Supervision':
						url = `${apiUrl}/${resource}/Movie`
						options.method = 'POST'
						request = { movieid: params.id }
						options.body = JSON.stringify(request)
						break
					case 'Domainsitems':
						var array = params.id.split('_')
						url = `${apiUrl}/${resource}?domainid=${array[0]}&langid=${array[1]}&itemid=${array[2]}`
						break
					default:
						url = `${apiUrl}/${resource}/${params.id}`
						break
				}
				break
			case GET_MANY: {
				switch (resource) {
					case 'QuickSearch':
						url = `${apiUrl}/${resource}/?term=${params.term}`
						break
					case 'Companiesmovies':
						url = `${apiUrl}/${resource}/${params.ids[0]}`
						break
					default:{
						const query = { filter: JSON.stringify({ id: params.ids }) }
						url = `${apiUrl}/${resource}?${stringify(query)}`
						break
					}
				}
				break
			}
			case GET_MANY_REFERENCE: {
				const { page, perPage } = params.pagination
				const { field, order } = params.sort
				const query = {
					sort: JSON.stringify([field, order]),
					range: JSON.stringify([(page - 1) * perPage, page * perPage - 1]),
					filter: JSON.stringify({
						...params.filter,
						[params.target]: params.id
					})
				}
				url = `${apiUrl}/${resource}?${stringify(query)}`
				break
			}
			case UPDATE:
				options.method = 'PUT'
				switch (resource) {
					case 'Movieseditorials':
						url = `${apiUrl}/${resource}/${params.data.movieid}`
						params.data['modifiedby'] = parseInt(Token.getId(), 10)
						options.body = JSON.stringify(params.data)
						break
					case 'PricePlanning/TransfersRights':
						url = `${apiUrl}/${resource}/${params.id}`
						// const apiInput = {
						// 	values : params.values
						// }
						options.body = JSON.stringify(params.transfersRights)
						break
					case 'PricePlanning/TransfersRightsByPeriod':
					{
						url = `${apiUrl}/${resource}`
						const input = {
							values : params.values
						}
						options.body = JSON.stringify(input)
						break
					}
					case 'PricePlanning/UpdatePricingClass':
					{
						url = `${apiUrl}/${resource}/${params.id}`
						const input =  params.objectId +'_'+params.classeId
						options.body = JSON.stringify(input)
						break
					}
					case 'Companiespeoples':
					{
						const { id, companyid, roleid, civilityid, lastname, firstname, birthdate, deathdate, properties, recordid, score, createdon, createdby, modifiedby } = params.data
						url = `${apiUrl}/${resource}/${params.id}`
						params.data['modifiedby'] = parseInt(Token.getId(), 10)
						options.body = JSON.stringify({
							id,
							companyid,
							roleid,
							civilityid,
							lastname,
							firstname,
							birthdate,
							deathdate,
							properties,
							recordid,
							score,
							createdon,
							createdby,
							modifiedby
						})
						break
					}
					case 'Moviespictures':
						url = `${apiUrl}/${resource}/${params.id}`
						options.headers = new Headers({ enctype: 'multipart/form-data' })
						formData.append('image', params.data.image)
						initialValues = Object.entries(params.data).filter(value => value[0] !== 'image')
						initialValues.map(value => formData.append(value[0], value[1]))
						options.body = formData
						break
					default:
						if (null != params && null != params.id) url = `${apiUrl}/${resource}/${params.id}`
						else url = `${apiUrl}/${resource}`
						params.data['modifiedby'] = parseInt(Token.getId(), 10)
						options.body = JSON.stringify(params.data)
				}
				break
			case CREATE:
				url = `${apiUrl}/${resource}`
				options.method = 'POST'
				if (resource.split('/')[0] === 'PictureWizard') resource = 'PictureWizard'
				switch (resource) {
					case 'Moviescontents':
						params.data['createdby'] = parseInt(Token.getId(), 10)
						params.data['modifiedby'] = parseInt(Token.getId(), 10)
						options.body = JSON.stringify(params.data)
						break
					case 'Tools/Upload':
						options.headers = new Headers({ enctype: 'multipart/form-data' })
						options.body = params.data
						break
					case 'PictureWizard':
						options.headers = new Headers({ enctype: 'multipart/form-data' })
						options.body = params.data
						break
					case 'Dispatcherconfigjobs/ChangeDispatchConfigJobsstatus':{
						options.headers = new Headers({ enctype: 'multipart/form-data' })
						const dispatcherConfigIds = params.updatedRecord['dispatcherConfigJobsIds'].split('|')
						let recordId
						let serverId = '1'
						let dispatcherConfigJobsId = ''
						if(params.updatedRecord['serverName'] === 'serverOne'){
							dispatcherConfigJobsId = dispatcherConfigIds[0]
							recordId = params.updatedRecord['recordIdServerOne']
						} 
						else {
							dispatcherConfigJobsId = dispatcherConfigIds[1]
							recordId = params.updatedRecord['recordIdServerTwo']
							serverId = '2'
						}
						const currentRecord = {
							id: dispatcherConfigJobsId,
							ServerId: serverId,
							Jobtypeid: params.id,
							CurrentStatus: params.updatedRecord['CurrentStatus'],
							disabled: params.updatedRecord['NewStatus'],
							recordid: recordId
						}
						options.body = JSON.stringify(currentRecord)
						break
					}
					default:
						params.data['createdby'] = parseInt(Token.getId(), 10)
						params.data['modifiedby'] = parseInt(Token.getId(), 10)
						options.body = JSON.stringify(params.data)
				}
				break
			case DELETE:
				url = `${apiUrl}/${resource}/${params.id}`
				options.method = 'DELETE'
				break
			default:
				throw new Error(`Unsupported fetch action type ${type}`)
		}
		return { url, options }
	}

	/**
   * @param {Object} response HTTP response from fetch()
   * @param {String} type One of the constants appearing at the top if this file, e.g. 'UPDATE'
   * @param {String} resource Name of the resource to fetch, e.g. 'posts'
   * @param {Object} params The data request params, depending on the type
   * @returns {Object} Data response
   */
	/* eslint-disable indent */
	const convertHTTPResponse = (response, type, resource, params) => {
		const { json } = response
		let listJson
		switch (type) {
			case GET_LIST:
			case GET_MANY_REFERENCE:
				if (json.data.length > 0) {
					switch (resource) {
						case 'Moviescontents':
							listJson = Object.values(json.data).map(items => {
								return {
									...items,
									intTypeid: items.typeid,
									convertedDuration: DateFormatter.getFormattedDuration(items.duration),
									convertedSize: prettyBytes(items.size)
								}
							})
							return {
								data: listJson,
								total: parseInt(json.data.length, 10)
							}
						case 'Peoplespictures':
							listJson = Object.values(json.data).map(items => {
								return {
									...items,
									typeid: DomainsItems.getFormattedLabel(8, items.typeid),
									intTypeid: items.typeid
								}
							})
							return {
								data: listJson,
								total: parseInt(json.data.length, 10)
							}
						case 'Peoplesmovies':
							listJson = Object.values(json.data).map(items => {
								return {
									...items,
									id: items.movieid
								}
							})
							return {
								data: listJson,
								total: parseInt(json.data.length, 10)
							}
						case 'Domainsitems':
							listJson = Object.values(json.data).map(items => {
								return {
									...items,
									id: `${items.domainid}_${items.langid}_${items.itemid}`
								}
							})
							return {
								data: listJson,
								total: json.paging.totalItems,
							}
						case 'Moviespictures':
							listJson = Object.values(json.data).map(items => {
								return {
									...items,
									intTypeid: items.typeid
								}
							})
							return {
								data: listJson,
								total: parseInt(json.data.length, 10)
							}
						case 'Jobs/Supervision':
							return {
								data: json.data,
								total: parseInt(json.data.length, 10),
							}
						case 'PricePlanning/Price':
								listJson = Object.values(json.data).map(items => {
									return {
										...items
									}
								})
								return {
									data: listJson,
									total: parseInt(json.data.length, 10),
								}
						// case 'DispatcherConfigurations':
						// 	listJson = Object.values(json.data).map(items => {
						// 		let serverOneStatus = 'not configured'
						// 		let serverTwoStatus = 'not configured'
						// 		let recordIdServerOne
						// 		let recordIdServerTwo
						// 		let jobParamsServerOne
						// 		let jobParamsServerTwo
						// 		if(items.jobStatus === true){
						// 			items.jobStatus = 'enabled'
						// 		}
						// 		else{
						// 			items.jobStatus = 'disabled'
						// 		}
						// 		if(items.serverIds && items.serversStatus && items.recordIds){
						// 			const serversStatus = items.serversStatus.split('|')
						// 			serverOneStatus = (serversStatus[0] === '0') ? 'enabled' : 'disabled'
						// 			const recordIds = items.recordIds.split('|')
						// 			recordIdServerOne = recordIds[0]
						// 			if(serversStatus[1]){
						// 				serverTwoStatus = (serversStatus[1] === '0') ? 'enabled' : 'disabled'
						// 			}
						// 			if(recordIds[1]){
						// 				recordIdServerTwo = recordIds[1]
						// 			}
						// 		}
						// 		if(items.jobParams){
						// 			const jobParamsArray = items.jobParams.split('|')
						// 			jobParamsServerOne = jobParamsArray[0]
						// 			jobParamsServerTwo = jobParamsArray[1]
						// 		}
						// 		return {
						// 			...items,
						// 			serverOneStatus: serverOneStatus,
						// 			serverTwoStatus: serverTwoStatus,
						// 			recordIdServerOne: recordIdServerOne,
						// 			recordIdServerTwo: recordIdServerTwo,
						// 			jobParamsServerOne: jobParamsServerOne,
						// 			jobParamsServerTwo: jobParamsServerTwo
						// 		}
						// 	})
						// 	return {
						// 		data: listJson,
						// 		total: parseInt(json.data.length, 10),
						// 	}
						case 'Watcher/IngestionStatus':
							return {
								data: json.data,
								total: parseInt(json.data.length, 10),
							}
						case 'Moviespriorities/TopPrio':
							return {
								data: json.data,
								total: parseInt(json.paging.totalItems, 10),
							}
						case 'PricePlanning/TransfersRights':
							return {
								data: json.data,
								total: 10,
							}
						case 'Companiesalbums':
							return albumsToTree(json.data)
						case 'Pricingslists':
							listJson = Object.values(json.data).map(items => {
								return {
									...items,
									id: `${items.pricingid}_${items.pricinglineid}`
								}
							})
							return {
								data: listJson,
								total: json.paging.totalItems,
							}
						default:
							return {
								data: json.data,
								total: parseInt(json.paging.totalItems, 10)
							}
					}
				} else {
					return {
						data: json.data,
						total: parseInt(0, 10)
					}
				}
			case GET_ONE:
				switch (resource) {
					case 'Movieseditorials':
						return {
							data: {
								...json.data,
								id: json.data.movieid
							}
						}
					case 'Peopleseditorials':
						return {
							data: {
								...json.data,
								id: json.data.peopleid
							}
						}
					case 'DispatcherConfigurations':
							return {
								data: {
									...json.data,
									id: json.data.id
								}
							}
					case 'PricePlanning/TransfersRights':
						return {
							data : {
								...json.data,
								id : `${json.data.objectId}_${json.data.objectTypeId}`
							}
						}
					case 'Domainsitems':
						return {
							data: {
								...json.data,
								id: `${json.data.domainid}_${json.data.langid}_${json.data.itemid}`
							}
						}
					default:
						return { data: json.data }
				}
			case CREATE:
				if (null != json && null != json.data && null != json.id) 
				{

				return { data: json.data, id: json.id }}
				else {
					return { data: json.data }
				}
			case UPDATE:
				switch (resource) {
					case 'Movieseditorials':
						return {
							data: {
								...json.data,
								id: json.data.movieid
							}
						}
					case 'Peopleseditorials':
						return {
							data: {
								...json.data,
								id: json.data.peopleid
							}
						}
					default:
						return { data: json.data }
				}
			case GET_MANY:
				if (json.data.length > 0) {
					return {
						data: json.data,
						total: parseInt(json.paging.totalItems, 10)
					}
				}
				else {
					return { data: [ json.data ] }
				}

			default:
				return {
					status: response.status,
					statusText: response.statusText,
					data: json.data
				}
		}
	}

	/**
	* @param {string} type Request type, e.g GET_LIST
	* @param {string} resource Resource name, e.g. "posts"
	* @param {Object} payload Request parameters. Depends on the request type
	* @returns {Promise} the Promise for a data response
	*/
	return (type, resource, params) => {
		// return empty list for some resources when no parameters where sended
		if (type === GET_LIST) {
			let callApi = true
			if (resource === 'Companiesmovies') {
				callApi = (Object.keys(params).length > 0 && Object.keys(params.filter).length === 2)
			}
			if (resource === 'Companiesalbums') {
				callApi = (Object.keys(params).length > 0 && Object.keys(params.filter).length === 2)

			}
			else if (resource === 'Companiespeoples') {
				callApi = (Object.keys(params).length > 0 && Object.keys(params.filter).length === 2)
			}
			else if (resource === 'Companiesproducts') {
				callApi = (Object.keys(params).length > 0 && Object.keys(params.filter).length === 2)
			}
			else if (resource === 'Domainsitems') {
				callApi = (Object.keys(params).length > 0 && Object.keys(params.filter).length >= 1)
			}
			else if (resource === 'Catalogbase/List') {
				callApi = (Object.keys(params).length > 0 && Object.keys(params.filter).length >= 1)
			}

			if (!callApi) {
				Logger.debug('No filters, so we return an empty list')
				return new Promise(resolve => { return resolve({ data: [], total: 0 }) })
			}
		}

		// simple-rest doesn't handle filters on UPDATE route, so we fallback to calling UPDATE n times instead
		if (type === UPDATE_MANY) {
			return Promise.all(
				params.ids.map(id =>
					httpClient(`${apiUrl}/${resource}/${id}`, {
						method: 'PUT',
						body: JSON.stringify(params.data)
					})
				)
			).then(responses => ({
				data: responses.map(response => response.json)
			}))
		}

		// simple-rest doesn't handle filters on DELETE route, so we fallback to calling DELETE n times instead
		if (type === DELETE_MANY) {
			return Promise.all(
				params.ids.map(id =>
					httpClient(`${apiUrl}/${resource}/${id}`, {
						method: 'DELETE'
					})
				)
			).then(responses => ({
				data: responses.map(response => response.json)
			}))
		}

		const { url, options } = convertDataRequestToHTTP(type, resource, params)
		const resp = httpClient(url, options)
			.then(response => convertHTTPResponse(response, type, resource, params))
			.catch(error => {
				console.log('Fetch error: ' + error.message + ' status : ' + error.status)
 				var errorMessage = 'ra.notification.http_error'
				switch (error.message) {
				case 'Forbidden':
					errorMessage = 'ra.auth.sign_in_error'
					break
				case 'Unauthorized':
						errorMessage = 'ra.notification.logged_out'
						break
				case 'messages.fileNoLongerAvailable':
						errorMessage = 'ra.notification.item_doesnt_exist'
						break
				default:
					errorMessage = 'Erreur de chargement des données'
					break
				}
				const httpClientError = {
					message : errorMessage,
					status : error.status
				}
				// return Promise.reject(new Error(errorMessage))
				return Promise.reject(httpClientError)
			})
		return resp
	}
}
