import React, { PureComponent } from 'react'
import Typography from '@material-ui/core/Typography'


class PropertiesField extends PureComponent {
	getValueFromProperties = (property) => {
		let parser = new DOMParser()
		let xmlDoc = parser.parseFromString(property, "text/xml")
		let Properties = []
		let res =''
		if (xmlDoc.getElementsByTagName("PROPS")[0] !== undefined) {
			for (let i = 0; i < xmlDoc.getElementsByTagName("PROPS")[0].childNodes.length; i++) {
				let newProp = {
					name: xmlDoc.getElementsByTagName("PROPS")[0].childNodes[i].getAttribute("NAME"),
					value: xmlDoc.getElementsByTagName("PROPS")[0].childNodes[i].getAttribute("VALUE")
				}
				Properties.push(newProp)
			}
		}			
		if (Properties && Properties.length !== 0)  
			(Properties.forEach((elt) => (res += elt.name + ' = ' + elt.value + '\n')))
		return res
	}
	render() {
		const { propertiesItem } = this.props
		return (
			<Typography>
				{this.getValueFromProperties(propertiesItem)}
			</Typography>)
	}
}

export default PropertiesField
