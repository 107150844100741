import React from 'react'
import Toolbar from '@material-ui/core/Toolbar'
import Typography from '@material-ui/core/Typography'
import { connect } from 'react-redux'
import compose from 'recompose/compose'
import { translate } from 'react-admin'
import { Publicationstracking } from '../../../../requests'
import { Logger } from '../../../../tools'
import { onChangeUser, onChangeCategory, onChangeTaskType,onChangeSearch } from '../../../../actions/publicationTracking'
import Button from '@material-ui/core/Button'
import { CancelIcon, CheckIcon } from '../../../../constants/icons'
import { withStyles } from '@material-ui/core/styles'

const styles = theme =>  ({
	root: {
		paddingLeft: theme.spacing(2),
		paddingRight: theme.spacing(1)
	},
	highlight:{
		color: 'rgba(0, 0, 0, 0.87)',
		backgroundColor:'white',
		maxWidth:'500px'
	},
	title: {
		flex: '1 1 100%',
		marginLeft : '-650px',
		marginTop: '25px'
	},
	button: {
		margin: theme.spacing(1),
		width : '150px'
	},
	buttonCancel: {
		margin: theme.spacing(1),
		width : '150px',
		background: '#e23434'
	},
	buttonCheck: {
		margin: theme.spacing(1),
		width : '170px',
		background: '#0c9e0c'
	},
	leftIcon: {
		marginRight: theme.spacing(1),
	},
	dialogFooter: {
		flex: '0 0 auto',
		margin: '8px 4px',
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'flex-end',
		marginLeft : '300px',
		marginTop:'-100px'
	}
})
  
const EnhancedToolbar = (props) => {
	const { classes, numSelected, selectedIds, selectedUser, manageProcessLoader, initializeSelection,  initializePlanningData, closeModal, modalContent } = props
	const disagreeButtonText = modalContent.disagreeButtonText
	const agreeButtonText = modalContent.agreeButtonText

	const savePublication = (itemToBeUpdated) => {
		return new Promise((resolve, reject) => {
			Publicationstracking.savePublication(itemToBeUpdated).then(
				(json) => {
					Logger.log(json)
					resolve()
				}
			).catch((error)=>{
				Logger.debug(error)
				reject()
			})
		})
	}

	const handleAssignButtonClick = (event) => {
		if(selectedIds.length !== 0 ){
			manageProcessLoader(false, true, '')
			selectedIds.reduce((accumulatorPromise, value) => {
				const publication = {
					PlanningId : value,
					ContactId : selectedUser.id,
					Comments : '',
					CommentLevelId : 1
				}
				return accumulatorPromise.then(() => {
					return savePublication(publication)
				})
			}, Promise.resolve()).then(() =>{
				Logger.log('save ok')
				const resultText = {
					userName : selectedUser.gsm ,
					rowCount : numSelected
				}
				manageProcessLoader(true, true, resultText)
				initializePlanningData()
				initializeSelection()
				// onChangeCategory(null)
				// onChangeUser(null)
				// onChangeTaskType(null)
				// onChangeSearch(null)
			}).catch((error)=>{
				Logger.log('save ko')
				Logger.log(error)
			})
		}
	}

	return (
		<Toolbar
			className={classes.highlight}
		>
			<div className={classes.dialogFooter}>
					<Button onClick={(event) => handleAssignButtonClick(event)} disabled={numSelected === 0 ||  selectedUser ===null} color="primary" variant="contained" className={classes.buttonCheck}>
						<CheckIcon className={classes.leftIcon} />
						{agreeButtonText}
					</Button>
					<Button onClick={closeModal} color="secondary" variant="contained" className={classes.buttonCancel}>
						<CancelIcon className={classes.leftIcon} />
						{disagreeButtonText}
					</Button>
			</div>
				<Typography className={classes.title} color="inherit" variant="body1" component="div">
					{numSelected} selectionnés
				</Typography>
			
		</Toolbar>
	)
}

const mapStateToProps = state => ({
	...state,
	selectedUser : state.reducer.publicationTracking.selectedUser
})

const mapDispatchToProps = ({
	onChangeCategory,
	onChangeUser,
	onChangeTaskType,
	onChangeSearch
})

const enhance = compose(
	connect(mapStateToProps, mapDispatchToProps),
	withStyles(styles)
)

export default translate(enhance(EnhancedToolbar))