import React from 'react'
import { SimpleShowLayout, SelectField, TextField, RichTextField } from 'react-admin'
import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import Typography from '@material-ui/core/Typography'
import CardChild from '../../../CardChild'
import { ContactField, MyDateField } from '../../common/fields'
import { DomainsItems } from '../../../tools'
import { withStyles } from '@material-ui/core/styles'

const styles = theme =>   ({
	cardPaddingMargin: {
		padding: 20,
		margin: 20,
		flex: '10',
	},
	cardDiv: {
		flex: '1 auto',
	},
	flexContent: {
		display: 'flex',
		alignItems: 'flex-start',
	}
})

const EditorialsShow = withStyles(styles)(({ classes, record } = this.props) => (
	<div className={classes.flexContent}>
		<Card className={classes.cardPaddingMargin}>
			<CardChild>
				<SimpleShowLayout record={record}>
					<SelectField label="fields.langid" source="langid" choices={DomainsItems.getSelectList(1)} allowEmpty={false} />
					<TextField label="fields.birthplace" source="birthPlace" />
					<TextField label="fields.deathplace" source="deathPlace" />
					<RichTextField label="fields.biography" source="bioGraphy" />
				</SimpleShowLayout>
			</CardChild>
		</Card>
		<div className={classes.cardDiv}>
			<Card className={classes.cardPaddingMargin}>
				<CardContent>
					<Typography variant="title">Gestion</Typography>
				</CardContent>
				<CardChild>
					<SimpleShowLayout record={record}>
						<MyDateField showTime={true} label="fields.createdon" source="createdon" />
						<ContactField label="fields.createdby" source="createdby" />
						<MyDateField showTime={true} label="fields.modifiedon" source="modifiedon" />
						<ContactField label="fields.modifiedby" source="modifiedby" />
					</SimpleShowLayout>
				</CardChild>
			</Card>
		</div>
	</div>
))

export default EditorialsShow
