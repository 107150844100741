import { AspectRatio } from '../constants/types'
import picture from '../pictures/photoImage.png'
import defaultcover from '../pictures/defaultcover.png'

export default class Url {

	static defaultPicture = picture// process.env.REACT_APP_MEDIA + '/Portals/Web/Content/Images/defaultcover_43.png'
	static defaultCover = defaultcover
	static defaultPictureLarge = process.env.REACT_APP_MEDIA + '/Portals/Web/Content/Images/defaultcover_169.png'
	static defaultAvatar = process.env.REACT_APP_MEDIA + '/Portals/Web/Content/Images/defaultcover_43.png'

	static defaultPictureByRatio = (aspectRatio) => {
		if (AspectRatio.COVER === aspectRatio)
			return defaultcover// Url.defaultPicture
		if (AspectRatio.LANDMARK === aspectRatio)
			return picture//Url.defaultPictureLarge
		return picture//Url.defaultPicture
	}

	static pictureById = (id, type, filename) => {
		if (null == id || id <= 0 || null == filename || '' === filename)
			return Url.defaultCover

		return `${process.env.REACT_APP_MEDIA}/${type}/${id}/Pictures/${filename}`
	}

	static pictureByIdLarge = (id, type, filename) => {
		if (null == id || id <= 0 || null == filename || '' === filename)
			return Url.defaultPictureLarge

		return `${process.env.REACT_APP_MEDIA}/${type}/${id}/Pictures/${filename}`
	}

	static pictureByPath = (root, filename) => {
		if (null == root || '' === root || null == filename || '' === filename)
			return Url.defaultPicture

		return `${process.env.REACT_APP_MEDIA}/${root}/${filename}`
	}

	static pictureByType = (type, filename) => {
		if (null === filename || '' === filename)
			return null

		return `${process.env.REACT_APP_MEDIA}/${type}/${filename}`
	}

	static pictureMovie = (id, filename) => Url.pictureById(id, 'Movies', filename)
	static pictureMovieLarge = (id, filename) => Url.pictureByIdLarge(id, 'Movies', filename)
	static pictureAlbum = (id, filename) => Url.pictureById(id, 'Albums', filename)
	static pictureAlbumLarge = (id, filename) => Url.pictureByIdLarge(id, 'Albums', filename)
	static pictureCartouche = (filename) => Url.pictureByType('Cartouches', filename)
	static picturePeople = (id, filename) => Url.pictureById(id, 'Peoples', filename)
	static pictureBanner = (filename) => Url.pictureByPath('highlights/banners', filename)
	static pictureTuile = (filename) => Url.pictureByPath('highlights/tuiles', filename)
	static pictureIntro = (filename) => Url.pictureByPath('adverts/intros', filename)
	static pictureHeader = (filename) => Url.pictureByPath('adverts/headers', filename)

	static avatarMovie = (id) => Url.pictureById(id, 'Movies', `${id}_1_150x200.jpg`)
	static avatarAlbum = (id) => Url.pictureById(id, 'Albums', `${id}_295x165.jpg`)
	static avatarPeople = (id) => Url.pictureById(id, 'Peoples', `${id}_613.jpg`)
}
