import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-admin'
import AvatarComplexField from './AvatarComplexField'
import { Url, Links } from '../../../tools'
import { getId } from '../../../tools/utils'
import { withStyles } from '@material-ui/core/styles'

const styles = {
	// MuiAvatar:{
	// 	root:{
	// 		borderRadius : 'unset'
	// 	}
	// }
}

const AlbumField = props => {
	const { sourceId, sourceLabel, isLink  } = props
	const id = getId(props, sourceId)
	const field = <AvatarComplexField
		src={Url.avatarAlbum(id)}
		sourceId={sourceId}
		sourceLabel={sourceLabel}
		{...props} />
	return (
		<Fragment>
			{
				isLink ?
					<Link to={Links.CompaniesalbumsShow(id)}>
						{field}
					</Link>
					:
					field
			}
		</Fragment>
	)
}

AlbumField.propTypes = {
	sourceId: PropTypes.string,
	sourceLabel: PropTypes.string,
	addId: PropTypes.bool,
	addLabel: PropTypes.bool,
	isLink: PropTypes.bool,
	addAvatar: PropTypes.bool,
	classes: PropTypes.object.isRequired
}

AlbumField.defaultProps = {
	sourceId: 'id',
	sourceLabel: 'name',
	addId: false,
	addLabel: true,
	isLink: false,
	addAvatar: false,
}

export default withStyles(styles)(AlbumField)
