import React, { Children } from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import MyCardContentInner from './MyCardContentInner'
import { LabeledInput } from '../common/inputs'

const sanitizeRestProps = ({
	children,
	className,
	record,
	resource,
	basePath,
	version,
	initialValues,
	translate,
	...rest
}) => rest

export const MySimpleFormLayout = ({
	basePath,
	className,
	children,
	record,
	resource,
	version,
	...rest
}) => (
	<MyCardContentInner
		className={className}
		key={version}
		{...sanitizeRestProps(rest)}
	>
		{Children.map(
			children,
			field =>
				field ? (
					<div
						key={field.props.source}
						className={classnames(
							`ra-field ra-field-${field.props.source}`,
							field.props.className
						)}
					>
						{field.props.addLabel ? (
							<LabeledInput
								record={record}
								resource={resource}
								basePath={basePath}
								label={field.props.label}
								source={field.props.source}
								isRequired={field.props.isRequired}
							>
								{field}
							</LabeledInput>
						) : typeof field.type === 'string' ? (
							field
						) : (
							React.cloneElement(field, {
								record,
								resource,
								basePath,
							})
						)}
					</div>
				) : null
		)}
	</MyCardContentInner>
)

MySimpleFormLayout.propTypes = {
	basePath: PropTypes.string,
	className: PropTypes.string,
	children: PropTypes.node,
	record: PropTypes.object,
	resource: PropTypes.string,
	version: PropTypes.number,
}

export default MySimpleFormLayout