import React from 'react'
import Chip from '@material-ui/core/Chip'
import Tooltip from '@material-ui/core/Tooltip'
import { withStyles } from '@material-ui/core/styles'

const styles = {
	chip: {
	},
}

const ChipCustom = withStyles(styles)(({avatar, label, tooltip, classes}) => {

	const chip = <Chip
		avatar={null != avatar ? avatar : null}
		label={label}
		clickable
		className={classes.chip} />

	if (null == tooltip)				
		return (chip)
	else
		return (<Tooltip title={tooltip}>{chip}</Tooltip>)
})

export default ChipCustom