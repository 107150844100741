import React, { Component } from 'react'

let styles = {
	titleNotice: {
		color : ''
	},
	paddingLeft : '20px'
}

class MovieTitleCell extends Component{
	// constructor(props) {
	// 	super(props)
	// }

	render(){
		const { label, customWidth, titleCommentText, titleCommentColor } = this.props
		if(titleCommentText !== null){
			styles.titleNotice.color = titleCommentColor !== '' ? titleCommentColor : 'black'
			return(
				<div style={{ fontWeight : 'bold', width: customWidth,textAlign: 'left' ,paddingLeft : '5px'}}>
					{label}
					<div 
						style = {{
							...styles.titleNotice
						}}
					>
						{titleCommentText}
					</div>
				</div>
			)
		}
		else{
			return(
				<div style={{ fontWeight : 'bold', width: customWidth ,textAlign: 'left' ,paddingLeft : '5px'}}>
					{label}
				</div>
			)
		}
	}
}

export default MovieTitleCell