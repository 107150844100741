/* eslint-disable no-mixed-spaces-and-tabs */
import React, { Component  } from 'react'
import MUIDataTable from 'mui-datatables'
import { connect } from 'react-redux'
import compose from 'recompose/compose'
import classnames from 'classnames'
import { PublicationTrackingReferenceData, DomainsItems, DateFormatter, Contacts as ContactsTools } from '../../tools'
import ReferenceDataCell from './fields/ReferenceDataCell'
import MovieTitleCell from './fields/MovieTitleCell'
import DateCell from './fields/DateCell'
import PriceCell from './fields/PriceCell'
import WeekSelector from './fields/WeekSelector'
import CustomFooter from './fields/CustomFooter'
import LaunchExcelImport from './fields/LaunchExcelImport'
import PlanningUpload from './fields/PlanningUpload'
import CustomFilter from './fields/CustomFilter'
import ValidateUpdate from './fields/ValidateUpdate'
import Preparation from './fields/Preparation'
import UserCell from './fields/UserCell'
import PublicationStats from './fields/PublicationStats'
import ResponsibleColumnHeader from './fields/ResponsibleColumnHeader'
import PreparationBoardLauncher from './fields/PreparationBoardLauncher'
import { loadingDomainsItems } from '../../actions/domainsItems'
import { convertDataToMaterialTableRow, getDistinctDate, getDistinctItem } from './utilities/publicationTrackingUtilities'
import { translate } from 'react-admin'
import red from '@material-ui/core/colors/red'
import grey from '@material-ui/core/colors/grey'
import { onChangeWeek, onChangeYear, onLoadPlanning } from '../../actions/publicationTracking'
import { Publicationstracking } from '../../requests'
import ValidateDelete from './fields/ValidateDelete'
import ParamWeekResponible from './fields/ParamWeekResponible'
import ReferentContent from './fields/ReferentContent'
import { yellow } from '@material-ui/core/colors'
import PrioColumnHeader from './fields/PrioColumnHeader'
import SendEmail from './fields/SendEmail'
import CustomSort from './fields/CustomSort'
import { createTheme, withStyles, ThemeProvider, } from '@material-ui/core/styles'
import LinearProgress from '@material-ui/core/LinearProgress'
import CircularProgress from '@material-ui/core/CircularProgress'
import Button from '@material-ui/core/Button'
import Tooltip from '@material-ui/core/Tooltip'
import RefreshIcon from '@material-ui/icons/Refresh'
const customStyles = ({
	root: {
		//width: '95%',
		//backgroundColor: '#232c31',
		padding: '2px',
		paddingRight: '1%',
		height: '500px'
	},
	UpdatedRow: {
		'& td': { backgroundColor: yellow[100] }
	},
	DeletedRow: {
		'& td': {
			backgroundColor: red[200],
			'text-decoration': 'line-through'
		}
	},

	SeasonRow: {
		'& td': { backgroundColor: grey[400] }
	},
	NameCell: {
		//   fontWeight: 90,
		//   backgroundColor : 'green'
	},
	publicationPlanningHeader: {
		display: 'flex',
		width: '100%',
		//backgroundColor: '#232c31',

	},
	circularProgress: {
		position: 'absolute',
		top: '50%',
		left: '50%',
		zIndex: '10000'
	}
})
class Planning extends Component  {
	constructor(props) {
		super(props)
		this.state = {
			denseTable: true,
			stacked: true,
			users: [],
			badgeVisible: true,
			roles: [],
			PrioCount: 0,
			lockPrioUpdate: false,
			defaultTri: true,
			weekvalue: props.weekNumber,
			yearvalue: props.yearNumber,
			loading: true,
			rowPerPage: 0,
			pageNumber: 0,
			isLoading: false
		}
	}
	shouldComponentUpdate(nextProps, nextState) {
		return this.props.planningData !== nextProps.planningData
			|| this.props.weekNumber !== nextProps.weekNumber
			|| this.props.isUpdatePlanningTri !== nextProps.isUpdatePlanningTri
			|| this.state.roles !== nextState.roles
			? true : false
	}

	componentDidMount() {
		const { onChangeWeek, onChangeYear, onLoadPlanning } = this.props
 		let weekNumber = DateFormatter.getNumberOfWeek()
		const week = Number.parseInt(weekNumber.toString().substring(4, 6).length === 2 ? weekNumber.toString().substring(4, 6) : '0' + weekNumber.toString().substring(4, 6))
		onChangeWeek(this.state.weekvalue !== 0 ? this.state.weekvalue : week)
		onChangeYear(this.state.yearvalue !== 0 ? this.state.yearvalue : Number.parseInt(weekNumber.toString().substring(0, 4)))
		weekNumber = (this.state.weekvalue !== 0 && this.state.yearvalue !== 0) ? Number.parseInt(this.state.yearvalue.toString() + (this.state.weekvalue.toString().length === 2 ? this.state.weekvalue.toString() : '0' + this.state.weekvalue.toString())) :
		(Number.parseInt(weekNumber.toString().substring(0, 4) + week.toString()))
		Publicationstracking.getPlanning(weekNumber).then((json) => {
			const trackingData = json.data
			let planningToBeSaved = {
				publicationsplannings: trackingData.publicationsplannings,
				publicationsstats: []
			}
			const users = ContactsTools.getContactsByGroupid(160)
			let distribution = {
				pgm: trackingData.publicationsplannings.filter(x => x.publications !== null && (x.taskTypeId === 14 || x.taskTypeId === 16 || x.taskTypeId === 17 || x.taskTypeId === 27 || x.taskTypeId === 28)),
				ouv: trackingData.publicationsplannings.filter(x => x.publications !== null && (x.taskTypeId === 21 || x.taskTypeId === 22)),
				susp: trackingData.publicationsplannings.filter(x => x.publications !== null && (x.taskTypeId === 11 || x.taskTypeId === 12 || x.taskTypeId === 13 || x.taskTypeId === 24 || x.taskTypeId === 25)),
				chgtprix: trackingData.publicationsplannings.filter(x => x.publications !== null && (x.taskTypeId === 4 || x.taskTypeId === 6 || x.taskTypeId === 7)),
				autre: trackingData.publicationsplannings.filter(x => x.publications !== null && ((x.taskTypeId >= 1 && x.taskTypeId <= 3) || x.taskTypeId === null)),

			}

			if (trackingData.publicationsstats !== null && trackingData.publicationsstats.length !== 0) {
				trackingData.publicationsstats.forEach(item => {
					const currentUser = users.find(u => u.id === item.contactId)
					const stat = {
						contactName: currentUser !== undefined ? currentUser.gsm : "",
						publicationsCount: item.publicationsCount,
						pgm: distribution.pgm !== undefined ? distribution.pgm.filter(x => x.publications.contactId === item.contactId).length : 0,
						ouv: distribution.ouv !== undefined ? distribution.ouv.filter(x => x.publications.contactId === item.contactId).length : 0,
						susp: distribution.susp !== undefined ? distribution.susp.filter(x => x.publications.contactId === item.contactId).length : 0,
						chgtprix: distribution.chgtprix !== undefined ? distribution.chgtprix.filter(x => x.publications.contactId === item.contactId).length : 0,
						autre: distribution.autre !== undefined ? distribution.autre.filter(x => x.publications.contactId === item.contactId).length : 0,
						publicationPercent: Number((item.publicationsCount * 100 / trackingData.publicationsplannings.length).toFixed(1))
					}
					planningToBeSaved.publicationsstats.push(stat)
				})
			}
			this.setState({ isLoading: false })
			onLoadPlanning(planningToBeSaved)

		})
		Publicationstracking.getWeekResponible(weekNumber).then((json) => {
			this.setState({ roles: json.data })
		})
	}
	// componentDidUpdate(previousProps, previousState) {
	// 		if(previousProps !== this.props)
	// 		{
	// 			const { weekNumber,yearNumber, planningData} = this.props
	// 			if(previousProps.weekNumber !== this.props.weekNumber || previousProps.yearNumber !== this.props.yearNumber){
	// 				load=0
	// 			}
	// 		}
	// }
	getMuiTheme = () => createTheme({
		overrides: {
			MuiPaper: {
				root: {
					backgroundColor: '#FFF',
				}
			},
			MUIDataTable: {
				responsiveScroll: {
					//overflow:'auto',
					minWidth: 'calc(850px + 100vw - 1024px)',
					maxWidth: 'calc(950px + 100vw - 1024px)',
					minHeight: 'calc(80vh - 100px)',//'680px',
				},
				root: {
					backgroundColor: '#AAF',
				},
				paper: {
					boxShadow: 'none',
					width: 'fit-content',
					marginTop: '5px'
					//minHeight:'calc(100vh - 70px)'
					//width: '95%',
				}
			},
			MUITable: {
				root: {
					//borderCollapse: 'inherit !important'
				}
			},
			MuiTableCell: {
				root: {
					textAlign: 'center',
					lineHeight: '1em'
				},
			},
			MuiIconButton: {
				root: {
					padding: '10px',
				}
			},
			MUIDataTableBodyCell: {
				root: {
					//backgroundColor: '#FFF',
					padding: '0px',
					//paddingTop: '6px',
					boxShadow: 'inset 0 1px 0 gey, inset 0 2px 0 white',
					lineHeight: '1em',
					fontSize: '0.8125rem'
				}
			},
			MUIDataTableHeadCell: {
				data: {
					color: '#0000008a',
					fontWeight: 'bold',
					fontSize: '0.825em',
					lineHeight: '1em'
				},

				root: {
					backgroundColor: '#FFF',
					padding: '6px',
					'&:nth-child(1)': {
						width: '5%',
						//backgroundColor: '#FFF',
						padding: '1px',
						position: 'sticky',
						top: '0',
						zIndex: '1',
						color: '#0000008a',
						fontWeight: 'bold',
						fontSize: '0.825em',
						backgroundColor: '#FFF',
						borderBottom: '1px solid rgba(224, 224, 224, 1)'
					},
					//position: '-webkit-sticky',
					position: 'sticky',
					top: '0',
					zIndex: '0',
					color: '#0000008a',
					fontWeight: 'bold',
					fontSize: '0.825em',

				}
			},
			MuiDialog: {
				paperWidthSm: {
					minWidth: '400px',
					maxWidth: '800px',
					backgroundColor: '#fff'// '#232c31'
				}
			},
			MUIDataTableToolbar: {
				actions: {
					position: 'sticky',
					right: '0px',
					bottom: '0px',
					flex: 'unset',
				}
			},
			MuiCircularProgress :{
				colorPrimary :{
					color :'#288DB1',
					animationDuration: "550ms"
				}
			}
			// MuiAutocomplete :{
			// 	endAdornment:{
			// 		top: 'Calc(50% - 30px)'
			// 	}}
		},
	})
	responsibleCheck = (datatable) => {
		const results = ((datatable.filter(x => x.userId === 999999)).length !== 0)
		return results
	}
	onSortClick = () => {
		this.setState({ defaultTri: !this.state.defaultTri })
	}
	RefreshContent = () => {
		const { onLoadPlanning, weekNumber, yearNumber } = this.props
			const weekFilter = Number.parseInt(`${yearNumber.toString()}${weekNumber.toString().length === 2 ? weekNumber.toString() : '0' + weekNumber.toString()}`)
			Publicationstracking.getPlanning(weekFilter).then((json) => {
				const trackingData = json.data
				let planningToBeSaved = {
					publicationsplannings: trackingData.publicationsplannings,
					publicationsstats: []
				}
				const users = ContactsTools.getContactsByGroupid(160)
				let distribution = {
					pgm: trackingData.publicationsplannings.filter(x => x.publications !== null && (x.taskTypeId === 14 || x.taskTypeId === 16 || x.taskTypeId === 17 || x.taskTypeId === 27 || x.taskTypeId === 28)),
					ouv: trackingData.publicationsplannings.filter(x => x.publications !== null && (x.taskTypeId === 21 || x.taskTypeId === 22)),
					susp: trackingData.publicationsplannings.filter(x => x.publications !== null && (x.taskTypeId === 11 || x.taskTypeId === 12 || x.taskTypeId === 13 || x.taskTypeId === 24 || x.taskTypeId === 25)),
					chgtprix: trackingData.publicationsplannings.filter(x => x.publications !== null && (x.taskTypeId && PublicationTrackingReferenceData.getTasksTypes().find(r => r.itemid === x.taskTypeId).taskGroupDomainItemId === 7)),
					autre: trackingData.publicationsplannings.filter(x => x.publications !== null && ((x.taskTypeId >= 1 && x.taskTypeId <= 3) || x.taskTypeId === null)),

				}

				if (trackingData.publicationsstats !== null && trackingData.publicationsstats.length !== 0) {
					trackingData.publicationsstats.forEach(item => {
						const currentUser = users.find(u => u.id === item.contactId)
						const stat = {
							contactName: currentUser !== undefined ? currentUser.gsm : "",
							publicationsCount: item.publicationsCount,
							pgm: distribution.pgm !== undefined ? distribution.pgm.filter(x => x.publications.contactId === item.contactId).length : 0,
							ouv: distribution.ouv !== undefined ? distribution.ouv.filter(x => x.publications.contactId === item.contactId).length : 0,
							susp: distribution.susp !== undefined ? distribution.susp.filter(x => x.publications.contactId === item.contactId).length : 0,
							chgtprix: distribution.chgtprix !== undefined ? distribution.chgtprix.filter(x => x.publications.contactId === item.contactId).length : 0,
							autre: distribution.autre !== undefined ? distribution.autre.filter(x => x.publications.contactId === item.contactId).length : 0,
							publicationPercent: Number((item.publicationsCount * 100 / trackingData.publicationsplannings.length).toFixed(1))
						}
						planningToBeSaved.publicationsstats.push(stat)
					})
				}
				this.setState({ isLoading: false })
				onLoadPlanning(planningToBeSaved)

			})

	}
	render() {
		const { domainsItemsLoaded, planningData, loadingDomainsItems, translate, weekNumber, yearNumber, classes, isUpdatePlanningTri } = this.props
		const weekFilter = Number.parseInt(`${yearNumber.toString()}${weekNumber.toString().length === 2 ? weekNumber.toString() : '0' + weekNumber.toString()}`)
		const users = ContactsTools.getContactsByGroupid(160)
		if (domainsItemsLoaded === true || DomainsItems.exists()) {
			const dataFromRedux = planningData
			const validDataFromRedux = dataFromRedux.filter(item => item.week === weekFilter)
			const count = validDataFromRedux.filter(x => x.publications === null).length
			const PrioCount = validDataFromRedux.filter(x => x.prio !== 0).length
 			if (domainsItemsLoaded === false) loadingDomainsItems(true)
			const data = convertDataToMaterialTableRow(validDataFromRedux, isUpdatePlanningTri)
			const visible = this.responsibleCheck(data)
			const distinctDates = getDistinctDate(data)
			const categories = PublicationTrackingReferenceData.getCategories()
			const taskGroup = PublicationTrackingReferenceData.getTasksGroups()
			const tasktypes = PublicationTrackingReferenceData.getTasksTypes()
			const versions = PublicationTrackingReferenceData.getVersions()
			const weekCategories = getDistinctItem(data, 'category', categories)
			const weektasktype = getDistinctItem(data, 'tasktype', tasktypes)
			const weekResponible = getDistinctItem(data, 'responsable', users)
			const weektaskGroup = getDistinctItem(data, 'taskGroup', taskGroup)

			const columns = [
				{
					name: 'id',
					label: ' ',
					options: {
						setCellHeaderProps: () => ({
							style: {
								fontWeight: 'bold'
							}
						}),
						filter: false,
						search: false,
						customBodyRender: (value, tableMeta) => {
							if ((tableMeta.rowData[28] === 'updated') && tableMeta.rowData[30] !== 1) {
								const validationType = tableMeta.rowData[28] === 'updated' ? 'update' : 'delete'
								return <ValidateUpdate movieId={value} movieTitle={tableMeta.rowData[10]} validationType={validationType} />
							}
							else if ((tableMeta.rowData[28] === 'deleted') && tableMeta.rowData[30] !== 1) {
								const validationType = tableMeta.rowData[28] === 'updated' ? 'update' : 'delete'
								return <ValidateDelete movieId={value} movieTitle={tableMeta.rowData[10]} validationType={validationType} />
							}
							else {
								if (tableMeta.rowData[28] === 'new') {
									const validationType = 'new'
									return <ValidateUpdate validationType={validationType} />
								}
								else
									return <div></div>
							}
						},
					}
				},
				{
					name: 'userId',
					label: 'Responsable',
					options: {
						setCellHeaderProps: () => ({ style: { fontWeight: 'bold', zIndex: '0' } }),
						filter: true,
						filterType: 'custom',
						customFilterListOptions: {
							render: v => {
								let res = ''
								v.forEach(element => {
									const selectedUser = users.find(x => x.id === element)
									res += selectedUser !== undefined ? selectedUser.gsm + ', ' : ''
								});
								return `Responsable: ${res}`
							}
						},
						filterOptions: {
							logic: (user, filters) => {
								if (filters.length) return !filters.includes(user)
								return false
							},
							display: (filterList, onChange, index, column) => {
								let optionsValues = []
								optionsValues.push({
									value: 999999,
									label: 'vide'
								})
								weekResponible.forEach(item => {
									const optionValue = {
										value: item.id,
										label: item.gsm
									}
									optionsValues.push(optionValue)
								})
								const defaultLabel = 'Responsable'
								return <CustomFilter filterList={filterList} onChange={onChange} index={index} column={column}
									optionsValues={optionsValues} defaultLabel={defaultLabel} />
							}
						},
						customBodyRender: (value, tableMeta) => {
							const width = 80
							const users = ContactsTools.getContactsByGroupid(160)
							const user = users.find(u => u.id === value)
							return <UserCell user={user} users={users} planningId={tableMeta.rowData[0]} pageType='planning' customWidth={width} />
						},
						customHeadRender: (columnMeta) => (
							<ResponsibleColumnHeader label={columnMeta.label} />
						)
					}
				},
				{
					name: 'control',
					label: 'Control',
					options: {
						setCellHeaderProps: () => ({ style: { fontWeight: 'bold', zIndex: '0' } }),
						filter: false,
						search: false,
						display: 'excluded'
					}
				},
				{
					name: 'week',
					label: 'Semaine',
					options: {
						setCellHeaderProps: () => ({ style: { fontWeight: 'bold', zIndex: '0' } }),
						filter: false,
						search: false,
						display: 'excluded'
					}
				},
				{
					name: 'affair',
					label: 'Num Affaire',
					options: {
						setCellHeaderProps: () => ({ style: { fontWeight: 'bold', minWidth: '25px', zIndex: '0' } }),
						filter: false,
					}
				},
				{
					name: 'pgm',
					label: 'N° Prog.',
					options: {
						setCellHeaderProps: () => ({ style: { fontWeight: 'bold', textAlign: 'center', minWidth: '45px', zIndex: '0' } }),
						filter: false,
					},
					customBodyRender: () => ({ style: { textAlign: 'center' } })
				},
				{
					name: 'planningDate',
					label: 'Date',
					options: {
						setCellHeaderProps: () => ({ style: { fontWeight: 'bold', textAlign: 'center', minWidth: '38px', zIndex: '0' } }),
						filter: true,
						filterType: 'custom',
						customFilterListOptions: {
							render: v => {
								let res = ''
								if (v.length)
									v.forEach(element => {
										res += DateFormatter.getDayNameDate(element) + ', '
									});
								else
									res = DateFormatter.getDayNameDate(v)
								return `Date: ${res}`
							}
						},
						filterOptions: {
							logic: (date, filters) => {
								if (filters.length)
									return !filters.includes(date)
								return false
							},
							display: (filterList, onChange, index, column) => {
								let optionsValues = []
								distinctDates.forEach(item => {
									const optionValue = {
										value: item,
										label: DateFormatter.getDayNameDate(item)
									}
									optionsValues.push(optionValue)
								})
								const defaultLabel = 'Date'
								return <CustomFilter filterList={filterList} onChange={onChange} index={index} column={column}
									optionsValues={optionsValues} defaultLabel={defaultLabel} />
							}
						},
						customBodyRender: (value) => {
							const label = DateFormatter.getDayNameDate(value)
							const width = 110
							return <DateCell label={label} customWidth={width} />
						}
					}
				},
				{
					name: 'toBeConfirmed',
					label: 'To be confirmed',
					options: {
						setCellHeaderProps: () => ({ style: { fontWeight: 'bold', zIndex: '0' } }),
						filter: false,
						search: false,
						display: 'false'
					}
				},
				{
					name: 'prio',
					label: 'Prio ',
					options: {
						setCellHeaderProps: () => ({ style: { fontWeight: 'bold', textAlign: 'center', width: '150px'} }),
						filter: false,
						search: false,
						customBodyRender: (value, tableMeta) => {
							const validationType = "prioUpdate"
							return <ReferentContent PRIO={value} movieId={tableMeta.rowData[0]} movieTitle={tableMeta.rowData[10]} validationType={validationType} enabled={false} />
						},
						customHeadRender: (columnMeta) => (
							<PrioColumnHeader label={columnMeta.label} PrioCount={PrioCount} lockPrioUpdate={this.state.lockPrioUpdate} enabled={false} onLoadPlanning={onLoadPlanning} />
						)
					}
				},
				{
					name: 'movieId',
					label: 'MID',
					options: {
						setCellHeaderProps: () => ({ style: { fontWeight: 'bold', textAlign: 'center', width: '50px',  zIndex: '0' } }),
						filter: false,
					}
				},
				{
					name: 'movieTitle',
					label: 'Titre',
					options: {
						customFilterListOptions: {
							render: v => {
								return `Titre: ${v}`
							}
						},
						setCellHeaderProps: () => ({ style: { fontWeight: 'bold', zIndex: '0',    textAlign: 'left' } }),
						filter: true,
						filterType: 'textField',
						customBodyRender: (value, tableMeta) => {
							const width = 400
							return <MovieTitleCell label={value} customWidth={width} titleCommentText={tableMeta.rowData[tableMeta.columnIndex + 1]} titleCommentColor="red" />
						}
					}
				},
				{
					name: 'movieTitleComment',
					label: 'Commentaire des titres',
					options: {
						setCellHeaderProps: () => ({ style: { fontWeight: 'bold' } }),
						filter: false,
						search: false,
						display: 'excluded'
					}
				},
				{
					name: 'categoryId',
					label: 'Cat.',
					options: {
						setCellHeaderProps: () => ({ style: { fontWeight: 'bold',  zIndex: '0' } }),
						filter: true,
						filterType: 'custom',
						customFilterListOptions: {
							render: v => {
								let res = ''
								v.forEach(element => {
									const selectedCategory = weekCategories.find(x => x.itemid === element)
									res += selectedCategory !== undefined ? selectedCategory.label + ', ' : ''
								});
								return `Catégorie: ${res}`
							}
						},
						filterOptions: {
							logic: (category, filters) => {
								if (filters.length) return !filters.includes(category)
								return false
							},
							display: (filterList, onChange, index, column) => {
								let optionsValues = []
								weekCategories.forEach(item => {
									const optionValue = {
										value: item.itemid,
										label: item.label
									}
									optionsValues.push(optionValue)
								})
								const defaultLabel = 'Catégorie'
								return <CustomFilter filterList={filterList} onChange={onChange} index={index} column={column}
									optionsValues={optionsValues} defaultLabel={defaultLabel} />
							}
						},
						customBodyRender: (value, tableMeta) => {
							const type = 'category'
							const width = 80
							return <ReferenceDataCell referencedataid={value} type={type} customWidth={width} seasonRow={tableMeta.rowData[29]} />
						},
						setCellProps: (value) => {
							const { referencedataid } = value.props
							const backgroundColor = PublicationTrackingReferenceData.getCellInformation(referencedataid, 'category').color
							return {
								className: classnames(
									{
										[this.props.classes.NameCell]: true
									}),
								style: {
									backgroundColor: backgroundColor,
								}
							}
						}
					}
				},
				{
					name: 'ayd',
					label: 'AYD',
					options: {
						filterType: 'multiselect',
						customFilterListOptions: {
							render: v => {
								return `AYD: ${v}`
							}
						},
						setCellHeaderProps: () => ({ style: { fontWeight: 'bold', textAlign: 'center', zIndex: '0' } }),
						filter: true,
					}
				},
				{
					name: 'taskTypeId',
					label: 'Accès',
					options: {
						setCellHeaderProps: () => ({ style: { fontWeight: 'bold', textAlign: 'center',  zIndex: '0' } }),
						filter: true,
						filterType: 'custom',
						customFilterListOptions: {
							render: v => {
								let res = ''
								v.forEach(element => {
									const selectedTaskType = weektasktype.find(x => x.itemid === element)
									res += selectedTaskType !== undefined ? selectedTaskType.label + ', ' : ''
								});
								return `Accès: ${res}`
							}
						},
						filterOptions: {
							logic: (tasktype, filters) => {
								if (filters.length) return !filters.includes(tasktype)
								return false
							},
							display: (filterList, onChange, index, column) => {
								let optionsValues = []
								weektasktype.forEach(item => {
									const optionValue = {
										value: item.itemid,
										label: item.label
									}
									optionsValues.push(optionValue)
								})
								const defaultLabel = 'Accès'
								return <CustomFilter filterList={filterList} onChange={onChange} index={index} column={column}
									optionsValues={optionsValues} defaultLabel={defaultLabel} />
							}
						},
						customBodyRender: (value, tableMeta) => {
							const type = 'taskType'
							const width = 120
							return <ReferenceDataCell referencedataid={value} type={type} customWidth={width} seasonRow={tableMeta.rowData[29]} />
						},
						setCellProps: (value) => {
							const { referencedataid } = value.props
							const backgroundColor = PublicationTrackingReferenceData.getCellInformation(referencedataid, 'taskType').color
							return {
								className: classnames(
									{
										[this.props.classes.NameCell]: true
									}),
								style: {
									backgroundColor: backgroundColor
								}
							}
						}
					}
				},
				{
					name: 'versionId',
					label: 'Vers.',
					options: {
						setCellHeaderProps: () => ({ style: { fontWeight: 'bold', textAlign: 'center', width: '50px', zIndex: '0' } }),
						filter: true,
						filterType: 'custom',
						customFilterListOptions: {
							render: v => {
								let res = ''
								v.forEach(element => {
									const selectedVersion = versions.find(x => x.itemid === element)
									res += selectedVersion !== undefined ? selectedVersion.label + ', ' : ''
								});
								return `Version: ${res}`
							}
						},
						filterOptions: {
							logic: (version, filters) => {
								if (filters.length) return !filters.includes(version)
								return false
							},
							// display: (filterList, onChange, index, column) => {
							// 	let optionsValues = []
							// 	versions.forEach(item => {
							// 		const optionValue = {
							// 			value: item.itemid,
							// 			label: item.label
							// 		}
							// 		optionsValues.push(optionValue)
							// 	})
							// 	const defaultLabel = 'Vers.'
							// 	return <CustomFilter filterList={filterList} onChange={onChange} index={index} column={column}
							// 		optionsValues={optionsValues} defaultLabel={defaultLabel} />
							// }
						},
						customBodyRender: (value, tableMeta) => {
							const type = 'version'
							const width = 50
							return <ReferenceDataCell referencedataid={value} type={type} customWidth={width} seasonRow={tableMeta.rowData[29]} />
						},
						setCellProps: (value) => {
							const { referencedataid } = value.props
							const backgroundColor = PublicationTrackingReferenceData.getCellInformation(referencedataid, 'version').color
							return {
								className: classnames(
									{
										[this.props.classes.NameCell]: true
									}),
								style: {
									backgroundColor: backgroundColor
								}
							}
						}
					}
				},
				{
					name: 'formatId',
					label: 'Format',
					options: {
						setCellHeaderProps: () => ({ style: { fontWeight: 'bold', textAlign: 'left', width: '50px',  zIndex: '0' } }),
						filter: false,
						customBodyRender: (value) => {
							const type = 'format'
							const width = 50
							return <ReferenceDataCell referencedataid={value} type={type} customWidth={width} />
						},
						setCellProps: (value) => {
							const { referencedataid } = value.props
							const backgroundColor = PublicationTrackingReferenceData.getCellInformation(referencedataid, 'format').color
							return {
								className: classnames(
									{
										[this.props.classes.NameCell]: true
									}),
								style: {
									backgroundColor: backgroundColor
								}
							}
						}
					}
				},
				{
					name: 'bk',
					label: 'B&k',
					options: {
						setCellHeaderProps: () => ({ style: { fontWeight: 'bold' } }),
						filter: false,
						search: false,
						display: 'excluded'
					}
				},
				{
					name: 'dtvntr',
					label: 'DTV/ NTR',
					options: {
						setCellHeaderProps: () => ({ style: { fontWeight: 'bold', minWidth: '80px',  zIndex: '0' } }),
						filter: false,
						search: false,
						customBodyRender: (value, tableMeta) => {
							return value;
						}
					}
				},
				{
					name: 'vodDateShifted',
					label: 'Date Vod decalée',
					options: {
						setCellHeaderProps: () => ({ style: { fontWeight: 'bold', zIndex: '0',minWidth:100 } }),
						filter: false,
						search: false,
						//display: 'false'
						customBodyRender: (value, tableMeta) => {
							return value
						}
					}
				},
				{
					name: 'sdLocationPrice',
					label: 'LOC SD',
					options: {
						setCellHeaderProps: () => ({ style: { fontWeight: 'bold', zIndex: '0',minWidth:60 } }),
						filter: false,
						customBodyRender: (value, tableMeta) => {
							const width = 60
							return <PriceCell label={value} comments={tableMeta.rowData[31]} customWidth={width} />
						}
					}
				},
				{
					name: 'hdUhdLocationPrice',
					label: 'LOC HD/4K',
					options: {
						setCellHeaderProps: () => ({ style: { fontWeight: 'bold', zIndex: '0',minWidth:60 } }),
						filter: false,
						customBodyRender: (value, tableMeta) => {
							const width = 60
							return <PriceCell label={value} comments={tableMeta.rowData[32]} customWidth={width} />
						}
					}
				},
				{
					name: 'sdEstPrice',
					label: 'EST SD',
					options: {
						setCellHeaderProps: () => ({ style: { fontWeight: 'bold', zIndex: '0',minWidth:60 } }),
						filter: false,
						customBodyRender: (value, tableMeta) => {
							const width = 60
							return <PriceCell label={value} comments={tableMeta.rowData[33]} customWidth={width} />
						}
					}
				},
				{
					name: 'estHdUhdPrice',
					label: 'EST HD/4K',
					options: {
						setCellHeaderProps: () => ({ style: { fontWeight: 'bold', zIndex: '0',minWidth:60} }),
						filter: false,
						customBodyRender: (value, tableMeta) => {
							const width = 60
							return <PriceCell label={value} comments={tableMeta.rowData[34]} customWidth={width} />
						}
					}
				},
				{
					name: 'bkDvdPrice',
					label: 'B&K DVD',
					options: {
						// 	setCellHeaderProps: () => ({ style: { fontWeight: 'bold' } }),
						// 	filter: false,
						// 	customBodyRender: (value, tableMeta) => {
						// 		const width = 30
						// 		return <PriceCell label={value} comments={tableMeta.rowData[35]} customWidth={width} />
						// 	}
						filter: false,
						search: false,
						display: 'excluded'
					}
				},
				{
					name: 'bkBrPrice',
					label: 'B&K BR',
					options: {
						filter: false,
						search: false,
						display: 'excluded'
						// setCellHeaderProps: () => ({ style: { fontWeight: 'bold' } }),
						// filter: false,
						// customBodyRender: (value, tableMeta) => {
						// 	const width = 30
						// 	return <PriceCell label={value} comments={tableMeta.rowData[36]} customWidth={width} />
						// }
					}
				},
				{
					name: 'eanDvd',
					label: 'EAN DVD',
					options: {
						filter: false,
						search: false,
						display: 'excluded'
					}
				},
				{
					name: 'eanBr',
					label: 'EAN BR',
					options: {
						filter: false,
						search: false,
						display: 'excluded'
					}
				},
				{
					name: 'rowStatus',
					label: 'Commentaire des titres',
					options: {
						filter: false,
						search: false,
						display: 'excluded'
					}
				},
				{
					name: 'isSeasonRow',
					label: 'Commentaire des titres',
					options: {
						filter: false,
						search: false,
						display: 'excluded'
					}
				},
				{
					name: 'isUpdateApproved',
					label: 'Commentaire des titres',
					options: {
						filter: false,
						search: false,
						display: 'excluded'
					}
				},
				{
					name: 'locSdPriceComments',
					label: 'Commentaire des titres',
					options: {
						filter: false,
						search: false,
						display: 'excluded'
					}
				},
				{
					name: 'locHdUhdPriceComments',
					label: 'Commentaire des titres',
					options: {
						filter: false,
						search: false,
						display: 'excluded'
					}
				},
				{
					name: 'sdEstPriceComments',
					label: 'Commentaire des titres',
					options: {
						filter: false,
						search: false,
						display: 'excluded'
					}
				},
				{
					name: 'uhdEstPriceComments',
					label: 'Commentaire des titres',
					options: {
						filter: false,
						search: false,
						display: 'excluded'
					}
				},
				{
					name: 'bkDvdPriceComments',
					label: 'Commentaire des titres',
					options: {
						filter: false,
						search: false,
						display: 'excluded'
					}
				},
				{
					name: 'bkBrPriceComments',
					label: 'Commentaire des titres',
					options: {
						filter: false,
						search: false,
						display: 'excluded'
					}
				},
				{
					name: 'taskGroupId',
					label: "Groupe d'accès",
					options: {
						setCellHeaderProps: () => ({ style: { fontWeight: 'bold', textAlign: 'center', padding: '5px', zIndex: '0' } }),
						filter: true,
						filterType: 'custom',
						customFilterListOptions: {
							render: v => {
								let res = ''
								v.forEach(element => {
									const selectedTaskGroup = weektaskGroup.find(x => x.itemid === element)
									res += selectedTaskGroup !== undefined ? selectedTaskGroup.label + ', ' : ''
								});
								return `Groupe d'accès: ${res}`
							}
						},
						filterOptions: {
							logic: (taskGroupe, filters) => {
								if (filters.length) return !filters.includes(taskGroupe)
								return false
							},
							display: (filterList, onChange, index, column) => {
								let optionsValues = []
								weektaskGroup.forEach(item => {
									const optionValue = {
										value: item.itemid,
										label: item.label
									}
									optionsValues.push(optionValue)
								})
								const defaultLabel = "Groupe d'accès"
								return <CustomFilter filterList={filterList} onChange={onChange} index={index} column={column}
									optionsValues={optionsValues} defaultLabel={defaultLabel} />
							}
						},
						display: 'false'
					}
				},
			]

			const options = {
				filter: true,
				sort: false,
 				filterType: 'dropdown',
				responsive: 'scroll',
				fixedHeaderOptions: {
					xAxis: true,
					yAxis: true,
				},
				fixedSelectColumn: true,
				// selectableRows: "multiple",

				// fixedHeader: true,
				// fixedHeader: true,
				rowsPerPage: 50,
				rowHover: false,
				textLabels: {
					body: {
						noMatch: (planningData.length === 0 && !domainsItemsLoaded) || this.props.isLoading ? <LinearProgress /> : 'Aucune ligne trouvée!',//( load<6 || data.length !==0) ? <LinearProgress /> : 'Aucune ligne trouvée!',//		<CircularProgress />,//						'Aucune ligne trouvée!',
						//toolTip: 'Trier'
						//sort : false
					},
					filter: {
						all: 'Toutes',
						title: 'FILTRES',
						reset: 'REINITIALISER',
					},
					selectedRows: {
						text: 'rows has been deleted',
						delete: 'Delete Row',
						deleteAria: 'Deleted Selected Rows'
					},
					toolbar: {
						search: 'Rechercher',
						downloadCsv: 'Télécharger CSV',
						print: 'Imprimer',
						viewColumns: 'Voir colonnes',
						filterTable: 'Filtrer',
					},
					viewColumns: {
						title: 'Montrer colonnes',
						titleAria: 'Montrer/Cacher colonnes',
					},
				},
				setRowProps: (row) => {
					return {
						className: classnames(
							{
								[classes.UpdatedRow]: row[28] === 'updated' && row[30] !== 1,
								[classes.DeletedRow]: row[28] === 'deleted',
								[classes.SeasonRow]: row[29] === true
							}),
						style: { border: '1px solid white', }
					}
				},
				setTableProps: () => {
					return {
						// padding: this.state.denseTable ? "none" : "default",
						// size: this.state.denseTable ? "small" : "medium",
						padding: 'normal',
						size: 'small',
					}
				},
				selectableRows: 'none',
				selectableRowsOnClick: true,
				resizableColumns: false,
				print: false,
				download: false,
				onRowClick: (rowData, rowState) => {
				},
				customToolbar: () => {
					return (
						<>
							<Tooltip title={'Actualiser'}>
								<Button onClick={() => this.RefreshContent()} style={{ padding: '0px', borderRadius: '50%', minHeight: '45px', minWidth: '45px' }} >
									<RefreshIcon style={{ color: 'rgba(0, 0, 0, 0.54)', fontSize: '1.4rem' }} />
								</Button>
							</Tooltip>
							<CustomSort />

						</>

					)
				},

				customFooter: (count, page, rowsPerPage, changeRowsPerPage, changePage, textLabels) => {
					textLabels.rowsPerPage = 'Lignes par page'
					// this.setState({rowPerPage : rowsPerPage})
					// this.setState({pageNumber : page})

					return (
						<CustomFooter
							count={count}
							page={page}
							rowsPerPage={rowsPerPage}
							changeRowsPerPage={changeRowsPerPage}
							changePage={changePage}
							textLabels={textLabels} />
					)
				}
			}

			return (
				<React.Fragment>
					<div className={classes.root}>
						<div className={classes.publicationPlanningHeader}>
							<WeekSelector />
							<ThemeProvider theme={this.getMuiTheme()}>
								<ParamWeekResponible roles={this.state.roles} weekFilter={weekFilter} />
							</ThemeProvider >
							<PlanningUpload />
							<LaunchExcelImport />
							<ThemeProvider theme={this.getMuiTheme()}>
								<Preparation visible={visible} count={count} isclose={false} weekCategories={weekCategories} weektasktype={weektasktype} weektaskGroup={weektaskGroup} />
							</ThemeProvider >
							<PreparationBoardLauncher />
							<ThemeProvider theme={this.getMuiTheme()}>
								<SendEmail roles={this.state.roles} weekNumber={weekNumber} yearNumber={yearNumber} />
							</ThemeProvider >
							<PublicationStats type='planning' />

						</div>
						<ThemeProvider theme={this.getMuiTheme()}>
							<MUIDataTable className='MUIDataTable'
								title={translate('titles.publicationPlanning')}

								data={data} columns={columns} options={options}
							/>
							{this.props.isLoading && <div className={classes.circularProgress}><CircularProgress size={50} thickness={3} value={25}/></div>}

						</ThemeProvider >
					</div>
				</React.Fragment>
			)
		}
		else {
			return <div> <LinearProgress /> CHARGEMENT DU PLANNING...</div>
		}
	}
}


const mapStateToProps = state => ({
	...state,
	publicationsTrackingData: state.admin.resources['PublicationsTracking/Planning']
		? state.admin.resources['PublicationsTracking/Planning'].data
		: {},
	domainsItemsLoaded: state.reducer.Home.domainsItemsLoaded ? state.reducer.Home.domainsItemsLoaded : false,
	weekNumber: state.reducer.publicationTracking.weekNumber !== null ? state.reducer.publicationTracking.weekNumber : 0,
	yearNumber: state.reducer.publicationTracking.yearNumber !== null ? state.reducer.publicationTracking.yearNumber : 0,
	planningData: state.reducer.publicationTracking.planningData !== null ? state.reducer.publicationTracking.planningData : [],
	isUpdatePlanningTri: state.reducer.publicationTracking.isUpdatePlanningTri,
	//isUpdatePrioLocked: state.reducer.publicationTracking.isUpdatePrioLocked

})

const mapDispatchToProps = ({
	loadingDomainsItems,
	onChangeWeek,
	onChangeYear,
	onLoadPlanning
})

const enhance = compose(
	connect(mapStateToProps, mapDispatchToProps, null, {
		areStatesEqual: (prev, next) => next.reducer.publicationTracking.planningData === prev.reducer.publicationTracking.planningData
			&& next.reducer.publicationTracking.isUpdatePlanningTri === prev.reducer.publicationTracking.isUpdatePlanningTri
			//&& next.reducer.publicationTracking.isUpdatePrioLocked === prev.reducer.publicationTracking.isUpdatePrioLocked
	}),
	withStyles(customStyles)
)

export default translate(enhance(Planning))