import React from 'react'
import PropTypes from 'prop-types'
import AvatarField from './AvatarField'
import NameField from './NameField'
//import { withStyles } from '@material-ui/core/styles'
import { createTheme,  withStyles , ThemeProvider } from '@material-ui/core/styles'

const styles =   ({
	root: {
		display: 'flex',
		flexWrap: 'nowrap',
		alignItems: 'center',
		// width: '50px',
		// height: '50px'
	}
})
const getMuiTheme = () => createTheme({
	overrides: {
		MuiAvatar: {
			root: {
				borderRadius: '0%',
				// width: '50px',
				// height: '50px'
			}
		},
	},
})
const AvatarComplexField = props => {
	const { addAvatar, classes, style, ...rest } = props
	return (
		<div className={classes.root} style={style}>
			{
				addAvatar ?
				<ThemeProvider  theme={getMuiTheme()}>
					<AvatarField {...props} />
				</ThemeProvider>
				:
				null
			}
			<NameField style={{ marginLeft: '5px' }} {...rest} />
		</div>
	)
}

AvatarComplexField.propTypes = {
	record: PropTypes.object,
	sourceId: PropTypes.string,
	sourceLabel: PropTypes.string,
	addId: PropTypes.bool,
	addLabel: PropTypes.bool,
	addAvatar: PropTypes.bool,
	classes : PropTypes.object.isRequired
}

AvatarComplexField.defaultProps = {
	record: {},
	sourceId: 'id',
	sourceLabel: 'label',
	addId: false,
	addLabel: true,
	addAvatar: true,
}

export default withStyles(styles)(AvatarComplexField)
