import React, { Component, Children, Fragment } from 'react'
import classnames from 'classnames'

class CardChild extends Component {
	render() {
		// eslint-disable-next-line react/prop-types
		const { children } = this.props
		return (
			<Fragment>
				{Children.map(
					children,
					field =>
						field && (
							<div
								key={field.props.source}
								className={classnames(
									'ra-field',
									`ra-field-${field.props.source}`,
									field.props.className
								)}
							>
								{field}
							</div>
						)
				)}
			</Fragment>
		)
	}
}

export default CardChild
