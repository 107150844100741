import Api from './Api'

const Companiestransfers = {
	getAll: () =>
		Api.Requests.getAll('/Companiestransfers'),
	getPricePlanningTargets:() =>
		Api.Requests.getAll('/CompaniesTransfers/PricePlanningTargets'),
	GetTransferPackByAlbum:(albumid) =>
		Api.Requests.getAll(`/CompaniesTransfers/GetTransferPack?id=${albumid}`),
	UpdateProductPack:(albumid,pricingClasse,startDateVod,endDateVod,startDateEst,endDateEst,actionId) =>
		Api.Requests.postWithBody(`/CompaniesTransfers/UpdateProductPack?albumid=${albumid}&pricingClasse=${pricingClasse}&startDateVod=${startDateVod}
		&endDateVod=${endDateVod}&startDateEst=${startDateEst}&endDateEst=${endDateEst}&actionId=${actionId}`),
	CheckPack: (albumid) =>
		Api.Requests.getAll(`/Companiestransfers/CheckPack?id=${albumid}`),

		
	}

export default Companiestransfers