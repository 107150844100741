import {
	GET_ONE,
	GET_LIST
} from 'ra-core/lib/dataFetchActions'

export const CRUD_GET_ANOTHER_ONE = 'C+/CRUD_GET_ANOTHER_ONE'
export const CRUD_GET_ANOTHER_ONE__LOADING = 'C+/CRUD_GET_ANOTHER_ONE_LOADING'
export const CRUD_GET_ANOTHER_ONE_FAILURE = 'C+/CRUD_GET_ANOTHER_ONE_FAILURE'
export const CRUD_GET_ANOTHER_ONE_SUCCESS = 'C+/CRUD_GET_ANOTHER_ONE_SUCCESS'

export const crudGetAnotherOne = (resource, id) => ({
	type: CRUD_GET_ANOTHER_ONE,
	payload: { id },
	meta: {
		resource,
		fetch: GET_ONE,
		id,
		onFailure: {
			notification: {
				body: 'ra.notification.item_doesnt_exist',
				level: 'warning',
			},
			redirectTo: 'list',
			refresh: true,
		},
	},
})

export const CRUD_GET_ANOTHER_LIST = 'C+/CRUD_GET_ANOTHER_LIST'
export const CRUD_GET_ANOTHER_LIST_LOADING = 'C+/CRUD_GET_ANOTHER_LIST_LOADING'
export const CRUD_GET_ANOTHER_LIST_FAILURE = 'C+/CRUD_GET_ANOTHER_LIST_FAILURE'
export const CRUD_GET_ANOTHER_LIST_SUCCESS = 'C+/CRUD_GET_ANOTHER_LIST_SUCCESS'

export const crudGetAnotherList = (resource, id, pagination = { page: 1, perPage: 10 }, sort = null, filter = {}) => ({
	type: CRUD_GET_ANOTHER_LIST,
	payload: { id, pagination, sort, filter },
	meta: {
		resource,
		fetch: GET_LIST,
		id,
		onFailure: {
			notification: {
				body: 'ra.notification.http_error',
				level: 'warning',
			},
		},
	},
})
