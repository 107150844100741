import { Logger, Token } from '../tools'
import { AUTH_LOGOUT } from 'react-admin'
import authProvider from '../authProvider'
import { showNotification } from 'react-admin'
import { connect } from 'react-redux'
import compose from 'recompose/compose'

const defaultLang = 2
const defaultPageNumber = 1
const defaultPageSize = 10

var parameters = (method, url, headers) => new Request(process.env.REACT_APP_API + url, {
	method: method,
	//credentials: 'include',
	headers: new Headers({
		'Content-Type': 'application/json',
		'Authorization': `Bearer ${Token.getToken()}`,
		...headers
	}),
})

var parametersWithBody = (method, url, headers, data) => new Request(process.env.REACT_APP_API + url, {
	method: method,
	//credentials: 'include',
	headers: new Headers({
		'Content-Type': 'application/json',
		'Authorization': `Bearer ${Token.getToken()}`,
		...headers
	}),
	body: JSON.stringify(data)
})
var parametersWithFile = (method, url, headers, data) => 
fetch(process.env.REACT_APP_API + url, {
	method: method,
	//credentials: 'include',
	headers: new Headers({ 
		//enctype: 'multipart/form-data',
	'Authorization': `Bearer ${Token.getToken()}`,
	...headers }),
	body: data
})
var authParameters = (method, url, headers) => new Request(process.env.REACT_APP_AUTH + url, {
	method: method,
	//credentials: 'include',
	headers: new Headers({
		'Content-Type': 'application/json',
		...headers
	}),
})

const Lang = (langid = defaultLang) => `langid=${langid}`
const Limit = (pageSize = defaultPageSize, pageNumber = defaultPageNumber) => `pageSize=${pageSize}&pageNumber=${pageNumber}`
const Requests = {
	get: (url, headers) =>
		fetch(parameters('GET', url, headers)).then(resolveOrReject).then(responseToJson).catch(catchError),
	getAll: (url, headers) =>
		fetch(parameters('GET', url, headers)).then(resolveOrReject).then(responseToJson).catch(catchError),
	put: (url, headers) =>
		fetch(parameters('PUT', url, headers)).then(resolveOrReject).then(responseToJson).catch(catchError),
	postWithBody: (url, data, headers ) =>
		fetch(parametersWithBody('POST', url, headers, data)).then(resolveOrReject).then(responseToJson).catch(catchError),
	postWithFile: (url, data, headers) =>
		fetch(parametersWithFile('POST', url, headers, data)).then(resolveOrReject).then(responseToJson).catch(catchError),
	delete: (url, headers) =>
		fetch(parametersWithBody('DELETE', url, headers)).then(resolveOrReject).then(responseToJson)
}

const AuthRequests = {
	post: (url, headers) =>
		fetch(authParameters('POST', url, headers)).then(resolveOrReject).then(responseToJson).catch(catchError),
	get: (url, headers) =>
		fetch(authParameters('GET', url, headers)).then(resolveOrReject).then(responseToJson).catch(catchError),
}

const resolveOrReject = response => {
	if (response.status >= 200 && response.status < 300) {
		return Promise.resolve(response)
	} else {
		if(response.status === 401 || response.status === 403){
			showNotification('ra.notification.logged_out', 'warning', { autoHideDuration : '6000' })
			authProvider(AUTH_LOGOUT, response)
			// window.location.href = process.env.PUBLIC_URL + '/#/login'
			// window.location.reload(true)
		}
		return Promise.reject(response)
	}
}

const responseToJson = response => {
	const json = response.json()
	//console.log(response)
	if (json.error) {
		console.log('Fetch error: ' + json.error )
		Logger.error(json.error)
		throw new Error(json.error.message)
	}

	return json
}

const catchError = error => {
	console.log('Fetch error: ' + error.message + ' status : ' + error.status)
 				var errorMessage = 'ra.notification.http_error'
				switch (error.message) {
				case 'Forbidden':
					errorMessage = 'ra.auth.sign_in_error'
					break
				case 'Unauthorized':
					errorMessage = 'ra.notification.http_error'//'ra.notification.logged_out'
					break
				default:
					errorMessage = 'ra.notification.logged_out'
					break
				}
				const httpClientError = {
					message : errorMessage,
					status : error.status
				}
				//return Promise.reject(httpClientError)
				Logger.error(httpClientError)
				throw httpClientError
}
 
const mapDispatchToProps = {
	showNotification, 
}
compose( connect(null,mapDispatchToProps))
export default { AuthRequests, Requests, Lang, Limit }