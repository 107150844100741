import get from 'lodash/get'

export const getId = (props, field) => {
	return getRecordValue(props, field ? field : 'id')
}

export const getValue = (props, field) => {
	return getRecordValue(props, field ? field : 'value')
}

const getRecordValue = ({ value, record }, field) => {
	if (value)
		return value
	if (record) {
		let recordvalue = get(record, field)
		if (recordvalue instanceof Object) {
			recordvalue = get(recordvalue, field ? field : 'value')
		}
		if (recordvalue)
			return recordvalue
	}
	return null
}

export const getUrlVars = () => {
	const vars = {}
	window.location.href.replace(/[?&]+([^=&]+)=([^&]*)/gi, (m, key, value) => {
		vars[key] = decodeURI(value)
		return undefined
	})

	return vars
}

export const getAveragetargetage = () => {
	let result = []
	for (let i = 0; i <= 100; i++) {
		i === 0
			? result.push({ id: i, name: 'Non défini' })
			: result.push({ id: i, name: `${i}` })
	}
	return result
}

export const getPack = () => {
	let result = []
	for (let i = 0; i <= 10; i++) {
		i === 0
			? result.push({ id: i, name: 'Non' })
			: result.push({ id: i, name: `${i}` })
	}
	return result
}

export const getNameProduct = (value, nameproduct) => {
	if (typeof value == 'undefined' && value.lentgh === 0)
		return ''

	let sliceStr
	let count = 0
	let position = value.lastIndexOf(' ')
	let lastIndexOf
	let textfield = ''

	if (position !== -1) {
		sliceStr = value.slice(0, position)
		position = sliceStr.indexOf('/')
	}

	while (position !== -1) {
		count++
		position = sliceStr.indexOf('/', position + 1)
	}

	if (nameproduct === 0) {
		for (let i = 0; i < count; i++) {
			textfield += value.split('/')[i] + '/'
		}
		if (textfield === '') textfield = value.split('/')[0] + '/'
		return textfield
	}

	if (nameproduct === 1) {
		sliceStr !== undefined
			? (lastIndexOf = sliceStr.lastIndexOf('/') + 1)
			: (lastIndexOf = value.lastIndexOf('/') + 1)
		return value.slice(lastIndexOf)
	}
}
