import React from 'react'
import { Show, Edit, Create, List, SelectInput, TextInput, Datagrid, TextField, Filter } from 'react-admin'
import { ConcatFieldPeoples, PeopleField } from '../common/fields'
import { MyListActions, MyShowActions, MyEditActions, MyCreateActions } from '../common/actions'
import { CardShow, CardEdit, CardCreate } from '.'

const mode = [
	{ id: 1, name: 'Nom de la personnalité' },
	{ id: 2, name: 'Identifiant de la personnalité' },
	{ id: 3, name: 'Rôle de la personnalité' },
	{ id: 8, name: 'Titre du programme' },
]

const CompaniespeoplesFilter = (props) => (
	<Filter {...props}>
		<SelectInput label="fields.criteria"  source="mode" choices={mode} alwaysOn allowEmpty={false} />
		<TextInput label="fields.value" source="q" alwaysOn allowEmpty={false} resettable clearAlwaysVisible />
	</Filter>
)

const CompaniespeoplesList = (props) => (
	<List
		title="titles.companiesPeoplesList"
		filters={<CompaniespeoplesFilter />}
		actions={<MyListActions />}
		filterDefaultValues={{ mode: 1}}
		bulkActionButtons={false}
		{...props}
	>
		<Datagrid rowClick="show">
			<PeopleField addAvatar={true} sourceId="id" label="" size={36} />
			<TextField source="id" label="fields.peopleid" sortable={false} />
			<ConcatFieldPeoples label="fields.name" source='lastname;firstname;value' sortable={false} />
		</Datagrid>
	</List>
)

const Title = (props) => <PeopleField addId={true} addFirstName={true} addLastName={true} sourceFirstName='firstname' sourceLastName='lastname' addAvatar={true} size={36} {...props} />

const CompaniespeoplesShow = (props) => (
	<Show title={<Title />} actions={<MyShowActions />} {...props}>
		<CardShow />
	</Show>
)

const CompaniespeoplesEdit = (props) => (
	<Edit title={<Title />} undoable={false} actions={<MyEditActions />} {...props}>
		<CardEdit />
	</Edit>
)

const CompaniespeoplesCreate = (props) => (
	<Create title={<Title />} undoable={false} actions={<MyCreateActions />} {...props}>
		<CardCreate />
	</Create>
)

export { CompaniespeoplesList, CompaniespeoplesShow, CompaniespeoplesEdit, CompaniespeoplesCreate }