import React, { Component, Fragment } from 'react'
import { connect } from 'react-redux'
import { change, submit, isSubmitting, getFormValues, initialize, blur, registerField, unregisterField } from 'redux-form'
import { stringify } from 'query-string'
import { fetchEnd, fetchStart, showNotification, Button, SaveButton, SimpleForm, CREATE, SimpleShowLayout, ImageInput, SelectInput } from 'react-admin'
import Dialog from '@material-ui/core/Dialog'
import DialogContent from '@material-ui/core/DialogContent'
import DialogActions from '@material-ui/core/DialogActions'
import Typography from '@material-ui/core/Typography'
import { ImageField } from '../../common/fields'
import compose from 'recompose/compose'
import DefaultImage from '../../../pictures/defaultImage.png'
import { crudGetOne as crudGetOneAction } from 'ra-core/lib/actions/dataActions'
import dataProvider from '../../../dataProvider'
import Radio from '@material-ui/core/Radio'
import { getAttrValueFromProps } from '../../../tools/xmlDocParser'
import Checkbox from '@material-ui/core/Checkbox'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import { DomainsItems, Url } from '../../../tools'
import { AddIcon, CancelIcon } from '../../../constants/icons'
import PropTypes from 'prop-types'
import { withStyles  ,  } from '@material-ui/core/styles'

const styles = theme =>   ({
	cardPaddingMargin: {
		padding: 20,
		margin: 20,
		flex: '1 auto'
	},
	cardDiv: {
		flex: '1 auto'
	},
	flexContent: {
		display: 'flex',
		alignItems: 'flex-start',
		marginLeft: -14
	},
	divMarginLeft: {
		marginLeft: -14
	},
	img: {
		width: '100%'
	},
	imgPreview: {
		margin: '0 auto',
		maxWidth: 500,
		display: 'block'
	},
	inputTwoSide: {
		marginLeft: theme.spacing(1),
		marginRight: theme.spacing(1)
	},
	inputRight: {
		marginRight: theme.spacing(1)
	},
	button: {
		margin: theme.spacing(1)
	},
	rootPadding: {
		padding: 0
	},
	bannerAbsoluteTop: {
		position: 'absolute',
		top: 'auto',
		zIndex: 2,
		display: 'block',
		width: '100%'
	},
	pictureAbsoluteTop: {
		width: '100%',
		position: 'absolute',
		top: 'auto',
		zIndex: 1,
		display: 'block'
	},
	divRelative: {
		position: 'relative',
		padding: 0,
		maxWidth: 535
	},
	hidden: {
		display: 'none'
	}
})

const currentPivot = () => {
	const ids = [6, 13, 16, 18, 19, 55, 71, 84, 195, 239, 305]
	let typeid = []
	ids.map(id => typeid.push({ id: id, name: `${DomainsItems.getLabel(8, id)} (${id})` }))
	return typeid
}

const cartouche = () => {
	let cartouche = new Array({ id: 0, name: 'Aucun' })
	DomainsItems.getItems(107).map(item => cartouche.push({ id: item.itemid, name: `${item.label} (${item.itemid})` }))
	return cartouche
}

const REDUX_FORM_ASSISTANT = 'post-quick-assistant'

class PostQuickAssistantButton extends Component {
	state = {
		error: false,
		showDialog: false,
		filename: '',
		cartridge: '',
		idChild: '',
		selectedInputFromRadio: 'a',
		// TODO: schedule refactoring
		disabledA: false,
		disabledB: true,
		banner: false,
		sourceid: 1,
		sendtobddp: true,
		applytoseason: false,
		pivotid: 0
	}

	handleClick = (recordParent) => {
		const id = window.location.href.split('=')[1]
		if (recordParent !== undefined) {
			//exemple

		} else
			this.props.crudGetOne('Companiesmovies', id)
		this.setState({ showDialog: true })
	}

	handleCloseClick = () => {
		this.setState({ showDialog: false })
	}

	handleSaveClick = () => {
		const { submit, unregisterField } = this.props

		unregisterField(REDUX_FORM_ASSISTANT, 'typeid')
		// Trigger a submit of our custom quick create form
		// This is needed because our modal action buttons are oustide the form
		submit(REDUX_FORM_ASSISTANT)
		this.setState({ showDialog: false })
	}

	handleSubmit = values => {
		const { fetchStart, fetchEnd, showNotification } = this.props
		const formData = new FormData()
		formData.append('image', this.state.file)
		console.log(this.state.file)

		let results = {}
		const initialValues = Object.entries(values).filter(value => value[0] !== 'typeid' && value[0] !== 'pictures')
		initialValues.forEach(value => {
			results[value[0]] = value[1]
			formData.append(value[0], value[1])
		})

		let query = {
			sourceId: results.sourceid,
			objectId: results.objectid,
			id: results.id,
			pivotId: results.pivotid,
			cartoucheId: results.cartoucheid,
			sendToBddp: results.sendtobddp,
			applyToSeason: results.applytoseason
		}
		// Dispatch an action letting react-admin know a API call is ongoing
		fetchStart()

		// As we want to know when the new post has been created in order to close the modal, we use the
		// dataProvider directly
		dataProvider(CREATE, `PictureWizard/Pivot/?${stringify(query)}`, { data: formData })
			.then(() => {
				this.setState({ showDialog: false })
				showNotification('Done', 'success') // TODO: schedule refactoring
			})
			.catch(error => {
				showNotification(error.message, 'error')
			})
			.finally(() => {
				// Dispatch an action letting react-admin know a API call has ended
				fetchEnd()
			})
	}

	handleChange = (value) => {
		const { recordChild, idsChild, change } = this.props
		idsChild.forEach(id => {
			if (value === recordChild[id].intTypeid) {
				change(REDUX_FORM_ASSISTANT, 'pivotid', recordChild[id].intTypeid)
				this.setState({ filename: recordChild[id].filename, idChild: recordChild[id].id })
			}
		})
	}

	handleChangeCartouche = (value) => {
		const item = value !== 0 ? DomainsItems.getItem(107, value) : null
		this.setState({
			cartridge: value !== 0 ? getAttrValueFromProps('FILENAME', item.properties) : null,
			banner: value !== 0 ? true : false
		})
	}

	handleChangeRadioChecked = event => {
		const { change } = this.props
		const value = event.target.value
		this.setState({
			selectedInputFromRadio: value,
			// TODO: schedule refactoring
			disabledA: value === 'a' ? false : true,
			disabledB: value === 'b' ? false : true
		})
		if (this.state.disabledA) change(REDUX_FORM_ASSISTANT, 'sourceid', 1)
		if (this.state.disabledB) change(REDUX_FORM_ASSISTANT, 'sourceid', 2)
	}

	postDefaultValue = () => {
		const id = window.location.href.split('=')[1]
		return ({
			objectid: 1,
			id: parseInt(id, 10),
			sourceid: this.state.sourceid,
			pivotid: this.state.pivotid,
			sendtobddp: this.state.sendtobddp,
			applytoseason: this.state.applytoseason
		})
	}

	handleChangeSendToBdd = () => {
		const { change } = this.props
		change(REDUX_FORM_ASSISTANT, 'sendtobddp', !this.state.sendtobddp)
		this.setState({
			sendtobddp: !this.state.sendtobddp
		})
	}

	handleUploadImage = (event) => {
		console.log('nandalo')
		this.setState({ file: event.rawFile })
	}

	render() {
		const { showDialog } = this.state
		const { isSubmitting, classes, recordParent, recordChild, valuePicture } = this.props

		const title = recordParent ? `${recordParent.title} (${recordParent.id})` : null
		const checked = this.state.selectedInputFromRadio
		const filename = this.state.filename
		const cartridge = this.state.cartridge
		const banner = this.state.banner

		// TODO: schedule refactoring
		const disabledA = this.state.disabledA
		const disabledB = this.state.disabledB
		const previewFromUploadPicture = valuePicture ? (valuePicture.pictures ? valuePicture.pictures.src : valuePicture) : valuePicture
		const previewDefaultPicture = <img src={DefaultImage} alt="" className={classes.img} />
		const cartoucheValue = Url.pictureCartouche(cartridge)

		let previewPicture = ''

		switch (checked) {
		case 'a':
			typeof previewFromUploadPicture === 'string'
				? previewPicture = (
					<div className={classes.divRelative}>
						<img src={previewFromUploadPicture} alt="Preview" className={classes.pictureAbsoluteTop} />
						{banner ? (
							<img
								alt=""
								src={cartoucheValue}
								className={classes.bannerAbsoluteTop}
							/>
						) : null}
					</div>
				) : previewPicture = previewDefaultPicture
			break
		case 'b':
			filename.length > 0
				? previewPicture = (
					<SimpleShowLayout
						record={recordChild[this.state.idChild]}
						basePath="/Moviespictures"
						classes={{
							root: classes.rootPadding
						}}
					>
						<ImageField source="filename" styleImg="pictureAbsoluteTop" cartouche={cartridge} banner={true} />
					</SimpleShowLayout>
				) : previewPicture = previewDefaultPicture
			break
		default:
			previewPicture = previewDefaultPicture
		}

		return (
			<Fragment>
				<Button onClick={this.handleClick.bind(this, recordParent)} label="Assistant">
					<AddIcon />
				</Button>
				<Dialog
					fullWidth
					open={showDialog}
					onClose={this.handleCloseClick}
					aria-label="Image pour"
				>
					<DialogContent>
						<SimpleForm
							// We override the redux-form name to avoid collision with the react-admin main form
							form={REDUX_FORM_ASSISTANT}
							resource="Moviespictures"
							defaultValue={this.postDefaultValue}
							// We override the redux-form onSubmit prop to handle the submission ourselves
							onSubmit={this.handleSubmit}
							// We want no toolbar at all as we have our modal actions
							toolbar={null}
							classes={{
								form: classes.rootPadding
							}}
						>
							<Fragment>
								<Typography variant="title">Image pour {title}</Typography>
								<div className={classes.flexContent}>
									<Radio
										checked={checked === 'a'}
										onChange={this.handleChangeRadioChecked}
										value="a"
									/>
									<ImageInput source="pictures" label="Related pictures" accept="image/*" disableClick={disabledA} onChange={this.handleUploadImage.bind(this)} classes={{ removeButton: classes.hidden }}>
										<ImageField source="src" title="title" upload={true} hidden={true} />
									</ImageInput>
								</div>
								<div className={classes.divMarginLeft}>
									<Radio
										checked={checked === 'b'}
										onChange={this.handleChangeRadioChecked}
										value="b"
									/>
									<SelectInput label="fields.typeid" source="typeid" choices={currentPivot()} allowEmpty={false} onChange={(event, key) => { this.handleChange(key) }} disabled={disabledB} />
								</div>
								<Typography variant="title">Assistant</Typography>
								<SimpleShowLayout classes={{ root: classes.rootPadding }}>
									<SelectInput label="fields.typeid" source="typeid" choices={currentPivot()} allowEmpty={false} onChange={(event, key) => { this.handleChange(key) }} />
									<SelectInput label="fields.cartoucheid" source="cartoucheid" choices={cartouche()} allowEmpty={false} onChange={(event, key) => { this.handleChangeCartouche(key) }} />
									<FormControlLabel
										control={
											<Checkbox
												checked={this.state.sendtobddp}
												onChange={this.handleChangeSendToBdd}
											/>
										}
										label="Envoyer le pivot vers BDDP"
									/>
								</SimpleShowLayout>
								<Typography variant="title">Pré-visualisation</Typography>
								{previewPicture}
							</Fragment>
						</SimpleForm>
					</DialogContent>
					<DialogActions>
						<SaveButton
							saving={isSubmitting}
							onClick={this.handleSaveClick}
						/>
						<Button label="ra.action.cancel" onClick={this.handleCloseClick}>
							<CancelIcon />
						</Button>
					</DialogActions>
				</Dialog>
			</Fragment>
		)
	}
}

const mapStateToProps = state => {
	const id = window.location.href.split('=')[1]
	return ({
		isSubmitting: isSubmitting(REDUX_FORM_ASSISTANT)(state),
		valuePicture: getFormValues(REDUX_FORM_ASSISTANT)(state),
		recordParent: state.admin.resources['Companiesmovies']
			? state.admin.resources['Companiesmovies'].data[id]
			: {},
		recordChild: state.admin.resources['Moviespictures']
			? state.admin.resources['Moviespictures'].data
			: {},
		idsChild: state.admin.resources['Moviespictures']
			? state.admin.resources['Moviespictures'].list.ids
			: {}
	})
}

PostQuickAssistantButton.propTypes = {
	classes: PropTypes.object.isRequired,
}
const mapDispatchToProps = {
	change,
	fetchEnd,
	fetchStart,
	showNotification,
	submit,
	crudGetOne: crudGetOneAction,
	initialize,
	blur,
	registerField,
	unregisterField
}

const enhance = compose(
	connect(mapStateToProps, mapDispatchToProps),
	withStyles(styles)
)

export default enhance(PostQuickAssistantButton)
