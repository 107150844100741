import CacheItems from './CacheItems'
import { Companiestransfers as request } from '../requests'

class PricePlanning extends CacheItems {
	constructor(cacheKey = 'pricePlanning') {
		super(cacheKey, request.getPricePlanningTargets, false)
	}

	static get instance() {
		if (!this.singleton) {
			this.singleton = new PricePlanning()
			this.singleton.logger.debug(`${this.__proto__.name} > new instance`)
		}

		return this.singleton
	}

	// --------------------
	// Custom methods below
	// --------------------

	// Get targets 
	getTargets = () => {
		const data = this.getAllItems()
		return Object.values(data)
	}
}

const PricePlanningInstance = PricePlanning.instance

export default PricePlanningInstance