import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { showListField as showListFieldAction } from '../../../actions/showAction'
import compose from 'recompose/compose'
import { withStyles } from '@material-ui/core/styles'

const styles = ({
	link: {
		textDecoration: 'none',
		color: 'inherit',
	},
})

class LinkListItem extends Component {
	handleClick = (id) => {
		const { showListField } = this.props
		showListField(id)
	}

	render() {
		const {
			classes,
			children,
			id,
		} = this.props

		return (
			<div className={classes.link} onClick={() => this.handleClick(id)}>
				<span>{children}</span>
			</div>
		)
	}
}

LinkListItem.propTypes = {
	showListField: PropTypes.func,
	classes: PropTypes.object.isRequired,

}

const enhance = compose(
	connect(
		null,
		{ showListField: showListFieldAction }
	),
	withStyles(styles)
)

export default enhance(LinkListItem)
