import React, {useEffect, useRef, useState,useContext } from 'react';
import { createTheme, ThemeProvider, withStyles } from '@material-ui/core/styles';
import { IconButton, Button, CircularProgress, Badge, Tooltip, Typography, Accordion, AccordionSummary, AccordionDetails } from '@material-ui/core';
import { green, blue, red, cyan } from '@material-ui/core/colors';
import { ChevronLeft, ChevronRight, Add, LocalActivity, Public, Group, DeleteOutline, DeleteSweep, ExpandMore } from '@material-ui/icons/';
import { CustomDisplayDialog, CustomAlert } from '../layout/GlobalStyles';
import { Url } from '../../tools'
import SearchListField from '../common/fields/SearchListField'
import { ConfigContext } from '../layout/ConfigProvider';



const styles = () => ({
	container: {
		position:'relative',
		margin: '0px',
		padding: '1%',
		// height:'270px',
		width:'100%',
		maxWidth:'100%',
		whiteSpace:'nowrap',
		overflowX: 'hidden',
		overflowY: 'hidden',
		// border: '1px solid #E0E0E0',
		// borderRadius: 5,
	},
	title: {
		display:'inline-block',
		// marginRight: '20px',
		// marginTop: '0px',
		// marginBottom: '30px',
		color: 'white',
		fontWeight: 'bold',
		fontSize: '13px',
		fontFamily: 'Roboto, Helvetica, Arial, sans-serif',
		lineHeight: 1,
	},
	buttons: {
		position:'relative',
		float:'right',
	},
	menu: {
		display: 'flex',
		maxWidth: '100%',
		alignItems : 'center',
		'&:after':{
			opacity:0
		}
	},
	buttonLeft: {
		position: 'relative',
		width:'50px', 
		display:'inline-block',
	},
	buttonRight: {
		width:'50px',
		display:'inline-block',
		float: 'right',
		textAlign: 'right',
	},
	itemsList: {
		margin: '0px',
		marginTop: '10px',
		height: 'auto',
		width: '100%',
		paddingRight: '0px',
		paddingLeft: '0px',
		overflowX: 'hidden',
		overflowY: 'hidden',
		flexGrow:'1',
	},
	items: {
		position:'relative',
		display:'inline-block',
		height:'240px',
		width:'11%',
		marginRight:'5px',
		'&:last-child':{
			marginRight:'0px'
		},
		overflow:'hidden',
		border: '1px solid #E0E0E0',
	},
	imageStyle: {
		height:'100%',
		width:'100%',
	},
	iconStyle: {
		position:'relative',
		left:'20%',
		top:'5%',
		height:'60%',
		width:'60%',
	},
	hoverLink: {
		position: 'absolute',
		height:"100%",
		width:'100%',
		bottom: '0',
		left:'0',
		"&:hover $hoverMask":{
			opacity:1,
			backgroundColor: 'rgba(50,50,50,0.6)'
		},
		"&:hover $hoverMaskForIcons":{
			opacity:1,
			backgroundColor: 'rgba(50,50,50,0.6)'
		},
		"&:hover ~ $promotionsText":{
			opacity:0,
		},
		"&:hover ~ $deleteItemButton":{
			opacity:1
		},
		zIndex:1200
	},
	hoverMask: {
		position: 'absolute',
		display: 'flex',
		flexDirection:'column',
		alignItems: 'center',
  		justifyContent: 'center',
		height:'6.7em',
		width:'100%',
		bottom: '0',
		left:'0',
		opacity:0,
		transition: '0.5s',
	},
	hoverText: {
		position:'relative',
		display:'-webkit-box',
		color: 'white',
		maxHeight:'4.6em',
		minHeight:'1.3em',
		maxWidth: '100%',
		fontSize:'1.1em',
		textAlign: 'center',
		whiteSpace: 'initial',
		// overflowWrap: 'break-word',
		WebkitBoxOrient: 'vertical',
		WebkitLineClamp: 4,
		overflow: 'hidden',
		textOverflow: 'ellipsis',
		bottom: '0',
	},
	promotionsText: {
		position:'absolute',
		color: 'black',
		width:'90%',
		fontSize:'1.1em',
		textAlign: 'center',
		marginBottom:'10px',
		paddingRight: '20px',
		paddingLeft: '10px',
		whiteSpace: 'initial',
		overflowWrap: 'break-word',
		overflow:'hidden',
		whiteSpace:'nowrap',
		textOverflow: "ellipsis",
		bottom: '0',
		opacity:1,
		transition: '0.5s',
	},
	deleteItemButton: {
		position: 'absolute',
		top: '5px',
		right: '5px',
		height:'25px',
		width:'25px',
		opacity:0,
		"&:hover":{
			opacity:1
		},
		backgroundColor: red[600],
		borderRadius: 5,
		cursor:'pointer',
		zIndex:1500
	}
});

const StyledBadge = withStyles((theme) => ({
	badge: {
	  right: -15,
	  top: -5,
	color:"#288DB1",
	backgroundColor:'white',
	  border: `2px solid ${theme.palette.background.paper}`,
	  padding: '2px 3px 2px 2px',
	},
  }))(Badge);
  const AddButton = withStyles((theme) => ({
	root: {
	  color: 'white',
	  backgroundColor: '#288DB1',
	  '&:hover': {
		backgroundColor: '#1C627B',
	  },
	},
  }))(Button);
  const DelButton = withStyles((theme) => ({
	root: {
	  color: 'white',
	  backgroundColor: red[600],
	  '&:hover': {
		backgroundColor: red[700],
	  },
	},
  }))(Button);


function SidescrollList(props){
	const { config } = useContext(ConfigContext);
	const [maxScroll, setMaxScroll] = useState(null)
	const [currentScroll, setCurrentScroll] = useState(0)
	const [openAddFavorites, setOpenAddFavorites] = useState(false)
	const [openModal, setOpenModal] = useState(false)
	const [modalDelAll, setModalDelAll] = useState(0)
	const [modalText, setModalText] = useState("")
	const [modalFavoriteId, setModalFavoriteId] = useState("")
	const [snackbar, setSnackbar] = useState({
		open: false,
		message: ' ',
		severity: ''
	  });
	const [expanded, setExpanded] = useState(localStorage.getItem(props.localStorageName)!=null?JSON.parse(JSON.stringify(localStorage.getItem(props.localStorageName))) == 'true':true)
	const [delHistory, setDelHistory] = useState({})
	const [maxFav, setMaxFav] = useState(10)

	let scrollList = useRef(null);

	useEffect(() => {
		scrollList.current.addEventListener('scroll', handleScroll, { passive: true });
	
		return () => {
			scrollList.current.removeEventListener('scroll', handleScroll);
		};
	}, []);

	useEffect(()=>{
		setMaxScroll(scrollList.current.scrollWidth - scrollList.current.clientWidth);
		setMaxFav(config && config.maxFavoris)
	})
	const scrollTo = (elementRef, scrollOffset) => {
		elementRef.current.scrollTo({
			left: elementRef.current.scrollLeft+scrollOffset,
			behavior: "smooth"
		  });
		  setCurrentScroll(elementRef.current.scrollLeft)
	}




	const handleScroll = () => {
		setCurrentScroll(scrollList.current.scrollLeft);
	};

	const handlePanel = (panel) => (event, newExpanded) => {
		localStorage.setItem(props.localStorageName, !expanded)
		setExpanded(!expanded);
	  };
	
	const handleClickOpenAddFavorites = () => {
		setOpenAddFavorites(true);
	}
	const handleClickCloseAddFavorites = () => {
		setOpenAddFavorites(false);
	}
	const handleOpenModal = (objectId, favoriteId, deleteAll, history) => {
		setModalFavoriteId(favoriteId)
		setModalDelAll(deleteAll)
		if(objectId == 1 && deleteAll == 0){
			setModalText('Êtes-vous sûr(e) de vouloir supprimer ce programme de vos favoris ?')
		}
		if(objectId == 2 && deleteAll == 0){
			setModalText('Êtes-vous sûr(e) de vouloir supprimer cet album de vos favoris ?')
		}
		if(objectId == 6 && deleteAll == 0){
			setModalText('Êtes-vous sûr(e) de vouloir supprimer cette promotion de vos favoris ?')
		}
		if(objectId == 1 && deleteAll == 1){
			setModalText('Êtes-vous sûr(e) de vouloir supprimer tous vos programmes favoris ?')
		}
		if(objectId == 2 && deleteAll == 1){
			setModalText('Êtes-vous sûr(e) de vouloir supprimer tous vos albums favoris ?')
		}
		if(objectId == 6 && deleteAll == 1){
			setModalText('Êtes-vous sûr(e) de vouloir supprimer toutes vos promotions favorites ?')
		}
		if(props.objectId == "history" && deleteAll == 0){
			setModalText('Êtes-vous sûr(e) de vouloir supprimer cet élément ?')
			setDelHistory(history)
		}
		if(props.objectId == "history" && deleteAll == 1){
			setModalText('Êtes-vous sûr(e) de vouloir supprimer tous vos éléments récemment consultés ?')
		}
		setOpenModal(1)
	}
	const handleCloseModal = () => {
		setOpenModal(0)
		setModalDelAll(0)
	}

	//Gestion de la snackbar
	const handleSnackbarOpen = (message, severity) => {
		setSnackbar({ ...snackbar, open: true, message: message, severity: severity});
	  };
	const handleSnackbarClose = () => {
	setSnackbar({ ...snackbar, open: false });
	};
	const { vertical, horizontal, open, message } = snackbar;
	

	

	var itemsList
	if(props.isFavoriteAlbumsLoading == true || props.isFavoriteMoviesLoading == true || props.isFavoritePromotionsLoading == true){
		itemsList = <CircularProgress />
	}else if(props.isFavoriteAlbumsLoading == false || props.isFavoriteMoviesLoading == false || props.isFavoritePromotionsLoading == false || props.objectId == 'history'){
		itemsList = 
			props.data.map((data)=>{
				var img = null;
				switch(data.objectId){
					case 1 :
					case '1': 
						img = 
							<>
							<img
								title={data.title}
								alt={data.title}
								src={Url.pictureById(data.id, 'Movies', props.objectId=="history"?data.id+'_1_150x200.jpg':data.filename)}
								onError={
									e => {
										e.target.onerror = null
										e.target.src = Url.defaultCover
									}
								}
								className={props.classes.imageStyle} />
								<a href={"/#/Companiesmovies/"+data.id+"/show"} className={props.classes.hoverLink}>
									<div className={props.classes.hoverMask}>
										<div className={props.classes.hoverText}>
											{data.title}
										</div>
										<div className={props.classes.hoverText}>
											({data.id})
										</div>
									</div>
								</a>
								</>
						break;
					case 2:
					case '2': 
						img = 
							<>
							<img
								title={data.title}
								alt={data.title}
								src={Url.pictureById(data.id, 'Albums', props.objectId=="history"?'H.jpg':data.filename)}
								onError={
									e => {
										e.target.onerror = null
										e.target.src = Url.defaultCover
									}
								}
								className={props.classes.imageStyle} />
								<a href={"/#/Companiesalbums/"+data.id+"/show"} className={props.classes.hoverLink}>
									<div className={props.classes.hoverMask}>
										<div className={props.classes.hoverText}>
											{data.title}
										</div>
										<div className={props.classes.hoverText}>
											({data.id})
										</div>
									</div>
								</a>
								</>
						break;
					case 6 :
					case '6':
						if(data.typeId == 1){
							img = 
								<>
								<LocalActivity className={props.classes.iconStyle} />
								<a href={"/#/Promotions/"+data.id+"/show"} className={props.classes.hoverLink}>
									<div className={props.classes.hoverMask}>
										<div className={props.classes.hoverText}>
											{data.title}
										</div>
										<div className={props.classes.hoverText}>
											({data.id})
										</div>
									</div>
								</a>
								<p className={props.classes.promotionsText}>{data.title} ({data.id})</p>
								</>
						}else if(data.typeId == 2){
							img = 
								<>
								<Public className={props.classes.iconStyle} />
								<a href={"/#/Promotions/"+data.id+"/show"} className={props.classes.hoverLink}>
									<div className={props.classes.hoverMask}>
										<div className={props.classes.hoverText}>
											{data.title}
										</div>
										<div className={props.classes.hoverText}>
											({data.id})
										</div>
									</div>
								</a>
								<p className={props.classes.promotionsText}>{data.title} ({data.id})</p>
								</>
						}else if(data.typeId == 3){
							img = 
								<>
								<Group className={props.classes.iconStyle} />
								<a href={"/#/Promotions/"+data.id+"/show"} className={props.classes.hoverLink}>
									<div className={props.classes.hoverMask}>
										<div className={props.classes.hoverText}>
											{data.title}
										</div>
										<div className={props.classes.hoverText}>
											({data.id})
										</div>
									</div>
								</a>
								<p className={props.classes.promotionsText}>{data.title} ({data.id})</p>
								</>
						}
						break;
				}
				return(
					<li className={props.classes.items}>
						{img}
						<div className={props.classes.deleteItemButton}>
								<a onClick={() => {
									handleOpenModal(props.objectId, data.favoriteId, 0, JSON.stringify(data)); 
								}}>
									<DeleteOutline style={{fontSize: 25,verticalAlign: "middle", color:"white", textAlign:'center'}}/>
								</a>
						</div>
					</li>
				)
			})
	}

	let multiSelectMax = maxFav-Number(props.data.length)
	var searchListField = null
	switch(props.objectId){
		case '1':
			searchListField = 
			<SearchListField 
				open={openAddFavorites} 
				close={handleClickCloseAddFavorites} 
				exceptionList={props.data} 
				Title='Ajouter des programmes favoris' 
				getFavoriteMovies={props.getFavoriteMovies}
				TypeItem='Movies'
				multiSelect={true} 
				multiSelectMax={multiSelectMax}
				source='AddFavoriteMovie' 
			/>
			break;
		case '2':
			searchListField = 
			<SearchListField 
				open={openAddFavorites} 
				close={handleClickCloseAddFavorites} 
				exceptionList={props.data} 
				Title='Ajouter des albums favoris' 
				getFavoriteAlbums={props.getFavoriteAlbums}
				TypeItem='Albums' 
				multiSelect={true} 
				multiSelectMax={multiSelectMax}
				source='AddFavoriteAlbum' 
			/>
			break;
		case '6':
			searchListField = 
			<SearchListField 
				open={openAddFavorites} 
				close={handleClickCloseAddFavorites} 
				exceptionList={props.data} 
				Title='Ajouter des promotions favoris' 
				getFavoritePromotions={props.getFavoritePromotions}
				TypeItem='Promotions' 
				multiSelect={true} 
				multiSelectMax={multiSelectMax}
				source='AddFavoritePromotion' 
			/>
			break;
	}


	return(
		<ThemeProvider>
			<div style={{ margin: '5px'}}>
			<Accordion square expanded={expanded} onChange={handlePanel()}>
				<AccordionSummary
					style={{backgroundColor:"#288DB1"}}
					expandIcon={<ExpandMore style={{color:"white"}} />} >
						<Typography className={props.classes.title}>
							<StyledBadge badgeContent={props.data.length} showZero color="blue">
								{props.title}
							</StyledBadge>
						</Typography>
				</AccordionSummary>
				<AccordionDetails style={{width:'100%', padding:0, marginBottom:"20px"}}>
				<div style={props.style} className={props.classes.container}>
					<div style={{width:"100%", height:'50px'}} >
						<div className={props.classes.buttons}>
							{props.objectId != 'history' ? 
								<Tooltip title={props.data.length >= Number(maxFav)?"Le nombre maximum de favoris est de : "+maxFav:"Ajouter des favoris"} >
									<span>
									<AddButton variant="contained" size="small" disabled={props.data.length >= Number(maxFav)?true:false} style={{marginRight:'10px'}} onClick={()=>{
										if(props.data.length < Number(maxFav)){
											handleClickOpenAddFavorites()
										}else{
											// handleSnackbarOpen('Le nombre maximal de favoris est atteint ('+window.Max_Favoris+')', 'error')
										}
									}}>
											<Add />
									</AddButton>
									</span>
								</Tooltip>
							: null}
							<Tooltip title={props.data.length==0?"Il n'y a pas d'éléments à supprimer":"Tout supprimer"}>
								<span>
								<DelButton variant="contained" disabled={props.data.length==0?true:false} size="small" onClick={()=>{handleOpenModal(props.objectId, 0, 1)}} >
										<DeleteSweep />
								</DelButton>
								</span>
							</Tooltip>
						</div>
					</div>

					{searchListField}

					<div className={props.classes.menu}>
						<div className={props.classes.buttonLeft}>
							{currentScroll==0?null:
								<IconButton
									onMouseDown={() => scrollTo(scrollList, -1000)}
									size="small"
									style={{verticalAlign:"middle"}}
								>
									<ChevronLeft style={{fontSize: 30,verticalAlign: "middle", color:"#3F51B5"}}/>
								</IconButton>
							}
						</div>
						
						<ul className={props.classes.itemsList} ref={scrollList}>
							{itemsList}
						</ul>

						<div className={props.classes.buttonRight}>
							{currentScroll>maxScroll-10 || maxScroll < 100 ? null :
								<IconButton
									onMouseDown={() => scrollTo(scrollList, 1000)}
									size="small"
									style={{verticalAlign:"middle"}}
								>
									<ChevronRight style={{fontSize: 30,verticalAlign: "middle", color:"#3F51B5"}}/>
								</IconButton>
							}
						</div>
					</div>
				</div>
				</AccordionDetails>
			</Accordion>
			</div>



			<CustomDisplayDialog 
				open={openModal} 
				handleClose={handleCloseModal} 
				info="Alerte"
				title="Avertissement"
				content={modalText}
				type='window' 
				handleSave={()=>{
					if(props.objectId == 1 && modalDelAll == 0){
						props.removeFavoriteMovies(modalFavoriteId)
					}
					if(props.objectId == 2 && modalDelAll == 0){
						props.removeFavoriteAlbums(modalFavoriteId)
					}
					if(props.objectId == 6 && modalDelAll == 0){
						props.removeFavoritePromotions(modalFavoriteId)
					}
					if(props.objectId != 'history' && modalDelAll == 1){
						props.removeAllFavorites(Number(props.objectId))
						setModalDelAll(0)
					}
					if(props.objectId == 'history' && modalDelAll == 0){
						var history = props.data
						var parsedDelHistory = JSON.parse(delHistory)
						const index = history.findIndex(i => i.id === parsedDelHistory.id && i.objectId === parsedDelHistory.objectId)
						history.splice(index, 1)
						props.updateHistoryData(history)
						localStorage.setItem('history', JSON.stringify(history))
					}
					if(props.objectId == 'history' && modalDelAll == 1){
						localStorage.removeItem('history')
						props.updateHistoryData([])
						setModalDelAll(0)
					}
					handleCloseModal()
				}}
			/>
			<CustomAlert libelle={snackbar.message} severity={snackbar.severity} open={open} setOpen={handleSnackbarClose} />
			{/* <p>maxScroll {maxScroll}</p>
			<p>currentScroll {currentScroll}</p> */}
		</ThemeProvider>
	)
}
	
	


export default withStyles(styles)(SidescrollList)