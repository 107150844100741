import React, { Component } from 'react'
import PropTypes from 'prop-types'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import LinkListItem from './LinkListItem'
import { linkToRecord } from 'ra-core'
import { EditButton } from 'react-admin'
import { withStyles } from '@material-ui/core/styles'

const styles = ({
	tertiary: { float: 'right', opacity: 0.541176 },
	root: {
		height: 550,
		overflow: 'auto'
	}
})

class SimpleChildList extends Component {
	render() {
		const {
			basePath,
			className,
			record,
			idsReducer,
			edit = false,
			classes,
			children,
		} = this.props
console.log(record)
console.log(idsReducer)

		return(
			<List className={className} classes={{ root: classes.root }}>
				{idsReducer !== undefined ? (
					Object.values(idsReducer).map(id => (
						<span key={record[id].id}>
							<LinkListItem key={record[id].id} id={record[id].id}>
								<ListItem button>
									{React.Children.map(
										children,
										field =>
											React.cloneElement(field, {
												key: id,
												record: record[id],
												basePath,
											})
									)}
									{
										edit ? (
											<EditButton to={linkToRecord(basePath, record[id].id)}/>
										) : null
									}
								</ListItem>
							</LinkListItem>
						</span>
					))
				) : null}
			</List>
		)
	}
}

SimpleChildList.propTypes = {
	basePath: PropTypes.string,
	className: PropTypes.string,
	record: PropTypes.object,
	idsReducer: PropTypes.array,
	classes: PropTypes.object.isRequired,
}


export default withStyles(styles)(SimpleChildList)
