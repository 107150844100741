import CacheItems from './CacheItems'
import { Pricings as request } from '../requests'

class Pricings extends CacheItems {
	constructor(cacheKey = 'dataPricings') {
		super(cacheKey, request.getAll, false)
	}

	static get instance() {
		if (!this.singleton) {
			this.singleton = new Pricings()
			this.singleton.logger.debug(`${this.__proto__.name} > new instance`)
		}

		return this.singleton
	}

	// --------------------
	// Custom methods below
	// --------------------

	// Get one pricing
	getItem = (pricingid) => {
		if (pricingid) {
			const data = this.getAllItems()
			if (null != data && data.length > 0) {
				return Object.values(data).find(item => item.id === pricingid)
			}
		}
		return null
	}

	// Get label
	getLabel = (domainid) => {
		if (domainid === -1) return 'Tous (-1)'
		const item = this.getItem(domainid)
		const label = null != item ? item.name : ''
		return label.trim()
	}

	// Get formatted label 
	getFormattedLabel = (domainid) => {
		if (domainid) {
			const label = this.getLabel(domainid)
			return `${label} (${domainid})`.trim()
		}
		return ''
	}

	// Get formatted list to use in selects
	getSelectList = (withAll = false, objectTypeId = undefined) => {
		let list = this.getAllItems()
		if(objectTypeId) list = list && list.filter(s=>s.typeid === objectTypeId)
		return this.getGenericItemsList(list, 'id', 'name', withAll)
	}
}

const PricingsInstance = Pricings.instance

export default PricingsInstance
