import React, { useState, useContext,useEffect } from 'react'
import Button from '@material-ui/core/Button'
import CssBaseline from '@material-ui/core/CssBaseline'
import TextField from '@material-ui/core/TextField'
import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'
import Container from '@material-ui/core/Container'
import { createTheme, ThemeProvider } from '@material-ui/core/styles'
import { Authentification } from '../../requests'
import { withStyles } from '@material-ui/core/styles'
import { Grid } from '@material-ui/core'
import { ConfigContext } from './../layout/ConfigProvider'
 

const theme = createTheme({
	overrides: {

	MuiFormLabel:{
		root:{
			"&$focused": {
				"color": "#288DB1"
			},
		}
	},
	MuiInputBase: {
		'root': {  // - The Input-root, inside the TextField-root
			'&:hover fieldset': {
				borderColor: '#288DB1 !important', // - Set the Input border when parent has :hover
			},
			'&.Mui-focused fieldset': { // - Set the Input border when parent is focused 
				borderColor: '#288DB1 !important',
			},
			'&.underline after':{
				borderBottom: '2px solid #288DB1'

			}
		},
	} ,
	 MuiInput : {
		underline:{
			'&:after':{
				borderBottom: '2px solid #288DB1'
			}
		}}
		
}}
)
const styles = (theme) => ({
	input: {
		"&:-internal-autofill-selected": {
			backgroundcolor: "-internal-light-dark(black,red) !important  "
		}
	},
	error: {
		color: 'red',
		fontSize: 'larger',
		fontWeight: 'bold',
		padding: '25px'
	},
	helperText: {
		color: 'red',
		position: "absolute",
		marginRight: "0",
		bottom: "-2.5em",
		fontSize:'0.72rem'
	}
	//createTheme()
})
 
function ForgottenPwd(props) {
	const {createPwd, resetPwd ,onResetPwd, onCreatePwd} = props
	const { config } = useContext(ConfigContext)

	const [textFieldValueError, setTextFieldValueError] = useState('')
	const [sendMailStatus, setSendMailStatus] = useState(false)
	const [mailAdress, setmailAdress] = useState('')
	// const [errorMessage, setErrorMessage] = React.useState('')
	const [firstConnexion, setFirstConnexion] = useState(false)
	const [expirationToken, setExpirationToken] = useState(60)

	useEffect(() => {
		setExpirationToken(config ? config.expirationToken : 30)
  
	}, [])

	const validateForm = (params) => {
		let errorFields = false

		if (params.adresseMail === null || params.adresseMail === undefined || params.adresseMail === '') {
			setTextFieldValueError('Adresse mail obligatoire')
			errorFields = true
		}
		else {
			/* eslint-disable */
			var reg = /^([A-Za-z0-9_\-\.])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,4})$/
			if (reg.test(params.adresseMail) === false) {
				setTextFieldValueError('Invalide Email Address')
				errorFields = true
			}
			else
				setTextFieldValueError('')
		}

		if (errorFields) {
			return {
				success: false,
				message: errorFields
			}
		}
		return {
			success: true
		}
	}

	const handlePWdReset = (event) => {
		event.preventDefault()
		const data = new FormData(event.currentTarget)
		//setErrorMessage(false)

		const params = {
			adresseMail: data.get('Adressemail'),
		}
		if (validateForm(params).success) {
			setmailAdress(params.adresseMail)
			setSendMailStatus(true)
			return new Promise((resolve, reject) =>

				Authentification.checkUser(params.adresseMail).then((json) => {
					let user = json.data
					if (user && user.password !== null && user.password !== '') {
						setFirstConnexion(false)
						Authentification.sendmailpasswordReset(params.adresseMail, window.location.href,expirationToken).then((json) => {
							setmailAdress(params.adresseMail)
							setSendMailStatus(true)
							setTextFieldValueError('')
							resolve()
						}
						)
							.catch((error) => {
								setTextFieldValueError(error.status === 404 ? 'Utilisateur invalide, Veuillez contacter votre administrateur.' : "Problème d'envoi de l'email, Réessayer")
								setSendMailStatus(false)
								reject()

							})

					} else if (user && (user.password === '' || user.password === null)) {
						setFirstConnexion(true)
						setSendMailStatus(true)
						Authentification.sendmailpasswordReset(params.adresseMail, window.location.href, expirationToken).then((json) => {
							setmailAdress(params.adresseMail)
							setSendMailStatus(true)
							setTextFieldValueError('')
							resolve()
						}
						)
							.catch((error) => {
								setTextFieldValueError(error.status === 404 ? 'Utilisateur invalide, Veuillez contacter votre administrateur.' : "Problème d'envoi de l'email, Réessayer")
								setSendMailStatus(false)
								reject()

							})

					}
				}
				).catch((error) => {
					setTextFieldValueError(error.status === 404 ? 'Utilisateur invalide, Veuillez contacter votre administrateur.' : "Problème d'envoi de l'email, Réessayer")
					setSendMailStatus(false)
					reject()

				})


			)


		}
	}

	const onReset =()=>{
			onResetPwd(false)
			onCreatePwd(false)
	}
	return (
		<ThemeProvider theme={theme}>
			<Container component="main" maxWidth="xs" style={{ minWidth:  '480px',marginBottom:'40px' }}  >
				<CssBaseline />
				<Box
					sx={{
						marginTop: 16,
						marginBottom: 8,
						display: 'flex',
						flexDirection: 'column',
						alignItems: 'center',
					}}
				>
					{resetPwd && <Typography style={{fontSize: '1.3rem',fontWeight:'bold',color:'#009dcd' }}>Réinitialiser mot de passe</Typography>}
					{createPwd && <Typography style={{ fontSize: '1.3rem',fontWeight:'bold' ,color:'#009dcd' }}>Première connexion</Typography> }
					{/* {!sendMailStatus && <Typography>{errorMessage}</Typography>} */}
					{!sendMailStatus ?
						<Box component="form" onSubmit={handlePWdReset} onReset={onReset} noValidate sx={{ mt: 1 }} style={{ minWidth: '450px' }}>

							<TextField
								// margin="normal"
								required
								fullWidth={true}
								id="Adressemail"
								label="Saisir votre adresse mail"
								name="Adressemail"
								//autoComplete="email"
								autoFocus
								error={textFieldValueError !== "" }
								helperText={textFieldValueError}
								FormHelperTextProps={{
									classes: {
										root: props.classes.helperText
									}
								}}
							/>
						<Grid Container >
							<Button
								type="submit"
								width="50%"
								variant="contained"
								sx={{ mt: 3, mb: 2 }}
								style={{ marginBottom: '15px', background: '#288DB1', color: 'white', marginTop: '50px', width:'40%'}}
							>
								ENVOYER
							</Button>
							<Button
								type="reset"
								// fullWidth
								color="secondary"
								variant="contained"
								sx={{ mt: 3, mb: 2 }}
								style={{ marginBottom: '15px',  color: 'white', marginTop: '50px',marginLeft: '10%', width:'40%'}}
							>
								RETOUR
							</Button>
							{/* <Link variant="body2" style={{ textAlign: 'left', color: '#288db1', cursor: 'pointer' }} onClick={() => onCreatePwd(true)}>
								Première connexion
							</Link>
							<Link variant="body2" style={{ marginLeft: '20%', textAlign: 'right', color: '#288db1', cursor: 'pointer' }} onClick={() => onResetPwd(true)}>
								Mot de passe oublié ?
							</Link> */}
						</Grid>
							
							

						</Box> :

						<Box component="form" noValidate sx={{ mt: 1 }}  >
							{firstConnexion ?
								<Typography style={{ fontSize: '1rem',padding:'20px 5px' }}>
									Il s'agit de votre première connexion sur HAWC.<br />
									Un email avec un lien d'initialisation de mot de passe a été envoyé à votre adresse <br /><span style={{ fontWeight: 'bold' }}>{mailAdress}</span><br />
									Vérifiez vos emails et cliquez sur le lien pour créer un nouveau mot de passe.
								</Typography>
								:
								<Typography style={{ fontSize: '1rem',padding:'20px 5px' }}>
									Un email avec un lien de réinitialisation de mot de passe a été envoyé à votre adresse <br /><span style={{ fontWeight: 'bold' }}>{mailAdress}</span><br />
									Vérifiez vos emails et cliquez sur le lien pour modifier votre mot de passe.
								</Typography>}
								<Button
									onClick={()=>onReset()}
									variant="contained"
									sx={{ mt: 3, mb: 2 }}
									style={{ background: '#288DB1', color: 'white',cursor: 'pointer'  }}
								>
									RETOUR
								</Button>
						</Box>

					}


				</Box>

			</Container>
		</ThemeProvider>
	)
}

export default  withStyles(styles)(ForgottenPwd)