import React, { Component } from 'react'
import PropTypes from 'prop-types'
import SmallIconButton from './SmallIconButton'
import { PeopleIcon } from '../../../constants/icons'

class PeopleIconButton extends Component {
	render() {
		const { id, classes } = this.props
		return (
			<SmallIconButton id={id} classes={classes} label='pluralizations.peoples' basePath='/Companiespeoples'>
				<PeopleIcon />
			</SmallIconButton>
		)
	}
}

PeopleIconButton.propTypes = {
	id: PropTypes.number,
	classes: PropTypes.object.isRequired,
}

export default PeopleIconButton