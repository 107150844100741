import React, { Component, Fragment } from 'react'
import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import { connect } from 'react-redux'
import compose from 'recompose/compose'
import withWidth from '@material-ui/core/withWidth'
import isEmpty from 'lodash/isEmpty'
import { fetchEnd, fetchStart, TextField, Datagrid, showNotification, DateField } from 'react-admin'
import { crudGetList as crudGetListAction } from 'ra-core/lib/actions/dataActions'
import { setSidebarVisibility } from 'ra-core'
import uniq from 'lodash/uniq'
import Typography from '@material-ui/core/Typography'
import { ExtendedTextField, IconStatusField } from '../common/fields'
import { withStyles } from '@material-ui/core/styles'

const styles = ({ palette: { primary1Color } }) => ({
	card: {
		marginTop: '-14px',
		paddingTop: 0,
		display: 'flex',
		justifyContent: 'flex-start',
		alignItems: 'flex-end',
		width: '100%'
	},
	body: { display: 'flex', alignItems: 'flex-end' },
	spacer: { width: 48 },
	icon: { color: primary1Color || '#00bcd4', paddingBottom: 0 },
	clearFix: { clear: 'right' },
	marginRight: { marginRight: 30 },
	flex: { display: 'flex', flexDirection: 'column', padding: 0, alignItems: 'center', backgroundColor: '#303030' },
	table: { marginBottom: 20, backgroundColor: '#6f6d6d' },
	title: { alignSelf: 'flex-start' },
	colorText: { color: '#fff' }
})

class ContentsworkflowList extends Component {

	componentDidMount() {

		const page = 1
		const perPage = -1
		const pagination = {
			page: parseInt(page, 10),
			perPage: parseInt(perPage, 10),
		}
		const values = { q: null, mode: null, order: null }

		this.props.crudGetList(
			'Contentsworkflow/Product',
			pagination,
			{ field: 'content.filename', order: 'ASC' },
			values
		)
	}

	UNSAFE_componentWillMount() {
		this.props.setSidebarVisibility(false)
	}

	customGrid = (record, classes) => {
		let filename = []

		Object.values(record).forEach(item => {
			filename.push(item.content.filename)
		})

		let uniqFilename = uniq(filename)
		let data = []
		let dataValues = []
		let ids = []
		let values

		for (let i = 0; i < uniqFilename.length; i++) {
			values = Object.values(record).filter(items => uniqFilename[i] === items.content.filename)
			dataValues.push(values)
		}

		let count = []

		if (dataValues.length > 0) {

			dataValues.forEach(item => {
				count.push(item.length)
			})

			return dataValues.map((item, index) => {

				if (count[index] === item.length) {
					data = []
					ids = []
				}

				item.forEach((it, key) => {
					data[item[key].id] = item[key]
					ids[key] = item[key].id
				})

				return (
					<Fragment key={index}>
						<Typography
							component="span"
							variant="title"
							classes={{ title: classes.title }}
						>
							{item[0].content.filename}
						</Typography>
						{this.groupByDatagrid(data, ids, classes, index)}
					</Fragment>
				)
			})
		}
	}

	groupByDatagrid = (data, ids, classes, index) => {
		return (
			<Datagrid key={index} data={data} ids={ids} currentSort={{ field: 'content.filename', order: 'ASC' }} basePath="/Contentsworkflow/Product" classes={{ table: classes.table }}>
				<ExtendedTextField label="fields.transferid" source="transferid" transferid />
				<IconStatusField label="fields.stateid" source="stateid" />
				<TextField label="fields.externalid" source="externalid" />
				<DateField showTime={true} label="fields.createdon" source="createdon" />
				<DateField showTime={true} label="fields.modifiedon" source="modifiedon" />
				<ExtendedTextField label="fields.modecommid" source="modecommid" domainid={92} />
			</Datagrid>
		)
	}

	render() {
		const {
			classes = {},
			record,
			isLoading
		} = this.props

		if (isLoading)
			return null

		return (
			<Card>
				<CardContent className={classes.flex}>
					{isEmpty(record) ? (<span className={classes.colorText}>Aucun contenu n'est provisionné.</span>) : this.customGrid(record, classes)}
				</CardContent>
			</Card>
		)
	}
}

const mapStateToProps = state => ({
	record: state.admin.resources['Contentsworkflow/Product']
		? state.admin.resources['Contentsworkflow/Product'].data
		: {},
	ids: state.admin.resources['Contentsworkflow/Product']
		? state.admin.resources['Contentsworkflow/Product'].list.ids
		: {},
	isLoading: state.admin.loading
})

const mapDispatchToProps = {
	fetchEnd,
	fetchStart,
	showNotification,
	crudGetList: crudGetListAction,
	setSidebarVisibility
}

const enhance = compose(
	connect(mapStateToProps, mapDispatchToProps),
	withStyles(styles),
	withWidth({ resizeInterval: Infinity }) // used to initialize the visibility on first render
)

export default enhance(ContentsworkflowList)
