import React from 'react'

import Tab from '@material-ui/core/Tab'
import Tabs from '@material-ui/core/Tabs'
import { withStyles } from '@material-ui/core/styles'
import MenuItem from '@material-ui/core/MenuItem'
import TextField from '@material-ui/core/TextField'
import Select from '@material-ui/core/Select'
import { InputLabel ,Badge} from '@material-ui/core'
import IconButton from '@material-ui/core/IconButton'
import Switch from '@material-ui/core/Switch'
import Radio from '@material-ui/core/Radio'
import Tooltip from '@material-ui/core/Tooltip'
import Zoom from '@material-ui/core/Zoom'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import cyan from '@material-ui/core/colors/cyan'
import Slide from '@material-ui/core/Slide'
import FormHelperText from '@material-ui/core/FormHelperText'
import Button from '@material-ui/core/Button'
import { CancelIcon, CheckIcon, EditIcon, FormatListBulletedIcon, InfoIcon, CloseIcon, CheckCircleOutlineIcon, ErrorOutlineIcon, AddIcon, EuroIcon } from '../../constants/icons'
import { Link } from 'react-router-dom'
import Alert from '@material-ui/lab/Alert'
import Collapse from '@material-ui/core/Collapse'
import { Checkbox ,Typography} from '@material-ui/core'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Icon from '@material-ui/core/Icon'
import LinearProgressGlobal from '../common/LinearProgressGlobal'
import InputAdornment from '@material-ui/core/InputAdornment'
import Autocomplete from '@material-ui/lab/Autocomplete'
import Fab from '@material-ui/core/Fab'
import FormControl from "@material-ui/core/FormControl"
import CircularProgressWithLabel from '../layout/CircularProgressWithLabel'
import Divider from '@material-ui/core/Divider'
import BlockIcon from '@material-ui/icons/Block'

const AntTabs = withStyles({
	indicator: {
		backgroundColor: '#FFF',
		marginBottom: '-.3em'
	},
	root: {
		//paddingLeft: '9px',
		borderBottom: '.2em solid #288DB1',
		marginTop: '-3em',
		marginBottom: '.5em',
		'&$selected': {
			color: '#111'
		}

	},
})(Tabs)

const AntTab = withStyles((theme) => ({
	root: {
		maxWidth: '100%',
		height: '2em',
		borderBottomLeftRadius: '-2px',
		borderBottomRightRadius: '-2px',
		margin: '0',
		textTransform: 'none',
		color: '#000',
		fontStyle: 'bold',
		'&:hover': {
			backgroundColor: '#288DB1',
			color: '#FFF',
			borderTopLeftRadius: '0px',
			borderTopRightRadius: '0px',
		},
		'&:selected': {
			backgroundColor: '#288DB1',
			color: '#FFF',
			borderTopLeftRadius: '0px',
			borderTopRightRadius: '0px',
		},
		'&:focused': {
			backgroundColor: '#288DB1',
			color: '#FFF',
			borderTopLeftRadius: '0px',
			borderTopRightRadius: '0px',
		},

	},
	selected: {
		backgroundColor: '#288DB1 !important',
		color: '#FFF',
		borderTopLeftRadius: '0px',
		borderTopRightRadius: '0px',
		outline: '.2em solid #288DB1',
		fontWeight: 'bold',
	},
	wrapper: {
		flexDirection: 'row',
	},
	labelIcon: {
		minHeight: '50px'
	}
}))((props) => <Tab {...props} />)

const CustomTextField = withStyles((theme) => ({
	label: {
		marginTop: '0',
		marginBottom: '.5em',
		color: '#288DB1',
		fontWeight: 'bold',
		fontSize: '0.97em',
		fontFamily: 'Roboto, Helvetica, Arial, sans-serif',
		lineHeight: 1,
		// "&$focused": {
		// 	color: '#fff',
		// 	backgroundColor: '#39a5cb'
		// },
		focused: {
			color: '#000',
		},
		hover: {
			color: '#000',
		},
	},
	customText: {
		margin: '.5em .5em .5em .5em',
	},
	helperText: {
		// position: "relative",
		// right: "1.2em",
		// width: "100%",
		// textAlign: "end"
		position: "absolute",
		marginRight: "1%",
		bottom: "-1.6em",
		right: "0",
		fontSize:'0.7rem'
	}
}))((props) =>
	<div className={props.classes.customText} style={props.style}>
		{/* <InputLabel htmlFor="input-with-icon-adornment" className={props.classes.label} required={props.required ? props.required : false}>{props.label}</InputLabel> */}
		{props.create ?
			<TextField
				//required   
				label={props.label}
				className={props.classes.label}
				required={props.required ? props.required : false}
				InputLabelProps={{
					shrink: true,
					className: props.classes.label
				}

				}
				
				type={props.type ? props.type : 'text'}
				variant="outlined" size="small" value={props.value}
				onChange={(e) => props.handleChange(e)} //error={props.errorLabel != null ? true : false}
				multiline={props.multiline} rows={props.rows} fullWidth={props.fullWidth}
				onKeyPress={(e) => props.handleKeyPress ? props.handleKeyPress(e) : null}
				InputProps={{
					endAdornment: <>
						{props.textAdornment && props.textAdornment.length > 0 ?
							<InputAdornment position="end" size="small">
								{props.textAdornment}
							</InputAdornment>
							:
							props.removeicon && <InputAdornment position="end" size="small">
								<IconButton
									aria-label='supprimer'
								>
									{props.removeicon && props.value !== '' ? <CloseIcon onClick={props.valueReset} /> : <div></div>
									}
								</IconButton>
							</InputAdornment>
						}
					</>
				}}
				inputProps={{ maxLength: props.textFieldLenght, readOnly :props.readOnly }}
				disabled={props.disabled ? props.disabled : null}
				helperText={props.textFieldLenght ? `${parseInt(props.textFieldLenght) - props.value.length} caractères restants. ` + (props.errorLabel ? props.errorLabel : "") : (props.errorLabel ? props.errorLabel : "")}
				error={props.errorLabel && props.errorLabel.length > 0}
				FormHelperTextProps={{
					classes: {
						root: props.classes.helperText
					}
				}}
				/>
			:
			// <TextField
			// 	variant="outlined" size="small" disabled={props.disabled} value={props.value} defaultValue={props.defaultValue} readOnly={props.readOnly}
			// 	inputProps={props.inputProps}
			// 	onFocus={props.onFocus}
			// 	onChange={props.onChange}
			// 	onClick={props.onClick}
			// 	multiline={props.multiline} rows={props.rows} fullWidth={props.fullWidth} />
			// />
			// :
			<TextField
				variant="outlined" size="small" disabled={props.disabled} value={props.value} defaultValue={props.defaultValue} readOnly={props.readOnly}
				inputProps={props.inputProps}
				type={props.type ? props.type : 'text'}
				className={props.classes.label}
				onFocus={props.onFocus}
				onChange={props.onChange}
				onClick={props.onClick}
				onKeyDown={props.onKeyDown}
				label={props.label}
				required={props.required ? props.required : false}
				error={props.errorLabel && props.errorLabel.length > 0 }
				helperText={props.errorLabel}
				FormHelperTextProps={{
					classes: {
						root: props.classes.helperText
					}
				}}
				InputLabelProps={{
					shrink: true,
					className: props.classes.label
				}}
				multiline={props.multiline} rows={props.rows} fullWidth={props.fullWidth}
				InputProps={{
					endAdornment: <>
						{props.textAdornment ?
							<InputAdornment position="end" size="small">
								{props.textAdornment}
							</InputAdornment>
							:
							props.endAdornmentIcon ?
							<InputAdornment position="end">
								<IconButton
									onClick={props.handleEndAdornmentClick}
									onMouseDown={props.handleEndAdornmentMouseDown}
									size="small"
									style={{verticalAlign:"middle"}}
								>
									{props.endAdornmentIcon}
								</IconButton>
							</InputAdornment>
							: null
						}
					</>
				}}
			/>
		}
	</div>
)

const CustomSelect = withStyles((theme) => ({
	label: {
		marginTop: '0',
		marginBottom: '.5em',
		color: '#288DB1',
		fontWeight: 'bold',
		fontSize: '1em',
		fontFamily: 'Roboto, Helvetica, Arial, sans-serif',
		lineHeight: 1,
		transform: 'translate(14px, -6px) scale(0.7)',

	},
	customText: {
		margin: '.5em .5em .5em .5em',
		color: '#000',
		paddingBottom: '5px'
	},
	select: {
		minWidth: 200,
		maxHeight: 40,
	},
	helperText: {
		position: "relative",
		right: "1.2em",
		width: "100%",
		textAlign: "end",
		color: "#F44336"
	},
	menuPaper:{
		maxHeight:'320px'
	}
}))((props) =>
	<div className={props.classes.customText} >
		<FormControl variant="outlined" fullWidth={props.fullWidth} >
			<InputLabel htmlFor="input-with-icon-adornment" className={props.classes.label} disabled={props.disabled} required={props.required ? props.required : false}>{props.label}</InputLabel>
			<Select className={props.classes.select}
				//placeholder=""
				required={props.required ? props.required : false}
				disabled={props.disabled}
				label={props.label}
				notched={true}
				di
				size="small"
				value={props.value ? props.propsType ==='pricingClass' ? props.value : props.value.id : null}
				onChange={(e) => props.handleChange(e)}
				inputlabelprops={{
					shrink: true
				}}
				inputprops={{
					readOnly: true,
				}}
				MenuProps={{
					anchorOrigin: {
					  vertical: "bottom",
					  horizontal: "left"
					},
					getContentAnchorEl: null,
					classes: { paper: props.classes.menuPaper }
				  }}
				fullWidth={props.fullWidth}
				// inputProps={{ 'aria-label': 'Without label' }}
				error={props.errorLabel != null ? true : false}
				variant="outlined" {...props}>
				{props.propsType === "domainItem" ?
					(props.values ? props.values.map((value) => {
						return <MenuItem key={parseInt(value.itemid)} disabled={value.disabled ? value.disabled : false} value={parseInt(value.itemid)}>{`${value.label} (${value.itemid})`}</MenuItem>
					}) : []) : (
						props.propsType === "Responsible" ?
							(props.values ? props.values.map((value) => {
								return <MenuItem key={value.id} value={value.id}>{value.gsm}</MenuItem>
							}) : [])
							:
							props.values ? props.values.map((value) => {
								return <MenuItem key={value.id} value={value.id}>{value.name}</MenuItem>
							}) : [])}
			</Select>
			<FormHelperText className={props.classes.helperText}>{props.errorLabel}</FormHelperText>
		</FormControl>
	</div>
)



const InputLabelTitle = withStyles((theme) => ({
	label: {
		marginTop: '0',
		marginBottom: '1em',
		color: '#288DB1',
		fontWeight: 'bold',
		fontSize: '13px',
		fontFamily: 'Roboto, Helvetica, Arial, sans-serif',
		lineHeight: 1,
	},
	customText: {
		margin: '.5em .5em .5em .5em',
		marginTop: '1.2rem',
		color: '#000'
	},
}))((props) =>
	<div className={props.classes.customText} style={props.style}>
		<InputLabel htmlFor="input-with-icon-adornment" className={props.classes.label}>{props.label}</InputLabel>
	</div>
)

const CustomUpdateBar = withStyles((theme) => ({
	container: {
		position: 'absolute',
		right: '1em',
		zIndex: 0,
		top: '50%',
		transform: 'translate(0, -50%)'
	},
	customText: {
		color: '#000',
		marginRight: '2em'
	},
	buttonCancel: {
		margin: theme.spacing(1),
		width: '150px',
		background: '#e23434'
	},
	buttonCheck: {
		margin: theme.spacing(1),
		width: '150px',
		background: '#0c9e0c',
		'&:hover': {
			background: '#509f30'
		}
	},
	leftIcon: {
		marginRight: theme.spacing(1),
	},
}))((props) =>
	<div className={props.classes.container}>
		<Button color="primary" variant="contained" className={props.classes.buttonCheck} onClick={props.method}>
			<CheckIcon className={props.classes.leftIcon} />
			Valider
		</Button>
		{props.to ?
			(<Button component={Link} color="secondary" variant="contained" className={props.classes.buttonCancel} to={props.to} onClick={props.cancel}>
				<CancelIcon className={props.classes.leftIcon} />
				Annuler
			</Button>)
			:
			(<Button color="secondary" variant="contained" className={props.classes.buttonCancel} onClick={props.cancel}>
				<CancelIcon className={props.classes.leftIcon} />
				Annuler
			</Button>)
		}
	</div>
)

const CustomSwitch = withStyles({
	switchBase: {
		color: '#288DB1',
		'&$checked': {
			color: '#288DB1',
		},
		'&$checked + $track': {
			backgroundColor: '#619fbc',
		},
	},
	checked: {
		track:{
			backgroundColor: '#619fbc'
		}
	},
	track: {},
})(Switch);

const CustomRadio = withStyles({
	checked: {
		color: '#288DB1',
		'&$checked': {
			color: '#288DB1',
		},
		'&$checked + $track': {
			backgroundColor: '#288DB1',
		},
	},
	// track: {},
})(Radio)

const CustomShowBar = withStyles((theme) => ({
	container: {
		position: 'absolute',
		right: '1em',
		zIndex: 0,
		top: '50%',
		transform: 'translate(0, -50%)'
	},
	customText: {
		color: '#000',
		marginRight: '2em'
	},
	buttonCancel: {
		margin: theme.spacing(1),
		width: '150px',
		background: '#288DB1',
		color: '#fff',
		'&:hover': {
			background: '#6fa5b8'
		}
	},
	buttonCheck: {
		margin: theme.spacing(1),
		width: '150px',
		background: '#0c9e0c',
		'&:hover': {
			background: '#509f30'
		}
	}
	,buttonedit: {
		margin: theme.spacing(1),
		minWidth: '150px',
		background: '#3f51b5',
		'&:hover': {
			background: '#6370ba'
		}
	},
	leftIcon: {
		marginRight: theme.spacing(1),
	},
}))((props) =>
	<div className={props.classes.container}>

		<Tooltip title={props.editButtonTooltip}>
			<span>
				<Button component={Link} color="primary" variant="contained" className={props.classes.buttonedit} to={props.to} disabled={props.disabledEdit ? props.disabledEdit : false}>
					<EditIcon className={props.classes.leftIcon} />
					EDITER
				</Button>
			</span>
		</Tooltip>
		<Button component={Link} color="primary" variant="contained" className={props.classes.buttonCancel} to={props.back}>
			<FormatListBulletedIcon className={props.classes.leftIcon} />
			LISTE
		</Button>
	</div>
)
const CustomShowBarWithAddBtn = withStyles((theme) => ({
	container: {
		position: 'absolute',
		right: '1em',
		zIndex: 0,
		top: '50%',
		transform: 'translate(0, -50%)'
	},
	customText: {
		color: '#000',
		marginRight: '2em'
	},
	buttonCancel: {
		margin: theme.spacing(1),
		width: '150px',
		background: '#288DB1',
		color: '#fff',
		'&:hover': {
			background: '#6fa5b8'
		}
	},
	buttonAdd: {
		margin: theme.spacing(1),
		width: '150px',
		background: '#0c9e0c',
		'&:hover': {
			background: '#509f30'
		}
	},
	buttonedit: {
		margin: theme.spacing(1),
		minWidth: '150px',
		background: '#3f51b5',
		'&:hover': {
			background: '#6370ba'
		}
	},
	leftIcon: {
		marginRight: theme.spacing(1),
	},
}))((props) =>
	<div className={props.classes.container}>
		<Button component={Link} color="primary" variant="contained" className={props.classes.buttonAdd} to={props.addto} onClick={props.onAddClick} disabled={props.disabledAdd ? props.disabledAdd : false}>
			<AddIcon className={props.classes.leftIcon} />
			AJOUTER
		</Button>
		<Tooltip title={props.editButtonTooltip}>
			<Button component={Link} color="primary" variant="contained" className={props.classes.buttonedit} to={props.editto} disabled={props.disabledEdit ? props.disabledEdit : false}>
				<EditIcon className={props.classes.leftIcon} />
				EDITER
			</Button>
		</Tooltip>
		<Button component={Link} color="primary" variant="contained" className={props.classes.buttonCancel} to={props.back}>
			<FormatListBulletedIcon className={props.classes.leftIcon} />
			LISTE
		</Button>
	</div>
)
const CustomAlert = withStyles((theme) => ({
	root: {
		width: '100%',
		'& > * + *': {
			marginTop: theme.spacing(2),
		},
	},
	alert: {
		position: 'fixed',
		top: '3.5em',
		zIndex: 10000,
		width: '20em',
		height: '4em',
		right: '-9.5em',
		transform: 'translate(-50%)',
	},
	span: {
		top: '50%',
		left: '60%',
		position: 'absolute',
		transform: 'translate(-50%,-50%)',
		width: '80%'
	},
	icon: {
		position: 'absolute',
		top: '50%',
		transform: 'translate(0, -50%)'
	},
}))((props) =>
	<div className={props.classes.root}>
		<Collapse in={props.open}>
			<Alert severity={props.severity} variant="filled" className={props.classes.alert}
				iconMapping={props.severity === "success" ?
					{ success: <CheckCircleOutlineIcon fontSize="inherit" className={props.classes.icon} /> } : 
					props.severity === "info" ? { info :<InfoIcon fontSize="inherit" className={props.classes.icon} /> }
					: { error: <ErrorOutlineIcon fontSize="inherit" className={props.classes.icon} /> }
				}
			>
				{props.open ? <LinearProgressGlobal setOpen={props.setOpen} severity={props.severity} /> : null}
				<span className={props.classes.span}>{props.libelle}</span>
			</Alert>
		</Collapse>
	</div>
)
const CustomAlertInfo = withStyles((theme) => ({
	root: {
		width: '100%',
		'& > * + *': {
			marginTop: theme.spacing(2),
		},
	},
	alert: {
		position: 'fixed',
		top: '3.5em',
		zIndex: 10000,
		width: '20em',
		height: '4em',
		right: '-9.5em',
		transform: 'translate(-50%)',
	},
	span: {
		top: '50%',
		left: '60%',
		position: 'absolute',
		transform: 'translate(-50%,-50%)',
		width: '80%'
	},
	icon: {
		position: 'absolute',
		top: '50%',
		transform: 'translate(0, -50%)'
	},
}))((props) =>
	<div className={props.classes.root}>
		<Collapse in={props.open}>
			<Alert severity={props.severity} variant="filled" className={props.classes.alert}
				iconMapping={ <InfoIcon fontSize="inherit" className={props.classes.icon} /> }		
			>
				{props.open ? <CircularProgressWithLabel setOpen={props.setOpen} severity={props.severity} stateid={props.stateid} /> : null}
				<span className={props.classes.span}>{props.libelle}</span>
			</Alert>
		</Collapse>
	</div>
)
const CustomInfoTooltip = withStyles((theme) => ({
}))((props) =>
	<Tooltip title={props.label}
		placement={props.placement}
		TransitionComponent={Zoom}
	>
		<IconButton>
			<InfoIcon />
		</IconButton>
	</Tooltip>
)
const CustomFooterList = withStyles((theme) => ({
	root: {
		height:'40px',
		display:'flex', 	
		justifyContent: 'flex-end',	
		padding: '20px 24px 0px 24px',
	},
}))((props) =>
<div>
 	<Divider />
	<div className={props.classes.root} >
		Nb. d'éléments : {props.curent} / {props.total} 
	</div>
</div>		 
)

const CustomCheckBox = withStyles((theme) => ({
	label: {
		marginTop: '0',
		marginBottom: '1em',
		color: '#288DB1',
		fontWeight: 'bold',
		fontSize: '13px',
		fontFamily: 'Roboto, Helvetica, Arial, sans-serif',
		lineHeight: 1,
		disabled: {
			color: '#288DB1',
			backgroundColor: '#288DB1',
		},
	},
	customcheck:{
		color: '#288DB1',
		'&$checked': {
			color: '#288DB1',
		},
	},
	customText: {
		margin: '.5em .5em .5em .5em'
	}
}))((props) =>
	<Tooltip title={props.tooltipTitle ?props.tooltipTitle : '' }>
	<FormControlLabel
		control={(props.type==='export' && props.disabled) ? 
		<Checkbox icon={<BlockIcon />} checkedIcon={<BlockIcon />} color='#288DB1'   style={{ marginTop: props.isprice ? '15px' : '0px' }} />
		: <Checkbox color='#288DB1'  checked={props.value} className={props.classes.customcheck} style={{ marginTop: props.isprice ? '15px' : '0px' }} />}
		className={props.classes.label}
		label={props.label}
		style={{ color: props.color ? props.color : '#288DB1' }}
		labelPlacement={props.labelPlacement ? props.labelPlacement : "start"}
		disabled={props.disabled ? props.disabled : false}
		onChange={props.onChange}
	/>
	</Tooltip>
)

const CustomButtonGlobal = withStyles((theme) => ({
	button: {
		margin: theme.spacing(1),
		marginTop: '0px',
		backgroundColor: '#288DB1',
		width: '150px',
		maxHeight: '35px',
		fontSize: '0.75rem',
		top: '5px',
		lineHeight: '1.2em',
		'&:hover': {
			background: '#1c627b'
		},
	},
}))((props) =>
	<div className={props.classes.container}>
		{/* onClick={this.handleImportButtonClick} */}
		<Button variant="contained" className={props.classes.button}
			endIcon={<Icon>{props.Icon}</Icon>}>
			{props.title}

		</Button>
	</div>
)
const CustomAddToListButton = withStyles((theme) => ({
	button: {
		boxShadow: 'none',
		width: '35px',
		height: '30px',
		background: '#0c9e0c',
		marginLeft: '4px',
		'&:hover': {
			background: '#509f30'
		}
	},
}))((props) =>
	<Tooltip title={props.title}>
		<Fab size="small" color="primary" className={props.classes.button} component={Link} to={props.link}>
			<AddIcon />
		</Fab>
	</Tooltip>
)
const CustomDisplayDialog = withStyles((theme) => ({
	dialogHeader: {
		padding: '10px 10px 8px',
		background: cyan[500],
		color: 'white',
		fontSize: 'medium',
		fontWeight: 'bold',
		textAlign: 'center',
	},
	listContentBreak: {
		whiteSpace: 'break-spaces',
	},
	listContentnormal: {
		whiteSpace: 'normal',
	},
	closeIcon: {
		width: '15px',
		position: 'absolute',
		top: '0px',
		right: '0px',
		borderRadius: '0',
		color: 'white',
		'&:hover': {
			background: 'red'
		}
	},
	buttonCancel: {
		width: '150px',
		background: '#3f51b5',
		margin: '8px',
	},
	buttonCheck: {
		margin: theme.spacing(1),
		width: '150px',
		background: '#0c9e0c',
		'&:hover': {
			background: '#509f30'
		}
	},
	buttonClose: {
		margin: theme.spacing(1),
		width: '150px',
		background: '#e23434'
	},
	leftIcon: {
		marginRight: '.5em',
	},
}))((props) =>
	<Dialog onClose={props.handleClose} fullWidth={true} maxWidth={props.width ? props.width : "md"} 
			disableEscapeKeyDown={true}
			disableBackdropClick = {true}
			aria-labelledby="customized-dialog-title" open={props.open} TransitionComponent={Transition}>
		<div className={props.classes.dialogHeader}>
			{props.title?props.title:props.info.toUpperCase()}
			<Button onClick={props.handleClose} className={props.classes.closeIcon} >
				<CloseIcon />
			</Button>
		</div>
		<DialogContent dividers className={props.info === 'proprietesList' ? props.classes.listContentBreak : props.classes.listContentnormal}>
			{props.content}
		</DialogContent>
		<DialogActions>
			{props.info === 'Alerte' ?
				<div>
					<Button onClick={props.handleSave} color="secondary" variant="contained" className={props.classes.buttonCheck}>
						<CheckIcon className={props.classes.leftIcon} />
						Valider
					</Button>
					{props.type === 'window' ?
						<Button onClick={props.handleClose} color="secondary" variant="contained" className={props.classes.buttonClose} >
							<CancelIcon className={props.classes.leftIcon} />
							Annuler
						</Button> :
						<Button component={Link} onClick={props.handleClose} color="secondary" variant="contained" className={props.classes.buttonClose} to={props.to}>
							<CancelIcon className={props.classes.leftIcon} />
							Annuler
						</Button>}
				</div>
				:
				<Button onClick={props.handleClose} color="secondary" variant="contained" className={props.classes.buttonClose}>
					<CancelIcon className={props.classes.leftIcon} />
					Fermer
				</Button>
			}
		</DialogActions>
	</Dialog>
)

const CustomDialog = withStyles((theme) => ({
	dialogHeader: {
		padding: '10px 10px 8px',
		background: cyan[500],
		color: 'white',
		fontSize: 'medium',
		fontWeight: 'bold',
		textAlign: 'center',
	},
	closeIcon: {
		width: '15px',
		position: 'absolute',
		top: '0px',
		right: '0px',
		borderRadius: '0',
		color: 'white',
		'&:hover': {
			background: 'red'
		}
	},
	buttonCancel: {
		width: '150px',
		background: '#e23434',
	},
	buttonCheck: {
		margin: theme.spacing(1),
		width: '150px',
		background: '#0c9e0c',
		'&:hover': {
			background: '#509f30'
		}
	},
	leftIcon: {
		marginRight: '.5em',
	},
}))((props) =>
	<Dialog onClose={props.handleClose} fullWidth={true} maxWidth={props.width ? props.width : "md"} 
		disableEscapeKeyDown={true}
		disableBackdropClick = {true}
		aria-labelledby="customized-dialog-title" 
		open={props.open} 
		TransitionComponent={Transition}>
		<div className={props.classes.dialogHeader}>
			{props.info.toUpperCase()}
			<Button onClick={props.handleClose} className={props.classes.closeIcon} >
				<CloseIcon />
			</Button>
		</div>
		<DialogContent dividers>
			{props.content}
		</DialogContent>
		<DialogActions>
			
			{props.info !== "Alerte" && 
			<Button onClick={props.method} color="secondary" variant="contained" className={props.classes.buttonCheck}>
				{props.info.toUpperCase() === "SUPPRIMER" ? <CheckIcon className={props.classes.leftIcon} /> :<EditIcon className={props.classes.leftIcon} /> }
					Valider
			</Button>
			}
			

			<Button onClick={props.handleClose} color="secondary" variant="contained" className={props.classes.buttonCancel}>
				<CancelIcon className={props.classes.leftIcon} />
				Annuler
			</Button>
		</DialogActions>
	</Dialog>
)

const Transition = React.forwardRef(function Transition(props, ref) {
	return <Slide direction="up" ref={ref} {...props} />
})
const CustomAutocomplete = withStyles((theme) => ({
	label: {
		marginTop: '0',
		marginBottom: '.5em',
		color: '#288DB1',
		fontWeight: 'bold',
		fontSize: '1em',
		fontFamily: 'Roboto, Helvetica, Arial, sans-serif',
		lineHeight: 1,
		transform: 'translate(14px, -6px) scale(0.7)'
	},
	customText: {
		margin: '.5em .5em .5em .5em',
		color: '#000'
	},
	select: {
		minWidth: 200,
		maxHeight: 40,
	},
	helperText: {
		position: "relative",
		right: "1.2em",
		width: "100%",
		textAlign: "end",
		color: "#F44336"
	}
}))((props) =>
	<div className={props.classes.customText} >
		<FormControl variant="outlined" fullWidth={props.fullWidth} >
			<InputLabel htmlFor="input-with-icon-adornment" className={props.classes.label} required={props.required ? props.required : false}>{props.label}</InputLabel>
			<Autocomplete className={props.classes.select}
				options={props.values}
				label={props.label}
				getOptionLabel={(option) => option.label}
				value={props.value ? props.value.id : null}
				//style={{ width: 300 }}
				renderInput={(params) => <TextField {...params} variant="outlined" placeholder=" " />}
				notched={true}
				size="small"
				onChange={(e, newvalue) => props.handleChange(e, newvalue)}
				// inputValue={props.inputValue ? props.inputValue : null}
				// onInputChange={(e,newinputValue) => props.handleChange(e,newinputValue)}
				inputlabelprops={{
					//shrink: true,
				}}
				inputprops={{
					readOnly: true,
				}}
				// inputProps={{ 'aria-label': 'Without label' }}
				error={props.errorLabel != null ? true : false}
				variant="outlined" {...props}>

			</Autocomplete>
			<FormHelperText className={props.classes.helperText}>{props.errorLabel}</FormHelperText>
		</FormControl>
	</div>
)
const StyledBadge = withStyles((theme) => ({
	badge: {
	  right: -15,
	  top: -3,
	color:"#288DB1",
	backgroundColor:'white',
	  border: `2px solid ${theme.palette.background.paper}`,
	  padding: '2px 3px 2px 2px',
	},
  }))(Badge)

const CustomPriceInput = withStyles((theme) => ({
	label: {
		marginTop: '0',
		marginBottom: '.5em',
		color: '#288DB1',
		fontWeight: 'bold',
		fontSize: '0.97em',
		fontFamily: 'Roboto, Helvetica, Arial, sans-serif',
		lineHeight: 1,
		width: '150px',
		marginLeft: '10px',
		// "&$focused": {
		// 	color: '#fff',
		// 	backgroundColor: '#39a5cb'
		// },
		focused: {
			color: '#000',
		},
		hover: {
			color: '#000',
		},
	},
	customText: {
		//margin: '.5em .5em .5em .5em',
	},
	helperText: {
		// position: "relative",
		// right: "1.2em",
		// width: "100%",
		// textAlign: "end"
		position: "absolute",
		marginRight: "1%",
		bottom: "-1.6em",
		right: "0",
		fontSize:'0.7rem'
	},
	adornment: {
		fontSize: 15
		//marginTop: '15px'
	}
  }))((props) =>
  <div className={props.classes.customText} >
	  <FormControl variant="outlined" fullWidth={props.fullWidth} >
		  <TextField
		  	  type='Number'
			  style={{marginTop:'10px' }}
			  label={props.label}
			  className={props.classes.label}
			  required={props.required ? props.required : false}
			  InputLabelProps={{
				  shrink: true,
				  className: props.classes.label
			  }}
 			  variant="outlined" size="small"
			  value={props.value}
			  onChange={(e) => props.handleChange(e)}
			//placeholder={withPlaceholder ? '€' : null}
 			InputProps={ {
				endAdornment: (
					<InputAdornment position="end">
						<EuroIcon color="disabled" className={props.classes.adornment} />
					</InputAdornment>
				)} }
			inputProps={{   readOnly :props.readOnly }}

	
 		/> 
		  <FormHelperText className={props.classes.helperText}>{props.errorLabel}</FormHelperText>
	  </FormControl>
  </div>
)
 
export {
	AntTabs,
	AntTab,
	CustomTextField,
	CustomSelect,
	CustomUpdateBar,
	CustomSwitch,
	CustomRadio,
	CustomShowBar,
	CustomAlert,
	CustomInfoTooltip,
	CustomDisplayDialog,
	CustomCheckBox,
	CustomButtonGlobal,
	CustomDialog,
	CustomShowBarWithAddBtn,
	InputLabelTitle,
	CustomAutocomplete,
	CustomAddToListButton,
	CustomAlertInfo,
	CustomFooterList,
	StyledBadge,
	CustomPriceInput
	 
}