import React, { Fragment } from 'react'
import { ArrayField, TextField, DeleteButton, Datagrid } from 'react-admin'
import Card from '@material-ui/core/Card'
import { ImageField } from '../../common/fields'
import { withStyles } from '@material-ui/core/styles'

const styles = theme => ({
	cardPaddingMargin: {
		padding: 20,
		margin: 20,
		flex: '1 auto',
		width: '50%',
		height: 750,
		overflow: 'auto'
	},
	cardDiv: {
		flex: '1 auto',
	}
})

const MoviesList = withStyles(styles)(
	({ ids, data, classes, ...props }) => (
		<Fragment>
			<Card className={classes.cardPaddingMargin} {...props} >
				<ArrayField data={data} ids={ids}>
					<Datagrid data={data} ids={ids}>
						<ImageField label="fields.pictures" source="movieid" styleImg="imagesmovies" types="movies" />
						<TextField label="fields.movieid" source="movieid" />
						<DeleteButton />
					</Datagrid>
				</ArrayField>
			</Card>
		</Fragment>        
	)
)

MoviesList.defaultProps = {
	ids: [],
	data: {},
}

export default MoviesList
