import Api from './Api'

const Vouchers = {
	getCsvByPromoId : (promoId) =>
		Api.Requests.getAll('/PromotionsVouchers/List/' + promoId),
	countLines: (promoId) =>
		Api.Requests.get('/PromotionsVouchers/Count/' + promoId),
	GetPromoVoucherHapi: (promoId) =>
		Api.Requests.get('/PromotionsVouchers/GetPromoVoucherHapi/' + promoId)
}

export default Vouchers