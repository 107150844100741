import React ,{useEffect} from 'react'
import PropTypes from 'prop-types'
import { Redirect } from "react-router-dom"
import { createTheme, ThemeProvider } from '@material-ui/core/styles'
import { AntTab, AntTabs, CustomShowBar, CustomUpdateBar, CustomDisplayDialog, CustomAlert, CustomShowBarWithAddBtn } from '../layout/GlobalStyles'
import CompaniesalbumsFiche from './TabItems/CompaniesalbumsFiche'
import Typography from '@material-ui/core/Typography'
import Box from '@material-ui/core/Box'
import { AlbumIcon, MovieIcon, PhotoIcon, DescriptionIcon, BookIcon } from '../../constants/icons'
import AlbumEditorialFiche from './TabItems/AlbumEditorialFiche'
import AlbumMovieFiche from './TabItems/AlbumMovieFiche'
import AlbumImageFiche from './TabItems/AlbumImageFiche'
import SousAlbumFiche from './TabItems/SousAlbumFiche'
import { Links,Token } from '../../tools'
import { OnChangeTab, OnUpdateAlbumEditorial, OnUpdateAlbumFiche, OnAddDissociatedAlbum, OnAssociateSousAlbumList, OnAlbumPictureUpdate,OnAlbumMovieUpdate,OnUpdateProgrammeMode,OnAlbumMovieListUpdate } from '../../actions/companiesAlbums'
import { connect } from 'react-redux'
import compose from 'recompose/compose'
import { validateAlbum, validateEdito, validateSousAlbum, validateImage ,validateAlbumMovie} from './validate'
import CompaniesAlbums from '../../requests/CompaniesAlbums'
import AlbumsPictures from '../../requests/AlbumsPictures'
import CircularProgress from '@material-ui/core/CircularProgress'
import { withStyles } from '@material-ui/core/styles'
import { stringify } from 'query-string'


function TabPanel(props) {
	const { children, value, index, ...other } = props
	return (
		<div
			role="tabpanel"
			hidden={value !== index}
			id={`simple-tabpanel-${index}`}
			aria-labelledby={`simple-tab-${index}`}
			{...other}
		>
			{value === index && (
				<Box p={2}>
					<Typography>{children}</Typography>
				</Box>
			)}
		</div>
	);
}
const styles = theme => ({

	circularProgress: {
		position: 'absolute',
		top: '50%',
		left: '50%',
	}

})
TabPanel.propTypes = {
	children: PropTypes.node,
	index: PropTypes.any.isRequired,
	value: PropTypes.any.isRequired,
};

function a11yProps(index) {
	return {
		id: `simple-tab-${index}`,
		'aria-controls': `simple-tabpanel-${index}`,
	};
}
const CompaniesalbumsShowFields = (props) => {
	const { type, create, classes, tabValue, OnChangeTab, albumFiche, OnUpdateAlbumFiche, albumEditorial, OnUpdateAlbumEditorial,record,OnAlbumMovieUpdate,
		associatesousAlbumList, dissocitedAlbum, OnAddDissociatedAlbum, OnAssociateSousAlbumList,OnUpdateProgrammeMode, OnAlbumPictureUpdate ,albumPicture,
		 isImagePivot, image, albumMovie ,mode,addedMoviesToAlbum,OnAlbumMovieListUpdate } = props
	const [value, setValue] = React.useState(0)
	const [openErrorMessage, setOpenErrorMessage] = React.useState(false)
	const [isLoading, setIsLoading] = React.useState(false)
	const [errorMessage, setErrorMessage] = React.useState('')
	const [openValidateMessageAlert, setopenValidateMessageAlert] = React.useState(false)
	const [openAlbumSaveEnd, setopenAlbumSaveEnd] = React.useState('')
	const [redirect, setRedirect] = React.useState(false)
	const [show, setShow] = React.useState(false)
	const [showError, setShowError] = React.useState(false)
	const [modeNew, setModeNew] = React.useState(false)
	React.useEffect(() => {
		//setShow(true)
		// if(type !== 'create' && albumFiche.id !== 0 && record !== albumFiche) {
		// 	//OnUpdateAlbumFiche(record)	
		// }
	},type,albumFiche)
	// useEffect(() => {
	// 	// if(type == 'show')
	// 	// 	onResetPromo()


	// 	if (type !== 'create') {
	// 		setIsLoading(true)
	// 		if (record && record.id !== 0) {
	// 			// Promotions.getPromotionById(record.id).then((json) => {
	// 			// 	if (json.data !== undefined) {
	// 			// 		onPromotionLoad(json.data)
	// 			// 		setIsLoading(false)
	// 			// 	}
	// 			// })
	// 			setIsLoading(false)
	// 		}
	// 	}
	// }, OnUpdateAlbumFiche, albumFiche)
	const handleChange = (event, newValue) => {
		if(mode !== '') setOpenErrorMessage(true)
		if ((value !== newValue || newValue === 0 ) && create) setOpenErrorMessage(true)
		if (!create && mode==='') {
			setValue(newValue)
			OnChangeTab(newValue)
		}
	}
	const changeMode = () => {
		window.location.href && window.location.href.split('/')[6] === 'create'  ? setModeNew(true) : setModeNew(false)
	}
	const handleOpenAlert = () => {
		setShow(false)
		setShowError(false)
		setopenAlbumSaveEnd(false)
	}
	const getMuiTheme = () => createTheme({
		overrides: {
			MuiGrid: {
				container: {
					padding: '20px'
				}
			},
			MuiTab: {
				textColorInherit: {
					"&$disabled": {
						opacity: '0.25'
					},
				}
			},

		},
	})
	const EditActions = () => (
		<CustomUpdateBar to={Links.CompaniesalbumsShow(albumFiche.id)} method={() => saveAlbumItem()} cancel={() => Oncancel()} />
	)
	const ShowActions = () => (
		<CustomShowBar editButtonTooltip='' disabledEdit={(tabValue===2 && albumMovie && albumMovie.id ==='')} to={Links.CompaniesalbumsEdit(props.record.id, tabValue)} back={Links.CompaniesalbumsList()} />
	)
	const ShowActionsWithAdd = () => (
		<CustomShowBarWithAddBtn disabledEdit={(tabValue === 3 )//	&& (isImagePivot ||(albumPicture && albumPicture.id === '')) )
			 || (tabValue === 1 && albumEditorial.name==='')} 
			 disabledAdd ={(tabValue ===3)}
			 
			 editButtonTooltip='' 
		onAddClick={() => changeMode()} addto={tabValue === 0 ? Links.CompaniesalbumsAdd(props.record.id ) : Links.CompaniesalbumsAddItem(props.record.id, tabValue)} editto={Links.CompaniesalbumsEdit(props.record.id, value)} back={Links.CompaniesalbumsList()} />
	)
	const CreateActions = () => (
		<CustomUpdateBar to={albumFiche && albumFiche.id ? Links.CompaniesalbumsShow(albumFiche.id) :Links.CompaniesalbumsList()} method={() => saveAlbumItem()} cancel={() => Oncancel()} />
	)
	const handleCloseErrorMessage = () => {
		setOpenErrorMessage(false)
	}
	const handleCloseValidateErrorMessage = () => {
		setopenValidateMessageAlert(false)
	}
	const Oncancel = () => {
		OnUpdateAlbumFiche({})
		OnUpdateAlbumEditorial({})
		OnAlbumMovieUpdate()
		OnAlbumPictureUpdate()
		OnUpdateProgrammeMode('')
	}
	const createListMessageError = (list) => (
		<ul >
			{list ? list.map(item => (
				<li>{item}</li>
			)) : <div />}
		</ul>
	)
	const saveAlbumItem = () => {
		setIsLoading(true)
		switch (tabValue) {
			case 0:
				let validate = validateAlbum(albumFiche)
				if (!validate.success) {
					setErrorMessage(createListMessageError(validate.message))
					setopenValidateMessageAlert(true)
					setIsLoading(false)
				} else {
					//CompaniesAlbums.SaveAlbum(value)				
					return new Promise((resolve, reject) => {
						CompaniesAlbums.SaveAlbum(albumFiche).then((data) => {
							setIsLoading(false)
							setShow(true)				
							resolve()
							const timeId = setTimeout(() => {
								OnUpdateAlbumFiche(data? data.data : albumFiche)
								setopenAlbumSaveEnd(true)
								setRedirect(true)
								setShow(false)
							}, 3000)
							return () => {
								clearTimeout(timeId)
							}
							
						}
						).catch((error) => {
							setIsLoading(false)
							//Logger.debug(error)
							reject()
							setShowError(true)
							const timeId = setTimeout(() => {
								setShowError(false)
							}, 3000)
							return () => {
								clearTimeout(timeId)
							}
						})
						// .finally(()=>
						// {
							
						// }
						// )
					})
				}
				break;
			case 1:
				let validEdito = validateEdito(albumEditorial)
				if (!validEdito.success) {
					setErrorMessage(createListMessageError(validEdito.message))
					setopenValidateMessageAlert(true)
					setIsLoading(false)
				} else {
					//CompaniesAlbums.SaveAlbum(value)
					return new Promise((resolve, reject) => {
						CompaniesAlbums.SaveEdito(albumEditorial).then(() => {
							setIsLoading(false)
							setShow(true)
							const timeId = setTimeout(() => {
								OnUpdateAlbumEditorial(albumEditorial)
								setopenAlbumSaveEnd(true)
								resolve()
								setRedirect(true)
								setShow(false)
							}, 3000)
							return () => {
								clearTimeout(timeId)
							}
						}
						).catch((error) => {
							setIsLoading(false)
							//Logger.debug(error)
							reject()
							setShowError(true)
							const timeId = setTimeout(() => {
								setShowError(false)
							}, 3000)
							return () => {
								clearTimeout(timeId)
							}
						})
					})
				}
				break
				case 2: {
					if(mode ===''){
						let validate = validateAlbumMovie(albumMovie)
						console.log(albumMovie)
						if (!validate.success && mode==='' ) {
						setErrorMessage(createListMessageError(validate.message))
						setopenValidateMessageAlert(true)
						setIsLoading(false)
					}} 
					if(mode =='Assistant'){
						setIsLoading(true)
						console.log(addedMoviesToAlbum)
						if(addedMoviesToAlbum){
									return new Promise((resolve, reject) => {
										addedMoviesToAlbum.forEach(element => {
											if(element.rank !== element.newrank){
												element.rank = element.newrank
											 let newAlbumMovie = element
											newAlbumMovie.albumId = props.record.id
												 CompaniesAlbums.updateAlbumsMovies(newAlbumMovie).then((data) => {
													OnAlbumMovieUpdate(albumMovie)
													//setopenAlbumSaveEnd(true)
													setShow(true)
													resolve()
													setRedirect(true)
													setIsLoading(false)
													OnUpdateProgrammeMode('')
												 }).catch((error) => {
													setIsLoading(false)
													//Logger.debug(error)
													reject()
													setShowError(true)
													const timeId = setTimeout(() => {
														setShowError(false)
													}, 3000)
													return () => {
														clearTimeout(timeId)
													}
												})
										 
									}})
														
							})
							OnAlbumMovieListUpdate([])
						}
					}  else if(mode =='Clean'){
						//setopenValidateMessageAlert(true)
						if(addedMoviesToAlbum){
							return new Promise((resolve, reject) => {
								addedMoviesToAlbum.forEach(element => {
									if(element !== 0){
									 //  let newAlbumMovie = element
									// newAlbumMovie.albumId = props.record.id
										 CompaniesAlbums.removeAlbumsMovie(props.record.id,element).then((data) => {
											//setopenAlbumSaveEnd(true)
											setShow(true)
											resolve()
											setRedirect(true)
											setIsLoading(false)
										 }).catch((error) => {
											setIsLoading(false)
											//Logger.debug(error)
											reject()
											setShowError(true)
											const timeId = setTimeout(() => {
												setShowError(false)
											}, 3000)
											return () => {
												clearTimeout(timeId)
											}
										})
								 
							}})
							const timeId = setTimeout(() => {
								OnUpdateProgrammeMode('')
								OnAlbumMovieListUpdate([])	
							}, 5000)
							return () => {
								clearTimeout(timeId)
							}
										
						})
				
						}
						
	
						setIsLoading(false)
					}else{
						return new Promise((resolve, reject) => {
							if (albumMovie) {
								let newAlbumMovie = albumMovie
								console.log(albumMovie)
								newAlbumMovie.albumId = props.record.id
									 CompaniesAlbums.updateAlbumsMovies(albumMovie).then((data) => {
										OnAlbumMovieUpdate(albumMovie)
										setopenAlbumSaveEnd(true)
										setShow(true)
										resolve()
										setRedirect(true)
										setIsLoading(false)
									}).catch((error) => {
										setIsLoading(false)
										//Logger.debug(error)
										reject()
										setShowError(true)
										const timeId = setTimeout(() => {
											setShowError(false)
										}, 3000)
										return () => {
											clearTimeout(timeId)
										}
									})
							}
							 
						})
					}
				}
					break
	
			case 3:
				let validImage = validateImage(albumPicture)
				if (!validImage.success) {
					setErrorMessage(createListMessageError(validImage.message))
					setopenValidateMessageAlert(true)
					setIsLoading(false)
				} else {
					//CompaniesAlbums.SaveAlbum(value)
					return new Promise((resolve, reject) => {
						// let query = {
						// 	sourceId: 1,
						// 	objectId: 2,
						// 	pivotId: albumPicture.typeid,
						// 	id: albumPicture.albumid,
						// 	sendToBddp: false,
						// 	sendtoSFR: false,
						// 	applyToSeason: false
						// }
						const formData = new FormData()
						formData.append('image', image)
						let results = {}
						const initialValues = Object.entries(image).filter(value => value[0] !== 'typeid' && value[0] !== 'pictures')
						initialValues.forEach(value => {
							results[value[0]] = value[1]
							formData.append(value[0], value[1])
						})
		// const url = `${process.env.REACT_APP_API}/AlbumspicturesSpecifique/SaveAlbumPicture}`;
		// fetch(url, {
		// 	method: 'POST',
		// 	headers: new Headers({ 
		// 		//enctype: 'multipart/form-data',
		// 	'Authorization': `Bearer ${Token.getToken()}` }),
		// 	body: albumPicture && 
		// 	body : formData
		//   })
		//   .then(response =>
		// 	{ 
		// 	response.json()
		// 	setopenAlbumSaveEnd(true)
		// 	setShow(true)
		// })
		//   .then(data => {
		// 	console.log(data)
		// 		// Traitez la réponse comme nécessaire
		//   })
		//   .catch(error => {
		// 	console.error('Error:', error);
		// 	setShowError(true)
		// 						const timeId = setTimeout(() => {
		// 							setShowError(false)
		// 						}, 3000)
		// 						return () => {
		// 							clearTimeout(timeId)
		// 						}
		// 	 })
		
						// AlbumsPictures.SaveImage(albumPicture, formData).then(() => {
						// 	setopenAlbumSaveEnd(true)
						// 	setShow(true)
						// 	resolve()
						// 	setRedirect(true)
						// 	// this.setState({
						// 	// 	alertMessage : "Image ajouté avec succès",
						// 	// 	formError: false,
						// 	// 	showalert:true

						// 	// })
						// })
						// 	.catch(error => {
						// 		console.log(error)
						// 		//Logger.debug(error)
						// 		reject()
						// 		setShowError(true)
						// 		const timeId = setTimeout(() => {
						// 			setShowError(false)
						// 		}, 3000)
						// 		return () => {
						// 			clearTimeout(timeId)
						// 		}
 						// 		//showNotification(error.message, 'error')
						// 	})
						// 	.finally(() => {
						// 		// this.setState({ showDialog: false ,warningRequest: false})
						// 		setIsLoading(false)

						// 	})

					})
				}
				break

			
			case 4: {
				let validate = validateSousAlbum(associatesousAlbumList, dissocitedAlbum)
				if (!validate.success) {
					setErrorMessage(createListMessageError(validate.message))
					setopenValidateMessageAlert(true)
					setIsLoading(false)
				} else {
					return new Promise((resolve, reject) => {
						if (associatesousAlbumList) {
							associatesousAlbumList.forEach(element => {
								element.parentid = props.record.id
								CompaniesAlbums.SaveAlbum(element).then((data) => {
									OnAssociateSousAlbumList([])
									setopenAlbumSaveEnd(true)
									setShow(true)
									resolve()
									setRedirect(true)
									setIsLoading(false)
								}).catch((error) => {
									setIsLoading(false)
									//Logger.debug(error)
									reject()
									setShowError(true)
									const timeId = setTimeout(() => {
										setShowError(false)
									}, 3000)
									return () => {
										clearTimeout(timeId)
									}
								})
							})
						}
						if (dissocitedAlbum) {
							dissocitedAlbum.forEach(element => {
								CompaniesAlbums.SaveAlbum(element).then((data) => {
									OnAddDissociatedAlbum([])
									setopenAlbumSaveEnd(true)
									setShow(true)
									resolve()
									setRedirect(true)
									setIsLoading(false)
								}).catch((error) => {
									setIsLoading(false)
									//Logger.debug(error)
									reject()
									setShowError(true)
									const timeId = setTimeout(() => {
										setShowError(false)
									}, 3000)
									return () => {
										clearTimeout(timeId)
									}
								})
							})
						}
					})
				}
			}
				break

			default:
				break;
		}
	}
	return (

		<ThemeProvider theme={getMuiTheme()}>
			{redirect
				? <Redirect to={Links.CompaniesalbumsShow(albumFiche.id)} />
				: null
			}
			{show ? (<CustomAlert libelle={tabValue === 2 ?(mode==='Clean' ? 'Programmes supprimés' :'Programmes enregistrés'):"Album enregistré"} severity="success" open={!openAlbumSaveEnd} setOpen={handleOpenAlert} />) : null}
			{showError ? (<CustomAlert libelle="Erreur lors de l'enregistrement" severity="error" open={showError} setOpen={handleOpenAlert} />) : null}
			<AntTabs value={tabValue} onChange={handleChange} style={{ '&$selected': { color: '#111' }, marginTop: 'auto' }}
				TabIndicatorProps={{
					style: { transition: 'none' }
				}} aria-label="simple tabs example">
				<AntTab label={<span style={{ maxWidth: '8rem', margin: '.5rem' }} >FICHE ALBUM</span>} icon={<BookIcon style={{ marginRight: '.5em' }} />} {...a11yProps(0)} />
				<AntTab label="EDITORIAL" disabled={type === 'create'} icon={<DescriptionIcon style={{ marginRight: '.5em' }} />} {...a11yProps(1)} />
				<AntTab label="PROGRAMMES" disabled={props.record.typeid === 4 || type === 'create'} icon={<MovieIcon style={{ marginRight: '.5em' }} />} {...a11yProps(3)} />
				<AntTab label="IMAGES" disabled={type === 'create'} icon={<PhotoIcon style={{ marginRight: '.5em' }} />} {...a11yProps(2)} />
				<AntTab label="SOUS ALBUMS" disabled={type === 'create'} icon={<AlbumIcon style={{ marginRight: '.5em' }} />} {...a11yProps(4)} />

				{!create && mode ==='' ? ((tabValue ===  2 ||tabValue ===4 ) ?
					<ShowActions /> : <ShowActionsWithAdd />) : type === 'create' ? <CreateActions /> : <EditActions />}
				{tabValue === 2 && mode !=='' && !create && <EditActions />		}
			</AntTabs>
			<TabPanel value={tabValue} index={0}>
				<CompaniesalbumsFiche record={props.record !== albumFiche.id ? props.record : albumFiche} create={create} type={type} />
			</TabPanel>
			<TabPanel value={tabValue} index={1}>
				<AlbumEditorialFiche record={props.record} create={create} type={window.location.href && window.location.href.split('/')[6] === 'create' ? 'create' : 'edit'} />
			</TabPanel>
			<TabPanel value={tabValue} index={2}>
				<AlbumMovieFiche record={props.record} create={create} type={type} />
			</TabPanel>
			<TabPanel value={tabValue} index={3}>
				<AlbumImageFiche record={props.record} create={create} type={window.location.href && window.location.href.split('/')[6] === 'create' ? 'create' : 'edit'} />
			</TabPanel>
			<TabPanel value={tabValue} index={4}>
				<SousAlbumFiche record={props.record} create={create} type={type} />
			</TabPanel>



			<CustomDisplayDialog to={Links.CompaniesalbumsShow(props.record.id)} info='Alerte' handleClose={handleCloseErrorMessage} handleSave={() => saveAlbumItem(albumFiche)} open={openErrorMessage}
				content={"Veuillez valider ou annuler la modification avant de changer d'onglet !"} maxWidth="sm" />
			<CustomDisplayDialog handleClose={handleCloseValidateErrorMessage} open={openValidateMessageAlert} info='Error'
				content={errorMessage} maxWidth="xs" />
			{isLoading && <div className={classes.circularProgress}><CircularProgress size={50} thickness={2} /></div>}

		</ThemeProvider >

	)
}
const mapStateToProps = state => ({
	...state,
	tabValue: state.reducer.albums.tabValue !== undefined ? state.reducer.albums.tabValue : 0,
	albumFiche: state.reducer.albums.albumFiche,
	selectedAlbumParent: state.reducer.albums.selectedAlbumParent,
	albumEditorial: state.reducer.albums.albumEditorial,
	AlbumsData: state.reducer.albums.AlbumsData,
	// programme: state.reducer.albums.programme !== undefined ? state.reducer.albums.programme :null,
	albumPicture: state.reducer.albums.albumPicture !== undefined ? state.reducer.albums.albumPicture : null,
	dissocitedAlbum: state.reducer.albums.dissocitedAlbum,
	associatesousAlbumList: state.reducer.albums.associatesousAlbumList !== undefined ? state.reducer.albums.associatesousAlbumList : [],
	isImagePivot: state.reducer.albums.isImagePivot,
	image: state.reducer.albums.image !== undefined ? state.reducer.albums.image : null,
	albumMovie: state.reducer.albums.albumMovie !== undefined ? state.reducer.albums.albumMovie : null,
	mode : state.reducer.albums.mode ,
	addedMoviesToAlbum: state.reducer.albums.addedMoviesToAlbum !== undefined ? state.reducer.albums.addedMoviesToAlbum : [],

})

const mapDispatchToProps = ({
	OnChangeTab,
	OnUpdateAlbumFiche,
	OnUpdateAlbumEditorial,
	OnAddDissociatedAlbum,
	OnAssociateSousAlbumList,
	OnAlbumPictureUpdate,
	OnAlbumMovieUpdate,
	OnUpdateProgrammeMode,
	OnAlbumMovieListUpdate

})
const enhance = compose(
	connect(mapStateToProps, mapDispatchToProps),
	withStyles(styles)

)
export default enhance(CompaniesalbumsShowFields)

