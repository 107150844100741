import { ON_LOAD_SELECTED_CONFIG, ON_DISPATCHER_SET_SELECTED_TAB, ON_UPDATE_CONFIG } from '../constants/actions'

const defaultState = { 
	payload: {
		configid: 0,
		servicename: "",
		servername: "",
		config:"",
		createdon: "",
		createdby: 0,
		modifiedon:"",
		modifiedby: 0,
		recordid:"",
		disabled:false,
		disabledby: 0,
		disabledon: null
	},
	serviceType: '',
	selectedTabIndex: 0
}

export default (state = defaultState, action) => {
	if (action.type === ON_LOAD_SELECTED_CONFIG) {
		return {
			...state,
			payload: action.payload,
			serviceType: action.serviceType
		}
	} else if(action.type === ON_DISPATCHER_SET_SELECTED_TAB) {
		return {
			...state,
			selectedTabIndex: action.selectedTabIndex,
		}
	} else if(action.type === ON_UPDATE_CONFIG) {
		return {
			...state,
			payload: action.payload,
			serviceType: action.serviceType
		}
	}
	return state
}
