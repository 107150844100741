import React from 'react'
import { SimpleForm, List, Edit, Create } from 'react-admin'
import { MyListActions, MyEditActions, MyCreateActions } from '../../common/actions'
import { ContentsList, ContentsEdit, ContentsCreate } from '.'

const MoviescontentsList = props => (
	<List title="titles.moviesContentsList" actions={<MyListActions />} {...props} pagination={null}>
		<ContentsList />
	</List>
)

const MoviescontentsEdit = props => (
	<Edit title="titles.moviesContentsCard" undoable={false} actions={<MyEditActions />} {...props}>
		<SimpleForm>
			<ContentsEdit />
		</SimpleForm>
	</Edit>
)

const MoviescontentsCreate = props => (
	<Create title="titles.moviesContentsCard" undoable={false} actions={<MyCreateActions />} {...props}>
		<SimpleForm>
			<ContentsCreate />
		</SimpleForm>
	</Create>
)

export { MoviescontentsList, MoviescontentsEdit, MoviescontentsCreate }