import React, { Component } from 'react'
import FormControl from '@material-ui/core/FormControl'
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'
import { onLoadPlanning, updateResponsibleIds } from '../../../actions/publicationTracking'
import { connect } from 'react-redux'
import compose from 'recompose/compose'
import Typography from '@material-ui/core/Typography'
import { withStyles } from '@material-ui/core/styles'

const styles = theme => ({
	formControl: {
		margin: theme.spacing(1),
		width : '150px',
		minWidth: 120,
		textAlign: 'left',
	},
	selectEmpty: {
		marginTop: theme.spacing(2),
	},
	customSelect: {
		width : '100%',
		textAlign: 'left'
	}
})

class UserCell extends Component{
	constructor(props) {
		super(props)
		this.state = {
			initialUserId : 0,
			currentUserId : 0
		}
	}

	componentDidMount(){
		const { user } = this.props
		const userId = user !== undefined ? user.id : 0
		this.setState({
			initialUserId : userId,
			currentUserId : userId
		})
	}

	getSnapshotBeforeUpdate(prevProps, prevState) {
		if(prevProps.planningId !== this.props.planningId){
			this.setState({
				initialUserId : this.props.user !== undefined ? this.props.user.id : 0,
				currentUserId : this.props.user !== undefined ? this.props.user.id : 0,
			})
		}
		return null
	}

	componentDidUpdate(previousProps){
		const { publicationResponsibles, isUpdateResponsibleCompleted } = this.props
		if(publicationResponsibles !== previousProps.publicationResponsibles){
			if(publicationResponsibles.length === 0){
				if(isUpdateResponsibleCompleted){
					this.setState({
						initialUserId : this.state.currentUserId
					})
				}
				else{
					if(this.state.initialUserId !== this.state.currentUserId){
						this.setState({
							currentUserId : this.state.initialUserId
						})
					}
				}
			}
		}
	}

	handleChange = (event) => {
		const { planningId, updateResponsibleIds } = this.props
		const userId = event.target.value
		
		// const publication = {
		// 	PlanningId : planningId,
		// 	ContactId : userId,
		// 	Comments : '',
		// 	CommentLevelId : 1
		// }
		updateResponsibleIds(userId, planningId)
		this.setState({
			currentUserId : userId
		})
	}

	render(){
		const { users, classes, pageType, isUpdateResponsibleLocked, publicationResponsibles ,customWidth} = this.props
		//const userId = user !== undefined ? user.id : 0
		if(pageType === 'planning'){
			return(
				<React.Fragment>
					<FormControl className={classes.formControl}>
						<Select
							labelId="demo-simple-select-helper-label"
							id="demo-simple-select-helper"
							value={publicationResponsibles.length === 0 ? this.state.initialUserId : this.state.currentUserId}
							//value = {userId}
							onChange={event => this.handleChange(event)}
							className = {classes.customSelect}
							disabled = {isUpdateResponsibleLocked  }
						>
							{users.map((value, index) => {
								return <MenuItem key={value.id} value={value.id}>{value.gsm}</MenuItem>
							})}
						</Select>
					</FormControl>
				</React.Fragment>
			)
		}
		else{
			const responsible = users.find(u => u.id === this.state.currentUserId)

			return(
				<React.Fragment>
					<Typography className={classes.formControl} color="inherit" style={{ width: customWidth }}>
						{responsible !== undefined && responsible !== null ? responsible.gsm : ''}</Typography>
				</React.Fragment>
			)
		}
		
	}
}

const mapStateToProps = state => ({
	...state,
	weekNumber: state.reducer.publicationTracking.weekNumber !==null ? state.reducer.publicationTracking.weekNumber : 16,
	yearNumber: state.reducer.publicationTracking.yearNumber !==null ? state.reducer.publicationTracking.yearNumber : 2020,
	isUpdateResponsibleLocked: state.reducer.publicationTracking.isUpdateResponsibleLocked,
	publicationResponsibles : state.reducer.publicationTracking.publicationResponsibles,
	isUpdateResponsibleCompleted : state.reducer.publicationTracking.isUpdateResponsibleCompleted
})

const mapDispatchToProps = ({
	onLoadPlanning,
	updateResponsibleIds
})

const enhance = compose(
	connect(mapStateToProps, mapDispatchToProps),
	withStyles(styles)
)

export default enhance(UserCell)