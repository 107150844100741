
import React, { Component } from 'react'
import { FormControl, Select, InputLabel, MenuItem } from '@material-ui/core'
import Checkbox from '@material-ui/core/Checkbox'

class CustomFilter extends Component {
	render() {
		const { filterList, onChange, index, column, optionsValues, defaultLabel } = this.props
		console.log( this.props)
		if (defaultLabel !== 'Date') {
			return (
				// <FormControl>
				// 	<InputLabel htmlFor='select-multiple-chip'>
				// 		{defaultLabel}
				// 	</InputLabel>
				// 	<Select 
				// 		value={filterList[index]}
				// 		multiple
				// 		// renderValue={(selected) => {
				// 		// 	console.log(selected.join(', '))
				// 		// 	console.log(selected.map(element => ( optionsValues.find(s=>s.value === element).label)).join(', '))
				// 		// 	selected.map(element => ( optionsValues.find(s=>s.value === element).label)).join(', ') }}
				// 		onChange={event => {
				// 			filterList[index]  = event.target.value
				// 			onChange(filterList[index], index, column)
				// 		}}
				// 	>
				// 		{optionsValues.map(item => (
				// 			<MenuItem key={item.value} value={item.value}>
				// 				<Checkbox  />
				// 				{item.label}
				// 			</MenuItem>
				// 		))}
				// 	</Select>
				// </FormControl>
				<FormControl >
					<InputLabel id="demo-mutiple-checkbox-label">{defaultLabel}</InputLabel>
					<Select
						labelId="demo-mutiple-checkbox-label"
						id="demo-mutiple-checkbox"
						multiple
						value={filterList[index]}
						onChange={event => {
							filterList[index] = event.target.value
							onChange(filterList[index], index, column)
						}}
 						renderValue={(selected) => (selected.map(elt => optionsValues.find(s=>s.value === elt)).map(a=>a.label).join(', '))
						}
 					>
						{optionsValues.map((item) => (

							<MenuItem 
								key={item.label} value={item.value}>
								<Checkbox checked={filterList[index].indexOf(item.value) > -1} />
								{item.label}
 							</MenuItem>
						))}
					</Select>
				</FormControl>
			)
		}
		else {
			return (
				<FormControl>
					<InputLabel htmlFor='select-multiple-chip'>
						{defaultLabel}
					</InputLabel>
					<Select
						multiple
						value={filterList[index]}
						onChange={event => {
							filterList[index] = event.target.value
							onChange(filterList[index], index, column)
						}}
						renderValue={(selected) => (selected.map(elt => optionsValues.find(s=>s.value === elt)).map(a=>a.label).join(', '))}
						//anchorEl={null}
						
					>
						{optionsValues.map(item => (
							<MenuItem 
							key={item.value} value={item.value.toString()}>
								<Checkbox value={item.label} checked={filterList[index].indexOf(item.value) > -1} />
									{item.label}
							</MenuItem>
						))}
					</Select>
				</FormControl>
			)
		}
	}
}

export default CustomFilter

 