import CacheItems from './CacheItems'
import { Ratings as request } from '../requests'

class Ratings extends CacheItems {
	constructor(cacheKey = 'dataRatings') {
		super(cacheKey, request.getAll, true)
	}

	static get instance() {
		if (!this.singleton) {
			this.singleton = new Ratings()
			this.singleton.logger.debug(`${this.__proto__.name} > new instance`)
		}

		return this.singleton
	}

	// --------------------
	// Custom methods below
	// --------------------

	// Get one rating
	getItem = (ratingid) => {
		if (ratingid) {
			const data = this.getAllItems()
			if (null != data && data.length > 0) {
				return Object.values(data).find(item => item.id === ratingid)
			}
		}
		return null
	}

	// Get label
	getLabel = (ratingid) => {
		if (ratingid === -1) return 'Tous (-1)'
		const item = this.getItem(ratingid)
		const label = null != item ? item.title : ''
		return label.trim()
	}

	// Get formatted label 
	getFormattedLabel = (ratingid) => {
		if (ratingid) {
			const label = this.getLabel(ratingid)
			return `${label} (${ratingid})`.trim()
		}
		return ''
	}

	// Get formatted list to use in selects
	getSelectList = (withAll = false) => {
		const list = this.getAllItems()
		return this.getGenericItemsList(list, 'id', 'title', withAll)
	}
}

const RatingsInstance = Ratings.instance

export default RatingsInstance
