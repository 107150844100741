import Api from './Api'

const PromotionsVouchers = {
	getAll: () =>
		Api.Requests.getAll('/PromotionsVouchers'),
	getVoucher: (vouchercode) =>
		Api.Requests.getAll('/PromotionsVouchers/List?voucher=' + vouchercode),
	generateVoucherCode: (data,signal) =>
		Api.Requests.postWithBody('/PromotionsVouchers/Generate', data)
}

export default PromotionsVouchers
