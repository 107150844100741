import React from 'react'
import { CardActions, EditButton, ListButton } from 'react-admin'

const sanitizeRestProps = ({
	basePath,
	className,
	record,
	hasEdit,
	hasList,
	resource,
	...rest
}) => rest

const MyShowActions = ({
	basePath,
	className,
	data,
	hasEdit,
	hasList,
	resource,
	...rest
}) =>
	(
		<CardActions className={className} {...sanitizeRestProps(rest)}>
			{hasEdit && <EditButton basePath={basePath} record={data} />}
			{hasList && <ListButton basePath={basePath} />}
		</CardActions>
	)

export default MyShowActions