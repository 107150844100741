import React, { Component, Fragment } from 'react'
import TableCell from '@material-ui/core/TableCell'
import TableRow from '@material-ui/core/TableRow'
import { DateFormatter, DomainsItems } from '../../tools'
import { DomainsType } from '../../constants/types'
import { IconButton } from '@material-ui/core'
import classnames from 'classnames'
import { ExpandMoreIcon, EditIcon } from '../../constants/icons'
import { connect } from 'react-redux'
import compose from 'recompose/compose'
import { PRICING_TOOL_SELECT_LINE } from '../../constants/actions'
import { targets } from './PricingToolUpdate'
import { withStyles } from '@material-ui/core/styles'

const styles = theme => ({
	expand: {
		transform: 'rotate(0deg)',
		marginLeft: 'auto',
		transition: theme.transitions.create('transform', {
			duration: theme.transitions.duration.shortest,
		}),
	},
	expandOpen: {
		transform: 'rotate(180deg)',
	},
})

const CustomTableCell = withStyles(theme => ({
	root: {
		backgroundColor: theme.palette.common.white,
		color: theme.palette.common.black,
		fontSize: 12,
		paddingTop: 2,
		paddingRight: 4,
		paddingBottom: 2,
		paddingLeft: 4,
		border: '1px solid',
		borderColor: theme.palette.common.black,
		textAlign: 'center',
	},
	head: {
		backgroundColor: theme.palette.background.default,
		color: theme.palette.common.white,
		fontSize: 14,
		paddingTop: 2,
		paddingRight: 4,
		paddingBottom: 2,
		paddingLeft: 4,
		border: '1px solid',
		borderColor: theme.palette.common.black,
		textAlign: 'left',
	}
}))(TableCell)

class PricingToolPeriodGrid extends Component {
	state = { expanded: true }

	MapPeriod(id, period) {
		// eslint-disable-next-line react/prop-types
		const { classes } = this.props
		const key = `fragment_${id}_${period.startDate}_${period.endDate}`
		return (
			<Fragment key={key} >
				<TableRow>
					<CustomTableCell variant="head">
						<IconButton
							className={classnames(classes.expand, {
								[classes.expandOpen]: this.state.expanded,
							})}
							onClick={this.handleExpandClick}
							aria-expanded={this.state.expanded}
							aria-label="+"
						>
							<ExpandMoreIcon />
						</IconButton>
					</CustomTableCell>
					<CustomTableCell variant="head" colSpan={14}>{`Période ${DateFormatter.getDatePeriodLocale(period.startDate, period.endDate)}`}</CustomTableCell>
				</TableRow>
				{
					this.state.expanded ?
						this.MapPricings(id, period) :
						null
				}
			</Fragment>
		)
	}

	MapPricings(id, period) {
		if (!period || 0 === period.pricings.length) return null

		const pricingsByPriceType = period.pricings.map(pricing => {
			return this.AddNewMappedPricing(pricing)
		})

		const pricingsByTargets = pricingsByPriceType.reduce((r, { targets, ...pricing }) => {
			var temp = r.find(o => o.targets.some(s => s === targets[0]))
			if (temp) {
				//Object.entries(pricing).forEach(([k, v]) => v === null || (temp[k] = v))
				Object.entries(pricing).forEach(([k, v]) => {
					if (k === 'priceids' || k === 'modecomms' || k === 'targets') {
						if (!temp[k].some(s => s === v[0]))
							temp[k].push(v[0])
					}
					else {
						v === null || (temp[k] = v)
					}
				})
			} else {
				r.push(temp = { targets, ...pricing })
			}
			return r
		}, [])

		const results = pricingsByTargets.reduce((r, { priceVODSD, priceVODHD, priceVOD4K, priceESTSD, priceESTHD, priceEST4K, priceHDDVD, price4KDVD, priceHDBR, price4KBR, ...pricing }) => {
			var temp = r.find(o =>
				o.priceVODSD === priceVODSD &&
				o.priceVODHD === priceVODHD &&
				o.priceVOD4K === priceVOD4K &&
				o.priceESTSD === priceESTSD &&
				o.priceESTHD === priceESTHD &&
				o.priceEST4K === priceEST4K &&
				o.priceHDDVD === priceHDDVD &&
				o.price4KDVD === price4KDVD &&
				o.priceHDBR === priceHDBR &&
				o.price4KBR === price4KBR
			)
			if (temp) {
				Object.entries(pricing).forEach(([k, v]) => {
					if (k === 'priceids' || k === 'modecomms' || k === 'targets') {
						v.forEach(m => {
							if (!temp[k].some(s => s === m))
								temp[k].push(m)
						})
					}
				})
			} else {
				r.push(temp = { priceVODSD, priceVODHD, priceVOD4K, priceESTSD, priceESTHD, priceEST4K, priceHDDVD, price4KDVD, priceHDBR, price4KBR, ...pricing })
			}
			return r
		}, [])

		return (
			results.map(pricing => {
				const selectedRow = {
					priceids: pricing.priceids,
					modecomms: pricing.modecomms,
					targets: pricing.targets,
					dateStartVOD: period.startDate,
					dateEndVOD: period.endDate,
					dateStartEST: period.startDate,
					dateEndEST: period.endDate,
					priceVODSD: pricing.priceVODSD,
					priceVODHD: pricing.priceVODHD,
					priceVOD4K: pricing.priceVOD4K,
					priceESTSD: pricing.priceESTSD,
					priceESTHD: pricing.priceESTHD,
					priceEST4K: pricing.priceEST4K,
					priceHDDVD: pricing.priceHDDVD,
					price4KDVD: pricing.price4KDVD,
					priceHDBR: pricing.priceHDBR,
					price4KBR: pricing.price4KBR,
				}
				return (
					<TableRow key={`grid_pricing_line_${pricing.priceids.join('_')}`} hover={true} selected={true}>
						<CustomTableCell variant="head">
							<IconButton
								onClick={this.handleSelectClick.bind(this, selectedRow)}
								aria-label=">"
								data={selectedRow}
							>
								<EditIcon />
							</IconButton>
						</CustomTableCell>
						<CustomTableCell>{id}</CustomTableCell>
						<CustomTableCell style={{ display: 'none' }}>{pricing.priceids.join(',')}</CustomTableCell>
						<CustomTableCell>
							{
								pricing.modecomms.map((m, i) => {
									const modecomm = DomainsItems.getLabel(DomainsType.CONTRACTCOMMERCIALIZATIONMODE, m)
									return i > 0 ? ' / ' + modecomm : modecomm
								})
							}
						</CustomTableCell>
						<CustomTableCell>
							{
								pricing.targets.map((t, i) => {
									let target = targets.find(f => f.id === t)
									target = target ? target.name : ''
									return i > 0 ? ' / ' + target : target
								})
							}
						</CustomTableCell>
						<CustomTableCell>{`${DateFormatter.getDateLocale(period.startDate)} - ${DateFormatter.getDateLocale(period.endDate)}`}</CustomTableCell>
						<CustomTableCell>{pricing.priceVODSD}</CustomTableCell>
						<CustomTableCell>{pricing.priceVODHD}</CustomTableCell>
						<CustomTableCell>{pricing.priceVOD4K}</CustomTableCell>
						<CustomTableCell>{pricing.priceESTSD}</CustomTableCell>
						<CustomTableCell>{pricing.priceESTHD}</CustomTableCell>
						<CustomTableCell>{pricing.priceEST4K}</CustomTableCell>
						<CustomTableCell>{pricing.priceHDDVD}</CustomTableCell>
						<CustomTableCell>{pricing.price4KDVD}</CustomTableCell>
						<CustomTableCell>{pricing.priceHDBR}</CustomTableCell>
						<CustomTableCell>{pricing.price4KBR}</CustomTableCell>
					</TableRow>
				)
			})
		)
	}

	isVODSD = (pricing) => pricing.modeCommId === 1 && pricing.mediaId === 1
	isVODHD = (pricing) => pricing.modeCommId === 1 && pricing.mediaId === 2
	isVOD4K = (pricing) => pricing.modeCommId === 1 && pricing.mediaId === 4 && (pricing.subMediaId === 1 || pricing.subMediaId === 2)
	isESTSD = (pricing) => pricing.modeCommId === 3 && pricing.mediaId === 1
	isESTHD = (pricing) => pricing.modeCommId === 3 && pricing.mediaId === 2
	isEST4K = (pricing) => pricing.modeCommId === 3 && pricing.mediaId === 4 && pricing.subMediaId === 9
	isHDDVD = (pricing) => pricing.typeId === 4 && (pricing.mediaId === 1 || pricing.mediaId === 2)
	is4KDVD = (pricing) => pricing.typeId === 4 && pricing.mediaId === 4
	isHDBR = (pricing) => pricing.typeId === 5 && (pricing.mediaId === 1 || pricing.mediaId === 2)
	is4KBR = (pricing) => pricing.typeId === 5 && pricing.mediaId === 4

	AddNewMappedPricing(pricing) {
		return ({
			priceids: [pricing.priceId],
			modecomms: [pricing.modeCommId],
			targets: [pricing.target],
			priceVODSD: this.isVODSD(pricing) ? pricing.price : null,
			priceVODHD: this.isVODHD(pricing) ? pricing.price : null,
			priceVOD4K: this.isVOD4K(pricing) ? pricing.price : null,
			priceESTSD: this.isESTSD(pricing) ? pricing.price : null,
			priceESTHD: this.isESTHD(pricing) ? pricing.price : null,
			priceEST4K: this.isEST4K(pricing) ? pricing.price : null,
			priceHDDVD: this.isHDDVD(pricing) ? pricing.price : null,
			price4KDVD: this.is4KDVD(pricing) ? pricing.price : null,
			priceHDBR: this.isHDBR(pricing) ? pricing.price : null,
			price4KBR: this.is4KBR(pricing) ? pricing.price : null,
		})
	}

	handleExpandClick = () => {
		this.setState(state => ({ expanded: !state.expanded }))
	}

	handleSelectClick = (selectedRow) => {
		// eslint-disable-next-line react/prop-types
		this.props.onSelectLine(selectedRow)
	}

	render() {
		// eslint-disable-next-line react/prop-types
		const { id, period, isLoading } = this.props

		if (!id || !period || isLoading)
			return null

		return (
			<Fragment>
				{
					this.MapPeriod(id, period)
				}
			</Fragment>
		)
	}
}

const mapStateToProps = state => {
	return state
}

const mapDispatchToProps = dispatch => ({
	onSelectLine: (payload) =>
		dispatch({ type: PRICING_TOOL_SELECT_LINE, payload: payload })
})

const enhance = compose(
	connect(mapStateToProps, mapDispatchToProps),
	withStyles(styles)
)

export default enhance(PricingToolPeriodGrid)
