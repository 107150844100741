import React, { cloneElement, Component } from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import Labeled from 'ra-ui-materialui/lib/input/Labeled'
import compose from 'recompose/compose'
import { connect } from 'react-redux'
import { withStyles } from '@material-ui/core/styles'

const styles =   ({
	root: { display: 'flex', flexWrap: 'wrap' },
})

class ListChildField extends Component {
	render() {
		const {
			idsReducer,
			record,
			className,
			classes,
			basePath,
			children,
			resource,
			showHide = false,
			idList,
		} = this.props

		return (
			<div className={classnames(classes.root, className)}>
				{idsReducer !== undefined ? (
					/* eslint-disable no-unused-vars */
					Object.values(idsReducer).map((id, index) =>
						(record[id].id === idList && showHide === true) || showHide === false ?
							(<div key={record[id].id} id={record[id].id}>
								{React.Children.map(
									children,
									field =>
										field && (
											<div
												key={field.props.source}
												className={classnames(
													'ra-field',
													`ra-field-${field.props.source}`,
													field.props.className
												)}
											>
												{field.props.addLabel ? (
													<Labeled
														label={field.props.label}
														source={field.props.source}
														record={record[id]}
													>
														{field}
													</Labeled>
												) : typeof field.type === 'string' ? (
													field
												) : (
													cloneElement(field, {
														key: id,
														record: record[id],
														resource,
														basePath,
													})
												)}
											</div>
										)
								)}
							</div>) : null
					)) : null}
			</div>
		)
	}
}

ListChildField.propTypes = {
	idsReducer: PropTypes.array,
	record: PropTypes.object,
	className: PropTypes.string,
	classes: PropTypes.object.isRequired,
	basePath: PropTypes.string,
	children: PropTypes.node.isRequired,
	resource: PropTypes.string,
	showHide: PropTypes.bool,
	idList: PropTypes.array
}

const mapStateToProps = (state) => ({ idList: state.reducer.showListFieldReducer })

const enhance = compose(
	connect(
		mapStateToProps,
		null
	),
	withStyles(styles)
)

export default enhance(ListChildField)
