import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import get from 'lodash/get'
import Typography from '@material-ui/core/Typography'
import classnames from 'classnames'
import { withStyles } from '@material-ui/core/styles'

const styles = theme =>   ({
	root: {
		borderRadius: 4,
		border: '1px solid #ced4da',
		padding: '6px 8px',
		minWidth: '50px',
		minHeight: '20px',
	},
	enabled: {
		color: theme.palette.common.black,
		backgroundColor: theme.palette.common.white,
	},
	disabled: {
		color: theme.palette.text,
		backgroundColor: theme.palette.primary,
	},
})

class MyPureTextField extends PureComponent {
	render() {
		const { classes, source, disabled, style, record = {} } = this.props
		return (
			<Typography
				component="span"
				body1="body1"
				className={classnames(classes.root, {
					[classes.enabled]: !disabled,
					[classes.disabled]: disabled
				})}
				style={style}
			>
				{get(record, source)}
			</Typography>
		)
	}
}

MyPureTextField.propTypes = {
	classes: PropTypes.object.isRequired,
	source: PropTypes.string,
	disabled: PropTypes.bool,
	record: PropTypes.object,
	style: PropTypes.object,
}

const MyTextField = withStyles(styles)(MyPureTextField)

MyTextField.propTypes = {
	addLabel: PropTypes.bool,
	disabled: PropTypes.bool,
}

MyTextField.defaultProps = {
	addLabel: true,
}

export default MyTextField
