import { combineReducers } from 'redux'
import showLabelTabReducer from './showLabelTabReducer'
import showListFieldReducer from './showListFieldReducer'
import dataReferenceResourceReducer from './dataReferenceResourceReducer'
import Home from './home'
import Auth from './auth'
import Modal from './Modal'
import Pricing from './pricing'
import configurationManagerReducer from './configurationManagerReducer'
import pricePlanning from './pricePlanning'
import applicationStatus from './applicationStatus'
import publicationTracking from './publicationTracking'
import Promotion from './Promotion'
import albums from './albums'
import favorite from './favorite'
import configurationManager from './configurationManager'
import jobs from './jobs'


export default combineReducers({
	Auth,
	dataReferenceResourceReducer,
	Home,
	Modal,
	showLabelTabReducer,
	showListFieldReducer,
	Pricing,
	configurationManagerReducer,
	pricePlanning,
	applicationStatus,
	publicationTracking,
	Promotion,
	albums,
	favorite,
	configurationManager,
	jobs
})
