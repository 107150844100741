import React, {  Component } from 'react'
import MomentUtils from '@date-io/moment';
import {
    MuiPickersUtilsProvider,
	KeyboardDatePicker
 } from '@material-ui/pickers'
import { addField } from 'ra-core'
import sanitizeRestProps from '../inputs/sanitizeRestProps'
import moment from 'moment'
import PropTypes from 'prop-types'
import { createTheme, ThemeProvider  } from '@material-ui/core'
import { InputLabel } from '@material-ui/core'

const materialTheme = createTheme ({
	overrides: {
		MuiPickersModal: {
			dialogRoot:{
				backgroundColor: 'white',
				color: "black",
			}
		},
		MuiPickersToolbar:{
			toolbar : {
				backgroundColor: '#288DB1'
			}
		},
		MuiPickersDay: {
			daySelected : {
				backgroundColor:	'#288DB1'
			}
		},
		MuiButton:{
			textPrimary:{
				color: '#288DB1'
			}
		}
	},
});
moment.locale("fr")

const labels = {
	color: '#288DB1',
	fontWeight: 'bold',
	fontSize: '13px',
	fontFamily: 'Roboto, Helvetica, Arial, sans-serif',
	lineHeight: 1
}

const dateFormatter = v => {
	if (!(v instanceof Date) || isNaN(v)) return
	const pad = '00'
	const yyyy = v.getFullYear().toString()
	const MM = (v.getMonth() + 1).toString()
	const dd = v.getDate().toString()
	return `${yyyy}-${(pad + MM).slice(-2)}-${(pad + dd).slice(-2)}`
}

const sanitizeValue = value => {
	// null, undefined and empty string values should not go through dateFormatter
	// otherwise, it returns undefined and will make the input an uncontrolled one.
	if (value == null || value === '') {
		return ''
	}

	const finalValue = typeof value instanceof Date ? value : new Date(value)
	return dateFormatter(finalValue)
}

class BasicDatePicker extends Component {
	dateOnly = true

	onChange = (event) => {
		if (event) {
			let dateEvent = event.format('YYYY-MM-DD')
			let currentTime = this.dateOnly ? 'T00:00:00' : moment().format('THH:mm:ss')
			this.props.input.onChange(dateEvent + currentTime)
		}
		else {
			this.props.input.onChange(null)
		}
	}
	render() {
		const {
			className,
			required,
			meta,
			input,
			label,
			options,
			resource,
			source,
			dateOnly,
			shrink,
			classes,
			withCustomLabelStyle,
			disabled,
			errorLabel,
			...rest
		} = this.props

		this.dateOnly = dateOnly
		const value = sanitizeValue(input.value)
		if(value !== ''){
			return (
			<ThemeProvider  theme={materialTheme}>
			<MuiPickersUtilsProvider utils={MomentUtils}
				moment={moment}
				//locale={frLocale}
				locale="fr"
			>
				<InputLabel htmlFor="input-with-icon-adornment" style={labels} required={required ? required : false}>{label}</InputLabel>
				<KeyboardDatePicker
							{...input}
							className={className}
							margin="dense"
							keyboard
							inputVariant= "outlined"
							//variant="outlined"
							clearLabel="Vider"
       						cancelLabel="annuler"
							autoOk={false}
							error={errorLabel != null ? true : false}
							onError={(error, values) => {
								// console.log("Error is", error, values);
							}}
							disabled={disabled}
							helperText={errorLabel}
							format="DD/MM/YYYY"
							placeholder="JJ/MM/AAAA"
							size="small"
							// handle clearing outside => pass plain array if you are not controlling value outside
							//mask={value => (value && value !== '' ? [/\d/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/] : [])}
							//clearable
							disableopenonenter
							animateYearScrolling={false}
							value={value}
							onChange={this.onChange}
							onBlur={this.onBlur}
							InputLabelProps={withCustomLabelStyle ? {
								classes: {
									shrink: classes.customLabelStyle
								}
							} : {}}
							InputProps = {{
								disabled : disabled
							}}
							{...options}
							{...sanitizeRestProps(rest)}
						/> 
			</MuiPickersUtilsProvider>
			</ThemeProvider>
			)
		}
		else{
			return (
			<ThemeProvider  theme={materialTheme}>
			  <MuiPickersUtilsProvider utils={MomentUtils}
				moment={moment}
			 	//locale={frLocale}
				locale="fr"
				>
						<InputLabel htmlFor="input-with-icon-adornment" style={labels} required={required ? required : false}>{label}</InputLabel>
						 <KeyboardDatePicker
							{...input}
							className={className}
							margin="dense"
							keyboard
							inputVariant= "outlined"
							//variant="outlined"
							error={errorLabel != null ? true : false}
							helperText={errorLabel}
							format="DD/MM/YYYY"
							clearLabel="Vider"
							size="small"
							placeholder="JJ/MM/AAAA"
							disabled={disabled}
							// handle clearing outside => pass plain array if you are not controlling value outside
							// mask={value => (value && value !== '' ? [/\d/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/] : [])}
							//mask={() => ([])}
							//clearable
							disableopenonenter
							animateYearScrolling={false}
							value={null}
							onChange={this.onChange}
							onBlur={this.onBlur}
							InputLabelProps={withCustomLabelStyle ? {
								classes: {
									shrink: classes.customLabelStyle
								}
							} : {}}
							InputProps = {{
								disabled : disabled
							}}
							{...options}
							{...sanitizeRestProps(rest)}
						/> 
		  </MuiPickersUtilsProvider>
		  </ThemeProvider>
			)
		}
	}
}

BasicDatePicker.propTypes = {
	addLabel: PropTypes.bool,
	dateOnly: PropTypes.bool,
	shrink: PropTypes.bool,
}

const BasicDatePickerField = addField(BasicDatePicker)

BasicDatePickerField.defaultProps = {
	addLabel: true,
	dateOnly: true,
	shrink: true,
}

export default BasicDatePickerField
