import React, { Component } from 'react'
import { LockIcon, LockOpenIcon  } from '../../../constants/icons'
import IconButton from '@material-ui/core/IconButton'
import Tooltip from '@material-ui/core/Tooltip'
import { withStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'

const styles = () =>  ({
	responsibleHeader : {
		display: 'inline-block',
		color: 'rgba(0, 0, 0, 0.54)',
		fontSize: '0.75em',
		fontWeight: 'bold',
		marginTop: '24px'
	}
})

class CustomLockIconButton extends Component{
	constructor(props) {
		super(props)
	}
	render(){
		const { handleClick, isLocked , type} = this.props
		if(type === 'Prio')
		{
			if(isLocked){
				return(
					<React.Fragment>
						<Tooltip 	title={<><Typography color="inherit" style={{ fontSize: "10px" }}> Modifier contenu</Typography>
										<Typography color="inherit" style={{ fontSize: "10px" }}> -Contenu référent (vert)</Typography>
										<Typography color="inherit" style={{ fontSize: "10px" }}> -Arrivée tardive (bleu)</Typography>
										<Typography color="inherit" style={{ fontSize: "10px" }}> -Top prio (rouge)</Typography>
										</>}>
						<IconButton>						
							<LockIcon onClick = {handleClick} />
						</IconButton>
						</Tooltip>
					</React.Fragment>
				)
			}
			else{
				return(
					<React.Fragment>
						<Tooltip title={<><Typography color="inherit" style={{ fontSize: "10px" }}> Modifier contenu</Typography>
										<Typography color="inherit" style={{ fontSize: "10px" }}> -Contenu référent (vert)</Typography>
										<Typography color="inherit" style={{ fontSize: "10px" }}> -Arrivée tardive (bleu)</Typography>
										<Typography color="inherit" style={{ fontSize: "10px" }}> -Top prio (rouge)</Typography>
										</>}>
						<IconButton>
							<LockOpenIcon  onClick = {handleClick} />
						</IconButton>
						</Tooltip>
					</React.Fragment>
				)
			}
		}
		else 
		{
			if(isLocked){
				return(
					<React.Fragment>
						<Tooltip title="Modifier responsable">
						<IconButton>					
							<LockIcon onClick = {handleClick} />
						</IconButton>
						</Tooltip>
					</React.Fragment>
				)
			}
			else{
				return(
					<React.Fragment>
						<Tooltip title="Modifier responsable">
						<IconButton>
							<LockOpenIcon onClick = {handleClick} />
						</IconButton>
						</Tooltip>
					</React.Fragment>
				)
			}
		}
	}
}

export default withStyles(styles)(CustomLockIconButton)