import decodeJwt from 'jwt-decode'
import { validateNumber } from '30-seconds-of-code'

class Token {
	static get instance() {
		if (!this.singleton) {
			console.debug('Token > new instance')
			this.singleton = new Token()
		}

		return this.singleton
	}

	store(data) {
		sessionStorage.setItem('name', data.name)
		sessionStorage.setItem('token', data.token)
		sessionStorage.setItem('refreshToken', data.refreshToken)
		localStorage.setItem('refreshToken', data.refreshToken)
		// sessionStorage.setItem('isAlbumFavoris', true)


		const decodedToken = decodeJwt(data.token)
		if (null != decodedToken) {
			sessionStorage.setItem('id', decodedToken['http://schemas.xmlsoap.org/ws/2005/05/identity/claims/nameidentifier'])
			sessionStorage.setItem('type', decodedToken['typ'])
			sessionStorage.setItem('groupsid', decodedToken['http://schemas.microsoft.com/ws/2008/06/identity/claims/groupsid'])
			sessionStorage.setItem('userdata', decodedToken['http://schemas.microsoft.com/ws/2008/06/identity/claims/userdata'])
		}
	}

	tokenExists() {
		return null != this.getToken()
	}

	getToken() {
		return sessionStorage.getItem('token')
	}

	getRefreshToken() {
		//console.log(sessionStorage.getItem('refreshToken') ? sessionStorage.getItem('refreshToken') : localStorage.getItem('refreshToken'))
		return sessionStorage.getItem('refreshToken') ? sessionStorage.getItem('refreshToken') : localStorage.getItem('refreshToken')
	}

	getId() {
		const id = sessionStorage.getItem('id')
		return validateNumber(id) ? Number(id) : 0
	}

	getName() {
		return sessionStorage.getItem('name')
	}

	getUserData() {
		const userdata = sessionStorage.getItem('userdata')
		return null != userdata ? userdata.split(';') : []
	}

	getGroupId() {
		const id = sessionStorage.getItem('groupsid')
		return validateNumber(id) ? Number(id) : 0
	}

	getTypeId() {
		const id = sessionStorage.getItem('type')
		return validateNumber(id) ? Number(id) : 0
	}

	clear() {
		sessionStorage.removeItem('name')
		sessionStorage.removeItem('token')
		sessionStorage.removeItem('refreshToken')
		sessionStorage.removeItem('id')
		sessionStorage.removeItem('type')
		sessionStorage.removeItem('groupsid')
		sessionStorage.removeItem('userdata')
		localStorage.removeItem('refreshToken')

	}
}

const token = Token.instance

export default token