import red from '@material-ui/core/colors/red'

// primary => buttons, links, ...
// secondary => appbar, ...
// old bo blue variants: dark => #0178A3 | light => #288DB1

export const darkTheme = {
	props: {
		MuiInput: {
			disableUnderline: true,
		},
		MuiInputLabel: {
			shrink: true,
		},
		MuiCard: {
			elevation: 2,
			raised: true,
		},
		MuiTable: {
			padding: 'dense'
		},
		MuiTableCell: {
			padding: 'dense'
		},
		MuiMenuItem:{
			root :{
			color:'#000'
			}
		},
		MuiAvatar:{
			root:{
				borderRadius : '0%'
			}
		}
	},
	palette: {
		type: 'dark', // Switching the dark mode on is a single property value change.
		primary: {
			main: '#288DB1',
		},
		secondary: {
			main: '#ff9800',//'#288DB1',
		},
		error: red,
		background: {
			default: '#232C31',
		},
		text: {
			primary: '#000',
			secondary: '#000',
		},
	},
	overrides: {
		MuiTabs: {
			root: {
				color: '#000',
			}
		},
		MuiFormControl: {
			marginNormal: {
				marginTop: 8,
				marginBottom: 4,
			},
			marginDense: {
				marginTop: 6,
				marginBottom: 4,
			},
		},
		MuiFormLabel: {
			root: {
				fontWeight: 'bold',
				fontSize: '1.095rem',
				color: '#288DB1',
				"&$disabled": {
					"color": "#288DB1"
				},
				focused: {
					color: '#000',
				},
			},
		},
		MuiInputLabel: {
			root: {
				fontWeight: 'bold',
			},
			shrink: {
				width: 'max-content',
			},
		},
		MuiInputBase: {
			root: {
				color: 'white',
				"&$disabled": {
					"color": "grey"
				},
			}
		},
		MuiInput: {
			root: {
				color: 'white',
				"&$disabled": {
					"color": "grey"
				},
			},
			input: {
				borderRadius: 4,
				color: '#000',
				backgroundColor: '#fff',
				border: '1px solid #ced4da',
				padding: '6px 8px',
				minHeight: '20px',
				minWidth: '50px',
				width: 'auto',
			}
		},
		MuiSelect: {
			select: {
				height: '20px',
			},
			icon: {
				color: '#000',
			},
		},
		MuiIconButton : {
			root :{
				color: '#fff'
			}
		},
		MuiButton: {
			root: {
				color: '#aedeed'
			}
		},
		MuiMenuItem:  {
			//color:'#000',
			root: {
				color: '#000',
			}
		},
		MuiAppBar: {
			colorPrimary: {
				backgroundColor: '#288DB1'
			}
		},
		MuiCardContent: {
			root: {
				paddingTop: 8,
				paddingBottom: 8,
				paddingLeft: 16,
				paddingRight: 16,
				'&:first-child': {
					paddingTop: 8,
					paddingBottom: 8,
					paddingLeft: 16,
					paddingRight: 16,
				},
				'&:last-child': {
					paddingTop: 8,
					paddingBottom: 8,
					paddingLeft: 16,
					paddingRight: 16,
				},
			},
		},
		MuiCardHeader: {
			root: {
				paddingTop: 8,
				paddingBottom: 8,
				paddingLeft: 16,
				paddingRight: 16,
			},
		},
		MuiTableRow: {
			root: {
				height: '30px',
			},
		},
		MuiTableCell: {
			body: {
				color: '#000',
			}
		},
		MuiTypography: {
			root: {
				color: '#000',
			},
			body1: {
				color: '#000',
			}
		},
		MuiTableSortLabel: {
			active: {
				color: '#000',
			}
		},		
		MuiPaper:{
			root:{
				color:'#000',
				//backgroundColor: '#666'
				backgroundColor: '#fff'
			}
		},
		MuiSwitch: {
			bar: {
				backgroundColor: '#288DB1'
			}
		},
		MuiAvatar:{
			root:{
				borderRadius : '0%'
			}
		}
	},
	spacing: {
		unit: 8,
	},
	typography: {
		fontSize: 14,
		useNextVariants: true,
	}
}

export const lightTheme = {
	props: {
		MuiInput: {
			disableUnderline: true,
		},
		MuiInputLabel: {
			shrink: true,
		},
		MuiCard: {
			elevation: 2,
			raised: true,
		},
		MuiTable: {
			padding: 'dense'
		},
		MuiTableCell: {
			padding: 'dense'
		},
		MuiMenuItem:{
			root :{
				color:'#000'
				}
		},
		MuiAvatar:{
			root:{
				borderRadius : '0%'
			}
		}
	},
	palette: {
		type: 'light', // Switching the light mode on is a single property value change.
		primary: {
			main: '#288DB1',
		},
		secondary: {
			main: '#288DB1',
		},
		error: red,
		background: {
			paper: '#e8e8e8',
			default: '#EEEEEE',
		},
		text: {
			primary: '#000',
			secondary: '#000',
		}
	},
	overrides: {
		MuiTabs: {
			root: {
				color: '#000',
			}
		},
		MuiFormControl: {
			marginNormal: {
				marginTop: 8,
				marginBottom: 4,
			},
			marginDense: {
				marginTop: 6,
				marginBottom: 4,
			},
		},
		MuiFormLabel: {
			root: {
				fontWeight: 'bold',
				color: '#288DB1',
				"&$disabled": {
					"color": "#288DB1"
				},
				focused: {
					color: '#000',
				},
			},
		},
		MuiItemLink: {
			root: {
				color: '#288DB1'
			},
			"&$focused": {
				"color": "#fff",
				"background": "#288DB1"
			},
			"&$selected": {
				"color": "#fff",
				"background": "#288DB1"
			},
		},
		MuiInputLabel: {
			root: {
				fontWeight: 'bold',
			},
			shrink: {
				width: 'max-content',
			},
		},
		MuiInput: {
			input: {
				borderRadius: 4,
				color: '#000',
				backgroundColor: '#fff',
				border: '1px solid #ced4da',
				padding: '6px 8px',
				minHeight: '20px',
			}
		},
		MuiMenuItem:{
			root :{
				color:'#000'
				}
		},
		MuiSelect: {
			select: {
				height: '20px',
			},
			icon: {
				color: '#000',
			},
		},
		MuiCardContent: {
			root: {
				paddingTop: 8,
				paddingBottom: 8,
				paddingLeft: 16,
				paddingRight: 16,
				'&:first-child': {
					paddingTop: 8,
					paddingBottom: 8,
					paddingLeft: 16,
					paddingRight: 16,
				},
				'&:last-child': {
					paddingTop: 8,
					paddingBottom: 8,
					paddingLeft: 16,
					paddingRight: 16,
				},
			},
		},
		MuiCardHeader: {
			root: {
				paddingTop: 8,
				paddingBottom: 8,
				paddingLeft: 16,
				paddingRight: 16,
			},
		},
		MuiTableRow: {
			root: {
				height: '30px',
			},
		},
		MuiAvatar:{
			root:{
				borderRadius : '0%'
			}
		}
	},
	spacing: {
		unit: 8,
	},
	typography: {
		fontSize: 14,
		useNextVariants: true,
	}
}