import React from 'react'
import Card from '@material-ui/core/Card'
import { MySimpleFormLayout } from '../layout'
import { MyTextInput, QuickSearchInput } from '../common/inputs'
import { validateYear, validateWeek, validatePriority } from './validate'
import { MyTextField } from '../common/fields'
import { ProgramType } from '../../constants/types'
// import { ReferenceInput, AutocompleteInput } from 'react-admin'

const CardEdit = ({ record } = this.props) => (
	<Card>
		<MySimpleFormLayout record={record}>
			<MyTextField label="fields.id" source="id" disabled />
			<MyTextInput label="fields.year" source="year" validate={validateYear} />
			<MyTextInput label="fields.week" source="week" validate={validateWeek} />
			<QuickSearchInput label="fields.movieid" source="movieid" modes={[ProgramType.MOVIE]} />

			{/* <ReferenceInput
				source="movieid"
				reference="Companiesmovies"
				perPage={25}
				filterToQuery={searchText => ({ q: searchText, mode: 2 })}
			>
				<AutocompleteInput optionText={getOptionText} translateChoice={false} />
			</ReferenceInput> */}

			<MyTextInput label="fields.priority" source="priority" validate={validatePriority} />
		</MySimpleFormLayout>
	</Card>
)

// const getOptionText = (suggestion) => {
// 	return suggestion.title ? `${suggestion.title} (${suggestion.id})` : `${suggestion.value} (${suggestion.id})`

// }

export default CardEdit