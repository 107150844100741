import React from 'react'
import PropTypes from 'prop-types'
import TextField from '@material-ui/core/TextField'
import InputLabel from '@material-ui/core/InputLabel'


// const toLocaleStringSupportsLocales = (() => {
// 	// from https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Date/toLocaleString
// 	try {
// 		new Date().toLocaleString('i')
// 	} catch (error) {
// 		return error instanceof RangeError
// 	}
// 	return false
// })()

const labels = {
	marginTop: '0', 
	marginBottom: '1em', 
	color: '#288DB1',
	fontWeight: 'bold',
	fontSize: '13px',
	fontFamily: 'Roboto, Helvetica, Arial, sans-serif',
	lineHeight: 1
}

export const DateFieldPromotions = ({
	label,
	className,
	locales,
	options,
	record,
	showGestion = false,
	source,
	...rest
}) => {
	
	//const dateTime = source.split('.')

	if(source)
	{
		const dateTime = source.split('.')
		if (showGestion) {
			return (<div>
						<InputLabel htmlFor="input-with-icon-adornment" style={labels}>{label}</InputLabel>
						<TextField type="datetime-local" variant="outlined" value={dateTime[0]} 
							disabled InputLabelProps={{style: { color: '#fff' } }} style={{ marginRight:20, width: '100%'}}/>
					</div>
			)
		} else {
			// let dateString = date.toLocaleTimeString(locales, options)
			// let dateLabel = 'Date ' + label
			let dateFormated = dateTime[0].slice(0, -3)
			let display = []
			display.push(<div>
				<InputLabel htmlFor="input-with-icon-adornment" style={labels}>{label}</InputLabel>
				<TextField type="datetime-local" variant="outlined" value={dateFormated} disabled style={{ marginRight:20, width: '100%'}}/> 
			</div>)

			return display
		}
	} else {
		return (<div>
			<InputLabel htmlFor="input-with-icon-adornment" style={labels}>NO DATA</InputLabel>
			<TextField type="datetime-local" variant="outlined" disabled style={{ marginRight:20, width: '100%'}}/>
		</div>)
	}
	// } else {
	// return <TextField type="date" label={label} value={dateinfos[0]} />
	// }
}

DateFieldPromotions.propTypes = {
	addLabel: PropTypes.bool,
	basePath: PropTypes.string,
	className: PropTypes.string,
	cellClassName: PropTypes.string,
	headerClassName: PropTypes.string,
	label: PropTypes.string,
	locales: PropTypes.oneOfType([
		PropTypes.string,
		PropTypes.arrayOf(PropTypes.string),
	]),
	options: PropTypes.object,
	record: PropTypes.object,
	showTime: PropTypes.bool,
	sortBy: PropTypes.string,
	source: PropTypes.string,
}

export default DateFieldPromotions