import { ON_EXPORT_JOBS_LIST_UPDATE} from '../constants/actions'

const defaultState = { 
		ExportJobs : [],
 
}
 
export default (state = defaultState, { type, payload }) => {
	if (type === ON_EXPORT_JOBS_LIST_UPDATE) {
		return {
			...state,
			ExportJobs: payload.ExportJobs }
	}
	return state
}