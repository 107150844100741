import './index.css'
import React from 'react'
import ReactDOM from 'react-dom'
import App from './App'
import * as serviceWorker from './serviceWorker'
import { ConfigProvider } from './components/layout/ConfigProvider'

ReactDOM.render( 
<ConfigProvider>
	<App />
</ConfigProvider>,
 document.getElementById('root'))

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister()