import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import get from 'lodash/get'
import Typography from '@material-ui/core/Typography'



const mode = [null,  'Voucher',  'Global', 'Utilisateur' ]

class MyPureTextField extends PureComponent {
	render() {
		const {  source,  record = {} } = this.props
		let val = get(record, source)
		return (
			<Typography
				component="span"
				body1="body1"
			>
				{mode[val]}
			</Typography>
		)
	}
}

MyPureTextField.propTypes = {
	classes: PropTypes.object.isRequired,
	source: PropTypes.string,
	disabled: PropTypes.bool,
	record: PropTypes.object,
	style: PropTypes.object,
}

// const PromotionField = withStyles(styles)(MyPureTextField)
const PromotionField = (MyPureTextField)

PromotionField.propTypes = {
	addLabel: PropTypes.bool,
	disabled: PropTypes.bool,
}

PromotionField.defaultProps = {
	addLabel: true,
}

export default PromotionField
