import Api from './Api'

const PricingsLists = {
	getAll: () =>
		Api.Requests.getAll('/Pricingslists'),
	getAllWithChilds: () =>
		Api.Requests.getAll('/Pricingslists', { 'x-includes': 'Pricingline,Pricing' })
}

export default PricingsLists
