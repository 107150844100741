import * as React from 'react'
import Button from '@material-ui/core/Button'
import TextField from '@material-ui/core/TextField'
import Typography from '@material-ui/core/Typography'

import Link from '@material-ui/core/Link'
import Box from '@material-ui/core/Box'
import Container from '@material-ui/core/Container'
import { createTheme, ThemeProvider } from '@material-ui/core/styles'
import authProvider from '../../authProvider'
import { Grid } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import LinearProgress from '@material-ui/core/LinearProgress'
import { Contacts, DomainsItems, Companiestransfers, Authentification, Domains } from '../../requests'
import { Token  } from '../../tools'
 const styles = (theme) => ({
	input: {
		"&:-internal-autofill-selected": {
			backgroundcolor: "-internal-light-dark(black,red) !important  "
		}
	},
	error: {
		color: 'red',
		fontSize: 'larger',
		fontWeight: 'bold',
		padding: '25px'
	},
	helperText: {
		color: 'red',
		position: "absolute",
		marginRight: "1%",
		bottom: "-1.6em",
		//right: "0",
		fontSize: '0.7rem'
	},
	circularProgress: {
		position: 'absolute',
		top: '50%',
		left: '45%',
		zIndex: '100'
	},
	root: {
		width: '100%',
		'& > * + *': {
		  marginTop: theme.spacing(2),
		},
		color:'#288DB1'
	  },
})
const theme = createTheme({
	overrides: {
		MuiLinearProgress: {
			barColorPrimary:{
				backgroundColor: '#288DB1',
			},
			colorPrimary:{
				backgroundColor: '#288db154',

			}
		}
	}
})
 	


function LoginFrm(props) {
	const [loginValueError, setLoginValueError] = React.useState("");
	const [pwdValueError, setPwdValueError] = React.useState("");
	const [isLoading, setLoading] = React.useState(false);
	const { classes, onResetPwd, onCreatePwd , errorLogin} = props;
	console.log(errorLogin)
	React.useEffect(() => {
	  const checkAuthentication = async () => {
		const token = Token.getToken(); // Récupère le token du stockage
		const refresh_token = Token.getRefreshToken()
		
			if (token || refresh_token) {
				try {
				const isValid = await Authentification.refreshToken(refresh_token)
				console.log(isValid)
				if ( !isValid) {
				await redirectToOkta(); // Si le token est invalide, redirige vers Okta
				}
				else{
					Token.store(isValid.data)
					if (!localStorage.getItem("dataDomains")) {
						Domains.getAll().then((json) =>
							localStorage.setItem("dataDomains", JSON.stringify(json.data))
						)
					  }
					  if (!localStorage.getItem("dataContacts")) {
						  Contacts.getAll().then((json) =>
							  localStorage.setItem("dataContacts", JSON.stringify(json.data))
						  )
					  }
					  if (!sessionStorage.getItem("dataDomainsItems")) {
						  DomainsItems.getAll().then((json) =>
							  sessionStorage.setItem("dataDomainsItems", JSON.stringify(json.data))
						  )
					  }
					  if (!sessionStorage.getItem("pricePlanning")) {
						  Companiestransfers.getPricePlanningTargets().then((json) =>
							  sessionStorage.setItem("pricePlanning", JSON.stringify(json.data))
						  )
					  }
					  authProvider('AUTH_GET_PERMISSIONS').then(()=>{
						setTimeout(() => {
								window.location.href = process.env.PUBLIC_URL + '/#'
						  }, 2000)
					  })
					  
				  }
				} catch (error) {
				  console.error("Erreur lors de la vérification du token", error)
				  
				  await redirectToOkta(); // En cas d'erreur de vérification, redirige vers Okta
				}
			  } else {
				 
				await redirectToOkta(); // Si aucun token n'est présent, redirige vers Okta
			  }
		
		
	  };
  		if(process.env.NODE_ENV !== 'development' && !errorLogin)
	  		checkAuthentication();
	}, []);
  
	const redirectToOkta =  () => {
	  setLoading(true)
	  try {
		//const oktaSAMLUrl = 'https://mylogin.canal-plus.com/app/groupecanalplus_hawcdev_1/exkcm9laftXBS7Agk417/sso/saml';
		window.location.href = process.env.REACT_APP_OKTA;
		//await Authentification.SamlLogin(oktaSAMLUrl);
	  } catch (error) {
		console.error("Erreur lors de la redirection vers Okta", error);
		setLoading(false);
		setLoginValueError('Erreur lors de l\'authentification. Veuillez réessayer.');
	  }
	};
  
	const handleSubmit = async (event) => {
	  event.preventDefault();
	  setLoading(true);
  
	  const data = new FormData(event.currentTarget);
	  const params = {
		username: data.get('email'),
		password: data.get('password'),
	  };

	  const validateForm = () => {
		let errorFields = false;
		if (!params.username) {
		  setLoginValueError('Login obligatoire');
		  errorFields = true;
		} else {
		  setLoginValueError('');
		}
		if (!params.password) {
		  setPwdValueError('Mot de passe obligatoire');
		  errorFields = true;
		} else {
		  setPwdValueError('');
		}
		return !errorFields;
	  };
  
	  if (validateForm()) {
		try {
		  const result = await authProvider('AUTH_LOGIN', params);
		  if (result === 'ra.auth.sign_in_error') {
			setLoginValueError('Login ou Mot de passe incorrect');
		  } else {
			window.location.href = process.env.PUBLIC_URL + '/#';
		  }
		} catch (error) {
		  console.error("Erreur de connexion", error);
		  setLoginValueError('Erreur de connexion');
		}
	  }
	  setLoading(false);
	};
  
	return (
	  <ThemeProvider theme={theme}>
		<Container component="main" maxWidth="xs" style={{ width: "400px", padding: '24px' }}>
		  {process.env.NODE_ENV !== 'development' ? (
			errorLogin ? 
			<div>
				<Box
			  sx={{
				marginTop: 4,
				marginBottom: 8,
				display: 'flex',
				flexDirection: 'column',
				alignItems: 'center',
			  }}
			>
				<Box component="form" noValidate sx={{ mt: 1 }} style={{ minWidth: '350px' }}>
					<Typography  >
						Une erreur est survenue lors de la connexion. Veuillez contacter votre administrateur.
					</Typography>
				</Box>
			</Box>
			</div>
			:
			<div>
			  <br /> <br />
			  Veuillez vous authentifier pour commencer à utiliser l'application.
			  <Button
				type="submit"
				fullWidth
				variant="contained"
				sx={{ mt: 3, mb: 2 }}
				style={{ margin: '25px 0px', background: '#288DB1', color: 'white', maxWidth: '400px' }}
				onClick={redirectToOkta}
			  >
				CONNEXION
			  </Button>
 
			</div>
			
		  ) : (
			<Box
			  sx={{
				marginTop: 4,
				marginBottom: 8,
				display: 'flex',
				flexDirection: 'column',
				alignItems: 'center',
			  }}
			>
			  <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }} style={{ minWidth: '350px' }}>
				<TextField
				  margin="normal"
				  required
				  fullWidth
				  id="email"
				  label="Identifiant"
				  name="email"
				  autoComplete="email"
				  autoFocus
				  error={loginValueError !== ""}
				  helperText={loginValueError}
				  FormHelperTextProps={{
					classes: {
					  root: props.classes.helperText
					}
				  }}
				/>
				<TextField
				  margin="normal"
				  style={{ marginBottom: '0' }}
				  required
				  fullWidth
				  name="password"
				  label="Mot de passe"
				  type="password"
				  id="password"
				  autoComplete="current-password"
				  error={pwdValueError !== ""}
				  helperText={pwdValueError}
				  FormHelperTextProps={{
					classes: {
					  root: props.classes.helperText
					}
				  }}
				/>
				{isLoading && <div className={classes.root}><LinearProgress /></div>}
  
				<Button
				  type="submit"
				  fullWidth
				  variant="contained"
				  sx={{ mt: 3, mb: 2 }}
				  style={{ margin: '25px 0', background: '#288DB1', color: 'white' }}
				>
				  CONNEXION
				</Button>
  
				<Link variant="body2" style={{ textAlign: 'left', color: '#288db1', cursor: 'pointer' }} onClick={() => onCreatePwd(true)}>
				  Première connexion
				</Link>
				<Link variant="body2" style={{ marginLeft: '20%', textAlign: 'right', color: '#288db1', cursor: 'pointer' }} onClick={() => onResetPwd(true)}>
				  Mot de passe oublié ?
				</Link>
			  </Box>
			</Box>
		  )}
		</Container>
	  </ThemeProvider>
	);
  }
  
  export default withStyles(styles)(LoginFrm);


// function LoginFrm(props) {
// 	const [loginValueError, setLoginValueError] = React.useState("")
// 	const [pwdValueError, setPwdValueError] = React.useState("")
// 	const [isLoading, setloading] = React.useState(false)
// 	const {classes, onResetPwd, onCreatePwd } = props
// 	const [ipAddress, setIPAddress] = React.useState('')
// 	const params = new URLSearchParams(window.location.search);
// 	console.log(params)
// 		const token = params.get('token');
//         const refreshToken = params.get('refreshToken');
//         const name = params.get('name');
//         const returnUrl = params.get('returnUrl') || '/';
// 	// React.useEffect(() => {
// 	//   fetch('https://geolocation-db.com/json/')
// 	// 	.then(response => response.json())
// 	// 	.then(data => setIPAddress(data))
// 	// 	.catch(error => console.log(error))
// 	// }, []);
// 	//console.log(ipAddress)
// 	const validateForm = (params) => {
// 		let errorFields = false
// 		if (params.username === null || params.username === undefined || params.username === '') {
// 			setLoginValueError('Login obligatoire')
// 			errorFields = true
// 		}
// 		else setLoginValueError('')
// 		if (params.password === null || params.password === undefined || params.password === '') {
// 			setPwdValueError('Mot de passe obligatoire')
// 			errorFields = true
// 		}
// 		else setPwdValueError('')
// 		if (errorFields) {
// 			return {
// 				success: false,
// 				message: errorFields
// 			}
// 		}
// 		return {
// 			success: true
// 		}
// 	}
// 	const isOkta = window.isOktaLogin
// 	const handleSubmit = async (event) => {
// 		setloading(true)
// 		event.preventDefault()
// 		try {
// 			if (process.env.NODE_ENV !== 'development') {
// 				//window.location.href = "https://mylogin.canal-plus.com/app/groupecanalplus_hawcdev_1/exkcm9laftXBS7Agk417/sso/saml"

// 				await Authentification.SamlLogin('/#/Oktacallback')
// 				// fetch('/auth/Samllogin', {
// 				// 	method: 'POST',
// 				// 	headers: {
// 				// 		'Content-Type': 'application/json',
// 				// 	},
// 				// })
// 				.then((res) => {
// 					console.log(res)
// 					localStorage.removeItem("dataContacts")
// 					localStorage.removeItem("dataDomains")
// 					//Token.store(data)
//  					if (!localStorage.getItem("dataDomains")) {
// 						Domains.getAll().then((json) =>
// 							localStorage.setItem("dataDomains", JSON.stringify(json.data))
// 						)
// 					}
// 					if (!localStorage.getItem("dataContacts")) {
// 						Contacts.getAll().then((json) =>
// 							localStorage.setItem("dataContacts", JSON.stringify(json.data))
// 						)
// 					}
// 					if (!sessionStorage.getItem("dataDomainsItems")) {
// 						DomainsItems.getAll().then((json) =>
// 							sessionStorage.setItem("dataDomainsItems", JSON.stringify(json.data))
// 						)
// 					}
// 					if (!sessionStorage.getItem("pricePlanning")) {
// 						Companiestransfers.getPricePlanningTargets().then((json) =>
// 							sessionStorage.setItem("pricePlanning", JSON.stringify(json.data))
// 						)
// 					}
// 					setTimeout(() => {
// 						//if(!window.isOktaLogin)
// 						window.location.href = process.env.PUBLIC_URL + '/#'
// 					}, 500)
// 					return true
// 				}).catch(error => {
// 					console.log(error)
// 					var errorMessage = 'ra.notification.http_error'
// 					switch (error.message) {
// 						case 'Forbidden':
// 							console.log(error.message)
// 							errorMessage = 'ra.auth.sign_in_error'
// 							break
// 						default:
// 							errorMessage = 'ra.notification.http_error'
// 							break
// 					}
// 					return errorMessage
// 					//return Promise.reject(new Error(errorMessage))
// 				})
// 				// if (response.redirected) {
// 				// 	const result = await authProvider('AUTH_LOGIN', params);

// 				// 	window.location.href = response.url;
// 				// } else {
// 				// 	const result = await response.json();
// 				// 	if (result.redirectUrl) {
// 				// 		window.location.href = result.redirectUrl;
// 				// 	} else {
// 				// 		setLoading(false);
// 				// 		setLoginValueError('Erreur d\'initialisation de l\'authentification SAML');
// 				// 	}
// 				// }

// 				// const response = await Authentification.SamlLogin(process.env.PUBLIC_URL + '/#/Companiesalbums');
// 				// console.log(response);

// 				// if (response.status < 200 || response.status >= 300) {
// 				// 	throw new Error(response.statusText);
// 				// }
				
// 				// const { data } = await response.json();
// 				// localStorage.removeItem("dataContacts");
// 				// localStorage.removeItem("dataDomains");
// 				// Token.store(data);

// 				// if (!localStorage.getItem("dataDomains")) {
// 				// 	const domains = await Domains.getAll();
// 				// 	localStorage.setItem("dataDomains", JSON.stringify(domains.data));
// 				// }

// 				// if (!localStorage.getItem("dataContacts")) {
// 				// 	const contacts = await Contacts.getAll();
// 				// 	localStorage.setItem("dataContacts", JSON.stringify(contacts.data));
// 				// }

// 				// if (!sessionStorage.getItem("dataDomainsItems")) {
// 				// 	const domainsItems = await DomainsItems.getAll();
// 				// 	sessionStorage.setItem("dataDomainsItems", JSON.stringify(domainsItems.data));
// 				// }

// 				// if (!sessionStorage.getItem("pricePlanning")) {
// 				// 	const pricePlanning = await Companiestransfers.getPricePlanningTargets();
// 				// 	sessionStorage.setItem("pricePlanning", JSON.stringify(pricePlanning.data));
// 				// }

// 				// setTimeout(() => {
// 				// 	window.location.href = process.env.PUBLIC_URL + '/#';
// 				// }, 500);
// 				// return true;

// 			} else 
// 			{
// 				const data = new FormData(event.currentTarget);
// 				const params = {
// 					username: data.get('email'),
// 					password: data.get('password')
// 				};

// 				if (validateForm(params).success) {
// 					const result = await authProvider('AUTH_LOGIN', params);
// 					console.log(result);

// 					if (result === 'ra.auth.sign_in_error') {
// 						setLoginValueError('Login ou Mot de passe incorrecte');
// 						setloading(false);
// 					} else {
// 						window.location.href = process.env.PUBLIC_URL + '/#';
// 					}

// 				} else {
// 					setloading(false);
// 					setLoginValueError('Login ou Mot de passe incorrecte');
// 				}
// 			}
// 		} catch (error) {
// 			console.error(error);
// 			setLoginValueError('login erreur');
// 			setloading(false);
// 		}
	

		

// 		//let tentative = localStorage.getItem("C_BLACKLIST_CACHEKEY")!==''? localStorage.getItem("C_BLACKLIST_CACHEKEY").split('_')[1]  :0
// 		//const postBack = localStorage.setItem("C_BLACKLIST_CACHEKEY", params.username.split('@')[0] +'_'+tentative)		
// 		// try {
// 		// 	if (process.env.NODE_ENV !='development')
// 		// 		{
// 		// 	Authentification.SamlLogin('/#/dashboard').then((response)=>{
// 		// 			console.log(response)
				 
// 		// 				if (response.status < 200 || response.status >= 300) {
// 		// 					throw new Error(response.statusText) // Error message key from ra-language-french
// 		// 				}
// 		// 				return response.json()
// 		// 			})
// 		// 			.then(({ data }) => {
// 		// 				localStorage.removeItem("dataContacts")
// 		// 				localStorage.removeItem("dataDomains")
// 		// 				Token.store(data)
// 		// 				 if (!localStorage.getItem("dataDomains")) {
// 		// 					Domains.getAll().then((json) =>
// 		// 						localStorage.setItem("dataDomains", JSON.stringify(json.data))
// 		// 					)
// 		// 				}
// 		// 				if (!localStorage.getItem("dataContacts")) {
// 		// 					Contacts.getAll().then((json) =>
// 		// 						localStorage.setItem("dataContacts", JSON.stringify(json.data))
// 		// 					)
// 		// 				}
// 		// 				if (!sessionStorage.getItem("dataDomainsItems")) {
// 		// 					DomainsItems.getAll().then((json) =>
// 		// 						sessionStorage.setItem("dataDomainsItems", JSON.stringify(json.data))
// 		// 					)
// 		// 				}
// 		// 				if (!sessionStorage.getItem("pricePlanning")) {
// 		// 					Companiestransfers.getPricePlanningTargets().then((json) =>
// 		// 						sessionStorage.setItem("pricePlanning", JSON.stringify(json.data))
// 		// 					)
// 		// 				}
// 		// 				setTimeout(() => {
// 		// 					//if(!window.isOktaLogin)
// 		// 					window.location.href = process.env.PUBLIC_URL + '/#'
// 		// 				}, 500)
// 		// 				return true
// 		// 		})
// 		// 	}
// 		// 	else
// 		// 	{
// 		// 	const data = new FormData(event.currentTarget)
// 		// 	const params = {
// 		// 	username: data.get('email'),
// 		// 	password: data.get('password'),
// 		// 	}
// 		// 	if (validateForm(params).success) {
				
// 		// 		authProvider('AUTH_LOGIN', params).then(result => {
// 		// 			console.log(result)
// 		// 			// tentative++
// 		// 			// localStorage.setItem("C_BLACKLIST_CACHEKEY",  params.username.split('@')[0] +'_'+tentative +'_'+ ipAddress.IPv4)
// 		// 			if (result === 'ra.auth.sign_in_error') {
// 		// 				setLoginValueError('Login ou Mot de passe incorrecte')
// 		// 				setloading(false)
						
// 		// 			}

// 		// 		}).catch(error => {
// 		// 			console.error(error)
// 		// 			setLoginValueError('Login ou Mot de passe incorrecte')
// 		// 			//tentative++
// 		// 			// console.log(tentative)
// 		// 			// localStorage.setItem("C_BLACKLIST_CACHEKEY",  params.username.split('@')[0] +'_'+tentative +'_'+ ipAddress.IPv4)

// 		// 			setloading(false)

// 		// 		})

// 		// 	}
// 		// 	else{
// 		// 		setloading(false)
// 		// 		setLoginValueError('Login ou Mot de passe incorrecte')
// 		// 		// tentative++
// 		// 		// localStorage.setItem("C_BLACKLIST_CACHEKEY",  params.username.split('@')[0] +'_'+tentative +'_'+ ipAddress.IPv4)
// 		// 	}
// 		// 	}
// 		// } catch (error) {
// 		// 	console.log(error)
// 		// 	setLoginValueError('login erreur')
// 		// 	setloading(false)
// 		// 	// tentative++

// 		// 	// localStorage.setItem("C_BLACKLIST_CACHEKEY",  params.username.split('@')[0] +'_'+tentative +'_'+ ipAddress.IPv4)


// 		// }
// 	}
// 	return (
// 		<ThemeProvider theme={theme}>
// 			<Container component="main" maxWidth="xs" style={{ width: "400px", padding: '24px' }}  >
// 					<div>
// 					{
// 						process.env.NODE_ENV !=='development'		?	
// 						<div>
// 						<br/> <br/>
// 							Veuillez vous authentifier pour commencer à utiliser l'application..
// 							<Button
// 								type="submit"
// 								fullWidth
// 								variant="contained"
// 								sx={{ mt: 3, mb: 2 }}
// 								style={{ margin: '25px 0px', background: '#288DB1', color: 'white',maxWidth:'400px' }}
// 								onClick={handleSubmit}
// 							>
// 								CONNEXION
// 							</Button>
						
// 						</div>		
						
// 						:		
// 						<>	
// 					<Box
// 					sx={{
// 						marginTop: 4,
// 						marginBottom: 8,
// 						display: 'flex',
// 						flexDirection: 'column',
// 						alignItems: 'center',
// 					}}
// 					>
// 					<Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }} style={{ minWidth: '350px' }}>
// 						<TextField
// 							margin="normal"
// 							required
// 							fullWidth
// 							id="email"
// 							label="Identifiant"
// 							name="email"
// 							autoComplete="email"
// 							autoFocus
// 							error={loginValueError !== ""}
// 							helperText={loginValueError}
// 							FormHelperTextProps={{
// 								classes: {
// 									root: props.classes.helperText
// 								}
// 							}}
// 						/>
// 						<TextField
// 							margin="normal"
// 							style={{marginBottom:'0'}}
// 							required
// 							fullWidth
// 							name="password"
// 							label="Mot de passe"
// 							type="password"
// 							id="password"
// 							autoFocus
// 							autoComplete="current-password"
// 							errorLabel={pwdValueError !== ""}
// 							helperText={pwdValueError}
// 							FormHelperTextProps={{
// 								classes: {
// 									root: props.classes.helperText
// 								}
// 							}}
// 						/>
// 						{isLoading && <div  className={classes.root}><LinearProgress /></div>}

// 						<Button
// 							type="submit"
// 							fullWidth
// 							variant="contained"
// 							sx={{ mt: 3, mb: 2 }}
// 							style={{ margin: '25px 0', background: '#288DB1', color: 'white' }}
// 						>
// 							CONNEXION
// 						</Button>

// 						<Grid container >
// 							<Link variant="body2" style={{ textAlign: 'left', color: '#288db1', cursor: 'pointer' }} onClick={() => onCreatePwd(true)}>
// 								Première connexion
// 							</Link>
// 							<Link variant="body2" style={{ marginLeft: '20%', textAlign: 'right', color: '#288db1', cursor: 'pointer' }} onClick={() => onResetPwd(true)}>
// 								Mot de passe oublié ?
// 							</Link>
// 						</Grid>

// 					</Box>
// 				</Box>
// 				</>	
// 					}
// 					</div>
// 			</Container>
// 		</ThemeProvider>
// 	)
// }


// export default withStyles(styles)(LoginFrm)
