import React from 'react'
import Dialog from '@material-ui/core/Dialog'
import Slide from '@material-ui/core/Slide'
import PropTypes from 'prop-types'
import cyan from '@material-ui/core/colors/cyan'
import LinearProgress from '@material-ui/core/LinearProgress'
import Button from '@material-ui/core/Button'
import { CancelIcon } from '../../constants/icons'
import green from '@material-ui/core/colors/green'
import red from '@material-ui/core/colors/red'
import { withStyles } from '@material-ui/core/styles'

const Transition = React.forwardRef(function Transition(props, ref) {
	return <Slide direction="up" ref={ref} {...props} />
})

const styles = theme =>   ({
	paperWidthSm : {
		maxWidth: '600px',
		width: '400px'
	},
	button: {
		margin: theme.spacing(1),
		width : '150px'
	},
	buttonCancel: {
		margin: theme.spacing(1),
		width : '150px',
		background: '#e23434'
	},
	buttonCheck: {
		margin: theme.spacing(1),
		width : '150px',
		background: '#0c9e0c'
	},
	leftIcon: {
		marginRight: theme.spacing(1),
	},
	dialogHeader:{
		padding: '24px 24px 20px',
		background: cyan[500],
		color: 'white',
		fontSize: 'medium',
		fontWeight: 'bold',
		textAlign: 'center',
		//height: '36px'
	},
	dialogContent: {
		padding: '16px 24px 17px',
		flex: '1 1 auto',
		color: 'black',
		fontSize: '1rem',
		fontWeight: '400',
		textAlign : 'center'
	},
	dialogFooter: {
		flex: '0 0 auto',
		margin: '8px 4px',
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'flex-end'
	},
	margin: {
		margin: theme.spacing(1),
	},
})

const SuccessLinearProgress = withStyles({
	root: {
		height: 20,
		backgroundColor: green[200],
		borderRadius: 20,
	},
	bar: {
		borderRadius: 20,
		backgroundColor: green[700],
	},
})(LinearProgress)

const FailureLinearProgress = withStyles({
	root: {
		height: 20,
		backgroundColor: red[200],
		borderRadius: 20,
	},
	bar: {
		borderRadius: 20,
		backgroundColor: red[700],
	},
})(LinearProgress)

const CustomLinearProgress = props => {
	const { isVisible, progressLabel, updateProgress, totalCount } = props
	if(isVisible){
		return (
			<React.Fragment>
				{progressLabel} : { updateProgress } / { totalCount }
				<br/>
				<LinearProgress color="secondary" /> 
			</React.Fragment>
		)
	}
	else{
		return <div></div>
	}
}

function LinearProgressModal(props) {
	const [open, setOpen] = React.useState(false)
	const { modalOpen, onClose, classes, totalCount, updateProgress, numberOfSuccess, progressModalContent } = props
	const progressDiff = totalCount - updateProgress
	const numberOfFailedOperation = updateProgress - numberOfSuccess
	const { title, progress, success, failure, closeButtonText } = progressModalContent
	const successPercentage = Math.round((numberOfSuccess*100) / totalCount)
	const failurePercentage = Math.round((numberOfFailedOperation*100) / totalCount)
	
	function setOpenModalFromProps(modalOpen){
		setOpen(modalOpen)
	}

	React.useEffect(() => {
		setOpenModalFromProps(modalOpen)
	})

	return (
		<div>
			<Dialog
				open={open}
				TransitionComponent={Transition}
				keepMounted
				onClose={onClose}
				aria-labelledby="alert-dialog-slide-title"
				aria-describedby="alert-dialog-slide-description"
				disableEscapeKeyDown = {true}
				//disableBackdropClick = {true}
				classes = {{
					paperWidthSm : classes.paperWidthSm
				}}
			>
				<div className={classes.dialogHeader}>
					{title}
				</div>
				<div className={classes.dialogContent}>
					<CustomLinearProgress isVisible = {progressDiff === 0 ? false : true} progressLabel = {progress} updateProgress = { updateProgress } totalCount = { totalCount }/>
					<br/>
					{success} : { numberOfSuccess } / { totalCount }
					<SuccessLinearProgress
						className={classes.margin}
						variant="determinate"
						color="secondary"
						value={successPercentage}
					/>
					<br/>
					{failure} : { numberOfFailedOperation } / { totalCount }
					<FailureLinearProgress
						className={classes.margin}
						variant="determinate"
						color="secondary"
						value={failurePercentage}
					/>
				</div>
				<div className={classes.dialogFooter}>
					<Button onClick={onClose} color="secondary" variant="contained" className={classes.buttonCancel} disabled = { !(progressDiff === 0) }>
						<CancelIcon className={classes.leftIcon} />
						{closeButtonText}
					</Button>
				</div>
			</Dialog>
		</div>
	)
}

LinearProgressModal.propTypes = {
	modalOpen: PropTypes.bool,
	onClose: PropTypes.func,
	classes: PropTypes.object.isRequired,
	totalCount: PropTypes.number,
	updateProgress: PropTypes.object,
	numberOfSuccess: PropTypes.number,
	progressModalContent: PropTypes.object
}

export default withStyles(styles)(LinearProgressModal)