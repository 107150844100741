import React, { useState, useEffect } from 'react';
import compose from 'recompose/compose'
import { translate } from 'react-admin'

import { withStyles } from '@material-ui/core/styles'
import { CircularProgress, Typography, Accordion, AccordionSummary, AccordionDetails, Button, IconButton, Dialog, DialogTitle, DialogContent, DialogActions, Select, MenuItem } from '@material-ui/core'
import { CustomAlert, CustomCheckBox, CustomSelect,CustomSwitch } from '../layout/GlobalStyles'

import {DomainsItems} from '../../requests'

import Grid from '@material-ui/core/Grid'
import { InputLabel } from '@material-ui/core'
import Tooltip from '@material-ui/core/Tooltip'
import Chip from '@material-ui/core/Chip'
import albums from '../../reducers/albums';
import CompaniesAlbums from '../../requests/CompaniesAlbums';

const styles = (theme) => ({
	root: {
		display: 'flex',
		flexWrap: 'wrap',
		'& > *': {
			margin: theme.spacing(1),
			width: '100%',
			height: theme.spacing(10),
		},
	},
	
	labels: {
		fontSize: '1.1em',
		//fontWeight: 'bold',
		// color: '#288DB1',
		color: 'black',
	},
	
	chip: {
		color: '#fff',
		backgroundColor : '#288DB1',
		'&:hover': {
			background: '#1c627b'
		},
		'&:active': {
			background: '#1c627b'
		},
		'&:focus': {
			background: '#288DB1',
			color:'#fff'
		},
		// '&:disabled':{
		// 	background:'#1c627b' ,
		// 	color:'#fff'
		// }
	},
});


// const sfrSerieList =[ //type = 4
// 	{id:0,name: 'Série uniquement'},
// 	{id:1,name: 'Toutes les saisons'},
// 	{id:2,name: 'Série + saisons'}
// ]
// const sfrSaisonList =[ //type = 5
// 	{id:0,name: 'Saison uniquement'},
// 	{id:1,name: 'Toutes les saisons'},
// 	{id:2,name: 'Série + saisons'},
// 	{id:3,name: 'Programmes uniquement'}
// ]

// const sfrPackList =[ //type = 2
// 	{id:0,name: 'Pack uniquement'},
// 	{id:1,name: 'Pack + Programmes'},
// 	{id:2,name: 'Programmes uniquement'}
// ]
const sfrData =[
	{id:0,name: 'Tout'},
	{id:1,name: 'Metadata uniquement'},
	{id:2,name: 'Visuals uniquement'}
]
const modeMedia =[
	{id:0,name: 'Défaut'},
	{id:1,name: 'BA Uniquement'},
	{id:2,name: 'Ignorer BA'},
	{id:3,name: 'Ajouter BA'},
	{id:4,name: 'Disponibilté média'}
]
const cableabsOption =[
	{id:0,name: 'Tout'},
	{id:1,name: 'Infos Pack uniquement'},
	{id:2,name: 'Programmes uniquement'}
]
const imageBddpOption =[
	{id:0,name: 'Tout'},
	{id:1,name: 'Album uniquement'},
	{id:2,name: 'Programmes uniquement'}
]
const onChangeRules = (event, infos) => e => {
	let value
	switch (event) {
		case 'target':
			value = e.substring(0, e.length - 1)
			 this.setState({
				[event]: value
			}, () => {
				this.updateState()
			})

			break;

	
	}
}
const JobsComponent = (props) => {
	const { classes, job, listcheckedJobs, album, onExportJobs, ExportJobs } = props
	//const [selectedAlbum,setAlbum] = useState({})
	///sfr job
	const [sfrAlbum, setsfrAlbum] = useState(0)
	const [selectsfrdata, setsfrdata] = useState(0)
	//
	const [mediahubMode, setMediahubMode] = useState(0)
	const [mediahubCibles, setMediahubCibles] = useState(-1)
	const [cablelabsHapi, setCablelabsHapi] = useState(0)
	const [albumImageBDDP, setAlbumImageBDDP] = useState(1)
	const [choices, setChoices] = React.useState([])
	const [selectedMediaCible, setSelectedMediaCible] = useState([]);
	const [albumList, setAlbumList] = useState([])
	const [packProg, setpackProg] = useState([])
	const sfrList =[
		{id:0,name: album.typeid === 2 ? 'Pack uniquement' : album.typeid === 4 ? 'Série uniquement':album.typeid === 5 ? 'Saison uniquement' : 'Album' },
		{id:1,name: album.typeid === 4 || album.typeid ===5 ? 'Toutes les saisons' : 'N/A'  },
		{id:2,name: album.typeid === 4 || album.typeid ===5 ? 'Série + saisons' : 'Pack + Programmes'},
		{id:3,name: album.typeid === 5 || album.typeid ===2 ? 'Programmes uniquement'  : 'N/A' }
	
	]

	const handleMediaCibleClick = (label) => {
	  if (selectedMediaCible.includes(label)) {
		setSelectedMediaCible(selectedMediaCible.filter(chip => chip !== label))
	  } else {
		setSelectedMediaCible([...selectedMediaCible, label])
	  }
	  
	}
	useEffect( () => {
		if(!album.isPack)
			setCablelabsHapi(2)	
		else
			setCablelabsHapi(1)
	}, [album])
	useEffect( () => {
		if(job.itemid){
			setJobsProperties(selectsfrdata,'sfrData')	
		 	setJobsProperties(sfrAlbum,'sfrAlbumType')	}
	}, [job.itemid])


	useEffect( () => {
		if(choices.length === selectedMediaCible.length) 
			setMediahubCibles(-1)
		else 
			setMediahubCibles(0)
	}, [selectedMediaCible])

	useEffect(() => {

		const fetchData = async () => {
			let choicesData = await DomainsItems.getAll(148)
			setChoices(choicesData.data)
			let listcible =[]
			choicesData.data.map((element)=>
				listcible.push(element.label)
			)
			setSelectedMediaCible(listcible)
			 
		}
		fetchData().catch(console.error)
		CompaniesAlbums.getAllalbums().then(
			(res) =>{
				setAlbumList(res.data)
			}
		)
		if( album && album.typeid ===2){
			// CompaniesAlbums
		}

 	}, [])



	const AddButton = withStyles((theme) => ({
		root: {
			color: 'white',
			backgroundColor: '#288DB1',
			'&:hover': {
				backgroundColor: '#1C627B',
			},
		},
	}))(Button)

	const setJobsProperties = (id,propList) =>{
		console.log('setJobsProperties')
		switch (job.itemid){
			case 12:{
				if(propList ==='sfrData'){
					setsfrdata(id)
					let prop= job.properties
					if(id  === 0){
						prop.XML =1
						prop.PICTURES= 1 
						job.xml = 1
						job.picture =1
					}
					if(id  === 1){
						prop.XML =1
						prop.PICTURES= 0 
						job.xml = 1
						job.picture =0
					}
					if(id  === 2){
						prop.XML =0
						prop.PICTURES= 1 
						job.xml = 0
						job.picture =1
					}
					console.log(job)
					if(ExportJobs.length !==0){
						let sfrJobs = ExportJobs.filter(sfr =>sfr.typeid ===12 )
						console.log(sfrJobs)
						sfrJobs.forEach((elt)=>
						{
							elt.xml = job.xml
							elt.picture = job.picture
						}
						)
					}
 					
					
				}
				if(propList==='sfrAlbumType'){
					setsfrAlbum(id)
					let date = new Date()
					let listjobs = []
					let prop = job.properties
					switch(id){
						case 0://album uniquement
						{
 							let sfrAlbumjob ={
							typeid : job.itemid,
							refid : album.id, 
							xml : job.xml,
							picture : job.picture,
							executiondate : date.toLocaleString("en-US")
							}
							listjobs.push(sfrAlbumjob)
							onExportJobs(listjobs)
						}
						break
						case 1://'Toutes les saisons'
						{
							let albumparent = album

  							if(albumparent.typeid === 5){
								albumparent = albumList.filter(s=>s.id === album.parentid)[0]
							}

							if(albumparent.typeid === 4){
								let seasons = albumList.filter(s=>s.parentid === albumparent.id)
								seasons.forEach((season)=>{
									let newjob ={
										typeid : job.itemid,
										refid : season.id,
										xml : job.xml,
										picture : job.picture,
										executiondate : date.toLocaleString("en-US")
										}
									listjobs.push(newjob)
								})
								 
							}
							onExportJobs(listjobs)
						}
						break
						case 2 :// Série + saison(4,5)  et pack + programme(2)
							{
							if(album.typeid === 2){
								let seriejob ={
									typeid : job.itemid,
									refid : album.id,
									xml : job.xml,
									picture : job.picture,
									executiondate : date.toLocaleString("en-US")
									}
								listjobs.push(seriejob)
								CompaniesAlbums.getAlbumMoviesbyIds(album.id).then(res =>{
									if(res.data ){
										res.data.forEach((item)=>{
											if(item.movie.id !==0)
											{
												let progjob ={
												typeid : 11,
												refid : item.movie.id,
												xml : job.xml,
												picture : job.picture,
												executiondate : date.toLocaleString("en-US")
												}
											listjobs.push(progjob)
											}
										})
										

									}
								}

								)
							}else{
								let albumparent = album
								if(albumparent.typeid === 5){
									albumparent = albumList.filter(s=>s.id === album.parentid)[0]
									let seriejob ={
										typeid : job.itemid,
										refid : albumparent.id,
										xml : job.xml,
										picture : job.picture,
										executiondate : date.toLocaleString("en-US")
										}
									listjobs.push(seriejob)
								}
								if(albumparent.typeid === 4){
									let seasons = albumList.filter(s=>s.parentid === albumparent.id)
									seasons.forEach((season)=>{
									let newjob ={
										typeid : job.itemid,
										refid : season.id,
										xml : job.xml,
										picture : job.picture,
										executiondate : date.toLocaleString("en-US")
										}
									listjobs.push(newjob)
									})
								}
							}
							onExportJobs(listjobs)
							}
							break
						case 3 :{
							if(album.typeid === 2){
								 
								CompaniesAlbums.getAlbumMoviesbyIds(album.id).then(res =>{
									if(res.data ){
										res.data.forEach((item)=>{
											if(item.movie.id !==0)
											{
												let progjob ={
												typeid : 11,
												refid : item.movie.id,
												xml : job.xml,
												picture : job.picture,
												executiondate : date.toLocaleString("en-US")
												}
												listjobs.push(progjob)
											}
										})
									}
								}

								)
							}
							if(album.typeid === 5){
								CompaniesAlbums.getAlbumMoviesbyIds(album.id).then(res =>{
									if(res.data ){
										res.data.forEach((item)=>{
											if(item.movie.id !==0)
											{
												let progjob ={
												typeid : 11,
												refid : item.movie.id,
												xml : job.xml,
												picture : job.picture,
												executiondate : date.toLocaleString("en-US")
												}
												listjobs.push(progjob)
											}
										})
										

									}
								}

								)
							}
							onExportJobs(listjobs)
						}
						break
					}
				 
				}
					
 			}
		}

	}


	switch (job.itemid) {
		case 4:
			return (
				<div>
					<AddButton variant="contained" size="small" disabled={!listcheckedJobs.find(s=>s ===4) || album.typeid === 5} style={{ marginRight: '10px', marginBottom: '5px' }}>
						Voir les statut Bouygues (Ingrid)
					</AddButton>
				</div>
			)
			break;
		case 5:
			return (
				<div>

					<AddButton variant="contained" size="small" style={{ marginRight: '10px', marginBottom: '5px' }}>
						test 5
					</AddButton>
				</div>
			)
			break;
		case 7:
			return (
				<div>

					<AddButton variant="contained" size="small" style={{ marginRight: '10px', marginBottom: '5px' }}>
						test 7
					</AddButton>
				</div>
			)
			break;
		case 12:
			return (
				<div>

					{/* <AddButton variant="contained" size="small" style={{ marginRight: '10px', marginBottom: '5px' }}>
						test 12
					</AddButton> */}
					<Grid container>
						<Grid item xs='4px' >							
							<CustomSelect values={sfrList.filter(s=>s.name!=='N/A')} value={sfrAlbum} handleChange={(e) =>setJobsProperties(e.target.value,'sfrAlbumType')} disabled={!listcheckedJobs.find(s=>s ===12)}/>

							{/* <CustomSelect values={album.typeid===4 ? sfrSerieList : album.typeid === 5 ? sfrSaisonList : album.typeid ===2 ?sfrPackList :sfrList} value={sfrAlbum} handleChange={(e) =>setJobsProperties(e,'sfrAlbumType')} disabled={!listcheckedJobs.find(s=>s ===12)}/> */}
						</Grid>
						<Grid item xs='4px' >
							<CustomSelect values={sfrData} value={selectsfrdata} handleChange={(e) =>setJobsProperties(e.target.value,'sfrData') } disabled={!listcheckedJobs.find(s=>s ===12)} />
						</Grid>
					</Grid>
				</div>
			)
			break;
		case 15:
				return (
					<div>
	
						<CustomSelect values={modeMedia} label='Mode' disabled={!listcheckedJobs.find(s=>s ===15)} value={mediahubMode} handleChange={e=>setMediahubMode(e.target.value)}/>

						<Grid container>
							<Grid item xs='4px' >
							<InputLabel htmlFor="input-with-icon-adornment" className={classes.labels} >Cibles</InputLabel>
							</Grid>
							<Grid item xs='4px' >
								<Tooltip title={mediahubCibles === -1 ? "Désélectionner tout" : "Sélectionner tout"}	> 	
									<CustomSwitch
										checked={mediahubCibles === -1}
										onChange={(e,checked) => {checked ? setMediahubCibles(-1): setMediahubCibles(0)}}
										color="primary" size="small" 
										inputProps={{ 'aria-label': 'primary checkbox' }}
										className={classes.switch}
										disabled={!listcheckedJobs.find(s=>s === 15)}
									/>
								</Tooltip>
								</Grid>
								<Grid item xs='6px' style={{margin: '.5em'}}>
									{choices.map((element, index) => (
										<Tooltip key={index} title={element.label} style={{ backgroundColor: "#000",margin: '1.5em'}}>
											<Chip className={classes.chip}  clickable disabled={!listcheckedJobs.find(s => s === 15)} 
											label={element.label} 
											style={{background : selectedMediaCible.includes(element.label) ?'#288DB1': 'grey' ,margin: '.5em'}}
											onClick={() => handleMediaCibleClick(element.label)}
 											/>
										</Tooltip>
									))}
								</Grid>
							</Grid>
					</div>
				)
				break;
		case 29:
			return (
				<div>
					<CustomSelect values={cableabsOption}  disabled={!listcheckedJobs.find(s=>s ===29)} value={cablelabsHapi} handleChange={e=>setCablelabsHapi(e.target.value)}/>

					{/* <Tooltip title={cablelabsHapi === -1 ? "Désélectionner tout" : "Sélectionner tout"}	> 	
							<CustomSwitch
								checked={cablelabsHapi === -1}
								onChange={(e,checked) => {checked ? setCablelabsHapi(-1): setCablelabsHapi(0)}}
								color="primary" size="small" 
								inputProps={{ 'aria-label': 'primary checkbox' }}
								className={classes.switch}
								disabled={!listcheckedJobs.find(s=>s === 29) || !album.isPack}
							/>
					</Tooltip>
					<Tooltip title="Infos Pack uniquement" style={{ backgroundColor: "#000"}}>
						<Chip className={classes.chip} clickable disabled={!listcheckedJobs.find(s=>s ===29) || !album.isPack} 
						onClick={()=> setCablelabsHapi(1)} label="Infos Pack uniquement" value={cablelabsHapi ===1 ||cablelabsHapi === -1} 
						style={{margin: '.5em',background: (cablelabsHapi ===1 ||cablelabsHapi === -1) ?'#288DB1' :'grey'}}/>
					</Tooltip>
					<Tooltip title="Programmes uniquement" style={{ backgroundColor: "#000"}}>
						<Chip className={classes.chip} clickable disabled={!listcheckedJobs.find(s=>s ===29)}  
						onClick={()=> setCablelabsHapi(2)} label="Programmes uniquement" checked={cablelabsHapi ===2 ||cablelabsHapi === -1 } 
						style={{margin: '.5em',background: (cablelabsHapi ===2 ||cablelabsHapi === -1) ?'#288DB1' :'grey'}}/>
					</Tooltip> */}
					
				</div>
			)
			break;
		case 30:
			return (
				<div>
				<div>
					<InputLabel htmlFor="input-with-icon-adornment" className={classes.labels} >Options d'export image BDDP:</InputLabel>
					<CustomCheckBox label="Jaquette Standard (13)" disabled={!listcheckedJobs.find(s=>s ===30)} labelPlacement='end' color="#000"/>
					<CustomCheckBox label="16/9 (239)" disabled={!listcheckedJobs.find(s=>s ===30)} labelPlacement='end' color="#000"/>
				</div>
				<div>		 
				<CustomSelect values={imageBddpOption} value={albumImageBDDP} handleChange={e=>setAlbumImageBDDP(e.target.value)} disabled={!listcheckedJobs.find(s=>s ===30)}/>

					{/* <Tooltip title={albumImageBDDP === -1 ? "Désélectionner tout" : "Sélectionner tout"}> 	
							<CustomSwitch
								label="Export image BDDP album uniquement"
								checked={albumImageBDDP === -1}
								onChange={(e,checked) => {checked ? setAlbumImageBDDP(-1): setAlbumImageBDDP(0)}}
								color="primary" size="small" 
								inputProps={{ 'aria-label': 'primary checkbox' }}
								className={classes.switch}
								disabled={!listcheckedJobs.find(s=>s === 30)}
							/>
					</Tooltip> */}
					{/* <Tooltip title="Album uniquement" ><Chip className={classes.chip} clickable disabled={!listcheckedJobs.find(s=>s ===30)} onClick={()=> setAlbumImageBDDP(1)} 
					label="Album uniquement" value={albumImageBDDP ===1 ||albumImageBDDP === -1} style={{margin: '.5em' ,background: (albumImageBDDP ===1 ||albumImageBDDP === -1) ?'#288DB1' :'grey'}}/></Tooltip>
					<Tooltip title="Programmes uniquement"><Chip className={classes.chip} clickable disabled={!listcheckedJobs.find(s=>s ===30)}  onClick={()=> setAlbumImageBDDP(2)} 
					label="Programmes uniquement" checked={albumImageBDDP ===2 ||albumImageBDDP === -1 } style={{margin: '.5em',background: (albumImageBDDP ===2 ||albumImageBDDP === -1) ?'#288DB1' :'grey'}}/></Tooltip> */}
				</div>
				</div>
			)
			
			break;
		case 37:
			return (
				<div>

					{/* <AddButton variant="contained" size="small" style={{ marginRight: '10px', marginBottom: '5px' }}>
						test 37
					</AddButton> */}
				</div>
			)
			break;

		default:
			return (<div></div>)
			break;
	}

}

const enhance = compose(
	withStyles(styles)
)

export default translate(enhance(JobsComponent))

