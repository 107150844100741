import React, {useEffect} from 'react'
import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import CardChild from '../../CardChild'
import { RSACField, ContactField, ExtendedTextField, MyTextField, MyDateField, CardTitleField, MovieField, MyBooleanField } from '../common/fields'
import { MySimpleFormLayout } from '../layout'
import { TvShowIcon, ExternalIcon, RsacIcon, AuditedIcon, PropertiesIcon } from '../../constants/icons'
import { Typography } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import LocalStorage from '../../tools/LocalStorage'
// import { useEffect } from '@mui/utils/node_modules/@types/react'

const styles =   ({
	card: {
		paddingTop: 10,
		flex: '1 auto',
		backgroudColor :'#cacaca'
	},
});

// const CardShow = withStyles(styles)(({ classes, record } = this.props) => (
const CardShow = (props) => {
	const {classes, record} = props
	console.log(record)
	
	useEffect( () => {
		//Gestion historique
		LocalStorage.history(record.id, '1', record.title ? record.title : record.value, '0')
	}, [])
	
	return(
	<MySimpleFormLayout>
		<Card className={classes.card}>
			<CardContent>
				<Typography variant="title">
					<MovieField addId={true} addLabel={true} sourceId='id' sourceLabel='title' record={record} addAvatar={true} size={36} />
				</Typography>
			</CardContent>
			<CardChild>
				<MySimpleFormLayout record={record}>
					<MyTextField label="fields.id" source="id" disabled />
					<MyTextField label="fields.title" source="title" />
					<MyTextField label="fields.shorttitle" source="shorttitle" />
					<ExtendedTextField label="fields.typeid" source="typeid" domainid={4} />
					<ExtendedTextField label="fields.subtypeid" source="subtypeid" domainid={41} />
					<ExtendedTextField label="fields.pricingclassid" source="pricingclassid" domainid={87} />
					<ExtendedTextField label="fields.genderid" source="genderid" domainid={75} />
					<ExtendedTextField label="fields.subgenreid" source="subgenreid" domainid={95} />
					<ExtendedTextField label="fields.countryid" source="countryid" domainid={2} />
					<ExtendedTextField label="fields.langid" source="langid" domainid={1} />
					<MyDateField label="fields.releasedate" source="releasedate" />
					<MyTextField label="fields.year" source="year" />
					<MyTextField label="fields.duration" source="duration" />
					<MyBooleanField label="fields.color" source="color" />
					<ExtendedTextField label="fields.imagefmtid" source="imagefmtid" domainid={6} />
					<ExtendedTextField label="fields.audiofmtid" source="audiofmtid" domainid={7} />
					<MyTextField label="fields.copyright" source="copyright" />
					<MyTextField label="fields.visa" source="visa" />
					<ExtendedTextField label="fields.averagetargetage" source="averagetargetage" averagetargetage />
					<ExtendedTextField label="fields.classificationid" source="classificationid" domainid={102} />
					<ExtendedTextField label="fields.ratingid" source="ratingid" ratingid />
				</MySimpleFormLayout>
			</CardChild>
		</Card>

		<Card className={classes.card}>
			<CardContent>
				<CardTitleField icon={<TvShowIcon />}>Informations séries</CardTitleField>
			</CardContent>
			<CardChild>
				<MySimpleFormLayout record={record}>
					<MyTextField label="fields.season" source="season" />
					<ExtendedTextField label="fields.pack" source="pack" pack />
					<MyTextField label="fields.episode" source="episode" />
				</MySimpleFormLayout>
			</CardChild>
		</Card>

		<Card className={classes.card}>
			<CardContent>
				<CardTitleField icon={<ExternalIcon />}>Références externes</CardTitleField>
			</CardContent>
			<CardChild>
				<MySimpleFormLayout record={record}>
					<MyTextField label="fields.allocineid" source="allocineId" />
					<MyTextField label="fields.imdbid" source="imdbId" />
					<MyTextField label="fields.cncid" source="cncId" />
					<MyTextField label="fields.pfvideoid" source="pfVideoId" />
					<MyTextField label="fields.atgid" source="atgId" />
					<MyTextField label="fields.numaffaireantenne" source="numAffaireAntenne" />
					<MyTextField label="fields.idprogrammeantenne" source="idProgrammeAntenne" />
				</MySimpleFormLayout>
			</CardChild>
		</Card>

		<Card className={classes.card}>
			<CardContent>
				<CardTitleField icon={<RsacIcon />}>RSAC</CardTitleField>
			</CardContent>
			<CardChild>
				<MySimpleFormLayout record={record}>
					<RSACField label="Langue" rsacType="langue" source="rsac" />
					<RSACField label="Nudité" rsacType="nudite" source="rsac" />
					<RSACField label="Sexe" rsacType="sexe" source="rsac" />
					<RSACField label="Violence" rsacType="violence" source="rsac" />
				</MySimpleFormLayout>
			</CardChild>
		</Card>

		<Card className={classes.card}>
			<CardContent>
				<CardTitleField icon={<PropertiesIcon />}>Propriétés</CardTitleField>
			</CardContent>
			<CardChild>
				<MySimpleFormLayout record={record}>
					<MyTextField label="fields.properties" source="properties" />
				</MySimpleFormLayout>
			</CardChild>
		</Card>

		<Card className={classes.card}>
			<CardContent>
				<CardTitleField icon={<AuditedIcon />}>Gestion</CardTitleField>
			</CardContent>
			<CardChild>
				<MySimpleFormLayout record={record}>
					<MyDateField showTime={true} label="fields.createdon" source="createdon" />
					<ContactField label="fields.createdby" source="createdby" />
					<MyDateField showTime={true} label="fields.modifiedon" source="modifiedon" />
					<ContactField label="fields.modifiedby" source="modifiedby" />
				</MySimpleFormLayout>
			</CardChild>
		</Card>
	</MySimpleFormLayout>
	)}
// ))

export default withStyles(styles)(CardShow);
