export default ({
	alwaysOn,
	basePath,
	component,
	defaultValue,
	formClassName,
	initializeForm,
	input,
	isRequired,
	label,
	locale,
	meta,
	options,
	optionText,
	optionValue,
	record,
	resource,
	allowEmpty,
	source,
	textAlign,
	translate,
	translateChoice,
	...rest
}) => rest