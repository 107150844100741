import Api from './Api'

const PricePlanning = {
	getPrices: (objectId,objectTypeId) =>
		Api.Requests.getAll(`/PricePlanning/Price?objectId=${objectId}&objectTypeId=${objectTypeId}`),
	getPricinglist: (pricingId) =>
		Api.Requests.getAll(`/PricePlanning/PricingslistsSpecification?pricingid=${pricingId} `)
}

export default PricePlanning
