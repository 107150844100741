import React, { Component }  from 'react'
import PropTypes from 'prop-types'
import Typography from '@material-ui/core/Typography'
import { Avatar, Tooltip } from '@material-ui/core'
import Slide from '@material-ui/core/Slide'
import Dialog from '@material-ui/core/Dialog'
import DialogTitle from '@material-ui/core/DialogTitle'
import ExpansionPanel from '@material-ui/core/ExpansionPanel'
//import { Accordion as ExpansionPanel } from '@material-ui/core'
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails'
//import { AccordionDetails as ExpansionPanelDetails} from '@material-ui/core'
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary'
//import { AccordionSummary as ExpansionPanelSummary} from '@material-ui/core'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import Paper from '@material-ui/core/Paper'
import { translate } from 'react-admin'
import {withStyles }  from '@material-ui/core/styles'

const styles = theme => ({
	root: {
		width: '100%',
	},
	modalButton : {
		width: 30,
		height: 30,
		// backgroundColor: '#59a7b3',
		backgroundColor: theme.palette.common.white,
		// borderRadius: '8px',
		fontWeight: 'bold',
	},
	heading: {
		fontSize: theme.typography.pxToRem(15),
		flexBasis: '33.33%',
		flexShrink: 0,
		color: '#288DB1',
		fontWeight: 'bold'
	},
	secondaryHeading: {
		fontSize: theme.typography.pxToRem(15),
		color: theme.palette.text.secondary,
	},
	paper: {
		marginTop: theme.spacing(3),
		width: '100%',
		overflowX: 'auto',
		marginBottom: theme.spacing(2),
	},
	table: {
		minWidth: 650,
	}
})

const Transition = React.forwardRef(function Transition(props, ref) {
	return <Slide direction="up" ref={ref} {...props} />
})

const SubElementsWrapper = props => {
	// eslint-disable-next-line react/prop-types
	const {subElements, classes } = props
	if(subElements && subElements.length){
		// console.log('in the subelements condition '+subElements.length)
		return(
			<div className={classes.root}>
				<Paper className={classes.paper}>
					<Table className={classes.table} size="small">
						<TableHead>
							<TableRow>
								<TableCell></TableCell>
								<React.Fragment>
									{
										subElements[0].keyValues.map((kv, index) =>{
											return <TableCell align="right" key={index}>{kv.key}</TableCell>
										})
									}
								</React.Fragment>
							</TableRow>
						</TableHead>
						<TableBody>
							<React.Fragment>
								{
									subElements.map((item, index) =>{
										return (
											<TableRow key={index} >
												<TableCell component="th" scope="row">
													{item.textValue}
												</TableCell>
												{
													item.keyValues.map((kv, index) =>{
														return <TableCell align="right" key={index}>{kv.value}</TableCell>
													})
												}
											</TableRow>
										)
									})
								}
							</React.Fragment>
						</TableBody>
					</Table>
				</Paper>
			</div>
		)
	}
	else{
		return(
			<div></div>
		)
	}
}

const ExpansionPanelItem = props => {
	const { item, index, tooltipText, classes } = props
	const [expanded, setExpanded] = React.useState(item.subElements && item.subElements.length === 0 ? true : false)
	const handleChange = (event) => {
		setExpanded(expanded ? false : true)
	}
	return (
		<div className={classes.root} key={index}>
			<ExpansionPanel expanded={expanded} onChange={event => handleChange(event)}>
				<ExpansionPanelSummary
					expandIcon={<ExpandMoreIcon />}
					aria-controls="panel1bh-content"
					id="panel1bh-header"
				>
					<Tooltip title={tooltipText}>
						<Typography className={classes.heading}>{item.attributeName}</Typography>
					</Tooltip>
				</ExpansionPanelSummary>
				<ExpansionPanelDetails>
					<Typography className={classes.secondaryHeading}>{item.attributeValue}</Typography>
					<SubElementsWrapper classes = {classes} subElements={item.subElements} />
				</ExpansionPanelDetails>
			</ExpansionPanel>
		</div>
	)
}

const MultipleParamsWrapper = props => {
	// eslint-disable-next-line react/prop-types
	const { descriptions, classes, translate } = props
	const tooltipText = translate('messages.showParameterValue')
	return(
		<div>
			<React.Fragment>
				{descriptions.map( (item,index) => {
					return(
						<ExpansionPanelItem item = {item} index = {index} classes = {classes} tooltipText = {tooltipText}/>
					)
					
				})}
			</React.Fragment>
		</div>
	)
}

// eslint-disable-next-line react/prop-types
class CustomConfigurationModal extends Component {
	constructor(props) {
		super(props)
		this.state = {
			open: false
		}
	}

	handleOpen = () => {
		this.setState({
			open: true
		})
	}

	handleClose = () => {
		this.setState({
			open: false
		})
	}
	render(){
		const { modalContent , classes, tooltipText, translate } = this.props
		if(!modalContent.isEmpty){
			if(modalContent.contents){
				const element = modalContent.contents
				if(element.descriptions && 'null' !== element.descriptions){
					return(
						<div>
							<Tooltip title={tooltipText}>
								<Avatar onClick={this.handleOpen} className={classes.modalButton}>
									...
								</Avatar>
							</Tooltip>
							<Dialog
								open={this.state.open}
								TransitionComponent={Transition}
								keepMounted
								onClose={this.handleClose}
								aria-labelledby="alert-dialo000g-slide-title"
								aria-describedby="alert-dialog-slide-description"
								fullWidth={true}
								maxWidth = {'md'}
							>
								<React.Fragment key='1'>
									<DialogTitle id="alert-dialog-slide-title">
										{element.title}
									</DialogTitle>
									<MultipleParamsWrapper classes={classes} translate = {translate} descriptions={element.descriptions} />
								</React.Fragment>
							</Dialog>
						</div>
					)
				}
			}
		}
		else{
			return(
				<div></div>
			)
		}
	}
}

CustomConfigurationModal.propTypes = {
	source: PropTypes.string,
	record: PropTypes.object,
	classes: PropTypes.object.isRequired,
	modalContent: PropTypes.object,
	tooltipText: PropTypes.string,
	translate: PropTypes.func
}

export default translate(withStyles(styles)(CustomConfigurationModal))
