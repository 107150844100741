/* eslint-disable no-mixed-spaces-and-tabs */
import React, { Component } from 'react'
import MUIDataTable from 'mui-datatables'
import { connect } from 'react-redux'
import compose from 'recompose/compose'
import classnames from 'classnames'
import { PublicationTrackingReferenceData, DomainsItems, DateFormatter, Token } from '../../tools'
import ReferenceDataCell from './fields/ReferenceDataCell'
import MovieTitleCell from './fields/MovieTitleCell'
import DateCell from './fields/DateCell'
import PriceCell from './fields/PriceCell'
import WeekSelector from './fields/WeekSelector'
import CustomFooter from './fields/CustomFooter'
import CustomFilter from './fields/CustomFilter'
import ValidateUpdate from './fields/ValidateUpdate'
import ValidateDelete from './fields/ValidateDelete'
import UserCell from './fields/UserCell'
import StatusCell from './preparationboardfields/StatusCell'
import CommentsCell from './preparationboardfields/CommentsCell'
import { loadingDomainsItems } from '../../actions/domainsItems'
import { getDistinctDate, convertDataToPreparationMaterialTableRow, getDistinctItem } from './utilities/publicationTrackingUtilities'
import { translate } from 'react-admin'
import red from '@material-ui/core/colors/red'
import grey from '@material-ui/core/colors/grey'
import { onChangeWeek, onChangeYear, onLoadPlanning, updatePrioPlanningIds, updatePrepStaus, updateTimerCount } from '../../actions/publicationTracking'
import { Publicationstracking } from '../../requests'
import { Contacts as ContactsTools } from '../../tools'
import PlanningLauncher from './fields/PlanningLauncher'
import Button from '@material-ui/core/Button'
import { EditIcon } from '../../constants/icons'
import { Links } from '../../tools'
import { Link } from 'react-router-dom'
import ReferentContent from './fields/ReferentContent'
import { yellow } from '@material-ui/core/colors'
import CustomSort from './fields/CustomSort'
import TrackingsType from './fields/typepreparation/TrackingsType'
import { createTheme, withStyles, ThemeProvider, } from '@material-ui/core/styles'
import LinearProgress from '@material-ui/core/LinearProgress'
import TocIcon from '@material-ui/icons/Toc'
import Tooltip from '@material-ui/core/Tooltip'
import CircularProgress from '@material-ui/core/CircularProgress'
import RefreshIcon from '@material-ui/icons/Refresh'
import Chip from '@material-ui/core/Chip'
import { CustomAlert } from '../layout/GlobalStyles'
import { ConfigContext } from '../layout/ConfigProvider'

const styles = theme => ({
	root: {
		//width: '100%'
		padding: '2px',
		paddingRight: '1%',
		height: '500px'
	},
	defaultRow: {
		'& td': { backgroundColor: '#fff' }
	},
	UpdatedRow: {
		'& td': { backgroundColor: yellow[100] }
	},
	DeletedRow: {
		'& td': { backgroundColor: red[200] },
		'text-decoration': 'line-through'
	},
	SeasonRow: {
		'& td': { backgroundColor: grey[400] }
	},
	NameCell: {
		//zIndex: 1	,
		//opacity: 'inherit'

		//   fontWeight: 900,
		//   backgroundColor : 'green'
	},
	publicationPlanningHeader: {
		display: 'inline-flex'
	},
	InProgressButton: {
		margin: '8px'
	},
	button: {
		margin: theme.spacing(1),
		marginTop: '0px',
		top: '5px',
		width: '130px',
		maxHeight: '35px',
		fontSize: '0.73rem',
		backgroundColor: '#288DB1',
		'&:hover': {
			background: '#1c627b'
		},
		lineHeight: '1.2em'
	},
	rightIcon: {
		marginLeft: theme.spacing(1),
	},
	cellHeader: {
		textAlign: 'centre'
	},
	circularProgress: {
		position: 'absolute',
		top: '50%',
		left: '50%',
		zIndex: '10000',
		animationDuration: "550ms"
	},

})
let load = false
let timerId = 0

class PreparationBoard extends Component {
	static contextType = ConfigContext
	myValue = 0
	constructor(props) {
		super(props)
		//this.handler = this.handler.bind(this)
		this.state = {
			denseTable: true,
			stacked: true,
			users: [],
			inProgressData: [],
			showInProgress: false,
			colors: [],
			trackingtypes: [],
			rowsgroup: [],
			weekvalue: props.weekNumber,
			yearvalue: props.yearNumber,
			showuseronly: false,
			isLoading: false,
			selectedvalue: [],
			modifierStatus: true,
			publicationlist: [],
			categories: [],
			tasktypes: [],
			versions: [],
			taskGroup: [],
			statusTypes: [],
			prepstatustes: 0,
			data: [],
			rowPerPage: 50,
			pageNumber: 0,
			count: 0,
			updatestate: false,
			globalPubData: [],
			showSave: false,
			statusTypesPrep: [],
			statusTypesFront: [],
			trackingtypesGroup: []
			//timer: 10
		}

	}

	buildPlanningList = (jsonData, onLoadPlanning) => {
		const trackingData = jsonData
		let planningToBeSaved = {
			publicationsplannings: trackingData !== undefined ? trackingData.publicationsplannings : undefined,
			publicationsstats: []
		}
		this.setState({ isLoading: false })
		if(!load)
		onLoadPlanning(planningToBeSaved)
		// localStorage.setItem('counter',-1)

	}

	componentDidMount() {
		const { onChangeWeek, onChangeYear, onLoadPlanning } = this.props
		let weekNumber = DateFormatter.getNumberOfWeek()
		this.setState({ isLoading: true })
		localStorage.setItem('counter',-1)
		const week = Number.parseInt(weekNumber.toString().substring(4, 6).length === 2 ? weekNumber.toString().substring(4, 6) : '0' + weekNumber.toString().substring(4, 6))
		onChangeWeek(this.state.weekvalue !== 0 ? this.state.weekvalue : week)
		onChangeYear(this.state.yearvalue !== 0 ? this.state.yearvalue : Number.parseInt(weekNumber.toString().substring(0, 4)))
		weekNumber = (this.state.weekvalue !== 0 && this.state.yearvalue !== 0) ? ((this.state.yearvalue.toString() + (this.state.weekvalue.toString().length === 2 ? this.state.weekvalue.toString() : '0' + this.state.weekvalue.toString()))) :
			(Number.parseInt(weekNumber.toString().substring(0, 4) + (week.toString().length === 2 ? week.toString() : '0' + week.toString())))
		Publicationstracking.getInProgressPlublication(weekNumber).then((json) => {
			this.setState({
				inProgressData: json.data,

				count: json.data.length
			})

		})
		// Publicationstracking.getPublicationstrackingtypes().then((json) => {
		// 	this.setState({ trackingtypes: json.data })
		// })
		// Publicationstracking.getPublicationstrackingGroupetypes().then((json) => {
		// 	this.setState({ rowsgroup: json.data })
		// })
		Publicationstracking.getPlanning(weekNumber).then((json) => {
			this.buildPlanningList(json.data, onLoadPlanning)
			this.setState({
				isLoading: false,
				count: json.data.length
			})
		})
		this.setState({
			users: ContactsTools.getContactsByGroupid(160),
			categories: PublicationTrackingReferenceData.getCategories(),
			tasktypes: PublicationTrackingReferenceData.getTasksTypes(),
			versions: PublicationTrackingReferenceData.getVersions(),
			taskGroup: PublicationTrackingReferenceData.getTasksGroups(),
			statusTypes: PublicationTrackingReferenceData.getStatusTypes(),
			statusTypesPrep: PublicationTrackingReferenceData.getStatusTypePrepa(),
			statusTypesFront: PublicationTrackingReferenceData.getStatusTypeFront(),
			trackingtypes: PublicationTrackingReferenceData.getTrackingType(),
			trackingtypesGroup: PublicationTrackingReferenceData.getTrackingTypeGroups()

		})

	}

	// componentDidUpdate(prevProps, prevState) {
	// 	console.log(this.state.isLoading)
	// 	// Check if the 'loading' state has changed
	// 	if (this.state.isLoading !== prevState.isLoading) {
	// 	  this.onUpdateState() // Execute 'doSomething' when 'loading' state changes
	// 	}
	//   }

	// componentDidUpdate(previousProps,previousState){
	// 	if (previousProps.planningData !== this.props.planningData) {
	// 		// Lorsque les données sont mises à jour, lancer le compte à rebours
	// 		this.setState({counter:10})
	// 		//this.startCountdown()
	// 	  }

	// }
	shouldComponentUpdate(nextProps, nextState) {
		return this.props.planningData !== nextProps.planningData
			|| this.state.data !== nextState.data
			|| this.state.pageNumber !== nextState.pageNumber
			|| this.state.showuseronly !== nextState.showuseronly
			|| this.state.showSave !== nextState.showSave
			//|| this.state.updatestate !== nextState.updatestate
			//||this.state.showInProgress !== nextState.showInProgress
			? true : false
	}

	getMuiTheme = () => createTheme({
		overrides: {
			MuiPaper: {
				root: {
					backgroundColor: '#FFF',
				}
			},
			MUIDataTable: {
				responsiveScrollMaxHeight: {
					//maxHeight: 'none',
					overflowX: 'auto',
					minWidth: 'calc(850px + 100vw - 1024px)',
					maxWidth: 'calc(950px + 100vw - 1024px)',
					minHeight: 'calc(78vh - 100px)',//'680px',					 
				},
				root: {
					backgroundColor: '#AAF',
				},
				paper: {
					boxShadow: 'none',
					width: 'fit-content',
					marginTop: '5px'
					//minHeight: '750px'
				}
			},
			MUIDataTableBodyCell: {
				root: {
					//backgroundColor: '#FFF',
					padding: '0px',
					fontSize: '0.8rem',
					lineHeight: '1em'
				}
			},
			MUIDataTableHeadCell: {
				data: {
					color: '#0000008a',
					fontWeight: 'bold',
					fontSize: '0.825em',
					lineHeight: '1em',
				},
				root: {
					backgroundColor: '#FFF',
					padding: '0px 10px 0px 10px',
					'&:nth-child(1)': {
						width: '10%',
						//backgroundColor: '#FFF',
						padding: '0px',
						position: 'sticky',
						top: '0',
						zIndex: '1',
						color: '#0000008a',
						fontWeight: 'bold',
						fontSize: '0.825em',
						//backgroundColor: '#fff',
						borderBottom: '1px solid rgba(224, 224, 224, 1)'
					},
					'&:nth-child(2)': {
						width: '10%',
						//backgroundColor: '#FFF',
						padding: '0px',
						position: 'sticky',
						top: '0',
						zIndex: '1',
						color: '#0000008a',
						fontWeight: 'bold',
						fontSize: '0.825em',
						//backgroundColor: '#fff',
						borderBottom: '1px solid rgba(224, 224, 224, 1)'
					},
					position: 'sticky',
					top: '0',
					zIndex: '0',
					color: '#0000008a',
					fontWeight: 'bold',
					fontSize: '0.825em',
					
				},
				fixedHeaderCommon: {
					zIndex: 1,
					position: 'unset',

				}
			},
			MuiTableCell: {
				root: {
					textAlign: 'center',
					backgroundColor: '#fff'
				},
			},
			MuiDialog: {
				paperWidthSm: {
					maxWidth: '800px',
					backgroundColor: '#fff',//'#232c31'
				}
			},
			MUIDataTableToolbar: {
				actions: {
					position: 'sticky',
					right: '0px',
					bottom: '0px',
					//flex : 'unset',
				}
			},
			MuiIconButton: {
				root: {
					padding: '10px',
					borderRadius: '50%',
					minWidth: '40px',
					height: '45px'
				}
			},
			MuiCircularProgress :{
				colorPrimary :{
					color :'#288DB1',
					animationDuration: "550ms"
				}
			}
			
			// MuiLinearProgress:{
			// 	colorPrimary :{
			// 		backgroundColor: '#b7e2b6'
			// 	},
			// 	barColorPrimary:{
			// 		backgroundColor: '#478a31'
			// 	}
			// }
			// MuiTableSortLabel : {
			// 	width: '1px',
			// 	height: '1px'
			// }
		}
	})
	manageProcessLoader = (isOperationFinished, isProcessLoaderOpen, isFinishedUpdate) => {
		this.setState({
			isOperationFinished: isOperationFinished,
			isProcessLoaderOpen: isProcessLoaderOpen,
			isFinishedUpdate: isFinishedUpdate
		})
	}

	InProgressOnClick = (event) => {
		const { onLoadPlanning, weekNumber, yearNumber } = this.props
		this.setState({ isLoading: true })
		this.setState({ showInProgress: !this.state.showInProgress })
		if (!this.state.showInProgress) {
			Publicationstracking.getInProgressPlublication(Number.parseInt(`${yearNumber.toString()}${weekNumber.toString()}`)).then((json) => {
				this.setState({
					inProgressData: json.data,
					count: json.data.length
				})
				this.buildPlanningList(json.data, onLoadPlanning)
			})
		} else {
			Publicationstracking.getPlanning(Number.parseInt(`${yearNumber.toString()}${weekNumber.toString()}`)).then((json) => {
				this.buildPlanningList(json.data, onLoadPlanning)
			})
		}
	}
	showMyContent = () => {
		this.setState({ showuseronly: !this.state.showuseronly })
	}

	onUpdateState() {
		const { onLoadPlanning,  weekNumber, yearNumber } = this.props
		const { config } = this.context
		console.log(config)
		this.setState({ updatestate: !this.state.updatestate })
		localStorage.setItem('counter', 0)

		if (timerId) clearTimeout(timerId)
			//this.myValue = 10
 			// counter = setInterval(() => console.log(counter), 10000)
			timerId = window.setTimeout(() => {
			// clearInterval(counter)
			this.setState({ showSave: true })
			if (this.state.showInProgress) {
				Publicationstracking.getInProgressPlublication(Number.parseInt(`${yearNumber.toString()}${weekNumber.toString()}`)).then((json) => {
					this.setState({
						inProgressData: json.data,
						count: json.data.length
					})
					load = false
					this.buildPlanningList(json.data, onLoadPlanning)
				})
			}
			else {
				load = false
				Publicationstracking.getPlanning(Number.parseInt(`${yearNumber.toString()}${weekNumber.toString()}`)).then((json) => {
					this.buildPlanningList(json.data, onLoadPlanning)
				})
			}
			timerId = 0
		},
			parseInt(config ? config.refreshCounter: 10000))
 	}
	handleCloseAlert = () => {
		const { updateTimerCount } = this.props
		this.setState({ showSave: false })
		updateTimerCount(false)
	}

	RefreshContent = () => {
		const { onLoadPlanning, weekNumber, yearNumber, updateTimerCount } = this.props
		window.clearTimeout(timerId)
		updateTimerCount(false)
		this.setState({ isLoading: true })
		if (this.state.showInProgress) {
			Publicationstracking.getInProgressPlublication(Number.parseInt(`${yearNumber.toString()}${weekNumber.toString()}`)).then((json) => {
				this.setState({
					inProgressData: json.data,
					count: json.data.length
				})
				this.buildPlanningList(json.data, onLoadPlanning)
			})
		}
		else {
			Publicationstracking.getPlanning(Number.parseInt(`${yearNumber.toString()}${weekNumber.toString()}`)).then((json) => {
				this.buildPlanningList(json.data, onLoadPlanning)
				this.setState({ count: json.data.length })
			})
		}

	}
	changePage(pageNumber) {
		this.setState({ pageNumber: pageNumber })
	}
	changeRowsPerPage(rowPerPage) {
		this.setState({ rowPerPage: rowPerPage })
	}

	render() {
		const { domainsItemsLoaded, planningData, loadingDomainsItems, translate, weekNumber, yearNumber, classes,
			 isUpdatePlanningTri, onLoadPlanning,  updatePrepStaus ,updateTimerCount,startCount} = this.props
		const { statusTypes, statusTypesPrep, statusTypesFront, trackingtypes } = this.state
		const weekFilter = Number.parseInt(`${yearNumber.toString()}${weekNumber.toString().length === 2 ? weekNumber.toString() : '0' + weekNumber.toString()}`)
 		if (domainsItemsLoaded === true || DomainsItems.exists()) {
			const dataFromRedux = planningData
			const validDataFromRedux = dataFromRedux.filter(item => item.week === weekFilter)
			if (domainsItemsLoaded === false) loadingDomainsItems(true)
			let data = this.state.data
			if (this.state.showInProgress) {
				data = convertDataToPreparationMaterialTableRow(planningData, trackingtypes, isUpdatePlanningTri)
			}
			else {
				data = convertDataToPreparationMaterialTableRow(validDataFromRedux, trackingtypes, isUpdatePlanningTri)
			}
			if (this.state.showuseronly)
				data = data.filter(s => s.userId === Token.getId())
			const distinctDates = getDistinctDate(data)
			const weekCategories = getDistinctItem(data, 'category', this.state.categories)
			const weektasktype = getDistinctItem(data, 'tasktype', this.state.tasktypes)
			const weekResponible = getDistinctItem(data, 'responsable', this.state.users)
			const weektaskGroup = getDistinctItem(data, 'taskGroup', this.state.taskGroup)
			//Filters
			let columns = [
				{
					name: 'id',
					label: ' ',
					options: {
						setCellHeaderProps: () => ({
							style: {
								fontWeight: 'bold', padding: '0px'
							}
						}),
						filter: false,
						search: false,
						customBodyRender: (value, tableMeta) => {
							if ((tableMeta.rowData[31] === 'updated') && tableMeta.rowData[33] !== 1) {
								const validationType = tableMeta.rowData[31] === 'updated' ? 'update' : 'delete'
								return <ValidateUpdate movieId={value} movieTitle={tableMeta.rowData[13]} validationType={validationType} />
							}
							else if ((tableMeta.rowData[31] === 'deleted') && tableMeta.rowData[33] !== 1) {
								const validationType = tableMeta.rowData[31] === 'updated' ? 'update' : 'delete'
								return <ValidateDelete movieId={value} movieTitle={tableMeta.rowData[13]} validationType={validationType} />
							}
							else {
								if (tableMeta.rowData[31] === 'new') {
									const validationType = 'new'
									return <ValidateUpdate validationType={validationType} />
								}
								else
									return <div></div>
							}
						}
					}
				},
				{
					name: 'userId',
					label: 'Responsable',
					options: {
						setCellHeaderProps: () => ({
							style: {
								backgroundColor: '#FFF',
								padding: '1px',
								position: 'sticky',
								top: '0',
								left: '0px',
								zIndex: 12,
								color: '#0000008a',
								fontWeight: 'bold',
								fontSize: '0.825em',
								borderBottom: '1px solid rgba(224, 224, 224, 1)',
							}
						}),
						setCellProps: () => ({
							style: {
								position: "sticky",
								top: '0',
								left: "0",
								//backgroundColor:  (row[31] === 'updated' && row[33] !== 1) ? yellow[100] :   (row[31] === 'deleted' ? red[200] : "#fff"   )  ,
								zIndex: 1,
								borderBottom: '1px solid rgba(224, 224, 224, 1)'
							}
						}),
						filter: true,
						filterType: 'custom',
						customFilterListOptions: {
							render: v => {
								let res = ''
								v.forEach(element => {
									const selectedUser = this.state.users.find(x => x.id === element)
									res += selectedUser !== undefined ? selectedUser.gsm + ', ' : ''
								});
								return `Responsable: ${res}`
							}
						},
						filterOptions: {
							logic: (user, filters) => {
								if (filters.length) return !filters.includes(user)
								return false
							},
							display: (filterList, onChange, index, column, filterData) => {
								let optionsValues = []
								weekResponible.forEach(item => {
									const optionValue = {
										value: item.id,
										label: item.gsm
									}
									optionsValues.push(optionValue)
								})
								const defaultLabel = 'Responsable'
								return <CustomFilter filterList={filterList} onChange={onChange} index={index} column={column}
									optionsValues={optionsValues} defaultLabel={defaultLabel} />
							}
						},
						customBodyRender: (value, tableMeta) => {
							const users = ContactsTools.getContactsByGroupid(160)
							const width = 60
							const user = users.find(u => u.id === value)
							return <UserCell user={user} users={users} planningId={tableMeta.rowData[0]} pageType='preparation' customWidth={width} />
						}

					}
				},
				{
					name: 'preparationStatus',
					label: 'STATUT PREPA',
					options: {
						setCellHeaderProps: () => ({ style: { fontWeight: 'bold', textalign: 'center', maxWidth: '40px', paddingLeft: '20px', zIndex: 1 } }),
						filter: false,
						customBodyRender: (value, tableMeta) => {
 							return (
								<StatusCell responsibleId={tableMeta.rowData[1]} planningId={tableMeta.rowData[0]}
									selectedStatus={value}
									statusTypes={statusTypes} trackingGroupItemId={1}
									changedList={this.state.publicationlist}
									statusName='Prepa'
									updatePrepStaus={updatePrepStaus}
								/>)
						},
						setCellProps: (value) => {
							const currentStatuses = statusTypes.filter(s =>  s.itemid === value.props.selectedStatus)
							if (currentStatuses[0] !== undefined) {
								const backgroundColor = currentStatuses[0].color
								return {
									className: classnames(
										{
											[this.props.classes.NameCell]: true
										}),
									style: {
										backgroundColor: backgroundColor,
									}
								}
							}
						}
					}
				},
				{
					name: 'frontStatus',
					label: 'STATUT FRONT',
					options: {
						setCellHeaderProps: () => ({ style: { fontWeight: 'bold', textalign: 'center', maxWidth: '40px', paddingLeft: '20px', zIndex: 1 } }),
						filter: false,
						customBodyRender: (value, tableMeta) => {
							return (
								<StatusCell responsibleId={tableMeta.rowData[1]} planningId={tableMeta.rowData[0]}
									selectedStatus={value}
									statusTypes={statusTypes} trackingGroupItemId={1}
									changedList={this.state.publicationlist}
									statusName='Front'
									updatePrepStaus={updatePrepStaus}
								/>)
						 
						},
						setCellProps: (value) => {
							const currentStatuses = statusTypes.filter(s =>  s.itemid === value.props.selectedStatus)
							if (currentStatuses[0] !== undefined) {
								const backgroundColor = currentStatuses[0].color
								return {
									className: classnames(
										{
											[this.props.classes.NameCell]: true
										}),
									style: {
										backgroundColor: backgroundColor,
									}
								}
							}
						}
					}
				},
				{
					name: 'koDetails',
					label: 'Détails des ko',
					options: {
						setCellHeaderProps: (row) => ({
							style: {
								fontWeight: 'bold',
								textalign: 'center',
								backgroundColor: '#FFF',
								padding: '1px',
								//paddingLeft: '20px',
								position: 'sticky',
								top: '0',
								left: '125px',
								zIndex: 15,
								color: '#0000008a',
								//background: row[31] !== 'updated' ? "white" :yellow[100],
							}
						}),
						setCellProps: () => ({
							style: {
								whiteSpace: "nowrap",								//position: "-webkit-sticky",
								position: "sticky",
								left: "120px",
								//backgroundColor: '#fff', // (row[31] === 'updated' && row[33] === 0) ? yellow[100] :   (row[31] === 'deleted' ? red[200] : '#fff'   )  ,
								zIndex: 10,
								opacity: 'inherit',
								borderBottom: '1px solid rgba(224, 224, 224, 1)'
							}
						}),
						filter: false,
						search: false,
						customBodyRender: (value, tableMeta) => {
							return <CommentsCell commentvalue={value} statusName='comments' responsibleId={tableMeta.rowData[1]} planningId={tableMeta.rowData[0]} />
						}
					}
				},
				{
					name: 'control',
					label: 'Control',
					options: {
						setCellHeaderProps: () => ({ style: { fontWeight: 'bold', zIndex: 0 } }),
						filter: false,
						search: false,
						display: 'excluded'
					}
				},
				{
					name: 'week',
					label: 'Semaine',
					options: {
						setCellHeaderProps: () => ({ style: { fontWeight: 'bold', zIndex: 0 } }),
						filter: false,
						search: false,
						display: 'excluded'
					}
				},
				{
					name: 'affair',
					label: 'Num Affaire',
					options: {
						setCellHeaderProps: () => ({ style: { fontWeight: 'bold', minWidth: '40px',  zIndex: 0 } }),
						filter: false,
					}
				},
				{
					name: 'pgm',
					label: 'N° Prog.',
					options: {
						setCellHeaderProps: () => ({ style: { fontWeight: 'bold', minWidth: '40px', zIndex: 0 } }),
						filter: false,
					}
				},
				{
					name: 'planningDate',
					label: 'Date',
					options: {
						setCellHeaderProps: () => ({ style: { fontWeight: 'bold', minWidth: '40px',  zIndex: 0 } }),
						filter: true,
						filterType: 'custom',
						customFilterListOptions: {
							render: v => {
								let res = ''
								if (v.length)
									v.forEach(element => {
										res += DateFormatter.getDayNameDate(element) + ', '
									});
								else
									res = DateFormatter.getDayNameDate(v)
								return `Date: ${res}`
							}
						},
						filterOptions: {
							logic: (date, filters) => {
								if (filters.length) return !filters.includes(date)
								return false
							},
							display: (filterList, onChange, index, column) => {
								let optionsValues = []
								distinctDates.forEach(item => {
									const optionValue = {
										value: item,
										label: DateFormatter.getDayNameDate(item)
									}
									optionsValues.push(optionValue)
								})
								const defaultLabel = 'Date'
								return <CustomFilter filterList={filterList} onChange={onChange} index={index} column={column}
									optionsValues={optionsValues} defaultLabel={defaultLabel} />
							}
						},
						customBodyRender: (value) => {
							const label = DateFormatter.getDayNameDate(value)
							const width = 110
							return <DateCell label={label} customWidth={width} />
						}
					}
				},
				{
					name: 'toBeConfirmed',
					label: 'To be confirmed',
					options: {
						setCellHeaderProps: () => ({ style: { fontWeight: 'bold', zIndex: 0 } }),
						filter: false,
						search: false,
						display: 'false'
					}
				},
				{
					name: 'prio',
					label: 'Prio',
					options: {
						setCellHeaderProps: () => ({
							style: {
								padding: '1px',
								//paddingLeft: '20px',
								position: 'sticky',
								top: '0',
								zIndex: '1',
								color: '#0000008a',
								fontWeight: 'bold',
								fontSize: '0.825em',
								backgroundColor: '#FFF',
								borderBottom: '1px solid rgba(224, 224, 224, 1)'
							}
						}),
						filter: false,
						search: false,
						customBodyRender: (value, tableMeta) => {
							const validationType = "prioUpdate"
							return <ReferentContent PRIO={value} movieId={tableMeta.rowData[0]} movieTitle={tableMeta.rowData[13]} validationType={validationType} enabled={true} />
						}
					}
				},
				{
					name: 'movieId',
					label: 'MID',
					options: {
						setCellHeaderProps: () => ({
							style: {
								fontWeight: 'bold',
								maxWidth: '65px',
								textalign: 'center',
								backgroundColor: '#FFF',
								padding: '1px',
								//paddingLeft: '30px',
								position: 'sticky',
								top: '0',
								left: '320px',
								zIndex: 15,
								color: '#0000008a',

							}
						}),
						setCellProps: (row) => ({
							style: {
								whiteSpace: "nowrap",
								// position: "-webkit-sticky",
								position: "sticky",
								left: "320px",
								zIndex: 10,
								opacity: 'inherit',
								borderBottom: '1px solid rgba(224, 224, 224, 1)'
							}
						}),
						filter: false,
					}
				},
				{
					name: 'movieTitle',
					label: 'Titre',
					options: {
						customFilterListOptions: {
							render: v => {
								return `Titre: ${v}`
							}
						},
						setCellHeaderProps: () => ({
							style: {
								fontWeight: 'bold',
								//position: "-webkit-sticky",
								left: "363px",
								paddingLeft: '15px',
								textalign: 'center',
								backgroundColor: '#fff',
								padding: '1px',
								position: 'sticky',
								top: '0',
								zIndex: 16,
								color: '#0000008a',
							}
						}),
						setCellProps: (row) => ({
							style: {
								//whiteSpace: "nowrap",
								//position: "-webkit-sticky",
								position: "sticky",
								//top:'50px',
								left: "363px",
								zIndex: 10,
								opacity: 'inherit',
								borderBottom: '1px solid rgba(224, 224, 224, 1)'
							}
						}),
						filter: true,
						filterType: 'textField',
						customBodyRender: (value, tableMeta) => {
							const width = 500
							return <MovieTitleCell label={value} customWidth={width} titleCommentText={tableMeta.rowData[tableMeta.columnIndex + 1]} titleCommentColor="red" />
						}
					}
				},
				{
					name: 'movieTitleComment',
					label: 'Commentaire des titres',
					options: {
						setCellHeaderProps: () => ({ style: { fontWeight: 'bold', zIndex: 0 } }),
						filter: false,
						search: false,
						display: 'excluded'
					}
				},
				{
					name: 'categoryId',
					label: 'Cat.',
					options: {
						setCellHeaderProps: () => ({ style: { fontWeight: 'bold', paddingLeft: '15px', zIndex: 0 } }),
						filter: true,
						filterType: 'custom',
						customFilterListOptions: {
							render: v => {
								let res = ''
								v.forEach(element => {
									const selectedCategory = this.state.categories.find(x => x.itemid === element)
									res += selectedCategory !== undefined ? selectedCategory.label + ', ' : ''
								});
								return `Catégorie: ${res}`
							}
						},
						filterOptions: {
							logic: (category, filters) => {
								if (filters.length) return !filters.includes(category)
								return false
							},
							display: (filterList, onChange, index, column) => {
								let optionsValues = []
								weekCategories.forEach(item => {
									const optionValue = {
										value: item.itemid,
										label: item.label
									}
									optionsValues.push(optionValue)
								})
								const defaultLabel = 'Catégorie'
								return <CustomFilter filterList={filterList} onChange={onChange} index={index} column={column}
									optionsValues={optionsValues} defaultLabel={defaultLabel} />
							}
						},
						customBodyRender: (value) => {
							const type = 'category'
							const width = 80
							return <ReferenceDataCell referencedataid={value} type={type} customWidth={width} />
						},
						setCellProps: (value) => {
							const { referencedataid } = value.props
							const backgroundColor = PublicationTrackingReferenceData.getCellInformation(referencedataid, 'category').color
							return {
								className: classnames(
									{
										[this.props.classes.NameCell]: true
									}),
								style: {
									backgroundColor: backgroundColor
								}
							}
						}
					}
				},
				{
					name: 'ayd',
					label: 'AYD',
					options: {
						filterType: 'multiselect',
						customFilterListOptions: {
							render: v => {
								return `AYD: ${v}`
							}
						},
						setCellHeaderProps: () => ({ style: { fontWeight: 'bold',  zIndex: 0 } }),
						filter: true,
					}
				},
				{
					name: 'taskTypeId',
					label: 'Accès',
					options: {
						setCellHeaderProps: () => ({ style: { fontWeight: 'bold', textAlign: 'center',  zIndex: '0' } }),
						filter: true,
						filterType: 'custom',
						customFilterListOptions: {
							render: v => {
								let res = ''
								v.forEach(element => {
									const selectedTaskType = this.state.tasktypes.find(x => x.itemid === element)
									res += selectedTaskType !== undefined ? selectedTaskType.label + ', ' : ''
								});
								return `Accès: ${res}`
							}
						},
						filterOptions: {
							logic: (tasktype, filters) => {
								if (filters.length) return !filters.includes(tasktype)
								return false
							},
							display: (filterList, onChange, index, column) => {
								let optionsValues = []
								weektasktype.forEach(item => {
									const optionValue = {
										value: item.itemid,
										label: item.label
									}
									optionsValues.push(optionValue)
								})
								const defaultLabel = 'Accès'
								return <CustomFilter filterList={filterList} onChange={onChange} index={index} column={column}
									optionsValues={optionsValues} defaultLabel={defaultLabel} />
							}
						},
						customBodyRender: (value, tableMeta) => {
							const type = 'taskType'
							const width = 120
							return <ReferenceDataCell referencedataid={value} type={type} customWidth={width} seasonRow={tableMeta.rowData[32]} />
						},
						setCellProps: (value) => {
							const { referencedataid } = value.props
							const backgroundColor = PublicationTrackingReferenceData.getCellInformation(referencedataid, 'taskType').color
							return {
								className: classnames(
									{
										[this.props.classes.NameCell]: true
									}),
								style: {
									backgroundColor: backgroundColor
								}
							}
						}
					}
				},
				{
					name: 'versionId',
					label: 'Vers.',
					options: {
						setCellHeaderProps: () => ({ style: { fontWeight: 'bold', textAlign: 'center', width: '50px', zIndex: '0' } }),
						filter: true,
						filterType: 'custom',
						customFilterListOptions: {
							render: v => {
								let res = ''
								v.forEach(element => {
									const selectedVersion = this.state.versions.find(x => x.itemid === element)
									res += selectedVersion !== undefined ? selectedVersion.label + ', ' : ''
								});
								return `Version: ${res}`
							}
						},
						filterOptions: {
							logic: (version, filters) => {
								if (filters.length) return !filters.includes(version)
								return false
							},
		 
						},
						customBodyRender: (value) => {
							const type = 'version'
							const width = 50
							return <ReferenceDataCell referencedataid={value} type={type} customWidth={width} />
						},
						setCellProps: (value) => {
							const { referencedataid } = value.props
							const backgroundColor = PublicationTrackingReferenceData.getCellInformation(referencedataid, 'version').color
							return {
								className: classnames(
									{
										[this.props.classes.NameCell]: true
									}),
								style: {
									backgroundColor: backgroundColor
								}
							}
						}
					}
				},
				{
					name: 'formatId',
					label: 'Format',
					options: {
						setCellHeaderProps: () => ({ style: { fontWeight: 'bold', textAlign: 'center', maxWidth: '45px', zIndex: '0' } }),
						filter: false,
						customBodyRender: (value) => {
							const type = 'format'
							const width = 40
							return <ReferenceDataCell referencedataid={value} type={type} customWidth={width} />
						},
						setCellProps: (value) => {
							const { referencedataid } = value.props
							const backgroundColor = PublicationTrackingReferenceData.getCellInformation(referencedataid, 'format').color
							return {
								className: classnames(
									{
										[this.props.classes.NameCell]: true
									}),
								style: {
									backgroundColor: backgroundColor
								}
							}
						}
					}
				},
				{
					name: 'bk',
					label: 'B&k',
					options: {
						setCellHeaderProps: () => ({ style: { fontWeight: 'bold' } }),
						filter: false,
						search: false,
						display: 'excluded'
					}
				},
				{
					name: 'tvodInSvod',
					label: 'DTV/ NTR',
					options: {
						setCellHeaderProps: () => ({ style: { fontWeight: 'bold', minWidth: '60px', zIndex: '0' } }),
						filter: false,
						search: false,
						//display: 'false'
					}
				},
				{
					name: 'vodDateShifted',
					label: 'Date Vod decalée',
					options: {
						setCellHeaderProps: () => ({ style: { fontWeight: 'bold', minWidth: '80px',zIndex: '0' } }),
						filter: false,
						search: false,
						//display: 'false'
						customBodyRender: (value, tableMeta) => {
							return value;
						}
					}
				},
				{
					name: 'sdLocationPrice',
					label: 'LOC SD',
					options: {
						setCellHeaderProps: () => ({ style: { fontWeight: 'bold', zIndex: '0' } }),
						filter: false,
						customBodyRender: (value, tableMeta) => {
							const width = 60
							return <PriceCell label={value} comments={tableMeta.rowData[34]} customWidth={width} />
						}
					}
				},
				{
					name: 'hdUhdLocationPrice',
					label: 'LOC HD/4K',
					options: {
						setCellHeaderProps: () => ({ style: { fontWeight: 'bold', zIndex: '0' } }),
						filter: false,
						customBodyRender: (value, tableMeta) => {
							const width = 60
							return <PriceCell label={value} comments={tableMeta.rowData[35]} customWidth={width} />
						}
					}
				},
				{
					name: 'sdEstPrice',
					label: 'EST SD',
					options: {
						setCellHeaderProps: () => ({ style: { fontWeight: 'bold', zIndex: '0' } }),
						filter: false,
						customBodyRender: (value, tableMeta) => {
							const width = 60
							return <PriceCell label={value} comments={tableMeta.rowData[36]} customWidth={width} />
						}
					}
				},
				{
					name: 'estHdUhdPrice',
					label: 'EST HD/4K',
					options: {
						setCellHeaderProps: () => ({ style: { fontWeight: 'bold', zIndex: '0' } }),
						filter: false,
						customBodyRender: (value, tableMeta) => {
							const width = 60
							return <PriceCell label={value} comments={tableMeta.rowData[37]} customWidth={width} />
						}
					}
				},
				{
					name: 'bkDvdPrice',
					label: 'B&K DVD',
					options: {
						setCellHeaderProps: () => ({ style: { fontWeight: 'bold', zIndex: 0 } }),
						filter: false,
						customBodyRender: (value, tableMeta) => {
							const width = 30
							return <PriceCell label={value} comments={tableMeta.rowData[38]} customWidth={width} />
						},
						display: 'excluded'
					}
				},
				{
					name: 'bkBrPrice',
					label: 'B&K BR',
					options: {
						setCellHeaderProps: () => ({ style: { fontWeight: 'bold', zIndex: 0 } }),
						filter: false,
						customBodyRender: (value, tableMeta) => {
							const width = 30
							return <PriceCell label={value} comments={tableMeta.rowData[39]} customWidth={width} />
						},
						display: 'excluded'
					}
				},
				{
					name: 'eanDvd',
					label: 'EAN DVD',
					options: {
						setCellHeaderProps: () => ({ style: { fontWeight: 'bold', zIndex: 0 } }),
						filter: false,
						display: 'excluded'
					}
				},
				{
					name: 'eanBr',
					label: 'EAN BR',
					options: {
						setCellHeaderProps: () => ({ style: { fontWeight: 'bold', zIndex: 0 } }),
						filter: false,
						display: 'excluded'
					}
				},
				{
					name: 'rowStatus',
					label: 'Commentaire des titres',
					options: {
						filter: false,
						search: false,
						display: 'excluded'
					}
				},
				{
					name: 'isSeasonRow',
					label: 'Commentaire des titres',
					options: {
						filter: false,
						search: false,
						display: 'excluded'
					}
				},
				{
					name: 'isUpdateApproved',
					label: 'Commentaire des titres',
					options: {
						filter: false,
						search: false,
						display: 'excluded'
					}
				},
				{
					name: 'locSdPriceComments',
					label: 'Commentaire des titres',
					options: {
						filter: false,
						search: false,
						display: 'excluded'
					}
				},
				{
					name: 'locHdUhdPriceComments',
					label: 'Commentaire des titres',
					options: {
						filter: false,
						search: false,
						display: 'excluded'
					}
				},
				{
					name: 'sdEstPriceComments',
					label: 'Commentaire des titres',
					options: {
						filter: false,
						search: false,
						display: 'excluded'
					}
				},
				{
					name: 'uhdEstPriceComments',
					label: 'Commentaire des titres',
					options: {
						filter: false,
						search: false,
						display: 'excluded'
					}
				},
				{
					name: 'bkDvdPriceComments',
					label: 'Commentaire des titres',
					options: {
						filter: false,
						search: false,
						display: 'excluded'
					}
				},
				{
					name: 'bkBrPriceComments',
					label: 'Commentaire des titres',
					options: {
						filter: false,
						search: false,
						display: 'excluded'
					}
				},
				{
					name: 'taskGroupId',
					label: "Groupe d'accès",
					options: {
						setCellHeaderProps: () => ({ style: { fontWeight: 'bold', textAlign: 'center', zIndex: '0' } }),
						filter: true,
						filterType: 'custom',
						customFilterListOptions: {
							render: v => {
								let res = ''
								v.forEach(element => {
									const selectedTaskGroup = weektaskGroup.find(x => x.itemid === element)
									res += selectedTaskGroup !== undefined ? selectedTaskGroup.label + ', ' : ''
								});
								return `Groupe d'accès: ${res}`
							}
						},
						filterOptions: {
							logic: (taskGroupe, filters) => {
								if (filters.length) return !filters.includes(taskGroupe)
								return false
							},
							display: (filterList, onChange, index, column) => {
								let optionsValues = []
								weektaskGroup.forEach(item => {
									const optionValue = {
										value: item.itemid,
										label: item.label
									}
									optionsValues.push(optionValue)
								})
								const defaultLabel = "Groupe d'accès"
								return <CustomFilter filterList={filterList} onChange={onChange} index={index} column={column}
									optionsValues={optionsValues} defaultLabel={defaultLabel} />
							}
						},
						display: 'none'
					}
				},

			]
			if (trackingtypes && trackingtypes.find(s => !s.disabled)) {
				trackingtypes.filter(s => !s.disabled).sort((a, b) => (a.order > b.order) ? 1 : -1).forEach(el => {
					const col = {
						name: el.label,
						options: {
							setCellHeaderProps: () => ({ style: { fontWeight: 'bold', backgroundColor: el.color, paddingLeft: '10px', zIndex: 1 } }),
							filter: false,
							search: false,
							customBodyRender: (value, tableMeta) => {
								if (data) {
									let trackingvalues = data.filter(item => item.id === tableMeta.rowData[0])[0].trackingvalue
									value = trackingvalues  && trackingvalues.filter(type => type.trackingTypeId === el.itemid)[0]  ?
										trackingvalues.filter(type => type.trackingTypeId === el.itemid)[0].stateid : 0
								}
								return <StatusCell  updateTimerCount={updateTimerCount} startCount ={startCount}
									responsibleId={tableMeta.rowData[1]} planningId={tableMeta.rowData[0]} selectedStatus={value}
									statusTypes={el.trackingGroupDomainId === 1 ? statusTypesPrep : statusTypesFront} onLoadPlanning={onLoadPlanning}
									trackingGroupItemId={el.trackingGroupId === 1 ? 2 : 3} statusName={el.itemid} domainitemPrep={trackingtypes}
									updatestate={() => this.onUpdateState()
								}
								/>
							},
							setCellProps: (value) => {
								return {
									className: classnames(
										{
											[this.props.classes.NameCell]: true
										}),
								}
							},
						}
					}
					columns.push(col)
				})
			}


			const options = {
				filter: true,
				filterType: 'dropdown',
				//responsive: 'scroll',
				sort: false,
				responsive: 'scrollMaxHeight',
				fixedHeaderOptions: {
					xAxis: true,
					yAxis: true,
				},
				rowsPerPage: this.state.rowPerPage !== 0 ? this.state.rowPerPage : 50,
				rowHover: false,
				textLabels: {
					body: {
						noMatch: (this.state.data && this.state.data.length !== 0) ? <LinearProgress /> : 'Aucune ligne trouvée!',
						//toolTip: 'Trier'
					},
					filter: {
						all: 'Toutes',
						title: 'FILTRES',
						reset: 'REINITIALISER',
					},
					selectedRows: {
						text: 'rows has been deleted',
						delete: 'Delete Row',
						deleteAria: 'Deleted Selected Rows'
					},
					toolbar: {
						search: 'Rechercher',
						downloadCsv: 'Télécharger CSV',
						print: 'Imprimer',
						viewColumns: 'Voir colonnes',
						filterTable: 'Filtrer',
						//{this.state.showuseronly ? <div><br/> <Chip style={{margin : '8px 8px 0px 0px'}} label={ 'Responsable: ' + (this.state.users.find(s=>s.id === token.getId) ?this.state.users.find(s=>s.id === token.getId).gsm : '') }></Chip> </div> : <div/>}

					},
					viewColumns: {
						title: 'Montrer colonnes',
						titleAria: 'Montrer/Cacher colonnes',
					},
				},
				setRowProps: (row) => {
					return {
						className: classnames(
							{
								[classes.UpdatedRow]: row[31] === 'updated' && row[33] !== 1,
								[classes.DeletedRow]: row[31] === 'deleted',
								[classes.SeasonRow]: row[32] === true,
								//[classes.defaultRow]: row[30] === ""
							}),
						style: { border: '1px solid white', }
					}
				},
				setTableProps: () => {
					return {
						// padding: this.state.denseTable ? "none" : "default",
						// size: this.state.denseTable ? "small" : "medium",
						padding: 'normal',
						size: 'small',
					}
				},
				customToolbar: () => {
					return (
						<>
							<Tooltip title={this.state.showuseronly ? 'Afficher tous' : 'Mes contenus'}>
								<Button onClick={() => this.showMyContent()} style={{ padding: '0px', borderRadius: '50%', minHeight: '45px', minWidth: '45px' }} >
									<TocIcon style={{ color: 'rgba(0, 0, 0, 0.54)', fontSize: '1.4rem' }} />
								</Button>
							</Tooltip>
							<Tooltip title={'Actualiser'}>
								<Button onClick={() => this.RefreshContent()} style={{ padding: '0px', borderRadius: '50%', minHeight: '45px', minWidth: '45px' }} >
									<RefreshIcon style={{ color: 'rgba(0, 0, 0, 0.54)', fontSize: '1.4rem' }} />
								</Button>
							</Tooltip>
							<CustomSort />
						</>
					)
				},
				selectableRows: 'none',
				//selectableRowsOnClick: true,
				resizableColumns: false,
				//stickyHeader : true,
				rowDragManaged: true,
				print: false,
				download: false,
				// onRowClick: (event, rowData) => {
				// 	// this.changeStatus(event ? event[41].props.changedList: [])
				// },
				customFooter: (count, page, rowsPerPage, changeRowsPerPage, changePage, textLabels) => {
					textLabels.rowsPerPage = 'Lignes par page'
					this.setState({ rowPerPage: rowsPerPage })
					this.setState({ pageNumber: page })
					this.setState({ count: count })
					return (
						<CustomFooter
							count={count}
							page={page}
							rowsPerPage={rowsPerPage}
							changeRowsPerPage={this.changeRowsPerPage(rowsPerPage), changeRowsPerPage}
							changePage={this.changePage(page), changePage}
							textLabels={textLabels} />
						// <CustomFooter
						// 	count={count}
						// 	page={page}
						// 	rowsPerPage={rowsPerPage}
						// 	changeRowsPerPage={changeRowsPerPage}
						// 	changePage={changePage}
						// 	textLabels={textLabels} />
					)
				}
			}
			return (
				<React.Fragment>
					<div className={classes.root}>
						<div className={classes.publicationPlanningHeader} >
							<div hidden={this.state.showInProgress}><WeekSelector /></div>
							<ThemeProvider theme={this.getMuiTheme()}>
								<TrackingsType roles={this.state.roles} weekFilter={weekFilter}
									groups={this.state.rowsgroup} types={this.state.trackingtypes} />
							</ThemeProvider>

							<PlanningLauncher />
							<Button onClick={this.InProgressOnClick} component={Link} color="primary" variant="contained" className={classes.button} to={Links.PublicationPreparationLink()}>
								{this.state.showInProgress ? "Preparation" : "En Cours"}
								<EditIcon className={classes.rightIcon} />
							</Button>
  
						</div>
						<div>
							<ThemeProvider theme={this.getMuiTheme()}>
								{this.state.showSave ? (<CustomAlert libelle="Mise à jour des données ..." severity="success" open={this.state.showSave} setOpen={this.handleCloseAlert} />) : null}
								<MUIDataTable className='MUIDataTable' title={!this.state.showInProgress ?
									<div style={{ marginTop: '20px' }}  >
										{translate('titles.publicationPreparation')}
										<br />
										{this.state.showuseronly ? <Chip label={'Responsable:' + (this.state.users.filter(s => s.id.toString() === Token.getId().toString())[0] ? this.state.users.filter(s => s.id.toString() === Token.getId().toString())[0].gsm : '')} style={{ marginTop: '20px', fontSize: '0.7rem' }} /> : <div />}

									</div>
									:
									<div style={{ marginTop: '20px' }} >
										Preparations en cours
										<br />
										{this.state.showuseronly ? <Chip label={'Responsable:' + (this.state.users.filter(s => s.id.toString() === Token.getId().toString())[0] ? this.state.users.filter(s => s.id.toString() === Token.getId().toString())[0].gsm : '')} style={{ marginTop: '20px', fontSize: '0.7rem' }} /> : <div />}
									</div>
								}
									circularProgress={true}

									data={data} columns={columns} options={options} />
								{ (this.state.isLoading  ) && <div className={classes.circularProgress}><CircularProgress size={50} thickness={3} value={25} disableShrink  /></div>}

							</ThemeProvider>
						</div>
					</div>
				</React.Fragment>
			)
		}
		else {
			return <div> <LinearProgress /> CHARGEMENT DU PLANNING...</div>
		}
	}
}

const mapStateToProps = state => ({
	...state,
	publicationsTrackingData: state.admin.resources['PublicationsTracking/Planning']
		? state.admin.resources['PublicationsTracking/Planning'].data
		: {},
	domainsItemsLoaded: state.reducer.Home.domainsItemsLoaded ? state.reducer.Home.domainsItemsLoaded : false,
	weekNumber: state.reducer.publicationTracking.weekNumber !== null ? state.reducer.publicationTracking.weekNumber : 0,
	yearNumber: state.reducer.publicationTracking.yearNumber !== null ? state.reducer.publicationTracking.yearNumber : 0,
	planningData: state.reducer.publicationTracking.planningData !== null ? state.reducer.publicationTracking.planningData : [],
	isUpdatePlanningTri: state.reducer.publicationTracking.isUpdatePlanningTri,
	prepStatus: state.reducer.publicationTracking.prepStatus !== null ? state.reducer.publicationTracking.prepStatus : [],
	startCount: state.reducer.publicationTracking.startCount !== null ? state.reducer.publicationTracking.startCount : false,

})

const mapDispatchToProps = ({
	loadingDomainsItems,
	onChangeWeek,
	onChangeYear,
	onLoadPlanning,
	updatePrioPlanningIds,
	updatePrepStaus,
	updateTimerCount
})

const enhance = compose(
	connect(mapStateToProps, mapDispatchToProps
		, null, {
		areStatesEqual: (prev, next) =>
			next.reducer.publicationTracking.planningData === prev.reducer.publicationTracking.planningData
		//|| (next.reducer.publicationTracking.prepStatus=== [] && next.reducer.publicationTracking.prepStatus === prev.reducer.publicationTracking.prepStatus)
	}),
	withStyles(styles)
)

export default translate(enhance(PreparationBoard))