/* eslint-disable no-mixed-spaces-and-tabs */
import React from 'react'
import Dialog from '@material-ui/core/Dialog'
import Slide from '@material-ui/core/Slide'
import PropTypes from 'prop-types'
import cyan from '@material-ui/core/colors/cyan'
import { translate, showNotification, fetchEnd, fetchStart } from 'react-admin'
import { submit, initialize } from 'redux-form'
import { connect } from 'react-redux'
import compose from 'recompose/compose'
import { Publicationstracking } from '../../../../requests'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableRow from '@material-ui/core/TableRow'
import Paper from '@material-ui/core/Paper'
import { onLoadPlanning, onChangeUser, onChangeGroup } from '../../../../actions/publicationTracking'
import TablePagination from '@material-ui/core/TablePagination'
import EnhancedTableHead from '../preparation/EnhancedTableHead'
import { CloseIcon } from '../../../../constants/icons'
import Button from '@material-ui/core/Button'
import Brightness1Icon from '@material-ui/icons/Brightness1'
import AddIcon from '@material-ui/icons/Add'
import AddTrackingTypeModal from '../typepreparation/AddTrackingTypeModal'
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown'
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp'
import IconButton from '@material-ui/core/IconButton'
import Switch from '@material-ui/core/Switch'
import Collapse from '@material-ui/core/Collapse'
import TableHead from '@material-ui/core/TableHead'
import { Links } from '../../../../tools'
import { withStyles } from '@material-ui/core/styles'

const Transition = React.forwardRef(function Transition(props, ref) {
	return <Slide direction="up" ref={ref} {...props} />
})

const styles = theme => ({
	button: {
		margin: theme.spacing(1),
		width: '150px'
	},
	dialogGlobal: {
		background: theme.palette.background.default,//'#353333',
		maxHeight: '510px',
		overflow: 'overlay'
	},
	dialogHeader: {
		padding: '10px 10px 10px',
		background: cyan[500],
		color: 'white',
		fontSize: 'medium',
		fontWeight: 'bold',
		textAlign: 'center',
		//height: '36px'
	},
	closeIcon: {
		color: 'white',
		position: 'absolute',
		width: '15px',
		top: '0px',
		right: '0px',
		borderRadius: '0',
		'&:hover': {
			background: 'red'
		}
	},
	dialogContent: {
		padding: '16px 24px 17px',
		flex: '1 1 auto',
		color: 'white',
		fontSize: '1rem',
		fontWeight: '400',
	},
	formControl: {
		margin: theme.spacing(1),
		minWidth: 120,
	},
	selectEmpty: {
		marginTop: theme.spacing(2),
	},
	customSelect: {
		width: '80%'
	},
	table: {
		width: '600px'
	},
	tabCell: {
		disablePadding: true,
		textAlign: 'left',
		fontWeight: 'bolder',
		padding: '0px'
	},
	paper: {
		width: '100%',
		marginBottom: theme.spacing(2)
	},

	visuallyHidden: {
		border: 0,
		clip: 'rect(0 0 0 0)',
		height: 1,
		margin: -1,
		overflow: 'hidden',
		padding: 0,
		position: 'absolute',
		top: 20,
		width: 1,
	},
	paperWidthSm: {
		maxWidth: '800px',
	},


})

class TrackingTypeModal extends React.Component {
	constructor(props) {
		super(props)
		this.state = {
			open: false,
			enabled: true,
			stories: [],
			order: 'asc',
			orderBy: 'ordre',
			selected: [],
			page: 0,
			dense: true,
			rowsPerPage: 5,
			rows: [],
			rowsgroup: [],
			typeOpen: false,
			isOperationFinished: false,
			resultText: '',
			isupdate: true,
			openRow: false,
			activateRow: true,
			switchState: [],
			itemToadd: ''
		}
	}

	componentDidMount() {
		const { modalOpen, groups } = this.props
		this.setState({
			open: modalOpen,
			rowsgroup: groups
		})
	}


	handleclose = () => {
		this.setState({
			typeOpen: false
		})
		Links.PublicationPreparationLink()
	}
	//componentDidUpdate(previousProps, previousState) {
	// Publicationstracking.getPublicationstrackingtypes().then((json)=>{
	// 	this.setState({	rows : json.data})
	// })
	// Publicationstracking.getPublicationstrackingGroupetypes().then((json)=>{
	// 	this.setState({	rowsgroup : json.data})
	// })
	//}

	handleRequestSort = (event, property) => {
		const isAsc = this.state.orderBy === property && this.state.order === 'asc'
		this.setState({
			order: isAsc ? 'desc' : 'asc',
			orderBy: property
		})

	};

	handleSelectAllClick = (event) => {
		if (event.target.checked) {
			const newSelecteds = this.state.rowsgroup.map((n) => n.id)
			this.setState({
				selected: newSelecteds
			})
			return
		}
		this.setState({
			selected: []
		})
	};

	handleClick = (event, name) => {
		const selectedIndex = this.state.selected.indexOf(name)
		let newSelected = []
		if (selectedIndex === -1) {
			newSelected = newSelected.concat(this.state.selected, name)
		} else if (selectedIndex === 0) {
			newSelected = newSelected.concat(this.state.selected.slice(1))
		} else if (selectedIndex === this.state.selected.length - 1) {
			newSelected = newSelected.concat(this.state.selected.slice(0, -1))
		} else if (selectedIndex > 0) {
			newSelected = newSelected.concat(
				this.state.selected.slice(0, selectedIndex),
				this.state.selected.slice(selectedIndex + 1),
			)
		}
		this.setState({
			selected: newSelected
		})
	};

	handleChangePage = (event, newPage) => {
		this.setState({
			page: newPage
		})
	};

	handleChangeRowsPerPage = (event) => {
		this.setState({
			page: 0,
			rowsPerPage: parseInt(event.target.value, 10)
		})
	};



	isSelected = (name) => this.state.selected.indexOf(name) !== -1;


	descendingComparator = (a, b, orderBy) => {
		if (b[orderBy] < a[orderBy]) {
			return -1
		}
		if (b[orderBy] > a[orderBy]) {
			return 1
		}
		return 0
	}

	getComparator = (order, orderBy) => {
		return order === 'desc'
			? (a, b) => this.descendingComparator(a, b, orderBy)
			: (a, b) => -(this.descendingComparator(a, b, orderBy))
	}

	stableSort = (array, comparator) => {
		const stabilizedThis = array.map((el, index) => [el, index])
		stabilizedThis.sort((a, b) => {
			const order = comparator(a[0], b[0])
			if (order !== 0) return order
			return a[1] - b[1]
		})
		return stabilizedThis.map((el) => el[0])
	}

	manageProcessLoader = (isOperationFinished, typeOpen, resultText) => {
		this.setState({
			isOperationFinished: isOperationFinished,
			resultText: resultText,
			isupdate: true
		})
	}

	handleAddTypeOpen = () => {
		this.setState({
			typeOpen: true,
			itemToadd: 'type'
		})
	}
	handleAddGroupOpen = () => {
		this.setState({
			typeOpen: true,
			itemToadd: 'group'
		})
	}
	handleDesactivateTypeOpen = () => {
		Publicationstracking.saveTrackingTypes(this.state.selected, 'delete')
	}

	initializeSelection = () => {
		this.setState({
			selected: []
		})

	}

	initializePlanningData = () => {
		//const { weekNumber, yearNumber, onLoadPlanning, groups } = this.props
		Publicationstracking.getPublicationstrackingtypes().then((json) => {
			this.setState({ rows: json.data })
		})
		Publicationstracking.getPublicationstrackingGroupetypes().then((json) => {
			this.setState({ rowsgroup: json.data })
		})
	}
	setOpen = () => {
		this.setState({
			openRow: !this.state.openRow
		})
	}
	handleDesactivate = () => {
		this.setState({
			activateRow: !this.state.activateRow
		})
	}
	handleChange = (row, checked, switchtype) => {
		row.desactivated = !checked
		if (switchtype === 'type') {
			Publicationstracking.desactivateTrackingType(row.id)
		}
		else {
			Publicationstracking.desactivateTrackingGroup(row.id)
		}
	}

	render() {
		//const emptyRows = this.state.rowsPerPage - Math.min(this.state.rowsPerPage, this.state.rows.length - this.state.page * this.state.rowsPerPage)
		const { classes, onClose, modalOpen, translate, groups, types } = this.props
		const modalTitle = 'Paramétrage des Colonnes'
		const progressModalContent = {
			title: 'Affectation en cours',
			closeButtonText: translate('ra.action.close'),
			resultText: this.state.resultText
		}
		return (
			<div>
				<Dialog
					open={modalOpen}
					TransitionComponent={Transition}
					keepMounted
					onClose={onClose}
					aria-labelledby="alert-dialog-slide-title"
					aria-describedby="alert-dialog-slide-description"
					classes={{
						paperWidthSm: classes.paperWidthSm
					}}
				>
					<div className={classes.dialogHeader}>
						{modalTitle.toUpperCase()}
						<Button onClick={onClose} className={classes.closeIcon}  >
							<CloseIcon />
						</Button>
					</div>
					<div className={classes.dialogGlobal}>
						<div className={classes.dialogContent}>
							<Paper className={classes.paper}>
								<Button onClick={this.handleAddGroupOpen} color="primary" variant="contained" className={classes.importButton}>
									Ajouter Groupe <AddIcon className={classes.rightIcon} />
								</Button>
								<Button onClick={this.handleAddTypeOpen} color="primary" variant="contained" className={classes.importButton}>
									Ajouter Type<AddIcon className={classes.rightIcon} />
								</Button>

								<Table
									className={classes.table}
									aria-labelledby="tableTitle"
									size={this.state.dense ? 'small' : 'medium'}
									aria-label="enhanced table"
								>
									<EnhancedTableHead
										classes={classes}
										order={this.state.order}
										orderBy={this.state.orderBy}
										onRequestSort={this.handleRequestSort}
										rowCount={groups.length}
										type={'trackingtype'}
									/>
									<TableBody>
										{this.stableSort(groups, this.getComparator(this.state.order, this.state.orderBy))
											.slice(this.state.page * this.state.rowsPerPage, this.state.page * this.state.rowsPerPage + this.state.rowsPerPage)
											.map((grouprow) => {
												const isItemSelected = this.isSelected(grouprow.id)
												//const labelId = `enhanced-table-checkbox-${grouprow.id}`
												return (
													<React.Fragment>
														<TableRow className={classes.root}
															onClick={(event) => this.handleClick(event, grouprow.id)}
															key={grouprow.id}
														>
															<TableCell style={{ padding: '4px 10px 4px 10px', width: '20px' }} >
																<IconButton aria-label="expand row" size="small" onClick={() => this.setOpen(grouprow.id)}>
																	{this.state.openRow ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
																</IconButton>
															</TableCell>
															<TableCell className={classes.tabCell} style={{ textAlign: 'center' }}>{grouprow.ordre} </TableCell>
															<TableCell className={classes.tabCell}>{grouprow.groupName} </TableCell>
															<TableCell className={classes.tabCell}><Brightness1Icon style={{ color: grouprow.color }}></Brightness1Icon></TableCell>
															<TableCell className={classes.tabCell}>
																<Switch
																	key={grouprow.id}
																	onChange={(checkedrow) => this.handleChange(grouprow, checkedrow, 'group')}
																	value={grouprow.desactivated}
																	checked={!grouprow.desactivated}
																	color="secondary"
																	defaultChecked
																/>
															</TableCell>
														</TableRow>

														<TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
															<Collapse in={isItemSelected} timeout="auto" unmountOnExit>
																<Table size="small" aria-label="purchases">
																	<TableHead>
																		<TableRow>
																			<TableCell></TableCell>
																			<TableCell style={{ textAlign: 'left' }}>Libellé colonnes</TableCell>
																			<TableCell>Active</TableCell>
																		</TableRow>
																	</TableHead>
																	<TableBody>
																		{(types !== undefined && types.filter(r => r.trackingGroupId === grouprow.id) !== undefined) ?
																			types.filter(r => r.trackingGroupId === grouprow.id).map((typeRow) => (
																				<TableRow key={typeRow.trackingGroupId}>
																					<TableCell></TableCell>
																					<TableCell style={{ textAlign: 'left' }}>{typeRow.name}</TableCell>
																					<TableCell>
																						<Switch

																							key={typeRow.id}
																							onChange={(event, checked) => this.handleChange(typeRow, checked, 'type', event)}
																							value={typeRow.desactivated}
																							checked={!typeRow.desactivated}
																							color="primary"
																							defaultChecked
																							disabled={grouprow.desactivated}
																						/>
																					</TableCell>
																				</TableRow>
																			)) : ''}
																	</TableBody>
																</Table>
															</Collapse>
														</TableCell>
													</React.Fragment>
												)
											})}
									</TableBody>
								</Table>
								<TablePagination
									rowsPerPageOptions={[5, 10, 20]}
									component="div"
									count={groups.length}
									rowsPerPage={this.state.rowsPerPage}
									page={this.state.page}
									onPageChange={this.handleChangePage}
									onRowsPerPageChange={this.handleChangeRowsPerPage}
								/>
							</Paper>
						</div>
					</div>
					<AddTrackingTypeModal {...this.props}
						itemToadd={this.state.itemToadd}
						modalOpen={this.state.typeOpen}
						onClose={this.handleclose}
						group={groups}
						progressModalContent={progressModalContent}
					/>
				</Dialog>

			</div>

		)
	}
}

TrackingTypeModal.propTypes = {
	modalOpen: PropTypes.bool,
	onClose: PropTypes.func,
	modalContent: PropTypes.object,
	launchActivationProcess: PropTypes.func,
	crudGetList: PropTypes.func,
	//yearNumber: PropTypes.number,
	//weekNumber: PropTypes.number,
	classes: PropTypes.object.isRequired,
	groups: PropTypes.array
}

const mapStateToProps = state => ({
	...state,
	//weekNumber: state.reducer.publicationTracking.weekNumber !== null ? state.reducer.publicationTracking.weekNumber : 16,
	//yearNumber: state.reducer.publicationTracking.yearNumber !== null ? state.reducer.publicationTracking.yearNumber : 2020,
	planningData: state.reducer.publicationTracking.planningData !== null ? state.reducer.publicationTracking.planningData : [],
	isupdate: true,
})

const mapDispatchToProps = ({
	initialize,
	submit,
	showNotification,
	fetchEnd,
	fetchStart,
	onLoadPlanning,
	onChangeUser,
	onChangeGroup
})

const enhance = compose(
	connect(mapStateToProps, mapDispatchToProps),
	withStyles(styles)
)

export default translate(enhance(TrackingTypeModal))
