import React from 'react'
import Card from '@material-ui/core/Card'
import { MySimpleFormLayout } from '../layout'
import { MyTextInput, QuickSearchInput } from '../common/inputs'
import { validateYear, validateWeek, validatePriority } from './validate'
import { ProgramType } from '../../constants/types'

const CardCreate = ({ record } = this.props) => (
	<Card>
		<MySimpleFormLayout record={record}>
			<MyTextInput label="fields.year" source="year" validate={validateYear} />
			<MyTextInput label="fields.week" source="week" validate={validateWeek} />
			<QuickSearchInput label="fields.movieid" source="movieid" modes={[ProgramType.MOVIE]} />
			<MyTextInput label="fields.priority" source="priority" validate={validatePriority} />
		</MySimpleFormLayout>
	</Card>
)

export default CardCreate