import React, { Component } from 'react'
import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import { submit } from 'redux-form'
import { connect } from 'react-redux'
import compose from 'recompose/compose'
import { Waiting } from '../../common'
import { SimpleForm, SelectInput, TextInput, TextField, Datagrid } from 'react-admin'
import IconButton from '@material-ui/core/IconButton'
import { crudGetList as crudGetListAction } from 'ra-core/lib/actions/dataActions'
import { ConcatFieldMovies } from '../../common/fields'
import { SearchIcon } from '../../../constants/icons'
import { withStyles } from '@material-ui/core/styles'

const styles = ({ palette: { primary1Color } }) => ({
	card: {
		marginTop: '-14px',
		paddingTop: 0,
		display: 'flex',
		justifyContent: 'flex-start',
		alignItems: 'flex-end',
		width: '100%'
	},
	body: { display: 'flex', alignItems: 'flex-end' },
	spacer: { width: 48 },
	icon: { color: primary1Color || '#00bcd4', paddingBottom: 0 },
	clearFix: { clear: 'right' },
	marginRight: { marginRight: 30 },
	flex: { display: 'flex', padding: 0, alignItems: 'center' }
})

const REDUX_FORM_FILTER = 'form-filter'

const mode = [
	{ id: 1, name: 'Nom du produit' },
	{ id: 2, name: 'Identifiant du droit' },
	{ id: 4, name: 'Identifiant du programme' },
	{ id: 8, name: 'Titre du programme' },
	{ id: 16, name: 'Identifiant du pays' },
	{ id: 32, name: 'Identifiant du produit' }
]

class RightsList extends Component {

	handleSaveClick = () => {
		const { submit } = this.props

		// Trigger a submit of our custom quick create form
		// This is needed because our modal action buttons are oustide the form
		submit(REDUX_FORM_FILTER)
	}

	handleSubmit = values => {
		const id = window.location.href.split('=')[1]
		values['transferid'] = parseInt(id, 10)
		values['order'] = 1

		const page = 1
		const perPage = 10
		const pagination = {
			page: parseInt(page, 10),
			perPage: parseInt(perPage, 10),
		}
		this.props.crudGetList(
			'Transfersrights',
			pagination,
			{ field: 'id', order: 'DESC' },
			values
		)

	}

	render() {
		const {
			classes = {},
			record,
			ids,
			isLoading
		} = this.props

		return (
			<Card>
				<CardContent className={classes.flex}>
					<SimpleForm
						form={REDUX_FORM_FILTER}
						resource="Transfersrights"
						onSubmit={this.handleSubmit}
						toolbar={null}
					>
						<CardContent className={classes.card}>
							<SelectInput label="fields.criteria" source="mode" choices={mode} allowEmpty={false} className={classes.marginRight} />
							<TextInput label="fields.value" source="q" alwaysOn />
						</CardContent>
					</SimpleForm>
					<IconButton aria-label="Submit">
						<SearchIcon onClick={this.handleSaveClick} />
					</IconButton>
				</CardContent>
				{
					isLoading
						? (<Waiting />)
						: (
							<Datagrid data={record} ids={ids} currentSort={{ field: 'id', order: 'DESC' }} basePath="/Transfersrights" rowClick="show">
								<TextField source="id" sortable={false} />
								<ConcatFieldMovies label="fields.value" source='product.name;value' sortable={false} />
							</Datagrid>
						)
				}
			</Card>
		)
	}
}

const mapStateToProps = state => ({
	record: state.admin.resources['Transfersrights']
		? state.admin.resources['Transfersrights'].data
		: {},
	ids: state.admin.resources['Transfersrights']
		? state.admin.resources['Transfersrights'].list.ids
		: {},
	isLoading: state.admin.loading
})

const mapDispatchToProps = {
	submit,
	crudGetList: crudGetListAction
}

const enhance = compose(
	connect(mapStateToProps, mapDispatchToProps),
	withStyles(styles)
)

export default enhance(RightsList)
