import CacheItems from './CacheItems'
import { PromotionsVouchers as request } from '../requests'

class PromotionsVouchers extends CacheItems {
	constructor(cacheKey = 'dataPromotionsVouchers') {
		super(cacheKey, request.getAll, true)
	}

	static get instance() {
		if (!this.singleton) {
			this.singleton = new PromotionsVouchers()
			this.singleton.logger.debug(`${this.__proto__.name} > new instance`)
		}

		return this.singleton
	}

	// --------------------
	// Custom methods below
	// --------------------

	// Get all domains items for one domain
	getItems = (promoid) => {
		const key = `${this.cacheKey}:${promoid}`
		let items = JSON.parse(this.storage.getItem(key))

		// No domains items for one domain found in cache
		if (null == items) {
			const allItems = this.getAllItems()
			if (null != allItems && allItems.length > 0) {
				items = Object.values(allItems).filter(item => item.promoid === promoid)
				if (null != items)
					this.storage.setItem(key, JSON.stringify(items))
			}
		}

		return items
	}

	// Get one promotion
	getItem = (promoid) => {
		if (promoid) {
			const data = this.getAllItems()
			if (null != data && data.length > 0) {
				return Object.values(data).find(item => item.id === promoid)
			}
		}
		return null
	}

	// Get label
	getLabel = (promoid) => {
		if (promoid === -1) return 'Tous (-1)'
		const item = this.getItem(promoid)
		const label = null != item ? item.name : ''
		return label.trim()
	}

	// Get formatted label 
	getFormattedLabel = (promoid) => {
		if (promoid) {
			const label = this.getLabel(promoid)
			return `${label} (${promoid})`.trim()
		}
		return ''
	}

	// Get formatted list to use in selects
	getSelectList = (withAll = false) => {
		const list = this.getAllItems()
		return this.getGenericItemsList(list, 'id', 'name', withAll)
	}

}

const PromotionsVouchersInstance = PromotionsVouchers.instance

export default PromotionsVouchersInstance
