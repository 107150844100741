import React, { Component } from 'react'
import uniq from 'lodash/uniq'
import PropTypes from 'prop-types'
import onlyUpdateForKeys from 'recompose/onlyUpdateForKeys'
import MuiButton from '@material-ui/core/Button'
import compose from 'recompose/compose'
import classnames from 'classnames'
import { Link } from 'react-router-dom'
import { translate } from 'ra-core'
import { connect } from 'react-redux'
import Button from 'ra-ui-materialui/lib/button/Button'
import Responsive from 'ra-ui-materialui/lib/layout/Responsive'
import { AddIcon } from '../../../constants/icons'
import { withStyles } from '@material-ui/core/styles'

const styles = theme =>   ({
	floating: {
		color: theme.palette.getContrastText(theme.palette.primary.main),
		margin: 0,
		top: 'auto',
		right: 20,
		bottom: 60,
		left: 'auto',
		position: 'fixed',
		zIndex: 1000
	},
	floatingLink: {
		color: 'inherit'
	}
})

class CreateButton extends Component {
	state = {
		id: ''
	}

	// Doesn't work
	componentWillReceiveProps(nextProps) {
		const { record } = this.props
		if (nextProps.record !== record) {
			const id = record ? uniq(Object.values(record).map(item => item.movieid)) : null
			this.setState({
				id: id[0]
			})
		}
	}

	render() {
		const {
			resource,
			record = {},
			basePath = '',
			className,
			classes = {},
			translate,
			label = 'ra.action.create',
			...rest
		} = this.props

		// const movieid = this.state.id
		const movieid = record ? uniq(Object.values(record).map(item => item.movieid)) : null

		return (
			<Responsive
				small={
					<MuiButton
						component={Link}
						color="primary"
						className={classnames(classes.floating, className)}
						to={{
							pathname: `${basePath}/create`,
							state: { record: { movieid: movieid[0] } }
						}}
						{...rest}
					>
						<AddIcon />
					</MuiButton>
				}
				medium={
					<Button
						component={Link}
						to={{
							pathname: `${basePath}/create`,
							state: { record: { movieid: movieid[0] } }
						}}
						className={className}
						label={label && translate(label)}
						{...rest}
					>
						<AddIcon />
					</Button>
				}
			/>
		)
	}
}

CreateButton.propTypes = {
	basePath: PropTypes.string,
	className: PropTypes.string,
	classes: PropTypes.object.isRequired,
	label: PropTypes.string,
	size: PropTypes.string,
	translate: PropTypes.func.isRequired,
}

const mapStateToProps = (state, props) => ({
	record: state.admin.resources[props.resource].data
})

const enhance = compose(
	connect(mapStateToProps),
	translate,
	onlyUpdateForKeys(['basePath', 'label']),
	withStyles(styles)
)

export default enhance(CreateButton)