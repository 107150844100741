import React from 'react'
import { connect } from 'react-redux'
import compose from 'recompose/compose'

import Grid from '@material-ui/core/Grid'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContentText from '@material-ui/core/DialogContentText'
import { Button, DialogContent } from '@material-ui/core'
import { CustomTextField } from '../../layout/GlobalStyles'
import { onPromotionUpdate } from '../../../actions/Promotion'
import { LockIcon, LockOpenIcon, CloseIcon, CancelIcon, CheckIcon } from '../../../constants/icons'
import IconButton from '@material-ui/core/IconButton'
import Slide from '@material-ui/core/Slide'
import cyan from '@material-ui/core/colors/cyan'
import { withStyles } from '@material-ui/core/styles'
import Tooltip from '@material-ui/core/Tooltip'
import { Promotions } from '../../../requests'
import { Logger } from '../../../tools'

const styles = (theme) =>  ({
	root: {
		margin: 0,
	},
	closeButton: {
		marginLeft:'88%',
		marginTop:'-40px'
	},
	popupTitle: {
		color: 'white',
		fontSize: '18px'
	},
	dialogHeader:{
        padding: '10px 10px 8px',
        background: cyan[500],
        color: 'white',
        fontSize: 'medium',
        fontWeight: 'bold',
        textAlign: 'center',
    },
	closeIcon:{
        width : '15px',
        position: 'absolute',
        top: '0px',
        right: '0px',
        borderRadius: '0',
        color: 'white',
        '&:hover': {
            background: 'red'
        }
    },
	buttonCancel: {
		width : '150px',
		background: '#e23434',
		margin: theme.spacing(1),
	},
	leftIcon: {
		marginRight: theme.spacing(1),
	},
	buttonCheck: {
		background: '#0c9e0c',
		width : '150px',
		margin: theme.spacing(1),
	},
	customSearchButtonIcon: {
		margin: theme.spacing(1),
		color : theme.palette.text.primary,
		width : '4%',
		height : '38%',
	},
	button: {
		fontSize: '.9em',
		backgroundColor : '#288DB1',
		'&:hover': {
			background: '#1c627b'
		},
		marginTop: '1.2em',
		padding: '.5 1 .5 1',
		width: '85%',
		height: '3em'
	},
	buttonModal: {
		fontSize: '.9em',
		backgroundColor : '#288DB1',
		'&:hover': {
			background: '#1c627b'
		},
		padding: '.5 1 .5 1'
	},
})

// const labels = {
// 	marginTop: '0', 
// 	color: '#288DB1',
// 	fontWeight: 'bold',
// 	fontSize: '13px',
// 	fontFamily: 'Roboto, Helvetica, Arial, sans-serif',
// }

const Transition = React.forwardRef(function Transition(props, ref) {
	return <Slide direction="up" ref={ref} {...props} />
})

function PromotionsGlobal (props) {
	const { classes, create, type, closedProps, handler, onPromotionUpdate, promotion, controlField } = props
	const [openModal, setopenModal] = React.useState(0)
	
	const handleChange = () => {
		if(closedProps === 0) {
			setopenModal(1)
		}
	}

	const handleCloseModal = () => {
		setopenModal(0)
	}

	const closePromotion = () => {
		setopenModal(0)
		let prom = promotion
		prom.closed = 1
		handler(true)				
		onPromotionUpdate(prom)
		return new Promise((resolve, reject) => {
			
			Promotions.savePromotion(prom).then((data) => {
				if (data) {
					onPromotionUpdate(data.data)
				} 
				//onPromotionUpdateResponse(true)
				resolve()
			}
			).catch((error) => {//
				Logger.debug(error)
				reject()
			})
		})
		
	}

	const handleChangeValue = (event) => {
		let prom = promotion
		prom.name = event.target.value
		onPromotionUpdate(prom)
	}
	
	let promoCheckbox
	let promoId = [] 
	let promoName = [] 
	
	if (create) {
		if (type === 'edit') {
			promoId.push(
				<CustomTextField label="Identifiant promotion" value={promotion.id} />
			)
		} else if(type !== 'create') {
			promoId.push(	
				<CustomTextField label="Identifiant promotion" value={promotion.id} />
			)
		}
		promoName.push(
			<CustomTextField label="Nom promotion" value={promotion.name} fullWidth="true" handleChange={handleChangeValue} create={create} 
				required="true" errorLabel={controlField && controlField.name !== '' ? controlField.name : null}
				textFieldLenght={64}/>
		)
		promoCheckbox = (<Grid container direction='row' alignItems="center">
		<Grid item md={2}></Grid>
		<Grid item md={10} >
			{ closedProps ? 					
			<Grid item md={12}  style={{fontWeight: "600", fontSize: ".9em",color: 'red'}}>
			<IconButton  size="small" style={{ color: 'red',padding: 0}}>
				<Tooltip title="Clôturé">
					<LockIcon fontSize="large"/> 
				</Tooltip>
			</IconButton>
			Promotion clôturée 
		</Grid>
		: 	
		<Grid item md={12}  style={{fontWeight: "600", fontSize: ".9em",color: 'black'}}>
			<IconButton  size="small"   style={{ color: 'black',padding: 0}}>
				<Tooltip title="Non clôturé">
					<LockOpenIcon fontSize="large"/> 
				</Tooltip>
			</IconButton>
			Promotion non clôturée 
		</Grid>
			}
		</Grid>
 
		</Grid>)
	 
	}else {
		promoId.push(	
			<CustomTextField label="Identifiant promotion" value={promotion.id} />
		)
		promoName.push(
			<CustomTextField label="Nom promotion" value={promotion.name} fullWidth="true" justifyContent="center"/>
		)

		promoCheckbox =	(<Grid container direction='row' alignItems="center">
		<Grid item md={2}></Grid>
		<Grid item md={10}  >
			{ closedProps ? 
			<Grid item md={12}  style={{fontWeight: "600", fontSize: ".9em",color: 'red'}}>
				<IconButton  size="small" style={{ color: 'red',padding: 0}}>
					<Tooltip title="Clôturé">
						<LockIcon fontSize="large"/> 
					</Tooltip>
				</IconButton>
				Promotion clôturée 
			</Grid>
			: 	
			<Grid item md={12}  style={{fontWeight: "600", fontSize: ".9em",color: 'black'}}>
				<IconButton  size="small" onClick={() => handleChange()} style={{ color: 'black',padding: 0}}>
					<Tooltip title="Non clôturé">
						<LockOpenIcon fontSize="large"/> 
					</Tooltip>
				</IconButton>
				Promotion non clôturée 
			</Grid>
			 

			}
		</Grid>
		{/* <Grid item md={8} style={{fontWeight: "600", fontSize: ".9em"}}>
			{ closedProps 
				? <span>Promotion clôturée</span>
				: <span>Promotion non clôturée</span>
			}
		</Grid> */}
	</Grid>)
	
		
	}

	return (
		<div className={ classes.cardPaddingMargin }>
			<Dialog
				open={openModal}
				onClose={() => handleCloseModal()}
				TransitionComponent={Transition}
				aria-labelledby="customized-dialog-title" 
			>
				<div className={classes.dialogHeader}>
					CLOTURER LA PROMOTION
					<Button onClick={handleCloseModal} className={classes.closeIcon}>
						<CloseIcon/>				 
					</Button>
				</div>
				<DialogContent>
					<DialogContentText id="alert-dialog-description" style={{ color: 'black'}}>
						Après validation, vous ne pourrez plus réactiver cette promotion							
					</DialogContentText>
				</DialogContent>
				<DialogActions>
					<Button onClick={closePromotion} color="primary" variant="contained" className={classes.buttonCheck}>
						<CheckIcon className={classes.leftIcon} />
						Valider
					</Button>
					<Button onClick={handleCloseModal} color="secondary" variant="contained" className={classes.buttonCancel}>
						<CancelIcon className={classes.leftIcon} />
						Annuler
					</Button>
				</DialogActions>
			</Dialog>
			<Grid container spacing={12} direction="row" justify="space-between" >
				<Grid item md={12} sm={12} xs={12}>
					<Grid container spacing={2}>
						<Grid className={type === 'create' ? classes.display : null} item md={2} sm={2} xs>
							{ promoId }
						</Grid>
						{ create && type === 'create' ? 
							<Grid item md={6} sm={6} xs style={{  paddingRight: '32px'}}>
								{ promoName }
							</Grid>
							:
							<Grid item md={6} sm={6} xs style={{ paddingLeft: 0, paddingRight: 0}}>
								{ promoName }
							</Grid>
						}
						<Grid item md={3} xs>
							{ promoCheckbox }
						</Grid>
					</Grid>
				</Grid>
			</Grid>
		</div>
	)
}

const mapStateToProps = state => ({
	...state,
	promotion: state.reducer ? state.reducer.Promotion.payload : null,
	controlField: state.reducer ? state.reducer.Promotion.controlField : null,
})

const mapDispatchToProps = ({
	onPromotionUpdate
})

const enhance = compose(
	connect(mapStateToProps, mapDispatchToProps),
	withStyles(styles)
)

export default enhance(PromotionsGlobal)
