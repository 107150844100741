import React, { Component } from 'react'
import { Button, TextField, Typography } from '@material-ui/core'
import { connect } from 'react-redux'
import compose from 'recompose/compose'
import CircularProgress from '@material-ui/core/CircularProgress'
import Dialog from '@material-ui/core/Dialog'
import DialogContent from '@material-ui/core/DialogContent'
import DialogActions from '@material-ui/core/DialogActions'
import MUIDataTable from 'mui-datatables'
import { createTheme, withStyles, ThemeProvider } from '@material-ui/core/styles'
import IconButton from '@material-ui/core/IconButton'
import Tooltip from '@material-ui/core/Tooltip'
import Zoom from '@material-ui/core/Zoom'
import SearchIcon from '@material-ui/icons/Search'
import InputBase from '@material-ui/core/InputBase'
import Paper from '@material-ui/core/Paper'
import FlipToFrontIcon from '@material-ui/icons/FlipToFront'
import cyan from '@material-ui/core/colors/cyan'
import { CloseIcon, CancelIcon, CheckIcon, WarningIcon, EditIcon, YoutubeSearchedForIcon, DeleteIcon } from '../../../constants/icons'
import { fetchStart, GET_LIST } from 'react-admin'
import dataProvider from '../../../dataProvider'
import { onChangeParent, OnAddDissociatedAlbum, OnAssociateSousAlbumList, OnAlbumMovieListUpdate } from '../../../actions/companiesAlbums'
import ContactsFavorites from '../../../requests/ContactsFavorites'
import FormControl from '@material-ui/core/FormControl'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Switch from '@material-ui/core/Switch'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'
import { CustomTextField } from '../../layout/GlobalStyles'
import CompaniesAlbums from '../../../requests/CompaniesAlbums'
import { Properties } from '../../../requests'


const styles = (theme) => ({
	borderRadius: 15,
	border: 1,
	borderColor: 'white',
	borderStyle: 'solid',
	color: 'white',
	padding: '10px',
	paddingBottom: '30px',
	margin: '15px',
	marginBottom: '30px',
	marginRight: '30px',
	chip: {
		color: '#fff',
		backgroundColor: '#288DB1',
		'&:hover': {
			backgroundColor: '#1c627b'
		},
		'&:active': {
			backgroundColor: '#1c627b'
		},
		'&:focus': {
			background: '#288DB1'
		},
	},
	dialogHeader: {
		padding: '10px 10px 8px',
		background: cyan[500],
		color: 'white',
		fontSize: 'medium',
		fontWeight: 'bold',
		textAlign: 'center',
	},
	closeIcon: {
		width: '15px',
		position: 'absolute',
		top: '0px',
		right: '0px',
		borderRadius: '0',
		color: 'white',
		'&:hover': {
			background: 'red'
		}
	},
	buttonCheck: {
		margin: theme.spacing(1),
		width: '150px',
		background: '#0c9e0c'
	},
	buttonCancel: {
		width: '150px',
		background: '#e23434',
		margin: theme.spacing(1),
	},
	leftIcon: {
		marginRight: theme.spacing(1),
	},
	button: {
		backgroundColor: '#288DB1',
		'&:hover': {
			background: '#1c627b'
		}
	},
	labels: {
		margin: '.5em .5em .5em .5em',
		color: '#288DB1',
		fontWeight: 'bold',
		fontSize: '13px',
		fontFamily: 'Roboto, Helvetica, Arial, sans-serif',
		lineHeight: 1,
	},
	buttonRules: {
		backgroundColor: '#288DB1',
		maxHeight: "3em",
		width: '12em',
		padding: 0,
		'&:hover': {
			background: '#1c627b'
		},
		position: 'absolute',
		bottom: '3em',
		right: '3em',
	},
	badgeIcon: {
		fontSize: 18,
		marginTop: '.4em',
	},
	switchMode: {
		padding: "0 0 1em .5em"
	},
	switchSelectAll: {
		position: "relative",
	},
	switchLabel: {
		color: '#111',
		fontSize: '15px',
		fontFamily: 'Roboto, Helvetica, Arial, sans-serif',
		lineHeight: 1,
		paddingTop: ".8em"
	},
	validateIcon: {
		backgroundColor: '#288DB1',
		'&:hover': {
			background: '#1c627b'
		},
		margin: '.5em 0 .5em .5em'
	},

})
const getMuiTheme = () => createTheme({
	overrides: {
		MuiPaper: {
			elevation4: {
				boxShadow: 'none'
			}
		},
		MUIDataTableToolbarSelect: {
			root: {
				display: "none !important"
			}
		}
	},
})


class SearchListField extends Component {

	constructor(props) {
		super(props)
		this.state = {
			doublon: [],
			searchValue: '',
			searchMovies: [],
			searchAlbums: [],
			searchPromotions: [],
			isLoading: false,
			programmesAdded: [],
			albumsAdded: [],
			promotionsAdded: [],
			rowsSelected: [],
			selectedAddedRows: [],
			multiSelectMax: null,
			programmeSearchByList: false,
			textFieldValueProgrammeList: "",
			searchIdsNotExist: [],
			checkProgrammeIsLoading: false,
			albumMovieNotValidate: [],
			recurrent: 1,
			adultAuthorized: 2


		}
	}

	componentDidMount() {
		if (this.props.isprogrammeList) {

			this.setState({ programmeSearchByList: true })
			Properties.getPropertiesByObjName(2, 'AttributesType').then((json) => {
				if (json.data)
					if (json.data.filter(s => s.propText === 'AdultAuthorized'))
						this.setState({ adultAuthorized: json.data.filter(s => s.propText === 'AdultAuthorized')[0].refid })
				if (json.data.filter(s => s.propText === 'Recurrent'))
					this.setState({ recurrent: json.data.filter(s => s.propText === 'Recurrent')[0].refid })
			}
			)
		}

	}
	componentDidUpdate(prevProps) {
		// Is called upon opening or closing the component
		if (this.props.open !== prevProps.open) {
			if (this.props.open == true) {
				if (this.props.defaultValue) {
					this.setState({ searchValue: this.props.defaultValue })
					switch (this.props.TypeItem) {
						case 'Albums':
							this.searchAlbums()
							break;
						case 'Movies':
							this.searchCompaniesMovies()
							break;
						case 'Promotions':
							this.searchPromotions()
							break;
						default:
							this.searchCompaniesMovies()
					}
				} else {
					this.setState({ searchValue: '' })
				}
			}
			if (this.props.isprogrammeList) {
				this.setState({ programmeSearchByList: true })
			}
		}
	}

	handleChangeInput = (event) => {
		if (event.key === "Enter") {
			console.log('Enter key pressed')
		}
		this.setState({ searchValue: event.target.value })

	}

	getFavMovies = async (ids) => {
		const { getFavoriteMovies } = this.props
		await ContactsFavorites.addFavorite(ids).then(() => { getFavoriteMovies() }).catch((error) => { console.log(error); getFavoriteMovies() })
	}

	getFavAlbums = async (ids) => {
		const { getFavoriteAlbums } = this.props
		await ContactsFavorites.addFavorite(ids).then(() => { getFavoriteAlbums() }).catch((error) => { console.log(error); getFavoriteAlbums() })
	}

	getFavPromotions = async (ids) => {
		const { getFavoritePromotions } = this.props
		await ContactsFavorites.addFavorite(ids).then(() => { getFavoritePromotions() }).catch((error) => { console.log(error); getFavoritePromotions() })
	}

	validateSelection = (event) => {
		const { onChangeParent, close, TypeItem, albumFiche, OnUpdateAlbumFiche, source, exceptionList, ListToSearch,
			exceptionItem, OnAddDissociatedAlbum, dissocitedAlbum, OnAssociateSousAlbumList, associatesousAlbumList, hasFavoriteAlbumsChanged, getFavoriteAlbums, OnAlbumMovieListUpdate } = this.props
		if (this.state.searchAlbums && this.state.selectedAddedRows.length !== 0 && TypeItem === 'Albums') {
			if (source === 'parentId') {
				let album = albumFiche
				album.parentid = this.state.searchAlbums[this.state.selectedAddedRows] ?
					this.state.searchAlbums[this.state.selectedAddedRows].id : []
				OnUpdateAlbumFiche(album)
				onChangeParent(this.state.searchAlbums && this.state.searchAlbums[this.state.selectedAddedRows] ?
					this.state.searchAlbums[this.state.selectedAddedRows].id : [])
				//onChangeParent(album.parentid)
			}
			if (source === 'AssociersousAlbum') {
				let newalbums = associatesousAlbumList
				this.state.selectedAddedRows.forEach((item) => {
					if (ListToSearch && exceptionList && !exceptionList.find(s => s.id === this.state.searchAlbums[item].id)) {
						ListToSearch.filter(s => s.id === this.state.searchAlbums[item].id).forEach((element) => {
							exceptionList.push(element)
							newalbums.push(element)
						}
						)
					}
				})
				OnAssociateSousAlbumList(newalbums)

			}
			if (source === 'DissociersousAlbum') {
				let dissocited = exceptionItem
				dissocited.parentid = this.state.searchAlbums[this.state.selectedAddedRows].id

				if (dissocitedAlbum && !dissocitedAlbum.find(s => s.id === dissocited.id)) {
					dissocitedAlbum.push(dissocited)
					OnAddDissociatedAlbum(dissocitedAlbum)
				}
			}
			if (source === 'AddFavoriteAlbum') {
				// this.getFavAlbums({RefId:this.state.searchAlbums[this.state.selectedAddedRows].id, ObjectId: 2})
				this.state.selectedAddedRows.map((row) => {
					this.getFavAlbums({ RefId: this.state.searchAlbums[row].id, ObjectId: 2 })
				})
			}
			if (source === 'GetAlbumId') {
				// this.getFavMovies({RefId:this.state.searchMovies[this.state.selectedAddedRows].id, ObjectId: 1})
				this.state.selectedAddedRows.map((row) => {
					this.props.getAlbum(this.state.searchAlbums[row].id, 2)
				})
			}

			this.setState({ rowsSelected: [] })

			close()
		}

		if (this.state.searchMovies && this.state.selectedAddedRows.length !== 0 && TypeItem === 'Movies') {
			if (source === 'AddFavoriteMovie') {
				// this.getFavMovies({RefId:this.state.searchMovies[this.state.selectedAddedRows].id, ObjectId: 1})
				this.state.selectedAddedRows.map((row) => {
					this.getFavMovies({ RefId: this.state.searchMovies[row].id, ObjectId: 1 })
				})
			}

			if (source === 'GetMovieId') {
				// this.getFavMovies({RefId:this.state.searchMovies[this.state.selectedAddedRows].id, ObjectId: 1})
				this.state.selectedAddedRows.map((row) => {
					this.props.getMovie(this.state.searchMovies[row].id)
				})
			}
			this.setState({ rowsSelected: [] })

			close()
		}

		if (this.state.searchPromotions && this.state.selectedAddedRows.length !== 0 && TypeItem === 'Promotions') {
			if (source === 'AddFavoritePromotion') {
				// this.getFavPromotions({RefId:this.state.searchPromotions[this.state.selectedAddedRows].id, ObjectId: 6})
				this.state.selectedAddedRows.map((row) => {
					this.getFavPromotions({ RefId: this.state.searchPromotions[row].id, ObjectId: 6 })
				})
			}
			this.setState({ rowsSelected: [] })

			close()
		}
		if (this.state.programmeSearchByList && this.state.albumMovieNotValidate.length === 0 && this.state.searchMovies.length > 0) {
			if (source === 'albumMovies') {
				if (this.state.searchMovies.length > 0)
					OnAlbumMovieListUpdate(this.state.searchMovies)

				// let newalbums = associatesousAlbumList
				// this.state.selectedAddedRows.forEach((item) => {
				// 	if (ListToSearch && exceptionList && !exceptionList.find(s => s.id === this.state.searchAlbums[item].id)) {
				// 		ListToSearch.filter(s => s.id === this.state.searchAlbums[item].id).forEach((element) => {
				// 			exceptionList.push(element)
				// 			newalbums.push(element)
				// 		}
				// 		)
				// 	}
				// })
				// OnAssociateSousAlbumList(newalbums)
				this.setState({ textFieldValueProgrammeList: '', searchMovies: [], searchIdsNotExist: [], albumMovieNotValidate: [] })
			}
		}
	}

	cancelParentChange = (event) => {
		const { onChangeParent, close } = this.props
		this.setState({ textFieldValueProgrammeList: '', searchMovies: [], searchIdsNotExist: [], albumMovieNotValidate: [] })
		onChangeParent(0)
		close()
	}

	handleSearchButtonPress = (event) => {
		const { TypeItem } = this.props
		switch (TypeItem) {
			case 'Albums':
				this.searchAlbums(event)
				break;
			case 'Movies':
				this.searchCompaniesMovies(event)
				break;
			case 'Promotions':
				this.searchPromotions(event)
				break;
			default:
				this.searchCompaniesMovies(event)
		}
	}

	handleSearchKeyPress = (event) => {
		const { TypeItem } = this.props

		if (event.key === 'Enter') {
			switch (TypeItem) {
				case 'Albums':
					this.searchAlbums(event)
					break;
				case 'Movies':
					this.searchCompaniesMovies(event)
					break;
				case 'Promotions':
					this.searchPromotions(event)
					break;
				default:
					this.searchCompaniesMovies(event)
			}
		}
		// TypeItem === 'Albums' ? this.searchAlbums(event) : this.searchCompaniesMovies(event)
	}







	searchAlbums = (e) => {
		const { ListToSearch, exceptionItem, exceptionList } = this.props
		let albums = []
		if (e) {
			e.preventDefault()
		}
		this.setState({ doublon: [] })

		if (this.props.multiSelectMax) {
			this.setState({ multiSelectMax: this.props.multiSelectMax })
		}

		if (this.state.searchValue.includes(';')) {
			this.setState({ searchAlbums: [] })
			this.setState({ isLoading: true }, () => {
				let split = this.state.searchValue.split(';')
				fetchStart()
				this.setState({ isLoading: true }, () => {
					split.forEach(s => {
						if (Number.isInteger(parseInt(s))) {
							dataProvider(GET_LIST,
								"Companiesalbums/Search",
								{
									pagination: { page: 1, perPage: 100 },
									sort: { field: {}, order: {} },
									filter: { q: s, mode: 2 }
								}).then(resp => {
									if (!this.isUnmounted) {
										let element = []

										for (let index = 0; index < albums.length; index++) {
											element = [...element, parseInt(albums[index].getAttribute('ID'))]
										}

										if (this.state.albumsAdded !== undefined) {
											for (let index = 0; index < this.state.albumsAdded.length; index++) {
												element = [...element, parseInt(this.state.albumsAdded[index].getAttribute('ID'))]
											}
										}
										resp.data.forEach((r, i) => {
											if (ListToSearch && ListToSearch.map(m => m.id).includes(r.id) === false) {

												if (element.includes(r.id)) {
													resp.data.splice(i, 1)
													this.setState({ doublon: [...this.state.doublon, r.id] })
												} else {
													this.setState({ searchAlbums: [...this.state.searchAlbums, r] })
												}
											}
										})

										this.setState({ isLoading: false })
									}
								})
						}
					})
				})
			})

		} else if (this.state.searchValue.includes('*')) {
			this.setState({ searchAlbums: [] })
			this.setState({ isLoading: true }, () => {
				let searchValue = this.state.searchValue.replace('*', '%')
				fetchStart()
				this.setState({ isLoading: true }, () => {
					dataProvider(GET_LIST,
						"Companiesalbums/Search",
						{
							pagination: { page: 1, perPage: 100 },
							sort: { field: {}, order: {} },
							filter: { q: searchValue, mode: 512 }
						}).then(resp => {
							if (!this.isUnmounted) {
								let element = []

								for (let index = 0; index < albums.length; index++) {
									element = [...element, parseInt(albums[index].getAttribute('ID'))]
								}

								if (this.state.albumsAdded !== undefined) {
									for (let index = 0; index < this.state.albumsAdded.length; index++) {
										element = [...element, parseInt(this.state.albumsAdded[index].getAttribute('ID'))]
									}
								}
								resp.data.forEach((r, i) => {
									if (exceptionItem)
										resp.data.splice(exceptionItem.id, 1)
									if (exceptionList)
										exceptionList.forEach((item) =>
											resp.data.splice(item, 1))

									this.setState({ searchAlbums: [...this.state.searchAlbums, r] })

									// if (ListToSearch && ListToSearch.map(m => m.id).includes(r.id) === false) {

									// 	if(element.includes(r.id))
									// 	{
									// 		resp.data.splice(i, 1)
									// 		this.setState({ doublon: [...this.state.doublon, r.id] })
									// 	} else {
									// 		this.setState({ searchAlbums: [...this.state.searchAlbums, r] })
									// 	}
									// }
								})

								this.setState({ isLoading: false })
							}
						})
				})
			})
		} else {
			let mode = 1
			if (Number.isInteger(parseInt(this.state.searchValue)))
				mode = 2

			fetchStart()
			this.setState({ isLoading: true }, () => {
				dataProvider(GET_LIST,
					"Companiesalbums/Search",
					{
						pagination: { page: 1, perPage: 100 },
						sort: { field: {}, order: {} },
						filter: { q: this.state.searchValue, mode: mode }
					}).then(resp => {

						if (!this.isUnmounted) {

							let element = []

							if (albums !== undefined) {
								for (let index = 0; index < albums.length; index++) {
									element = [...element, parseInt(albums[index].getAttribute('ID'))]
								}
							}

							if (this.state.albumsAdded !== undefined) {
								for (let index = 0; index < this.state.albumsAdded.length; index++) {
									element = [...element, parseInt(this.state.albumsAdded[index].getAttribute('ID'))]
								}
							}

							let arr = JSON.parse(JSON.stringify(resp.data));
							if (exceptionItem)
								resp.data.splice(exceptionItem.id, 1)
							if (exceptionList)
								exceptionList.forEach((item) =>
									resp.data.splice(item, 1))
							resp.data.forEach((s) => {
								if (element.includes(s.id)) {
									let i = arr.map(a => { return a.id; }).indexOf(s.id);
									arr.splice(i, 1)
									this.setState({ doublon: [...this.state.doublon, s.id] })
								}
							})
							this.setState({ searchAlbums: arr })
							this.setState({ isLoading: false })
						}
					})
			})
		}
	}


	searchCompaniesMovies = (e) => {
		const { ListToSearch, exceptionItem, exceptionList } = this.props
		let programmes = []
		if (e) {
			e.preventDefault()
		}
		this.setState({ doublon: [] })

		if (this.props.multiSelectMax) {
			this.setState({ multiSelectMax: this.props.multiSelectMax })
		}

		if (this.state.searchValue.includes(';')) {
			this.setState({ searchMovies: [] }, () => {
				let split = this.state.searchValue.split(';')
				fetchStart()
				this.setState({ isLoading: true }, () => {
					split.forEach(s => {
						if (Number.isInteger(parseInt(s))) {
							dataProvider(GET_LIST,
								"Companiesmovies",
								{
									pagination: { page: 1, perPage: 100 },
									sort: { field: {}, order: {} },
									filter: { q: s, mode: 2 }
								}).then(resp => {
									if (!this.isUnmounted) {
										let element = []

										for (let index = 0; index < programmes.length; index++) {
											element = [...element, parseInt(programmes[index].getAttribute('ID'))]
										}

										if (this.state.programmesAdded !== undefined) {
											for (let index = 0; index < this.state.programmesAdded.length; index++) {
												element = [...element, parseInt(this.state.programmesAdded[index].getAttribute('ID'))]
											}
										}

										resp.data.forEach((r, i) => {
											if (ListToSearch && ListToSearch.map(m => m.id).includes(r.id) === false) {

												if (element.includes(r.id)) {
													resp.data.splice(i, 1)
													this.setState({ doublon: [...this.state.doublon, r.id] })
												} else {
													this.setState({ searchMovies: [...this.state.searchMovies, r] })
												}
											}
										})

										this.setState({ isLoading: false })
									}
								})
						}
					})
				})
			})
		} else if (this.state.searchValue.includes('*')) {
			this.setState({ searchMovies: [] })
			this.setState({ isLoading: true }, () => {
				let searchValue = this.state.searchValue.replace('*', '%')
				fetchStart()
				this.setState({ isLoading: true }, () => {
					dataProvider(GET_LIST,
						"Companiesmovies",
						{
							pagination: { page: 1, perPage: 100 },
							sort: { field: {}, order: {} },
							filter: { q: searchValue, mode: 512 }
						}).then(resp => {
							if (!this.isUnmounted) {
								let element = []
								for (let index = 0; index < programmes.length; index++) {
									element = [...element, parseInt(programmes[index].getAttribute('ID'))]
								}
								if (this.state.programmesAdded !== undefined) {
									for (let index = 0; index < this.state.programmesAdded.length; index++) {
										element = [...element, parseInt(this.state.programmesAdded[index].getAttribute('ID'))]
									}
								}
								resp.data.forEach((r, i) => {
									if (exceptionItem)
										resp.data.splice(exceptionItem.id, 1)
									if (exceptionList)
										exceptionList.forEach((item) =>
											resp.data.splice(item, 1))
									this.setState({ searchMovies: [...this.state.searchMovies, r] })
								})
								this.setState({ isLoading: false })
							}
						})
				})

			})
		} else {
			let mode = 2
			if (Number.isInteger(parseInt(this.state.searchValue)))
				mode = 1

			fetchStart()
			this.setState({ isLoading: true }, () => {
				dataProvider(GET_LIST,
					"Companiesmovies",
					{
						pagination: { page: 1, perPage: 100 },
						sort: { field: {}, order: {} },
						filter: { q: this.state.searchValue, mode: mode }
					}).then(resp => {

						if (!this.isUnmounted) {
							let element = []

							if (programmes !== undefined) {
								for (let index = 0; index < programmes.length; index++) {
									element = [...element, parseInt(programmes[index].getAttribute('ID'))]
								}
							}

							if (this.state.programmesAdded !== undefined) {
								for (let index = 0; index < this.state.programmesAdded.length; index++) {
									element = [...element, parseInt(this.state.programmesAdded[index].getAttribute('ID'))]
								}
							}

							let arr = JSON.parse(JSON.stringify(resp.data));
							if (exceptionItem)
								resp.data.splice(exceptionItem.id, 1)
							if (exceptionList)
								exceptionList.forEach((item) =>
									resp.data.splice(item, 1))
							resp.data.forEach((s) => {
								if (element.includes(s.id)) {
									let i = arr.map(a => { return a.id; }).indexOf(s.id);
									arr.splice(i, 1)
									this.setState({ doublon: [...this.state.doublon, s.id] })
								}
							})
							this.setState({ searchMovies: arr })
							this.setState({ isLoading: false })
						}
					})
			})
		}
	}



	searchPromotions = (e) => {
		const { ListToSearch, exceptionItem, exceptionList } = this.props
		let promotions = []
		if (e) {
			e.preventDefault()
		}
		this.setState({ doublon: [] })

		if (this.props.multiSelectMax) {
			this.setState({ multiSelectMax: this.props.multiSelectMax })
		}

		if (this.state.searchValue.includes(';')) {
			this.setState({ searchPromotions: [] }, () => {
				let split = this.state.searchValue.split(';')
				fetchStart()
				this.setState({ isLoading: true }, () => {
					split.forEach(s => {
						if (Number.isInteger(parseInt(s))) {
							dataProvider(GET_LIST,
								"Promotions",
								{
									pagination: { page: 1, perPage: 100 },
									sort: { field: {}, order: {} },
									filter: { q: s, mode: 2 }
								}).then(resp => {
									if (!this.isUnmounted) {
										let element = []

										for (let index = 0; index < promotions.length; index++) {
											element = [...element, parseInt(promotions[index].getAttribute('ID'))]
										}

										if (this.state.promotionsAdded !== undefined) {
											for (let index = 0; index < this.state.promotionsAdded.length; index++) {
												element = [...element, parseInt(this.state.promotionsAdded[index].getAttribute('ID'))]
											}
										}

										resp.data.forEach((r, i) => {
											if (ListToSearch && ListToSearch.map(m => m.id).includes(r.id) === false) {

												if (element.includes(r.id)) {
													resp.data.splice(i, 1)
													this.setState({ doublon: [...this.state.doublon, r.id] })
												} else {
													this.setState({ searchPromotions: [...this.state.searchPromotions, r] })
												}
											}
										})

										this.setState({ isLoading: false })
									}
								})
						}
					})
				})
			})
		} else if (this.state.searchValue.includes('*')) {
			this.setState({ searchPromotions: [] })
			this.setState({ isLoading: true }, () => {
				let searchValue = this.state.searchValue.replace('*', '%')
				fetchStart()
				this.setState({ isLoading: true }, () => {
					dataProvider(GET_LIST,
						"Promotions",
						{
							pagination: { page: 1, perPage: 100 },
							sort: { field: {}, order: {} },
							filter: { q: searchValue, mode: 512 }
						}).then(resp => {
							if (!this.isUnmounted) {
								let element = []
								for (let index = 0; index < promotions.length; index++) {
									element = [...element, parseInt(promotions[index].getAttribute('ID'))]
								}
								if (this.state.promotionsAdded !== undefined) {
									for (let index = 0; index < this.state.promotionsAdded.length; index++) {
										element = [...element, parseInt(this.state.promotionsAdded[index].getAttribute('ID'))]
									}
								}
								resp.data.forEach((r, i) => {
									if (exceptionItem)
										resp.data.splice(exceptionItem.id, 1)
									if (exceptionList)
										exceptionList.forEach((item) =>
											resp.data.splice(item, 1))
									this.setState({ searchPromotions: [...this.state.searchPromotions, r] })
								})
								this.setState({ isLoading: false })
							}
						})
				})

			})
		} else {
			let mode = 1
			if (Number.isInteger(parseInt(this.state.searchValue)))
				mode = 2

			fetchStart()
			this.setState({ isLoading: true }, () => {
				dataProvider(GET_LIST,
					"Promotions",
					{
						pagination: { page: 1, perPage: 100 },
						sort: { field: {}, order: {} },
						filter: { q: this.state.searchValue, mode: mode }
					}).then(resp => {

						if (!this.isUnmounted) {
							let element = []

							if (promotions !== undefined) {
								for (let index = 0; index < promotions.length; index++) {
									element = [...element, parseInt(promotions[index].getAttribute('ID'))]
								}
							}

							if (this.state.promotionsAdded !== undefined) {
								for (let index = 0; index < this.state.promotionsAdded.length; index++) {
									element = [...element, parseInt(this.state.promotionsAdded[index].getAttribute('ID'))]
								}
							}
							let arr = JSON.parse(JSON.stringify(resp.data));
							if (exceptionItem)
								resp.data.splice(exceptionItem.id, 1)
							if (exceptionList)
								exceptionList.forEach((item) =>
									resp.data.splice(item, 1))
							resp.data.forEach((s) => {
								if (element.includes(s.id)) {
									let i = arr.map(a => { return a.id; }).indexOf(s.id);
									arr.splice(i, 1)
									this.setState({ doublon: [...this.state.doublon, s.id] })
								}
							})
							this.setState({ searchPromotions: arr })
							this.setState({ isLoading: false })
						}
					}).catch(error => console.log(error))
			})
		}
	}

	handleChangeModeSwitch = () => {
		this.setState({ programmeSearchByList: !this.state.programmeSearchByList, searchMovies: [], textFieldValueProgrammeList: "", searchIdsNotExist: [], albumMovieNotValidate: [] })
	}
	handleTextFieldValueProgrammeList = (event) => {
		this.setState({ textFieldValueProgrammeList: event.target.value, searchMovies: [], searchIdsNotExist: [], textFieldValueProgrammeListError: "" })
	}
	handleClickChangeMode = () => {
		let newTextField = ""
		if (this.state.searchIdsNotExist.length > 0)
			newTextField = this.state.searchIdsNotExist.join('\n') + '\n' + this.state.searchMovies.map(m => m.id).join('\n')
		else
			newTextField = this.state.searchMovies.map(m => m.id).join('\n')
		this.setState({ searchMovies: [], textFieldValueProgrammeList: newTextField })
	}

	handleClickCheckMoviesIdsExist = () => {
		this.setState({ checkProgrammeIsLoading: true })
		let tempTextFieldValueProgrammeList = this.state.textFieldValueProgrammeList.replace(/[`~!@#$%^&*()_|+\-=?;:'",.<>{}[]\\\/]/gi, '')
		let arr = this.state.textFieldValueProgrammeList.split('\n')
			.filter((el) => {
				return el !== null && el !== ""
			})

		if (this.state.textFieldValueProgrammeList !== tempTextFieldValueProgrammeList) {
			this.setState({ textFieldValueProgrammeListError: "La saisie ne respecte pas les règles ci dessous." })
		} else {
			CompaniesAlbums.getMoviesbyIds(arr).then((json) => {
				if (json.data !== undefined) {
					let notExist = []
					arr.forEach(id => {
						if (!json.data.map(p => p.id).includes(parseInt(id)))
							notExist.push(id)
					})

					this.setState({ searchIdsNotExist: notExist, searchMovies: json.data, checkProgrammeIsLoading: false, textFieldValueProgrammeListError: "" })
					if (this.props.source === 'albumMovies') this.CheckMovieGenrePrincipal()
				}
			})
		}
		this.setState({ checkProgrammeIsLoading: false })

		// this.state.textFieldValueProgrammeList.replace(/[`~!@#$%^&*()_|+\-=?;:'",.<>\{\}\[\]\\\/]/gi, '').split('\n').forEach(v => {
		// 	if(Number.isInteger(parseInt(v)))
		// 		arr.push(parseInt(v))
		// })


	}
	CheckMovieGenrePrincipal = () => {
		const { albumMovie } = this.props
		let listMovies = this.state.searchMovies

		//let movie = {id:'',Message:'',IsError:false}
		let movieState = []
		listMovies && listMovies.forEach(element => {
			// Si c'est un programme "Adulte" on vérifie que l'on a coché sur l'album "Autoriser l'adulte" pour ajouter le programme à l'album
			if ((5 /* Adulte */ == element.classificationid) //&& albumMovie.attributes !== 2)   
				&& ((parseInt(this.state.adultAuthorized)) !== (albumMovie.attributes & parseInt(this.state.adultAuthorized)))) {
				let movie = {
					id: element.id,
					Message: "L'album n'autorise pas les programmes 'Adulte'.",
					IsError: true
				}
				movieState.push(movie)

				return (false)
			}
			// Si le programme à un rsac supérieur à celui de l'album on ne peut pas l'ajouter.
			// if (element.rsac > albumMovie.rsac) //Rsac.Compare(oMovie.Rsac.RsacValue, oAlbum.Rsac.RsacValue) >= 1)
			// {
			// 	let movie = {
			// 		id: element.id,
			// 		Message: "Le RSAC du programme est supérieur à celui de l'album.",
			// 		IsError: true
			// 	}
			// 	movieState.push(movie)

			// 	return (false)
			// }
			// Dans les albums mixte on autorise l'ajout des programmes récurrents et non récurrents
			if (8 /*Mixte*/ != albumMovie.typeid) {
				if ((1 /* Cinéma */ === element.classificationid)  //&&	(1 === albumMovie.attributes  ))
					&& (parseInt(this.state.recurrent) == (albumMovie.attributes & parseInt(this.state.recurrent)))) {
					let movie = {
						id: element.id,
						Message: "L'album n'autorise que les programmes récurrents.",
						IsError: true
					}
					movieState.push(movie)

					return (false)
				}

				if (((2 == element.classificationid) || (3 == element.classificationid) || (4 == element.classificationid)) 
					&& (parseInt(this.state.recurrent) !== (albumMovie.attributes & parseInt(this.state.recurrent))))
				{
					let movie = {
						id: element.id,
						Message: "L'album n'autorise que les programmes récurrents.",
						IsError: true
					}
					movieState.push(movie)

					return (false)
				}
			}

		})
		this.setState({ albumMovieNotValidate: movieState })
		return (true)
	}
	handleRemoveItem = (itemid) => {
		const {exceptionList} = this.props
		if (this.state.searchIdsNotExist.find(s => s === itemid)) {
			this.setState({
				searchIdsNotExist: this.state.searchIdsNotExist.filter(s => s !== itemid),
				textFieldValueProgrammeList: this.state.textFieldValueProgrammeList.replace(itemid, '')
			})
		}
		if (this.state.searchMovies.find(s => s.id === itemid) && this.state.albumMovieNotValidate.find(s => s.id === itemid)) {
			this.setState({
				searchMovies: this.state.searchMovies.filter(s => s.id !== itemid),
				albumMovieNotValidate: this.state.albumMovieNotValidate.filter(s => s.id !== itemid),
				textFieldValueProgrammeList: this.state.textFieldValueProgrammeList.replace(itemid, '')
			})
		}
		if(this.state.searchMovies.find(s => s.id === itemid) && exceptionList.find(s => s.movie.id === itemid)  ){
			this.setState({
				searchMovies: this.state.searchMovies.filter(s => s.id !== itemid),
				albumMovieNotValidate: this.state.albumMovieNotValidate.filter(s => s.id !== itemid),
				textFieldValueProgrammeList: this.state.textFieldValueProgrammeList.replace(itemid, '')
			})
		}

	}
	render() {
		const { Title, TypeItem, open, close, exceptionItem, exceptionList, classes, multiSelect, source, multiSearch, create, isprogrammeList } = this.props
		const options = {
			rowsPerPage: 10,
			rowHover: false,
			textLabels: {
				body: {
					noMatch: 'Aucune ligne trouvée',
					toolTip: 'Trier'
				},
			},
			setTableProps: () => {
				return {
					padding: 'normal',
					size: 'small',
				}
			},
			resizableColumns: false,
			print: false,
			download: false,
			search: false,
			filter: false,
			viewColumns: false,
			filterType: 'select',
			selectableRowsOnClick: true,
			selectableRows: multiSelect ? 'multiple' : 'single',
			fixedSelectColumn: true,
			customToolbar: false,
			rowsSelected: this.state.rowsSelected,
			isRowSelectable: (dataIndex) => {
				if (TypeItem === 'Albums' && this.state.searchAlbums && this.state.searchAlbums[dataIndex])
					return exceptionItem && exceptionList ?
						(exceptionList ? !exceptionList.find(s => s.id === this.state.searchAlbums[dataIndex].id) : false) && (exceptionItem ? this.state.searchAlbums[dataIndex].id !== exceptionItem.id : false)
						: exceptionItem ? this.state.searchAlbums[dataIndex].id !== exceptionItem.id : exceptionList ? !exceptionList.find(s => s.id === this.state.searchAlbums[dataIndex].id) : false
				//exceptionList ? !exceptionList.find(s=>s.id === this.state.searchAlbums[dataIndex].id ) :false
				if (TypeItem === 'Movies' && this.state.searchMovies && this.state.searchMovies[dataIndex]) {
					if (exceptionList) {
						if (source === 'albumMovies') {
							if (exceptionList.find(s => s.movie.id == this.state.searchMovies[dataIndex].id))
								return false
							else
								return true
						}
						else {
							if (exceptionList.find(s => s.id == this.state.searchMovies[dataIndex].id))
								return false
							else
								return true
						}
					}
					if (exceptionItem) {
						if (this.state.searchMovies[dataIndex].id === exceptionItem.id)
							return false
						else
							return true
					}

					// return exceptionItem && exceptionList ?
					// 	(exceptionList ? !exceptionList.find(s => s.id == this.state.searchMovies[dataIndex].id) : false) && (exceptionItem ? this.state.searchMovies[dataIndex].id !== exceptionItem.id : false)
					// 	: exceptionItem ? this.state.searchMovies[dataIndex].id !== exceptionItem.id : exceptionList ? !exceptionList.find(s => s.id === this.state.searchMovies[dataIndex].id) : false
				}
				if (TypeItem === 'Promotions' && this.state.searchPromotions && this.state.searchPromotions[dataIndex])
					return exceptionItem && exceptionList ?
						(exceptionList ? !exceptionList.find(s => s.id === this.state.searchPromotions[dataIndex].id) : false) && (exceptionItem ? this.state.searchPromotions[dataIndex].id !== exceptionItem.id : false)
						: exceptionItem ? this.state.searchPromotions[dataIndex].id !== exceptionItem.id : exceptionList ? !exceptionList.find(s => s.id === this.state.searchPromotions[dataIndex].id) : false
			},
			onRowSelectionChange: (rowsSelectedData, allRows, rowsSelected) => {
				this.setState({ rowsSelected: rowsSelected });
				this.setState({ selectedAddedRows: rowsSelected })
				if (this.props.multiSelectMax) {
					this.setState({ multiSelectMax: this.props.multiSelectMax - rowsSelected.length })
				}
			},
		}
		const ChangeModeSwitch = () => (

			<FormControl component="fieldset" className={classes.switchMode}>
				<FormControlLabel
					value="top"
					control={
						<Switch checked={this.state.programmeSearchByList}
							onChange={this.handleChangeModeSwitch}
							color="primary" size="small"
							inputProps={{ 'aria-label': 'primary checkbox' }}
						/>
					}
					label={<span style={{ fontSize: '.8em', marginLeft: '.5em' }}>Passer en mode <b>injection d'une liste</b> ?</span>}
					labelPlacement="right"
				/>
			</FormControl>
		)
		return (
			<div>
				<Dialog onClose={close} maxWidth="lg" minWidth="lg" 
				aria-labelledby="customized-dialog-title"
				disableEscapeKeyDown={true}
				disableBackdropClick = {true}
				open={open}>
					<div className={classes.dialogHeader}>
						{Title}
						<Button onClick={close} className={classes.closeIcon} >
							<CloseIcon />
						</Button>
					</div>
					<DialogContent dividers style={{ paddingTop: '.5em', paddingBottom: '.2em', minWidth: '600px' }}>
						<Typography>{source === 'DissociersousAlbum' ? 'Veuillez choisir un parent' : ''}</Typography>
						{multiSearch && !isprogrammeList && <div><ChangeModeSwitch /></div>}
						{!this.state.programmeSearchByList &&
							<>
								<div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
									<Paper component="form" style={{ padding: '1px 1px', marginBottom: '0.5rem', display: 'flex', alignItems: 'center', width: 400, }}>
										<InputBase
											style={{ marginLeft: '0.2rem', flex: 1, }}
											placeholder="Recherche par Identifiant ou par Titre"
											onChange={this.handleChangeInput}
											autoFocus
											value={this.state.searchValue}
											onKeyPress={(e) => {
												if (e.key === 'Enter') {
													this.handleSearchKeyPress(e)
													// write your functionality here
												}
											}}
											inputProps={{ 'aria-label': 'search google maps' }}
										/>
										<IconButton type="button" aria-label="search" onClick={(e) => this.handleSearchButtonPress(e)}>
											<SearchIcon />
										</IconButton>
										{
											this.state.doublon.length > 0 &&
											<Tooltip style={{ fontSize: '2em' }} title={"Les identifiants suivant sont déjà présent dans la liste: " + this.state.doublon.join(' - ')}
												placement="right"
												TransitionComponent={Zoom}
											>
												<IconButton>
													<FlipToFrontIcon />
												</IconButton>
											</Tooltip>
										}
									</Paper>
									{this.state.isLoading ? (<CircularProgress size="1.8em" style={{ marginLeft: '.5em' }} />) : null}
								</div>
								<ThemeProvider theme={getMuiTheme()}>
									<MUIDataTable data={TypeItem === 'Albums' ? this.state.searchAlbums : TypeItem === 'Promotions' ? this.state.searchPromotions : this.state.searchMovies}
										columns={[{ name: 'id', label: 'Identifiant' }, { name: 'value', label: 'Nom' }]} options={options} />
								</ThemeProvider>
							</>
						}
						{this.state.programmeSearchByList && <>
							{(this.state.searchMovies.length > 0 || this.state.searchIdsNotExist.length > 0) ?
								<List component="nav" className={classes.root} dense aria-label="contacts"
									style={{ height: '12em', overflow: 'auto', paddingTop: '10.5px', paddingBottom: '10.5px', margin: ".5em 0 .5em 0", border: "1px solid #E0E0E0", borderRadius: "4px", width: "99%" }}>
									{this.state.searchIdsNotExist.length > 0 ?
										this.state.searchIdsNotExist.map(id =>
											<ListItem button >
												<ListItemText inset primary={id} style={{ paddingLeft: 0, maxWidth: "80%" }} />
												<div>
													<IconButton style={{ marginLeft: "1em",color: 'red' }} >
														<Tooltip title="Programme non trouvé">
															<WarningIcon  />
														</Tooltip>
													</IconButton>
													<IconButton onClick={() => this.handleRemoveItem(id)}>
														<DeleteIcon />
													</IconButton>
												</div>
											</ListItem>
										)
										:
										null
									}
									{this.state.searchMovies.map(m =>
										<ListItem button>
											<ListItemText inset primary={m.shorttitle + " (" + m.id + ")"} style={{ paddingLeft: 0, maxWidth: "80%" }} />
											{this.state.albumMovieNotValidate && this.state.albumMovieNotValidate.find(s => s.id === m.id) ?
												<div>
													<IconButton style={{ marginLeft: "1em",color: 'red' }}>
														<Tooltip title={this.state.albumMovieNotValidate.filter(s => s.id === m.id)[0].Message}>
															<WarningIcon   />
														</Tooltip>
													</IconButton>
													<IconButton onClick={() => this.handleRemoveItem(m.id)}>
														<DeleteIcon />
													</IconButton>
												</div>
												:
												exceptionList && exceptionList.find(s => s.movie.id === m.id) ?
													<div>
														<IconButton style={{ marginLeft: "1em",color: 'red' }}>
															<Tooltip title="Programme déjà présent dans l'album">
																<WarningIcon   />
															</Tooltip>
														</IconButton>
														<IconButton onClick={() => this.handleRemoveItem(m.id)} >
															<DeleteIcon />
														</IconButton>
													</div>
													:
													<CheckIcon style={{ marginLeft: "1em", color: 'green' }} />}

										</ListItem>
									)}


								</List>
								:
								<CustomTextField value={this.state.textFieldValueProgrammeList} multiline={true}
									disabled={!this.state.programmeSearchByList} fullWidth={true} rows={10}
									handleChange={this.handleTextFieldValueProgrammeList} create={create}
									errorLabel={this.state.textFieldValueProgrammeListError} />
							}
							<div style={{ paddingLeft: ".5em", marginTop: "1.2em" }}>
								<Typography variant="caption" gutterBottom >
									Chaque ligne ne doit contenir qu'un seul identifiant et aucun séparateur.
								</Typography>
							</div>
							{this.state.searchMovies.length > 0 ?
								<Button variant="contained" size="small" color='primary'
									className={classes.validateIcon}
									onClick={this.handleClickChangeMode}
									disabled={!this.state.textFieldValueProgrammeList.length > 0}>
									<EditIcon style={{ marginRight: '.2em' }} /> EDITER
								</Button>
								:
								<Button variant="contained" size="small" color='primary'
									className={classes.validateIcon}
									onClick={this.handleClickCheckMoviesIdsExist}
									disabled={!this.state.textFieldValueProgrammeList.length > 0}>
									{this.state.checkProgrammeIsLoading ? (<CircularProgress size="1.8em" style={{ marginLeft: '.5em', marginRight: '.2em' }} />) : <YoutubeSearchedForIcon style={{ marginRight: '.2em' }} />}
									VERIFIER
								</Button>
							}
						</>}
					</DialogContent>
					<DialogActions style={{ justifyContent: "space-between" }}>
						{/* onClick={this.onChangeRules('programmes')} */}
						<span style={{ marginLeft: '5px', color: this.state.multiSelectMax < 0 ? 'red' : 'black' }}>{this.state.multiSelectMax != null ? 'Éléments selectionnés restants  : ' + this.state.multiSelectMax : ''}</span>
						<div>
							<Tooltip title={this.state.multiSelectMax < 0 ? "Vous avez dépassé le nombre d'éléments selectionnés autorisés" : ""}>
								<span>
									<Button color="primary" variant="contained"
										disabled={((!this.state.programmeSearchByList) && (this.state.selectedAddedRows.length === 0 || this.state.multiSelectMax < 0))
											|| (this.state.programmeSearchByList && (this.state.albumMovieNotValidate.length > 0 || this.state.searchMovies.length === 0))}
										onClick={e => this.validateSelection(e)} className={classes.buttonCheck}>

										<CheckIcon className={classes.leftIcon} />
										Valider
									</Button>
								</span>
							</Tooltip>
							<Button onClick={e => this.cancelParentChange(e)} color="secondary" variant="contained" className={classes.buttonCancel}>
								<CancelIcon className={classes.leftIcon} />
								Annuler
							</Button>
						</div>
					</DialogActions>
				</Dialog>
			</div>)
	}
}
const mapStateToProps = state => ({
	...state,
	selectedAlbumParent: state.reducer.albums.selectedAlbumParent,
	dissocitedAlbum: state.reducer.albums.dissocitedAlbum,
	associatesousAlbumList: state.reducer.albums.associatesousAlbumList !== undefined ? state.reducer.albums.associatesousAlbumList : [],


})

const mapDispatchToProps = ({
	onChangeParent,
	OnAddDissociatedAlbum,
	OnAssociateSousAlbumList,
	OnAlbumMovieListUpdate
})

const enhance = compose(
	connect(mapStateToProps, mapDispatchToProps),
	withStyles(styles)
)

export default enhance(SearchListField)