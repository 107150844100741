import React from 'react'
import { NumberInput } from 'react-admin'
import { EuroIcon } from '../../../constants/icons'
import { InputAdornment, withStyles   } from '@material-ui/core'

const styles =  ({
	adornment: {
		fontSize: 15,
		marginTop: '35px'
	}
})

 const PriceInput = ({ classes,  withPlaceholder = true, withAdornment = false, isLargeLabel, ...props }) => 	
 	
		<NumberInput
			//placeholder={withPlaceholder ? '€' : null}
			inputVariant= "outlined"
			InputProps={ {
				endAdornment: (
					<InputAdornment position="end">
						<EuroIcon color="disabled" className={classes.adornment} />
					</InputAdornment>
				)} 
		
		}
	
		{...props}
		/>   
 
	
// const PriceInput = props => {
// 	//console.log(props)
// 	const { classes,  withPlaceholder = true, withAdornment = false, isLargeLabel } = props
// 	console.log(props)
// 		return(
// 			<TextField
// 			//inputProps ={props}
// 			//onChange={handleChange}
// 			id="standard-number"
// 			type="number"
// 			InputProps={{
// 			startAdornment: (
// 				<InputAdornment position="start">
// 					<EuroIcon />
// 				</InputAdornment>
// 			),type: 'number',
// 			readOnly: false
// 			}}
// 		   InputLabelProps={{
// 			   shrink: true,
// 		   }}
// 			variant="outlined"
// 		/>
// 			// <TextField
// 			// {...props}
// 			// 	//label={props.label}
// 			// 	//value={props.value}
// 			// 	//disabled={props.disabled}
// 			// 	onChange={handleChange}
// 			// 	InputProps={{
// 			// 	startAdornment: (
// 			// 		<InputAdornment position="start">
// 			// 			<EuroIcon />
// 			// 		</InputAdornment>
// 			// 	),type: 'number',
// 			// 	readOnly: false
// 			// 	}}
// 			// 	variant="outlined"
// 			// />
// 		)
// }
	



export default withStyles(styles)(PriceInput)