import React from 'react'
import PropTypes from 'prop-types'
import { getValue } from '../../../tools/utils'
import { StarIcon } from '../../../constants/icons'

const style = { opacity: 0.87, width: 20, height: 20 }

const StarRatingField = props => (
	<span>
		{Array(getValue(props))
			.fill(true)
			.map((_, i) => <StarIcon key={i} style={style} />)}
	</span>
)

StarRatingField.propTypes = {
	value: PropTypes.number,
	record: PropTypes.object,
	source: PropTypes.string,
}

StarRatingField.defaultProps = {
	record: {},
	label: 'fields.rating',
	source: 'rating',
	addLabel: true,
}

export default StarRatingField
