import Api from './Api'

const PromotionsSubscribers = {
	getAll: () =>
		Api.Requests.getAll('/Promotionssubscribers/'),
	getAllSubBypromoId: (promoid) =>
		Api.Requests.getAll('/Promotionssubscribers/List/'+promoid),
	disableUser: (promoSub) =>
		Api.Requests.postWithBody('/Promotionssubscribers/Disable', promoSub)
}

export default PromotionsSubscribers
