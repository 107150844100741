import React, { useState, useEffect } from 'react'
import compose from 'recompose/compose'
import { connect } from 'react-redux'
import { createTheme, ThemeProvider, withStyles } from '@material-ui/core/styles'
import { Grid, Card, CardContent, Button, IconButton, Divider, Accordion, AccordionSummary, AccordionDetails, Paper, Typography, LinearProgress, CircularProgress } from '@material-ui/core'
// import { translate} from 'react-admin'
import { DateFormatter, PricePlanning, DomainsItems, Pricings, PricingsLists } from '../../tools'
import { CheckBox, ExpandMore } from '@material-ui/icons'
import { CustomTextField, CustomSelect, CustomCheckBox, CustomDialog, CustomAlert, CustomDisplayDialog, StyledBadge, CustomSwitch, CustomPriceInput } from '../layout/GlobalStyles'
import CompaniesAlbums from '../../requests/CompaniesAlbums'
import Companiestransfers from '../../requests/Companiestransfers'
import { PricePlanning as PricePlanningRequest, PricingsLists as PricingsListsRequest } from '../../requests'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableContainer from '@material-ui/core/TableContainer'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import { CancelIcon, CheckIcon, CloseIcon, EditIcon, VisibilityIcon, AssignmentTurnedInIcon, PublishIcon } from '../../constants/icons'
import Dialog from '@material-ui/core/Dialog'
import Slide from '@material-ui/core/Slide'
import cyan from '@material-ui/core/colors/cyan'
import { NewDatePicker } from '../common/inputs'
import moment from 'moment'
import { ProcessLoader } from '../common'
import { Tooltip } from '@material-ui/core'
 import { onSelectContent, onExpandUpdateForm, onChangeContentFilter, changeContentType, onPricePlanningFirstLoad, onPricePlanningChangePriceLine } from '../../actions/pricePlanning'
import { REDUX_FORM_PRICEPLANNING_UPDATE, REDUX_FORM_PRICEPLANNING_LOAD } from '../../constants/reduxFormName'
import { translate, fetchEnd, fetchStart, showNotification, GET_LIST, UPDATE } from 'react-admin'
import { crudGetList, crudGetOne } from 'ra-core/lib/actions/dataActions'
import dataProvider from '../../dataProvider'
import { reset, change, initialize } from 'redux-form'
import { Token, Links } from '../../tools'


const Transition = React.forwardRef(function Transition(props, ref) {
	return <Slide direction="up" ref={ref} {...props} />
})
const styles = theme => ({
	listContentBreak: {
		whiteSpace: 'break-spaces',
	},
	listContentnormal: {
		whiteSpace: 'normal',
	},
	gridForm: {
		width: 'calc(100% + 24px)',
		minWidth: '1100px'
	},
	title: {
		display: 'inline-block',
		color: 'white',
		fontWeight: 'bold',
		fontSize: '13px',
		fontFamily: 'Roboto, Helvetica, Arial, sans-serif',
		lineHeight: 1,
	},
	button: {
		fontSize: '.9em',
		backgroundColor: '#288DB1',
		color: 'white',
		'&:hover': {
			background: '#1c627b'
		},
		'&:disabled': {
			background: '#00000042'
		},
		padding: '.5 1 .5 1',
		marginTop: '5%',
		width: '80%',
		height: '3em'
	},
	leftIcon: {
		marginRight: theme.spacing(1),
	},
	dialogHeader: {
		padding: '10px 10px 8px',
		background: cyan[500],
		color: 'white',
		fontSize: 'medium',
		fontWeight: 'bold',
		textAlign: 'center',
	},
	dialogContent: {
		padding: '16px 24px 17px',
		flex: '1 1 auto',
		color: 'black',
		fontSize: '1rem',
		fontWeight: '400',
		zIndex: 200
	},
	dialogFooter: {
		flex: '0 0 auto',
		margin: '8px 4px',
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'flex-end'
	},
	buttonCancel: {
		// margin: theme.spacing(1),
		marginTop: '5%',
		width: '80%',
		background: '#e23434',
		height: '3em',
		padding: '.5 1 .5 1',

	},
	buttonClose: {
		margin: theme.spacing(1),
		width: '150px',
		background: '#e23434'
	},
	buttonCheck: {
		// margin: theme.spacing(1),
		marginTop: '5%',
		width: '80%',
		background: '#0c9e0c',
		height: '3em',
		padding: '.5 1 .5 1',

	},
	closeIcon: {
		width: '15px',
		position: 'absolute',
		top: '0px',
		right: '0px',
		borderRadius: '0',
		color: 'white',
		'&:hover': {
			background: 'red'
		}
	},
	ProgressBar: {
		// position: 'absolute',
		// top: '50%',
		// left: '45%',
		zIndex: '100',
		color: '#288DB1'
	},
	circularProgress: {
		position: 'absolute',
		top: '50%',
		left: '45%',
		zIndex: '100'
	},
})


const getMuiTheme = () => createTheme({
	overrides: {
		MuiBox: {
			root: {
				paddingTop: '5px'
			}
		},
		MuiGrid: {
			container: {
				paddingBottom: '0px',
				paddingTop: '0px',
				marginLeft: '25px'
			},
			spacing: {
				xs: {
					margin: 0
				}
			}
		},
		MuiTypography: {
			root: {
				paddingLeft: '10px'
			}
		},
		MuiCardContent: {
			root: {
				'&:first-child': {
					paddingTop: 0,
					paddingBottom: 0,
					paddingLeft: 8,
					paddingRight: 8,
				},
			}
		},
		MuiTableCell: {
			head: {
				fontWeight: 'bold'
			}
		},
		MuiOutlinedInput: {
			multiline: {
				width: '480px'
			},
			input: {
				padding: '10px 14px'
			},
			'root': {  // - The Input-root, inside the TextField-root
				'&:hover fieldset': {
					borderColor: '#288DB1 !important', // - Set the Input border when parent has :hover
				},
				'&.Mui-focused fieldset': { // - Set the Input border when parent is focused 
					borderColor: '#288DB1 !important',
				},
			},
		},
		MuiFormLabel: {
			root: {
				"&$focused": {
					"color": "#288DB1"
				},
				"&Mui-disabled": {
					color: "#288DB1"
				},

			}

		},

		MuiInputBase: {
			input: {
				padding: '10px 14px'
			}
		},
		MuiDialog: {
			paperWidthSm: {
				maxWidth: '1300px'
			}
		},
		PrivateSwitchBase: {
			root: {
				padding: '25px'
			}
		},
		MuiCheckbox: {
			root: {
				color: '#288DB1'
			}
		}

	},
})

const AssistantPack = (props) => {
	const { classes, defaultalbum, open, onClose } = props
	const [searchValue, setsearchValue] = useState(defaultalbum ? defaultalbum.id : '')
	const [album, setAlbum] = useState(defaultalbum ? defaultalbum : {})
	const [listClasseid, setlistClasseid] = useState([])
	const [listProductPack, setlistProductPack] = useState([])
	const [listTaget, setlistTarget] = useState([])
	const [cibleList, setCibleList] = useState([])
	const [modeEdit, setModeEdit] = useState(true)
	const [albumclasseid, setAlbumclasseid] = useState(album ? album.pricingClassId : 0)
	const [expanded, setExpanded] = useState({})
	const [regPack, setRegPack] = useState(false)
	const [checkedVod, setCheckedVod] = useState(false)
	const [checkedEst, setcheckedEst] = useState(false)
	const [checkedClassId, setcheckedClassId] = useState(false)
	const [openAlert, setOpenAlert] = useState(false)
	const [isPackExist, setIsPackExist] = useState(false)
	const [isLoading, setIsLoading] = useState(false)
	const [alerteMsg, setAlerteMsg] = useState('')
	const [isSucced, setSucced] = useState(false)
	const [isError, setError] = useState(false)
	const [progressBarOpen, setProgressBarOpen] = useState(false)
	const [errorMsg, setErrorMessage] = useState('')
	const [defaultContract, setDefaultContract] = useState({})
	const [checkAllVODPrice, setcheckAllVODPrice] = useState(false)
	const [applyButtonDisabled, setapplyButtonDisabled] = useState(false)
	const [checkedVodPrice, setCheckedVodPrice] = useState(false)
	const [checkedEstPrice, setCheckedEstPrice] = useState(false)
	const [openning, setOpenning] = useState(false)
	const [validate, setValidate] = useState(false)

	// const [insertItem, setInsertedItem] = useState(0)
	// const [updatedItem, setUpdatedItem] = useState(0)
	// var priceList = Object.values(contentPriceLists)
	// console.log(priceList)
	const [dates, setdates] = useState({
		startDateVOD: null,
		endDateVOD: null,
		startDateEST: null,
		endDateEST: null
	})
	const [classePrix, setClassePrix] = useState({
		prixVOD: {
			SD: 0,
			HD: 0,
			UHD: 0
		},
		prixEST: {
			SD: 0,
			HD: 0,
			UHD: 0
		}
	})
	const [prix, setPrix] = useState({
		prixVOD: {
			SD: 0,
			HD: 0,
			UHD: 0
		},
		prixEST: {
			SD: 0,
			HD: 0,
			UHD: 0
		}
	})
	const resetForm = () => {
		setOpenning(false)
		setModeEdit(false)
		setAlbumclasseid(defaultalbum ? defaultalbum.pricingClassId : 0)
		setRegPack(false)
		setValidate(false)
		setCheckedVod(false)
		setcheckedEst(false)
		setcheckedClassId(false)
		setAlerteMsg('')
		setCheckedEstPrice(false)
		setCheckedVodPrice(false)
		// setSucced(false)
		// setError(false)

	}
	const CloseForm = () => {
		resetForm()
		setlistProductPack([])
		setdates({
			startDateVOD: null,
			endDateVOD: null,
			startDateEST: null,
			endDateEST: null
		})
		setPrix({
			prixVOD: {
				SD: 0,
				HD: 0,
				UHD: 0
			},
			prixEST: {
				SD: 0,
				HD: 0,
				UHD: 0
			}
		})
		onClose()
	}

	useEffect(() => {
		setIsLoading(true)

		resetForm()
		setOpenning(true)
		let choices = Pricings.getSelectList(false, 2)
		setlistClasseid(choices.sort((a, b) => (a.id > b.id) ? 1 : -1))
		const storedTargets = PricePlanning.getTargets()
		// let priceList = PricingsLists.getItems('50')
		// console.log(priceList)
		setlistTarget(storedTargets)
		const cibles = DomainsItems.getItems(40)
		setCibleList(cibles)
		if (album.id) {
			Companiestransfers.CheckPack(album.id).then((json) => {
				setIsPackExist(json.data ? json.data : false)
				//setModeEdit(json.data ? false : true)
				if (!json.data) {
					setcheckedClassId(true)
					setcheckedEst(true)
					setCheckedVod(true)
					setCheckedEstPrice(true)
					setCheckedVodPrice(true)
					//setModeEdit(true)
				}
				setOpenning(false)
			}
			)
			PricePlanningRequest.getPrices(album.id, 2).then((json) => {
				setOpenning(true)
				if (json.data) {
					setDefaultContract(json.data)
					setdates({
						startDateVOD: json.data.find(s => s.modeCommercialization === 'VOD') ? json.data.filter(s => s.modeCommercialization === 'VOD')[0].startDate : null,
						//endDateVOD: json.data.find(s => s.modeCommercialization === 'VOD') ? json.data.filter(s => s.modeCommercialization === 'VOD')[0].endDate : null,
						startDateEST: json.data.find(s => s.modeCommercialization === 'EST') ? json.data.filter(s => s.modeCommercialization === 'EST')[0].startDate : null,
						//endDateEST: json.data.find(s => s.modeCommercialization === 'EST') ? json.data.filter(s => s.modeCommercialization === 'EST')[0].endDate : null
					})
					setcheckedEst(json.data.find(s => s.modeCommercialization === 'EST'))
					setCheckedVod(json.data.find(s => s.modeCommercialization === 'VOD'))
					setCheckedEstPrice(json.data.find(s => s.modeCommercialization === 'EST'))
					setCheckedVodPrice(json.data.find(s => s.modeCommercialization === 'VOD'))
					setOpenning(false)
				}
				if (album.pricingClassId)
					handleClasseChange(album.pricingClassId)
				setOpenning(false)

			})
		}

		setIsLoading(false)

	}, [open])

	const buildListPack = () => {
		setIsLoading(true)
		Companiestransfers.GetTransferPackByAlbum(album.id).then((json) => {
			if (json.data && json.data.companiesproducts) {

				buildProductList(json.data.companiesproducts)
				setIsLoading(false)
			}
		})

	}

	const buildProductList = (companiesproduct) => {
		//const companiesproduct = json.data.companiesproducts
		if (companiesproduct.length !== 0) setIsPackExist(true)
		setIsLoading(true)
		const listVOD = companiesproduct.filter(s => s.typeId === 1 || s.typeId === 2)
		const listEST = companiesproduct.filter(s => s.typeId !== 1 && s.typeId !== 2)
		setdates({
			startDateVOD: listVOD && listVOD.filter(s => s.startDate !== null).sort((a, b) => new Date(a.startDate) - new Date(b.startDate)) && listVOD.filter(s => s.startDate !== null).sort((a, b) => new Date(a.startDate) - new Date(b.startDate))[0] ? listVOD.filter(s => s.startDate !== null).sort((a, b) => new Date(a.startDate) - new Date(b.startDate))[0].startDate :dates.startDateVOD,
			endDateVOD: listVOD && listVOD.filter(s => s.endDate !== null).sort((a, b) => new Date(a.endDate) - new Date(b.endDate)) && listVOD.filter(s => s.endDate !== null).sort((a, b) => new Date(a.endDate) - new Date(b.endDate)).reverse()[0] ? listVOD.filter(s => s.endDate !== null).sort((a, b) => new Date(a.endDate) - new Date(b.endDate)).reverse()[0].endDate : dates.endDateVOD,
			startDateEST: listEST && listEST.filter(s => s.startDate !== null).sort((a, b) => new Date(a.startDate) - new Date(b.startDate)) && listEST.filter(s => s.startDate !== null).sort((a, b) => new Date(a.startDate) - new Date(b.startDate))[0] ? listEST.filter(s => s.startDate !== null).sort((a, b) => new Date(a.startDate) - new Date(b.startDate))[0].startDate : dates.startDateEST,
			endDateEST: listEST && listEST.filter(s => s.endDate !== null).sort((a, b) => new Date(a.endDate) - new Date(b.endDate)) && listEST.filter(s => s.endDate !== null).sort((a, b) => new Date(a.endDate) - new Date(b.endDate)).reverse()[0] ? listEST.filter(s => s.endDate !== null).sort((a, b) => new Date(a.endDate) - new Date(b.endDate)).reverse()[0].endDate : dates.endDateEST
		}
		)

		const groupedData = companiesproduct.reduce((acc, cur) => {
			if (acc[cur.transferId]) {
				acc[cur.transferId].push(cur)
			} else {
				acc[cur.transferId] = [cur]
			}
			return acc
		}, {})
		setlistProductPack(groupedData)
	}
	const handleChangeInput = (event, type) => {
		setsearchValue(type === 'reset' ? '' : event.target.value)
	}
	const searchAlbum = () => {
		//if(searchValue !=='' && !defaultalbum)
		CompaniesAlbums.getAlbum(searchValue).then((data) => {
			setAlbum(data.data)
		})
	}
	const handlePanel = (panel) => {
		setExpanded({ ...expanded, [panel]: !expanded[panel] })
	}
	const RegularisePack = () => {
		setProgressBarOpen(true)
		setRegPack(false)
		return new Promise((resolve, reject) => {
			Companiestransfers.UpdateProductPack(album.id, albumclasseid, dates.startDateVOD, dates.endDateVOD, dates.startDateEST, dates.endDateEST, 2)
				.then((json) => {

					if (json.data && json.data.companiesproducts) {

						setModeEdit(false)
						buildProductList(json.data.companiesproducts)
						setIsLoading(false)
						// setInsertedItem(json.data.companiesproducts[0].inserted)
						// setUpdatedItem(json.data.companiesproducts[0].updated)

						setSucced(true)
						setError(false)
					}
					//resetForm()
					resolve()
				}
				)
				.catch((error) => {
					setErrorMessage('Erreur de génération des produits')
					setIsLoading(false)
					setSucced(false)
					setError(true)
					reject()
				})
				.then(apiError => {
					// Vous pouvez maintenant accéder au message d'erreur retourné par le contrôleur
					if (apiError) {
						console.error('Erreur de l\'API:', apiError.message);
					}
				})

		})
	}
	const ValidateSavePack = () => {
		let errorList = []
		if (!checkedClassId && !checkedEst && !checkedVod) {
			errorList.push(" Veuillez sélectionner au moins un nouveau prix et/ou une date de publication avant de valider.")
			setOpenAlert(true)
		}
		// if (checkedEst &&  (dates.startDateEST == null ||  dates.endDateEST == null)) {
		// 	errorList.push("Dates EST ne doivent pas être nulles.")
		// 	setOpenAlert(true)
		// }
		// if (checkedVod && (dates.startDateVOD == null ||   dates.endDateVOD == null)) {
		// 	errorList.push("Dates VOD  ne doivent pas être nulles.")
		// 	setOpenAlert(true)
		// }
		if (checkedEst && (dates.startDateEST > dates.endDateEST)) {
			errorList.push("Date de fin EST doit être supérieur à la date du début EST.")
			setOpenAlert(true)
		}
		if (checkedVod && (dates.startDateVOD > dates.endDateVOD)) {
			errorList.push("Date de fin VOD doit être supérieur à la date du début VOD.")
			setOpenAlert(true)
		}
		if (checkedEst && dates.startDateEST == null) {
			errorList.push(" Veuillez renseingner une date de début EST.")
			setOpenAlert(true)
		}
		if (checkedVod && dates.startDateVOD == null ) {
			errorList.push(" Veuillez renseingner une date de début VOD.")
			setOpenAlert(true)
		}
		if (!isPackExist) {
			if (checkedClassId && albumclasseid === 0 || (checkedClassId && albumclasseid === 0)) {
				errorList.push(" Veuillez sélectionner un nouveau prix avant de valider.")
				setOpenAlert(true)
			}
		}
		setAlerteMsg(createListMessageError(errorList))
		return errorList.length === 0 ? true : false
	}
	const createListMessageError = (list) => (
		<ul >
			{list ? list.map(item => (
				<li>{item}</li>
			)) : <div />}
		</ul>
	)
	const updateMultipleTransfersRights = (itemToBeUpdated) => {

		return new Promise((resolve, reject) => {
			dataProvider(UPDATE, 'PricePlanning/TransfersRights', { id: itemToBeUpdated.Id, transfersRights: itemToBeUpdated }).then(
				(json) => {
					// this.setState({
					// 	updateProgress: this.state.updateProgress + 1,
					// 	numberOfSuccess: this.state.numberOfSuccess + 1
					// })

					resolve()
				}
			).catch((error) => {
				// Logger.debug(`Erreur durant la modification des prix : ${error.message} - Transfers right id ${itemToBeUpdated.Id}`)
				// this.setState({
				// 	updateProgress: this.state.updateProgress + 1
				// })
			})


		}
		)
	}
	const UpdatePrices = (transferList) => {
		const userId = parseInt(Token.getId(), 10)
		let valuesToBeUpdated = []
		if (checkedVodPrice) {
			if (prix.prixVOD.SD !== classePrix.prixVOD.SD) {
				transferList.filter(s => s.modeComm === 'VOD' && s.productFormat === 'SD').forEach(element => {
					let currentValue = {
						Id: element.id,
						Price: prix.prixVOD.SD,
						ModifiedBy: userId,
						Recordid: element.recordid,
						IsPriceModified: true,
						IsDateModified: false
					}
					valuesToBeUpdated.push(currentValue)
				})
			}
			if (prix.prixVOD.HD !== classePrix.prixVOD.HD) {
				transferList.filter(s => s.modeComm === 'VOD' && s.productFormat === 'HD').forEach(element => {
					let currentValue = {
						Id: element.id,
						Price: prix.prixVOD.HD,
						ModifiedBy: userId,
						Recordid: element.recordid,
						IsPriceModified: true,
						IsDateModified: false
					}
					valuesToBeUpdated.push(currentValue)
				})
			}
			if (prix.prixVOD.UHD !== classePrix.prixVOD.UHD) {
				transferList.filter(s => s.modeComm === 'VOD' && s.productFormat === 'UHD').forEach(element => {
					let currentValue = {
						Id: element.id,
						Price: prix.prixVOD.UHD,
						ModifiedBy: userId,
						Recordid: element.recordid,
						IsPriceModified: true,
						IsDateModified: false
					}
					valuesToBeUpdated.push(currentValue)
				})
			}
		}
		if (checkedEstPrice) {
			if (prix.prixEST.SD !== classePrix.prixEST.SD) {
				transferList.filter(s => s.modeComm === 'EST' && s.productFormat === 'SD').forEach(element => {
					let currentValue = {
						Id: element.id,
						Price: prix.prixEST.SD,
						ModifiedBy: userId,
						Recordid: element.recordid,
						IsPriceModified: true,
						IsDateModified: false
					}
					valuesToBeUpdated.push(currentValue)
				})
			}
			if (prix.prixEST.HD !== classePrix.prixEST.HD) {
				transferList.filter(s => s.modeComm === 'EST' && s.productFormat === 'HD').forEach(element => {
					let currentValue = {
						Id: element.id,
						Price: prix.prixEST.HD,
						ModifiedBy: userId,
						Recordid: element.recordid,
						IsPriceModified: true,
						IsDateModified: false
					}
					valuesToBeUpdated.push(currentValue)
				})
			}
			if (prix.prixEST.UHD !== classePrix.prixEST.UHD) {
				transferList.filter(s => s.modeComm === 'EST' && s.productFormat === 'UHD').forEach(element => {
					let currentValue = {
						Id: element.id,
						Price: prix.prixEST.UHD,
						ModifiedBy: userId,
						Recordid: element.recordid,
						IsPriceModified: true,
						IsDateModified: false
					}
					valuesToBeUpdated.push(currentValue)
				})
			}
		}
		valuesToBeUpdated.forEach(element => {
			updateMultipleTransfersRights(element)

		})

	}
	const ValidateChange =()=>{
		if(ValidateSavePack()){
			setValidate(true)
		}
	}		

	const SaveChange = () => {
		setIsLoading(true)
		setValidate(false)
		let actionId = 0
		//if (ValidateSavePack()) {
			setProgressBarOpen(true)
			if (!isPackExist) actionId = 1
			else
				if (regPack) actionId = 2
				else {
					if (checkedClassId && !checkedVod && !checkedEst && album.pricingClassId !== albumclasseid)
						actionId = 5
					else if (!checkedClassId && album.pricingClassId === albumclasseid && (checkedVod || checkedEst))
						actionId = 4
					else if (checkedClassId && (checkedVod || checkedEst) && album.pricingClassId !== albumclasseid)
						actionId = 3
				}

			if (checkedClassId || checkedVod || checkedEst) {
				return new Promise((resolve, reject) => {
					Companiestransfers.UpdateProductPack(album.id, albumclasseid, checkedVod ? dates.startDateVOD : null, checkedVod ? dates.endDateVOD : null, checkedEst ? dates.startDateEST : null, checkedEst ? dates.endDateEST : null, actionId)
						.then((json) => {

							CompaniesAlbums.getAlbum(album.id).then((res) => {
								setAlbum(res.data)
								if (res.data)
									setAlbumclasseid(res.data.pricingClassId)
							})

							if (json.data) {
								if (json.data.companiesproducts.length === 0) {
									setErrorMessage("Aucun programme n'a été trouvé pour cet album.")
									setError(true)
								}
								else {
									resetForm()
									buildProductList(json.data.companiesproducts)
									// setInsertedItem(json.data.companiesproducts[0].inserted)
									// setUpdatedItem(json.data.companiesproducts[0].updated)
									setSucced(true)
									setError(false)
								}
								setModeEdit(false)
								setIsLoading(false)

								dataProvider(GET_LIST, 'PricePlanning/TransfersRights', { pagination: { page: 1, perPage: 10 }, sort: { field: {}, order: {} }, filter: { objectId: album.id, objectTypeId: '2' } }).then((listtr) => {
									if (listtr.data && listtr.data.length !== 0) {
										UpdatePrices(listtr.data.transfersRights)
										buildListPack()
									}
								})
							}

							resolve()
						}
						)
						.catch((error) => {
							//setIsLoading(false)
							//Logger.debug(error)
							setErrorMessage('Erreur de génération des produits')
							setIsLoading(false)
							setSucced(false)
							setError(true)
							reject()
						}).then(apiError => {
							// Vous pouvez maintenant accéder au message d'erreur retourné par le contrôleur
							if (apiError) {
								console.error('Erreur de l\'API:', apiError.message);
							}
						})

				})
			//}
		}
		setIsLoading(false)
		//resetForm()

	}
	const endGeneration = () => {
		setProgressBarOpen(false)
		setSucced(false)
		setError(false)
	}
	const progressModalContent = {
		title: "Génération des produits",
		closeButtonText: "FERMER",
		operationFinished: isError ? errorMsg : 'Produits ajoutés / modifiés avec succès'
		// <div>Produits générés avec succès <br/> {insertItem} Lignes ont été ajoutées <br/> {updatedItem} Lignes ont été modifiées</div>
	}
	const cancelChange = () => {
		resetForm()
		if (listProductPack.length !== 0) buildListPack()
	}
	const handleDateChange = (newDate, fieldName) => {
		setdates({
			...dates,
			[fieldName]: moment(newDate).format('YYYY-MM-DDTHH:mm:ss')//.format('YYYY-MM-DDTHH:mm:ss'),
		})
	}
	const handleClasseChange = (value) => {
		setAlbumclasseid(value)
		const formats = DomainsItems.getItems(29)
		const SDformat = formats.find(d => d.label === 'SD').itemid
		const HDformat = formats.find(d => d.label === 'HD').itemid
		const UHDformat = formats.find(d => d.label === 'UHD').itemid
		let pricingslists = []
		let selectedClassificationId = value
		dataProvider(GET_LIST, 'Pricingslists', { pagination: {}, sort: { field: {}, order: {} }, filter: { pricingid: selectedClassificationId } }).then((json) => {
			if (json.data) {
				pricingslists = json.data
				const pricingsEST = pricingslists.filter(w => w.pricingline.commercialtypeid === 7 && w.pricingid === selectedClassificationId)
				const pricingsVOD = pricingslists.filter(w => w.pricingline.commercialtypeid >= 1 && w.pricingline.commercialtypeid <= 6 && w.pricingid === selectedClassificationId)

				setClassePrix({
					prixVOD: {
						SD: pricingsVOD.some(f => f.pricingline.formatid === SDformat) ? pricingsVOD.find(f => f.pricingline.formatid === SDformat).pricingline.price : null,
						HD: pricingsVOD.some(f => f.pricingline.formatid === HDformat) ? pricingsVOD.find(f => f.pricingline.formatid === HDformat).pricingline.price : null,
						UHD: pricingsVOD.some(f => f.pricingline.formatid === UHDformat) ? pricingsVOD.find(f => f.pricingline.formatid === UHDformat).pricingline.price : null
					},
					prixEST: {
						SD: pricingsEST.some(f => f.pricingline.formatid === SDformat) ? pricingsEST.find(f => f.pricingline.formatid === SDformat).pricingline.price : null,
						HD: pricingsEST.some(f => f.pricingline.formatid === HDformat) ? pricingsEST.find(f => f.pricingline.formatid === HDformat).pricingline.price : null,
						UHD: pricingsEST.some(f => f.pricingline.formatid === UHDformat) ? pricingsEST.find(f => f.pricingline.formatid === UHDformat).pricingline.price : null
					}
				})
				setPrix({
					prixVOD: {
						SD: pricingsVOD.some(f => f.pricingline.formatid === SDformat) ? pricingsVOD.find(f => f.pricingline.formatid === SDformat).pricingline.price : null,
						HD: pricingsVOD.some(f => f.pricingline.formatid === HDformat) ? pricingsVOD.find(f => f.pricingline.formatid === HDformat).pricingline.price : null,
						UHD: pricingsVOD.some(f => f.pricingline.formatid === UHDformat) ? pricingsVOD.find(f => f.pricingline.formatid === UHDformat).pricingline.price : null
					},
					prixEST: {
						SD: pricingsEST.some(f => f.pricingline.formatid === SDformat) ? pricingsEST.find(f => f.pricingline.formatid === SDformat).pricingline.price : null,
						HD: pricingsEST.some(f => f.pricingline.formatid === HDformat) ? pricingsEST.find(f => f.pricingline.formatid === HDformat).pricingline.price : null,
						UHD: pricingsEST.some(f => f.pricingline.formatid === UHDformat) ? pricingsEST.find(f => f.pricingline.formatid === UHDformat).pricingline.price : null
					}
				})
			}
		})
		// PricePlanningRequest.getPricinglist(selectedClassificationId).then((json)=>{
		// 	console.log(json.data)
		// 	pricingslists = json.data
		// })


	}
	const UpdatePrice = (event, type, format) => {
		let prixVod = prix.prixVOD
		let prixEst = prix.prixEST
		if (type === 'VOD') {
			prixVod = {
				...prix.prixVOD,
				[format]: event.target.value
			}
		}
		if (type === 'EST') {
			prixEst = {
				...prix.prixEST,
				[format]: event.target.value
			}
		}
		let price = {
			prixVOD: prixVod,
			prixEST: prixEst
		}
		setPrix(price)
	}
	// const handleSearchPrices = ( key) => {
	// 	// eslint-disable-next-line react/prop-types
	// 	const { crudGetList, onPricePlanningChangePriceLine } = this.props
	// 	initialize(REDUX_FORM_PRICEPLANNING_UPDATE)

	// 	crudGetList(
	// 		'Pricingslists', // resource
	// 		{}, // pagination
	// 		{}, // sort
	// 		{ pricingid: key } //filter
	// 	)

	// 	onPricePlanningChangePriceLine(key)
	// }

	const UpdatePack = () => {
		onPricePlanningFirstLoad()
		fetchStart()
		//reset the redux store and set the content type
		onExpandUpdateForm(false)
		onChangeContentFilter()
		changeContentType(2)
		change(REDUX_FORM_PRICEPLANNING_LOAD, 'objectId', album.id)
		window.open('/#' + Links.PlanningPricePackLink(album.id))
	}
	return (
		<div>
			<ThemeProvider theme={getMuiTheme()}>
				<div>
					<Dialog
						open={open}
						TransitionComponent={Transition}
						keepMounted
						disableEscapeKeyDown={true}
						disableBackdropClick={true}
						onClose={CloseForm}
						aria-labelledby="alert-dialog-slide-title"
						aria-describedby="alert-dialog-slide-description"
					>
						<div className={classes.dialogHeader}>
							ASSISTANT PACK {!isPackExist && modeEdit ? '- CRÉATION': ''}
							<Button onClick={CloseForm} className={classes.closeIcon} >
								<CloseIcon />
							</Button>
						</div>
						<div className={classes.dialogContent}>
							<div>

								<Card >
									<CardContent className={classes.cardContent} style={{ minWidth: '1300px' }}>
										<div className={classes.root}>
											{openning && <div className={classes.circularProgress}><CircularProgress size={50} thickness={3} value={25} /> </div>}

											<Grid container spacing={1} className={classes.gridForm}  >
												<Grid item xs={2} style={{ paddingLeft: '50px' }}>
													<CustomTextField label="Album ID" value={searchValue} handleChange={e => handleChangeInput(e, '')} //style={{ paddingLeft: '50px' }}
														valueReset={(e) => handleChangeInput(e, 'reset')} create={defaultalbum ? false : true}
														fullWidth="true" removeicon={true} />
												</Grid>
												{/* <Grid item xs={2}>
													<IconButton type="button" aria-label="search" onClick={() => searchAlbum()} style={{ marginRight: '20px' }}  >
														<SearchIcon />
													</IconButton>
												</Grid> */}
												<Grid item xs={6}>
													<CustomTextField label="Libellé Album" value={album.name} create="false" fullWidth="true" />
												</Grid>
												<Grid container spacing={2}>
													<Grid item xs={2} >
														<CustomCheckBox disabled={!modeEdit} value={checkedClassId} onChange={() => setcheckedClassId(!checkedClassId)} />
													</Grid>
													{!modeEdit ?
														<Grid item xs={6}>
															<CustomTextField label="Classe de prix associée" required={true}
																value={listClasseid.filter(s => s.id == album.pricingClassId) && listClasseid.filter(s => s.id == album.pricingClassId)[0] ? listClasseid.filter(s => s.id == album.pricingClassId)[0].name : ''}
																create="false" fullWidth />
														</Grid>
														:
														<Grid item xs={6} >
															<CustomSelect
																label="Classe de prix associée"
																value={albumclasseid}
																values={listClasseid}
																disabled={!modeEdit || !checkedClassId}
																fullWidth
																propsType='pricingClass'
																required={true}
																onChange={event => handleClasseChange(event.target.value)}
															/>
														</Grid>

													}
												</Grid>

												<Grid item xs={10} >

													<Grid container spacing={1} style={{ paddingLeft: '30px' }} >
														<Grid item xs={2} >
															<CustomCheckBox disabled={!modeEdit} value={checkedVod} label='Date VOD' labelPlacement='end' onChange={() => setCheckedVod(!checkedVod)} />
														</Grid>
														<Grid item xs={4}>
															<NewDatePicker input={dates.startDateVOD} required={true} dateType='start' startDateSource="vodStartDate" label='VOD' classes={classes} disabled={!checkedVod || !modeEdit} handleDateChange={(newDate) => handleDateChange(newDate, 'startDateVOD')} />
														</Grid>
														<Grid item xs={4}>
															<NewDatePicker input={dates.endDateVOD} required={false} dateType='end' endDateSource="vodEndDate" label='VOD' classes={classes} disabled={!checkedVod || !modeEdit} handleDateChange={(newDate) => handleDateChange(newDate, 'endDateVOD')} />
														</Grid>

													</Grid>
													<Grid container spacing={1} style={{ paddingLeft: '30px' }}>
														<Grid item xs={2} >
															<CustomCheckBox disabled={!modeEdit} value={checkedEst} label='Date EST' labelPlacement='end' onChange={() => setcheckedEst(!checkedEst)} />
														</Grid>
														<Grid item xs={4}>
															<NewDatePicker input={dates.startDateEST} required={true} dateType='start' startDateSource="estStartDate" label='EST' classes={classes} disabled={!checkedEst || !modeEdit} handleDateChange={(newDate) => handleDateChange(newDate, 'startDateEST')} />
														</Grid>
														<Grid item xs={4}>
															<NewDatePicker input={dates.endDateEST} required={false} dateType='end' endDateSource="estEndDate" label='EST' classes={classes} disabled={!checkedEst || !modeEdit} handleDateChange={(newDate) => handleDateChange(newDate, 'endDateEST')} />
														</Grid>

													</Grid>
												</Grid>
												<Grid item xs={7} >
													{/* //style={{marginLeft : '35px'}}> */}
													<Grid container spacing={1} style={{ paddingLeft: '30px' }}>
														<Grid item xs={3} >
															<CustomCheckBox disabled={!modeEdit} label='Prix VOD' value={checkedVodPrice} onChange={() => setCheckedVodPrice(!checkedVodPrice)} labelPlacement='end' />
														</Grid>
														<Grid item xs={3}>
															<CustomPriceInput label="SD" required={true} value={prix.prixVOD ? prix.prixVOD.SD : 0} readOnly={!checkedVodPrice || !modeEdit} handleChange={(e) => UpdatePrice(e, 'VOD', 'SD')} />
														</Grid>
														<Grid item xs={3}>
															<CustomPriceInput label="HD" required={true} value={prix.prixVOD ? prix.prixVOD.HD : 0} readOnly={!checkedVodPrice || !modeEdit} handleChange={(e) => UpdatePrice(e, 'VOD', 'HD')} />
														</Grid>
														<Grid item xs={3}>
															<CustomPriceInput label="4K" required={true} value={prix.prixVOD ? prix.prixVOD.UHD : 0} readOnly={!checkedVodPrice || !modeEdit} handleChange={(e) => UpdatePrice(e, 'VOD', 'UHD')} />
														</Grid>

													</Grid>
													<Grid container spacing={1} style={{ paddingLeft: '30px' }} >
														<Grid item xs={3} >
															<CustomCheckBox disabled={!modeEdit} label='Prix EST' value={checkedEstPrice} onChange={() => setCheckedEstPrice(!checkedEstPrice)} labelPlacement='end' />
														</Grid>
														<Grid item xs={3}>
															<CustomPriceInput label="SD" required={true} value={prix.prixEST ? prix.prixEST.SD : 0} readOnly={!checkedEstPrice || !modeEdit} handleChange={(e) => UpdatePrice(e, 'EST', 'SD')} />
														</Grid>
														<Grid item xs={3}>
															<CustomPriceInput label="HD" required={true} value={prix.prixEST ? prix.prixEST.HD : 0} readOnly={!checkedEstPrice || !modeEdit} handleChange={(e) => UpdatePrice(e, 'EST', 'HD')} />
														</Grid>
														<Grid item xs={3}>
															<CustomPriceInput label="4K" required={true} value={prix.prixEST ? prix.prixEST.UHD : 0} readOnly={!checkedEstPrice || !modeEdit} handleChange={(e) => UpdatePrice(e, 'EST', 'UHD')} />
														</Grid>

													</Grid>
			 
												</Grid>
												<Grid item xs={1}></Grid>
												<Grid item xs={3} style={{ marginBottom: '10px' }}>
													<Button onClick={() => buildListPack()} disabled={!isPackExist} className={classes.button} style={{ marginTop: 0 }}>
														<VisibilityIcon className={classes.leftIcon} />
														Aperçu
													</Button>
													{modeEdit ?
														<div>
															<Button color="primary" variant="contained" onClick={() => ValidateChange()} className={classes.buttonCheck}>
																<CheckIcon className={classes.leftIcon} />
																Valider
															</Button>
															<Button color="secondary" variant="contained" onClick={() => cancelChange()} className={classes.buttonCancel}>
																<CancelIcon className={classes.leftIcon} />
																Annuler
															</Button>
														</div>
														:
														<div>
															{isPackExist &&
																<Button onClick={() => setRegPack(true)} className={classes.button} disabled={modeEdit || listProductPack.length === 0}>
																	<AssignmentTurnedInIcon className={classes.leftIcon} />Régulariser</Button>

															}
															{isPackExist ? //UpdatePack()
																<Button onClick={() => UpdatePack()} className={classes.button} disabled={modeEdit} target="_blank">
																	<EditIcon className={classes.leftIcon} />Modifier</Button>
																:
																<Button onClick={() => setModeEdit(true)} className={classes.button} disabled={modeEdit}>
																	<EditIcon className={classes.leftIcon} /> Créer</Button>}
														</div>
													}
												</Grid>
											</Grid>
										</div>

										{/* </SimpleForm> */}
									</CardContent>
									<Divider />

									{/* {isLoading && <LinearProgress ></LinearProgress >} */}
									{isLoading && <div className={classes.ProgressBar}><LinearProgress size={50} thickness={3} value={25} /> </div>}

									<CardContent className={classes.cardContent} style={{ minWidth: '1200px' }}>
										{listProductPack && Object.keys(listProductPack).map(function (key, index) {
											return (
												<div>
													<Accordion square expanded={expanded[key]} onChange={() => { handlePanel(key) }} style={{ marginBottom: '10px', marginTop: '0px' }}>
														<AccordionSummary
															style={{ backgroundColor: "#288DB1" }}
															expandIcon={<ExpandMore style={{ color: "white" }} />} >
															<Typography className={classes.title}>
																<StyledBadge badgeContent={listProductPack[key].length} showZero color="blue">
																	{listTaget.filter(s => s.id == key) && listTaget.filter(s => s.id == key)[0] ? listTaget.filter(s => s.id == key)[0].label : 'UNDEFINED PACK'}
																</StyledBadge>
															</Typography>
														</AccordionSummary>
														<AccordionDetails style={{ padding: 10, display: 'flex', flexDirection: 'row', flexWrap: 'wrap' }}>
															<TableContainer component={Paper}>
																<Table className={classes.table} aria-label="simple table">
																	<TableHead>
																		<TableRow>
																			<TableCell>Nom</TableCell>
																			<TableCell align="left">Type de Commercialisation</TableCell>
																			<TableCell align="right">Prix</TableCell>
																			<TableCell align="center">Date de début</TableCell>
																			<TableCell align="center">Date de fin</TableCell>
																			<TableCell align="center">Nb contenus</TableCell>
																		</TableRow>
																	</TableHead>
																	<TableBody>
																		{listProductPack[key].map((row) => (
																			<TableRow key={row.transferId}>
																				<TableCell component="th" scope="row">
																					{row.name}
																				</TableCell>
																				<TableCell align="left">{cibleList && cibleList.filter(s => s.itemid == row.typeId) && cibleList.filter(s => s.itemid == row.typeId)[0] && cibleList.filter(s => s.itemid == row.typeId)[0].label}</TableCell>
																				<TableCell align="right">{row.price}</TableCell>
																				<TableCell align="center">{row.startDate === null ? '' : DateFormatter.getDayNameDate(row.startDate)}</TableCell>
																				<TableCell align="center">{row.endDate === null ? '' : DateFormatter.getDayNameDate(row.endDate)}</TableCell>
																				<TableCell align="center">{row.count}</TableCell>

																			</TableRow>
																		))}
																	</TableBody>
																</Table>
															</TableContainer>
														</AccordionDetails>
													</Accordion>
												</div>
											)
										})
										}
									</CardContent>
								</Card>
							</div>
						</div>
						<div className={classes.dialogFooter}>
							<Button onClick={CloseForm} color="secondary" variant="contained" className={classes.buttonClose}>
								<CancelIcon className={classes.leftIcon} />Fermer
							</Button>
						</div>

					</Dialog>
				</div>
				{openAlert && <CustomDialog content={alerteMsg} info="Alerte" open={openAlert} handleClose={() => { setOpenAlert(false) }} maxWidth="500px" />}
				{/* {isSucced ? <CustomAlert  libelle="Pack générer" severity="success" open={isSucced} setOpen={()=>setSucced(false)} /> : null}
 				{isError && <CustomAlert libelle="Erreur lors de la géneration des packs" severity="error" open={isError} setOpen={()=>setError(false)} />} */}
				{regPack && <CustomDisplayDialog info='Alerte' type='window' open={regPack}
					handleClose={() => setRegPack(false)} handleSave={RegularisePack}
					content="Vous êtes sur le point de mètre à jour  tous les produits, Voulez vous continuer ?" maxWidth='200px' />}
				{validate && <CustomDisplayDialog info='Alerte' type='window' open={validate}
					handleClose={() => setValidate(false)} handleSave={()=>SaveChange()}
					content="Vous êtes sur le point de créer les produits, Voulez vous continuer ?" maxWidth='200px' />}
				{/* {(isSucced || isError) && <CustomDialog content={isSucced ? 'Pack généré avec succes' : 'Erreur lors de génération des packs'} info={isSucced ? 'INFO' : 'ERROR'} open={isSucced || isError} 
						handleClose={() => (isSucced && setSucced(false)) ||(isError && setError(false))}  maxWidth="500px" />}
				 */}
				<ProcessLoader modalOpen={progressBarOpen}
					onClose={() => endGeneration()}
					isOperationFinished={isSucced || isError}
					progressModalContent={progressModalContent}
				/>
			</ThemeProvider >
		</div>
	)
}
const enhance = compose(
	connect(mapStateToProps, mapDispatchToProps),
	withStyles(styles)
)
const mapStateToProps = state => ({
	...state,
	contentPriceLists: state.reducer.pricePlanning.contentPriceLists
})
const mapDispatchToProps = {
	onPricePlanningFirstLoad,
	fetchEnd,
	fetchStart,
	crudGetList,
	crudGetOne,
	showNotification,
	onSelectContent: onSelectContent,
	onExpandUpdateForm,
	onChangeContentFilter,
	reset,
	changeContentType,
	change
}
export default translate(enhance(AssistantPack))
