import { ON_DOMAINS_ITEMS_LOAD } from '../constants/actions'

export function loadingDomainsItems(isLoaded) { 
	return { 
		type: ON_DOMAINS_ITEMS_LOAD, 
		payload: { 
			domainsItemsLoaded : isLoaded
		} 
	}
}
