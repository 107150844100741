import React from 'react'
import { TextInput, SelectInput, NumberInput, SimpleShowLayout } from 'react-admin'
import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import Typography from '@material-ui/core/Typography'
import { DomainsItems } from '../../../tools'
import { CheckboxInput, DatePickerCanal } from '../../common/inputs'
import { withStyles } from '@material-ui/core/styles'

const styles = theme => ({
	cardPaddingMargin: {
		padding: 20,
		margin: 20,
		flex: '1 auto'
	},
	cardDiv: {
		flex: '1 auto'
	},
	flexContent: {
		display: 'flex',
		alignItems: 'flex-start'
	},
	input: {
		display: 'inline-block'
	},
	inputLeft: {
		display: 'inline-block',
		marginLeft: 32
	},
	noMarginLeft: {
		marginLeft: 0
	}
})

const CardCreate = withStyles(styles)(({ classes } = this.props) => (
	<div className={classes.flexContent}>
		<Card className={classes.cardPaddingMargin}>
			<CardContent>
				<Typography variant="title">Droits du Transfert</Typography>
			</CardContent>
			<SimpleShowLayout>
				<TextInput label="fields.label" source="label" />
				<SelectInput label="fields.countryid" source="countryid" choices={DomainsItems.getSelectList(2)} allowEmpty={false} />
				<SelectInput label="fields.modecommid" source="modecommid" choices={DomainsItems.getSelectList(92)} allowEmpty={false} />
				<DatePickerCanal label="fields.startdate" source="startdate" />
				<DatePickerCanal label="fields.enddate" source="enddate" />
				<NumberInput label="fields.price" source="price" />
				<SelectInput label="fields.vatid" source="vatid" choices={DomainsItems.getSelectList(25)} allowEmpty={false} />
				<CheckboxInput label="fields.published" source="published" />
				<CheckboxInput label="fields.exclusive" source="exclusive" />
			</SimpleShowLayout>
		</Card>
		<div className={classes.cardDiv}>
			<Card className={classes.cardPaddingMargin}>
				<CardContent>
					<Typography variant="title">Taux</Typography>
				</CardContent>
				<SimpleShowLayout>
					<NumberInput label="fields.commissionfee" source="commissionfee" />
					<NumberInput label="fields.sacemfee" source="sacemfee" />
					<NumberInput label="fields.sacdfee" source="sacdfee" />
					<NumberInput label="fields.tsafee" source="tsafee" />
				</SimpleShowLayout>
			</Card>
		</div>
	</div>
))

export default CardCreate
