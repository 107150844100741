import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { refreshView as refreshViewAction, showNotification as showNotificationAction } from 'react-admin'
import { translate } from 'react-admin'
import compose from 'recompose/compose'
import { connect } from 'react-redux'
import Grid from '@material-ui/core/Grid'
import Drawer from '@material-ui/core/Drawer'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'
import WatcherFiles from './WatcherFiles'
import { AvatarCustom, FullScreenDialog } from '../common'
import dataProvider from '../../dataProvider'
import { Watcher } from '../../requests'
import { SendIcon, AttachmentIcon } from '../../constants/icons'
import { withStyles } from '@material-ui/core'

const styles = theme => ({
	root: {
		flexGrow: 1,
	},
	list: {
		width: 250,
	},
})

class WatcherIngest extends Component {
	state = { expanded: true, showRightPanel: false, showModal: false, selectedTypeid: null, selectedStateid: null, selectedFilename: null, selectedFilePath: null, modalContent: null }

	constructor(props) {
		super(props)
		this.toggleRightPanel = this.toggleRightPanel.bind(this)
		this.callback = this.callback.bind(this)
		this.handleMoveFileClick = this.handleMoveFileClick.bind(this)
		this.handleOpenShowFileClick = this.handleOpenShowFileClick.bind(this)
		this.onCloseShowFileClick = this.onCloseShowFileClick.bind(this)
	}

	toggleRightPanel = (open) => () => {
		this.setState({ showRightPanel: open })
	}

	callback = (typeid, stateid, filename, filepath) => {
		if (2 === stateid) // Ne pas afficher les actions sur des fichiers en cours de traitement
			this.setState({ showRightPanel: false })
		else
			this.setState({ showRightPanel: true, selectedTypeid: typeid, selectedStateid: stateid, selectedFilename: filename, selectedFilePath: filepath, modalContent: null })
	}

	handleMoveFileClick = (newStateid) => () => {
		console.log('Try to move the file ' + this.state.selectedFilename + ' from ' + this.state.selectedStateid + ' to ' + newStateid)

		dataProvider('CREATE', this.props.resource, { data: { 'typeid': this.state.selectedTypeid, 'oldStateid': this.state.selectedStateid, 'newStateid': newStateid, 'filename': this.state.selectedFilename } })
			.then(response => {
				console.log('File moved')
				this.props.showNotification(response.data, 'info')
			})
			.catch(error => {
				console.log('Error moving the file: ' + error.message)
				//this.props.showNotification(error.message, 'warning')
				this.props.showNotification("Dossier de destination n'existe pas", 'warning')

			})

		setTimeout(() => this.props.refreshView(), 1000)
	}

	handleOpenShowFileClick = (typeid, stateid, filename) => () => {
		console.log('Try to show the file ' + filename)

		let that = this
		Watcher.showFile(typeid, stateid, filename).then(json => {
			console.log('File content:')
			console.log(json)
			that.setState({ showModal: true, modalContent: json })
		})
	}

	onCloseShowFileClick = () => () => {
		this.setState({ showModal: false })
	}

	render() {
		const { ids, data, classes, isLoading, translate } = this.props

		if (isLoading || ids == null || null == data)
			return null

		const items = Object.values(ids).map(id => { return data[id] })

		const drawer = this.state.showRightPanel ?
			(
				<Drawer anchor="right" open={true} onClose={this.toggleRightPanel(false)}>
					<div
						tabIndex={0}
						role="button"
						onKeyDown={this.toggleRightPanel(false)}
						className={classes.list}
					>
						<List component="nav">
							<ListItem key='item_move_top' button onClick={this.handleMoveFileClick(3)} disabled={3 === this.state.selectedStateid || 1 !== this.state.selectedTypeid}>
								<AvatarCustom icon={<SendIcon />} />
								<ListItemText primary={translate('labels.moveToTop')} />
							</ListItem>
							<ListItem key='item_move_normal' button onClick={this.handleMoveFileClick(1)} disabled={1 === this.state.selectedStateid}>
								<AvatarCustom icon={<SendIcon />} />
								<ListItemText primary={translate('labels.moveToNormal')} />
							</ListItem>
							<ListItem key='item_show' button onClick={this.handleOpenShowFileClick(this.state.selectedTypeid, this.state.selectedStateid, this.state.selectedFilename)} disabled={1 !== this.state.selectedTypeid}>
								<AvatarCustom icon={<AttachmentIcon />} />
								<ListItemText primary={translate('labels.show')} />
							</ListItem>
						</List>
					</div>
				</Drawer>
			)
			: null

		const modal = this.state.showModal ? <FullScreenDialog open={true} title={this.state.selectedFilename} xml={this.state.modalContent} onClose={this.onCloseShowFileClick()} /> : null
				console.log(ids)
		return (
			<div>
				<Grid container direction='column' justify='flex-start' alignItems='stretch' className={classes.root} spacing={8}>
					<Grid item key="WatcherIngestItem_1">
						<WatcherFiles
							label={String(translate('labels.workingRepository'))}
							files={null != items.find(item => 1 === item.typeId) ? items.find(item => 1 === item.typeId).files : []}
							total={null != items.find(item => 1 === item.typeId) ? items.find(item => 1 === item.typeId).total : 0}
							callback={this.callback}
						/>
					</Grid>
					<Grid item key="WatcherIngestItem_2">
						<WatcherFiles
							label={String(translate('labels.workInProgress'))}
							files={null != items.find(item => 2 === item.typeId) ? items.find(item => 2 === item.typeId).files : []}
							total={null != items.find(item => 2 === item.typeId) ? items.find(item => 2 === item.typeId).total : 0}
							callback={this.callback}
						/>
					</Grid>
					<Grid item key="WatcherIngestItem_3">
						<WatcherFiles
							label={String(translate('labels.workingTop'))}
							files={null != items.find(item => 3 === item.typeId) ? items.find(item => 3 === item.typeId).files : []}
							total={null != items.find(item => 3 === item.typeId) ? items.find(item => 3 === item.typeId).total : 0}
							callback={this.callback}
						/>
					</Grid>
					<Grid item key="WatcherIngestItem_4">
						<WatcherFiles
							label={String(translate('labels.workingInError'))}
							files={null != items.find(item => 4 === item.typeId) ? items.find(item => 4 === item.typeId).files : []}
							total={null != items.find(item => 4 === item.typeId) ? items.find(item => 4 === item.typeId).total : 0}
							callback={this.callback}
						/>
					</Grid>
				</Grid>
				{drawer}
				{modal}
			</div>
		)
	}
}

WatcherIngest.propTypes = {
	resource: PropTypes.string,
	showNotification: PropTypes.func,
	refreshView: PropTypes.func,
	ids: PropTypes.array,
	data: PropTypes.object,
	classes: PropTypes.object.isRequired,
	isLoading: PropTypes.bool,
	translate: PropTypes.func,
}

export default translate(compose(withStyles(styles), connect(undefined, { refreshView: refreshViewAction, showNotification: showNotificationAction }))(WatcherIngest))
