import React, { Component } from 'react'
import PropTypes from 'prop-types'
import Button from '@material-ui/core/Button'
import { FolderIcon } from '../../../constants/icons'
import { withStyles } from '@material-ui/core/styles'

const styles =   ({
	smallIcon: {
		fontSize: 20,
	},
	statusButton: {
	}
})

class StatusButton extends Component {

	handleClick = () => {
		const { record = {} } = this.props
		const url = `$/#/Contentsworkflow/Product?id=${record.productid}`
		this.myPopUpWin(url, 1200, 900)
	}

	myPopUpWin = (url, width, height) => {
		var leftPosition, topPosition
		//Allow for borders.
		leftPosition = (window.screen.width / 2) - ((width / 2) + 10)
		//Allow for title and status bars.
		topPosition = (window.screen.height / 2) - ((height / 2) + 50)
		//Open the window.
		window.open(url, 'Window2',
			'status=no,height=' + height + ',width=' + width + ',resizable=yes,left='
			+ leftPosition + ',top=' + topPosition + ',screenX=' + leftPosition + ',screenY='
			+ topPosition + ',toolbar=no,menubar=no,scrollbars=no,location=no,directories=no')
	}

	render() {
		const { classes } = this.props

		return <Button onClick={this.handleClick} mini className={classes.statusButton}><FolderIcon className={classes.smallIcon} /></Button>
	}
}

StatusButton.propTypes = {
	record: PropTypes.object,
	classes: PropTypes.object.isRequired,
}

export default withStyles(styles)(StatusButton)
