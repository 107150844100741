
import React, { useEffect,useRef } from 'react'
import { withStyles,createTheme ,ThemeProvider} from '@material-ui/core/styles'
import LinearProgress from '@material-ui/core/LinearProgress'
 
const styles = theme =>  ({
	progress: {
		width: '100%',
		position: 'absolute',
		bottom: 0,
		left: 0,
		margin: 0,
		padding: 0,
		borderBottomLeftRadius: '2px',
		borderBottomRightRadius: '2px',
	},
	
})
  
const CircularProgressWithLabel = props => {
	const { classes, setOpen,severity,stateid } = props
    const [progress, setProgress] = React.useState(0)
 	const getMuiTheme = () => createTheme({
		overrides: {
			MuiLinearProgress:{
					colorPrimary :{
						backgroundColor:  '#5f9ed073' 
					},
					barColorPrimary:{
						backgroundColor:   '#1e5e92'
					}
				}
		},
	})
 
	useEffect(() => {
		let timer
		  setProgress(0)
		  const interval = 1000
		  const steps = 10
		  const stepSize = 100 / steps
		  timer = setInterval(() => {
			setProgress((oldProgress) => {
			  if (oldProgress >= 100) {
				setOpen()
				return 100
			  }
			  return Math.min(oldProgress + stepSize, 100)
			})
		  }, interval)
		return () => {
			setOpen()
			clearInterval(timer)
		}
	  }, [stateid])

	return (
		<ThemeProvider theme={getMuiTheme()}>
			<LinearProgress variant="determinate" value={progress} className={classes.progress}  />
		</ThemeProvider>
	)
}
export default withStyles(styles)(CircularProgressWithLabel)

 