import React from 'react'
import PropTypes from 'prop-types'
import { isArrayLike, orderBy } from '30-seconds-of-code'
import { Pricings } from '../../../tools'
import { MySelectInput } from '.'
import { withStyles } from '@material-ui/core'

const styles =   ({
	pricingDropDown: {
		//marginTop: '6%',
		marginBottom : '0px'
	},
	labelStyle:{
		color:'#288DB1',
		fontWeight: 'bold'
	}
})

const PricingsInput = (props) => {
	const { items, withAll, classes, objectTypeId,...rest } = props
	let choices = Pricings.getSelectList(withAll , objectTypeId)
	choices = isArrayLike(choices) ? choices : (isArrayLike(items) ? items : [])
	choices = orderBy(choices, ['id'], ['asc'])

	return (
		<MySelectInput
		 choices={choices} 
		 InputLabelProps={{
			shrink: true,
			classes: {shrink: classes.labelStyle}
		  }}
		 className = {classes.pricingDropDown}
		 {...rest} />
	)
}

PricingsInput.propTypes = {
	items: PropTypes.array,
	withAll: PropTypes.bool,
	classes: PropTypes.object.isRequired,

}

PricingsInput.defaultProps = {
	withAll: false,
	addLabel: true,
	items: [],
}

export default withStyles(styles)(PricingsInput)