import React from 'react'
import { Field  } from 'redux-form'
import Checkbox from '@material-ui/core/Checkbox'
import { PriceInput } from '../../common/inputs'
import {  withStyles } from '@material-ui/core'

const styles = ({
	checkBoxField :{
		// height: '25px'
		padding: '4px'
	}
})

class CustomPriceInput extends React.Component{
	constructor(props){
		super(props)
		this.state = {
			disabled : true
		}
	}
	shouldComponentUpdate(nextProps,nextState){
		return this.props.selectAllEST !== nextProps.selectAllEST || this.props.selectAllVOD !== nextProps.selectAllVOD 
		|| this.state.disabled !== nextState.disabled ? true : false
	}

	handleCheckboxAction = () => {
		const checkValue = this.state.disabled ? false : true
		this.setState({ disabled : checkValue })
	} 

	renderCheckbox = ({ input, label }) => {
		const { selectAllEST , selectAllVOD} = this.props
		if(input && input.name){
			if(input.name.includes('VOD') && selectAllVOD){
				input.value = true
			}
			if(input.name.includes('EST') && selectAllEST){
				input.value = true
			}
		}
		//console.log(input.name.contains('VOD') )
		// if(selectAllEST && input.name.contains('EST') ){
		// 	input.value =
		// }
 		this.setState({ disabled : input.value ? false : true})
		return (
			<Checkbox label={label}
				style={{color: '#288DB1'}}
				checked={input.value ? true : false}
				onChange={(event) => {input.onChange(event); this.handleCheckboxAction();}}/>
		)
	}
		
 

	render(){
		const { label, source, name, classes, inputValidation, isLargeLabel} = this.props
 		return(
			<div className = {classes.checkBoxField}>
	 			<Field
					name={name}
					label={label}
					component={(event)=>this.renderCheckbox(event,name)}
				/>
				<PriceInput label={label} source={source} className={isLargeLabel ? classes.priceLarge : classes.price} 
				validate={inputValidation} classes = {classes} isLargeLabel = {isLargeLabel} disabled = {this.state.disabled}/>
				{/* <br /> */}
			</div>
		)
	}
}

export default withStyles(styles)(CustomPriceInput)