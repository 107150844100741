import React, { Component } from 'react'
import {  withStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import { CustomTextField } from '../../layout/GlobalStyles'
import CompaniesAlbums from '../../../requests/CompaniesAlbums'
import { DomainsItems } from '../../../tools'
import { connect } from 'react-redux'
import compose from 'recompose/compose'
import { OnUpdateAlbumFiche,onChangeParent } from '../../../actions/companiesAlbums'

const styles = theme => ({
	container: {
		display: 'flex'
	},
	item: {
		marginRight: '1rem',
		marginLeft: '5px'
	},
	formControl: {
		margin: '.5em .5em 1.5em 2.2em',
		minWidth: '230px',
		height: '25px'
	},
	customSelect: {
		//position: 'absolute',
		top: '8px',
		margin: theme.spacing(1),
		//padding:'6px 8px',
		maxWidth: '90px',
		minHeight: '40px',
		backgroundColor: '#fff',
		borderRadius: '4px',
	},
 
})

class AlbumInfoGlobal extends Component {

	constructor(props) {
		super(props)
		this.state = {
			 
		}
	}
	 
	getalbumsType = () => {
		return Object.values(DomainsItems.getItems(21))
	}
	componentDidMount() { 
		const { recordelt,OnUpdateAlbumFiche,onChangeParent ,albumFiche} = this.props
		
		CompaniesAlbums.getAllalbums().then((json) => {
			if (json.data !== undefined) {
				this.setState({ albums: json.data })
			}
		})
		this.setState({ types: this.getalbumsType() })
		if(recordelt.id !== albumFiche.id)
		{
			this.setState({ itemRecord: recordelt })
			this.setState({ typeId: recordelt ? recordelt.typeid : 0 })
			OnUpdateAlbumFiche(recordelt)
			onChangeParent(recordelt.parentid)
		}
		else{
			this.setState({ itemRecord: albumFiche })
			this.setState({ typeId: albumFiche ? albumFiche.typeid : 0 })
			OnUpdateAlbumFiche(albumFiche)
			onChangeParent(albumFiche.parentid)
		}
	}
	

 
 
	closeSearch = (event) => {
 		this.setState({ openSearch: false })
	}

	render() {
		const { recordelt,  create, type,  handleChangeGeneral } = this.props 
		return (
				<Grid container direction="row" justifyContent="space-between" >
 						<Grid container>
							{(type !== 'create') ?
							<Grid item   xs={4}>
								<CustomTextField label="Identifiant d'album" required={true} value={recordelt.id} fullWidth={false}   />
							</Grid> : <Grid/>}
							<Grid item   xs={(type !== 'create') ? 8 : 12}>
								<CustomTextField label="Nom d'album" value={recordelt.name}  required={true} handleChange={handleChangeGeneral} width='35%' fullWidth={true} create={create} />
							</Grid>
							 
						</Grid>
 				</Grid>
 

		)
	}
}
const mapStateToProps = state => ({
	...state,
	selectedAlbumParent: state.reducer.albums.selectedAlbumParent,
	albumFiche: state.reducer.albums.albumFiche,
	AlbumsData: state.reducer.albums.AlbumsData,

})

const mapDispatchToProps = ({
	OnUpdateAlbumFiche,
	onChangeParent
})

const enhance = compose(
	connect(mapStateToProps, mapDispatchToProps),
	withStyles(styles)
)

export default enhance(AlbumInfoGlobal)

