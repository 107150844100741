import { ON_LOAD_FAVORITE_BY_OBJECT } from '../constants/actions'

export function onLoadfavorite(favorites){
	return {
		type : ON_LOAD_FAVORITE_BY_OBJECT,
		payload : {
			ContactFavorite : favorites
		}
	}
}
 