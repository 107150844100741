import React from 'react'
import PropTypes from 'prop-types'
import { isNumber, isArrayLike } from '30-seconds-of-code'
import { DomainsItems } from '../../../tools'
import { MySelectInput } from '.'

const DomainsItemsInput = (props) => {
	const { domainid, items, withAll, ...rest } = props

	let choices
	if (isNumber(domainid))
		choices = DomainsItems.getSelectList(domainid, withAll)

	return (
		<MySelectInput choices={isArrayLike(choices) ? choices : (isArrayLike(items) ? items : [])} {...rest} />
	)
}

DomainsItemsInput.propTypes = {
	domainid: PropTypes.number.isRequired,
	items: PropTypes.array,
	withAll: PropTypes.bool,
}

DomainsItemsInput.defaultProps = {
	withAll: false,
	addLabel: true,
	items: [],
}

export default DomainsItemsInput