import React, { Component } from 'react'
import { connect } from 'react-redux'
import compose from 'recompose/compose'

import Checkbox from '@material-ui/core/Checkbox'
import Grid from '@material-ui/core/Grid'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Radio from '@material-ui/core/Radio'
import RadioGroup from '@material-ui/core/RadioGroup'
import Typography from '@material-ui/core/Typography'
import { WatchLaterIcon, QueryBuilderIcon, WarningIcon } from '../../../constants/icons'
import { withStyles } from '@material-ui/core/styles'
import { InputLabel } from '@material-ui/core'
import Chip from '@material-ui/core/Chip'
import Button from '@material-ui/core/Button'
import SimpleTabs from './SimpleTabs'
import { onPromotionUpdate, onGetPromo } from '../../../actions/Promotion'
import { CustomTextField ,CustomSwitch} from '../../layout/GlobalStyles'

const styles = (theme) =>  ({
	boxStyle: {
		boxShadow: 'rgba(9, 30, 66, 0.25) 0px 1px 1px, rgba(9, 30, 66, 0.13) 0px 0px 1px 1px',
		padding: '1em',
		margin: 0,
		width: 'auto',
		borderRadius: '2px',
		paddingLeft: '4em'
	},
	boxLabel: {
		fontStyle: 'italic',
		fontSize: '.8em',
	},
	boxLabelMin: {
		fontStyle: 'italic',
		fontSize: '.8em',
		position: 'absolute',
		bottom: '25%'
	},
	boxLabelContainer: {
		width: '-webkit-fill-available',
		paddingLeft: '.5em'
	},
	boxLabelMinContainer: {
		position: 'relative',
    	width: '6em'
	},
	antiPadding: {
		marginLeft: '-3em'
	},
	chip: {
		color: '#fff',
		backgroundColor : '#288DB1',
		'&:hover': {
			backgroundColor: '#1c627b'
		},
		'&:active': {
			backgroundColor: '#1c627b'
		},
		'&:focus': {
			background: '#288DB1'
		},
	},
	label: {
		marginTop: '0',
		marginBottom: '1em',
		color: '#288DB1',
		fontWeight: 'bold',
		fontSize: '13px',
		fontFamily: 'Roboto, Helvetica, Arial, sans-serif',
		lineHeight: 1,
	},
	container: {
		marginTop: "8px !important", 
       marginLeft: "16px !important",
       marginRight: "16px !important",
	},
	subcomponent: {
		display: "flex",
		alignItems: "center",
    	justifyContent: "flex-start",
		marginLeft: "1em"
	},
	warningIcon: {
		width: ".7em",
		height: "auto",
		marginBottom: "-0.15em",
		marginRight: "0.2em"
	},
	reInitStyle: {
		width: "45vw"
	},
	buttonChecked: {
		margin: ".2em",
		border: "none",
		fontSize: ".8em",
		color: '#fff',
		backgroundColor : '#288DB1',
		'&:hover': {
			backgroundColor: '#1c627b'
		},
		'&:active': {
			backgroundColor: '#1c627b'
		},
		'&:focus': {
			background: '#288DB1'
		},
	},
	buttonUnchecked: {
		margin: ".2em",
		border: "none",
		fontSize: ".8em",
		color: '#111',
		backgroundColor : '#f0f0f0',
		'&:hover': {
			backgroundColor: '#E0E0E0'
		},
		'&:active': {
			backgroundColor: '#c4c4c4'
		},
		'&:focus': {
			background: '#f0f0f0'
		},
	}
})

const subTitle = {
	color: '#000',
	fontSize: 18,
	marginBottom: '1em',
	marginLeft: '.5em',
}
const switchtitle = {
	color: '#000',
	fontWeight: "bold",
	fontSize: ".9em",
}
// function formatHours(hours) {
// 	return (hours < 10) ? '0' + hours.toString() : hours.toString();
// }

function formatList(style, liste, type) {
	return (<Grid className={style} container spacing={1}>
		<RadioGroup row aria-label="position" defaultValue="top">
			{liste}
		</RadioGroup>
	</Grid>)
}

class PromotionsRecurrence extends Component {
	constructor(props) {
		super(props)
		this.state = {
			everyMonth: false,
			eachWeek: false,
			happyHour: false,
			alertMessage: "",
			initUseChecked : false
		}
	}

	componentDidMount(){
		const { source, create, classes, promotion, onPromotionUpdate, ...rest } = this.props

		// let start =  ''
		// let end = ''
		let initUse = 0

		// const serializer = new XMLSerializer()
		let parser = new DOMParser();
		let xmlDoc = parser.parseFromString(promotion.rules, "text/xml");
		// let prom = promotion
		// if (xmlDoc.getElementsByTagName("RECURRING")[0].getAttribute("START") === null) {
		// 	xmlDoc.getElementsByTagName("RECURRING")[0].setAttribute("START", start)
		// }
		
		// if (xmlDoc.getElementsByTagName("RECURRING")[0].getAttribute("END") === null) {
		// 	xmlDoc.getElementsByTagName("RECURRING")[0].setAttribute("END", end)
		// }

		if (xmlDoc.getElementsByTagName("RECURRING")[0].getAttribute("INITUSE") !== null) {
			initUse =xmlDoc.getElementsByTagName("RECURRING")[0].getAttribute("INITUSE")
			this.setState({initUseChecked: initUse ==1  ? true : false})
		}

		// prom.rules = serializer.serializeToString(xmlDoc)
		// onPromotionUpdate(prom) 
	}

	applyToStoreRecurrenceMinutes = (event, isStart) => {
		const { promotion, onPromotionUpdate } = this.props
		let prom = promotion
		let parser = new DOMParser()
		let xmlDoc = parser.parseFromString(promotion.rules,"text/xml")
		const serializer = new XMLSerializer()
		if(event.target.value && ((event.target.value < 60 && event.target.value > -1) || event.target.value === '')) {
			if (xmlDoc.getElementsByTagName("RECURRING")[0].getAttribute("START") === null) {
				xmlDoc.getElementsByTagName("RECURRING")[0].setAttribute("START", '* * * ? * * *')
			}
			
			if (xmlDoc.getElementsByTagName("RECURRING")[0].getAttribute("END") === null) {
				xmlDoc.getElementsByTagName("RECURRING")[0].setAttribute("END", '* * * ? * * *')
			}
			if(xmlDoc.getElementsByTagName("RECURRING")[0].getAttribute("START")) {
				if(isStart) {
					let value = xmlDoc.getElementsByTagName("RECURRING")[0].getAttribute("START").split(' ')
					value[1] = event.target.value === '' ? '*' : event.target.value 
					xmlDoc.getElementsByTagName("RECURRING")[0].setAttribute("START", value.join(' '))
			
				} else {
					let value = xmlDoc.getElementsByTagName("RECURRING")[0].getAttribute("END").split(' ')
					value[1] = event.target.value === '' ? '*' : event.target.value
					xmlDoc.getElementsByTagName("RECURRING")[0].setAttribute("END", value.join(' '))
					
				}
				prom.rules = serializer.serializeToString(xmlDoc)
				onPromotionUpdate(prom)
			}
		}	
	}

	applyToStoreRecurrenceHours = (value, checked, isStart) => {
		const { promotion, onPromotionUpdate } = this.props

		let prom = promotion
		let parser = new DOMParser()
		let xmlDoc = parser.parseFromString(promotion.rules,"text/xml")
		const serializer = new XMLSerializer()
		if (xmlDoc.getElementsByTagName("RECURRING")[0].getAttribute("START") === null) {
			xmlDoc.getElementsByTagName("RECURRING")[0].setAttribute("START", '* * * ? * * *')
		}
		
		if (xmlDoc.getElementsByTagName("RECURRING")[0].getAttribute("END") === null) {
			xmlDoc.getElementsByTagName("RECURRING")[0].setAttribute("END", '* * * ? * * *')
		}
		if(xmlDoc.getElementsByTagName("RECURRING")[0].getAttribute("START")) {
			if(isStart) {
				let start = xmlDoc.getElementsByTagName("RECURRING")[0].getAttribute("START").split(' ')
				this.hoursRecurrenceTraitement(value, checked, start) 
				xmlDoc.getElementsByTagName("RECURRING")[0].setAttribute("START", start.join(' '))
			} else {
				let end = xmlDoc.getElementsByTagName("RECURRING")[0].getAttribute("END").split(' ')
				this.hoursRecurrenceTraitement(value, checked, end) 

				xmlDoc.getElementsByTagName("RECURRING")[0].setAttribute("END", end.join(' '))
			}
			prom.rules = serializer.serializeToString(xmlDoc)
			onPromotionUpdate(prom)
		}
	}

	applyToStoreRecurrenceDays = (value, checked, isStart) => {
		const { promotion, onPromotionUpdate } = this.props
		let prom = promotion
		let parser = new DOMParser()
		let xmlDoc = parser.parseFromString(promotion.rules,"text/xml")
		const serializer = new XMLSerializer()
		if (xmlDoc.getElementsByTagName("RECURRING")[0].getAttribute("START") === null) {
			xmlDoc.getElementsByTagName("RECURRING")[0].setAttribute("START", '* * * ? * * *')
		}
		
		if (xmlDoc.getElementsByTagName("RECURRING")[0].getAttribute("END") === null) {
			xmlDoc.getElementsByTagName("RECURRING")[0].setAttribute("END", '* * * ? * * *')
		}
		if(xmlDoc.getElementsByTagName("RECURRING")[0].getAttribute("START")) {
			if(isStart) {
				let start = xmlDoc.getElementsByTagName("RECURRING")[0].getAttribute("START").split(' ')
				this.daysRecurrenceTraitement(value, checked, start)
				xmlDoc.getElementsByTagName("RECURRING")[0].setAttribute("START", start.join(' '))
			} else {
				let end = xmlDoc.getElementsByTagName("RECURRING")[0].getAttribute("END").split(' ')
				this.daysRecurrenceTraitement(value, checked, end)
				xmlDoc.getElementsByTagName("RECURRING")[0].setAttribute("END", end.join(' '))
			}
			prom.rules = serializer.serializeToString(xmlDoc)
			onPromotionUpdate(prom)
		}
	}

	applyToStoreRecurrenceSpecificNumDays = (value, checked, isStart) => {
		const { promotion, onPromotionUpdate } = this.props
		let prom = promotion
		let parser = new DOMParser()
		let xmlDoc = parser.parseFromString(promotion.rules,"text/xml")
		const serializer = new XMLSerializer()
		if (xmlDoc.getElementsByTagName("RECURRING")[0].getAttribute("START") === null) {
			xmlDoc.getElementsByTagName("RECURRING")[0].setAttribute("START", '* * * ? * * *')
		}
		
		if (xmlDoc.getElementsByTagName("RECURRING")[0].getAttribute("END") === null) {
			xmlDoc.getElementsByTagName("RECURRING")[0].setAttribute("END", '* * * ? * * *')
		}
		if(xmlDoc.getElementsByTagName("RECURRING")[0].getAttribute("START")) {
			if(isStart) {
				let start = xmlDoc.getElementsByTagName("RECURRING")[0].getAttribute("START").split(' ')
				this.specificDaysRecurrenceTraitement(value, checked, start) 
				xmlDoc.getElementsByTagName("RECURRING")[0].setAttribute("START", start.join(' '))
			} else {
				let end = xmlDoc.getElementsByTagName("RECURRING")[0].getAttribute("END").split(' ')
				this.specificDaysRecurrenceTraitement(value, checked, end) 
				xmlDoc.getElementsByTagName("RECURRING")[0].setAttribute("END", end.join(' '))
			}
			prom.rules = serializer.serializeToString(xmlDoc)
			onPromotionUpdate(prom)
		}
	}

	applyToStoreRecurrenceMonth = (value, checked, isStart) => {
		const { promotion, onPromotionUpdate } = this.props
		let prom = promotion
		console.log(value,checked)
		let parser = new DOMParser()
		let xmlDoc = parser.parseFromString(promotion.rules,"text/xml")
		const serializer = new XMLSerializer()
		if (xmlDoc.getElementsByTagName("RECURRING")[0].getAttribute("START") === null) {
			xmlDoc.getElementsByTagName("RECURRING")[0].setAttribute("START", '* * * ? * * *')
		}
		
		if (xmlDoc.getElementsByTagName("RECURRING")[0].getAttribute("END") === null) {
			xmlDoc.getElementsByTagName("RECURRING")[0].setAttribute("END", '* * * ? * * *')
		}
		if(xmlDoc.getElementsByTagName("RECURRING")[0].getAttribute("START")) {
			if(isStart) {
				let start = xmlDoc.getElementsByTagName("RECURRING")[0].getAttribute("START").split(' ')
				this.monthRecurrenceTraitement(value, checked, start)
				xmlDoc.getElementsByTagName("RECURRING")[0].setAttribute("START", start.join(' '))
			} else {
				let end = xmlDoc.getElementsByTagName("RECURRING")[0].getAttribute("END").split(' ')
				this.monthRecurrenceTraitement(value, checked, end)
				xmlDoc.getElementsByTagName("RECURRING")[0].setAttribute("END", end.join(' '))
			}
			prom.rules = serializer.serializeToString(xmlDoc)
			onPromotionUpdate(prom)
		}
	}

	applyToStoreRecurrenceInitUse = () => {
		const { promotion, onPromotionUpdate } = this.props
		let prom = promotion
		let parser = new DOMParser()
		let xmlDoc = parser.parseFromString(promotion.rules,"text/xml")
		const serializer = new XMLSerializer()
		let initUse = xmlDoc.getElementsByTagName("RECURRING")[0].getAttribute("INITUSE") !== null ? xmlDoc.getElementsByTagName("RECURRING")[0].getAttribute("INITUSE"): "0"
		if(initUse ) {
			if(initUse === "0")
				{
					this.setState({initUseChecked: true})
					xmlDoc.getElementsByTagName("RECURRING")[0].setAttribute("INITUSE", "1")
				}
			else 
				{
					this.setState({initUseChecked: false})
					xmlDoc.getElementsByTagName("RECURRING")[0].setAttribute("INITUSE", "0")
				}
		}

		prom.rules = serializer.serializeToString(xmlDoc)
		onPromotionUpdate(prom)
	}

	hoursRecurrenceTraitement = (value, checked, position) => {
		if(value === '' || value === true) {
			position[0] = '*'
			position[1] = '00' 
			position[2] = '*'
		}
		else if(value === false) {
			position[0] = "0"
			position[2] = "0"
		}
		else if(value !== true && value !== false) {
			position[0] = "0"
			position[2] = value
		}
		if(position[2] === '') {
			position[2] = '?'
		}
	}

	daysRecurrenceTraitement = (value, checked, position) => {
		let usDay = ['', 'SUN', 'MON', 'TUE', 'WED', 'THU', 'FRI', 'SAT']

		if(value === '' || value === true)
			position[5] = '*'
		else if(value === false)
			position[5] = "1"
		else if(value !== true && value !== false) {
			position[5] =  usDay[value]
		}
		if(position[5] === '') {
			position[5] = '?'
		}
		else 
			position[3] = '?'
	}

	specificDaysRecurrenceTraitement = (value, checked, position) => {
		 if(value === false)
			position[3] = "1"
		else if(value !== true && value !== false) {
			position[3] =  value
		}
		if(position[3] === '') {
			position[3] = '?'
		}
		else 
			position[5] = '?'
	}

	monthRecurrenceTraitement = (value, checked, position) => {
		if(value === '' || value === true)
			position[4] = '*'
		else if(value === false)
			position[4] = "1"
		else if(value !== true && value !== false) {
			const date = new Date(2009, value - 1 , 10);
			const month = date.toLocaleString('en-US', {month: 'short'}).toUpperCase()
			position[4] =  month
		}
		if(position[4] === '') {
			position[4] = '?'
		}
	}

	createList(value = null, pageTypeCreate, isStart, classes) {
		let endHours = 23
		let endDay = 31
		let day = ['empty', 'Dimanche', 'Lundi', 'Mardi', 'Mercredi', 'Jeudi', 'Vendredi', 'Samedi' ]
		let UsDay = ['SUN', 'MON', 'TUE', 'WED', 'THU', 'FRI', 'SAT']

		let month = ['empty', 'Janvier', 'Février', 'Mars', 'Avril', 'Mai', 'Juin', 'Juillet', 'Août', 'Septembre', 'Octobre', 'November', 'Décembre']
	
		let listeHours = []
		let listeDay = []
		let listeMonth = []
		let listeInfos = []
	
		let valueHours = null
		if (value[2] !== null && value[2] !== undefined) {
			if(Number.isInteger(parseInt(value[2])))
			{
				if (value[2].includes(',')) {
					valueHours = value[2].split(',')
				} else {
					valueHours = [value[2]]
				}
			}
		}

		//Create checkbox for hours
		for (let index = 0; index <= endHours; index++) {
			let everyhours = false
			let checkhours = false
			let created = false
			if (value !== '_start' || value !== '_end') {
				if (value[2] === '*') {
					everyhours = true
				}
	
				if (value[2] === index) {
					checkhours = true
				}
			} else {
				value = {
					0: '',
				}
			}
	
			if (index === 0) {
				listeHours.push(this.createRadioButton("Chaque heure", everyhours, pageTypeCreate, () => this.applyToStoreRecurrenceHours(true, null, isStart), classes));
				listeHours.push(<Grid item xs={12} style={{ marginTop: '10px', marginBottom: '10px', marginLeft: '-3em' }} > <hr /> </Grid>)
				listeHours.push(this.createRadioButton("Heure spécifique", !everyhours, pageTypeCreate, () => this.applyToStoreRecurrenceHours(false, null, isStart), classes));
			}

			if (valueHours !== null) {
				valueHours.forEach(element => {
					if (element === index.toString()) {
						listeHours.push(this.createButton(index, true, pageTypeCreate, () => this.applyToStoreRecurrenceHours(true, null, isStart), classes))
						created = true
					}
				})
			} 
			if(!created)
				listeHours.push(this.createButton(index, false, pageTypeCreate, () => this.applyToStoreRecurrenceHours(index, false, isStart), classes))
	
			if (index === endHours) {
				if (pageTypeCreate === true) {
					listeHours.push(
						<Grid item xs={12}>
							<Grid container>
								<CustomTextField label="Minutes" value={Number.isInteger(parseInt(value[1])) ? value[1] : null} handleChange={(e) => this.applyToStoreRecurrenceMinutes(e, isStart)} create={pageTypeCreate}/>
								<div className={classes.boxLabelMinContainer}><InputLabel className={classes.boxLabelMin}>Entre 0 et 59</InputLabel></div>
							</Grid>
						</Grid>
					)
				} else {
					listeHours.push(
						<Grid item xs={12}>
							<Grid container>
								<CustomTextField label="Minutes" value={Number.isInteger(parseInt(value[1])) ? value[1] : null} create={pageTypeCreate}/>
								<div className={classes.boxLabelMinContainer}><InputLabel className={classes.boxLabelMin}>Entre 0 et 59</InputLabel></div>
							</Grid>
						</Grid>
					)
				}
			}
		}

		let valueDaysWeek = null
		if (value[5] !== null && value[5] !== undefined) {
			valueDaysWeek = [value[5]]
		}
	
		//create checkbox for day name
		for (let index = 1; index < day.length; index++) {
			let specificDay = false
			let allDays = false
			let created = false
	
			if (value[5] === '*' && value[5] !== '?') {
				allDays = true
			} else if(value[5] === '?') {
				specificDay = true
			}
	
			if (index === 1) {
				listeDay.push(this.createRadioButton("Chaque jour", allDays, pageTypeCreate, () => this.applyToStoreRecurrenceDays(true, null, isStart), classes))
				listeDay.push(<Grid item xs={12} style={{ marginTop: '5px', marginBottom: '5px', marginLeft: '-3em' }} > <hr /> </Grid>)
				listeDay.push(this.createRadioButton("Jour spécifique", !allDays && !specificDay, pageTypeCreate, () => this.applyToStoreRecurrenceDays(false, null, isStart), classes))
			}
	
			if (valueDaysWeek !== null) {
				valueDaysWeek.forEach(element => {
					let dayNumber
					UsDay.map((v, i) => {
						if(v === element)
							dayNumber = i+1
					})
					if (dayNumber === index) {
						listeDay.push(this.createButton(day[index], true, pageTypeCreate, () => this.applyToStoreRecurrenceDays(index, true, isStart), classes, "150px", "4"))
						created = true
					}
				})
			} 
			if(!created)
				listeDay.push(this.createButton(day[index], false, pageTypeCreate, () => this.applyToStoreRecurrenceDays(index, false, isStart), classes, "150px", "4"))
		}
	
		//list day value
		let valueDays = null
		if (value[3] !== null) {
			if(Number.isInteger(parseInt(value[3])))
			{
				if (value[3].includes(',')) {
					valueDays = value[3].split(',')
				} else {
					valueDays = [value[3]]
				}
			}
		}
	
		//create checkbox for day number
		for (let index = 1; index <= endDay; index++) {
			let created = false
			if (index === 1) {
				listeDay.push(<Grid item xs={12} style={{ marginTop: '10px', marginBottom: '10px', marginLeft: '-3em' }}> <hr /> </Grid>)
				if (value[3] !== '?') {
					listeDay.push(this.createRadioButton("Numéro de jour spécifique", true, pageTypeCreate, () => this.applyToStoreRecurrenceSpecificNumDays(true, null, isStart), classes));
				} else {
					listeDay.push(this.createRadioButton("Numéro de jour spécifique", false, pageTypeCreate, () => this.applyToStoreRecurrenceSpecificNumDays(false, null, isStart), classes));
				}
			}
	
			if (valueDays !== null) {
				valueDays.forEach(element => {
					if (element === index.toString()) {
						//listeDay.push(this.createCheckBox(index, true, 1, pageTypeCreate, () => this.applyToStoreRecurrenceSpecificNumDays(index, true, isStart)))
						listeDay.push(this.createButton(index, true, pageTypeCreate, () => this.applyToStoreRecurrenceSpecificNumDays(index, true, isStart), classes, "50px", "2"))
						created = true
					}
				});
			}
	
			if (index=== value[3] && !created) {
				listeDay.push(this.createCheckBox(index, true))
			} else if (!created) {
				//listeDay.push(this.createCheckBox(index, false, 1, pageTypeCreate, () => this.applyToStoreRecurrenceSpecificNumDays(index, false, isStart)))
				listeDay.push(this.createButton(index, false, pageTypeCreate, () => this.applyToStoreRecurrenceSpecificNumDays(index, false, isStart), classes, "50px", "2"))
			}
	
			if (index === endDay) {
				if (value[3] === 'L') {
					//listeDay.push(this.createCheckBox('Dernier jour', true, 3, pageTypeCreate, () => this.applyToStoreRecurrenceSpecificNumDays('L', true, isStart)))
					listeDay.push(this.createButton('Dernier jour', true, pageTypeCreate, () => this.applyToStoreRecurrenceSpecificNumDays('L', true, isStart), classes))
				} else {
					//listeDay.push(this.createCheckBox('Dernier jour', false, 3, pageTypeCreate, () => this.applyToStoreRecurrenceSpecificNumDays('L', false, isStart)))
					listeDay.push(this.createButton('Dernier jour', false, pageTypeCreate, () => this.applyToStoreRecurrenceSpecificNumDays('L', false, isStart), classes))
				}
			}
		}
	
		let valueMonths = null
		if (value[4] !== null && value[4] !== undefined) {
			valueMonths = [value[4]]
		
		}

		//create checkbox for month name
		for (let index = 1; index < month.length; index++) {
			let checkMonth = false
			let created = false
	
			if (value[4] === '*') {
				checkMonth = true
			}
	
			if (index === 1) {
				listeMonth.push(this.createRadioButton("Chaque mois", checkMonth, pageTypeCreate, () => this.applyToStoreRecurrenceMonth(true, null, isStart), classes))
				listeMonth.push(<Grid item xs={12} style={{ marginTop: '10px', marginBottom: '10px', marginLeft: '-3em' }} > <hr /> </Grid>)
				listeMonth.push(this.createRadioButton("Mois spécifique", !checkMonth, pageTypeCreate, () => this.applyToStoreRecurrenceMonth(false, null, isStart), classes))
			}
			if (valueMonths !== null) {
				valueMonths.forEach(element => {
					let monthNumber = new Date( element + "01, 1995" ).getMonth() + 1;

					if (monthNumber == index) {
						//listeMonth.push(this.createCheckBox(month[index], true, 3, pageTypeCreate, () => this.applyToStoreRecurrenceMonth(index, true, isStart), classes))
						listeMonth.push(this.createButton(month[index], true, pageTypeCreate, () => this.applyToStoreRecurrenceMonth(index, true, isStart), classes, "150px", "4"))
						created = true
					}
				})
			}
	
			if (!created) {
				//listeMonth.push(this.createCheckBox(month[index], false, 3, pageTypeCreate, () => this.applyToStoreRecurrenceMonth(index, false, isStart), classes))
				listeMonth.push(this.createButton(month[index], false, pageTypeCreate, () => this.applyToStoreRecurrenceMonth(index, false, isStart), classes, "150px", "4"))
			}
		}
	
		listeInfos.listeHours = listeHours
		listeInfos.listeDay = listeDay
		listeInfos.listeMonth = listeMonth
	
		return listeInfos
	}

	createCheckBox(label = null, checked = false, width = 1, pageTypeCreate = false, method) {
		let style 
		if(width === 1) {
			style = {
				maxWidth: '15%',
				flexBasis: '15%'
			}
		}
		if (pageTypeCreate === true) {
			return (<Grid item xs={width} style={style}>
						<FormControlLabel onChange={method} checked={checked} control={<Checkbox color="primary" />} 
						label={<span>{label}</span>} labelPlacement="end" />
					</Grid>)
		} else {
			return (<Grid item xs={width} style={style}>
						<FormControlLabel  checked={checked} control={<Checkbox color="primary" />} 
						label={<span>{label}</span>} labelPlacement="end" />
					</Grid>)
		}
	}

	createRadioButton(label = null, checked = false, pageTypeCreate = false, method, classes) {
		if (pageTypeCreate) {
			return (
				<Grid item xs='12' className={classes.antiPadding}>
					<FormControlLabel checked={checked} control={<Radio color="primary" />}
					label={label} onChange={method} />
				</Grid>
			)
		} else {
			return (
				<Grid item xs='12' className={classes.antiPadding}>
					<FormControlLabel checked={checked} control={<Radio color="primary" />}
					label={label} />
				</Grid>
			)
		}
	}

	createButton(label = null, checked = false, pageTypeCreate = false, method, classes, width, gridWidthCell) {
		if (pageTypeCreate) {
			return (
				<Grid item xs={gridWidthCell} >
					<Button onClick={method} className={checked ? classes.buttonChecked : classes.buttonUnchecked} style={{ width: width }}>{label}</Button>
				</Grid>
			)
		} else {
			return (
				<Grid item xs={gridWidthCell} >
					<Button className={checked ? classes.buttonChecked : classes.buttonUnchecked} style={{ width: width }}>{label}</Button>
				</Grid>
			)
		}
	}

	render() {
		const { source, create, classes, promotion, onPromotionUpdate, ...rest } = this.props
		let parser = new DOMParser()
		let xmlDoc = parser.parseFromString(promotion.rules,"text/xml")
		let listeInfosStart, listeInfosEnd
		let initUse = 0
		let start =  ''
		let end = ''
		const loadRecurrence = () => {
			let parser = new DOMParser()
			let xmlDoc = parser.parseFromString(promotion.rules, "text/xml")
			if (xmlDoc.getElementsByTagName("RECURRING")[0].getAttribute("START") == null) {
				start = '* * * ? * * *'
				xmlDoc.getElementsByTagName("RECURRING")[0].setAttribute("START", '* * * ? * * *')
				// prom.rules = serializer.serializeToString(xmlDoc)
				// onPromotionUpdate(prom) 
			}
			
			if (xmlDoc.getElementsByTagName("RECURRING")[0].getAttribute("END") == null) {
				end = '* * * ? * * *'
				xmlDoc.getElementsByTagName("RECURRING")[0].setAttribute("END", '* * * ? * * *')
				// prom.rules = serializer.serializeToString(xmlDoc)
				// onPromotionUpdate(prom) 
			}
	
			if (xmlDoc.getElementsByTagName("RECURRING")[0].getAttribute("INITUSE") == null) {
				initUse = 0
				xmlDoc.getElementsByTagName("RECURRING")[0].setAttribute("INITUSE", initUse)
			}
	
			if (xmlDoc.getElementsByTagName("RECURRING")[0].getAttribute("START") != null) {
				start = xmlDoc.getElementsByTagName("RECURRING")[0].getAttribute("START").split(' ')
			} 
			
			if (xmlDoc.getElementsByTagName("RECURRING")[0].getAttribute("END") != null) {
				end = xmlDoc.getElementsByTagName("RECURRING")[0].getAttribute("END").split(' ')
			} 
	
			if (xmlDoc.getElementsByTagName("RECURRING")[0].getAttribute("INITUSE") != null) {
				initUse = xmlDoc.getElementsByTagName("RECURRING")[0].getAttribute("INITUSE")
			}

			listeInfosStart = this.createList(start, create, true, classes)
			listeInfosEnd = this.createList(end, create, false, classes)

			if(start.join(' ') === "* 0 0 1 * ? *" && end.join(' ') === "* 59 23 L * ? *" && !this.state.everyMonth)
				this.setState({ everyMonth: true, eachWeek: false, happyHour: false })
			else if(start.slice(0, 5).join(' ') === "* 0 0 ? *" && start.slice(6).join(' ') === "*" &&
				end.slice(0, 5).join(' ') === "* 59 23 ? *" && end.slice(6).join(' ') === "*" && !this.state.eachWeek)
				this.setState({ everyMonth: false, eachWeek: true, happyHour: false })
			else if(start[0] === "*" && start.slice(3, 7).join(' ') === "? * * *" && start[2] !== "*" &&
				end[0] === "*" && end.slice(3, 7).join(' ') === "? * * *" && end[2] !== "*" && !this.state.happyHour)
				this.setState({ everyMonth: false, eachWeek: false, happyHour: true })
		}

		
		const handleClickApplyMode = (startValue, endValue, state) => {
			const { promotion, onPromotionUpdate } = this.props

			let start =  ''
			let end = ''

			let prom = promotion
			let parser = new DOMParser()
			const serializer = new XMLSerializer()

			let xmlDoc = parser.parseFromString(promotion.rules, "text/xml")

			if (xmlDoc.getElementsByTagName("RECURRING")[0].getAttribute("START") != null) {
				start = startValue
				xmlDoc.getElementsByTagName("RECURRING")[0].setAttribute("START", start)
				prom.rules = serializer.serializeToString(xmlDoc)
				onPromotionUpdate(prom) 
			}

			if (xmlDoc.getElementsByTagName("RECURRING")[0].getAttribute("END") != null) {
				end = endValue
				xmlDoc.getElementsByTagName("RECURRING")[0].setAttribute("END", end)
				prom.rules = serializer.serializeToString(xmlDoc)
				onPromotionUpdate(prom) 
			}
			if(state === "everyMonth")
				this.setState({ everyMonth: true, eachWeek: false, happyHour: false, alertMessage: "" })
			else if(state === "eachWeek")
				this.setState({ everyMonth: false, eachWeek: true, happyHour: false, alertMessage: "Jour de début et de fin à sélectionner" })
			else if(state === "happyHour")
				this.setState({ everyMonth: false, eachWeek: false, happyHour: true, alertMessage: "Heure de début et de fin à sélectionner" })

			loadRecurrence()
		}
		const ResetRecurrence =(type)=>{
			const { promotion, onPromotionUpdate } = this.props
			let prom = promotion
			let parser = new DOMParser()
			const serializer = new XMLSerializer()
			let xmlDoc = parser.parseFromString(promotion.rules, "text/xml")
			 
			if (type ==='START' && xmlDoc.getElementsByTagName("RECURRING")[0].getAttribute("START") != null) {
				let x = xmlDoc.getElementsByTagName("RECURRING")
				x[0].removeAttribute("START")
 			}
			if (type ==='END' && xmlDoc.getElementsByTagName("RECURRING")[0].getAttribute("END") != null) {
				let x = xmlDoc.getElementsByTagName("RECURRING")
				x[0].removeAttribute("END")		
			}
			prom.rules = serializer.serializeToString(xmlDoc)
			onPromotionUpdate(prom) 
		}
		loadRecurrence()
		
		return (
			<Grid item xs={12} className={classes.container}>
				<Grid container spacing={4}  >
				<Grid item xs={6}>
					<div className={classes.subcomponent}>
						<div className={classes.typeMode}>
							<InputLabel htmlFor="input-with-icon-adornment" className={classes.label}>Types de récurrences</InputLabel>
						 	<div>
								<Chip className={this.state.everyMonth ? classes.chip : null} label="Chaque mois" style={{margin: '.5em'}} 
									onClick={() => create ? handleClickApplyMode("0 01 0 1 * ? *", "0 59 23 L * ? *", "everyMonth") : null}
								/>
								<Chip className={this.state.eachWeek ? classes.chip : null} label="Par semaine" style={{margin: '.5em'}} 
									onClick={() => create ? handleClickApplyMode("* 0 0 ? * SUN *", "* 59 23 ? * SAT *", "eachWeek") : null}
								/>
								<Chip className={this.state.happyHour ? classes.chip : null} label="Happy hour" style={{margin: '.5em'}} 
									onClick={() => create ? handleClickApplyMode("* 0 0 ? * * *", "* 0 0 ? * * *", "happyHour") : null}
								/>
							</div>
							<div style={{ height: "1em"}}>
								{ this.state.alertMessage.length > 0 && <Typography variant="caption" gutterBottom>
									<WarningIcon className={classes.warningIcon}/>{this.state.alertMessage}
								</Typography> }
							</div>
						</div>

						
					</div>
					</Grid>
					<Grid item xs={6}>

 					<div style={{ paddingTop: "15px" }} >
					 <FormControlLabel
						control={
							<CustomSwitch
							checked={this.state.initUseChecked}
							color="primary" size="small"
							inputProps={{ 'aria-label': 'primary checkbox' }}
							onChange={this.applyToStoreRecurrenceInitUse}
							disabled={!create}
							/>
						}
						label={<span style={switchtitle}>Activer la réinitialisation des usages de la promo à chaque période</span>} 
					/>
					
 					</div>
 					</Grid>
				</Grid>
					<Grid container spacing={4} style={{ marginTop: ".5em" }}>
						<Grid item xs={6}>
							<Grid container direction="row">
								<Grid item xs={8} style={{display:'inline-flex',marginTop:'15px'}}>
									<QueryBuilderIcon /><Typography style={subTitle}>Début</Typography>
								</Grid>
								<Grid item xs={4} >
									<CustomTextField fullWidth={true}  value={xmlDoc.getElementsByTagName("RECURRING")[0].getAttribute("START") ? xmlDoc.getElementsByTagName("RECURRING")[0].getAttribute("START") : ''}   readOnly style={{marginRight:'0'}}/>
								</Grid>
							</Grid>
							<SimpleTabs tab1={formatList(classes.boxStyle, listeInfosStart.listeHours, start)} tab2={formatList(classes.boxStyle, listeInfosStart.listeDay)} tab3={formatList(classes.boxStyle, listeInfosStart.listeMonth)} WithReset={create} onReset={()=>ResetRecurrence('START')}/>
						</Grid>
						<Grid item xs={6}>
							<Grid container direction="row">
								<Grid item xs={8} style={{display:'inline-flex',marginTop:'15px'}}>
									<WatchLaterIcon /><Typography style={subTitle}>Fin</Typography>
								</Grid>
							
								<Grid item xs={4}>
									<CustomTextField fullWidth={true} value={xmlDoc.getElementsByTagName("RECURRING")[0].getAttribute("END") ? xmlDoc.getElementsByTagName("RECURRING")[0].getAttribute("END") : ''}   readOnly  />
								</Grid>
							</Grid>
							<SimpleTabs tab1={formatList(classes.boxStyle, listeInfosEnd.listeHours, end)} tab2={formatList(classes.boxStyle, listeInfosEnd.listeDay)} tab3={formatList(classes.boxStyle, listeInfosEnd.listeMonth)} WithReset={create} onReset={()=>ResetRecurrence('END')} />
						</Grid>
					</Grid>
			</Grid>
		)
	}
}

const mapStateToProps = state => ({
	...state,
	promotion: state.reducer ? state.reducer.Promotion.payload : null
})

const mapDispatchToProps = ({
	onPromotionUpdate,
	onGetPromo
})

const enhance = compose(
	connect(mapStateToProps, mapDispatchToProps),
	withStyles(styles)
)

export default enhance(PromotionsRecurrence)