import React, { useEffect } from 'react'
import GestionFields from '../../common/fields/GestionFields'
import Grid from '@material-ui/core/Grid'
import { CustomTextField, CustomAutocomplete } from '../../layout/GlobalStyles'
import { Accordion as ExpansionPanel } from '@material-ui/core'
import { AccordionDetails as ExpansionPanelDetails} from '@material-ui/core'
import { AccordionSummary as ExpansionPanelSummary} from '@material-ui/core'
import Typography from '@material-ui/core/Typography'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'
import { createTheme, withStyles, ThemeProvider } from '@material-ui/core/styles'
import Divider from '@material-ui/core/Divider'
import XMLViewer from 'react-xml-viewer'
import Button from '@material-ui/core/Button'
import { VisibilityIcon, SettingsIcon, ExpandMoreIcon } from '../../../constants/icons'
import { connect } from 'react-redux'
import { Links } from '../../../tools'
import { CustomShowBar, CustomUpdateBar, InputLabelTitle, CustomDisplayDialog, CustomDialog } from '../../layout/GlobalStyles'
import { DomainsItems, DispatcherServers, DispatcherConfigurations } from '../../../requests'
import { ON_UPDATE_CONFIG } from '../../../actions/ConfigurationManager'
import IconButton from '@material-ui/core/IconButton'
import DeleteIcon from '@material-ui/icons/Delete'
import CreateIcon from '@material-ui/icons/Create'
import { Logger } from '../../../tools'

import compose from 'recompose/compose'
import { translate } from 'react-admin'

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import TableFooter from '@material-ui/core/TableFooter';
import TablePagination from '@material-ui/core/TablePagination';

const styles = theme =>  ({
	root: {
		width: '100%'
	},
	heading: {
		color: 'white',
		fontSize: theme.typography.pxToRem(15),
		fontWeight: theme.typography.fontWeightBold,
	},
	expansionPanelTitle:{
		background: '#288DB1',//cyan['A700'],
	},
	expansionPanel:{
		background: 'white'// theme.palette.text.secondary//'dimgrey'
	},
	switchContentType : {
		width : '97px'
	},
	switchContentTypeTitle: {
		fontSize: 'small',
		fontWeight: 'bold',
		color: theme.palette.text.secondary,
		marginBottom : '5px'
	},
	listContainer: {
		backgroundColor: theme.palette.background.paper,
		position: 'relative',
		overflow: 'auto',
		maxHeight: '20vh',
		minHeight: '20vh',
		minWidth: 'auto',
		border: "1px solid rgb(40, 141, 177, 0.5)",
		padding: ".5em",
		borderRadius: "5px",
		marginLeft: ".5em",
	},
	elementListStyle: {
		fontSize: ".8em"
	},
	button: {
		position: "relative",
		right: "0",
		transform: "translate(0%, 0%)",
		fontSize: '.9em',
		backgroundColor : '#288DB1',
		'&:hover': {
			background: '#1c627b'
		},
	},
	buttonExpertModeSpe: {
		position: "relative",
		top: "50%",
    	transform: "translate(0, -40%)",
		marginLeft: "1em",
		'&:hover': {
			color: '#D5D5D5'
		},
		width: "1em",
		height: "1em",
		userSelect: "none"
	},
	expandIcon: {
		color: '#fff',
		'&:hover': {
			color: '#D5D5D5'
		},
	},
	titleStyle: {
		marginBottom: '2px',
		marginLeft: '.5em',
		color: "#000"
		
	},
	bannerTitle: {
		color: '#288DB1',
		fontSize: ".8em",
		fontWeight: "750",
		position: "absolute",
		top: "50%",
		transform: "translate(0, -50%)",
		textTransform: "uppercase"
	},
	footerStyle: {
		display:'flex', 
		justifyContent: 'flex-end',
		padding: '0px 24px 0px 24px',
	}
})

const getMuiTheme = () => createTheme({
	overrides: {
		MuiListItemText: {
			primary: {
				fontSize: '1em'
			}
		},
		MuiAccordionSummary: {
			root: {
				height: "64px",
				color:'white'
			},
			content: {
				paddingBottom: ".5em"
			}
		},

	},
})

function getMatchingElement(value, data) {
	let matching
	data.forEach(element => {
		if (parseInt(element.itemid) === parseInt(value)) {
			matching = element
		}
	})
	if (matching === undefined) {
		matching = 0
	}
	return matching
}

const ConfigurationManagerDetails = (props) => {
	const { classes, serviceType, configurationSelected, onUpdateConfig, create} = props
	const [panelExpanded, setPanelExpanded] = React.useState("")
	const [listWorkers, setListWorkers] = React.useState()
	const [openGlobalConfigXml, setOpenGlobalConfigXml] = React.useState(false)
	const [openWorkerConfigXml, setOpenWorkerConfigXml] = React.useState(false)
	const [openJobConfigXml, setOpenJobConfigXml] = React.useState(false)
	const [openUpdateLine, setOpenUpdateLine] = React.useState(false)
	const [openRemoveLine, setOpenRemoveLine] = React.useState(false)
	const [msJobInfosDisplay, setMsJobInfosDisplay] = React.useState()
	const [msJobListDisplay, setMsJobListDisplay] = React.useState()
	const [xmlActiveWorker, setXmlActiveWorker] = React.useState()
	const [xmlActiveJob, setXmlActiveJob] = React.useState()
	const [pageParam, setPageParam] = React.useState(0)
	const [pageMapping, setPageMapping] = React.useState(0)
	const [dispatcherServers, setDispatcherServers] = React.useState([])
	const [displayRow, setDisplayRow] = React.useState()
	const [rowUpdated, setRowUpdated] = React.useState()
	const [jobIndexRowUpdated, setJobIndexRowUpdated] = React.useState(-1)
	const [jobMappingIndexRowUpdate, setJobMappingIndexRowUpdated] = React.useState()
	//const [jobsTypes, setJobsTypes] = React.useState([])
	const [isLoading, setIsLoading] = React.useState(false)
	const [showError, setShowError] = React.useState(false)
	const [displayDeletedRow, setDisplayDeletedRow] = React.useState()

	let jobsTypes = []

	const EditActions = () => (
		<CustomUpdateBar to={Links.ConfigurationManagerShow(configurationSelected.configid)} method={saveConfiguration}/>
	) 
	
	const ShowActions = () => (
		<CustomShowBar to={Links.ConfigurationManagerEdit(configurationSelected.configid)} back={Links.ConfigurationManagerList()} editButtonTooltip="Passer en mode EDIT"/>
	)

	const saveConfiguration = () => {
		setIsLoading(true)
		
		return new Promise((resolve, reject) => {
			console.log(configurationSelected)
			DispatcherConfigurations.save(configurationSelected).then((data) => {
					console.log('RETURN', data)
					resolve()
					setIsLoading(false)
			}).catch((error)=>{
				setIsLoading(false)
				Logger.debug(error)
				reject()
				setShowError(true)
				const timeId = setTimeout(() => {
					setShowError(false)
				}, 3000)
				return () => {
					clearTimeout(timeId)
				}
			})
		})
	}
	
	useEffect(() => {
		updateDisplay()
		updateDeleteDisplay()
	}, [rowUpdated])

	const updateDisplay = () => {
		setDisplayRow(
			rowUpdated ? 
				<Grid container>
					{ 	rowUpdated.elements && rowUpdated.elements.length > 0 ?
					<Grid item xs={3}>
						<CustomTextField label={"value"} create={true} value={rowUpdated.value ? rowUpdated.value : null} fullWidth={true} handleChange={(e) =>  updateRowValue(e)}/>
					</Grid>
					: null
					}
					{ 	rowUpdated.elements ? rowUpdated.elements.map((el, index) => {
							return(
							<Grid item xs={3}><CustomTextField label={el.name} create={true} value={el.value} handleChange={(e) =>  updateRowElement(e, index)} /></Grid>
							)})
						:  rowUpdated.value && rowUpdated.value.length > 20 ?
							<><Grid item xs={3}>
								<CustomTextField label={"name"} create={true} value={rowUpdated.name ? rowUpdated.name : null} fullWidth={true} handleChange={(e) =>  updateRowName(e)}/>
							</Grid>
							<Grid item xs={12}>
								<CustomTextField label={"value"} create={true} value={rowUpdated.value ? rowUpdated.value : null} fullWidth={true} handleChange={(e) =>  updateRowValue(e)}/>
							</Grid></>
							:
							<><Grid item xs={3}>
								<CustomTextField label={"name"} create={true} value={rowUpdated.name ? rowUpdated.name : null} fullWidth={true} handleChange={(e) =>  updateRowName(e)}/>
							</Grid>
							<Grid item xs={3}>
								<CustomTextField label={rowUpdated.name ? rowUpdated.name : "value"} create={true} value={rowUpdated.value ? rowUpdated.value : null} handleChange={(e) =>  updateRowValue(e)}/>
							</Grid></>
						
					}
				</Grid>
			 : null
		)
	}

	const updateDeleteDisplay = () => {
		setDisplayDeletedRow(
			rowUpdated ? 
			<>
				<Typography variant="body2" gutterBottom>
					Êtes-vous sûr de vouloir supprimer cet élément ?
				</Typography>
				<Grid container>
					{ rowUpdated.value && rowUpdated.value.length > 20 ?
					<Grid item xs={12}>
						<CustomTextField label={rowUpdated.name ? rowUpdated.name : "value"} value={rowUpdated.value ? rowUpdated.value : null} fullWidth={true}/>
					</Grid>
					:
					<Grid item xs={3}>
						<CustomTextField label={rowUpdated.name ? rowUpdated.name : "value"} value={rowUpdated.value ? rowUpdated.value : null} />
					</Grid>
					}

					{ 	rowUpdated.elements ? rowUpdated.elements.map((el, index) => {
						return(  <Grid item xs={3}><CustomTextField label={el.name} value={el.value} /></Grid> )})
						: null
					}
				</Grid>
			</>
			 : null
			
		)
	}

	useEffect(() => {
		if(serviceType === "VodDispatcher") {
			createWorkers()
		} else if(serviceType === "MSJobsExecute") {
			const fetchData = async () => {
				await DomainsItems.getAll(93).then(resp => {
					jobsTypes = resp.data
				})
				
				let jobsArr = createJobs()
				displayInfosMsJobsExecute()
				setMsJobListDisplay(msJobsExecuteList(jobsArr))			

			}

			
			fetchData().catch(console.error)

		}

		const fetchData = async () => {
			await DispatcherServers.getAll().then((json) => {
				if (json.data !== undefined) {
					let arr = []
					json.data.forEach(d => {
						arr.push({
							serverid: d.id,
							label: d.servername,
							ipAdd: d.ipadressv4
						})
					})
					setDispatcherServers(arr)
				}
			})
		}
		fetchData().catch(console.error)

	}, [panelExpanded, configurationSelected, onUpdateConfig])

	const displayInfosMsJobsExecute = () => {
		let parser = new DOMParser()
		let xmlDoc = parser.parseFromString(configurationSelected.config, "text/xml")

		let smtpServer = xmlDoc.getElementsByTagName('configuration')[0].getAttribute('smtpserver')
		let logFile = xmlDoc.getElementsByTagName('logs')[0].getAttribute('filename')
		let intervalSecondsCheckJobs = xmlDoc.getElementsByTagName('jobs')[0].getAttribute('interval_seconds_check_jobs')
		let intervalSecondsDoJobs = xmlDoc.getElementsByTagName('jobs')[0].getAttribute('interval_seconds_do_jobs')
		let maxRows = xmlDoc.getElementsByTagName('jobs')[0].getAttribute('max_rows')
		let runStarthour = xmlDoc.getElementsByTagName('jobs')[0].getAttribute('run_starthour')
		let runEndhour = xmlDoc.getElementsByTagName('jobs')[0].getAttribute('run_endhour')
		let rebootTimeslot = xmlDoc.getElementsByTagName('jobs')[0].getAttribute('reboot_timeslot')

		
		setMsJobInfosDisplay(
			msJobsExecuteInfo(smtpServer,
				intervalSecondsCheckJobs,
				intervalSecondsDoJobs,
				maxRows,
				runStarthour,
				runEndhour,
				rebootTimeslot,
				logFile
		))
	}
	
	const createWorkers = () => {
		let parser = new DOMParser()
		const serializer = new XMLSerializer()
		let xmlDoc = parser.parseFromString(configurationSelected.config, "text/xml")
		
		let workers = xmlDoc.getElementsByTagName("WORKERS")[0]

		let workersArr = [
			<Grid container direction="row" style={{ marginBottom: "1em"}}>
				<Grid item xs={3} style={{display: "flex"}}>
					<SettingsIcon style={{marginTop: '3px', color: "#000"}} />
					<Typography variant="h6" className={classes.titleStyle}>Workers</Typography>
				</Grid>
				<Grid item xs={5}>
				</Grid>
				<Grid item xs={4} style={{justifyContent: "flex-end", display: "flex", paddingRight: "0.5em"}}>
					<Button color="primary" variant="contained" className={classes.button} onClick={handleClickGlobalOpenConfigXml}><VisibilityIcon style={{marginRight: '.5em'}}/>MODE EXPERT</Button>
				</Grid>
			</Grid>
		]
			let sourcePathList = []
			let destinationPathList = []
			let rulesFile
			let workingPath
			let errorPath
			let waitTimeBeforeRescaningSourcePath
			let nbMinutesBeforeDeletingExistingFile
			let nbMinutesBeforeRemovingEndedThreadOfExistingFile
			let nbMinutesBeforeKillingExecutingThread

			for (let index = 0; index < workers.getElementsByTagName('WORKER').length; index++) {
				let xmlWorker = serializer.serializeToString(workers.getElementsByTagName('WORKER')[index]).replace(/&lt;/g, '<').replace(/&gt;/g, '>')
				let name = workers.getElementsByTagName('WORKER')[index].getAttribute('NAME')
				let server = workers.getElementsByTagName('WORKER')[index].getAttribute('SERVERS')
				let maxThread = workers.getElementsByTagName('WORKER')[index].getAttribute('MAXTHREADS')

				rulesFile = workers.getElementsByTagName('WORKER')[index].getElementsByTagName('RULESETFILE')[0].childNodes[0].nodeValue
				workingPath = workers.getElementsByTagName('WORKER')[index].getElementsByTagName('WORKING_PATH')[0].childNodes[0].nodeValue
				errorPath = workers.getElementsByTagName('WORKER')[index].getElementsByTagName('ERROR_PATH')[0].childNodes[0].nodeValue
				waitTimeBeforeRescaningSourcePath = workers.getElementsByTagName('WORKER')[index].getElementsByTagName('WAIT_TIME_BEFORE_RESCANING_SOURCE_PATH')[0].childNodes[0].nodeValue
				nbMinutesBeforeDeletingExistingFile = workers.getElementsByTagName('WORKER')[index].getElementsByTagName('NB_MINUTES_BEFORE_DELETING_EXISTING_FILE')[0].childNodes[0].nodeValue
				nbMinutesBeforeRemovingEndedThreadOfExistingFile = workers.getElementsByTagName('WORKER')[index].getElementsByTagName('NB_MINUTES_BEFORE_REMOVING_ENDED_THREAD_OF_EXISTING_FILE')[0].childNodes[0].nodeValue
				nbMinutesBeforeKillingExecutingThread = workers.getElementsByTagName('WORKER')[index].getElementsByTagName('NB_MINUTES_BEFORE_KILLING_EXECUTING_THREAD')[0].childNodes[0].nodeValue

				let sourcesPathElements = workers.getElementsByTagName('WORKER')[index].getElementsByTagName('SOURCE_PATHS')[0]
				for (let index = 0; index < sourcesPathElements.getElementsByTagName('SOURCE_PATH').length; index++) {
					let sourcesPath = sourcesPathElements.getElementsByTagName('SOURCE_PATH')[index].childNodes[0].nodeValue
					sourcePathList.push(sourcesPath)
				}

				let destinationPathElements = workers.getElementsByTagName('WORKER')[index].getElementsByTagName('DESTINATION_PATHS')[0]
				
				for (let index = 0; index < destinationPathElements.getElementsByTagName('DESTINATION_PATH').length; index++) {
					let destinationPath = destinationPathElements.getElementsByTagName('DESTINATION_PATH')[index].childNodes[0].nodeValue
					destinationPathList.push(destinationPath)
				}

				workersArr.push(
					expandedPanel(name, 
						maxThread,
						server, 
						sourcePathList, 
						rulesFile, 
						workingPath, 
						errorPath,
						destinationPathList, 
						waitTimeBeforeRescaningSourcePath,
						nbMinutesBeforeDeletingExistingFile, 
						nbMinutesBeforeKillingExecutingThread,
						nbMinutesBeforeRemovingEndedThreadOfExistingFile,
						index, xmlWorker)
				)
			}
			setListWorkers(workersArr)
	}

	const createJobs = () => {
		let parser = new DOMParser()
		const serializer = new XMLSerializer()
		let xmlDoc = parser.parseFromString(configurationSelected.config, "text/xml")

		let jobsArr = []
		let paramsList = []
		let mapList = []

		for (let index = 0; index < xmlDoc.getElementsByTagName('job').length; index++) {
			mapList = []
			let jobs = xmlDoc.getElementsByTagName('job')
			let columns = []
			for (let i = 0; i < jobs[index].getElementsByTagName('param').length; i++) {
				let jobParams = jobs[index].getElementsByTagName('param')

				if(jobParams[i].getAttribute('name') === "mapping") {
					let v = jobParams[i].getElementsByTagName('map')
					for(let j = 0; j < v.length; j++) {
						let mapElements = []
						let mapAttLen = v[j].attributes.length
						if(mapAttLen > 0) {
							for (let k = 0; k < mapAttLen; k++) {
								mapElements.push({ name: v[j].attributes[k].name,
									value: v[j].attributes[k].nodeValue})
							}
						}
						columns = mapElements.map(el => el.name).filter(distinct)
						mapList.push({ value: v[j].childNodes[0] ? v[j].childNodes[0].nodeValue : null, elements: mapElements})
					}
				} else {
					paramsList.push({ name: jobParams[i].getAttribute('name'), 
					value: jobParams[i].childNodes[0] ? jobParams[i].childNodes[0].nodeValue : ""})
				}
			}
			let xmlJob = serializer.serializeToString(xmlDoc.getElementsByTagName('job')[index]).replace(/&lt;/g, '<').replace(/&gt;/g, '>')
			let jobType = jobsTypes.length > 0 && getMatchingElement(xmlDoc.getElementsByTagName('job')[index].getAttribute('type'), jobsTypes)
			jobsArr.push(jobsExpandedPanel(index, jobType, paramsList, mapList, xmlJob, columns))
		}

		return jobsArr
	}

	const handleChange = (event, panel) =>{
		if(panelExpanded === panel)
			setPanelExpanded("")
		else
			setPanelExpanded(panel)
	}

	const handleClickGlobalOpenConfigXml = () => {
		setOpenGlobalConfigXml(true)
	}

	const handleCloseGlobalConfigXml = () => {
		setOpenGlobalConfigXml(false)
	}

	const handleClickWorkerOpenConfigXml = (xmlWorker) => {
		setXmlActiveWorker(xmlWorker)
		setOpenWorkerConfigXml(true)
	}

	const handleCloseWorkerConfigXml = () => {
		setOpenWorkerConfigXml(false)
	}

	const handleClickJobOpenConfigXml = (xmlJob) => {
		setXmlActiveJob(xmlJob)
		setOpenJobConfigXml(true)
	}

	const handleCloseJobConfigXml = () => {
		setOpenJobConfigXml(false)
	}

	const handleClickOpenUpdateLine = (row, indexJob, mappingIndex) => {
		setRowUpdated(row)
		setJobIndexRowUpdated(indexJob)
		setJobMappingIndexRowUpdated(mappingIndex)
		setOpenUpdateLine(true)
	}

	const handleClickOpenRemoveLine = (row, indexJob, mappingIndex) => {
		setRowUpdated(row)
		setJobIndexRowUpdated(indexJob)
		setJobMappingIndexRowUpdated(mappingIndex)
		setOpenRemoveLine(true)
	}

	const updateRowValue = (e) => {
		let row = rowUpdated
		row.value = e.target.value
		setRowUpdated(row)
		updateDisplay()
	}

	const updateRowName = (e) => {
		let row = rowUpdated
		row.name = e.target.value
		setRowUpdated(row)
		updateDisplay()
	}

	const updateRowElement = (e, index) => {
		let row = rowUpdated
		row.elements[index].value = e.target.value
		setRowUpdated(row)
		updateDisplay()
	}

	const handleCloseUpdateLine = () => {
		setOpenUpdateLine(false)
	}

	const handleCloseRemoveLine = () => {
		setOpenRemoveLine(false)
	}

	const debugWorkerConfig = () => {
		let display = []
		display.push(<XMLViewer xml={xmlActiveWorker} style={{ backgroundColor: 'white', paddingLeft: '2em'}} />)
		return display
	}

	const debugJobConfig = () => {
		let display = []
		display.push(<XMLViewer xml={xmlActiveJob} style={{ backgroundColor: 'white', paddingLeft: '2em'}} />)
		return display
	}

	const debugGlobalConfig = () => {
		let display = []
		display.push(<XMLViewer xml={configurationSelected.config} style={{ backgroundColor: 'white', paddingLeft: '2em'}} />)
		return display
	}

	const expandedPanel = (name, maxThread,
		server, sourcePathList, rulesFile, workingPath, errorPath, destinationPathList, waitTimeBeforeRescaningSourcePath,
		nbMinutesBeforeDeletingExistingFile, nbMinutesBeforeKillingExecutingThread,
		nbMinutesBeforeRemovingEndedThreadOfExistingFile, index, xmlWorker) => {
		return (<ExpansionPanel expanded={panelExpanded == "panel".concat(index) ? true : false} className={classes.expansionPanel} >
			<ExpansionPanelSummary
				expandIcon={<ExpandMoreIcon className={classes.expandIcon} fontSize="large" />}
				aria-controls={"panel".concat(index, "a-content")}
				id={"panel".concat(index, "a-header")}
				className = { classes.expansionPanelTitle }
				IconButtonProps={{
					onClick: event => handleChange(event, "panel".concat(index))
				}}
			>
				<Typography className={classes.heading}>{name}
						<VisibilityIcon className={classes.buttonExpertModeSpe} onClick={() => handleClickWorkerOpenConfigXml(xmlWorker)}/>
				</Typography>
			</ExpansionPanelSummary>
			<ExpansionPanelDetails>
				<Grid container spacing={2}>
					<Grid item xs={5}>
						<CustomTextField label="Nom" value={name} fullWidth="true" />
					</Grid>
					<Grid item xs={5}>
						<CustomTextField label="Server" value={server} fullWidth="true" />
					</Grid>
					<Grid item xs={2}>
						<CustomTextField label="Max thread" value={maxThread} fullWidth="true" />
					</Grid>
					<Grid item xs={4}>
						<CustomTextField label="Fichier des règles" value={rulesFile} fullWidth="true" />
					</Grid>
					<Grid item xs={8}>
					</Grid>
					<Grid item xs={6}>
						<CustomTextField label="Chemin d'accès exécution" value={workingPath} fullWidth="true" />
					</Grid>
					<Grid item xs={6}>
						<CustomTextField label="Chemin d'accès erreur" value={errorPath} fullWidth="true" />
					</Grid>
					<Grid item xs={12}>
						<InputLabelTitle label="Chemin d'accès des sources" />
						<List dense className={classes.listContainer}>
							{sourcePathList.map((value, index) => {
								return (
									<><ListItem key={index}>
										<ListItemText id={index} primary={value} className={classes.elementListStyle} />
									</ListItem>
									<Divider /></>
								)
							})}
						</List>
					</Grid>
					<Grid item xs={12}>
						<InputLabelTitle label="Chemin d'accès des destinations" />
						<List dense className={classes.listContainer}>
							{destinationPathList.map((value, index) => {
								return (
									<><ListItem key={index}>
										<ListItemText id={index} primary={value} className={classes.elementListStyle}/>
									</ListItem>
									<Divider /></>
								)
							})}
						</List>
					</Grid>
					<Grid item xs={3}>
						<CustomTextField label="Délai avant re scannage chemin d'accès des sources" value={waitTimeBeforeRescaningSourcePath} fullWidth="true" />
					</Grid>
					<Grid item xs={3}>
						<CustomTextField label="Minutes avant suppression des fichiers existants" value={nbMinutesBeforeDeletingExistingFile} fullWidth="true" />
					</Grid>
					<Grid item xs={3}>
						<CustomTextField label="Minutes avant arrêt des thread en cours d'exécution" value={nbMinutesBeforeKillingExecutingThread} fullWidth="true" />
					</Grid>
					<Grid item xs={3}>
						<CustomTextField label="Minutes avant suppression des thread terminés" value={nbMinutesBeforeRemovingEndedThreadOfExistingFile} fullWidth="true" />
					</Grid>
				</Grid>
			</ExpansionPanelDetails>
		</ExpansionPanel>)
	}

	const msJobsExecuteInfo = (smtpServer, intervalSecondsCheckJobs, intervalSecondsDoJobs, maxRows, runStarthour,	runEndhour,	rebootTimeslot, logFile) => {
		return (
			<Grid container spacing={2}>
				<Grid item xs={3}>
					<CustomTextField label="Serveur smtp" value={smtpServer} fullWidth="true" handleChange={handleChangeSmtpServer} create={create}/>
				</Grid>
				<Grid item xs={9}>
					<CustomTextField label="Chemin d'accès fichier des logs" value={logFile} fullWidth="true" handleChange={handleChangeLogFilename} create={create}/>
				</Grid>
				<Grid item xs={12}>
					{/* //<InputLabelTitle label="JOBS" /> */}
					<Grid container direction="row">
						<Grid item xs={3} style={{display: "flex"}}>
							<SettingsIcon style={{marginTop: '3px', color: "#000", marginRight: ".5em"}} />
							<Typography variant="h6" className={classes.titleStyle}>Jobs</Typography>
						</Grid>
						<Grid item xs={5}>
						</Grid>
						<Grid item xs={4} style={{justifyContent: "flex-end", display: "flex", paddingRight: "0.5em"}}>
							<Button color="primary" variant="contained" className={classes.button} onClick={handleClickGlobalOpenConfigXml}><VisibilityIcon style={{marginRight: '.5em'}}/>MODE EXPERT</Button>
						</Grid>
					</Grid>
				</Grid>
				<Grid item xs={4}>
					<CustomTextField label="Intervalle de vérification" textAdornment="secondes" create={create} value={intervalSecondsCheckJobs} handleChange={(e) => handleChangeJobsParams(e, 'interval_seconds_check_jobs')} fullWidth="true" />
				</Grid>
				<Grid item xs={4}>
					<CustomTextField label="Intervalle d'éxécution" textAdornment="secondes" create={create} value={intervalSecondsDoJobs} handleChange={(e) => handleChangeJobsParams(e, 'interval_seconds_do_jobs')} fullWidth="true" />
				</Grid>
				<Grid item xs={4}>
					<CustomTextField label="Nombre de lignes max" value={maxRows} create={create} handleChange={(e) => handleChangeJobsParams(e, 'max_rows')} fullWidth="true" />
				</Grid>
				
				<Grid item xs={4}>
					<CustomTextField label="Heure de début d'éxécution" value={runStarthour} fullWidth="true" />
				</Grid>
				<Grid item xs={4}>
					<CustomTextField label="Heure de fin d'éxécution" value={runEndhour} fullWidth="true" />
				</Grid>
				<Grid item xs={4}>
					<CustomTextField label="Heure de redémarrage" value={rebootTimeslot} fullWidth="true" />
				</Grid>
			</Grid>
		)
	}

	const msJobsExecuteList = (jobsArr) => {
		return (
			<Grid container spacing={2}>
				<Grid item xs={12}>
					{ jobsArr }
				</Grid>
			</Grid>
		)
	}

	const StyledTableCell = withStyles((theme) => ({
		head: {
		  backgroundColor: "#288DB1",
		  color: theme.palette.common.white,
		},
		body: {
		  fontSize: 14,
		},
	}))(TableCell)

	const handleChangePageParam = (event, newPage) => {
		setPageParam(newPage)
	}

	const handleChangePageMapping = (event, newPage) => {
		setPageMapping(newPage)
	}

	const handleChangeServer = (e,newValue) =>{
		if(newValue) {
			let parser = new DOMParser()
			const serializer = new XMLSerializer()
			let conf = configurationSelected
	
			let xmlDoc = parser.parseFromString(configurationSelected.config, "text/xml")
			xmlDoc.getElementsByTagName('configuration')[0].setAttribute('servername', newValue.label)
	
			conf.servername = newValue.label
			conf.config  = serializer.serializeToString(xmlDoc)
			onUpdateConfig(conf, serviceType)
		}
	}

	const handleChangeServiceName = (event) => {
		if(event.target.value.length > 0) {
			let conf = configurationSelected
			conf.servicename = event.target.value
			onUpdateConfig(conf, serviceType)
		}
	}

	const handleChangeSmtpServer = (event) => {
		if(event.target.value.length > 0) {
			let parser = new DOMParser()
			const serializer = new XMLSerializer()
			let conf = configurationSelected
	
			let xmlDoc = parser.parseFromString(configurationSelected.config, "text/xml")
			xmlDoc.getElementsByTagName('configuration')[0].setAttribute('smtpserver', event.target.value)
			
			conf.config  = serializer.serializeToString(xmlDoc)

			onUpdateConfig(conf, serviceType)
			displayInfosMsJobsExecute()
		}
	}

	const handleChangeLogFilename = (event) => {
		if(event.target.value.length > 0) {
			let parser = new DOMParser()
			const serializer = new XMLSerializer()
			let conf = configurationSelected
	
			let xmlDoc = parser.parseFromString(configurationSelected.config, "text/xml")
			xmlDoc.getElementsByTagName('logs')[0].setAttribute('filename', event.target.value)
			
			conf.config  = serializer.serializeToString(xmlDoc)

			onUpdateConfig(conf, serviceType)
			displayInfosMsJobsExecute()
		}
	}

	const handleChangeJobsParams = (event, attribute) => {
		if(event.target.value.length > 0) {
			let parser = new DOMParser()
			const serializer = new XMLSerializer()
			let conf = configurationSelected
	
			let xmlDoc = parser.parseFromString(configurationSelected.config, "text/xml")
			xmlDoc.getElementsByTagName('jobs')[0].setAttribute(attribute, event.target.value)
			
			conf.config  = serializer.serializeToString(xmlDoc)

			onUpdateConfig(conf, serviceType)
			displayInfosMsJobsExecute()
		}
	}

	const handleChangeJobsMappingParams = () => {
		let parser = new DOMParser()
		const serializer = new XMLSerializer()
		let conf = configurationSelected
		let xmlDoc = parser.parseFromString(configurationSelected.config, "text/xml")
		console.log('JOBINDEX', jobIndexRowUpdated)
		if(jobIndexRowUpdated != undefined && jobIndexRowUpdated != -1) {
			let job = xmlDoc.getElementsByTagName('job')[jobIndexRowUpdated]
			for (let i = 0; i < job.getElementsByTagName('param').length; i++) {
				let jobParams = job.getElementsByTagName('param')
				if(jobParams[i].getAttribute('name') === "mapping") {
					let v = jobParams[i].getElementsByTagName('map')[jobMappingIndexRowUpdate]
					v.childNodes[0].nodeValue = rowUpdated.value
					rowUpdated.elements.forEach(el => {
						v.setAttribute(el.name, el.value)
					})
				}
			}	
		}
				
		conf.config  = serializer.serializeToString(xmlDoc)
		onUpdateConfig(conf, serviceType)
		setMsJobListDisplay(msJobsExecuteList(createJobs()))	
		handleCloseUpdateLine()
	}

	const handleRemoveJobsMappingParams = () => {
		let parser = new DOMParser()
		const serializer = new XMLSerializer()
		let conf = configurationSelected
		let xmlDoc = parser.parseFromString(configurationSelected.config, "text/xml")
		let job = xmlDoc.getElementsByTagName('job')[jobIndexRowUpdated]
		for (let i = 0; i < job.getElementsByTagName('param').length; i++) {
			let jobParams = job.getElementsByTagName('param')
			if(jobParams[i].getAttribute('name') === "mapping") {
				jobParams[i].getElementsByTagName('map')[jobMappingIndexRowUpdate].remove()
			}
		}			
		conf.config  = serializer.serializeToString(xmlDoc)
		onUpdateConfig(conf, serviceType)
		setMsJobListDisplay(msJobsExecuteList(createJobs()))	
		handleCloseRemoveLine()
	}

	const distinct = (value, index, self) => {
		return self.indexOf(value) === index
	}

	const jobsExpandedPanel = (indexJob, jobType, paramsList, mapList, xmlJob, columns) => {
		return (<ExpansionPanel expanded={panelExpanded == "panel".concat(indexJob) ? true : false} className={classes.expansionPanel} >
			<ExpansionPanelSummary
				expandIcon={<ExpandMoreIcon className={classes.expandIcon} fontSize="large" />}
				aria-controls={"panel".concat(indexJob, "a-content")}
				id={"panel".concat(indexJob, "a-header")}
				className = { classes.expansionPanelTitle }
				IconButtonProps={{
					onClick: event => handleChange(event, "panel".concat(indexJob))
				}}
			>
				<Typography className={classes.heading}>{ jobType.label + " (" + jobType.itemid + ")" }
						<VisibilityIcon className={classes.buttonExpertModeSpe} onClick={() => handleClickJobOpenConfigXml(xmlJob)}/>
				</Typography>
			</ExpansionPanelSummary>
			<ExpansionPanelDetails>
			<Grid container spacing={2}>
				<Grid item xs={12}>
					{ paramsList.length > 0 ?
						<>
							<Typography variant="h6" className={classes.titleStyle}>Paramètres</Typography>
							<TableContainer component={Paper}>
								<Table sx={{ minWidth: 650 }} aria-label="simple table" size="small" dense>
									<TableHead>
										<TableRow>
											<StyledTableCell>Name</StyledTableCell>
											<StyledTableCell>Value</StyledTableCell>
											<StyledTableCell></StyledTableCell>
											<StyledTableCell></StyledTableCell>
										</TableRow>
									</TableHead>
									<TableBody>
										{paramsList.slice(pageParam * 10, pageParam * 10 + 10).map((row, index) => (
											<TableRow
												key={index}
												sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
											>
											<StyledTableCell component="th" scope="row" width={250}>
												{row.name}
											</StyledTableCell>
											<StyledTableCell component="th" scope="row">
												{row.value}
											</StyledTableCell>
											<StyledTableCell component="th" scope="row" width={10}>
												<IconButton title='Modifier' edge="end" aria-label="delete" onClick={() => handleClickOpenUpdateLine(row)} style={{height: "1em", width: "1em"}}>
													<CreateIcon style={{height: ".8em"}}/>
												</IconButton>
											</StyledTableCell>
											
											<StyledTableCell component="th" scope="row" width={10}>
												<IconButton title='Supprimer' edge="end" aria-label="delete" style={{height: "1em", width: "1em"}}>
													<DeleteIcon style={{height: ".8em"}}/>
												</IconButton>
											</StyledTableCell>
										
											</TableRow>
										))}
									</TableBody>
									<TableFooter>
										<TablePagination
											colSpan={3}
											count={paramsList.length}
											rowsPerPage={10}
											page={pageParam}
											SelectProps={{
												inputProps: { 'aria-label': 'rows per page' },
												native: true,
											}}
											onPageChange={handleChangePageParam}
										/>
									</TableFooter>
								</Table>
							</TableContainer>
						</>
						: null
					}
				</Grid>
				<Grid item xs={12}>
					{ mapList.length > 0 ?
						<>
							<Typography variant="h6" className={classes.titleStyle}>Mapping</Typography>
							<TableContainer component={Paper}>
								<Table sx={{ minWidth: 650 }} aria-label="simple table" size="small" dense>
									<TableHead>
										<TableRow>
											<StyledTableCell>Value</StyledTableCell>
											{ columns.map((colName) => {
												return( 
													<StyledTableCell style={{ textTransform: "capitalize"}}>{colName}</StyledTableCell>
												)
											})}
											<StyledTableCell></StyledTableCell>
											<StyledTableCell></StyledTableCell>
										</TableRow>
									</TableHead>
									<TableBody>
										{ mapList.slice(pageMapping * 10, pageMapping * 10 + 10).map((row, index) => {
											return (
												<TableRow
												key={index}
												sx={{ '&:last-child td, &:last-child th': { border: 0 } }} style={{margin: 0, padding: 0}}
												>
													<StyledTableCell component="th" scope="row">
														{row.value}
													</StyledTableCell>	
													{ row.elements.map((el) => {
														return(<StyledTableCell component="th" scope="row">
															{el.value}
														</StyledTableCell>)
													})}
													<StyledTableCell component="th" scope="row" width={10}>
														<IconButton title='Modifier' edge="end" aria-label="delete" onClick={() => handleClickOpenUpdateLine(row, indexJob, index)} style={{height: "1em", width: "1em"}}>
															<CreateIcon style={{height: ".8em"}}/>
														</IconButton>
													</StyledTableCell>
													<StyledTableCell component="th" scope="row" width={10}>
														<IconButton title='Supprimer' edge="end" aria-label="delete" style={{height: "1em", width: "1em"}} onClick={() => handleClickOpenRemoveLine(row, indexJob, index)}>
															<DeleteIcon style={{height: ".8em"}}/>
														</IconButton>
													</StyledTableCell>
												
												</TableRow>
											)
										})}
									</TableBody>
									<TableFooter>
										<TablePagination
											colSpan={3}
											count={mapList.length}
											rowsPerPage={10}
											page={pageMapping}
											SelectProps={{
												inputProps: { 'aria-label': 'rows per page' },
												native: true,
											}}
											onPageChange={handleChangePageMapping}
										/>
									</TableFooter>
								</Table>
							</TableContainer>
						</>
						: null
					}
				</Grid>
			</Grid>
			</ExpansionPanelDetails>
		</ExpansionPanel>)
	}

	return (
		<ThemeProvider theme={getMuiTheme()}>
			<div style={{ position: "relative", top: ".7em", right: "0", marginBottom: "3em"}}>
				<div style={{ height: "1em" }}>
					<span className={classes.bannerTitle}>{serviceType}</span>
					{ !create ? <ShowActions /> : <EditActions />}
					<Divider style={{ position: "relative", top: "2em", height: "0.2em", background: "#288DB1", marginLeft: "-1em", marginRight: "-1em" }} />
				</div>
			</div>
			<Grid container spacing={8} style={{ paddingBottom: "1em"}}>
				<Grid item xs={9} direction="row">
					<Grid container spacing={2}>
						<Grid item xs={2}>
							<CustomTextField label="Identifiant" value={configurationSelected.configid} fullWidth="true" />
						</Grid>
						<Grid item xs={5}>
							<CustomTextField label="Nom du service" value={configurationSelected.servicename} fullWidth="true" handleChange={handleChangeServiceName} create={create}/>
						</Grid>
						<Grid item xs={5}>
						{ create ? 
							<CustomAutocomplete label="Nom du serveur" propsType="domainItem" values={dispatcherServers} 
							value={dispatcherServers && dispatcherServers.find(d => d.label === configurationSelected.servername) ? dispatcherServers.find(d => d.label === configurationSelected.servername) : {}} 
							fullWidth="true" handleChange={(e,newValue) => handleChangeServer(e,newValue)}/>
							: 
							<CustomTextField label="Nom du serveur" value={configurationSelected.servername} fullWidth="true" />
						}
						</Grid>
					</Grid>
					<Grid item xs={12} direction="row">
						{ serviceType === "MSJobsExecute" ? msJobInfosDisplay : null }
					</Grid>
				</Grid>
				<Grid item xs={3} direction="row">
					<GestionFields classes={classes} record={configurationSelected} />
				</Grid>
			</Grid>
						
			<Grid container spacing={8}>
				<Grid item xs={12} direction="row">
					{ serviceType === "VodDispatcher" ?  listWorkers : serviceType === "MSJobsExecute" ? msJobListDisplay : null }
				</Grid>
			</Grid>

			<CustomDisplayDialog info="Configuration globale" handleClose={handleCloseGlobalConfigXml} open={openGlobalConfigXml} content={debugGlobalConfig()}/>
			<CustomDisplayDialog info="Worker configuration" handleClose={handleCloseWorkerConfigXml} open={openWorkerConfigXml} content={debugWorkerConfig()}/>
			<CustomDisplayDialog info="Job configuration" handleClose={handleCloseJobConfigXml} open={openJobConfigXml} content={debugJobConfig()}/>
			<CustomDialog info="Modifier" handleClose={handleCloseUpdateLine} open={openUpdateLine}	content={displayRow} method={handleChangeJobsMappingParams} />
			<CustomDialog info="Supprimer" handleClose={handleCloseRemoveLine} open={openRemoveLine} content={displayDeletedRow} method={handleRemoveJobsMappingParams} />
			
		</ThemeProvider>
	)
}

const mapStateToProps = state => ({
	...state,
	configurationSelected: state.reducer ? state.reducer.configurationManager.payload : null,
	serviceType: state.reducer ? state.reducer.configurationManager.serviceType : null,
})

const mapDispatchToProps = dispatch => ({
	onUpdateConfig: (payload, serviceType) =>
		dispatch({ type: ON_UPDATE_CONFIG, payload: payload, serviceType: serviceType }),
})

const enhance = compose(
	connect(mapStateToProps, mapDispatchToProps),
	withStyles(styles)
)

export default translate(enhance(ConfigurationManagerDetails))
