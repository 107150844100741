import React from 'react'
import { connect } from 'react-redux'
import compose from 'recompose/compose'
import { setQuickSearchMaintenanceModeStatus } from '../../actions/applicationStatus'
import { QuickSearch } from '../../requests'
import { WarningIcon } from '../../constants/icons'
import { orange } from '@material-ui/core/colors'
import { Tooltip } from '@material-ui/core'
import { translate } from 'react-admin'
import Typography from '@material-ui/core/Typography'
import {  withStyles } from '@material-ui/core'

const styles = theme =>({
	albumInformationField : {
		width: '100%',
	},
})

const HtmlTooltip = withStyles(theme => ({
	tooltip: {
		backgroundColor: orange[500],
		color: 'rgba(0, 0, 0, 0.87)',
		maxWidth: 220,
		fontSize: theme.typography.pxToRem(12),
		border: '1px solid #dadde9',
	},
}))(Tooltip)

class SearchWarning extends React.Component {
	componentDidMount(){
		const { setQuickSearchMaintenanceModeStatus } = this.props
		QuickSearch.checkSearchMode().then((json)=>{
			setQuickSearchMaintenanceModeStatus(json.data.value.enableMaintenanceMode)
		})
	}

	render(){
		const { isMaintenanceModeEnabled, translate } = this.props
		const warningMessage = translate('messages.quickSearchMaintenance')
		if(isMaintenanceModeEnabled === true){
			return (
				<HtmlTooltip title={
					<React.Fragment>
						<Typography color="inherit">{warningMessage}</Typography>
					</React.Fragment>
				}
				>
					<WarningIcon style={{ color: orange[500] }}/>
				</HtmlTooltip>
				// <Tooltip title={warningMessage}>	
				// 	<WarningIcon style={{ color: orange[500] }}/>
				// </Tooltip>
			)
		}
		else{
			return(
				<div></div>
			)
		}
	}
}

const mapStateToProps = state => ({
	...state,
	isMaintenanceModeEnabled : state.reducer.applicationStatus !== null ?  state.reducer.applicationStatus.isMaintenanceModeEnabled : false
})

const mapDispatchToProps = {
	setQuickSearchMaintenanceModeStatus
}

const enhance = compose(
	connect(mapStateToProps, mapDispatchToProps),
	withStyles(styles)
)
export default translate(enhance(SearchWarning))