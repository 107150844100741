import React, { Component, Fragment } from 'react'
import { connect } from 'react-redux'
import { PricingToolFilter, PricingToolUpdate, PricingToolMovieGrid } from '.'

class PricingTool extends Component {
	render() {
		// eslint-disable-next-line react/prop-types
		const { record, ids, isLoading } = this.props

		const grid = (ids && record && !isLoading) ?
			ids.map(id => {
				const movie = record[id]
				return movie ?
					(
						<div key={`divPricingToolMovieGrid_${id}`} style={{ marginTop: '16px' }}>
							<PricingToolMovieGrid movie={movie} />
						</div>
					) :
					null
			}) :
			null

		return (
			<Fragment>
				<PricingToolFilter />
				<div style={{ marginTop: '16px' }}>
					<PricingToolUpdate />
				</div>
				<div style={{ marginBottom: '50px' }}>
					{grid}
				</div>
			</Fragment>
		)
	}
}

const mapStateToProps = state => ({
	record: state.admin.resources['Transfersrightsprices/MoviesByPeriods']
		? state.admin.resources['Transfersrightsprices/MoviesByPeriods'].data
		: {},
	ids: state.admin.resources['Transfersrightsprices/MoviesByPeriods']
		? state.admin.resources['Transfersrightsprices/MoviesByPeriods'].list.ids
		: {},
	isLoading: state.admin.loading
})


export default connect(mapStateToProps)(PricingTool)

