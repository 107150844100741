import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import CardContent from '@material-ui/core/CardContent'
import { withStyles } from '@material-ui/core/styles'

const styles =   ({
	root: {
		paddingTop: 0,
		paddingBottom: 0,
		'&:first-child': {
			paddingTop: 8,
		},
		'&:last-child': {
			paddingTop: 8,
			paddingBottom: 8,
		},
	},
})

const MyCardContentInner = ({ classes, className, children }) => (
	<CardContent className={classnames(classes.root, className)} >
		{children}
	</CardContent>
)

MyCardContentInner.propTypes = {
	className: PropTypes.string,
	classes: PropTypes.object.isRequired,
	children: PropTypes.node,
}

export default withStyles(styles)(MyCardContentInner)