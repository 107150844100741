import React from 'react'
import { TextField, DateField, NumberField } from 'react-admin'
import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import Typography from '@material-ui/core/Typography'
import { SimpleChildList } from '../../common/lists'
import PostQuickAssistantButton from './PostQuickAssistantButton'
import { ListChildField, ImageField, ContactField, ExtendedTextField } from '../../common/fields'
import { withStyles } from '@material-ui/core/styles'

const styles = theme =>   ({
	cardPaddingMargin: {
		padding: 20,
		margin: 20,
		flex: '1 auto',
	},
	cardDiv: {
		flex: '1 auto',
	},
	flexContent: {
		display: 'flex',
		alignItems: 'flex-start',
	}
})

const PicturesList = withStyles(styles)(({ classes, data, ids, basePath } = this.props) => (
	<div className={classes.flexContent}>
		<Card className={classes.cardPaddingMargin}>
			<CardContent>
				<Typography variant="title">Image pivot</Typography>
				<PostQuickAssistantButton />
			</CardContent>
			<SimpleChildList record={data} idsReducer={ids} basePath={basePath} edit>
				<ImageField label="fields.filename" source="filename" styleImg="imagespeoples" />
				<ExtendedTextField label="fields.typeid" source="typeid" domainid={8} />
				<NumberField label="fields.typeid" source="intTypeid" />
			</SimpleChildList>
		</Card>
		<div className={classes.cardDiv}>
			<Card className={classes.cardPaddingMargin}>
				<CardContent>
					<Typography variant="title">Visuel</Typography>
				</CardContent>
				<ListChildField showHide={true} record={data} idsReducer={ids}>
					<TextField label="fields.id" source="id" />
					<ExtendedTextField label="fields.countryid" source="countryid" domainid={2} />
					<ExtendedTextField label="fields.typeid" source="typeid" domainid={8} />
					<TextField label="fields.filename" source="filename" />
					<TextField label="fields.title" source="title" />
					<NumberField label="fields.width" source="width" />
					<NumberField label="fields.height" source="height" />
				</ListChildField>
			</Card>
			<Card className={classes.cardPaddingMargin}>
				<CardContent>
					<Typography variant="title">Gestion</Typography>
				</CardContent>
				<ListChildField showHide={true} record={data} idsReducer={ids}>
					<DateField showTime={true} label="fields.createdon" source="createdon" />
					<ContactField label="fields.createdby" source="createdby" />
					<DateField showTime={true} label="fields.modifiedon" source="modifiedon" />
					<ContactField label="fields.modifiedby" source="modifiedby" />
				</ListChildField>
			</Card>
		</div>
		<Card className={classes.cardPaddingMargin}>
			<CardContent>
				<Typography variant="title">Visualisation</Typography>
			</CardContent>
			<ListChildField showHide={true} record={data} idsReducer={ids} basePath={basePath}>
				<ImageField source="filename" styleImg="initial" />
			</ListChildField>
		</Card>
	</div>
))

export default PicturesList
