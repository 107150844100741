import CacheItems from './CacheItems'
import { GroupsRights as request } from '../requests'

class GrouprsRights extends CacheItems {
	constructor(cacheKey = 'dataGrouprsRights') {
		super(cacheKey, request.getAll, false)
	}

	static get instance() {
		if (!this.singleton) {
			this.singleton = new GrouprsRights()
			this.singleton.logger.debug(`${this.__proto__.name} > new instance`)
		}

		return this.singleton
	}

	// --------------------
	// Custom methods below
	// --------------------

	// Get one group right
	getItem = (groupid, procid) => {
		if (groupid && procid) {
			const data = this.getAllItems()
			if (null != data && data.length > 0) {
				return Object.values(data).find(item => item.groupid === groupid && item.procid === procid)
			}
		}
		return null
	}
}

const GrouprsRightsInstance = GrouprsRights.instance

export default GrouprsRightsInstance
