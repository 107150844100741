import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { green, grey } from '@material-ui/core/colors'
import get from 'lodash/get'
import FormGroup from '@material-ui/core/FormGroup'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Switch from '@material-ui/core/Switch'
import { Tooltip } from '@material-ui/core'
import { connect } from 'react-redux'
import { withStyles,  createTheme , ThemeProvider } from '@material-ui/core/styles'

const theme = createTheme ({
	overrides: {
		MuiSwitch: {
			bar: {
				'$checked$colorSecondary + &': {
					opacity: 1.0,
					backgroundColor: green[500]
				},
				backgroundColor: '#fff'
			}
		}
	},
	typography: {
		useNextVariants: true,
	}
})

const CustomSwitch = withStyles({
	root:{
		marginLeft: '75px'
	},
	switchBase: {
		color: grey[300],
		'&$checked': {
			color: green[500],
		},
		'&$checked + $track': {
			backgroundColor: green[500],
		},
	},
	checked: {
	},
})(Switch)

class CustomSwitchButton extends Component {
	constructor(props) {
		super(props)
		this.switchFunction = this.switchFunction.bind(this)
		const { source, record = {}, tooltipText } = props
		const currentStatus = get(record, source)
		this.state = {
			tooltipText: currentStatus === 'enabled' ? tooltipText.deactivateText : tooltipText.activateText
		}
	}

	switchFunction = (event) => {
		if(this.props.enableSwitchProcess(this.props) === true){
			this.props.switchFunction(this.props)
			this.setState({
				tooltipText: event.target.checked ? this.props.tooltipText.deactivateText : this.props.tooltipText.activateText
			})
		}
	}
	render(){
		const { source, record = {}, jobExecuteList } = this.props
		const switchButtonStatus = source === 'serverOneStatus' ? jobExecuteList[record.id].serverOneStatus : jobExecuteList[record.id].serverTwoStatus // get the state from redux
		return (
			<Tooltip title={this.state.tooltipText} leaveTouchDelay={1000}>
				<FormGroup>
					<FormControlLabel
						control={
							<ThemeProvider theme={theme}>
								<CustomSwitch
									checked={switchButtonStatus === 'enabled'? true : false}
									onChange={(event)=>this.switchFunction(event)}
									value="checkedB"
								/>
							</ThemeProvider>
						}
					/>
				</FormGroup>
			</Tooltip>
		)
	}

}

CustomSwitchButton.propTypes = {
	source: PropTypes.string,
	record: PropTypes.object,
	classes: PropTypes.object.isRequired,
	serverName: PropTypes.string,
	switchFunction: PropTypes.func,
	enableSwitchProcess: PropTypes.func,
	tooltipText: PropTypes.object
}

const mapStateToProps = state => ({
	...state,
	jobExecuteList: state.admin.resources['Dispatcherconfigjobs/ConfigurationManager']
		? state.admin.resources['Dispatcherconfigjobs/ConfigurationManager'].data
		: {}
})

export default connect(mapStateToProps,null)(CustomSwitchButton)