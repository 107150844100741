import React from 'react'
import { Notification } from 'react-admin'
import green from '@material-ui/core/colors/green'
import {withStyles} from '@material-ui/core/styles'

const styles = (theme) => ({
	anchorOriginTopCenter : {
		fontWeight: 'bold',
		textAlign: 'center'
	},
	confirm: {
		backgroundColor: theme.palette.background.default,
	},
	warning: {
		backgroundColor: theme.palette.error.light
	},
	undo: {
		color: theme.palette.primary.light,
	},
	info: {
		backgroundColor: green[500]
	}
})

const MyNotification = props => {
	const { classes ,...other} = props
	return(
		<Notification {...other} className={ classes.anchorOriginTopCenter } anchorOrigin={{vertical: 'top',horizontal: 'center',}} autoHideDuration={4000} />
	)
}

export default withStyles(styles)(MyNotification)