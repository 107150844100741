import Api from './Api'

const DispatcherConfigurations = {
	getAll: () =>
		Api.Requests.getAll('/DispatcherConfigurations/GetDispatcherConfigurations'),
	getDispatchConfigById : (id) =>
		Api.Requests.getAll('/DispatcherConfigurations/GetDispatcherConfigurations?id=' + id),
	save : (data) =>
		Api.Requests.postWithBody('/DispatcherConfigurations/SaveDispatcherConfigurations', data),
}

export default DispatcherConfigurations
