import { QUICKSEARCH_MAINTENANCE_MODE } from '../constants/actions'

const initialState = {
	isMaintenanceModeEnabled : false
}

export default (state = initialState, { type, payload }) => {
	if (type === QUICKSEARCH_MAINTENANCE_MODE) {
		return { 
			...state,
			isMaintenanceModeEnabled : payload.isMaintenanceModeEnabled
		}
	}
	return state
}