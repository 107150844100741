import React from 'react'
import { Button } from 'react-admin'
import PropTypes from 'prop-types'
import shouldUpdate from 'recompose/shouldUpdate'
import ImageEye from '@material-ui/icons/RemoveRedEye'
import { Link } from 'react-router-dom'
import { linkToRecord } from 'ra-core'
import { getValue } from '../../../tools/utils'

const MyShowButton = ({
	basePath = '',
	label = 'ra.action.show',
	record = {},
	icon = <ImageEye />,
	sources,
	...rest
}) => {

	let to = `${linkToRecord(basePath, record.id)}`
	if (sources && sources.length > 0) {
		to = basePath
		Object.values(sources).map((s, i) => {
			to += 0 === i ? '?' : '&'
			to += s + '='
			to += getValue({ record }, s)
			return null
		})
	}
	to += '/show'

	return (
		<Button
			component={Link}
			to={to}
			label={label}
			{...rest}
		>
			{icon}
		</Button>
	)
}

MyShowButton.propTypes = {
	basePath: PropTypes.string,
	label: PropTypes.string,
	sources: PropTypes.array,
	record: PropTypes.object,
	icon: PropTypes.element,
}

const enhance = shouldUpdate(
	(props, nextProps) =>
		(props.record && nextProps.record && props.record.id !== nextProps.record.id) ||
		props.basePath !== nextProps.basePath ||
		(props.record == null && nextProps.record != null)
)

export default enhance(MyShowButton)