import React, { Fragment, Component } from 'react'
//import { DatePicker, TimePicker } from '@material-ui/pickers'
import { MuiPickersUtilsProvider, DatePicker,TimePicker    } from '@material-ui/pickers' // KeyboardTimePicker,	KeyboardDatePicker
import MomentUtils from '@date-io/moment'
import { addField } from 'ra-core'
import sanitizeRestProps from '../inputs/sanitizeRestProps'
import moment from 'moment'
import PropTypes from 'prop-types'
import AccessTimeIcon from '@material-ui/icons/AccessTime'
import { createTheme, ThemeProvider  } from '@material-ui/core'


moment.locale('fr')
const materialTheme = createTheme ({
	overrides: {
		MuiPickersModal: {
			dialogRoot:{
				backgroundColor: 'white',
				color: "black",
			}
		},
		MuiPickersToolbar:{
			toolbar : {
				backgroundColor: '#288DB1'
			}
		},
		MuiPickersDay: {
			daySelected : {
				backgroundColor:	'#288DB1'
			}
		},
		MuiButton:{
			textPrimary:{
				color: '#288DB1'
			}
		},
		MuiInputBase: {
			root: {
				//color: 'white',
				"&$disabled": {
					"color": "black"
				},
			}},
		MuiFormLabel:{
			root:{
				"&$focused": {
					"color": "#288DB1"
				},
			}
		}
	},
});
/* eslint-disable no-unused-vars */
const dateFormatter = (v, t) => {
	if (!(v instanceof Date) || isNaN(v)) return
	const pad = '00'
	const yyyy = v.getFullYear().toString()
	const MM = (v.getMonth() + 1).toString()
	const dd = v.getDate().toString()
	const HH = v.getHours().toString()
	const mm = v.getMinutes().toString()
	const ss = v.getSeconds().toString()
	return `${yyyy}-${(pad + MM).slice(-2)}-${(pad + dd).slice(-2)} ${(pad + HH).slice(-2)}:${(pad + mm).slice(-2)}:${(pad + ss).slice(-2)}`
}


const sanitizeValue = (value, type) => {
	// null, undefined and empty string values should not go through dateFormatter
	// otherwise, it returns undefined and will make the input an uncontrolled one.
	if (value == null || value === '') {
		return ''
	}

	const finalValue = typeof value instanceof Date ? value : new Date(value)
	return dateFormatter(finalValue, type)
}

class BasicDatePicker extends Component {
	dateOnly = true

	constructor(props) {
		super(props)
		this.state = {
			starttime: [],
			endtime: [],
		}
	}

	onChange = (dateType) => event => {
		if (event) {
			let dateEvent = event.format('YYYY-MM-DDTHH:mm:ss')
			// let currentTime = this.dateOnly ? 'T00:00:00' : moment().format('THH:mm:ss')
			// this.props.input.onChange(dateEvent)
			if (dateType === 'end') {
				if (this.state.endtime.length !== 0) {
					dateEvent = event.set('hour',this.state.endtime[0]).set('minute',this.state.endtime[1]).set('second',59).format('YYYY-MM-DDTHH:mm:ss')
				} else {
					dateEvent = event.set('hour',23).set('minute',59).set('second',59).format('YYYY-MM-DDTHH:mm:ss')
				}
				this.props.input.onChange(dateEvent)
			}
			else if(dateType === 'start') {
				if (this.state.starttime.length !== 0) {
					dateEvent = event.set('hour',this.state.starttime[0]).set('minute',this.state.starttime[1]).format('YYYY-MM-DDTHH:mm:ss')
				}
				this.props.input.onChange(dateEvent)
			} else {
				
				this.setState({[dateType] : [event.hours(), event.minutes()]}, () => {
					// console.log(this.state)
				})
				if (dateType === 'end') {
					dateEvent = event.set('second',59).format('YYYY-MM-DDTHH:mm:ss')
				}
				this.props.input.onChange(dateEvent)
			}
		}
		else {
			this.props.input.onChange(null)
		}
	}

	render() {
		const {
			className,
			isRequired,
			meta,
			input,
			label,
			options,
			resource,
			source,
			dateOnly,
			shrink,
			classes,
			withCustomLabelStyle,
			dateType,
			...rest
		} = this.props
		this.dateOnly = dateOnly
		const { touched, error } = meta
		const value = sanitizeValue(input.value, dateType)
		let localStyle, formatedLabel
		let splitLabel = label.split('publication')
		if (dateType === 'start') {
			formatedLabel = 'Début' + splitLabel[1]
			localStyle = { width: '140px', marginLeft: '5px', marginTop: '15px', color:'#288DB1' }

		} else {
			formatedLabel = 'Fin' + splitLabel[1]
			localStyle = { width: '140px', marginLeft: '5px', marginTop: '15px', color:'#288DB1' }
		}
		if (value !== '') {
			return (

				<ThemeProvider  theme={materialTheme}>
				<MuiPickersUtilsProvider
					utils={MomentUtils}
					moment={moment}
					locale="fr"
				>
					<Fragment>
						<DatePicker
							{...input}
							className={className}
							margin="normal"
							keyboard
							autoOk={false}
							ampm={false}
							//variant="outlined"
							error={!!(touched && error)}
							helperText={touched && error}
							// label={!shrink ?
							// 	<FieldTitle
							// 		label={formatedLabel}
							// 		source={source}
							// 		resource={resource}
							// 		isRequired={isRequired}
							// 	/> :
							// 	null
							// }
							label={formatedLabel}
							inputVariant= "outlined"
							format="DD/MM/YYYY"
							placeholder="JJ/MM/AAAA"
							// handle clearing outside => pass plain array if you are not controlling value outside
							mask={value => (value && value !== '' ? [/\d/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/] : [])}
							// mask={() => ([])}
							clearable
							disableOpenOnEnter
							animateYearScrolling={false}
							value={value}
							onChange={this.onChange(dateType)}
							onBlur={this.onBlur}
							// InputLabelProps={withCustomLabelStyle ? {
							// 	classes: {
							// 		shrink: classes.customLabelStyle
							// 	},
							// 	//style: { paddingLeft: '45px' }
							// } : {}}
							InputLabelProps={{
								shrink: true,
								classes: {shrink: classes.customLabelStyle}
							  }}
							// InputProps={{
							// 	disabled: true
							// }}
							clearLabel="Vider"
							cancelLabel="annuler"
							style={localStyle}
							{...options}
							{...sanitizeRestProps(rest)}
						/>
						<TimePicker
							{...input}
							className={className}
							margin="normal"
							keyboard
							autoOk={false}
							ampm={false}
							//variant="outlined"
							inputVariant= "outlined"
							error={!!(touched && error)}
							helperText={touched && error}
							// label={!shrink ?
							// 	<FieldTitle
							// 		label={label}
							// 		source={source}
							// 		resource={resource}
							// 		isRequired={isRequired}
							// 	/> :
							// 	null
							// }
							format="HH:mm:ss"
							placeholder="HH:MM:SS"
							// handle clearing outside => pass plain array if you are not controlling value outside
							mask={value => (value && value !== '' ? [/\d/, /\d/, ':', /\d/, /\d/, ':', /\d/, /\d/] : [])}
							// mask={() => ([])}
							clearable
							disableOpenOnEnter
							animateYearScrolling={false}
							value={value}
							onChange={this.onChange(dateType+'time')}
							onBlur={this.onBlur}
							// InputLabelProps={withCustomLabelStyle ? {
							// 	classes: {
							// 		shrink: classes.customLabelStyle
							// 	}
							// } : {}}
							InputLabelProps={{
								shrink: true,
								classes: {shrink: classes.customLabelStyle}
							  }}
							// InputProps={{
							// 	disabled: true
							// }}
							clearLabel="Vider"
							cancelLabel="annuler"
							style={{ width: '120px', marginLeft: '15px'  }}
							keyboardIcon={<AccessTimeIcon />}
							{...options}
							{...sanitizeRestProps(rest)}
						/>
					</Fragment>
				</MuiPickersUtilsProvider>
				</ThemeProvider>
			)
		}
		else {
			return (
				<ThemeProvider  theme={materialTheme}>
				<MuiPickersUtilsProvider
					utils={MomentUtils}
					moment={moment}
					locale="fr"
				>
					<Fragment>
						<DatePicker
							{...input}
							className={className}
							margin="normal"
							keyboard
							clearLabel="Vider"
							cancelLabel="annuler"
							autoOk={false}
							ampm={false}
							inputVariant= "outlined"
							//variant="outlined"
							error={!!(touched && error)}
							helperText={touched && error}
							label={formatedLabel}
							// label={!shrink ?
							// 	<FieldTitle
							// 		label={formatedLabel}
							// 		source={source}
							// 		resource={resource}
							// 		isRequired={isRequired}
							// 	/> :
							// 	null
							// }
							format="DD/MM/YYYY"
							placeholder="JJ/MM/AAAA"
							// handle clearing outside => pass plain array if you are not controlling value outside
							//mask={value => (value && value !== '' ? [/\d/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/] : [])}
							mask={() => ([])}
							clearable
							disableOpenOnEnter
							animateYearScrolling={false}
							value={null}
							onChange={this.onChange(dateType)}
							onBlur={this.onBlur}
							// InputLabelProps={withCustomLabelStyle ? {
							// 	classes: {
							// 		shrink: classes.customLabelStyle
							// 	},
							// 	//style: { paddingLeft: '45px' }
							// } : {}}
							InputLabelProps={{
								shrink: true,
								classes: {shrink: classes.customLabelStyle}
							  }}
							style={localStyle}
							{...options}
							{...sanitizeRestProps(rest)}
						/>
						<TimePicker
							{...input}
							className={className}
							margin="normal"
							keyboard
							clearLabel="Vider"
							cancelLabel="annuler"
							autoOk={false}
							ampm={false}
							inputVariant= "outlined"
							//variant="outlined"
							error={!!(touched && error)}
							helperText={touched && error}
							// label={!shrink ?
							// 	<FieldTitle
							// 		label={label}
							// 		source={source}
							// 		resource={resource}
							// 		isRequired={isRequired}
							// 	/> :
							// 	null
							// }
							format="HH:mm:ss"
							placeholder="HH:MM:SS"
							// handle clearing outside => pass plain array if you are not controlling value outside
							// mask={value => (value && value !== '' ? [/\d/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/] : [])}
							mask={() => ([])}
							clearable
							disableOpenOnEnter
							animateYearScrolling={false}
							value={null}
							onChange={this.onChange(dateType+'time')}
							onBlur={this.onBlur}
							// InputLabelProps={withCustomLabelStyle ? {
							// 	classes: {shrink: classes.customLabelStyle}
							// } : {}}
							InputLabelProps={{
								shrink: true,
								classes: {shrink: classes.customLabelStyle}
							  }}
							style={{ width: '120px', marginLeft: '15px'  }}
							keyboardIcon={<AccessTimeIcon />}
							{...options}
							{...sanitizeRestProps(rest)}
						/>

					</Fragment>
				</MuiPickersUtilsProvider>
				</ThemeProvider>
			)
		}
	}
}

BasicDatePicker.propTypes = {
	addLabel: PropTypes.bool,
	dateOnly: PropTypes.bool,
	shrink: PropTypes.bool,
	// input: PropTypes.object,
	// classes: PropTypes.object.isRequired,
	// className: PropTypes.object,
	// meta: PropTypes.object,
	// label: PropTypes.string,
	// options: PropTypes.object,
	// source: PropTypes.object,
	// isRequired: PropTypes.bool,
	// resource: PropTypes.object,
	// withCustomLabelStyle: PropTypes.object,
	// dateType: PropTypes.object

}

const BasicDatePickerField = addField(BasicDatePicker)

BasicDatePickerField.defaultProps = {
	addLabel: true,
	dateOnly: true,
	shrink: true,
}

export default BasicDatePickerField
