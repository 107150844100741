import React, { Component } from 'react'
import PropTypes from 'prop-types'
import get from 'lodash/get'
import MenuItem from '@material-ui/core/MenuItem'
import compose from 'recompose/compose'
import { addField, translate, FieldTitle  } from 'ra-core'
import { ResettableTextField } from 'react-admin'
import { withStyles } from '@material-ui/core/styles'

const sanitizeRestProps = ({
	addLabel,
	allowEmpty,
	basePath,
	choices,
	className,
	component,
	crudGetMatching,
	crudGetOne,
	defaultValue,
	filter,
	filterToQuery,
	formClassName,
	initializeForm,
	input,
	isRequired,
	label,
	locale,
	meta,
	onChange,
	options,
	optionValue,
	optionText,
	disableValue,
	perPage,
	record,
	reference,
	resource,
	setFilter,
	setPagination,
	setSort,
	sort,
	source,
	textAlign,
	translate,
	translateChoice,
	validation,
	...rest
}) => rest

const styles = theme => ({
	input: {
		minWidth: theme.spacing(20),
	},
})

export class MySelectInput extends Component {
	state = {
		value: this.props.input.value,
	};

	UNSAFE_componentWillReceiveProps(nextProps) {
		if (nextProps.input.value !== this.props.input.value) {
			this.setState({ value: nextProps.input.value })
		}
	}

	handleChange = eventOrValue => {
		const value = eventOrValue.target
			? eventOrValue.target.value
			: eventOrValue
		this.props.input.onChange(value)

		// HACK: For some reason, redux-form does not consider this input touched without calling onBlur manually
		this.props.input.onBlur()
		this.setState({ value })
	};

	addAllowEmpty = choices => {
		if (this.props.allowEmpty) {
			return [<MenuItem value="" key="null" />, ...choices]
		}

		return choices
	};
	renderMenuItemOption = choice => {
		const { optionText, translate, translateChoice } = this.props
		if (React.isValidElement(optionText))
			return React.cloneElement(optionText, {
				record: choice,
			})
		const choiceName =
			typeof optionText === 'function'
				? optionText(choice)
				: get(choice, optionText)
		return translateChoice
			? translate(choiceName, { _: choiceName })
			: choiceName
	};

	renderMenuItem = choice => {
		const { optionValue, disableValue } = this.props
		return (
			<MenuItem
				key={get(choice, optionValue)}
				value={get(choice, optionValue)}
				disabled={get(choice, disableValue)}
			>
				{this.renderMenuItemOption(choice)}
			</MenuItem>
		)
	};

	render() {
		const {
			allowEmpty,
			choices,
			classes,
			className,
			input,
			isRequired,
			label,
			meta,
			options,
			resource,
			source,
			shrink,
			...rest
		} = this.props
		if (typeof meta === 'undefined') {
			throw new Error(
				'The MySelectInput component wasn\'t called within a redux-form <Field>. Did you decorate it and forget to add the addField prop to your component? See https://marmelab.com/react-admin/Inputs.html#writing-your-own-input-component for details.'
			)
		}
		const { touched, error, helperText = false } = meta

		return (
			<ResettableTextField
				select
				margin="normal"
				value={this.state.value}
				label={!shrink ?
					<FieldTitle
						label={label}
						source={source}
						resource={resource}
						isRequired={isRequired}
					/> :
					null
				}
				name={input.name}
				className={`${classes.input} ${className}`}
				clearAlwaysVisible
				error={!!(touched && error)}
				helperText={(touched && error) || helperText}
				{...options}
				{...sanitizeRestProps(rest)}
				onChange={this.handleChange}
			>
				{this.addAllowEmpty(choices.map(this.renderMenuItem))}
			</ResettableTextField>
		)
	}
}

MySelectInput.propTypes = {
	allowEmpty: PropTypes.bool.isRequired,
	choices: PropTypes.arrayOf(PropTypes.object),
	classes: PropTypes.object.isRequired,
	className: PropTypes.string,
	input: PropTypes.object,
	isRequired: PropTypes.bool,
	label: PropTypes.string,
	meta: PropTypes.object,
	options: PropTypes.object,
	optionText: PropTypes.oneOfType([
		PropTypes.string,
		PropTypes.func,
		PropTypes.element,
	]).isRequired,
	optionValue: PropTypes.string.isRequired,
	disableValue: PropTypes.string,
	resource: PropTypes.string,
	source: PropTypes.string,
	translate: PropTypes.func.isRequired,
	translateChoice: PropTypes.bool,
	shrink: PropTypes.bool,
}

MySelectInput.defaultProps = {
	allowEmpty: false,
	classes: {},
	choices: [],
	options: {},
	optionText: 'name',
	optionValue: 'id',
	translateChoice: true,
	disableValue: 'disabled',
}

const composed = compose(
	addField,
	translate,
	withStyles(styles)
)(MySelectInput)

composed.defaultProps = {
	shrink: true,
	addLabel: true,
}

export default composed