export default ({
	addLabel,
	allowEmpty,
	basePath,
	cellClassName,
	className,
	formClassName,
	headerClassName,
	label,
	linkType,
	locale,
	record,
	resource,
	sortable,
	sortBy,
	source,
	textAlign,
	translateChoice,
	...rest
}) => rest