import React from 'react'
import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import CardChild from '../../CardChild'
import { getAveragetargetage, getPack } from '../../tools/utils'
import { Ratings } from '../../tools'
import { RSACInput, ButtonInput, DatePickerCanal, MyTextInput, DomainsItemsInput, MySelectInput } from '../common/inputs'
import { MySimpleFormLayout } from '../layout'
import { CardTitleField } from '../common/fields'
import { TvShowIcon, ExternalIcon, RsacIcon, PropertiesIcon } from '../../constants/icons'
import { withStyles } from '@material-ui/core/styles'

const styles =   ({
	card: {
		paddingTop: 10,
		flex: '1 auto',
		backgroudColor :'#cacaca'
	},
})

const color = [
	{ id: 1, name: 'Couleur' },
	{ id: 0, name: 'Noir & Blanc' }
]

const CardCreate = withStyles(styles)(({ classes, record } = this.props) => (
	<MySimpleFormLayout>
		<Card className={classes.card}>
			<MySimpleFormLayout record={record}>
				<MyTextInput label="fields.title" source="title" l />
				<MyTextInput label="fields.shorttitle" source="shorttitle" l />
				<DomainsItemsInput label="fields.typeid" source="typeid" domainid={4} allowEmpty={false} />
				<DomainsItemsInput label="fields.subtypeid" source="subtypeid" domainid={41} allowEmpty={false} />
				<DomainsItemsInput label="fields.pricingclassid" source="pricingclassid" domainid={87} allowEmpty={false} />
				<DomainsItemsInput label="fields.genderid" source="genderid" domainid={75} allowEmpty={false} />
				<DomainsItemsInput label="fields.subgenreid" source="subgenreid" domainid={95} allowEmpty={false} />
				<DomainsItemsInput label="fields.countryid" source="countryid" domainid={2} allowEmpty={false} />
				<DomainsItemsInput label="fields.langid" source="langid" domainid={1} allowEmpty={false} />
				<DatePickerCanal label="fields.releasedate" source="releasedate" />
				<MyTextInput label="fields.year" source="year" xs />
				<MyTextInput label="fields.duration" source="duration" xs />
				<ButtonInput label="fields.color" source="color" choices={color} />
				<DomainsItemsInput label="fields.imagefmtid" source="imagefmtid" domainid={6} allowEmpty={false} />
				<DomainsItemsInput label="fields.audiofmtid" source="audiofmtid" domainid={7} allowEmpty={false} />
				<MyTextInput label="fields.copyright" source="copyright" xl />
				<MyTextInput label="fields.visa" source="visa" xl />
				<MySelectInput label="fields.averagetargetage" source="averagetargetage" choices={getAveragetargetage()} allowEmpty={false} />
				<DomainsItemsInput label="fields.classificationid" source="classificationid" domainid={102} allowEmpty={false} />
				<MySelectInput label="fields.ratingid" source="ratingid" choices={Ratings.getSelectList()} allowEmpty={false} />
			</MySimpleFormLayout>
		</Card>

		<Card className={classes.card}>
			<CardContent>
				<CardTitleField icon={<TvShowIcon />}>Informations séries</CardTitleField>
			</CardContent>
			<CardChild>
				<MySimpleFormLayout record={record}>
					<MyTextInput label="fields.season" source="season" xs />
					<MySelectInput label="fields.pack" source="pack" choices={getPack()} allowEmpty={false} />
					<MyTextInput label="fields.episode" source="episode" xs />
				</MySimpleFormLayout>
			</CardChild>
		</Card>

		<Card className={classes.card}>
			<CardContent>
				<CardTitleField icon={<ExternalIcon />}>Références externes</CardTitleField>
			</CardContent>
			<CardChild>
				<MySimpleFormLayout record={record}>
					<MyTextInput label="fields.allocineid" source="allocineId" />
					<MyTextInput label="fields.imdbid" source="imdbId" />
					<MyTextInput label="fields.cncid" source="cncId" />
					<MyTextInput label="fields.pfVideoid" source="pfVideoId" />
					<MyTextInput label="fields.idbddp" source="idBddp" />
					<MyTextInput label="fields.atgid" source="atgId" />
					<MyTextInput label="fields.numaffaireantenne" source="numAffaireAntenne" />
					<MyTextInput label="fields.idprogrammeantenne" source="idProgrammeAntenne" />
				</MySimpleFormLayout>
			</CardChild>
		</Card>

		<Card className={classes.card}>
			<CardContent>
				<CardTitleField icon={<RsacIcon />}>RSAC</CardTitleField>
			</CardContent>
			<CardChild>
				<MySimpleFormLayout record={record}>
					<RSACInput label="Langue" rsacType="langue" source="rsac" />
					<RSACInput label="Nudité" rsacType="nudite" source="rsac" />
					<RSACInput label="Sexe" rsacType="sexe" source="rsac" />
					<RSACInput label="Violence" rsacType="violence" source="rsac" />
				</MySimpleFormLayout>
			</CardChild>
		</Card>

		<Card className={classes.card}>
			<CardContent>
				<CardTitleField icon={<PropertiesIcon />}>Propriétés</CardTitleField>
			</CardContent>
			<CardChild>
				<MySimpleFormLayout record={record}>
					<MyTextInput label="fields.properties" source="properties" xl />
				</MySimpleFormLayout>
			</CardChild>
		</Card>

	</MySimpleFormLayout>
))

export default CardCreate
