import React, { Component } from 'react'
import { AssignmentTurnedInIcon } from '../../../constants/icons'
import { translate } from 'react-admin'
import { connect } from 'react-redux'
import compose from 'recompose/compose'
import Button from '@material-ui/core/Button'
import AssignPreparationModal from './AssignPreparationModal'
import Badge from '@material-ui/core/Badge'
import Tooltip from '@material-ui/core/Tooltip'
import { withStyles } from '@material-ui/core/styles'

const styles = theme =>  ({
	button: {
		margin: theme.spacing(1),
		marginTop: '0px',
		backgroundColor : '#288DB1',
		top: '5px',
		width : '150px',
		maxHeight: '35px',
		fontSize: '0.75rem',
		lineHeight: '1.2em',
		'&:hover': {
			background: '#1c627b'
		}
	},
 

	
})
const StyledBadge = withStyles((theme) => ({
	badge: {
		top: '-15px',
		right: '-35px',
		zIndex: '0'
	},
  }))(Badge);
const defaultProps = {
	color: "secondary",
};
class Preparation extends Component{
	constructor(props) {
		super(props)
		this.state = {
			modalOpen : false,
			isProcessLoaderOpen : false,
			isOperationFinished : false,
			resultText : null
		}
	}

	handleImportButtonClick = () => {
		this.setState({
			modalOpen : true
		})
	}

	handleCloseProgressBar = () => {
		this.setState({
			isProcessLoaderOpen: false,
			modalOpen : false
		})
	}

	componentDidMount(){
		this.setState({ 
			modalOpen : false
		})
	}
	handleClose = () => {
		this.setState({modalOpen: false})
	}

	render(){
		const { translate, classes, visible, count } = this.props
		const modalContent = {
			disagreeButtonText : translate('ra.action.close'),
			agreeButtonText: 'Valider'
		}
		return(
			
			<React.Fragment>
				
				<Button onClick={this.handleImportButtonClick} color="primary" variant="contained" className={classes.button}>
					{translate('labels.assignPreparation')}				
					{(visible ) ?  ( 
						<Tooltip title="lignes non affectées" >
							 <StyledBadge badgeContent={count} max={999} {...defaultProps} />				
						</Tooltip> 
					) : ''  }
					<AssignmentTurnedInIcon className={classes.rightIcon} />
				</Button>
				<AssignPreparationModal {...this.props}
					modalOpen = {this.state.modalOpen} 
					onClose={this.handleClose} 
					modalContent = {modalContent} 
				/>
			</React.Fragment>
		)
	}
}

const mapStateToProps = state => ({
	...state,
	weekNumber: state.reducer.publicationTracking.weekNumber !==null ? state.reducer.publicationTracking.weekNumber : 16,
	yearNumber: state.reducer.publicationTracking.yearNumber !==null ? state.reducer.publicationTracking.yearNumber : 2020
})

const mapDispatchToProps = ({
	// showNotification,
	// onLoadWeeks,
	// onLoadPlanning
})

const enhance = compose(
	connect(mapStateToProps, mapDispatchToProps),
	withStyles(styles)
)

export default translate(enhance(Preparation))