import React from 'react'
import { SimpleShowLayout, SimpleForm, Show, Edit, Create } from 'react-admin'
import { MyShowActions, MyEditActions, MyCreateActions } from '../../common/actions'
import { CardShow, CardEdit, CardCreate } from '.'

const TransfersrightsShow = (props) => (
	<Show title="titles.transfersRightsCard" actions={<MyShowActions />} {...props}>
		<SimpleShowLayout>
			<CardShow />
		</SimpleShowLayout>
	</Show>
)

const TransfersrightsEdit = (props) => (
	<Edit title="titles.transfersRightsCard" undoable={false} actions={<MyEditActions />} {...props}>
		<SimpleForm>
			<CardEdit />
		</SimpleForm>
	</Edit>
)

const TransfersrightsCreate = (props) => (
	<Create title="titles.transfersRightsCard" undoable={false} actions={<MyCreateActions />} {...props}>
		<SimpleForm>
			<CardCreate />
		</SimpleForm>
	</Create>
)

export { TransfersrightsShow, TransfersrightsEdit, TransfersrightsCreate }