import { ON_PRICE_PLANNING_SELECT_CONTENT, ON_PRICE_PLANNING_EXPAND_UPDATE_FORM, ON_PRICE_PLANNING_CHANGE_CONTENTID_FIELD,
	ON_PRICE_PLANNING_LOAD, ON_PRICE_PLANNING_SELECT_PRICELINE,
	ON_CHECK_UPDATE_FORM, RESET_PREVIEW, ON_PRICE_PLANNING_CHANGE_CONTENTTYPE } from '../constants/actions'

export function onSelectContent(currentPriceClassificationId, currentContentId, data) { 
	return { 
		type: ON_PRICE_PLANNING_SELECT_CONTENT, 
		payload: { 
			selectedPrice : currentPriceClassificationId,
			selectedContent: currentContentId,
			data : data
		} 
	}
}

export function onExpandUpdateForm(isExpanded) {
	return {
		type: ON_PRICE_PLANNING_EXPAND_UPDATE_FORM,
		payload: {
			iExpanded : isExpanded
		}
	}
}

export function onChangeContentFilter(){
	return { 
		type : ON_PRICE_PLANNING_CHANGE_CONTENTID_FIELD
	}
}

export function onPricePlanningFirstLoad(){
	return { 
		type : ON_PRICE_PLANNING_LOAD
	}
}

export function onPricePlanningChangePriceLine(currentPriceClassificationId){
	return {
		type : ON_PRICE_PLANNING_SELECT_PRICELINE,
		payload: {
			selectedPrice : currentPriceClassificationId
		}
	}
}

export function onCheckUpdateForm(resultsPreview){
	return {
		type: ON_CHECK_UPDATE_FORM,
		payload: {
			resultsPreview : resultsPreview
		}
	}
}

export function resetPreview(){
	return {
		type : RESET_PREVIEW
	}
}

export function changeContentType(objectTypeId){
	return {
		type : ON_PRICE_PLANNING_CHANGE_CONTENTTYPE,
		payload : {
			newContentType : objectTypeId
		}
	}
}