import React, { Component } from 'react'
import { createTheme, withStyles, ThemeProvider } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import { CustomTextField, CustomCheckBox, CustomFooterList, CustomDisplayDialog, CustomAlert,CustomUpdateBar } from '../../layout/GlobalStyles'
import CompaniesAlbums from '../../../requests/CompaniesAlbums'
import { DomainsItems } from '../../../../src/tools'
import GestionFields from '../../common/fields/GestionFields'
import Card from '@material-ui/core/Card'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'
import ListItemAvatar from '@material-ui/core/ListItemAvatar'
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction'
import ListItemIcon from '@material-ui/core/ListItemIcon'
//import CloudDownloadIcon from '@material-ui/icons/CloudDownload'
import AssistantIcon from '@material-ui/icons/Assistant'
//import AddIcon from '@material-ui/icons/Add'
import ClearIcon from '@material-ui/icons/Clear'
import { Button, Tooltip, Typography } from '@material-ui/core'
import { Url, Links } from '../../../tools'
import Link from '@material-ui/core/Link'
import AlbumInfoGlobal from './AlbumInfoGlobal'
import { OnAlbumMovieUpdate, OnAlbumMovieListUpdate,OnUpdateFilterProgrammes,OnUpdateProgrammeMode } from '../../../actions/companiesAlbums'
import { connect } from 'react-redux'
import compose from 'recompose/compose'
import ListSubheader from '@material-ui/core/ListSubheader'
//import SearchIcon from '@material-ui/icons/Search'
import IconButton from '@material-ui/core/IconButton'
import LinearProgress from '@material-ui/core/LinearProgress'
import SearchListField from '../../common/fields/SearchListField'
import Papa from 'papaparse'
import { DeleteIcon, SearchIcon, AddIcon, CloudDownloadIcon, } from '../../../constants/icons'
import CustomFilter from './CustomFilter'
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import DragIndicatorIcon from '@material-ui/icons/DragIndicator';

const styles = theme => ({
	container: {
		display: 'flex',
		paddingTop: '10px'
	},
	item: {
		marginRight: '1rem',
		marginLeft: '5px'
	},
	formControl: {
		margin: '.5em .5em 1.5em 2.2em',
		minWidth: '230px',
		height: '25px'
	},
	customSelect: {
		//position: 'absolute',
		top: '8px',
		margin: theme.spacing(1),
		//padding:'6px 8px',
		maxWidth: '90px',
		minHeight: '40px',
		backgroundColor: '#fff',
		borderRadius: '4px',
	},
	cardPaddingMargin: {
		marginRight: '20px'
	},
	button: {
		//margin: theme.spacing(1),
		marginTop: '5px',
		backgroundColor: '#288DB1',
		color: 'white',
		width: '90%',
		maxHeight: '35px',
		// maxWidth: '132px',
		fontSize: '0.7rem',
		//top: '5px',
		lineHeight: '1.2em',
		'&:hover': {
			background: '#1c627b',
			textDecoration: 'none'
		}
	},
	listContainer: {
		backgroundColor: theme.palette.background.paper,
		position: 'relative',
		overflow: 'auto',
		maxHeight: 'calc(70vh - 100px)',
		minWidth: 'auto'

	},
	imageStyle: {
		width: 50,
		maxWidth: 50,
		height: 60,
		objectFit: 'cover',
	},
	rightIcon: {
		marginLeft: theme.spacing(1),
	},
})

// export function modalContent(props) {
// 	return (
// 		<div>
// 			<Typography>
// 				Vous êtes sur le point d'ajouter les programmes selectionnés,
// 				Voulez vous modifier le rang des programmes ajoutés ?
// 			</Typography>
// 			<CustomTextField
// 				label="Rang"
// 				type="number"
// 				value={props.rang}
// 				fullWidth="true"
// 				required="true"
// 				handleChange={e => props.handleRangChange(e)}
// 				errorLabel={props.rang < 0 ? 'Rang doit être suppérieur à 0' : false}
// 			/>
// 		</div>
// 	)

// }

class AlbumMovieFiche extends Component {

	constructor(props) {
		super(props)
		this.state = {
			Albumsmovies: [],
			AlbumsMoviesInit: [],
			types: [],
			childCount: [],
			selectedPerentId: '',
			Listproperties: [],
			count: 0,
			propertiesShow: false,
			ids: [],
			movies: [],
			selectedmovie: {
				rank: 0,
				movie: {
					id: '',
					title: ''
				}
			},
			selectedAlbumMovie: {},
			searchValue: '',
			isManuProg: false,
			openProglist: false,
			isLoading: false,
			//isAssistantRang: false,
			removeMode: false,
			addedRang: 0,
			inProgress: 0,
			movietoRemove: {},
			openRemoveMessage: false,
			showError: false,
			show: false,
			succedMessage: '',
			errorMessage: '',
			checked: [0],
			openFilter: false,
			filterValues: {},
			newMovieRang:[]
		}
	}
	OnchangeParent = (event) => {
		this.setState({ selectedPerentId: event.target.value })
	}
	getalbumsType = () => {
		return Object.values(DomainsItems.getItems(21))
	}

	componentDidMount() {
		//if (this.state.data === []) {
		const { record, type, create, albumMovie,mode,OnUpdateProgrammeMode } = this.props
		if(mode !=='')OnUpdateProgrammeMode('')
		this.setState({ isLoading: true }, () => {
			CompaniesAlbums.getAlbumMoviesbyIds(record.id).then((res) => {
				this.setState({
					movies: res.data,
					AlbumsMoviesInit: res.data
				}, () => { this.setState({ isLoading: false }) })
			})

		})

		if (type === 'edit' && create)
			this.setState({ selectedmovie: albumMovie })

	}
	componentDidUpdate(previewprops,previewstate){
		const { record } = this.props

		if(previewprops.filterProgrammes !== this.props.filterProgrammes){
			this.filterProgrammes()
		}
		if(previewprops.mode !== this.props.mode){
			this.setState({ isLoading: true }, () => {
				CompaniesAlbums.getAlbumMoviesbyIds(record.id).then((res) => {
					this.setState({
						movies: res.data,
						AlbumsMoviesInit: res.data
					}, () => { this.setState({ isLoading: false }) })
				})
	
			})
		}
	}
	handleRangChange(event) {
		this.setState({ addedRang: event.target.value })
	}
	handleMovieClick(event, select) {
		const { OnAlbumMovieUpdate, record } = this.props
		console.log(select)
		let movie = select
		CompaniesAlbums.getAlbumMoviesdetails(record.id, select.movie.id).then((json) => {
			console.log(json.data)
			if (json.data) {

				movie.createdon = json.data.createdon
				movie.createdby = json.data.createdby
				movie.modifiedon = json.data.modifiedon
				movie.modifiedby = json.data.modifiedby
				this.setState({ selectedmovie: movie })
				OnAlbumMovieUpdate(movie)
			} else {
				this.setState({ selectedmovie: select })
				OnAlbumMovieUpdate(select)
			}
		})

	}
	getMuiTheme = () => createTheme({
		overrides: {
			MuiBox: {
				root: {
					paddingTop: '5px'
				}
			},
			MuiGrid: {
				container: {
					// paddingBottom: '15px',
					// paddingTop:'10px'
				}
			},
			MuiTypography: {
				root: {
					// margin: '25px',
					// fontSize: 'larger',
					// color: '#288db1'
				}
			},
			MuiCardContent: {
				root: {
					'&:first-child': {
						paddingTop: 0,
						paddingBottom: 0,
						paddingLeft: 8,
						paddingRight: 8,
					},
				}
			},
			MuiFormLabel: {
				root: {
					"&$focused": {
						"color": "#288DB1"
					},
				}
			},
			MuiOutlinedInput: {
				multiline: {
					width: '480px'
				},
				'root': {  // - The Input-root, inside the TextField-root
					'&:hover fieldset': {
						borderColor: '#288DB1 !important', // - Set the Input border when parent has :hover
					},
					'&.Mui-focused fieldset': { // - Set the Input border when parent is focused 
						borderColor: '#288DB1 !important',
					},
				},
			},
			MuiTypography :{
				colorTextSecondary:{
					color: 'red'
				}}



		},
	})
	handleChangeGeneralItem = (event, field) => {
		const { OnAlbumMovieUpdate } = this.props
		let movie = this.state.selectedmovie
		console.log(movie)
		// if (field === 'Title') movie.movie.title = event.target.value
		if (field === 'Rang') movie.rank = event.target.value
		this.setState({ selectedmovie: movie })
		OnAlbumMovieUpdate(movie)

	}
	handleChangeInput = (event, type) => {
		this.setState({ searchValue: event.target.value })
		if (type === 'reset') {
			this.setState({ searchValue: '' })
			this.setState({ movies: this.state.AlbumsMoviesInit })
		}

	}

	// import file
	handleImportButtonClick = (loaded = false) => {
		let moviesSCV = []
		let movies = this.state.isManuProg ? this.state.movies.filter(s => !s.system) : this.state.movies
		if (movies.length > 0) {
			movies.map(d => {
				if (d.movie)
					moviesSCV.push({ id: '[' + d.movie.id + ']', programme: d.movie.title + '/' + d.rank })
			})
			this.exportCsvFile(moviesSCV)
		}

	}
	exportCsvFile = (data) => {
		const { record } = this.props
		var options = {
			quotes: false,
			quoteChar: '"',
			escapeChar: '"',
			delimiter: "\t",
			header: true,
			newline: "\r\n",
			skipEmptyLines: false,
			columns: null
		}
		var csv = Papa.unparse(data, options)
		var csvData = new Blob([csv], { type: 'plain/text;charset=utf-8;' })
		var csvURL = null
		if (navigator.msSaveBlob) {
			csvURL = navigator.msSaveBlob(csvData, 'export_programme_' + record.id + '.txt')
		} else {
			csvURL = window.URL.createObjectURL(csvData)
		}
		var tempLink = document.createElement('a')
		tempLink.href = csvURL
		tempLink.setAttribute('download', 'export_programme_' + record.id + '.txt')
		tempLink.click()
	}
	searchMovie = () => {
		if (this.state.searchValue === '') {
			this.setState({ movies: this.state.AlbumsMoviesInit })
		}
		else {
			let res = []
			res = this.state.AlbumsMoviesInit.filter(s => s.movie.title.toLocaleUpperCase().includes(this.state.searchValue.toLocaleUpperCase()) || s.movie.id == this.state.searchValue)
			// if(!this.state.isManuProg ) res = res.filter(s=>s.system === false)
			this.setState({ movies: res })

		}
	}
	OnchangeIsAutoProg = () => {
		this.setState({ isManuProg: !this.state.isManuProg })
	}
	handleAddMoviesClick = () => {
		this.props.OnAlbumMovieListUpdate([])
		this.setState({ openProglist: true })
	}
	handleRemoveMoviesClick = () => {
		const {  OnUpdateProgrammeMode } = this.props
		//this.setState({ removeMode: true })
		OnUpdateProgrammeMode('Clean')
		// const editUrl = Links.CompaniesalbumsEdit(record.id);
        // window.location.href = editUrl
	}
	closeSearch = (event) => {
		this.props.OnAlbumMovieListUpdate([])

		this.setState({ addedRang: 0, openProglist: false })
	}
	// add album Movie
	saveAlbumMovies = () => {
		const { addedMoviesToAlbum, OnAlbumMovieListUpdate, record } = this.props
		let result = addedMoviesToAlbum
		if (result.length !== 0) {
			let inProgressCount = 0
			Promise.all(result.map(async elt => {
				await CompaniesAlbums.addAlbumsMovies(record.id, elt.id, this.state.addedRang)
			})).then(() => {
				CompaniesAlbums.getAlbumMoviesbyIds(record.id)
					.then(res => {
						this.setState({
							movies: res.data,
							AlbumsMoviesInit: res.data
						}, () => {
							this.setState({
								show: true,
								succedMessage: 'Programmes ajoutés',
								isLoading: false,
								openProglist: false
							})
							OnAlbumMovieListUpdate([])
							//resolve()
						})
					})
			}).catch((error) => {
				//reject()
				this.setState({ showError: true })
				const timeId = setTimeout(() => {
					this.setState({
						showError: false,
						errorMessage: 'Erreur en ajoutant les programmes'
					})
					OnAlbumMovieListUpdate([])
				}, 3000)
				return () => {
					clearTimeout(timeId)
				}
			})
		}



	}

	//remove albumMovie
	SetopenRemoveAlerte = (event, value) => {
		this.setState({
			movietoRemove: value,
			openRemoveMessage: true
		})
	}
	handleCloseErrorMessage = () => {
		this.setState({ openRemoveMessage: false })

	}
	handleRemoveMovieValidate = () => {
		const { record } = this.props
		this.setState({ openRemoveMessage: false, isLoading: true })
		new Promise((resolve, reject) => {
			CompaniesAlbums.removeAlbumsMovie(record.id, this.state.movietoRemove.movie.id).then((data) => {
				CompaniesAlbums.getAlbumMoviesbyIds(record.id).then((res) => {
					this.setState({
						movies: res.data,
						AlbumsMoviesInit: res.data
					}, () => { this.setState({ isLoading: false }) })
				})

				this.setState({
					show: true,
					succedMessage: 'Programme supprimé'
				})
				resolve()
			}
			).catch((error) => {
				reject()
				this.setState({ showError: true })
				const timeId = setTimeout(() => {
					this.setState({
						showError: false,
						errorMessage: 'Erreur lors de la suppression'
					})
				}, 3000)
				return () => {
					clearTimeout(timeId)
				}
			})
		})

	}
	handlecheckToRemove = (value, checkedvalue) => {
		const { checked, isManuProg, movies } = this.state
		let currentIndex = 0
		let newChecked = [...checked]
		if (value === 'all') {
			if (checkedvalue) {
				let movie = isManuProg ? movies.filter(s => !s.system) : movies

				movie.forEach((elt => {
					currentIndex = checked.indexOf(elt.movie.id)
					if (currentIndex === -1) {
						newChecked.push(elt.movie.id)
					}
				}))
			}
			else {
				newChecked = [0]
			}

		} else {
			currentIndex = checked.indexOf(value)
			if (currentIndex === -1) {
				newChecked.push(value)
			} else {
				newChecked.splice(currentIndex, 1)
			}
		}
		this.setState({ checked: newChecked })
		this.props.OnAlbumMovieListUpdate(newChecked.filter(s=>s !=0))
	}

	//Gestion Alert 
	handleOpenAlert = () => {
		this.setState({ show: false })
		this.setState({ showError: false })
	}
	onDragEnd = ({ destination, source }) => {
		const { movies } = this.state
		// dropped outside the list
		if (!destination) return

		const newItems = this.reorder(movies, source.index, destination.index);
		// Create a new array here!
		
		this.setState({ movies: newItems })
		//setItems(newItems)
		const updatedMovies = newItems.map((movie, index) => ({
			...movie,
			newrank:  movies.length - parseInt(index) // index + 1 // Mettre à jour le rang en fonction de la position; ajustez selon votre logique de rang
		}))
		this.setState({ movies: updatedMovies })

		this.setState({newMovieRang : updatedMovies})
		this.props.OnAlbumMovieListUpdate(updatedMovies)
 
	}
	reorder = (list, startIndex, endIndex) => {
		const result = Array.from(list)
		const [removed] = result.splice(startIndex, 1)
		result.splice(endIndex, 0, removed)
		
		return result
	}
	 
	handleUpdateRank =()=>{
		const {mode,OnUpdateProgrammeMode} = this.props
		//this.setState({ isAssistantRang: true })
		OnUpdateProgrammeMode('Assistant')
	}
	filterProgrammes =()=>{
		const {filterProgrammes}  = this.props
		//if(filterProgrammes.rank !=='' && filterProgrammes.rankorder !==''){
			switch (filterProgrammes.rankorder) {
				case 'sup':
					if(filterProgrammes.rank)
						this.setState({movies : this.state.movies.filter(s=>s.rank >= filterProgrammes.rank)})
					break;
				case 'inf':
					if(filterProgrammes.rank)
						this.setState({movies : this.state.movies.filter(s=>s.rank <= filterProgrammes.rank)})
					break;
				default:
					//this.setState({ movies: this.state.AlbumsMoviesInit })
					break;
			}
			switch (filterProgrammes.dateorder) {
				case 'sup':
					if(filterProgrammes.date)
						this.setState({movies : this.state.movies.filter(s=>s.createdon >= filterProgrammes.date)})
					break;
				case 'inf':
					if(filterProgrammes.date)
						this.setState({movies : this.state.movies.filter(s=>s.createdon <= filterProgrammes.date)})
				default:
					//this.setState({ movies: this.state.AlbumsMoviesInit })
					break;
			}
			if(filterProgrammes.rank ==='' && filterProgrammes.date===''){
				this.setState({ movies: this.state.AlbumsMoviesInit })

			}
		 
	}
	render() {
		const { record, classes, create, type, albumMovie, addedMoviesToAlbum ,filterProgrammes,OnUpdateFilterProgrammes,mode} = this.props
		let movies = this.state.isManuProg ? this.state.movies.filter(s => !s.system) : this.state.movies
		if(mode === 'Assistant') movies = this.state.movies.filter(s=>s.rank !== 0)
		//this.filterProgrammes()

 		return (
			<div>
				<ThemeProvider theme={this.getMuiTheme()}>
					<Grid container spacing={12} direction="row" justify="space-between" >
						<Grid container direction="row" xs={12}>
							<Grid container>
								<Grid item xs={4}>
									<Card className={classes.cardPaddingMargin}>
										<List dense className={classes.listContainer}
											subheader={
												<ListSubheader >

													<Grid container direction="row">
														<Grid item xs={7}>
															<CustomTextField label="Recherche programme" value={this.state.searchValue} handleChange={e => this.handleChangeInput(e, '')}
																valueReset={(e) => this.handleChangeInput(e, 'reset')} create="true" handleKeyPress={e => this.searchMovie()}
																fullWidth="true" removeicon={true} />
														</Grid>
														<Grid item xs={5}>
															<IconButton type="button" aria-label="search" onClick={() => this.searchMovie()}   >
																<SearchIcon />
															</IconButton>
															<Tooltip title='Exporter Liste'>
																<IconButton type="button" aria-label="export" onClick={() => this.handleImportButtonClick()} disabled={movies.length === 0} >
																	<CloudDownloadIcon />
																</IconButton>
															</Tooltip>
															{/* <Tooltip title={!this.state.openFilter ? 'Filtre' : ''} >
																<IconButton type="button" aria-label="filter" onClick={() => { this.setState({ openFilter: true }) }}    >
																	<CustomFilter open={this.state.openFilter} filter={filterProgrammes} OnUpdateFilterProgrammes={OnUpdateFilterProgrammes}></CustomFilter>
																</IconButton>
															</Tooltip> */}


														</Grid>
														<Grid item xs='11'>
															<CustomCheckBox label="Masquer les éléments saisi par le système ?" value={this.state.isManuProg} onChange={this.OnchangeIsAutoProg} labelPlacement="end" width='20%' fullWidth="true" />
														</Grid >
														{mode === 'Clean' &&
															<Grid item xs='1'  >
																<CustomCheckBox onChange={(e, checked) => this.handlecheckToRemove('all', checked)} tooltipTitle='Sélectionner tout' />
															</Grid>}
														
													</Grid>
													{mode ==='Assistant' &&
															<Grid item   >
																<Typography>Déplacer les programmes pour changer le rang</Typography>
 															</Grid>}

												</ListSubheader>
											}
										>
											{this.state.AlbumsMoviesInit.length === 0 && this.state.isLoading && <LinearProgress />}
											{mode ==='Assistant' ?
												<DragDropContext onDragEnd={this.onDragEnd}>
													<Droppable droppableId="droppable-list">
														{(provided) => (
															<div ref={provided.innerRef} {...provided.droppableProps}>
																{movies.map((value, index) => (
																	<Draggable
																		draggableId={`${value ? value.movie.id : 0}`}
																		index={index}
																		key={value ? value.movie.id : 0}
																	>
																		{(provided, snapshot) => (
																			
																			<ListItem key={value.movie.id}  //button
																				ref={provided.innerRef}
																				{...provided.draggableProps}
																				{...provided.dragHandleProps}
																				className={
																					snapshot.isDragging ? classes.draggingListItem : ""
																				}
																				//disabled={value.rank ==0}

																			>

																				<ListItemAvatar>
																					<img
																						src={Url.avatarMovie(value.movie.id)}
																						className={classes.imageStyle}
																					/>
																				</ListItemAvatar>
																				<Tooltip title='cliquez pour déplacer'>
																				<ListItemText id={value.movie.id} primary={`${value.movie.title} (${value.movie.id}) [${value.rank}]`} secondary={` ${value.newrank ? value.newrank : value.rank}`} /> 
																				{/* secondary={` ${movies.length - parseInt(index)}`} */}
																				{/*  */}
																				</Tooltip>
																				<ListItemSecondaryAction >
																					<IconButton title='Déplacer'  
																						edge="end"  
																						{...provided.draggableProps}
																						{...provided.dragHandleProps}	
																						//onClick={(event) => this.SetopenRemoveAlerte(event, value)} 
																						>
																						<DragIndicatorIcon />
																					</IconButton>  
																				</ListItemSecondaryAction>
																			</ListItem>
																		)}
																	</Draggable>
																))}
																{provided.placeholder}
															</div>
														)}
													</Droppable>
												</DragDropContext>
												:
												movies.map((value) => {
													return (
														<ListItem key={value.movie.id} button
															disabled={create && type === 'edit' && albumMovie && albumMovie.movie && albumMovie.movie.id !== value.movie.id}
															selected={this.state.selectedmovie === value}
															style={{ background: (this.state.selectedmovie === value && 'gainsboro') || (value.system && 'oldlace') }}
															onClick={(event) => this.handleMovieClick(event, value)}>
															<ListItemAvatar>
																<img
																	src={Url.avatarMovie(value.movie.id)}
																	className={classes.imageStyle}
																/>
															</ListItemAvatar>
															<ListItemText id={value.movie.id} primary={`${value.movie.title} (${value.movie.id}) [${value.rank}]`} />
															<ListItemSecondaryAction >
																{mode==='' ?
																	<IconButton title='Supprimer' disabled={type === 'create'}
																		edge="end" aria-label="delete"
																		onClick={(event) => this.SetopenRemoveAlerte(event, value)} >
																		<DeleteIcon />
																	</IconButton> :
																	<CustomCheckBox
																		edge="start"
																		value={this.state.checked.indexOf(value.movie.id) !== -1}
																		tabIndex={-1}
																		disableRipple
																		//inputProps={{ 'aria-labelledby': labelId }}
																		onChange={() => this.handlecheckToRemove(value.movie.id)}
																	/>
																}
															</ListItemSecondaryAction>
														</ListItem>
													)
												})
											}


										</List>
										<CustomFooterList curent={movies.length} total={this.state.AlbumsMoviesInit.length} />
										{/* <Divider />

										<div style={{height:'40px',display:'flex', 	justifyContent: 'flex-end',	padding: '20px 24px 0px 24px',}}>
											Nb d'éléments : {movies.length} / {this.state.AlbumsMoviesInit.length} 
										</div>
									*/}
									</Card>
									{!create && (mode ==='') &&
									<Grid container style={{ paddingTop: '5px' }}>
										<Grid item xs={3}>
											<Button onClick={this.handleAddMoviesClick} color="primary" variant="contained" className={classes.button} style={{fontSize:'9.5px'}}>
												Ajouter Programmes
												<AddIcon className={classes.rightIcon} />
											</Button>
										</Grid>

										<Grid item xs={3}>
											{/* <Button color="primary" onClick={this.handleUpdateRank} variant="contained" className={classes.button}> */}
											<Button    onClick={this.handleUpdateRank} color="primary" variant="contained"    className={classes.button} disabled={!movies.find(s=>s.rank !=0)}>
												Assistant Rang
												<AssistantIcon className={classes.rightIcon} />
											</Button>
										</Grid>

										<Grid item xs={3}>
											<Button   onClick={this.handleRemoveMoviesClick}  disabled={mode==='Clean'} color="primary" variant="contained" className={classes.button}>
												Nettoyer
												<ClearIcon className={classes.rightIcon} />
											</Button>
										</Grid>
										 
										<Grid item xs={3}>
											<Button color="primary" variant="contained" className={classes.button} disabled={true}>
												Assistant Discount
												<CloudDownloadIcon className={classes.rightIcon} />
											</Button>
										</Grid>
									</Grid>}
									{/* {(this.state.isAssistantRang || this.state.removeMode ) &&
 
										<Grid  container style={{ paddingTop: '5px' }}>
											<Grid item xs={4}>
											<Button onClick={this.handleImportButtonClick} color="primary" variant="contained" className={classes.button}>
												Valider
												<CloudDownloadIcon className={classes.rightIcon} />
											</Button>
										</Grid> 
										<Grid item xs={3}>
											<Button color="primary" variant="contained" className={classes.button}>
												Annuler
												<CloudDownloadIcon className={classes.rightIcon} />
											</Button>
										</Grid>
										</Grid>
									


									} */}
									
								</Grid>

								<Grid item xs={8}>

									<Grid container>
										<Grid item xs={8} direction="row" >
											<Grid item xs={12}>
												<AlbumInfoGlobal recordelt={record} create={false} />
											</Grid>
											<CustomTextField label="ID Programme" value={this.state.selectedmovie &&  this.state.selectedmovie.movie ? this.state.selectedmovie.movie.id :''} width='35%' fullWidth="false" />
											<CustomTextField label="Titre" required={true} value={this.state.selectedmovie && this.state.selectedmovie.movie ? this.state.selectedmovie.movie.title :''} width='35%' fullWidth="true" />
											<CustomTextField label="Rang" required={true} create={create} type='number' handleChange={(event, field) => this.handleChangeGeneralItem(event, 'Rang')} value={this.state.selectedmovie? this.state.selectedmovie.rank : 0} width='35%' fullWidth="true" />
											<div style={{ paddingLeft: '10px' }}>
												<CustomCheckBox label="Saisi par le système" value={this.state.selectedmovie && this.state.selectedmovie.system ? 1 : 0} labelPlacement="end" width='35%' fullWidth="true" />
											</div>
											<br></br>

											{this.state.selectedmovie && this.state.selectedmovie.movie && this.state.selectedmovie.movie.id !== '' && 
											<Link
												component="button"
												variant="body2"
												onClick={() => {
													window.open(Url.pictureMovie(this.state.selectedmovie.movie.id, this.state.selectedmovie.movie.id + '_pivot_large.jpg'), '_blank')
												}}
											>
												<img src={Url.pictureMovie(this.state.selectedmovie.movie.id, this.state.selectedmovie.movie.id + '_pivot_large.jpg')} style={{ width: '300px', height: '400px' }} />
											</Link>}

										</Grid>

										<Grid item xs={4} direction="row" >
											<GestionFields classes={classes} record={this.state.selectedmovie   } />
										</Grid>
									</Grid>
								</Grid>

							</Grid>
						</Grid>
					</Grid>
					{/*Ajout des programmes   */}
					<SearchListField open={this.state.openProglist} close={this.closeSearch} exceptionList={this.state.AlbumsMoviesInit} source='albumMovies' isprogrammeList={true}
						TypeItem='Movies' multiSelect={true} multiSearch={true} create={true} Title='Ajouter un ou plusieurs programmes' albumMovie={record} />
					<CustomDisplayDialog info="Alerte" title='Ajout des programmes' handleClose={this.closeSearch}
						open={this.state.openProglist && addedMoviesToAlbum.length !== 0} handleSave={this.saveAlbumMovies}
						content={<div>
							<Typography>
								Vous êtes sur le point d'ajouter le/les programmes à l'album,
								Voulez vous continuer ?
							</Typography>
							{/* <CustomTextField
								label="Rang"
								type="number"
								value={this.state.addedRang}
								fullWidth="true"
								required="true"
								onChange={e => this.handleRangChange(e)}
								errorLabel={this.state.addedRang < 0 ? 'Le rang doit être supérieur à 0' : false}
							/> */}
						</div>
						}
						 width='sm' type='window' />
					{/*Suppression de programme   */}
					<CustomDisplayDialog info='Alerte' type='window' handleClose={this.handleCloseErrorMessage} handleSave={this.handleRemoveMovieValidate} open={this.state.openRemoveMessage}
						content={
							<span>
								{record.isPack ? 'Ce programme peut être utilisé dans un pack. ' : ''}
								<br />Êtes-vous sûr de vouloir supprimer le programme :<br />
								<b>{this.state.movietoRemove && this.state.movietoRemove.movie ? this.state.movietoRemove.movie.title : ''}</b> ?
							</span>}
						width='sm' />


					{/* control  des   alert   */}
					{this.state.show ? (<CustomAlert libelle={this.state.succedMessage} severity="success" open={!this.state.showError} setOpen={this.handleOpenAlert} />) : null}
					{this.state.showError ? (<CustomAlert libelle={this.state.errorMessage} severity="error" open={this.state.showError} setOpen={this.handleOpenAlert} />) : null}
					{/* {this.state.openFilter && <CustomFilter open={this.state.openFilter}></CustomFilter>} */}
				</ThemeProvider >
			</div>

		)
	}
}
const mapStateToProps = state => ({
	...state,
	albumMovie: state.reducer.albums.albumMovie !== undefined ? state.reducer.albums.albumMovie : null,
	addedMoviesToAlbum: state.reducer.albums.addedMoviesToAlbum !== undefined ? state.reducer.albums.addedMoviesToAlbum : [],
	filterProgrammes : state.reducer.albums.filterProgrammes !== undefined ? state.reducer.albums.filterProgrammes : {},
	mode : state.reducer.albums.mode 

})

const mapDispatchToProps =  ({
	OnAlbumMovieUpdate,
	OnAlbumMovieListUpdate,
	OnUpdateFilterProgrammes,
	OnUpdateProgrammeMode 
})
const enhance = compose(
	connect(mapStateToProps, mapDispatchToProps),
	withStyles(styles)
)
export default enhance(AlbumMovieFiche)

