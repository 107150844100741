/* eslint-disable no-mixed-spaces-and-tabs */
import React from 'react'
import Dialog from '@material-ui/core/Dialog'
import Slide from '@material-ui/core/Slide'
import PropTypes from 'prop-types'
import cyan from '@material-ui/core/colors/cyan'
import {  translate, showNotification, fetchEnd, fetchStart } from 'react-admin'
import { DateFormatter, PublicationTrackingReferenceData } from '../../../tools'
import { submit, initialize  } from 'redux-form'
import { connect } from 'react-redux'
import compose from 'recompose/compose'
import { Publicationstracking } from '../../../requests'
import { Contacts as ContactsTools } from '../../../tools'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableRow from '@material-ui/core/TableRow'
import Paper from '@material-ui/core/Paper'
import { onLoadPlanning, onChangeUser, onChangeCategory,onChangeTaskType,onChangeSearch, onChangeTaskGroup } from '../../../actions/publicationTracking'
import TablePagination from '@material-ui/core/TablePagination'
import Checkbox from '@material-ui/core/Checkbox'
import EnhancedTableHead from './preparation/EnhancedTableHead'
import EnhancedToolbar from './preparation/EnhancedToolbar'
import UserSelector from './preparation/UserSelector'
import AssignProcessLoader from './preparation/AssignProcessLoader'
import {CloseIcon } from '../../../constants/icons'
import Button from '@material-ui/core/Button'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import { yellow } from '@material-ui/core/colors'
import PublicationStats from '../fields/PublicationStats'
import { withStyles } from '@material-ui/core/styles'
import ReferenceDataCell from './ReferenceDataCell'
const Transition = React.forwardRef(function Transition(props, ref) {
	return <Slide direction="up" ref={ref} {...props} />
})

const styles = theme => ({
	button: {
		margin: theme.spacing(1),
		width : '150px'
	},
	dialogGlobal : {
		background: theme.palette.background.default,//'#353333',
		maxHeight: '60%',
		overflow: 'scroll',
		
	},
	dialogHeader:{
		padding: '10px 10px 10px',
		background: cyan[500],
		color: 'white',
		fontSize: 'medium',
		fontWeight: 'bold',
		textAlign: 'center',
		//height: '36px'
	},
	closeIcon:{
		//marginLeft: '40%',
		width : '15px',
		position: 'absolute',
    	top: '0px',
    	right: '0px',
		borderRadius: '0',
		color: 'white',
		'&:hover': {
			background: 'red'//red
		}
	},
	dialogContent: {
		padding: '16px 24px 17px',
		flex: '1 1 auto',
		color: 'white',
		fontSize: '1rem',
		fontWeight: '400',
	},
	formControl: {
		margin: theme.spacing(1),
		minWidth: 120,
	},
	selectEmpty: {
		marginTop: theme.spacing(2),
	},
	customSelect: {
		width : '80%'
	},
	table: {
		// width: '100%',
		//width : '1055px'
	},
	tabCell: {
		padding: '5px 0px 5px 0px',
		lineHeight:'1.3em'
	},
	paper: {
		width: '100%',
		marginBottom: theme.spacing(2),
	},
	  
	visuallyHidden: {
		border: 0,
		clip: 'rect(0 0 0 0)',
		height: 1,
		margin: -1,
		overflow: 'hidden',
		padding: 0,
		position: 'absolute',
		top: 20,
		width: 1,
	},
	paperWidthSm : {
		maxWidth: '1220px',
		// width: '400px'
	},
	isupdateclasse :{
		padding : '15px 5px 25px 200px'
	},
	rightIcon: {
		marginLeft: theme.spacing(1),
	},
	showAll:{
		margin: '8px',
		 marginLeft: '75%',
		 marginTop: '-50px',
		//marginRight: '60%'		
	}
})

class AssignPreparationModal extends React.Component {
	constructor(props) {
		super(props)
		this.state = {
			open : false,
			enabled : true,
			stories : [],
			order : 'asc',
			orderBy : 'categoryId',
			selected : [],
			page : 0,
			dense : true,
			rowsPerPage : 50,
			rows : [],
			isProcessLoaderOpen : false,
			isOperationFinished : false,
			resultText : '',
			isupdate : true
		}
	}


	componentDidMount(){
		const { modalOpen } = this.props
		this.setState({ 
			open : modalOpen
		})
	}

	componentDidUpdate(previousProps, previousState) {
	 
		if(previousProps.modalOpen !== this.props.modalOpen ){
			const { modalOpen, onChangeUser, onChangeCategory ,onChangeTaskType,onChangeSearch,onChangeTaskGroup  } = this.props
			this.setState({ 
				open : modalOpen,
			})
			onChangeUser(null)
			onChangeCategory(null)
			onChangeTaskType(null)
			onChangeTaskGroup(null)
			onChangeSearch(null)
			previousState.isupdate = true
			this.setState({
				isupdate: true
			})
		}
		if (previousProps !== this.props || previousState.isupdate !== this.state.isupdate    ) {
			const { planningData, weekNumber, yearNumber, selectedCategory, selectedTaskType,selectedTaskGroup, programTitle,  } = this.props
			const weekFilter = Number.parseInt(`${yearNumber.toString()}${weekNumber.toString().length ===2 ? weekNumber.toString() : '0' +weekNumber.toString()}`)
			let planningDataFiltered = planningData.filter(item => item.week === weekFilter && item.publications === null
				&& item.desactivatedBy === null && item.desactivatedOn === null)
			if(!this.state.isupdate)
			//  planningDataFiltered  = planningData.filter(item => item.week === weekFilter && item.publications === null 
			// 	&& item.desactivatedBy === null && item.desactivatedOn === null)
			// 	else
			 planningDataFiltered  = planningData.filter(item => item.week === weekFilter  
					&& item.desactivatedBy === null && item.desactivatedOn === null)	
			if(selectedCategory !== 0){
				planningDataFiltered = planningDataFiltered.filter(item => item.categoryId === selectedCategory)
			}
			if(selectedTaskType !== 0){
				planningDataFiltered = planningDataFiltered.filter(item => item.taskTypeId === selectedTaskType)
			}
			if(selectedTaskGroup !== 0){
				const ids =	[...new Set(this.props.weektasktype.filter(s=>s.taskGroupDomainItemId === selectedTaskGroup).map(x => x.itemid))]
				planningDataFiltered =[...new Set(planningDataFiltered.filter(x => ids.includes(x.taskTypeId)))]
			}
			if(programTitle !== ""){
				let searchplanning = []
				planningDataFiltered.forEach(element => {
					if (element.movieTitle.toUpperCase().includes(programTitle.toUpperCase()))
						searchplanning.push(element)
				})
				planningDataFiltered = searchplanning
			}
			this.setState({ 
				rows : planningDataFiltered
			})
			onLoadPlanning(planningDataFiltered)
		}
	}

	handleRequestSort = (event, property) => {
		const isAsc = this.state.orderBy === property && this.state.order === 'asc'
		this.setState({
			order : isAsc ? 'desc' : 'asc',
			orderBy : property
		})
	};
	
	handleSelectAllClick = (event) => {
		if (event.target.checked) {
		  const newSelecteds = this.state.rows.map((n) => n.id)
		  this.setState({
			  selected : newSelecteds
		  })
		  return
		}
		this.setState({
			selected : []
		})
	};
	
	handleClick = (event, name) => {
		const selectedIndex = this.state.selected.indexOf(name)
		let newSelected = []
		if (selectedIndex === -1) {
		  newSelected = newSelected.concat(this.state.selected, name)
		} else if (selectedIndex === 0) {
		  newSelected = newSelected.concat(this.state.selected.slice(1))
		} else if (selectedIndex === this.state.selected.length - 1) {
		  newSelected = newSelected.concat(this.state.selected.slice(0, -1))
		} else if (selectedIndex > 0) {
		  newSelected = newSelected.concat(
				this.state.selected.slice(0, selectedIndex),
				this.state.selected.slice(selectedIndex + 1),
		  )
		}
		this.setState({
			selected : newSelected
		})
	};
	
	handleChangePage = (event, newPage) => {
		this.setState({
			page : newPage
		})
	};
	
	handleChangeRowsPerPage = (event) => {
		this.setState({
			page : 0,
			rowsPerPage : parseInt(event.target.value, 10)
		})
	};
	
	handleChangeDense = (event) => {
		this.setState({
			dense : event.target.checked
		})
	  };
	
	isSelected = (name) => this.state.selected.indexOf(name) !== -1;


	descendingComparator = (a, b, orderBy) => {
		if (b[orderBy] < a[orderBy]) {
		  return -1
		}
		if (b[orderBy] > a[orderBy]) {
		  return 1
		}
		return 0
	}
	  
	getComparator = (order, orderBy) => {
		return order === 'desc'
		  ? (a, b) => this.descendingComparator(a, b, orderBy)
		  : (a, b) => -(this.descendingComparator(a, b, orderBy))
	}
	  
	stableSort = (array, comparator) => {
		const stabilizedThis = array.map((el, index) => [el, index])
		stabilizedThis.sort((a, b) => {
		  const order = comparator(a[0], b[0])
		  if (order !== 0) return order
		  return a[1] - b[1]
		})
		return stabilizedThis.map((el) => el[0])
	}

	manageProcessLoader = (isOperationFinished, isProcessLoaderOpen, resultText) => {
		this.setState({
			isOperationFinished : isOperationFinished,
			isProcessLoaderOpen : isProcessLoaderOpen,			
			resultText : resultText,
			//isupdate: true
		})
	}

	handleCloseProgressBar = () => {
		this.setState({
			isProcessLoaderOpen: false
		})
	}

	initializeSelection = () => {
		this.setState({
			selected : []
		})
		
	}

	initializePlanningData = () => {
		const { weekNumber, yearNumber, onLoadPlanning } = this.props
		Publicationstracking.getPlanning(Number.parseInt(`${yearNumber.toString()}${weekNumber.toString().length ===2 ? weekNumber.toString() : '0' +weekNumber.toString()}`)).then((json)=>{
			const trackingData = json.data
			let planningToBeSaved = {
				publicationsplannings : trackingData.publicationsplannings,
				publicationsstats : []
			}
			const users = ContactsTools.getContactsByGroupid(160)
			let distribution = {
				pgm: trackingData.publicationsplannings.filter(x => x.publications !== null && (x.taskTypeId === 14 || x.taskTypeId === 16 || x.taskTypeId === 17 || x.taskTypeId === 27 || x.taskTypeId === 28)),
				ouv: trackingData.publicationsplannings.filter(x => x.publications !== null && (x.taskTypeId === 21 || x.taskTypeId === 22)),
				susp: trackingData.publicationsplannings.filter(x => x.publications !== null && (x.taskTypeId === 11 || x.taskTypeId === 12 || x.taskTypeId === 13 || x.taskTypeId === 24 || x.taskTypeId === 25)),
				chgtprix: trackingData.publicationsplannings.filter(x => x.publications !== null && (x.taskTypeId && PublicationTrackingReferenceData.getTasksTypes().find(r => r.itemid === x.taskTypeId).taskGroupDomainItemId  === 7 )),
				//regulprix: trackingData.publicationsplannings.filter(x => x.publications !== null && (x.taskTypeId >= 31 && x.taskTypeId <= 46)),
				autre: trackingData.publicationsplannings.filter(x => x.publications !== null && ((x.taskTypeId >= 1 && x.taskTypeId <= 3) || x.taskTypeId === null)),

			}

			if (trackingData.publicationsstats !== null && trackingData.publicationsstats.length !== 0) {
				trackingData.publicationsstats.forEach(item => {
					const currentUser = users.find(u => u.id === item.contactId)

					const stat = {
						contactName: currentUser ? currentUser.gsm : '',
						publicationsCount: item.publicationsCount,
						pgm: distribution.pgm !== undefined ? distribution.pgm.filter(x => x.publications.contactId === item.contactId).length : 0,
						ouv: distribution.ouv !== undefined ? distribution.ouv.filter(x => x.publications.contactId === item.contactId).length : 0,
						susp: distribution.susp !== undefined ? distribution.susp.filter(x => x.publications.contactId === item.contactId).length : 0,
						chgtprix: distribution.chgtprix !== undefined ? distribution.chgtprix.filter(x => x.publications.contactId === item.contactId).length : 0,
						//regulprix: distribution.regulprix !== undefined ? distribution.regulprix.filter(x => x.publications.contactId === item.contactId).length : 0,
						autre: distribution.autre !== undefined ? distribution.autre.filter(x => x.publications.contactId === item.contactId).length : 0,
						publicationPercent: Number((item.publicationsCount * 100 / trackingData.publicationsplannings.length).toFixed(1))
					}
					planningToBeSaved.publicationsstats.push(stat)
				})
			}
			onLoadPlanning(planningToBeSaved)

		})
	}
	 handleChange = (event) => {
		this.setState({  isupdate: !this.state.isupdate }) 
		this.initializeSelection()
	  };
	  handleOnClose =() =>{
		const { onClose} = this.props
		this.initializePlanningData()
		onClose()
	}
	render(){
		const emptyRows = this.state.rowsPerPage - Math.min(this.state.rowsPerPage, this.state.rows.length - this.state.page * this.state.rowsPerPage)
		const { classes, onClose, modalContent, translate } = this.props
		const modalTitle = translate('labels.assignPreparation')
		const progressModalContent = {
			title : 'Affectation en cours',
			closeButtonText : translate('ra.action.close'),
			resultText : this.state.resultText
		}
		const users = ContactsTools.getContactsByGroupid(160)
		return (
			<div>
				<Dialog
					open={this.state.open}
					TransitionComponent={Transition}
					keepMounted
					onClose={this.handleOnClose}
					disableEscapeKeyDown={true}
					disableBackdropClick={true}
					aria-labelledby="alert-dialog-slide-title"
					aria-describedby="alert-dialog-slide-description"
					classes = {{
						paperWidthSm : classes.paperWidthSm
					}}
				>
					<div className={classes.dialogHeader}>
						{modalTitle}
						<Button onClick={this.handleOnClose}  className={classes.closeIcon}  >
							<CloseIcon/>				 
						</Button>
					</div>
					<div className = {classes.dialogGlobal}>
						<div className={classes.dialogContent}>	
							<Paper className={classes.paper}>
								<UserSelector  weekCategories ={this.props.weekCategories} weektasktype={this.props.weektasktype} weektaskGroup={this.props.weektaskGroup} modalOpen ={this.props.modalOpen}/>
								
								<PublicationStats type='Assign'/>

								<EnhancedToolbar numSelected={this.state.selected.length} 
									selectedIds = {this.state.selected} manageProcessLoader = {this.manageProcessLoader} initializeSelection = {this.initializeSelection}
									initializePlanningData = {this.initializePlanningData} closeModal = {onClose} modalContent = {modalContent}
								/>
								<FormControlLabel 
								control={<Checkbox checked ={!this.state.isupdate}  onChange={this.handleChange} name="update" />}
								label="Afficher toutes les lignes"  className={classes.showAll}
								/>
								<Table
									className={classes.table}
									aria-labelledby="tableTitle"
									size={this.state.dense ? 'small' : 'medium'}
									aria-label="enhanced table"
								>
									<EnhancedTableHead
										classes={classes}
										numSelected={this.state.selected.length}
										order={this.state.order}
										orderBy={this.state.orderBy}
										onSelectAllClick={this.handleSelectAllClick}
										onRequestSort={this.handleRequestSort}
										rowCount={this.state.rows.length}
										type={'AssignPreparation'}
									/>
									<TableBody>
										{this.stableSort(this.state.rows, this.getComparator(this.state.order, this.state.orderBy))
											.slice(this.state.page * this.state.rowsPerPage, this.state.page * this.state.rowsPerPage + this.state.rowsPerPage)
											.map((row, index) => {
												const isItemSelected = this.isSelected(row.id)
												const labelId = `enhanced-table-checkbox-${index}`

												return (
													<TableRow
														hover
														onClick={(event) => this.handleClick(event, row.id)}
														role="checkbox"
														aria-checked={isItemSelected}
														tabIndex={-1}
														key={row.id}
														selected={isItemSelected}
														style={{backgroundColor: row.isUpdateApproved ? '#fff' : yellow[100] }}
													>
														<TableCell className={classes.tabCell} padding="checkbox">
															<Checkbox
																checked={isItemSelected}
																inputProps={{ 'aria-labelledby': labelId }}
															/>
														</TableCell>
														{/* <TableCell align="center" className={classes.tabCell} component="th" id={labelId} scope="row" >
															{row.id}
														</TableCell> */}
														<TableCell align="center"  width="150px" className={classes.tabCell}>{DateFormatter.getDayNameDate(row.planningDate)}</TableCell>
														<TableCell align="center" className={classes.tabCell} >
														 {
															 row.publications !== null ? ((users.find(x=>x.id ===  row.publications.contactId)!== undefined 
															 && users.find(x=>x.id ===  row.publications.contactId)!== null) ? (users.find(x=>x.id ===  row.publications.contactId).gsm) : '') : ''	 
														 }
														</TableCell> 
														<TableCell align="center" className={classes.tabCell} >{row.affair}</TableCell>
														<TableCell align="center" width="50px" className={classes.tabCell}>{row.pgm}</TableCell>
														<TableCell align="center" width="500px" className={classes.tabCell}>{row.movieTitle}</TableCell>
														<TableCell align="center" style ={{backgroundColor:PublicationTrackingReferenceData.getCellInformation(row.categoryId, 'category').color}} className={classes.tabCell}>
															{row.categoryId !== null ? PublicationTrackingReferenceData.getCellInformation(row.categoryId, 'category').label : <ReferenceDataCell referencedataid={row.categoryId} type={'category'} customWidth={80} seasonRow={false}></ReferenceDataCell>}
														</TableCell>
														<TableCell align="center" style ={{backgroundColor:PublicationTrackingReferenceData.getCellInformation(row.taskTypeId, 'taskType').color}} className={classes.tabCell}>
															{row.taskTypeId !== null ? PublicationTrackingReferenceData.getCellInformation(row.taskTypeId, 'taskType').label : <ReferenceDataCell referencedataid={row.taskTypeId} type={'taskType'} customWidth={80} seasonRow={false}></ReferenceDataCell>}
														</TableCell>
													</TableRow>
												)
											})}
										{emptyRows > 0 && (
											<TableRow style={{ height: (this.state.dense ? 33 : 53) * emptyRows }}>
												<TableCell colSpan={6} />
											</TableRow>
										)}
									</TableBody>
								</Table>
								<TablePagination
									rowsPerPageOptions={[10, 20, 50, 100]}
									component="div"
									count={this.state.rows.length}
									rowsPerPage={this.state.rowsPerPage}
									page={this.state.page}
									onPageChange={this.handleChangePage}
									onRowsPerPageChange={this.handleChangeRowsPerPage}
								/>
							</Paper>
						</div>
					</div>
					<AssignProcessLoader modalOpen = {this.state.isProcessLoaderOpen} 
						onClose={this.handleCloseProgressBar}  
						isOperationFinished = { this.state.isOperationFinished }
						progressModalContent = {progressModalContent}
					/>
				</Dialog>
			</div>
		)
	}
}

AssignPreparationModal.propTypes = {
	modalOpen: PropTypes.bool,
	onClose: PropTypes.func,
	modalContent: PropTypes.object,
	launchActivationProcess: PropTypes.func,
	crudGetList : PropTypes.func,
	yearNumber : PropTypes.number,
	weekNumber : PropTypes.number,
	classes: PropTypes.object.isRequired,

}

const mapStateToProps = state => ({
	...state,
	weekNumber: state.reducer.publicationTracking.weekNumber !==null ? state.reducer.publicationTracking.weekNumber : 16,
	yearNumber: state.reducer.publicationTracking.yearNumber !==null ? state.reducer.publicationTracking.yearNumber : 2020,
	planningData : state.reducer.publicationTracking.planningData !==null ? state.reducer.publicationTracking.planningData : [],
	selectedCategory : state.reducer.publicationTracking.selectedCategory !== null ? state.reducer.publicationTracking.selectedCategory : 0,
	selectedTaskType : state.reducer.publicationTracking.selectedTaskType !== null ? state.reducer.publicationTracking.selectedTaskType : 0,
	selectedTaskGroup : state.reducer.publicationTracking.selectedTaskGroup !== null ? state.reducer.publicationTracking.selectedTaskGroup : 0,
	selectedUser : state.reducer.publicationTracking.selectedUser,
	programTitle : state.reducer.publicationTracking.programTitle !== null ? state.reducer.publicationTracking.programTitle : "",
	isupdate: true,
})

const mapDispatchToProps = ({
	initialize,
	submit,
	showNotification,
	fetchEnd,
	fetchStart,
	onLoadPlanning,
	onChangeUser,
	onChangeCategory,
	onChangeTaskType,
	onChangeTaskGroup,
	onChangeSearch
})

const enhance = compose(
	connect(mapStateToProps, mapDispatchToProps),
	withStyles(styles)
)

export default translate(enhance(AssignPreparationModal))
