import Api from './Api'

const Authentification = {
	login: (username, password) =>
		Api.AuthRequests.post(`/login/user=${encodeURIComponent(username)}&password=${btoa(encodeURIComponent(password))}&isApi=true`),
	refreshToken: (refreshToken) =>
		Api.AuthRequests.post(`/refresh/token=${refreshToken}`),
	sendmailpasswordReset: (emailAdresse,url,delai) =>
		Api.AuthRequests.post(`/sendmailtoResetPwd?email=${emailAdresse}&delai=${delai}&urlex=${url}`),
	passwordReset: (password,token) =>
		Api.AuthRequests.post('/ResetPassword?password='+ btoa(encodeURIComponent(password)) +'&token='+encodeURIComponent(token)),
	checkUser : (emailAdresse) =>
		Api.AuthRequests.post(`/checkUser?email=${emailAdresse}`),
	SamlLogin: (returnUrl) =>
		Api.AuthRequests.get(`/login/Samllogin?returnUrl=${returnUrl} `),
	SamlLogout: (returnUrl) =>
		Api.AuthRequests.get(`/logout?returnUrl=${returnUrl}`),
	LoginRollback :(uid)=>
		Api.AuthRequests.post(`/login/Loginrollback?uid=${uid }`)
	}

export default Authentification