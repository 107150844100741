import React from 'react'
import {
	TextInput,
	SelectInput,
	NumberInput,
	SimpleShowLayout
} from 'react-admin'
import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import Typography from '@material-ui/core/Typography'
import { DomainsItems } from '../../../tools'
import { ExtendedInput, CheckboxInput, DatePickerCanal } from '../../common/inputs'
import { withStyles} from '@material-ui/core/styles'

const styles = theme => ({
	cardPaddingMargin: {
		padding: 20,
		margin: 20,
		flex: '1 auto'
	},
	cardDiv: {
		flex: '1 auto'
	},
	flexContent: {
		display: 'flex',
		alignItems: 'flex-start'
	},
	input: {
		display: 'inline-block'
	},
	inputLeft: {
		display: 'inline-block',
		marginLeft: 32
	},
	noMarginLeft: {
		marginLeft: 0
	}
})

const CardEdit = withStyles(styles)(({ classes, record } = this.props) => (
	<div className={classes.flexContent}>
		<Card className={classes.cardPaddingMargin}>
			<CardContent>
				<Typography variant="title">Droits du Transfert</Typography>
			</CardContent>
			<SimpleShowLayout>
				<TextInput label="fields.id" source="id" disabled/>
				<TextInput label="fields.label" source="label" />
				<SelectInput label="fields.countryid" source="countryid" choices={DomainsItems.getSelectList(2)} allowEmpty={false} />
				<TextInput label="fields.name" source="product.name" disabled/>
				<SelectInput label="fields.modecommid" source="modecommid" choices={DomainsItems.getSelectList(92)} allowEmpty={false} />
				<DatePickerCanal label="fields.startdate" source="startdate" />
				<DatePickerCanal label="fields.enddate" source="enddate" />
				<NumberInput label="fields.price" source="price" />
				<SelectInput label="fields.vatid" source="vatid" choices={DomainsItems.getSelectList(25)} allowEmpty={false} />
				<TextInput label="fields.sold" source="sold" disabled/>
				<CheckboxInput label="fields.published" source="published" />
				<CheckboxInput label="fields.exclusive" source="exclusive" />
			</SimpleShowLayout>
		</Card>
		<div className={classes.cardDiv}>
			<Card className={classes.cardPaddingMargin}>
				<CardContent>
					<Typography variant="title">Taux</Typography>
				</CardContent>
				<SimpleShowLayout>
					<NumberInput label="fields.commissionfee" source="commissionfee" />
					<NumberInput label="fields.sacemfee" source="sacemfee" />
					<NumberInput label="fields.sacdfee" source="sacdfee" />
					<NumberInput label="fields.tsafee" source="tsafee" />
				</SimpleShowLayout>
			</Card>
			<Card className={classes.cardPaddingMargin}>
				<CardContent>
					<Typography variant="title">Gestion</Typography>
				</CardContent>
				<SimpleShowLayout>
					<ExtendedInput label="fields.createdon" source="createdon" date disabled />
					<ExtendedInput label="fields.createdby" source="createdby" contact disabled />
					<ExtendedInput label="fields.modifiedon" source="modifiedon" date disabled />
					<ExtendedInput label="fields.modifiedby" source="modifiedby" contact disabled />
					<ExtendedInput label="fields.publishedon" source="publishedon" date disabled />
					<ExtendedInput label="fields.publishedby" source="publishedby" contact disabled />
					<ExtendedInput label="fields.desactivatedon" source="desactivatedon" date disabled />
					<ExtendedInput label="fields.desactivatedby" source="desactivatedby" contact disabled />
				</SimpleShowLayout>
			</Card>
		</div>
	</div>
))

export default CardEdit
