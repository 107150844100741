import Api from './Api'

const CompaniesAlbums = {
	getAll: (critere, value) =>
 		Api.Requests.getAll(`/Companiesalbums/Search?search=${value}&mode=${critere}`),
	getAlbum: (albumid) =>
		Api.Requests.getAll(`/CompaniesAlbumsSpecifique/GetAlbum?id=${albumid}`),
	getAllalbums: () =>
		Api.Requests.getAll(`/Companiesalbums/SimpleList`),
	getAlbumsMovies: (id) =>
		Api.Requests.getAll(`/CompaniesAlbumsSpecifique/AlbumsByMovieId?id=${id}`),
	addAlbumsMovies: (albumid,movieid,rank) =>
		Api.Requests.put(`/CompaniesAlbumsSpecifique/AddAlbumMovie?albumid=${albumid}&movieid=${movieid}&rank=${rank}`),
	updateAlbumsMovies: (albumMovie,albumid) =>
		Api.Requests.postWithBody(`/CompaniesAlbumsSpecifique/UpdateAlbumMovie?albumId`, albumMovie),
	removeAlbumsMovie: (albumid,movieid) =>
		Api.Requests.delete(`/CompaniesAlbumsSpecifique/DeleteAlbumMovie?albumid=${albumid}&movieid=${movieid}`),
	getAlbumsMovies: (id) =>
		Api.Requests.getAll(`/CompaniesAlbumsSpecifique/AlbumsByMovieId?id=${id}`),
	getAlbumsChildsCount: () =>
		Api.Requests.getAll('/Companiesalbums/CountChilds'),
	getAlbumsEditorials: (albumid,langId) =>
		Api.Requests.getAll(`/Albumseditorials/AlbumsEditorials?albumid=${albumid}&langid=${langId}`),
	getMovies: (albumid) =>
		Api.Requests.getAll(`/Albumsmovies?albumid=${albumid}`),
	getMoviesbyIds: (ids) =>
		Api.Requests.getAll(`/Companiesmovies/CompanieMoviesByIds?moviesIds=${ids}`),
	getAlbumMoviesbyIds: (id) =>
		Api.Requests.getAll(`/CompaniesAlbumsSpecifique/AlbumMoviesByAlbumId?id=${id}`),
	getAlbumMoviesdetails: (albumid,movieid) =>
		Api.Requests.getAll(`/CompaniesAlbumsSpecifique/GetAlbumMoviesDetails?albumid=${albumid}&movieid=${movieid}`),	
	SaveAlbum : (data) =>
		Api.Requests.postWithBody('/CompaniesAlbumsSpecifique/SaveAlbum', data),
	SaveEdito : (data) =>
		Api.Requests.postWithBody('/AlbumsEditorialsSpecifique/SaveAlbumEditorial', data),
	RemoveEdito : (albumid,langId) =>
		Api.Requests.delete(`/AlbumsEditorialsSpecifique/DeleteAlbumEdito?albumid=${albumid}&langid=${langId}`),

	}

export default CompaniesAlbums