import React from 'react'
import { SimpleForm, List, Create, } from 'react-admin'
import PicturesList from './PicturesList'
import PicturesCreate from './PicturesCreate'

const PeoplespicturesList = (props) => (
	<List title="titles.peoplesPicturesList" actions={null} {...props} pagination={null}>
		<PicturesList />
	</List>
)

const PeoplespicturesCreate = (props) => (
	<Create title="titles.peoplespicturesCard" undoable={false} {...props}>
		<SimpleForm>
			<PicturesCreate />
		</SimpleForm>
	</Create>
)

export {
	PeoplespicturesList,
	PeoplespicturesCreate
}