import React from 'react'
import { CustomTextField ,CustomSelect} from '../../layout/GlobalStyles'
import IconButton from '@material-ui/core/IconButton'
import { SearchIcon} from '../../../constants/icons'
import Paper from '@material-ui/core/Paper'
import Grid from '@material-ui/core/Grid'
import { ThemeProvider, withStyles } from '@material-ui/core/styles'

// const mode = [
// 	{ id: 1, name: 'Identifiant du programme' },
// 	{ id: 2, name: 'Titre du programme' },
// 	{ id: 4, name: 'Nom de la personnalité' },
// 	{ id: 8, name: 'Identifiant de la personnalité' },
// 	{ id: 16, name: 'Identifiant du contenu' }
// ]

const styles = (theme) =>  ({
	button: {
		fontSize: '.8em',
		backgroundColor : '#288DB1',
		'&:hover': {
			background: '#1c627b'
		},
		marginTop: '2.8em',
		padding: '.5 1 .5 1',
		width: '18%',
		height: '3em',
		position: 'absolute',
		right: '3em',
		lineHeight: '1.2em'
	},
	root:{},
	formControl:{},
	searchButton:{
		root:{
			'&:hover': {
		    width: '45px',
   			 height: '50px'
		},
		}
		
	}
})

function FilterField(props) {
 
	const handleKeyPress = (event) => {
		if(event.key === 'Enter'){
			props.ButtonClick()
		}
	}
	return (
		<ThemeProvider>
			<Paper elevation={3} style={{height:'100px', ...props.paperStyle}}>
				<Grid container direction="row">
				<div style={{width:"60%"}}>
					<div style={{marginTop: "1em", display: "flex", alignItems:'center'}}>
						<CustomSelect 
							values={props.values} value={props.selectedValue} label="Critère" create="true" 
							handleChange={(e)=>props.selectedChange(e)} classes={props.classes}
						/>
						<CustomTextField label="Valeur"  value={props.textValue}  handleChange={props.valueChange}  valueReset={props.valueReset}
						create="true" handleKeyPress={(e)=>handleKeyPress(e)} removeicon={props.removeicon} errorLabel={props.errorLabel} fullWidth="true" style={{...props.textfieldStyle}} />
					<IconButton  onClick={props.ButtonClick} className={props.classes.searchButton} >
						<SearchIcon />
					</IconButton>
					{ props.displayRadio }
					</div>
					{ props.displayData }
				</div>
				
				{ props.switch }
				</Grid>
			</Paper>
		</ThemeProvider>
)
}

export default withStyles(styles)(FilterField)