import { translate } from 'react-admin'
import React, { Component } from 'react'
import {  updatePrioPlanningIds } from '../../../actions/publicationTracking'
import { connect } from 'react-redux'
import compose from 'recompose/compose'
import IconButton from '@material-ui/core/IconButton'
import StarIcon from '@material-ui/icons/Star'
import { withStyles } from '@material-ui/core/styles'

const styles = theme =>  ({
	customSelect: {
		width: '100%',
		textAlign: 'left'
	},
	validateButton: {
		width: '10%',
		color: 'green'
	},
	iconButton: {
		color: 'white',
		justifyContent: 'center',
    	alignContent: 'center' 
		//marginLeft: '25px'
	},
	iconPrioButton: {
		color: '#0fd42e',
		//marginLeft: '25px'
		justifyContent: 'center',
    	alignContent: 'center' 
	},
	iconPrioTardifButton: {
		color: 'blue',
		//marginLeft: '25px'
		justifyContent: 'center',
    	alignContent: 'center' 
	},
	iconTopPrioButton: {
		color: 'red',
		//marginLeft: '25px'
		justifyContent: 'center',
    	alignContent: 'center' 
	}
})
class ReferentContent extends Component {
	constructor(props) {
		super(props)
		this.state = {
			initialPrioValue: 0,
			currentPrioValue: 0,
			listMovieToPrio : []
		}
	}
	Rerender = () => {
        this.forceUpdate()
     }
	componentDidMount(prevProps, prevState) {
		const { PRIO } = this.props
		//const userId = user !== undefined ? user.id : 0
		this.setState({
			initialPrioValue: PRIO,
			currentPrioValue: PRIO
		})
	}

	getSnapshotBeforeUpdate(prevProps, prevState) {
		if (prevProps.movieId !== this.props.movieId) {
			this.setState({
				initialPrioValue: this.props.PRIO !== undefined ? this.props.PRIO : 0,
				currentPrioValue: this.props.PRIO !== undefined ? this.props.PRIO : 0,
			})
		}

		return null
	}

	componentDidUpdate(previousProps , prevState) {
		const { planningPrios, isUpdatePrioCompleted } = this.props
		if (planningPrios !== previousProps.planningPrios) {
			if (planningPrios.length === 0) {
				if (isUpdatePrioCompleted) {
					this.setState({
						initialPrioValue: this.state.currentPrioValue
					})
				}
				else {
					if (this.state.initialPrioValue !== this.state.currentPrioValue) {
						this.setState({
							currentPrioValue: this.state.initialPrioValue
						})
					}
				}
			}
		}
	}

	handleChange = () => {
		const { movieId, isUpdatePrioLocked ,updatePrioPlanningIds} = this.props
		if (!isUpdatePrioLocked) {
			let prio = 0
			switch (this.state.currentPrioValue) {
				case 0:
					prio = 1
					break;
				case 1:
					prio = 2
					break;
				case 2:
					prio = 3
					break;
				case 3:
					prio = 0
					break;
				default:
					prio = 0
					break;
			}
			this.setState({	currentPrioValue: prio	})
			updatePrioPlanningIds(prio, movieId)

		}

	}

	render() {
		const { classes, isUpdatePrioLocked } = this.props
		// const LockedbuttonTitle = this.state.currentPrioValue === 1 ? "Contenu référent" : this.state.currentPrioValue === 2 ? "Contenu à arrivée tardive" : "Contenu référent TOP prio."
		return (
			<React.Fragment>
				{
					isUpdatePrioLocked !== undefined && isUpdatePrioLocked === true ?
						<div>
							{this.state.currentPrioValue === 0 ?
								<div></div>
								:
									<IconButton onClick={this.handleChange} className={this.state.currentPrioValue === 1 ? classes.iconPrioButton : 
														(this.state.currentPrioValue === 2 ? classes.iconPrioTardifButton : ( this.state.currentPrioValue ===3 ? classes.iconTopPrioButton : classes.iconButton))}
										variant="contained" aria-label="validate update" component="span">
										<StarIcon />
									</IconButton>
							}
						</div>
						:	
							<IconButton onClick={this.handleChange} className={this.state.currentPrioValue === 1 ? classes.iconPrioButton : 
								(this.state.currentPrioValue === 2 ? classes.iconPrioTardifButton : (this.state.currentPrioValue === 3 ? classes.iconTopPrioButton: classes.iconButton))}
								variant="contained" aria-label="validate update" component="span">
								<StarIcon />
							</IconButton>
 				}

			</React.Fragment>
		)

	}
}

const mapStateToProps = state => ({
	...state,
	weekNumber: state.reducer.publicationTracking.weekNumber !== null ? state.reducer.publicationTracking.weekNumber : 16,
	yearNumber: state.reducer.publicationTracking.yearNumber !== null ? state.reducer.publicationTracking.yearNumber : 2020,
	isUpdatePrioLocked: state.reducer.publicationTracking.isUpdatePrioLocked,
	planningPrios: state.reducer.publicationTracking.planningPrios,
	isUpdatePrioCompleted: state.reducer.publicationTracking.isUpdatePrioCompleted
})

const mapDispatchToProps =({
	updatePrioPlanningIds
})
// const mapDispatchToProps = dispatch => {
// 		updatePrioPlanningIds };
const enhance = compose(
	connect(mapStateToProps, mapDispatchToProps),
	withStyles(styles)
)

export default translate(enhance(ReferentContent))
