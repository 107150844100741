/* eslint-disable indent */
import React, { Component } from 'react'
import FormGroup from '@material-ui/core/FormGroup'
import CustomCheckBoxItem from './CustomCheckBoxItem'
// import './CheckboxGroup.scss'

class CustomCheckboxItemGroup extends Component {
	constructor(props) {
		super(props)
		this.state = {
			parentCheckboxChecked: false,
			parentFrCheckboxChecked: false
		}
	}

	componentWillReceiveProps(nextProps) {
		this.updateParentWithChildren(nextProps)
	}

	UNSAFE_componentWillMount() {
		this.updateParentWithChildren(this.props)
	}

	handleParentCheckboxChange = (isChecked) => {
		const { otherCheckboxes, frCheckboxes, onCheckboxGroupChange } = this.props
		const newFrCheckState = frCheckboxes.map((aCheckbox) => ({
			...aCheckbox,
			checked: isChecked,
		}))
		const newOtherCheckState = otherCheckboxes.map((aCheckbox) => ({
			...aCheckbox,
			checked: isChecked,
		}))
		const newCheckState = [...newFrCheckState, ...newOtherCheckState]
		onCheckboxGroupChange(newCheckState)
	}

	handleFrParentCheckboxChange = (isChecked) => {
		const { otherCheckboxes, frCheckboxes, onCheckboxGroupChange } = this.props
		const newFrCheckState = frCheckboxes.map((aCheckbox) => ({
			...aCheckbox,
			checked: isChecked,
		}))
		const newCheckState = [...newFrCheckState, ...otherCheckboxes]
		onCheckboxGroupChange(newCheckState)
	}

	updateParentWithChildren = (props) => {
		const { otherCheckboxes, frCheckboxes } = props
		let allChecked = false
		//let allFrChecked = false
		for (let i = 0; i < frCheckboxes.length; i += 1) {
			if (frCheckboxes[i].checked) {
				allChecked = true
				//allFrChecked = true
			} else {
				allChecked = false
				//allFrChecked = false
				break
			}
		}
		if (allChecked === true) {
			for (let i = 0; i < otherCheckboxes.length; i += 1) {
				if (otherCheckboxes[i].checked) {
					allChecked = true
				} else {
					allChecked = false
					break
				}
			}
		}
		this.setState({
			parentCheckboxChecked: allChecked,
			//parentFrCheckboxChecked: allFrChecked
		})
	}

	handleChildCheckboxChange = (isChecked, index) => {
		const { otherCheckboxes, frCheckboxes } = this.props
		const { onCheckboxGroupChange } = this.props
		const newFrCheckState = frCheckboxes.map(
			(aCheckbox, i) => (index === i ? { ...aCheckbox, checked: isChecked } : aCheckbox)
		)
		let newOtherCheckState = []
		if (index >= frCheckboxes.length) {
			const otherIndex = index - frCheckboxes.length
			newOtherCheckState = otherCheckboxes.map(
				(aCheckbox, i) => (otherIndex === i ? { ...aCheckbox, checked: isChecked } : aCheckbox)
			)
		}
		else {
			newOtherCheckState = otherCheckboxes
		}
		const newCheckState = [...newFrCheckState, ...newOtherCheckState]
		onCheckboxGroupChange(newCheckState)
	}

	renderFrCheckboxes = () => {
		const { frCheckboxes } = this.props
		if (!frCheckboxes) {
			return null
		}

		return frCheckboxes.map((aCheckbox, index) => (
			<CustomCheckBoxItem
				key={index}
				checkboxLabel={aCheckbox.label}
				checkboxValue={aCheckbox.value}
				checked={aCheckbox.checked}
				checkboxChangeCallback={(checkStatus) => this.handleChildCheckboxChange(checkStatus, index)}
			/>
		))
	}

	renderOtherCheckboxes = () => {
		const { frCheckboxes, otherCheckboxes } = this.props
		if (!otherCheckboxes) {
			return null
		}

		return otherCheckboxes.map((aCheckbox, index) => (
			<CustomCheckBoxItem
				key={index + frCheckboxes.length}
				checkboxLabel={aCheckbox.label}
				checkboxValue={aCheckbox.value}
				checked={aCheckbox.checked}
				checkboxChangeCallback={(checkStatus) => this.handleChildCheckboxChange(checkStatus, index + frCheckboxes.length)}
			/>
		))
	}

	render() {
		const { parentCheckboxChecked } = this.state
		return (
			<div className="checkbox-wrapper">
				<FormGroup>
					<div className="checkbox-head">
						{/* <CustomCheckBoxItem
							checkboxLabel="Toutes les cibles France"
							checkboxValue="all-fr"
							checked={parentFrCheckboxChecked}
							checkboxChangeCallback={this.handleFrParentCheckboxChange}
						/> */}
						<CustomCheckBoxItem
							checkboxLabel="Toutes les cibles"
							checkboxValue="all"
							checked={parentCheckboxChecked}
							checkboxChangeCallback={this.handleParentCheckboxChange}
						/>
					</div>
					<br />
					<div className="checkbox-children">{this.renderFrCheckboxes()}</div>
					<br />
					<div className="checkbox-children">{this.renderOtherCheckboxes()}</div>
				</FormGroup>
			</div>
		)
	}
}
// CustomCheckboxItemGroup.propTypes = {
// 	otherCheckboxes: PropTypes.array,
// 	frCheckboxes: PropTypes.array,
// 	onCheckboxGroupChange: PropTypes.func,
// }
export default CustomCheckboxItemGroup