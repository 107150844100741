import React from 'react'
import { Link } from 'react-router-dom'

const Logo = props => {
	const { src, classes } = props
	return (
		<Link to = "/">
			<img src={src} alt="BO Hawc" className={classes.logo} />
		</Link>
	)
}
export default Logo