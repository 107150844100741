import React from 'react'
import { SimpleShowLayout, SimpleForm, Show, Edit, Create } from 'react-admin'
import { MyShowActions, MyEditActions, MyCreateActions } from '../../common/actions'
import { EditorialsShow, EditorialsEdit, EditorialsCreate } from '.'

const MovieseditorialsShow = props => (
	<Show title="titles.moviesEditorialsCard" actions={<MyShowActions />} {...props}>
		<SimpleShowLayout>
			<EditorialsShow />
		</SimpleShowLayout>
	</Show>
)

const MovieseditorialsEdit = props => (
	<Edit title="titles.moviesEditorialsCard" undoable={false} actions={<MyEditActions />} {...props}>
		<SimpleForm>
			<EditorialsEdit />
		</SimpleForm>
	</Edit>
)

const MovieseditorialsCreate = props => (
	<Create title="titles.moviesEditorialsCard" undoable={false} actions={<MyCreateActions />} {...props}>
		<SimpleForm>
			<EditorialsCreate />
		</SimpleForm>
	</Create>
)

export { MovieseditorialsShow, MovieseditorialsEdit, MovieseditorialsCreate }