import React from 'react'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import Slide from '@material-ui/core/Slide'
import PropTypes from 'prop-types'
import { CancelIcon, CheckIcon, CloseIcon } from '../../../constants/icons'
import cyan from '@material-ui/core/colors/cyan'
import { DatePickerCanal } from '../../common/inputs'
import { SimpleForm, translate, showNotification } from 'react-admin'
import { REDUX_FORM_PROCESS_PLANNING_FILTER } from '../../../constants/reduxFormName'
import { submit, initialize  } from 'redux-form'
import { connect } from 'react-redux'
import compose from 'recompose/compose'
import InputLabel from '@material-ui/core/InputLabel'
import MenuItem from '@material-ui/core/MenuItem'
import FormControl from '@material-ui/core/FormControl'
import Select from '@material-ui/core/Select'
import "date-fns"
import { createTheme, withStyles , } from '@material-ui/core/styles'


const Transition = React.forwardRef(function Transition(props, ref) {
	return <Slide direction="up" ref={ref} {...props} />
})

const styles = theme =>  ({
	button: {
		margin: theme.spacing(1),
		width : '150px'
	},
	buttonCancel: {
		width : '200px',
		background: '#e23434',
		margin: theme.spacing(1),
	},
	buttonCheck: {
		margin: theme.spacing(1),
		width : '220px',
		background: '#0c9e0c'
	},
	leftIcon: {
		marginRight: theme.spacing(1),
	},
	dialogGlobal : {
		background: theme.palette.background.default,//'#353333'
	},
	dialogHeader:{
		padding: '10px 10px 10px',
		background: cyan[500],
		color: 'white',
		fontWeight: 'bold',
		textAlign: 'center',
		//height: '36px',
		fontSize: '0.9rem'
	},
	closeIcon:{
		width : '15px',
		position: 'absolute',
    	top: '0px',
    	right: '0px',
		borderRadius: '0',
		color: 'white',
		'&:hover': {
			background: 'red'
		}	 
	},
	dialogContent: {
		padding: '16px 20px 17px',
		flex: '1 1 auto',
		color: 'white',
		fontSize: '1rem',
		fontWeight: '400',
		width : '400px'
	},
	dialogFooter: {
		flex: '0 0 auto',
		// margin: '8px 4px',
		marginLeft : '30px',
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'flex-end',
		width : '400px'
	},
	formControl: {
		// margin: theme.spacing(1),
		minWidth: '400px',
		marginTop : '8px',
		width : '300px'
	},
	selectEmpty: {
		marginTop: theme.spacing(2),
	},
	customSelect: {
		width : '80%',
	},
	datePickerInput : {
		width : '100%'
	}
})
class PlanningProcessParameterModal extends React.Component {
	constructor(props) {
		super(props)
		this.state = {
			open : false,
			enabled : true,
			interval : 31,
			isEndDateEnabled : false,
			isIntervalEnabled : true
		}
	}

	componentDidMount(){
		const { modalOpen } = this.props
		this.setState({ open : modalOpen })  
	}

	componentDidUpdate(previousProps, previousState) {
		if (previousProps.modalOpen !== this.props.modalOpen) {
			const { modalOpen } = this.props
			this.setState({ open : modalOpen }) 
		}
	}
	enableAcceptButton = (value)=>{
		this.setState({
			enabled : value
		})
	}

	handleSaveClick = () => {
		const { submit } = this.props
		submit(REDUX_FORM_PROCESS_PLANNING_FILTER)
	}

	isEmpty = (obj) => {
		for(var key in obj) {
			if(obj.hasOwnProperty(key))
				return false;
		}
		return true;
	}

	handleSubmit = (values) => {
		const { launchActivationProcess, showNotification } = this.props
		if(!this.isEmpty(values)){
			if(new Date(values.enddate) < new Date(values.startdate)){
				showNotification('La date de fin doit être après la date de début', 'warning', { autoHideDuration : '3000' })
				return
			}
			if(values.enddate === undefined){
				showNotification('La date de fin doit être remplie', 'warning', { autoHideDuration : '3000' })
				return
			}
		}
		const processParameter = {
			dateinterval : this.isEmpty(values) ? this.state.interval : null,
			startdate : !this.isEmpty(values) ? values.startdate : null,
			enddate : !this.isEmpty(values) ? values.enddate : null,
		}
		launchActivationProcess(processParameter)
		this.setState({ open: false })
	}

	getMuiTheme = () => createTheme({
		overrides: {
			FormInput:{
				input:{
					//width : '300px'
				}
			}
		}
	})

	handleDateChange = (updatedDate) => {
		const updatedDateObject = Object.values(updatedDate)
		if(updatedDateObject !== null){
			this.setState(
				{
					isEndDateEnabled : true,
					isIntervalEnabled : false
				}
			)
		}
	}

	handleChange = (event) => {
		this.setState({ interval : event.target.value })
	}

	render(){
		const { onClose,  modalContent, classes, translate} = this.props
		const modalTitle = modalContent.title
		const disagreeButtonText = modalContent.disagreeButtonText
		const agreeButtonText = modalContent.agreeButtonText
		const labelStartDate = translate('fields.startdate')
		const labelEndDate = translate('fields.enddate')
		return (
			<div>
				<Dialog
					open={this.state.open}
					TransitionComponent={Transition}
					keepMounted
					onClose={onClose}
					disableEscapeKeyDown={true}
					disableBackdropClick={true}
					aria-labelledby="alert-dialog-slide-title"
					aria-describedby="alert-dialog-slide-description"
					style = {{ zIndex : 1200 }}
				>
					<div className={classes.dialogHeader}>
						{modalTitle}
						<Button onClick={onClose}  className={classes.closeIcon} >
							<CloseIcon/>				 
						</Button>
					</div>
					<div className = {classes.dialogGlobal}>
						<SimpleForm form={REDUX_FORM_PROCESS_PLANNING_FILTER} onSubmit={this.handleSubmit} toolbar={null}>
							<div className={classes.dialogContent}>		
								{/* {modalDescription} */}
								<DatePickerCanal onChange={this.handleDateChange} source="startdate" label = {labelStartDate} dateOnly shrink={true} className = {classes.datePickerInput}/>
								<DatePickerCanal source="enddate" label = {labelEndDate} dateOnly shrink={true} disabled = {!this.state.isEndDateEnabled} className = {classes.datePickerInput}/>
								<FormControl className={classes.formControl} variant="outlined">
									<InputLabel >Intervalle à partir de la date du jour</InputLabel>
									<Select
										// label="Intervalle à partir de la date du jour"
										label="Intervalle à partir de la date du jour"
										inputProps={{
											name: 'intervalle',
											id: 'outlined-age-native-simple',
										}}
										value={this.state.interval}
										onChange={this.handleChange}
										// className = {classes.customSelect}
										disabled = {!this.state.isIntervalEnabled}
									>
										<MenuItem value={31}>31</MenuItem>
										<MenuItem value={56}>56</MenuItem>
									</Select>
								</FormControl>
							</div>
							<div className={classes.dialogFooter}>
								<Button onClick={this.handleSaveClick} color="primary" variant="contained" className={classes.buttonCheck} disabled = {!this.state.open}>
									<CheckIcon className={classes.leftIcon} />
									{agreeButtonText}
								</Button>
								<Button onClick={onClose} color="secondary" variant="contained" className={classes.buttonCancel}>
									<CancelIcon className={classes.leftIcon} />
									{disagreeButtonText}
								</Button>
							</div>
						</SimpleForm>
					</div>
				</Dialog>
			</div>
		)
	}
}

PlanningProcessParameterModal.propTypes = {
	modalOpen: PropTypes.bool,
	onClose: PropTypes.func,
	modalContent: PropTypes.object,
	launchActivationProcess: PropTypes.func
}

const mapStateToProps = state => ({
	...state,
	formValues: state.form[REDUX_FORM_PROCESS_PLANNING_FILTER] ? state.form[REDUX_FORM_PROCESS_PLANNING_FILTER].values : {},
	weekNumber: state.reducer.publicationTracking.weekNumber !==null ? state.reducer.publicationTracking.weekNumber : 202016
})

const mapDispatchToProps = ({
	initialize,
	submit,
	showNotification
})

const enhance = compose(
	connect(mapStateToProps, mapDispatchToProps),
	withStyles(styles)
)

export default translate(enhance(PlanningProcessParameterModal))
