import { required, minValue, maxValue, number } from 'react-admin'
import dataProvider from '../../dataProvider'
import {  Logger } from '../../tools'
import { AUTH_LOGOUT } from 'react-admin'
import authProvider from '../../authProvider'

export const validateTargets = [required()]

export const validateContentId = [number()]

export const validatePrice =(priceLimit) => [number(), minValue(0), maxValue(parseInt(priceLimit))]

export const validatePriceCheckboxes = (values, errorMessage) => {
 	if(values){
		//if one of the publication date is checked then we should return true
		if(values.checkVodDate || values.checkEstDate || values.checkEstBkDate){
			return {
				success : true
			}
		}

		if(!values.checkVodDate && !values.checkEstDate && !values.checkEstBkDate && !values.checkPriceVODSD 
			&& !values.checkPriceVODHD 
			&& !values.checkPriceVOD4K 
			&& !values.checkPriceESTSD 
			&& !values.checkPriceESTHD
			&& !values.checkPriceEST4K
			// && !values.checkPriceHDDVD
			// && !values.checkPriceHDBR
			// && !values.checkPrice4KDVD
			// && !values.checkPrice4KBR
			){
			return {
				success : false,
				message : errorMessage
			}
		}
		return {
			success : true
		}
	}
	return {
		success : false,
		message : errorMessage
	}
}

function isNotChecked(currentValue){
	return !currentValue.checked
}

export const validatePublicationTargets = (values, errorMessage) => {
	if(values && values.targets){
		if(values.targets.every(isNotChecked)){
			return {
				success : false,
				message : errorMessage
			}
		}
		return  {
			success : true
		}
	}
	return  {
		success : false,
		message : errorMessage
	}
}

function checkPublicationPeriodInterval(errorMessages, errorMessageObject, datapickerCheckbox, startDate, endDate, modeComm){
	if(datapickerCheckbox){
		if(!startDate){
			errorMessages.push(errorMessageObject.emptyPublicationDate.replace('#modeComm', modeComm))
		}
		else{
			if(endDate && new Date(endDate) <= new Date(startDate)){
				errorMessages.push(errorMessageObject.publicationIntervalError.replace('#modeComm', modeComm))
			}
		}
	}
}

export const validatePublicationDate = (values, errorMessage) => {
	var errorMessages = []
	checkPublicationPeriodInterval(errorMessages, errorMessage, values.checkVodDate, values.vodStartDate, values.vodEndDate, 'VOD')
	checkPublicationPeriodInterval(errorMessages, errorMessage, values.checkEstDate, values.estStartDate, values.estEndDate, 'EST')
	// checkPublicationPeriodInterval(errorMessages, errorMessage, values.checkEstBkDate, values.estBkStartDate, values.estBkEndDate, 'EST B&K')
	if(errorMessages.length !== 0){
		return {
			success :false,
			message : errorMessages.join(' - ')
		}
	}
	return {
		success : true
	}
}

function priceBelongToInterval(currentValue, minValue, maxValue){
	console.log(currentValue)
	return currentValue >= minValue && currentValue <= maxValue
}


/**
 * 
 * TODO: A refactoring is necessary in order to have clean code
 * 
 */
export const validateAppleTragetsPrice = (values, errorMessage) => {
	return new Promise((resolve, reject) => {
		dataProvider('GET_ONE', 'Pricingscodes/PriceInterval', { typeId: 1 }).then(response => {
			if(values && values.targets){
				const priceValues = [ values.price4KBR, values.price4KDVD, values.priceEST4K, values.priceESTHD, values.priceESTSD, values.priceHDBR, values.priceHDDVD, 
					values.priceVOD4K, values.priceVODHD, values.priceVODSD].filter(price => price !== null && price !== '')
				const interval = {
					minValue : response.data.minPrice,
					maxValue : response.data.maxPrice
				}
				const appleIosTargets = values.targets.filter(target => target.label === 'ios' || target.label === 'appel' || target.label === 'apple')
				if(appleIosTargets.some(target => target.checked)){
					if(!priceValues.every((price) => priceBelongToInterval(price, interval.minValue, interval.maxValue))){
						const result = {
							success : false,
							message : `${errorMessage} ${interval.minValue} et ${interval.maxValue}`
						}
						resolve(result)
					}
					else{
						const result = {
							success : true
						}
						resolve(result)
					}
				}
				else
				{
					const result = {
						success : true
					}
					resolve(result)
				}
			}
			else {
				const result = {
					success : true
				}
				resolve(result)
			}
			
		})
			.catch((error) => {
				Logger.debug('Error calling pricing codes '+error.message)
				if(error.status === 401 || error.status === 403){
					authProvider(AUTH_LOGOUT, error)
				}
			})
	})
}

export const validatePriceCheckboxesValues = (values, errorMessage) => {
	let errorFields = []
	if(values){
		if(values.checkPriceVODSD){
			if(values.priceVODSD === null || values.priceVODSD === ''){
				errorFields.push('VOD SD')
			}
		}
		if(values.checkPriceVODHD){
			if(values.priceVODHD === null || values.priceVODHD === ''){
				errorFields.push('VOD HD')
			}
		}
		if(values.checkPriceVOD4K){
			if(values.priceVOD4K === null || values.priceVOD4K === ''){
				errorFields.push('VOD 4K')
			}
		}
		if(values.checkPriceESTSD){
			if(values.priceESTSD === null || values.priceESTSD === ''){
				errorFields.push('EST SD')
			}
		}
		if(values.checkPriceESTHD){
			if(values.priceESTHD === null || values.priceESTHD === ''){
				errorFields.push('EST HD')
			}
		}
		if(values.checkPriceEST4K){
			if(values.priceEST4K === null || values.priceEST4K === ''){
				errorFields.push('EST 4K')
			}
		}
		// if(values.checkPriceHDDVD){
		// 	if(values.priceHDDVD === null || values.priceHDDVD === ''){
		// 		errorFields.push('B&K HD DVD')
		// 	}
		// }
		// if(values.checkPriceHDBR){
		// 	if(values.priceHDBR === null || values.priceHDBR === ''){
		// 		errorFields.push('B&K HD BR')
		// 	}
		// }
		// if(values.checkPrice4KDVD){
		// 	if(values.price4KDVD === null || values.price4KDVD === ''){
		// 		errorFields.push('B&K 4K DVD')
		// 	}
		// }
		// if(values.checkPrice4KBR){
		// 	if(values.price4KBR === null || values.price4KBR === ''){
		// 		errorFields.push('B&K 4K BR')
		// 	}
		// }
	}
	if(errorFields.length !== 0){
		return {
			success : false,
			message : `${errorMessage} ${errorFields.join(' - ')}`
		}
	}
	return  {
		success : true
	}
}

export const validateNumberOfUpdateProcess = (transfersRightsTemp, errorMessage) => {
	console.log(transfersRightsTemp)
	if(transfersRightsTemp && transfersRightsTemp.length === 0){
		return {
			success : false,
			message : `${errorMessage}`
		}
	}
	return {
		success : true
	}
}
export const validateCheckAllPriceValues = (values, checkAllESTPrice,checkAllVODPrice,errorMessage) => {
	if(values){
			values.checkPriceESTSD =checkAllESTPrice
			values.checkPriceESTHD=checkAllESTPrice
			values.checkPriceEST4K=checkAllESTPrice
			values.checkPriceVODSD =checkAllVODPrice
			values.checkPriceVODHD =checkAllVODPrice
			values.checkPriceVOD4K =checkAllVODPrice
		
		return {
			success : true
		}
		 
		
	}
	return {
		success : false,
		message : errorMessage
	}
}