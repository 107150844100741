import React, { Component } from 'react'
import TableCell from '@material-ui/core/TableCell'
import TableRow from '@material-ui/core/TableRow'
import amber from '@material-ui/core/colors/amber'
import green from '@material-ui/core/colors/green'
import { DateFormatter, PricePlanning } from '../../tools'
import { translate } from 'react-admin'
import compose from 'recompose/compose'
import { connect } from 'react-redux'
import { onExpandUpdateForm } from '../../actions/pricePlanning'
import { change  } from 'redux-form'
import { defaultDate } from '../../constants/defaultValues'
import { withStyles  } from '@material-ui/core/styles'

const REDUX_FORM_PRICEPLANIFICATION_UPDATE = 'form-priceplanification-update'

const StyledTableCell = withStyles(theme => ({
	
	head: {
		backgroundColor: amber[50],
		color: theme.palette.primary.dark,
		fontSize: 14,
		paddingTop: 2,
		paddingRight: 4,
		paddingBottom: 2,
		paddingLeft: 4,
		border: '2px solid',
		borderColor: 'grey',
		textAlign: 'left',
		fontWeight: 'bolder'
	},
	body: {
	  fontSize: 13,
	  fontWeight: 'bolder',
	  padding: '2px 8px'
	},
	
}))(TableCell);
  
const StyledTableRow = withStyles(theme => ({
	root: {
		'&:nth-of-type(odd)': {
			backgroundColor: theme.palette.background.default,
		},
	},
	tableTitle: {
		backgroundColor: '#fafafa',// grey[200],
		color: theme.palette.common.black,
		fontWeight: 'bold'
	},
}))(TableRow);

const StyledPreviewModifiedTableRow = withStyles(theme => ({
	root: {
		backgroundColor: green[700]
	},
	tableTitle: {
		backgroundColor: '#fafafa',// grey[200],
		color: theme.palette.common.black,
		fontWeight: 'bold'
	},
}))(TableRow);

const CustomTableHeader = props => {
	const { isFirstLine, classes, translate } = props
	if(isFirstLine){
		return (
			<TableRow className={classes.tableTitle}>
				<StyledTableCell align="left">{translate('fields.periodTableId')}</StyledTableCell>
				<StyledTableCell align="left">{translate('fields.periodTableModeComm')}</StyledTableCell>
				<StyledTableCell align="left">{translate('fields.periodTableTarget')}</StyledTableCell>
				<StyledTableCell align="left">{translate('fields.periodTableInterval')}</StyledTableCell>
				<StyledTableCell align="left">{translate('fields.periodTableSD')}</StyledTableCell>
				<StyledTableCell align="left">{translate('fields.periodTableHD')}</StyledTableCell>
				<StyledTableCell align="left">{translate('fields.periodTable4K')}</StyledTableCell>
				{/* <StyledTableCell align="right">{translate('fields.periodTableHDDVD')}</StyledTableCell>
				<StyledTableCell align="right">{translate('fields.periodTable4KDVD')}</StyledTableCell>
				<StyledTableCell align="right">{translate('fields.periodTableHDBR')}</StyledTableCell>
				<StyledTableCell align="right">{translate('fields.periodTable4KBR')}</StyledTableCell> */}
			</TableRow>
		)
	}
	else{
		return <div></div>
	}
}

const styles = theme => ({
	root: {
		width: '100%',
		marginTop: theme.spacing(1),
		// overflowX: 'auto',
	},
	table: {
		minWidth: 700,
	},
	cardHeader:{
		background: amber[50],
		textAlign: 'left',
		height: '16px'
	},
	cardHeaderTitle:{
		backgroundColor: amber[50],
		color: theme.palette.common.black,
		fontWeight: 'bold',
		fontSize: 'smaller'
	},
	tableTitle: {
		backgroundColor: '#fafafa',// grey[900],
		color: 'white',
		fontWeight: 'bold'
	},
})

class PricePlanificationPeriodTableItem extends Component{
	isChecked = (periodItem, modeComm, price) => {
		return false
		// return periodItem.modeCommercialization === modeComm &&  price !== 0
	}

	getPrice = (periodItem, modeComm, price) => {
		return periodItem.modeCommercialization === modeComm &&  price !== -1 ?  price : ''
	}

	isEstBkDateChecked(periodItem){
		return periodItem.modeCommercialization === 'EST' 
		&& periodItem.priceHDDvd !== 0 
		&& periodItem.priceHDBr !== 0 
		&& periodItem.price4KDvd !== 0
		&& periodItem.price4KBr !== 0
	}

	buildTargetsCheckedArray = (periodItemTargets) => {
		var storedTargets = PricePlanning.getTargets()
		let targets = []
		storedTargets.forEach((storedTarget) => {
			const currentTarget = {
				value : storedTarget.label,
				// label : getTargetLabel(storedTarget.label),
				label: storedTarget.comments,
				checked : periodItemTargets.includes(storedTarget.label),
				countryId : storedTarget.countryId
			}
			targets.push(currentTarget)
		})
		return targets
	}

	handleRowClick = (event, row, periodItem) => {
		const { onExpandUpdateForm, scrollAction, change } = this.props
		const rowEndDate = row.endDate === null ? '' : row.endDate
		const isEst = periodItem.modeCommercialization === 'EST'
		const isVod = periodItem.modeCommercialization === 'VOD'
		change(REDUX_FORM_PRICEPLANIFICATION_UPDATE, 'checkVodDate', false)
		change(REDUX_FORM_PRICEPLANIFICATION_UPDATE, 'vodStartDate', isVod ? row.startDate : '')
		change(REDUX_FORM_PRICEPLANIFICATION_UPDATE, 'vodEndDate', isVod ? rowEndDate : '')
		change(REDUX_FORM_PRICEPLANIFICATION_UPDATE, 'checkEstDate', false)
		change(REDUX_FORM_PRICEPLANIFICATION_UPDATE, 'estStartDate', isEst ? row.startDate : null)
		change(REDUX_FORM_PRICEPLANIFICATION_UPDATE, 'estEndDate', isEst ? rowEndDate : null)
		change(REDUX_FORM_PRICEPLANIFICATION_UPDATE, 'checkEstBkDate', false)
		change(REDUX_FORM_PRICEPLANIFICATION_UPDATE, 'estBkStartDate', this.isEstBkDateChecked(periodItem) ? row.startDate : null)
		change(REDUX_FORM_PRICEPLANIFICATION_UPDATE, 'estBkEndDate', this.isEstBkDateChecked(periodItem) ? rowEndDate : null)
		change(REDUX_FORM_PRICEPLANIFICATION_UPDATE, 'targets', this.buildTargetsCheckedArray(periodItem.targets))
		change(REDUX_FORM_PRICEPLANIFICATION_UPDATE, 'checkPriceVODSD', this.isChecked(periodItem, 'VOD',periodItem.priceSD))
		change(REDUX_FORM_PRICEPLANIFICATION_UPDATE, 'priceVODSD', this.getPrice(periodItem, 'VOD',periodItem.priceSD))
		change(REDUX_FORM_PRICEPLANIFICATION_UPDATE, 'checkPriceVODHD', this.isChecked(periodItem, 'VOD',periodItem.priceHD))
		change(REDUX_FORM_PRICEPLANIFICATION_UPDATE, 'priceVODHD', this.getPrice(periodItem, 'VOD',periodItem.priceHD))
		change(REDUX_FORM_PRICEPLANIFICATION_UPDATE, 'checkPriceVOD4K', this.isChecked(periodItem, 'VOD',periodItem.price4K))
		change(REDUX_FORM_PRICEPLANIFICATION_UPDATE, 'priceVOD4K', this.getPrice(periodItem, 'VOD',periodItem.price4K))
		change(REDUX_FORM_PRICEPLANIFICATION_UPDATE, 'checkPriceESTSD', this.isChecked(periodItem, 'EST',periodItem.priceSD))
		change(REDUX_FORM_PRICEPLANIFICATION_UPDATE, 'priceESTSD', this.getPrice(periodItem, 'EST',periodItem.priceSD))
		change(REDUX_FORM_PRICEPLANIFICATION_UPDATE, 'checkPriceESTHD', this.isChecked(periodItem, 'EST',periodItem.priceHD))
		change(REDUX_FORM_PRICEPLANIFICATION_UPDATE, 'priceESTHD', this.getPrice(periodItem, 'EST',periodItem.priceHD))
		change(REDUX_FORM_PRICEPLANIFICATION_UPDATE, 'checkPriceEST4K', this.isChecked(periodItem, 'EST',periodItem.price4K))
		change(REDUX_FORM_PRICEPLANIFICATION_UPDATE, 'priceEST4K', this.getPrice(periodItem, 'EST',periodItem.price4K))
		change(REDUX_FORM_PRICEPLANIFICATION_UPDATE, 'checkPriceHDDVD', this.isChecked(periodItem, 'EST',periodItem.priceHDDvd))
		change(REDUX_FORM_PRICEPLANIFICATION_UPDATE, 'priceHDDVD', this.getPrice(periodItem, 'EST',periodItem.priceHDDvd))
		change(REDUX_FORM_PRICEPLANIFICATION_UPDATE, 'checkPriceHDBR', this.isChecked(periodItem, 'EST',periodItem.priceHDBr))
		change(REDUX_FORM_PRICEPLANIFICATION_UPDATE, 'priceHDBR', this.getPrice(periodItem, 'EST',periodItem.priceHDBr))
		change(REDUX_FORM_PRICEPLANIFICATION_UPDATE, 'checkPrice4KDVD', this.isChecked(periodItem, 'EST',periodItem.price4KDvd))
		change(REDUX_FORM_PRICEPLANIFICATION_UPDATE, 'price4KDVD', this.getPrice(periodItem, 'EST',periodItem.price4KDvd))
		change(REDUX_FORM_PRICEPLANIFICATION_UPDATE, 'checkPrice4KBR', this.isChecked(periodItem, 'EST',periodItem.price4KBr))
		change(REDUX_FORM_PRICEPLANIFICATION_UPDATE, 'price4KBR', this.getPrice(periodItem, 'EST',periodItem.price4KBr))
		onExpandUpdateForm(true) // Open the update form section
		scrollAction() // scroll to the update form
	}
	render(){
		const { classes, row, index, translate, currentContentId, isPreviewTable } = this.props
		const isFirstLine = index === 0
		// const startDate = row.startDate ? DateFormatter.getDateLocaleCustom(row.startDate) +' '+ DateFormatter.getTimeLocaleCustom(row.startDate) : ''
		// const endDate = row.endDate && row.endDate !== defaultDate ? DateFormatter.getDateLocaleCustom(row.endDate) +' '+ DateFormatter.getTimeLocaleCustom(row.endDate) : ''
		const startDate = row.startDate ? DateFormatter.getDateLocaleCustom(row.startDate): ''
		const endDate = row.endDate && row.endDate !== defaultDate ? DateFormatter.getDateLocaleCustom(row.endDate) : ''
		const itemTitle = translate('titles.periodTableSubtitle').replace('#startDate', startDate).replace('#endDate', endDate)
		if(isPreviewTable){
			return(
				<React.Fragment>
					<CustomTableHeader isFirstLine={isFirstLine} classes={classes} translate = {translate} />
					<TableRow style={{ height: '30px',backgroundColor:'white'}}>
						<StyledTableCell variant="head" colSpan={20}>{itemTitle}</StyledTableCell>
					</TableRow>
					{row.transfersRightsByPeriodItems.map(periodItem => {
						if(periodItem.isDifferent){
							return(
								<StyledPreviewModifiedTableRow key={row.name}>
									<StyledTableCell align="left">{currentContentId}</StyledTableCell>
									<StyledTableCell align="left">{periodItem.modeCommercialization}</StyledTableCell>
									<StyledTableCell align="left">{periodItem.targetsLabel.join(' / ')}</StyledTableCell>
									<StyledTableCell align="left">{startDate} - {endDate}</StyledTableCell>
									<StyledTableCell align="left">{periodItem.priceSD === -1 ? '' : periodItem.priceSD}</StyledTableCell>
									<StyledTableCell align="left">{periodItem.priceHD === -1 ? '' : periodItem.priceHD}</StyledTableCell>
									<StyledTableCell align="left">{periodItem.price4K === -1 ? '' : periodItem.price4K}</StyledTableCell>
									{/* <StyledTableCell align="right">{periodItem.priceHDDvd === -1 ? '' : periodItem.priceHDDvd}</StyledTableCell>
									<StyledTableCell align="right">{periodItem.price4KDvd === -1 ? '' : periodItem.price4KDvd}</StyledTableCell>
									<StyledTableCell align="right">{periodItem.priceHDBr === -1 ? '' : periodItem.priceHDBr}</StyledTableCell>
									<StyledTableCell align="right">{periodItem.price4KBr === -1 ? '' : periodItem.price4KBr}</StyledTableCell> */}
								</StyledPreviewModifiedTableRow>
							)
						}
						else{
							return(
								<StyledTableRow hover = {true} onClick ={(event) => this.handleRowClick(event, row, periodItem)} style={{cursor: 'pointer'}} key={row.name}>
									<StyledTableCell align="left">{currentContentId}</StyledTableCell>
									<StyledTableCell align="left">{periodItem.modeCommercialization}</StyledTableCell>
									<StyledTableCell align="left">{periodItem.targetsLabel.join(' / ')}</StyledTableCell>
									<StyledTableCell align="left">{startDate} - {endDate}</StyledTableCell>
									<StyledTableCell align="left">{periodItem.priceSD === -1 ? '' : periodItem.priceSD}</StyledTableCell>
									<StyledTableCell align="left">{periodItem.priceHD === -1 ? '' : periodItem.priceHD}</StyledTableCell>
									<StyledTableCell align="left">{periodItem.price4K === -1 ? '' : periodItem.price4K}</StyledTableCell>
									{/* <StyledTableCell align="right">{periodItem.priceHDDvd === -1 ? '' : periodItem.priceHDDvd}</StyledTableCell>
									<StyledTableCell align="right">{periodItem.price4KDvd === -1 ? '' : periodItem.price4KDvd}</StyledTableCell>
									<StyledTableCell align="right">{periodItem.priceHDBr === -1 ? '' : periodItem.priceHDBr}</StyledTableCell>
									<StyledTableCell align="right">{periodItem.price4KBr === -1 ? '' : periodItem.price4KBr}</StyledTableCell> */}
								</StyledTableRow>
							)
						}
					})}
				</React.Fragment>
			)
		}
		else {
			return(
				<React.Fragment>
					<CustomTableHeader isFirstLine={isFirstLine} classes={classes} translate = {translate} />
					<TableRow style={{ height: '30px'}}>
						<StyledTableCell variant="head" colSpan={20}>{itemTitle}</StyledTableCell>
					</TableRow>
					{row.transfersRightsByPeriodItems.map(periodItem => {
						return(
							<StyledTableRow hover = {true} onClick ={(event) => this.handleRowClick(event, row, periodItem)} style={{cursor: 'pointer'}} key={row.name}>
								<StyledTableCell align="left">{currentContentId}</StyledTableCell>
								<StyledTableCell align="left">{periodItem.modeCommercialization}</StyledTableCell>
								<StyledTableCell align="left">{periodItem.targetsLabel.join(' / ')}</StyledTableCell>
								<StyledTableCell align="left">{startDate} - {endDate}</StyledTableCell>
								<StyledTableCell align="left">{periodItem.priceSD === -1 ? '' : periodItem.priceSD }</StyledTableCell>
								<StyledTableCell align="left">{periodItem.priceHD === -1 ? '' : periodItem.priceHD }</StyledTableCell>
								<StyledTableCell align="left">{periodItem.price4K === -1 ? '' : periodItem.price4K}</StyledTableCell>
								{/* <StyledTableCell align="right">{periodItem.priceHDDvd === -1 ? '' : periodItem.priceHDDvd}</StyledTableCell>
								<StyledTableCell align="right">{periodItem.price4KDvd === -1 ? '' : periodItem.price4KDvd}</StyledTableCell>
								<StyledTableCell align="right">{periodItem.priceHDBr === -1 ? '' : periodItem.priceHDBr}</StyledTableCell>
								<StyledTableCell align="right">{periodItem.price4KBr === -1 ? '' : periodItem.price4KBr}</StyledTableCell> */}
							</StyledTableRow>
						)
					})}
				</React.Fragment>
			)
		}
	}
}

const mapStateToProps = state => ({
	...state,
	currentContentId: state.reducer.pricePlanning.contentSelected,
	isUpdateFormExpanded: state.reducer.pricePlanning.isUpdateFormExpanded
})

const mapDispatchToProps = ({
	onExpandUpdateForm,
	change
})


const enhance = compose(
	connect(mapStateToProps, mapDispatchToProps),
	withStyles(styles)
)

export default translate(enhance(PricePlanificationPeriodTableItem))