import React, { Component } from 'react'
import IdleTimer from 'react-idle-timer'
import authProvider from './authProvider'
import { AUTH_LOGOUT } from 'react-admin'
import { ON_REFRESH_TOKEN } from './constants/actions'
import { Logger, Token } from './tools'
import { get } from 'axios'
import { Contacts, DomainsItems, Companiestransfers, Authentification, Domains } from './requests'

 
export default class IdleLayout extends Component {
	constructor(props) {
		super(props)

		this.state = {
			// idleTimeout : 1000 * 10 * 1,
			// callRefreshTimeout : 1000 * 5 * 1,
			idleTimeout : 1000 * 60 * 15,
			callRefreshTimeout : 1000 * 60 * 13,
			showModal: false,
			userLoggedIn: false
		}

		this.idleTimer = null
		this.onAction = this._onAction.bind(this)
		this.onActive = this._onActive.bind(this)
		this.onIdle = this._onIdle.bind(this)
	}



	//  checkAuthentication = async () => {
	// 	  const token = Token.getToken(); // Récupère le token du stockage
	// 	  const refresh_token = Token.getRefreshToken()
  
	// 	  if (token || refresh_token) {
	// 		try {
	// 		   const isValid = await Authentification.refreshToken(refresh_token)
	// 		  if (!isValid) {
	// 				 await this.redirectToOkta(); // Si le token est invalide, redirige vers Okta
	// 		  }
	// 		  else{
	// 			  Token.store(isValid.data)
	// 			  if (!localStorage.getItem("dataDomains")) {
	// 				  Domains.getAll().then((json) =>
	// 					  localStorage.setItem("dataDomains", JSON.stringify(json.data))
	// 				  )
	// 			  }
	// 			  if (!localStorage.getItem("dataContacts")) {
	// 				  Contacts.getAll().then((json) =>
	// 					  localStorage.setItem("dataContacts", JSON.stringify(json.data))
	// 				  )
	// 			  }
	// 			  if (!sessionStorage.getItem("dataDomainsItems")) {
	// 				  DomainsItems.getAll().then((json) =>
	// 					  sessionStorage.setItem("dataDomainsItems", JSON.stringify(json.data))
	// 				  )
	// 			  }
	// 			  if (!sessionStorage.getItem("pricePlanning")) {
	// 				  Companiestransfers.getPricePlanningTargets().then((json) =>
	// 					  sessionStorage.setItem("pricePlanning", JSON.stringify(json.data))
	// 				  )
	// 			  }
	// 			  authProvider('AUTH_GET_PERMISSIONS').then(()=>{
	// 				  setInterval(() => {
	// 					  //console.log('rollback get cache')
	// 					   window.location.href = process.env.PUBLIC_URL + '/#'
	// 				  }, 2000)
	// 			  })
				  
	// 		  }
	// 		} catch (error) {
	// 		  console.error("Erreur lors de la vérification du token", error);
	// 		  await this.redirectToOkta(); // En cas d'erreur de vérification, redirige vers Okta
	// 		}
	// 	  } else {
	// 		await this.redirectToOkta(); // Si aucun token n'est présent, redirige vers Okta
	// 	  }
	// 	}
	
	 
	
	//    redirectToOkta = async () => {
	// 	// setLoading(true)
	// 	try {
	// 	  console.log(process.env.REACT_APP_OKTA)
  
	// 	  const oktaSAMLUrl = 'https://mylogin.canal-plus.com/app/groupecanalplus_hawcdev_1/exkcm9laftXBS7Agk417/sso/saml';
	// 	  window.location.href = oktaSAMLUrl;
	// 	  //await Authentification.SamlLogin(oktaSAMLUrl);
	// 	} catch (error) {
	// 	  console.error("Erreur lors de la redirection vers Okta", error);
	// 	//   setLoading(false);
	// 	//   setLoginValueError('Erreur lors de l\'authentification. Veuillez réessayer.');
	// 	}
	//   };



	componentDidMount(){
		//this.checkAuthentication();
		//get the default value from the code app settings
		get(process.env.REACT_APP_AUTH + '/session').then(result => {
			console.log(result)
			if(result !== undefined && result.data.data !== undefined){
				this.setState({
					idleTimeout : result.data.data.idleTimeout * 1000 * 60,
					callRefreshTimeout : (result.data.data.idleTimeout - 2) * 1000 * 60
				})
			}	
		}).catch(error =>{
			Logger.debug('Get session error: ' + error.message)
			return Promise.reject(new Error(error.message))
		})
		

	}
 
	render() {
		console.log('idlelayout test')
		return (
			<div>
				<IdleTimer
					ref={ref => { this.idleTimer = ref }}
					element={document}
					onActive={this.onActive}
					onIdle={this.onIdle}
					onAction={this.onAction}
					debounce={100}
					timeout={this.state.idleTimeout}
				/>
			</div>
		)
	}
 
	_onAction(e) {
		if(Token.tokenExists()){
			// console.log('elapsed time ', this.idleTimer.getElapsedTime())
			// console.log('user did something', e)
			if(this.idleTimer.getElapsedTime() >= this.state.callRefreshTimeout){
				Logger.debug('Do the refresh token - Elapsed time '+this.idleTimer.getElapsedTime())
				console.log('Do the refresh token - Elapsed time '+this.idleTimer.getElapsedTime())
				//Do the refresh token
				authProvider(ON_REFRESH_TOKEN, )
				this.idleTimer.reset()
			}
			// console.log('user did something', e)
			// console.log('time remaining', this.idleTimer.getRemainingTime())
		}
	}

	_onActive(e) {
		if(Token.tokenExists()){
			console.log('user is active', e)
			console.log('time remaining', this.idleTimer.getRemainingTime())
		}
	}

	_onIdle(e) {
		// console.log('user is idle', e)
		if(Token.tokenExists()){
			const idle = {
				message : 'idle timeout'
			}
			//Log out the user
			Logger.debug('Logout the user because he/she is idle')
			authProvider(AUTH_LOGOUT, idle)
		}
		console.log('last active', this.idleTimer.getLastActiveTime())
	}
}