import React, { Component } from 'react'
import { REDUX_FORM_NAME, SimpleShowLayout } from 'react-admin'
import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import Typography from '@material-ui/core/Typography'
import TextField from '@material-ui/core/TextField'
import Grid from '@material-ui/core/Grid'
import Checkbox from '@material-ui/core/Checkbox'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import { autofill, registerField } from 'redux-form'
import { connect } from 'react-redux'
import compose from 'recompose/compose'
import CardChild from '../../CardChild'
import { MicroEligibilityType } from '../../constants/types'
import { Token, Contacts } from '../../tools'
import { MyTextInput } from '../common/inputs'
import { PromotionsRules, PromotionsGeneral } from './modules'
import MenuItem from '@material-ui/core/MenuItem'
import { withStyles } from '@material-ui/core/styles'



const styles = theme =>  ({
	cardPaddingMargin: {
		padding: 20,
		margin: 20,
		flex: '1 auto'
	},
	cardDiv: {
		flex: '1 auto',
		// height: '86.9%',
		padding: 20,
		margin: 20,
	},
	flexContent: {
		display: 'flex',
		alignItems: 'flex-start',
		padding: 20,
		margin: 20,
	},
	flexContentRules: {
		display: 'flex',
		alignItems: 'flex-start',
		marginTop: 10,
		marginBottom: 10,
	},
	input: {
		display: 'inline-block'
	},
	inputLeft: {
		display: 'inline-block',
		marginLeft: 32
	},
	noMarginLeft: {
		marginLeft: 0
	},
	paddingCheckBox: {
		marginLeft: 0
	},
	paddingCheckBoxCloture: {
		marginTop: '-10px'
	},
	fullWidth: {
		width: '90%',
		marginBottom: '20px',
		marginRight: '10px',
		marginTop: '-8px'
	},
	fullWidthInput: {
		width: '90%',
		marginBottom: '20px',
		marginRight: '10px',
		marginTop: '8px',
	},
	fullWidthDescription: {
		width: '97%',
	},
	fullWidthSelect: {
		width: '90%',
		marginTop: '4px',
	},
	descriptionInput: {
		lineHeight: '25px',
		paddingLeft: '7px'
	},
	promotionTitle: {
		marginTop: '-20px',
		marginLeft: '10px',
		fontSize: '26px',
	},
})

class CardCreate extends Component {

	constructor(props) {
		super(props)
		// this.onChangeRules = this.onChangeRules.bind(this)
		this.state = {
			startTime: '00:00',
			endTime: '23:59',
			createType: [],
		}
	}

	

	createSelect(selectValue) {
		let listValue = []
		listValue.push(<MenuItem value="0" selected><em>Aucun</em></MenuItem>)

		selectValue.forEach(element => {
			listValue.push(<MenuItem value={element.id}>{element.name}</MenuItem>)
		})

		return listValue
	}

	createEligibility(){
		let listEligibility = []

		listEligibility.push(<Grid xs={12}><FormControlLabel control={<Checkbox color="primary" />} name={MicroEligibilityType.RCPL_TVOD} label={MicroEligibilityType.RCPL_TVOD} labelPlacement="end" onChange={this.onChangeRules('micro')} /></Grid>)
		listEligibility.push(<Grid xs={12}><FormControlLabel control={<Checkbox color="primary" />} name={MicroEligibilityType.CP_ALD} label={MicroEligibilityType.CP_ALD} labelPlacement="end" onChange={this.onChangeRules('micro')} /></Grid>)
		
		return listEligibility
	}

	onChangeGeneral = e => {
		console.log(e, 'promo')
	}

	render() {
		const { classes, autofill } = this.props
		autofill(REDUX_FORM_NAME, 'contactid', parseInt(Token.getId(), 10))

		return (
			<div className={classes.cardDiv}>
				<Grid container spacing={3}>
					<Grid item xs={7}>
						<PromotionsGeneral classes={classes} create={true} handleInput={this.onChangeGeneral()} />
					</Grid>
					<Grid item xs={5}>
						<Card className={classes.cardDiv}>
							<CardContent>
								<Typography variant="title">Gestion</Typography>
							</CardContent>
							<CardChild>
								<SimpleShowLayout>
									<Grid container spacing={3} style={{ textAlign: 'center' }}>
										<MyTextInput type='hidden' label="fields.contactid" source="contactid" choices={Contacts.getSelectList()} allowEmpty={false} />
										<Grid xs>
											<TextField label="Créé par" value='' />
										</Grid>
										<Grid xs>
											<TextField label="Créé le" value='' />
										</Grid>
										<Grid xs={12}>
											<br />
										</Grid>
										<Grid xs>
											<TextField label="Modifié par" value='' />
										</Grid>
										<Grid xs>
											<TextField label="Modifié le" value='' />
										</Grid>

									</Grid>
								</SimpleShowLayout>
							</CardChild>
						</Card>
					</Grid>
					{this.state.createType}
					{/* <PromotionsRules classes={classes} create={true} /> */}

				</Grid>

			</div>
		)
	}
}

const mapDispatchToProps = {
	autofill,
	registerField
}

const enhance = compose(
	connect(null, mapDispatchToProps),
	withStyles(styles)
)

export default enhance(CardCreate)
