import React from 'react'
import { NumberField } from 'react-admin'
import { Link } from 'react-router-dom'
import { Links } from '../../../tools'
import { getId } from '../../../tools/utils'

const IdField = props => {
	const { isLink, basePath, ...rest } = props

	if (isLink) {
		const id = getId(props)
		const link = Links.Show(basePath, id, true)
		return <NumberField component={Link} to={link} {...rest} options={{ useGrouping: false }} />
	}

	return <NumberField {...rest} options={{ useGrouping: false }} />
}

IdField.defaultProps = {
	addLabel: true,
	isLink: false,
}

export default IdField
