import React from 'react'
import { connect } from 'react-redux'
import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import Button from '@material-ui/core/Button'
import { translate, changeLocale, Title } from 'react-admin'
import compose from 'recompose/compose'
import { changeTheme } from '../../actions/configuration'
import { setQuickSearchMaintenanceModeStatus } from '../../actions/applicationStatus'
import { userHasAdminRights } from '../../userrights/validate'
import {withStyles , }  from '@material-ui/core/styles'

const styles = theme =>   ({
	label: { width: '10em', display: 'inline-block',  },
	button: { 
		margin: '1em' ,
		//backgroundColor: 'grey',
		"&$hover": {
			backgroundColor : '#288DB1'
	},
		//backgroundColor : '#288DB1'
	},
})

const Configuration = ({
	classes,
	theme,
	locale,
	changeTheme,
	changeLocale,
	translate,
	isMaintenanceModeEnabled,
	setQuickSearchMaintenanceModeStatus,
}) => (
	
	<Card>
		<Title title={translate('titles.configuration')} />
		<CardContent>
			<div className={classes.label}>{translate('fields.theme')}</div>
			<Button
				variant="contained"
				className={classes.button}
				color={theme === 'light' ? 'primary' : 'default'}
				//backgroundColor ={theme === 'light' ? '#288DB1' : 'default'}
				onClick={() => changeTheme('light')}
			>
				{translate('labels.light')}
			</Button>
			<Button
				variant="contained"
				className={classes.button}
				color={theme === 'dark' ? 'primary' : 'default'}
				//backgroundColor ={theme == 'dark' ? '#288DB1' : 'default'}
				onClick={() => changeTheme('dark')}
			>
				{translate('labels.dark')}
			</Button>
		</CardContent>
		<CardContent>
			<div className={classes.label}>{translate('fields.language')}</div>
			<Button
				variant="contained"
				className={classes.button}
				color={locale === 'en' ? 'primary' : 'default'}
				onClick={() => changeLocale('en')}
			>
				en
			</Button>
			<Button
				variant="contained"
				className={classes.button}
				color={locale === 'fr' ? 'primary' : 'default'}
				onClick={() => changeLocale('fr')}
			>
				fr
			</Button>
		</CardContent>
		{userHasAdminRights() === true ? 
			<CardContent>
				<div className={classes.label}>{translate('fields.searchMode')}</div>
				<Button
					variant="contained"
					className={classes.button}
					color={isMaintenanceModeEnabled === false ? 'primary' : 'default'}
					onClick={() => setQuickSearchMaintenanceModeStatus(false)}
				>
					{translate('labels.normalSearchMode')}
				</Button>
				<Button
					variant="contained"
					className={classes.button}
					color={isMaintenanceModeEnabled === true ? 'primary' : 'default'}
					onClick={() => setQuickSearchMaintenanceModeStatus(true)}
				>
					{translate('labels.emergencySearchMode')}
				</Button>
			</CardContent>
			: <div></div>
		}
	</Card>
)

const mapStateToProps = state => ({
	theme: state.theme,
	locale: state.i18n.locale,
	isMaintenanceModeEnabled : state.reducer.applicationStatus !== null ?  state.reducer.applicationStatus.isMaintenanceModeEnabled : false
})

export default compose(
	connect(
		mapStateToProps,
		{
			changeLocale,
			changeTheme,
			setQuickSearchMaintenanceModeStatus,
		}
	),
	translate,
	withStyles(styles)
)(Configuration)