import React from 'react'
import PropTypes from 'prop-types'
import Card from '@material-ui/core/Card'
import Typography from '@material-ui/core/Typography'
import { CardIcon } from './index'
import { withStyles } from '@material-ui/core/styles'

const styles =   ({
	main: {
		flex: '1',
		marginLeft: '1em',
		marginTop: 20,
	},
	card: {
		overflow: 'inherit',
		textAlign: 'left',
		padding: 16,
		minHeight: 52,
	},
	title: {
		marginTop: 60,
		textOverflow: 'ellipsis',
		overflow: 'hidden',
		whiteSpace: 'nowrap',
		color :'#000'
	}
})

// eslint-disable-next-line react/prop-types
const CustomCardIcon = ({ Icon, value, label, classes, bgColor }) => (
	<div className={classes.main}>
		<CardIcon Icon={Icon} bgColor={bgColor} />
		<Card className={classes.card}>
			<Typography className={classes.title} variant="subheading">
				<span>{label}</span>
			</Typography>
			<Typography variant="title" component="h2">
				<span>{value}</span>
			</Typography>
		</Card>
	</div>
)

CustomCardIcon.propTypes = {
	value: PropTypes.string,
	label: PropTypes.string,
	classes: PropTypes.object.isRequired,
	bgColor: PropTypes.string,
}

export default withStyles(styles)(CustomCardIcon)