import React from 'react'
import { TextInput, SelectInput, TabbedForm, FormTab } from 'react-admin'
import { DatePickerCanal } from '../common/inputs'
import { DomainsItems } from '../../tools'
import { MainIcon } from '../../constants/icons'

const CardCreate = ({ ...props }) => (
	<TabbedForm {...props}>
		<FormTab label="Fiche" icon={<MainIcon />}>
			<SelectInput label="fields.roleid" source="roleid" choices={DomainsItems.getSelectList(22)} allowEmpty={false} />
			<SelectInput label="fields.civilityid" source="civilityid" choices={DomainsItems.getSelectList(23)} allowEmpty={false} />
			<TextInput label="fields.lastname" source="lastname" />
			<TextInput label="fields.firstname" source="firstname" />
			<DatePickerCanal label="fields.birthdate" source="birthdate" />
			<DatePickerCanal label="fields.deathdate" source="deathdate" />
		</FormTab>
	</TabbedForm>
)

export default CardCreate
