import React, { Component, useMemo } from 'react'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import Slide from '@material-ui/core/Slide'
import PropTypes from 'prop-types'
import cyan from '@material-ui/core/colors/cyan'
import { SimpleForm, translate, showNotification } from 'react-admin'
import { submit  } from 'redux-form'
import { connect } from 'react-redux'
import compose from 'recompose/compose'
import green from '@material-ui/core/colors/green'
import red from '@material-ui/core/colors/red'
import grey from '@material-ui/core/colors/grey'
import { ProcessLoader } from '../../common'
import { Token, Logger } from '../../../tools'
import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import FileInformationContainer from './dropzone/FileInformationContainer'
import Typography from '@material-ui/core/Typography'
import Dropzone from 'react-dropzone'
import { CloudUploadIcon, CloseIcon } from '../../../constants/icons'
import { stringify } from 'query-string'
import { post } from 'axios'
import { withStyles } from '@material-ui/core/styles'

const Transition = React.forwardRef(function Transition(props, ref) {
	return <Slide direction="up" ref={ref} {...props} />
})

const styles = theme =>  ({
	button: {
		//margin: theme.spacing(1),
		margin: '5px 0  5px 0',
 		width : '150px',
		backgroundColor:'#43a047'

	},
	buttonCancel: {
		margin: theme.spacing(1),
		width : '200px',
		background: '#e23434'
	},
	buttonCheck: {
		margin: theme.spacing(1),
		width : '200px',
		background: '#0c9e0c'
	},
	leftIcon: {
		marginRight: theme.spacing(1),
	},
	dialogHeader:{
		padding: '10px 10px 10px',
		background: cyan[500],
		color: 'white',
		fontSize: 'medium',
		fontWeight: 'bold',
		textAlign: 'center',
		//height: '36px'
	},
	closeIcon:{
		width : '15px',
		position: 'absolute',
    	top: '0px',
    	right: '0px',
		borderRadius: '0',
		color: 'white',
		'&:hover': {
			background: 'red'//red
		}
	},
	dialogContent: {
		padding: '16px 24px 17px',
		flex: '1 1 auto',
		color: 'white',
		fontSize: '1rem',
		fontWeight: '400'
	},
	dialogFooter: {
		flex: '0 0 auto',
		margin: '8px 4px',
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'flex-end'
	},
	formControl: {
		margin: theme.spacing(1),
		minWidth: 120,
	},
	selectEmpty: {
		marginTop: theme.spacing(2),
	},
	customSelect: {
		width : '80%'
	},
	rightIcon: {
		// marginLeft: theme.spacing(1),
	},
	fileAccepted: {
		backgroundColor : green[500],
		marginTop: '5px',
		textAlign : 'center'
	},
	fileRejected: {
		backgroundColor : red[400],
		marginTop: '5px',
		textAlign : 'center'
	},
	fileInformationSection : {
		width : '100%'
	},
	noJsValidation:{
		backgroundColor : grey[200],
		marginTop: '5px',
		textAlign : 'center'
	}
})

const baseStyle = {
	display: 'flex',
	flexDirection: 'column',
	alignItems: 'center',
	padding: '20px',
	borderRadius: '7px',
	backgroundColor: '#b3b3b3',
	color: 'grey[900]',
	outline: 'none',
	transition: 'border .24s ease-in-out'
}

// const activeStyle = {
// 	backgroundColor: '#2196f3'
// }

// const acceptStyle = {
// 	backgroundColor: green[200]
// }

// const rejectStyle = {
// 	backgroundColor: red[400]
// }

const REDUX_FORM_PLANNING_UPLOAD_TOOLS = 'REDUX_FORM_PLANNING_UPLOAD_TOOLS'
const maxSize = 20971520

class PlanningUploadModal extends Component {
	constructor(props) {
		super(props)
		this.state = {
			open : false,
			enabled : true,
			acceptedFiles: [],
			rejectedFiles: [],
			style : baseStyle,
			isButtonSaveActivated : false,
			isProcessLoaderOpen : false,
			isOperationFinished : false
		}
	}

	componentDidMount(){
		const { modalOpen } = this.props
		this.setState({ open : modalOpen })  
	}

	componentDidUpdate(previousProps, previousState) {
		if (previousProps.modalOpen !== this.props.modalOpen) {
			const { modalOpen } = this.props
			this.setState({ open : modalOpen }) 
		}
	}

	handleOnDrop = (acceptedFiles, rejectedFiles) => {
		//Debug format issue
		Logger.info('accepted files')
		Logger.info(acceptedFiles)
		Logger.info('rejected files')
		Logger.info(rejectedFiles)
		const isJsValidationEnabled = parseInt(process.env.REACT_APP_ACTIVATE_DROPZONE_JSVALIDATION, 10) === 1 ? true : false
		if(isJsValidationEnabled){
			if(acceptedFiles && acceptedFiles.length !== 0){
				this.setState({
					file : acceptedFiles[0],
					isButtonSaveActivated : true,
					acceptedFiles : acceptedFiles,
					rejectedFiles : []
				})
			}
			else{
				this.setState({ 
					isButtonSaveActivated : false,
					rejectedFiles : rejectedFiles,
					acceptedFiles : [] 
				})
			}
		}
		else{
			this.setState({
				file : acceptedFiles[0],
				isButtonSaveActivated : true,
				acceptedFiles : acceptedFiles,
				rejectedFiles : []
			})
		}
	}


	handleSaveClick = () => {
		const { submit } = this.props
		submit(REDUX_FORM_PLANNING_UPLOAD_TOOLS)
	}

	handleCloseProgressBar = () => {
		this.setState({isProcessLoaderOpen: false})
	}

	handleSubmit = values => {
		const { showNotification } = this.props
		const isJsValidationEnabled = parseInt(process.env.REACT_APP_ACTIVATE_DROPZONE_JSVALIDATION, 10) === 1 ? true : false
		var formData = new FormData()
		formData.append('files', this.state.file)
		let query = {
			fileName: this.state.file.name,
			triggerBackendValidation : !isJsValidationEnabled
		}
		const url = `${process.env.REACT_APP_API}/Tools/PlanningUpload?${stringify(query)}`
		const t = Token.getToken()
		const config = {
			headers: {
				'content-type': 'multipart/form-data',
				'Authorization': `Bearer ${t}`
			},
		}
		console.log(formData)
		Logger.info(this.state.file.name)
		//check size first
		if(this.state.file.size > maxSize){
			showNotification(`La taille du fichier est de ${this.state.file.size} octets et est supérieur à la taille autorisée`, 'warning', { autoHideDuration : '6000' })
			this.setState({
				isButtonSaveActivated : false
			})
		}
		else{
			this.setState({ 
				isProcessLoaderOpen : true,
				isOperationFinished : false,
				open: false
			})
			post(url, formData, config).then((response) => {
				Logger.info(response)
				this.setState({ 
					isButtonSaveActivated : false,
					acceptedFiles : [],
					rejectedFiles : [],
					isOperationFinished : true
				})
			}).catch(error => {
				const { response } = error
				Logger.info(response)
				this.setState({
					isButtonSaveActivated : false,
					isProcessLoaderOpen : false,
					isOperationFinished : true
				})
				if(response.status === 801){
					showNotification(`La taille du fichier est de ${this.state.file.size} octets et est supérieur à la taille autorisée`, 'warning', { autoHideDuration : '6000' })
				}
				else if(response.status === 802){
					showNotification('Le fichier n’est pas dans un format reconnu', 'warning', { autoHideDuration : '6000' })
				}
				else{
					showNotification('Erreur lors du transfert du fichier', 'warning', { autoHideDuration : '6000' })
				}
			})
		}
	}

	render(){
		const { onClose,  modalContent, classes, translate} = this.props
		const modalTitle = modalContent.title
		const saveButtonLabel = 'Transférer'
		const isJsValidationEnabled = parseInt(process.env.REACT_APP_ACTIVATE_DROPZONE_JSVALIDATION, 10) === 1 ? true : false
		const progressModalContent = {
			title : translate('titles.importPlanning'),
			closeButtonText : translate('ra.action.close'),
			operationFinished : translate('messages.uploadSuccess')
		}
		return (
			<div>
				<Dialog
					open={this.state.open}
					TransitionComponent={Transition}
					keepMounted
					onClose={onClose}
					disableEscapeKeyDown={true}
					disableBackdropClick={true}
					aria-labelledby="alert-dialog-slide-title"
					aria-describedby="alert-dialog-slide-description" 
				>
						<div className={classes.dialogHeader}>
							{modalTitle}           
						<Button onClick={onClose}  className={classes.closeIcon} >
								<CloseIcon/>				 
						</Button>	
						</div>
					<Card>
						<CardContent>
							<SimpleForm 
								onSubmit={this.handleSubmit}
								form={REDUX_FORM_PLANNING_UPLOAD_TOOLS}
								resource="Tools/Upload"
								// We want no toolbar at all as we have our modal actions
								toolbar={null}
							>
								{/* <Typography variant="body2" gutterBottom>
									Import du planning de publication
								</Typography> */}
								<Dropzone 
									onDrop={this.handleOnDrop}
									multiple = {false}
									onDragEnter = { this.handleOnDragEnter }
								>
									{({getRootProps, getInputProps, isDragActive, isDragReject, rejectedFiles, isDragAccept, acceptedFiles, draggedFiles }) => {
										// Logger.info('dragged')
										// Logger.info(isDragReject)
										// Logger.info(isDragAccept)
										const style = useMemo(() => ({
											...baseStyle,
											...(isDragActive ? baseStyle : {}),
											...(isDragAccept ? baseStyle : {}),
											...(isDragReject ? baseStyle : {}),
											// backgroundColor: "#00BCD4",
											// color: "#fff",
											borderRadius: "5px"
										}), [
											isDragActive,
											isDragReject
										])
										return (
											<React.Fragment>
												<div {...getRootProps({style})}>
													<input {...getInputProps()} />
													{!isDragActive && 'Glissez votre fichier* ici ou cliquez pour sélectionner le fichier à transférer'}
													{isDragActive && !isDragReject  && 'Fichier prêt à être transféré!'}
													{isDragReject && 'Fichier prêt à être transféré!'}
												</div>
											</React.Fragment>
										)}
									}
								</Dropzone>
								<FileInformationContainer classes = {classes} acceptedFiles = { this.state.acceptedFiles } rejectedFiles = { this.state.rejectedFiles } isJsValidationEnabled = {isJsValidationEnabled}/>
								<Button onClick={this.handleSaveClick} color='primary'  variant="contained" className={classes.button} disabled = {!this.state.isButtonSaveActivated} style={{marginLeft:0,	backgroundColor:this.state.isButtonSaveActivated && '#43a047'}}>
									<CloudUploadIcon className={classes.leftIcon} />
									{saveButtonLabel}
								</Button>
								<Typography variant="caption" gutterBottom minwidth="450px !importante" >
									*Le fichier doit être de type .xlsm ou .xlsx avec une taille inférieure ou égale à 20 Mo
								</Typography>
							</SimpleForm>
						</CardContent>
					</Card>
					<ProcessLoader modalOpen = {this.state.isProcessLoaderOpen} 
						onClose={this.handleCloseProgressBar}  
						isOperationFinished = { this.state.isOperationFinished }
						progressModalContent = {progressModalContent}
					/>
				</Dialog>
			</div>
		)
	}
}

PlanningUploadModal.propTypes = {
	modalOpen: PropTypes.bool,
	onClose: PropTypes.func,
	modalContent: PropTypes.object,
	classes: PropTypes.object.isRequired,
}

const mapStateToProps = state => ({
	...state
})

const mapDispatchToProps = ({
	submit,
	showNotification
})

const enhance = compose(
	connect(mapStateToProps, mapDispatchToProps),
	withStyles(styles)
)

export default translate(enhance(PlanningUploadModal))
