
import { Token } from '../tools'
import { ContactType } from '../constants/types'
import { any, isArrayLike } from '30-seconds-of-code'

export const userHasRight = (permissions, procid) => {
	if (!isArrayLike(permissions) || procid <= 0)
		return false

	const typeid = Token.getTypeId()
	if (ContactType.SUPERVISOR === typeid || ContactType.ADMINISTRATOR === typeid) {
		// Logger.debug('User is administrator/supervisor and can use procid: ' + procid)
		return true
	}

	// !!!! TEMPORARY - HIDE SOME RESOURCES FOR NON SUPERVISORS/ADMINISTRATORS
	// if (ContactType.SUPERVISOR !== typeid && ContactType.ADMINISTRATOR !== typeid) {
	// 	Logger.debug('User can use procid: ' + procid)
	// 	return (procid === Procs.INGESTION_STATUS || procid === Procs.JOBS_SUPERVISION)
	// }
	// !!!! TEMPORARY - HIDE SOME RESOURCES FOR NON SUPERVISORS/ADMINISTRATORS

	const hasRight = any(permissions, p => Number(p) === procid)
	// Logger.debug('User can use procid: ' + procid)
	return hasRight
}

export const userHasAdminRights = () => {
	const typeid = Token.getTypeId()
	if (ContactType.SUPERVISOR === typeid || ContactType.ADMINISTRATOR === typeid) {
		// Logger.debug('User is administrator/supervisor and can use procid: ' + procid)
		return true
	}
	return false
}