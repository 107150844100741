import React from 'react'
import TableCell from '@material-ui/core/TableCell'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import TableSortLabel from '@material-ui/core/TableSortLabel'
import Checkbox from '@material-ui/core/Checkbox'

class EnhancedTableHead extends React.Component {
	
	render(){
		
		const { classes, onSelectAllClick, order, orderBy, numSelected, rowCount, onRequestSort,type } = this.props
		const createSortHandler = (property) => (event) => {
			onRequestSort(event, property)
		}
		if (type === 'AssignPreparation')
		{
			const headCells = [
				//{ id: 'id', numeric: false, disablePadding: true, label: 'Id' },
				{ id: 'planningDate', numeric: false, disablePadding: false, label: 'Date' },
				{ id: 'user', numeric: false, disablePadding: false, label: 'Responsable' },
				{ id: 'affair', numeric: true, disablePadding: false, label: 'Num Affaire' },
				{ id: 'pgm', numeric: false, disablePadding: false, label: 'ID Prog.' },
				{ id: 'movieTitle', numeric: true, disablePadding: false, label: 'Titre' },
				{ id: 'categoryId', numeric: false, disablePadding: false, label: 'Catégorie' },
				{ id: 'taskTypeId', numeric: false, disablePadding: false, label: 'Accès' },
			]
	
		
			return (
				<TableHead>
					<TableRow>
						<TableCell key={0} padding="checkbox">
							<Checkbox
								indeterminate={numSelected > 0 && numSelected < rowCount}
								checked={rowCount > 0 && numSelected === rowCount}
								onChange={onSelectAllClick}
								inputProps={{ 'aria-label': 'Choisir toutes les lignes' }}
							/>
						</TableCell>
						{headCells.map((headCell) => (
							<TableCell
								key={headCell.id}
								style={{fontWeight : 'normal'}}
								align={headCell.numeric ? 'center' : 'left'}
								padding={headCell.disablePadding ? 'none' : 'normal'}//'6px 10px 6px 10px'
								sortDirection={orderBy === headCell.id ? order : false}
							>
								<TableSortLabel
									active={orderBy === headCell.id}
									direction={orderBy === headCell.id ? order : 'asc'}
									onClick={createSortHandler(headCell.id)}
								>
									{headCell.label}
									{orderBy === headCell.id ? (
										<span className={classes.visuallyHidden}>
											{order === 'desc' ? 'Tri decroissant' : 'Tri croissant'}
										</span>
									) : null}
								</TableSortLabel>
							</TableCell>
						))}
					</TableRow>
				</TableHead>
			)

		}
		else if( type === 'trackingtype')
		{
			const headCells = [
				{ id: 'id', numeric: true, disablePaddingLeft: false,disablePaddingRight: true , label: 'Groupe' },
				{ id: 'Ordre', numeric: true, disablePaddingLeft: true,disablePaddingRight: true , label: 'Ordre' },
				{ id: 'Label', numeric: false, disablePaddingLeft: true,disablePaddingRight: false , label: 'Désignation' },	
				{ id: 'color', numeric: false, disablePaddingLeft: true,disablePaddingRight: false , label: 'Couleur' },
				{ id: 'desactivated', numeric: false, disablePaddingLeft: false,disablePaddingRight: false , label: 'Actif' },
			]
	
		
			return (
				<TableHead>
					<TableRow>
						{/* <TableCell padding="checkbox">
							<Checkbox
								indeterminate={numSelected > 0 && numSelected < rowCount}
								checked={rowCount > 0 && numSelected === rowCount}
								onChange={onSelectAllClick}
								inputProps={{ 'aria-label': 'Choisir toutes les lignes' }}
							/>
						</TableCell> */}
						{headCells.map((headCell) => (
							<TableCell
								key={headCell.id}
								style ={
									{
										textAlign: headCell.numeric ? 'centre' : 'left',
										paddingLeft: headCell.disablePaddingLeft ? '0px' : 'normal',
										paddingRight: headCell.disablePaddingRight ? '0px' : 'normal'
								
									}
								}
									 
								sortDirection={orderBy === headCell.id ? order : false}
							>
								<TableSortLabel
									active={orderBy === headCell.id}
									direction={orderBy === headCell.id ? order : 'asc'}
									onClick={createSortHandler(headCell.id)}
								>
									{headCell.label}
									{orderBy === headCell.id ? (
										<span className={classes.visuallyHidden}>
											{order === 'desc' ? 'Tri decroissant' : 'Tri croissant'}
										</span>
									) : null}
								</TableSortLabel>
							</TableCell>
						))}
					</TableRow>
				</TableHead>
			)
		}
		
	}
	
}

export default EnhancedTableHead