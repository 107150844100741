import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Url } from '../../tools'
import { AspectRatio } from '../../constants/types'
import { isNil } from '30-seconds-of-code'

class Image extends Component {
	constructor() {
		super()
		this.handleLoad = this.handleLoad.bind(this)
		this.handleError = this.handleError.bind(this)
		this.state = {error: false, isLoaded: false}
	}

	handleLoad () {
		if (this.state.isLoaded)
			return
		this.setState({isLoaded: true})
	}

	handleError () {
		if (this.state.error)
			return
		this.setState({error: true})
	}

	getDefault(aspectRatio) {
		return Url.defaultPictureByRatio(aspectRatio)
	}

	render() {
		const { url, width, height} = this.props
		let { aspectRatio } = this.props
		//const test =  process.env.REACT_APP_MEDIA + url.substring(7, url.length).replace('%7Bid%7D',url.split('/')[3])
		//:process.env.REACT_APP_MEDIA + url.substring(7, url.length).replace('%7Bid%7D_1_150x200.jpg','H.jpg')
		// let url2 =""

		// if (url.split('/')[2] !== process.env.REACT_APP_MEDIA  ){
		// 	url2 = process.env.REACT_APP_MEDIA + url.substring(7, url.length).replace('%7Bid%7D_1_150x200.jpg',`mvs_movie_${url.split('/')[3]}_603.jpg`)
		// }
		// console.log(url2)

		if (!aspectRatio)
			aspectRatio = AspectRatio.COVER
	 
		if (isNil(String, url)) {
			return (
				<img
					src={this.getDefault(aspectRatio)}
					width={width}
					height={height}
					alt='' />
			)
		}

		return <img
			src={this.state.error ? this.getDefault(aspectRatio) : url}
			onLoad={this.handleLoad}
			onError={this.handleError}
			width={width}
			height={height}
			alt={url} />
	}
}

Image.propTypes = {
	url: PropTypes.string,
	width: PropTypes.number,
	height: PropTypes.number,
	aspectRatio: PropTypes.object,
}

export default Image

