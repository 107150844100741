export default class LocalStorage {
	static history = (id, objectId, title, typeId, filename) => {
		//Gestion historique
		var historyValue = {'id': id, 'objectId': objectId, 'title': title, 'typeId' : typeId, 'filename' : filename}
		var history = JSON.parse(localStorage.getItem('history'))
		if(history!=null){
			var contains = history.some(elem =>{
				return JSON.stringify(historyValue.id) === JSON.stringify(elem.id) && JSON.stringify(historyValue.objectId) === JSON.stringify(elem.objectId);
			  });
			if(contains){
				const index = history.findIndex(i => i.id === historyValue.id && i.objectId === historyValue.objectId)
				history.splice(index, 1)
				history.unshift(historyValue)
			}else if(!contains){
				history.unshift(historyValue)
			}
		}else{
			history = [historyValue]
		}
		localStorage.setItem('history', JSON.stringify(history))
		history = JSON.parse(localStorage.getItem('history'))
		if(history.length > 20){
			history.pop()
			localStorage.setItem('history', JSON.stringify(history))
		}
	}
}