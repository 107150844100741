import React from 'react'
import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import CardChild from '../../CardChild'
import { ContactField, MyTextField, MyDateField, CardTitleField } from '../common/fields'
import { MySimpleFormLayout } from '../layout'
import { AuditedIcon } from '../../constants/icons'
import { Typography } from '@material-ui/core'
import { Title } from './index'
import { withStyles } from '@material-ui/core/styles'

const styles = ({
	card: {
		paddingTop: 10,
		flex: '1 auto'
	},
})

const CardShow = withStyles(styles)(({ classes, record } = this.props) => (
	<MySimpleFormLayout>
		<Card>
			<CardContent>
				<Typography variant="title">
					<Title record={record}/>
				</Typography>
			</CardContent>
			<CardChild>
				<MySimpleFormLayout record={record}>
					<MyTextField label="fields.id" source="id" disabled />
					<MyTextField label="fields.year" source="year" isRequired />
					<MyTextField label="fields.week" source="week" isRequired />
					<MyTextField label="fields.movieid" source="movieid" isRequired />
					<MyTextField label="fields.priority" source="priority" isRequired />
				</MySimpleFormLayout>
			</CardChild>
		</Card>

		<Card className={classes.card}>
			<CardContent>
				<CardTitleField icon={<AuditedIcon />}>Gestion</CardTitleField>
			</CardContent>
			<CardChild>
				<MySimpleFormLayout record={record}>
					<MyDateField showTime={true} label="fields.createdon" source="createdon" />
					<ContactField label="fields.createdby" source="createdby" />
					<MyDateField showTime={true} label="fields.modifiedon" source="modifiedon" />
					<ContactField label="fields.modifiedby" source="modifiedby" />
				</MySimpleFormLayout>
			</CardChild>
		</Card>
	</MySimpleFormLayout>
))

export default CardShow
