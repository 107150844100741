import React, { Fragment, Component } from 'react'
//import { DatePicker, TimePicker } from '@material-ui/pickers'
import { MuiPickersUtilsProvider, DatePicker,TimePicker    } from '@material-ui/pickers' // KeyboardTimePicker,	KeyboardDatePicker
import MomentUtils from '@date-io/moment'
import sanitizeRestProps from '../inputs/sanitizeRestProps'
import moment from 'moment'
import AccessTimeIcon from '@material-ui/icons/AccessTime'
import { createTheme, ThemeProvider,withStyles  } from '@material-ui/core'

const styles = ({
	customInputClass: {
		width: '100px',
		marginRight: '5px'
	},
	customLabelStyle: {
		//width: 'max-content',
		transform: 'translate(0, 1.5px) scale(0.75)',
		transformOrigin: 'top left',
		color:'#288DB1',
		"&$disabled": {
			"color": "#288DB1"
		},
		"&$focused": {
			"color": "#288DB1"
		},
		//marginLeft: '-2em'
	},
})
moment.locale('fr')
const materialTheme = createTheme ({
	overrides: {
		MuiPickersModal: {
			dialogRoot:{
				backgroundColor: 'white',
				color: "black",
			}
		},
		MuiPickersToolbar:{
			toolbar : {
				backgroundColor: '#288DB1'
			}
		},
		MuiPickersDay: {
			daySelected : {
				backgroundColor:	'#288DB1'
			}
		},
		MuiButton:{
			textPrimary:{
				color: '#288DB1'
			}
		},
		MuiInputBase: {
			root: {
				//color: 'white',
				"&$disabled": {
					"color": "black"
				},
			}},
		MuiFormLabel:{
			root:{
				"&$focused": {
					"color": "#288DB1"
				},
			}
		},
		MuiFormControl:{
			marginNormal:{
				marginLeft:'25px'
			}}
		
		},
})
/* eslint-disable no-unused-vars */
const dateFormatter = (v, t) => {
	if (!(v instanceof Date) || isNaN(v)) return
	const pad = '00'
	const yyyy = v.getFullYear().toString()
	const MM = (v.getMonth() + 1).toString()
	const dd = v.getDate().toString()
	const HH = v.getHours().toString()
	const mm = v.getMinutes().toString()
	const ss = v.getSeconds().toString()
	return `${yyyy}-${(pad + MM).slice(-2)}-${(pad + dd).slice(-2)} ${(pad + HH).slice(-2)}:${(pad + mm).slice(-2)}:${(pad + ss).slice(-2)}`
}


const sanitizeValue = (value, type) => {
	// null, undefined and empty string values should not go through dateFormatter
	// otherwise, it returns undefined and will make the input an uncontrolled one.
	if (value == null || value === '') {
		return ''
	}

	const finalValue = typeof value instanceof Date ? value : new Date(value)
	return dateFormatter(finalValue, type)
}

class NewDatePicker extends Component {
	dateOnly = true

	constructor(props) {
		super(props)
		this.state = {
			starttime: [],
			endtime: [],
			value : null
		}
	}

	handleDateChange  = (dateType) => event => {
		console.log(event)
		if (event) {
			let dateEvent = event.format('YYYY-MM-DDTHH:mm:ss')
			// let currentTime = this.dateOnly ? 'T00:00:00' : moment().format('THH:mm:ss')
			// this.props.input.onChange(dateEvent)
 			if (dateType === 'end') {
				if (this.state.endtime.length !== 0) {
					dateEvent = event.set('hour',this.state.endtime[0]).set('minute',this.state.endtime[1]).set('second',59).format('YYYY-MM-DDTHH:mm:ss')
				} else {
					dateEvent = event.set('hour',23).set('minute',59).set('second',59).format('YYYY-MM-DDTHH:mm:ss')
				}
				this.setState({value: dateEvent}) 
				this.props.handleDateChange(new Date(dateEvent))
				//this.props.input.onChange(dateEvent)
			}
			else if(dateType === 'start') {
				if (this.state.starttime.length !== 0) {
					dateEvent = event.set('hour',this.state.starttime[0]).set('minute',this.state.starttime[1]).format('YYYY-MM-DDTHH:mm:ss')
				}
				else{
					dateEvent = event.set('hour',0).set('minute',0).set('second',0).format('YYYY-MM-DDTHH:mm:ss')
				}
				this.setState({value: dateEvent}) 
				this.props.handleDateChange(new Date(dateEvent))
				//this.props.input.onChange(dateEvent)
			} else {
				
				this.setState({[dateType] : [event.hours(), event.minutes()]}, () => {
					// console.log(this.state)
				})
				if (dateType === 'end') {
					dateEvent = event.set('second',59).format('YYYY-MM-DDTHH:mm:ss')
				}
				this.setState({value: dateEvent}) 
				this.props.handleDateChange(new Date(dateEvent))
				//this.props.input.onChange(dateEvent)
			}
			return dateEvent
		}
		else {
			this.setState({value: null}) 
			this.props.handleDateChange(null)
			//this.props.input.onChange(null)
		}
	}
	componentDidUpdate =(previousProps)=>{
		
		const {input, dateType} = this.props
		if(input !== previousProps.input)
		this.setState({value : sanitizeValue(input, dateType) })
	}
	render() {
		const {
			className,
			// isRequired,
			// meta,
			 input,
			 label,
			 options,
			// resource,
			// source,
			 dateOnly,
			// shrink,
			 classes,
			// withCustomLabelStyle,
			 dateType,
			error,
			
			...rest
		} = this.props
		this.dateOnly = dateOnly
		// const { touched, error } = meta
		//this.setState({value : sanitizeValue(input, dateType) })
		const {value} = this.state 

		let localStyle, formatedLabel
		if (dateType === 'start') {
			formatedLabel = 'Début ' + label
			localStyle = { width: '140px', marginLeft: '15px', marginTop: '15px', color:'#288DB1' }

		} else {
			formatedLabel = 'Fin ' + label
			localStyle = { width: '140px', marginLeft: '15px', marginTop: '15px', color:'#288DB1' }
		}
		if (value && value !== '') {
			return (

				<ThemeProvider  theme={materialTheme}>
				<MuiPickersUtilsProvider
					utils={MomentUtils}
					moment={moment}
					locale="fr"
				>
					<Fragment>
						<DatePicker
							{...input}
							className={className}
							styles={{marginLeft:'20px'}}
							//margin="normal"
							//keyboard
							autoOk={false}
							ampm={false}
							//variant="outlined"
							error={error}
							helperText={error ? 'Date invalide' : ''}
							// label={!shrink ?
							// 	<FieldTitle
							// 		label={formatedLabel}
							// 		source={source}
							// 		resource={resource}
							// 		isRequired={isRequired}
							// 	/> :
							// 	null
							// }
							label={formatedLabel}
							inputVariant= "outlined"
							format="DD/MM/YYYY"
							placeholder="JJ/MM/AAAA"
							// handle clearing outside => pass plain array if you are not controlling value outside
							mask={value => (value && value !== '' ? [/\d/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/] : [])}
							// mask={() => ([])}
							clearable
							//disableOpenOnEnter
							animateYearScrolling={false}
							value={value}
							onChange={this.handleDateChange(dateType)}
							onBlur={this.onBlur}
							// InputLabelProps={withCustomLabelStyle ? {
							// 	classes: {
							// 		shrink: classes.customLabelStyle
							// 	},
							// 	//style: { paddingLeft: '45px' }
							// } : {}}
							InputLabelProps={{
								shrink: true,
								classes: {shrink: classes.customLabelStyle}
							  }}
							// InputProps={{
							// 	disabled: true
							// }}
							clearLabel="Vider"
							cancelLabel="annuler"
							style={localStyle}
							{...options}
							{...sanitizeRestProps(rest)}
						/>
						<TimePicker
							{...input}
							className={className}
							margin="normal"
							keyboard
							autoOk={false}
							ampm={false}
							//variant="outlined"
							inputVariant= "outlined"
							// error={!!(touched && error)}
							// helperText={touched && error}
							// label={!shrink ?
							// 	<FieldTitle
							// 		label={label}
							// 		source={source}
							// 		resource={resource}
							// 		isRequired={isRequired}
							// 	/> :
							// 	null
							// }
							format="HH:mm:ss"
							placeholder="HH:MM:SS"
							// handle clearing outside => pass plain array if you are not controlling value outside
							mask={value => (value && value !== '' ? [/\d/, /\d/, ':', /\d/, /\d/, ':', /\d/, /\d/] : [])}
							// mask={() => ([])}
							clearable
							disableOpenOnEnter
							animateYearScrolling={false}
							value={value}
							onChange={this.handleDateChange(dateType+'time')}
							onBlur={this.onBlur}
							// InputLabelProps={withCustomLabelStyle ? {
							// 	classes: {
							// 		shrink: classes.customLabelStyle
							// 	}
							// } : {}}
							InputLabelProps={{
								shrink: true,
								classes: {shrink: classes.customLabelStyle}
							  }}
							// InputProps={{
							// 	disabled: true
							// }}
							clearLabel="Vider"
							cancelLabel="annuler"
							style={{ width: '120px', marginLeft: '15px'  }}
							keyboardIcon={<AccessTimeIcon />}
							{...options}
							{...sanitizeRestProps(rest)}
						/>
					</Fragment>
				</MuiPickersUtilsProvider>
				</ThemeProvider>
			)
		}
		else {
			return (
				<ThemeProvider  theme={materialTheme}>
				<MuiPickersUtilsProvider
					utils={MomentUtils}
					moment={moment}
					locale="fr"
				>
					<Fragment>
						<DatePicker
							{...input}
							className={className}
							styles={{marginLeft:'20px'}}
							//margin="normal"
							keyboard
							clearLabel="Vider"
							cancelLabel="annuler"
							autoOk={false}
							ampm={false}
							inputVariant= "outlined"
							//variant="outlined"
							// error={!!(touched && error)}
							// helperText={touched && error}
							label={formatedLabel}
							// label={!shrink ?
							// 	<FieldTitle
							// 		label={formatedLabel}
							// 		source={source}
							// 		resource={resource}
							// 		isRequired={isRequired}
							// 	/> :
							// 	null
							// }
							format="DD/MM/YYYY"
							placeholder="JJ/MM/AAAA"
							// handle clearing outside => pass plain array if you are not controlling value outside
							//mask={value => (value && value !== '' ? [/\d/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/] : [])}
							mask={() => ([])}
							clearable
							disableOpenOnEnter
							animateYearScrolling={false}
							value={null}
							onChange={this.handleDateChange(dateType)}
							onBlur={this.onBlur}
							// InputLabelProps={withCustomLabelStyle ? {
							// 	classes: {
							// 		shrink: classes.customLabelStyle
							// 	},
							// 	//style: { paddingLeft: '45px' }
							// } : {}}
							InputLabelProps={{
								shrink: true,
								classes: {shrink: classes.customLabelStyle}
							  }}
							style={localStyle}
							{...options}
							{...sanitizeRestProps(rest)}
						/>
						<TimePicker
							{...input}
							className={className}
							margin="normal"
							keyboard
							clearLabel="Vider"
							cancelLabel="annuler"
							autoOk={false}
							ampm={false}
							inputVariant= "outlined"
							//variant="outlined"
							// error={!!(touched && error)}
							// helperText={touched && error}
							// label={!shrink ?
							// 	<FieldTitle
							// 		label={label}
							// 		source={source}
							// 		resource={resource}
							// 		isRequired={isRequired}
							// 	/> :
							// 	null
							// }
							format="HH:mm:ss"
							placeholder="HH:MM:SS"
							// handle clearing outside => pass plain array if you are not controlling value outside
							// mask={value => (value && value !== '' ? [/\d/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/] : [])}
							mask={() => ([])}
							clearable
							disableOpenOnEnter
							animateYearScrolling={false}
							value={null}
							onChange={this.handleDateChange(dateType+'time')}
							onBlur={this.onBlur}
							// InputLabelProps={withCustomLabelStyle ? {
							// 	classes: {shrink: classes.customLabelStyle}
							// } : {}}
							InputLabelProps={{
								shrink: true,
								classes: {shrink: classes.customLabelStyle}
							  }}
							style={{ width: '120px', marginLeft: '15px'  }}
							keyboardIcon={<AccessTimeIcon />}
							{...options}
							{...sanitizeRestProps(rest)}
						/>

					</Fragment>
				</MuiPickersUtilsProvider>
				</ThemeProvider>
			)
		}
	}
}



 

export default withStyles(styles)(NewDatePicker)
