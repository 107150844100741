import React, { Component } from 'react'
import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import { initialize, reset } from 'redux-form'
import { connect } from 'react-redux'
import compose from 'recompose/compose'
import { SimpleForm, TextInput, translate, fetchEnd, fetchStart, showNotification, GET_LIST } from 'react-admin'
import { crudGetList as crudGetListAction, crudGetOne as crudGetOneAction } from 'ra-core/lib/actions/dataActions'
import {  Tooltip } from '@material-ui/core'
import { ProgramType } from '../../constants/types'
import Grid from '@material-ui/core/Grid'
import dataProvider from '../../dataProvider'
import { DateFormatter } from '../../tools'
import { onSelectContent, onChangeContentFilter, onPricePlanningFirstLoad } from '../../actions/pricePlanning'
import CheckContentInput from './fields/CheckContentInput'
import { REDUX_FORM_PRICEPLANNING_LOAD } from '../../constants/reduxFormName'
import SwitchObjectType from './fields/SwitchObjectType'
import { withStyles  } from '@material-ui/core/styles'

const styles = theme =>  ({
	root:{
		flexGrow: 1,
		'&$disabled':{
			color:'white'
		},
		width: '100%'
	},
	paper: {
		padding: theme.spacing(2),
		textAlign: 'center',
		color: theme.palette.text.secondary,
	},
	cardContent: {
		flex: '1 auto',
		marginTop :'-1%',
		width: 'fit-content'
	},
	secondaryHeading: {
		fontSize: 'small',
		fontWeight: 'bold',
		color: theme.palette.text.secondary,
	},
	button: {
		margin:  theme.spacing(1),
		backgroundColor : 'rgb(28, 98, 123)'
	},
	rightIcon: {
		marginLeft:  theme.spacing(1),
	},
	groupeDateField: {
		display: 'flex',
		justifyContent: 'space-between',
		margin: '0 auto',
		width: '100%'
	},
	customDateField: {
		display: 'flex',
		color: 'white',
		width: '50%'
	},
	gridForm: {
		minWidth: '1350px',
		width: 'calc(100% + 24px)'
	},
	contractDateField : {
		width: '65%',
	},
	albumInformationField :{
		marginTop : '5px',
		width: '68%',
		"&disabled": {
			color: "red"
		}
	},
	searchContainer : {
		marginBottom : '0%'
	}
})

const CustomDateField = props => {
	const { hasMultipleContractDate, source, label, translate, classes } = props
	const multipleStartDateMessage = translate('messages.multipleStartDate')
	if(hasMultipleContractDate){
		return(
			<Tooltip title={multipleStartDateMessage}>
				<TextInput 
					InputProps = {{ 
						classes : {
							formControl : classes.contractDateField
						}}
					}
					label={label} source={source} disabled {...props}/>
			</Tooltip>
		)
	}
	else{
		return(
			<TextInput 
				InputProps = {{ 
					classes : {
						formControl : classes.contractDateField
					}}
				}
				label={label} source={source} disabled {...props}/>
		)
	}
}

class PricePlanificationFilter extends Component{
	constructor(props) {
		super(props)
		this.state = {
			isVODhasMultipleContractDate : false,
			isESThasMultipleContractDate : false
		}
	}

	componentDidMount(){
		const { onPricePlanningFirstLoad } = this.props
		onPricePlanningFirstLoad()
	}
	
	handleQuickSearchResult = (suggestion) => {
		const { crudGetList, onSelectContent, fetchEnd, fetchStart, showNotification, crudGetOne, objectTypeId } = this.props
		fetchStart()
		dataProvider(GET_LIST, 'PricePlanning/Price', { pagination: { page: 1 , perPage: 10 }, sort: { field: {}, order: {} }, filter: {objectId : suggestion.id, objectTypeId : objectTypeId} }).then((json)=>{
			if(json.data && json.data.length !== 0){
				const [ first ] = json.data
				onSelectContent(first.pricingClassId, suggestion.id, json.data) // set the default price classification id and the content id
				crudGetList(
					'Pricingslists', // resource
					{}, // pagination
					{}, // sort
					{ pricingid: first.pricingClassId } //filter
				)
				crudGetOne(
					'PricePlanning/TransfersRights', // resource
					`${suggestion.id}_${objectTypeId}`
				)
			}
		})
			.catch((error) => {
				console.log('Get Content Price Error: '+ error.message)
				showNotification('messages.getAlbumPriceListError', 'warning', { messageArgs : { autoHideDuration : '6000' }})
			})
			.finally(()=>{
				fetchEnd()
			})
	}

	handleParentComponentChange = () => {
		const { onChangeContentFilter } = this.props
		onChangeContentFilter()
	}

	formDefaultValues = () => { 
		const { contentPriceLists } = this.props
		let defaultValues = {
			objectId : '',
			name : '',
			dateStartVOD : '',
			dateStartEST : '',
			dateEndVOD  :'',
			dateEndEST : '',
			classificationId : ''
		}
		var priceList = Object.values(contentPriceLists)
		if(priceList){
			priceList.forEach( item => {
				if (!defaultValues.name){
					defaultValues.name = item.name
					defaultValues.objectId = item.objectId
				}
				if ('VOD' === item.modeCommercialization){
					const startDate = item.startDate ? DateFormatter.getDateLocale(item.startDate) : ''
					const endDate = item.endDate ? DateFormatter.getDateLocale(item.endDate) : ''
					defaultValues.dateStartVOD = startDate
					defaultValues.dateEndVOD = endDate
					this.setState({ isVODhasMultipleContractDate : item.hasMultipleContractDate})
				}
				else {
					const startDate = item.startDate ? DateFormatter.getDateLocale(item.startDate) : ''
					const endDate = item.endDate ? DateFormatter.getDateLocale(item.endDate) : ''
					defaultValues.dateStartEST = startDate
					defaultValues.dateEndEST = endDate
					this.setState({ isESThasMultipleContractDate : item.hasMultipleContractDate})
				}
			})
		}
		return defaultValues
	}

	render(){
		// eslint-disable-next-line react/prop-types
		const { classes, translate, objectTypeId } = this.props
		const searchContentPlaceholder = translate('fields.searchContentPlaceHolder')
		const modes = objectTypeId === 1 ? [ProgramType.MOVIE] : [ProgramType.ALBUM]
		return(
			<Card className={classes.root}>
				<CardContent className = {classes.cardContent} >
					<SimpleForm
						form={REDUX_FORM_PRICEPLANNING_LOAD}
						onSubmit={this.handleSubmit}
						toolbar={null}
						defaultValue={this.formDefaultValues}
						enableReinitialize= {true}
					>
						<Grid container spacing={3} className = {classes.gridForm}>
							<Grid item xs={12} sm={6}>
								<div className = {classes.searchContainer}>
									<CheckContentInput label = "Identifiant" source = "objectId" modes={modes} placeholder = {searchContentPlaceholder}/>
									<SwitchObjectType />
								</div>
								<br/>
								<TextInput className = {classes.albumInformationField}
									label="fields.title" source="name" disabled/>
							</Grid>

							<Grid item xs={12} sm={6} >
								<div className={classes.groupeDateField}>
									<CustomDateField classes = { classes } source="dateStartVOD" label="fields.contractPeriodStartDateVOD" className={classes.customDateField} hasMultipleContractDate={this.state.isVODhasMultipleContractDate} translate={translate}/>
									<CustomDateField classes = { classes } source="dateEndVOD" label="fields.contractPeriodEndDateVOD" className={classes.customDateField} hasMultipleContractDate={false} translate={translate} /> <br/>
								</div>
								<div className={classes.groupeDateField}>
									<CustomDateField classes = { classes } source="dateStartEST" label="fields.contractPeriodStartDateEST" className={classes.customDateField} hasMultipleContractDate={this.state.isESThasMultipleContractDate} translate={translate}/>
									<CustomDateField classes = { classes } source="dateEndEST" label="fields.contractPeriodEndDateEST" className={classes.customDateField} hasMultipleContractDate={false} translate={translate} /> <br/>
								</div>
							</Grid>
						</Grid>
					</SimpleForm>
				</CardContent>
			</Card>
		)
	}
}

const mapStateToProps = state => ({
	...state,
	contentPriceLists: state.reducer.pricePlanning.contentPriceLists,
	objectTypeId: state.reducer.pricePlanning.objectTypeId
})

const mapDispatchToProps = {
	initialize,
	reset,
	fetchEnd,
	fetchStart,
	crudGetList: crudGetListAction,
	crudGetOne: crudGetOneAction,
	showNotification,
	onSelectContent: onSelectContent,
	onChangeContentFilter: onChangeContentFilter,
	onPricePlanningFirstLoad: onPricePlanningFirstLoad
}

const enhance = compose(
	connect(mapStateToProps, mapDispatchToProps),
	withStyles(styles)
)


export default translate(enhance(PricePlanificationFilter))