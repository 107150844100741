import React from 'react'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import WatchLaterIcon from '@material-ui/icons/WatchLater'
import { Contacts } from '../../../tools'
import { CustomTextField } from '../../layout/GlobalStyles'
import { DateFormatter } from '../../../tools'
 

const titleStyle = {
	marginBottom: '2px',
	marginLeft: '.5em',
	color: "#000"
}

const gridStyle = {
	marginBottom: '1px',
	display: 'flex',
    flexDirection: 'column'
}
export default function GestionFields (props) {

	const { record } = props

	return (
		<div>
			<Grid container direction="row"  style={{marginBottom: '15px'}}>
				<WatchLaterIcon style={{marginTop: '3px', color: "#000"}} />
				<Typography variant="h6" style={ titleStyle }>Gestion</Typography>
			</Grid>
			<Grid container direction="column">
				<Grid item xs style={ gridStyle }>
					<CustomTextField label="Créé par" value={Contacts.getFormattedLabel(record ? record.createdby: '')} fullWidth={true}/>
				</Grid>
				<Grid item xs style={ gridStyle }>
					<CustomTextField label="Créé le" value={DateFormatter.getDayNameDateHourMinSec(record ? record.createdon : '')} fullWidth={true}/>
				</Grid>
				<Grid item xs style={ gridStyle }>
				</Grid>
				<Grid item xs style={ gridStyle }>
					<CustomTextField label="Modifié par" value={Contacts.getFormattedLabel(record ? record.modifiedby : '')} fullWidth={true}/>
				</Grid>
				<Grid item xs style={ gridStyle }>
					<CustomTextField label="Modifié le" value={DateFormatter.getDayNameDateHourMinSec(record? record.modifiedon:'')} fullWidth={true}/>
				</Grid>
			</Grid>
		</div>
	)
}