import React from 'react'
import { DateFormatter } from '../../../tools'
import CustomFooter from './../../layout/MuiCustomFooter'
import MUIDataTable from 'mui-datatables'
import { createTheme, withStyles, ThemeProvider } from '@material-ui/core/styles'
import { Contacts } from '../../../tools'
import compose from 'recompose/compose'
import { Links } from '../../../tools'
import { Link } from 'react-router-dom'
import { connect } from 'react-redux'
import { onLoadSelectedConfig } from '../../../actions/ConfigurationManager'

const styles = (theme) =>  ({
	detailContainer: {
		margin: "1em 2em 2em 2em",
		paddingBottom: "2em"
	},
	divider: {
		margin: "0 0 2em 0",
		border: "1px solid rgb(40, 141, 177, 0.5)",
		borderRadius: "5px"
	},
	linkStyle: {
		color: "#000",
		textDecoration: "none" 
	},
})

const getMuiTheme = () => createTheme({
	overrides: {
		MuiPaper: {
			root: {
				backgroundColor: '#FFF',
			}
		},
		MUIDataTable: {
			root: {
				backgroundColor: '#AAF',
			},
			paper: {
				boxShadow: 'none',
				width: '100%',
			}
		},
		MuiTableCell: {
			root: {
				textAlign: 'left',
				lineHeight: '1em'
			},
		},
		MUIDataTableBodyCell: {
			root: {
				//backgroundColor: '#FFF',
				padding: '0px',
				boxShadow: 'inset 0 1px 0 gey, inset 0 2px 0 white',
				lineHeight: '2em',
				fontSize: '0.8125rem',
			}
		},

		MUIDataTableHeadCell: {
			data: {
				color: '#0000008a',
				fontWeight: 'bold',
				fontSize: '0.825em',
				lineHeight: '1.2em'
			},

			root: {
				backgroundColor: '#FFF',
				padding: '1px',
				//paddingLeft: '20px',// '2px 1px 2px 20px',
				'&:nth-child(1)': {
					width: '3%',
				},
				//position: '-webkit-sticky',
				position: 'sticky',
				top: '0',
				zIndex: '0',
				color: '#0000008a',
				fontWeight: 'bold',
				fontSize: '0.825em',

			}
		},
		MuiDialog: {
			paperWidthSm: {
				minWidth: '400px',
				maxWidth: '800px',
				backgroundColor: '#fff'// '#232c31'
			}
		},
		MUIDataTableToolbar: {
			actions: {
				position: 'sticky',
				right: '0px',
				bottom: '0px',
				flex: 'unset',
			}
		},
		MuiBadge: {
			badge: {
				fontSize: '0.7rem',
			},
			colorSecondary: {
				backgroundColor: '#288db1'
			}
		},
		MUIDataTableFilterList: {
			chip: {
			  display: 'none'
			}
		},
		MUIDataTableFilter: {
			root: {
				width: '13em'
			}
		}
	},
})


const ConfigurationManagerTable = (props) => {
	const { classes, data, title, serviceType,   onLoadSelectedConfig } = props
	
	const [selectedValue, setSelectedValue] = React.useState()

	const columns = [
		{
			name: 'configid',
			label: 'ID',
			options: {
				setCellHeaderProps: () => ({ style: { fontWeight: 'bold', color: '#000', paddingLeft: '30px' } }),
				filter: false,
				setCellProps: () => ({ style: { paddingLeft: '30px' } }),
				customBodyRender: (value, metadata) => {
					return <Link to={Links.ConfigurationManagerShow(metadata.rowData[0])} className={classes.linkStyle}>
						{metadata.rowData[0]}
					</Link>
				}
			}
		},
		{
			name: 'servername',
			label: 'SERVER NAME',
			options: {
				setCellHeaderProps: () => ({ style: { fontWeight: 'bold', color: '#000', paddingLeft: '30px' } }),
				filter: false,
				setCellProps: () => ({ style: { paddingLeft: '30px'} }),
				customBodyRender: (value, metadata) => {
					return (
						<div>
							{ value }
						</div>
					)
				}
			}
		},
		{
			name: 'servicename',
			label: 'SERVICE NAME',
			options: {
				setCellHeaderProps: () => ({ style: { fontWeight: 'bold', color: '#000', paddingLeft: '10px' } }),
				filter: true,
				setCellProps: () => ({ style: { paddingLeft: '10px' } }),
				customBodyRender: (value) => {
					return (
						<div>
							{ value }
						</div>
					)
				},
			}
		},
		{
			name: 'modifiedon',
			label: 'DERNIERE MODIFICATION LE',
			options: {
				setCellHeaderProps: () => ({ style: { fontWeight: 'bold', color: '#000'} }),
				customBodyRender: (value) => {
					return (
						<div>
							{ DateFormatter.getDayNameDateHourMinSec(value) }
						</div>
					)
				},
				filter: false
			}
		},
		{
			name: 'modifiedby',
			label: 'DERNIERE MODIFICATION PAR',
			options: {
				setCellHeaderProps: () => ({ style: { fontWeight: 'bold', color: '#000' } }),
				customBodyRender: (value) => {
					return (
						<div>
							{ Contacts.getFormattedLabel(value) }
						</div>
					)
				},
				filter: false
			}
		},
	]

	const options = {
		responsive: 'scrollMaxHeight',
		fixedHeader: true,
		rowHover: true,
		textLabels: {
			body: {
				noMatch: 'Aucune ligne',
				toolTip: 'Trier'
			},
			filter: {
				all: 'Toutes',
				title: 'FILTRES',
				reset: 'REINITIALISER',
			},
			selectedRows: {
				text: 'rows has been deleted',
				delete: 'Delete Row',
				deleteAria: 'Deleted Selected Rows'
			},
			toolbar: {
				search: 'Rechercher',
				downloadCsv: 'Télécharger CSV',
				print: 'Imprimer',
				viewColumns: 'Voir colonnes',
				filterTable: 'Filtrer',
			},
			viewColumns: {
				title: 'Montrer colonnes',
				titleAria: 'Montrer/Cacher colonnes',
			},
		},
		setTableProps: () => {
			return {
				padding: 'none',
				size: 'medium',
			}
		},
		selectableRows: 'none',
		onRowClick: (rowData, rowMeta) => {
			setSelectedValue(data[rowMeta.rowIndex])
			onLoadSelectedConfig(data[rowMeta.rowIndex], serviceType)
		},
		resizableColumns: false,
		print: false,
		download: false,
		rowsPerPage: 10,
		customFooter: (count, page, rowsPerPage, changeRowsPerPage, changePage, textLabels) => {
			textLabels.rowsPerPage = 'Lignes par page'
			return (
				<CustomFooter
					count={count}
					page={page}
					rowsPerPage={rowsPerPage}
					changeRowsPerPage={changeRowsPerPage}
					changePage={changePage}
					textLabels={textLabels} />
			)
		},
		customToolbar: () => {
			return (
				<>
				{/* <Tooltip title="Créer une promotion">
					<IconButton size="small" component={Link} to={Links.PromotionCreate()}>
						<AddIcon />
					</IconButton>
				</Tooltip>
				 */}
				</>
			)
		}
	}

	return (
		<ThemeProvider theme={getMuiTheme()}>

			<div style={{ width: "100%" }}>
				<MUIDataTable title={"Liste " + title} data={data} columns={columns} options={options} />
			
			{/* { selectedValue !== undefined ? 
				<>
					<hr class="solid" className={classes.divider}/>
					<div className={classes.detailContainer}>
						<ConfigurationManagerDetails data={selectedValue} serviceType={serviceType} />
					</div>
				</>
				: null
			} */}
			</div>
		</ThemeProvider>
	)
}

const mapStateToProps = state => ({
	...state,
	configurationSelected: state.reducer ? state.reducer.configurationManager.payload : null,
})

const mapDispatchToProps = ({
	onLoadSelectedConfig
})

const enhance = compose(
	connect(mapStateToProps, mapDispatchToProps),
	withStyles(styles)
)

export default enhance(ConfigurationManagerTable)

