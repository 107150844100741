import React from 'react'
import { NumberField } from 'react-admin'
import classnames from 'classnames'
import { getValue } from '../../../tools/utils'
import { withStyles } from '@material-ui/core'

const coloredStyles =   ({
	error: { color: 'red' },
})

const NumberFieldError = props => {
	const { reference, sign, source, classes } = props
	const value = getValue(props, source)

	return (
		<NumberField
			className={classnames({
				[classes.error]: '+' === sign && value > reference,
				[classes.error]: '-' === sign && value < reference,
			})}
			{...props} />
	)
}

export default withStyles(coloredStyles)(NumberFieldError)
