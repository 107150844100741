/* eslint-disable indent */
import React, { Component } from 'react'
import {  showNotification, translate } from 'react-admin'
import StoryModal from './StoryModal'
import { connect } from 'react-redux'
import compose from 'recompose/compose'
import IconButton from '@material-ui/core/IconButton'
import DeleteIcon from '@material-ui/icons/Delete'
import { withStyles } from '@material-ui/core/styles'

const styles = theme =>  ({
	validateButton: {
		// margin: theme.spacing(1),
		width : '10%',
		color : 'green',
		padding: '0px'
	}
})

class ValidateDelete extends Component{
	constructor(props) {
		super(props)
		this.state = {
			modalOpen : false
		}
	}

	handleValidateButtonClick = () => {
		this.setState({modalOpen: true})
	}

	handleClose = () => {
		this.setState({modalOpen: false})
	}
	componentDidUpdate(previousProps, previousState) {
		if (previousProps.movieId !== this.props.movieId) {
			//const { movieId } = this.props
			this.setState({ modalOpen : false }) 
		}
	}
	render(){
		const { movieId,  translate, movieTitle, validationType } = this.props
		const modalContent = {
			title : translate('titles.importPlanning'),
			//description : translate('messages.importPlanningInstruction'),
			disagreeButtonText : translate('ra.action.close'),
			agreeButtonText: translate('ra.action.confirm')
		}
		return(
			<React.Fragment>
				<IconButton onClick={this.handleValidateButtonClick} color = "black" variant="contained" aria-label="validate update" component="span">
					<DeleteIcon />
				</IconButton>
				<StoryModal {...this.props}
					modalOpen = {this.state.modalOpen} 
					onClose={this.handleClose} 
					modalContent = {modalContent} 
					launchActivationProcess = { this.handleSubmit } 
					rowId = {movieId}
					movieTitle = {movieTitle}
					validationType = {validationType}
					/>
			</React.Fragment>
		)
	}
}

const mapStateToProps = state => ({
	...state,
	weekNumber: state.reducer.publicationTracking.weekNumber !==null ? state.reducer.publicationTracking.weekNumber : 16,
	yearNumber: state.reducer.publicationTracking.yearNumber !==null ? state.reducer.publicationTracking.yearNumber : 2020
})

const mapDispatchToProps = ({
	showNotification,
})

const enhance = compose(
	connect(mapStateToProps, mapDispatchToProps),
	withStyles(styles)
)

export default translate(enhance(ValidateDelete))