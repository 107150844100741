import React, { cloneElement } from 'react'
import { CardActions, CreateButton, ExportButton } from 'react-admin'
import { sanitizeListRestProps } from 'ra-core'

const MyListActions = ({
	bulkActions,
	currentSort,
	className,
	resource,
	filters,
	displayedFilters,
	exporter,
	filterValues,
	hasCreate,
	basePath,
	selectedIds,
	onUnselectItems,
	showFilter,
	total,
	...rest
}) =>
	(
		<CardActions className={className} {...sanitizeListRestProps(rest)}>
			{bulkActions &&
				cloneElement(bulkActions, {
					basePath,
					filterValues,
					resource,
					selectedIds,
					onUnselectItems,
				})}
			{filters &&
				cloneElement(filters, {
					resource,
					showFilter,
					displayedFilters,
					filterValues,
					context: 'button',
				})}
			{hasCreate && <CreateButton basePath={basePath} />}
			{exporter !== false && (
				<ExportButton
					disabled={total === 0}
					resource={resource}
					sort={currentSort}
					filter={filterValues}
					exporter={exporter}
				/>
			)}
		</CardActions>
	)

export default MyListActions