import React from 'react'
import PropTypes from 'prop-types'
import Image from '../Image'
import { ImageList,ImageListItem } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'


const styles = theme => ({
	main: {
	},
	root: {
		//display: 'flex',
		flexWrap: 'wrap',
		//justifyContent: 'space-around',
		overflow: 'hidden',
		// TODO : use palette instead of fixed color
		backgroundColor: '#f4f5f6',
		//opacity: 0.99,
		filter: 'brightness(0.7)'
	},
	gridList: {
		width: '100%',
		//height: '100%',
	},
	gridListTile: {
		// width:'10%',
		// imgFullWidth: '10%',
		// imgFullHeight: '20%',
	},
})

function ImageGridList(props) {
	const { classes, list } = props
	if (!list)
		return null

	return (
		<div className={classes.root}>
			<ImageList rowHeight='10%' className={classes.gridList} cols={10}>
				{Object.values(list).map(item => (
					<ImageListItem key={item} className={classes.gridListTile} style={{width:'12.5%',height:'25%'}} cols={1}>
						<Image url={item} width ={200} height={250}/>
					</ImageListItem>
				))}
			</ImageList>
		</div>
	)
}

ImageGridList.propTypes = {
	classes: PropTypes.object.isRequired,
	list: PropTypes.array,
}

export default withStyles(styles)(ImageGridList)