import React, { cloneElement, Component } from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import Labeled from 'ra-ui-materialui/lib/input/Labeled'
import compose from 'recompose/compose'
import { connect } from 'react-redux'
import { withStyles } from '@material-ui/core/styles'

const styles =   ({
	root: { display: 'flex', flexWrap: 'wrap' },
})

const parser = new DOMParser()

class ListField extends Component {
	render() {
		const {
			ids,
			data,
			className,
			classes,
			basePath,
			children,
			resource,
			idList,
			xml = false,
			showHide = false,
		} = this.props

		return (
			<div
				className={classnames(classes.root, className)}
			>
				{ids.map((id, index) =>
					<div key={index}>
						{
							xml !== true ?
								(
									((data[id].id === idList && showHide === true) || showHide === false) ?
										(<div key={data[id].id} id={data[id].id}>
											{React.Children.map(
												children,
												field =>
													field && (
														<div
															key={field.props.source}
															className={classnames(
																'ra-field',
																`ra-field-${field.props.source}`,
																field.props.className
															)}
														>
															{field.props.addLabel ? (
																<Labeled
																	label={field.props.label}
																	source={field.props.source}
																	record={data[id]}
																>
																	{field}
																</Labeled>
															) : typeof field.type === 'string' ? (
																field
															) : (
																cloneElement(field, {
																	key: id,
																	record: data[id],
																	resource,
																	basePath,
																})
															)}
														</div>
													)
											)}
										</div>) : null


								) : (

									Object.values(parser.parseFromString(data[id].filmography, 'text/xml').getElementsByTagName('MOVIE')).map(item => {
										return (
											((item.attributes[0].nodeValue === idList && showHide === true) || showHide === false) ?
												(<div key={item.attributes[0].nodeValue} id={item.attributes[0].nodeValue}>
													{React.Children.map(
														children,
														field =>
															field && (
																<div
																	key={field.props.source}
																	className={classnames(
																		'ra-field',
																		`ra-field-${field.props.source}`,
																		field.props.className
																	)}
																>
																	{field.props.addLabel ? (
																		<Labeled
																			label={field.props.label}
																			source={field.props.source}
																			record={data[id]}
																		>
																			{field}
																		</Labeled>
																	) : typeof field.type === 'string' ? (
																		field
																	) : (
																		cloneElement(field, {
																			key: id,
																			record: data[id],
																			resource,
																			basePath,
																		})
																	)}
																</div>
															)
													)}
												</div>) : null
										)
									})
								)

						}
					</div>
				)}
			</div>
		)
	}
}

ListField.propTypes = {
	ids: PropTypes.array,
	data: PropTypes.object,
	className: PropTypes.string,
	classes: PropTypes.object.isRequired,
	basePath: PropTypes.string,
	children: PropTypes.node.isRequired,
	resource: PropTypes.string,
	idList: PropTypes.array,
	xml: PropTypes.bool,
	showHide: PropTypes.bool
}

const mapStateToProps = (state) => ({ idList: state.reducer.showListFieldReducer })

const enhance = compose(
	connect(
		mapStateToProps,
		null
	),
	withStyles(styles)
)

export default enhance(ListField)
