import React from 'react'
import PropTypes from 'prop-types'
import get from 'lodash/get'
import { Link } from 'react-router-dom'
import { translate } from 'ra-core'
import TextField from '@material-ui/core/TextField'
import IconButton from '@material-ui/core/IconButton'
import { getNameProduct } from '../../../tools/utils'
import { Ratings, Profiles, Companies, Contacts, Services, Transfers, DomainsItems, Links, Domains } from '../../../tools'
import { DomainIcon, LinkIcon } from '../../../constants/icons'
import { withStyles } from '@material-ui/core'

const styles =    ({
	rootButton: {
		width: '33px',
		height: '33px',
		marginLeft: '8px',
	},
	rootIcon: {
		fontSize: 24,
	},
})

const ExtendedTextField = ({
	className,
	source,
	record = {},
	domain = false,
	domainid = null,
	profileid = false,
	ratingid = false,
	companyid = false,
	contactid = false,
	serviceid = false,
	transferid = false,
	averagetargetage = false,
	pack = false,
	listonly = false,
	style = {},
	nameproduct,
	translate,
	classes,
}) => {
	let textfield, link
	const value = get(record, source)

	if (averagetargetage) {
		if (value === 0)
			textfield = 'Non défini'
		else
			textfield = value
	}
	else if (pack) {
		if (value === 0)
			textfield = 'Non'
		else
			textfield = value
	}
	else if (nameproduct === 0 || nameproduct === 1) {
		if (value !== undefined && source === 'name') {
			if (nameproduct === 0) {
				textfield = getNameProduct(value, nameproduct)
			}
			if (nameproduct === 1) {
				textfield = getNameProduct(value, nameproduct)
			}
		}
	}
	else if (value && domainid ) {
		textfield = DomainsItems.getFormattedLabel(domainid, value)
		link = <IconButton component={Link} title={translate('labels.show')} to={Links.DomainsitemsShow(domainid, value)} className={classes.rootButton} >
			<DomainIcon className={classes.rootIcon} />
		</IconButton>
	}
	else if (domain)
		textfield = Domains.getFormattedLabel(value)
	else if (ratingid)
		textfield = Ratings.getFormattedLabel(value)
	else if (profileid)
		textfield = Profiles.getFormattedLabel(value)
	else if (companyid)
		textfield = Companies.getFormattedLabel(value)
	else if (contactid)
		textfield = Contacts.getFormattedLabel(value)
	else if (serviceid)
		textfield = Services.getFormattedLabel(value)
	else if (transferid) {
		textfield = Transfers.getFormattedLabel(value)
		link = <IconButton component={Link} title={translate('labels.show')} to={Links.CompaniestransfersShow(value)} className={classes.rootButton} >
			<LinkIcon className={classes.rootIcon} />
		</IconButton>
	}

	if (!textfield)
		textfield = ''

	return (
		<div style={style}>
			{
				listonly ?
					<span className={className}>{textfield}</span> :
					<TextField value={textfield} className={className} disabled />
			}
			{link}
		</div>
	)
}

ExtendedTextField.propTypes = {
	className: PropTypes.object,
	classes: PropTypes.object.isRequired,
	style: PropTypes.object,
	source: PropTypes.string,
	record: PropTypes.object,
	domain: PropTypes.bool,
	domainid: PropTypes.number,
	profileid: PropTypes.bool,
	ratingid: PropTypes.bool,
	companyid: PropTypes.bool,
	contactid: PropTypes.bool,
	serviceid: PropTypes.bool,
	transferid: PropTypes.bool,
	averagetargetage: PropTypes.bool,
	listonly: PropTypes.bool,
	pack: PropTypes.bool,
	nameproduct: PropTypes.number,
	translate: PropTypes.func,
}

const PureExtendedTextField = withStyles(styles)(ExtendedTextField)

PureExtendedTextField.defaultProps = {
	addLabel: true,
}

export default translate(PureExtendedTextField)
