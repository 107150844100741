import CacheItems from './CacheItems'
import { PricingsLists as request } from '../requests'

class PricingsLists extends CacheItems {
	constructor(cacheKey = 'dataPricingsLists') {
		super(cacheKey, request.getAllWithChilds, false)
	}

	static get instance() {
		if (!this.singleton) {
			this.singleton = new PricingsLists()
			this.singleton.logger.debug(`${this.__proto__.name} > new instance`)
		}

		return this.singleton
	}

	// --------------------
	// Custom methods below
	// --------------------

	// Get one pricing list
	getItem = (pricingid, pricinglineid) => {
		if (pricingid) {
			const data = this.getAllItems()
			if (null != data && data.length > 0) {
				return Object.values(data).find(item => item.pricingid === pricingid && item.pricinglineid === pricinglineid)
			}
		}
		return null
	}

	// Get items by pricingid
	getItems = (pricingid) => {
		if (pricingid) {
			const data = this.getAllItems()
			if (null != data && data.length > 0) {
				return Object.values(data).where(item => item.pricingid === pricingid)
			}
		}
		return []
	}
}

const PricingsListsInstance = PricingsLists.instance

export default PricingsListsInstance
