import React, { Component } from 'react'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import { IconButton } from '@material-ui/core'
import classnames from 'classnames'
import { ExpandMoreIcon } from '../../constants/icons'
import { PricingToolPeriodGrid } from '.'
import { withStyles } from '@material-ui/core/styles'

const styles = theme => ({
	expand: {
		transform: 'rotate(0deg)',
		marginLeft: 'auto',
		transition: theme.transitions.create('transform', {
			duration: theme.transitions.duration.shortest,
		}),
	},
	expandOpen: {
		transform: 'rotate(180deg)',
	},
})

const CustomTableHead = withStyles(theme => ({
	head: {
		backgroundColor: theme.palette.common.black,
		color: theme.palette.common.white,
		fontSize: 14,
		paddingTop: 2,
		paddingRight: 4,
		paddingBottom: 2,
		paddingLeft: 4,
		textAlign: 'center',
	},
}))(TableCell)

const CustomTableCell = withStyles(theme => ({
	root: {
		backgroundColor: theme.palette.common.white,
		color: theme.palette.common.black,
		fontSize: 12,
		paddingTop: 2,
		paddingRight: 4,
		paddingBottom: 2,
		paddingLeft: 4,
		border: '1px solid',
		borderColor: theme.palette.common.black,
		textAlign: 'center',
	},
	head: {
		backgroundColor: theme.palette.background.default,
		color: theme.palette.common.white,
		fontSize: 14,
		paddingTop: 2,
		paddingRight: 4,
		paddingBottom: 2,
		paddingLeft: 4,
		border: '1px solid',
		borderColor: theme.palette.common.black,
		textAlign: 'left',
	}
}))(TableCell)

class PricingToolMovieGrid extends Component {
	state = { expanded: true }

	MapMovie(movie) {
		// eslint-disable-next-line react/prop-types
		const { classes } = this.props
		return (
			<Table key={`table_movie_${movie.id}`}>
				<TableHead>
					<TableRow>
						<CustomTableHead></CustomTableHead>
						<CustomTableHead>MID</CustomTableHead>
						<CustomTableHead>VOD/EST</CustomTableHead>
						<CustomTableHead>CIBLE</CustomTableHead>
						<CustomTableHead>Période</CustomTableHead>
						<CustomTableHead>VOD SD</CustomTableHead>
						<CustomTableHead>VOD HD</CustomTableHead>
						<CustomTableHead>VOD 4K</CustomTableHead>
						<CustomTableHead>EST SD</CustomTableHead>
						<CustomTableHead>EST HD</CustomTableHead>
						<CustomTableHead>EST 4K</CustomTableHead>
						<CustomTableHead>HD DVD</CustomTableHead>
						<CustomTableHead>4K DVD</CustomTableHead>
						<CustomTableHead>HD BR</CustomTableHead>
						<CustomTableHead>4K BR</CustomTableHead>
					</TableRow>
				</TableHead>

				<TableBody>
					<TableRow>
						<CustomTableCell variant="head">
							<IconButton
								className={classnames(classes.expand, {
									[classes.expandOpen]: this.state.expanded,
								})}
								onClick={this.handleExpandClick}
								aria-expanded={this.state.expanded}
								aria-label="+"
							>
								<ExpandMoreIcon />
							</IconButton>
						</CustomTableCell>
						<CustomTableCell variant="head">{movie.id}</CustomTableCell>
						<CustomTableCell variant="head" colSpan={13}>{movie.title}</CustomTableCell>
					</TableRow>
					{
						this.state.expanded ?
							movie.periods.map(period => {
								return <PricingToolPeriodGrid key={`PricingToolPeriodGrid_${movie.id}_${period.startDate}_${period.endDate}`} id={movie.id} period={period} />
							}) :
							null
					}
				</TableBody>
			</Table>
		)
	}

	handleExpandClick = () => {
		this.setState(state => ({ expanded: !state.expanded }))
	}

	render() {
		// eslint-disable-next-line react/prop-types
		const { movie, isLoading } = this.props

		if (!movie || isLoading)
			return null

		return this.MapMovie(movie)
	}
}

export default withStyles(styles)(PricingToolMovieGrid)

