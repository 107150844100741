import React, { Component } from 'react'
import Button from '@material-ui/core/Button';
import SnackbarContent from '@material-ui/core/SnackbarContent'
import { connect } from 'react-redux'
import { resetPreview } from '../../../actions/pricePlanning'
import compose from 'recompose/compose'
import { UndoIcon } from '../../../constants/icons'
import deepOrange from '@material-ui/core/colors/deepOrange'
import { withStyles } from '@material-ui/core/styles'

const styles = theme => ({
	root: {
		maxWidth: 600,
		display : 'inline-block'
	},
	snackbar: {
		margin: theme.spacing(1),
	},
	buttonCancel: {
		margin: theme.spacing(1),
		width : '150px',
		background: deepOrange[500],
	},
	leftIcon: {
		marginRight: theme.spacing(1),
	},
})

class TablePreviewTitle extends Component {
	handleClick = () => {
		const { resetPreview } = this.props
		console.log('reinit')
		resetPreview()
	}

	action = (message, classes) => {
		return(
			<Button color="secondary" size="small" onClick = {this.handleClick} variant="contained" className={classes.buttonCancel}>
				<UndoIcon className={classes.leftIcon} />
				{message}
			</Button>
		)
	}

	render(){
		const { classes, message, buttonText } = this.props
		return (
			<div className={classes.root}>
			  <SnackbarContent
				className={classes.snackbar}
				message={message}
				action={this.action(buttonText, classes)}
			  />
			</div>
		  )
	}
}

const mapDispatchToProps = ({
	resetPreview
})

const enhance = compose(
	connect(null, mapDispatchToProps),
	withStyles(styles)
)

export default enhance(TablePreviewTitle)