import React from 'react'
import Switch from '@material-ui/core/Switch'
import Typography from '@material-ui/core/Typography'
import { translate } from 'react-admin'
import compose from 'recompose/compose'
import { changeContentType, onExpandUpdateForm, onChangeContentFilter } from '../../../actions/pricePlanning'
import { connect } from 'react-redux'
import { reset, change  } from 'redux-form'
import { REDUX_FORM_PRICEPLANNING_LOAD, REDUX_FORM_PRICEPLANNING_UPDATE } from '../../../constants/reduxFormName'
import {  withStyles } from '@material-ui/core'

const styles = theme => ({
	switchContainer : {
		display : 'inline-block',
		marginTop : '2%'
	},
	programText : {
		float: 'left',
		marginTop: '10%',
		display: 'block'
	},
	packText : {
		float: 'right',
		marginTop: '10%',
		display: 'block'
	},
	switchRoot : {
		width: '62px',
		display: 'inline-flex',
		position: 'relative',
		flexShrink: '0',
		verticalAlign: 'middle',
		marginTop: '6%'
	}
})

const CustomSwitch = withStyles({
	switchBase: {
	//marginBottom:'11px',
	  color: '#89cfe8',
	  '&$checked': {
		color: '#288DB1',
	  },
	  '&$checked + $track': {
		backgroundColor: '#288DB1',
	  },
	},
	checked: {},
	track: {},
  })(Switch);
  
const SwitchObjectType = props => {
	const { classes, objectTypeId, changeContentType, onExpandUpdateForm, onChangeContentFilter, reset, translate, change, contentSelected } = props
	const switchValue = objectTypeId === 1 ? 'checkedA' : 'checkedB' 
	const checked = objectTypeId === 1 ? true : false

	const handleChange = name => event => {
		onExpandUpdateForm(false)
		onChangeContentFilter()
		if(contentSelected !== null){
			change(REDUX_FORM_PRICEPLANNING_LOAD, 'objectId', '')
		}
		change(REDUX_FORM_PRICEPLANNING_LOAD, 'name', '')
		change(REDUX_FORM_PRICEPLANNING_LOAD, 'dateStartVOD', '')
		change(REDUX_FORM_PRICEPLANNING_LOAD, 'dateEndVOD', '')
		change(REDUX_FORM_PRICEPLANNING_LOAD, 'dateStartEST', '')
		change(REDUX_FORM_PRICEPLANNING_LOAD, 'dateEndEST', '')
		reset(REDUX_FORM_PRICEPLANNING_UPDATE)
		changeContentType(name === 'checkedA' ? 2 : 1)
	}

	return (
		<div className = {classes.switchContainer}>
			<Typography  className = {classes.programText}>
				{translate('fields.ispack')}
			</Typography>
			<CustomSwitch
				checked={checked}
				onChange={handleChange(switchValue)}
				value= {switchValue}
				inputProps={{ 'aria-label': 'secondary checkbox' }}
				classes = {{
					root : classes.switchRoot
				}}
			/>
			<Typography  className = {classes.packText}>
				{translate('fields.isprogram')}
			</Typography>
		</div>
	)
}

const mapStateToProps = state => ({
	...state,
	objectTypeId: state.reducer.pricePlanning.objectTypeId,
	contentSelected : state.reducer.pricePlanning.contentSelected
})

const mapDispatchToProps = {
	changeContentType,
	onExpandUpdateForm,
	onChangeContentFilter,
	reset, 
	change
}

const enhance = compose(
	connect(mapStateToProps, mapDispatchToProps),
	withStyles(styles)
)

export default translate(enhance(SwitchObjectType))