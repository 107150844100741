import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-admin'
import AvatarComplexField from './AvatarComplexField'
import { Url, Links } from '../../../tools'
import { getId, getValue } from '../../../tools/utils'
import { withStyles } from '@material-ui/core/styles'

const styles = {
	
}

const PeopleField = props => {
	const { sourceId, sourceFirstName, sourceLastName, addId, isLink } = props

	const id = getId(props, sourceId)
	const firstname = getValue(props, sourceFirstName)
	const lastname = getValue(props, sourceLastName)

	if (!id && !firstname && !lastname)
		return null

	let text = `${firstname ? firstname : ''} ${lastname ? lastname : ''}`.trim()
	if (addId)
		text += ` (${id})`

	const field = <AvatarComplexField
		src={Url.avatarPeople(id)}
		sourceId={sourceId}
		text={text ? text : ''}
		{...props} />

	return (
		<Fragment>
			{
				isLink ?
					<Link to={Links.CompaniespeoplesShow(id)}>
						{field}
					</Link>
					:
					field
			}
		</Fragment>
	)
}

PeopleField.propTypes = {
	sourceId: PropTypes.string,
	sourceFirstName: PropTypes.string,
	sourceLastName: PropTypes.string,
	addId: PropTypes.bool,
	addLabel: PropTypes.bool,
	isLink: PropTypes.bool,
	addAvatar: PropTypes.bool,
	classes: PropTypes.object.isRequired,
}

PeopleField.defaultProps = {
	sourceId: 'id',
	sourceFirstName: 'firstname',
	sourceLastName: 'lastname',
	addId: false,
	addLabel: true,
	isLink: false,
	addAvatar: false,
}

export default withStyles(styles)(PeopleField)
