import { translate } from 'react-admin'
import React, { Component ,PureComponent,useContext } from 'react'
import { connect } from 'react-redux'
import compose from 'recompose/compose'
import IconButton from '@material-ui/core/IconButton'
import Tooltip from '@material-ui/core/Tooltip'
import { withStyles } from '@material-ui/core/styles'
import FavoriteIcon from '@material-ui/icons/Favorite'
import FavoriteBorderIcon from '@material-ui/icons/FavoriteBorder'
import ContactsFavorites from '../../../requests/ContactsFavorites'
import { Logger } from '../../../tools'
import { onLoadAlbums } from '../../../actions/companiesAlbums'
import { onPromoLoadList } from '../../../actions/Promotion'
import { onLoadfavorite } from '../../../actions/ContactFavorite'
import { ConfigContext } from '../../layout/ConfigProvider'

const styles = theme => ({
	// customSelect: {
	// 	width: '100%',
	// 	textAlign: 'left'
	// },

})
class FavoriteItem extends PureComponent {
	
	constructor(props) {
		super(props)
		this.state = {
			contactFav:[]
		}
	}
	
	static contextType = ConfigContext
	// componentDidMount() {
	// 	const { onLoadfavorite,objectId,contactFavorite } = this.props
	// 	if( contactFavorite.length===0)
	// 	{
	// 		ContactsFavorites.getAll(objectId).then((fav) => {
	// 				if (fav.data)
	// 				onLoadfavorite(fav.data)
	// 		})}

	// }
	// shouldComponentUpdate(props, state) {
	// 	console.log(state)
	// 	return (props.objectId !== this.props.objectId || this.props.contactFavorite !== props.contactFavorite) 
	// }
	searchfunction = () => {
		const { critere, searchValue ,onLoadAlbums,contactFavorite,AlbumsData} = this.props
		const val = searchValue  
		this.setState({ isLoading: true })
		let res = []
		if (AlbumsData && val !== '')
		{
			switch (critere) {
				case 1: {
					res = AlbumsData.filter(x => x.name.toUpperCase().includes(val.toUpperCase()))
				}
					break
				case 2: {
					res = AlbumsData.filter(x => x.id.toString() === val)
				}
					break
				case 3: {
					// this.getAlbumsByMovieId(val)
					// res = this.state.albumMovie
				}
					break
				case 4: {
					res = AlbumsData.filter(x => x.bddpId.includes(val))
				}
					break
				default:
					break
			}
		}
		}
	// loadObjectData = () =>{
	// 	const {  objectId, refId,favStatus, onLoadAlbums, onPromoLoadList, AlbumsData, PromotionListSearch  } = this.props

	// 	}
	// 	if (contactFavorite) {
	// 		contactFavorite.forEach((item) =>
	// 		{
	// 			if( res && res.find(x => x.id === item.refId) === undefined )
	// 				res.push(AlbumsData.find(x => x.id === item.refId))
	// 		})
	// 	}
		
	// 	// this.setState({ data: this.convertDataToTable(res).sort((a, b) => (a.id < b.id) ? 1 : -1).sort((a, b) => (a.favorite < b.favorite) ? 1 : -1)
	// 	// })
	// 	onLoadAlbums(res.sort((a, b) => (a.id < b.id) ? 1 : -1).sort((a, b) => (a.favorite < b.favorite) ? 1 : -1))
	// }
	loadObjectData = () => {
		const {  objectId, refId,favStatus, onLoadAlbums, onPromoLoadList, AlbumsData, PromotionListSearch   } = this.props
		switch (objectId) {
			case 1:
				break
			case 2:

				if (favStatus === 0) {
					AlbumsData.forEach(element => {
						if (element.id === refId) element.favorite = 1
					});
					onLoadAlbums(AlbumsData.sort((a, b) => (a.id < b.id) ? 1 : -1).sort((a, b) => (a.favorite < b.favorite) ? 1 : -1))
				}
				if (favStatus === 1) {
					AlbumsData.forEach(element => {
						if (element.id === refId) element.favorite = 0
					});
					onLoadAlbums(AlbumsData.sort((a, b) => (a.id < b.id) ? 1 : -1).sort((a, b) => (a.favorite < b.favorite) ? 1 : -1))
				}
				this.searchfunction()
				break
			case 6: 
				if(favStatus === 0 ) {
					PromotionListSearch.forEach(element => {
						if(element.id === refId) 
							element.favorite =  1
					})
					onPromoLoadList(PromotionListSearch.sort((a, b) => (a.id > b.id) ? 1 : -1).sort((a, b) => (a.favorite < b.favorite) ? 1 : -1))	
				}
		
				if(favStatus === 1 ) 
					onPromoLoadList(PromotionListSearch.filter(x=>x.id !== refId).sort((a, b) => (a.id > b.id) ? 1 : -1).sort((a, b) => (a.favorite < b.favorite) ? 1 : -1))
			break
			default:
				break
		}
	}
	handleChange = () => {
		const { favStatus, refId, objectId, onLoadfavorite, ObjectFav,count,contactFavorite } = this.props
		const { config } = this.context 
 		const maxFav = config.maxFavoris
		if (refId) {
			switch (favStatus) {
				case 0:
					let fav = {
						RefId: refId,
						ObjectId: objectId,
					}
					if (count < maxFav) {
						ContactsFavorites.addFavorite(fav).then(
							(json) => {
								ContactsFavorites.getAll(objectId).then((json) => {
									onLoadfavorite(json.data)
								}).then(()=>this.loadObjectData())
								Logger.debug('save ok')
							}

						).catch((error) => {
							Logger.debug(error)
						})
					}
					break
				case 1:
					
					const favId = ObjectFav && ObjectFav.find(x => x.refId == refId && x.objectId == objectId) ? ObjectFav.find(x => x.refId === refId && x.objectId === objectId).favoriteId : 0
					if (fav !== 0){
						ContactsFavorites.removeFavorite(favId)
							.then(() => {
								Logger.debug('favoriteID = ' + favId + ' est supprimé')
								ContactsFavorites.getAll(objectId).then((json) => {
									onLoadfavorite(json.data)
 								}).then(()=>this.loadObjectData())
 
							}

							)
							.catch((error) => {
								ContactsFavorites.getAll(objectId).then((json) => {
									onLoadfavorite(json.data)
									 
								}).then(()=>this.loadObjectData())
								Logger.debug(error)
							})}
					break
				default:
					break
			}
		}
	}

	render() {
		const { favStatus, object, count,AlbumsData, } = this.props
		const { config } = this.context 
 		const maxFav = config.maxFavoris
 		return (
			<React.Fragment>
				{
					favStatus === 0 ?
						<Tooltip  title={count >= maxFav ? 'Vous avez atteint le nombre maximum des favoris' : 'Ajouter ' + object + ' aux favoris'}>
							<IconButton  variant="contained" onClick={this.handleChange}  >
								<FavoriteBorderIcon style={{ color: '#000' }} />
							</IconButton>
						</Tooltip> :
						<Tooltip title={'Supprimer ' + object + ' des favoris'}>
							<IconButton  variant="contained" onClick={this.handleChange}  >
								<FavoriteIcon style={{ color: '#9b1928' }} />
							</IconButton>
						</Tooltip>

				}

			</React.Fragment>
		)

	}
}

const mapStateToProps = state => ({
	...state,
	critere: state.reducer.albums.critere !== null ? state.reducer.albums.critere : 1,
	searchValue: state.reducer.albums.searchValue !== undefined ? state.reducer.albums.searchValue : '',
	AlbumsData: state.reducer.albums.AlbumsData,
	contactFavorite : state.reducer.favorite.contactFavorite ,
	PromotionListSearch: state.reducer.Promotion.PromotionListSearch
})

const mapDispatchToProps = ({
	onLoadAlbums,
	onLoadfavorite,
	onPromoLoadList

})

const enhance = compose(
	connect(mapStateToProps, mapDispatchToProps),
	withStyles(styles)
)

export default translate(enhance(FavoriteItem))
