import CacheItems from './CacheItems'
import { Publicationstracking as request } from '../requests'

class PublicationsTracking extends CacheItems {
	constructor(cacheKey = 'publicationTracking') {
		super(cacheKey, request.getPlanning, false)
	}

	static get instance() {
		if (!this.singleton) {
			this.singleton = new PublicationsTracking()
			this.singleton.logger.debug(`${this.__proto__.name} > new instance`)
		}

		return this.singleton
	}

	// --------------------
	// Custom methods below
	// --------------------

	// Get targets 
	getTargets = () => {
		const data = this.getAllItems()
		return Object.values(data)
	}
}

const PublicationsTrackingInstance = PublicationsTracking.instance

export default PublicationsTrackingInstance