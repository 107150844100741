import React from 'react'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import Slide from '@material-ui/core/Slide'
import PropTypes from 'prop-types'
import { CancelIcon, CheckIcon,CloseIcon } from '../../constants/icons'
import cyan from '@material-ui/core/colors/cyan'
import { withStyles } from '@material-ui/core/styles'
const Transition = React.forwardRef(function Transition(props, ref) {
	return <Slide direction="up" ref={ref} {...props} />
})

const styles = theme =>    ({
	listContentBreak:{
		whiteSpace: 'break-spaces',
	},
	listContentnormal:{
		whiteSpace: 'normal',
	},
	closeIcon:{
        width : '15px',
        position: 'absolute',
        top: '0px',
        right: '0px',
        borderRadius: '0',
        color: 'white',
        '&:hover': {
            background: 'red'
        }
    },
	button: {
		margin: theme.spacing(1),
		width : '150px'
	},
	buttonCancel: {
		margin: theme.spacing(1),
		width : '150px',
		background: '#e23434'
	},
	buttonCheck: {
		margin: theme.spacing(1),
		width : '150px',
		background: '#0c9e0c'
	},
	leftIcon: {
		marginRight: theme.spacing(1),
	},
	dialogHeader:{
        padding: '10px 10px 8px',
        background: cyan[500],
        color: 'white',
        fontSize: 'medium',
        fontWeight: 'bold',
        textAlign: 'center',
	},
	dialogContent: {
		padding: '16px 24px 17px',
		flex: '1 1 auto',
		color: 'black',
		fontSize: '1rem',
		fontWeight: '400',
 	},
	dialogFooter: {
		flex: '0 0 auto',
		margin: '8px 4px',
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'flex-end'
	},
	avatarStatus: {
		width: 15,
		height: 15
	},
	headerCell: {
		fontSize: '1rem',
		fontWeight : 'bold'
	},
	statusFieldCell: {
		width: '10%'
	},
	jobTypeFieldCell: {
		width: '10%'
	},
	propertyFieldCell: {
		width: '5%'
	},
	labelFieldHeaderCell: {
		textAlign: 'center'
	},
	labelFieldCell: {
		width: 'auto',
		textAlign: 'center'
	},
	jobParamsCell: {
		width: '100px'
	},
	activateDispatchServerHeader: {
		textAlign : 'center'
	},
	activateDispatchServerCell: {
		width : '60px'
	},
	root: {
		display: 'flex',
		flexWrap: 'nowrap',
		alignItems: 'center'
	},
})

function AlertDialogSlide(props) {
	const [open, setOpen] = React.useState(false)
	const [enabled, enableAcceptButton] = React.useState(true)
	const { modalOpen, onClose,  modalContent, launchActivationProcess, classes} = props
	React.useEffect(() => {
		setOpenModalFromProps(modalOpen)
	})

	function setOpenModalFromProps(modalOpen){
		setOpen(modalOpen)
	}

	const modalTitle = modalContent.title
	const modalDescription = modalContent.description
	const disagreeButtonText = modalContent.disagreeButtonText
	const agreeButtonText = modalContent.agreeButtonText
	return (
		<div>
			<Dialog
				open={open}
				TransitionComponent={Transition}
				keepMounted
				onClose={onClose}
				aria-labelledby="alert-dialog-slide-title"
				aria-describedby="alert-dialog-slide-description"
			>
				<div className={classes.dialogHeader}>
					{modalTitle}
					<Button onClick={onClose}  className={classes.closeIcon} >
						<CloseIcon/>				 
					</Button>
				</div>
				<div className={classes.dialogContent}>
					{modalDescription}
				</div>
				<div className={classes.dialogFooter}>
					<Button onClick={() => {enableAcceptButton(false); launchActivationProcess(props)} } color="primary" variant="contained" className={classes.buttonCheck} disabled = {!open}>
						<CheckIcon className={classes.leftIcon} />
						{agreeButtonText}
					</Button>
					<Button onClick={onClose} color="secondary" variant="contained" className={classes.buttonCancel}>
						<CancelIcon className={classes.leftIcon} />
						{disagreeButtonText}
					</Button>
				</div>
			</Dialog>
		</div>
	)
}

AlertDialogSlide.propTypes = {
	modalOpen: PropTypes.bool,
	onClose: PropTypes.func,
	modalContent: PropTypes.object,
	launchActivationProcess: PropTypes.func,
	classes: PropTypes.object.isRequired
}

export default withStyles(styles)(AlertDialogSlide)
