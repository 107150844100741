import React, { Component } from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import { translate } from 'react-admin'
import compose from 'recompose/compose'
import Card from '@material-ui/core/Card'
import CardHeader from '@material-ui/core/CardHeader'
import CardContent from '@material-ui/core/CardContent'
import IconButton from '@material-ui/core/IconButton'
import Collapse from '@material-ui/core/Collapse'
import CardActions from '@material-ui/core/CardActions'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'
import { AvatarCustom, Progress } from '../common'
import { DateFormatter } from '../../tools'
import { FolderIcon, WatchLaterIcon, ImportExportIcon, PriorityHighIcon, ErrorIcon, BlockIcon, ExpandMoreIcon } from '../../constants/icons'
import { withStyles } from '@material-ui/core'

class WatcherFiles extends Component {
	state = { expanded: true }

	handleExpandClick = () => {
		this.setState(state => ({ expanded: !state.expanded }))
	}

	handleFileClick = (typeid, stateid, filename, filepath) => () => {
		this.props.callback(typeid, stateid, filename, filepath)
	}

	mapItems = (files) => {
		if (null == files || 0 === files.length) {
			const { translate } = this.props
			return <ListItem><AvatarCustom icon={<BlockIcon />} /><ListItemText primary={translate('labels.noneFiles')} /></ListItem>
		}

		return files.map(file => {
			let icon = <FolderIcon />
			let color
			if (1 === file.stateId) { // Pending
				icon = <WatchLaterIcon />
			}
			else if (2 === file.stateId) { // Progress
				icon = <ImportExportIcon />
				color = 'green'
			}
			else if (3 === file.stateId) { // Top
				icon = <PriorityHighIcon />
				color = 'blue'
			}
			else if (4 === file.stateId) { // Error
				icon = <ErrorIcon />
				color = 'red'
			}

			return (
				<div key={`div_${file.id}`} onClick={this.handleFileClick(file.typeId, file.stateId, file.filename, file.externalPath)}>
					<ListItem key={file.id} button dense>
						<AvatarCustom icon={icon} color={color} />
						<ListItemText primary={file.filename} secondary={DateFormatter.getDateTimeLocale(file.modifiedon)} />
						<ListItemText primary={file.label} secondary={file.externalRef} style={{ textAlign: 'right' }} />
					</ListItem>
				</div>
			)
		})
	}

	render() {
		const { label, files, total, classes, isLoading, translate } = this.props
		console.log(files)

		if (isLoading)
			return <Progress />

		const list = this.mapItems(files)

		const subHeaderDisplayed = files.length > 0 ?
			translate('pluralizations.numFilesDisplayed', { smart_count: files.length }) :
			translate('labels.empty')

		const subHeaderFull = total > 0 ?
			translate('pluralizations.numFilesFull', { smart_count: total }) :
			translate('labels.empty')

		return (
			<Card className={classes.card}>
				<CardHeader
					title={label}
					subheader={files.length > 0 ? `${subHeaderDisplayed} / ${subHeaderFull}` : subHeaderDisplayed}
				/>

				<CardActions className={classes.spacing} disableSpacing>
					<IconButton
						className={classnames(classes.expand, {
							[classes.expandOpen]: this.state.expanded,
						})}
						onClick={this.handleExpandClick}
						aria-expanded={this.state.expanded}
						aria-label="+"
					>
						<ExpandMoreIcon />
					</IconButton>
				</CardActions>

				<Collapse in={this.state.expanded} timeout="auto" unmountOnExit>
					<CardContent>
						<List component='nav' dense>
							{list}
						</List>
					</CardContent>
				</Collapse>
			</Card>
		)
	}
}

const styles = theme => ({
	card: {
		width: '100%',
	},
	spacing: {
		display: 'flex',
		marginTop: '-70px',
	},
	expand: {
		transform: 'rotate(0deg)',
		transition: theme.transitions.create('transform', {
			duration: theme.transitions.duration.shortest,
		}),
		marginLeft: 'auto',
		[theme.breakpoints.up('sm')]: {
			marginRight: -8,
		},
	},
	expandOpen: {
		transform: 'rotate(180deg)',
	}
})

WatcherFiles.propTypes = {
	callback: PropTypes.func,
	label: PropTypes.string,
	files: PropTypes.array,
	total: PropTypes.number,
	classes: PropTypes.object.isRequired,
	isLoading: PropTypes.bool,
	translate: PropTypes.func,
}

const enhance = compose(
	translate,
	withStyles(styles)
)

export default enhance(WatcherFiles)
