import React from 'react'
import { List } from 'react-admin'
import MoviesList from './MoviesList'

const PeoplesmoviesList = props => (
	<List title="titles.peoplesMoviesList" actions={null} {...props} pagination={null}>
		<MoviesList />
	</List>
)

export default PeoplesmoviesList