import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import compose from 'recompose/compose'
import { translate } from 'react-admin'
import { Redirect } from "react-router-dom"

import DescriptionIcon from '@material-ui/icons/Description'
import StraightenIcon from '@material-ui/icons/Straighten'
import Typography from '@material-ui/core/Typography'
import ReplayIcon from '@material-ui/icons/Replay'
import CircularProgress from '@material-ui/core/CircularProgress'

import PropTypes from 'prop-types'
import { PromotionsRecurrence, PromotionsRules, PromotionsGeneral, PromotionsGlobal } from './modules'
import { withStyles } from '@material-ui/core/styles'
import { ON_PROMO_LOAD, ON_RESET_PROMO, ON_PROMO_UPDATE_REPONSE, ON_PROMO_UPDATE, ON_PROMO_SET_CONTROL_FIELD, ON_PROMO_RESET_CONTROL_FIELD, ON_PROMO_SET_SELECTED_TAB } from '../../actions/Promotion'
import { Links } from '../../tools'
import { Promotions } from '../../requests'
import { Logger } from '../../tools'
import { CustomShowBar, CustomUpdateBar, AntTab, AntTabs, CustomAlert, CustomDisplayDialog } from '../layout/GlobalStyles'
import ValidatePromotionFields from './ValidatePromotionFields'
import LocalStorage from '../../tools/LocalStorage'

const styles = theme => ({
	root: {
		flexGrow: 1,
		width: '100%'
	},
	cardPaddingMargin: {
		margin: 20,
		flex: '1 auto'
	},
	circularProgress: {
		position: 'absolute',
		top: '50%',
		left: '50%',
	},
	display: {
		display: 'none'
	},
	container: {
		marginLeft: '-16px',
		marginRight: '-16px',
		marginTop: '-8px'
	},
	list: {
		color: 'red'
	},
	listItem: {
		margin: '.5em'
	}
})

function TabPanel(props) {
	const { children, value, index, ...other } = props;
	return (
		<div
			role="tabpanel"
			hidden={value !== index}
			id={`full-width-tabpanel-${index}`}
			aria-labelledby={`full-width-tab-${index}`}
			{...other}
		>
			{value === index && (
				<div p={3}>
					<Typography>{children}</Typography>
				</div>
			)}
		</div>
	)
}

const onChangeRules = (event, infos) => e => {
	let value

	switch (event) {
		case 'target':
			value = e.substring(0, e.length - 1);
			this.setState({
				[event]: value
			}, () => {
				this.updateState()
			})

			break;

		case 'micro':
			this.setState({ micro: e }, () => {
				this.updateState()
			});
			break;

		case 'diffusion':

			if (e.target.checked) {
				let temp = this.state.diffusion
				temp = temp + parseInt(e.target.name, 10)
				this.setState({ [event]: temp }, () => {
					this.updateState()
				});
			} else {
				let temp = this.state.diffusion
				temp = temp - parseInt(e.target.name, 10)
				this.setState({ [event]: temp }, () => {
					this.updateState()
				});
			}

			break;

		case 'media':
			if (e.target.checked) {
				let temp = this.state.media
				temp = temp + parseInt(e.target.name, 10)
				this.setState({ [event]: temp }, () => {
					this.updateState()
				});
			} else {
				let temp = this.state.media
				temp = temp - parseInt(e.target.name, 10)
				this.setState({ [event]: temp }, () => {
					this.updateState()
				});
			}
			break;

		case 'albums':
			if (e.includes(';')) {
				let splitAlbums = e.split(';')
				let listAlbums = ''
				for (let index = 0; index < splitAlbums.length; index++) {
					if (index !== (splitAlbums.length - 1)) {
						listAlbums += '<AID ID="' + splitAlbums[index] + '" EXCLUDE="0" />';
					}
				}
				this.setState({ albumList: listAlbums }, () => {
					this.updateState()
				})
			} else {
				this.setState({ albumList: '' }, () => {
					this.updateState()
				})
			}
			break
		default:
			break
	}

}

TabPanel.propTypes = {
	children: PropTypes.node,
	index: PropTypes.any.isRequired,
	value: PropTypes.any.isRequired,
}

function setPropsTab(index) {
	return {
		id: `simple-tab-${index}`,
		'aria-controls': `simple-tabpanel-${index}`,
	}
}

const PromotionDetail = (props) => {
	const { classes, record, create, type, onPromotionLoad, promotion, isVoucherExist, returned, promotionApplyAllCatalogue, selectedTabIndex, onPromotionUpdateResponse, onSetControlField, onPromotionUpdate, onResetControlField, onPromoSetSelectedTab } = props
	//const [value, setValue] = useState(0)
	const [closed, setclosed] = useState(record.closed)
	const [isLoading, setIsLoading] = useState(false)
	const [redirect, setRedirect] = useState(false)
	const [show, setShow] = React.useState(true)
	const [showError, setShowError] = React.useState(false)
	const [errorMessage, setErrorMessage] = React.useState('')
	const [openErrorMessage, setOpenErrorMessage] = React.useState(false)

	useEffect(() => {
		// if(type == 'show')
		// 	onResetPromo()


		if (type !== 'create') {
			setIsLoading(true)
			if (record && record.id !== 0) {
				Promotions.getPromotionById(record.id).then((json) => {
					if (json.data !== undefined) {
						onPromotionLoad(json.data)
						setIsLoading(false)
					}
				})
				
			}
		}
	}, onPromotionLoad, Promotions)

	useEffect(() => {
		//Gestion historique
		LocalStorage.history(record.id, '6', record.name, record.typeid)
	})

	const handleChange = (event, newValue) => {
		onPromoSetSelectedTab(newValue)
	}

	const updateAdded = (value) => {
		setclosed({ value })
	}

	const EditActions = () => (
		<CustomUpdateBar to={Links.PromotionShow(promotion.id)} method={savePromotion} />
	)

	const ShowActions = () => (
		<CustomShowBar to={Links.PromotionEdit(promotion.id)} back={Links.PromotionList()} disabledEdit={promotion.closed} editButtonTooltip={promotion.closed ? "Une promotion clôturée ne peut pas être modifiée" : "Passer en mode EDIT"} />
	)

	const CreateActions = () => (
		<CustomUpdateBar to={Links.PromotionList()} method={savePromotion} />
	)

	const createListMessageError = (list) => (
		<ul className={classes.list}>
			{list.map(item => (
				<li className={classes.listItem}>{item}</li>
			))}
		</ul>
	)

	const correctProperties = () => {
		let parser = new DOMParser()
		let xmlDoc = parser.parseFromString(promotion.properties, "text/xml")
		let prom = promotion

		if (promotion.typeid !== 1 && xmlDoc.length > 0) {
			prom.properties = ""
			onPromotionUpdate(prom)
		}
	}
	const correctCurrencyrules = () => {
			let parser = new DOMParser()
			let prom = promotion
			const serializer = new XMLSerializer()
			let xmlDoc = parser.parseFromString(promotion.rules, "text/xml")
			if(xmlDoc.getElementsByTagName("RECURRING")[0].getAttribute("START") == '* * * ? * * *' ){
				xmlDoc.getElementsByTagName("RECURRING")[0].removeAttribute('START')			
			}
			if(xmlDoc.getElementsByTagName("RECURRING")[0].getAttribute("END") == '* * * ? * * *' ){
				xmlDoc.getElementsByTagName("RECURRING")[0].removeAttribute('END')
			}
			if( xmlDoc.getElementsByTagName("RECURRING")[0].getAttribute("INITUSE") ==0 ){
				xmlDoc.getElementsByTagName("RECURRING")[0].removeAttribute('INITUSE')
			}
			prom.rules=  serializer.serializeToString(xmlDoc)
 			onPromotionUpdate(prom)
		
	}

	const savePromotion = () => {
		onResetControlField()
		setIsLoading(true)
		correctProperties()
		correctCurrencyrules()
		let validate = ValidatePromotionFields.validatePromotion(promotion, isVoucherExist, promotionApplyAllCatalogue)
		if (!validate.success) {
			setErrorMessage(createListMessageError(validate.message))
			onSetControlField(validate.errorField)
			setOpenErrorMessage(true)
			setIsLoading(false)
		} else {
			return new Promise((resolve, reject) => {
			console.log(promotion)
				Promotions.savePromotion(promotion).then((data) => {		
					if (data) {
						onPromotionUpdate(data.data)
					} 
					onPromotionUpdateResponse(true)
					resolve()
					setRedirect(true)
					setIsLoading(false)
				}
				).catch((error) => {
					setIsLoading(false)
					Logger.debug(error)
					reject()
					setShowError(true)
					const timeId = setTimeout(() => {
						setShowError(false)
					}, 3000)
					return () => {
						clearTimeout(timeId)
					}
				})
			})
		}
	}

	const handleCloseErrorMessage = () => {
		setOpenErrorMessage(false)
	}

	const handleOpenAlert = () => {
		setShow(false)
		setShowError(false)
		onPromotionUpdateResponse(false)
	}
	return (
		<div className={classes.container}>
			{redirect && promotion && promotion.id !== 0
				? <Redirect to={Links.PromotionShow(promotion.id)} />
				: null
			}
			{show && returned ? (<CustomAlert libelle="Promotion enregistrée" severity="success" open={returned} setOpen={handleOpenAlert} />) : null}
			{showError ? (<CustomAlert libelle="Erreur lors de l'enregistrement" severity="error" open={showError} setOpen={handleOpenAlert} />) : null}

			<AntTabs
				value={selectedTabIndex}
				onChange={handleChange}
				style={{ marginTop: 'auto' }}
				TabIndicatorProps={{
					style: { transition: 'none' }
				}}
				fullWidth
			>
				<AntTab label={<span style={{ margin: ".5em", fontSize: "1.2em" }}>INFORMATIONS GENERALES</span>} {...setPropsTab(0)} wrapped="false" icon={<DescriptionIcon style={{ margin: '0.5em 0 .5em 0.5em' }} />} />
				<AntTab label="REGLES" {...setPropsTab(1)} icon={<StraightenIcon style={{ marginRight: '.5em' }} />} />
				<AntTab label="RECURRENCE" {...setPropsTab(2)} icon={<ReplayIcon style={{ marginRight: '.5em' }} />} />
				{!create ? <ShowActions /> : type === 'create' ? <CreateActions /> : <EditActions />}
			</AntTabs>

			<TabPanel value={selectedTabIndex} index={0} >
				<PromotionsGlobal classes={classes} record={promotion} create={create} type={type} closedProps={closed} handler={updateAdded} />
				<PromotionsGeneral classes={classes} record={promotion} create={create} type={type} />
			</TabPanel>
			<TabPanel value={selectedTabIndex} index={1} >
				<PromotionsGlobal classes={classes} record={promotion} create={create} type={type} closedProps={closed} handler={updateAdded} />
				<PromotionsRules source={promotion} classes={classes} create={create} type={type} />
			</TabPanel>
			<TabPanel value={selectedTabIndex} index={2} >
				<PromotionsGlobal classes={classes} record={promotion} create={create} type={type} closedProps={closed} handler={updateAdded} />
				<PromotionsRecurrence source={promotion} classes={classes} create={create} type={type} handleInputInit={onChangeRules('initUse')} handleInputStart={onChangeRules('recStart')} handleInputEnd={onChangeRules('recEnd')} />
			</TabPanel>
			<CustomDisplayDialog info="ERREURS DE SAISIE" handleClose={handleCloseErrorMessage} open={openErrorMessage} content={errorMessage} width="sm" />
			{isLoading && <div className={classes.circularProgress}><CircularProgress size={50} thickness={2} /></div>}
		</div>
	)
}

const mapStateToProps = state => ({
	...state,
	promotion: state.reducer ? state.reducer.Promotion.payload : null,
	returned: state.reducer.Promotion.returned,
	isVoucherExist: state.reducer ? state.reducer.Promotion.isVoucherExist : null,
	promotionApplyAllCatalogue: state.reducer ? state.reducer.Promotion.promotionApplyAllCatalogue : null,
	selectedTabIndex: state.reducer ? state.reducer.Promotion.selectedTabIndex : null,
})

const mapDispatchToProps = dispatch => ({
	onPromotionLoad: (payload) =>
		dispatch({ type: ON_PROMO_LOAD, payload: payload }),
	onPromotionUpdateResponse: (returned) =>
		dispatch({ type: ON_PROMO_UPDATE_REPONSE, returned: returned }),
	onResetPromo: () =>
		dispatch({ type: ON_RESET_PROMO }),
	onPromotionUpdate: (payload) =>
		dispatch({ type: ON_PROMO_UPDATE, payload: payload }),
	onSetControlField: (controlField) =>
		dispatch({ type: ON_PROMO_SET_CONTROL_FIELD, controlField: controlField }),
	onResetControlField: () =>
		dispatch({ type: ON_PROMO_RESET_CONTROL_FIELD }),
	onPromoSetSelectedTab: (selectedTabIndex) =>
		dispatch({ type: ON_PROMO_SET_SELECTED_TAB, selectedTabIndex: selectedTabIndex }),
})

const enhance = compose(
	connect(mapStateToProps, mapDispatchToProps),
	withStyles(styles)
)

export default translate(enhance(PromotionDetail))

