import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-admin'
import AvatarComplexField from './AvatarComplexField'
import { Url, Links } from '../../../tools'
import { getId } from '../../../tools/utils'
import { withStyles } from '@material-ui/core/styles'

const styles = {
	MuiAvatar:{
		root:{
			borderRadius : 'unset'
		}
	}
}

const MovieField = props => {
	const { sourceId, sourceLabel, isLink } = props
	const id = getId(props, sourceId)

	if (isLink && !id)
		return null
		
	const field = <AvatarComplexField
		src={Url.avatarMovie(id)}
		sourceId={sourceId}
		sourceLabel={sourceLabel}
		{...props} />

	return (
		<Fragment>
			{
				isLink ?
					<Link to={Links.CompaniesmoviesShow(id)}>
						{field}
					</Link>
					:
					field
			}
		</Fragment>
	)
}

MovieField.propTypes = {
	sourceId: PropTypes.string,
	sourceLabel: PropTypes.string,
	addId: PropTypes.bool,
	addLabel: PropTypes.bool,
	isLink: PropTypes.bool,
	addAvatar: PropTypes.bool,
	classes: PropTypes.object.isRequired,

}

MovieField.defaultProps = {
	sourceId: 'id',
	sourceLabel: 'title',
	addId: false,
	addLabel: true,
	isLink: false,
	addAvatar: false,
}

export default withStyles(styles)(MovieField)
