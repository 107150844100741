import React, { Component } from 'react'
import { MenuListComposition } from '../layout'
import { MenuSection } from '../../configurations/MenuSection'
import { Token } from '../../tools'
import { userHasRight } from '../../userrights/validate'

class Menu extends Component {
	userDoesntHaveRights = (currentItem) => {
		const permissions = Token.getUserData()
		return !userHasRight(permissions, currentItem.procid)
	}

	render() {
		const showMenu = MenuSection.map((item, key) => {
			if(item.menunameitem.every(this.userDoesntHaveRights)){
				return <div key={key} ></div>
			}
			return <MenuListComposition key={key} menuname={item.menuname} menunameitem={item.menunameitem} />
		})

		return (
			<div style={{ display: 'flex' }} >
				{showMenu}
			</div>
		)
	}
}

export default Menu 
