export const ON_LOAD_SELECTED_CONFIG = 'ON_LOAD_SELECTED_CONFIG'
export const ON_DISPATCHER_SET_SELECTED_TAB = 'ON_DISPATCHER_SET_SELECTED_TAB'
export const ON_UPDATE_CONFIG = 'ON_UPDATE_CONFIG'

export const onLoadSelectedConfig = (payload, serviceType) => ({
	type: ON_LOAD_SELECTED_CONFIG,
	payload,
	serviceType
})

export const onDispatcherSetSelectedTab = (selectedTabIndex) => ({
	type: ON_DISPATCHER_SET_SELECTED_TAB,
	selectedTabIndex
})

export const onUpdateConfig = (payload, serviceType) => ({
	type: ON_UPDATE_CONFIG,
	payload,
	serviceType
})
