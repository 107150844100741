import React, { Component } from 'react'
import { translate } from 'react-admin'
import { connect } from 'react-redux'
import compose from 'recompose/compose'
import Button from '@material-ui/core/Button'
import Tooltip from '@material-ui/core/Tooltip'
// import ClickAwayListener from '@material-ui/core/ClickAwayListener'
import StatsTooltipTitle from './StatsTooltipTitle'
import GroupIcon from '@material-ui/icons/Group'
import { withStyles } from '@material-ui/core/styles'

const styles = theme =>  ({
	button: {
		position: 'fixed',
		zIndex: '999991',
		top: '60px',
		right: '20px',
		height: '55px',
		width: '55px',
		borderRadius: ' 50%',
		fontSize: '29px',
		backgroundColor: '#288DB1',
		lineHeight: '1.2em',
		marginTop: '0px',
		'&:hover': {
			background: '#1c627b'
		}
	},
	buttonAssign: {
		position: 'fixed',
		zIndex: '999991',
		top: '200px',
		right: '21%',
		height: '50px',
		width: '50px',
		borderRadius: '50%',
		fontSize: '29px',
		backgroundColor: '#288DB1',
		//lineHeight: '1.2em',
		'&:hover': {
			background: '#1c627b'
		}
	},
	muiTooltip: {
		popper: {
			//z-index: 1500;
			opacity: 'unset',
			backgroundColor: '#f5f5f9',
			color: 'rgba(0, 0, 0, 0.87)',
			maxWidth: '950px',
			fontSize: theme.typography.pxToRem(10),
			border: '1px solid #dadde9',
			position: 'absolute',
			top: '0px',
			right: '5px'
		}
	}
})


class PublicationStats extends Component {
	constructor(props) {
		super(props)
		this.state = {
			open: false,
			targetAttr: ''
		}
	}

	handleTooltipClose = () => {
		this.setState({
			open: false
		})
	}

	handleTooltipOpen = (event) => {
		this.setState({
			open: true,
		})
	}

	render() {
		const { translate, classes, stats, type } = this.props
		if (stats.length !== 0) {
			const modalContent = {
				title: '',
				//description : translate('messages.importPlanningInstruction'),
				disagreeButtonText: translate('ra.action.undo'),
				agreeButtonText: translate('ra.action.confirm')
			}
			return (
				<React.Fragment>
					{/* <ClickAwayListener onClickAway={this.handleTooltipClose}> */}
					<Tooltip title='Répartition'>
						<Button onClick={this.handleTooltipOpen} color="primary" variant="contained" className={type === 'planning' ? classes.button : classes.buttonAssign}>
							<GroupIcon className={classes.rightIcon} />
						</Button>
					</Tooltip>
					<StatsTooltipTitle {...this.props}
						modalOpen={this.state.open}
						//targetAttr = {this.state.targetAttr} 
						onClose={this.handleTooltipClose}
						modalContent={modalContent}
						className={classes.muiTooltip} />

					{/* </ClickAwayListener> */}
				</React.Fragment>
			)
			// return(
			// 	<React.Fragment>
			// 		<div >
			// 		< ClickAwayListener onClickAway={this.handleTooltipClose}>
			// 			<HtmlTooltip
			// 				PopperProps={{
			// 					disablePortal: true
			// 				}}
			// 				onClose={this.handleTooltipClose}
			// 				open={this.state.open}
			// 				disableFocusListener
			// 				disableHoverListener
			// 				disableTouchListener
			// 				title={
			// 					<StatsTooltipTitle stats = {stats} />
			// 				}
			// 			>
			// 				<Button onClick={this.handleTooltipOpen} color="primary" variant="contained" className={classes.importButton}>
			// 					<GroupIcon className={classes.rightIcon} />
			// 				</Button>
			// 			</HtmlTooltip>

			// 		</ClickAwayListener>
			// 		</div>
			// 	</React.Fragment>
			// )
		}
		else {
			return (
				<div></div>
			)
		}
	}
}

const mapStateToProps = state => ({
	...state,
	stats: state.reducer.publicationTracking.stats
})

const enhance = compose(
	connect(mapStateToProps, null),
	withStyles(styles, { index: 1 })
)

export default translate(enhance(PublicationStats))