import React, { Component, useEffect, useCallback } from 'react'
import { Link } from 'react-router-dom'
import { DomainsItems } from '../../../tools'

import { connect } from 'react-redux'
import compose from 'recompose/compose'
import { translate } from 'react-admin'
import CircularProgress from '@material-ui/core/CircularProgress'
import { createTheme, withStyles, ThemeProvider } from '@material-ui/core/styles'
import MUIDataTable from 'mui-datatables'
import { FormControl } from '@material-ui/core'
import Promotions from './../../../requests/Promotions'
import { PromotionsType } from '../../../constants/types'
import CustomFooter from '../../../components/publicationtracking/fields/CustomFooter'
import { Links } from '../../../tools'
import { Redirect } from "react-router-dom"
import { AddIcon, LocalActivityIcon, GroupIcon, PublicIcon } from '../../../constants/icons'
import IconButton from '@material-ui/core/IconButton'
import Tooltip from '@material-ui/core/Tooltip'
import { LockIcon, LockOpenIcon } from '../../../constants/icons'
import { ON_RESET_PROMO, ON_PROMO_SET_SEARCH_PARAMS, ON_LOAD_PROMO_LIST } from '../../../actions/Promotion'
import FilterField from '../../common/fields/FilterField'
import { DateFormatter } from '../../../tools'
import Switch from '@material-ui/core/Switch'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Zoom from '@material-ui/core/Zoom'
import FavoriteItem from '../../common/fields/FavoriteItem'
import ContactsFavorites from './../../../requests/ContactsFavorites'
import { Badge } from '@material-ui/core'
import Typography from '@material-ui/core/Typography'
import CustomFilter from '../../publicationtracking/fields/CustomFilter'
import Button from '@material-ui/core/Button'
import TableHead from '@material-ui/core/TableHead'
import TableCell from '@material-ui/core/TableCell'
import { Contacts } from '../../../tools'
import LinearProgress from '@material-ui/core/LinearProgress'
import { CustomAddToListButton } from './../../layout/GlobalStyles'
import { onLoadfavorite } from '../../../actions/ContactFavorite'


const getMuiTheme = () => createTheme({
	overrides: {
		MuiPaper: {
			root: {
				backgroundColor: '#FFF',
			}
		},
		MUIDataTable: {
			root: {
				backgroundColor: '#AAF',
			},
			paper: {
				boxShadow: 'none',
				width: '100%',
			}
		},
		MuiTableCell: {
			root: {
				textAlign: 'left',
				lineHeight: '1em',
			},
		},
		MUIDataTableBodyCell: {
			root: {
				//backgroundColor: '#FFF',
				padding: '0px',
				boxShadow: 'inset 0 1px 0 gey, inset 0 2px 0 white',
				lineHeight: '1em',
				fontSize: '0.8125rem'
			}
		},
		MUIDataTableHeadCell: {
			data: {
				color: '#0000008a',
				fontWeight: 'bold',
				fontSize: '0.825em',
				lineHeight: '1.2em'
			},

			root: {
				backgroundColor: '#FFF',
				padding: '1px',
				//paddingLeft: '20px',// '2px 1px 2px 20px',
				'&:nth-child(1)': {
					width: '3%',
				},
				//position: '-webkit-sticky',
				position: 'sticky',
				top: '0',
				zIndex: '0',
				color: '#0000008a',
				fontWeight: 'bold',
				fontSize: '0.825em',

			}
		},
		MuiDialog: {
			paperWidthSm: {
				minWidth: '400px',
				maxWidth: '800px',
				backgroundColor: '#fff'// '#232c31'
			}
		},
		MUIDataTableToolbar: {
			actions: {
				position: 'sticky',
				right: '0px',
				bottom: '0px',
				flex: 'unset',
			}
		},
		MuiBadge: {
			badge: {
				fontSize: '0.7rem',
			},
			colorSecondary: {
				backgroundColor: '#288db1'
			},
			anchorOriginTopRightRectangle: {
				top: '-3px',
				right: '-6px'
			}
		},
		MUIDataTableFilterList: {
			chip: {
				display: 'none'
			}
		},
		MUIDataTableFilter: {
			root: {
				width: '13em'
			}
		}
	},
})

const styles = (theme) => ({
	root: {
		display: 'flex',
		flexWrap: 'wrap',
		'& > *': {
			margin: theme.spacing(1),
			width: '100%',
			height: theme.spacing(10),
		},
	},
	formControl: {
		margin: theme.spacing(2),
		width: '15%'
	},
	searchButton: {
		color: 'rgba(0, 0, 0, 0.54)',
		margin: theme.spacing(3)
	},
	switchs: {
		right: '0%',
		position: 'absolute',
		marginTop: '.8em',
		width: '20em'
	},
	switch: {
		marginBottom: '.7em'
	},
	spanLabel: {
		fontSize: '.8em'
	},
	linkStyle: {
		color: "#000",
		textDecoration: "none"
	},
	favHeaderStyle: {
		padding: '1px',
		top: '0',
		paddingBottom: '10px',
		zIndex: '1',
		color: '#0000008a',
		fontWeight: 'bold',
		fontSize: '0.78em',
		backgroundColor: '#FFF',
		// borderBottom: '1px solid rgba(224, 224, 224, 1)'
	},
	button: {
		width: 'auto',
		background: '#288DB1',
		color: '#fff',
		position: 'relative',
		right: '1.5em',
		fontSize: '.8em'
	},
	circularProgress: {
		position: 'absolute',
		top: '50%',
		left: '50%',
		zIndex: '10000'
	}
})

const objectId = 6
 
const mode = [
	{ id: 1, name: "Nom de la promotion"},
	{ id: 2, name: "Identifiant de la promotion" }
]
class PromotionsListFilter extends Component {

	constructor(props) {
		super(props)
		this.state = {
			defaultActivePromChecked: false,
			contactFav: [],
			displayAll: false,
			loading: false,
			rowsPerPageState: 20,
			promoType: [],
			AllPromotions: [],
			searchValue: '',
			resultId : ''
		}
	}
	getPromotionsType = () => {
		return Object.values(DomainsItems.getItems(114))
	}
	componentDidMount() {
		const { onResetPromo, contactFavorite,searchPromoListParams,onLoadfavorite } = this.props
		onResetPromo()
		this.setState({ promoType: this.getPromotionsType(),
			searchValue : searchPromoListParams.value })
		// if (contactFavorite && contactFavorite !== [] && contactFavorite.filter(s => s.objectId === objectId).length !== 0) {
		// 	this.setState({ contactFav: contactFavorite })
		// 	this.searchfunction(contactFavorite)
		// } else {
		ContactsFavorites.getAll(objectId).then((fav) => {
				if (fav.data) {
					onLoadfavorite(fav.data)
					this.setState({ contactFav: fav.data })
					this.searchfunction(fav.data)
				}
			})

		// }
	}
	componentDidUpdate =(prevprop,prevstate)=>{
		const {onLoadfavorite ,contactFavorite} = this.props
		if(this.state.contactFav !== prevstate.contactFav ){
			onLoadfavorite(this.state.contactFav)
		}
		if(  contactFavorite !== prevprop.contactFavorite){
			this.setState({ contactFav: contactFavorite })
		}
	}
	getAllPromotions = () => {
		const { searchPromoListParams, onPromoSetSearchParams,contactFavorite } = this.props

		this.setState({
			loading: true,
			displayAll: true,
			searchValue: ''
		})
		Promotions.getAll().then((json) => {
			this.setState({ AllPromotions: json.data })
			this.setDataReturned(json.data, this.state.contactFav)
		})
		const newSearchPromoListParams = searchPromoListParams
		newSearchPromoListParams.value = ""
		onPromoSetSearchParams(newSearchPromoListParams)
	}

	setDataReturned = (dataReturned, favData) => {
		const { onPromoLoadList } = this.props
		let result = []
		if (dataReturned !== undefined) {
			dataReturned.forEach(element => {
				result.push({
					id: element.id,
					name: element.name,
					typeid: element.typeid,
					modifiedon: element.modifiedon,
					modifiedby: element.modifiedby,
					closed: element.closed,
					favorite: favData && favData.find(x => x.refId == element.id) ? 1 : 0
				})
			})

			!this.state.defaultActivePromChecked ?
			result = result.filter(d => d.closed === 0).sort((a, b) => (a.id > b.id) ? 1 : -1).sort((a, b) => (a.favorite < b.favorite) ? 1 : -1):
			result.sort((a, b) => (a.id > b.id) ? 1 : -1).sort((a, b) => (a.favorite < b.favorite) ? 1 : -1)
			if(this.state.resultId !== '') 
				result.push({
					id: this.state.resultId.id,
					name: this.state.resultId.name,
					typeid: this.state.resultId.typeid,
					modifiedon: this.state.resultId.modifiedon,
					modifiedby: this.state.resultId.modifiedby,
					closed: this.state.resultId.closed,
					favorite: favData && favData.find(x => x.refId == this.state.resultId.id) ? 1 : 0
				})
			onPromoLoadList(result)

				// ? onPromoLoadList(result.filter(d => d.closed === 0).sort((a, b) => (a.id > b.id) ? 1 : -1).sort((a, b) => (a.favorite < b.favorite) ? 1 : -1))
				// : onPromoLoadList(result.sort((a, b) => (a.id > b.id) ? 1 : -1).sort((a, b) => (a.favorite < b.favorite) ? 1 : -1))

			this.setState({ loading: false })
		}

	}


	handleSearchButtonClick = () => {
		const { searchPromoListParams, onPromoSetSearchParams,contactFavorite } = this.props
		let newSearchPromoListParams = searchPromoListParams
 		newSearchPromoListParams.value = this.state.searchValue

		onPromoSetSearchParams(newSearchPromoListParams)
		this.searchfunction(this.state.contactFav)
	}
	handleSwitchCloturedChange = () => {
		this.setState({
			loading: true,
			defaultActivePromChecked: !this.state.defaultActivePromChecked
		})
		this.searchfunction(this.state.contactFav)
	}





	searchfunction = (favData) => {
		const { searchPromoListParams,contactFavorite } = this.props
 		const val = searchPromoListParams.value
		const critere = searchPromoListParams.critere
		if(critere === 1 && parseInt(val)){
			Promotions.getPromotion(2,val).then((json) => {
				this.setState({ resultId: json.data[0] }) 
			})
		}

		if (this.state.displayAll && val === '') {
			this.setState({ loading: true })
			Promotions.getAll().then((json) => {
				this.setDataReturned(json.data, favData)
			})
		}  
		else if ((critere === 1 && val.length >= 2) || (critere === 2 && val !=='')) {
			this.setState({ loading: true })
			Promotions.getPromotion(critere, val).then((json) => {
				this.setDataReturned(json.data, favData)
			})
			// switch (critere) {
			// 	case 1: {
			// 		Promotions.getPromotion(2, val).then((json) => {
			// 			this.setDataReturned(json.data, favData)
			// 		})
			// 	}
			// 		break
			// 	case 2: {
			// 		Promotions.getPromotion(1, val).then((json) => {
			// 			this.setDataReturned(json.data, favData)
			// 		})
			// 	}
			// 		break
			// 	default:
			// 		break
			// }
		} else if (favData && favData.length > 0 ||this.state.contactFav.length > 0) {
			this.setState({ loading: true })

			Promotions.getAll().then((json) => {
				const refIds = favData.map(c => c.refId)
				let arr = []
				json.data.forEach(element => {
					if (refIds.includes(element.id))
						arr.push(element)
				})
				this.setDataReturned(arr, favData)
			})
			//setLoading(false)

		}
	}
	getMuiTheme = () => createTheme({
		overrides: {
			MuiBox: {
				root: {
					paddingTop: '5px'
				}
			},
			MuiGrid: {
				container: {
					paddingBottom: '15px'
				}
			},
			MuiTypography: {
				root: {
					paddingLeft: '10px'
				}
			},
			MuiCardContent: {
				root: {
					'&:first-child': {
						paddingTop: 0,
						paddingBottom: 0,
						paddingLeft: 8,
						paddingRight: 8,
					},
				}
			},

			MuiOutlinedInput: {
				multiline: {
					width: '480px'
				},
				input: {
					padding: '10px 14px'
				}
			},
			MuiInputBase: {
				input: {
					padding: '10px 14px'
				}
			}

		},
	})
	handleValueChange = (event, type) => {
		const { searchPromoListParams, onPromoSetSearchParams,contactFavorite } = this.props

		let newSearchPromoListParams = searchPromoListParams

		if (type === 'reset') {
			newSearchPromoListParams.value = ''
			this.setState({
				loading: true,
				searchValue: ''
			})

			this.searchfunction(this.state.contactFav)
			onPromoSetSearchParams(newSearchPromoListParams)

		} else {
			this.setState({ searchValue: event.target.value })
			// if (event.target.value.length >= 2 || searchPromoListParams.critere !== 1) {
			// 	//newSearchPromoListParams.value = event.target.value
			// 	this.setState({ searchValue: event.target.value })

			// } else {
			// 	this.setState({ searchValue: event.target.value })
			// 	//newSearchPromoListParams.value = event.target.value
			// }
			//onPromoSetSearchParams(newSearchPromoListParams)
		}


	}
	handleChange = (event) => {
		const {  searchPromoListParams,   onPromoSetSearchParams,contactFavorite } = this.props
		let newSearchPromoListParams = searchPromoListParams
		newSearchPromoListParams.critere = event.target.value
		onPromoSetSearchParams(newSearchPromoListParams)
		this.searchfunction(this.state.contactFav)
	}

	render() {
		const { classes, searchPromoListParams,   PromotionListSearch,contactFavorite } = this.props
		const columns = [
			{
				name: 'id',
				label: 'ID',
				options: {
					setCellHeaderProps: () => ({ style: { fontWeight: 'bold', color: '#000', paddingLeft: '40px', paddingTop: ".8em" } }),
					filter: false,
					setCellProps: () => ({ style: { paddingLeft: '30px' } }),
					customBodyRender: (value, metadata) => {
						return <Link to={Links.PromotionShow(metadata.rowData[0])} className={classes.linkStyle}>
							{metadata.rowData[0]}
						</Link>
					}
				}
			},
			{
				name: 'name',
				label: 'NOM',
				options: {
					setCellHeaderProps: () => ({ style: { fontWeight: 'bold', color: '#000', paddingLeft: '30px', paddingTop: ".8em" } }),
					filter: false,
					setCellProps: () => ({ style: { paddingLeft: '30px', width: '40em' } }),
					customBodyRender: (value, metadata) => {
						return <Link to={Links.PromotionShow(metadata.rowData[0])} className={classes.linkStyle}>
							{metadata.rowData[1]}
						</Link>
					}
				}
			},
			{
				name: 'typeid',
				label: 'TYPE',
				options: {
					setCellHeaderProps: () => ({ style: { fontWeight: 'bold', color: '#000', paddingLeft: '20px', paddingTop: ".8em" } }),
					filter: true,
					customBodyRender: (value) => {
						let selectMode = this.state.promoType ? this.state.promoType.find(m => m.itemid === value) : []
						return (
							<div style={{ display: "flex", alignItems: "center", alignContent: "space-between" }}>
								{selectMode && selectMode.itemid === 1 ? (<LocalActivityIcon />) : selectMode && selectMode.itemid === 2
									? <PublicIcon /> :selectMode && selectMode.itemid === 3 ? <GroupIcon /> : null
								}
								<div style={{ marginLeft: ".5em" }}>{selectMode ? selectMode.label : ""}</div>
							</div>
						)
					},
					filterOptions: {
						renderValue: v => v === 1 ? 'Voucher' : v === 2 ? 'Global' : v === 3 ? 'Utilisateur' : null,
						fullWidth: true,
					},
				}
			},
			{
				name: 'modifiedon',
				label: 'MODIFIE LE',
				options: {
					setCellHeaderProps: () => ({ style: { fontWeight: 'bold', color: '#000', paddingTop: ".8em" } }),
					customBodyRender: (value) => {
						return (
							<div>
								{DateFormatter.getDayNameDateHourMinSec(value)}
							</div>
						)
					},
					filter: false
				}
			},
			{
				name: 'modifiedby',
				label: 'MODIFIE PAR',
				options: {
					setCellHeaderProps: () => ({ style: { fontWeight: 'bold', color: '#000', paddingTop: ".8em" } }),
					customBodyRender: (value) => {
						return (
							<div>
								{Contacts.getFormattedLabel(value)}
							</div>
						)
					},
					filter: false
				}
			},
			{
				name: 'closed',
				label: 'CLOTUREE',
				options: {
					setCellHeaderProps: () => ({ style: { fontWeight: 'bold', color: '#000', paddingLeft: '10px', paddingTop: ".8em" } }),
					setCellProps: () => ({ style: { paddingLeft: '30px' } }),
					customBodyRender: (value) => {
						return (
							<div>
								{value === 1
									? <Tooltip title="Clôturé">
										<LockIcon style={{ color: 'red' }} />
									</Tooltip>
									: <Tooltip title="Non clôturé">
										<LockOpenIcon style={{ color: '#C4C6CA' }} />
									</Tooltip>
								}
							</div>
						)
					},
					filter: true,
					customFilterListOptions: {
						update: (filterList, filterPos, index) => {
							filterList[index].splice(filterPos, 1);
							return filterList;
						}
					},
					filterOptions: {
						fullWidth: true,
						logic: (location, filters, row) => {
							if (filters.length) return !filters.includes(location);
							return false;
						},
						renderValue: v => v === 0 ? 'Active' : 'Cloturée',
					}
				}
			},
			{
				name: 'favorite',
				label: 'FAVORIS',
				options: {
					filter: true,
					search: true,
					filterType: 'custom',
					customFilterListOptions: {
						render: v => {
							let res = ''
							v.forEach(item => item === 0 ? res += 'Non favoris,' : res += 'Favoris,')
							return `Favoris: ${res}`
						}
					},
					filterOptions: {
						logic: (favorite, filters) => {
							if (filters.length) return !filters.includes(favorite)
							return false
						},
						display: (filterList, onChange, index, column) => {
							let optionsValues = []
							optionsValues.push({ value: 0, label: "Non favoris" })
							optionsValues.push({ value: 1, label: "Favoris" })

							const defaultLabel = 'Favoris'
							return <CustomFilter filterList={filterList} onChange={onChange} index={index} column={column}
								optionsValues={optionsValues} defaultLabel={defaultLabel} />
						}
					},
					customBodyRender: (value, tableMeta) => {
						return <FavoriteItem favStatus={value} object='cet promotion' refId={tableMeta.rowData[0]} isFavoritShow={true}
							objectId={objectId} ObjectFav={this.state.contactFav} count={this.state.contactFav.length} />
					},
					customHeadRender: (columnMeta) => (
						<TableCell style={{ position: "sticky", top: 0, background: "#fff", zIndex: 1000, paddingTop: '.8em', minWidth: '65px' }}>
							<TableHead>
								<Badge badgeContent={this.state.contactFav.length} max={99} color='secondary'>
									<Typography className={classes.favHeaderStyle}>FAVORIS</Typography>
								</Badge>
							</TableHead>
						</TableCell>
					)
				}
			},
		]
		const options = {
			responsive: 'scrollMaxHeight',
			fixedHeader: true,
			responsive: 'scroll',
			rowHover: true,
			textLabels: {
				body: {
					noMatch: PromotionListSearch.length !== 0 ? <LinearProgress /> : 'Aucune ligne trouvée!',
					toolTip: 'Trier'
				},
				filter: {
					all: 'Toutes',
					title: 'FILTRES',
					reset: 'REINITIALISER',
				},
				selectedRows: {
					text: 'rows has been deleted',
					delete: 'Delete Row',
					deleteAria: 'Deleted Selected Rows'
				},
				toolbar: {
					search: 'Rechercher',
					downloadCsv: 'Télécharger CSV',
					print: 'Imprimer',
					viewColumns: 'Voir colonnes',
					filterTable: 'Filtrer',
				},
				viewColumns: {
					title: 'Montrer colonnes',
					titleAria: 'Montrer/Cacher colonnes',
				},
			},
			setTableProps: () => {
				return {
					padding: 'none',
					size: 'medium',
				}
			},
			selectableRows: 'none',
			resizableColumns: false,
			print: false,
			download: false,
			rowsPerPage: this.state.rowsPerPageState,
			customFooter: (count, page, rowsPerPage, changeRowsPerPage, changePage, textLabels) => {
				textLabels.rowsPerPage = 'Lignes par page'
				return (
					<CustomFooter
						count={count}
						page={page}
						rowsPerPage={this.state.rowsPerPageState}
						changeRowsPerPage={changeRowsPerPage}
						changePage={changePage}
						textLabels={textLabels} />
				)
			},
			onChangeRowsPerPage: (numberOfRows: number) => {
				this.setState({ rowsPerPageState: numberOfRows })
			},
			download: true,
			downloadOptions: {
				filename: 'promotion_liste.csv',
				separator: ',',
			},
			customToolbar: () => {
				return (
					<CustomAddToListButton link={Links.PromotionCreate()} title='Créer une promotion' />
					// <Tooltip title="Créer une promotion">
					// 	{/* <IconButton  size="small" component={Link} to={Links.PromotionCreate()}>
					// 		<AddIcon />
					// 	</IconButton> */}
					// 	<Fab  size="small" color="primary" style={{boxShadow: 'none', width : '45px' ,height : '45px',background: '#0c9e0c', marginLeft : '4px'}} component={Link} to={Links.PromotionCreate()}>
					// 		<AddIcon />
					// 	</Fab>
					// </Tooltip>
				)
			}
		}
		const CloturedFilterSwitch = () => (
			<div className={classes.switchs}>
				<FormControl component="fieldset" className={classes.switch}>
					<FormControlLabel
						value="top"
						control={
							<Tooltip title={!this.state.defaultActivePromChecked ? "Afficher les promotions clôturée et actives" : "Ne pas afficher les promotions clôturée"}
								placement="right-start"
								TransitionComponent={Zoom}
							>
								<Switch checked={this.state.defaultActivePromChecked}
									onChange={() => this.handleSwitchCloturedChange()}
									color="primary" size="small"
									inputProps={{ 'aria-label': 'primary checkbox' }}
								/>
							</Tooltip>

						}
						label={<span style={{ fontSize: '.8em', marginLeft: '.5em' }}>Inclure les promotions clôturées ?</span>}
						labelPlacement="right"
					/>
				</FormControl>
				<span>
					<Button variant="contained" disabled={this.state.displayAll} className={classes.button} onClick={() => this.getAllPromotions()}>
						Afficher toutes les promotions
					</Button>
				</span>
			</div>
		)
		return (
			<ThemeProvider theme={getMuiTheme()}>
				{this.state.loading && <div className={classes.circularProgress}><CircularProgress size={50} thickness={2} /></div>}

				<div className={classes.root}>
					<FilterField selectedValue={searchPromoListParams.critere} values={mode} classes={classes} removeicon={true}
						valueChange={(e) => this.handleValueChange(e, '')} selectedChange={event => this.handleChange(event)}
						ButtonClick={() => this.handleSearchButtonClick()} textValue={this.state.searchValue}
						valueReset={event => this.handleValueChange(event, 'reset')}
						switch={<CloturedFilterSwitch />}
					/>
					<div style={{ height: 500, width: "100%" }}>
						<MUIDataTable className='MUIDataTable' stickyHeader title="Liste des promotions" data={PromotionListSearch} columns={columns} options={options} />
					</div>
				</div>
			</ThemeProvider>
		)


	}
}

const mapStateToProps = state => ({
	...state,
	searchPromoListParams: state.reducer ? state.reducer.Promotion.searchPromoListParams : null,
	promotion: state.reducer ? state.reducer.Promotion.payload : null,
	PromotionListSearch: state.reducer.Promotion.PromotionListSearch,
	contactFavorite: state.reducer.favorite.contactFavorite,
})

const mapDispatchToProps = dispatch => ({
	onResetPromo: () =>
		dispatch({ type: ON_RESET_PROMO }),
	onPromoSetSearchParams: (searchPromoListParams) =>
		dispatch({ type: ON_PROMO_SET_SEARCH_PARAMS, searchPromoListParams: searchPromoListParams }),
	onPromoLoadList: (searchPromoList) =>
		dispatch({ type: ON_LOAD_PROMO_LIST, searchPromoList: searchPromoList }),
	onLoadfavorite
	
})

const enhance = compose(
	connect(mapStateToProps, mapDispatchToProps),
	withStyles(styles)
)

export default translate(enhance(PromotionsListFilter))

