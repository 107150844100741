import React, { Component } from 'react'
import {
	TextInput, SelectInput, REDUX_FORM_NAME
} from 'react-admin'
import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import Typography from '@material-ui/core/Typography'
import CardChild from '../../CardChild'
import { DomainsItems } from '../../tools'
import { autofill, registerField, change, unregisterField } from 'redux-form'
import { connect } from 'react-redux'
import compose from 'recompose/compose'
import { withStyles } from '@material-ui/core/styles'

const styles = theme => ({
	cardPaddingMargin: {
		padding: 20,
		margin: 20,
		flex: '1 auto'
	},
	cardDiv: {
		flex: '1 auto'
	},
	flexContent: {
		display: 'flex',
		alignItems: 'flex-start'
	},
	input: {
		display: 'inline-block'
	},
	inputLeft: {
		display: 'inline-block',
		marginLeft: 32
	},
	noMarginLeft: {
		marginLeft: 0
	}
})

class CardCreate extends Component {
	state = {
		name_0: '',
		name_1: '',
		result: ''
	}

	handleChange = (event) => {
		let name_0
		let name_1

		if (event.target.name === 'name_0') {
			name_0 = event.target.value
			name_1 = this.state.name_1
		} else {
			name_0 = this.state.name_0
			name_1 = event.target.value
		}

		let result = name_0 + "" + name_1

		this.setState({
			name_0: name_0,
			name_1: name_1,
			result: result
		})
	}

	render() {
		const { classes, registerField, autofill, change, unregisterField } = this.props

		registerField(REDUX_FORM_NAME, 'companyid', 'Field')
		unregisterField(REDUX_FORM_NAME, 'name_0', 'Field')
		unregisterField(REDUX_FORM_NAME, 'name_1', 'Field')
		autofill(REDUX_FORM_NAME, 'companyid', 1)
		change(REDUX_FORM_NAME, 'name', this.state.result)

		return (
			<div className={classes.flexContent}>
				<Card className={classes.cardPaddingMargin}>
					<CardContent>
						<Typography variant="title">Fiche du nouveau programme</Typography>
					</CardContent>
					<CardChild>
						<TextInput label="fields.name" source="name_0" onChange={this.handleChange} className={classes.input} />
						<TextInput label="" source="name_1" onChange={this.handleChange} className={classes.inputLeft} classes={{ root: classes.noMarginLeft }} />
						<SelectInput label="fields.typeid" source="typeid" choices={DomainsItems.getSelectList(40)} allowEmpty={false} />
					</CardChild>
				</Card>
			</div>
		)
	}
}

const mapDispatchToProps = {
	autofill,
	change,
	registerField,
	unregisterField
}

const enhance = compose(
	connect(null, mapDispatchToProps),
	withStyles(styles)
)

export default enhance(CardCreate)
