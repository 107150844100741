import React from 'react'
import { SimpleShowLayout} from 'react-admin'
import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import Typography from '@material-ui/core/Typography'
import { Contacts } from '../../tools'
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import CardChild from '../../CardChild'
import { PromotionsType } from '../../constants/types'
import { DateFieldPromotions } from '../common/fields'
import { PromotionsRecurrence, PromotionsVoucher, PromotionsUsers, PromotionsGeneral } from './modules'
import { withStyles } from '@material-ui/core/styles'


const styles = theme =>  ({
	cardPaddingMargin: {
		padding: 20,
		margin: 20,
		flex: '1 auto'
	},
	cardDiv: {
		flex: '1 auto',
		// height: '86.9%',
		padding: 20,
		margin: 20,
	},
	cardDiv2: {
		flex: '1 auto',
		// height: '86.9%',
		padding: 0,
		margin: 20,
	},
	flexContent: {
		display: 'flex',
		alignItems: 'flex-start',
		padding: 0,
		margin: 20,
	},
	flexContentRules: {
		display: 'flex',
		alignItems: 'flex-start',
		marginTop: 10,
		marginBottom: 10,
	},
	input: {
		display: 'inline-block'
	},
	inputLeft: {
		display: 'inline-block',
		marginLeft: 32
	},
	noMarginLeft: {
		marginLeft: 0
	},
	paddingCheckBox: {
		marginLeft: 0
	},
	paddingCheckBoxCloture: {
		marginTop: '-10px'
	},
	fullWidth: {
		width: '95.5%',
		marginBottom: '5px',
	},
	fullWidthInput: {
		width: '95.5%',
		marginBottom: '5px',
		marginTop: '0px',
	},
	fullWidthDescription: {
		width: '97%',
	},
	descriptionInput: {
		lineHeight: '25px',
		paddingLeft: '7px'
	},
	promotionTitle: {
		marginTop: '-20px',
		marginLeft: '10px',
		fontSize: '26px',
	},
})

const mode = [
	{ typeid: null, name: 'Tous' },
	{ typeid: PromotionsType.GLOBAL, name: 'Global' },
	{ typeid: PromotionsType.UTILISATEUR, name: 'Utilisateur' },
	{ typeid: PromotionsType.VOUCHER, name: 'Voucher' },
]

const CardEdit = withStyles(styles)(({ classes, record } = this.props) => (
	<div className={classes.cardDiv2}>
		<Grid container spacing={3}>
			<Grid item xs={7}>
				<PromotionsGeneral classes={classes} record={record} create={true} type={'edit'} />
			</Grid>

			<Grid item xs={5}>
				<Card className={classes.cardDiv}>
					<CardContent>
						<Typography variant="title">Gestion</Typography>
					</CardContent>
					<CardChild>
						<SimpleShowLayout>
							<Grid container spacing={3}>
								<Grid xs>
									<TextField label="Créé par" value={Contacts.getFormattedLabel(record.createdby)}/>
								</Grid>
								<Grid xs>
									<DateFieldPromotions label="Créé le" source={record.createdon} showGestion={true}/>
								</Grid>
								<Grid xs={12}>
									<br/>
								</Grid>
								<Grid xs>
									<TextField label="Modifié par" value={Contacts.getFormattedLabel(record.modifiedby)}/>
								</Grid>
								<Grid xs>
									<DateFieldPromotions label="Modifié le" source={record.modifiedon} showGestion={true}/>
								</Grid>
							</Grid>
						</SimpleShowLayout>
					</CardChild>
				</Card>
			</Grid>
			{/* <PromotionsRules classes={classes} source={record} create={true} type={'edit'} /> */}
			
		</Grid>
	</div>
))

function displayTypeInformations(source = null, classes) {
	let displayList = []
	if (source.typeid === mode[2].typeid) {
		displayList.push( <PromotionsUsers source={source} classes={classes} /> )
	}
	if (source.typeid === mode[3].typeid ) {
		displayList.push( <PromotionsVoucher source={source} classes={classes} /> )
	} else {
		displayList.push( <PromotionsRecurrence source={source} classes={classes} /> )
	}

	return displayList
} 

export default CardEdit
