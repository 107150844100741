import { PRICING_TOOL_SELECT_LINE, PRICING_TOOL_SELECT_LINE_RESET } from '../constants/actions'

export default (state = {}, { type, payload }) => {
	if (type === PRICING_TOOL_SELECT_LINE) {
		return { selected: payload }
	}
	else if (type === PRICING_TOOL_SELECT_LINE_RESET) {
		return { selected: null }
	}
	return state
}