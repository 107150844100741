export default class ValidatePromotionFields {
	static validatePromotion = (values, isVoucherExist, promotionApplyAllCatalogue) => {

		let errorFields = {
			name: "",
			startdate: "",
			enddate: "",
			typeid: "",
			promotype: "",
			validitytype: "",
			validitytypeduration: "",
			consumptiontype: "",
			voucher: ""
		}

		//let parser = new DOMParser()
		//let xmlDocVoucher = parser.parseFromString(values.properties, "text/xml")
		let voucher = values.max
		let isInfiniCode = values.singleCode !== null && values.max == -1 ? true : false
		let messages = []

		if (values) {
			if (values.name === null || values.name === undefined || values.name === '') {
				messages.push('Veuillez renseigner le nom de la promotion')
				errorFields.name = 'Veuillez renseigner le nom de la promotion'
			}
			if (values.typeid == 0 || values.typeid === undefined) {
				messages.push('Veuillez sélectionner un type de promotion')
				errorFields.typeid = 'Veuillez sélectionner un type de promotion'
			}
			if (values.startdate === null || values.startdate === undefined) {
				messages.push('Veuillez saisir une date de début')
				errorFields.startdate = 'Veuillez saisir une date de début'
			}
			if (values.enddate === null || values.enddate === undefined) {
				messages.push('Veuillez saisir une date de fin')
				errorFields.enddate = 'Veuillez saisir une date de fin'
			}
			if (values.startdate && values.enddate) {
				if (values.startdate >= values.enddate) {
					messages.push('La date de fin de la promotion ne peux pas être inférieur ou égale à la date de début')
					errorFields.enddate = 'La date de fin de la promotion ne peux pas être inférieur ou égale à la date de début'
				}
			}

			if (isInfiniCode && parseInt(voucher) !== -1 && voucher < isVoucherExist.count) {
				messages.push('Le nombre de codes total ne peut pas être inférieur au nombre de codes déjà disponibles')
				errorFields.voucher = 'Le nombre de codes total ne peut pas être inférieur au nombre de codes déjà disponibles'
			}
			if (values.typeid !== 0 && values.typeid === 1) {
				//let parser = new DOMParser()
				//let xmlDoc = parser.parseFromString(values.properties,"text/xml")	

				//if(xmlDoc.getElementsByTagName("PROPS")[0].hasChildNodes()) {
				let nbCode = values.max //  xmlDoc.getElementsByTagName("PROPS")[0].childNodes[0].getAttribute("VALUE")
				let lenCode = values.codeLength  //xmlDoc.getElementsByTagName("PROPS")[0].childNodes[1].getAttribute("VALUE")
				let prefix = values.prefix ? values.prefix : 0 // xmlDoc.getElementsByTagName("PROPS")[0].childNodes[2].getAttribute("VALUE")
				let maxVoucher = 0
				if (nbCode !== 0) {
					let NoPrefLen = lenCode - prefix.length
					maxVoucher = Math.round(260 ** parseInt(NoPrefLen / 2))
					if (NoPrefLen % 2 === 1) {
						maxVoucher = maxVoucher * 10
					}
				}
				if (maxVoucher < nbCode) {
					messages.push('Le nombre de codes total ne peut pas être supérieur à ' + maxVoucher)
					errorFields.voucher = 'Le nombre de codes total ne peut pas être supérieur à ' + maxVoucher
				}
				if(values.nbUse < 1){
					messages.push('Le nombre d`utilsations ne peut pas être inférieur à 1' )
					errorFields.voucher = 'Le nombre d`utilsations ne peut pas être inférieur à 1'
				}
				//}
			}
			let messagesRules = this.validatePromotionRules(values, promotionApplyAllCatalogue, errorFields)
			messagesRules.forEach(v => messages.push(v))
		}
		if (messages.length !== 0) {
			return {
				success: false,
				message: messages,
				errorField: errorFields
			}
		}
		return {
			success: true
		}
	}

	static validatePromotionRules = (values, promotionApplyAllCatalogue, errorFields) => {
		let parser = new DOMParser()
		let xmlDoc = parser.parseFromString(values.rules, "text/xml")
		let messagesRules = []

		let promoType = xmlDoc.getElementsByTagName("REBATE")[0].getAttribute("TYPEID")
		let validityType = xmlDoc.getElementsByTagName("TTL")[0].getAttribute("TYPEID")
		let validitytypeduration = xmlDoc.getElementsByTagName("TTL")[0].getAttribute("DURATION")
		let consumptionType = xmlDoc.getElementsByTagName("REBATE")[0].getAttribute("MODEID")
		let medias = xmlDoc.getElementsByTagName("REBATE")[0].getAttribute("MEDIA")
		let diffusion = xmlDoc.getElementsByTagName("REBATE")[0].getAttribute("DISTTECHNOLOGY")

		if (promoType == 0 || promoType === undefined) {
			errorFields.promotype = 'Veuillez sélectionner un type de réduction'
			messagesRules.push('Veuillez sélectionner un type de réduction')
		}
		if (validityType == 0 || validityType === undefined) {
			errorFields.validitytype = 'Veuillez sélectionner le type de validité'
			messagesRules.push('Veuillez sélectionner le type de validité')

		}
		if  ((validitytypeduration =='P0Y0M0DT0H0M0S'  ) || (validitytypeduration && validitytypeduration.split('-').length >1)) {
			errorFields.validitytypeduration = 'La durée de promotion est invalide'
			messagesRules.push('La durée de promotion est invalide')
		}
		 
		if (consumptionType == 0 || consumptionType === undefined) {
			errorFields.consumptiontype = 'Veuillez saisir un mode de consommation'
			messagesRules.push('Veuillez saisir un mode de consommation')
		}

		if (!promotionApplyAllCatalogue
			&& xmlDoc.getElementsByTagName("MIDS")[0].childNodes.length === 0
			&& xmlDoc.getElementsByTagName("AIDS")[0].childNodes.length === 0
			&& xmlDoc.getElementsByTagName("BDDPIDS")[0].childNodes.length === 0
			&& xmlDoc.getElementsByTagName("HAPILISTIDS")[0].childNodes.length === 0) {
			messagesRules.push('Veuillez saisir au moins 1 programme ou 1 album ou 1 id de liste bddp ou 1 id de liste HAPI')
		}
 		if(xmlDoc.getElementsByTagName("BDDPIDS")[0].getElementsByTagName("BDDPID")){
			for (let index = 0; index < xmlDoc.getElementsByTagName("BDDPIDS")[0].getElementsByTagName("BDDPID").length; index++) {
				console.log(typeof xmlDoc.getElementsByTagName("BDDPIDS")[0].getElementsByTagName("BDDPID")[index].getAttribute('ID') )
				if(isNaN( xmlDoc.getElementsByTagName("BDDPIDS")[0].getElementsByTagName("BDDPID")[index].getAttribute('ID') ))
					{
						messagesRules.push('BDDP ID doit être un entier')
						break
					}

			}
		}

 		if (!xmlDoc.getElementsByTagName("USERQUALIFIER")[0].hasAttribute("MICRO")) {
			messagesRules.push('Veuillez sélectionner au moins un élément dans la catégorie Micro éligibilité')
		}

		if (medias == 0 || medias === undefined) {
			messagesRules.push('Veuillez sélectionner au moins un élément dans la catégorie Medias inclus')
		}

		if (diffusion == 0 || diffusion === undefined) {
			messagesRules.push('Veuillez sélectionner au moins un élément dans la catégorie Mode de diffusion')
		}

		if (!xmlDoc.getElementsByTagName("TARGETS")[0].hasChildNodes()) {
			messagesRules.push('Veuillez sélectionner au moins un élément dans la catégorie Cible de diffusion')
		}

		return messagesRules
	}
}