import React, { Component } from 'react'
import { SyncIcon } from '../../../constants/icons'
import { Publicationstracking } from '../../../requests'
import { Contacts as ContactsTools ,PublicationTrackingReferenceData} from '../../../tools'
import {  showNotification, translate } from 'react-admin'
import { connect } from 'react-redux'
import compose from 'recompose/compose'
import Button from '@material-ui/core/Button'
import PlanningProcessParameterModal from './PlanningProcessParameterModal'
import { Logger } from '../../../tools'
import { onLoadWeeks, onLoadPlanning } from '../../../actions/publicationTracking'
import ImportProcessLoader from './ImportProcessLoader'
import { withStyles } from '@material-ui/core/styles'

const styles = theme =>  ({
	button: {
		margin: theme.spacing(1),
		marginTop: '0px',
		top: '5px',
		width : '150px',
		maxHeight: '35px',
		fontSize: '0.75rem',
		backgroundColor : '#288DB1',
		lineHeight: '1.2em',
		'&:hover': {
			background: '#1c627b'
		}
	},
})

class LaunchExcelImport extends Component{
	constructor(props) {
		super(props)
		this.state = {
			modalOpen : false,
			isProcessLoaderOpen : false,
			isOperationFinished : false,
			isOperationFailed : false,
			resultText : null
		}
	}

	handleImportButtonClick = () => {
		this.setState({
			modalOpen : true
		})
	}

	handleCloseProgressBar = () => {
		this.setState({
			isProcessLoaderOpen: false,
			modalOpen : false,
			isOperationFinished : false,
			isOperationFailed : false
		})
	}

	handleClose = () => {
		this.setState({modalOpen: false})
	}

	handleSubmit = (processParameter) => {
		const {yearNumber, weekNumber, onLoadPlanning } = this.props
		console.log(yearNumber + ''+ weekNumber)
		this.setState({ 
			isProcessLoaderOpen : true,
			isOperationFinished : false
		})
		Publicationstracking.launchManualExcelImport(processParameter.dateinterval, processParameter.startdate, processParameter.enddate).then((json)=>{
			Publicationstracking.getPlanning(yearNumber + ''+ weekNumber).then((json) => {
				const trackingData = json.data
				let planningToBeSaved = {
					publicationsplannings: trackingData.publicationsplannings,
					publicationsstats: []
				}
				const users = ContactsTools.getContactsByGroupid(160)
				let distribution = {
					pgm: trackingData.publicationsplannings.filter(x => x.publications !== null && (x.taskTypeId === 14 || x.taskTypeId === 16 || x.taskTypeId === 17 || x.taskTypeId === 27 || x.taskTypeId === 28)),
					ouv: trackingData.publicationsplannings.filter(x => x.publications !== null && (x.taskTypeId === 21 || x.taskTypeId === 22)),
					susp: trackingData.publicationsplannings.filter(x => x.publications !== null && (x.taskTypeId === 11 || x.taskTypeId === 12 || x.taskTypeId === 13 || x.taskTypeId === 24 || x.taskTypeId === 25)),
					chgtprix: trackingData.publicationsplannings.filter(x => x.publications !== null && (x.taskTypeId && PublicationTrackingReferenceData.getTasksTypes().find(r => r.itemid === x.taskTypeId).taskGroupDomainItemId  === 7 )),
					//regulprix: trackingData.publicationsplannings.filter(x => x.publications !== null && (x.taskTypeId >= 31 && x.taskTypeId <= 46)),
					autre: trackingData.publicationsplannings.filter(x => x.publications !== null && ((x.taskTypeId >= 1 && x.taskTypeId <= 3)  || x.taskTypeId === null)),
	
				}
	 
				if (trackingData.publicationsstats !== null && trackingData.publicationsstats.length !== 0) {
					trackingData.publicationsstats.forEach(item => {
						const currentUser = users.find(u => u.id === item.contactId)
	
						const stat = {
							contactName: currentUser ? currentUser.gsm : '',
							publicationsCount: item.publicationsCount,
							pgm: distribution.pgm !== undefined ? distribution.pgm.filter(x => x.publications.contactId === item.contactId).length : 0,
							ouv: distribution.ouv !== undefined ? distribution.ouv.filter(x => x.publications.contactId === item.contactId).length : 0,
							susp: distribution.susp !== undefined ? distribution.susp.filter(x => x.publications.contactId === item.contactId).length : 0,
							chgtprix: distribution.chgtprix !== undefined ? distribution.chgtprix.filter(x => x.publications.contactId === item.contactId).length : 0,
							//regulprix: distribution.regulprix !== undefined ? distribution.regulprix.filter(x => x.publications.contactId === item.contactId).length : 0,
							autre: distribution.autre !== undefined ? distribution.autre.filter(x => x.publications.contactId === item.contactId).length : 0,
							publicationPercent: Number((item.publicationsCount * 100 / trackingData.publicationsplannings.length).toFixed(1))
						}
						planningToBeSaved.publicationsstats.push(stat)
					})
				}
				onLoadPlanning(planningToBeSaved)
			})
			// Publicationstracking.getWeeks().then((json)=>{
			// 	onLoadWeeks(json.data)
			// })
			this.setState({ 
				isOperationFinished : true,
				resultText : {
					rowCount : json.data.processedRowsCount,
					newRow : json.data.newRowsCount,
					updatedRow : json.data.updatedRowsCount,
					deletedRow : json.data.deletedRowsCount,
					errorCount : json.data.errorCount
				}
			})
		}).catch(error => {
			Logger.log('Excel synchro error')
			Logger.log(error)
			this.setState({
				isOperationFinished : true,
				isOperationFailed : true
			})
		})
	}

	render(){
		const { translate, classes } = this.props
		const progressModalContent = {
			title : translate('titles.synchPlanning'),
			closeButtonText : translate('ra.action.close'),
			operationFinished : this.state.resultText
		}
		const modalContent = {
			title : translate('titles.synchPlanning'),
			description : translate('messages.syncPlanningInstruction'),
			disagreeButtonText : translate('ra.action.undo'),
			agreeButtonText: translate('ra.action.confirm')
		}
		return(
			<React.Fragment>
				<Button onClick={this.handleImportButtonClick} color="primary" variant="contained" className={classes.button}>
					{translate('labels.launchExcelImport')}
					<SyncIcon className={classes.rightIcon} />
				</Button>
				<PlanningProcessParameterModal {...this.props}
					modalOpen = {this.state.modalOpen} 
					onClose={this.handleClose} 
					modalContent = {modalContent} 
					launchActivationProcess = { this.handleSubmit }/>
				<ImportProcessLoader modalOpen = {this.state.isProcessLoaderOpen} 
					onClose={this.handleCloseProgressBar}  
					isOperationFinished = { this.state.isOperationFinished }
					isOperationFailed = { this.state.isOperationFailed }
					progressModalContent = {progressModalContent}
				/>
			</React.Fragment>
		)
	}
}

const mapStateToProps = state => ({
	...state,
	weekNumber: state.reducer.publicationTracking.weekNumber !==null ? state.reducer.publicationTracking.weekNumber : 16,
	yearNumber: state.reducer.publicationTracking.yearNumber !==null ? state.reducer.publicationTracking.yearNumber : 2020
})

const mapDispatchToProps = ({
	showNotification,
	onLoadWeeks,
	onLoadPlanning
})

const enhance = compose(
	connect(mapStateToProps, mapDispatchToProps),
	withStyles(styles)
)

export default translate(enhance(LaunchExcelImport))