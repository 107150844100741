import React from 'react'
import PropTypes from 'prop-types'
import get from 'lodash/get'
import classnames from 'classnames'
import sanitizeRestProps from './sanitizeRestProps'
import { Url } from '../../../tools'
import LazyLoad from 'react-lazyload'
import { withStyles } from '@material-ui/core/styles'
import defaultcover from '../../../pictures/defaultcover.png'

const styles =   ({
	list: {
		display: 'flex',
		listStyleType: 'none'
	},
	imagespeoples: {
		//margin: '0.3rem',
		//width: '37px',
		//height: '50px !important',
		maxWidth: '50px',
		maxHeight: '50px',
	},
	imageslarge: {
		//margin: '0.3rem',
		width: '50px',
		//height: '50px !important',
		maxWidth: '50px',
		maxHeight: '50px',
		position: 'absolute',
	},
	imagesPromotions: {
		aspectRatio: "auto 200 / 250",
		width: "auto",
    	height: "250px",
		margin: 0,
		background: "#CACBCC"
	},
	initial: {
		//width: '100%'
	},
	pictureAbsoluteTop: {
		width: '100%',
		position: 'absolute',
		top: 'auto',
		zIndex: 1,
		display: 'block'
	},
	imagesmovies: {
		width: 90,
		height: 120
	},
	imagesAlbums: {
		width: 150,
		maxWidth : 300,
		height: 200,
		objectFit: 'contain',
	},
	bannerAbsoluteTop: {
		position: 'absolute',
		top: 'auto',
		zIndex: 2,
		display: 'block',
		width: '100%'
	},
	divRelative: {
		position: 'relative'
	},
	hidden: {
		display: 'none'
	}
})

export const ImageField = ({
	className,
	classes = {},
	record,
	source,
	src,
	title,
	styleImg,
	types,
	basePath,
	cartouche,
	banner = false,
	upload = false,
	hidden = false,
	...rest
}) => {
	let sourceValue = ''
	let sourceDefaultValue = ''
	let cartoucheValue = ''
	const filename = get(record, source)
	switch (basePath) {
	case '/Peoplespictures':
		sourceValue = Url.picturePeople(record.peopleid, filename)
		sourceDefaultValue = Url.defaultPicture
		break
	case '/Moviespictures':
		if (upload === true) {
			sourceValue = filename
		} else {
			if(filename && filename.length > 0) {	
				sourceValue = Url.pictureMovie(record.movieid, filename)
			} else {
				sourceValue = defaultcover
			}
		}
		break
	case '/Albumspictures':
		if (upload === true) {
			sourceValue = filename
		} else {
			if(filename && filename.length > 0) {
				sourceValue = Url.pictureAlbum(record.albumid, filename)
			} else {
				sourceValue = defaultcover
			}
			// console.log('values', sourceValue, sourceDefaultValue)
		}
		break
	default:
		break
	}

	if (banner) {
		cartoucheValue = Url.pictureCartouche(cartouche)
	}

	if (types !== 'movies') {
	
	} else {
		sourceValue = Url.pictureMovie(record.movieid, `mvs_movie_${record.movieid}_1212.jpg`)
		sourceDefaultValue = defaultcover
	}

	if (Array.isArray(sourceValue)) {
		return (
			<ul
				className={classnames(classes.list, className)}
				{...sanitizeRestProps(rest)}
			>
				{sourceValue.map((file, index) => {
					const titleValue = get(file, title) || title
					const srcValue = get(file, src) || title

					return (
						<li key={index}>
							<LazyLoad>
								<div className={classnames({
										[classes.imagesmovies]: styleImg === 'imagesmovies',
										[classes.initial]: styleImg === 'initial',
										[classes.imagespeoples]: styleImg === 'imagespeoples',
										[classes.imageslarge]: styleImg === 'imageslarge',
										[classes.pictureAbsoluteTop]: styleImg === 'pictureAbsoluteTop',
										[classes.imagesPromotions]: styleImg === 'imagesPromotions',
										[classes.imagesAlbums]: styleImg === 'imagesAlbums'
									})}>
								<img
									alt={titleValue}
									title={titleValue}
									src={srcValue}
									onError={
										e => {
											e.target.onerror = null
											e.target.src = sourceDefaultValue
										}
									}
									
								/>
								</div>
							</LazyLoad>
						</li>
					)
				})}
			</ul>
		)
	}

	const titleValue = get(record, title) || title

	return (
		<div className={
			classnames(
				className,
				upload ? null : classes.divRelative,
				hidden ? classes.hidden : null
			)
		}
		{...sanitizeRestProps(rest)}>
			<LazyLoad>
				<img
					title={titleValue}
					alt={titleValue}
					src={upload ? sourceValue = filename : sourceValue}
					onError={
						e => {
							e.target.onerror = null
							e.target.src = sourceDefaultValue
						}
					}
					className={classnames({
						[classes.imagesmovies]: styleImg === 'imagesmovies',
						[classes.initial]: styleImg === 'initial',
						[classes.imagespeoples]: styleImg === 'imagespeoples',
						[classes.imageslarge]: styleImg === 'imageslarge',
						[classes.pictureAbsoluteTop]: styleImg === 'pictureAbsoluteTop',
						[classes.imagesPromotions]: styleImg === 'imagesPromotions',
						[classes.imagesAlbums]: styleImg === 'imagesAlbums'

					})}
				/>
			</LazyLoad>
			{
				banner ? (
					<img
						alt=""
						src={cartoucheValue}
						className={classes.bannerAbsoluteTop}
					/>
				) : null
			}
		</div>
	)
}

ImageField.propTypes = {
	addLabel: PropTypes.bool,
	basePath: PropTypes.string,
	className: PropTypes.string,
	cellClassName: PropTypes.string,
	headerClassName: PropTypes.string,
	classes: PropTypes.object.isRequired,
	record: PropTypes.object,
	sortBy: PropTypes.string,
	source: PropTypes.string.isRequired,
	src: PropTypes.string,
	title: PropTypes.string
}

export default withStyles(styles)(ImageField)
