import React, { Component } from 'react'
import Tooltip from '@material-ui/core/Tooltip'
import SortIcon from '@material-ui/icons/Sort'
import { Button } from '@material-ui/core'
import { updatePlanningTri } from '../../../actions/publicationTracking'
import { connect } from 'react-redux'
import compose from 'recompose/compose'
import {  translate } from 'react-admin'
import { withStyles } from '@material-ui/core/styles'


const styles = () =>  ({
	button:{
		marginLeft: '15px'
	}

})

class CustomSort extends Component{
	constructor(props) {
		super(props)
	}
	handleUpdatePlanningTri = () => {
		const {isUpdatePlanningTri, updatePlanningTri } = this.props
		updatePlanningTri(isUpdatePlanningTri ? true : false)
	}
	render(){
		const {   isUpdatePlanningTri } = this.props
				return(
					<React.Fragment>
						<Tooltip title={isUpdatePlanningTri ? 'trier par catégories' : 'trier par responsable'}>
							<Button   onClick = {this.handleUpdatePlanningTri} style={{padding: '0px',borderRadius:'50%',minHeight:'45px',minWidth:'45px'}}>
								<SortIcon style={{ color: 'rgba(0, 0, 0, 0.54)',fontSize: '1.4rem' }}/>
							</Button>
							
						</Tooltip>
					</React.Fragment>
				)
	}
}
const mapStateToProps = state => ({
	...state,
	isUpdatePlanningTri: state.reducer.publicationTracking.isUpdatePlanningTri,
})
const mapDispatchToProps = ({
	updatePlanningTri
})
const enhance = compose(
	connect(mapStateToProps, mapDispatchToProps),
	withStyles(styles)
)

export default translate(enhance(CustomSort))
