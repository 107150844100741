import React from 'react'
import { SelectInput , TextInput } from 'react-admin'
import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import Typography from '@material-ui/core/Typography'
import CardChild from '../../CardChild'
import { ExtendedInput } from '../common/inputs'
import { getNameProduct } from '../../tools/utils'
import { DomainsItems } from '../../tools'
import {Labeled} from 'react-admin'
import { withStyles } from '@material-ui/core/styles'

const styles = theme => ({
	cardPaddingMargin: {
		padding: 20,
		margin: 20,
		flex: '1 auto'
	},
	cardDiv: {
		flex: '1 auto'
	},
	flexContent: {
		display: 'flex',
		alignItems: 'flex-start'
	},
	input: {
		display: 'inline-block'
	},
	inputLeft: {
		display: 'inline-block',
		marginLeft: 32
	},
	noMarginLeft: {
		marginLeft: 0
	}
})

const CardEdit = withStyles(styles)(({ classes, record } = this.props) => (
	<div className={classes.flexContent}>
		<Card className={classes.cardPaddingMargin}>
			<CardContent>
				<Typography variant="title">Fiche du produit {typeof record.name !== 'undefined' ? record.name : record.value}</Typography>
			</CardContent>
			<CardChild>
				<TextInput label="fields.id" source="id" disabled/>
				<ExtendedInput label="fields.name" source="name" nameproduct={0} className={classes.input} disabled />
				<ExtendedInput label="" source="name" nameproduct={1} className={classes.inputLeft} classes={{ root: classes.noMarginLeft }} disabled />
				<SelectInput label="fields.typeid" source="typeid" choices={DomainsItems.getSelectList(40)} allowEmpty={false} />
				<Labeled label="Programme associé">
					<Typography>{getNameProduct(typeof record.name !== 'undefined' ? record.name : record.value, 1)}</Typography>
				</Labeled>
			</CardChild>
		</Card>
		<div className={classes.cardDiv}>
			<Card className={classes.cardPaddingMargin}>
				<CardContent>
					<Typography variant="title">Gestion</Typography>
				</CardContent>
				<CardChild>
					<ExtendedInput label="fields.createdon" source="createdon" date disabled />
					<ExtendedInput label="fields.createdby" source="createdby" contact disabled />
					<ExtendedInput label="fields.modifiedon" source="modifiedon" date disabled />
					<ExtendedInput label="fields.modifiedby" source="modifiedby" contact disabled />
				</CardChild>
			</Card>
		</div>
	</div>
))

export default CardEdit
