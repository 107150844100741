import Api from './Api'

const ContactsFavorites = {
	getAll: (objectid) =>
 		Api.Requests.getAll('/Contactsfavorites/search?objectId='+ objectid),
	getAllWithTitle: (objectid) =>
 		Api.Requests.getAll('/Contactsfavorites/searchFavoritelistWithTitle?objectId='+ objectid),
	addFavorite : (data) =>
		Api.Requests.postWithBody('/Contactsfavorites/SaveFavorite?refId='+  data.RefId + '&objectId=' + data.ObjectId),
	removeFavorite : (favoriteid) =>
		Api.Requests.delete('/Contactsfavorites/removeFavorite?favoriteId=' + favoriteid ),	
	removeAllFavorites : (objectid) =>
		Api.Requests.delete('/Contactsfavorites/removeAllFavorites?objectId=' + objectid ),	
	}

export default ContactsFavorites