import { ON_PRICE_PLANNING_SELECT_CONTENT, ON_PRICE_PLANNING_CHANGE_CONTENTID_FIELD, 
	ON_PRICE_PLANNING_EXPAND_UPDATE_FORM, ON_PRICE_PLANNING_LOAD, ON_PRICE_PLANNING_SELECT_PRICELINE
	, ON_CHECK_UPDATE_FORM, RESET_PREVIEW, ON_PRICE_PLANNING_CHANGE_CONTENTTYPE } from '../constants/actions'

const initialState = {
	priceSelected : null,
	contentSelected : null,
	isUpdateFormExpanded : false,
	contentPriceLists : [],
	isOnPricePlanningPage : false,
	transfersRightsByPeriodPreview : [],
	objectTypeId : 1
}

export default (state = initialState, { type, payload }) => {
	if (type === ON_PRICE_PLANNING_SELECT_CONTENT) {
		return { 
			...state,
			priceSelected: payload.selectedPrice ,
			contentSelected: payload.selectedContent !== null ? payload.selectedContent : state.contentSelected,
			contentPriceLists : payload.data
		}
	}
	if (type === ON_PRICE_PLANNING_CHANGE_CONTENTID_FIELD) {
		return { 
			...state,
			priceSelected: null ,
			contentSelected: null,
			contentPriceLists : [],
			transfersRightsByPeriodPreview: []
		}
	}
	if (type === ON_PRICE_PLANNING_EXPAND_UPDATE_FORM) {
		return { 
			...state,
			isUpdateFormExpanded  : payload.iExpanded
		}
	}
	if(type === ON_PRICE_PLANNING_LOAD){
		return {
			...state,
			isOnPricePlanningPage : true
		}
	}
	if(type === ON_PRICE_PLANNING_SELECT_PRICELINE){
		return { 
			...state,
			priceSelected: payload.selectedPrice
		}
	}
	if(type === '@@router/LOCATION_CHANGE'){
		//on route change, initialize the current price and album value in the store
		return {
			...state,
			priceSelected: null ,
			contentSelected: null,
			contentPriceLists : [],
			isOnPricePlanningPage : false,
			transfersRightsByPeriodPreview : [],
			objectTypeId : 1
		}
	}
	if(type === ON_CHECK_UPDATE_FORM){
		return {
			...state,
			transfersRightsByPeriodPreview : payload.resultsPreview
		}
	}
	if(type === RESET_PREVIEW){
		return {
			...state,
			transfersRightsByPeriodPreview : []
		}
	}
	if(type === ON_PRICE_PLANNING_CHANGE_CONTENTTYPE){
		return {
			...state,
			objectTypeId : payload.newContentType
		}
	}
	return state
}