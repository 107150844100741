import React,{useState, useEffect} from 'react'
import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'
// import { useLocation } from 'react-router-dom';

function ErrorPage() {
  const location = window.location.origin
  const [errorMessage, setErrorMessage] = useState('');

	const { classes, error } = this.props


	useEffect(() => {
		const hash = window.location.href;
		const queryString = hash.split('?')[1]; 
		console.log(queryString)
		const urlParams = new URLSearchParams(queryString);
		const message = urlParams.get('message') || 'Erreur de connexion. Veuillez contacter votre administrateur. '; 
		console.log(message.replace('messages.',''))
        if (message) {
            setErrorMessage(decodeURIComponent(message.replace('messages.','')));
        }
    }, [error])
  console.log(location)
  
  return (
    <div>
      <Box component="form" noValidate sx={{ mt: 1 }}>
        <Typography variant="h1" >
            {errorMessage || 'Une erreur est survenue lors de la connexion. Veuillez contacter votre administrateur.'}
         </Typography>
      </Box>
    </div>
  );
}

export default ErrorPage
