import React from 'react'
import { Show,Edit ,SimpleForm,Create} from 'react-admin'
import CompaniesalbumsShowFields from './CompaniesalbumsShowFields'
// import CompaniesalbumsEdit from './CompaniesalbumsEdit'
// import { MyEditActions } from '../common/actions'

import { createTheme, ThemeProvider } from '@material-ui/core/styles'

const getMuiTheme = () => createTheme({
	overrides: {
		MuiCardContent: {
			root: {
				padding: '0'
			}
		},
		MuiFormLabel:{
			root:{
				"&$focused": {
					"color": "#288DB1"
				},
			}
		},
		MuiOutlinedInput: {
			'root': {  // - The Input-root, inside the TextField-root
				'&:hover fieldset': {
					borderColor: '#288DB1 !important', // - Set the Input border when parent has :hover
				},
				'&.Mui-focused fieldset': { // - Set the Input border when parent is focused 
					borderColor: '#288DB1 !important',
				},
			},
		}
	},
	display: {
		display: 'none'
	}
})
// const getMuiTheme = () => createTheme({
// 	overrides: { 
// 		MuiCardActions: {
// 			root: {
// 				justifyContent: 'flex-end'
// 			}
// 		}

// 	},
// })
 
export const CompaniesalbumsShow = props => {
	return(
	<ThemeProvider theme={getMuiTheme()}>
		<Show title={'test'} actions={null} {...props}>
			<CompaniesalbumsShowFields  create={false} />
		</Show>
	</ThemeProvider>
)}
export const CompaniesalbumsEdit = props => (
	<ThemeProvider theme={getMuiTheme()}>
	<Edit undoable={true} actions={null} {...props}>
		<SimpleForm toolbar={null} style={{paddingTop: 0, margin: '-16px -16px 0'}}>
			<CompaniesalbumsShowFields create={true} type='edit'/>
		</SimpleForm>
	</Edit>
</ThemeProvider>
)
export const CompaniesalbumsCreate = (props) => (
	<ThemeProvider theme={getMuiTheme()}>
		<Create undoable={false} actions={null} {...props}>
			<SimpleForm toolbar={null} style={{paddingTop: 0, margin: '-16px -16px 0'}}>
				<CompaniesalbumsShowFields create={true} type='create'/>
			</SimpleForm>
		</Create>
	</ThemeProvider>
)
export const CompaniesalbumsCreateEdito = (props) => (
	<ThemeProvider theme={getMuiTheme()}>
		<Edit undoable={false} actions={null} {...props}>
			<SimpleForm toolbar={null} style={{paddingTop: 0, margin: '-16px -16px 0'}}>
				<CompaniesalbumsShowFields create={true} type='create' tabValue={'1' || '2'} />
			</SimpleForm>
		</Edit>
	</ThemeProvider>
)

