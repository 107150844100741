import Api from './Api'

const PricingscodesRequests = {
	getAll: () =>
		Api.Requests.getAll('/Pricingscodes/PricingscodesGet'),
	LoadPricingscodes: (pricingcodeid) =>
		Api.Requests.getAll(`/Pricingscodes/PricingscodesLoad?pricingcodeid=${pricingcodeid}`),
	AddPricingscodes: (data) =>
		Api.Requests.postWithBody('/Pricingscodes/AddPricingsCodes', data),
	UpdatePricingscodes: (data) =>
		Api.Requests.postWithBody('/Pricingscodes/UpdatePricingsCodes', data),
	DisablePricingscodes: (data) =>
		Api.Requests.postWithBody('/Pricingscodes/DisablePricingsCodes', data),
}

export default PricingscodesRequests
