import React from 'react'
import { Field  } from 'redux-form'
import Checkbox from '@material-ui/core/Checkbox'
import { DatePickerMaterial } from '../../common/inputs'
import {  withStyles } from '@material-ui/core'

// const renderCheckbox = ({ input, label }) => (
// 	<Checkbox label={label}
// 	  checked={input.value ? true : false}
// 	  style={{marginTop: '20px'}}
// 	  onChange={input.onChange}/>
// )  

const styles = ({
	customDateClass: {
		// marginTop: '15px',
		// padding: '4px',
		// paddingTop: '15px'
		//marginBottom :'-25px'
	},
	customInputClass: {
		width: '100px',
		marginRight: '5px'
	},
	customcheck:{
		marginTop:'15px',
		'&$checked': {
			color: '#288DB1',
		},
	},
	customLabelStyle: {
		//width: 'max-content',
		transform: 'translate(0, 1.5px) scale(0.75)',
		transformOrigin: 'top left',
		color:'#288DB1',
		"&$disabled": {
			"color": "#288DB1"
		},
		"&$focused": {
			"color": "#288DB1"
		},
		//marginLeft: '-2em'
	},
})


class CustomDatePickerCanal extends React.Component{
	constructor(props){
		super(props)
		this.state = {
			disabled : true
		}
	}
// const renderCheckbox = ({ input, label }) => (
// 	<Checkbox label={label}
// 	  checked={input.value ? true : false}
// 	  style={{marginTop: '20px'}}
// 	  onChange={input.onChange}/>
// )  
	handleCheckboxAction = () => {
		const checkValue = this.state.disabled ? false : true
		this.setState({ disabled : checkValue })
	} 

	renderCheckbox = ({ input, label }) => {
		const {  classes,disableCheck } = this.props
		this.setState({ disabled : input.value ? false : true})
		return (
			<Checkbox label={label} 
				className = {classes.customcheck}
				checked={input.value ? true : false}
				style={{color: '#288DB1'}}
				disabled={disableCheck}
				onChange={(event) => {input.onChange(event); this.handleCheckboxAction();}}/>
		)
	}
		
 

	render(){
		const { label, startDateSource, endDateSource, name, classes  } = this.props
		return (
			<div className = {classes.customDateClass}>
				<Field
					name={name}
					label={label}
					component={this.renderCheckbox}
				/>
				{/* <DatePickerCanal className = {classes.customInputClass} withCustomLabelStyle = {true} source={startDateSource} label={label} dateOnly shrink={false} classes={classes}/> 
				<DatePickerCanal className = {classes.customInputClass} withCustomLabelStyle = {true} source={endDateSource} dateOnly shrink={true} classes={classes}/><br /> */}
	
				<DatePickerMaterial className = {classes.customInputClass} withCustomLabelStyle = {true} source={startDateSource} label = {label} dateOnly shrink = {false} classes = {classes} dateType = {'start'} disabled = {this.state.disabled}/>
				<DatePickerMaterial className = {classes.customInputClass} withCustomLabelStyle = {true} source={endDateSource}   label = {label} dateOnly shrink = {false} classes = {classes} dateType = {'end'}  disabled = {this.state.disabled} />
				
				<br />
			</div>
		)
	}
}

export default withStyles(styles)(CustomDatePickerCanal)