import React, { Component } from 'react'
import FormControl from '@material-ui/core/FormControl'
import { connect } from 'react-redux'
import compose from 'recompose/compose'
import { translate } from 'react-admin'
import { onChangeWeek, onLoadWeeks, onChangeYear, onLoadPlanning, onChangeUser, onChangeCategory, onChangeTaskType, onChangeSearch, onChangeTaskGroup } from '../../../../actions/publicationTracking'
import { crudGetList as crudGetListAction } from 'ra-core/lib/actions/dataActions'
import { Contacts as ContactsTools } from '../../../../tools'
import TextField from '@material-ui/core/TextField'
import InputAdornment from '@material-ui/core/InputAdornment'
import { SearchIcon, CloseIcon } from '../../../../constants/icons'
import { withStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import Autocomplete from '@material-ui/lab/Autocomplete'
import IconButton from '@material-ui/core/IconButton'
import Button from '@material-ui/core/Button';


// const theme = createTheme({
// 	overrides: {
// 		MuiSelect: {
// 			selectMenu: {
// 				width: '200px',
// 				overflow: 'hidden',
// 				minHeight: '1.1875em',
// 				whiteSpace: 'nowrap',
// 				textOverflow: 'ellipsis'
// 			}
// 		}
// 	},
// })

const styles = theme => ({
	formControl: {
		margin: theme.spacing(1),
		minWidth: 200,
	},
	selectEmpty: {
		marginTop: theme.spacing(2),
	},
	customSelect: {
		width: '80%'
	},
	button: {
		margin: theme.spacing(1),
		marginTop: '20px',
		backgroundColor: '#288DB1',
		color: '#fff',
		width: '170px',
		maxHeight: '35px',
		maxWidth: '150px',
		fontSize: '0.7rem',
		top: '5px',
		lineHeight: '1.2em',
		'&:hover': {
			background: '#1c627b'
		}
	},
})

class UserSelector extends Component {
	constructor(props) {
		super(props)
		this.state = {
			titleValue: '',
			categoryfilter: {},
			taskType: {},
			taskGroup: {},
			selectUser: {}

		}
	}

	componentDidMount() {
		this.setState({ titleValue: '' })
		this.setState({ categoryfilter: {} })
		this.setState({ taskType: {} })
		this.setState({ taskGroup: {} })
		this.setState({ selectUser: {} })

	}
	componentDidUpdate(previousProps) {
		if (this.props.modalOpen !== previousProps.modalOpen) {
			this.setState({ titleValue: '' })
			this.setState({ categoryfilter: {} })
			this.setState({ taskType: {} })
			this.setState({ taskGroup: {} })
			this.setState({ selectUser: {} })
		}
	}
	handleChange = (event, source) => {
		if (source === 'titre') {
			const { onChangeSearch } = this.props
			this.setState({ titleValue: event.target.value })
			if (event.target.value.length === 0)
				onChangeSearch(null)

		}
	}
	handleChangeAuto = (event, newvalue, source) => {
		const { onChangeCategory, onChangeTaskType, onChangeTaskGroup, onChangeUser } = this.props
		if (source === 'category') {
			this.setState({ categoryfilter: newvalue })
			onChangeCategory(newvalue ? newvalue.itemid : 0)
		}
		if (source === 'taskType') {
			this.setState({ taskType: newvalue })
			onChangeTaskType(newvalue ? newvalue.itemid : 0)
		}
		if (source === 'taskGroup') {
			this.setState({ taskGroup: newvalue })
			onChangeTaskGroup(newvalue ? newvalue.itemid : 0)
		}
		if (source === 'user') {
			this.setState({ selectUser: newvalue })
			onChangeUser(newvalue)
		}

	}
	handleKeyPress = (event) => {
		const { onChangeSearch } = this.props
		if (event.key === 'Enter') {
			onChangeSearch(this.state.titleValue)
		}
	}
	reset = (e, item) => {
		if (item === 'title') {
			const { onChangeSearch } = this.props
			this.setState({ titleValue: '' })
			onChangeSearch(null)
		}
	}
	resetFilter = (e) => {
		const { onChangeCategory, onChangeSearch, onChangeTaskType, onChangeTaskGroup, onChangeUser } = this.props
		this.setState({ titleValue: '' })
		this.setState({ categoryfilter: {} })
		this.setState({ taskType: {} })
		this.setState({ taskGroup: {} })
		this.setState({ selectUser: {} })
		onChangeSearch(null)
		onChangeUser(null)
		onChangeTaskType(null)
		onChangeTaskGroup(null)
		onChangeCategory(null)

	}
	render() {
		const { classes, weekCategories, weektasktype, weektaskGroup  } = this.props
		const users = ContactsTools.getContactsByGroupid(160) ? ContactsTools.getContactsByGroupid(160) : []
		return (
			<React.Fragment>
				<Grid container >
					<Grid item xs={12}>
						<FormControl className={classes.formControl}>
							<Autocomplete
								id="combo-box-demo"
								options={weekCategories}
								getOptionLabel={(option) => option.label}
								onChange={(e, newValue) => this.handleChangeAuto(e, newValue, "category")}
								value={this.state.categoryfilter}
								renderInput={(params) => <TextField  {...params} label="Catégorie"
								/>}
							/>
						</FormControl>
						<FormControl className={classes.formControl}>
							<Autocomplete
								id="combo-box-demo"
								options={weektaskGroup}
								getOptionLabel={(option) => option.label}
								value={this.state.taskGroup}
								onChange={(e, newValue) => this.handleChangeAuto(e, newValue, "taskGroup")}
								renderInput={(params) => <TextField {...params} label="Groupe d'accés" />}
							/>
						</FormControl>
						<FormControl className={classes.formControl}>
							<Autocomplete
								id="combo-box-demo"
								options={weektasktype}
								getOptionLabel={(option) => option.label}
								value={this.state.taskType}
								onChange={(e, newValue) => this.handleChangeAuto(e, newValue, "taskType")}
								renderInput={(params) => <TextField {...params} label="Accès" />}
							/>
						</FormControl>
						<FormControl className={classes.formControl}>
							<TextField id="standard-basic" label="Titre" variant="standard"
								style={{ width: '300px' }}
								value={this.state.titleValue}
								// defaultValue={''}
								onChange={event => this.handleChange(event, "titre")}
								onKeyPress={event => this.handleKeyPress(event)}
								helperText={<p style={{ fontSize: '0.65rem' }}>
									Recherche à partir de 3 lettres <br />
									Click "entrer" pour valider
								</p>}

								InputProps={{
									startAdornment: (
										<InputAdornment position="start">
											<SearchIcon />
										</InputAdornment>
									),
									endAdornment: (<InputAdornment position="end" size="small">
										<IconButton
											aria-label='supprimer'
										>
											<CloseIcon onClick={(e) => this.reset(e, 'title')} />
										</IconButton></InputAdornment>)
								}}>

							</TextField>
						</FormControl>
						<Button className={classes.button} onClick={() => this.resetFilter()}>Réinitialiser</Button>

					</Grid>
					<Grid item xs={4}>

					</Grid>
					<Grid item xs={10}>
						<FormControl className={classes.formControl} style={{ minWidth: '250px' }}>
							<Autocomplete
								id="combo-box-demo"
								options={users}
								value={this.state.selectUser}
								getOptionLabel={(option) => option.gsm ? option.gsm : option.name}
								onChange={(e, newValue) => this.handleChangeAuto(e, newValue, "user")}
								renderInput={(params) => <TextField {...params} label="Affecter à un responsable" />}
							/>
						</FormControl>


					</Grid>
				</Grid>
				{/* <AssignmentIndIcon /> */}
			</React.Fragment>
		)
	}
}

const mapStateToProps = state => ({
	...state,
	weekNumber: state.reducer.publicationTracking.weekNumber !== null ? state.reducer.publicationTracking.weekNumber : 16,
	yearNumber: state.reducer.publicationTracking.yearNumber !== null ? state.reducer.publicationTracking.yearNumber : 2020,
	weeks: state.reducer.publicationTracking.weeks !== null ? state.reducer.publicationTracking.weeks : [],
	years: state.reducer.publicationTracking.years !== null ? state.reducer.publicationTracking.years : [],
	selectedUser: state.reducer.publicationTracking.selectedUser !== null ? state.reducer.publicationTracking.selectedUser : {
		id: 0,
		name: ''
	},
	selectedCategory: state.reducer.publicationTracking.selectedCategory !== null ? state.reducer.publicationTracking.selectedCategory : 0,
	selectedTaskGroup: state.reducer.publicationTracking.selectedTaskGroup !== null ? state.reducer.publicationTracking.selectedTaskGroup : 0,
	selectedTaskType: state.reducer.publicationTracking.selectedTaskType !== null ? state.reducer.publicationTracking.selectedTaskType : 0,
	programTitle: state.reducer.publicationTracking.programTitle !== null ? state.reducer.publicationTracking.programTitle : ""
})

const mapDispatchToProps = ({
	onChangeWeek,
	crudGetList: crudGetListAction,
	onLoadWeeks,
	onChangeYear,
	onLoadPlanning,
	onChangeUser,
	onChangeCategory,
	onChangeTaskType,
	onChangeSearch,
	onChangeTaskGroup
})

const enhance = compose(
	connect(mapStateToProps, mapDispatchToProps),
	withStyles(styles)
)

export default translate(enhance(UserSelector))