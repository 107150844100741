import React from 'react'
import { SimpleForm, Show, Edit, Create, List, SelectInput, TextInput, Datagrid, TextField, Filter } from 'react-admin'
import { ConcatFieldMovies, MovieField } from '../common/fields'
import { MyListActions, MyShowActions, MyEditActions, MyCreateActions } from '../common/actions'
import { CardShow, CardEdit, CardCreate } from '.'

const mode = [
	{ id: 1, name: 'Identifiant du programme' },
	{ id: 2, name: 'Titre du programme' },
	{ id: 4, name: 'Nom de la personnalité' },
	{ id: 8, name: 'Identifiant de la personnalité' },
	{ id: 16, name: 'Identifiant du contenu' }
]

const CompaniesmoviesFilter = props => (
	<Filter {...props}>
		<SelectInput
			label="fields.criteria"
			variant= "outlined"
			source="mode"
			choices={mode}
			optionValue="id"
			style={{color:'red'}}
			optionText="name"
			alwaysOn
			allowEmpty={false}
		/>
		<TextInput label="fields.value" variant= "outlined" source="q" alwaysOn allowEmpty={false} resettable clearAlwaysVisible />
	</Filter>
)

const CompaniesmoviesList = (props) => (
	<List
		title="titles.companiesMoviesList"
		filters={<CompaniesmoviesFilter />}
		actions={<MyListActions />}
		filterDefaultValues={{ mode: 1 }}
		bulkActionButtons={false}
		{...props}
	>
		<Datagrid rowClick="show">
			<MovieField addAvatar={true} sourceId="id" label="" size={36} />
			<TextField source="id" label="fields.movieid" sortable={false} />
			<ConcatFieldMovies source='title;value' label="fields.title" sortable={false} />
		</Datagrid>
	</List>
)

const CompaniesmoviesShow = props => (
	<Show title=' ' actions={<MyShowActions />} {...props} >
		<CardShow />
	</Show>
)

const CompaniesmoviesEdit = props => (
	<Edit title="titles.companiesMoviesCard" undoable={false} actions={<MyEditActions />} {...props}>
		<SimpleForm>
			<CardEdit />
		</SimpleForm>
	</Edit>
)

const CompaniesmoviesCreate = props => (
	<Create title="titles.companiesMoviesCard" undoable={false} actions={<MyCreateActions />} {...props}>
		<SimpleForm>
			<CardCreate />
		</SimpleForm>
	</Create>
)

export { CompaniesmoviesList, CompaniesmoviesShow, CompaniesmoviesEdit, CompaniesmoviesCreate }