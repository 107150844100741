import React from 'react'
import { CardActions, ListButton } from 'react-admin'

const sanitizeRestProps = ({
	basePath,
	className,
	hasList,
	resource,
	...rest
}) => rest

const MyCreateActions = ({ basePath, className, hasList, ...rest }) => (
	<CardActions className={className} {...sanitizeRestProps(rest)}>
		{hasList && <ListButton basePath={basePath} />}
	</CardActions>
)

export default MyCreateActions