import React from 'react'
import { connect } from 'react-redux'
import compose from 'recompose/compose'

import { MicroEligibilityType } from '../../../constants/types'
import Checkbox from '@material-ui/core/Checkbox'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import { withStyles } from '@material-ui/core/styles'

import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'
import { Button } from '@material-ui/core'
import TextField from '@material-ui/core/TextField'

import Tooltip from '@material-ui/core/Tooltip'
import IconButton from '@material-ui/core/IconButton'
import AddIcon from '@material-ui/icons/Add'
import DeleteIcon from '@material-ui/icons/Delete'

import { onPromotionUpdate } from '../../../actions/Promotion'

const listItemCustom = {
	height: '40px'
}


const styles = (theme) => ({
	button: {
		backgroundColor: '#288DB1',
		'&:hover': {
			background: '#1c627b'
		},
		marginLeft: '.5em',
		marginTop: '.2em'
	},
})

function PromotionsAdd(props) {

	const [searchValue, setSearchValue] = React.useState("")
	// const [msgError, setMsgError] = React.useState("")


	let { classes, create, value, type, info, promotion, disabled } = props
	let element = []
	let parser = new DOMParser()
	let xmlDoc = parser.parseFromString(promotion.rules, "text/xml")

	let bddpList = xmlDoc.getElementsByTagName("BDDPIDS")[0].getElementsByTagName("BDDPID")
	let hapiList = xmlDoc.getElementsByTagName("HAPILISTIDS")[0].getElementsByTagName("HAPILISTID")

	const handleChangeValue = (list) => {
		let parser = new DOMParser()

		const { promotion, onPromotionUpdate } = props
		let xmlDoc = parser.parseFromString(promotion.rules, "text/xml")

		const serializer = new XMLSerializer()
		let prom = promotion
		if (type === 'bddp' && xmlDoc.getElementsByTagName("BDDPIDS")[0]) {
			while (xmlDoc.getElementsByTagName("BDDPIDS")[0].firstChild) {
				xmlDoc.getElementsByTagName("BDDPIDS")[0].removeChild(xmlDoc.getElementsByTagName("BDDPIDS")[0].firstChild)
			}
			list.forEach(element => {
				xmlDoc.getElementsByTagName("BDDPIDS")[0].prepend(element)
			})

			prom.rules = serializer.serializeToString(xmlDoc).replace(/&lt;/g, '<').replace(/&gt;/g, '>')
			onPromotionUpdate(prom)
		}
		if (type === 'hapi' && xmlDoc.getElementsByTagName("HAPILISTIDS")[0]) {
			while (xmlDoc.getElementsByTagName("HAPILISTIDS")[0].firstChild) {
				xmlDoc.getElementsByTagName("HAPILISTIDS")[0].removeChild(xmlDoc.getElementsByTagName("HAPILISTIDS")[0].firstChild)
			}
			list.forEach(element => {
				xmlDoc.getElementsByTagName("HAPILISTIDS")[0].prepend(element)
			})

			prom.rules = serializer.serializeToString(xmlDoc).replace(/&lt;/g, '<').replace(/&gt;/g, '>')
			onPromotionUpdate(prom)
		}
	}

	const createList = (create, listValue = null, type = 'albums', info) => {
		let liste = []
		if (listValue === null || listValue.length === 0) {
			if (create && type === 'micro') {
				liste.push(<ListItem style={listItemCustom}><FormControlLabel control={<Checkbox color="primary" />} name={MicroEligibilityType.RCPL_TVOD} label={MicroEligibilityType.RCPL_TVOD} labelPlacement="end" onChange={onChangeRules('micro')} /></ListItem>)
				liste.push(<ListItem style={listItemCustom}><FormControlLabel control={<Checkbox color="primary" />} name={MicroEligibilityType.CP_ALD} label={MicroEligibilityType.CP_ALD} labelPlacement="end" onChange={onChangeRules('micro')} /></ListItem>)
			} else if (create && type === 'albums') {
				liste.push(<ListItem style={listItemCustom}>
					<TextField variant="standard" onChange={onChangeRules('Search', info)} />
					<Button variant="contained" size="small" color='primary' style={{ marginLeft: '5px' }} onClick={onChangeRules('albumAdd', info)}><Tooltip title="Ajouter"><AddIcon /></Tooltip></Button></ListItem>)
			} else {
				liste.push(<ListItem style={listItemCustom} button> <ListItemText primary={<em>Aucun</em>} /> </ListItem>)
			}
		} else {
			switch (type) {
				case 'micro':
					let onChange = create ? onChangeRules('micro') : null
					if (listValue.includes(';')) {
						liste.push(<ListItem style={listItemCustom}><FormControlLabel checked={true} control={<Checkbox color="primary" />} name={MicroEligibilityType.RCPL_TVOD} label={MicroEligibilityType.RCPL_TVOD} labelPlacement="end" onChange={onChange} /></ListItem>)
						liste.push(<ListItem style={listItemCustom}><FormControlLabel checked={true} control={<Checkbox color="primary" />} name={MicroEligibilityType.CP_ALD} label={MicroEligibilityType.CP_ALD} labelPlacement="end" onChange={onChange} /></ListItem>)
					} else if (listValue.length > 0) {
						if (listValue === MicroEligibilityType.RCPL_TVOD) {
							liste.push(<ListItem style={listItemCustom}><FormControlLabel checked={true} control={<Checkbox color="primary" />} name={MicroEligibilityType.RCPL_TVOD} label={MicroEligibilityType.RCPL_TVOD} labelPlacement="end" onChange={onChange} /></ListItem>)
							liste.push(<ListItem style={listItemCustom}><FormControlLabel checked={false} control={<Checkbox color="primary" />} name={MicroEligibilityType.CP_ALD} label={MicroEligibilityType.CP_ALD} labelPlacement="end" onChange={onChange} /></ListItem>)
						} else {
							liste.push(<ListItem style={listItemCustom}><FormControlLabel checked={false} control={<Checkbox color="primary" />} name={MicroEligibilityType.RCPL_TVOD} label={MicroEligibilityType.RCPL_TVOD} labelPlacement="end" onChange={onChange} /></ListItem>)
							liste.push(<ListItem style={listItemCustom}><FormControlLabel checked={true} control={<Checkbox color="primary" />} name={MicroEligibilityType.CP_ALD} label={MicroEligibilityType.CP_ALD} labelPlacement="end" onChange={onChange} /></ListItem>)
						}
					}
					break
				case 'bddp':
					for (let index = 0; index < listValue.length; index++) {
						liste.push(<ListItem>
							<ListItemText primary={listValue[index].getAttribute('ID')} button />
							{create ?
								(<IconButton aria-label="delete" onClick={onChangeRules('albumDelete', listValue[index].getAttribute('ID'))} >
									<Tooltip title="Supprimer"><DeleteIcon color="primary" /></Tooltip>
								</IconButton>)
								: null
							}
						</ListItem>)
					}
					break
				case 'hapi':
					for (let index = 0; index < listValue.length; index++) {
						liste.push(<ListItem>
							<ListItemText primary={listValue[index].getAttribute('ID')} button />
							{create ?
								(<IconButton aria-label="delete" onClick={onChangeRules('albumDelete', listValue[index].getAttribute('ID'))} >
									<Tooltip title="Supprimer"><DeleteIcon color="primary" /></Tooltip>
								</IconButton>)
								: null
							}
						</ListItem>)
					}
					break
				default:
					break
			}
		}
		return liste
	}

	const onChangeRules = (event, element) => e => {
		switch (event) {
			case 'micro':
				const { promotion, onPromotionUpdate } = props
				let parser = new DOMParser()
				const serializer = new XMLSerializer()
				let xmlDoc = parser.parseFromString(promotion.rules, "text/xml")
				let prom = promotion

				let microListe = xmlDoc.getElementsByTagName("USERQUALIFIER")[0].getAttribute("MICRO")
				if (e.target.checked === true) {
					if (microListe === '') {
						xmlDoc.getElementsByTagName("USERQUALIFIER")[0].setAttribute("MICRO", e.target.name)
						prom.rules = serializer.serializeToString(xmlDoc)
						onPromotionUpdate(prom)
					} else {
						if (xmlDoc.getElementsByTagName("USERQUALIFIER")[0].getAttribute("MICRO")) {
							xmlDoc.getElementsByTagName("USERQUALIFIER")[0].setAttribute("MICRO", microListe + ";" + e.target.name)
							prom.rules = serializer.serializeToString(xmlDoc)
							onPromotionUpdate(prom)
						}
					}
				} else {
					let beforeSemicolon = ';' + e.target.name
					let afterSemicolon = e.target.name + ';'
					if (microListe.includes(beforeSemicolon))
						microListe = microListe.replace((beforeSemicolon), '')
					else if (microListe.includes(afterSemicolon))
						microListe = microListe.replace((afterSemicolon), '')
					else
						microListe = microListe.replace(e.target.name, '')

					xmlDoc.getElementsByTagName("USERQUALIFIER")[0].setAttribute("MICRO", microListe)
					prom.rules = serializer.serializeToString(xmlDoc)
					onPromotionUpdate(prom)
				}
				break
			case 'albumAdd':
				if (searchValue.length > 0) {
					const search = searchValue
					if (type === 'bddp') {
						if(!parseInt( search))
							console.log(search)
						let listIds = []
						listIds.push('<BDDPID ID="' + search + '" EXCLUDE="0" />')
						for (let index = 0; index < bddpList.length; index++) {
							listIds.push('<BDDPID ID="' + bddpList[index].getAttribute('ID') + '" EXCLUDE="0" />')
						}
						handleChangeValue(listIds)
					}
					if (type === 'hapi') {
						let listhapiIds = []
						listhapiIds.push('<HAPILISTID ID="' + search + '" EXCLUDE="0" />')
						for (let index = 0; index < hapiList.length; index++) {
							listhapiIds.push('<HAPILISTID ID="' + hapiList[index].getAttribute('ID') + '" EXCLUDE="0" />')
						}

						handleChangeValue(listhapiIds)
					}

				}
				setSearchValue('')

				break
			case 'albumDelete':
				if (type === 'bddp') {
					for (let index = 0; index < bddpList.length; index++) {
						const elementToDelete = bddpList[index]
						if (element === elementToDelete.getAttribute('ID')) {
							bddpList[index].parentNode.removeChild(bddpList[index])
						}
					}

					let listIds = []
					for (let index = 0; index < bddpList.length; index++) {
						listIds.push('<BDDPID ID="' + bddpList[index].getAttribute('ID') + '" EXCLUDE="0" />')
					}
					handleChangeValue(listIds)
				}
				if (type === 'hapi') {
					for (let index = 0; index < hapiList.length; index++) {
						const elementToDelete = hapiList[index]
						if (element === elementToDelete.getAttribute('ID')) {
							hapiList[index].parentNode.removeChild(hapiList[index])
						}
					}

					let listIds = []
					for (let index = 0; index < hapiList.length; index++) {
						listIds.push('<HAPILISTID ID="' + hapiList[index].getAttribute('ID') + '" EXCLUDE="0" />')
					}
					handleChangeValue(listIds)
				}
				break
			case 'search':
				// console.log(parseInt(e.target.value))
				// if(type === 'bddp' && parseInt(e.target.value) === null){
				// 	setMsgError('BDDP doit être un entier')
				// } 

				setSearchValue(e.target.value)
				break
			default:
				break
		}
	}

	element.push(createList(create, type === 'bddp' ? bddpList : type === 'hapi' ? hapiList : value, type, info))
	//element.push(createList(create, type === 'hapi' ? hapiList : value, type, info))

	return (
		<div>
			{create && (type === 'bddp' || type === 'hapi') ?
				(<div>
					<TextField variant="outlined" size="small" onChange={onChangeRules('search')} value={searchValue}  
					type={type ==='bddp' ? 'number' : 'text'}
					 placeholder='Ajouter' />
					<Tooltip title="Ajouter">
						<Button variant="contained" size="small" color="primary" onClick={onChangeRules('albumAdd')} disabled={disabled}
							className={classes.button}>
							<AddIcon />
						</Button>
					</Tooltip>
				</div>)
				: null
			}
			<List component="nav" style={{ boxShadow: 'rgba(9, 30, 66, 0.25) 0px 1px 1px, rgba(9, 30, 66, 0.13) 0px 0px 1px 1px', marginTop: '.5em' }}>
				{element}
			</List>

		</div>

	)
}

const mapStateToProps = state => ({
	...state,
	promotion: state.reducer ? state.reducer.Promotion.payload : null
})

const mapDispatchToProps = ({
	onPromotionUpdate
})

const enhance = compose(
	connect(mapStateToProps, mapDispatchToProps),
	withStyles(styles)
)

export default enhance(PromotionsAdd)