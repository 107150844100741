import React from 'react'
import Dialog from '@material-ui/core/Dialog'
import Slide from '@material-ui/core/Slide'
import PropTypes from 'prop-types'
import cyan from '@material-ui/core/colors/cyan'
import LinearProgress from '@material-ui/core/LinearProgress'
import Button from '@material-ui/core/Button'
import {CancelIcon} from '../../../../constants/icons'
import { withStyles,  } from '@material-ui/core/styles'

const Transition = React.forwardRef(function Transition(props, ref) {
	return <Slide direction="up" ref={ref} {...props} />
})

const styles = theme =>  ({
	paperWidthSm : {
		maxWidth: '600px',
		width: '400px'
	},
	button: {
		margin: theme.spacing(1),
		width : '150px'
	},
	buttonCancel: {
		margin: theme.spacing(1),
		width : '150px',
		background: '#e23434'
	},
	buttonCheck: {
		margin: theme.spacing(1),
		width : '150px',
		//background: '#0c9e0c'
	},
	leftIcon: {
		marginRight: theme.spacing(1),
	},
	dialogHeader:{
		padding: '10px 10px 10px',
		background: cyan[500],
		color: 'white',
		fontSize: 'medium',
		fontWeight: 'bold',
		textAlign: 'center',
		//height: '36px'
	},
	closeIcon:{
		//marginLeft: '40%',
		width : '15px',
		position: 'absolute',
    	top: '0px',
    	right: '0px',
		borderRadius: '0',
		color: 'white',
		'&:hover': {
			background: 'red'//red
		}
	},
	dialogContent: {
		padding: '16px 24px 17px',
		flex: '1 1 auto',
		//color: '#fff',
		fontSize: '1rem',
		fontWeight: '400',
		textAlign : 'center',
		//backgroundColor : '#424242'
	},
	dialogFooter: {
		flex: '0 0 auto',
		// margin: '8px 4px',
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'flex-end',
		//backgroundColor : '#424242'
	},
	margin: {
		margin: theme.spacing(1),
	},
})

const CustomLinearProgress = props => {
	const { isOperationFinished, operationFinishedText } = props
	if(!isOperationFinished){
		return (
			<React.Fragment>
				<br/>
				<LinearProgress color="secondary" /> 
			</React.Fragment>
		)
	}
	else{
		if(operationFinishedText !== undefined){
			return (
				<React.Fragment>
					<div>Nombre de lignes : {operationFinishedText.rowCount} </div>
					<div>Assignées à {operationFinishedText.userName !== undefined ? operationFinishedText.userName  : '' } </div>
				</React.Fragment>
			)
		}
		else{
			return (
				<React.Fragment>
					<div>Affectation des nouveaux responsables effectuée avec succès</div>
				</React.Fragment>
			)
		}
	}
}

function AssignProcessLoader(props) {
	const [open, setOpen] = React.useState(false)
	const { modalOpen, onClose, classes, progressModalContent, isOperationFinished } = props
	const { resultText } = progressModalContent
	
	function setOpenModalFromProps(modalOpen){
		setOpen(modalOpen)
	}

	React.useEffect(() => {
		setOpenModalFromProps(modalOpen)
	})

	return (
		<div>
			<Dialog
				open={open}
				TransitionComponent={Transition}
				keepMounted
				onClose={onClose}
				aria-labelledby="alert-dialog-slide-title"
				aria-describedby="alert-dialog-slide-description"
				disableEscapeKeyDown = {true}
				disableBackdropClick = {true}
				classes = {{
					paperWidthSm : classes.paperWidthSm
				}}
			>
				<div className={classes.dialogHeader}>
				{!isOperationFinished ? 'Assignation en cours' : 'Assignation terminée'}
					{/* <Button onClick={onClose}  className={classes.closeIcon}  >
						<CloseIcon/>				 
					</Button> */}
				</div>
				<div className={classes.dialogContent}>
					<CustomLinearProgress isVisible = {true} operationFinishedText = {resultText} isOperationFinished = {isOperationFinished} />
				</div>
				<div className={classes.dialogFooter}>
					<Button onClick={onClose} color="secondary" variant="contained" className={classes.buttonCheck} disabled = {!isOperationFinished}>
						<CancelIcon className={classes.leftIcon} />
						Fermer
					</Button>
				</div>
			</Dialog>
		</div>
	)
}

AssignProcessLoader.propTypes = {
	modalOpen: PropTypes.bool,
	onClose: PropTypes.func
}

export default withStyles(styles)(AssignProcessLoader)