import Api from './Api'

const Promotions = {
	getAll: () =>
		Api.Requests.getAll('/Promotions'),
	savePromotion : (data) =>
		Api.Requests.postWithBody('/PromotionsGeneral/Promotions', data),
	getPromotion : (mode, value) =>
		Api.Requests.getAll('/PromotionsGeneral/GetPromotion?mode=' + mode +'&value=' + value),
	getPromotionById : (id) =>
		Api.Requests.getAll('/PromotionsGeneral/GetPromotionById?id=' + id),
	// getPromotionList : (ids) =>
	// 	Api.Requests.postWithBody('/PromotionsGeneral/GetPromotionList', ids),	
	getHapiPromotion : (id) =>
		Api.Requests.getAll('/PromotionsGeneral/GetPromotionHapi/'+ id),
}

export default Promotions
