import React from 'react'
import PropTypes from 'prop-types'
import AppBar from '@material-ui/core/AppBar'
import Tabs from '@material-ui/core/Tabs'
import Tab from '@material-ui/core/Tab'
import Tooltip from '@material-ui/core/Tooltip'
import Typography from '@material-ui/core/Typography'
import Grid from '@material-ui/core/Grid'
import { makeStyles } from '@material-ui/core/styles'
import { UndoIcon } from '../../../constants/icons'
import IconButton from '@material-ui/core/IconButton'

const TabPanel = (props) => {
	const { children, value, index, ...other } = props
	return (
		<div
			role="tabpanel"
			hidden={value !== index}
			id={`simple-tabpanel-${index}`}
			aria-labelledby={`simple-tab-${index}`}
			{...other}
		>
			{value === index && (
				<Grid p={3}>
					<Typography>{children}</Typography>
				</Grid>
			)}
		</div>
	)
}

TabPanel.propTypes = {
	children: PropTypes.node,
	index: PropTypes.any.isRequired,
	value: PropTypes.any.isRequired,
}

function a11yProps(index) {
	return {
		id: `simple-tab-${index}`,
		'aria-controls': `simple-tabpanel-${index}`,
	}
}

const useStyles = makeStyles((theme) => ({
	root: {
	  backgroundColor: theme.palette.background.paper,
	},
	indicator: {
		backgroundColor: '#288DB1',
		marginBottom: '-.3em',
	},
}))

export default function SimpleTabs(props) {
	const {tab1, tab2, tab3,WithReset,onReset} = props
	const [value, setValue] = React.useState(0)
	const classes = useStyles()

	const handleChange = (event, newValue) => {
		setValue(newValue)
	}

	return (
		<div className={classes.root}>
			<AppBar position="static">
				<Tabs value={value} onChange={handleChange} className={classes.indicator} TabIndicatorProps={{style: {background:'#3F51B5'}}} aria-label="simple tabs">
					<Tab label="Heures" {...a11yProps(0)} />
					<Tab label="Jours" {...a11yProps(1)} />
					<Tab label="Mois" {...a11yProps(2)} />
					{WithReset && <div style={{    position: 'absolute', right: 10}}>
					<Tooltip title ='Réinitialiser'>
						<IconButton aria-label="Submit" style={{height:'40px',width:'40px'}} onClick={onReset}>
							<UndoIcon style={{paddingLeft:'30%', color: '#fff'}}></UndoIcon>
                        </IconButton>
					</Tooltip>
					</div>}
				</Tabs>
			</AppBar>
			<TabPanel value={value} index={0}>
				{tab1}
			</TabPanel>
			<TabPanel value={value} index={1}>
				{tab2}
			</TabPanel>
			<TabPanel value={value} index={2}>
				{tab3}
			</TabPanel>
		</div>
	)
}
