import React from 'react'
import PropTypes from 'prop-types'
import { translate } from 'react-admin'
import { DomainsItems } from '../../../tools'
import { CustomCardIcon } from '../../common'
import { WatchLaterIcon, PlayIcon, DoneIcon, ErrorIcon, CancelIcon } from '../../../constants/icons'
import { DomainsType } from '../../../constants/types'
import Color from 'color'
import { withStyles } from '@material-ui/core/styles'

const styles = {
	card: {
	},
}

function JobStatus(props) {
	const { stateid, count, isLoading, classes, translate } = props

	if (isLoading) {
		return null
	}

	const di = DomainsItems.getLabel(DomainsType.JOBSTATE, stateid)
	let label = di ? di : 'unknown'
	const sCount = String(count)

	if (0 === stateid) { // pending
		return <CustomCardIcon value={sCount} label={translate('labels.pending')} Icon={WatchLaterIcon} bgColor='gray' classes={classes} />
	}
	if (1 === stateid) { // inprogress
		return <CustomCardIcon value={sCount} label={label} Icon={PlayIcon} bgColor={ Color('#0000FF').alpha(0.5).string()} classes={classes} />
	}
	else if (2 === stateid) { // done
		return <CustomCardIcon value={sCount} label={label} Icon={DoneIcon} bgColor={Color('#008000').alpha(0.6).string() } classes={classes} />
	}
	else if (3 === stateid) { // error
		return <CustomCardIcon value={sCount} label={label} Icon={ErrorIcon} bgColor={Color('#FF0000').alpha(0.6).string()} classes={classes} />
	}
	else if (4 === stateid) { // canceled
		return <CustomCardIcon value={sCount} label={label} Icon={CancelIcon} bgColor={Color('#FFA500').alpha(0.8).string()} classes={classes} />
	}
	else if (5 === stateid) { // standby
		return <CustomCardIcon value={sCount} label={label} Icon={WatchLaterIcon} bgColor={Color('#FFA500').alpha(0.7).string()} classes={classes} />
	}
}

JobStatus.propTypes = {
	stateid: PropTypes.number,
	count: PropTypes.number,
	isLoading: PropTypes.bool,
	translate: PropTypes.func,
}

export default translate(withStyles(styles)(JobStatus))