import React, { Component } from 'react'
import { translate } from 'react-admin'
import { connect } from 'react-redux'
import compose from 'recompose/compose'
import Button from '@material-ui/core/Button'
import { Links } from '../../../tools'
import { Link } from 'react-router-dom'
import { EditIcon } from '../../../constants/icons'
import { withStyles } from '@material-ui/core/styles'

const styles = theme => ({
	importButton: {
		margin: theme.spacing(1),
		marginTop: '0px',
		top: '5px',
		width : '150px',
		maxHeight: '35px',
		fontSize: '0.75rem',
		backgroundColor : '#288DB1',
		lineHeight: '1.2em',
		'&:hover': {
			background: '#1c627b'
		}
	},
	rightIcon: {
		marginLeft: theme.spacing(1),
	},
})

class PreparationBoardLauncher extends Component{
	constructor(props) {
		super(props)
		this.state = {
			modalOpen : false,
			isProcessLoaderOpen : false,
			isOperationFinished : false,
			resultText : null
		}
	}

	render(){
		const { classes  } = this.props
		const to = Links.PublicationPreparationLink()
		return(
			<React.Fragment>
				<Button component = {Link} color="primary" variant="contained" className={classes.importButton} to = {to}>
					Preparer Publication
					<EditIcon className={classes.rightIcon} />
				</Button>
			</React.Fragment>
		)
	}
}

const mapStateToProps = state => ({
	...state,
	weekNumber: state.reducer.publicationTracking.weekNumber !==null ? state.reducer.publicationTracking.weekNumber : 16,
	yearNumber: state.reducer.publicationTracking.yearNumber !==null ? state.reducer.publicationTracking.yearNumber : 2020
})

const mapDispatchToProps = ({
	// showNotification,
	// onLoadWeeks,
	// onLoadPlanning
})

const enhance = compose(
	connect(mapStateToProps, mapDispatchToProps),
	withStyles(styles)
)

export default translate(enhance(PreparationBoardLauncher))