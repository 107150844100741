import React from 'react'
import { SelectInput, TextInput, SimpleShowLayout, Labeled } from 'react-admin'
import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import Typography from '@material-ui/core/Typography'
import { DomainsItems, Profiles } from '../../../tools'
import { ButtonGroupInput, CheckboxInput } from '../../common/inputs'
import { withStyles } from '@material-ui/core/styles'

const styles = theme =>   ({
	cardPaddingMargin: {
		padding: 20,
		margin: 20,
		flex: '1 auto'
	},
	disabledInput: {
		color: '#999'
	},
	flexContent: {
		display: 'flex',
		alignItems: 'flex-start'
	}
})

const ContentsCreate = withStyles(styles)(props => {
	const { classes, record } = props
	return (
		<div className={classes.flexContent}>
			<Card className={classes.cardPaddingMargin}>
				<CardContent>
					<Typography variant="title">Créer un contenu</Typography>
				</CardContent>
				<SimpleShowLayout>
					<Labeled label="fields.movieid">
						<span className={classes.disabledInput}>{record.movieid}</span>
					</Labeled>
					<ButtonGroupInput label="Audio" />
					<CheckboxInput label="fields.multilanguage" source="multilanguage" />
					<SelectInput
						label="fields.typeid"
						source="typeid"
						choices={DomainsItems.getSelectList(20)}
						allowEmpty={false}
					/>
					<SelectInput
						label="fields.profileid"
						source="profileid"
						choices={Profiles.getSelectList()}
						allowEmpty={false}
					/>
					<TextInput label="fields.filename" source="filename" />
					<TextInput label="fields.sourcename" source="sourcename" />
					<TextInput label="fields.orginalname" source="orginalname" />
					<SelectInput
						label="fields.drmprovid"
						source="drmprovid"
						choices={DomainsItems.getSelectList(49)}
						allowEmpty={false}
					/>
					<CheckboxInput label="fields.usecommondrm" source="usecommondrm" />
				</SimpleShowLayout>
			</Card>
			<Card className={classes.cardPaddingMargin}>
				<CardContent>
					<Typography variant="title">
						Informations complémentaires
					</Typography>
				</CardContent>
				<SimpleShowLayout>
					<TextInput label="fields.duration" source="duration" />
					<TextInput label="fields.size" source="size" />
					<SelectInput
						label="fields.stateid"
						source="stateid"
						choices={DomainsItems.getSelectList(86)}
						allowEmpty={false}
					/>
					<TextInput label="fields.reference" source="reference" />
					<TextInput label="fields.storagepathname" source="storagepathname" />
				</SimpleShowLayout>
			</Card>
		</div>
	)
})

export default ContentsCreate
