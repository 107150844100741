import React from 'react'
import { SimpleShowLayout, TextInput, NumberInput } from 'react-admin'
import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import Typography from '@material-ui/core/Typography'
import { ImageField } from '../../common/fields'
import { withStyles } from '@material-ui/core/styles'

const styles = theme =>   ({
	cardPaddingMargin: {
		padding: 20,
		margin: 20,
		flex: '1 auto',
	},
	cardDiv: {
		flex: '1 auto',
	},
	flexContent: {
		display: 'flex',
		alignItems: 'flex-start',
	}
})

const PicturesCreate = withStyles(styles)(({ classes, record, ids, basePath } = this.props) => (
	<div className={classes.flexContent}>
		<div className={classes.cardDiv}>
			<Card className={classes.cardPaddingMargin}>
				<CardContent>
					<Typography variant="title">Visuel</Typography>
				</CardContent>
				<SimpleShowLayout record={record}>
					<TextInput label="fields.id" source="id" />
					<TextInput label="fields.typeid" source="typeid" />
					<TextInput label="fields.filename" source="filename" />
					<TextInput label="fields.title" source="title" />
					<NumberInput label="fields.width" source="width" />
					<NumberInput label="fields.height" source="height" />
				</SimpleShowLayout>
			</Card>
		</div>
		<Card className={classes.cardPaddingMargin}>
			<CardContent>
				<Typography variant="title">Visualisation</Typography>
			</CardContent>
			<SimpleShowLayout record={record} basePath={basePath}>
				<ImageField source="filename" styleImg="initial" />
			</SimpleShowLayout>
		</Card>
	</div>
))

export default PicturesCreate
