/* eslint-disable no-mixed-spaces-and-tabs */
import React from 'react'
import Dialog from '@material-ui/core/Dialog'
import PropTypes from 'prop-types'
import cyan from '@material-ui/core/colors/cyan'
import { translate, } from 'react-admin'
import { connect } from 'react-redux'
import compose from 'recompose/compose'
import { Publicationstracking } from '../../../requests'
import Paper from '@material-ui/core/Paper'
import {  onChangeUser } from '../../../actions/publicationTracking'
import { CloseIcon } from '../../../constants/icons'
import Button from '@material-ui/core/Button'
import InputLabel from '@material-ui/core/InputLabel'
import MenuItem from '@material-ui/core/MenuItem'
import FormControl from '@material-ui/core/FormControl'
import Select from '@material-ui/core/Select'
import { Labeled } from 'react-admin'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import { CancelIcon, SendIcon } from '../../../constants/icons'
import RadioGroup from '@material-ui/core/RadioGroup'
import TextField from '@material-ui/core/TextField'
import DatePicker from "react-multi-date-picker"
import moment from 'moment'
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography'
import "react-multi-date-picker/styles/layouts/mobile.css"
import { createTheme, withStyles, ThemeProvider } from '@material-ui/core/styles'
import { CustomSwitch, CustomRadio } from '../../layout/GlobalStyles'
// const Transition = React.forwardRef(function Transition(props, ref) {
// 	return <Slide direction="up" ref={ref} {...props} />
// })
moment.locale("fr")

const theme = createTheme({
	overrides: {
		MuiSelect: {
			selectMenu: {
				width: '70px',
				overflow: 'hidden',
				minHeight: '1.1875em',
				maxHeight: '400px',
				whiteSpace: 'nowrap',
				textOverflow: 'ellipsis'
			}
		},
		MuiSnackbarContent: {
			root: {
				backgroundColor: 'rgb(66 202 70 / 80%)'
			}
		}
	},
})

const styles = theme => ({
	dialogGlobal: {
		background: theme.palette.background.default, //'#353333',
		maxHeight: '70%',
		width: '600px',
		minHeight: '450px'

	},
	dialogHeader: {
		padding: '10px 10px 10px',
		background: cyan[500],
		color: 'white',
		fontSize: 'medium',
		fontWeight: 'bold',
		textAlign: 'center',
		//height: '36px'
		//	paddingLeft : '18%'
	},
	closeIcon: {
		color: 'white',
		position: 'absolute',
		width: '15px',
		top: '0px',
		right: '0px',
		borderRadius: '0',
		'&:hover': {
			background: 'red'
		}
	},
	dialogContent: {
		padding: '16px 24px 17px',
		flex: '1 1 auto',
		color: 'white',
		fontSize: '1rem',
		fontWeight: '400',
	},
	formControl: {
		//margin: theme.spacing(3),
		margin: '4px 55px',
		minWidth: '50px',
		display: 'flex',
		padding: '10px 0px 0px 0px'
	},
	formControlswitch: {
		//margin: theme.spacing(3),
		margin: '4px 35px',
		minWidth: '50px',
		display: 'flex',
		padding: '10px 0px 0px 0px',
		position: 'relative'
	},
	dialogFooter: {
		flex: '0 0 auto',
		// margin: '8px 4px',
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'flex-end',
		backgroundColor: theme.palette.background.default,//'#353333'
	},

	dropdownStyle: {
		width: '80px'
	},
	paper: {
		width: '100%',
		marginBottom: theme.spacing(2),
		dropdownStyle: {
			width: '50px'

		},
	},
	switchContainer: {
		display: 'inline-block',
		marginTop: '0',
		position: 'relative',
		marginLeft: '60px'
	},
	paperWidthSm: {
		maxWidth: '750px',
	},
	isupdateclasse: {
		padding: '15px 5px 25px 200px'
	},
	labels: {
		marginTop: '0px',
		marginBottom: '8px',
		fontWeight: 'bold',
		font: { size: '1.4rem' },
		lineHeight: '0',
		Component: {
			padding: '0',
		}
	},
	radioGroup: {
		display: 'inline',
		marginLeft: '60px'

	},
	container: {
		display: 'flex'
	},
	button: {
		margin: theme.spacing(1),
		width: '150px'
	},
	buttonCancel: {
		//margin: theme.spacing(1),
		width: '150px',
		background: '#e23434'
	},
	buttonCheck: {
		margin: theme.spacing(1),
		width: '150px',
		background: '#0c9e0c'
	},

	pubText: {
		float: 'right',
		marginTop: '10%',
		display: 'block'
	},
	refText: {
		float: 'left',
		marginTop: '10%',
		display: 'block'
	},
	leftIcon: {
		marginRight: '10px',
	},
	rmdp: {
		margin: '1px 85px',

	},
	rightIcon: {
		marginLeft: theme.spacing(1),
	},
	snackbarStyleViaContentProps: {
		backgroundColor: 'rgb(66 202 70)'
	}
})

class SelectEmailModel extends React.Component {
	constructor(props) {
		super(props)
		this.state = {
			open: false,
			mailmode: 'instantane',
			referentmail: false,
			publicationMail: false,
			mailtype: 'publication',
			yearValue: '',
			weekvalue: '',
			sendDate: new Date(),
			destinataire: false,
			publicationDates: new Date(),
			rangedate: new Date(),
			showAlert: false,
			checked: false,
			text: '',
			error: ''
		}
	}

	componentDidMount() {
		const { modalOpen } = this.props
		this.setState({
			open: modalOpen,
		})
	}

	componentDidUpdate(previousProps, previousState) {
		const { modalOpen, onChangeUser, weekNumber, yearNumber } = this.props
		if (previousProps.modalOpen !== this.props.modalOpen) {

			this.setState({
				open: modalOpen,
				yearValue: yearNumber,
				weekvalue: Number(weekNumber) 
			})
			onChangeUser(null)
			previousState.isupdate = true
			this.setState({
				isupdate: true
			})
		}

	}
	handleChange = (event, source) => {
		source === "year" ? this.setState({ yearValue: event.target.value })
			: this.setState({ weekvalue: event.target.value })

	}
	handleChangeAlert(event) {
		const target = event.target;
		const value = target.type === 'checkbox' ? target.checked : target.value;
		const name = target.name;
		this.setState({ [name]: value });
	}
	handleRadioChange = (event) => {
		this.setState({ mailmode: event.target.value })
	};
	handleValidateClick = () => {
		const { onAlert } = this.props
		if(this.state.error === '')
		{
		if (this.state.mailtype === "referent") {
			const job = {
				Typeid: 18,
				Refid: 88,
				Executiondate: this.state.mailmode === "instantane" ? new Date() : this.state.sendDate,
				Priority: this.state.destinataire ? 0 : 10,
				Externalid: this.state.yearValue.toString() + this.state.weekvalue.toString(),
				Text : this.state.text
			}
			Publicationstracking.sendMailJob(job, 'referent')
			onAlert()
			this.handleCloseClick()
		}
		if (this.state.mailtype === "publication" && this.state.publicationDates !== '') {
			const job = {
				Typeid: 18,
				Refid: 91,
				Executiondate: this.state.mailmode === "instantane" ? new Date() : this.state.sendDate,
				Priority: this.state.destinataire ? 0 : 10,
				Externalid: this.state.publicationDates,
				Text : this.state.text
			}
			Publicationstracking.sendMailJob(job, 'publication')
			onAlert()
			this.handleCloseClick()
		}
	}

	}

	handleCloseClick = () => {
		const { onClose, weekNumber, yearNumber } = this.props
		this.setState({
			mailmode: 'instantane',
			yearValue: yearNumber,
			weekvalue: Number(weekNumber) ,
			sendDate: new Date(),
			publicationDates: '',
			mailtype: 'publication',
			//showAlert : false
		})
		onClose()
	}


	handleDateChange = (event) => {
		this.setState({ sendDate: event.target.value })
	}
	handleSwitchChange = (event, checked) => {
		this.setState({ destinataire: checked })
	}
	typeMailChange = (event) => {
		this.setState({ mailtype: event.target.value })
	}
	handleDatePublicationChange(date) {
		let dates = ''
		date.forEach(element => {
			dates += ',' + element.day + '/' + element.month.number + '/' + element.year
		});
		this.setState({ publicationDates: dates.substr(1) })
	}
	handleTextChange = (event) => {
		this.setState({ text: event.target.value })
		// if (!event.target.value.match(/[%<>\\$'"]/)) {
		// 	this.setState({ error: "" })
		// } else {
		// 	this.setState({ error:"Caractères non autorisés: %<>$ ' \""})
		// }
	}
	render() {
		const { classes, onClose, weekNumber, yearNumber } = this.props
		const modalTitle = 'Envoyer rapport'
 		// const progressModalContent = {
		// 	title: 'Envoyer rapport',
		// 	closeButtonText: translate('ra.action.close'),
		// 	resultText: this.state.resultText
		// }
		
		var weeks = [];
		for (var i = 1; i <= 53; i++) {
			weeks.push(i)
		}

		let newDate = new Date()
		let year = newDate.getFullYear()
		var years = []
		years.push(year - 1)
		years.push(year)
		years.push(year + 1)
		return (
			<div>
				<Dialog
					open={this.state.open}
					//TransitionComponent={Transition}
					keepMounted
					onClose={onClose}
					aria-labelledby="alert-dialog-slide-title"
					aria-describedby="alert-dialog-slide-description"
					disableEscapeKeyDown={true}
					disableBackdropClick={true}
					classes={{
						paperWidthSm: classes.paperWidthSm
					}}
				>
					<div className={classes.dialogHeader}>
						{modalTitle.toUpperCase()}
						<Button onClick={onClose} className={classes.closeIcon}  >
							<CloseIcon />
						</Button>
					</div>
					<div className={classes.dialogGlobal}>
						<div className={classes.dialogContent}>
							<Paper className={classes.paper}>
								<FormControl className={classes.formControl}>

								</FormControl>
								<FormControl className={classes.formControl} style={{ position: 'relative' }} >
									<Labeled label="Type de rapport :" id="demo-simple-select-helper-label" className={classes.labels}  ></Labeled>
									<ThemeProvider theme={theme}>
										<RadioGroup className={classes.radioGroup} onChange={event => this.typeMailChange(event)} value={this.state.mailtype}  >
											<FormControlLabel value={"publication"} control={<CustomRadio />} label="Publication" defaultValue />
											<FormControlLabel value={"referent"} control={<CustomRadio />} label="Contenus référents" />
										</RadioGroup>
									</ThemeProvider>
									{this.state.mailtype === "referent" ?
										<ThemeProvider theme={theme}>
											<form className={classes.container}>
												<FormControl className={classes.formControl}>
													<InputLabel id="demo-dialog-select-label">Semaine</InputLabel>
													<Select
														labelId="demo-simple-select-helper-label"
														id="demo-simple-select-helper"
														defaultValue={("0" + (Number(weekNumber)).toString()).slice(-2)}
														value={this.state.weekvalue}
														onChange={event => this.handleChange(event, "week")}
														className={classes.customSelect}
														MenuProps={{ classes: { paper: classes.dropdownStyle } }}
													>
														{weeks.map((value) => {
															return <MenuItem value={("0" + value).slice(-2)}>{("0" + value).slice(-2)}</MenuItem>
														})}
													</Select>
												</FormControl>
												<FormControl className={classes.formControl}>
													<InputLabel htmlFor="demo-dialog-native">Année</InputLabel>
													<Select
														labelId="demo-simple-select-helper-label"
														id="demo-simple-select-helper"
														defaultValue={yearNumber}
														value={this.state.yearValue}//{yearNumber}
														onChange={event => this.handleChange(event, "year")}
														className={classes.customSelect}
														MenuProps={{ classes: { paper: classes.dropdownStyle } }}
													>
														{years.map((value) => {
															return <MenuItem value={value}>{value}</MenuItem>
														})}
													</Select>
												</FormControl>
											</form>
										</ThemeProvider>
										: <div></div>
									}
									{this.state.mailtype === "publication" ?
										<div>
											<Labeled label="Période de publication :" id="demo-simple-select-helper-label" className={classes.labels}  ></Labeled>
											<DatePicker
												range
												calendarPosition={'bottom'}
												style={{ margin: '1px 100px' }}
												//type="input-icon"
												label="Date publication :"
												selected={this.state.publicationDates}
												startDate={this.state.publicationDates}
												showOtherDays
												weekStartDayIndex={1}
												cancelLabel="annuler"
												//locale='fr'
												numberOfMonths={2}
												format="DD/MM/YYYY"
												onChange={(date) => this.handleDatePublicationChange(date)}
												mapDays={({ date }) => {
													let props = {}
													let isWeekend = [0, 6].includes(date.weekDay.index)
													if (isWeekend) props.className = "highlight highlight-red"
													return props
												}}
												className="rmdp-mobile"
											/>
										</div> : <div></div>}


								</FormControl>
								
								<FormControl className={classes.formControl}>
									<Labeled label="Destinataire :" id="demo-simple-select-helper-label" className={classes.labels}></Labeled>
									{/* <SwitchMailDestinataire /> */}
									<div className={classes.switchContainer}>

										{/* <InputLabel htmlFor="demo-dialog-native" style={{position: 'relative' ,fontSize: '0.775rem',color : 'black' ,marginRight: '5px'}}>Moi uniquement</InputLabel>
										    <FormControlLabel
											control={<PurpleSwitch
											classes = {{
												root : classes.switchRoot
											}}
											 onChange={(checkedrow) => this.handleSwitchChange(checkedrow)}   />} />
										<InputLabel htmlFor="demo-dialog-native"  style={{position: 'relative',  fontSize: '0.775rem',color : 'black'}} >Liste des contacts</InputLabel> */}
										<Typography component="div">
											<Grid component="label" container alignItems="center" spacing={1}>
												<Grid item>Moi uniquement</Grid>
												<Grid item>
													<CustomSwitch classes={{
														root: classes.switchRoot
													}}
														defaultValue={this.state.destinataire}
														onChange={(e, checked) => this.handleSwitchChange(e, checked)} name="checkedC" />
												</Grid>
												<Grid item>Liste des contacts</Grid>
											</Grid>
										</Typography>
									</div>

								</FormControl>

								<FormControl className={classes.formControl}>
									<Labeled label="Mode d'envoi :" id="demo-simple-select-helper-label" className={classes.labels}></Labeled>
									<ThemeProvider theme={theme}>
									<RadioGroup className={classes.radioGroup} onChange={event => this.handleRadioChange(event)} value={this.state.mailmode}  >
										<FormControlLabel value={"instantane"} control={<CustomRadio />} label="Instantané" defaultValue />
										<FormControlLabel value={"programme"} control={<CustomRadio />} label="Programmé" />
									</RadioGroup>
									</ThemeProvider>
									{this.state.mailmode === "programme" ?
										<TextField id="datetime-local" label="Date et heure d'envoi" type="datetime-local"
											value={this.state.sendDate} onChange={event => this.handleDateChange(event)}
											style={{ marginLeft: '15px', marginBottom: '20px' }}
											className={classes.textField} InputLabelProps={{ shrink: true }}
											inputProps={{ min: "2021-06-08" }} minDate={new Date()} />
										: <div></div>}
								</FormControl>
							
								<FormControl className={classes.formControl}>
										<Labeled label="Message :" id="demo-simple-select-helper-label" className={classes.labels}></Labeled>
										<TextField
											id="outlined-multiline-static"
											label=""
											multiline
											minRows={5}
											onChange={(e) => this.handleTextChange(e)}
											value={this.state.text}
											variant="outlined"
											size="small"
											inputProps={{ maxLength: 200 }}
											helperText={`${200 - this.state.text.length} caractères restants. ` + this.state.error}
											error={!!this.state.error}
										/>
								</FormControl>
								<br/>

								</Paper>
							{/*<div >
							 <AlertProvider template={AlertTemplate} {...options} >
								<Alert onClick={this.handleValidateClick}  className={classes.buttonCheck}  />
							</AlertProvider>
							</div>		 */}
							<div className={classes.dialogFooter}>
								<Button onClick={this.handleValidateClick} disabled={this.state.error !== ''} color="primary" variant="contained" className={classes.buttonCheck}  >
									<SendIcon />
									Envoyer
								</Button>
								<Button onClick={this.handleCloseClick} color="secondary" variant="contained" className={classes.buttonCancel}  >
									<CancelIcon className={classes.leftIcon} />
									Fermer
								</Button>
							</div>
						</div>

					</div>

				</Dialog>
			</div>
		)
	}
}

SelectEmailModel.propTypes = {
	modalOpen: PropTypes.bool,
	onClose: PropTypes.func,
	modalContent: PropTypes.object,
	launchActivationProcess: PropTypes.func,
	crudGetList: PropTypes.func,
	yearNumber: PropTypes.number,
	weekNumber: PropTypes.number
}

const mapStateToProps = state => ({
	...state,
	weekNumber: state.reducer.publicationTracking.weekNumber !== null ? state.reducer.publicationTracking.weekNumber : 16,
	yearNumber: state.reducer.publicationTracking.yearNumber !== null ? state.reducer.publicationTracking.yearNumber : 2020,
	planningData: state.reducer.publicationTracking.planningData !== null ? state.reducer.publicationTracking.planningData : [],
	selectedCategory: state.reducer.publicationTracking.selectedCategory !== null ? state.reducer.publicationTracking.selectedCategory : 0,
	selectedUser: state.reducer.publicationTracking.selectedUser !== null ? state.reducer.publicationTracking.selectedUser : {
		id: 0,
		name: ''
	},
	isupdate: true,
})

const mapDispatchToProps = ({
	// initialize,
	// submit,
	// showNotification,
	// fetchEnd,
	// fetchStart,
	// onLoadPlanning,
	onChangeUser,
	// onChangeCategory
})

const enhance = compose(
	connect(mapStateToProps, mapDispatchToProps),
	withStyles(styles)
)

export default translate(enhance(SelectEmailModel))
