import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import get from 'lodash/get'
import pure from 'recompose/pure'
import sanitizeRestProps from './sanitizeRestProps'

const ConcatFieldPeoples = ({ className, source = {}, record = {}, ...rest }) => {
	const arr = source.split(';')
	return (
		<span className={className} {...sanitizeRestProps(rest)}>
			{
				get(record, arr[2]) !== undefined
					? (<Fragment> {get(record, arr[2])} </Fragment>)
					: (<Fragment> {get(record, arr[0])}, {get(record, arr[1])} </Fragment>)
			}
		</span>
	)
}

ConcatFieldPeoples.propTypes = {
	addLabel: PropTypes.bool,
	basePath: PropTypes.string,
	className: PropTypes.string,
	cellClassName: PropTypes.string,
	headerClassName: PropTypes.string,
	label: PropTypes.string,
	record: PropTypes.object,
	sortBy: PropTypes.string,
	source: PropTypes.string.isRequired,
}

const PureConcatFieldPeoples = pure(ConcatFieldPeoples)

PureConcatFieldPeoples.defaultProps = {
	addLabel: true,
}

export default PureConcatFieldPeoples
