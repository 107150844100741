import React from 'react'
import {	TextInput,	SelectInput,	NumberInput,	SimpleShowLayout} from 'react-admin'
import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import Typography from '@material-ui/core/Typography'
import RichTextInput from 'ra-input-rich-text'
import { DomainsItems, Services, Contacts, Companies } from '../../tools'
import { CheckboxInput, ExtendedInput, DatePickerCanal } from '../common/inputs'
import { withStyles} from '@material-ui/core/styles'

const styles = theme => ({
	cardPaddingMargin: {
		padding: 20,
		margin: 20,
		flex: '1 auto'
	},
	cardDiv: {
		flex: '1 auto'
	},
	flexContent: {
		display: 'flex',
		alignItems: 'flex-start'
	},
	input: {
		display: 'inline-block'
	},
	inputLeft: {
		display: 'inline-block',
		marginLeft: 32
	},
	noMarginLeft: {
		marginLeft: 0
	}
})

const CardEdit = withStyles(styles)(({ classes, record } = this.props) => (
	<div className={classes.flexContent}>
		<Card className={classes.cardPaddingMargin}>
			<CardContent>
				<Typography variant="title">Fiche du transfert {record.label}</Typography>
			</CardContent>
			<SimpleShowLayout>
				<TextInput label="fields.id" source="id" disabled/>
				<TextInput label="fields.label" source="label" />
				<SelectInput label="fields.companyid" source="companyid" choices={Companies.getSelectList()} allowEmpty={false} />
				<SelectInput label="fields.contactid" source="contactid" choices={Contacts.getSelectList()} allowEmpty={false} />
				<SelectInput label="fields.serviceid" source="serviceid" choices={Services.getSelectList()} allowEmpty={false} />
				<SelectInput label="fields.modeid" source="modeid" choices={DomainsItems.getSelectList(9)} allowEmpty={false} />
				<DatePickerCanal label="fields.startdate" source="startdate" />
				<DatePickerCanal label="fields.enddate" source="enddate" />
				<CheckboxInput label="fields.renewed" source="renewed" />
				<CheckboxInput label="fields.exclusive" source="exclusive" />
				<RichTextInput label="fields.comments" source="comments" />
				<SelectInput label="fields.vatid" source="vatid" choices={DomainsItems.getSelectList(25)} allowEmpty={false} />
				<SelectInput label="fields.currencyid" source="currencyid" choices={DomainsItems.getSelectList(3)} allowEmpty={false} />
				<NumberInput label="fields.commissionfee" source="commissionfee" />
			</SimpleShowLayout>
		</Card>
		<div className={classes.cardDiv}>
			<Card className={classes.cardPaddingMargin}>
				<CardContent>
					<Typography variant="title">Gestion</Typography>
				</CardContent>
				<SimpleShowLayout>
					<ExtendedInput label="fields.createdon" source="createdon" date disabled />
					<ExtendedInput label="fields.createdby" source="createdby" contact disabled />
					<ExtendedInput label="fields.modifiedon" source="modifiedon" date disabled />
					<ExtendedInput label="fields.modifiedby" source="modifiedby" contact disabled/>
				</SimpleShowLayout>
			</Card>
		</div>
	</div>
))

export default CardEdit
