import { connect } from 'react-redux'
import compose from 'recompose/compose'
import { onChangeWeek, onLoadWeeks, onChangeYear, onLoadPlanning } from '../../../actions/publicationTracking'
import { crudGetList as crudGetListAction } from 'ra-core/lib/actions/dataActions'
import { Publicationstracking } from '../../../requests'
import { Contacts as ContactsTools,PublicationTrackingReferenceData } from '../../../tools'
import TextField from '@material-ui/core/TextField'
import React, { Component } from 'react'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import InputLabel from '@material-ui/core/InputLabel'
import MenuItem from '@material-ui/core/MenuItem'
import FormControl from '@material-ui/core/FormControl'
import Select from '@material-ui/core/Select'
import { translate } from 'react-admin'
import UpdateIcon from '@material-ui/icons/Update'
import Tooltip from '@material-ui/core/Tooltip'
import { withStyles } from '@material-ui/core/styles'
import CircularProgress from '@material-ui/core/CircularProgress'


const styles = theme =>  ({
	container: {
		display: 'flex',
		flexWrap: 'wrap',
	},
	formControl: {
		margin: theme.spacing(1),
		minWidth: 120,
	},
	customSelect:
	{
		//position: 'absolute',
		top: '8px',
		//margin: theme.spacing(1),

		//padding:'6px 8px',
		maxWidth: '90px',
		minHeight: '40px',
		backgroundColor: '#fff',
		borderRadius: '4px',

	},
	importButton: {
		margin: theme.spacing(1),
		marginTop: '0px',
		maxWidth: '90px',
		height: '32px',
		minHeight: '30px',
		top: '6px',
		backgroundColor: '#fff',
		//lineHeight: '1.2em',
		borderRadius: '4px',
		paddingLeft: '5px',
		borderBottom: '1px solid white'
	},
	weekbutton: {
		top: '5px',
		margin: theme.spacing(1),
		marginTop: '0px',
		maxWidth: '230px',
		height: '35px',
		minHeight: '30px',
		padding: '6px 12px',
		backgroundColor: '#288DB1',
		'&:hover': {
			background: '#1c627b'
		}
	},
	mainclasse: {
		//paddingBottom: '20px',
		paddingRight: '30px',
	},
	dropdownStyle:{
		maxHeight: '200px'
	},
	circularProgress: {
		position: 'absolute',
		top: '50%',
		left: '50%',
	},
})
class WeekSelector extends Component {
	constructor(props) {
		super(props)
		this.state = {
			open: false,
			DefaultWeek: 0,
			week: 0,
			year: 0,
			isLoading: false
		}
	}
	handleChange = (event, source) => {
 		let week = source === "year" ? ("0" + this.state.week).slice(-2) : event.target.value
		let year = source === "year" ? event.target.value : this.state.year

		this.setState({week: week})
		this.setState({year: year})

	}
	handleReset = () => {
 		let d = new Date();
		var target = new Date(d.valueOf());
		var dayNr = (d.getDay() + 6) % 7;
		target.setDate(target.getDate() - dayNr + 3);
		var jan4 = new Date(target.getFullYear(), 0, 4);
		var dayDiff = (target - jan4) / 86400000;
		var weekNr = Math.ceil(dayDiff / 7);
		var week = ("0" + weekNr).slice(-2)
		var year = d.getFullYear()
		this.setState({week: week})
		this.setState({year: year})		
	};
	handleSubmit = () => {
		const { onChangeWeek,  onChangeYear, onLoadPlanning } = this.props
		this.setState({isLoading: true})
		let weeksFilter = Number.parseInt(`${this.state.year.toString()}${this.state.week.toString()}`)
		onChangeWeek(this.state.week)
		onChangeYear(this.state.year)
		Publicationstracking.getPlanning(weeksFilter)
			.then((json) => {
				this.buildPlanningList(json.data, onLoadPlanning)
			})
		this.setState({ open: false })
	}
	handleClose = () => {
		//this.handleReset()
		const {  weekNumber, yearNumber } = this.props
		this.setState({week: weekNumber})
		this.setState({year: yearNumber})
		this.setState({
			open: false
		})
	};
	buildPlanningList = (jsonData, onLoadPlanning) => {
		const trackingData = jsonData
		let planningToBeSaved = {
			publicationsplannings: trackingData.publicationsplannings,
			publicationsstats: []
		}
		const users = ContactsTools.getContactsByGroupid(160)
		let distribution = {
			pgm: trackingData.publicationsplannings.filter(x => x.publications !== null && (x.taskTypeId === 14 || x.taskTypeId === 16 || x.taskTypeId === 17 || x.taskTypeId === 27 || x.taskTypeId === 28)),
			ouv: trackingData.publicationsplannings.filter(x => x.publications !== null && (x.taskTypeId === 21 || x.taskTypeId === 22)),
			susp: trackingData.publicationsplannings.filter(x => x.publications !== null && (x.taskTypeId === 11 || x.taskTypeId === 12 || x.taskTypeId === 13 || x.taskTypeId === 24 || x.taskTypeId === 25)),
			chgtprix: trackingData.publicationsplannings.filter(x => x.publications !== null && (x.taskTypeId && PublicationTrackingReferenceData.getTasksTypes().find(r => r.itemid === x.taskTypeId).taskGroupDomainItemId  === 7 )),
			//regulprix: trackingData.publicationsplannings.filter(x => x.publications !== null && (x.taskTypeId >= 31 && x.taskTypeId <= 46)),
			autre: trackingData.publicationsplannings.filter(x => x.publications !== null && ((x.taskTypeId >= 1 && x.taskTypeId <= 3) || x.taskTypeId === null)),

		}

		if (trackingData.publicationsstats !== null && trackingData.publicationsstats.length !== 0) {
			trackingData.publicationsstats.forEach(item => {
				const currentUser = users.find(u => u.id === item.contactId)

				const stat = {
					contactName: currentUser ? currentUser.gsm : '',
					publicationsCount: item.publicationsCount,
					pgm: distribution.pgm !== undefined ? distribution.pgm.filter(x => x.publications.contactId === item.contactId).length : 0,
					ouv: distribution.ouv !== undefined ? distribution.ouv.filter(x => x.publications.contactId === item.contactId).length : 0,
					susp: distribution.susp !== undefined ? distribution.susp.filter(x => x.publications.contactId === item.contactId).length : 0,
					chgtprix: distribution.chgtprix !== undefined ? distribution.chgtprix.filter(x => x.publications.contactId === item.contactId).length : 0,
					//regulprix: distribution.regulprix !== undefined ? distribution.regulprix.filter(x => x.publications.contactId === item.contactId).length : 0,
					autre: distribution.autre !== undefined ? distribution.autre.filter(x => x.publications.contactId === item.contactId).length : 0,
					publicationPercent: Number((item.publicationsCount * 100 / trackingData.publicationsplannings.length).toFixed(1))
				}
				planningToBeSaved.publicationsstats.push(stat)
			})
		}
		onLoadPlanning(planningToBeSaved)
		this.setState({isLoading: false})

	}
	render() {
		const { classes, weekNumber, yearNumber } = this.props
		const handleClickOpen = () => {
			console.log('open')
			this.setState({
				open: true
			})
			this.setState({week: weekNumber})
			this.setState({year: yearNumber})
		};
		var weeks = [];
		for (var i = 1; i <= 53; i++) {
			weeks.push(i);
		}
		let newDate = new Date()
		let year = newDate.getFullYear();
		var years = []
		years.push(year - 1)
		years.push(year)
		years.push(year + 1)
		return (
			<div className={classes.mainclasse}>
				<TextField className={classes.importButton} InputProps={{ disableUnderline: true }} onClick={handleClickOpen} value={'S' + (weekNumber.toString().length ===2 ? weekNumber : ('0'+weekNumber.toString())) + '-' + yearNumber} />
				<Button onClick={handleClickOpen} className={classes.weekbutton} color="primary" variant="contained"  >
					<Tooltip title="Modifier la période">
						<UpdateIcon className={classes.rightIcon} />
					</Tooltip>
				</Button>
				<Dialog disableEscapeKeyDown open={this.state.open} onClose={this.handleClose}>
					<DialogTitle>Selectionner une semaine</DialogTitle>
					<DialogContent>
						<form className={classes.container}>
							<FormControl className={classes.formControl}>
								<InputLabel id="demo-dialog-select-label">Semaine</InputLabel>
								<Select
									labelId="demo-simple-select-helper-label"
									id="demo-simple-select-helper"
									value={Number.parseInt(("0" + this.state.week).slice(-2))}
									onChange={event => this.handleChange(event, "week")}
									className={classes.customSelect}
									MenuProps={{ classes: { paper: classes.dropdownStyle }}}
								>
									{weeks.map((value) => {
										return <MenuItem key={value} value={Number.parseInt(("0" + value).slice(-2))}>{("0" + value).slice(-2)}</MenuItem>
									})}
								</Select>
							</FormControl>
							<FormControl className={classes.formControl}>
								<InputLabel htmlFor="demo-dialog-native">Année</InputLabel>
								<Select
									labelId="demo-simple-select-helper-label"
									id="demo-simple-select-helper"
									value={this.state.year}
									onChange={event => this.handleChange(event, "year")}
									className={classes.customSelect}
									MenuProps={{ classes: { paper: classes.dropdownStyle } }}
								>
									{years.map((value) => {
										return <MenuItem key={value} value={value}>{value}</MenuItem>
									})}
								</Select>
							</FormControl>

						</form>
					</DialogContent>
					<DialogActions>
						<Button onClick={this.handleReset} color="primary">
							Cette semaine
						</Button>
						<Button onClick={this.handleClose} color="primary">
							Annuler
						</Button>
						<Button onClick={this.handleSubmit} color="primary">
							Ok
						</Button>
					</DialogActions>
				</Dialog>
				{this.state.isLoading && <div className={classes.circularProgress}><CircularProgress size={50} thickness={2}/></div>}

			</div>
		)
	}
}
const mapStateToProps = state => ({
	...state,
	weekNumber: state.reducer.publicationTracking.weekNumber !== null ? state.reducer.publicationTracking.weekNumber : 16,
	yearNumber: state.reducer.publicationTracking.yearNumber !== null ? state.reducer.publicationTracking.yearNumber : 2020,
	weeks: state.reducer.publicationTracking.weeks !== null ? state.reducer.publicationTracking.weeks : [],
	years: state.reducer.publicationTracking.years !== null ? state.reducer.publicationTracking.years : [],
})
const mapDispatchToProps = ({
	onChangeWeek,
	crudGetList: crudGetListAction,
	onLoadWeeks,
	onChangeYear,
	onLoadPlanning
})

const enhance = compose(
	connect(mapStateToProps, mapDispatchToProps),
	withStyles(styles, { index: 1 })
)
export default translate(enhance(WeekSelector))